import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl, Button } from 'react-bootstrap';
import CheckBox from './CheckBox';

const LabelReviewItem = ({
  IsInnerLabelOk,
  Label,
  IsOuterLabelOk,
  IsLeafletOk,
  LabelReviewItemId,
  Comment,
  updateLabelReviewItem,
  editLabelReviewItemComment,
  resetLabelReviewItemComment,
}) => {
  const updateCheckboxForLabel = (id, label) => ({ target }) => updateLabelReviewItem(id, { [label]: target.checked });
  return (
    <div>
      <Col sm={3}>
        {Label}
      </Col>
      <Col sm={1}>
        <CheckBox
          checked={IsInnerLabelOk === true}
          onChange={updateCheckboxForLabel(LabelReviewItemId, 'IsInnerLabelOk')}
        />
      </Col>
      <Col sm={1}>
        <CheckBox
          checked={IsOuterLabelOk === true}
          onChange={updateCheckboxForLabel(LabelReviewItemId, 'IsOuterLabelOk')}
        />
      </Col>
      <Col sm={1}>
        <CheckBox checked={IsLeafletOk === true} onChange={updateCheckboxForLabel(LabelReviewItemId, 'IsLeafletOk')} />
      </Col>
      <Col sm={6}>
        <Col sm={11}>
          <FormControl
            value={Comment}
            style={{ height: '60px' }}
            componentClass="textarea"
            bsSize="small"
            onChange={({ target }) => editLabelReviewItemComment(LabelReviewItemId, target.value)}
          />
        </Col>
        <Col sm={1}>
          <Button
            bsSize="small"
            bsStyle="default"
            type="button"
            onClick={() => resetLabelReviewItemComment(LabelReviewItemId)}
          >
            <i className="fa fa-undo" aria-hidden="true" />
          </Button>
        </Col>
      </Col>
    </div>
  );
};

export default LabelReviewItem;

LabelReviewItem.defaultProps = {
  IsInnerLabelOk: undefined,
  IsOuterLabelOk: undefined,
  IsLeafletOk: undefined,
  Comment: undefined,
};

LabelReviewItem.propTypes = {
  IsInnerLabelOk: PropTypes.bool,
  Label: PropTypes.string.isRequired,
  IsOuterLabelOk: PropTypes.bool,
  IsLeafletOk: PropTypes.bool,
  LabelReviewItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  Comment: PropTypes.string,
  updateLabelReviewItem: PropTypes.func.isRequired,
  editLabelReviewItemComment: PropTypes.func.isRequired,
  resetLabelReviewItemComment: PropTypes.func.isRequired,
};
