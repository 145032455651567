import queryString from 'query-string';
import { get, del, post } from './apiUtils';

const defaultUrl = '/ProductProportions/';

export default {
  getProductProportions: ({ id, query } = {}) => {
    const url = `${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`;
    return get(url);
  },
  deleteProductProportion: id => del(`${defaultUrl}${id}`),
  saveProductProportion: data => post(`${defaultUrl}`, data),
  reOrderProductProportions: data => post(`${defaultUrl}/ReOrder`, data),
  updateMaxQty: data => post('/UpdateMaxQty', data),
};
