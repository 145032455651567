import { takeLatest, all, select, put } from 'redux-saga/effects';
import * as cosAnnexeFormActions from './cosAnnexeFormActions';
import { createSaveFormWithValidation } from '../utils';
import { cosAnnexeSagas } from '../../cosAnnexe';
import { modalTypes, modalActions, modalSelectors } from '../../modal';

export default function* root() {
  yield all([takeLatest(cosAnnexeFormActions.submitCosAnnexeForm.REQUEST, submitCosAnnexeFormSaga)]);
}

const submitCosAnnexeFormSaga = createSaveFormWithValidation(
  cosAnnexeFormActions.submitCosAnnexeForm,
  cosAnnexeSagas.saveCosAnnexeSaga,
  {
    * onSuccess() {
      const modalType = yield select(modalSelectors.getModalType);
      if (modalType === modalTypes.COS_ANNEXE_FORM) {
        yield put(modalActions.hideModal());
      }
    },
  },
);
