import { takeLatest, takeEvery, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import productPhraseTRApi from '../../api/productPhraseTRApi';
import * as productPhraseTRTypes from './productPhraseTRTypes';
import * as productPhraseTRActions from './productPhraseTRActions';
import * as productPhraseTRSchemas from './productPhraseTRSchemas';

export default function* root() {
  yield all([
    takeEvery(productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.REQUEST, getProductPhraseTRListForProductSaga),
    takeLatest(productPhraseTRTypes.SAVE_PRODUCT_PHRASE_TR.REQUEST, saveProductPhraseTRSaga),
  ]);
}

function* saveProductPhraseTRSaga({ payload, type }) {
  const { ProductId, data, CpsrTypeCode } = payload;

  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productPhraseTRActions.saveProductPhraseTR, productPhraseTRApi.saveProductPhraseTR, data);
  yield put(productPhraseTRActions.listProductPhraseTRForProduct.request({ ProductId, CpsrTypeCode }));
  yield put(applicationActions.unsetLoading(type));
}

function* getProductPhraseTRListForProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    productPhraseTRActions.listProductPhraseTRForProduct,
    productPhraseTRApi.getProductPhraseTRList,
    productPhraseTRSchemas.productPhraseWithPhraseListSchema,
    {
      ...payload,
      OptIncludePhrase: true,
    },
  );
  yield put(applicationActions.unsetLoading(type));
}
