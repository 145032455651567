import queryString from 'query-string';
import { get, post, del } from './apiUtils';

const defaultUrl = '/ControlCenter/';

export default {
  startHangfire: () => get(`${defaultUrl}startHangfire`),
  stopHangfire: () => get(`${defaultUrl}stopHangfire`),
  allowLogin: () => post(`${defaultUrl}allowLogin`),
  prohibitLogin: () => post(`${defaultUrl}prohibitLogin`)
};
