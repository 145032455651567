import * as applicationSelectors from './applicationSelectors';
import * as applicationActions from './applicationActions';
import sagas from './applicationSagas';
import reducer from './applicationReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { applicationSelectors, applicationActions };
export default reducer;
