import { schema } from 'normalizr';
import * as substanceSchemas from '../substance/substanceSchemas';
import * as shadeProportionSchemas from '../shadeProportion/shadeProportionSchemas';
import * as substanceCosAnnexeSchemas from '../substanceCosAnnexe/substanceCosAnnexeSchemas';

export const inciSubstanceSchema = new schema.Entity(
  'inciSubstances',
  {
    Substance: substanceSchemas.substanceSchema,
  },
  { idAttribute: 'SubstanceId' },
);

export const inciListSchema = new schema.Entity(
  'inciList',
  {
    Substances: [inciSubstanceSchema],
    SubstanceCosAnnexes: substanceCosAnnexeSchemas.substanceCosAnnexeListSchema,
  },
  { idAttribute: 'ProductId' },
);

const shadeWithInciListSchema = new schema.Entity(
  'shadeWithInciList',
  {
    ShadeProportions: shadeProportionSchemas.shadeProportionListSchema,
    InciList: new schema.Entity(
      'inciList',
      {
        Substances: [inciSubstanceSchema],
        SubstanceCosAnnexes: substanceCosAnnexeSchemas.substanceCosAnnexeListSchema,
      },
      { idAttribute: 'ShadeId' },
    ),
  },
  { idAttribute: 'ShadeId' },
);

export const shadesWithInciListSchema = [shadeWithInciListSchema];
