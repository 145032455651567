import queryString from 'query-string';
import * as R from 'ramda';
import { get, post, del, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/Shades/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getShades: R.compose(
    get,
    getUrl,
  ),
  updateInci: ({ id, query } = {}) =>
    get(`${defaultUrl}UpdateInci${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  saveShade: data => post(defaultUrl, data),
  deleteShade: id => del(`${defaultUrl}${id}`),
  getShadesWithInci: productId => get(`${defaultUrl}ShadesWithInci?productId=${productId}`),
};
