import React from 'react';
import PropTypes from 'prop-types';
import CosAnnexeListContainer from './_CosAnnexeListContainer';

const CosAnnexeTab = props => {
  const { substanceId } = props;
  return (
    <div>
      <CosAnnexeListContainer substanceId={substanceId} />
    </div>);
};

CosAnnexeTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default CosAnnexeTab;
