import { createSelector } from 'reselect';
import { createGeneralSelectors } from '../_common/selectors';
import * as R from 'ramda';
import { PrefixIndexStrategy } from 'js-search';
import createFilterOptions from 'react-select-fast-filter-options';

const getOrderItemSlice = state => state.orderItem;
const getPlannerDashboardResultId = state => state.orderItem.plannerDashboardResultId;
const getPlannerDashboardResult = state => state.orderItem.plannerDashboardResult;
export const { getById, getIds, makeGetByQuery } = createGeneralSelectors(getOrderItemSlice);
export const getOrderItem = (state, id) => state.orderItem.byId[id];
export const getOrderItemList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getOrderPlannerDashboard = createSelector(
  [getPlannerDashboardResultId, getPlannerDashboardResult],
  (plannerDashboardResultId, plannerDashboardResult) => plannerDashboardResultId.map(id => plannerDashboardResult[id]),
);
export const sortByName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'Name'))]));

const orderItemPrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetOrderItemListFilter = createSelector(R.identity, productList =>
  createFilterOptions({
    options: productList,
    labelKey: 'Name',
    valueKey: 'OrderItemId',
    indexStrategy: orderItemPrefixIndexStrategy,
  }),
);