import PropTypes from 'prop-types';
import React from 'react';
import ProductImpactAssessmentList from './ProductImpactAssessmentList';

const ImpactAssessmentsTab = props => {
  const { ...otherProps } = props;

  return (
    <div>
      <ProductImpactAssessmentList {...otherProps} />
    </div>
  );
};

ImpactAssessmentsTab.propTypes = {};

export default ImpactAssessmentsTab;
