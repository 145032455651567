import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { userRoleTypes } from '../userRole';

const byId = handleActions(
  {
    [userRoleTypes.USER_ROLE_LIST_FOR_ROLES.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.roles,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [userRoleTypes.USER_ROLE_LIST_FOR_ROLES.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...Object.keys(payload.entities.roles).map(Number)]),
    ],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
