import {normalize} from 'normalizr';
import {takeLatest, put, all, select, takeEvery} from 'redux-saga/effects';
import uuidV1 from 'uuid/v1';
import * as formulaReviewWizardTypes from '../formulaReviewWizardTypes';
import * as rawMatDataReviewSchemas from '../../../rawMatDataReview/rawMatDataReviewSchemas';
import * as rawMatDataReviewWizardActions from './rawMatDataReviewWizardActions';
import * as rawMatDataReviewWizardTypes from './rawMatDataReviewWizardTypes';
import * as rawMatDataReviewWizardSelectors from './rawMatDataReviewWizardSelectors';
// import * as rawMatDataReviewWizardSelectors from './rawMatDataReviewWizardSelectors';
import {formulaReviewSelectors} from '../../../formulaReview';
import {referentialSelectors} from '../../../referentials';
import {rawMatDataReviewSelectors} from '../../../rawMatDataReview';

export default function* root() {
  yield all([
    takeLatest(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.SUCCESS, newFomulaReviewWizardSaga),
    takeLatest(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.SUCCESS, editFomulaReviewWizardSaga),
    takeEvery(rawMatDataReviewWizardTypes.UPDATE_RAWMAR_REVIEW_DATA, updateRawMatDataReviewWizardSaga),
  ]);
}

function* updateRawMatDataReviewWizardSaga({payload}) {
  if (Object.prototype.hasOwnProperty.call(payload, 'IsOk')) {
    const {id, IsOk} = payload;
    const {RawMatAnnexePartCode} = yield select(
      state => rawMatDataReviewWizardSelectors.getRawMatDataReviewsbyId(state)[id],
    );

    const rawMatAnnexePart = yield select(state =>
      referentialSelectors.getRawMatAnnexeParts(state).find(ra => ra.Code === RawMatAnnexePartCode),
    );

    const defaultComment = rawMatAnnexePart.DefaultComment;

    yield put(rawMatDataReviewWizardActions.resetComment(id, IsOk ? '' : defaultComment));
  }
}

function* editFomulaReviewWizardSaga({payload}) {
  const {FormulaReviewId} = payload;

  const rawMatDataReviewList = yield select(state =>
    rawMatDataReviewSelectors.getRawMatDataReviewListForFormulaReview(state, FormulaReviewId),
  );

  const result = normalize(rawMatDataReviewList, rawMatDataReviewSchemas.rawMatDataReviewListSchema);
  yield put(rawMatDataReviewWizardActions.loadRawMatReviewData(result));
}

function* newFomulaReviewWizardSaga({payload}) {
  const {ProductId, FormulaReviewId} = payload;

  const formulaReviewForCopy = yield select(state =>
    formulaReviewSelectors.getById(state)[FormulaReviewId],
  );

  const latestFormulaReviewRawMatDataReviewList = formulaReviewForCopy
    ? yield select(state =>
      rawMatDataReviewSelectors.getRawMatDataReviewListForFormulaReview(state, formulaReviewForCopy.FormulaReviewId),
    )
    : [];

  const rawMatDataReviewList = yield select(state =>
    referentialSelectors.getRawMatAnnexeParts(state).map(RawMatAnnexePart => {
      const rawMatDataReview = latestFormulaReviewRawMatDataReviewList.find(
        rr => rr.RawMatAnnexePartCode === RawMatAnnexePart.Code,
      );
      if (rawMatDataReview) {
        rawMatDataReview.MandatoryRawMats = null;
        rawMatDataReview.NotMandatoryRawMats = null;
      }
      return {
        ...(rawMatDataReview || {
          RawMatAnnexePartCode: RawMatAnnexePart.Code,
          IsOk: true,
        }),
        RawMatDataReviewId: uuidV1(),
      };
    }),
  );

  const result = normalize(rawMatDataReviewList, rawMatDataReviewSchemas.rawMatDataReviewListSchema);

  yield put(rawMatDataReviewWizardActions.loadRawMatReviewData(result));
}
