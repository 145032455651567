import React from 'react';
import PropTypes from 'prop-types';
import SubstanceFunctionListContainer from './_SubstanceFunctionListContainer';

const FunctionListTab = ({ substanceId }) => (
  <div>
    <SubstanceFunctionListContainer substanceId={substanceId} />
  </div>
);

FunctionListTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default FunctionListTab;
