import * as productPhraseFormSelectors from './productPhraseFormSelectors';
import * as productPhraseFormActions from './productPhraseFormActions';
import * as productPhraseFormTypes from './productPhraseFormTypes';
import sagas from './productPhraseFormSagas';
import reducer from './productPhraseFormReducer';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productPhraseFormSelectors, productPhraseFormActions, productPhraseFormTypes };
export default reducer;
