import PropTypes from 'prop-types';
import React from 'react';
import { HelpBlock, Checkbox, FormGroup, ControlLabel } from 'react-bootstrap';

const CheckboxField = props => {
  const {
    label,
    input,
    meta: { error, touched, dirty },
    formAlignment,
    ...otherProps
  } = props;

  let validationState = null;

  if ((touched || dirty) && error) {
    validationState = 'error';
  } else if (dirty) {
    validationState = 'warning';
  }

  return (
    <FormGroup>
      {formAlignment && <ControlLabel />}
      <Checkbox {...input} checked={input.value} bsSize="small" validationState={validationState} {...otherProps}>
        {label}
      </Checkbox>
      {touched && error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

CheckboxField.defaultProps = {
  type: 'text',
  formAlignment: true,
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object, // eslint-disable-line
  formAlignment: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default CheckboxField;
