import { createSelector } from 'reselect';
import uuidV1 from 'uuid/v1';
import { substanceSelectors } from '../../substance';
import { inciListSelectors } from '../../inciList';

export const makeGetInciSubstancesWithImpuritiesSubstanceForProduct = createSelector(
  [inciListSelectors.getInciSubstancesForProduct, substanceSelectors.getImpuritySubstances],
  (inciSubstanceForProduct, substanceImpurityList) =>
    () => {
      const sustanceImpurityIds = substanceImpurityList.map(substance => substance.SubstanceId);
      return inciSubstanceForProduct.filter(rmp => sustanceImpurityIds.includes(rmp.SubstanceId));
    },
);

export const makeGetInciSubstanceListForProduct = createSelector(
  [inciListSelectors.getInciSubstancesForProduct, substanceSelectors.getImpuritySubstances, inciListSelectors.getInciListForProduct],
  (inciSubstanceForProduct, substanceImpurityList, inciList) =>
    () => {
      return substanceImpurityList.map(({ SubstanceId }) => {
        const correspondingInciSubstance = inciSubstanceForProduct.find(rmp => rmp.SubstanceId === SubstanceId);
        return correspondingInciSubstance
          ? { ...correspondingInciSubstance }
          : {
            SubstanceId,
            InciSubstanceId: uuidV1(),
            InciListId: inciList ? inciList.InciListId : undefined,
            HasImpurity: true,
          };
      });
    },
);
