import { cosAnnexeSelectors } from '../../cosAnnexe';
import { cosAnnexeTRSelectors } from '../../cosAnnexeTR';
import {referentialSelectors} from "../../referentials";

export const getInitialValues = (state, cosAnnexeId) => {
  const cosAnnexe = cosAnnexeSelectors.getById(state)[cosAnnexeId];
  const cosAnnexeTRs = cosAnnexeTRSelectors.getCosAnnexeTRByCosAnnexeId(state, cosAnnexeId);
  const languageList = referentialSelectors.getLanguageList(state);
  const values = {
    cosAnnexe
  }
  languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
    const productForLang = cosAnnexeTRs.find(elem => elem.LanguageCode === lang.Alpha2);
    values[lang.Alpha2] = productForLang ? productForLang : {
      CosAnnexeId: cosAnnexeId,
      LanguageCode: lang.Alpha2,
    }
  });
  return values;
};
