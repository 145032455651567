import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import { InputField, SelectField } from '../../../_Common/Forms';
import { phraseFormActions } from '../../../../redux/form/phraseForm';
import { referentialSelectors, referentialsTypes } from '../../../../redux/referentials';
import { applicationSelectors } from '../../../../redux/application';
import formNames from '../../../../constants/formNames';
import { requiredField, maxLength2048, maxLength16 } from '../../../../utils/fieldValidation';
import {cpsrChaptersSelectors} from "../../../../redux/cpsrChapters";
import CheckBox from "../../../Wizards/LabelReviewWizard/_Common/CheckBox";
import BaseLoader from "../../BaseLoader";

const formName = formNames.phrase;
class PhraseForm extends Component {
  state = {
    selectedChapter: [],
    selectedType: 'CPSRA',
    key: 1,
  };
  key = 1;

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues != null && nextProps.initialValues.ChaptersCode != null
      && this.props.initialValues.ChaptersCode !== nextProps.initialValues.ChaptersCode &&
      this.state.selectedChapter !== nextProps.initialValues.ChaptersCode) {
      this.setState({
        selectedChapter: nextProps.initialValues.ChaptersCode.slice(),
        selectedType: nextProps.initialValues.CpsrTypeCode,
      });
    }
  }

  chapterIsSelected = (chapterCode) => {
    const { selectedChapter } = this.state;
    return selectedChapter && selectedChapter.includes(chapterCode);
  }

  chapterIsDisabled = (CpsrTypeCode) => {
    const { selectedType } = this.state;
    const returned = selectedType !== undefined && selectedType !== CpsrTypeCode;
    return returned;
  }

  checkboxClick = (chapterCode) => {
    let values = this.state.selectedChapter;
    if(values.includes(chapterCode)){
      values = values.filter(elem => elem !== chapterCode);
    } else {
      values.push(chapterCode);
    }
    this.props.change('ChaptersCode', values);
    this.setState({ selectedChapter: values });
  }

  render() {
    const {onSubmit, handleSubmit, cpsrTypes, isLoadingCpsrTypes, cpsrChapters, isLoading } = this.props;
    return (
      <BaseLoader isLoading={isLoading}>
        {!isLoading && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Grid fluid>
              <Row>
                <Col sm={6}>
                  <Field
                    name="PhraseCode"
                    label={`${I18n.t('phrase.phraseCode')} * 1`}
                    component={InputField}
                    validate={[requiredField, maxLength16]}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name="CpsrTypeCode"
                    isLoading={isLoadingCpsrTypes}
                    label={`${I18n.t('phrase.CpsrType')} *`}
                    component={SelectField}
                    options={cpsrTypes}
                    validate={requiredField}
                    onValueChange={(data) => {
                      this.setState({selectedType: data.Key });
                    }}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="Text"
                    label={`${I18n.t('phrase.text')} *`}
                    component={InputField}
                    validate={[requiredField, maxLength2048]}
                    componentClass="textarea"
                    style={{height: '200px'}}
                  />
                </Col>
                <h4>{I18n.t('phrase.chapters')}</h4>
                <div style={{ maxHeight: '450px', overflow: 'scroll' }}>
                  <Col sm={12}>
                    {cpsrChapters.map(chapter => {
                      this.key++;
                      return (
                        <Col sm={12} key={this.key}>
                          <Row>
                            <Col sm={12}>
                              <CheckBox
                                disabled={this.chapterIsDisabled(chapter.CpsrTypeCode)}
                                text={chapter.ChapterTitle}
                                checked={this.chapterIsSelected(chapter.ChapterCode)}
                                onChange={(data) => {
                                  this.checkboxClick(chapter.ChapterCode);
                                }}
                                key={chapter.ChapterCode}
                                random={Math.random()}
                              />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Col>
                </div>
              </Row>
            </Grid>
          </Form>
        )}
      </BaseLoader>
    );
  }
};

const mapStateToProps = (state, { phraseId }) => {
  const selector = formValueSelector(formName);
  const selectedCpsrTypeCode = selector(state, 'CpsrTypeCode');

  return ({
    cpsrTypes: referentialSelectors.getCpsrTypeList(state),
    cpsrChapters: cpsrChaptersSelectors.getCpsrChapterList(state),
    // initialValues: values,
    isLoadingCpsrTypes: applicationSelectors.isLoading(state, referentialsTypes.ALL_REFERENTIALS.REQUEST),
    selectedCpsrTypeCode,
  });
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => phraseFormActions.submitPhraseForm(data, dispatch),
  onChapterCheckboxClick: chapterCode => dispatch(phraseFormActions.onChapterCheckboxClickSelected(chapterCode)),
});

PhraseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChapterCheckboxClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cpsrTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  cpsrChapters: PropTypes.arrayOf(
    PropTypes.shape({
      CpsrChapterId: PropTypes.number.isRequired,
      ChapterCode: PropTypes.string.isRequired,
      ChapterTitle: PropTypes.string.isRequired,
    }),
    ).isRequired,
  isLoadingCpsrTypes: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(PhraseForm);
