import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isDirty, formValueSelector, isInvalid, isSubmitting } from 'redux-form';
import ProductProportionFormModal from './ProductProportionFormModal';
import { productSelectors, productTypes } from '../../../../redux/product';
import { rawMatSupplierSelectors } from '../../../../redux/rawMatSupplier';
import { applicationSelectors } from '../../../../redux/application';
import {
  productProportionFormActions,
  productProportionFormSelectors,
} from '../../../../redux/form/productProportionForm';
import formNames from '../../../../constants/formNames';

const formName = formNames.productProportionForm;
const formValueselector = formValueSelector(formName);

const ProductProportionFormContainer = props => <ProductProportionFormModal productId={props.ProductId} {...props} />;

const mapStateToProps = (state, { ProductId, ProductProportionId }) => {
  const getInitialValues = productProportionFormSelectors.makeGetInitialValuesForProduct(ProductId);
  return {
    isLoadingContent: applicationSelectors.isLoading(state, productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST),
    isSubmitting: isSubmitting(formName)(state),
    currentProduct: productSelectors.getProduct(state, ProductId),
    rawMatSupplierList: rawMatSupplierSelectors.getRawMatSupplierList(state),
    rawMatList: productProportionFormSelectors.getRawMatList(state)(ProductId),
    isDirty: isDirty(formName)(state),
    invalid: isInvalid(formName)(state),
    isInEditing: ProductProportionId != null,
    initialValues: getInitialValues(state)(ProductProportionId),
    rawMatId: formValueselector(state, 'RawMatId'),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(productProportionFormActions.onSaveButtonClick()),
  onRawMatSelected: rawMatId => dispatch(productProportionFormActions.onRawMatSelected(rawMatId)),
  onAddButtonClicked: () => dispatch(productProportionFormActions.onAddButtonClicked()),
});

ProductProportionFormContainer.propTypes = {
  ProductId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductProportionFormContainer);
