import { handleActions } from 'redux-actions';
import { ALL_REFERENTIALS } from './referentialsTypes';

export const initialState = {
  countryList: [],
  languageList: [],
  inciCalculationTypes: [],
  CpsrTypeList: [],
  CpsrPhraseStyleList: [],
  CpsrAChapters: [],
  CpsrBChapters: [],
  CpsrInfoRmChapters: [],
  DistributionChannels: [],
  Uoms: [],
  RegulatoryRegions: [],
  Regions: [],
  FormulaReviewConclusions: [],
  ProductUseTypes: [],
  LabelInfoAvailabilityCodes: [],
  LabelReviewReferentialData: [],
  OrderTypes: [],
  SubstanceInformationTypes: [],
  ImpurityTypes: [],
  ImpactAssessmentThresholdTypes: [],
  ImpactAssessmentThresholdComparators: [],
  DirectiveStatusTypes: [],
  MosCalculationTimeRanges: [],
  MosCalculationTypes: [],
  CpsrBTypeList: [],
  PaoHourglassTypes: [],
  CosAnnexeTypes: [],
  ProcessTypeRefList: [],
  Currencies: [],
  CramerClasses: [],
};

export default handleActions(
  {
    [ALL_REFERENTIALS.SUCCESS]: (state, { payload }) => ({
      ...state,
      CosAnnexeTypes: payload.CosAnnexeTypes,
      languageList: payload.Languages,
      countryList: payload.Countries,
      CpsrAChapters: payload.CpsrAChapters,
      CpsrBChapters: payload.CpsrBChapters,
      CpsrBTypeList: payload.CpsrBTypes,
      CpsrInfoRmChapters: payload.CpsrInfoRmChapters,
      CpsrPhraseStyleList: payload.CpsrPhraseStyles,
      CpsrTypeList: payload.CpsrTypes,
      DirectiveStatusTypes: payload.DirectiveStatusTypes,
      DistributionChannels: payload.DistributionChannels,
      FormulaReviewConclusions: payload.FormulaReviewConclusions,
      ImpurityTypes: payload.ImpurityTypes,
      ImpactAssessmentThresholdTypes: payload.ImpactAssessmentThresholdTypes,
      ImpactAssessmentThresholdComparators: payload.ImpactAssessmentThresholdComparators,
      inciCalculationTypes: payload.InciCalculationTypes,
      LabelInfoAvailabilityCodes: payload.LabelInfoAvailabilityCodes,
      LabelReviewReferentialData: payload.LabelReviewReferentialData,
      MosCalculationTimeRanges: payload.MosCalculationTimeRanges,
      MosCalculationTypes: payload.MosCalculationTypes,
      OrderTypes: payload.OrderTypes,
      PaoHourglassTypes: payload.PaoHourglassTypes,
      ProductUseTypes: payload.ProductUseTypes,
      RawMatAnnexeParts: payload.RawMatAnnexeParts,
      RegulatoryRegions: payload.RegulatoryRegions,
      Regions: payload.Regions,
      SubstanceInformationTypes: payload.SubstanceInformationTypes,
      Uoms: payload.Uoms,
      ProcessTypeRefList: payload.ProcessTypeRefs,
      Currencies: payload.Currencies,
      CramerClasses: payload.CramerClasses,
    }),
  },
  initialState,
);
