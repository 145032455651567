import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { createSaveForm } from '../form/utils';
import workItemApi from '../../api/workItemApi';
import * as workItemTypes from './workItemTypes';
import * as workItemActions from './workItemActions';
import { workItemListSchema } from './workItemSchemas';
import formNames from '../../constants/formNames';
import { callApiSaga, createFetchSaga } from '../sagaUtils';
import { modalTypes, modalActions, modalSelectors } from '../modal';
import * as taskActions from '../task/taskActions';
import taskApi from '../../api/taskApi';
import * as taskTypes from '../task/taskTypes';

export default function* root() {
  yield all([
    takeLatest(workItemTypes.WORK_ITEM_LIST.REQUEST, getWorkItemListSaga),
    takeLatest(workItemTypes.SAVE_WORK_ITEM.REQUEST, saveWorkItemSaga),
    takeLatest(workItemTypes.DELETE_WORK_ITEM.REQUEST, deleteWorkItemSaga),
  ]);
}

function* getWorkItemListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const workItems = yield call(workItemApi.getWorkItems, payload);
    const normalizedData = normalize(workItems, workItemListSchema);
    yield put(workItemActions.listWorkItems.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(workItemActions.listWorkItems.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* deleteWorkItemSaga({ payload, type }) {
  const { workItemId, searchCriteria } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(workItemActions.deleteWorkItem, workItemApi.deleteWorkItem, workItemId);
  yield put(workItemActions.listWorkItems.request(searchCriteria));
  yield put(applicationActions.unsetLoading(type));
}

export function* saveWorkItemSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { searchCriteria } = payload;
  yield callApiSaga(workItemActions.saveWorkItem, workItemApi.saveWorkItem, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.WORK_ITEM_FORM) {
    yield put(modalActions.hideModal());
  }
  yield put(workItemActions.listWorkItems.request(searchCriteria));
  if (searchCriteria.expert !== null) {
    yield put(taskActions.listTasks.request({ IncludeDetails: true, Expert: searchCriteria.expert }));
  }
  yield put(applicationActions.unsetLoading(type));
}
