import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {applicationSelectors} from '../../../redux/application';
import {productActions, productTypes, productSelectors} from '../../../redux/product';
import CheckListTab from './CheckListTab';
import workflowStatus from '../../../constants/workflowStatus';

class CheckListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <CheckListTab {...this.props} />;
  }
}

const mapStateToProps = (state, {productId}) => {
  const isLoadingCheckListTab = applicationSelectors.isLoading(
    state,
    productTypes.FETCH_LAST_FR_CHECK_LIST.REQUEST,
    productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST,
  );
  const isLoadingMissingDocument = applicationSelectors.isLoading(
    state,
    productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST,
  );
  const lastFRProductData = productSelectors.getCurrentLastFrCheckList(state);
  const missingDocuments = productSelectors.getCurrentLastFrMissingDocument(state);

  return {
    isLoadingCheckListTab,
    isLoadingMissingDocument,
    lastFRProductData,
    missingDocuments,
  };
};

const mapDispatchToProps = (dispatch, {productId}) => ({
  onLoad: () => {
    dispatch(productActions.fetchLastFRCheckList.request(productId));
    dispatch(productActions.fetchLastFRMissingDocument.request(productId));
  },
  onRefreshMissingDocuments: () => {
    dispatch(productActions.fetchLastFRMissingDocument.request(productId));
  },
  onReceivedClick: document => dispatch(productActions.checkListChangeStatusDocument.request({
    productId,
    document,
    status: workflowStatus.DOCUMENT_RECEIVED
  })),
  onVerifiedClick: document => dispatch(productActions.checkListChangeStatusDocument.request({
    productId,
    document,
    status: workflowStatus.DOCUMENT_RECEIVED_VERIFIED
  })),
  onMissingReceivedClick: document =>
    dispatch(
      productActions.missingDocumentChangeStatus.request({
        id: document.FormulaReviewMissingDocumentId,
        status: workflowStatus.DOCUMENT_RECEIVED,
        productId,
      }),
    ),
  onMissingVerifiedClick: document =>
    dispatch(
      productActions.missingDocumentChangeStatus.request({
        id: document.FormulaReviewMissingDocumentId,
        status: workflowStatus.DOCUMENT_VERIFIED,
        productId,
      }),
    ),
});

CheckListContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckListContainer);
