import PropTypes from 'prop-types';
import React from 'react';
import uuidV1 from 'uuid/v1';
import reactStringReplace from 'react-string-replace';
import { Field } from 'redux-form';
import RIEField from './RIEField';

class EditableLabelField extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.input.value !== nextProps.input.value;
  }

  render() {
    const {
      input: { value },
      placeholders,
    } = this.props;

    return (
      <div>
        {placeholders.reduce(
          (acc, myValue) =>
            reactStringReplace(acc, myValue.value, match => (
              <Field
                key={uuidV1()}
                component={RIEField}
                name={myValue.name}
                label={myValue.name}
                initValue={myValue.initialValue || `${match}`}
              />
            )),
          value,
        )}
      </div>
    );
  }
}
EditableLabelField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  placeholders: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default EditableLabelField;
