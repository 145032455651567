import { getFormValues, formValueSelector } from 'redux-form';
import { getOr } from 'lodash/fp';
import { productPhraseSelectors } from '../../productPhrase';
import { phraseSelectors } from '../../phrase';

export const getFormValue = (state, formName, field) => formValueSelector(formName)(state, field);

export const getCurrentFormValues = (state, formName) => {
  const result = getFormValues(formName)(state);
  return result;
};

export const getCpsrChapterFilter = (state, formName) => getFormValue(state, formName, 'cpsrChapter');

export const nbSelectedItems = (state, formName) => getOr(0, 'length', getFormValue(state, formName, 'selectedIds'));

export const getInitialFormValues = (state, productId, cpsrTypeCode) => {
  const result = productPhraseSelectors
    .getProductPhraseList(state)
    .filter(productPhrase => productPhrase.ProductId === productId && productPhrase.CpsrTypeCode === cpsrTypeCode)
    .reduce(
      (acc, productPhrase) => ({
        ...acc,
        [productPhrase.ProductPhraseId]: {
          ...productPhrase,
          Phrase: phraseSelectors.getphrase(state, productPhrase.PhraseId),
        },
        allIds: [...acc.allIds, productPhrase.ProductPhraseId],
        visibleIds: [...acc.visibleIds, productPhrase.ProductPhraseId],
      }),
      {
        allIds: [],
        selectedIds: [],
        cpsrChapter: undefined,
        visibleIds: [],
      },
    );
  return result;
};
