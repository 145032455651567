import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { companyDocumentActions, companyDocumentTypes, companyDocumentSelectors } from '../../../redux/companyDocument';
import { applicationSelectors } from '../../../redux/application';

import CompanyDocumentsTabs from './CompanyDocumentsTabs';

class CompanyDocumentsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <CompanyDocumentsTabs {...this.props} />;
  }
}

const mapStateToProps = (state, { companyId }) => ({
  companyDocuments: companyDocumentSelectors.getCompanyDocumentListForCompany(state, companyId),
  isLoadingCompanyDocumentList: applicationSelectors.isLoading(
    state,
    companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY.REQUEST,
    companyDocumentTypes.SAVE_COMPANY_DOCUMENT.REQUEST,
    companyDocumentTypes.UPLOAD_COMPANY_DOCUMENT.REQUEST,
    companyDocumentTypes.DELETE_COMPANY_DOCUMENT.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => dispatch(companyDocumentActions.listCompanyDocumentsForCompany.request(companyId)),
  getCompanyDocuments: () => dispatch(companyDocumentActions.listCompanyDocumentsForCompany.request(companyId)),
  saveCompanyDocument: data => dispatch(companyDocumentActions.saveCompanyDocument.request(companyId, data)),
  onUploadFile: data => dispatch(companyDocumentActions.uploadCompanyDocument.request(companyId, data)),
  downloadCompanyDocument: id => dispatch(companyDocumentActions.downloadDocument.request(id)),
  deleteCompanyDocument: id => dispatch(companyDocumentActions.deleteCompanyDocument.request(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyDocumentsContainer);
