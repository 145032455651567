import { createAction } from 'redux-actions';
import { createRequestActions } from '../../utils';
import * as allergenModalTypes from './allergenModalTypes';

export const onSaveButtonClick = createAction(
  allergenModalTypes.SAVE_BUTTON_CLICKED,
  (rawMatId, data) => data,
  (rawMatId, data) => ({ rawMatId }),
);

export const saveRawMatProportionAllergensForRawMat = createRequestActions(
  allergenModalTypes.SAVE_RAWMAT_PROPORTION_ALLERGENS,
  (rawMatId, data) => data,
  (rawMatId, data) => ({ rawMatId }),
);
