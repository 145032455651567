import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getById = ({ draftLabel }) => draftLabel.byId;
export const getIds = ({ draftLabel }) => draftLabel.list.allIds;
export const getByProductIds = ({ draftLabel }) => draftLabel.list.byProductIds;
export const getDraftLabelList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getDraftLabel = (state, id) => state.draftLabel.byId[id];

export const getDraftLabelForProduct = productId =>
  createSelector([getByProductIds, getById], (byProductIds, byId) => byId[R.head(R.propOr([], productId, byProductIds))]);

export const makeGetDraftLabelForProduct = createSelector(
  getDraftLabelForProduct,
  draftLabelListForProduct => draftLabelListForProduct,
);
