import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { Row, Col, ButtonToolbar, Button, Form } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

import BaseLoader from '../../_Common/BaseLoader';
import ToxProfileItemsForm from './ToxProfileItemsForm';

const ToxProfileForm = props => {
  const {
    handleSubmit,
    onSaveSubstanceToxProfileItems,
    onAddButtonClick,
    onCancelButtonClick,
    isFormPristine,
    submitting,
    isLoading,
    nbSelectedItems,
    onDeleteSubstanceItems,
    invalid,
    onPrintClick,
  } = props;

  return (
    <BaseLoader isLoading={isLoading}>
      <Form onSubmit={handleSubmit(onSaveSubstanceToxProfileItems)}>
        <div>
          <Row>
            <Col sm={5}>
              <ButtonToolbar>
                {/* <Button
                  type="button"
                  bsSize="small"
                  bsStyle="success"
                  onClick={clickToPaste}
                  disabled={false}
                >
                  <i className="fa fa-floppy-o" />
                  &nbsp;
                  <Translate value="general.save" />
                </Button> */}
                <Button type="button" bsSize="small" onClick={onAddButtonClick}>
                  <Translate value="substanceToxProfile.addItem" />
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="danger"
                  type="button"
                  onClick={onCancelButtonClick}
                  disabled={isFormPristine || submitting}
                >
                  <i className="fa fa-undo" />
                  &nbsp;
                  <Translate value="general.cancel" />
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="danger"
                  type="button"
                  onClick={onDeleteSubstanceItems}
                  disabled={nbSelectedItems === 0}
                >
                  <Translate value="general.delete" /> {nbSelectedItems > 0 && `(${nbSelectedItems})`}
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="success"
                  type="submit"
                  disabled={isFormPristine || submitting || invalid}
                >
                  <i className="fa fa-floppy-o" />
                  &nbsp;
                  <Translate value="general.save" />
                </Button>
                <Button bsSize="small" type="button" onClick={onPrintClick}>
                  <i className="fa fa-print" aria-hidden="true" />
                  &nbsp;
                  <Translate value="general.print" />
                </Button>
              </ButtonToolbar>
            </Col>
            <br />
            <br />
          </Row>
          <ToxProfileItemsForm {...props} />
        </div>
      </Form>
    </BaseLoader>
  );
};

ToxProfileForm.defaultProps = {
  cpsrChapterFilter: undefined,
  statusList: [],
  status: undefined,
  onLoadTemplateButtonClick: () => {},
};

ToxProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveSubstanceToxProfileItems: PropTypes.func.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  isFormPristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nbSelectedItems: PropTypes.number.isRequired,
  onDeleteSubstanceItems: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ToxProfileForm);
