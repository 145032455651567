import * as rawMatDocumentSelectors from './rawMatDocumentSelectors';
import * as rawMatDocumentActions from './rawMatDocumentActions';
import * as rawMatDocumentTypes from './rawMatDocumentTypes';
import sagas from './rawMatDocumentSagas';
import reducer from './rawMatDocumentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { rawMatDocumentSelectors, rawMatDocumentActions, rawMatDocumentTypes };
export default reducer;
