import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import { css } from 'glamor';
import formNames from '../../../../constants/formNames';
import { InputField, DateField } from '../../../_Common/Forms';
import { isPositiveOrZeroInteger, requiredField } from '../../../../utils/fieldValidation';
import { workItemActions } from '../../../../redux/workItem';
import { workItemFormSelectors } from '../../../../redux/form/workItemForm';
import * as workItemFormActions from '../../../../redux/form/workItemForm/workItemFormActions';

const formName = formNames.workItem;

const style = {
  detailsContainer: css({
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__input-container': {
      width: '100%',
    },
    '& .react-datepicker__time-list': {
      paddingInlineStart: '0px',
    },
  }),
};

class WorkItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderItemId: null,
    };
  }

  handleStartedOnChange = (e, val) => {
    this.props.onStartedOnChange(val);
  };

  handleFinishedOnChange = (e, val) => {
    this.props.onFinishedOnChange(val);
  };

  handleDurationOnChange = (e, val) => {
    this.props.onDurationChange(val);
  };

  render() {
    const { onSubmit, handleSubmit } = this.props;

    return (
      <div className={style.detailsContainer}>
        <Form horizontal onSubmit={handleSubmit(onSubmit)}>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Field
                  name="OrderNr"
                  label={`${I18n.t('dailyRecords.orderNr')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="ProductCode"
                  label={`${I18n.t('dailyRecords.productCode')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="ProductName"
                  label={`${I18n.t('dailyRecords.productName')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="ProcessType"
                  label={`${I18n.t('dailyRecords.processType')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="Region"
                  label={`${I18n.t('dailyRecords.region')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="TaskName"
                  label={`${I18n.t('dailyRecords.task')}`}
                  component={InputField}
                  readOnly
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="StartedOn"
                  label={I18n.t('dailyRecords.startedOn')}
                  component={DateField}
                  labelColSize={4}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat={'DD/MM/YYYY HH:mm'}
                  validate={[requiredField]}
                  onChange={this.handleStartedOnChange}
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="Duration"
                  label={`${I18n.t('dailyRecords.duration')}`}
                  component={InputField}
                  validate={[isPositiveOrZeroInteger]}
                  onChange={this.handleDurationOnChange}
                />
              </Col>
              <Col sm={12}>
                <Field
                  name="FinishedOn"
                  label={I18n.t('dailyRecords.finishedOn')}
                  component={DateField}
                  labelColSize={4}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat={'DD/MM/YYYY HH:mm'}
                  onChange={this.handleFinishedOnChange}
                />
              </Col>
            </Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, { WorkItemId }) => ({
  initialValues: workItemFormSelectors.getInitialValues(WorkItemId)(state),
});

const mapDispatchToProps = (dispatch, { searchCriteria }) => ({
  onSubmit: data => dispatch(workItemActions.saveWorkItem.request({ ...data, searchCriteria })),
  onStartedOnChange: val => dispatch(workItemFormActions.onStartedDateChanged(val)),
  onFinishedOnChange: val => dispatch(workItemFormActions.onFinishDateChanged(val)),
  onDurationChange: val => dispatch(workItemFormActions.onDurationChanged(val)),
});

WorkItemForm.defaultProps = {
  initialValues: {},
};

WorkItemForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onStartedOnChange: PropTypes.func.isRequired,
  onFinishedOnChange: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(WorkItemForm);
