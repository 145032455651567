import { createRequestTypes } from '../utils';

export const COMPANY_LIST = createRequestTypes('company/COMPANY_LIST');
export const COMPANY = createRequestTypes('company/COMPANY');
export const COMPANY_PRODUCT_LIST = createRequestTypes('company/COMPANY_PRODUCT_LIST');
export const COMPANY_PRODUCT_LIST_BY_RAW_MATS = createRequestTypes('company/COMPANY_PRODUCT_LIST_BY_RAW_MATS');
export const COMPANY_RAW_MAT_LIST = createRequestTypes('company/COMPANY_RAW_MAT_LIST');
export const COMPANY_DISTRIBUTOR_LIST = createRequestTypes('company/COMPANY_DISTRIBUTOR_LIST');
export const COMPANY_ORDER_LIST = createRequestTypes('company/COMPANY_ORDER_LIST');
export const SAVE_COMPANY = createRequestTypes('company/SAVE_COMPANY');
export const DELETE_COMPANY = createRequestTypes('company/DELETE_COMPANY');
export const ADD_DISTRIBUTOR_TO_COMPANY = createRequestTypes('company/ADD_DISTRIBUTOR_TO_COMPANY');
export const ADD_DISTRIBUTION_CHANNEL_TO_COMPANY = createRequestTypes('company/ADD_DISTRIBUTION_CHANNEL_TO_COMPANY');
export const NEW_COMPANY = 'company/NEW_COMPANY';
export const COMPANY_DISTRIBUTION_CHANNEL_LIST = createRequestTypes('company/COMPANY_DISTRIBUTION_CHANNEL_LIST');
export const REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY = createRequestTypes(
  'company/REMOVE_DISTRIBUTION_CHANNEL_FROM_COMPANY',
);
export const SUBMIT_COMPANY_FORM = 'company/SUBMIT_COMPANY_FORM';
export const SUBMIT_COPY_RM_FORM = createRequestTypes('company/SUBMIT_COPY_RM_FORM');
export const UPDATE_COMPANY_RPSTATUS = createRequestTypes('company/UPDATE_COMPANY_RPSTATUS');
export const UPDATE_COMPANY_RPSTATUS2 = createRequestTypes('company/UPDATE_COMPANY_RPSTATUS2');