import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import ShadeXRmCompoListContainer from './_ShadeXRmCompoListContainer';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
  }),
};

const ShadeXRmCompoModal = props => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <div className={styles.modalBody}>
      <Modal.Body>
        <ShadeXRmCompoListContainer productId={props.productId} />
      </Modal.Body>
    </div>
    <Modal.Footer>
      <div className={styles.buttonPanel}>
        <ButtonToolbar>
          <Button bsSize="small" bsStyle="danger" type="button" onClick={props.closeModal}>
            <i className="fa fa-times" />&nbsp;
            <Translate value="general.close" />
          </Button>
        </ButtonToolbar>
      </div>
    </Modal.Footer>
  </div>
);

ShadeXRmCompoModal.defaultProps = {
  title: '',
};

ShadeXRmCompoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
};

export default ShadeXRmCompoModal;
