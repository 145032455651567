import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { statsActions } from '../../../redux/stats';
import { userSelectors } from '../../../redux/user';
import StatPageForm from './StatPageForm';

const formName = 'statPageForm';

class StatPageFormContainer extends Component {
  componentDidMount() {
    // this.props.onLoadStatPageForm();
  }

  render() {
    return (
      <div>
        <StatPageForm {...this.props} />
      </div>
    );
  }
}

StatPageFormContainer.propTypes = {
  // onLoadStatPageForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const currentUser = userSelectors.getCurrentUser(state);
  return {
    showStatPageForm: currentUser.ActionCodes.indexOf('ViewDashboard') >= 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSearchClicked: payload => dispatch(statsActions.computeStats.request(payload)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(StatPageFormContainer);
