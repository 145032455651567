import PropTypes from 'prop-types';
import React from 'react';
import BaseFormGroup from './BaseFormGroup';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

class CanvasField extends React.PureComponent {
  state = {
    touched: false,
    changed: false,
    structure: null,
    initialImage: null,
  };

  componentDidMount() {
    this.checkInitialImage();
  }

  componentDidUpdate() {
    this.checkInitialImage();
  }

  checkInitialImage() {
    const initialImage = this.props.initialImage;
    if (initialImage !== this.state.initialImage) {
      this.setState({ initialImage, changed: false });
      if (initialImage != null) {
        this.onImageLoad(`data:image/png;base64,${initialImage}`);
      } else {
        this.clearImage();
      }
    }
  }

  clearImage() {
    const canvas = document.getElementById('mycanvas');
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, 200, 100);
  }

  onImageLoad(src) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.getElementById('mycanvas');
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, 200, 100);
      const imageWidth = this.width;
      const imageHeight = this.height;
      const propLg = imageWidth / 200;
      const propHt = imageHeight / 100;
      const prop = propLg > propHt ? propLg : propHt;
      const drawWidth = imageWidth / prop;
      const drawHeight = imageHeight / prop;

      const startWidth = (200 - drawWidth) / 2;
      const startHeight = (100 - drawHeight) / 2;
      ctx.drawImage(img, startWidth, startHeight, drawWidth, drawHeight);
    };
    img.src = src;
  }

  handleChange = value => {
    this.setState({
      touched: true,
    });
    this.props.input.onChange(value);
  };

  onPaste = e => {
    this.setState({
      touched: true,
    });
    this.retrieveImageFromClipboardAsBlob(e, imageBlob => {
      if (imageBlob) {
        this.setState({ changed: true, structure: imageBlob });

        const URLObj = window.URL || window.webkitURL;
        const src = URLObj.createObjectURL(imageBlob);

        this.onImageLoad(src);
      }
    });
    e.preventDefault();
  };

  retrieveImageFromClipboardAsBlob = (pasteEvent, callback) => {
    const divZone = document.getElementById('divZone');
    divZone.innerText = this.props.placeholder;

    if (pasteEvent.clipboardData === false) {
      if (typeof callback == 'function') {
        callback(undefined);
      }
    }

    const items = pasteEvent.clipboardData.items;

    if (items === undefined) {
      if (typeof callback == 'function') {
        callback(undefined);
      }
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === -1) continue;
      const blob = items[i].getAsFile();

      if (typeof callback == 'function') {
        callback(blob);
      }
    }
  };

  render() {
    const { placeholder, onSave } = this.props;

    return (
      <BaseFormGroup {...this.props} meta={{ ...this.props.meta, touched: this.state.touched }}>
        <div width="200">
          <div
            id="divZone"
            style={{ border: '1px solid #ddd ', borderRadius: '4px' }}
            width="200"
            contentEditable="true"
            onPaste={this.onPaste}
          >
            {placeholder}
            <br />
            <br />
          </div>
          <canvas id="mycanvas" width="200" height="100" style={{ border: '1px solid #ddd', borderRadius: '4px' }} />
          <Button
            bsSize="small"
            bsStyle="success"
            type={'button'}
            onClick={() => onSave(this.state.structure)}
            disabled={!this.state.changed}
          >
            <i className="fa fa-floppy-o" />
            &nbsp;
            <Translate value="general.save" />
          </Button>
        </div>
      </BaseFormGroup>
    );
  }
}

CanvasField.defaultProps = {
  placeholder: '',
};

CanvasField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string,
  onSave: PropTypes.func,
  data: PropTypes.object,
};

export default CanvasField;
