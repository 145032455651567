import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { functionSelectors, functionActions } from '../../../redux/function';
import {
  substanceFunctionActions,
  substanceFunctionSelectors,
  substanceFunctionTypes,
} from '../../../redux/substanceFunction';
import SubstanceFunctionList from './SubstanceFunctionList';

class SubstanceFunctionListContainer extends Component {
  static propTypes = {
    substanceId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <SubstanceFunctionList {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => {
  const getSubstanceFunctionListForSubstance = substanceFunctionSelectors.makeGetSubstanceFunctionListForSubstance(
    substanceId,
  );
  const getFunctionListNotLinkedWithSubstance = functionSelectors.makeGetFunctionListNotLinkedWithSubstance(
    substanceId,
  );
  return {
    functionListNotLinkedWithSubstance: getFunctionListNotLinkedWithSubstance(state),
    substanceFunctionList: getSubstanceFunctionListForSubstance(state),
    functionList: functionSelectors.getFunctionList(state),
    isLoading: applicationSelectors.isLoading(
      state,
      substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST.REQUEST,
      substanceFunctionActions.saveSubstanceFunction.REQUEST,
      substanceFunctionActions.deleteSubstanceFunction.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(
        substanceFunctionActions.listSubstanceFunctions.request({
          substanceId,
          OptIncludeFunction: true,
        }),
      );
    }
    dispatch(functionActions.listFunctions.request());
  },
  fetchSubstanceFunctionListForSubstance: () =>
    dispatch(
      substanceFunctionActions.listSubstanceFunctions.request({
        substanceId,
        OptIncludeFunction: true,
      }),
    ),
  onDelete: ({ SubstanceFunctionId }) =>
    dispatch(
      substanceFunctionActions.deleteSubstanceFunction.request(SubstanceFunctionId, {
        substanceId,
        OptIncludeFunction: true,
      }),
    ),
  onAdd: ({ FunctionId }) =>
    dispatch(substanceFunctionActions.saveSubstanceFunction.request({ FunctionId, substanceId })),
});

SubstanceFunctionListContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceFunctionListContainer);
