import { all, call, put, takeLatest } from 'redux-saga/effects';
import { applicationActions } from '../../application';
import { modalActions } from '../../modal';
import { shadeInciListSagas } from '../../shadeInciList';
import { shadeXInciActions, shadeXInciTypes } from './index';

export default function* root() {
  yield all([takeLatest(shadeXInciTypes.LOAD_MODAL_DATA.REQUEST, loadModalDataSaga)]);
}

function* loadModalDataSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { ProductId } = payload;
    yield call(shadeInciListSagas.getShadesInciListForProductSaga, { payload: { ProductId } });

    yield put(shadeXInciActions.loadModalData.success(payload));
    yield put(modalActions.showShadeXInciModal({ productId: ProductId }));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(shadeXInciActions.loadModalData.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
