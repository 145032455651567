import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Row, Col, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import { InputField, SelectField } from '../../_Common/Forms';
import formNames from '../../../constants/formNames';
import BaseLoader from '../../_Common/BaseLoader';
import { isPositiveOrZeroInteger } from '../../../utils/fieldValidation';

const OrderProcessInfoForm = props => {
  const {
    handleSubmit,
    onSaveOrderProcessInfo,
    onSelectMultipleProductClick,
    pristine,
    currencies,
    submitting,
    isLoading,
    currentOrderItems,
    availableProductsForCurrentOrder
  } = props;


  const handleSelectMultipleProductClick = () => {
    onSelectMultipleProductClick(currentOrderItems, availableProductsForCurrentOrder);
  }

  return (
    <BaseLoader isLoading={isLoading} minHeight={'50px'}>
      <Form onSubmit={handleSubmit(onSaveOrderProcessInfo)}>
        <Grid fluid>
          <Row>
            <Col sm={2}>
              <Field
                name="CurrencyCode"
                label={I18n.t('orderProcess.currency')}
                component={SelectField}
                options={currencies}
                labelField="Value"
                valueField="Key"
              />
            </Col>
            <Col sm={1}>
              <Field
                name="DiscountPercentage"
                label={I18n.t('orderProcess.discountPercent')}
                component={InputField}
                validate={isPositiveOrZeroInteger}
              />
            </Col>
            <Col sm={1}>
              <br />
              <Button bsSize="small" bsStyle="success" type={'submit'} disabled={pristine || submitting}>
                <i className="fa fa-floppy-o" />
                &nbsp;
                <Translate value="general.save" />
              </Button>
            </Col>
            <Col sm={1}>
              <br />
              <Button bsSize="small" bsStyle="default" onClick={handleSelectMultipleProductClick} >
                <Translate value="order.selectMultipleProductButton" />
              </Button>
            </Col>
          </Row>
        </Grid>
      </Form>
    </BaseLoader>
  );
};

OrderProcessInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveOrderProcessInfo: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default reduxForm({
  form: formNames.orderProcessInfo,
  destroyOnUnmount: true,
  enableReinitialize: true,
  asyncBlurFields: [],
  initialValues: {
    OrderId: -1,
  },
})(OrderProcessInfoForm);
