import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { substanceSelectors } from '../../../redux/substance';
import { SelectField } from '../Forms';

const SubstanceListDropdown = props => {
  const { substanceList, substancetListFilter, sortByName, children } = props;

  const filterOptions = (...args) => sortByName(substancetListFilter(...args));

  return children ? (
    children({ substanceList, filterOptions })
  ) : (
    <SelectField
      options={substanceList}
      labelField="FullName"
      valueField="SubstanceId"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  let substanceList = initialOptions || substanceSelectors.getSubstanceList(state);
  substanceList = getSubstanceList(substanceList);
  return {
    substanceList,
    sortByName: substanceSelectors.sortByName,
    substancetListFilter: substanceSelectors.makeGetSubstanceListFilter(substanceList),
  };
};

SubstanceListDropdown.defaultProps = {
  children: null,
};

SubstanceListDropdown.propTypes = {
  substanceList: PropTypes.arrayOf(
    PropTypes.shape({
      SubstanceId: PropTypes.number.isRequired,
      InciName: PropTypes.string,
      CasNr: PropTypes.string,
    }),
  ).isRequired,
  substancetListFilter: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const getSubstanceList = createSelector(R.identity, rawMatList => {
  const setSubstanceFullName = substance =>
    R.assoc('FullName', `${R.prop('InciName', substance)} - ${R.prop('CasNr', substance)}`, substance);
  return rawMatList.map(setSubstanceFullName);
});

export default connect(mapStateToProps, null)(SubstanceListDropdown);
