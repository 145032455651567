import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MandateProductList from './MandateProductList';
import { applicationSelectors } from '../../../../redux/application';
import { mandateProductSelectors } from '../../../../redux/mandateProduct';
import { mandateTypes } from '../../../../redux/mandate';
import { productSelectors, productTypes } from '../../../../redux/product';

const MandateProductListContainer = props => <MandateProductList {...props} />;

const mapStateToProps = (state, { mandateId }) => {
  const getMandateProducts = mandateProductSelectors.makeGetMandateProductForMandate(mandateId);
  return {
    mandateProductList: getMandateProducts(state),
    productList: productSelectors.getProductList(state),
    isLoading: applicationSelectors.isLoading(
      state,
      mandateTypes.SAVE_MANDATE.REQUEST,
      mandateTypes.FETCH_MANDATES.REQUEST,
      productTypes.PRODUCT_LIST_FOR_COMPANY.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { mandateId }) => ({});

MandateProductListContainer.propTypes = {
  mandateId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MandateProductListContainer);
