import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { toxProfileItemSchema, toxProfileItemListSchema } from './toxProfileItemSchemas';
import toxProfileApi from '../../api/toxProfileItemApi';
import * as toxProfileItemTypes from './toxProfileItemTypes';
import * as toxProfileItemActions from './toxProfileItemActions';
import { createFetchSaga, callApiSaga } from '../sagaUtils';
import { createSaveForm } from '../form/utils';
import formNames from '../../constants/formNames';
import { applicationActions } from '../application';

export default function* root() {
  yield all([
    takeLatest(toxProfileItemTypes.TOXPROFILEITEM_LIST.REQUEST, getToxProfileItemSaga),
    takeLatest(toxProfileItemTypes.SAVE_TOXPROFILEITEM.REQUEST, saveToxProfileItemFormSaga),
    takeLatest(toxProfileItemTypes.DELETE_TOXPROFILEITEM.REQUEST, deleteToxProfileItemSaga),
  ]);
}

const saveToxProfileItemFormSaga = createSaveForm(formNames.toxProfileItemForm, saveToxProfileItemSaga);

export function* saveToxProfileItemSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { substanceId, data } = payload;
  yield callApiSaga(toxProfileItemActions.saveToxProfileItem, toxProfileApi.saveToxProfileItem, data);
  yield put(toxProfileItemActions.listToxProfileItems.request({ substanceId }));
  yield put(applicationActions.unsetLoading(type));
}

export const getToxProfileItemSaga = createFetchSaga(
  function* getToxProfileSaga({ payload, type }) {
    const items = yield call(toxProfileApi.getToxProfile, payload);
    const schema = Array.isArray(items) ? toxProfileItemListSchema : toxProfileItemSchema;
    const normalizedData = normalize(items, schema);
    console.log('payload', payload);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(toxProfileItemActions.listToxProfileItems.success(data, payload));
    },
    onFailure: (error, payload) => {
      put(toxProfileItemActions.listToxProfileItems.failure(error, payload));
    },
  },
);

export function* deleteToxProfileItemSaga({ type, payload }) {
  const { substanceId, Ids } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(toxProfileItemActions.deleteToxProfileItem, toxProfileApi.deleteToxProfileItem, Ids);
  yield put(toxProfileItemActions.listToxProfileItems.request({ substanceId }));
  yield put(applicationActions.unsetLoading(type));
}
