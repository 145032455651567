import { createSelector } from 'reselect';

export const getById = ({ substanceInformation }) => substanceInformation.byId;
export const getIds = ({ substanceInformation }) => substanceInformation.list.allIds;
export const getSubstanceInformationList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);
export const getSubstanceInformation = (state, id) => state.substanceInformation.byId[id];

const getSubstanceInformationListForSubstance = substanceId =>
  createSelector([getSubstanceInformationList], substanceInformationList =>
    substanceInformationList.filter(si => si.SubstanceId === substanceId),
  );

export const makeGetSubstanceInformationListForSubstance = createSelector(
  getSubstanceInformationListForSubstance,
  substanceInformationListForSubstance => substanceInformationListForSubstance,
);
