import * as phraseListModalSelectors from './phraseListModalSelectors';
import * as phraseListModalActions from './phraseListModalActions';
import * as phraseListModalTypes from './phraseListModalTypes';
import sagas from './phraseListModalSagas';
import reducer from './phraseListModalReducer';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { phraseListModalSelectors, phraseListModalActions, phraseListModalTypes };
export default reducer;
