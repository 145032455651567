import * as labelReviewClaimWizardSelectors from './labelReviewClaimWizardSelectors';
import * as labelReviewClaimWizardActions from './labelReviewClaimWizardActions';
import * as labelReviewClaimWizardTypes from './labelReviewClaimWizardTypes';
import sagas from './labelReviewClaimWizardSagas';
import reducer from './labelReviewClaimWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { labelReviewClaimWizardSelectors, labelReviewClaimWizardActions, labelReviewClaimWizardTypes };
export default reducer;
