import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import { Row, Col, Form, ButtonToolbar, Button} from 'react-bootstrap';
import { css } from 'glamor';
import {reduxForm} from "redux-form";
import PhraseTRForm from "./PhraseTRForm";
import Select from "react-select";

const styles = {
  container: css({
    marginTop: '10px',
  }),
  titleContainer: css({
    display: 'flex',
    justifyContent: "center",
    marginBottom: '10px',
    '& .Select': { width: '200px' },
  }),
};

class PhraseTranslationList extends PureComponent {
  constructor(props) {
    super(props);
    let lang = '';
    if(props.languages.length > 0){
      lang = props.languages[0].Alpha2;
    }
    this.state = {
      selectedLanguage: lang,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = option => {
    this.setState({ selectedLanguage: option });
  };

  render(){
    const {
      formValues,
      onSaveTranslation,
      onCancelButtonClick,
      handleSubmit,
      isFormPristine,
      submitting,
      invalid,
      languages,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSaveTranslation)}>
        <div>
          <div {...css({ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end', marginRight: '10px' })}>
            <ButtonToolbar>
              <Button
                bsSize="small"
                bsStyle="danger"
                type="button"
                onClick={onCancelButtonClick}
                disabled={isFormPristine || submitting}
              >
                <i className="fa fa-undo" />
                &nbsp;
                <Translate value="general.cancel" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="submit"
                disabled={isFormPristine || submitting || invalid}
              >
                <i className="fa fa-floppy-o" />
                &nbsp;
                <Translate value="general.save" />
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <div className={styles.titleContainer}>
              <span><b><Translate value="phraseTranslation.english"/></b></span>
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.titleContainer}>
              <Select
                onChange={this.handleChange}
                value={this.state.selectedLanguage}
                options={languages}
                labelKey="Value"
                valueKey="Alpha2"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className={styles.container}>
            {formValues && formValues.allCodes && formValues.allCodes.map(phraseCode => (
              <PhraseTRForm key={phraseCode} phraseCode={phraseCode} selectedLanguage={this.state.selectedLanguage} {...this.props}/>
            ))}
          </Col>
        </Row>
      </Form>
    );
  }
};

PhraseTranslationList.propTypes = {
  phraseList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  onSaveTranslation: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PhraseTranslationList);
