import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tab, Nav, NavItem } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';

// import other containers
import SubstanceDetailsContainer from './_SubstanceDetailsContainer';
import SubstanceCompositionContainer from './SubstanceComposition';
import SubstanceRawMatList from './SubstanceRawMatList';
import SubstanceProductList from './SubstanceProductList';
import SubstanceFunctionList from './SubstanceFunctionList';
import SubstanceDocuments from './SubstanceDocuments';
import CosAnnexe from './CosAnnexe';
import Information from './Information';
import MosForm from './MosForm';
import MosCertForm from './MosCertForm';
import ToxProfile from './ToxProfile';
import CpsrArgumentsForm from './CpsrArgumentsForm';
import SubstanceClpTab from './SubstanceClp';
import SubstanceClpTabTitle from './SubstanceClpTabTitle';
import SubstanceCpsrArgumentTabTitle from './SubstanceCpsrArgumentTabTitle';
import SubstanceMosTabTitle from './SubstanceMosTabTitle';
import SubstanceMosCertTabTitle from './SubstanceMosCertTabTitle';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const SubstanceDetails = props => {
  const substanceId = Number(props.params.id);
  return (
    <div className={styles.detailsContainer}>
      <SubstanceDetailsContainer substanceId={substanceId} {...props} />
      <Tab.Container id="SubstanceTabs" defaultActiveKey={1}>
        <Col sm={12}>
          <Nav bsStyle="tabs">
            <NavItem eventKey={1}>{I18n.t('substance.composition')}</NavItem>
            <NavItem eventKey={2}>{I18n.t('substance.cosAnnexes')}</NavItem>
            <NavItem eventKey={3}>
              <SubstanceMosTabTitle />
            </NavItem>
            {/* <NavItem eventKey={13}>
              <SubstanceMosCertTabTitle />
            </NavItem> */}
            <NavItem eventKey={4}>{I18n.t('substance.Tox')}</NavItem>
            <NavItem eventKey={5}>{I18n.t('substance.functions')}</NavItem>
            <NavItem eventKey={6}>{I18n.t('substance.information')}</NavItem>
            <NavItem eventKey={7}>
              <SubstanceCpsrArgumentTabTitle />
            </NavItem>
            <NavItem eventKey={8}>{I18n.t('substance.productList')}</NavItem>
            <NavItem eventKey={9}>{I18n.t('substance.rawMatList')}</NavItem>
            <NavItem eventKey={10}>{I18n.t('substance.substanceList')}</NavItem>
            <NavItem eventKey={11}>
              <SubstanceClpTabTitle />
            </NavItem>
            <NavItem eventKey={12}>{I18n.t('substance.linkedDocuments')}</NavItem>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey={1}>
              <SubstanceCompositionContainer substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <CosAnnexe substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <MosForm substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={13}>
              <MosCertForm substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={4}>
              <ToxProfile substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={5}>
              <SubstanceFunctionList substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={6}>
              <Information substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={7}>
              <CpsrArgumentsForm />
            </Tab.Pane>
            <Tab.Pane eventKey={8}>
              <SubstanceProductList substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={9}>
              <SubstanceRawMatList substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={10}>Not implemented yet</Tab.Pane>
            <Tab.Pane eventKey={11}>
              <SubstanceClpTab substanceId={substanceId} />
            </Tab.Pane>
            <Tab.Pane eventKey={12}>
              <SubstanceDocuments substanceId={substanceId} />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </div>
  );
};

SubstanceDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default SubstanceDetails;
