import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as rawMatActionTypes from './rawMatActionTypes';

const byId = handleActions(
  {
    [rawMatActionTypes.FETCH_RAWMAT_ACTIONS.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.rawMatActions),
  },
  {},
);

const allIds = handleActions(
  {
    [rawMatActionTypes.FETCH_RAWMAT_ACTIONS.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
