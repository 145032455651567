import { createAction } from 'redux-actions';
import { createFetchAction } from '../../utils';
import * as formulaReviewWizardTypes from './formulaReviewWizardTypes';

export const newFormulaReviewWizard = createFetchAction(
  formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD,
  'formulaReviewId',
);

export const editFormulaReviewWizard = createFetchAction(
  formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD,
  'formulaReviewId',
);

export const loadFormulaReviewData = createAction(formulaReviewWizardTypes.LOAD_FORMULA_REVIEW_DATA);

export const updateFormulaReview = createAction(formulaReviewWizardTypes.UPDATE_FORMULA_REVIEW);

export const saveFormulaReview = createAction(
  formulaReviewWizardTypes.SAVE_FORMULA_REVIEW,
  (ProductId, FormulaReviewId) => ({
    ProductId,
    FormulaReviewId,
  }),
);
