import { createSelector } from 'reselect';
import * as R from 'ramda';
import { productProportionSelectors } from '../../../../redux/productProportion';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { shadeSelectors } from '../../../../redux/shade';
import { shadeProportionSelectors } from '../../../../redux/shadeProportion';
import { PercentageCellRenderer } from '../../Grids';


export const makeGetColumnsForProduct = createSelector(shadeSelectors.makeGetShadesForProduct, getShadesForProduct =>
  createSelector(getShadesForProduct, shadesForProduct => [
    { headerName: '', pinned: 'left', field: 'rmName', width: 250, suppressSizeToFit: true },
    ...shadesForProduct.map(({ ShadeName, ShadeCode, ShadeId }) => ({
      headerName: `${ShadeName} (${ShadeCode})`,
      field: ShadeId.toString(),
      width: 100,
      suppressSizeToFit: true,
      cellRenderer: PercentageCellRenderer,
    })),
  ]),
);

// eslint-disable-next-line
export const makeGetShadeRmCompoDataForProduct = createSelector(
  [
    productProportionSelectors.makeGetProductProportionsForProduct,
    rawMatSelectors.getById,
    shadeSelectors.makeGetShadesForProduct,
    shadeProportionSelectors.getById,
  ],
  (getProductProportionsForProduct, rawMatById, getShadesForProduct, shadeProportionById) =>
    createSelector(
      [getProductProportionsForProduct, getShadesForProduct],
      (productProportionsForProduct, shadesForProduct) => {
        const data = [];
        productProportionsForProduct.forEach(pp => {
          const rawMat = rawMatById[pp.RawMatId];
          const rmData = {
            rmName: rawMat.Name,
            rawMatId: rawMat.RawMatId,
          };
          const prevRmData = data.find(val => val.rawMatId === rawMat.RawMatId);

          shadesForProduct.forEach(shade => {
            const shadeProportions = shade.ShadeProportions.map(shId => shadeProportionById[shId]);
            const shadeProportionCorrespondingToProductProportion = R.find(
              R.propEq('ProductProportionId', pp.ProductProportionId),
            )(shadeProportions);

            if (!prevRmData) {
              if (shadeProportionCorrespondingToProductProportion) {
                rmData[shade.ShadeId] = shadeProportionCorrespondingToProductProportion.Percentage;
              } else {
                rmData[shade.ShadeId] = null;
              }
            } else if (shadeProportionCorrespondingToProductProportion) {
              prevRmData[shade.ShadeId] += shadeProportionCorrespondingToProductProportion.Percentage;
            }
          });

          if (!prevRmData) data.push(rmData);
        });

        return data;
      },
    ),
);
