import { createRequestActions, createFetchAction } from '../utils';
import * as labelReviewTypes from './labelReviewTypes';

export const listLabelReviewForProduct = createRequestActions(
  labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST,
  ProductId => ({ ProductId }),
);

export const saveLabelReview = createRequestActions(labelReviewTypes.SAVE_LABEL_REVIEW);
export const updateLabelReview = createRequestActions(labelReviewTypes.UPDATE_LABEL_REVIEW);

export const fetchLabelReviews = createFetchAction(labelReviewTypes.FETCH_LABEL_REVIEWS, 'labelReviewId');
