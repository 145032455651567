import { schema, normalize } from 'normalizr';
import { get, post, del } from './apiUtils';

const defaultUrl = '/Homepages/';

export const homepageSchema = new schema.Entity('homePages', {}, { idAttribute: 'HomePageId' });

export const homepageListSchema = [homepageSchema];

export default {
  getHomepage: () => get('/Homepage/').then(res => normalize(res, homepageSchema)),
  saveHomepage: data => post(`${defaultUrl}`, data).then(res => normalize(res, homepageSchema)),
  deleteHomepage: id => del(`${defaultUrl}${id}`),
};
