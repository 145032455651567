import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { productSelectors, productActions } from '../../../redux/product';

class ProductCpsrbConclusionSelectField extends Component {
  state = {
    selectedValue: this.props.conclusionStatus,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.conclusionStatus !== nextProps.conclusionStatus) {
      this.setState(() => ({
        selectedValue: nextProps.conclusionStatus,
      }));
    }
  }

  handleOnChange = value => {
    this.setState(
      () => ({
        selectedValue: value,
      }),
      () => this.props.onChange(value),
    );
  };

  render() {
    const { conclusionList, disabled } = this.props;
    const { selectedValue } = this.state;
    return (
      <Select
        options={conclusionList}
        onChange={this.handleOnChange}
        value={selectedValue}
        labelKey="Name"
        valueKey="Code"
        clearable={false}
        disabled={disabled}
      />
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const conclusionStatus = R.prop('CpsrBConclusionStatus')(product);
  return {
    conclusionStatus,
    conclusionList: workflowStatusSelectors.getCpsrbConclusionStatusList(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onChange: ({ Code }) =>
    dispatch(productActions.updateCPSRBConclusionStatus.request({ productId, workflowStatusCode: Code })),
});

ProductCpsrbConclusionSelectField.defaultProps = {
  conclusionStatus: '',
  disabled: false,
};

ProductCpsrbConclusionSelectField.propTypes = {
  conclusionList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  conclusionStatus: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCpsrbConclusionSelectField);
