import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as productPhraseTypes from './productPhraseTypes';

const byId = handleActions(
  {
    [productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.productPhrases,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
