import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import productCategoryTRApi from '../../api/productCategoryTRApi';
import * as productCategoryTRTypes from './productCategoryTRTypes';
import * as productCategoryTRActions from './productCategoryTRActions';
import * as productCategoryTRSchemas from './productCategoryTRSchemas';

export default function* root() {
  yield all([
    takeLatest(productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.REQUEST, getProductCategoryTRListSaga),
    takeLatest(productCategoryTRTypes.SAVE_PRODUCT_CATEGORY_TR.REQUEST, saveProductCategoryTRSaga),
  ]);
}

export function* getProductCategoryTRListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    productCategoryTRActions.listProductCategoryTR,
    productCategoryTRApi.getProductCategoryTRList,
    productCategoryTRSchemas.productCategoryTRListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.REQUEST));
}

export function* saveProductCategoryTRSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    productCategoryTRActions.saveProductCategoryTR,
    productCategoryTRApi.saveProductCategoryTR,
    productCategoryTRSchemas.productCategoryTRListSchema,
    payload,
  );
  yield put(productCategoryTRActions.listProductCategoryTR.request(payload));
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}
