import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rawMatDocumentActions, rawMatDocumentTypes, rawMatDocumentSelectors } from '../../../redux/rawMatDocument';
import { applicationSelectors } from '../../../redux/application';

import RawMatDocumentsTab from './RawMatDocumentsTab';

class RawMatDocumentsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    rawMatId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.rawMatId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.rawMatId !== this.props.rawMatId && this.props.rawMatId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <RawMatDocumentsTab {...this.props} />;
  }
}

const mapStateToProps = (state, { rawMatId }) => ({
  rawMatDocuments: rawMatDocumentSelectors.getRawMatDocumentListForRawMat(state)(rawMatId),
  isLoadingRawMatDocumentList: applicationSelectors.isLoading(
    state,
    rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.REQUEST,
    rawMatDocumentTypes.SAVE_RAWMAT_DOCUMENT.REQUEST,
    rawMatDocumentTypes.UPLOAD_RAWMAT_DOCUMENT.REQUEST,
    rawMatDocumentTypes.DELETE_RAWMAT_DOCUMENT.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onLoad: () => dispatch(rawMatDocumentActions.listRawMatDocumentsForRawMat.request(rawMatId)),
  getRawMatDocuments: () => dispatch(rawMatDocumentActions.listRawMatDocumentsForRawMat.request(rawMatId)),
  saveRawMatDocument: data => dispatch(rawMatDocumentActions.saveRawMatDocument.request(rawMatId, data)),
  onUploadFile: data => dispatch(rawMatDocumentActions.uploadRawMatDocument.request(rawMatId, data)),
  deleteRawMatDocument: id => dispatch(rawMatDocumentActions.deleteRawMatDocument.request(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RawMatDocumentsContainer);
