import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Button, Grid, Col, ControlLabel, FormGroup, ButtonToolbar } from 'react-bootstrap';
import BaseLoader from '../../_Common/BaseLoader';
import DraftLabelFormContainer from './_DraftLabelFormContainer';

const DraftLabelTab = ({
  onPerformReviewButtonClick,
  onPrintButtonClick,
  isLoading,
  productId,
  printButtonDisabled,
}) => (
  <BaseLoader isLoading={isLoading}>
    <Grid fluid>
      <Col sm={12}>
        <Col sm={3}>
          <DraftLabelFormContainer productId={productId} />
        </Col>
        <Col sm={2}>
          <FormGroup bsSize="small">
            <ControlLabel>&nbsp;</ControlLabel>
            <ButtonToolbar>
              <Button type="button" bsSize="small" bsStyle="info" onClick={onPerformReviewButtonClick}>
                <i className="fa fa-pencil-square-o" />&nbsp; <Translate value="draftLabel.edit" />
              </Button>
              <Button type="button" bsSize="small" disabled={printButtonDisabled} onClick={onPrintButtonClick}>
                <i className="fa fa-print" aria-hidden="true" />&nbsp;<Translate value="general.print" />
              </Button>
            </ButtonToolbar>
          </FormGroup>
        </Col>
      </Col>
    </Grid>
  </BaseLoader>
);

DraftLabelTab.propTypes = {
  onPerformReviewButtonClick: PropTypes.func.isRequired,
  onPrintButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  printButtonDisabled: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
};

export default DraftLabelTab;
