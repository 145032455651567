import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { createFetchSaga, callApiSagaAndNormalize, callApiSaga } from '../sagaUtils';
import { applicationActions } from '../application';
import cosAnnexeApi from '../../api/cosAnnexeApi';
import * as cosAnnexeTypes from './cosAnnexeTypes';
import * as cosAnnexeSchemas from './cosAnnexeSchemas';
import * as cosAnnexeActions from './cosAnnexeActions';

export default function* root() {
  yield all([
    takeLatest(cosAnnexeTypes.FETCH_COSANNEXES.REQUEST, fetchCosAnnexeSaga),
    takeLatest(cosAnnexeTypes.SAVE_COSANNEXE.REQUEST, saveCosAnnexeSaga),
    takeLatest(cosAnnexeTypes.DELETE_COSANNEXE.REQUEST, deleteCosAnnexeSaga),
  ]);
}

const fetchCosAnnexeSaga = createFetchSaga(
  function* fetchCosAnnexeSaga({ payload, type }) {
    const shades = yield call(cosAnnexeApi.getCosAnnexes, payload);
    const schema = Array.isArray(shades) ? cosAnnexeSchemas.cosAnnexeListSchema : cosAnnexeSchemas.cosAnnexeSchema;
    const normalizedData = normalize(shades, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(cosAnnexeActions.fetchCosAnnexes.success(data, payload));
    },
    onFailure: (error, payload) => put(cosAnnexeActions.fetchCosAnnexes.failure(error, payload)),
  },
);

export function* saveCosAnnexeSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  try {
    yield callApiSagaAndNormalize(
      cosAnnexeActions.saveCosAnnexe,
      cosAnnexeApi.saveCosAnnexes,
      cosAnnexeSchemas.cosAnnexeSchema,
      payload,
    );
    // yield put(cosAnnexeActions.saveCosAnnexe.success(data));
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

function* deleteCosAnnexeSaga(action) {
  yield put(applicationActions.setLoading(cosAnnexeTypes.DELETE_COSANNEXE.REQUEST));
  try {
    yield callApiSaga(cosAnnexeActions.deleteCosAnnexe, cosAnnexeApi.deleteCosAnnexes, action.payload.id);
  } catch (error) {
    yield put(cosAnnexeActions.deleteCosAnnexe.failure(error));
  } finally {
    yield put(applicationActions.setLoading(cosAnnexeTypes.DELETE_COSANNEXE.REQUEST));
  }
}
