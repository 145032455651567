import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import OrderMissingDocumentListModal from './OrderMissingDocumentListModal';
import { orderSelectors, orderTypes } from '../../../../redux/order';
import { applicationSelectors } from '../../../../redux/application';

const OrderMissingDocumentListModalContainer = props => <OrderMissingDocumentListModal {...props} />;

const mapStateToProps = (state, { orderId }) => {
  const order = orderSelectors.getCurrentOrder(state);
  const missingDocuments = orderSelectors.getCurrentOrderMissingDocuments(state);
  const orderNr = R.propOr('', 'OrderNr', order);
  return {
    title: I18n.t('orderActionsModal.title', { orderNr }),
    isLoading: applicationSelectors.isLoading(state, orderTypes.ORDER_MISSING_DOCUMENT_LIST.REQUEST),
    text: missingDocuments
  };
};

OrderMissingDocumentListModalContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(OrderMissingDocumentListModalContainer);
