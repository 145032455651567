import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Row, Col, Button, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { compose } from 'recompose';
import { Field, FormSection, reduxForm } from 'redux-form';
// import { I18n } from 'react-redux-i18n';
import { I18n, Translate } from 'react-redux-i18n';
import BaseLoader from '../_Common/BaseLoader';
import formNames from '../../constants/formNames';
import { InputField, CheckBoxField, SelectField, DateField, RTEField, AddressForm } from '../_Common/Forms';
import DetailHeader from '../_Common/DetailHeader';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import CollapsePanelLink from '../_Common/CollapsePanelLink';
import {
  requiredField,
  maxLength20,
  maxLength50,
  maxLength64,
  maxLength255,
  upperCaseOrNumber,
} from '../../utils/fieldValidation';
import StepComponent from '../_Common/StepComponent';

const CompanyForm = props => {
  const {
    handleSubmit,
    isNew,
    companyTitle,
    onSaveCompany,
    countryList,
    companyList,
    isLoadingDetails,
    inciCalculationTypes,
    showDetails,
    onShowDetails,
    onUploadFile,
    invalid,
    expertList,
    customerSupportList,
    saleRepList,
    inDuplicateMode,
    currencies,
    onInitializePolling,
    onPoll,
    onRecalcTaskPriorities,
    workflowStatusList,
    RPStatus,
    onChangeRPStatus,
    dirty,
    showRPStatusHistory
  } = props;

  const handleChangeRPStatus = (data) => {
    console.log('Handle Change RP Status ');
    onChangeRPStatus( data, props.companyId );
  }
  const handleUpload = data => {
    onUploadFile({ data });
  }
  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveCompany)}>
        <DetailHeader
          title={`${I18n.t('company.title')} : ${companyTitle}`}
          showDuplicate={!isNew && !inDuplicateMode}
          {...props}
        >
          <Grid fluid>
            <Row>
              <Col sm={2}>
                <Field
                  name="Code"
                  label={`${I18n.t('company.code')} * 1`}
                  component={InputField}
                  validate={[requiredField, maxLength20]}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="ShortName"
                  label={`${I18n.t('company.shortName')} 1`}
                  component={InputField}
                  validate={maxLength64}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="ProductCodePrefix"
                  label={`${I18n.t('company.supplierCode')} * 1`}
                  component={InputField}
                  validate={[requiredField, maxLength20, upperCaseOrNumber]}
                />
              </Col>
              <Col sm={2}>
              <Field
                  name="CustomerSupport"
                  label={I18n.t('company.customerSupport')}
                  component={SelectField}
                  options={customerSupportList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CreatedOn"
                  label={I18n.t('company.creationDate')}
                  component={DateField}
                  labelColSize={3}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={1}>
                <Field name="IsActive" label={I18n.t('company.isActive')} component={CheckBoxField} />
              </Col>
              {/* <Col sm={1}>
                {
                  <Button
                    bsSize="small"
                    bsStyle="default"
                    type="button"
                    onClick={onPoll}
                  >
                    <i className="fa fa-files-o" />
                    &nbsp;
                    <Translate value="order.poll" />
                  </Button>
                {
              </Col> */}

            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="FullName"
                  label={`${I18n.t('company.fullName')} *`}
                  component={InputField}
                  validate={[requiredField, maxLength255]}
                />
              </Col>         
              <Col sm={2}>                
              <Field
                  name="SalesRepresentative"
                  label={I18n.t('company.salesRep')}
                  component={SelectField}
                  options={saleRepList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CSM"
                  label={I18n.t('company.csm')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="Expert"
                  label={I18n.t('company.expert')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
            </Row>
            <Row>   
              <Col sm={8}></Col>
              <Col sm={2}>
                <Field
                  name="CSM2"
                  label={I18n.t('company.csm2')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="Expert2"
                  label={I18n.t('company.expert2')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={7}>
                <FormSection name="Address">
                  <AddressForm {...props} />
                </FormSection>
              </Col>
              <Col sm={2}>
                {/* <Field
                  name="InvoiceType"
                  label={I18n.t('company.invoiceType')}
                  // options={[{ value: 'upon quote' }, { value: 'cash' }, { value: '50%' }, { value: 'PO' }]}
                  labelField="value"
                  valueField="value"
                  formAlignment={false}
                /> */}
                <Field
                  name="InvoiceType"
                  label={I18n.t('company.invoiceType')}
                  component={SelectField}
                  options={[{ Value: 'upon quote' }, { Value: 'cash' }, { Value: '50%' }, { Value: 'PO' }]}
                  labelField="Value"
                  valueField="Value"
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
              <br></br>
                <Field
                  name="IsBlackListed"
                  label={I18n.t('company.isBlackListed')}
                  component={CheckBoxField}
                  formAlignment={false}
                />
              </Col>
              <Col sm={2}>
                <ControlLabel>{I18n.t('company.RPStatus')}</ControlLabel>
                <StepComponent
                  label={I18n.t('company.RPStatus')}
                  options={workflowStatusList}
                  value={RPStatus}
                  onChange={handleChangeRPStatus}
                  disabled={isNew || dirty}
                  onHistoryClick={showRPStatusHistory}
                />
              </Col>
              {/* <Col sm={1}>
                <br/>
                  <OverlayTrigger placement="top" overlay={
                      <Tooltip id="tooltip">
                        <Translate value="company.recalcTaskPrioritiesTooltip" />
                      </Tooltip>
                    }>
                      <Button
                        bsSize="small"
                        bsStyle="default"
                        type="button"
                        onClick={onRecalcTaskPriorities}
                      >
                        <i className="fa fa-files-o" />
                        &nbsp;
                        <Translate value="company.recalcTaskPriorities" />
                      </Button>
                  </OverlayTrigger>
              </Col> */}
            </Row>
            <CollapsePanelLink
              defaultCollapsed={!showDetails}
              onExpandPanel={onShowDetails}
              disabled={invalid && showDetails}
            >
              <div>
                <Row>
                  <Col sm={2}>
                    <Field
                      name="UsesPrivateRawMat"
                      label={I18n.t('company.UsesPrivateRawMat')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="FRWithCompo"
                      label={I18n.t('company.FRWithCompo')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="UsesSpecificCpsra"
                      label={I18n.t('company.CASpecific')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="IsICMADMember"
                      label={I18n.t('company.IsICMADMember')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="UsesUsInciNames"
                      label={I18n.t('company.UsesUsInciNames')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="UsesManualInci"
                      label={I18n.t('company.UsesManualInci')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={8}>
                    <Field name="Memo" label={I18n.t('company.memo')} component={RTEField} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Field
                      name="InciCalculationType"
                      label={I18n.t('company.InciCalculationType')}
                      component={SelectField}
                      options={inciCalculationTypes}
                      labelField="Value"
                      valueField="Key"
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="CPNPIdentification"
                      label={I18n.t('company.CPNPIdentification')}
                      component={InputField}
                      validate={maxLength64}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="CPNPUser"
                      label={I18n.t('company.CPNPUser')}
                      component={InputField}
                      validate={maxLength64}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="CPNPCountry"
                      label={I18n.t('company.CPNPCountry')}
                      component={SelectField}
                      options={countryList}
                      labelField="Value"
                      valueField="Key"
                      matchPos="start"
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="DefaultResponsibleId"
                      label={I18n.t('company.defaultResponsible')}
                      component={SelectField}
                      options={companyList}
                      labelField="ShortName"
                      valueField="CompanyId"
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="PreferredCurrency"
                      label={I18n.t('company.preferedCurrency')}
                      component={SelectField}
                      options={currencies}
                      labelField="Value"
                      valueField="Key"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Field
                      name="MetrologyIndic"
                      label={I18n.t('company.MetrologyIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="BatchNrIndic"
                      label={I18n.t('company.BatchNrIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="PackagingFillingSOPIndic"
                      label={I18n.t('company.PackagingFillingSOPIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="GMPCertif"
                      label={I18n.t('company.GMPCertif')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="FinishedProductQCIndic"
                      label={I18n.t('company.FinishedProductQCIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="IngredientsQCIndic"
                      label={I18n.t('company.IngredientsQCIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="GreenDotCertif"
                      label={I18n.t('company.GreenDotCertif')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="FinishedProductNATIndic"
                      label={I18n.t('company.FinishedProductNATIndic')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field
                      name="IngredientsNATCertif"
                      label={I18n.t('company.IngredientsNATCertif')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="OrganicsCertif"
                      label={I18n.t('company.OrganicsCertif')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                    <Field name="OtherSOP" label={I18n.t('company.OtherSOP')} component={InputField} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Field name="Alpha" label={I18n.t('company.alpha')} component={InputField} />
                  </Col>
                  <Col sm={2}>
                    <Field name="Gamma" label={I18n.t('company.gamma')} component={InputField} />
                </Col>
                  <Col sm={2}>
                    <Field name="Delta" label={I18n.t('company.delta')} component={InputField} />
                  </Col>
                </Row>
              </div>
            </CollapsePanelLink>
          </Grid>
        </DetailHeader>
      </Form>
    </BaseLoader>
  );
};

CompanyForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  companyTitle: PropTypes.string.isRequired,
  onSaveCompany: PropTypes.func.isRequired,
  onDeleteCompany: PropTypes.func.isRequired,
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  inciCalculationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isLoadingDetails: PropTypes.bool.isRequired,
  showDetails: PropTypes.bool.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  expertList: PropTypes.array.isRequired, //eslint-disable-line
  saleRepList: PropTypes.array.isRequired, //eslint-disable-line
  customerSupportList: PropTypes.array.isRequired, //eslint-disable-line
  onDuplicate: PropTypes.func.isRequired,
  inDuplicateMode: PropTypes.bool.isRequired,
};

export default compose(
  withLeaveRouteFormDirtyWrapper(formNames.company),
  reduxForm({
    form: formNames.company,
    destroyOnUnmount: true,
    asyncBlurFields: [], // this seems to prevent the error
    enableReinitialize: true,
    initialValues: {
      CompanyId: -1,
    },
    // validate,
  }),
)(CompanyForm);

// const form = reduxForm({
//   form: 'companyForm',
//   destroyOnUnmount: true,
//   asyncBlurFields: [], // this seems to prevent the error
//   enableReinitialize: true,
//   initialValues: {
//     CompanyId: -1,
//   },
//   // validate,
// })(CompanyForm);

// export default withLeaveRouteFormDirtyWrapper(formNames.company)(form);