import PropTypes from 'prop-types';
import React from 'react';
import GeneralGrid from './GeneralGrid';

const EditableGrid = props => (
  <GeneralGrid
    singleClickEdit
    // suppressCellSelection="false"
    // rowDeselection="true"
    showAddButton
    stopEditingWhenGridLosesFocus
    {...props}
  />
);

EditableGrid.propTypes = {
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  columns: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onRefresh: PropTypes.func,
  onAdd: PropTypes.func,
  keyName: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedId: PropTypes.string,
  onCellValueChanged: PropTypes.func,
};

export default EditableGrid;
