import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { productProportionSelectors, productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { SelectionGrid, PercentageCellRenderer } from '../../../_Common/Grids';
import { orderAscendingNullAtEnd } from '../../../_Common/Grids/utils';

const ProductProportionList = ({ height, productProportions, rawMats, whenGridReady, isLoading }) => {
  const getRawMatById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('RawMatId'), R.identity]))(rawMats),
  );

  const getProductProportionById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('ProductProportionId'), R.identity]))(productProportions),
  );

  const getValueFromColDef = R.path(['colDef', 'field']);

  const getProductProportionFromRow = R.compose(
    getProductProportionById,
    R.pathOr({}, ['data', 'ProductProportionId']),
  );

  const getRawMatValue = R.converge(R.propOr(''), [
    getValueFromColDef,
    R.compose(
      getRawMatById,
      R.prop('RawMatId'),
      getProductProportionFromRow,
    ),
  ]);

  const columns = [
    {
      headerName: I18n.t('rawMat.code'),
      field: 'Code',
      valueGetter: getRawMatValue,
    },
    {
      headerName: I18n.t('rawMat.name'),
      field: 'Name',
      valueGetter: getRawMatValue,
    },
    // cosmos-2177 JLL added
    {
      headerName: I18n.t('rawMatProportion.percentage'),
      field: 'Percentage',
      cellRenderer: PercentageCellRenderer,
    },
    // end
    {
      headerName: I18n.t('productProportion.order'),
      field: 'Order',
      editable: true,
      sort: 'asc',
      comparator: orderAscendingNullAtEnd,
    },
  ];

  return (
    <SelectionGrid
      data={productProportions}
      showFilterRow={false}
      isLoading={isLoading}
      keyName="ProductProportionId"
      columns={columns}
      height={height}
      singleClickEdit
      stopEditingWhenGridLosesFocus
      whenGridReady={whenGridReady}
    />
  );
};

const mapStateToProps = (state, { productId }) => {
  const getProductProportions = productProportionSelectors.makeGetProductProportionForProduct(productId);
  return {
    productProportions: getProductProportions(state),
    rawMats: rawMatSelectors.makeGetRawMatListForProduct(productId)(state),
    isLoading: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
  };
};

ProductProportionList.defaultProps = {
  height: '',
  productProportions: [],
};

ProductProportionList.propTypes = {
  height: PropTypes.string,
  whenGridReady: PropTypes.func.isRequired,
  productProportions: PropTypes.array.isRequired, //eslint-disable-line
  rawMats: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(ProductProportionList);
