import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditButtonRenderer } from '../../_Common/Grids';
import WithPrivileges from '../../_Common/WithPrivileges';
import userActionsCodes from '../../../constants/userActionsCodes';
import * as R from 'ramda';

const getYN = ({data, colDef}) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always(''));

const CategoryList = props => {
  const { categoryList, isLoading, getCategoryList, onEditButtonClick, onAddButtonClick } = props;

  return (
    <WithPrivileges requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        const columns = [
          {
            headerName: I18n.t('category.code'),
            field: 'Code',
            width: 20,
            sort: 'asc',
          },
          {
            headerName: I18n.t('category.description'),
            field: 'Description',
            width: 60,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('general.actions'),
            width: 25,
            hide: !isAllowedToEditAdd,
            cellRendererFramework: EditButtonRenderer,
            cellRendererParams: {
              onClick: onEditButtonClick,
            },
          },
          {
            headerName: I18n.t('category.useQuantity'),
            field: 'UseQuantity',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.useSite'),
            field: 'UseSite',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.useSurface'),
            field: 'UseSurface',
            width: 25,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.useFrequency'),
            field: 'UseFrequency',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.useRetentionFactor'),
            field: 'UseRetentionFactor',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.targetPopulation'),
            field: 'TargetPopulation',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.bodyWeight'),
            field: 'BodyWeight',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },

          {
            headerName: I18n.t('category.amountSprayed'),
            field: 'AmountSprayed',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.airborneFraction'),
            field: 'AirborneFraction',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.distributionVolume1'),
            field: 'DistributionVolume1',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.exposureTime1'),
            field: 'ExposureTime1',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.distributionVolume2'),
            field: 'DistributionVolume2',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.exposureTime2'),
            field: 'ExposureTime2',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.humanBreathMinuteVolume'),
            field: 'HumanBreathMinuteVolume',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.meanApplicationPerDay'),
            field: 'MeanApplicationPerDay',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.fractionReachingAlveoli'),
            field: 'FractionReachingAlveoli',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.defaultFactorSubstanceExchange'),
            field: 'DefaultFactorSubstanceExchange',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.useDescription'),
            field: 'UseDescription',
            width: 35,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.normalUse'),
            field: 'NormalUse',
            width: 35,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.reasonableUse'),
            field: 'ReasonableUse',
            width: 35,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.misuse'),
            field: 'Misuse',
            width: 35,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.sensitizationAssessmentFactors'),
            field: 'SensitizationAssessmentFactors',
            width: 25,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.sensitizationQuantity'),
            field: 'SensitizationQuantity',
            width: 30,
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('category.dermalAbsorption100'),
            field: 'IsDermalAbsorption100',
            width: 30,
            valueGetter: getYN, 
            cellStyle: {
              'white-space': 'normal',
            },
          },
        ];

        return (
          <SelectionGrid
            data={categoryList}
            columns={columns}
            onRefresh={getCategoryList}
            //      isLoading={isLoading}
            showLoadingIcon={isLoading}
            stopEditingWhenGridLosesFocus
            singleClickEdit
            keyName="CategoryId"
            showAddButton={isAllowedToEditAdd}
            showFilterRow
            onAdd={onAddButtonClick}
          />
        );
      }}
    </WithPrivileges>
  );
};

CategoryList.propTypes = {
  categoryList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default CategoryList;
