import { normalize } from 'normalizr';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import companyDocumentApi from '../../api/companyDocumentApi';
import documentApi from '../../api/documentApi';
import * as companyDocumentTypes from './companyDocumentTypes';
import * as companyDocumentActions from './companyDocumentActions';
import * as companyDocumentSchemas from './companyDocumentSchemas';
import * as companyDocumentSelectors from './companyDocumentSelectors';

export default function* root() {
  yield all([
    takeLatest(companyDocumentTypes.SAVE_COMPANY_DOCUMENT.REQUEST, saveCompanyDocumentSagas),
    takeLatest(companyDocumentTypes.UPLOAD_COMPANY_DOCUMENT.REQUEST, uploadCompanyDocumentSagas),
    takeLatest(companyDocumentTypes.UPLOAD_COMPANY_NOTIFICATION_DATA.REQUEST, uploadCompanyNotificationDataSagas),
    takeLatest(companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY.REQUEST, getCompanyDocumentListForCompanySaga),
    takeLatest(companyDocumentTypes.DELETE_COMPANY_DOCUMENT.REQUEST, deleteRawMatDocumentSaga),
  ]);
}

function* deleteRawMatDocumentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { CompanyDocumentId } = payload;
  yield callApiSaga(
    companyDocumentActions.deleteCompanyDocument,
    companyDocumentApi.deleteCompanyDocument,
    CompanyDocumentId,
  );

  const companyDocument = yield select(state => companyDocumentSelectors.getCompanyDocument(state, CompanyDocumentId));
  if (companyDocument && companyDocument.CompanyId) {
    const { CompanyId } = companyDocument;
    yield put(companyDocumentActions.listCompanyDocumentsForCompany.request(CompanyId));
  }

  yield put(applicationActions.unsetLoading(type));
}

function* saveCompanyDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { CompanyId, ...data } = payload;
  yield callApiSaga(companyDocumentActions.saveCompanyDocument, documentApi.saveDocument, data);
  yield put(companyDocumentActions.listCompanyDocumentsForCompany.request(CompanyId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadCompanyDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { CompanyId } = payload;
  yield callApiSaga(companyDocumentActions.saveCompanyDocument, companyDocumentApi.uploadCompanyDocument, payload);
  yield put(companyDocumentActions.listCompanyDocumentsForCompany.request(CompanyId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadCompanyNotificationDataSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { CompanyId } = payload;
  yield callApiSaga(companyDocumentActions.saveCompanyNotificationData, companyDocumentApi.uploadCompanyNotificationData, payload);
  // yield put(companyDocumentActions.listCompanyDocumentsForCompany.request(CompanyId));
  yield put(applicationActions.unsetLoading(type));
}

function* getCompanyDocumentListForCompanySaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { CompanyId } = payload;
    const companyDocuments = yield call(companyDocumentApi.getCompanyDocumentList, {
      CompanyId,
      OptIncludeDocument: true,
    });
    const normalizedData = normalize(companyDocuments, companyDocumentSchemas.companyDocumentListWithDocumentSchema);
    yield put(companyDocumentActions.listCompanyDocumentsForCompany.success(normalizedData, CompanyId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(companyDocumentActions.listCompanyDocumentsForCompany.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
