import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import categoryTRApi from '../../api/categoryTRApi';
import * as categoryTRTypes from './categoryTRTypes';
import * as categoryTRActions from './categoryTRActions';
import * as categoryTRSchemas from './categoryTRSchemas';

export default function* root() {
  yield all([
    takeLatest(categoryTRTypes.CATEGORY_TR_LIST.REQUEST, getCategoryListSaga),
    takeLatest(categoryTRTypes.SAVE_CATEGORY_TRs.REQUEST, saveCategoryTRsSaga),
  ]);
}

export function* getCategoryListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(categoryTRTypes.CATEGORY_TR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    categoryTRActions.listCategoriesTR,
    categoryTRApi.getCategoryTRList,
    categoryTRSchemas.categoryTRListSchema,
  );
  yield put(applicationActions.unsetLoading(categoryTRTypes.CATEGORY_TR_LIST.REQUEST));
}

export function* saveCategoryTRsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    categoryTRActions.saveCategoryTRs,
    categoryTRApi.saveCategoryTR,
    categoryTRSchemas.categoryTRListSchema,
    payload,
  );
  yield put(categoryTRActions.listCategoriesTR.request());
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}
