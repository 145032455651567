import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const columns = [
  {
    headerName: I18n.t('distributor.name'),
    width: 80,
    field: 'Name',
    sort: 'asc',
  },
  {
    headerName: I18n.t('distributor.country'),
    width: 25,
    field: 'CountryAlpha3',
  },
];

const DistributorList = props => {
  const {
    distributorList,
    onDistributorSelected,
    onRefreshList,
    onAddDistributor,
    requestingDistributorList,
    selectedId,
  } = props;
  return (
    <SelectionGrid
      data={distributorList}
      onSelected={onDistributorSelected}
      onRefresh={onRefreshList}
      isLoading={requestingDistributorList}
      selectedId={selectedId}
      columns={columns}
      keyName="DistributorId"
      suppressRowClickSelection
      showAddButton
      onAdd={onAddDistributor}
    />
  );
};

DistributorList.defaultProps = {
  selectedId: '',
};

DistributorList.propTypes = {
  distributorList: PropTypes.arrayOf(
    PropTypes.shape({
      DistributorId: PropTypes.number,
      Name: PropTypes.string,
    }),
  ).isRequired,
  onDistributorSelected: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  onAddDistributor: PropTypes.func.isRequired,
  requestingDistributorList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default DistributorList;
