import { reject, flow } from 'lodash/fp';
import { getFormValues, formValueSelector, isDirty as isFormDirty } from 'redux-form';
import { PrefixIndexStrategy } from 'js-search';
import { createSelector } from 'reselect';
import createFilterOptions from 'react-select-fast-filter-options';
import { substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { substanceProportionSelectors, substanceProportionTypes } from '../../../../redux/substanceProportion';

export const getSubstanceList = (state, substanceId, substanceProportionId) => {
  const secondLevelSubstanceList = substanceSelectors.getSecondLevelSubstances(state);
  const substanceProportionForSubstance = substanceProportionSelectors.getSubstanceProportionListForSubstance(
    state,
    substanceId,
  );

  const removeCurrentSubstance = reject(s => s.SubstanceId === substanceId);
  const removeSubstancesAlreadyInComposition = reject(s =>
    substanceProportionForSubstance.map(sp => sp.ReferencedSubstanceId).includes(s.SubstanceId),
  );

  // if in edit mode, we populate the dropdown only with the current substanceProportion value
  if (substanceProportionId) {
    const substanceProportion = getSubstanceProportion(state, substanceProportionId);
    const substance = substanceSelectors.getSubstance(state, substanceProportion.ReferencedSubstanceId);
    return [substance];
  }

  const result = flow(
    removeCurrentSubstance,
    removeSubstancesAlreadyInComposition,
  )(secondLevelSubstanceList);
  return result;
};

export const getCurrentFormValues = state => getFormValues('substanceProportionForm')(state);

export const getSelectedSubstanceId = state =>
  formValueSelector('substanceProportionForm')(state, 'ReferencedSubstanceId');

export const getSelectedSubstanceDetails = state =>
  substanceSelectors.getSubstance(state, getSelectedSubstanceId(state));

export const isDirty = state => isFormDirty('substanceProportionForm')(state);
export const isSubmittable = state => isDirty(state) && !isLoadingContent(state);
const isLoadingContent = state =>
  applicationSelectors.isLoading(state, substanceProportionTypes.SUBSTANCE_PROPORTION_LIST.REQUEST);

export const getSubstanceProportion = (state, substanceProportionId) => {
  if (!substanceProportionId) return undefined;
  const substanceProportion = substanceProportionSelectors.getSubstanceProportion(state, substanceProportionId);
  const result = {
    ...substanceProportion,
    Substance: substanceSelectors.getSubstance(state, substanceProportion.SubstanceId),
  };
  return result;
};

const prefixIndexStrategy = new PrefixIndexStrategy();
export const getSubstanceListFilter = createSelector(getSubstanceList, substanceList =>
  createFilterOptions({
    options: substanceList,
    labelKey: 'InciName',
    valueKey: 'SubstanceId',
    indexStrategy: prefixIndexStrategy,
  }),
);
