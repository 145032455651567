import * as R from 'ramda';
import { createSelector } from 'reselect';
import { I18n } from 'react-redux-i18n';
import inciCalculationTypes from '../../../../constants/inciCalculationTypes';
import { productSelectors } from '../../../../redux/product';
import { shadeSelectors } from '../../../../redux/shade';
import { shadeInciListSelectors } from '../../../../redux/shadeInciList';
import { PercentageCellRenderer } from '../../Grids';

export const makeGetColumnsForProduct = createSelector(shadeSelectors.makeGetShadesForProduct, getShadesForProduct =>
  createSelector(getShadesForProduct, shadesForProduct => [
    { headerName: 'Type', pinned: 'left', field: 'substanceType', width: 75, suppressSizeToFit: true, sort: 'asc' },
    { headerName: 'Substance\\Shade', pinned: 'left', field: 'inciName', width: 350, suppressSizeToFit: true },
    ...shadesForProduct.map(({ ShadeName, ShadeCode, ShadeId }) => ({
      headerName: `${ShadeName} (${ShadeCode})`,
      field: ShadeId.toString(),
      width: 100,
      suppressSizeToFit: true,
      cellRenderer: PercentageCellRenderer,
    })),
  ]),
);

export const makeGetShadeInciCompoDataForProduct = createSelector(
  [shadeSelectors.makeGetShadesForProduct, shadeInciListSelectors.getById, productSelectors.makeGetInciCalculationType],
  (getShadesForProduct, shadeInciListById, getInciCalculationTypeForProduct) =>
    createSelector([getShadesForProduct, getInciCalculationTypeForProduct], (shades, productInciCalculationType) => {
      const makeGetPercentage = inciCalculationType => inciSubstance =>
        R.cond([
          [
            R.equals(inciCalculationTypes.SPEC),
            R.always(R.prop('PercentageInProductForInciCalculation', inciSubstance)),
          ],
          [
            R.equals(inciCalculationTypes.SPEC0),
            R.always(inciSubstance.PercentageInProductForInciCalculation || inciSubstance.PercentageInProduct),
          ],
          [R.always(true), R.always(R.prop('PercentageInProduct', inciSubstance))],
        ])(inciCalculationType);

      const getPercentage = makeGetPercentage(productInciCalculationType);

      const shadeInciList = [];
      shades.forEach(shade => {
        const shadeInci = shadeInciListById[shade.ShadeId];
        shadeInci.Substances.forEach(inciSubstance => {
          const existingData = shadeInciList.find(data => data.inciName === inciSubstance.Substance.InciName);
          if (existingData) {
            existingData[shade.ShadeId] = getPercentage(inciSubstance);
          } else {
            shadeInciList.push({
              inciName: inciSubstance.Substance.InciName,
              substanceType: I18n.t(`substanceTypes.${inciSubstance.SubstanceType}`),
              [shade.ShadeId]: getPercentage(inciSubstance),
            });
          }
        });
      });
      return shadeInciList;
    }),
);
