import * as taskTypeSelectors from './taskTypeSelectors';
import * as taskTypeActions from './taskTypeActions';
import * as taskTypeTypes from './taskTypeTypes';
import * as taskTypeSchemas from './taskTypeSchemas';

import sagas, * as taskTypeSagas from './taskTypeSagas';
import reducer from './taskTypeReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { taskTypeSelectors, taskTypeActions, taskTypeTypes, taskTypeSchemas, taskTypeSagas };
export default reducer;
