import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IngredientForm from './IngredientForm';
import { ingredientActions, ingredientSelectors, ingredientTypes } from '../../redux/ingredient';
import { applicationSelectors } from '../../redux/application';

class ingredientDetailsContainer extends Component {
  static propTypes = {
    ingredientId: PropTypes.number.isRequired,
    onLoadIngredient: PropTypes.func.isRequired,
    currentIngredient: PropTypes.object.isRequired, //eslint-disable-line
  };

  componentWillMount() {
    // console.log('ingredientDetailsContainer Mounted');
    this.props.onLoadIngredient(this.props.ingredientId);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.ingredientId !== this.props.ingredientId) {
      // console.log('ingredientDetailsContainer Updated');
      this.props.onLoadIngredient(this.props.ingredientId);
    }
  }

  render() {
    const { currentIngredient } = this.props;
    return <IngredientForm initialValues={currentIngredient} {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    currentIngredient: ingredientSelectors.getCurrentIngredient(state),
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      ingredientTypes.SAVE_INGREDIENT.REQUEST,
      ingredientTypes.INGREDIENT.REQUEST,
    ),
    ingredientTitle: ingredientSelectors.getIngredientTitle(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadIngredient: id => {
      if (id > 0) {
        dispatch(ingredientActions.ingredient.request(id));
      }
    },
    onSaveingredient: data => dispatch(ingredientActions.saveIngredient.request(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ingredientDetailsContainer);
