import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';

import InputField from './InputField';
import SelectField from './SelectField';

const AddressForm = ({ countryList }) =>
  (<Row>
    <Col sm={12}>
      <Grid fluid>
        <Row>
          <Col sm={4}>
            <Field name="AddressLine1" label={I18n.t('address.addressLine1')} component={InputField} />
            <Field name="AddressLine2" label={I18n.t('address.addressLine2')} component={InputField} />
          </Col>
          <Col sm={8}>
            <Row>
              <Col sm={6}>
                <Field name="City" label={I18n.t('address.city')} component={InputField} />
              </Col>
              <Col sm={6}>
                <Field name="Location" label={I18n.t('address.location')} component={InputField} />
              </Col>
            </Row>
            <Row>
              <Col sm={5}>
                <Field name="PostalCode" label={I18n.t('address.postalCode')} component={InputField} />
              </Col>
              <Col sm={7}>
                <Field
                  name="CountryCode"
                  label={I18n.t('address.country')}
                  component={SelectField}
                  options={countryList}
                  labelField="Value"
                  valueField="Key"
                  matchPos="start"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </Col>
  </Row>);

AddressForm.propTypes = {
  countryList: PropTypes.array, //eslint-disable-line
};

export default AddressForm;
