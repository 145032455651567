import { handleActions } from 'redux-actions';
import * as orderTypes from './orderTypes';

export const initialState = {
  orderList: [],
  currentOrder: {},
  currentOrderProcessInfo: {},
  currentOrderProducts: [],
  currentOrderMissingDocuments: '',
  productsOwnedByCurrentOrderCompany: [],
};

export default handleActions(
  {
    [orderTypes.ORDER_LIST.SUCCESS]: (state, { payload }) => ({ ...state, orderList: payload }),
    [orderTypes.ORDER_MISSING_DOCUMENT_LIST.SUCCESS]: (state, { payload }) => ({ ...state, currentOrderMissingDocuments: payload }),
    [orderTypes.ORDER_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({ ...state, currentOrderProducts: payload }),
    [orderTypes.ORDER.SUCCESS]: (state, { payload }) => ({ ...state, currentOrder: payload }),
    [orderTypes.ORDER_PROCESS_INFO.SUCCESS]: (state, { payload }) => ({ ...state, currentOrderProcessInfo: payload }),
    [orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY.SUCCESS]: (state, { payload }) => ({
      ...state,
      productsOwnedByCurrentOrderCompany: payload,
    }),
    [orderTypes.DELETE_ORDER.SUCCESS]: state => ({ ...state, currentOrder: {} }),
    [orderTypes.NEW_ORDER]: (state, { payload }) => ({
      ...state,
      currentOrder: {
        OrderId: -1,
        ...payload,
        // orderNumber: '',
      },
      currentOrderProducts: [],
      productsOwnedByCurrentOrderCompany: [],
    }),
  },
  initialState,
);
