import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isDirty, isSubmitting } from 'redux-form';
import { modalActions } from '../redux/modal';

const withLeaveRouteFormDirtyWrapper = BaseComponent =>
  class extends Component {
    static propTypes = {
      router: PropTypes.shape({
        setRouteLeaveHook: PropTypes.func,
      }).isRequired,
      route: PropTypes.object, //eslint-disable-line
      onRouteLeaveDirty: PropTypes.func.isRequired,
      isDirty: PropTypes.bool.isRequired,
      submitting: PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);
      this.isInLeaveHook = false;
    }
    componentDidMount() {
      const { router, route, onRouteLeaveDirty } = this.props;
      router.setRouteLeaveHook(route, routeContext => {
        if (this.props.isDirty && !this.isInLeaveHook && !this.props.submitting) {
          this.isInLeaveHook = true;
          onRouteLeaveDirty(routeContext.pathname, () => {
            // callback when modal is closed
            this.isInLeaveHook = false;
          });
          return false;
        }
        return true;
      });
    }
    render() {
      return <BaseComponent {...this.props} />;
    }
  };

export default formName =>
  compose(
    connect(
      state => ({
        isDirty: isDirty(formName)(state),
        submitting: isSubmitting(formName)(state),
      }),
      {
        onRouteLeaveDirty: modalActions.showLeaveRouteDirtyModal,
      },
    ),
    withLeaveRouteFormDirtyWrapper,
  );
