import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditDeleteButtonRenderer, PercentageCellRenderer } from '../../_Common/Grids';
import { orderAscendingNullAtEnd } from '../../_Common/Grids/utils';

const getColorFromRowLevel = R.cond([
  [R.equals(0), R.always('#FFFFFF')],
  [R.equals(1), R.always('#EEEEEE')],
  [R.equals(2), R.always('#DDDDDD')],
  [R.T, R.always('')],
]);

const setBackgroundFromColor = R.objOf('background');

const isLeafNode = R.compose(
  R.isEmpty,
  R.propOr([], 'Children'),
);

const getLeafNodesForNode = R.when(
  R.complement(isLeafNode),
  R.compose(
    R.chain(child => getLeafNodesForNode(child)),
    R.prop('Children'),
  ),
);

const getLevel1TotalPercentage = R.compose(
  R.sum,
  R.pluck('PercentageInProduct'),
);

const getRowStyle = R.compose(
  setBackgroundFromColor,
  getColorFromRowLevel,
  R.path(['node', 'level']),
);

const isPinnedRow = R.compose(
  R.has('rowPinned'),
  R.prop('node'),
);
const lessThan100 = R.lt(R.__, 100); //eslint-disable-line

const isImpurity = R.propEq('IsImpurity', true);
const isIngredient = R.propEq('IsIngredient', true);
const isNano = R.propEq('IsNano', true);

const getCellStyle = R.ifElse(
  R.converge(R.and, [isPinnedRow, R.propSatisfies(lessThan100, 'value')]),
  R.always({ color: 'red', fontWeight: 'bold' }),
  R.compose(
    R.objOf('textDecoration'),
    R.ifElse(R.anyPass([isImpurity, isIngredient]), R.always('underline dashed red'),
      R.ifElse(R.anyPass([isNano]), R.always('underline dashed green'), R.always('none'))),
    R.propOr({}, 'data'),
    ),
);

class CompositionList extends Component {
  componentDidMount() {
    if(this.props.productProportionIdToEdit){
      this.props.onReOrderCompositionButtonClick(this.props.productProportionIdToEdit);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.grid) {
      this.setPinnedRowBottomData(prevProps.currentProductComposition);
    }
  }

  setGrid = g => {
    this.grid = g;
    this.setPinnedRowBottomData(this.props.currentProductComposition);
  };

  setPinnedRowBottomData = productProportions => {
    if (this.grid) {
      this.grid.api.setPinnedBottomRowData([{ PercentageInProduct: getLevel1TotalPercentage(productProportions) }]);
    }
  };

  render() {
    const {
      currentProductComposition,
      getProductProportions,
      isLoadingCompositionList,
      onAdd,
      deleteProductProportion,
      onEdit,
      functionById,
      isAddDisabled,
      isProductCompositionEditable,
    } = this.props;

    const columns = [
      {
        headerName: I18n.t('productProportion.order'),
        field: 'Order',
        sort: 'asc',
        width: 65,
        suppressSizeToFit: true,
        comparator: orderAscendingNullAtEnd,
        cellRenderer: 'group',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: I18n.t('general.name'),
        field: 'Name',
        width: 250,
        minWidth: 200,
        cellRenderer: 'group',
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: I18n.t('general.code'),
        field: 'RmCode',
      },
      {
        headerName: I18n.t('general.supplier'),
        field: 'Supplier',
      },
      {
        headerName: I18n.t('substance.function'),
        valueGetter: ({ data }) => R.pathOr('', [data.FunctionId, 'Name'])(functionById)
      },
      {
        headerName: I18n.t('substance.range'),
        field: 'PercentageRange',
        width: 90
      },
      {
        headerName: I18n.t('general.quantityInParent'),
        field: 'PercentageInParent',
        cellRenderer: PercentageCellRenderer,   
        width: 100
      },
      {
        headerName: I18n.t('general.quantityInProduct'),
        field: 'PercentageInProduct',
        cellRenderer: PercentageCellRenderer,
      },
      {
        headerName: 'CasNr', //I18n.t('substance.range'),
        valueGetter: ({ data }) => { return data.ReferencedSubstance == undefined ? '' : data.ReferencedSubstance.CasNr; },
        width: 90
      },
      {
        headerName: 'EcNr', //I18n.t('substance.range'),
        valueGetter: ({ data }) => { return data.ReferencedSubstance == undefined ? '' : data.ReferencedSubstance.EcNr; },
        width: 90
      },
      {
        headerName: I18n.t('general.actions'),
        width: 120,
        minWidth: 120,
        cellRendererFramework: EditDeleteButtonRenderer,
        suppressSizeToFit: true,
        cellRendererParams: {
          onDelete: ({ ProductProportionId }) => deleteProductProportion(ProductProportionId),
          onEdit: ({ ProductId, ProductProportionId }) => onEdit(ProductId, ProductProportionId),
          isDeleteDisable: !isProductCompositionEditable,
          isEditDisable: !isProductCompositionEditable,
        },
      },
    ];

    const defaultColDef = {
      cellStyle: getCellStyle,
    };

    const getNodeChildDetails = rowItem => {
      if (rowItem.Type === 'rawMat' || rowItem.Type === 'complexSubstance') {
        return {
          group: true,
          expanded: true,
          children: rowItem.Children,
          key: rowItem.Name,
        };
      }
      return null;
    };

    return (
      <SelectionGrid
        data={currentProductComposition}
        keyName="Id"
        columns={columns}
        getNodeChildDetails={getNodeChildDetails}
        isLoading={isLoadingCompositionList}
        onRefresh={getProductProportions}
        onAdd={onAdd}
        deltaRowDataMode={false}
        getRowStyle={getRowStyle}
        showExpandCollapseButtons
        whenGridReady={this.setGrid}
        defaultColDef={defaultColDef}
        addDisabled={isAddDisabled || !isProductCompositionEditable}
        singleClickEdit
        stopEditingWhenGridLosesFocus
      />
    );
  }
}

CompositionList.defaultProps = {
  currentProductComposition: [],
  isAddDisabled: false,
};

CompositionList.propTypes = {
  currentProductComposition: PropTypes.array.isRequired, //eslint-disable-line
  getProductProportions: PropTypes.func.isRequired,
  isLoadingCompositionList: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  deleteProductProportion: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isAddDisabled: PropTypes.bool,
  isProductCompositionEditable: PropTypes.bool.isRequired,
  functionById: PropTypes.object.isRequired, //eslint-disable-line
};

export default CompositionList;
