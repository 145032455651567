import * as cosAnnexeFormSelectors from './cosAnnexeFormSelectors';
import * as cosAnnexeFormActions from './cosAnnexeFormActions';
import * as cosAnnexeFormTypes from './cosAnnexeFormTypes';
import sagas from './cosAnnexeFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { cosAnnexeFormActions, cosAnnexeFormTypes, cosAnnexeFormSelectors };
