import React from 'react';
import { connect } from 'react-redux';

import Login from './Login';
import { userActions, userTypes, userSelectors } from '../../redux/user';
import { applicationSelectors } from '../../redux/application';

const LoginPageContainer = props => <Login {...props} />;

function mapStateToProps(state) {
  return {
    isAuthenticating: applicationSelectors.isLoading(userTypes.AUTHENTICATE.REQUEST),
    errorDescription: userSelectors.getErrorDesciption(state),
    applicationVersion: state.application.applicationVersion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogin: (login, password) => {
      dispatch(userActions.authenticate.request(login, password));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);