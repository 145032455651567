import { map, compose, any, all, equals } from 'ramda';

export function isGeneralErrorShown({ application }) {
  return application.generalError !== '' && application.generalError !== undefined;
}

export function formSelector({ form }) {
  return form;
}

export function isLoading({ application }, ...keys) {
  return keys.some(k => application.loaders[k] === true);
}

const isLoadingKey = key => ({ application }) => application.loaders[key] === true;

const makeGetIsLoading = reducerPredicate => (...args) => state =>
  compose(
    reducerPredicate,
    map(arg => (typeof arg === 'function' ? arg(state) : isLoadingKey(arg)(state))),
  )(args);

export const getIsLoading = makeGetIsLoading(any(equals(true)));
export const getAllIsLoading = makeGetIsLoading(all(equals(true)));
