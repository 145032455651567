import { createSelector } from 'reselect';

export const getById = ({ impactAssessmentThreshold }) => impactAssessmentThreshold.byId;
export const getIds = ({ impactAssessmentThreshold }) => impactAssessmentThreshold.list.allIds;
export const getImpactAssessmentThreshold = (state, id) => getById(state)[id];

export const getImpactAssessmentThresholdList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

export const getImpactAssessmentThresholdsForImpactAssessment = createSelector(
  [getImpactAssessmentThresholdList],
  impactAssessmentThresholdList =>
    createSelector(
      impactAssessmentId => impactAssessmentId,
      impactAssessmentId =>
        impactAssessmentThresholdList.filter(
          impactAssessmentThreshold => impactAssessmentThreshold.ImpactAssessmentId === impactAssessmentId,
        ),
    ),
);
