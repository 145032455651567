import { schema } from 'normalizr';
import { complianceReviewSchemas } from '../complianceReview';
import { rawMatDataReviewSchemas } from '../rawMatDataReview';
import { productDataReviewSchemas } from '../productDataReview';

export const formulaReviewSchema = new schema.Entity(
  'formulaReviews',
  {
    ComplianceReviews: complianceReviewSchemas.complianceReviewListSchema,
    RawMatDataReviews: rawMatDataReviewSchemas.rawMatDataReviewListSchema,
    ProductDataReview: productDataReviewSchemas.productDataReviewSchema,
  },
  { idAttribute: 'FormulaReviewId' },
);

export const formulaReviewListSchema = [formulaReviewSchema];
