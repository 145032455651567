import { createSelector } from 'reselect';
import formFieldState from "../../../constants/formFieldState";

export const getStateByFieldName = ({formError}) => formError.stateByFieldName;

export const formHasError = createSelector(getStateByFieldName, fieldsStatus => {
  let hasError = false;
  Object.keys(fieldsStatus).forEach(key => {
    if(fieldsStatus[key] === formFieldState.error) {
      hasError = true;
    }
  });
  return hasError;
});
