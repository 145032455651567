import { takeLatest, put, takeEvery, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import dashboardApi from '../../api/dashboardApi';
import * as dashboardTypes from './dashboardTypes';
import * as dashboardActions from './dashboardActions';
import { dashboardListSchema, dashboardSchema, dashboardResultSchema } from './dashboardSchemas';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(dashboardTypes.DASHBOARD_RESULT_LIST.REQUEST, getDashboardResultListSaga),
    takeLatest(dashboardTypes.DASHBOARD_LIST.REQUEST, getDashboardListSaga),
    takeLatest(dashboardTypes.DASHBOARD.REQUEST, getDashboardSaga),
  ]);
}

function* onLocationChange(action) {
  // if (action.payload.pathname === '/dashboards/-1') {
  //   yield put(dashboardActions.newdashboard());
  // }
}

function* getDashboardListSaga() {
  yield put(applicationActions.setLoading(dashboardTypes.DASHBOARD_LIST.REQUEST));
  yield callApiSagaAndNormalize(dashboardActions.listDashboards, dashboardApi.getDashboardList, dashboardListSchema);
  yield put(applicationActions.unsetLoading(dashboardTypes.DASHBOARD_LIST.REQUEST));
}

function* getDashboardSaga(action) {
  yield put(applicationActions.setLoading(dashboardTypes.DASHBOARD.REQUEST));
  yield callApiSagaAndNormalize(dashboardActions.dashboard, dashboardApi.getDashboard, dashboardSchema, action.payload);
  yield put(applicationActions.unsetLoading(dashboardTypes.DASHBOARD.REQUEST));
}

function* getDashboardResultListSaga(action) {
  yield put(applicationActions.setLoading(dashboardTypes.DASHBOARD_RESULT_LIST.REQUEST));
  yield callApiSagaAndNormalize(dashboardActions.getDashboardResults, dashboardApi.getDashboardResults, dashboardResultSchema, action.payload);
  yield put(applicationActions.unsetLoading(dashboardTypes.DASHBOARD_RESULT_LIST.REQUEST));
}
