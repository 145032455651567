import * as userRoleSelectors from './userRoleSelectors';
import * as userRoleActions from './userRoleActions';
import * as userRoleTypes from './userRoleTypes';
import * as userRoleSchemas from './userRoleSchemas';
import sagas, * as userRoleSagas from './userRoleSagas';

import reducer from './userRoleReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { userRoleSelectors, userRoleActions, userRoleTypes, userRoleSchemas, userRoleSagas };
export default reducer;
