import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Baby from 'babyparse';
const XLSX = require('xlsx');

const ExportProductToExcelButton = ({ onClick }) => (
  <Button bsSize="small" onClick={onClick}>
     {I18n.t('rawMat.exportProductsToExcel')}&nbsp;&nbsp;<i className="fa fa-file-excel" />
  </Button>
);

ExportProductToExcelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

// const mapDispatchToProps = (dispatch, { companyId, filter }) => ({
//   onClick: () => dispatch(push({ pathname: '/rawMats', query: { companyId, filter } })),
// });

// const export2Excel = () => {
//   if (this.grid) {
//     this.grid.api.selectAllFiltered();
//     // this.grid.api.forEachNodeAfterFilterAndSort(this.exportNode2Excel);
//     this.download2Excel(this.parseCsv(this.grid.api.getDataAsCsv()));
//     this.grid.api.deselectAll();
//   }
// };

// const parseCsv = (csvString) => {
//   const json = Baby.parse(csvString, { header: true, dynamicTyping: true, });
//   const sheet = XLSX.utils.json_to_sheet(json.data);
//   return sheet;
// };

// const download2Excel = (sheet) => {
//   const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };

//   const wb = { SheetNames: [], Sheets: {} };
//   wb.SheetNames.push('sheet');
//   wb.Sheets.sheet = sheet;
//   const wbout = XLSX.write(wb, wopts);

//   function s2ab(s) {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   }

//   FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'CalmosDownload.xlsx');
// }

export default connect(null, null)(ExportProductToExcelButton);
// export default connect(null, mapDispatchToProps)(ExportProductToExcelButton);
