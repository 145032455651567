import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import RawMatActionsModal from './RawMatActionsModal';
import { rawMatSelectors } from '../../../../redux/rawMat';

const RawMatActionsModalContainer = props => <RawMatActionsModal {...props} />;

const mapStateToProps = (state, { rawMatId }) => {
  const rawMat = rawMatSelectors.getById(state)[rawMatId];
  const rawMatName = R.propOr('', 'Name', rawMat);
  return {
    title: I18n.t('rawMatActionsModal.title', { rawMatName }),
  };
};

RawMatActionsModalContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(RawMatActionsModalContainer);
