import { put, all, select, takeEvery } from 'redux-saga/effects';
import * as workItemFormActions from './workItemFormActions';
import { isNullOrUndef } from '../../../utils/utils';
import { change, getFormValues } from 'redux-form';
import formNames from '../../../constants/formNames';
import moment from 'moment';

const formName = formNames.workItem;

export default function* root() {
  yield all([
    takeEvery(workItemFormActions.onStartedDateChanged, onStartedDateChanged),
    takeEvery(workItemFormActions.onFinishDateChanged, onFinishDateChanged),
    takeEvery(workItemFormActions.onDurationChanged, onDurationChanged),
  ]);
}

function* onStartedDateChanged(data) {
  const value = data.payload.value;
  const formValues = yield select(state => getFormValues(formName)(state));
  if (formValues.FinishedOn !== null) {
    const startedOn = moment.utc(value)
      .utc();
    const finishedOn = moment.utc(formValues.FinishedOn)
      .utc();
    const difference = moment.duration(finishedOn.diff(startedOn));
    const minutes = difference.asMinutes();
    // eslint-disable-next-line radix
    yield put(change(formName, 'Duration', parseInt(Math.round(minutes))));
  }
}

function* onFinishDateChanged(data) {
  const value = data.payload.value;
  const formValues = yield select(state => getFormValues(formName)(state));
  const startedOn = moment.utc(formValues.StartedOn)
    .utc();
  const finishedOn = moment.utc(value)
    .utc();
  const difference = moment.duration(finishedOn.diff(startedOn));
  const minutes = difference.asMinutes();
  // eslint-disable-next-line radix
  yield put(change(formName, 'Duration', parseInt(Math.round(minutes))));
}

function* onDurationChanged(data) {
  const value = data.payload.value;
  if (isNullOrUndef(value) || value === '') {
    yield put(change(formName, 'FinishedOn', null));
  } else {
    const formValues = yield select(state => getFormValues(formName)(state));
    const startedOn = moment.utc(formValues.StartedOn)
      .utc();
    startedOn.add(value, 'm');
    yield put(change(formName, 'FinishedOn', startedOn.format()));
  }
}
