import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, LinkCellRenderer, DeleteButtonRenderer, DateCellRenderer } from '../../_Common/Grids';

const SubstanceDocumentList = props => {
  const {
    substanceDocuments,
    saveSubstanceDocument,
    isLoadingSubstanceDocumentList,
    getSubstanceDocuments,
    deleteSubstanceDocument,
  } = props;

  const handleCellValueChange = ({ data }) => saveSubstanceDocument(data.Document);
  const handleDeleteClick = ({ SubstanceDocumentId }) => deleteSubstanceDocument(SubstanceDocumentId);

  const columns = [
    {
      headerName: I18n.t('document.name'),
      field: 'Document.FileName',
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'Document.FileName',
        linkKey: 'Document.FileLink',
      },
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('document.description'),
      field: 'Document.Description',
      editable: true,
      onCellValueChanged: handleCellValueChange,
    },
    {
      headerName: I18n.t('document.createdBy'),
      field: 'CreatedBy',
    },
    {
      headerName: I18n.t('document.createdOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      sort: 'desc',
    },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      width: 100,
      suppressSizeToFit: true,
      cellRendererParams: {
        onClick: handleDeleteClick,
      },
    },
  ];

  return (
    <SelectionGrid
      data={substanceDocuments}
      keyName="SubstanceDocumentId"
      columns={columns}
      isLoading={isLoadingSubstanceDocumentList}
      onRefresh={getSubstanceDocuments}
      stopEditingWhenGridLosesFocus
      showAddButton={false}
    />
  );
};

SubstanceDocumentList.defaultProps = {
  substanceDocuments: [],
};

SubstanceDocumentList.propTypes = {
  isLoadingSubstanceDocumentList: PropTypes.bool.isRequired,
  saveSubstanceDocument: PropTypes.func.isRequired,
  getSubstanceDocuments: PropTypes.func.isRequired,
  deleteSubstanceDocument: PropTypes.func.isRequired,
  substanceDocuments: PropTypes.array.isRequired, //eslint-disable-line
};

export default SubstanceDocumentList;
