import { schema } from 'normalizr';
import * as documentSchemas from '../document/documentSchemas';

export const rawMatDocumentSchema = new schema.Entity('rawMatDocuments', {}, { idAttribute: 'RawMatDocumentId' });
export const rawMatDocumentWithDocumentSchema = new schema.Entity(
  'rawMatDocuments',
  {
    Document: documentSchemas.documentSchema,
  },
  { idAttribute: 'RawMatDocumentId' },
);

export const rawMatDocumentListWithDocumentSchema = [rawMatDocumentWithDocumentSchema];
