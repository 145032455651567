import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, FormSection } from 'redux-form';
import { Form, Grid, Row, Col, Panel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { InputField, SelectField } from '../../../_Common/Forms';
import SubstanceBasicFormSection from '../../../Substances/SubstanceBasicFormSection';
import { isNullOrUndef } from '../../../../utils/utils';
import BaseLoader from '../../../_Common/BaseLoader';
import { isNumberField } from '../../../../utils/fieldValidation';

const SubstanceProportionForm = props => {
  const {
    handleSubmit,
    substanceList,
    fetchSubstance,
    selectedSubstanceId,
    onSubstanceSelected,
    substanceProportionId,
    isFecthingSubstanceDetails,
    substanceListFilter,
    sortByInciName,
    ...otherProps
  } = props;
  const handleSubstanceChange = (e, newValue) => {
    onSubstanceSelected(newValue);
  };

  const isInEditMode = !isNullOrUndef(substanceProportionId);

  const isSubstanceSelected = !isNullOrUndef(selectedSubstanceId);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <Field
              name="ReferencedSubstanceId"
              filterOptions={(...args) => sortByInciName(substanceListFilter(...args))}
              label={I18n.t('general.substance')}
              component={SelectField}
              options={substanceList}
              labelField="InciName"
              valueField="SubstanceId"
              onChange={handleSubstanceChange}
              disabled={isInEditMode}
              useVirtualized
            />
            {isSubstanceSelected && (
              <div>
                <Panel header={I18n.t('substanceProportion.substanceDetailsHeader')} bsStyle="primary">
                  <BaseLoader isLoading={isFecthingSubstanceDetails}>
                    <FormSection name="Substance">
                      <SubstanceBasicFormSection {...otherProps} readOnly={isSubstanceSelected} />
                    </FormSection>
                  </BaseLoader>
                </Panel>

                <Panel header={I18n.t('substanceProportion.substanceProportionHeader')} bsStyle="primary">
                  <Col sm={3}>
                    <Field
                      name="Percentage"
                      label={I18n.t('substanceProportion.percentage')}
                      component={InputField}
                      inputGroupLabel="%"
                      validate={isNumberField}
                    />
                  </Col>
                </Panel>
              </div>
            )}
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

SubstanceProportionForm.defaultProps = {
  selectedSubstanceId: undefined,
  substanceProportionId: undefined,
};

SubstanceProportionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  substanceList: PropTypes.arrayOf(
    PropTypes.shape({
      SubstanceId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      InciName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchSubstance: PropTypes.func.isRequired,
  selectedSubstanceId: PropTypes.number,
  onSubstanceSelected: PropTypes.func.isRequired,
  substanceProportionId: PropTypes.number,
  isFecthingSubstanceDetails: PropTypes.bool.isRequired,
  substanceListFilter: PropTypes.func.isRequired,
  sortByInciName: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'substanceProportionForm',
  destroyOnUnmount: true,
  asyncBlurFields: [], // this seems to prevent the error
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    SubstanceProportionId: -1,
  },
})(SubstanceProportionForm);
