import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as generalDashboardTypes from './generalDashboardTypes';

// export const listgeneralDashboards = createRequestActions(generalDashboardTypes.generalDashboard_LIST);
export const savegeneralDashboard = createRequestActions(generalDashboardTypes.SAVE_GENERALDASHBOARD);
export const deletegeneralDashboard = createRequestActions(
  generalDashboardTypes.DELETE_GENERALDASHBOARD,
  generalDashboardId => ({ generalDashboardId }),
);
export const updategeneralDashboard = createAction(
  generalDashboardTypes.UPDATE_GENERALDASHBOARD,
  (generalDashboardId, data) => ({
    generalDashboardId,
    ...data,
  }),
);

export const listProducts = createRequestActions(
  generalDashboardTypes.GENERALDASHBOARD_LIST,
  ({ productId: id, ...query } = {}) => ({
    ...(id && { id }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);
