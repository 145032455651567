import { createRequestActions, createFetchAction } from '../utils';
import * as formulaReviewTypes from './formulaReviewTypes';

export const listFormulaReviewForProduct = createRequestActions(
  formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST,
  ProductId => ({ ProductId }),
);

export const fetchFormulaReviews = createFetchAction(formulaReviewTypes.FETCH_FORMULA_REVIEWS, 'formulaReviewId');

export const saveFormulaReview = createRequestActions(formulaReviewTypes.SAVE_FORMULA_REVIEW);
