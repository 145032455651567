import * as impactAssessmentProductSelectors from './impactAssessmentProductSelectors';
import * as impactAssessmentProductActions from './impactAssessmentProductActions';
import * as impactAssessmentProductTypes from './impactAssessmentProductTypes';
import * as impactAssessmentProductSchemas from './impactAssessmentProductSchemas';
import sagas, * as impactAssessmentProductSagas from './impactAssessmentProductSagas';
import reducer from './impactAssessmentProductReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { impactAssessmentProductSelectors, impactAssessmentProductActions, impactAssessmentProductTypes, impactAssessmentProductSchemas, impactAssessmentProductSagas };
export default reducer;
