import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { substanceFunctionTypes } from '../substanceFunction';
import * as functionTypes from './functionTypes';
import * as rawMatTypes from '../rawMat/rawMatTypes';
import * as rawMatProportionTypes from '../rawMatProportion/rawMatProportionTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';

// export const initialState = {
//   functionList: [],
// };

// export default createFetchList(functionTypes.FUNCTION_LIST, 'FunctionId');

const byId = handleActions(
  {
    [functionTypes.FUNCTION_LIST.SUCCESS]: (state, { payload }) => ({
      ...payload.entities.functions,
    }),
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      ...payload.entities.functions,
    }),
    [substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST.SUCCESS]: (state, { payload, meta: { query } }) => {
      if (query && query.OptIncludeFunction && payload.entities.functions) {
        return {
          ...state,
          ...payload.entities.functions,
        };
      }
      return state;
    },
  },
  {},
);

const allIds = handleActions(
  {
    [functionTypes.FUNCTION_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.functions) {
        return [...new Set([...state, ...Object.keys(payload.entities.functions).map(Number)])];
      }
      return state;
    },
    [substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST.SUCCESS]: (state, { payload, meta: { query } }) => {
      if (query && query.OptIncludeFunction && payload.entities.functions) {
        return [...new Set([...state, ...Object.keys(payload.entities.functions).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
