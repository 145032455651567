import { createAction } from 'redux-actions';
import * as labelReviewSymbolWizardTypes from './labelReviewSymbolWizardTypes';
import { createUpdateAction } from '../../../../utils/actions';
import {createRequestActions} from "../../../utils";

export const loadLabelReviewSymbolData = createAction(labelReviewSymbolWizardTypes.LOAD_LABEL_REVIEW_SYMBOL_DATA);
export const handleFieldEdit = createUpdateAction(labelReviewSymbolWizardTypes.FIELD_EDITED);
export const updateLabelReviewSymbol = createAction(labelReviewSymbolWizardTypes.UPDATE_LABEL_REVIEW_SYMBOL);
export const handleAddButtonClick = createAction(labelReviewSymbolWizardTypes.ADD_BUTTON_CLICKED);
export const handleDeleteButtonClick = createAction(labelReviewSymbolWizardTypes.DELETE_BUTTON_CLICKED);
export const handleRevertButtonClick = createAction(labelReviewSymbolWizardTypes.REVERT_BUTTON_CLICKED);
export const addNewLabelReviewSymbol = createAction(labelReviewSymbolWizardTypes.ADD_NEW_LABEL_REVIEW_SYMBOL);
export const removeLabelReviewSymbol = createAction(labelReviewSymbolWizardTypes.REMOVE_LABEL_REVIEW_SYMBOL);
export const uploadSymbolImage = createRequestActions(
  labelReviewSymbolWizardTypes.UPLOAD_SYMBOL_IMAGE,
  (code, data) => ({
    code,
    ...data,
  }),
);
export const updateLabelSymbolDocID = createAction(labelReviewSymbolWizardTypes.UPDATE_SYMBOL_DOCUEMNTID);
