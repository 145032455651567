import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as labelReviewTypes from '../labelReview/labelReviewTypes';
import { referentialsTypes } from '../referentials';

const byCode = handleActions(
  {
    [labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.labelSymbolCorrectiveActions,
    }),
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload }) =>
      (payload.entities && payload.entities.labelSymbolCorrectiveActions
        ? {
          ...state,
          ...payload.entities.labelSymbolCorrectiveActions,
        }
        : state),
    [referentialsTypes.ALL_REFERENTIALS.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.LabelSymbols.reduce((acc, labelSymbol) => {
        const labelSymbolToAdd = labelSymbol.LabelSymbolCorrectiveAction
          ? { [labelSymbol.LabelSymbolCorrectiveAction.Code]: { ...labelSymbol.LabelSymbolCorrectiveAction } }
          : null;
        return {
          ...acc,
          ...labelSymbolToAdd,
        };
      }, {}),
    }),
  },
  {},
);

const allCodes = handleActions(
  {
    [combineActions(
      labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS,
      labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.labelSymbolCorrectiveActions) {
        return [...new Set([...state, ...Object.keys(payload.entities.labelSymbolCorrectiveActions)])];
      }
      return state;
    },
    [referentialsTypes.ALL_REFERENTIALS.SUCCESS]: (state, { payload }) => [
      ...new Set([
        ...state,
        ...payload.LabelSymbols.map(
          labelSymbol => labelSymbol.LabelSymbolCorrectiveAction && labelSymbol.LabelSymbolCorrectiveAction.Code,
        ).filter(labelSymbol => labelSymbol !== null),
      ]),
    ],
  },
  [],
);

export default combineReducers({
  byCode,
  list: combineReducers({
    allCodes,
  }),
});
