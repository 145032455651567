import { takeEvery, put, all } from 'redux-saga/effects';
import { labelSymbolWizardActions, labelSymbolWizardTypes } from './index';

export default function* root() {
  yield all([
    // takeLatest(
    //   [labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD, labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD],
    //   newOrEditLabelReviewSaga,
    // ),
    takeEvery([labelSymbolWizardTypes.FIELD_EDITED], onEditFieldSaga),
  ]);
}

// function* onDeleteButtonClickedSaga({ payload }) {
//   const labelReviewSymbolId = payload;
//   yield put(labelReviewSymbolWizardActions.removeLabelReviewSymbol(labelReviewSymbolId));
// }

function* onEditFieldSaga({ payload }) {
  yield put(labelSymbolWizardActions.updateLabelSymbol(payload));
}

// function* newOrEditLabelReviewSaga({ payload }) {
//   const { LabelReviewId, ProductId } = payload;
//   const editMode = !isNullOrUndef(LabelReviewId);
//   const isLoading = yield select(labelReviewWizardSelectors.isLoadingWizard);
//   const publicLabelSymbolList = yield select(labelSymbolSelectors.getPublicLabelSymbolList);
//   if (isLoading) {
//     yield take([referentialsTypes.ALL_REFERENTIALS.SUCCESS, labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]);
//   }

//   const currentLabelReviewSymbolList = editMode
//     ? yield select(state => labelReviewSymbolSelectors.getLabelReviewSymbolListForLabelReview(state, LabelReviewId))
//     : yield select(state => labelReviewSymbolSelectors.getLabelReviewSymbolListFromLatestLabelReview(state, ProductId));

//   const privateLabelSymbolListForLabelReviewSymbolList = yield select(state =>
//     labelSymbolSelectors
//       .getPrivateLabelSymbolList(state)
//       .filter(labelSymbol =>
//         currentLabelReviewSymbolList.map(labelReviewSymbol => labelReviewSymbol.SymbolCode).includes(labelSymbol.Code),
//       ),
//   );

//   const result = normalize(
//     [...privateLabelSymbolListForLabelReviewSymbolList, ...publicLabelSymbolList],
//     labelSymbolSchemas.labelSymbolListSchema,
//   );
//   yield put(labelSymbolWizardActions.loadLabelSymbolData(result));
// }
