import React from 'react';
import OrderTaskList from './OrderTaskList';
import { connect } from 'react-redux';

const OrderTaskTab = props => {
  const { isOrderEditable, ...otherProps } = props;
  return (
    <div>
      <OrderTaskList isAddDisabled={!isOrderEditable} allowDelete={isOrderEditable} {...otherProps} />
    </div>
  );
};

OrderTaskTab.propTypes = {
  // isOrderEditable: PropTypes.bool.isRequired,
};

// export default connect(null, null)(OrderTaskTab);
export default OrderTaskTab;
