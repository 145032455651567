import * as formulaReviewWizardSelectors from './formulaReviewWizardSelectors';
import * as formulaReviewWizardActions from './formulaReviewWizardActions';
import * as formulaReviewWizardTypes from './formulaReviewWizardTypes';
import sagas from './formulaReviewWizardSagas';
import reducer from './formulaReviewWizardReducer';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { formulaReviewWizardSelectors, formulaReviewWizardActions, formulaReviewWizardTypes };
export default reducer;
