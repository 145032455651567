import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {isPristine, isSubmitting, reset, submit} from "redux-form";
import {cosAnnexeTRFormActions, cosAnnexeTRFormSelectors} from "../../../../redux/form/cosAnnexeTRForm";
import {referentialSelectors} from "../../../../redux/referentials";
import CosAnnexeTRModal from "./CosAnnexeTRModal";
import formNames from "../../../../constants/formNames";
import {applicationSelectors} from "../../../../redux/application";
import {cosAnnexeTRActions, cosAnnexeTRTypes} from "../../../../redux/cosAnnexeTR";

const formName = formNames.cosAnnexeTR;

const CosAnnexeTRModalContainer = props => (
  <CosAnnexeTRModal {...props}/>
);

const mapStateToProps = (state, {CosAnnexeId}) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const initialFormValues = cosAnnexeTRFormSelectors.getInitialValues(state, CosAnnexeId);
  const isFormPristine = isPristine(formName)(state);
  return {
    title: `${I18n.t('cosAnnexeTRFormModal.title')}`,
    initialValues: initialFormValues,
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isSaveDisabled: isFormSubmitting || isFormPristine,
    isLoading: applicationSelectors.isLoading(state, cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, {CosAnnexeId}) => {
  return ({
    onLoad: () => {
      dispatch(cosAnnexeTRActions.listCosAnnexeTR.request({ CosAnnexeId }));
    },
    onSaveButtonClick: () => dispatch(submit(formName)),
    onSubmit: data => cosAnnexeTRFormActions.submitCosAnnexeTRForm(data, dispatch),
    onCancelButtonClick: () => {
      dispatch(reset(formName));
    },
  });
};

CosAnnexeTRModalContainer.defaultProps = {
  cosAnnexeId: undefined,
};

CosAnnexeTRModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  cosAnnexeId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CosAnnexeTRModalContainer);
