import { createRequestActions } from '../utils';
import * as productDocumentTypes from './productDocumentTypes';

export const saveProductDocument = createRequestActions(
  productDocumentTypes.SAVE_PRODUCT_DOCUMENT,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);

export const uploadProductDocument = createRequestActions(
  productDocumentTypes.UPLOAD_PRODUCT_DOCUMENT,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);

export const uploadProductComposition = createRequestActions(
  productDocumentTypes.UPLOAD_PRODUCT_COMPOSITION,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);

export const sendProductDocumentToCosmos = createRequestActions(
  productDocumentTypes.SEND_PRODUCT_DOCUMENT_TO_COSMOS,
  (ProductDocumentId, data) => ({
    ProductDocumentId,
    ...data,
  }),
);

export const listProductDocumentsForProduct = createRequestActions(
  productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT,
  ProductId => ({ ProductId }),
  (CompanyDocuments, ProductId) => ({ ProductId }),
);

export const deleteProductDocument = createRequestActions(
  productDocumentTypes.DELETE_PRODUCT_DOCUMENT,
  ProductDocumentId => ({ ProductDocumentId }),
);
