import { createSelector } from 'reselect';
import * as productSelectors from '../product/productSelectors';
import * as documentSelectors from '../document/documentSelectors';
import config from '../../config';

const API_ROOT = config.baseUrl;

export const getById = ({ productDocument }) => productDocument.byId;
export const getIds = ({ productDocument }) => productDocument.list.allIds;
export const getProductDocument = (state, id) => getById(state)[id];
export const getProductDocumentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getProductDocumentListForProduct = (state, productId) => {
  const product = productSelectors.getProduct(state, productId);
  if (!product || !product.ProductDocuments) return undefined;
  return product.ProductDocuments.map(pdId => {
    const productDocument = getProductDocument(state, pdId);
    return {
      ...productDocument,
      Document: {
        ...documentSelectors.getById(state)[productDocument.DocumentId],
        FileLink: `${API_ROOT}/ProductsDocuments/${pdId}/File`,
      },
    };
  });
};
