import sagaManager from '../sagaManager';
import * as companyActions from './companyActions';
import reducer from './companyReducer';
import sagas, * as companySagas from './companySagas';
import * as companySelectors from './companySelectors';
import * as companyTypes from './companyTypes';


sagaManager.addSagaToRoot(sagas);

export { companySelectors, companyActions, companyTypes, companySagas };
export default reducer;
