import { createSelector } from 'reselect';

export const getById = ({ rawMatAction }) => rawMatAction.byId;
export const getIds = ({ rawMatAction }) => rawMatAction.list.allIds;
export const getRawMatActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getRawMatAction = (state, id) => state.rawMatAction.byId[id];

const getRawMatActionsForRawMat = rawMatId =>
  createSelector(getRawMatActionList, rawMatActionList =>
    rawMatActionList.filter(rawMatAction => rawMatAction.RawMatId === rawMatId),
  );

export const makeGetRawMatActionsForRawMat = createSelector(
  getRawMatActionsForRawMat,
  rawMatActionsForSubtance => rawMatActionsForSubtance,
);
