import { schema } from 'normalizr';
import * as documentSchemas from '../document/documentSchemas';

export const productDocumentSchema = new schema.Entity('productDocuments', {}, { idAttribute: 'ProductDocumentId' });
export const productDocumentWithDocumentSchema = new schema.Entity(
  'productDocuments',
  {
    Document: documentSchemas.documentSchema,
  },
  { idAttribute: 'ProductDocumentId' },
);

export const productDocumentListWithDocumentSchema = [productDocumentWithDocumentSchema];
