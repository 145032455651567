import React from 'react';
import { reduxForm, FormSection } from 'redux-form';
import formNames from '../../constants/formNames';
import ContactForm from '../_Common/Forms/ContactForm';

const CompanyContactFormSection = props => (
  <FormSection name="Contact">
    <ContactForm {...props} />
  </FormSection>
);

export default reduxForm({
  form: formNames.company,
  destroyOnUnmount: true,
  enableReinitialize: true,
  // validate,
})(CompanyContactFormSection);
