import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Row, Col, Grid, Button, FormControl, FormGroup } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import VirtualizedSelect from 'react-virtualized-select';
import { RawMatListDropdown } from '../../_Common/Dropdowns';

class CosmosComposition extends Component {
  static propTypes = {
    cosmosComposition: PropTypes.object.isRequired, // eslint-disable-line
    rawMatList: PropTypes.array, // eslint-disable-line
    initialValues: PropTypes.shape({
      RawMatId: PropTypes.number,
      percentage: PropTypes.string.isRequired,
    }).isRequired,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: {},
    rawMatList: [],
  };

  constructor(props) {
    super(props);
    this.state = props.initialValues;
    this.onSelectedRawMatChanged = this.onSelectedRawMatChanged.bind(this);
    this.onpercentageChanged = this.onpercentageChanged.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.isFullyCompleted = this.isFullyCompleted.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.initialValues !== nextProps.initialValues) {
    this.setState({
      rawMatId: nextProps.initialValues.rawMatId,
      percentage: nextProps.initialValues.percentage,
    });
    // }
  }

  onSave() {
    this.props.onSave({
      productProportionId: _.get(this.props.cosmosComposition.productProportion, 'ProductProportionId', null),
      productId: this.props.cosmosComposition.productId,
      CosmosProportionId: this.props.cosmosComposition.prodigumProductProportion.ProportionId,
      RawMatId: this.state.rawMatId,
      Percentage: this.state.percentage,
      CosmospercentageOnLastMatch: this.props.cosmosComposition.prodigumProductProportion.BeforeTransfoPct,
      CosmosIngredientId: this.props.cosmosComposition.prodigumProductProportion.ReferencedId,
    });
  }

  // shouldComponentUpdate(nextProps) {
  //   return !nextProps.isLoadingProductProportion;
  // }

  onSelectedRawMatChanged(value) {
    this.setState({
      rawMatId: value,
    });
  }

  onpercentageChanged(value) {
    this.setState({
      percentage: value,
    });
  }

  isFullyCompleted() {
    return !(_.isNil(this.state.percentage) || _.isNil(this.state.rawMatId));
  }
  isDirty() {
    return !_.isEqual(this.state, this.props.initialValues);
  }

  render() {
    const { rawMatList, initialValues, cosmosComposition, onDelete, onAdd } = this.props;

    // const getProdigumProductProportionLabel = () => {
    //   const item = cosmosComposition.prodigumProductProportion;
    //   return item ? `${item.IngredientTradeName} - ${item.IngredientSupplierName} - ${item.BeforeTransfoPct}%` : '';
    // };

    const getIngredientName = () => {
      const item = cosmosComposition.prodigumProductProportion;
      return item ? `${item.IngredientTradeName}` : '';
    };

    const getIngredientSupplierName = () => {
      const item = cosmosComposition.prodigumProductProportion;
      return item ? `${item.IngredientSupplierName}` : '';
    };

    const getIngredientpercentage = () => {
      const item = cosmosComposition.prodigumProductProportion;
      return item ? `${item.BeforeTransfoPct}%` : '';
    };

    return (
      <Grid fluid>
        <Row>
          <span style={cosmosComposition.haspercentageChanged ? { fontWeight: 'bold' } : null}>
            <Col sm={2}>
              <p>{getIngredientName()}</p>
            </Col>
            <Col sm={2}>{getIngredientSupplierName()}</Col>
            <Col sm={1}>{getIngredientpercentage()}</Col>
          </span>
          <Col sm={4}>
            <RawMatListDropdown initialOptions={rawMatList}>
              {({ rawMatList, filterOptions }) => (
                <VirtualizedSelect
                  value={_.get(this.state, 'rawMatId', initialValues.rawMatId)}
                  labelKey="FullName"
                  valueKey="RawMatId"
                  options={rawMatList}
                  filterOptions={filterOptions}
                  onChange={value => this.onSelectedRawMatChanged(_.get(value, 'RawMatId', null))}
                />
              )}
            </RawMatListDropdown>
            {/* <VirtualizedSelect
              value={_.get(this.state, 'rawMatId', initialValues.rawMatId)}
              labelKey="FullName"
              valueKey="RawMatId"
              options={rawMatList}
              onChange={value => this.onSelectedRawMatChanged(_.get(value, 'RawMatId', null))}
            /> */}
          </Col>
          <Col sm={1}>
            <FormGroup bsSize="small">
              <FormControl
                type="number"
                value={_.get(this.state, 'percentage', initialValues.percentage)}
                onChange={e => this.onPChanged(e.target.value)}
                disabled={cosmosComposition.productProportion == null}
                min={0}
                max={100}
                step={0.1}
              />
            </FormGroup>
          </Col>
          <Col sm={2}>
            <Button
              bsSize="small"
              bsStyle="success"
              onClick={this.onSave}
              disabled={
                (cosmosComposition.productProportion == null && !this.isFullyCompleted()) ||
                (cosmosComposition.productProportion != null && (!this.isDirty() || !this.isFullyCompleted()))
              }>
              <i className="fa fa-floppy-o" />&nbsp;
              <Translate value="general.save" />
            </Button>
            &nbsp;
            {cosmosComposition.productProportion != null ? (
              <Button
                bsSize="small"
                bsStyle="danger"
                onClick={() => onDelete(cosmosComposition.productProportion.ProductProportionId)}>
                <i className="fa fa-trash" />&nbsp;
                <Translate value="general.delete" />
              </Button>
            ) : null}
            &nbsp;
            {_.get(cosmosComposition, 'prodigumProductProportion.CorrespondingRawMatId', null) == null ? (
              <Button
                bsSize="small"
                bsStyle="default"
                onClick={() => onAdd(cosmosComposition.prodigumProductProportion)}>
                <i className="fa fa-plus" />&nbsp;
                {/* <Translate value="general.add" /> */}
              </Button>
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default CosmosComposition;
