import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import { css } from 'glamor';

class ButtonDropDown extends PureComponent {
  static defaultProps = {
    buttonText: 'Select',
    labelKey: 'Value',
    valueKey: 'Key',
  };

  static propTypes = {
    options: PropTypes.array, // eslint-disable-line
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    onButtonClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { valueKey } = this.props;
    const nextOptionsList = nextProps.options;
    const { selectedOption } = this.state;
    if (selectedOption !== null && !nextOptionsList.find(elem => elem[valueKey] === selectedOption[valueKey])) {
      this.setState({
        selectedOption: null,
      });
    }
  }

  onButtonClick = () => {
    this.props.onButtonClick(this.state.selectedOption);
  };

  handleChange = option => {
    this.setState({ selectedOption: option });
  };

  render() {
    const { buttonText, ...otherProps } = this.props;
    const select = css({
      // '& .Select': { zIndex: 999 },
    });
    return (
      <div {...select}>
        <Row>
          <Col sm={3}>
            <FormGroup>
              <Select
                onChange={this.handleChange}
                value={this.state.selectedOption}
                // openOnFocus
                {...otherProps}
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <Button
              bsSize="small"
              bsStyle="default"
              type="button"
              onClick={this.onButtonClick}
              disabled={this.state.selectedOption === null}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ButtonDropDown;
