import { createSelector } from 'reselect';
import * as R from 'ramda';
import { mandateSelectors } from '../mandate';

export const getById = ({ mandateProduct }) => mandateProduct.byId;
export const getIds = ({ mandateProduct }) => mandateProduct.list.allIds;
export const getMandateProduct = (state, id) => getById(state)[id];
export const getMandateProductList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

const getMandateProductForMandate = mandateId =>
  createSelector([mandateSelectors.getById, getById], (mandateById, mandateProductById) =>
    R.compose(R.map(id => mandateProductById[id]), R.prop('MandateProducts'))(
      mandateById[mandateId],
    ),
  );

export const makeGetMandateProductForMandate = createSelector(getMandateProductForMandate, R.identity);
