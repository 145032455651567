import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import Select from 'react-select';
import { Modal, Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel, form, InputGroup } from 'react-bootstrap';
import { isNullOrUndef } from '../../../../utils/utils';
import TaskActionList from './TaskActionList';

class ActionHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      action: undefined,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.reset = this.reset.bind(this);
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  handleActionChange(data) {
    this.setState(() => ({
      action: data,
    }));
  }

  reset() {
    this.setState(() => ({
      comment: '',
      action: undefined,
    }));
  }

  handleAddClick() {
    const { comment, action: { Code } } = this.state;
    this.props.onAdd({ Comment: comment, ActionCode: Code });
    this.reset();
  }

  render() {
    const { title, closeModal, actionList, onAdd, ...otherProps } = this.props;

    const isValid = !isNullOrUndef(this.state.action);

    const actionRenderer = action => `${action.Code} - ${action.Description}`;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <TaskActionList {...otherProps} />
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <form>
                <Col sm={3}>
                  <FormGroup bsSize="small">
                    <ControlLabel>Action</ControlLabel>
                    <Select
                      options={actionList}
                      labelKey="Description"
                      valueKey="Code"
                      value={this.state.action}
                      onChange={this.handleActionChange}
                      optionRenderer={actionRenderer}
                    />
                  </FormGroup>
                </Col>
                <Col sm={9}>
                  <FormGroup>
                    <ControlLabel>Comment</ControlLabel>
                    <InputGroup>
                      <FormControl
                        // componentClass="textarea"
                        bsSize="small"
                        value={this.state.comment}
                        onChange={this.handleCommentChange}
                        placeholder="Enter your comment..."
                      />
                      <InputGroup.Button>
                        <Button bsSize="small" onClick={this.handleAddClick} disabled={!isValid}>
                          Add
                        </Button>
                      </InputGroup.Button>
                    </InputGroup>

                  </FormGroup>
                </Col>
              </form>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
            <i className="fa fa-times" />
            <Translate value="general.close" />
          </Button>
        </Modal.Footer>

      </div>
    );
  }
}

ActionHistoryModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  actionList: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default ActionHistoryModal;
