import * as referentialSelectors from './referentialsSelectors';
import * as referentialActions from './referentialsActions';
import * as referentialsTypes from './referentialsTypes';
import sagas from './referentialsSagas';
import reducer from './referentialsReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { referentialSelectors, referentialActions, referentialsTypes };
export default reducer;
