import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../../_Common/BaseLoader';
import MinimalProductForm from './MinimalProductForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const AddProductToOrderModal = props => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>{I18n.t('modal.productForm.title')}</Modal.Title>
    </Modal.Header>
    <BaseLoader isLoading={props.isLoading}>
      <Modal.Body>
        <MinimalProductForm {...props} />
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={props.closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={props.onSaveButtonClick}
              disabled={props.isFormInValid}
            >
              <Translate value="general.save" />&nbsp;
              <i className="fa fa-arrow-circle-right" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </BaseLoader>
  </div>
);

AddProductToOrderModal.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFormInValid: PropTypes.bool.isRequired,
};

export default AddProductToOrderModal;
