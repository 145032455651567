import { get } from './apiUtils';

const defaultUrl = '/referentials/';
const cpsrTypes = 'CpsrTypes';
const countryTypes = 'Countries';
const InciCalculationTypes = 'InciCalculationTypes';
const cpsrPhraseStyles = 'CpsrPhraseStyles';

export default {
  getPhraseReferentialList: () => get(`${defaultUrl}?type=${cpsrTypes}`),
  getCountryList: () => get(`${defaultUrl}?type=${countryTypes}`),
  getInciCalculationTypes: () => get(`${defaultUrl}?type=${InciCalculationTypes}`),
  getCsprTypes: () => get(`${defaultUrl}?type=${cpsrTypes}`),
  getCpsrPhraseStyles: () => get(`${defaultUrl}?type=${cpsrPhraseStyles}`),
  getAllReferentials: () => get(defaultUrl),
};
