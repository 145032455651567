import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form, Grid, Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { RadioFields } from '../../../_Common/Forms';
import { CompanyListDropdown } from '../../../_Common/Dropdowns';
import formNames from '../../../../constants/formNames';
import { generateAgreementFormActions } from '../../../../redux/form/generateAgreement';

class GenerateAgrementForm extends React.Component {
  render() {
    const { handleSubmit, companyList, onSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col sm={12}>
                  <Field
                    name="CompanyId"
                    label={I18n.t('distributorAgreements.company')}
                    component={CompanyListDropdown}
                    initialOptions={companyList}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Field
                    name="InvoiceAddressee"
                    label={I18n.t('distributorAgreements.invoiceAddressee')}
                    component={RadioFields}
                    labelField="Value"
                    valueField="Key"
                    options={[{ Key: 'COMPANY', Value: 'Company' }, { Key: 'DISTRIBUTOR', Value: 'Distributor' }]}
                  />
                </Col>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: data => generateAgreementFormActions.submitAgreementForm({ ...data }, dispatch),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.generateAgreement,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(GenerateAgrementForm);
