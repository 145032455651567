import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Grid, Row, Col, ControlLabel, Button, ButtonToolbar, Checkbox } from 'react-bootstrap';
import Select from 'react-select';
import { GeneralGrid, DateCellRenderer } from '../../../_Common/Grids';
import { taskSelectors } from '../../../../redux/task';
import StatusCellRenderer from '../../../Orders/OrderTask/StatusCellRenderer';
import userActionsCodes from '../../../../constants/userActionsCodes';
import WithPrivileges from '../../../_Common/WithPrivileges';
import DurationCellRenderer from '../../../_Common/Grids/DurationCellRenderer';

const ActionButtonsRenderer = props => {
  const { onEditButtonClick, data, searchCriteria, onStartButtonClick, onStopButtonClick } = props;

  return (
    <div>
      <ButtonToolbar>
        <Button
          bsStyle="info"
          bsSize="xsmall"
          type="button"
          onClick={() =>
            onEditButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.reassign" />
        </Button>
        <Button
          bsStyle="success"
          bsSize="xsmall"
          type="button"
          disabled={data.Open}
          onClick={() =>
            onStartButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.start" />
        </Button>
        <Button
          bsStyle="danger"
          bsSize="xsmall"
          type="button"
          disabled={!data.Open}
          onClick={() =>
            onStopButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.stop" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

class TaskList extends Component {
  constructor(props) {
    super(props);
    let expert = null;
    //if (props.currentUserActionCodes.find(currentUserActionCode => currentUserActionCode === userActionsCodes.CalmosExpertAction) !== undefined) {
      expert = props.user.UserName;
    //}
    this.state = {
      userToSeek: expert,
      userSelected: expert,
      filterStatus: true
    };
  }

  componentDidMount() {
    const { getTaskList, user } = this.props;
    getTaskList(user.UserName, true);
  }

  compareDueDate(date1Str, date2Str) {
    if (date1Str === null && date2Str === null) {
      return 0;
    }
    if (date1Str === null) {
      return 1;
    }
    if (date2Str === null) {
      return -1;
    }
    const date1 = Date.parse(date1Str);
    const date2 = Date.parse(date2Str);
    return date2 < date1 ? 1 : -1;
  }

  getNumberFromStatus(status) {
    switch (status) {
      case 'TASK_INPROGRESS':
        return 1;
      case 'TASK_TODO':
        return 2;
      case 'TASK_ONHOLD':
        return 3;
      case 'TASK_CREATED':
        return 4;
      case 'TASK_DONE':
        return 5;
      case 'TASK_CANCELED':
        return 6;
      default:
        return 0;
    }
  }

  compareStatus = (status1, status2) => {
    const valStatus1 = this.getNumberFromStatus(status1);
    const valStatus2 = this.getNumberFromStatus(status2);
    // eslint-disable-next-line no-nested-ternary
    return (valStatus1 < valStatus2 ? -1 : valStatus1 > valStatus2 ? 1 : 0);
  };

  render() {
    const {
      expertList,
      isLoading,
      getTaskList,
      getTasksSelector,
      onEditButtonClick,
      onEditSelectedButtonClick,
      onStartButtonClick,
      onStopButtonClick,
      workflowStatusList,
      onChangeStatus,
      onRowDoubleClicked,
      showStatusHistory,
    } = this.props;

    const columns = [
      {
        headerName: I18n.t('orderTask.priority'),
        field: 'Priority',
        width: 70,
        suppressSizeToFit: true,
        sort: 'desc',
        sortedAt: 0,
        // comparator: this.compareDueDate,
      },
      {
        headerName: I18n.t('orderTask.orderNr'),
        field: 'OrderNumber',
        width: 70,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.orderStatus'),
        field: 'OrderStatus',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.code'),
        field: 'ProductCode',
        width: 80,
        suppressSizeToFit: true,
        valueGetter: ({ data }) => (data.ProductCode ? data.ProductCode : I18n.t('orderProcess.unknown')),
        cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
      },
      {
        headerName: I18n.t('orderTask.product'),
        field: 'ProductName',
        valueGetter: ({ data }) => (data.ProductName ? data.ProductName : I18n.t('orderProcess.unknown')),
        sort: 'asc',
        sortedAt: 2,
      },
      {
        headerName: I18n.t('taskType.region'),
        field: 'RegionValue',
        width: 80,
        suppressSizeToFit: true,
      },      {
        headerName: I18n.t('orderProcess.processType'),
        field: 'ProcessTypeCode',
        width: 120,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.task'),
        field: 'TaskTypeName',
      },
      {
        headerName: I18n.t('orderTask.taskOrderNr'),
        field: 'TaskTypeOrderNr',
        sort: 'asc',
        sortedAt: 4,
        width: 60,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.accountMgr'),
        field: 'AccountManager',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.expert'),
        field: 'Expert',
        width: 80,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.status'),
        field: 'Status',
        suppressSizeToFit: true,
        width: 180,
        cellRendererFramework: StatusCellRenderer,
        cellRendererParams: {
          options: workflowStatusList,
          onChange: data => {
            onChangeStatus({ data, Expert: this.state.userToSeek });
          },
          onHistoryClick: showStatusHistory,
        },
        sort: 'asc',
        sortedAt: 3,
        comparator: this.compareStatus,
      },
      {
        headerName: I18n.t('toDoList.expectedDuration'),
        field: 'ExpectedDuration',
        width: 80,
        cellRenderer: DurationCellRenderer,
        suppressFilter: true,
      },
      {
        headerName: I18n.t('toDoList.timeSpent'),
        field: 'DurationDone',
        width: 80,
        cellRenderer: DurationCellRenderer,
        suppressFilter: true,
      },
      {
        headerName: I18n.t('orderTask.dueDate'),
        field: 'DueDate',
        cellRenderer: DateCellRenderer,
        cellRendererParams: {
          includeHours: false,
        },
        // sort: 'asc',
        // sortedAt: 0,
        // comparator: this.compareDueDate,
        width: 100,
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('orderTask.memo'),
        field: 'Description',
      },
      {
        headerName: I18n.t('general.actions'),
        field: 'Open',
        cellRendererFramework: ActionButtonsRenderer,
        suppressSizeToFit: true,
        width: 200,
        cellRendererParams: {
          onEditButtonClick,
          onStartButtonClick,
          onStopButtonClick,
          searchCriteria: { IncludeDetails: true, Expert: this.state.userToSeek },
        },
        suppressFilter: true,
      },
    ];
    const taskList = getTasksSelector({
      IncludeDetails: true,
      Expert: this.state.userToSeek,
      FilterStatus: this.state.filterStatus,
    });

    return (
      <WithPrivileges requiredActionCodes={[userActionsCodes.CalmosExpertAction, userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        return (
      <Grid fluid>
        <Row style={{ marginBottom: '5px' }}>
          <Col sm={1}>
            <ControlLabel>
              <Translate value="orderTask.expert" />
            </ControlLabel>
            <Select
              options={expertList}
              labelKey="LoginName"
              valueKey="LoginName"
              value={this.state.userSelected}
              onChange={value => {
                this.setState({
                  userSelected: value ? value.LoginName : null,
                });
              }}
              clearable
            />
          </Col>
          <Col sm={2}>
            <div style={{ marginTop: '23px' }}>
              <Checkbox
                onChange={({ target }) => this.setState({ filterStatus: target.checked })}
                checked={this.state.filterStatus}
                bsSize="small">
                Filter on status
              </Checkbox>
            </div>
            {/* <Field label={I18n.t('rawMatProportion.isAllergen')} onChange={({ target }) => this.setState({ filterStatus: target.checked })}  component={CheckBoxField} /> */}
          </Col>
          <Col sm={1}>
            <Button
              style={{ marginTop: '22px' }}
              bsStyle="success"
              bsSize="small"
              onClick={() => {
                this.setState({ userToSeek: this.state.userSelected });
                getTaskList(this.state.userSelected, this.state.filterStatus);
              }}
            >
              <Translate value="generalDashboard.search" />
              &nbsp;
              <i className="fa fa-search" aria-hidden="true" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <GeneralGrid
              rowSelection= 'multiple'
              data={taskList}
              columns={columns}
              keyName="TaskId"
              isLoading={isLoading}
              onRefresh={() => getTaskList(this.state.userToSeek, this.state.filterStatus)}
              rowHeight={30}
              rowDoubleClicked={onRowDoubleClicked}
              onExtraButtonClick={(rows) => {
                onEditSelectedButtonClick(rows.map(task => task.TaskId), { Expert: this.state.userSelected, FilterStatus: this.state.filterStatus, IncludeDetails: true });
              }}
            />
          </Col>
        </Row>
      </Grid>);}}
      </WithPrivileges>
    );
  }
}

const mapStateToProps = (state, props) => {
  const getTasks = taskSelectors.makeGetByQuery(state);
  return {
    getTasksSelector: getTasks,
  };
};

export default connect(
  mapStateToProps,
  null,
)(TaskList);

TaskList.propTypes = {
  taskList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getTaskList: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  currentUserActionCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.shape({
    UserName: PropTypes.string.isRequired,
  }).isRequired,
};
