import { createSelector } from 'reselect';

export const getById = ({ productPhrase }) => productPhrase.byId;
export const getIds = ({ productPhrase }) => productPhrase.list.allIds;
export const getProductPhrase = (state, id) => getById(state)[id];

export const getProductPhraseList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

// export const getFilteredProductPhraseList = (state, cpsrTypeCode) => {
//   const productPhraseList = getProductPhraseList(state).filter(pp => pp.CpsrTypeCode === cpsrTypeCode);
//   return productPhraseList.map(productPhrase => ({
//     ...productPhrase,
//     Phrase: phraseSelectors.getphrase(state, productPhrase.PhraseId),
//   }));
// };

// export const getProductPhraseForProduct = productId =>
//   createSelector(getProductPhraseList, R.filter(R.propEq('ProductId', productId)));
