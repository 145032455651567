import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as userRoleTypes from './userRoleTypes';
import { LOGOUT } from '../user/userTypes';

const byId = handleActions(
  {
    [userRoleTypes.USER_ROLE_LIST_FOR_ROLES.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.userRoles,
    }),
    [LOGOUT]: () => ({}),
  },
  {},
);

const allIds = handleActions(
  {
    [userRoleTypes.USER_ROLE_LIST_FOR_ROLES.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
    [LOGOUT]: () => []
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
