import queryString from 'query-string';
import { uploadFiles, get, del } from './apiUtils';

const defaultUrl = '/CompaniesDocuments/';
const upload = '/File/';
const uploadNotificationData = '/NotificationDataFile/';

export default {
  uploadCompanyDocument: ({ CompanyId, data }) => uploadFiles(`${defaultUrl}${CompanyId}${upload}`, data),
  uploadCompanyNotificationData: ({ CompanyId, data }) => uploadFiles(`${defaultUrl}${CompanyId}${uploadNotificationData}`, data),
  getCompanyDocumentList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  deleteCompanyDocument: id => del(`${defaultUrl}${id}`),
};
