import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, Button, Collapse, Alert } from 'react-bootstrap';
import { css } from 'glamor';
import { Field, reduxForm } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import InputField from '../_Common/Forms/InputField';

const styles = {
  buttonPanel: css({
    textAlign: 'center',
  }),
};

const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = 'User name is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

const LoginForm = ({ handleSubmit, pristine, submitting, onLogin, isAuthenticating, errorDescription }) => (
  <form onSubmit={handleSubmit(onLogin)}>
    <Field name="username" label={I18n.t('login.username')} type="text" component={InputField} />
    <Field name="password" label={I18n.t('login.password')} type="password" component={InputField} />
    <hr />
    <FormGroup>
      <div className={styles.buttonPanel}>
        <Button type="submit" bsStyle="primary" disabled={pristine || submitting || isAuthenticating}>
          <i className={submitting || isAuthenticating ? 'fa fa-cog fa-spin' : 'fa fa-key'} />&nbsp;
          <Translate value="login.loginButton" />
        </Button>
      </div>
    </FormGroup>
    <Collapse in={errorDescription !== ''}>
      <Alert bsStyle="danger">
        {errorDescription}
      </Alert>
    </Collapse>
  </form>
  );

LoginForm.defaultProps = {
  errorDescription: '',
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  errorDescription: PropTypes.string,
};

export default reduxForm({
  form: 'loginForm',
  destroyOnUnmount: true,
  validate,
})(LoginForm);
