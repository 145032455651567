import { createSelector } from 'reselect';
import * as labelReviewSelectors from '../labelReview/labelReviewSelectors';

export const getById = ({ labelReviewClaim }) => labelReviewClaim.byId;
export const getIds = ({ labelReviewClaim }) => labelReviewClaim.list.allIds;
export const getLabelReviewClaim = (state, id) => getById(state)[id];
export const getLabelReviewClaimList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getLabelReviewClaimListForLabelReview = createSelector(
  [labelReviewSelectors.getLabelReview, getById],
  (labelReview, labelReviewClaimById) => {
    if (!labelReview) return [];
    return labelReview.LabelReviewClaims.map(labelReviewClaimId => labelReviewClaimById[labelReviewClaimId]);
  },
);

export const getLabelReviewClaimListFromLatestLabelReview = (state, productId) => {
  const latestLabelReview = labelReviewSelectors.getLatestLabelReviewForProduct(state, productId);
  if (!latestLabelReview) return [];
  return getLabelReviewClaimListForLabelReview(state, latestLabelReview.LabelReviewId);
};
