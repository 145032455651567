import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {isPristine, isSubmitting, reset, submit} from "redux-form";
import {productCategoryTRFormSelectors,productCategoryTRFormActions} from "../../../../redux/form/productCategoryTRForm";
import {referentialSelectors} from "../../../../redux/referentials";
import ProductCategoryTRModal from "./ProductCategoryTRModal";
import formNames from "../../../../constants/formNames";
import {applicationSelectors} from "../../../../redux/application";
import {productCategoryTRActions, productCategoryTRTypes} from "../../../../redux/productCategoryTR";

const formName = formNames.productCategoryTR;
const ProductCategoryTRModalContainer = props => (
  <ProductCategoryTRModal {...props}/>
);

const mapStateToProps = (state, {ProductCategoryId}) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const initialFormValues = productCategoryTRFormSelectors.getInitialValues(state, ProductCategoryId);
  const isFormPristine = isPristine(formName)(state);
  return {
    title: `${I18n.t('productCategoryTRFormModal.title')}`,
    initialValues: initialFormValues,
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isSaveDisabled: isFormSubmitting || isFormPristine,
    isLoading: applicationSelectors.isLoading(state, productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, {ProductCategoryId}) => {
  return ({
    onLoad: () => {
      dispatch(productCategoryTRActions.listProductCategoryTR.request({ ProductCategoryId }));
    },
    onSaveButtonClick: () => dispatch(submit(formName)),
    onSubmit: data => productCategoryTRFormActions.submitProductCategoryTRForm(data, dispatch),
    onCancelButtonClick: () => {
      dispatch(reset(formName));
    },
  });
};

ProductCategoryTRModalContainer.defaultProps = {
  categoryId: undefined,
};

ProductCategoryTRModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryTRModalContainer);
