import React from 'react';
import { connect } from 'react-redux';
import * as selectors from './selectors';
import ShadeXRmCompoList from './ShadeXRmCompoList';

const ShadeXRmCompoListContainer = props => <ShadeXRmCompoList {...props} />;

const mapStateToProps = (state, { productId }) => ({
  columns: selectors.makeGetColumnsForProduct(state)(productId),
  data: selectors.makeGetShadeRmCompoDataForProduct(state)(productId),
});


export default connect(mapStateToProps)(ShadeXRmCompoListContainer);
