import { takeLatest, put, all } from 'redux-saga/effects';
import { modalActions, modalTypes } from '../modal';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga } from '../sagaUtils';
import * as substanceProportionTypes from './substanceProportionTypes';
import * as substanceProportionActions from './substanceProportionActions';
import { substanceProportionListSchema } from './substanceProportionSchemas';
import substancePropotionApi from '../../api/substancePropotionApi';

export default function* root() {
  yield all([
    takeLatest(substanceProportionTypes.SUBSTANCE_PROPORTION_LIST.REQUEST, getSubstanceProportionListSaga),
    takeLatest(substanceProportionTypes.DELETE_SUBSTANCE_PROPORTION.REQUEST, deleteSubstanceProportionSaga),
    takeLatest(substanceProportionTypes.SAVE_SUBSTANCE_PROPORTION.REQUEST, saveSubstanceProportionSaga),
    takeLatest(
      [substanceProportionTypes.ADD_SUBSTANCE_PROPORTION, substanceProportionTypes.EDIT_SUBSTANCE_PROPORTION],
      onAddEditSubstanceProportion,
    ),
  ]);
}

export function* saveSubstanceProportionSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  // const { SubstanceId } = payload;
  yield callApiSaga(
    substanceProportionActions.saveSubstanceProportion,
    substancePropotionApi.saveSubstanceProportion,
    payload,
  );
  // yield put(substanceProportionActions.listSubstanceProportions.request());
  yield put(applicationActions.unsetLoading(type));
}

function* deleteSubstanceProportionSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { SubstanceProportionId } = payload;
  yield callApiSaga(
    substanceProportionActions.deleteSubstanceProportion,
    substancePropotionApi.deleteSubstanceProportion,
    SubstanceProportionId,
  );
  yield put(substanceProportionActions.listSubstanceProportions.request());
  yield put(applicationActions.unsetLoading(type));
}

function* getSubstanceProportionListSaga({ type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    substanceProportionActions.listSubstanceProportions,
    substancePropotionApi.getSubstanceProportionList,
    substanceProportionListSchema,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* onAddEditSubstanceProportion({ payload }) {
  yield put(modalActions.showModal({ modalType: modalTypes.SUBSTANCE_PROPORTION, modalProps: { ...payload } }));
}
