import React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { referentialSelectors } from '../../../redux/referentials';
import { substanceTypes } from '../../../redux/substance';
import CpsrArgumentsForm from './CpsrArgumentsForm';
import formNames from '../../../constants/formNames';

const CpsrArgumentsFormContainer = props => (
  <CpsrArgumentsForm {...props} />
);

const mapStateToProps = state => ({
  isLoading: applicationSelectors.isLoading(
    state,
    substanceTypes.SAVE_SUBSTANCE.REQUEST,
    substanceTypes.SUBSTANCE.REQUEST,
  ),
  impurityTypes: referentialSelectors.getImpurityTypes(state),
});

export default compose(
  reduxForm({
    form: formNames.substance,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
  connect(mapStateToProps, null),
)(CpsrArgumentsFormContainer);
