import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField, SelectField } from '../../../_Common/Forms';
import { requiredField } from '../../../../utils/fieldValidation';

const RawMatBasicFormSection = props => {
  const { rawMatSupplierList, readOnly } = props;
  return (
    <div>
      <Col sm={4}>
        <Field
          name="Code"
          label={`${I18n.t('general.code')} * 1`}
          component={InputField}
          readOnly={readOnly}
          validate={requiredField}
        />
      </Col>
      <Col sm={4}>
        <Field
          name="Name"
          label={`${I18n.t('general.name')} *`}
          component={InputField}
          readOnly={readOnly}
          validate={requiredField}
        />
      </Col>
      <Col sm={4}>
        <Field
          name="RawMatSupplierId"
          label={`${I18n.t('rawMat.supplier')} *`}
          component={SelectField}
          options={rawMatSupplierList}
          labelField="Name"
          valueField="RawMatSupplierId"
          disabled={readOnly}
          allowCreate
          validate={requiredField}
          useVirtualized
        />
      </Col>
    </div>
  );
};

RawMatBasicFormSection.defaultProps = {
  name: 'RawMat',
  readOnly: false,
};

RawMatBasicFormSection.propTypes = {
  rawMatSupplierList: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      RawMatSupplierId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  readOnly: PropTypes.bool,
};

export default RawMatBasicFormSection;
