import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import SubstanceActionsModal from './SubstanceActionsModal';
import { substanceSelectors } from '../../../../redux/substance';

const SubstanceActionsModalContainer = props => <SubstanceActionsModal {...props} />;

const mapStateToProps = (state, { substanceId }) => {
  const substance = substanceSelectors.getById(state)[substanceId];
  const substanceName = R.propOr('', 'InciName', substance);
  return {
    title: I18n.t('substanceActionsModal.title', { substanceName }),
  };
};

SubstanceActionsModalContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(SubstanceActionsModalContainer);
