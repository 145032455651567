import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {SelectionGrid, LinkCellRenderer} from '../../_Common/Grids';

const columns = [
  {
    headerName: I18n.t('rawMat.code'),
    field: 'Code',
    sort: 'asc',
  },
  {
    headerName: I18n.t('rawMat.name'),
    field: 'Name',
    cellRendererFramework: LinkCellRenderer,
    cellRendererParams: {
      textKey: 'Name',
      linkKey: 'Url',
      downloadable: false
    },
    suppressSizeToFit: true,
  },
  {
    headerName: I18n.t('rawMat.supplier'),
    width: 150,
    field: 'SupplierName',
  },
];

const SubstanceRawMatList = props => {
  
  props.rawMatList.forEach(element => {
    element.Url = '#rawmats/' + element.RawMatId;
  });


  const { rawMatList, isLoadingRawMatList, fetchRawMatList, onRowDoubleClicked } = props;
  return (
    <SelectionGrid
      data={rawMatList}
      keyName="RawMatId"
      columns={columns}
      isLoading={isLoadingRawMatList}
      onRefresh={fetchRawMatList}
      showAddButton={false}
      rowDoubleClicked={onRowDoubleClicked}
      // onSelected={onRawMatSelected}
    />
  );
};

SubstanceRawMatList.defaultProps = {
  rawMatList: [],
};

SubstanceRawMatList.propTypes = {
  rawMatList: PropTypes.arrayOf(
    PropTypes.shape({
      RawMatId: PropTypes.number.isRequired,
    }).isRequired,
  ),
  isLoadingRawMatList: PropTypes.bool.isRequired,
  fetchRawMatList: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
};

export default SubstanceRawMatList;
