import queryString from 'query-string';
import * as R from 'ramda';
import { createSelector } from 'reselect';

export const createMakeGetByQueryLoading = getLoadingSlice => query =>
  R.compose(
    R.propOr(false, queryString.stringify(query)),
    getLoadingSlice,
  );

export const createMakeGetByQuery = getByIdSlice => getByQueryIdsSlice =>
  createSelector([getByIdSlice, getByQueryIdsSlice], (byId, byQueryIds) =>
    createSelector(R.identity, query =>
      R.compose(
        R.filter(R.complement(R.isNil)),
        R.map(id => byId[id]),
        R.propOr([], queryString.stringify(query)),
      )(byQueryIds),
    ),
  );

export const createGeneralSelectors = getStateSlice => {
  const getLoading = state => getStateSlice(state).meta.loading;
  const getById = state => getStateSlice(state).byId;
  const getByQueryIds = state => getStateSlice(state).list.byQueryIds;
  const makeGetItemById = createSelector(id => id, id => createSelector(getById, byId => byId[id]));

  return {
    getById,
    getByQueryIds,
    getIds: state => getStateSlice(state).list.allIds,
    getLoading,
    makeGetItemById,
    makeGetByQueryLoading: createMakeGetByQueryLoading(getLoading),
    makeGetByQuery: createMakeGetByQuery(getById)(getByQueryIds),
  };
};

export const createAuthorizationCheck = createGetItemById => getAuthorizationCodes => requiredAuthorizationList =>
  createSelector(createGetItemById, getItem =>
    createSelector(
      getItem,
      R.compose(
        containsAtLeastOne(requiredAuthorizationList),
        getAuthorizationCodes,
      ),
    ),
  );

const containsAtLeastOne = requiredItemList => allItemsList =>
  requiredItemList.some(requiredItem => allItemsList.find(item => item === requiredItem) !== undefined);
