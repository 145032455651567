import PropTypes from 'prop-types';
import React from 'react';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { Translate as T } from 'react-redux-i18n';

const UserMenu = ({ userDetails, onLogout }) => {
  const Title = (
    <span><i className="fa fa-user" />&nbsp;{userDetails.UserName}</span>
  );
  return (
    <NavDropdown title={Title} id="basic-nav-dropdown">
      <MenuItem onClick={() => onLogout()}>
        <i className="fa fa-power-off" />&nbsp;<T value="userMenu.logout" />
      </MenuItem>
    </NavDropdown>
  );
};

UserMenu.propTypes = {
  userDetails: PropTypes.shape({
    UserName: PropTypes.string,
  }).isRequired,
  onLogout: PropTypes.func,
};

export default UserMenu;
