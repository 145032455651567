import queryString from 'query-string';
import { get, del, post } from './apiUtils';

const defaultUrl = '/ProductsCategories/';

export default {
  getProductCategoryListForProduct: productId => {
    const url = `${defaultUrl}/byProduct/${productId}`;
    return get(url);
  },
  deleteProductCategory: id => {
    del(`${defaultUrl}${id}`);
  },
  saveProductCategory: data => post(`${defaultUrl}`, data),
  markProductCategoryAsPrincipal: data => {
    return post(`${defaultUrl}/MarkAsPrincipal`, data)
  },
};
