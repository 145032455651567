import * as userSelectors from './userSelectors';
import * as userActions from './userActions';
import * as userTypes from './userTypes';
import * as userSchemas from './userSchemas';
import sagas from './userSagas';
import reducer from './userReducer';


import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { userSelectors, userActions, userTypes, userSchemas };
export default reducer;

