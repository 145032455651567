import {handleActions} from 'redux-actions';
import {combineReducers} from 'redux';
import {merge} from 'lodash';
import * as processTypeTypes from './processTypeTypes';

const byId = handleActions(
  {
    [processTypeTypes.PROCESSTYPE_LIST.SUCCESS]: (state, {payload}) =>
      merge({}, state, payload.entities.processTypes),
  },
  {},
);

const allIds = handleActions(
  {
    [processTypeTypes.PROCESSTYPE_LIST.SUCCESS]: (state, {payload}) => payload.result,
  },
  [],
);

const processTypeRefsById = handleActions(
  {
    [processTypeTypes.PROCESSTYPEREF_LIST.SUCCESS]: (state, {payload}) =>
      merge({}, state, payload.entities.processTypeRefs),
  },
  [],
);

const processTypeRefsAllIds = handleActions(
  {
    [processTypeTypes.PROCESSTYPEREF_LIST.SUCCESS]: (state, {payload}) => {
      console.log('RESULT', payload);
      return payload.result;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    processTypeRefsAllIds,
  }),
  processTypeRefsById,
});
