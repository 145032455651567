import React from 'react';
import { connect } from 'react-redux';
import { isDirty, formValueSelector, isInvalid, isSubmitting, submit } from 'redux-form';
import TaskTypeFormModal from './TaskTypeFormModal';
import { taskTypeTypes } from '../../../../redux/taskType';
import { applicationSelectors } from '../../../../redux/application';
import { referentialSelectors } from '../../../../redux/referentials';
import { taskTypeFormActions, taskTypeFormSelectors } from '../../../../redux/form/taskTypeForm';
import formNames from '../../../../constants/formNames';
import { processTypeActions } from '../../../../redux/processType';

const formName = formNames.taskTypeForm;
const formValueselector = formValueSelector(formName);

class TaskTypeFormContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <TaskTypeFormModal {...this.props} />;
  }
}

const mapStateToProps = (state, { TaskTypeId }) => ({
  isLoadingContent: applicationSelectors.isLoading(state, taskTypeTypes.SAVE_TASKTYPE.REQUEST),
  isSubmitting: isSubmitting(formName)(state),
  processTypeList: taskTypeFormSelectors.getProcessTypeList(state),
  regions: referentialSelectors.getRegions(state),
  currencies: referentialSelectors.getCurrencies(state),
  isDirty: isDirty(formName)(state),
  invalid: isInvalid(formName)(state),
  isInEditing: TaskTypeId != null,
  initialValues: taskTypeFormSelectors.getInitialValues(state)(TaskTypeId),
  processTypeId: formValueselector(state, 'ProcessTypeId'),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(processTypeActions.listProcessTypeRefs.request()),
  onSaveButtonClick: () => dispatch(submit(formName)),
  onProcessTypeSelected: processTypeId => dispatch(taskTypeFormActions.onProcessTypeSelected(processTypeId)),
  onAddButtonClicked: () => dispatch(taskTypeFormActions.onAddButtonClicked()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskTypeFormContainer);
