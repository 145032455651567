import { takeLatest, put, all, fork, select } from 'redux-saga/effects';
import { submit, reset } from 'redux-form';
import { formNameList, getDirtyForms } from './selectors';

import * as types from './types';

export default function* root() {
  yield all([
    takeLatest(types.SAVE_BUTTON_CLICK, saveButtonClickSaga),
    takeLatest(types.CANCEL_BUTTON_CLICK, cancelButtonClickSaga),
  ]);
}

function* saveButtonClickSaga() {
  const distryForms = yield select(getDirtyForms);
  yield distryForms.map(fNamep => fork(saveForm, fNamep));
}

function* saveForm(formName) {
  yield put(submit(formName));
}

function* resetForm(formName) {
  yield put(reset(formName));
}

function* cancelButtonClickSaga() {
  yield formNameList.map(fNamep => fork(resetForm, fNamep));
}
