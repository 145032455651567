import PropTypes from 'prop-types';
/**
 * Created by kna on 29-12-16.
 */
import React, { Component } from 'react';
import { css } from 'glamor';


class ColumnFlexBox extends Component {

  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.styles = {
      columnFlexBox: css({
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexFlow: 'column',
        '& .fit-to-content': {
          flex: '0 1 auto',
        },
        '& .fit-to-space': {
          display: 'block',
          flex: '1 1 100%',
        },
      }),
    };
  }

  render() {
    const { children } = this.props;

    return (
      <div className={this.styles.columnFlexBox}>
        {children}
      </div>
    );
  }
}

const FitToContentBox = ({ children }) => (
  <div className="fit-to-content">
    {children}
  </div>
);

FitToContentBox.propTypes = {
  children: PropTypes.node,
};

const FitToSpaceBox = ({ children }) => (
  <div className="fit-to-space">
    {children}
  </div>
);

FitToSpaceBox.propTypes = {
  children: PropTypes.node,
};

export { ColumnFlexBox, FitToContentBox, FitToSpaceBox };
