import React from 'react';
import { connect } from 'react-redux';
import LabelReviewWizardModal from './LabelReviewWizardModal';
import { applicationSelectors } from '../../../redux/application';
import { referentialSelectors, referentialsTypes } from '../../../redux/referentials';
import { labelReviewWizardSelectors, labelReviewWizardActions } from '../../../redux/wizard/labelReviewWizard';
import {
  labelReviewItemWizardSelectors,
  labelReviewItemWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewItemWizard';
import { labelReviewTypes } from '../../../redux/labelReview';

const LabelReviewWizardContainer = props => <LabelReviewWizardModal height="70vh" {...props} />;

const mapStateToProps = state => ({
    labelInfoAvailabilityCodes: referentialSelectors.getLabelInfoAvailabilityCodes(state),
    labelReview: labelReviewWizardSelectors.getLabelReview(state),
    labelReviewItemList: labelReviewItemWizardSelectors.getLabelReviewItemListWithDetails(state),
    isLoading: applicationSelectors.isLoading(
      state,
      labelReviewTypes.SAVE_LABEL_REVIEW.REQUEST,
      labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.REQUEST,
      referentialsTypes.ALL_REFERENTIALS.REQUEST,
    ),
  });

const mapDispatchToProps = dispatch => ({
  updateLabelReview: data => dispatch(labelReviewWizardActions.updateLabelReview(data)),
  updateLabelReviewItem: (id, data) => dispatch(labelReviewItemWizardActions.updateLabelReviewItem(id, data)),
  editLabelReviewItemComment: (id, data) => dispatch(labelReviewItemWizardActions.editComment(id, data)),
  resetLabelReviewItemComment: id => dispatch(labelReviewItemWizardActions.resetComment(id)),
  saveLabelReview: () => dispatch(labelReviewWizardActions.onSaveButtonClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewWizardContainer);
