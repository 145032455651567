import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Button, Collapse } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import { RTEField } from '../../_Common/Forms';
import HomePageFormViewer from './HomePageFormViewer';

class HomePageForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false
    }
  }
  render() {
    const { onSaveButtonClicked, isSavingForm, showHomePageForm } = this.props;
    return (
      <Grid fluid>
        <div>
          <HomePageFormViewer />
        </div>
        {showHomePageForm && (
          <div>
            <Button
              onClick={() => this.setState({ open: !this.state.open }) }
            >
              Click to toggle editor
            </Button>
            <Collapse in={this.state.open}>
              <div>
                <br />
                <Form>
                  <Field
                    name="HtmlContent"
                    label={I18n.t('site.memo')}
                    component={RTEField}
                  />
                  <Button
                    bsSize="small"
                    bsStyle="success"
                    onClick={onSaveButtonClicked}
                  >
                    <Translate value="general.save" />&nbsp;
                    {isSavingForm && (
                      <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                    )}
                  </Button>
                </Form>
              </div>
            </Collapse>
          </div>
        )}
      </Grid>
    );
  }
}

HomePageForm.defaultProps = {
  isSavingForm: false,
  showHomePageForm: false
};

HomePageForm.propTypes = {
  showHomePageForm: PropTypes.bool,
  onSaveButtonClicked: PropTypes.func.isRequired,
  isSavingForm: PropTypes.bool
};

export default HomePageForm;
