import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'react-bootstrap';
import { prop, propOr } from 'ramda';
import { css } from 'glamor';
import { connect } from 'react-redux';
import CheckBox from './_Common/CheckBox';
import Textarea from './_Common/Textarea';
import {
  labelReviewSymbolWizardSelectors,
  labelReviewSymbolWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewSymbolWizard';
import { labelSymbolSelectors } from '../../../redux/labelSymbol';
import LabelSymbol from './LabelSymbol';

const styles = {
  labelReviewSymbolItem: css({
    '& textarea.input-sm': {
      height: '60px',
    },
    '& img': {
      width: '40px',
      height: '40px',
    },
  }),
  veticalAlign: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

const LabelReviewSymbolItem = props => (
  <div className={styles.labelReviewSymbolItem}>
    <Col sm={5}>
      <div className={styles.veticalAlign}>
        <Col sm={4}>
          <CheckBox
            checked={props.IsOnLabel}
            text="On the label"
            onChange={({ target }) => props.handleFieldEdit({ IsOnLabel: target.checked })}
          />
        </Col>
        <Col sm={8}>
          <LabelSymbol code={props.SymbolCode} />
        </Col>
      </div>
    </Col>
    <Col sm={1}>
      <CheckBox
        checked={props.IsOk}
        inline={false}
        text="Ok"
        onChange={({ target }) => props.handleFieldEdit({ IsOk: target.checked })}
      />
      <CheckBox
        checked={props.HasCorrectiveActions}
        inline={false}
        text="Rem."
        onChange={({ target }) => props.handleFieldEdit({ HasCorrectiveActions: target.checked })}
      />
    </Col>
    <Col sm={6}>
      <Col sm={11}>
        <Textarea text={props.CorrectiveActions} fieldName="CorrectiveActions" onChange={props.handleFieldEdit} />
      </Col>
      {props.IsPublic && (
        <Col sm={1}>
          <Button onClick={() => props.handleRevertButtonClick(props.SymbolCode)} bsSize="small">
            <i className="fa fa-undo" aria-hidden="true" />
          </Button>
        </Col>
      )}
      {!props.IsPublic && (
        <Col sm={1}>
          <Button onClick={props.handleDeleteButtonClick} bsSize="small" bsStyle="danger">
            <i className="fa fa-times" aria-hidden="true" />
          </Button>
        </Col>
      )}
    </Col>
  </div>
);

LabelReviewSymbolItem.defaultProps = {
  IsOnLabel: undefined,
  IsOk: undefined,
  HasCorrectiveActions: undefined,
  CorrectiveActions: undefined,
};

LabelReviewSymbolItem.propTypes = {
  IsOnLabel: PropTypes.bool,
  IsOk: PropTypes.bool,
  HasCorrectiveActions: PropTypes.bool,
  handleFieldEdit: PropTypes.func.isRequired,
  CorrectiveActions: PropTypes.string,
  SymbolCode: PropTypes.string.isRequired,
  handleRevertButtonClick: PropTypes.func.isRequired,
  handleDeleteButtonClick: PropTypes.func.isRequired,
  IsPublic: PropTypes.bool.isRequired,
};

const mapStateToProps = (_, ownProps) => {
  const { labelReviewSymbolId } = ownProps;
  return state => {
    const labelReviewSymbol = labelReviewSymbolWizardSelectors.getLabelReviewSymbol(state, labelReviewSymbolId);
    const labelSymbol = labelSymbolSelectors.getLabelSymbol(state, labelReviewSymbol.SymbolCode);
    return {
      IsOnLabel: prop('IsOnLabel', labelReviewSymbol),
      IsOk: prop('IsOk', labelReviewSymbol),
      HasCorrectiveActions: prop('HasCorrectiveActions', labelReviewSymbol),
      CorrectiveActions: prop('CorrectiveActions', labelReviewSymbol),
      SymbolCode: prop('SymbolCode', labelReviewSymbol),
      IsPublic: propOr(false, 'Public', labelSymbol),
    };
  };
};

const mapDispatchToProps = (_, ownProps) => {
  const { labelReviewSymbolId } = ownProps;
  return dispatch => ({
    handleFieldEdit: data => dispatch(labelReviewSymbolWizardActions.handleFieldEdit(labelReviewSymbolId, data)),
    handleDeleteButtonClick: () =>
      dispatch(labelReviewSymbolWizardActions.handleDeleteButtonClick(labelReviewSymbolId)),
    handleRevertButtonClick: symbolCode =>
      dispatch(labelReviewSymbolWizardActions.handleRevertButtonClick({ symbolCode, labelReviewSymbolId })),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LabelReviewSymbolItem);
