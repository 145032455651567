import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Checkbox } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

const CheckBoxCellRenderer = (props) => {
  const { data, onChange } = props
  return (
  <Row className='align-items-center'>
    <Col>
    <Checkbox
      checked={data.checked}
      inline={data.inline}
      // onChange={({ target }) => props.handleFieldEdit({ IsOk: target.checked })}
      onChange={(item) => {
        console.log(item)
        data.checked = item.currentTarget.checked;
        onChange(data)
      }}
    />
    </Col>
  </Row>)
};

CheckBoxCellRenderer.defaultProps = {
};

CheckBoxCellRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
};

export default CheckBoxCellRenderer;
