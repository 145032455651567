import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import orderActionApi from '../../api/orderActionApi';
import * as orderActionTypes from './orderActionTypes';
import * as orderActionSchemas from './orderActionSchemas';
import * as orderActionActions from './orderActionActions';

export default function* root() {
  yield all([takeLatest(orderActionTypes.FETCH_ORDER_ACTIONS.REQUEST, getOrderActionsSaga)]);
}

export function* getOrderActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const orderActionList = yield call(orderActionApi.getOrderActions, payload);
    const normalizedData = normalize(orderActionList, orderActionSchemas.orderActionListSchema);
    yield put(orderActionActions.fetchOrderActions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(orderActionActions.fetchOrderActions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
