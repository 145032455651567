import { createSelector } from 'reselect';

export const getById = ({ rawMatPhrase }) => rawMatPhrase.byId;
// export const getIds = ({ rawMatPhrase }) => rawMatPhrase.list.allIds;
// export const getRawMatPhraseList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getRawMatPhraseList = createSelector(getById, rawMatPhraseById => Object.values(rawMatPhraseById));
export const getRawMatPhrase = (state, id) => state.rawMatPhrase.byId[id];

const getRawMatPhrasesForRawMat = rawMatId =>
  createSelector(getRawMatPhraseList, rawMatPhraseList =>
    rawMatPhraseList.filter(rawMatPhrase => rawMatPhrase.RawMatId === rawMatId),
  );

export const makeGetRawMatPhrasesForRawMat = createSelector(
  getRawMatPhrasesForRawMat,
  rawMatPhrasesForRawMat => rawMatPhrasesForRawMat,
);
