import { createRequestActions, createFetchAction } from '../utils';
import * as rawMatProportionTypes from './rawMatProportionTypes';

export const addRawMatProportionsFromRawMat = createRequestActions(
  rawMatProportionTypes.ADD_RAWMAT_PROPORTIONS_FROM_RAWMAT,
);
export const deleteRawMatProportion = createRequestActions(rawMatProportionTypes.DELETE_RAWMAT_PROPORTION);
export const saveRawMatProportions = createRequestActions(rawMatProportionTypes.SAVE_RAWMAT_PROPORTIONS);
export const fetchRawMatProportions = createFetchAction(
  rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS,
  'RawMatProportionId',
);
