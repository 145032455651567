import { handleActions } from 'redux-actions';
import { merge } from 'lodash';
import * as dashboardTypes from './dashboardTypes';

export const initialState = {
  dashboardList: [],
  currentDashboard: {},
  byId: {},
  list: {
    allIds: [],
  },
  dashboardResults: [],
};

export default handleActions(
  {
    [dashboardTypes.DASHBOARD_RESULT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      dashboardResults : payload.result,
    }),
    [dashboardTypes.DASHBOARD_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.dashboards),
      list: {
        ...state.list,
        allIds: payload.result,
      },
    }),
    [dashboardTypes.DASHBOARD.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.dashboards),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, payload.result])],
      },
    }),
  },
  initialState,
);
