import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as R from 'ramda';
import queryString from 'query-string';
import { getBaseType } from '../../utils';
import * as formulaReviewWizardTypes from './formulaReviewWizardTypes';
import complianceReviewWizardReducer from './complianceReviewWizard';
import productDataReviewWizardReducer from './productDataReviewWizard';
import rawMatDataReviewWizardReducer from './rawMatDataReviewWizard';

const formulaReviewReducer = handleActions(
  {
    [formulaReviewWizardTypes.LOAD_FORMULA_REVIEW_DATA]: (state, { payload }) => ({
      ...payload,
    }),
    [formulaReviewWizardTypes.UPDATE_FORMULA_REVIEW]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

const loading = handleActions(
  {
    [combineActions(
      formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.REQUEST,
      formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.SUCCESS,
      formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.REQUEST ? payload : meta;
      const querystring = `${getBaseType(type)}/${queryString.stringify(R.omit(['include'])(query))}`;
      return {
        ...state,
        [querystring]: type === formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.REQUEST,
      };
    },
    [combineActions(
      formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST,
      formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.SUCCESS,
      formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST ? payload : meta;
      const querystring = `${getBaseType(type)}/${queryString.stringify(R.omit(['include'])(query))}`;
      return {
        ...state,
        [querystring]: type === formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST,
      };
    },
  },
  {},
);

export default combineReducers({
  complianceReviews: complianceReviewWizardReducer,
  productDataReview: productDataReviewWizardReducer,
  rawMatDataReviews: rawMatDataReviewWizardReducer,
  formulaReview: formulaReviewReducer,
  meta: combineReducers({
    loading,
  }),
});
