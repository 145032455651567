import { createSelector } from 'reselect';
import { taskTypeSelectors } from '../../taskType';
import { isNullOrUndef } from '../../../utils/utils';
import { processTypeSelectors } from '../../processType';
import * as R from 'ramda';

export const getProcessTypeList = createSelector(processTypeSelectors.getProcessTypeList, list => list);

export const getInitialValues = createSelector(
  [taskTypeSelectors.getById, processTypeSelectors.getById],
  (taskTypeById, processTypeById) =>
    createSelector(R.identity, taskTypeId => {
      const taskType = taskTypeById[taskTypeId];
      const isInEditMode = !isNullOrUndef(taskType);
      if (!isInEditMode) {
        return {};
      }
      taskType.ProcessType = processTypeById[taskType.ProcessTypeId];
      return taskType;
    }),
);
