import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, MenuItem, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LanguageSelector from './LanguageSelector';
import UserMenu from './UserMenu';

import TooltipWrapper from '../_Common/TooltipWrapper';

const TooltipLinkContainer = ({ tooltip, iconClass, ...other }) => (
  <LinkContainer {...other}>
    <NavItem>
      <TooltipWrapper text={tooltip} place="right" effect="solid">
        <i className={iconClass} />
      </TooltipWrapper>
    </NavItem>
  </LinkContainer>
);

TooltipLinkContainer.propTypes = {
  tooltip: PropTypes.string,
  iconClass: PropTypes.string,
};

const Header = props => (
  <Navbar fluid fixedTop inverse>
    <Navbar.Header>
      <Navbar.Brand>
        <a href="#/home" style={{ fontSize: 25 }}>
          Calmos3
        </a>
        {process.env.REACT_APP_ENV != 'prod' ? (
          <span style={{ color: 'Tomato', fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;Test environment</span>
        ) : (
          ''
        )}
      </Navbar.Brand>
      <Navbar.Toggle />
    </Navbar.Header>
    <Nav pullRight>
      <LanguageSelector {...props} />
      <UserMenu {...props} />
      <Dropdown id={'DropDownMenu'}>
        <Dropdown.Toggle noCaret style={{ background: '#566998', border: '0px' }}>
          <i className="fa fa-bars" aria-hidden="true" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem eventKey="1" href="#/categoriesMgnt">
            Categories
          </MenuItem>
          <MenuItem eventKey="2" href="#/cosAnnexesMgnt">
            Cos Annexes
          </MenuItem>
          <MenuItem eventKey="3" href="#/toxProfileStructureMgnt">
            Tox Profile Structure
          </MenuItem>
          <MenuItem eventKey="4" href="#/processTemplateMgnt">
            Process Templates
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  </Navbar>
);

export default Header;
