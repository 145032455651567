import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
// import { enableRipple } from '@syncfusion/ej2-base';
// import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { SelectionGrid } from '../_Common/Grids';

// enableRipple(true);

class ToxProfileStructure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      structure: [],
      treeData: [{ title: 'Chicken', expanded: true, children: [{ title: 'Egg' }] }],
      team: [
        {
          id: 1,
          name: '??????????? ASP.NET MVC Team',
          expanded: true,
          child: [
            { id: 2, pid: 1, name: 'Smith' },
            { id: 3, pid: 1, name: 'Johnson', isSelected: true },
            { id: 4, pid: 1, name: 'Anderson' },
          ],
        },
        {
          id: 5,
          name: 'Windows Team',
          child: [
            { id: 6, pid: 5, name: 'Clark' },
            { id: 7, pid: 5, name: 'Wright' },
            { id: 8, pid: 5, name: 'Lopez' },
          ],
        },
        {
          id: 9,
          name: 'Web Team',
          child: [
            { id: 11, pid: 9, name: 'Joshua' },
            { id: 12, pid: 9, name: 'Matthew' },
            { id: 13, pid: 9, name: 'David' },
          ],
        },
        {
          id: 14,
          name: 'Build Team',
          child: [
            { id: 15, pid: 14, name: 'Ryan' },
            { id: 16, pid: 14, name: 'Justin' },
            { id: 17, pid: 14, name: 'Robert' },
          ],
        },
        {
          id: 18,
          name: 'WPF Team',
          child: [
            { id: 19, pid: 18, name: 'Brown' },
            { id: 20, pid: 18, name: 'Johnson' },
            { id: 21, pid: 18, name: 'Miller' },
          ],
        },
      ],
    };
  }

  componentDidMount() {}

  nodeDropped(event) {
    // alert(event.draggedNode);
    console.log(
      `${event.draggedNodeData.id
      }  idx: ${
        event.dropIndex
      }  lvl: ${
        event.dropLevel
      }  ord: ${
        event.draggedNodeData.Order}`,
    );
  }

  render() {
    const fields = {
      dataSource: this.props.treeStructure,
      id: 'id',
      parentID: 'pid',
      text: 'name',
      hasChildren: 'hasChild',
      selected: 'isSelected',
    };

    const defaultColumns = {
      editable: ({ node }) => node.group,
    };

    const columns = [
      {
        headerName: 'Code',
        field: 'id',
        cellRenderer: 'group',
        width: 200,
        minWidth: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Name',
        field: 'name',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        width: 140,
      },
      {
        headerName: 'Order',
        field: 'order',
        editable: true,
        width: 140,
        sort: 'asc',
      },
    ];

    const getNodeChildDetails = rowItem => {
      if (rowItem.child.length > 0) {
        return {
          group: true,
          expanded: true,
          children: rowItem.child,
          key: rowItem.id,
        };
      }
      return null;
    };

    return (
      <div>
        <span>Tox Profile Structure</span>
        &nbsp;&nbsp;
        <span>
          <Button
            bsSize="small"
            bsStyle="default"
            type="button"
            // onClick={onStartOrder}
            // disabled={!isOrderEditable}
          >
            <i className="fa fa-files-o" />
            &nbsp;
            <Translate value="substanceToxProfile.addItem" />
          </Button>
        </span>
        {/* <SortableTree treeData={this.state.treeData}
          onChange={treeData => this.setState({ treeData })}
        /> */}
        <SelectionGrid
          data={this.props.treeStructure}
          columns={columns}
          // isLoading={isLoadingRawMatCompositionList}
          // onRefresh={getRawMatProportions}
          getNodeChildDetails={getNodeChildDetails}
          keyName="Code"
          // editType="fullRow"
          defaultColDef={defaultColumns}
          stopEditingWhenGridLosesFocus
          // onAdd={onAdd}
          // addDisabled={isAddDisabled || !isRawMatCompositionEditable}
          deltaRowDataMode={false}
        />
        {/* <TreeViewComponent fields={fields} allowDragAndDrop nodeDropped={this.nodeDropped} /> */}
      </div>
    );
  }
}

ToxProfileStructure.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default ToxProfileStructure;
