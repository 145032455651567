import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import EditInciListModal from './EditInciListModal';
import { applicationSelectors } from '../../../../redux/application';
import { productSelectors } from '../../../../redux/product';
import { inciListSelectors, inciListTypes } from '../../../../redux/inciList';
import formNames from '../../../../constants/formNames';

const formName = formNames.editInciListForm;
const isAnyTrue = R.any(R.equals(true));

const EditListModalContainer = props => <EditInciListModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName')(product);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  const calculatedInciList = inciListSelectors.makeGetInciListForProduct(state)(productId);
  return {
    title: I18n.t('editInciListModal.title', { productName }),
    calculatedInciTextUpperPart: R.pathOr('', ['InciText', 'UpperPart'], calculatedInciList),
    calculatedInciTextLowerPart: R.pathOr('', ['InciText', 'LowerPart'], calculatedInciList),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    isFormSubmitting,
    isLoadingInciList: applicationSelectors.isLoading(state, inciListTypes.INCI_LIST_FOR_PRODUCT.REQUEST),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditListModalContainer);
