import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as workItemTypes from './workItemTypes';
import queryString from 'query-string';

const byId = handleActions(
  {
    [workItemTypes.WORK_ITEM_LIST.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.workItems),
  },
  {},
);

const allIds = handleActions(
  {
    [workItemTypes.WORK_ITEM_LIST.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
  },
  [],
);

const byQueryIds = handleActions(
  {
    [workItemTypes.WORK_ITEM_LIST.SUCCESS]: (state, { payload, meta = {} }) => {
      const queryStr = queryString.stringify(meta);
      return {
        ...state,
        [queryStr]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
});
