import { createAction } from 'redux-actions';
import * as types from './types';

export const saveButtonClick = ({ productId, cpsrTypeCode, formName }) =>
  createAction(types.SAVE_BUTTON_CLICK, selectedProductId => ({
    currentProductId: productId,
    selectedProductId,
    cpsrTypeCode,
    formName,
  }));
