import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FormControl, InputGroup, Button, FormGroup, Radio } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Translate } from 'react-redux-i18n';
import Loader from 'react-loader-advanced';
import AutoHeight from '../AutoHeight';

class CustomFilerGrid extends Component {
  static propTypes = {
    data: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    onSelected: PropTypes.func,
    onRefresh: PropTypes.func,
    isLoading: PropTypes.bool,
    selectedId: PropTypes.string,
    columns: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    keyName: PropTypes.string,
    showAddButton: PropTypes.bool,
    onAdd: PropTypes.func,
    showFilterRow: PropTypes.bool,
    radioFilter: PropTypes.func,
    radioButtons: PropTypes.array, // eslint-disable-line
    rowDoubleClicked: PropTypes.func,
  };

  static defaultProps = {
    showFilterRow: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      radioFilterState: this.props.radioButtons ? this.getDefaultCheckedRadioButton(this.props.radioButtons) : '',
    };

    this.rowChanged = this.rowChanged.bind(this);
    this.setFilterText = this.setFilterText.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.gridReady = this.gridReady.bind(this);
    this.externalFilterChanged = this.externalFilterChanged.bind(this);
    this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
    this.getDefaultCheckedRadioButton = this.getDefaultCheckedRadioButton.bind(this);
    this.rowDoubleClicked = this.rowDoubleClicked.bind(this);
  }

  componentDidUpdate() {
    const { selectedId } = this.props;
    const { keyName } = this.props;

    if (selectedId) {
      this.grid.api.forEachNode((node, index) => {
        if (node.data[keyName].toString() === selectedId) {
          node.setSelected(true);
          this.grid.api.ensureIndexVisible(index);
        }
      });
    }
  }

  setFilterText(filter) {
    this.setState({
      filter,
    });
    if (this.grid) {
      this.grid.api.setQuickFilter(filter);
    }
  }

  getDefaultCheckedRadioButton(radioButtons) {
    const value = radioButtons.find(r => r.default === true);
    if (typeof value === 'undefined') {
      return '';
    }
    return value.value;
  }

  isExternalFilterPresent() {
    // return this.state.radioFilterState !== 'all';
    return true;
  }

  doesExternalFilterPass(node) {
    return this.props.radioFilter(node, this.state.radioFilterState);
    // switch (this.state.orderType) {
    //   case 'withEndDate':
    //     return node.data.EndDate !== null;
    //   case 'withoutEndDate':
    //     return node.data.EndDate === null;
    //   default:
    //     return true;
    //   }
  }

  externalFilterChanged(newValue) {
    this.setState({ radioFilterState: newValue }, () => this.grid.api.onFilterChanged());
  }

  rowChanged() {
    if (this.grid) {
      this.grid.api.sizeColumnsToFit();
    }
  }

  rowClicked(e) {
    const { keyName, selectedId } = this.props;
    if (this.props.onSelected) {
      this.props.onSelected(e.data[keyName]);
    }

    // WARN : Be carefull with the following lines
    // keep selection in sync with route param, after leaving route when form is dirty
    if (e.data[keyName] !== selectedId) {
      // This line will force refresh of the grid after current js execution loop
      // It will then execute the componentDidUpdate method
      //  that will sync the selection with route params
      setTimeout(() => this.setState({}), 0);
    }
  }

  rowDoubleClicked(e) {
    if (this.props.rowDoubleClicked) {
      this.props.rowDoubleClicked(e.data);
    }
  }

  gridReady(g) {
    this.grid = g;
    this.grid.api.sizeColumnsToFit();
  }

  componentStateChanged = () => {
    this.grid.api.sizeColumnsToFit();
  };

  render() {
    const loading = '<span style="padding: 10px;"><i class="fa fa-refresh fa-4x fa-spin"/></span>';
    const { data, isLoading, onRefresh, columns, showAddButton, onAdd, showFilterRow, radioButtons } = this.props;

    return (
      <div className="ag-blue">
        {showFilterRow ? (
          <FormGroup>
            <InputGroup>
              <FormControl
                type="text"
                value={this.state.filter}
                onChange={e => this.setFilterText(e.target.value)}
                placeholder="Filter..."
              />
              <InputGroup.Button>
                <ReactTooltip id="filterTip" place="bottom" type="dark" effect="solid">
                  <Translate value="selectionGrid.clearFilter" />
                </ReactTooltip>
                <Button onClick={() => this.setFilterText('')} bsStyle="default" data-for="filterTip" data-tip>
                  <i className="fa fa-remove" />
                </Button>
                <ReactTooltip id="refreshTip" place="bottom" type="dark" effect="solid">
                  <Translate value="selectionGrid.refreshData" />
                </ReactTooltip>
                <Button onClick={onRefresh} bsStyle="primary" data-for="refreshTip" data-tip>
                  <i className="fa fa-refresh" />
                </Button>
                {showAddButton ? (
                  <ReactTooltip id="addTip" place="bottom" type="dark" effect="solid">
                    <Translate value="selectionGrid.add" />
                  </ReactTooltip>
                ) : null}
                {showAddButton ? (
                  <Button onClick={onAdd} bsStyle="success" data-for="addTip" data-tip>
                    <i className="fa fa-plus" />
                  </Button>
                ) : null}
              </InputGroup.Button>
            </InputGroup>
          </FormGroup>
        ) : null}
        {radioButtons ? (
          <FormGroup onChange={e => this.externalFilterChanged(e.target.value)}>
            {radioButtons.map(r => (
              <Radio
                inline
                name="radioFilter"
                key={r.value}
                value={r.value}
                checked={this.state.radioFilterState === r.value}
                readOnly
              >
                {r.label}
              </Radio>
            ))}
          </FormGroup>
        ) : null}

        {/* <FormGroup onChange={e => this.externalFilterChanged(e.target.value)}> */}
        {/* <Radio inline name="OrderFilter" value="all" checked={this.state.orderType === 'all'} readOnly> */}
        {/* Show all </Radio> */}
        {/* <Radio inline name="OrderFilter" value="withEndDate" checked={this.state.orderType === 'withEndDate'} readOnly > Below */}
        {/* Show only if with end date </Radio> */}
        {/* <Radio inline name="OrderFilter" value="withoutEndDate" checked={this.state.orderType === 'withoutEndDate'} readOnly> Below */}
        {/* Show only if without end date </Radio> */}
        {/* </FormGroup> */}

        <Loader
          show={isLoading}
          contentBlur={1}
          message={
            <span>
              <i className="fa fa-refresh fa-spin fa-4x" />
            </span>
          }
        >
          <AutoHeight externalUsedHeight={138}>
            <AgGridReact
              rowData={data}
              onRowClicked={this.rowClicked}
              columnDefs={columns}
              onGridReady={this.gridReady}
              // onComponentStateChanged={this.componentStateChanged}
              // onModelUpdated={this.rowChanged}
              onGridSizeChanged={this.rowChanged}
              onCellValueChanged={this.cellValueChanged}
              // rowSelected={this.rowSelected}
              onRowDoubleClicked={this.rowDoubleClicked}
              rowSelection="single"
              enableSorting
              enableFilter
              suppressCellSelection
              suppressNoRowsOverlay
              overlayLoadingTemplate={loading}
              isExternalFilterPresent={this.isExternalFilterPresent}
              doesExternalFilterPass={this.doesExternalFilterPass}
              {...this.props}
            />
          </AutoHeight>
        </Loader>
      </div>
    );
  }
}

export default CustomFilerGrid;
