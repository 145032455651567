import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { referentialSelectors } from '../../../redux/referentials';
import { SelectField } from '../Forms';

const DirectiveStatusTypesDropdown = props => {
  const { directiveStatusList } = props;
  return <SelectField options={directiveStatusList} labelField="Value" valueField="Code" {...props} />;
};

const mapStateToProps = state => ({
  directiveStatusList: referentialSelectors.getDirectiveStatusTypes(state),
});

DirectiveStatusTypesDropdown.propTypes = {
  directiveStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps, null)(DirectiveStatusTypesDropdown);
