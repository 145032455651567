import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

const EditDeleteButtonRenderer = props => {
  const { onDelete, node, onEdit, data, isDeleteDisable, isEditDisable } = props;
  return (
    <div style={{ textAlign: 'center', widht: '100%' }}>
      {node.level === 0 && (
        <ButtonToolbar style={{ textAlign: 'center', widht: '100%' }}>
          <EditButton onClick={() => onEdit(data)} disabled={isEditDisable} {...props} />
          <DeleteButton onClick={() => onDelete(data)} disabled={isDeleteDisable} {...props} />
        </ButtonToolbar>
      )}
    </div>
  );
};

EditDeleteButtonRenderer.defaultProps = {
  node: {},
  isDeleteDisable: false,
  isEditDisable: false,
};

EditDeleteButtonRenderer.propTypes = {
  node: PropTypes.shape({
    level: PropTypes.number,
  }).isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDeleteDisable: PropTypes.bool,
  isEditDisable: PropTypes.bool,
};

export default EditDeleteButtonRenderer;
