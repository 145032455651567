import React from 'react';
import { Route, IndexRedirect, IndexRoute, Router } from 'react-router';

import { userSelectors } from './redux/user';

// Containers
import { AppShellContainer } from './components/App';
import { LoginPageContainer } from './components/Login';
import { ControlCenterPageContainer } from './components/ControlCenter';
import { HomePageContainer } from './components/Home';
import { Orders, OrderDetails } from './components/Orders';
import { CompaniesPageContainer, CompanyDetailsContainer } from './components/Companies';
import { Distributors, DistributorDetails } from './components/Distributors';
import { Phrases } from './components/Phrases';
import { Categories } from './components/Categories';
import { CosAnnexes } from './components/CosAnnexes';
import { ToxProfileStructurePageContainer } from './components/ToxProfileStructure';
import { ProcessTemplateContainer } from './components/ProcessTemplate';
import { Products, ProductDetails } from './components/Products';
import { Ingredients, IngredientDetails } from './components/Ingredients';
import { Substances, SubstanceDetails } from './components/Substances';
import { RawMats, RawMatDetails } from './components/RawMats';
import RawMatCompositionContainer from './components/RawMats/RawMatComposition';
import { DashboardsPageContainer, DashboardDetailsContainer } from './components/Dashboards';
import { SafecosmeticDashboard } from './components/SafecosmeticDashboard';
import { GeneralDashboard } from './components/GeneralDashboard';
import { ImpactAssessments, ImpactAssessmentDetails } from './components/ImpactAssessments';
import { StatPageContainer } from './components/Stats';
import { Errors } from './components/Errors';
import { More } from './components/More';
import { PlannerDashboardContainer } from './components/PlannerDashboard';
import { UserPlannerContainer } from './components/Planner/UserPlannerPage';
import SessionTimeout from './components/App/SessionTimeout';
// import GeneralDashboardProductList from './components/GeneralDashboard/GeneralDashboard2/GeneralDashboardProductList';

export const getRoutes = store => {
  const state = store.getState();
  const authRequired = (nextState, replace) => {
    // Now you can access the store object here.
    if (!userSelectors.isAuthenticated(state)) {
      // Not authenticated, redirect to login.
      replace('/login');
    }
  };

  // <Route
  // path="/products"
  // component={props => <ProtectedRoute requiredActionCodes={['test']} component={Products} />}/>

  return (
    <Router>
      <Route path="/">
        <IndexRedirect to="/login" />
        <Route path="/login" component={LoginPageContainer} />
        <Route onEnter={authRequired} component={AppShellContainer}>
          <Route path="/controlCenter" component={ControlCenterPageContainer} />
          <Route path="/home" component={HomePageContainer} />
          {/* <Route path="/products">
            <SessionTimeout ComposedClass={Products}>
              <Route path="/products/:id">
                <SessionTimeout ComposedClass={ProductDetails} /> 
              </Route>
            </SessionTimeout>
          </Route> */}
          <Route path="/products" component={Products}>
            <Route path="/products/:id" component={ProductDetails} />
          </Route>
          <Route path="/ingredients" component={Ingredients}>
            <Route path="/ingredients/:id" component={IngredientDetails} />
          </Route>
          <Route path="/substances" component={Substances}>
            <Route path="/substances/:id" component={SubstanceDetails} />
          </Route>
          <Route path="/companies" component={CompaniesPageContainer}>
            <Route path="/companies/:id" component={CompanyDetailsContainer} />
          </Route>
          <Route path="/orders" component={Orders}>
            <Route path="/orders/:id" component={OrderDetails} />
          </Route>
          <Route path="/distributors" component={Distributors}>
            <Route path="/distributors/:id" component={DistributorDetails} />
          </Route>
          <Route path="rawmats" component={RawMats}>
            <Route path=":id" component={RawMatDetails}>
              <IndexRoute component={RawMatCompositionContainer} />
              <Route path="composition" component={RawMatCompositionContainer} />
              <Route path="phrases" component={() => <p>phrases</p>} />
            </Route>
          </Route>
          <Route path="/phrases" component={Phrases} />
          <Route path="/more" component={More} />
          <Route path="/dashboard" component={DashboardsPageContainer}>
            <Route path="/dashboard/:id" component={DashboardDetailsContainer} />
          </Route>
          <Route path="/stats" component={StatPageContainer} />
          <Route path="/errors" component={Errors} />
          <Route path="/generalDashboard" component={GeneralDashboard} />
          <Route path="/categoriesMgnt" component={Categories} />
          <Route path="/safecosmeticDashboard" component={SafecosmeticDashboard} />
          <Route path="/cosAnnexesMgnt" component={CosAnnexes} />
          <Route path="/toxProfileStructureMgnt" component={ToxProfileStructurePageContainer} />
          <Route path="/processTemplateMgnt" component={ProcessTemplateContainer} />
          <Route path="/impactAssessments" component={ImpactAssessments}>
            <Route path="/impactAssessments/:id" component={ImpactAssessmentDetails} />
          </Route>
          <Route path="/planner" component={UserPlannerContainer} />
          <Route path="/plannerDashboard" component={PlannerDashboardContainer} />
        </Route>
      </Route>
    </Router>
  );
};

export default getRoutes;
