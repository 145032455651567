import { createRequestTypes } from '../utils';

export const SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR = createRequestTypes(
  'companyDistributorAgreement/SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR',
);
// export const LIST_COMPANY_DISTRIBUTOR_AGREEMENTS = createRequestTypes(
//   'companyDistributorAgreement/LIST_COMPANY_DISTRIBUTOR_AGREEMENTS',
// );
export const AGREEMENT_LIST_FOR_DISTRIBUTOR = createRequestTypes(
  'companyDistributorAgreement/AGREEMENT_LIST_FOR_DISTRIBUTOR',
);
export const SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY = createRequestTypes(
  'companyDistributorAgreement/SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY',
);
export const DOWNLOAD_AGREEMENT = createRequestTypes('companyDistributorAgreement/DOWNLOAD_AGREEMENT');
export const MARK_AS_SENT_AGREEMENT = createRequestTypes('companyDistributorAgreement/MARK_AS_SENT_AGREEMENT');
export const MARK_AS_SIGNED_AGREEMENT = createRequestTypes('companyDistributorAgreement/MARK_AS_SIGNED_AGREEMENT');
export const REVOKE_AGREEMENT = createRequestTypes('companyDistributorAgreement/REVOKE_AGREEMENT');
export const REMOVE_AGREEMENT = createRequestTypes('companyDistributorAgreement/REMOVE_AGREEMENT');
export const FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY = createRequestTypes(
  'companyDistributorAgreement/FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY',
);
export const FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR = createRequestTypes(
  'companyDistributorAgreement/FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR',
);
