import { createSelector } from 'reselect';

export const getById = ({ substanceToxProfile }) => substanceToxProfile.byId;
export const getSubstanceToxProfleList = createSelector(getById, substanceToxProfileById =>
  Object.values(substanceToxProfileById),
);

export const getSubstanceToxProfile = (state, id) => state.substanceToxProfile.byId[id];

const getSubstanceToxProfilesForSubstance = substanceId =>
  createSelector(getSubstanceToxProfleList, substanceToxProfileList =>
    substanceToxProfileList.find(substanceToxProfile => substanceToxProfile.SubstanceId === substanceId),
  );

export const makeGetSubstanceToxProfilesForSubstance = createSelector(
  getSubstanceToxProfilesForSubstance,
  substanceToxProflesForSubstance => substanceToxProflesForSubstance,
);
