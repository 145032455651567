import { all, put, takeLatest } from 'redux-saga/effects';
import generalDashboardApi from '../../api/generalDashboardApi';
import { applicationActions } from '../application';
import * as generalDashboardActions from './generalDashboardActions';
import * as generalDashboardSchemas from './generalDashboardSchemas';
import * as generalDashboardTypes from './generalDashboardTypes';
import { callApiSagaAndNormalize } from '../sagaUtils';

export default function* root() {
  yield all([takeLatest(generalDashboardTypes.GENERALDASHBOARD_LIST.REQUEST, getProductListSaga)]);
}

export function* getProductListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(generalDashboardTypes.GENERALDASHBOARD_LIST.REQUEST));
  try {
    yield callApiSagaAndNormalize(
      generalDashboardActions.listProducts,
      generalDashboardApi.getGeneralDashboard,
      generalDashboardSchemas.generalDashboardListSchema,
      payload,
    );
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(generalDashboardActions.listProducts.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(generalDashboardTypes.GENERALDASHBOARD_LIST.REQUEST));
  }
}
