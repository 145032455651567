import * as productPhraseTRFormSelectors from './productPhraseTRFormSelectors';
import * as productPhraseTRFormActions from './productPhraseTRFormActions';
import * as productPhraseTRFormTypes from './productPhraseTRFormTypes';
import sagas from './productPhraseTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productPhraseTRFormSelectors, productPhraseTRFormActions, productPhraseTRFormTypes };
