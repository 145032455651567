import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import * as toxProfileChapterActions from './toxProfileChapterActions';
import * as toxProfileChapterTypes from './toxProfileChapterTypes';
import toxProfileChapterApi from '../../api/toxProfileChapterApi';
import { toxProfileChapterListSchema } from './toxProfileChapterSchemas';

export default function* root() {
  yield all([takeLatest(toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.REQUEST, getToxProfileChapterListSaga)]);
}

function* getToxProfileChapterListSaga({ payload }) {
  yield put(applicationActions.setLoading(toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    toxProfileChapterActions.listToxProfilChapter,
    toxProfileChapterApi.getToxProfilChapterList,
    toxProfileChapterListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.REQUEST));
}
