import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, isPristine, isSubmitting, reset} from 'redux-form';
import {categoryTRFormActions} from '../../../../redux/form/categoryTRForm';
import {referentialSelectors} from '../../../../redux/referentials';
import formNames from '../../../../constants/formNames';
import CategoryTRForm from "./CategoryTRForm";

const formName = formNames.categoryTR;

class CategoryTRFormContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render(){
    return (<CategoryTRForm form={formName} {...this.props}/>);
  }
};

const mapStateToProps = (state, { categoryCode }) => {
  const formValues = getFormValues(formName);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormPristine = isPristine(formName)(state);

  return ({
    formValues: formValues(state),
    formName,
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isSaveDisabled: isFormSubmitting || isFormPristine,
  });
}

const mapDispatchToProps = (dispatch, { categoryCode }) => ({
  onLoad: () => {
    dispatch(categoryTRFormActions.loadForm({ categoryCode }));
  },
  onSubmit: data => categoryTRFormActions.submitCategoryForm(data, dispatch),
  onCancelButtonClick: () => {
    dispatch(reset(formName));
  },
});

CategoryTRFormContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps,mapDispatchToProps)(CategoryTRFormContainer);
