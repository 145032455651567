import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as impactAssessmentActionTypes from './impactAssessmentActionTypes';

const byId = handleActions(
  {
    [impactAssessmentActionTypes.FETCH_IMPACT_ASSESSMENT_ACTIONS.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.impactAssessmentActions),
  },
  {},
);

const allIds = handleActions(
  {
    [impactAssessmentActionTypes.FETCH_IMPACT_ASSESSMENT_ACTIONS.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
