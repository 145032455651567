import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const TrueYesCellRenderer = ({ value }) => {
  if (isNullOrUndef(value)) return '';
  return value === true ? 'yes' : '';
};

TrueYesCellRenderer.defaultProps = {
  value: '',
};

TrueYesCellRenderer.propTypes = {
  value: PropTypes.string,
};

export default TrueYesCellRenderer;
