import { schema } from 'normalizr';

export const referencedSubstanceSchema = new schema.Entity('substances', {}, { idAttribute: 'SubstanceId' });

export const substanceProportionSchema = new schema.Entity(
  'substanceProportions',
  {},
  { idAttribute: 'SubstanceProportionId' },
);

export const substanceProportionWithSubstanceSchema = new schema.Entity(
  'substanceProportions',
  { ReferencedSubstance: referencedSubstanceSchema },
  { idAttribute: 'SubstanceProportionId' },
);

export const substanceProportionListSchema = [substanceProportionSchema];
export const substanceProportionWithSubstanceListSchema = [substanceProportionWithSubstanceSchema];
