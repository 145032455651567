import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as phraseTRTypes from './phraseTRTypes';

const byId = handleActions(
  {
    [phraseTRTypes.PHRASE_TRANSLATION_LIST.SUCCESS]: (state, { payload }) => ({
        ...state,
        ...payload.entities.phraseTRs,
      }),
  },
  {},
);

const allIds = handleActions(
  {
    [phraseTRTypes.PHRASE_TRANSLATION_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
