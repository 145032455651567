import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { orderActions, orderTypes, orderSelectors } from '../../../redux/order';
import { orderItemActions, orderItemSelectors, orderItemTypes } from '../../../redux/orderItem';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { processTypeActions } from '../../../redux/processType';
import { referentialSelectors } from '../../../redux/referentials';
import { orderFormSelectors } from '../../../redux/form/orderForm';
import { processActions } from '../../../redux/process';

import OrderProcessTab from './OrderProcessTab';
import { push } from 'react-router-redux';

class OrderProcessContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.orderId !== this.props.orderId && this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <OrderProcessTab {...this.props} />;
  }
}

const mapStateToProps = (state, { orderId }) => {
  const currencies = referentialSelectors.getCurrencies(state);
  const { currentOrderProcessInfo, currentOrder } = state.order;
  const exchangeRate = currentOrderProcessInfo.ExchangeRate;
  const getItemForOrder = orderItemSelectors.makeGetByQuery(state);
  let items = getItemForOrder({ orderId, IncludeDetails: true });
  items = items.map(elem => {
    const currency = currencies.find(cur => cur.Key === currentOrderProcessInfo.CurrencyCode);
    return ({
      ...elem,
      Processes: elem.Processes.map(proc => ({ ...proc })),
      Currency: currency ? currency.Value : '',
      CurrencyCode: currency ? currency.Key : '',
    });
  });

  return {
    exchangeRate: exchangeRate,
    currentOrderItems: items,
    currentOrder: currentOrder,
    currentOrderProcessInfo,
    currencies,
    // workflowStatusNameById: workflowStatusSelectors.getById(state),
    isOrderEditable: orderSelectors.makeIsOrderEditable(orderId)(state),
    availableProductsForCurrentOrder: orderSelectors.getProductsOwnedByCurrentOrderCompany(state),
    // availableProductsForCurrentOrder: orderSelectors.getAvailableProductsForCurrentOrder(state),
    isLoading: applicationSelectors.isLoading(
      state,
      orderTypes.ORDER_PROCESS_INFO.REQUEST,
      orderItemTypes.ORDER_ITEM_LIST.REQUEST,
    ),
    showAddButton: !orderFormSelectors.isNew(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onLoad: () => {
    dispatch(processTypeActions.listProcessTypes.request());
    dispatch(orderActions.orderProcessInfo.request(orderId));
    dispatch(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  },
  onSaveOrderProcessInfo: formData => dispatch(orderActions.saveOrderProcessInfo.request(formData)),
  getOrderItems: () => dispatch(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true })),
  onDeleteClick1: orderItemId => dispatch(orderItemActions.deleteOrderItem.request({ orderItemId, orderId })),
  onDeleteClick: (orderItemId) =>
    dispatch(
      modalActions.showModal({
        modalType: 'CONFIRM',
        modalProps: {
          title: 'Are you sure ?',
          message: 'Are you sure you want to delete this item ?',
          onConfirm: () => dispatch(orderItemActions.deleteOrderItem.request({ orderItemId, orderId })),
        },
      }),
  ),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showOrderItemFormModal({
        orderId,
      }),
    ),
  onEditButtonClick: orderItemId =>
    dispatch(
      modalActions.showOrderItemFormModal({
        orderId,
        orderItemId,
      }),
    ),
  onProcessEditButtonClick: ({ ProcessId }) =>
    dispatch(
      modalActions.showProcessFormModal({
        ProcessId,
        orderId,
      }),
    ),
  onProcessDeleteButtonClick1: processId => dispatch(processActions.deleteProcess.request({ processId, orderId })),
  onProcessDeleteButtonClick: (processId) =>
    dispatch(
      modalActions.showModal({
        modalType: 'CONFIRM',
        modalProps: {
          title: 'Are you sure ?',
          message: 'Are you sure you want to delete this process ?',
          onConfirm: () => dispatch(processActions.deleteProcess.request({ processId, orderId })),
        },
      }),
  ),
  onProcessAddButtonClick: orderItemId => dispatch(processActions.addProcess.request({ orderItemId, orderId })),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
  onSelectMultipleProductClick: (orderItems, products) => {
    dispatch(
      modalActions.showOrderItemMultipleProductSelectionFormModal({
        orderItems, products, orderId
      }),    
    )
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderProcessContainer);
