import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Panel } from 'react-bootstrap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { css } from 'glamor';

const cssEditor = css({
  '& .rdw-editor-toolbar': {
    marginBottom: '-10px',
  },
  '& .panel-body': {
    padding: '5px',
  },
});

class RTEField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: props.input.value
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.input.value)))
        : EditorState.createEmpty(),
    };
  }

  componentWillReceiveProps(nextProps) {
    const currentValue = this.props.input.value;
    const nextValue = nextProps.input.value;
    const currentState = this.state.editorState;
    let nextState = null;

    if (currentValue !== nextValue) {
      if (nextValue === '') {
        nextState = EditorState.createEmpty();
      } else {
        nextState = EditorState.push(currentState, convertFromRaw(JSON.parse(nextValue)));
      }

      const currentStateSelection = currentState.getSelection();
      if (currentStateSelection.getHasFocus()) {
        nextState = EditorState.acceptSelection(nextState, currentStateSelection);
      }
      this.setState({
        editorState: nextState,
      });
    }
  }

  handleChange = editorState => {
    const { input } = this.props;
    const content = convertToRaw(editorState.getCurrentContent());

    this.setState({
      editorState,
    });

    input.onChange(JSON.stringify(content));
  };

  render() {
    // const {
    //   input: { onBlur, onFocus },
    // } = this.props;
    const { editorState } = this.state;
    return (
      <Panel className={`${cssEditor}`}>
        <Editor
          // toolbarOnFocus
          editorState={editorState}
          onEditorStateChange={this.handleChange}
          // onBlur={onBlur}
          // onFocus={onFocus}
        />
      </Panel>
    );
  }
}

RTEField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
};

export default RTEField;
