import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import { css } from 'glamor';

class LinkingDropdown extends Component {

  static propTypes = {
    options: PropTypes.array, // eslint-disable-line
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    onClick: PropTypes.func,
    currentElemenIdFieldName: PropTypes.string.isRequired,
    linkElementIdFieldName: PropTypes.string.isRequired,
    currentElemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit = () => {
    this.props.onClick({
      [this.props.currentElemenIdFieldName]: this.props.currentElemId,
      [this.props.linkElementIdFieldName]: this.state.selectedOption.value.toString(),
    });
    this.setState(
      { selectedOption: null },
    );
  }

  handleChange = (val) => {
    this.setState(
      { selectedOption: val },
    );
  };

  render() {
    const { options, valueField, labelField } = this.props;
    const opts = options.map((opt) => ({ label: opt[labelField], value: opt[valueField] }));
    const select = css({
      '& .Select': { zIndex: 999 },
    });
    return (
      <div {...select}>
        <Row>
          <Col sm={3}>
            <FormGroup>
              <Select
                options={opts}
                onChange={this.handleChange}
                value={this.state.selectedOption}
                openOnFocus
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <Button
              bsSize="small"
              bsStyle="default"
              type="button"
              onClick={this.onSubmit}
              disabled={this.state.selectedOption === null}
            >
              Link
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

}

export default LinkingDropdown;
