import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productDocumentActions, productDocumentTypes, productDocumentSelectors } from '../../../redux/productDocument';
import { applicationSelectors } from '../../../redux/application';

import ProductDocumentsTab from './ProductDocumentsTab';

class ProductDocumentsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ProductDocumentsTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => ({
  productDocuments: productDocumentSelectors.getProductDocumentListForProduct(state, productId),
  isLoadingProductDocumentList: applicationSelectors.isLoading(
    state,
    productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT.REQUEST,
    productDocumentTypes.SAVE_PRODUCT_DOCUMENT.REQUEST,
    productDocumentTypes.UPLOAD_PRODUCT_DOCUMENT.REQUEST,
    productDocumentTypes.DELETE_PRODUCT_DOCUMENT.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { productId, productDocumentId }) => ({
  onLoad: () => dispatch(productDocumentActions.listProductDocumentsForProduct.request(productId)),
  getProductDocuments: () => dispatch(productDocumentActions.listProductDocumentsForProduct.request(productId)),
  saveProductDocument: data => dispatch(productDocumentActions.saveProductDocument.request(productId, data)),
  sendProductDocumentToCosmos: (productDocumentId) => 
    dispatch(productDocumentActions.sendProductDocumentToCosmos.request(productDocumentId)),
  onUploadFile: data => dispatch(productDocumentActions.uploadProductDocument.request(productId, data)),
  deleteProductDocument: id => dispatch(productDocumentActions.deleteProductDocument.request(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDocumentsContainer);
