import { createSelector } from 'reselect';
import {getCategoryList} from "../category/categorySelectors";

export const getById = ({ categoryTR }) => categoryTR.byId;
export const getIds = ({ categoryTR }) => categoryTR.list.allIds;
export const getCategoryTRList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));


export const getCategoryByCode = (state, code) => getCategoryTRList(state).filter(elem => elem.CategoryCode === code);

