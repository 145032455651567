import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as distributorTypes from './distributorTypes';

export const listDistributors = createRequestActions(distributorTypes.DISTRIBUTOR_LIST);
export const distributor = createRequestActions(distributorTypes.DISTRIBUTOR);
export const saveDistributor = createRequestActions(distributorTypes.SAVE_DISTRIBUTOR);
export const listDistributorProducts = createRequestActions(distributorTypes.DISTRIBUTOR_PRODUCT_LIST);
export const listDistributorAgreements = createRequestActions(distributorTypes.DISTRIBUTOR_AGREEMENT_LIST);
export const deleteDistributor = createRequestActions(distributorTypes.DELETE_DISTRIBUTOR);
export const newDistributor = createAction(distributorTypes.NEW_DISTRIBUTOR);
export const listProductDistributors = createRequestActions(distributorTypes.PRODUCT_DISTRIBUTOR_LIST);
