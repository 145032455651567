import { createSelector } from 'reselect';
import * as documentSelectors from '../document/documentSelectors';
import config from '../../config';

const API_ROOT = config.baseUrl;

export const getById = ({ substanceDocument }) => substanceDocument.byId;
export const getIds = ({ substanceDocument }) => substanceDocument.list.allIds;
export const getSubstanceDocument = (state, id) => getById(state)[id];
export const getSubstanceDocumentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

const getSubstanceDocumentsForSubstance = substanceId =>
  createSelector(getSubstanceDocumentList, substanceDocumentList =>
    substanceDocumentList.filter(sd => sd.SubstanceId === substanceId),
  );

export const makeGetSubstanceDocumentsForSubstance = createSelector(
  getSubstanceDocumentsForSubstance,
  substanceDocumentsForSubstance => substanceDocumentsForSubstance,
);

const getSubstanceDocumentListWithDocumentForSubstance = substanceId =>
  createSelector(
    [getSubstanceDocumentsForSubstance(substanceId), documentSelectors.getById],
    (substanceDocumentsForSubstance, documentById) =>
      substanceDocumentsForSubstance.map(sd => ({
        ...sd,
        Document: {
          ...documentById[sd.DocumentId],
          FileLink: `${API_ROOT}/SubstancesDocuments/${sd.SubstanceDocumentId}/File`,
        },
      })),
  );

export const makeGetSubstanceDocumentListWithDocumentForSubstance = createSelector(
  getSubstanceDocumentListWithDocumentForSubstance,
  substanceDocumentListWithDocumentForSubstance => substanceDocumentListWithDocumentForSubstance,
);

// {
//   const rawMat = rawMatSelectors.getRawMat(state, rawMatId);
//   if (!rawMat || !rawMat.RawMatDocuments) return undefined;
//   return rawMat.RawMatDocuments.map(rdId => {
//     const rawMatDocument = getRawMatDocument(state, rdId);
//     return {
//       ...rawMatDocument,
//       Document: {
//         ...documentSelectors.getById(state)[rawMatDocument.DocumentId],
//         FileLink: `${API_ROOT}/RawMatsDocuments/${rdId}/File`,
//       },
//     };
//   });
// };

// export const getDocumentListForSubstance = substanceId =>
//   createSelector(
//     [getSubstanceDocumentsForSubstance(substanceId), documentSelectors.getDocumentList],
//     (substanceDocumentListForSubstance, documentList) =>
//       documentList.filer(d => substanceDocumentListForSubstance.includes(sd => sd.DocumentId === d.DocumentId)),
//   );

// export const makeGetDocumentListForSubstance = createSelector(
//   getDocumentListForSubstance,
//   documentListForSubstance => documentListForSubstance,
// );
