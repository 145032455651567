import {put, all, select, takeLatest, call} from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as productTRFormActions from './productTRFormActions';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import {referentialSelectors} from "../../referentials";
import {productTRSagas} from "../../productTR";

export default function* root() {
  yield all([
    takeLatest(productTRFormActions.submitProductTRForm.REQUEST, submitProductTRFormSaga),
  ]);
}

const submitProductTRFormSaga = createSaveFormWithValidation(productTRFormActions.submitProductTRForm, saveProductTRSaga);

export function* saveProductTRSaga({payload}) {
  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const finalValue = [];
  languages.forEach(lang => {
    finalValue.push(payload[lang.Alpha2]);
  })

  yield call(productTRSagas.saveProductTRsSaga, {payload: finalValue});
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PRODUCT_TR_FORM) {
    yield put(modalActions.hideModal());
  }
}

