import leaflet from './leaflet.png';
import bunny from './bunny.png';
import greendot from './greendot.png';
import recycling from './recycling.png';
import fsc from './fsc.png';
import hairColorAttention from './hairColorAttention.jpg';
import leapingBunny from './leapingBunny.png';
import calmos from './calmos.png';
import blackHand from './blackHand.png';
import blackRabbit from './blackRabbit.png';
import redHeart from './redHeart.png';
import tmOrR from './tmOrR.png';

export default {
  leaflet,
  bunny,
  greendot,
  recycling,
  fsc,
  hairColorAttention,
  leapingBunny,
  calmos,
  blackHand,
  blackRabbit,
  redHeart,
  tmOrR,
};
