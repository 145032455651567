import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { rawMatSelectors, rawMatTypes, rawMatActions } from '../../../../redux/rawMat';
import { CommentModal } from '../../../_Common/Modals';
import { workflowStatusSelectors } from '../../../../redux/workflowStatus';
import { applicationSelectors } from '../../../../redux/application';

const RawMatStatusCommentModalContainer = props => <CommentModal {...props} />;

RawMatStatusCommentModalContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
  nextStatusCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { rawMatId, nextStatusCode }) => {
  const rawMat = rawMatSelectors.getById(state)[rawMatId];
  const getStatusNameByCode = R.compose(
    R.propOr('', 'Name'),
    statusCode => workflowStatusSelectors.getById(state)[statusCode],
  );
  const currentStatusName = getStatusNameByCode(rawMat.Status);
  const nextStatusName = getStatusNameByCode(nextStatusCode);

  return {
    title: I18n.t('rawMatStatusModal.title', { currentStatusName, nextStatusName }),
    isLoading: applicationSelectors.isLoading(state, rawMatTypes.UPDATE_RAWMAT_STATUS.REQUEST),
    label: I18n.t('rawMatStatusModal.label'),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId, nextStatusCode }) => ({
  onConfirm: Comment =>
    dispatch(rawMatActions.updateStatus.request({ WorkflowStatusCode: nextStatusCode, Comment, rawMatId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RawMatStatusCommentModalContainer);
