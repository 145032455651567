import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import { ProductClpSchema } from './productClpSchemas';
import productApi from '../../api/productApi';
import * as productClpTypes from './productClpTypes';
import * as productClpActions from './productClpActions';

export default function* root() {
  yield all([takeLatest(productClpTypes.FETCH_PRODUCT_CLP_FOR_PRODUCT.REQUEST, getProductClpForProductSaga)]);
}

function* getProductClpForProductSaga({ payload, type }) {
  const { ProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    productClpActions.fetchProductClpForProduct,
    productApi.getProductClp,
    ProductClpSchema,
    ProductId,
  );
  yield put(applicationActions.unsetLoading(type));
}
