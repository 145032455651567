import { createSelector } from 'reselect';
import { phraseSelectors } from '../../phrase';
import * as R from "ramda";

const defaultInitialValues = {
  CpsrTypeCode: 'CPSRA',
};

export const getInitialValues = createSelector([phraseSelectors.getById], phraseById =>
  createSelector(
    phraseId => phraseById[phraseId],
    phrase => phrase || defaultInitialValues,
  ),
);

export const makeGetInitialValues = createSelector(
  [phraseSelectors.getById],
  (phraseById) =>
    createSelector(R.identity, phraseId => {
      const phrase = phraseById[phraseId];
      if (!phrase) return defaultInitialValues;
      return { ...phrase, ChaptersCode: phrase.ChaptersCode.slice() };
    }),
);

export const getCurrent = createSelector([phraseSelectors.getCurrent], current => current);
