import * as productCategoryTRSelectors from './productCategoryTRSelectors';
import * as productCategoryTRActions from './productCategoryTRActions';
import * as productCategoryTRTypes from './productCategoryTRTypes';
import * as productCategoryTRSchemas from './productCategoryTRSchemas';

import sagas, * as productCategoryTRSagas from './productCategoryTRSagas';
import reducer from './productCategoryTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productCategoryTRSelectors, productCategoryTRActions, productCategoryTRTypes, productCategoryTRSchemas, productCategoryTRSagas };
export default reducer;
