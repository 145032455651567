import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import {
  impactAssessmentActionSelectors,
  impactAssessmentActionTypes,
  impactAssessmentActionActions,
} from '../../../../redux/impactAssessmentAction';
import { applicationSelectors } from '../../../../redux/application';
import { DateCellRenderer, SelectionGrid } from '../../../_Common/Grids';
import dateComparator from '../../../../utils/dateComparator';
import { actionSelectors } from '../../../../redux/action';

class ImpactAssessmentActionsList extends PureComponent {
  componentDidMount() {
    this.props.getImpactAssessmentActionList();
  }

  getActionByCode = actionCode => {
    const { actionList } = this.props;
    return actionList.find(action => action.Code === actionCode);
  };

  getActionValue = ({ colDef, data }) =>
    R.compose(
      R.propOr('', colDef.field),
      this.getActionByCode,
      R.propOr('', 'ActionCode'),
    )(data);

  columns = [
    {
      headerName: I18n.t('productAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('productAction.code'),
      field: 'ActionCode',
      width: 40,
    },
    {
      headerName: I18n.t('productAction.description'),
      field: 'Description',
      valueGetter: this.getActionValue,
      width: 150,
    },
    {
      headerName: I18n.t('productAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  render() {
    return (
      <SelectionGrid
        data={this.props.impactAssessmentActionList}
        keyName="ImpactAssessmentActionId"
        columns={this.columns}
        isLoading={this.props.isLoading}
        onRefresh={this.props.getImpactAssessmentActionList}
        showAddButton={false}
        height={this.height}
      />
    );
  }
}

const mapStateToProps = (state, { impactAssessmentId }) => {
  const getImpactAssessmentActions = impactAssessmentActionSelectors.makeGetImpactAssessmentActionsForSubstance(
    impactAssessmentId,
  );
  return {
    impactAssessmentActionList: getImpactAssessmentActions(state),
    actionList: actionSelectors.getActionList(state),
    isLoading: applicationSelectors.isLoading(
      state,
      impactAssessmentActionTypes.FETCH_IMPACT_ASSESSMENT_ACTIONS.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { impactAssessmentId }) => ({
  getImpactAssessmentActionList: () =>
    dispatch(impactAssessmentActionActions.fetchImpactAssessmentActions.request({ impactAssessmentId })),
});

ImpactAssessmentActionsList.defaultProps = {
  height: '',
};

ImpactAssessmentActionsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  impactAssessmentActionList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  getImpactAssessmentActionList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImpactAssessmentActionsList);
