import { createRequestActions, createFetchAction } from '../utils';
import * as rawMatPhraseTypes from './rawMatPhraseTypes';

export const fetchRawMatPhrases = createFetchAction(rawMatPhraseTypes.FETCH_RAWMAT_PHRASES, 'rawMatPhraseId');
export const fetchAllRawMatPhrases = createRequestActions(rawMatPhraseTypes.FETCH_ALL_RAWMAT_PHRASES);
export const saveRawMatPhrase = createRequestActions(rawMatPhraseTypes.SAVE_RAWMAT_PHRASE);

export const deleteRawMatPhrase = createRequestActions(rawMatPhraseTypes.DELETE_RAWMAT_PHRASE, (id, { ...query }) => ({
  id,
  query,
}));
