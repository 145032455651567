import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getUoms = state => state.referentials.Uoms;
export const getImpurityTypes = state => state.referentials.ImpurityTypes;
export const getImpactAssessmentThresholdTypes = state => state.referentials.ImpactAssessmentThresholdTypes;
export const getImpactAssessmentThresholdComparators = state => state.referentials.ImpactAssessmentThresholdComparators;
export const getRegulatoryRegions = state => state.referentials.RegulatoryRegions;
export const getRegions = state => state.referentials.Regions;
export const getDistributionChannelList = state => state.referentials.DistributionChannels;
export const getRawMatAnnexeParts = ({ referentials }) => referentials.RawMatAnnexeParts;
export const getFormulaReviewConclusions = ({ referentials }) => referentials.FormulaReviewConclusions;
export const getCpsrTypeList = ({ referentials }) => referentials.CpsrTypeList;
export const getCpsrPhraseStyleList = ({ referentials }) => referentials.CpsrPhraseStyleList;
export const makeGetCpsrPhraseStyleList = () => getCpsrPhraseStyleList;
export const getCountryList = ({ referentials }) => referentials.countryList;
export const getLanguageList = ({ referentials }) => referentials.languageList;

export const getCpsrAChapters = ({ referentials }) => referentials.CpsrAChapters;
export const getCpsrBChapters = ({ referentials }) => referentials.CpsrBChapters;
export const getCpsrInfoRmChapters = ({ referentials }) => referentials.CpsrInfoRmChapters;
export const getProductUseTypes = ({ referentials }) => referentials.ProductUseTypes;
export const getPaoHourglassTypes = ({ referentials }) => referentials.PaoHourglassTypes;
export const getCosAnnexeTypes = ({ referentials }) => referentials.CosAnnexeTypes;
export const getMosCalculationTimeRanges = ({ referentials }) => referentials.MosCalculationTimeRanges;
export const getMosCalculationTypes = ({ referentials }) => referentials.MosCalculationTypes;
export const getCramerClasses = ({ referentials }) => referentials.CramerClasses;

export const makeGetCpsrChapters = cprsTypeCode => ({ referentials }) =>
  (cprsTypeCode === 'CPSRA' ? referentials.CpsrAChapters : referentials.CpsrBChapters);
export const getCpsrChapters = ({ referentials }, cprsTypeCode) => {
  if (cprsTypeCode === 'CPSRA') return referentials.CpsrAChapters;
  if (cprsTypeCode === 'CPSRB') return referentials.CpsrBChapters;
  return undefined;
};

export const getCpsrBTypeList = state => state.referentials.CpsrBTypeList;

export const getOrderTypes = ({ referentials }) => referentials.OrderTypes;
export const getDirectiveStatusTypes = ({ referentials }) => referentials.DirectiveStatusTypes;
export const getSubstanceInformationTypes = ({ referentials }) => referentials.SubstanceInformationTypes;
export const getLabelInfoAvailabilityCodes = ({ referentials }) => referentials.LabelInfoAvailabilityCodes;
export const getLabelReviewItemReferentialData = ({ referentials }) =>
  referentials.LabelReviewReferentialData.filter(data => data.Type === 'ITEM');
export const getLabelReviewInciReferentialData = ({ referentials }) =>
  referentials.LabelReviewReferentialData.find(data => data.Type === 'INCI');
export const getLabelReviewClaimDefaultRemarks = ({ referentials }) =>
  R.prop('Comment', referentials.LabelReviewReferentialData.find(data => data.Type === 'CLAIM'));

export const getLabelReviewReferentialData = ({ referentials }) => referentials.LabelReviewReferentialData;

export const getCountryByCode = code =>
  createSelector(getCountryList, countryList => countryList.find(country => country.Key === code));
export const makeGetCountryByCode = createSelector(getCountryByCode, country => country);

export const makeGetCosAnnexeTypeByCode = createSelector(getCosAnnexeTypes, cosAnnexeTypeList =>
  createSelector(R.identity, cosAnnexeTypeCode =>
    cosAnnexeTypeList.find(cosAnnexeType => cosAnnexeType.Code === cosAnnexeTypeCode),
  ),
);
export const getCurrencies = state => state.referentials.Currencies;
