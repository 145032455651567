import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { distributorSelectors } from '../distributor';
import { referentialSelectors } from '../referentials';
import { rawMatSelectors } from '../rawMat';
import { mandateSelectors } from '../mandate';
import { companyDistributorAgreementSelectors } from '../companyDistributorAgreement';
import createFilterOptions from 'react-select-fast-filter-options';
import { PrefixIndexStrategy } from 'js-search';

const detailFormValueSelector = formValueSelector('companyForm');

export const getCurrentCompany = state => state.company.currentCompany;
// export const getCurrentCompanyId = createSelector([getCurrentCompany], currentCompany => currentCompany.CompanyId);

export const getCurrentCompanyRPStatus = state => {
  const currentCompany = getCurrentCompany(state);
  // const companyRPWorkflowStatus = workflowStatusSelectors.getWorkflowStatusListByCode('COMPANY_RP')(state);
  // if (companyRPWorkflowStatus.length) {
  //   return currentCompany.IsValidated ? companyRPWorkflowStatus[1].Code : companyRPWorkflowStatus[0].Code;
  // }
  return currentCompany.RPStatus;
  // return undefined;
};


export const getCurrentCompanyDistributors = state => state.company.currentCompanyDistributors;
export const getCurrentCompanyDistributoionChannels = state => state.company.currentCompanyDistributionChannels;

export const getById = ({ company }) => company.byId;
export const getIds = ({ company }) => company.list.allIds;
export const getCompany = (state, id) => getById(state)[id];
export const getCompanyList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export function companyTitle(state) {
  const name = detailFormValueSelector(state, 'FullName') || '';
  return `${name}`;
}

export const getRawMatListForCompany = createSelector(rawMatSelectors.getRawMatList, rawMatList =>
  createSelector(companyId => rawMatList.filter(rawMat => rawMat.CompanyId === companyId), R.identity),
);

export const getDistributorsNotLinkedWithCurrentCompany = createSelector(
  [getCurrentCompanyDistributors, distributorSelectors.getDistributorList],
  (currentCompanyDistributors, distributorList) =>
    distributorList.filter(d1 => !currentCompanyDistributors.some(d2 => d1.DistributorId === d2.DistributorId)),
);

export const getDistributionChannelsNotLinkedWithCurrentCompany = createSelector(
  [getCurrentCompanyDistributoionChannels, referentialSelectors.getDistributionChannelList],
  (currentCompanyDistributionChannels, distributionChannelList) =>
    distributionChannelList.filter(
      d1 => !currentCompanyDistributionChannels.some(d2 => d1.Key === d2.DistributionChannelCode),
    ),
);

const makeGetByIdForObj = obj =>
  R.compose(
    R.defaultTo({}),
    R.flip(R.prop)(obj),
  );

const getCompanyForMandate = mandateId =>
  createSelector(
    [getById, mandateSelectors.getById, companyDistributorAgreementSelectors.getById],
    (companyById, mandateById, agreementById) =>
      R.compose(
        makeGetByIdForObj(companyById),
        R.prop('CompanyId'),
        makeGetByIdForObj(agreementById),
        R.prop('AgreementId'),
        makeGetByIdForObj(mandateById),
      )(mandateId),
  );

export const makeGetCompanyForMandate = createSelector(getCompanyForMandate, R.identity);

const companyPrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetSubstanceListFilter = createSelector(R.identity, companyList =>
  createFilterOptions({
    options: companyList,
    labelKey: 'ShortName',
    valueKey: 'CompanyId',
    indexStrategy: companyPrefixIndexStrategy,
  }),
);
export const sortByShortName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'ShortName'))]));

export const getCompanyPrivateRMListExceptSpecific = companyId =>
  createSelector([getIds, getById], (allIds, byId) =>
    allIds.map(id => byId[id]).filter(company => company.CompanyId !== companyId && company.UsesPrivateRawMat),
  );

export const makeGetCompanyPrivateRMListExceptSpecific = createSelector(
  getCompanyPrivateRMListExceptSpecific,
  R.identity,
);
