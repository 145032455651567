import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import GenerateAgrementForm from './GenerateAgrementForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const GenerateAgrementFormModal = props => {
  const { closeModal, onSaveButtonClick, isDirty, invalid, isLoadingContent } = props;
  return (
    <div>
      <Modal.Header closeButton>
        <Translate value="distributorAgreements.addAgreement" />
      </Modal.Header>
      <Modal.Body>
        <GenerateAgrementForm {...props} />
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />
              &nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={onSaveButtonClick}
              disabled={!isDirty || invalid || isLoadingContent}
            >
              <i className="fa fa-floppy-o" />
              &nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

GenerateAgrementFormModal.defaultProps = {};

GenerateAgrementFormModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default GenerateAgrementFormModal;
