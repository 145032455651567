import * as ingredientSelectors from './ingredientSelectors';
import * as ingredientActions from './ingredientActions';
import * as ingredientTypes from './ingredientTypes';
import sagas from './ingredientSagas';
import reducer from './ingredientReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { ingredientSelectors, ingredientActions, ingredientTypes };
export default reducer;
