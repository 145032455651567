import * as labelSymbolCorrectiveActionSelectors from './labelSymbolCorrectiveActionSelectors';
// import * as labelSymbolCorrectiveActionActions from './labelSymbolCorrectiveActionActions';
// import * as labelSymbolCorrectiveActionTypes from './labelSymbolCorrectiveActionTypes';
import * as labelSymbolCorrectiveActionSchemas from './labelSymbolCorrectiveActionSchemas';
// import sagas from './labelSymbolCorrectiveActionSagas';
import reducer from './labelSymbolCorrectiveActionReducer';

// import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { labelSymbolCorrectiveActionSelectors, labelSymbolCorrectiveActionSchemas };
export default reducer;
