import { createSelector } from 'reselect';
import { phraseTRSelectors } from '../../phraseTR';
import { phraseSelectors } from '../../phrase';
import { referentialSelectors } from '../../referentials';
import * as R from "ramda";

const sortByCode = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'PhraseCode'))]));

export const makeGetInitialFormValue = createSelector(
  [phraseSelectors.getphraseList, phraseTRSelectors.getPhraseTRList, referentialSelectors.getLanguageList],
  (phraseList, phraseTRList, languageList) => {
    const sortedPhraseList = sortByCode(phraseList);
    const values = sortedPhraseList.reduce((acc, phrase) => {
      const TRS = {};
      languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
        const tr = phraseTRList.find(elem => {
          return elem.LanguageCode === lang.Alpha2 && elem.PhraseCode === phrase.PhraseCode;
        });
        TRS[lang.Alpha2] = tr;
      });

      return {
        ...acc,
        [phrase.PhraseCode]: TRS,
        allCodes: [...acc.allCodes, phrase.PhraseCode],
      }
    }, { allCodes: [] });
    return values;
  }
);
