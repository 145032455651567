import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Modal} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import {css} from "glamor";
import SubstanceTRForm from "./SubstanceTRForm";
import BaseLoader from "../../BaseLoader";

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const SubstanceTRModal = props => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <BaseLoader isLoading={props.isLoading}>
        <SubstanceTRForm {...props}/>
      </BaseLoader>
    </Modal.Body>
    <Modal.Footer>
      <div className={styles.buttonPanel}>
        <ButtonToolbar>
          <Button bsSize="small" bsStyle="danger" type="button" onClick={props.closeModal}>
            <i className="fa fa-times" />
            &nbsp;
            <Translate value="general.close" />
          </Button>
          <Button
            bsSize="small"
            bsStyle="success"
            type="button"
            onClick={props.onSaveButtonClick}
            disabled={props.isSaveDisabled}
          >
            <Translate value="general.save" />
            &nbsp;
            <i className="fa fa-arrow-circle-right" />
          </Button>
        </ButtonToolbar>
      </div>
    </Modal.Footer>
  </div>
);

SubstanceTRModal.propTypes = {
  title: PropTypes.string.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SubstanceTRModal;
