import React from 'react';
import { connect } from 'react-redux';
import { submit, isInvalid } from 'redux-form';
import AddProductToOrderModal from './AddProductToOrderModal';
import { orderActions, orderTypes } from '../../../../redux/order';
import { applicationSelectors } from '../../../../redux/application';
import formNames from '../../../../constants/formNames';

const AddProductToOrderModalContainer = props => <AddProductToOrderModal {...props} />;

const mapStateToProps = state => ({
  isLoading: applicationSelectors.isLoading(state, orderTypes.SAVE_PRODUCT_AND_ADD_TO_CURRENT_ORDER.REQUEST),
  isFormInValid: isInvalid(formNames.minimalProduct)(state),
});

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.minimalProduct)),
  onSubmit: data => dispatch(orderActions.saveProductAndAddToCurrentOrder.request(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductToOrderModalContainer);
