import { css } from 'glamor';
import moment from 'moment';
import PropTypes from 'prop-types';
import { any, head, map, pipe, propEq, values } from 'ramda';
import React, { Component } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { validate } from 'spected';
import BaseLoader from '../../../_Common/BaseLoader';
import LabelReviewApprovalForm from './LabelReviewApprovalForm';

const verify = validate(
  () => ({
    isError: false,
    description: undefined,
  }),
  errors => ({
    isError: true,
    description: head(errors),
  }),
);

const parseMomentToString = value => {
  if (!moment.isMoment(value)) return value;
  return value.format();
};

const isAnyError = pipe(values, any(propEq('isError', true)));

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class LabelReviewApprovalModal extends Component {
  constructor(props) {
    super(props);
    const currentDate = moment();
    this.state = {
      currentuser: this.props.currentUser,
      approvedOn: currentDate,
      sentOn: currentDate,
      approvalComment: undefined,
      isApproved: false,
    };
  }

  updateFor = key => value =>
    this.setState(() => ({
      [key]: value,
    }));

  handleSubmit = () => {
    this.props.onSave(map(parseMomentToString, this.state));
  };

  isValidDate = [[value => moment(value).isValid(), I18n.t('form.validDateError')]];

  validationRules = {
    approvedOn: this.isValidDate,
    sentOn: this.isValidDate,
  };

  render() {
    const { closeModal, title, isLoading, currentUser, ...otherProps } = this.props;
    const errors = verify(this.validationRules, this.state);
    const hasErrors = isAnyError(errors);
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseLoader isLoading={isLoading}>
            <LabelReviewApprovalForm {...otherProps} {...this.state} updateFor={this.updateFor} errors={errors} />
          </BaseLoader>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />&nbsp;
                <Translate value="general.close" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="button"
                disabled={hasErrors || isLoading}
                onClick={this.handleSubmit}
              >
                <Translate value="general.save" />&nbsp;
                <i className="fa fa-floppy-o" aria-hidden="true" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

LabelReviewApprovalModal.defaultProps = {
  title: 'Label Review Approval',
};

LabelReviewApprovalModal.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LabelReviewApprovalModal;
