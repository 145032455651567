import * as phraseSelectors from './phraseSelectors';
import * as phraseActions from './phraseActions';
import * as phraseTypes from './phraseTypes';
import * as phraseSchemas from './phraseSchemas';

import sagas, * as phraseSagas from './phraseSagas';
import reducer from './phraseReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { phraseSelectors, phraseActions, phraseTypes, phraseSchemas, phraseSagas };
export default reducer;
