import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companySelectors } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import { distributorActions } from '../../../redux/distributor';
import CompanyDistributorList from './CompanyDistributorList';
import {
  companyDistributorAgreementSelectors,
  companyDistributorAgreementActions,
  companyDistributorAgreementTypes,
} from '../../../redux/companyDistributorAgreement';
import workflowStatus from '../../../constants/workflowStatus';

class CompanyDistributorsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <CompanyDistributorList {...this.props} />;
  }
}

const mapStateToProps = (state, { companyId }) => {
  const getCompanyDistributorAgreements = companyDistributorAgreementSelectors.makeGetCompanyDistributorAgreementsForCompany(
    companyId,
  );
  return {
    currentCompanyDistributors: getCompanyDistributorAgreements(state),
    distributorsNotLinkedWithCurrentCompany: companySelectors.getDistributorsNotLinkedWithCurrentCompany(state),
    requestingCompanyDistributorList: applicationSelectors.isLoading(
      state,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => {
    dispatch(distributorActions.listDistributors.request());
    dispatch(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForCompany.request({ companyId }));
  },
  onRefresh: () =>
    dispatch(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForCompany.request({ companyId })),
  addDistributorToCompany: data =>
    dispatch(
      companyDistributorAgreementActions.saveCompanyDistributorAgreementForCompany.request(
        { ...data, companyId, StatusCode: workflowStatus.AGREEMENT_CREATED },
        { companyId },
      ),
    ),
  onRowDoubleClicked: distributor => dispatch(push(`/distributors/${distributor.DistributorId}`)),
  onRemoveBtnClicked: distributor =>
    dispatch(companyDistributorAgreementActions.removeDistributorFromCompanyAction(companyId, distributor)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyDistributorsContainer);
