import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { mandateActions } from '../mandate';
import companyDistributorAgreementApi from '../../api/companyDistributorAgreementApi';
import * as companyDistributorAgreementActions from './companyDistributorAgreementActions';
import * as companyDistributorAgreementTypes from './companyDistributorAgreementTypes';
import * as companyDistributorAgreementSchemas from './companyDistributorAgreementSchemas';
import { modalActions } from '../modal';

export default function* root() {
  yield all([
    takeLatest(
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.REQUEST,
      getCompanyDistributorAgreementsSaga,
    ),
    takeLatest(
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.REQUEST,
      getCompanyDistributorAgreementsSaga,
    ),
    takeLatest(
      companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY.REQUEST,
      saveCompanyDistributorAgreementsSaga,
    ),
    takeLatest(
      companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR.REQUEST,
      saveCompanyDistributorAgreementsSaga,
    ),
    takeLatest(companyDistributorAgreementTypes.MARK_AS_SENT_AGREEMENT.REQUEST, markAsSentAgreementsSaga),
    takeLatest(companyDistributorAgreementTypes.MARK_AS_SIGNED_AGREEMENT.REQUEST, markAsSignedAgreementsSaga),
    takeLatest(companyDistributorAgreementTypes.REVOKE_AGREEMENT.REQUEST, revokeAgreementsSaga),
    takeLatest(companyDistributorAgreementTypes.REMOVE_AGREEMENT.REQUEST, removeCompanyDistributorAgreementSaga),
  ]);
}

export function* getCompanyDistributorAgreementsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const companyDistributorAgreementList = yield call(
      companyDistributorAgreementApi.getCompanyDistributorAgreements,
      payload,
    );
    const normalizedData = normalize(
      companyDistributorAgreementList,
      companyDistributorAgreementSchemas.companyDistributorAgreementListSchema,
    );
    if (type === companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.REQUEST) {
      yield put(
        companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForCompany.success(normalizedData, payload),
      );
    } else if (type === companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.REQUEST) {
      yield put(
        companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.success(
          normalizedData,
          payload,
        ),
      );
    }
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

export function* saveCompanyDistributorAgreementsSaga(action) {
  const { type, payload } = action;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    companyDistributorAgreementActions.saveCompanyDistributorAgreementForCompany,
    companyDistributorAgreementApi.saveCompanyDistributorAgreement,
    payload,
  );
  yield put(
    companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request({
      distributorId: payload.distributorId,
    }),
  );
  yield put(mandateActions.fetchMandates.request({ distributorId: payload.distributorId }));
  yield put(modalActions.hideModal());
  yield put(applicationActions.unsetLoading(type));
}

export function* markAsSentAgreementsSaga(action) {
  const { type, payload, meta } = action;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    companyDistributorAgreementActions.markAsSentAgreement,
    companyDistributorAgreementApi.markAsSentAgreement,
    payload,
  );
  yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request(meta.query));
  yield put(applicationActions.unsetLoading(type));
}

export function* markAsSignedAgreementsSaga(action) {
  const { type, payload, meta } = action;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    companyDistributorAgreementActions.markAsSignedAgreement,
    companyDistributorAgreementApi.markAsSignedAgreement,
    payload,
  );
  yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request(meta.query));
  if (type === companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY.REQUEST) {
    yield callApiSaga(
      companyDistributorAgreementActions.saveCompanyDistributorAgreementForCompany,
      companyDistributorAgreementApi.saveCompanyDistributorAgreement,
      payload,
    );
    yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForCompany.request(meta.query));
  } else if (type === companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR.REQUEST) {
    yield callApiSaga(
      companyDistributorAgreementActions.saveCompanyDistributorAgreementForDistributor,
      companyDistributorAgreementApi.saveCompanyDistributorAgreement,
      payload,
    );
    yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request(meta.query));
  }
  yield put(mandateActions.fetchMandates.request(meta.query));
  yield put(applicationActions.unsetLoading(type));
}

export function* revokeAgreementsSaga(action) {
  const { type, payload, meta } = action;
  const { companyDistributorAgreementId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    companyDistributorAgreementActions.revokeAgreement,
    companyDistributorAgreementApi.revokeAgreement,
    companyDistributorAgreementId,
  );
  yield put(companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request(meta.query));
  yield put(mandateActions.fetchMandates.request(meta.query));
  yield put(applicationActions.unsetLoading(type));
}

export function* removeCompanyDistributorAgreementSaga(action) {
  yield put(applicationActions.setLoading(companyDistributorAgreementTypes.REMOVE_AGREEMENT.REQUEST));
  const {
    payload: { CompanyId, CompanyDistributorAgreementId },
  } = action;
  yield callApiSaga(
    companyDistributorAgreementActions.removeDistributorFromCompany,
    companyDistributorAgreementApi.removeAgreement,
    CompanyDistributorAgreementId,
    CompanyId,
  );
  yield put(
    companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForCompany.request({ companyId: CompanyId }),
  );
  yield put(applicationActions.unsetLoading(companyDistributorAgreementTypes.REMOVE_AGREEMENT.REQUEST));
}
