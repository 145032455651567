import { isInvalid, isDirty, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../constants/formNames';

const isAnyTrue = R.any(R.equals(true));
const areAllTrue = R.all(R.equals(true));
export const formNameList = [formNames.substance, formNames.substanceClp];

const isFormDirty = state => formName => isDirty(formName)(state);

export const getDirtyForms = R.flip(R.useWith(R.filter, [isFormDirty, R.identity]))(formNameList);

const isAnyForm = fn => R.compose(isAnyTrue, R.juxt(R.map(fn, formNameList)));
const areAllForm = fn => R.compose(areAllTrue, R.juxt(R.map(fn, formNameList)));

export const dirty = isAnyForm(isDirty);
export const invalid = isAnyForm(isInvalid);
export const pristine = areAllForm(isPristine);
export const submitting = isAnyForm(isSubmitting);
