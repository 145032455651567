import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { css } from 'glamor';
import RawMatCompositionList from './RawMatCompositionList';
import UpdateInciQtyButton from './UpdateInciQtyButton';
import GoToSubstancesButton from './GoToSubstancesButton';

const RawMatCompositionTab = ({
  onAllergensInPerfumeButtonClick,
  actionButtonsDisabled,
  onImpuritiesButtonClick,
  isAddDisabled,
  isRawMatCompositionEditable,
  rawMatId,
  ...rest
}) => (
  <div>
    <div {...css({ marginBottom: '10px' })}>
      <ButtonToolbar>
        <Button
          bsSize="small"
          onClick={onAllergensInPerfumeButtonClick}
          disabled={actionButtonsDisabled || !isRawMatCompositionEditable}
        >
          <Translate value="rawMatCompositionTab.allergensInPerfumeButtonLabel" />
        </Button>
        <Button
          bsSize="small"
          onClick={onImpuritiesButtonClick}
          disabled={actionButtonsDisabled || !isRawMatCompositionEditable}
        >
          <Translate value="rawMatCompositionTab.impuritiesButtonLabel" />
        </Button>
        <UpdateInciQtyButton rawMatId={rawMatId} disabled={isAddDisabled || !isRawMatCompositionEditable} />
        <GoToSubstancesButton rawMatId={rawMatId} />
      </ButtonToolbar>
    </div>
    <RawMatCompositionList rawMatid={rawMatId} isRawMatCompositionEditable={isRawMatCompositionEditable} {...rest} />
  </div>
);

RawMatCompositionTab.propTypes = {
  onAllergensInPerfumeButtonClick: PropTypes.func.isRequired,
  onImpuritiesButtonClick: PropTypes.func.isRequired,
  actionButtonsDisabled: PropTypes.bool.isRequired,
};

export default RawMatCompositionTab;
