import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form, Grid, Row, Col } from 'react-bootstrap';
import { RadioFields, SelectField } from '../../../_Common/Forms';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import formNames from '../../../../constants/formNames';
import { productProportionFormActions } from '../../../../redux/form/productProportionForm';

class ProductDuplicateOrReplaceForm extends React.Component {
  render() {
    const { availableOrdersForCurrentProduct, handleSubmit, onSubmit, onChangeMode, onChangeOrderNr } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col lg={2}>
                  <Field
                    name="TypeCode"
                    component={RadioFields}
                    onChange={(e, newValue, oldValue) => onChangeMode(newValue, oldValue)}
                    labelField="Value"
                    valueField="Key"
                    options={[{ Key: 'Duplicate', Value: 'Duplicate' }, { Key: 'Replace', Value: 'Replace' }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <Field
                    name="Order"
                    label={I18n.t('product.orderNr')}
                    component={SelectField}
                    labelField="OrderNr"
                    valueField="OrderId"
                    options={availableOrdersForCurrentProduct}
                    onChange={(e, newValue, oldValue) => onChangeOrderNr(newValue, oldValue)}
                  />
                </Col>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

ProductDuplicateOrReplaceForm.defaultProps = {
  productId: undefined,
};

ProductDuplicateOrReplaceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  onChangeOrderNr: PropTypes.func.isRequired,
  productId: PropTypes.number,
  mode: PropTypes.string,
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => {
    productProportionFormActions.submitProductProportionForm({ ...data, productId }, dispatch);
  },
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.productProportionForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProductDuplicateOrReplaceForm);
