import { createSelector } from 'reselect';
import { impactAssessmentThresholdSelectors } from '../../impactAssessmentThreshold';

const getInitialValues = impactAssessmentThresholdId =>
  createSelector(
    [impactAssessmentThresholdSelectors.getById],
    thresholdById =>
      thresholdById[impactAssessmentThresholdId] || {
        QuantityThreshold: undefined,
        MoSThreshold: undefined,
      },
  );

export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues);
