import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Modal, Col, Row, Tabs, Tab} from 'react-bootstrap';
import { css } from 'glamor';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { CategoryForm } from '../../Forms';
import { categorySelectors } from '../../../../redux/category';
import CategoryTRFormContainer from "./CategoryTRFormContainer";

const styles = {
  modalBody: css({
    overflow: 'auto',
  }),
  tabs: css({
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
};
const CategoryFormModal = ({ categoryId, title, closeModal, categoryCode }) => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <div className={styles.modalBody}>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <div className={styles.tabs}>
              <Tabs id="CategoryTabs" mountOnEnter unmountOnExit>
                <Tab eventKey={1} title={I18n.t('category.tabDetails')}>
                  <CategoryForm categoryId={categoryId}  closeModal={closeModal} />
                </Tab>
                <Tab eventKey={2} title={I18n.t('category.tabTranslations')}>
                  <CategoryTRFormContainer categoryCode={categoryCode} />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </div>
  </div>
);

const mapStateToProps = (state, { categoryId }) => {
  const category = categorySelectors.getById(state)[categoryId];
  const categoryCode = R.propOr('', 'Code', category);
  return {
    title: category ? I18n.t('categoryFormModal.editTitle', { categoryCode }) : I18n.t('categoryFormModal.addTitle'),
    categoryCode,
  };
};

CategoryFormModal.defaultProps = {
  categoryId: undefined,
};

CategoryFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(CategoryFormModal);
