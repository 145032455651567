import { createSelector } from 'reselect';
import * as R from 'ramda';
// import * as impactAssessmentValueSelectors from '../impactAssessmentValue/impactAssessmentValueSelectors';

export const getById = ({ impactAssessmentProduct }) => impactAssessmentProduct.byId;
export const getIds = ({ impactAssessmentProduct }) => impactAssessmentProduct.list.allIds;
export const getImpactAssessmentProduct = (state, id) => getById(state)[id];

export const getImpactAssessmentProductList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

export const getImpactAssessmentProductsForImpactAssessment = createSelector(
  [getImpactAssessmentProductList],
  impactAssessmentProductList =>
    createSelector(
      impactAssessmentId => impactAssessmentId,
      impactAssessmentId =>
        impactAssessmentProductList.filter(
          impactAssessmentProduct => impactAssessmentProduct.ImpactAssessmentId === impactAssessmentId,
        ).map(elem => ({
          ...elem,
          id: elem.ImpactAssessmentProductId,
          Values: elem.Values.map(val => ({
            ...val,
            id: `${elem.ImpactAssessmentProductId}-${val.ImpactAssessmentValueId}`,
          }))
        })),
    ),
);

export const getImpactAssessmentProductsForImpactAssessmentValues = createSelector(
  [getImpactAssessmentProductList],
  impactAssessmentProductList => {
    return createSelector(
      impactAssessmentId => impactAssessmentId,
      impactAssessmentId => {
        const result = [];
        impactAssessmentProductList.filter(
          impactAssessmentProduct => impactAssessmentProduct.ImpactAssessmentId === impactAssessmentId,
        ).forEach(prod => {
          prod.Values.forEach(val => {
            result.push({
              ...val,
              Code: prod.Code,
              ProductName: prod.ProductName,
              IsNotified: prod.IsNotified,
              NotifiedOn: prod.NotifiedOn,
              NotificationCode: prod.NotificationCode,
              CategoryDescription: prod.CategoryDescription,
              ProductUseTypeCode: prod.ProductUseTypeCode,
              Status: prod.Status,
            });
          });
        });

        return result;
      },
    );
  }
);
