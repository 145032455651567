import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as productPhraseTRTypes from './productPhraseTRTypes';

const byId = handleActions(
  {
    [productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.productPhraseTRs,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
