import { createSelector } from 'reselect';
import * as R from 'ramda';
import { createGeneralSelectors } from '../_common/selectors';

const getShadeStateSlice = state => state.shade;
export const {
  getById,
  getIds,
  getLoading,
  getByQueryIds,
  makeGetByQuery,
  makeGetByQueryLoading,
} = createGeneralSelectors(getShadeStateSlice);

export const getByProductIds = state => getShadeStateSlice(state).list.byProductIds;
export const getShade = (state, id) => getById(state)[id];
export const getShadeList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

// const makeGetShadeIdsForProduct = createSelector(getByProductIds, byProductIds =>
//   createSelector(productId => byProductIds[productId], shadeIds => R.defaultTo([], shadeIds)),
// );

export const makeGetShadesForProduct = createSelector([makeGetByQuery], getShadesByQuery =>
  createSelector(R.identity, productId => getShadesByQuery({ productId })),
);
// export const makeGetShadesForProduct = createSelector(
//   [makeGetShadeIdsForProduct, getById],
//   (getShadeIdsForProduct, byId) =>
//     createSelector(
//       productId => getShadeIdsForProduct(productId),
//       shadeIdsForProduct => shadeIdsForProduct.map(id => byId[id]),
//     ),
// );
