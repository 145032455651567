import React from 'react';
import PropTypes from 'prop-types';
import { GeneralGrid } from '../../../_Common/Grids';

const ShadeXRmCompoList = props => {
  const { columns, data } = props;

  return (
    <GeneralGrid
      data={data}
      height="70vh"
      columns={columns}
      isLoading={false}
      keyName="ProductProportionId"
      showRefreshButton={false}
      suppressFieldDotNotation
    />
  );
};

ShadeXRmCompoList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired, //eslint-disable-line
};

export default ShadeXRmCompoList;
