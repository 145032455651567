import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import formActionSaga from 'redux-form-saga';
import thunk from 'redux-thunk';
import { hashHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { autoRehydrate } from 'redux-persist';
import Reactotron from '../ReactotronConfig';
// import DevTools from '../components/DevTools';

import rootReducer from './reducers';
import sagas from './sagas';
import locales, { setFallbackTranslation } from '../locales';

// const sagaMonitor = Reactotron.createSagaMonitor();

// const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const sagaMiddleware = createSagaMiddleware();

const router = routerMiddleware(hashHistory);

const composeEnhancers = composeWithDevTools({});

const enhancer = composeEnhancers(applyMiddleware(thunk, router, sagaMiddleware), autoRehydrate());

export default function configureStore(initialState) {
  const createStore = process.env.NODE_ENV !== 'production' ? Reactotron.createStore : reduxCreateStore;

  const store = createStore(rootReducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept(
      './reducers',
      () => store.replaceReducer(require('./reducers')), // eslint-disable-line global-require
    );
  }

  [sagas, formActionSaga].forEach(saga => sagaMiddleware.run(saga));

  // sagaMiddleware.run(sagas);

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(locales.data));
  store.dispatch(setLocale(locales.defaultLanguage));
  setFallbackTranslation();
  return store;
}
