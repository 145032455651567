import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { categoryActions, categoryTypes, categorySelectors } from '../../../redux/category';
import { categoryTRActions, categoryTRTypes, categoryTRSelectors } from '../../../redux/categoryTR';
import CategoryList from './CategoryList';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';

class CategoryListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <CategoryList {...this.props} />;
  }
}

const mapStateToProps = state => ({
  categoryList: categorySelectors.getCategoryList(state),
  isLoading: applicationSelectors.isLoading(
    state,
    categoryTypes.CATEGORY_LIST.REQUEST,
    categoryTypes.SAVE_CATEGORY.REQUEST,
  ),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(categoryActions.listCategories.request());
    dispatch(categoryTRActions.listCategoriesTR.request());
  },
  getCategoryList: () => dispatch(categoryActions.listCategories.request()),
  onAddButtonClick: () => dispatch(modalActions.showCategoryFormModal({ modalWidth: '1200px' })),
  onEditButtonClick: ({ CategoryId: categoryId }) =>
    dispatch(modalActions.showCategoryFormModal({ categoryId, modalWidth: '1200px' })),
  saveCategory: data => dispatch(categoryActions.saveCategory.request(data)),
  onDeleteButtonClick: ({ categoryId }) => dispatch(categoryActions.deleteCategory.request(categoryId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryListContainer);

CategoryListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
