import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues, isPristine, isSubmitting, reset } from 'redux-form';
import { applicationSelectors } from '../../../redux/application';
import formNames from '../../../constants/formNames';
import { productPhraseFormActions, productPhraseFormSelectors } from '../../../redux/form/productPhraseForm';
import { modalActions } from '../../../redux/modal';
import { phraseListModalActions } from '../../../redux/modal/PhraseListModal';
import { phraseTypes } from '../../../redux/phrase';
import { productActions, productSelectors, productTypes } from '../../../redux/product';
import { productPhraseActions, productPhraseTypes } from '../../../redux/productPhrase';
import { referentialSelectors } from '../../../redux/referentials';
import workflowStatus from '../../../constants/workflowStatus';
import ProductCPSRForm from './ProductCPSRForm';
import {errorsFormSelectors} from "../../../redux/form/errorsForm";

const getFormName = cpsrTypdeCode => `Product${cpsrTypdeCode}Form`;

class ProductPhraseFormContainer extends Component {
  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    const formName = getFormName(this.props.CpsrTypeCode);
    return <ProductCPSRForm form={formName} {...this.props} />;
  }
}

const mergeResult = formNamess => fn => state =>
  R.compose(
    R.all(R.equals(true)),
    R.map(formName => fn(formName)(state)),
  )(formNamess);

const mapStateToProps = (_state, { CpsrTypeCode }) => {
  const formName = getFormName(CpsrTypeCode);
  const showConclusionStatus = CpsrTypeCode === 'CPSRB';
  const formValues = getFormValues(formName);
  console.log('formValues', formValues);

  return (state, { productId }) => {
    const getIsCpsrXEditable =
      CpsrTypeCode === 'CPSRA' ? productSelectors.makeIsCpsrAEditable : productSelectors.makeIsCpsrBEditable;
    const product = productSelectors.getProduct(state, productId);
    const cpsrBConclusionStatus = R.propOr('', 'CpsrBConclusionStatus')(product);
    const workflowStatusNextStepDisabled =
      CpsrTypeCode === 'CPSRB' &&
      cpsrBConclusionStatus === workflowStatus.CPSRB_CONCLUSION_NOTDONE &&
      product.CpsrBStatus === workflowStatus.CPSRB_CHECKED;

    const formHasError = errorsFormSelectors.formHasError(state);

    return {
      CpsrChapters: referentialSelectors.getCpsrChapters(state, CpsrTypeCode),
      formValues: formValues(state),
      formName,
      isFormPristine: mergeResult([formName, formNames.productCpsrbCommentForm])(isPristine)(state),
      CpsrPhraseStyleList: referentialSelectors.getCpsrPhraseStyleList(state),
      isLoading: applicationSelectors.getIsLoading(
        productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.REQUEST,
        productPhraseTypes.SAVE_PRODUCT_PHRASE.REQUEST,
        productTypes.UPDATE_PRODUCT_CPSR_STATUS.REQUEST,
        productTypes.UPDATE_PRODUCT_CPSRB_CONCLUSION_STATUS.REQUEST,
        isSubmitting(formNames.productCpsrbCommentForm),
      )(state),
      isCpsrXEditable: getIsCpsrXEditable(productId)(state),
      isLoadingPhrases: applicationSelectors.isLoading(state, phraseTypes.PHRASE_LIST.REQUEST),
      nbSelectedItems: productPhraseFormSelectors.nbSelectedItems(state, formName),
      cpsrChapterFilter: productPhraseFormSelectors.getCpsrChapterFilter(state, formName),
      productName: R.propOr('', 'ProductName', product),
      workflowStatusNextStepDisabled,
      showConclusionStatus,
      cpsrTypeCode: CpsrTypeCode,
      formHasError,
      regions: referentialSelectors.getRegions(state),
      countries: referentialSelectors.getCountryList(state),
    };
  };
};

const mapDispatchToProps = (dispatch, { productId: ProductId, CpsrTypeCode }) => {
  const formName = getFormName(CpsrTypeCode);
  return {
    onLoad: () => {
      dispatch(productPhraseActions.listProductPhraseForProduct.request({ ProductId, CpsrTypeCode }));
      dispatch(productPhraseFormActions.loadForm({ ProductId, CpsrTypeCode, formName }));
    },
    onSelectProductPhrase: data => dispatch(productPhraseFormActions.onSelectProductPhrase({ formName, ...data })),
    onSelectFilter: data => dispatch(productPhraseFormActions.onSelectFilter({ formName, filter: data })),
    onSaveProductPhrases: formData =>
      dispatch(productPhraseFormActions.onSaveButtonClick({ formName, formData, ProductId, CpsrTypeCode })),
    onDeleteProductPhrases: () =>
      dispatch(productPhraseFormActions.onDeleteButtonClick({ formName, ProductId, CpsrTypeCode })),
    onAddProductPhrase: () =>
      dispatch(phraseListModalActions.showPhraseListModal({ CpsrTypeCode, ProductId, formName })),
    onEditProductPhrase: ProductPhraseId =>
      dispatch(phraseListModalActions.showPhraseListModal({ ProductPhraseId, CpsrTypeCode, ProductId, formName })),
    onAddTablePhrase: () => dispatch(productPhraseFormActions.addTablePhrase({ CpsrTypeCode, ProductId, formName })),
    resetPlaceholder: field => dispatch(productPhraseFormActions.resetPlaceholders({ formName, field })),
    onChangeStatus: ({ Code }) => dispatch(productActions.updateCPSRStatus.request(ProductId, Code, CpsrTypeCode)),
    onCancelButtonClick: () => {
      dispatch(reset(formName));
      dispatch(reset(formNames.productCpsrbCommentForm));
    },
    onLoadTemplateButtonClick: () => {
      dispatch(productActions.listProducts2.request());
      dispatch(
        modalActions.showProductCpsrLoadTemplateModal({
          productId: ProductId,
          cpsrTypeCode: CpsrTypeCode,
          formName,
        }),
      )
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductPhraseFormContainer);

ProductPhraseFormContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  CpsrTypeCode: PropTypes.string.isRequired,
};
