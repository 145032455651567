import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { createFetchSaga, callApiSaga } from '../sagaUtils';
import { modalTypes, modalActions, modalSelectors } from '../modal';
import { createSaveForm } from '../form/utils';
import formNames from '../../constants/formNames';
import shadeApi from '../../api/shadeApi';
import * as shadeActions from './shadeActions';
import * as shadeTypes from './shadeTypes';
import { shadeListSchema, shadeSchema } from './shadeSchemas';

export default function* root() {
  yield all([
    takeLatest(shadeTypes.FETCH_SHADES.REQUEST, fetchShadeSagas),
    takeLatest(shadeTypes.SAVE_SHADE.REQUEST, saveShadeFormSaga),
    takeLatest(shadeTypes.DELETE_SHADE.REQUEST, deleteShadeSaga),
    takeLatest(shadeTypes.UPDATE_INCI.REQUEST, updateInciSaga),
  ]);
}

const saveShadeFormSaga = createSaveForm(formNames.shade, saveShadeSaga);

export function* saveShadeSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { productId } = payload;
  yield callApiSaga(shadeActions.saveShade, shadeApi.saveShade, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.SHADE_FORM) {
    yield put(modalActions.hideModal());
  }
  yield put(shadeActions.fetchShades.request({ productId }));
  yield put(applicationActions.unsetLoading(type));
}

export const fetchShadeSagas = createFetchSaga(
  function* fetchShadeSagas({ payload, type }) {
    const shades = yield call(shadeApi.getShades, payload);
    const schema = Array.isArray(shades) ? shadeListSchema : shadeSchema;
    const normalizedData = normalize(shades, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(shadeActions.fetchShades.success(data, payload));
    },
    onFailure: (error, payload) => put(shadeActions.fetchShades.failure(error, payload)),
  },
);

export function* updateInciSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const shadeList = yield call(shadeApi.updateInci, payload);
    const normalizedData = normalize(shadeList, shadeListSchema);
    yield put(shadeActions.updateInci.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(shadeActions.updateInci.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* deleteShadeSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { id, query } = payload;
    yield call(shadeApi.deleteShade, id);
    yield put(shadeActions.deleteShade.success(id, payload));
    yield put(shadeActions.fetchShades.request(query));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(shadeActions.deleteShade.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
