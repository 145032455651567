import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, ButtonToolbar, FormGroup, ControlLabel } from 'react-bootstrap';
import { reduxForm, Field, submit, Form } from 'redux-form';
import { compose } from 'recompose';
import { I18n, Translate } from 'react-redux-i18n';
import formNames from '../../../../constants/formNames';
import LoadingButton from '../../../_Common/LoadingButton';
import { InputField } from '../../../_Common/Forms';
import { substanceCosAnnexeSelectors } from '../../../../redux/substanceCosAnnexe';
import { substanceCosAnnexeFormActions } from '../../../../redux/form/substanceCosAnnexeForm';

const SubstanceCosAnnexeForm = props => {
  const { onSubmit, handleSubmit, onSaveButtonClick, submitting, pristine } = props;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={4}>
            <Field name="CasNr" label={`${I18n.t('substanceCosAnnexe.CasNr')}`} component={InputField} />
          </Col>
          <Col sm={4}>
            <Field name="EcNr" label={`${I18n.t('substanceCosAnnexe.EcNr')}`} component={InputField} />
          </Col>
          <Col sm={4}>
            <Field name="Ratio" label={`${I18n.t('substanceCosAnnexe.Ratio')}`} component={InputField} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field name="Inci" label={`${I18n.t('substanceCosAnnexe.Inci')}`} component={InputField} />
          </Col>
          <Col sm={6}>
            <FormGroup>
              <ControlLabel>&nbsp;</ControlLabel>
              <ButtonToolbar>
                <LoadingButton
                  onClick={onSaveButtonClick}
                  bsSize="small"
                  bsStyle="success"
                  isLoading={submitting}
                  disabled={pristine}
                >
                  <Translate value="general.save" />
                </LoadingButton>
              </ButtonToolbar>
            </FormGroup>
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { substanceCosAnnexeId }) => ({
  initialValues: substanceCosAnnexeSelectors.getById(state)[substanceCosAnnexeId] || {},
});

const mapDispatchToProps = (dispatch, { cosAnnexeCode }) => ({
  onSaveButtonClick: () => dispatch(submit(formNames.substanceCosAnnexeForm)),
  onSubmit: data =>
    substanceCosAnnexeFormActions.submitSubstanceCosAnnexeForm({ ...data, CosAnnexeCode: cosAnnexeCode }, dispatch),
});

SubstanceCosAnnexeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNewButtonClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.substanceCosAnnexeForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(SubstanceCosAnnexeForm);
