import * as inciListSelectors from './inciListSelectors';
import * as inciListActions from './inciListActions';
import * as inciListTypes from './inciListTypes';
import * as inciListSchemas from './inciListSchemas';
import sagas, * as inciListSagas from './inciListSagas';
import reducer from './inciListReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { inciListSelectors, inciListActions, inciListTypes, inciListSchemas, inciListSagas };
export default reducer;
