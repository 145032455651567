import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { workflowStatusSelectors } from '../redux/workflowStatus';

class WorkflowStatus extends Component {
  render() {
    const { workflowStatus } = this.props;
    return this.props.render(workflowStatus);
  }
}

const mapStateToProps = state => ({
  workflowStatus: workflowStatusSelectors.getWorkflowStatusList(state),
});

WorkflowStatus.propTypes = {
  render: PropTypes.func.isRequired,
  workflowStatus: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const WorkflowStatusWithData = connect(mapStateToProps)(WorkflowStatus);

export default WorkflowStatusWithData;

export const withWorkflowStatus = BaseComponent => {
  const WorkflowStatusHOC = hoistNonReactStatics(WorkflowStatusWithData, BaseComponent);
  return props => <WorkflowStatusHOC render={BaseComponent} {...props} />;
};
