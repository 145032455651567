import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as substanceProductTypes from './substanceProductTypes';

const bySubstanceId = handleActions(
  {
    [substanceProductTypes.FETCH_PRODUCTS_FOR_SUBSTANCE.SUCCESS]: (state, { payload, meta: { SubstanceId } }) => ({
      [SubstanceId]: payload.result,
    }),
  },
  {},
);

export default combineReducers({
  bySubstanceId,
});
