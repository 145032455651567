import * as homepageSelectors from './homepageSelectors';
import * as homepageActions from './homepageActions';
import * as homepageTypes from './homepageTypes';
import * as homepageSchemas from './homepageSchemas';
// import sagas from './homepageSagas';
import reducer from './homepageReducer';

// import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { homepageSelectors, homepageActions, homepageTypes, homepageSchemas };
export default reducer;
