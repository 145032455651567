import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import ProductProportionSelectRMList from './ProductProportionSelectRMList';
import ProductProportionSelectRMListDX from './ProductProportionSelectRMListDX';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class ProductProportionSelectRMModal extends React.Component {
  state = {
    limitedRawMatList: []
  }

  setGrid = g => {
    this.grid = g;
  };
  handleOnSave = () => {
    if (this.grid) {
      this.props.onSave(this.grid.api.getModel().rowsToDisplay.map(rn => rn.data));
    }
  };

  render() {
    const { title, closeModal, productId, onSave, isSaveDisabled, rawMatList } = this.props;
    // const { limitedRawMatList } = this.state;
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <ProductProportionSelectRMList height="60vh" rawMats={rawMatList} whenGridReady={this.setGrid} {...this.props}/>*/}
          {/* <br/> */}
          {/* <Button bsSize="small" bsStyle="primary" type="button" onClick={() => this.setState({limitedRawMatList: rawMatList.filter(rm =>
              rm.Name.includes('tocopherol'))})}>
            <i className="fa fa-times" />
            &nbsp;
            Pre-filter
          </Button> */}
          <ProductProportionSelectRMListDX height="30vh" rawMats={rawMatList} whenGridReady={this.setGrid} {...this.props}/>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />
                &nbsp;
                <Translate value="general.close" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

ProductProportionSelectRMModal.defaultProps = {};

ProductProportionSelectRMModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
};

export default ProductProportionSelectRMModal;
