import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { push } from 'react-router-redux';
import { dashboardActions, dashboardTypes } from '../../../redux/dashboard';
import { applicationSelectors } from '../../../redux/application';
import DashboardResultList from './DashboardResultList';

class DashboardResultsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    dashboardTypeId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.dashboardTypeId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.dashboardTypeId !== this.props.dashboardTypeId && this.props.dashboardTypeId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <DashboardResultList {...this.props} />;
  }
}

const mapStateToProps = state => {
  const { dashboard: { currentDashboardResults } } = state;
  return {
    currentDashboardResults,
    requestingDashboardResultList: applicationSelectors.isLoading(
      state,
      dashboardTypes.DASHBOARD_RESULT_LIST.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { dashboardTypeId }) => ({
  onLoad: () => {
    dispatch(dashboardActions.getDashboardResults.request(dashboardTypeId));
  },
  onRefresh: () => dispatch(dashboardActions.getDashboardResults.request(dashboardTypeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardResultsContainer);
