import { takeLatest, put, all, select, fork } from 'redux-saga/effects';
import { change, getFormValues } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import * as types from './types';

const formName = formNames.draftLabel;

const isFirstPage = R.equals(0);

const similarFields = [
  ['MarketingTextOnInner', 'MarketingTextOnOuter', 'MarketingTextOnLeaflet'],
  ['OriginInnerText', 'OriginOuterText'],
  ['LeafletSymbolTextOnInner', 'LeafletSymbolTextOnOuter'],
  ['PAOSymbolRemarkOnInner', 'PAOSymbolRemarkOnOuter'],
  ['BatchCodeRemarkOnInner', 'BatchCodeRemarkOnOuter'],
];

export default function* root() {
  yield all([takeLatest(types.NEXT_BUTTON_CLICKED, onNextButtonClickedSaga)]);
}

function* setFormValue(field, value) {
  yield put(change(formName, field, value));
}

function* copyField(fieldCopyForm, fieldCopyTo, formValues) {
  const valueFieldCopyTo = R.propOr(null, fieldCopyTo, formValues);
  const valueFieldCopyFrom = R.propOr(null, fieldCopyForm, formValues);
  if (!valueFieldCopyTo) {
    yield fork(setFormValue, fieldCopyTo, valueFieldCopyFrom);
  }
}

const findSimilarFields = R.compose(R.without(R.identity), field => R.find(R.contains(field), similarFields));

function* onNextButtonClickedSaga({ payload }) {
  const { currentPage } = payload;
  if (isFirstPage(currentPage)) {
    const formValues = yield select(getFormValues(formName));
    const fieldsToCopyFrom = [
      'MarketingTextOnInner',
      'OriginInnerText',
      'LeafletSymbolTextOnInner',
      'PAOSymbolRemarkOnInner',
      'BatchCodeRemarkOnInner',
    ];

    yield fieldsToCopyFrom.map(fieldToCopyFrom => {
      const fieldsToCopyTo = findSimilarFields(fieldToCopyFrom);
      return fieldsToCopyTo.map(fieldCopyTo => fork(copyField, fieldToCopyFrom, fieldCopyTo, formValues));
    });
  }
}
