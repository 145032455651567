import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import BaseLoader from '../../../_Common/BaseLoader';
import SubstanceList from './_SubstanceListContainer';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class AllergenListModal extends React.Component {
  onSaveButtonClick = () => {
    this.props.onSaveButtonClick(this.getRowsData());
  };

  setGrid = g => this.setState({ grid: g });

  getRowsData = () => {
    if (this.state.grid) {
      return this.state.grid.api.rowModel.rowsToDisplay.map(rn => rn.data);
    }
    return [];
  };

  render() {
    const { title, closeModal, rawMatId } = this.props;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <BaseLoader>
          <Modal.Body>
            <SubstanceList rawMatId={rawMatId} whenGridReady={this.setGrid} />
          </Modal.Body>
        </BaseLoader>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />&nbsp;
                <Translate value="general.close" />
              </Button>
              <Button bsSize="small" bsStyle="success" type="button" onClick={this.onSaveButtonClick}>
                <Translate value="general.save" />&nbsp;
                <i className="fa fa-arrow-circle-right" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

AllergenListModal.propTypes = {
  rawMatId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
};

export default AllergenListModal;
