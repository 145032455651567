import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import MandateProductsModal from './MandateProductsModal';
import { productSelectors } from '../../../../redux/product';
import { mandateProductSelectors } from '../../../../redux/mandateProduct';
import { companySelectors } from '../../../../redux/company';
import { mandateSelectors } from '../../../../redux/mandate';
import { distributorSelectors } from '../../../../redux/distributor';
import * as actions from './actions';

class MandateProductsModalContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <MandateProductsModal {...this.props} />;
  }
}

const mapStateToProps = (state, { mandateId }) => {
  const company = companySelectors.makeGetCompanyForMandate(mandateId)(state);
  const distributor = distributorSelectors.makeGetDistributorForMandate(mandateId)(state);
  const mandate = mandateSelectors.getById(state)[mandateId];
  const getMandateProducts = mandateProductSelectors.makeGetMandateProductForMandate(mandateId);
  // TODO: Improve
  const availableProductListForMandate = productSelectors.makeGetProductsForCompany(company.CompanyId)(state);
  // const availableProductListForMandate = productSelectors.getProductsForMandate(mandateId)(state);
  const initialMandateProductList = getMandateProducts(state);
  const isNewMandate = initialMandateProductList.length === 0;
  return {
    title: `${I18n.t('mandateProductModal.title')} ${I18n.t('mandateProductModal.distributor')}: ${`${distributor.Name
    } `}${I18n.t('mandateProductModal.company')}: ${company.ShortName}`,
    initialMandateProductList,
    availableProductListForMandate,
    isNewMandate,
    cosmeticBrand: mandate.CosmeticBrand,
    validityStartDate: mandate.ValidityStartDate,
    status: mandate.StatusCode,
  };
};

const mapDispatchToProps = (dispatch, { mandateId, distributorId }) => ({
  onLoad: () => dispatch(actions.onLoad(mandateId)),
  onSave: (data, mandateData) => dispatch(actions.onSave(mandateId, distributorId, data, mandateData)),
});

MandateProductsModalContainer.propTypes = {
  mandateId: PropTypes.number.isRequired,
  distributorId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MandateProductsModalContainer);
