import * as productSelectors from './productSelectors';
import * as productActions from './productActions';
import * as productTypes from './productTypes';
import * as productSchemas from './productSchemas';
import sagas, * as productSagas from './productSagas';
import reducer from './productReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productSelectors, productActions, productTypes, productSchemas, productSagas };
export default reducer;
