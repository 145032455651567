import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as R from 'ramda';
import queryString from 'query-string';
import { getBaseType } from '../../utils';
import * as labelReviewWizardTypes from './labelReviewWizardTypes';
import labelReviewItemReducer from './labelReviewItemWizard';
import labelReviewInciReducer from './labelReviewInciWizard';
import labelReviewClaimReducer from './labelReviewClaimWizard';
import labelReviewSymbolReducer from './labelReviewSymbolWizard';
import labelSymbolWizardReducer from './labelSymbolWizard';

const labelReviewReducer = handleActions(
  {
    [labelReviewWizardTypes.LOAD_LABEL_REVIEW_DATA]: (state, { payload }) => ({
      ...payload,
    }),
    [labelReviewWizardTypes.UPDATE_LABEL_REVIEW]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

const selectedProduct = handleActions(
  {
    [combineActions(
      labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST,
      labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST,
    )]: (state, { payload: { query: { ProductId } = {} } = {} }) => ({
      ProductId,
    }),
  },
  {},
);

const loading = handleActions(
  {
    [combineActions(
      labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST,
      labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS,
      labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST ? payload : meta;
      const querystring = `${getBaseType(type)}/${queryString.stringify(R.omit(['include'])(query))}`;
      return {
        ...state,
        [querystring]: type === labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST,
      };
    },
    [combineActions(
      labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST,
      labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS,
      labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST ? payload : meta;
      const querystring = `${getBaseType(type)}/${queryString.stringify(R.omit(['include'])(query))}`;
      return {
        ...state,
        [querystring]: type === labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST,
      };
    },
  },
  {},
);

export default combineReducers({
  labelReview: labelReviewReducer,
  labelReviewItems: labelReviewItemReducer,
  labelReviewInci: labelReviewInciReducer,
  labelReviewClaims: labelReviewClaimReducer,
  labelReviewSymbols: labelReviewSymbolReducer,
  labelSymbols: labelSymbolWizardReducer,
  meta: combineReducers({
    loading,
    selectedProduct,
  }),
});
