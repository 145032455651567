import * as impurityModalActions from './impurityModalActions';
import * as impurityModalTypes from './impurityModalTypes';
import * as impurityModalSelectors from './impurityModalSelectors';
import sagas from './impurityModalSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { impurityModalActions, impurityModalTypes, impurityModalSelectors };
