import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import { Grid, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as R from 'ramda';
import { InputField } from '../../../_Common/Forms';
import { substanceToxProfileFormSelectors } from '../../../../redux/form/substanceToxProfileForm';
import { substanceToxProfileActions } from '../../../../redux/substanceToxProfile';
import formNames from '../../../../constants/formNames';

const formName = formNames.substanceToxProfile;

const maxLength = 2048;
const isTooLong = value => value && value.length > maxLength;

const validate = R.compose(R.map(() => I18n.t('form.maxLengthError', { max: maxLength })), R.filter(isTooLong));

const mapStateToProps = (state, { substanceId }) => {
  const getInitialValues = substanceToxProfileFormSelectors.makeGetInitialValues(substanceId);
  return {
    initialValues: getInitialValues(state),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onSubmit: data => dispatch(substanceToxProfileActions.saveSubstanceToxProfile.request({ ...data, substanceId })),
});

export const renderField = fieldName => (
  <Field
    name={fieldName}
    label={I18n.t(`substanceToxicologicalProfile.${fieldName}`)}
    component={InputField}
    componentClass="textarea"
  />
);

export const createForm = BaseComponent => {
  const newComponent = props => (
    <Form>
      <Grid fluid>
        <BaseComponent {...props} />
      </Grid>
    </Form>
  );

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: formName,
      enableReinitialize: true,
      destroyOnUnmount: true,
      validate,
    }),
  )(newComponent);
};
