import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as productCategoryTRTypes from './productCategoryTRTypes';

const byId = handleActions(
  {
    [productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.productCategoryTR),
  },
  {},
);

const allIds = handleActions(
  {
    [productCategoryTRTypes.PRODUCT_CATEGORY_TR_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
