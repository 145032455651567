import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as productActionTypes from './productActionTypes';

const byId = handleActions(
  {
    [productActionTypes.PRODUCT_ACTION_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.productActions,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [productActionTypes.PRODUCT_ACTION_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
