import { del, post, get, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/RawMatProportions/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  deleteRawMatProportion: id => del(`${defaultUrl}${id}`),
  addRawMatProportionsFromRawMat: data => post(`${defaultUrl}AddRawMat`, data),
  saveRawMatProportion: data => post(`${defaultUrl}`, data),
  fetchRawMatProportions: ({ id, query } = {}) => get(getUrl({ id, query })),
};
