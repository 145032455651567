import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n, Translate } from 'react-redux-i18n';
import { productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import {PercentageCellRenderer, SelectionGrid} from '../../../_Common/Grids';
import LoadingButton from "../../../_Common/LoadingButton";
import { ButtonToolbar } from 'react-bootstrap';
import {rawMatSelectors} from "../../../../redux/rawMat";
// import DevExpressTrial from "./DevExpressTrial"

const getColorFromRowLevel = R.cond([
  [R.equals(0), R.always('#FFFFFF')],
  [R.equals(1), R.always('#EEEEEE')],
  [R.equals(2), R.always('#DDDDDD')],
  [R.T, R.always('')],
]);

const setBackgroundFromColor = R.objOf('background');

const getRowStyle = R.compose(
  setBackgroundFromColor,
  getColorFromRowLevel,
  R.path(['node', 'level']),
);

const actionButtonsRenderer = props => {
  const { data, node, onShowCompo, getIsShowCompoLoading, onAddToProduct, getIsAddToProductLoading } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      {node.level === 0 && (
        <ButtonToolbar>
          {data.Children.length === 0 && (
            <LoadingButton isLoading={getIsShowCompoLoading(data)} bsSize="xs" bsStyle="info" type="button" onClick={() => onShowCompo(data)}>
              <i className="fa fa-lg" aria-hidden="true" />&nbsp;<Translate value="rawMat.showCompo" />
            </LoadingButton>
          )}
          <LoadingButton isLoading={getIsAddToProductLoading(data)} bsSize="xs" bsStyle="info" type="button" onClick={() => onAddToProduct(data)}>
            <i className="fa fa-lg" aria-hidden="true" />&nbsp;<Translate value="rawMat.addToProductShort" />
          </LoadingButton>
        </ButtonToolbar>
      )}
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  // onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onShowCompo: PropTypes.func.isRequired,
  getIsShowCompoLoading: PropTypes.func.isRequired,
};

const ProductProportionSelectRMList = ({ height, productProportions, rawMats, whenGridReady, isLoading, addToProduct, onShowCompo, getIsShowCompoLoading, getIsAddToProductLoading }) => {

  const columns = [
    {
      headerName: I18n.t('rawMat.code'),
      field: 'Code',
      cellRenderer: 'group',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    {
      headerName: I18n.t('rawMat.name'),
      field: 'Name',
    },
    {
      headerName: I18n.t('rawMat.supplier'),
      field: 'SupplierName',
    },
    {
      headerName: I18n.t('general.quantityInRawMat'),
      field: 'Percentage',
      cellRenderer: PercentageCellRenderer,
    },
    {
      headerName: I18n.t('rawMat.actions'),
      cellRendererFramework: actionButtonsRenderer,
      cellRendererParams: {
        onShowCompo: onShowCompo,
        onAddToProduct: addToProduct,
        // getIsEditLoading: ({ FormulaReviewId }) => getIsLoadingWizardForFormulaReview(FormulaReviewId),
        getIsShowCompoLoading:  ({ RawMatId }) => getIsShowCompoLoading(RawMatId),
        getIsAddToProductLoading:  ({ RawMatId }) => getIsAddToProductLoading(RawMatId),
      },
      width: 200,
      minWidth: 200,
      suppressSizeToFit: true,
    },
  ];


  const getNodeChildDetails = rowItem => {
    if (rowItem.RawMatId) {
      return {
        group: true,
        expanded: true,
        // children: rowItem.Children?[{ name: 'TOCOPHEROL ACETATE, HEAVY METALS, AS, Cd, CU, Pb, Hg, ZN, TOCOPHEROL, ISOBUTYL ACETATE'}]:[],
        children: rowItem.Children?rowItem.Children:[],
        key: rowItem.Name,
      };
    }
    return null;
  };

  return (
    <>
    {/* <DevExpressTrial /> */}
    <SelectionGrid
      data={rawMats}
      showFilterRow={true}
      showAddButton={false}
      isLoading={isLoading}
      keyName="Id"
      columns={columns}
      height={height}
      singleClickEdit
      stopEditingWhenGridLosesFocus
      whenGridReady={whenGridReady}
      getNodeChildDetails={getNodeChildDetails}
      showExpandCollapseButtons
      immutableData={false}
      getRowStyle={getRowStyle}
    />
    </>
  );
};

const mapStateToProps = (state, { productId }) => {
  return {
    rawMats: rawMatSelectors.getRawMatListWithCompo(state),
    isLoading: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
  };
};

ProductProportionSelectRMList.defaultProps = {
  height: '',
  productProportions: [],
};

ProductProportionSelectRMList.propTypes = {
  height: PropTypes.string,
  whenGridReady: PropTypes.func.isRequired,
  productProportions: PropTypes.array.isRequired, //eslint-disable-line
  rawMats: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(ProductProportionSelectRMList);
