import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import LabelReviewList from './LabelReviewList';
import BaseLoader from '../../_Common/BaseLoader';
import LoadingButton from '../../_Common/LoadingButton';

const LabelReviewTab = props => {
  const { onNewLabelReviewClick, isLoadingLabelReviewTab, isLoadingNewWizard, ...otherProps } = props;

  return (
    <BaseLoader isLoading={isLoadingLabelReviewTab}>
      <div>
        <LoadingButton type="button" bsSize="small" onClick={onNewLabelReviewClick} isLoading={isLoadingNewWizard}>
          <Translate value="product.addLabelReview" />
        </LoadingButton>
      </div>
      <br />
      <div>
        <LabelReviewList {...otherProps} />
      </div>
    </BaseLoader>
  );
};

LabelReviewTab.propTypes = {
  onNewLabelReviewClick: PropTypes.func.isRequired,
  isLoadingLabelReviewTab: PropTypes.bool.isRequired,
  isLoadingNewWizard: PropTypes.bool.isRequired,
};

export default LabelReviewTab;
