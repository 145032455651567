import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import {
  substanceActionSelectors,
  substanceActionTypes,
  substanceActionActions,
} from '../../../../redux/substanceAction';
import { applicationSelectors } from '../../../../redux/application';
import { DateCellRenderer, SelectionGrid } from '../../../_Common/Grids';
import dateComparator from '../../../../utils/dateComparator';
import { actionSelectors } from '../../../../redux/action';

const SubstanceActionsList = props => {
  const { actionList, substanceActionList, isLoading, height, getSubstanceActionList } = props;

  const getActionByCode = actionCode => actionList.find(action => action.Code === actionCode);

  const getActionValue = ({ colDef, data }) =>
    R.compose(R.propOr('', colDef.field), getActionByCode, R.propOr('', 'ActionCode'))(data);

  const columns = [
    {
      headerName: I18n.t('productAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('productAction.code'),
      field: 'ActionCode',
      width: 40,
    },
    {
      headerName: I18n.t('productAction.description'),
      field: 'Description',
      valueGetter: getActionValue,
      width: 150,
    },
    {
      headerName: I18n.t('productAction.comment'),
      field: 'Comment',
    },
    {
      headerName: I18n.t('productAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  return (
    <SelectionGrid
      data={substanceActionList}
      keyName="SubstanceActionId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getSubstanceActionList}
      showAddButton={false}
      height={height}
    />
  );
};

const mapStateToProps = (state, { substanceId }) => {
  const getSubstanceActions = substanceActionSelectors.makeGetSubstanceActionsForSubstance(substanceId);
  return {
    substanceActionList: getSubstanceActions(state),
    actionList: actionSelectors.getActionList(state),
    isLoading: applicationSelectors.isLoading(state, substanceActionTypes.FETCH_SUBSTANCE_ACTIONS.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  getSubstanceActionList: () => dispatch(substanceActionActions.fetchSubstanceActions.request({ substanceId })),
});

SubstanceActionsList.defaultProps = {
  height: '',
};

SubstanceActionsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  substanceActionList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  getSubstanceActionList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceActionsList);
