import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { createRequestActions } from '../utils';

import * as orderTypes from './orderTypes';

export const submitOrderForm = createFormAction(orderTypes.SUBMIT_ORDER_FORM);

export const listOrders = createRequestActions(orderTypes.ORDER_LIST);
export const listOrderProducts = createRequestActions(orderTypes.ORDER_PRODUCT_LIST);
export const order = createRequestActions(orderTypes.ORDER);
export const orderMissingDocumentList = createRequestActions(orderTypes.ORDER_MISSING_DOCUMENT_LIST);
export const orderProcessInfo = createRequestActions(orderTypes.ORDER_PROCESS_INFO);
export const saveOrder = createRequestActions(orderTypes.SAVE_ORDER);
export const deleteOrder = createRequestActions(orderTypes.DELETE_ORDER);
export const saveOrderProcessInfo = createRequestActions(orderTypes.SAVE_ORDER_PROCESS_INFO);
export const listProductsOwnedByOrderCompany = createRequestActions(orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY);
export const saveOrderProducts = createRequestActions(orderTypes.SAVE_ORDER_PRODUCTS);
export const saveProductAndAddToCurrentOrder = createRequestActions(orderTypes.SAVE_PRODUCT_AND_ADD_TO_CURRENT_ORDER);
export const addProductToCurrentOrder = createRequestActions(orderTypes.ADD_PRODUCT_TO_CURRENT_ORDER);
export const removeProductFromOrder = createAction(orderTypes.REMOVE_PRODUCT_FROM_ORDER, (OrderId, ProductId) => ({
  OrderId,
  ProductId,
}));
export const newOrder = createAction(orderTypes.NEW_ORDER);

export const saveOrderProduct = orderProduct => ({
  type: orderTypes.SAVE_ORDER_PRODUCTS.REQUEST,
  payload: [
    {
      ...orderProduct,
    },
  ],
});

export const addProductToCurrentOrderAction = ({ ProductId }) => ({
  type: orderTypes.ADD_PRODUCT_TO_CURRENT_ORDER.REQUEST,
  payload: ProductId,
});

export const updateStatus = createRequestActions(orderTypes.UPDATE_ORDER_STATUS, data => ({
  ...data,
  WorkflowCode: 'ORDER',
}));
