import React from 'react';
import { css } from 'glamor';
import { Grid, Row, Col } from 'react-bootstrap';
import StatPageForm from './StatPageForm';

const Stats = () => (
  <Grid fluid>
    <Row className={`${css({ paddingTop: '30px' })}`}>
      <Col sm={12}>
        <StatPageForm />
      </Col>
    </Row>
  </Grid>
);

export default Stats;
