import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  companyDistributorAgreementSelectors,
  companyDistributorAgreementActions,
  companyDistributorAgreementTypes,
} from '../../../redux/companyDistributorAgreement';
import { companySelectors } from '../../../redux/company';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { applicationSelectors } from '../../../redux/application';
import { documentSelectors, documentActions, documentTypes } from '../../../redux/document';
import DistributorAgreementList from './DistributorAgreementList';
import { reportActions } from '../../../redux/report';
import { modalActions } from '../../../redux/modal';

class DistributorAgreementListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }
  componentDidUpdate(previousProps) {
    if (previousProps.distributorId !== this.props.distributorId) {
      this.props.onLoad();
    }
  }

  render() {
    return <DistributorAgreementList {...this.props} />;
  }
}

const mapStateToProps = (state, { distributorId }) => {
  const getCompanyDistributorAgreementList = companyDistributorAgreementSelectors.makeGetCompanyDistributorAgreementsForDistributor(
    distributorId,
  );
  return {
    companyDistributorAgreementList: getCompanyDistributorAgreementList(state),
    companyList: companySelectors.getCompanyList(state),
    agreementStatusList: workflowStatusSelectors.getAgreementStatusList(state),
    documentList: documentSelectors.getDocumentList(state),
    isLoading: applicationSelectors.isLoading(
      state,
      documentTypes.DOWNLOAD_DOCUMENT.REQUEST,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.REQUEST,
      companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR.REQUEST,
      companyDistributorAgreementTypes.REVOKE_AGREEMENT.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { distributorId }) => ({
  onLoad: () => {
    if (distributorId > 0) {
      dispatch(
        companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request({ distributorId }),
      );
    }
  },
  onAddButtonClick: () => dispatch(modalActions.showGenerateAgrementModal({ distributorId })),
  onRefresh: () =>
    dispatch(
      companyDistributorAgreementActions.fetchCompanyDistributorAgreementsForDistributor.request({ distributorId }),
    ),
  onDownloadButtonClick: ({ CompanyDistributorAgreementId }) =>
    dispatch(
      companyDistributorAgreementActions.downloadAgreement.request(CompanyDistributorAgreementId, { distributorId }),
    ),
  onPrintButtonClick: ({ CompanyDistributorAgreementId }) =>
    dispatch(reportActions.getAgreementReport.request(CompanyDistributorAgreementId)),
  onMarkAsSentButtonClick: ({ CompanyDistributorAgreementId }) =>
    dispatch(
      companyDistributorAgreementActions.markAsSentAgreement.request(CompanyDistributorAgreementId, { distributorId }),
    ),
  onMarkAsSignedButtonClick: ({ CompanyDistributorAgreementId }) =>
    dispatch(
      companyDistributorAgreementActions.markAsSignedAgreement.request(CompanyDistributorAgreementId, {
        distributorId,
      }),
    ),
  onRevokeButtonClick: ({ CompanyDistributorAgreementId }) =>
    dispatch(
      companyDistributorAgreementActions.revokeAgreement.request(CompanyDistributorAgreementId, { distributorId }),
    ),
  onAgreementClick: ({ DocumentId }) => dispatch(documentActions.downloadDocument.request(DocumentId)),
});

DistributorAgreementListContainer.propTypes = {
  distributorId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DistributorAgreementListContainer);
