import PropTypes from 'prop-types';
import React from 'react';
import EditButton from './EditButton';

const EditButtonRenderer = props => {
  const { node, data, onClick, isLoading, isDisabled } = props;
  const handleClick = () => {
    onClick(data);
  };
  return (
    <div style={{ textAlign: 'center' }}>
      {node.level === 0 && <EditButton onClick={handleClick} isLoading={isLoading} disabled={isDisabled} />}
    </div>
  );
};

EditButtonRenderer.defaultProps = {
  isLoading: false,
  isDisabled: false,
};

EditButtonRenderer.propTypes = {
  node: PropTypes.shape({
    level: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EditButtonRenderer;
