import React, { Component } from 'react';
import { css } from 'glamor';
import { Button, InputGroup, Grid, Row, Col } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import images from '../../assets/img';
import { controlCenterTypes, controlCenterActions,  } from '../../redux/controlCenter';
import { connect } from 'react-redux';
import config from '../../config';

class ControlCenter extends Component {

  render () {

    return (
      <Grid fluid>
        <Row className={`${css({ paddingTop: '30px', paddingLeft: '200px' })}`}>
          <Col>
            <Button
                bsSize="small"
                bsStyle="default"
                type="button"
                onClick={() => {
                  window.open(config.baseUrl + '/hangfire');
                  // window.location.href = config.baseUrl + '/hangfire';
                  return null;
                }}
            >
              <i className="fa fa-files-o"/>
              &nbsp;
              hangfire
            </Button>
          </Col>
          </Row>
          <Row className={`${css({ paddingTop: '30px', paddingLeft: '200px' })}`}>
          <Col sm={12}>
            <InputGroup.Button>
              <Button bsStyle="success" bsSize="small" onClick={this.props.startHangfire}>
                            <Translate value="controlCenter.startHangfire" />
                            &nbsp;
                            <i className="fa fa-play-circle" aria-hidden="true" />
              </Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button bsStyle="danger" bsSize="small" onClick={this.props.stopHangfire}>
                            <Translate value="controlCenter.stopHangfire" />
                            &nbsp;
                            <i className="fa fa-stop-circle" aria-hidden="true" />
              </Button>
            </InputGroup.Button>
            </Col>
        </Row>
        <Row className={`${css({ paddingTop: '30px', paddingLeft: '200px' })}`}>
          <Col sm={12}>
            <InputGroup.Button>
              <Button bsStyle="success" bsSize="small" onClick={this.props.allowlogin}>
                            <Translate value="controlCenter.allowLogin" />
                            &nbsp;
                            <i className="fa fa-play-circle" aria-hidden="true" />
              </Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button bsStyle="danger" bsSize="small" onClick={this.props.prohibitLogin}>
                            <Translate value="controlCenter.prohibitLogin" />
                            &nbsp;
                            <i className="fa fa-stop-circle" aria-hidden="true" />
              </Button>
            </InputGroup.Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startHangfire: () => dispatch(controlCenterActions.startHangfire.request()),
  stopHangfire: () => dispatch(controlCenterActions.stopHangfire.request()),
  allowLogin: () => dispatch(controlCenterActions.allowLogin.request()),
  prohibitLogin: () => dispatch(controlCenterActions.prohibitLogin.request()),
});

export default connect(
  null,
  mapDispatchToProps,
)(ControlCenter);
