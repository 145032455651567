import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { GeneralGrid, EditDeleteButtonRenderer, LinkCellRenderer, DateCellRenderer } from '../../_Common/Grids';
import { Row, Col, Grid } from 'react-bootstrap';
import StatusCellRenderer from './StatusCellRenderer';
import DurationCellRenderer from '../../_Common/Grids/DurationCellRenderer';
import { connect } from 'react-redux';

const OrderTaskList = props => {
  const {
    currentTasks,
    isLoading,
    getTasks,
    onDeleteButtonClick,
    onEditButtonClick,
    onEditSelectedButtonClick,
    onAddButtonClick,
    workflowStatusList,
    onChangeStatus,
    onRowDoubleClicked,
    showStatusHistory,
    orderId,
  } = props;

  const compareDueDate = (date1Str, date2Str) => {
    if (date1Str === null && date2Str === null) {
      return 0;
    }
    if (date1Str === null) {
      return 1;
    }
    if (date2Str === null) {
      return -1;
    }
    const date1 = Date.parse(date1Str);
    const date2 = Date.parse(date2Str);
    return date2 < date1 ? 1 : -1;
  };

  const getNumberFromStatus = (status) => {
    switch (status) {
      case 'TASK_INPROGRESS':
        return 1;
      case 'TASK_TODO':
        return 2;
      case 'TASK_ONHOLD':
        return 3;
      case 'TASK_CREATED':
        return 4;
      case 'TASK_DONE':
        return 5;
      case 'TASK_CANCELED':
        return 6;
      default:
        return 0;
    }
  };

  const compareStatus = (status1, status2) => {
    const valStatus1 = getNumberFromStatus(status1);
    const valStatus2 = getNumberFromStatus(status2);
    // eslint-disable-next-line no-nested-ternary
    return (valStatus1 < valStatus2 ? -1 : valStatus1 > valStatus2 ? 1 : 0);
  };

  const columns = [
    //{
      // headerName: I18n.t('orderTask.code'),
      // sort: 'desc',
      // field: 'ProductCode',
      // cellRendererFramework: LinkCellRenderer,
      // width: 75,
      // cellRendererParams: {
      //   textKey: 'ProductCode',
      //   linkKey: 'Url',
      //   downloadable: false
      // },
      // suppressSizeToFit: true,
    //}
    {
      headerName: I18n.t('orderTask.code'),
      field: 'ProductCode',
      valueGetter: ({ data }) => (data.ProductName ? data.ProductCode : I18n.t('orderProcess.unknown')),
      width: 100,
      cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    }
    ,   
    {
      headerName: I18n.t('orderTask.product'),
      field: 'ProductName',
      valueGetter: ({ data }) => (data.ProductName ? data.ProductName : I18n.t('orderProcess.unknown')),
      width: 200,
      cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    },
    {
      headerName: I18n.t('taskType.region'),
      field: 'RegionValue',
      width: 65,
    },
    {
      headerName: I18n.t('orderProcess.type'),
      field: 'ProcessTypeValue',
    },
    {
      headerName: I18n.t('orderTask.task'),
      field: 'TaskTypeName',
    },
    {
      headerName: I18n.t('orderTask.accountMgr'),
      field: 'AccountManager',
      width: 70,
    },
    {
      headerName: I18n.t('orderTask.expert'),
      field: 'Expert',
      width: 70,
    },
    {
      headerName: I18n.t('orderTask.status'),
      field: 'Status',
      suppressSizeToFit: true,
      width: 150,
      cellRendererFramework: StatusCellRenderer,
      cellRendererParams: {
        options: workflowStatusList,
        onChange: onChangeStatus,
        onHistoryClick: showStatusHistory,
      },
      comparator: compareStatus,
    },
    {
      headerName: I18n.t('toDoList.expectedDuration'),
      field: 'ExpectedDuration',
      width: 80,
      cellRenderer: DurationCellRenderer,
      suppressFilter: true,
    },
    {
      headerName: I18n.t('orderTask.dueDate'),
      field: 'DueDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      width: 85,
      comparator: compareDueDate,
      filter: false
    },
    {
      headerName: I18n.t('orderTask.memo'),
      field: 'Description',
    },

    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: EditDeleteButtonRenderer,
      suppressSizeToFit: true,
      width: 120,
      cellRendererParams: {
        onDelete: ({ TaskId }) => onDeleteButtonClick(TaskId),
        onEdit: ({ TaskId }) => onEditButtonClick(TaskId),
      },
    },
  ];

  currentTasks.forEach(element => {
    element.Url = '#products/' + element.ProductId;
  });

  
  return (
    <Grid fluid>
      <Row>
        <Col sm={12}>
          <GeneralGrid
            rowSelection= 'multiple'
            key={`task-${orderId}`}
            data={currentTasks}
            columns={columns}
            keyName="TaskId"
            isLoading={isLoading}
            onRefresh={getTasks}
            onAdd={onAddButtonClick}
            showAddButton
            rowHeight={30}
            rowDoubleClicked={onRowDoubleClicked}
            onExtraButtonClick={(rows) => {
              onEditSelectedButtonClick(rows.map(task => task.TaskId));
            }}
          />
        </Col>
      </Row>
    </Grid>
  );
};

OrderTaskList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentTasks: PropTypes.arrayOf(
    PropTypes.shape({
      TaskId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  getTasks: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onEditSelectedButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  workflowStatusList: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  workflowStatusNameById: PropTypes.object, //eslint-disable-line
};

export default connect(null, null)(OrderTaskList);
