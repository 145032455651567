import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { createSaveForm } from '../form/utils';
import formNames from '../../constants/formNames';
import substanceClpApi from '../../api/substanceClpApi';
import * as substanceClpTypes from './substanceClpTypes';
import * as substanceClpSchemas from './substanceClpSchemas';
import * as substanceClpActions from './substanceClpActions';

export default function* root() {
  yield all([
    takeLatest(substanceClpTypes.FETCH_SUBSTANCE_CLPS.REQUEST, getSubstanceClpsSaga),
    takeLatest(substanceClpTypes.SAVE_SUBSTANCE_CLP.REQUEST, saveSubstanceClpFormSaga),
  ]);
}

const saveSubstanceClpFormSaga = createSaveForm(formNames.substanceClp, saveSubstanceClpSaga);

export function* saveSubstanceClpSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));

  const { substanceId } = payload;
  yield callApiSaga(substanceClpActions.saveSubstanceClp, substanceClpApi.saveSubstanceClp, payload);

  yield put(substanceClpActions.fetchSubstanceClps.request({ substanceId }));

  yield put(applicationActions.unsetLoading(type));
}

export function* getSubstanceClpsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceClpList = yield call(substanceClpApi.getSubstanceClps, payload);
    const normalizedData = normalize(substanceClpList, substanceClpSchemas.substanceClpListSchema);
    yield put(substanceClpActions.fetchSubstanceClps.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceClpActions.fetchSubstanceClps.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
