import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { companySelectors } from '../../../redux/company';
import { SelectField } from '../Forms';

const CompanyListDropdown = props => {
  const { companyList, companyListFilter, sortByName, children } = props;

  const filterOptions = (...args) => sortByName(companyListFilter(...args));

  return children ? (
    children({ companyList, filterOptions })
  ) : (
    <SelectField
      options={companyList}
      labelField="ShortName"
      valueField="CompanyId"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  const companyList = initialOptions || companySelectors.getCompanyList(state);
  return {
    companyList,
    sortByName: companySelectors.sortByShortName,
    companyListFilter: companySelectors.makeGetSubstanceListFilter(companyList),
  };
};

CompanyListDropdown.defaultProps = {
  children: null,
};

CompanyListDropdown.propTypes = {
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      CompanyId: PropTypes.number.isRequired,
      ShortName: PropTypes.string,
    }),
  ).isRequired,
  companyListFilter: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  children: PropTypes.func,
};

export default connect(
  mapStateToProps,
  null,
)(CompanyListDropdown);
