import { createRequestTypes } from '../utils';

export const FORMULA_REVIEW_LIST = createRequestTypes('formulaReview/FORMULA_REVIEW_LIST');
export const FORMULA_REVIEW_FOR_PRODUCT_LIST = createRequestTypes(
  'formulaReview/FORMULA_REVIEW_FOR_PRODUCT_LIST',
  ProductId => ({ ProductId }),
);
export const FORMULA_REVIEW = createRequestTypes('formulaReview/FORMULA_REVIEW');
export const SAVE_FORMULA_REVIEW = createRequestTypes('formulaReview/SAVE_FORMULA_REVIEW');
export const FETCH_FORMULA_REVIEWS = createRequestTypes('formulaReview/FETCH_FORMULA_REVIEWS');
