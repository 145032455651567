import { schema } from 'normalizr';
import * as substanceProportionSchemas from '../substanceProportion/substanceProportionSchemas';

export const substanceSchema = new schema.Entity('substances', {}, { idAttribute: 'SubstanceId' });

export const substanceWithProportionSchema = new schema.Entity('substances', {
  SubstanceProportions: substanceProportionSchemas.substanceProportionWithSubstanceListSchema,
}, { idAttribute: 'SubstanceId' });

export const substanceListSchema = [substanceSchema];
