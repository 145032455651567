import { createRequestTypes } from '../utils';

export const FETCH_REPORT = createRequestTypes('reports/FETCH_REPORT');
export const FETCH_FORMULAREVIEW_REPORT = createRequestTypes('reports/FETCH_FORMULAREVIEW_REPORT');
export const FETCH_LABELREVIEW_REPORT = createRequestTypes('reports/FETCH_LABELREVIEW_REPORT');
export const FETCH_FORMULAREVIEW_COMPOREPORT = createRequestTypes('reports/FETCH_FORMULAREVIEW_COMPOREPORT');
export const FETCH_FORMULAREVIEW_QUICKREPORT = createRequestTypes('reports/FETCH_FORMULAREVIEW_QUICKREPORT');
export const FETCH_PRODUCT_REPORT_LIST = createRequestTypes('reports/FETCH_PRODUCT_REPORT_LIST');
export const FETCH_AGREEMENT_REPORT = createRequestTypes('reports/FETCH_AGREEMENT_REPORT');
export const FETCH_MANDATE_REPORT = createRequestTypes('reports/FETCH_MANDATE_REPORT');
export const FETCH_QUICKMANDATE_REPORT = createRequestTypes('reports/FETCH_QUICKMANDATE_REPORT');
export const FETCH_TOXPROFILE_REPORT = createRequestTypes('reports/FETCH_TOXPROFILE_REPORT');
