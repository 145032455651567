import { normalize } from 'normalizr';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { applicationActions } from '../application';
import shadeApi from '../../api/shadeApi';
import * as shadeInciListTypes from './shadeInciListTypes';
import * as shadeInciListSchemas from './shadeInciListSchemas';
import * as shadeInciListActions from './shadeInciListActions';

export default function* root() {
  yield all([takeLatest(shadeInciListTypes.SHADES_INCI_LIST_FOR_PRODUCT.REQUEST, getShadesInciListForProductSaga)]);
}

export function* getShadesInciListForProductSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { ProductId } = payload;
    const shadesInciList = yield call(shadeApi.getShadesWithInci, ProductId);
    const normalizedData = normalize(shadesInciList, shadeInciListSchemas.shadesWithInciListSchema);
    yield put(shadeInciListActions.getShadesInciListForProduct.success(normalizedData, ProductId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(shadeInciListActions.getShadesInciListForProduct.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
