import * as substanceFunctionSelectors from './substanceFunctionSelectors';
import * as substanceFunctionActions from './substanceFunctionActions';
import * as substanceFunctionTypes from './substanceFunctionTypes';
import sagas from './substanceFunctionSagas';
import reducer from './substanceFunctionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceFunctionSelectors, substanceFunctionActions, substanceFunctionTypes };
export default reducer;
