import * as safecosmeticDashboardSelectors from './safecosmeticDashboardSelectors';
import * as safecosmeticDashboardActions from './safecosmeticDashboardActions';
import * as safecosmeticDashboardTypes from './safecosmeticDashboardTypes';
import * as safecosmeticDashboardSchemas from './safecosmeticDashboardSchemas';

import sagas, * as safecosmeticDashboardSagas from './safecosmeticDashboardSagas';
import reducer from './safecosmeticDashboardReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { safecosmeticDashboardSelectors, safecosmeticDashboardActions, safecosmeticDashboardTypes, safecosmeticDashboardSchemas, safecosmeticDashboardSagas };
export default reducer;
