import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import queryString from 'query-string';
import { removeById } from '../utils';
import * as substanceCosAnnexeTypes from './substanceCosAnnexeTypes';
import * as inciListTypes from '../inciList/inciListTypes';

const byId = handleActions(
  {
    [combineActions(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS,
      substanceCosAnnexeTypes.SAVE_SUBSTANCE_COS_ANNEXE.SUCCESS,
    )]: (state, { payload, meta = {} }) => ({ ...state, ...payload.entities.substanceCosAnnexes }),
    [substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE.SUCCESS]: removeById,
    [inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.substanceCosAnnexes,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [combineActions(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS,
      substanceCosAnnexeTypes.SAVE_SUBSTANCE_COS_ANNEXE.SUCCESS,
    )]: (state, { payload, meta = {} }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
    [inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload, meta = {} }) =>
      (payload.entities.substanceCosAnnexes
        ? [...new Set([...state, ...Object.keys(payload.entities.substanceCosAnnexes).map(Number)])]
        : state),
    [substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

const loading = handleActions(
  {
    [combineActions(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.REQUEST,
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS,
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.REQUEST ? payload : meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: type === substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.REQUEST,
      };
    },
  },
  {},
);

const byQueryIds = handleActions(
  {
    [substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
    [substanceCosAnnexeTypes.SAVE_SUBSTANCE_COS_ANNEXE.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: [...new Set(Array.isArray(payload.result)
          ? [...state[querystring], ...payload.result]
          : [...state[querystring], payload.result])],
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
