import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Row, Col, Radio, ToggleButtonGroup, Checkbox } from 'react-bootstrap';
import { reportSelectors } from '../../../../redux/report';
import { css } from 'glamor';
import {referentialSelectors} from "../../../../redux/referentials";
import Select from 'react-select';
import regionCode from "../../../../constants/regionCode";

const DocumentItem = connect((state, { productId, documentName }) => {
  const getIsReportPrintable = reportSelectors.makeIsReportPrintable(state)(productId);
  return {
    disabled: !getIsReportPrintable(documentName),
  };
})(({ documentName, disabled, onChange }) => {
  const handleOnChange = e => onChange(e.target.value);
  return (
    <Radio value={documentName} disabled={disabled} name="documents" onChange={handleOnChange}>
      <Translate value={`report.${documentName}`} />
    </Radio>
  );
});

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    '& .btn-group': {
      width: '100%',
    },
    '& hr': {
      marginTop: '10px',
      marginBottom: '10px'
    }
  }),
  errorContainer: css({
    display: 'flex',
    justifyContent: 'center',
    color: 'red',
  }),
};

const DocumentList = props => {
  const {
    onChange,
    docs0,
    docs1,
    docs2,
    docs3,
    docs4,
    docs5,
    docs6,
    docs7,
    productId,
    selectedRegion,
    selectedCountry,
    includeInci,
    onIncludeInciChanged,
    compositionInInci,
    onCompositionInInciChanged,
    onRegionSelected,
    onCountrySelected,
    regions,
    countries,
    message
  } = props;

  return (
    <div className={styles.container}>
      <ToggleButtonGroup  name="documents" type="radio">
        <Row>
          <br />
          {docs0.map(({ name }) => (
            <Col xs={6} key={`col0${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <hr/>
        <Row>
          <br />
          <Col xs={3}>
            <Select
              options={regions}
              labelKey="Value"
              valueKey="Key"
              value={selectedRegion}
              onChange={onRegionSelected}
            />
          </Col>
          <Col xs={3}>
            <Select
              options={selectedRegion ? countries.filter(elem => elem.RegionCode === selectedRegion) : countries}
              labelKey="Value"
              valueKey="Key"
              value={selectedCountry}
              onChange={onCountrySelected}
              clearable="true"
              disabled={selectedRegion !== regionCode.ASEAN}
            />
          </Col>
        </Row>
        <Row>
          <br/>
          <div className={styles.errorContainer}>{message}</div>
        </Row>
        <Row>
          <br />
          {docs1.map(({ name }) => (
            <Col xs={3} key={`col1${name}`}>
              <DocumentItem
                key={name}
                documentName={name}
                productId={productId}
                onChange={onChange}
                style="display:float-left"
              />
            </Col>
          ))}
        </Row>
        <Row>
          <br />
          {docs2.map(({ name }) => (
            <Col xs={3} key={`col2${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={3} key={`checkCol2`}>
            <Checkbox
              onChange={({ target }) => {
                onIncludeInciChanged(target.checked);
              }}
              checked={includeInci}>
              Include INCI
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <br />
          {docs3.map(({ name }) => (
            <Col xs={4} key={`col3${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={3}>
            <Checkbox
              onChange={({ target }) => {
                onCompositionInInciChanged(target.checked);
              }}
              checked={compositionInInci}>
              Composition in INCI
            </Checkbox>
          </Col>
        </Row>
        <hr/>
        <Row>
          {docs4.map(({ name }) => (
            <Col xs={4} key={`col4${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <Row>
          <br />
          {docs5.map(({ name }) => (
            <Col xs={4} key={`col5${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <Row>
          <br />
          {docs6.map(({ name }) => (
            <Col xs={4} key={`col6${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>
        <Row>
          <br />
          {docs7.map(({ name }) => (
            <Col xs={4} key={`col7${name}`}>
              <DocumentItem key={name} documentName={name} productId={productId} onChange={onChange} />
            </Col>
          ))}
        </Row>{' '}
      </ToggleButtonGroup>
    </div>
  );
};

DocumentList.defaultProps = {
  documents: [],
  docs0: [],
  docs1: [],
  docs2: [],
  docs3: [],
  docs4: [],
  docs5: [],
  docs6: [],
};

DocumentList.propTypes = {
  onChange: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  docs0: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs1: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs2: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs3: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs4: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs5: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  docs6: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  selectedRegion: PropTypes.string,
  onRegionSelected: PropTypes.func,
  selectedCountry: PropTypes.string,
  onCountrySelected: PropTypes.func,
  message: PropTypes.string,
  includeInci: PropTypes.bool,
  onIncludeInciChanged: PropTypes.func,
  compositionInInci: PropTypes.string,
  onCompositionInInciChanged: PropTypes.func,
};

const mapStateToProps = () => state => ({
  documents: reportSelectors.getProductReports(state),
  docs0: reportSelectors.getProductReports(state).filter(item => item.row === 0),
  docs1: reportSelectors.getProductReports(state).filter(item => item.row === 1),
  docs2: reportSelectors.getProductReports(state).filter(item => item.row === 2),
  docs3: reportSelectors.getProductReports(state).filter(item => item.row === 3),
  docs4: reportSelectors.getProductReports(state).filter(item => item.row === 4),
  docs5: reportSelectors.getProductReports(state).filter(item => item.row === 5),
  docs6: reportSelectors.getProductReports(state).filter(item => item.row === 6),
  docs7: reportSelectors.getProductReports(state).filter(item => item.row === 7),
  regions: referentialSelectors.getRegions(state),
  countries: referentialSelectors.getCountryList(state),
});

export default connect(mapStateToProps)(DocumentList);
