import { takeLatest, put, all, call, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga, createFetchSaga } from '../sagaUtils';
import { formulaReviewTypes, formulaReviewActions, formulaReviewSchemas } from './index';

import formulaReviewApi from '../../api/formulaReviewApi';

export default function* root() {
  yield all([
    takeLatest(formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST.REQUEST, getFormulaReviewListForProductSaga),
    takeLatest(formulaReviewTypes.SAVE_FORMULA_REVIEW.REQUEST, saveFormulaReviewSaga),
    takeEvery(formulaReviewTypes.FETCH_FORMULA_REVIEWS.REQUEST, fetchFormulaReviewSaga),
  ]);
}

export const fetchFormulaReviewSaga = createFetchSaga(
  function* fetchFormulaReviewSaga({ payload, type }) {
    const formulaReviews = yield call(formulaReviewApi.fetchFormulaReviews, payload);
    const schema = Array.isArray(formulaReviews)
      ? formulaReviewSchemas.formulaReviewListSchema
      : formulaReviewSchemas.formulaReviewSchema;
    const normalizedData = normalize(formulaReviews, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(formulaReviewActions.fetchFormulaReviews.success(data, payload));
    },
    onFailure: (error, payload) => put(formulaReviewActions.fetchFormulaReviews.failure(error, payload)),
  },
);

function* getFormulaReviewListForProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    formulaReviewActions.listFormulaReviewForProduct,
    formulaReviewApi.getFormulaReviews,
    formulaReviewSchemas.formulaReviewListSchema,
    {
      ...payload,
    },
  );

  yield put(applicationActions.unsetLoading(type));
}

function* saveFormulaReviewSaga({ payload, type }) {
  const { ProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(formulaReviewActions.saveFormulaReview, formulaReviewApi.saveFormulaReview, payload);
  yield put(formulaReviewActions.fetchFormulaReviews.request({ ProductId }));
  yield put(applicationActions.unsetLoading(type));
}
