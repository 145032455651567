import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ActionHistoryModal from './ActionHistoryModal';
import { taskActionActions, taskActionSelectors, taskActionTypes } from '../../../../redux/taskAction';
import { applicationSelectors } from '../../../../redux/application';
import { actionSelectors } from '../../../../redux/action';

class TaskActionsContainer extends React.Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (<ActionHistoryModal height="60vh" {...this.props} />);
  }
}

const mapStateToProps = (state, { TaskId }) => ({
  taskActionList: taskActionSelectors.getTaskActionForTask(state, TaskId),
  isLoading: applicationSelectors.isLoading(state, taskActionTypes.TASK_ACTION_LIST.REQUEST, taskActionTypes.SAVE_TASK_ACTION.REQUEST),
  actionList: actionSelectors.getActionListForEntityNotInWorkflow('task')(state),
});

const mapDispatchToProps = (dispatch, { TaskId }) => ({
  onLoad: () => dispatch(taskActionActions.listTaskActions.request(TaskId)),
  getTaskActionList: () => dispatch(taskActionActions.listTaskActions.request(TaskId)),
  onAdd: data => dispatch(taskActionActions.saveTaskAction.request(TaskId, data)),
});

TaskActionsContainer.propTypes = {
  TaskId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskActionsContainer);
