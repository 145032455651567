import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid } from '../../_Common/Grids';
import ActionButtonsRenderer from './ActionButtonsRenderer';
import WithPrivileges from '../../_Common/WithPrivileges';
import userActionsCodes from '../../../constants/userActionsCodes';

const CosAnnexeList = props => {
  const {
    cosAnnexeList,
    isLoading,
    getCosAnnexeList,
    onEditButtonClick,
    onAddButtonClick,
    onDetailsButtonClick,
    onDeleteButtonClick,
    onTranslateClick,
  } = props;

  return (
    <WithPrivileges requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        const columns = [
          {
            headerName: I18n.t('cosAnnexe.Code'),
            field: 'Code',
            sort: 'asc',
          },
          {
            headerName: I18n.t('cosAnnexe.Region'),
            field: 'RegionCode',
            sort: 'asc',
          },
          {
            headerName: I18n.t('cosAnnexe.Country'),
            field: 'CountryAlpha2',
            sort: 'asc',
          },
          {
            headerName: I18n.t('cosAnnexe.Name'),
            field: 'Name',
          },
          {
            headerName: I18n.t('cosAnnexe.Reference'),
            field: 'Reference',
          },
          {
            headerName: I18n.t('cosAnnexe.MaxUse'),
            field: 'MaxUse',
          },
          {
            headerName: I18n.t('cosAnnexe.CalculationCondition'),
            field: 'CalculationCondition',
          },
          // {
          //   headerName: I18n.t('cosAnnexe.UseConditionWording'),
          //   field: 'UseConditionWording',
          // },
          // {
          //   headerName: I18n.t('cosAnnexe.Warning'),
          //   field: 'Warning',
          // },
          // {
          //   headerName: I18n.t('cosAnnexe.SccsOpinions'),
          //   field: 'SccsOpinions',
          // },
          // {
          //   headerName: I18n.t('cosAnnexe.OtherRestrictions'),
          //   field: 'OtherRestrictions',
          // },
          // {
          //   headerName: I18n.t('cosAnnexe.OtherRemarks'),
          //   field: 'OtherRemarks',
          // },
          {
            headerName: I18n.t('cosAnnexe.UsedIn'),
            field: 'UsedIncosAnnexeType.Description',
          },
          {
            headerName: I18n.t('cosAnnexe.NotUsedIn'),
            field: 'NotUsedIncosAnnexeType.Description',
          },
          {
            headerName: I18n.t('general.actions'),
            hide: !isAllowedToEditAdd,
            width: 240,
            minWidth: 240,
            cellRendererFramework: ActionButtonsRenderer,
            cellRendererParams: {
              onEdit: onEditButtonClick,
              onViewDetails: onDetailsButtonClick,
              onDelete: onDeleteButtonClick,
              onTranslateClick: onTranslateClick,
            },
          },
        ];

        return (
          <SelectionGrid
            data={cosAnnexeList}
            columns={columns}
            onRefresh={getCosAnnexeList}
            showLoadingIcon={isLoading}
            keyName="CosAnnexeId"
            showAddButton={isAllowedToEditAdd}
            showFilterRow
            onAdd={onAddButtonClick}
          />
        );
      }}
    </WithPrivileges>
  );
};

CosAnnexeList.propTypes = {
  cosAnnexeList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDetailsButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  getCosAnnexeList: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default CosAnnexeList;
