import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as rawMatDocumentTypes from '../rawMatDocument/rawMatDocumentTypes';
import * as companyDocumentTypes from '../companyDocument/companyDocumentTypes';
import * as productDocumentTypes from '../productDocument/productDocumentTypes';
import * as substanceDocumentTypes from '../substanceDocument/substanceDocumentTypes';
import * as mandateTypes from '../mandate/mandateTypes';
import * as companyDistributorAgreementTypes from '../companyDistributorAgreement/companyDistributorAgreementTypes';
import {documentTypes} from "./index";

const byId = handleActions(
  {
    [combineActions(
      rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.SUCCESS,
      companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY.SUCCESS,
      productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT.SUCCESS,
      substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.SUCCESS,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS,
      mandateTypes.FETCH_MANDATES.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      ...payload.entities.documents,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [combineActions(
      rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.SUCCESS,
      companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY.SUCCESS,
      productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT.SUCCESS,
      substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.SUCCESS,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS,
      mandateTypes.FETCH_MANDATES.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.documents) {
        return [...new Set([...state, ...Object.keys(payload.entities.documents).map(Number)])];
      }
      return state;
    },
  },
  [],
);

const imageById = handleActions(
  {
    [combineActions(
      documentTypes.GET_IMAGE_DOCUMENT.SUCCESS,
    )]: (state, { payload }) => {
      return ({
        ...state,
        [payload.DocumentId]: payload.Content,
      })
    },
  },
  {},
);


export default combineReducers({
  byId,
  imageById,
  list: combineReducers({
    allIds,
  }),
});
