import React from 'react';
import { Col, Row, ControlLabel, Media } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import clpImages from '../../../../assets/img/clp';
import images from '../../../../assets/img';
import { InputField, CheckBoxField } from '../../../_Common/Forms';
import CheckBoxSymbol from './CheckBoxSymbol';
import { requiredField } from '../../../../utils/fieldValidation';
import { createForm } from './utils';
import PaoHourglassTypeSelectdField from './PaoHourglassTypeSelectdField';

const InnerLabelForm = props => (
  <Col sm={12}>
    <Col sm={6}>
      <Col sm={11}>
        <Row>
          <Field name="HasInnerLabel" label={I18n.t('draftLabel.HasInnerLabel')} component={CheckBoxField} />
        </Row>
        <Row>
          <Field name="ProductName" label={I18n.t('draftLabel.ProductName')} component={InputField} />
        </Row>
        <Row>
          <Field name="ProductFunction" label={I18n.t('draftLabel.ProductFunction')} component={InputField} />
        </Row>
        <Row>
          <Field name="NominalContents" label={I18n.t('draftLabel.NominalContents')} component={InputField} />
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsInstructionOnInner"
              label={I18n.t('draftLabel.IsInstructionOnInner')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="InstructionText"
              label={I18n.t('draftLabel.InstructionText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsMarketingtextOnInner"
              label={I18n.t('draftLabel.IsMarketingtextOnInner')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="MarketingTextOnInner"
              label={I18n.t('draftLabel.MarketingTextOnInner')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Field name="OriginInnerText" label={I18n.t('draftLabel.OriginInnerText')} component={InputField} />
        </Row>
        <Row>
          <Field
            name="CompanyInnerText"
            label={I18n.t('draftLabel.CompanyInnerText')}
            component={InputField}
            componentClass="textarea"
          />
        </Row>
        <Row>
          <Field
            name="IsIngredientOnInner"
            label={I18n.t('draftLabel.IsIngredientOnInner')}
            component={CheckBoxField}
          />
        </Row>
      </Col>
    </Col>
    <Col sm={6}>
      <Col sm={11} smOffset={1}>
        <Row>
          <Col sm={4}>
            <Media>
              <Media.Body>
                <Field
                  name="IsHairColorAttentionSymbolOnInner"
                  label={I18n.t('draftLabel.IsHairColorAttentionSymbolOnInner')}
                  component={CheckBoxField}
                />
              </Media.Body>
              <Media.Left align="middle">
                <img src={images.hairColorAttention} alt="hairColorAttention" />
              </Media.Left>
            </Media>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field name="IsWarningOnInner" label={I18n.t('draftLabel.IsWarningOnInner')} component={CheckBoxField} />
          </Col>
          <Col sm={9}>
            <Field
              name="WarningText"
              label={I18n.t('draftLabel.WarningText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <ControlLabel>{I18n.t('draftLabel.ClpSymboles')}</ControlLabel>
        </Row>
        <Row>
          <Col sm={2}>
            <Field name="IsFlammableSymbolOnInner" component={CheckBoxSymbol} imageSrc={clpImages.flammable} />
          </Col>
          <Col sm={2}>
            <Field name="IsCombustibleSymbolOnInner" component={CheckBoxSymbol} imageSrc={clpImages.oxidising} />
          </Col>
          <Col sm={2}>
            <Field name="IsCorrosiveSymbolOnInner" component={CheckBoxSymbol} imageSrc={clpImages.corrosive} />
          </Col>
          <Col sm={2}>
            <Field name="IsIrritantSymbolOnInner" component={CheckBoxSymbol} imageSrc={clpImages.irritant} />
          </Col>
          <Col sm={2}>
            <Field
              name="IsAllergenSymbolOnInner"
              component={CheckBoxSymbol}
              imageSrc={clpImages.inhalationSensitizer}
            />
          </Col>
          <Col sm={2}>
            <Field name="IsEnvironmentSymbolOnInner" component={CheckBoxSymbol} imageSrc={clpImages.environment} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsTranslationOnInner"
              label={I18n.t('draftLabel.IsTranslationOnInner')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="TranslationText"
              label={I18n.t('draftLabel.TranslationText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field name="IsLeafletOnInner" label={I18n.t('draftLabel.IsLeafletOnInner')} component={CheckBoxField} />
            <Field
              name="IsLeafletMandatoryOnInner"
              label={I18n.t('draftLabel.IsLeafletMandatoryOnInner')}
              component={CheckBoxField}
              formAlignment={false}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="LeafletSymbolTextOnInner"
              label={
                <div>
                  {I18n.t('draftLabel.LeafletSymbolTextOnInner')}{' '}
                  <img
                    style={{ width: '30px' }}
                    src={images.leaflet}
                    alt={I18n.t('draftLabel.LeafletSymbolTextOnInner')}
                  />
                </div>
              }
              placeholder={I18n.t('draftLabel.LeafletSymbolTextOnInner')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsPAOSymbolOnInner"
              label={I18n.t('draftLabel.IsPAOSymbolOnInner')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="PAOSymbolRemarkOnInner"
              label={I18n.t('draftLabel.PAOSymbolRemarkOnInner')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
          <Row>
            <Col sm={12}>
              <Field
                component={PaoHourglassTypeSelectdField}
                name="PAOHourglassTypeCode"
                label={I18n.t('draftLabel.PAOHourglassTypeCode')}
                validate={requiredField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field name="IsGreenDotSymbolOnInner" component={CheckBoxSymbol} imageSrc={images.greendot} />
            </Col>
            <Col sm={2}>
              <Field name="IsRecyclingSymbolOnInner" component={CheckBoxSymbol} imageSrc={images.recycling} />
            </Col>
            <Col sm={2}>
              <Field name="IsFSCSymbolOnInner" component={CheckBoxSymbol} imageSrc={images.fsc} />
            </Col>
            <Col sm={4}>
              <Field
                name="IsBunnySymbolOnInner"
                component={CheckBoxSymbol}
                imageSrc={[images.leapingBunny, images.bunny]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="BatchCodeRemarkOnInner"
                label={I18n.t('draftLabel.BatchCodeRemarkOnInner')}
                component={InputField}
                componentClass="textarea"
              />
            </Col>
          </Row>
        </Row>
      </Col>
    </Col>
  </Col>
);

export default createForm(InnerLabelForm);
