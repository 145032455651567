import { createRequestActions } from '../utils';
import * as substanceProductTypes from './substanceProductTypes';

export const fetchProductsForSubstance = createRequestActions(
  substanceProductTypes.FETCH_PRODUCTS_FOR_SUBSTANCE,
  SubstanceId => ({
    SubstanceId,
  }),
  (result, SubstanceId) => ({
    SubstanceId,
  }),
);
