import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import NotificationList from './NotificationList';
import BaseLoader from '../../_Common/BaseLoader';
import LoadingButton from '../../_Common/LoadingButton';

const NotificationTab = props => {
  const { isLoading, ...otherProps } = props;

  return (
    <BaseLoader isLoading={isLoading}>
      {/* <div>
        <LoadingButton type="button" bsSize="small" onClick={onNewNotificationClick} isLoading={isLoadingNewWizard}>
          <Translate value="product.addNotification" />
        </LoadingButton>
      </div>
      <br /> */}
      <div>
        <NotificationList {...otherProps} />
      </div>
    </BaseLoader>
  );
};

NotificationTab.propTypes = {
  onNewNotificationClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditLoading: PropTypes.bool.isRequired,
};

export default NotificationTab;
