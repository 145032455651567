import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import {Translate} from 'react-redux-i18n';
import {Row, Col, Panel, Button} from 'react-bootstrap';
import { css } from 'glamor';
import {phraseSelectors} from "../../../redux/phrase";
import CustomSimpleField from "../../_Common/Forms/Custom/CustomSimpleField";

const styles = {
  phraseRender: css({
    display: 'flex',
  }),
};

const PhraseTRForm = props => {
  const {
    phrase,
    formValues,
    onAddButtonClick,
    selectedLanguage,
    phraseCode,
    change,
  } = props;

  const data = formValues[phraseCode][selectedLanguage];

  return (
    <Row key={phrase.PhraseId}>
      <Col sm={1}>
        <div className={styles.phraseRender}>
          {phrase.PhraseCode}:
        </div>
      </Col>
      <Col sm={5}>
        <Panel bsStyle="primary">
          <div className={styles.phraseRender}>
            {phrase.Text}
          </div>
        </Panel>
      </Col>
      {selectedLanguage && selectedLanguage !== "" && (
        <Col md={6}>
          {data ? (
            <Col sm={10}>
              <CustomSimpleField
                name={`${phraseCode}.${selectedLanguage}.Text`}
                component="textarea"
                value={formValues[phraseCode][selectedLanguage].Text}
                onChange={(newValue) => change(`${phraseCode}.${selectedLanguage}.Text`, newValue)}
              />
            </Col>
          ) : (
            <Col sm={2} smOffset={10}>
              <Button
                bsSize="small"
                bsStyle="info"
                type="button"
                onClick={() => onAddButtonClick({phraseCode, selectedLanguage})}
              >
                <Translate value="phraseTranslation.add" />
              </Button>
            </Col>
          )}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state, {phraseCode}) => {
  const phrase = phraseSelectors.getPhraseByCode(state, phraseCode);
  return {
    phrase,
  };
};

PhraseTRForm.propTypes = {
  phraseCode: PropTypes.string.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(PhraseTRForm);
