import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PhraseListModal from './PhraseListModal';
import { phraseActions, phraseTypes } from '../../../../redux/phrase';
import { phraseListModalActions, phraseListModalSelectors } from '../../../../redux/modal/PhraseListModal';
import { productPhraseSelectors } from '../../../../redux/productPhrase';
import { applicationSelectors } from '../../../../redux/application';
import { phraseCpsrChapterSelectors } from '../../../../redux/phraseCpsrChapter';
import {cpsrChaptersActions, cpsrChaptersSelectors} from "../../../../redux/cpsrChapters";

class PhraseListContainer extends React.Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <PhraseListModal {...this.props} />;
  }
}

const mapStateToProps = (state, { CpsrTypeCode, ProductPhraseId }) => {
  const CpsrChapterCode = R.compose(R.propOr('', 'CpsrChapterCode'), R.propOr({}, ProductPhraseId))(
    productPhraseSelectors.getById(state),
  );

  const getIsLoadingPhraseCpsrChapters = phraseCpsrChapterSelectors.makeGetByQueryLoading({ CpsrChapterCode });

  return {
    editMode: !!ProductPhraseId,
    phraseList: phraseListModalSelectors.makeGetPhraseList({ ProductPhraseId, CpsrTypeCode })(state),
    cpsrChapters: cpsrChaptersSelectors.getCpsrChapterList(state),
    filter: phraseListModalSelectors.getFilter(state),
    isLoading: applicationSelectors.getIsLoading(phraseTypes.PHRASE_LIST.REQUEST, getIsLoadingPhraseCpsrChapters)(
      state,
    ),
  };
};

const mapDispatchToProps = (dispatch, { ProductId, CpsrTypeCode, formName, ProductPhraseId }) => ({
  onLoad: () => {
    dispatch(phraseListModalActions.onLoad({ ProductPhraseId }));
    dispatch(cpsrChaptersActions.listCpsrChapter.request());
  },
  getPhraseList: () => dispatch(phraseActions.listPhrases.request()),
  onChangeFilter: value => dispatch(phraseListModalActions.setFilter(value ? 'all' : CpsrTypeCode)),
  onSubmitButtonClick: (PhraseId, CpsrChapterCode ) =>
    dispatch(phraseListModalActions.submit({ PhraseId, CpsrTypeCode, ProductId, formName, ProductPhraseId, CpsrChapterCode })),
});

PhraseListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhraseListContainer);
