import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { createForm, renderField } from './utils';

const IrritationAndPhototoxForm = () => (
  <div>
    <Row>
      <Col sm={6}>{renderField('EyeIrritation')}</Col>
      <Col sm={6}>{renderField('Sensitization')}</Col>
    </Row>
    <Row>
      <Col sm={6}>{renderField('SkinIrritation')}</Col>
      <Col sm={6}>{renderField('Phototoxicity')}</Col>
    </Row>
  </div>
);

IrritationAndPhototoxForm.propTypes = {};

export default createForm(IrritationAndPhototoxForm);
