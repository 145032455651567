import * as substanceCosAnnexeSelectors from './substanceCosAnnexeSelectors';
import * as substanceCosAnnexeActions from './substanceCosAnnexeActions';
import * as substanceCosAnnexeTypes from './substanceCosAnnexeTypes';
import * as substanceCosAnnexeSchemas from './substanceCosAnnexeSchemas';
import sagas, * as substanceCosAnnexeSagas from './substanceCosAnnexeSagas';
import reducer from './substanceCosAnnexeReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  substanceCosAnnexeSelectors,
  substanceCosAnnexeActions,
  substanceCosAnnexeTypes,
  substanceCosAnnexeSchemas,
  substanceCosAnnexeSagas,
};
export default reducer;
