import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import impactAssessmentActionApi from '../../api/impactAssessmentActionApi';
import * as impactAssessmentActionTypes from './impactAssessmentActionTypes';
import * as impactAssessmentActionSchemas from './impactAssessmentActionSchemas';
import * as impactAssessmentActionActions from './impactAssessmentActionActions';

export default function* root() {
  yield all([
    takeLatest(impactAssessmentActionTypes.FETCH_IMPACT_ASSESSMENT_ACTIONS.REQUEST, getImpactAssessmentActionsSaga),
  ]);
}

export function* getImpactAssessmentActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const impactAssessmentActionList = yield call(impactAssessmentActionApi.getImpactAssessmentActions, payload);
    const normalizedData = normalize(
      impactAssessmentActionList,
      impactAssessmentActionSchemas.impactAssessmentActionListSchema,
    );
    yield put(impactAssessmentActionActions.fetchImpactAssessmentActions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(impactAssessmentActionActions.fetchImpactAssessmentActions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
