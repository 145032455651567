import React from 'react';
import { I18n } from 'react-redux-i18n';
import { propOr } from 'ramda';
import { connect } from 'react-redux';
import { productSelectors } from '../../../../redux/product';
import ShadeXInciCompoModal from './ShadeXInciCompoModal';

const ShadeXInciCompoModalContainer = props => <ShadeXInciCompoModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = propOr('', 'ProductName', product);
  return {
    title: I18n.t('shadeCompoInciModal.title', { productName }),
  };
};

export default connect(mapStateToProps)(ShadeXInciCompoModalContainer);
