import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import IngredientList from './IngredientList';
import { ingredientActions, ingredientTypes, ingredientSelectors } from '../../redux/ingredient';
import { applicationSelectors } from '../../redux/application';

class IngredientsPageContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    // console.log('IngredientPageContainer Mounted');
    this.props.onLoad();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log('IngredientPageContainer updated');
  // }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <IngredientList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ingredientList: ingredientSelectors.getIngredientList(state),
    requestingIngredientList: applicationSelectors.isLoading(state, ingredientTypes.INGREDIENT_LIST.REQUEST),
  };
};

function mapDispatchToProps(dispatch, { location }) {
  return {
    onLoad: () => dispatch(ingredientActions.listIngredients.request()),
    onIngredientSelected: id => {
      dispatch(push({ ...location, pathname: `/ingredients/${id}` }));
    },
    onAddIngredient: () => dispatch(push('/ingredients/-1')),
    onRefreshList: () => dispatch(ingredientActions.listIngredients.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsPageContainer);
