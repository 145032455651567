import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Translate} from "react-redux-i18n";
import React from "react";
import {css} from "glamor";

const styles = {
  buttonDisable: css({
    '& .btn[disabled]': {
      opacity: 0.35,
    },
  }),
};

const ActionButtonsRenderer = props => {
  const {
    onMarkAsReceivedClick,
    getIsReceivedButtonDisabled,
    onMarkAsVerifiedClick,
    getIsVerifiedButtonDisabled,
    data,
  } = props;

  const handleButtonClick = fn => () => {
    fn(data.text);
  };

  const receivedButtonDisabled = getIsReceivedButtonDisabled(data);
  const verifiedButtonDisabled = getIsVerifiedButtonDisabled(data);

  const idReceived = "tooltipReceived" + data.text;
  const idVerified = "tooltipVerified" + data.text;

  return (
    <div className={styles.buttonDisable}>
      <ButtonToolbar>
        <OverlayTrigger placement="left" overlay={
          <Tooltip id={idReceived}>
            <Translate value="lastFRCheckList.received"/>
          </Tooltip>
        }>
          <Button
            bsStyle="info"
            bsSize="xsmall"
            type="button"
            disabled={receivedButtonDisabled}
            onClick={handleButtonClick(onMarkAsReceivedClick)}
          >
            <i className="fa fa-download" aria-hidden="true"/>
            &nbsp;
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement="right" overlay={
          <Tooltip id={idVerified}>
            <Translate value="lastFRCheckList.verified"/>
          </Tooltip>
        }>
          <Button
            bsSize="xsmall"
            bsStyle="success"
            type="button"
            disabled={verifiedButtonDisabled}
            onClick={handleButtonClick(onMarkAsVerifiedClick)}
          >
            <i className="fa fa-check" aria-hidden="true"/>
            &nbsp;
          </Button>
        </OverlayTrigger>
      </ButtonToolbar>
    </div>
  );
};

export default ActionButtonsRenderer;
