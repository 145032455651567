import * as actionSelectors from './actionSelectors';
import * as actionActions from './actionActions';
import * as actionTypes from './actionTypes';
import * as actionSchemas from './actionSchemas';
import sagas from './actionSagas';
import reducer from './actionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { actionSelectors, actionActions, actionTypes, actionSchemas };
export default reducer;
