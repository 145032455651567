import * as R from 'ramda';
import { get, post, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/ShadeProportions/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getShadeProportions: R.compose(
    get,
    getUrl,
  ),
  // getShadeProportions: query => get(getUrl(query))
  saveShadeProportion: data => post(defaultUrl, data),
};
