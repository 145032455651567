import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { createSaveForm } from '../form/utils';
import sessionApi from '../../api/sessionApi';
import * as sessionTypes from './sessionTypes';
import * as sessionActions from './sessionActions';
// import { taskListSchema } from './taskSchemas';
// import formNames from '../../constants/formNames';
import { callApiSaga, createFetchSaga } from '../sagaUtils';

export default function* root() {
  yield all([
    // takeLatest(taskTypes.TASK_LIST.REQUEST, getTaskListSaga),
    // takeLatest(taskTypes.SAVE_TASK.REQUEST, saveTaskFormSaga),
    // takeLatest(taskTypes.DELETE_TASK.REQUEST, deleteTaskSaga),
    // takeLatest(taskTypes.UPDATE_TASK_STATUS.REQUEST, updateTaskStatusSaga),
    takeLatest(sessionTypes.START_SESSION.REQUEST, startSessionSaga),
    takeLatest(sessionTypes.STOP_SESSION.REQUEST, stopSessionSaga),
  ]);
}

export function* startSessionSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { searchCriteria, session } = payload;
  yield callApiSaga(sessionActions.startSession, sessionApi.startSession, session);
  // yield put(taskActions.listTasks.request(searchCriteria));
  yield put(applicationActions.unsetLoading(type));
}

export function* stopSessionSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { searchCriteria, session } = payload;
  yield callApiSaga(sessionActions.stopSession, sessionApi.stopSession, session);
  // yield put(taskActions.listTasks.request(searchCriteria));
  yield put(applicationActions.unsetLoading(type));
}
// const saveTaskFormSaga = createSaveForm(formNames.task, saveTaskSaga);

// function* getTaskListSaga({ payload, type }) {
//   try {
//     yield put(applicationActions.setLoading(type));
//     const tasks = yield call(taskApi.getTasks, payload);
//     const normalizedData = normalize(tasks, taskListSchema);
//     yield put(taskActions.listTasks.success(normalizedData, payload));
//   } catch (error) {
//     if (error.Message) {
//       const message = error.Message;
//       yield put(applicationActions.setGeneralError(message));
//       yield put(taskActions.listTasks.failure(error));
//     }
//   }
//   yield put(applicationActions.unsetLoading(type));
// }

// function* deleteTaskSaga({ payload, type }) {
//   const { taskId, orderId } = payload;
//   yield put(applicationActions.setLoading(type));
//   yield callApiSaga(taskActions.deleteTask, taskApi.deleteTask, taskId);
//   yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
//   yield put(applicationActions.unsetLoading(type));
// }

// export function* saveTaskSaga({ type, payload }) {
//   console.log('date', payload.DueDate);
//   yield put(applicationActions.setLoading(type));
//   const { orderId } = payload;
//   yield callApiSaga(taskActions.saveTask, taskApi.saveTask, payload);

//   const modalType = yield select(modalSelectors.getModalType);
//   if (modalType === modalTypes.TASK_FORM) {
//     yield put(modalActions.hideModal());
//   }
//   if (payload.searchCriteria) {
//     yield put(taskActions.listTasks.request(payload.searchCriteria));
//   } else {
//     yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
//   }
//   yield put(applicationActions.unsetLoading(type));
// }

// const updateTaskStatusSaga = createFetchSaga(
//   function* updatePTaskStatusSaga({ payload, type }) {
//     const id = yield callApiSaga(taskActions.saveTask, taskApi.updateStatus, payload);
//     return id;
//   },
//   {
//     * onSuccess(id, payload) {
//       yield put(taskActions.saveTask.success(id, payload));
//       const isModalShown = yield select(modalSelectors.isModalShown);
//       if (isModalShown) {
//         yield put(modalActions.hideModal());
//       }
//       if (id !== undefined) {
//         yield put(taskActions.listTasks.request(payload.searchCriteria));
//       }
//     },
//     onFailure: (error, payload) => put(taskActions.saveTask.failure(error, payload)),
//   },
// );


