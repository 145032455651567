import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../Grids/SelectionGrid';
import { categorySelectors } from '../../../redux/category';

const columns = [
  {
    headerName: I18n.t('general.code'),
    width: 100,
    field: 'Code',
    suppressSizeToFit: true,
  },
  {
    headerName: I18n.t('general.description'),
    field: 'CategoryDescription',
  },
];

const CategoryListModal = props => {
  const { categoryList } = props;
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Category List</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SelectionGrid
          data={categoryList}
          columns={columns}
          keyName="CategoryId"
          height="70vh"
        />
      </Modal.Body>
    </div>
  );
};

const mapStateToProps = state => ({
  categoryList: categorySelectors.getCategoryList(state),
});

CategoryListModal.propTypes = {
  categoryList: PropTypes.array.isRequired, //eslint-disable-line
};

export default connect(mapStateToProps, null)(CategoryListModal);
