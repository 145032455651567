import { I18n } from 'react-redux-i18n';
import en from './en';
import fr from './fr';

const env = process.env.NODE_ENV;

const defaultLanguage = 'en';

const fallbackTranslationMissing = (key, replacements) => `Missing translation: ${key}`;

export const setFallbackTranslation = () => {
  I18n.setHandleMissingTranslation(fallbackTranslationMissing);
};

const data = {
  en,
  fr,
};

const locales = {
  defaultLanguage,
  data,
};

if (env !== 'production') {
  window.getTranslations = () => {};
}

export default locales;
