import queryString from 'query-string';
import { post, get, del } from './apiUtils';

const defaultUrl = '/Sessions';
// const actionUrl = '/Actions';

export default {
  // getTasks: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  // saveTask: data => post(defaultUrl, data),
  // deleteTask: id => del(`${defaultUrl}/${id}`),
  // updateStatus: data => post(`${defaultUrl}/status`, data),
  // getTaskActionList: query => (query ? get(`${defaultUrl}${actionUrl}?${queryString.stringify(query)}`) : get(`${defaultUrl}${actionUrl}`)),
  // saveTaskAction: data => post(`${defaultUrl}${actionUrl}`, data),
  startSession: data => {
    post(`${defaultUrl}/start`, data)
  },
  stopSession: data => {
    post(`${defaultUrl}/stop`, data)
  },
};
