import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rawMatActions, rawMatSelectors, rawMatTypes } from '../../../redux/rawMat';
import { substanceActions } from '../../../redux/substance';
import { substanceFunctionActions } from '../../../redux/substanceFunction';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { functionSelectors, functionActions } from '../../../redux/function';
import {
  rawMatProportionActions,
  rawMatProportionTypes,
  rawMatProportionSelectors,
} from '../../../redux/rawMatProportion';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import RawMatCompositionTab from './RawMatCompositionTab';

class RawMatCompositionContainer extends Component {
  static propTypes = {
    rawMatId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.rawMatId !== this.props.rawMatId) {
      this.props.onLoad();
    }
  }

  render() {
    return <RawMatCompositionTab {...this.props} />;
  }
}

const mapStateToProps = () => {
  const getStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('PRODUCT');
  return (state, { rawMatId }) => {
    const isLoadingRawMatProportionList = rawMatProportionSelectors.makeGetByQueryLoading({ rawMatId });
    return {
      currentRawMatComposition: rawMatSelectors.getRawMatCompositionForRawMat(state)(rawMatId),
      functionById: functionSelectors.getById(state),
      isLoadingRawMatCompositionList: applicationSelectors.getIsLoading(
        rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST,
        isLoadingRawMatProportionList,
      )(state),
      isAddDisabled: applicationSelectors.getIsLoading(
        rawMatTypes.RAWMAT.REQUEST,
        rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST,
        isLoadingRawMatProportionList,
      )(state),
      isRawMatCompositionEditable: rawMatSelectors.makeIsRawMatEditable(rawMatId)(state),
      actionButtonsDisabled: applicationSelectors.getIsLoading(
        state,
        rawMatTypes.RAWMAT_LIST.REQUEST,
        rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.REQUEST,
        isLoadingRawMatProportionList,
      )(state),
      status: getStatusList(state),
    };
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onLoad: () => {
    if (rawMatId > 0) {
      dispatch(
        rawMatProportionActions.fetchRawMatProportions.request({
          rawMatId,
          include: {
            OptIncludeSubstance: true,
            OptIncludeFunctions: true,
            OptIncludeSubstanceProportions: true
          },
        }),
      );
      dispatch(rawMatActions.listAllowedNewSubstanceInComposition.request(rawMatId));
    }
    dispatch(functionActions.listFunctions.request());
    dispatch(substanceActions.listSubstances.request());
    dispatch(substanceFunctionActions.listSubstanceFunctions.request());
  },
  onAllergensInPerfumeButtonClick: () => dispatch(modalActions.showAllergenListModal({ rawMatId })),
  onImpuritiesButtonClick: () => dispatch(modalActions.showImpurityListModal({ rawMatId })),
  getRawMatProportions: () =>
    dispatch(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId,
        include: {
          OptIncludeSubstance: true,
          OptIncludeFunctions: true,
        },
      }),
    ),
  onAdd: () => dispatch(rawMatActions.addEditRawMatProportion({ rawMatId })),
  onEdit: (rawMatId, rawMatProportionId) => {
    dispatch(rawMatActions.addEditRawMatProportion({ rawMatId, rawMatProportionId }))
  },
  onDelete: rawMatProportionId =>
    dispatch(rawMatProportionActions.deleteRawMatProportion.request({ rawMatId, rawMatProportionId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RawMatCompositionContainer);
