import * as productPhraseTRSelectors from './productPhraseTRSelectors';
import * as productPhraseTRActions from './productPhraseTRActions';
import * as productPhraseTRTypes from './productPhraseTRTypes';
import sagas from './productPhraseTRSagas';
import reducer from './productPhraseTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productPhraseTRSelectors, productPhraseTRActions, productPhraseTRTypes };
export default reducer;
