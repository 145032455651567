import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';

import orderProductApi from '../../api/orderProductApi';
import * as orderProductTypes from './orderProductTypes';
import * as orderProductActions from './orderProductActions';
import { detailedOrderProductListSchema } from './orderProductSchemas';

export default function* root() {
  yield all([
    takeLatest(orderProductTypes.ORDER_PRODUCT_LIST.REQUEST, getOrderProductListSaga),
    takeLatest(orderProductTypes.DELETE_ORDER_PRODUCT.REQUEST, deleteOrderProductSaga),
  ]);
}

function* deleteOrderProductSaga({ payload, type }) {
  const { ProductId, OrderId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(orderProductActions.deleteOrderProduct, orderProductApi.deleteOrderProduct, { ProductId, OrderId });
  // yield put(orderProductActions.deleteOrderProduct.request());
  yield put(applicationActions.unsetLoading(type));
}

function* getOrderProductListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const ordersProducts = yield call(orderProductApi.getOrdersProducts, payload);
    const normalizedData = normalize(ordersProducts, detailedOrderProductListSchema);
    yield put(orderProductActions.listOrdersProducts.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(orderProductActions.listOrdersProducts.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
