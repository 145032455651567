import { css } from 'glamor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Button, ButtonToolbar, Checkbox, Col, Modal, Row} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import BaseLoader from '../../../_Common/BaseLoader';
import PhraseList from './PhraseList';
import Select from "react-select";
import * as R from "ramda";

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class PhraseListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhraseId: null,
      chapterCode: null
    };
  }

  selectPhrase = phraseId => {
    this.setState(() => ({
      selectedPhraseId: phraseId,
    }));
  };

  deselectPhrase = phraseId => {
    this.setState(() => ({
      selectedPhraseId: phraseId,
    }));
    this.setState({ chapterCode: null })
  };

  submit = ({ data }) => {
    if (data) this.props.onSubmitButtonClick(data.PhraseId, this.state.chapterCode);
    else this.props.onSubmitButtonClick(this.state.selectedPhraseId, this.state.chapterCode);
  };

  render() {
    const { closeModal, onChangeFilter, filter, onSubmitButtonClick, editMode, cpsrChapters, ...otherProps } = this.props;

    // const finalPhraseList = this.props.phraseList.filter(elem => this.state.chapterCode !== null && elem.ChaptersCode.includes(this.state.chapterCode))
    const finalPhraseList = this.props.phraseList.filter(elem => (this.state.chapterCode !== null && elem.ChaptersCode.includes(this.state.chapterCode))
                                                         || filter === 'all')
    const finalChapter = getChapterList(cpsrChapters, filter);

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('modal.phraseList.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseLoader isLoading={false}>
            <div style={{ marginBottom: '5px' }}>
              <Row>
                <Col lg={4}>
                  <Select
                    placeholder="Select a chapter..."
                    options={finalChapter}
                    valueKey="ChapterCode"
                    labelKey="FullTitle"
                    value={this.state.chapterCode}
                    onChange={value => {
                      this.setState({
                        chapterCode: value ? value.ChapterCode : null,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <PhraseList onSelectPhrase={this.selectPhrase} onRowDoucleClick={this.submit} finalPhraseList={finalPhraseList} {...otherProps} />
          </BaseLoader>
          {/* {editMode && ( */}
            <Checkbox
              onChange={({ target }) => {
                onChangeFilter(target.checked);
                this.deselectPhrase();
              }}
              checked={filter === 'all'}>
              Show all
            </Checkbox>
          {/* )} */}
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />&nbsp;
                <Translate value="general.close" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="button"
                onClick={this.submit}
                disabled={!this.state.selectedPhraseId}>
                <Translate value={editMode ? 'general.edit' : 'general.add'} />&nbsp;
                <i className="fa fa-arrow-circle-right" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

PhraseListModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default PhraseListModal;

const getChapterList = (chapterList, cpsr) => {
  const setChapterFullTitle = chapter => R.assoc('FullTitle', `${R.prop('Order', chapter)} ${R.prop('ChapterTitle', chapter)}`, chapter);
  chapterList = chapterList.filter(chapter => chapter.CpsrTypeCode === cpsr).map(setChapterFullTitle);

  return chapterList;
};
