import * as rawMatProportionSelectors from './rawMatProportionSelectors';
import * as rawMatProportionActions from './rawMatProportionActions';
import * as rawMatProportionTypes from './rawMatProportionTypes';
import * as rawMatProportionSchema from './rawMatProportionSchema';
import sagas, * as rawMatProportionSagas from './rawMatProportionSagas';
import reducer from './rawMatProportionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  rawMatProportionSelectors,
  rawMatProportionActions,
  rawMatProportionTypes,
  rawMatProportionSchema,
  rawMatProportionSagas,
};
export default reducer;
