import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as productClpTypes from './productClpTypes';

const byProductId = handleActions(
  {
    [productClpTypes.FETCH_PRODUCT_CLP_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.productClps,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [productClpTypes.FETCH_PRODUCT_CLP_FOR_PRODUCT.SUCCESS]: (state, { payload }) => [...state, payload.result],
  },
  [],
);

export default combineReducers({
  byProductId,
  list: combineReducers({
    allIds,
  }),
});
