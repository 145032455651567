import { createRequestActions } from '../../utils';
import * as shadeXRmTypes from './shadeXRmTypes';

export const loadModalData = createRequestActions(
  shadeXRmTypes.LOAD_MODAL_DATA,
  ProductId => ({
    ProductId,
  }),
  (result, ProductId) => ({
    ProductId,
  }),
);
