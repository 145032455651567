import { takeLatest, put, takeEvery, all } from 'redux-saga/effects';
import { LOCATION_CHANGE, replace } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';

import ingredientApi from '../../api/ingredientApi';
import * as ingredientTypes from './ingredientTypes';
import * as ingredientActions from './ingredientActions';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(ingredientTypes.INGREDIENT_LIST.REQUEST, getIngredientListSaga),
    takeLatest(ingredientTypes.INGREDIENT.REQUEST, getIngredientSaga),
    takeLatest(ingredientTypes.SAVE_INGREDIENT.REQUEST, saveIngredientSaga),
  ]);
}

function* onLocationChange(action) {
  if (action.payload.pathname === '/ingredients/-1') {
    yield put(ingredientActions.newIngredient());
  }
}

function* getIngredientListSaga() {
  yield put(applicationActions.setLoading(ingredientTypes.INGREDIENT_LIST.REQUEST));
  yield callApiSaga(ingredientActions.listIngredients, ingredientApi.getIngredientList);
  yield put(applicationActions.unsetLoading(ingredientTypes.INGREDIENT_LIST.REQUEST));
}

function* getIngredientSaga(action) {
  yield put(applicationActions.setLoading(ingredientTypes.INGREDIENT.REQUEST));
  yield callApiSaga(ingredientActions.ingredient, ingredientApi.getIngredient, action.payload);
  yield put(applicationActions.unsetLoading(ingredientTypes.INGREDIENT.REQUEST));
}

function* saveIngredientSaga(action) {
  yield put(applicationActions.setLoading(ingredientTypes.SAVE_INGREDIENT.REQUEST));
  const ingredientId = yield callApiSaga(ingredientActions.saveIngredient, ingredientApi.saveIngredient, action.payload);
  yield put(replace(`/ingredients/${ingredientId}`));
  yield put(ingredientActions.ingredient.request(ingredientId));
  yield put(ingredientActions.listIngredients.request());
  yield put(applicationActions.unsetLoading(ingredientTypes.SAVE_INGREDIENT.REQUEST));
}
