import React from 'react';
import GeneralGrid from './GeneralGrid';
import GeneralDashboardSearchCriteriaPanel from '../GeneralDashboardSearchCriteriaPanel';

const SafecosmeticDashboardSearchGrid = props => (<div>
  <GeneralDashboardSearchCriteriaPanel {...props} />
  <GeneralGrid showAddButton {...props} />
</div>);

SafecosmeticDashboardSearchGrid.propTypes = {};

export default SafecosmeticDashboardSearchGrid;
