import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { productActions, productTypes, productSelectors } from '../../../redux/product';
import { applicationSelectors } from '../../../redux/application';
import ProductOrdersTab from './ProductOrdersTab';
import {getCurrentProductOrdersWithStatus} from "../../../redux/product/productSelectors";

class ProductOrdersContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ProductOrdersTab {...this.props} />;
  }
}

const mapStateToProps = state => ({
  currentProductOrders: productSelectors.getCurrentProductOrdersWithStatus(state),
  availableOrdersForCurrentProduct: productSelectors.getAvailableOrdersForCurrentProduct(state),
  isLoading: applicationSelectors.isLoading(state, productTypes.PRODUCT_ORDER_LIST.REQUEST),
});

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(productActions.listProductOrders.request(productId));
    //dispatch(productActions.listOrdersOwnedByProductCompany.request(productId));
  },
  getProductOrders: () => dispatch(productActions.listProductOrders.request(productId)),
  onDeleteClick: OrderId => dispatch(productActions.removeOrderFromProduct(productId, OrderId)),
  onRowDoubleClicked: order => dispatch(push(`/orders/${order.OrderId}`)),
  addOrderToCurrentProduct: data => dispatch(productActions.addOrderToCurrentProductAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductOrdersContainer);
