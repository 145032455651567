import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

const detailFormValueSelector = formValueSelector('dashboardForm');

export const getById = ({ dashboard }) => dashboard.byId;
export const getIds = ({ dashboard }) => dashboard.list.allIds;
export const getDashboard = (state, id) => getById(state)[id];
export const getDashboardList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export function dashboardTitle(state) {
  const name = detailFormValueSelector(state, 'Name') || '';
  return `${name}`;
}
