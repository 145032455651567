import { merge } from 'lodash';
import queryString from 'query-string';
import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import * as formulaReviewTypes from '../formulaReview/formulaReviewTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';
import * as rawMatTypes from '../rawMat/rawMatTypes';
import * as rawMatProportionTypes from '../rawMatProportion/rawMatProportionTypes';
import * as substanceTypes from './substanceTypes';

const byId = handleActions(
  {
    [combineActions(substanceTypes.SUBSTANCE_LIST.SUCCESS, substanceTypes.FETCH_SUBSTANCES.SUCCESS)]: (
      state,
      { payload },
    ) => merge({}, state, payload.entities.substances),
    [substanceTypes.FETCH_SUBSTANCES.SUCCESS]: (state, payload, meta = {}) =>
      (payload.entities && payload.entities.substances
        ? {
          ...state,
          ...payload.entities.substances,
        }
        : state),
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS,
      substanceTypes.SUBSTANCE_PROPORTIONS.SUCCESS,
      formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => merge({}, state, payload.entities.substances),
    [substanceTypes.SUBSTANCE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.substances,
    }),
  },
  {},
);

const byQueryIds = handleActions(
  {
    [substanceTypes.FETCH_SUBSTANCES.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(query);
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [substanceTypes.FETCH_SUBSTANCES.REQUEST]: (state, { payload, meta = {} }) => {
      const { query = {} } = payload;
      const querystring = queryString.stringify(query);
      return {
        ...state,
        [querystring]: true,
      };
    },
    [combineActions(substanceTypes.FETCH_SUBSTANCES.SUCCESS, substanceTypes.FETCH_SUBSTANCES.FAILURE)]: (
      state,
      { payload, meta = {} },
    ) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(query);
      return {
        ...state,
        [querystring]: false,
      };
    },
  },
  {},
);

const allIds = handleActions(
  {
    [substanceTypes.SUBSTANCE_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [substanceTypes.FETCH_SUBSTANCES.SUCCESS]: (state, { payload }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS,
      substanceTypes.SUBSTANCE_PROPORTIONS.SUCCESS,
      formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.substances) {
        return [...new Set([...state, ...Object.keys(payload.entities.substances).map(Number)])];
      }
      return state;
    },
    [substanceTypes.SUBSTANCE.SUCCESS]: (state, { payload }) => {
      if (!state.includes(payload.result)) {
        return [...new Set([...state, payload.result])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  // substanceReducer,
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
