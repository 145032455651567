import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import ButtonDropdown from '../../_Common/ButtonDropDown';
import { SelectionGrid, DeleteButtonRenderer } from '../../_Common/Grids';

const SubstanceFunctionList = props => {
  const {
    substanceFunctionList,
    isLoading,
    functionList,
    fetchSubstanceFunctionListForSubstance,
    onDelete,
    functionListNotLinkedWithSubstance,
    onAdd,
    substanceId,
  } = props;

  const getFunctionById = functionId => functionList.find(f => f.FunctionId === functionId);
  const getFunction = R.pipe(R.prop('FunctionId'), getFunctionById);

  const columns = [
    {
      headerName: I18n.t('function.Name'),
      field: 'Name',
      valueGetter: ({ colDef, data }) => R.propOr('', colDef.field, getFunction(data)),
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('function.Description'),
      field: 'Description',
      valueGetter: ({ colDef, data }) => R.propOr('', colDef.field, getFunction(data)),
    },
    {
      headerName: I18n.t('general.actions'),
      width: 20,
      cellRendererFramework: DeleteButtonRenderer,
      cellRendererParams: {
        onClick: onDelete,
      },
    },
  ];

  return (
    <div>
      <ButtonDropdown
        options={functionListNotLinkedWithSubstance}
        labelKey="Name"
        valueKey="FunctionId"
        buttonText="Add"
        onButtonClick={onAdd}
      />
      <SelectionGrid
        data={substanceFunctionList}
        key={`${substanceId}-${substanceFunctionList.length}`}
        keyName="SubstanceFunctionId"
        columns={columns}
        isLoading={isLoading}
        onRefresh={fetchSubstanceFunctionListForSubstance}
        showAddButton={false}
      />
    </div>
  );
};

SubstanceFunctionList.defaultProps = {
  substanceFunctionList: [],
  functionListNotLinkedWithSubstance: [],
  functionList: [],
};

SubstanceFunctionList.propTypes = {
  substanceFunctionList: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  substanceId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  functionListNotLinkedWithSubstance: PropTypes.array.isRequired, //eslint-disable-line
  functionList: PropTypes.array.isRequired, //eslint-disable-line
  fetchSubstanceFunctionListForSubstance: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default SubstanceFunctionList;
