import { schema } from 'normalizr';
import { shadeProportionSchemas } from '../shadeProportion';

export const shadeSchema = new schema.Entity(
  'shades',
  {
    ShadeProportions: shadeProportionSchemas.shadeProportionListSchema,
  },
  { idAttribute: 'ShadeId' },
);

export const shadeListSchema = [shadeSchema];
