import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as labelReviewTypes from '../labelReview/labelReviewTypes';

const byId = handleActions(
  {
    [labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.labelReviewIncis,
    }),
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload }) =>
      (payload.entities && payload.entities.labelReviewIncis
        ? {
          ...state,
          ...payload.entities.labelReviewIncis,
        }
        : state),
  },
  {},
);

const allIds = handleActions(
  {
    [combineActions(
      labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS,
      labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.labelReviewIncis) {
        return [...new Set([...state, ...Object.keys(payload.entities.labelReviewIncis).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
