import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import { SelectionGrid } from '../_Common/Grids';

const columns = [
  {
    headerName: I18n.t('rawMat.name'),
    width: 200,
    field: 'Name',
    sort: 'asc',
  },
  {
    headerName: I18n.t('rawMat.supplier'),
    width: 150,
    field: 'SupplierName',
  },
  {
    headerName: I18n.t('rawMat.status'),
    width: 150,
    field: 'StatusName',
  },
];

const RawMatList = props => {
  const { rawMatList, onRawMatSelected, onRefreshList, isLoadingRawMatList, selectedId, onAddRawMat } = props;
  return (
    <SelectionGrid
      data={rawMatList}
      onSelected={onRawMatSelected}
      onRefresh={onRefreshList}
 //     isLoading={isLoadingRawMatList}
      showLoadingIcon={isLoadingRawMatList}
      selectedId={selectedId}
      columns={columns}
      keyName="RawMatId"
      showAddButton
      suppressRowClickSelection
      onAdd={onAddRawMat}
    />
  );
};

RawMatList.defaultProps = {
  selectedId: '',
};

RawMatList.propTypes = {
  rawMatList: PropTypes.arrayOf(
    PropTypes.shape({
      rawMatId: PropTypes.number,
      rawMatName: PropTypes.string,
      rawMatCode: PropTypes.string,
    }),
  ).isRequired,
  onRawMatSelected: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  isLoadingRawMatList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
  onAddRawMat: PropTypes.func.isRequired,
};

export default RawMatList;
