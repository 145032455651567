import { createSelector } from 'reselect';
import * as R from 'ramda';
import { productProportionSelectors } from '../../../../redux/productProportion';
import { shadeSelectors } from '../../../../redux/shade';
import { shadeProportionSelectors } from '../../../../redux/shadeProportion';

const getShadeCompoForShade = shadeId =>
  createSelector(
    [
      shadeSelectors.getById,
      productProportionSelectors.getproductProportionList,
      shadeProportionSelectors.makeGetShadeProportionsForShade,
    ],
    (shadeById, productProportionList, getShadeProportionsForShade) => {
      const productId = R.path([shadeId, 'ProductId'])(shadeById);
      const shadeProportions = getShadeProportionsForShade(shadeId);

      const ppForProduct = R.compose(
        R.map(R.assoc('ShadeId', shadeId)),
        R.map(R.pick(['ProductProportionId'])),
        R.filter(R.propEq('ProductId', productId)),
      )(productProportionList);

      return R.unionWith(R.eqBy(R.prop('ProductProportionId')), shadeProportions, ppForProduct);
    },
  );

export const makeGetShadeCompoForShade = createSelector(
  getShadeCompoForShade,
  shadeCompoForShade => shadeCompoForShade,
);
