import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import uuidV1 from 'uuid/v1';
import { css } from 'glamor';

const styles = {
  spanBlock: css({
    display: 'inline-block',
  }),
};

const TooltipWrapper = ({ children, text, ...otherProps }) => {
  const id = uuidV1();
  return (
    <span>
      <ReactTooltip id={`ttip_${id}`} {...otherProps} >
        {text}
      </ReactTooltip>
      <span
        className={styles.spanBlock}
        data-tip
        data-for={`ttip_${id}`}
      >
        {children}
      </span>
    </span>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default TooltipWrapper;
