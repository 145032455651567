import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomSelect from "./CustomSelect";
import * as referentialSelectors from "../../../../redux/referentials/referentialsSelectors";
import cpsrTypes from "../../../../constants/cpsrTypes";

const CpsrChaptersDropdownCustom = props => {
  const { cpsrChapterList } = props;
  return <CustomSelect options={cpsrChapterList} labelField="Value" valueField="Key" {...props} />;
};

const mapStateToProps = () => (state, { cpsrTypeCode }) => {
  const getCpsrChapters = referentialSelectors.makeGetCpsrChapters(cpsrTypeCode);
  return {
    cpsrChapterList: getCpsrChapters(state),
  };
};

CpsrChaptersDropdownCustom.propTypes = {
  cpsrTypeCode: PropTypes.oneOf([cpsrTypes.CPSRA, cpsrTypes.CPSRB]).isRequired,
  cpsrChapterList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(CpsrChaptersDropdownCustom);
