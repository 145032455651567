import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import ProductProportionForm from './ProductProportionForm';
import BaseLoader from '../../../_Common/BaseLoader';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
};

const ProductProportionFormModal = props => {
  const {
    currentProduct: { ProductName },
    onSaveButtonClick,
    isDirty,
    closeModal,
    isLoadingContent,
    isSubmitting,
  } = props;

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Add Raw Material to {ProductName} product composition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseLoader isLoading={isSubmitting}>
          <ProductProportionForm {...props} />
        </BaseLoader>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={onSaveButtonClick}
              disabled={!isDirty || isLoadingContent}>
              <i className="fa fa-floppy-o" />&nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Body>
    </div>
  );
};

ProductProportionFormModal.propTypes = {
  currentProduct: PropTypes.shape({
    ProductName: PropTypes.string.isRequired,
    ProductId: PropTypes.number.isRequired,
  }).isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoadingContent: PropTypes.bool.isRequired,
};

export default ProductProportionFormModal;
