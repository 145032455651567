import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting, formValueSelector } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
import { referentialSelectors } from '../../../../redux/referentials';
// import { productSelectors } from '../../../../redux/product';
import { impactAssessmentThresholdTypes } from '../../../../redux/impactAssessmentThreshold';
import ThresholdModal from './ThresholdModal';
import { substanceTypes } from '../../../../redux/substance';

const formName = formNames.impactAssessmentThreshold;
const isAnyTrue = R.any(R.equals(true));

const formselector = formValueSelector(formName);

const ThresholdModalContainer = props => <ThresholdModal {...props} impactAssesmentid={props.impactAssesmentId} />;

const mapStateToProps = (state, { impactAssessmentId, impactAssessmentThresholdId }) => {
  // const impactAssessment = productSelectors.getById(state)[productId];
  // const productName = R.propOr('', 'ProductName', impactAssessment);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  const thresholdType = formselector(state, 'TypeCode');
  return {
    requestingSubstanceList: applicationSelectors.isLoading(state, substanceTypes.SUBSTANCE_LIST.REQUEST),
    isLoading: applicationSelectors.isLoading(
      state,
      impactAssessmentThresholdTypes.SAVE_IMPACT_ASSESSMENT_THRESHOLD.REQUEST,
    ),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('impactAssessmentThresholdModal.title'),
    impactAssessmentThresholdTypes: referentialSelectors.getImpactAssessmentThresholdTypes(state),
    impactAssessmentThresholdComparators: referentialSelectors.getImpactAssessmentThresholdComparators(state),
    thresholdType,
    impactAssessmentId,
    impactAssessmentThresholdId,
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.impactAssessmentThreshold)),
});

ThresholdModalContainer.defaultProps = {
  impactAssessmentId: undefined,
  impactAssessmentThresholdId: -1,
};

ThresholdModalContainer.propTypes = {
  impactAssessmentId: PropTypes.number.isRequired,
  impactAssessmentThresholdId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThresholdModalContainer);
