import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Grid, Panel, FormControl } from 'react-bootstrap';
import { prop, path } from 'ramda';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import LabelReviewItemHeader from './_Common/LabelReviewItemHeader';
import LabelReviewItem from './_Common/LabelReviewItem';
import InciText from '../../_Common/InciText';
import { labelReviewWizardSelectors } from '../../../redux/wizard/labelReviewWizard';
import {
  labelReviewItemWizardSelectors,
  labelReviewItemWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewItemWizard';
import {
  labelReviewInciWizardSelectors,
  labelReviewInciWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewInciWizard';
import { inciListSelectors } from '../../../redux/inciList';

const styles = {
  inciText: css({
    marginTop: '25px',
    marginBottom: '25px',
  }),
  commentWithLabel: css({
    '& .row': {
      marginBottom: '15px',
    },
    '& textarea.input-sm': {
      height: '60px',
    },
  }),
};

const CommentWithLabel = ({ label, text, onChange }) =>
  (<div>
    <Col sm={4}>
      {label}
    </Col>
    <Col sm={8}>
      <FormControl
        value={text || undefined}
        componentClass="textarea"
        bsSize="small"
        onChange={({ target }) => onChange(target.value)}
      />
    </Col>
  </div>);

CommentWithLabel.defaultProps = {
  text: undefined,
};
CommentWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const LabelReviewInci = props => {
  const handleCommentEdit = field => value => props.handleCommentEdit(field, value);
  return (
    <Grid fluid>
      <Col sm={12}>
        <Row>
          <LabelReviewItemHeader />
        </Row>
        <Row>
          <LabelReviewItem {...props} />
        </Row>
        <Row>
          <div className={styles.inciText}>
            <Panel header="InciList">
              <InciText {...props} />
            </Panel>
          </div>
        </Row>
        <div className={styles.commentWithLabel}>
          <Row>
            <CommentWithLabel
              label={I18n.t('labelReviewWizard.missingIngredients')}
              text={props.MissingIngredients}
              onChange={handleCommentEdit('MissingIngredients')}
            />
          </Row>
          <Row>
            <CommentWithLabel
              label={I18n.t('labelReviewWizard.wrongIngredients')}
              text={props.WrongIngredients}
              onChange={handleCommentEdit('WrongIngredients')}
            />
          </Row>
          <Row>
            <CommentWithLabel
              label={I18n.t('labelReviewWizard.wrongOrder')}
              text={props.WrongOrder}
              onChange={handleCommentEdit('WrongOrder')}
            />
          </Row>
          <Row>
            <CommentWithLabel
              label={I18n.t('labelReviewWizard.wrongSpelling')}
              text={props.WrongSpelling}
              onChange={handleCommentEdit('WrongSpelling')}
            />
          </Row>
        </div>
      </Col>
    </Grid>
  );
};

LabelReviewInci.defaultProps = {
  MissingIngredients: undefined,
  WrongIngredients: undefined,
  WrongOrder: undefined,
  WrongSpelling: undefined,
};

LabelReviewInci.propTypes = {
  MissingIngredients: PropTypes.string,
  WrongIngredients: PropTypes.string,
  WrongOrder: PropTypes.string,
  WrongSpelling: PropTypes.string,
  handleCommentEdit: PropTypes.func.isRequired,
};

const mapStateToProps = () => state => {
  const productId = labelReviewWizardSelectors.getProductId(state);
  const labelReviewItemInci = labelReviewItemWizardSelectors.getLabelReviewInciWithDetails(state);
  const labelReviewInci = labelReviewInciWizardSelectors.getLabelReviewInci(state);
  const inciText = inciListSelectors.getInciTextForProduct(state)(productId);
  return {
    IsInnerLabelOk: prop('IsInnerLabelOk', labelReviewItemInci),
    IsOuterLabelOk: prop('IsOuterLabelOk', labelReviewItemInci),
    IsLeafletOk: prop('IsLeafletOk', labelReviewItemInci),
    Comment: prop('Comment', labelReviewItemInci),
    Label: path(['ReferentialData', 'Label'], labelReviewItemInci),
    MissingIngredients: prop('MissingIngredients', labelReviewInci),
    WrongIngredients: prop('WrongIngredients', labelReviewInci),
    WrongOrder: prop('WrongOrder', labelReviewInci),
    WrongSpelling: prop('WrongSpelling', labelReviewInci),
    LabelReviewItemId: prop('LabelReviewItemId', labelReviewItemInci),
    inciUpperPart: prop('UpperPart', inciText),
    inciLowerPart: prop('LowerPart', inciText),
  };
};

const mapDispatchToProps = dispatch => ({
  updateLabelReviewItem: (id, data) => dispatch(labelReviewItemWizardActions.updateLabelReviewItem(id, data)),
  editLabelReviewItemComment: (id, data) => dispatch(labelReviewItemWizardActions.editComment(id, data)),
  resetLabelReviewItemComment: id => dispatch(labelReviewItemWizardActions.resetComment(id)),
  handleCommentEdit: (field, value) => dispatch(labelReviewInciWizardActions.handleCommentEdit(field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewInci);
