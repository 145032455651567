import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, DeleteButtonRenderer, DateCellRenderer } from '../../../_Common/Grids';
import { ControlLabel } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

const MandateProductList = props => {
  const { mandateProductList, productList, height, whenGridReady, onDeleteButtonClick, isLoading } = props;

  const getProductById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('ProductId'), R.identity]))(productList),
  );

  const getDataFromRow = R.prop('data');

  const getProductFromRow = R.compose(getProductById, R.prop('ProductId'), getDataFromRow);

  const columns = [
    {
      headerName: I18n.t('mandateProducts.code'),
      valueGetter: R.compose(R.propOr('', 'Code'), getProductFromRow),
      width: 80,
    },
    {
      headerName: I18n.t('mandateProducts.product'),
      valueGetter: R.compose(R.propOr('', 'ProductName'), getProductFromRow),
    },
    {
      headerName: I18n.t('mandateProducts.NotificationDate'),
      valueGetter: R.compose(R.propOr('', 'NotifiedOn'), getProductFromRow),
      cellRenderer: DateCellRenderer,
      width:80,
    },
    {
      headerName: I18n.t('mandateProducts.NotificationCode'),
      valueGetter: R.compose(R.propOr('', 'NotificationCode'), getProductFromRow),
    },
   {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      cellRendererParams: {
        onClick: onDeleteButtonClick,
      },
      width: 60,
    },
  ];

  return (
  <div>
  <ControlLabel>
    <Translate value="mandateProducts.products" />
    </ControlLabel>
    <SelectionGrid
      data={mandateProductList}
      // onRefresh={onRefresh}
      isLoading={isLoading}
      columns={columns}
      keyName="MandateProductId"
      showAddButton={false}
      height={height}
      whenGridReady={whenGridReady}
    />
    </div>
  );
};

MandateProductList.defaultProps = {
  height: '',
};

MandateProductList.propTypes = {
  mandateProductList: PropTypes.array.isRequired, //eslint-disable-line
  productList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  whenGridReady: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MandateProductList;
