import React from 'react';
import PropTypes from 'prop-types';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import formNames from '../../../../constants/formNames';
import { InputField } from '../../../_Common/Forms';
import { requiredField, maxLength255 } from '../../../../utils/fieldValidation';

const MinimalProductForm = props => {
  const { handleSubmit } = props;
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <Field
              name="ProductName"
              label={`${I18n.t('product.productName')} *`}
              component={InputField}
              horizontal
              validate={[requiredField, maxLength255]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="ShadeCount"
              label={`${I18n.t('product.shadeCount')} *`}
              component={InputField}
              horizontal
              validate={[requiredField, maxLength255]}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

MinimalProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formNames.minimalProduct,
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: {
    ProductId: -1,
    ShadeCount: 0
  },
  // validate,
})(MinimalProductForm);
