import React from 'react';
import PropTypes from 'prop-types';
import RawMatProductListContainer from './_RawMatProductListContainer';

const RawMatProductTab = ({ rawMatId }) => (
  <div>
    <RawMatProductListContainer rawMatId={rawMatId} />
  </div>
);

RawMatProductTab.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default RawMatProductTab;
