import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as productActionTypes from './productActionTypes';

export const listProductActions = createRequestActions(productActionTypes.PRODUCT_ACTION_LIST, ProductId => ({
  ProductId,
}));

export const showProductActionsHistory = createAction(productActionTypes.SHOW_PRODUCT_ACTIONS_HISTORY, ProductId => ({
  ProductId,
}));

export const saveProductAction = createRequestActions(productActionTypes.SAVE_PRODUCT_ACTION, (ProductId, data) => ({
  ProductId,
  ...data,
}));
