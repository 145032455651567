import PropTypes from 'prop-types';
import React from 'react';
import ProductTaskList from './ProductTaskList';
import ButtonDropDown from '../../_Common/ButtonDropDown';

const TasksTab = props => {
  const { availableOrdersForCurrentProduct, addOrderToCurrentProduct, ...otherProps } = props;

  return (
    <div>
      <ButtonDropDown
        options={availableOrdersForCurrentProduct}
        labelKey="OrderNr"
        valueKey="OrderId"
        buttonText="Add"
        onButtonClick={addOrderToCurrentProduct}
      />
      <ProductTaskList {...otherProps}
      />
    </div>
  );
};


TasksTab.propTypes = {
  availableOrdersForCurrentProduct: PropTypes.arrayOf(
    PropTypes.shape({
      OrderNr: PropTypes.string.isRequired,
      OrderId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  addOrderToCurrentProduct: PropTypes.func.isRequired,
};

export default TasksTab;
