import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { createSaveForm } from '../form/utils';
import { modalSelectors, modalActions, modalTypes } from '../modal';
import formNames from '../../constants/formNames';
import substanceToxProfileApi from '../../api/substanceToxProfileApi';
import * as substanceToxProfileTypes from './substanceToxProfileTypes';
import * as substanceToxProfileSchemas from './substanceToxProfileSchemas';
import * as substanceToxProfileActions from './substanceToxProfileActions';

export default function* root() {
  yield all([
    takeLatest(substanceToxProfileTypes.FETCH_SUBSTANCE_TOX_PROFILES.REQUEST, getSubstanceToxProfilesSaga),
    takeLatest(substanceToxProfileTypes.SAVE_SUBSTANCE_TOX_PROFILE.REQUEST, saveSubstanceToxFormSaga),
  ]);
}

const saveSubstanceToxFormSaga = createSaveForm(formNames.substanceToxProfile, saveSubstanceToxProfileFormSaga);

export function* saveSubstanceToxProfileFormSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));

  const { substanceId } = payload;
  yield callApiSaga(
    substanceToxProfileActions.saveSubstanceToxProfile,
    substanceToxProfileApi.saveSubstanceToxProfile,
    payload,
  );

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.SUBSTANCE_TOX_PROFILE) {
    yield put(modalActions.hideModal());
  }

  yield put(substanceToxProfileActions.fetchSubstanceToxProfiles.request({ substanceId }));

  yield put(applicationActions.unsetLoading(type));
}

export function* getSubstanceToxProfilesSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceToxProfileList = yield call(substanceToxProfileApi.getSubstanceToxProfiles, payload);
    const normalizedData = normalize(substanceToxProfileList, substanceToxProfileSchemas.substanceToxProfileListSchema);
    yield put(substanceToxProfileActions.fetchSubstanceToxProfiles.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceToxProfileActions.fetchSubstanceToxProfiles.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
