import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as phraseTypes from './phraseTypes';

export const getPhrase = createRequestActions(phraseTypes.GET_PHRASE);
export const savePhrase = createRequestActions(phraseTypes.SAVE_PHRASE);
export const deletePhrase = createRequestActions(phraseTypes.DELETE_PHRASE, PhraseId => ({ PhraseId }));
export const updatePhrase = createAction(phraseTypes.UPDATE_PHRASE, (PhraseId, data) => ({
  PhraseId,
  ...data,
}));

export const listPhrases = createRequestActions(
  phraseTypes.PHRASE_LIST,
  ({ phraseId: id, ...query } = {}) => ({
    ...(id && { id }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);
