import * as processTypeSelectors from './processTypeSelectors';
import * as processTypeActions from './processTypeActions';
import * as processTypeTypes from './processTypeTypes';
import * as processTypeSchemas from './processTypeSchemas';

import sagas, * as processTypeSagas from './processTypeSagas';
import reducer from './processTypeReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { processTypeSelectors, processTypeActions, processTypeTypes, processTypeSchemas, processTypeSagas };
export default reducer;
