import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { modalActions, modalTypes, modalSelectors } from '../../modal';
import { inciSubstanceSagas, inciSubstanceActions } from '../../inciSubstance';
import * as productInciImpurityModalTypes from './productInciImpurityModalTypes';
import * as productInciImpurityModalActions from './productInciImpurityModalActions';
import * as productInciImpurityModalSelectors from './productInciImpurityModalSelectors';
import {inciListActions} from "../../inciList";

export default function* root() {
  yield all([
    takeLatest(productInciImpurityModalTypes.SAVE_BUTTON_CLICKED, onSaveButtonClickSaga),
    takeLatest(
      productInciImpurityModalTypes.SAVE_INCI_SUBSTANCE_IMPURITIES.REQUEST,
      saveInciSubstanceImpuritiesForProductSaga,
    ),
  ]);
}

function* saveInciSubstanceImpuritiesForProductSaga(action) {
  const { productId } = action.meta;
  yield call(inciSubstanceSagas.saveInciSubstancesSaga, action);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.PRODUCT_INCI_IMPURITY_LIST) {
    yield put(modalActions.hideModal());
  }

  yield put(
    inciListActions.getInciListForProduct.request(productId),
  );
}

function* onSaveButtonClickSaga({ payload, meta }) {
  const { productId } = meta;
  console.log('1', meta);
  const existingInciSubstances = yield select(state =>
    productInciImpurityModalSelectors.makeGetInciSubstancesWithImpuritiesSubstanceForProduct(state, productId)(productId),
  );
  console.log('2', existingInciSubstances);

  const data = payload.reduce(
    (acc, inciSubs) => {
      console.log('inciSubs', inciSubs);
      const isNewIS = !Number.isInteger(inciSubs.InciSubstanceId);
      const hasPercentage = !!inciSubs.PercentageInProduct;
      const existingInciSubstance = R.compose(R.find(R.propEq('InciSubstanceId', inciSubs.InciSubstanceId)))(
        existingInciSubstances,
      );

      const isPercentageEdited = R.compose(
        R.apply(R.complement(R.equals)),
        R.map(value => value.toString()),
        R.map(R.propOr('', 'PercentageInProduct')),
      )([existingInciSubstance, inciSubs]);

      const shouldBeDeleted = !hasPercentage && !isNewIS;
      const shouldBeSaved = (hasPercentage && isNewIS) || (hasPercentage && isPercentageEdited);

      if(inciSubs.SubstanceId === 25103 || inciSubs.InciSubstanceId === 713158){
        console.log('existingInciSubstance', existingInciSubstance);
        console.log('shouldBeDeleted', shouldBeDeleted);
        console.log('hasPercentage', hasPercentage);
        console.log('isNewIS', isNewIS);
        console.log('isPercentageEdited', isPercentageEdited);
      }

      return {
        ...acc,
        data: shouldBeSaved ? [...acc.data, { ...inciSubs, productId, IsImpurity: true }] : acc.data,
        idsToDelete: shouldBeDeleted ? [...acc.idsToDelete, inciSubs.InciSubstanceId] : acc.idsToDelete,
      };
    },
    { data: [], idsToDelete: [] },
  );
  console.log('3', data);

  yield put(productInciImpurityModalActions.saveInciSubstanceImpuritiesForProductSaga.request(productId, data));
}
