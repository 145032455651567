import { schema } from 'normalizr';
import * as actionSchemas from '../action/actionSchemas';

export const orderActionSchema = new schema.Entity(
  'orderActions',
  { Action: actionSchemas.actionSchema },
  { idAttribute: 'OrderActionId' },
);

export const orderActionListSchema = [orderActionSchema];
