import { createRequestActions } from '../utils';
import * as substanceRawMatTypes from './substanceRawMatTypes';

export const fetchRawMatsForSubstance = createRequestActions(
  substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE,
  SubstanceId => ({
    SubstanceId,
  }),
  (result, SubstanceId) => ({
    SubstanceId,
  }),
);
