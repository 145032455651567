import React from 'react';
import PropTypes from 'prop-types';
import { Field, Fields } from 'redux-form';
import { Col, Row, Grid, Button, FormGroup, ControlLabel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import {InputField, InputFieldWithDropdown, SelectField} from '../../_Common/Forms';
import { isNumberField, maxLength2048, maxLength32,maxLength9, isPositiveOrZeroDecimal, isPositiveOrZeroInteger } from '../../../utils/fieldValidation';
import BaseLoader from '../../_Common/BaseLoader';

const MosAndToxForm = props => {
  const { isLoading, uomList, onSubstanceToxProfileButttonClick, cramerClasseList, onCramerClasseSelected } = props;

  return (
    <BaseLoader isLoading={isLoading}>
      <Grid fluid>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={2}>
                  <Field
                    name="CramerClasseCode"
                    label={I18n.t('substance.CramerClasseCode')}
                    component={SelectField}
                    options={cramerClasseList}
                    labelField="Code"
                    valueField="Code"
                    onChange={(e, newValue, oldValue) => {
                      console.log('newValue', newValue);
                      onCramerClasseSelected(newValue);
                    }}
                  />
                </Col>
                <Col sm={10}>
                  <Field
                    name="CramerClasseInfo"
                    label={`${I18n.t('substance.CramerClasseDescription')}`}
                    componentClass="textarea"
                    component={InputField}
                    validate={maxLength2048}
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={10}>
                  <Field
                    name="Summary"
                    label={`${I18n.t('substance.SummaryCert')}`}
                    componentClass="textarea"
                    component={InputField}
                    validate={maxLength2048}
                  />
                </Col>
              </Row>
            </Col>
            </Row>
            <hr/>
            <section>
            <Row>
            <h4>{I18n.t('substance.DermalToxicity')}</h4>
            <Col sm={2}>
              <Fields
                names={['DermalAbsorption', 'DermalAbsorptionUom']}
                label={`${I18n.t('substance.DermalAbsorption')}`}
                options={uomList}
                component={InputFieldWithDropdown}
                getDefaultValue={() => 'Select'}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="DermalAbsorptionComment"
                label={`${I18n.t('substance.DermalAbsorptionComment')}`}
                componentClass="textarea"
                component={InputField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="DermalNoael"
                label={`${I18n.t('substance.DermalNoael')}`}
                component={InputField}
                inputGroupLabel={'mg/kg bw/day'}
                validate={[isPositiveOrZeroInteger]}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="DermalNoaelSource"
                label={`${I18n.t('substance.noaelCertSource')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Field
                name="DermalNoaelComment"
                label={`${I18n.t('substance.DermalNoaelComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="DermalAssessmentFactor"
                label={`${I18n.t('substance.AssessmentFactor')}`}
                component={InputField}
                // inputGroupLabel={'mg/kg bw/day'}
                validate={[isPositiveOrZeroDecimal]}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="DermalAssessmentFactorComment"
                label={`${I18n.t('substance.AssessmentFactorComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
        </section>
        <hr />
        <section>
          <h4>{I18n.t('substance.IngestionToxicity')}</h4>
          <Row>
            <Col sm={1}>
              <Field
                name="IngestionAbsorption"
                label={`${I18n.t('substance.IngestionAbsorption')}`}
                component={InputField}
                inputGroupLabel={'%'}
                validate={[isPositiveOrZeroDecimal]}
              />
            </Col>
            <Col sm={11}>
              <Field
                name="IngestionAbsorptionComment"
                label={`${I18n.t('substance.IngestionAbsorptionComment')}`}
                componentClass="textarea"
                component={InputField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="IngestionNoael"
                label={`${I18n.t('substance.IngestionNoael')}`}
                component={InputField}
                inputGroupLabel={'mg/kg bw/day'}
                validate={isPositiveOrZeroDecimal}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="IngestionNoaelSource"
                label={`${I18n.t('substance.noaelCertSource')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Field
                name="IngestionNoaelComment"
                label={`${I18n.t('substance.IngestionNoaelComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="IngestionAssessmentFactor"
                label={`${I18n.t('substance.AssessmentFactor')}`}
                component={InputField}
                // inputGroupLabel={'mg/kg bw/day'}
                validate={isPositiveOrZeroDecimal}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="IngestionAssessmentFactorComment"
                label={`${I18n.t('substance.AssessmentFactorComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
        </section>
        <hr />
        <section>
          <h4>{I18n.t('substance.InhalationToxicity')}</h4>
          <Row>
            <Col sm={1}>
              <Field
                name="InhalationAbsorption"
                label={`${I18n.t('substance.InhalationAbsorption')}`}
                component={InputField}
                inputGroupLabel={'%'}
                validate={isPositiveOrZeroDecimal}
              />
            </Col>
            <Col sm={11}>
              <Field
                name="InhalationAbsorptionComment"
                label={`${I18n.t('substance.InhalationAbsorptionComment')}`}
                componentClass="textarea"
                component={InputField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="InhalationNoael"
                label={`${I18n.t('substance.InhalationNoael')}`}
                component={InputField}
                inputGroupLabel={'mg/kg bw/day'}
                validate={isPositiveOrZeroDecimal}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="InhalationNoaelSource"
                label={`${I18n.t('substance.noaelCertSource')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Field
                name="InhalationNoaelComment"
                label={`${I18n.t('substance.InhalationNoaelComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="InhalationAssessmentFactor"
                label={`${I18n.t('substance.AssessmentFactor')}`}
                component={InputField}
                // inputGroupLabel={'mg/kg bw/day'}
                validate={isPositiveOrZeroDecimal}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="InhalationAssessmentFactorComment"
                label={`${I18n.t('substance.AssessmentFactorComment')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
        </section>
        <hr />
        <section>
        <Row>
          {/* <Col sm={3}>
            <Col sm={7}>
              <FormGroup>
                <ControlLabel>&nbsp;</ControlLabel>
                <Button bsSize="small" block onClick={onSubstanceToxProfileButttonClick}>
                  Substance Toxicological Profile
                </Button>
              </FormGroup>
            </Col>
          </Col> */}
          <Col sm={2}>
            <Field
              name="Solubility"
              label={`${I18n.t('substance.SolubilityCert')}`}
              component={InputField}
              inputGroupLabel={'%'}
              validate={isPositiveOrZeroDecimal}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="Vap"
              label={`${I18n.t('substance.VapCert')}`}
              component={InputField}
              inputGroupLabel={'Pa'}
              validate={isPositiveOrZeroDecimal}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="DAa"
              label={`${I18n.t('substance.DAaCert')}`}
              component={InputField}
              inputGroupLabel={'%'}
              validate={isPositiveOrZeroDecimal}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="Dap"
              label={`${I18n.t('substance.DapCert')}`}
              component={InputField}
              validate={maxLength9}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="TheoreticalDap"
              label={`${I18n.t('substance.TheoreticalDapCert')}`}
              component={InputField}
              validate={maxLength9}
            />
          </Col>
        </Row>
        </section>
        <hr/>
        <Row>
          <Col sm={3}>
            <Col sm={7}>
              <FormGroup>
                <ControlLabel>&nbsp;</ControlLabel>
                <Button bsSize="small" block onClick={onSubstanceToxProfileButttonClick}>
                  Substance Toxicological Profile
                </Button>
              </FormGroup>
            </Col>
          </Col>
          <Col sm={2}>
            <Field
              name="Nesil"
              label={`${I18n.t('substance.Nesil')}`}
              component={InputField}
              inputGroupLabel={'µg/cm²'}
              validate={isPositiveOrZeroDecimal}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="RiCode"
              label={`${I18n.t('substance.RiCode')}`}
              component={InputField}
              validate={maxLength9}
            />
          </Col>
          <Col sm={1}>
            <Field name="LogP" label={`${I18n.t('substance.LogP')}`} component={InputField} validate={maxLength32} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={5}>
            <Field
              name="DocReferences"
              label={`${I18n.t('substance.DocReferences')}`}
              componentClass="textarea"
              component={InputField}
              validate={maxLength2048}
            />
          </Col>
          <Col sm={5}>
            <Field
              name="CosAbstract"
              label={'Summary of consulting documentation'}
              componentClass="textarea"
              component={InputField}
            />
          </Col>
        </Row>
      </Grid>
    </BaseLoader>
  );
};

MosAndToxForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubstanceToxProfileButttonClick: PropTypes.func.isRequired,
  uomList: PropTypes.array.isRequired, //eslint-disable-line
  cramerClasseList: PropTypes.array.isRequired, //eslint-disable-line
};

export default MosAndToxForm;
