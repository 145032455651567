import { takeLatest, all, put } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { ALL_REFERENTIALS } from './referentialsTypes';
import ReferentialApi from '../../api/referentialApi';
import { allReferentials } from './referentialsActions';
import { callApiSaga } from '../sagaUtils';

export default function* root() {
  yield all([takeLatest(ALL_REFERENTIALS.REQUEST, getAllReferentialData)]);
}

function* getAllReferentialData() {
  yield put(applicationActions.setLoading(ALL_REFERENTIALS.REQUEST));
  yield callApiSaga(allReferentials, ReferentialApi.getAllReferentials);
  yield put(applicationActions.unsetLoading(ALL_REFERENTIALS.REQUEST));
}
