import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import * as taskTypeTypes from './taskTypeTypes';
// import { companytaskTypeAgreementTypes } from '../companytaskTypeAgreement';

const byId = handleActions(
  {
    [taskTypeTypes.TASKTYPE_LIST.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.taskTypes),
    // [taskTypeTypes.TASKTYPE.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.taskTypes),
    // [taskTypeTypes.DELETE_TASKTYPE.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [taskTypeTypes.TASKTYPE_LIST.SUCCESS]: (state, { payload }) => payload.result,
    // [taskTypeTypes.TASKTYPE.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    // [taskTypeTypes.DELETE_TASKTYPE.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
