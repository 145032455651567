import {createSelector} from 'reselect';
import {createGeneralSelectors} from '../_common/selectors';
import config from '../../config';

const API_ROOT = config.baseUrl;

const getOrderImportListSlice = state => state.orderImport.orderImportList;
export const {getById, getIds, makeGetByQuery} = createGeneralSelectors(getOrderImportListSlice);

export const getOrderImportListById = state => state.orderImport.byId;
export const getOrderImportListAllId = state => state.orderImport.list.allIds;
export const getOrderImportList =
  createSelector([getOrderImportListAllId, getOrderImportListById],
    (allIds, byId) => allIds.map(id => {
      var elem = byId[id];

      return {
        ...elem,
        Document: {
          ...elem.Document,
          FileLink: `${API_ROOT}/OrderImportDocuments/${elem.DocumentId}/File`,
        }
      };
    }),
  );
