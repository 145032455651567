import { takeLatest, all, call } from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as rawMatFormActions from './rawMatFormActions';
import { isString } from '../../../utils/utils';
import { rawMatSagas } from '../../rawMat';

export default function* root() {
  yield all([takeLatest(rawMatFormActions.submitRawMatForm.REQUEST, submitRawMatFormSaga)]);
}

const submitRawMatFormSaga = createSaveFormWithValidation(rawMatFormActions.submitRawMatForm, saveRawMatFormSaga);

function* saveRawMatFormSaga({ type, payload, meta }) {
  const { RawMatSupplierId } = payload;

  const rawMatToSave = isString(RawMatSupplierId)
    ? {
      ...payload,
      RawMatSupplierId: -1,
      RawMatSupplier: {
        RawMatSupplierId: -1,
        Name: RawMatSupplierId,
      },
    }
    : payload;

  yield call(rawMatSagas.saveRawMatSaga, {
    type,
    payload: rawMatToSave,
    meta,
  });
}
