import * as labelSymbolWizardSelectors from './labelSymbolWizardSelectors';
import * as labelSymbolWizardActions from './labelSymbolWizardActions';
import * as labelSymbolWizardTypes from './labelSymbolWizardTypes';
import sagas from './labelSymbolWizardSagas';
import reducer from './labelSymbolWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { labelSymbolWizardSelectors, labelSymbolWizardActions, labelSymbolWizardTypes };
export default reducer;
