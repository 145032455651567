import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import StepComponent from '../../_Common/StepComponent';
import { connect } from 'react-redux';

const StatusCellRenderer = props => {
  const { onHistoryClick, options, onChange, data } = props;
  return (
    <StepComponent
      label={I18n.t('product.status')}
      options={options}
      value={data.Status}
      onChange={status => {
        status.TaskId = data.TaskId;
        onChange(status);
      }}
      onHistoryClick={() => onHistoryClick(data.TaskId)}
    />
  );
};

StatusCellRenderer.defaultProps = {
  node: {},
};

StatusCellRenderer.propTypes = {
  // options: PropTypes.object.isRequired, //eslint-disable-line
  onChange: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
};

// export default connect(
//   null,
//   mapDispatchToProps,
// )(StepComponent);
export default StatusCellRenderer;
// export default connect(null, null)(StatusCellRenderer);
