import { schema } from 'normalizr';
import { labelSymbolSchemas } from '../labelSymbol';

export const labelReviewSymbolSchema = new schema.Entity(
  'labelReviewSymbols',
  {},
  { idAttribute: 'LabelReviewSymbolId' },
);
export const labelReviewSymbolWithDetailsSchema = new schema.Entity(
  'labelReviewSymbols',
  {
    LabelSymbol: labelSymbolSchemas.labelSymbolWithDetailsSchema,
  },
  { idAttribute: 'LabelReviewSymbolId' },
);
export const labelReviewSymbolListSchema = [labelReviewSymbolSchema];
export const labelReviewSymbolWithDetailsListSchema = [labelReviewSymbolWithDetailsSchema];
