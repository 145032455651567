import { createSelector } from 'reselect';
import { referentialSelectors } from '../referentials';

export const getIds = ({ phrase }) => phrase.list.allIds;
export const getById = ({ phrase }) => phrase.byId;
export const getCurrent = ({ phrase }) => phrase.current;
export const getphrase = (state, id) => state.phrase.byId[id];
export const getphraseList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getPhraseByCode = (state, phraseCode) => getphraseList(state).find(elem => elem.PhraseCode === phraseCode);

export const getDetailedPhraseList = createSelector(
  [getphraseList, referentialSelectors.getCpsrTypeList],
  (phraseList, cpsrTypeList) =>
    phraseList.map(phrase => ({
      ...phrase,
      CpsrType: cpsrTypeList.find(cpsrType => cpsrType.CpsrTypeCode === phrase.CpsrTypeCode),
    })),
);

export const getTablePhraseForCpsrType = cpsrType =>
  createSelector(getphraseList, phraseList => {
    const result = phraseList.find(phrase => phrase.CpsrTypeCode === cpsrType && phrase.PhraseCode.startsWith('TB'));
    return result;
  });

const getPhraseListForCpsrTypeCode = cpsrTypeCode =>
  createSelector(getphraseList, phraseList => phraseList.filter(phrase => phrase.CpsrTypeCode === cpsrTypeCode));

export const makeGetPhraseListForCpsrTypeCode = createSelector(
  getPhraseListForCpsrTypeCode,
  phraseListForCpsrTypeCode => phraseListForCpsrTypeCode,
);
