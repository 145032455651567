import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import OrderDetailsContainer from './_OrderDetailsContainer';
import OrderProductsContainer from './OrderProducts';
import OrderProcessContainer from './OrderProcess';
import OrderTaskContainer from './OrderTask';

const styles = {
  productActions: css({
    '& #order-actions': {
      float: 'right',
      lineHeight: '34px',
      marginRight: '10px',
    },
  }),
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const OrderDetails = props => {
  const orderId = Number(props.params.id);
  const duplicateId = (props.location.query && Number(props.location.query.duplicateFrom)) || null;
  return (
    <div className={styles.detailsContainer}>
      <OrderDetailsContainer orderId={orderId} duplicateId={duplicateId} {...props} />
      <Col sm={12}>
        <Tabs id="OrderTabs" defaultActiveKey={1}>
          <Tab eventKey={1} title={I18n.t('order.processessTab')}>
            <div>
              <OrderProcessContainer orderId={orderId} />
            </div>
          </Tab>
          <Tab eventKey={2} title={I18n.t('order.tasksTab')}>
            <div>
              <OrderTaskContainer orderId={orderId} />
            </div>
          </Tab>
          <Tab eventKey={3} title={I18n.t('order.products')}>
            <div>
              <OrderProductsContainer orderId={orderId} />
            </div>
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

OrderDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      duplicateFrom: PropTypes.string,
    }),
  }),
};

OrderDetails.defaultProps = {
  location: PropTypes.shape({
    query: {},
  }),
};

export default OrderDetails;
