import { createSelector } from 'reselect';
import { substanceCosAnnexeSelectors } from '../substanceCosAnnexe';
import { createGeneralSelectors } from '../_common/selectors';

const getCosAnnexeStateSlice = state => state.cosAnnexe;
export const {
  getById,
  getIds,
  getLoading,
  getByQueryIds,
  makeGetByQuery,
  makeGetByQueryLoading,
} = createGeneralSelectors(getCosAnnexeStateSlice);

export const getCosAnnexeList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const makeGetCosAnnexeByCode = createSelector(getCosAnnexeList, cosAnnexeList => cosAnnexeCode =>
  cosAnnexeList.find(cos => cos.Code === cosAnnexeCode) || {},
);

export const makeGetCosAnnexeListForSubstance = createSelector(
  [getById, substanceCosAnnexeSelectors.makeGetSubstanceCosAnnexesForSubstance, makeGetCosAnnexeByCode],
  (cosAnnexeById, getSubstanceCosAnnexeListForSubstance, getCosAnnexeByCode) =>
    createSelector(getSubstanceCosAnnexeListForSubstance, substanceCosAnnexesForSubstance =>
      substanceCosAnnexesForSubstance.map(substanceCosAnnexe => getCosAnnexeByCode(substanceCosAnnexe.CosAnnexeCode)),
    ),
);
