import React from 'react';
import { css } from 'glamor';
import { Image, Grid, Row, Col } from 'react-bootstrap';
import images from '../../assets/img';
import HomePageForm from './homePageForm';

const Home = () => (
  <Grid fluid>
    <Row>
      <Col sm={12}>
        <Image className={`${css({ maxHeight: '300px', margin: '0 auto' })}`} src={images.calmos} responsive />
      </Col>
    </Row>
    <Row className={`${css({ paddingTop: '30px' })}`}>
      <Col sm={12}>
        <HomePageForm />
      </Col>
    </Row>
  </Grid>
);

export default Home;
