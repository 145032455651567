import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {GeneralGrid, CheckListItemRenderer} from '../../_Common/Grids';
import * as R from "ramda";
import workflowStatus from "../../../constants/workflowStatus";
import ActionButtonsRenderer from "./ActionButtonRenderer";

const MandatoryInfoInCaseInspection = props => {
  const {
    lastFRProductData,
    productId,
    onReceivedClick,
    onVerifiedClick
  } = props;

  const getIsIsReceivedButtonFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_MISSING),
    R.always(false),
    R.always(true),
  );

  const getIsVerifiedButtonDisabledFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_RECEIVED),
    R.always(false),
    R.always(true),
  );

  const itemList = [];
  if (lastFRProductData.PackagingInfoStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'PackagingInfoStatus',
      productId: productId,
      status: lastFRProductData.PackagingInfoStatus
    });
  }
  if (lastFRProductData.CompatibilityPackagingStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'CompatibilityPackagingStatus',
      productId: productId,
      status: lastFRProductData.CompatibilityPackagingStatus
    });
  }

  if (lastFRProductData.OutPackagingStabilityStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'OutPackagingStabilityStatus',
      productId: productId,
      status: lastFRProductData.OutPackagingStabilityStatus
    });
  }
  if (lastFRProductData.ProductMsdsStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({text: 'ProductMsdsStatus', productId: productId, status: lastFRProductData.ProductMsdsStatus});
  }
  if (lastFRProductData.ManufacturingMethodStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'ManufacturingMethodStatus',
      productId: productId,
      status: lastFRProductData.ManufacturingMethodStatus
    });
  }

  if (lastFRProductData.QCStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({text: 'QCStatus', productId: productId, status: lastFRProductData.QCStatus});
  }

  if (lastFRProductData.UndesirableEffectsStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'UndesirableEffectsStatus',
      productId: productId,
      status: lastFRProductData.UndesirableEffectsStatus
    });
  }
  if (lastFRProductData.NonAnimalTestingStatementStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'NonAnimalTestingStatementStatus',
      productId: productId,
      status: lastFRProductData.NonAnimalTestingStatementStatus
    });
  }

  const columns = [
    {
      headerName: 'Info type',
      field: 'text',
      width: 100,
      minWidth: 100,
      cellRendererFramework: CheckListItemRenderer
    },
    {
      headerName: I18n.t('general.actions'),
      width: 55,
      cellRendererFramework: ActionButtonsRenderer,
      cellRendererParams: {
        onMarkAsReceivedClick: onReceivedClick,
        onMarkAsVerifiedClick: onVerifiedClick,
        getIsReceivedButtonDisabled: R.compose(
          getIsIsReceivedButtonFromStatusCode,
          R.prop('status'),
        ),
        getIsVerifiedButtonDisabled: R.compose(
          getIsVerifiedButtonDisabledFromStatusCode,
          R.prop('status'),
        ),
      }
    }
  ];
  return (
    <div>
      <span><b><Translate value="lastFRCheckList.mandatoryInformationForInspection"/></b></span>
      <GeneralGrid
        key={itemList.length + props.isLoadingCheckListTab}
        data={itemList}
        columns={columns}
        keyName="text"
        height="15vh"
        showFilterRow={false}
      />
    </div>
  );
};

MandatoryInfoInCaseInspection.propTypes = {
  isLoadingCheckListTab: PropTypes.bool.isRequired,
};

export default MandatoryInfoInCaseInspection;
