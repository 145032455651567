import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  productProportionActions,
  productProportionTypes,
  productProportionSelectors,
} from '../../../redux/productProportion';
import { DetailedProductList } from '../../_Common/Lists';
import { applicationSelectors } from '../../../redux/application';

class RawMatProductListContainer extends Component {
  static propTypes = {
    rawMatId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.rawMatId !== this.props.rawMatId) {
      this.props.onLoad();
    }
  }

  render() {
    return <DetailedProductList {...this.props} />;
  }
}
const mapStateToProps = (state, { rawMatId }) => {
  const getProductsForRawMat = productProportionSelectors.makeGetProductsForRawMat(rawMatId);
  return {
    productList: getProductsForRawMat(state),
    isLoadingProductList: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onLoad: () => {
    if (rawMatId > 0) {
      dispatch(productProportionActions.listProductProportionsForRawMat.request(rawMatId));
    }
  },
  fetchProductList: () => dispatch(productProportionActions.listProductProportionsForRawMat.request(rawMatId)),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
});

RawMatProductListContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RawMatProductListContainer);
