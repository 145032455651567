import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { InputField, SelectField, DateField } from '../../../_Common/Forms';
import { taskFormSelectors } from '../../../../redux/form/taskForm';
import { taskActions } from '../../../../redux/task';
import { userSelectors } from '../../../../redux/user';
import userRoleTypes from '../../../../constants/userRoleTypes';
import { maxLength255, isValidDate, isPositiveOrZeroInteger, requiredField } from '../../../../utils/fieldValidation';
import { orderItemSelectors } from '../../../../redux/orderItem';
import { taskTypeSelectors } from '../../../../redux/taskType';
import moment from 'moment';
import { workflowStatusSelectors } from '../../../../redux/workflowStatus';

const formName = formNames.task;

const style = {
  detailsContainer: css({
    '& .react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__input-container': {
      width: '100%',
    },
  }),
};

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrderItemId: null,
      TaskTypeCode: null,
    };
  }

  render() {
    const { onSubmit, handleSubmit, expertList, manager, orderItems, taskId, initialValues, taskTypeList, taskIds, workflowStatusList } = this.props;
    const orderItemList = getOrderItemList(orderItems);
    // let processList = this.state.orderItemId
    //   ? orderItemList.find(elem => elem.OrderItemId === this.state.orderItemId).Processes
    //   : [];
    let productList = []
    let processList = [];
    orderItemList.map(orderItem => orderItem.Processes.map(process => {
      if (productList.indexOf(process.ProductCode) == -1) {
        processList.push(process);
        productList.push(process.ProductCode);
      }
    }));
    let taskTypeFinalList = [];
    if (this.state.orderItemId) {
      const processTypeId = orderItemList.find(elem => elem.OrderItemId === this.state.orderItemId).ProcessTypeId;
      taskTypeFinalList = this.state.orderItemId
        ? taskTypeList.filter(type => type.ProcessTypeId === processTypeId || type.Code === 'OTHER')
        : taskTypeList.filter(type => type.Code === 'OTHER');
    }
    processList = getProcessList(processList);

    return (
      <div className={style.detailsContainer}>
        <Form horizontal onSubmit={handleSubmit(onSubmit)}>
          <Grid fluid>
            {taskId === -1 && taskIds === undefined && (
              <div>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="OrderItemId"
                      label={I18n.t('orderTask.process')}
                      component={SelectField}
                      options={orderItemList}
                      labelField="FullName"
                      valueField="OrderItemId"
                      formAlignment={false}
                      onValueChange={value => {
                        this.setState({
                          orderItemId: value ? value.OrderItemId : null,
                        });
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="TaskTypeCode"
                      label={I18n.t('orderTask.taskType')}
                      component={SelectField}
                      options={taskTypeFinalList}
                      labelField="Name"
                      valueField="Code"
                      formAlignment={false}
                      onValueChange={value => {
                        this.setState({
                          TaskTypeCode: value ? value.Code : null,
                        });
                        this.props.change('ExpectedDuration', value.ExpectedDuration);
                        this.props.change('DueDate', calculateDueDate(moment(new Date()), value.DeliveryTime))
                      }}
                      validate={requiredField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="ProcessId"
                      label={I18n.t('orderTask.product')}
                      component={SelectField}
                      options={processList}
                      labelField="Label"
                      valueField="ProcessId"
                      formAlignment={false}
                      validate={requiredField}
                    />
                  </Col>
                </Row>
                {this.state.TaskTypeCode === 'OTHER' && (
                  <Row>
                    <Col sm={12}>
                      <Field
                        name="Weight"
                        label={`${I18n.t('taskType.weight')}`}
                        component={InputField}
                        validate={[isPositiveOrZeroInteger]}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
            <Row>
              <Col sm={12}>
                <Field
                  name="Expert"
                  label={I18n.t('orderTask.expert')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                />
              </Col>
            </Row>
            {manager && (
              <div>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="Description"
                      label={I18n.t('orderTask.memo')}
                      component={InputField}
                      componentClass="textarea"
                      rows={4}
                      validate={maxLength255}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="ExpectedDuration"
                      label={I18n.t('orderProcess.expectedDuration')}
                      component={InputField}
                      validate={isPositiveOrZeroInteger}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="DueDate"
                      label={I18n.t('orderTask.dueDate')}
                      component={DateField}
                      validate={isValidDate}
                    />
                  </Col>
                </Row>
                {taskIds !== undefined && (
                  <Row>
                    <Col sm={2}>
                      <Field
                        name="Status"
                        label={I18n.t('orderTask.status')}
                        component={SelectField}
                        options={workflowStatusList}
                        labelField="Name"
                        valueField="Code"
                        matchPos="start"
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, { taskId, orderId, taskIds }) => {
  const getFormInitialValues = taskFormSelectors.makeGetInitialValues(taskId);
  const getItemForOrder = orderItemSelectors.makeGetByQuery(state);
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('TASK');
  return {
    initialValues: getFormInitialValues(state),
    expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
    workflowStatusList: getWorkflowStatusList(state),
    orderItems: getItemForOrder({ orderId, IncludeDetails: true }),
    taskTypeList: taskTypeSelectors.getTaskTypeList(state),
    taskIds: taskIds
  };
};

const mapDispatchToProps = (dispatch, { orderId, productId, searchCriteria, taskIds }) => ({
  onSubmit: data => {
    dispatch(taskActions.saveTask.request({ ...data, orderId, productId, searchCriteria, taskIds }))
  },
});

TaskForm.defaultProps = {
  initialValues: {},
};

TaskForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const calculateDueDate = (date, deliveryTime) => {
  if (deliveryTime == 0) {
    return date
  }
  else
  {
    date = moment.utc(date).add(1,'days');
    const day = date.isoWeekday();
    const isWeekend = (day === 6 || day === 7);    // 6 = Saturday, 7 = Sunday
    if (isWeekend) {
      return calculateDueDate(date, deliveryTime);
    } else {
      return calculateDueDate(date, deliveryTime-1);
    }
  }
};

const getOrderItemList = orderItemList => {
  const setOrderItemFullName = orderItem =>
    R.assoc('FullName', `${R.prop('ProcessTypeValue', orderItem)} - ${R.prop('Region', orderItem)}`, orderItem);

  return orderItemList.map(setOrderItemFullName);
};

const getProcessList = processList => {
  const setProcessLabel = process =>
    R.assoc(
      'Label',
      `${R.prop('ProductName', process) ? R.prop('ProductName', process) : I18n.t('orderProcess.unknown')}`,
      process,
    );

  return processList.map(setProcessLabel);
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(TaskForm);
