import uuidV1 from 'uuid/v1';
import {takeLatest, put, all, select, call, takeEvery} from 'redux-saga/effects';
import * as formulaReviewWizardTypes from './formulaReviewWizardTypes';
import * as formulaReviewWizardActions from './formulaReviewWizardActions';
import * as formulaReviewWizardSelectors from './formulaReviewWizardSelectors';
import {createFetchSaga} from '../../sagaUtils';
import {
  formulaReviewSelectors,
  formulaReviewActions,
  formulaReviewTypes,
  formulaReviewSagas,
} from '../../formulaReview';
import {modalTypes, modalActions, modalSelectors} from '../../modal';
import {inciListSagas} from '../../inciList';
import {productSelectors} from '../../product';

export default function* root() {
  yield all([
    takeLatest(formulaReviewWizardTypes.SAVE_FORMULA_REVIEW, saveFormulaReviewSaga),
    takeLatest(formulaReviewTypes.SAVE_FORMULA_REVIEW.SUCCESS, onFormulaReviewSavedSaga),
    takeLatest(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST, newFomulaReviewWizardRequestSaga),
    takeLatest(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.SUCCESS, newFomulaReviewWizardSuccessSaga),
    takeLatest(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.REQUEST, editFomulaReviewWizardRequestSaga),
    takeEvery(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.SUCCESS, editFomulaReviewWizardSuccesSaga),
  ]);
}

// export function* waitFormulaReviewIsLoaded(FormulaReviewId) {
//   yield all([
//     take(
//       R.allPass([
//         R.propEq('type', formulaReviewTypes.FETCH_FORMULA_REVIEWS.SUCCESS),
//         R.pathEq(['meta', 'query', 'FormulaReviewId'], FormulaReviewId),
//       ]),
//     ),
//     take(R.propEq('type', inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS)),
//   ]);
// }

const editFomulaReviewWizardRequestSaga = createFetchSaga(
  function* editFomulaReviewWizardRequestSaga({payload, type}) {
    const {query} = payload;
    const result = yield all([
      call(formulaReviewSagas.fetchFormulaReviewSaga, {payload, type}),
      call(inciListSagas.getInciListForProductSaga, {payload: query, type}),
    ]);
    return result;
  },
  {
    * onSuccess(data, payload) {
      const {query: {FormulaReviewId, ProductId} = {}} = payload;
      yield put(formulaReviewWizardActions.editFormulaReviewWizard.success({FormulaReviewId, ProductId}, payload));
    },
    onFailure: (error, payload) => put(formulaReviewWizardActions.editFormulaReviewWizard.failure(error, payload)),
  },
);

function* editFomulaReviewWizardSuccesSaga({payload, meta}) {
  const {
    query: {FormulaReviewId, ProductId},
  } = meta;
  const formulaReview = yield select(state => formulaReviewSelectors.getFormulaReview(state, FormulaReviewId));

  yield put(formulaReviewWizardActions.loadFormulaReviewData(formulaReview));

  const product = yield select(state => productSelectors.getProduct(state, ProductId));
  yield put(
    modalActions.showModal({
      modalType: modalTypes.PRODUCT_FORMULA_REVIEW,
      modalProps: {
        ...payload,
        ProductId,
        title: `Edit Formula review ${product.ProductName}`,
        modalWidth: '1200px',
      },
    }),
  );
}

function* newFomulaReviewWizardSuccessSaga({payload, meta}) {
  const {
    query: {ProductId},
  } = meta;
  const { FormulaReviewId, ...otherPayload } = payload;

  const product = yield select(state => productSelectors.getProduct(state, ProductId));
  // cosmos-2268 JLL added
  // const latestFormulaReview = yield select(state =>
  //   formulaReviewSelectors.getLatestFormulaReviewForProduct(state, ProductId),
  // );
  const formulaReviewForCopy = yield select(state =>
    formulaReviewSelectors.getById(state)[FormulaReviewId],
  );

  const formulaReview = {
    FormulaReviewId: uuidV1(),
    Conclusion: 'CPSRA_NOTTODO',
    // cosmos-2268 JLL added
    CompositionComment: formulaReviewForCopy !== undefined ? formulaReviewForCopy.CompositionComment : '',
    ProductId,
    RegionCode: FormulaReviewId ? formulaReviewForCopy.RegionCode : null,
    CountryAlpha2: FormulaReviewId ? formulaReviewForCopy.CountryAlpha2 : null
  };

  yield put(formulaReviewWizardActions.loadFormulaReviewData(formulaReview));

  yield put(
    modalActions.showModal({
      modalType: modalTypes.PRODUCT_FORMULA_REVIEW,
      modalProps: {
        ...otherPayload,
        title: `New Formula review ${product.ProductName}`,
        modalWidth: '1200px',
      },
    }),
  );
}

const newFomulaReviewWizardRequestSaga = createFetchSaga(
  function* newFomulaReviewWizardRequestSaga({payload, type}) {
    const {query = {}} = payload;
    const {ProductId, FormulaReviewId} = query;

    if (FormulaReviewId) {
      yield all([
        call(formulaReviewSagas.fetchFormulaReviewSaga, {
          payload: {query: {...payload.query, FormulaReviewId}},
          type,
        }),
        call(inciListSagas.getInciListForProductSaga, {payload: query, type}),
      ]);
    }
  },
  {
    * onSuccess(data, payload) {
      const {query: {FormulaReviewId, ProductId} = {}} = payload;
      yield put(formulaReviewWizardActions.newFormulaReviewWizard.success({FormulaReviewId, ProductId}, payload));
    },
    onFailure: (error, payload) => put(formulaReviewWizardActions.newFormulaReviewWizard.failure(error, payload)),
  },
);

function* saveFormulaReviewSaga() {
  const formulaReview = yield select(formulaReviewWizardSelectors.getFormulaReviewWithDetails);
  yield put(formulaReviewActions.saveFormulaReview.request(formulaReview));
}

function* onFormulaReviewSavedSaga() {
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PRODUCT_FORMULA_REVIEW) {
    yield put(modalActions.hideModal());
  }
}
