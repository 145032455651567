import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { applicationSelectors } from '../../../redux/application';
import { DetailedProductList } from '../../_Common/Lists';
import {
  substanceProductActions,
  substanceProductSelectors,
  substanceProductTypes,
} from '../../../redux/substanceProduct';

class SubstanceProductListContainer extends Component {
  static propTypes = {
    substanceId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <DetailedProductList {...this.props} />;
  }
}
const mapStateToProps = (state, { substanceId }) => {
  const getProductsForSubstance = substanceProductSelectors.makeGetProductsForSubstance(substanceId);
  return {
    productList: getProductsForSubstance(state),
    isLoadingProductList: applicationSelectors.isLoading(
      state,
      substanceProductTypes.FETCH_PRODUCTS_FOR_SUBSTANCE.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceProductActions.fetchProductsForSubstance.request(substanceId));
    }
  },
  fetchProductList: () => dispatch(substanceProductActions.fetchProductsForSubstance.request(substanceId)),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
});

SubstanceProductListContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceProductListContainer);
