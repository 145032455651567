import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import SelectionGrid from '../_Common/Grids/SelectionGrid';

const CompaniesList = (props) => {
  const {
    companyList,
    onCompanySelected,
    requestingCompanyList,
    selectedId,
    addCompany,
    getCompanyList,
  } = props;

  const columns = [
    {
      headerName: I18n.t('company.code'),
      width: 140,
      field: 'Code',
      suppressSizeToFit: true,
      sort: 'asc',
    },
    {
      headerName: I18n.t('company.shortName'),
      width: 100,
      field: 'ShortName',
    },
    {
      headerName: I18n.t('company.am'),
      width: 35,
      field: 'CustomerSupport',
    },
  ];
  return (
    <SelectionGrid
      data={companyList}
      onSelected={onCompanySelected}
      onRefresh={getCompanyList}
      isLoading={requestingCompanyList}
      selectedId={selectedId}
      columns={columns}
      onFetchData={props.onFetchData}
      suppressRowClickSelection
      keyName="CompanyId"
      showAddButton
      onAdd={addCompany}
    />
  );
};

CompaniesList.defaultProps = {
  selectedId: '',
};

CompaniesList.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.shape({
    CompanyId: PropTypes.number,
    Code: PropTypes.string,
    FullName: PropTypes.string,
  })),
  getCompanyList: PropTypes.func,
  onCompanySelected: PropTypes.func,
  requestingCompanyList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
  addCompany: PropTypes.func,
};

export default CompaniesList;
