import { createRequestActions, createFetchAction } from '../utils';
import { createAction } from 'redux-actions';
import * as notificationTypes from './notificationTypes';

export const saveNotification = createRequestActions(notificationTypes.SAVE_NOTIFICATION);
export const deleteNotification = createRequestActions(notificationTypes.DELETE_NOTIFICATION);
export const updateNotification = createRequestActions(notificationTypes.UPDATE_NOTIFICATION);

export const editNotification = createAction(notificationTypes.EDIT_NOTIFICATION, ProductId => ({
  ProductId,
}));

export const listNotificationsForProduct = createRequestActions(
  notificationTypes.NOTIFICATIONS_FOR_PRODUCT_LIST,
  ProductId => ({ ProductId }),
);

export const generateComplianceCertificate = createRequestActions(notificationTypes.GENERATE_COMPLIANCE_CERTIFICATE);
export const downloadComplianceCertificate = createRequestActions(notificationTypes.DOWNLOAD_COMPLIANCE_CERTIFICATE);
export const reniewComplianceCertificate = createRequestActions(notificationTypes.RENIEW_COMPLIANCE_CERTIFICATE);


