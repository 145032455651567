import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { evolve, omit } from 'ramda';
import Companies from './Companies';
import { companyActions, companyTypes, companySelectors } from '../../redux/company';
import { applicationSelectors } from '../../redux/application';

class CompaniesPage extends Component {
  componentDidMount() {
    this.props.getCompanyList();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <Companies selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  companyList: companySelectors.getCompanyList(state),
  isLoadingDetails: applicationSelectors.isLoading(
    state,
    companyTypes.SAVE_COMPANY.REQUEST,
    companyTypes.COMPANY.REQUEST,
  ),
  requestingCompanyProductList: applicationSelectors.isLoading(state, companyTypes.COMPANY_PRODUCT_LIST.REQUEST),
  requestingCompanyList: applicationSelectors.isLoading(state, companyTypes.COMPANY_LIST.REQUEST),
});

CompaniesPage.propTypes = {
  getCompanyList: PropTypes.func.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
};

CompaniesPage.defaultProps = {
  params: PropTypes.shape({
    id: '',
  }),
  location: PropTypes.shape({
    query: {},
  }),
};

const mapDispatchToProps = (dispatch, { location }) => ({
  getCompanyList: () => dispatch(companyActions.listCompanies.request()),
  onCompanySelected: id =>
    dispatch(
      push(
        evolve({
          query: omit(['duplicateFrom']),
          pathname: () => `/companies/${id}`,
        })(location),
      ),
    ),
  addCompany: () => dispatch(push({ query: { show_details: true }, pathname: '/companies/-1' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage);
