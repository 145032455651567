import React from 'react';
import PropTypes from 'prop-types';
import InformationListContainer from './_InformationListContainer';

const InformationTab = props => {
  const { substanceId } = props;
  return <InformationListContainer substanceId={substanceId} />;
};

InformationTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default InformationTab;
