import uuidV1 from 'uuid/v1';
import {takeLatest, put, all, select} from 'redux-saga/effects';
import * as formulaReviewWizardTypes from '../formulaReviewWizardTypes';
import * as productDataReviewWizardActions from './productDataReviewWizardActions';
import {formulaReviewSelectors} from '../../../formulaReview';
import {productDataReviewSelectors} from '../../../productDataReview';
import workflowStatus from "../../../../constants/workflowStatus";

export default function* root() {
  yield all([
    takeLatest(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.SUCCESS, newFomulaReviewWizardSaga),
    takeLatest(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.SUCCESS, editFomulaReviewWizardSaga),
  ]);
}

function* editFomulaReviewWizardSaga({payload}) {
  const {FormulaReviewId} = payload;

  const productDataReview = yield select(state =>
    productDataReviewSelectors.getProductDataReviewForFormulaReview(state, FormulaReviewId),
  );

  yield put(productDataReviewWizardActions.loadProductReviewData(productDataReview));
}

function* newFomulaReviewWizardSaga({payload}) {
  const {ProductId, FormulaReviewId} = payload;

  const formulaReviewForCopy = yield select(state =>
    formulaReviewSelectors.getById(state)[FormulaReviewId],
  );

  const latestFormulaReviewProductDataReview = formulaReviewForCopy
    ? yield select(state =>
      productDataReviewSelectors.getProductDataReviewForFormulaReview(state, formulaReviewForCopy.FormulaReviewId),
    )
    : undefined;

  const finalProductDataReview = latestFormulaReviewProductDataReview ? {
    ...(latestFormulaReviewProductDataReview),
    FormulaStatus: latestFormulaReviewProductDataReview.FormulaStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.FormulaStatus,
    ProductCofaStatus: latestFormulaReviewProductDataReview.ProductCofaStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.ProductCofaStatus,
    InPackagingStabilityStatus: latestFormulaReviewProductDataReview.InPackagingStabilityStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.InPackagingStabilityStatus,
    PackagingInfoStatus: latestFormulaReviewProductDataReview.PackagingInfoStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.PackagingInfoStatus,
    CompatibilityPackagingStatus: latestFormulaReviewProductDataReview.CompatibilityPackagingStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.CompatibilityPackagingStatus,
    ChallengeTestStatus: latestFormulaReviewProductDataReview.ChallengeTestStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.ChallengeTestStatus,
    MicrobiologicalQualityStatus: latestFormulaReviewProductDataReview.MicrobiologicalQualityStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.MicrobiologicalQualityStatus,
    PrimaryLabelStatus: latestFormulaReviewProductDataReview.PrimaryLabelStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.PrimaryLabelStatus,
    SecondaryLabelStatus: latestFormulaReviewProductDataReview.SecondaryLabelStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.SecondaryLabelStatus,
    ProductSpfTestStatus: latestFormulaReviewProductDataReview.ProductSpfTestStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.ProductSpfTestStatus,
    DermatologicalIrritationTestsStatus: latestFormulaReviewProductDataReview.DermatologicalIrritationTestsStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.DermatologicalIrritationTestsStatus,
    OcularIrritationTestsStatus: latestFormulaReviewProductDataReview.OcularIrritationTestsStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.OcularIrritationTestsStatus,
    BatchNrStatus: latestFormulaReviewProductDataReview.BatchNrStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.BatchNrStatus,
    PackagingProcedureStatus: latestFormulaReviewProductDataReview.PackagingProcedureStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.PackagingProcedureStatus,
    OutPackagingStabilityStatus: latestFormulaReviewProductDataReview.OutPackagingStabilityStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.OutPackagingStabilityStatus,
    ProductMsdsStatus: latestFormulaReviewProductDataReview.ProductMsdsStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.ProductMsdsStatus,
    MetrologyStatementStatus: latestFormulaReviewProductDataReview.MetrologyStatementStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.MetrologyStatementStatus,
    ManufacturingMethodStatus: latestFormulaReviewProductDataReview.ManufacturingMethodStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.ManufacturingMethodStatus,
    QCStatus: latestFormulaReviewProductDataReview.QCStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.QCStatus,
    UndesirableEffectsStatus: latestFormulaReviewProductDataReview.UndesirableEffectsStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.UndesirableEffectsStatus,
    NonAnimalTestingStatementStatus: latestFormulaReviewProductDataReview.NonAnimalTestingStatementStatus === workflowStatus.DOCUMENT_VERIFIED ? workflowStatus.DOCUMENT_RECEIVED_VERIFIED : latestFormulaReviewProductDataReview.NonAnimalTestingStatementStatus,
  } : latestFormulaReviewProductDataReview;

  const productDataReview = {
    ...(finalProductDataReview || {
      ProductId,
      Conclusion: 'CPSRA_NOTTODO',
      FormulaStatus: workflowStatus.DOCUMENT_MISSING,
      ProductCofaStatus: workflowStatus.DOCUMENT_MISSING,
      InPackagingStabilityStatus: workflowStatus.DOCUMENT_MISSING,
      PackagingInfoStatus: workflowStatus.DOCUMENT_MISSING,
      CompatibilityPackagingStatus: workflowStatus.DOCUMENT_MISSING,
      ChallengeTestStatus: workflowStatus.DOCUMENT_MISSING,
      MicrobiologicalQualityStatus: workflowStatus.DOCUMENT_MISSING,
      PrimaryLabelStatus: workflowStatus.DOCUMENT_MISSING,
      SecondaryLabelStatus: workflowStatus.DOCUMENT_MISSING,
      ProductSpfTestStatus: workflowStatus.DOCUMENT_MISSING,
      DermatologicalIrritationTestsStatus: workflowStatus.DOCUMENT_MISSING,
      OcularIrritationTestsStatus: workflowStatus.DOCUMENT_MISSING,
      BatchNrStatus: workflowStatus.DOCUMENT_MISSING,
      PackagingProcedureStatus: workflowStatus.DOCUMENT_MISSING,
      OutPackagingStabilityStatus: workflowStatus.DOCUMENT_MISSING,
      ProductMsdsStatus: workflowStatus.DOCUMENT_MISSING,
      MetrologyStatementStatus: workflowStatus.DOCUMENT_MISSING,
      ManufacturingMethodStatus: workflowStatus.DOCUMENT_MISSING,
      QCStatus: workflowStatus.DOCUMENT_MISSING,
      UndesirableEffectsStatus: workflowStatus.DOCUMENT_MISSING,
      NonAnimalTestingStatementStatus: workflowStatus.DOCUMENT_MISSING,
    }),
    ProductDataReviewId: uuidV1(),
  };

  yield put(productDataReviewWizardActions.loadProductReviewData(productDataReview));
}
