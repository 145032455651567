import {get, post, del, download, uploadFiles} from './apiUtils';

const defaultUrl = '/Products/';
const productOrdersUrl = '/Orders/';
const productCategoriesUrl = '/Categories/';
const productTasksUrl = '/Tasks/';
const productImpactAssessmentsUrl = '/ImpactAssessments/';
const productPhrasesUrl = '/ProductPhrases/';
const proportionsUrl = '/Proportions/';
const ProdigumProductProportionsUrl = '/ProdigumProductProportions/';
const ImportedProductProportionsUrl = '/ImportedProductProportions/';
const ImportProductProportionsUrl = '/ImportProductProportions/';
const productProportionsUrl = '/ProductProportions/';
const fullProductProportionsUrl = '/FullProductProportions/';
const cosmosIngredientRawMatMatchesUrl = '/CosmosIngredientRawMatMatches/';
const inciListUrl = '/InciList/';
const mosCalculationListUrl = '/MosCalculationList/';
const formulaReviewListUrl = '/FormulaReviewList/';
const impuritiestUrl = '/Impurities/';
const lastFRCheckList = '/LastFRCheckList/';
const FRReceivedDocument = '/FRReceivedDocument/';
const lastFRMissingDocument = '/LastFRMissingDocument/';

export default {
  getProductList: () => get(`${defaultUrl}`),
  getProductList2: () => get(`${defaultUrl}/manufacturerAndCPSR`),
  getProduct: productId => get(`${defaultUrl}${productId}`),
  getProductOrders: id => get(`${defaultUrl}${id}${productOrdersUrl}`),
  getProductCategories: id => get(`${defaultUrl}${id}${productCategoriesUrl}`),
  getProductTasks: id => get(`${defaultUrl}${id}${productTasksUrl}`),
  getProductImpactAssessments: id => get(`${defaultUrl}${id}${productImpactAssessmentsUrl}`),
  getProductPhrases: id => get(`${productPhrasesUrl}`, {productId: id}),
  saveProduct: data => post(`${defaultUrl}`, data),
  saveProductPhrases: data => post(productPhrasesUrl, data),
  saveProductCpsrbComment: ({ProductId, ...rest}) => post(`${defaultUrl}${ProductId}/UpdateCpsrbComment`, rest),
  deleteProductPhrases: ids => del(productPhrasesUrl, ids),
  getProductProportions: id => get(`${defaultUrl}${id}${proportionsUrl}`),
  getFullProductProportions: id => get(`${defaultUrl}${id}${fullProductProportionsUrl}`),
  getProdigumProductProportions: id => get(`${defaultUrl}${id}${ProdigumProductProportionsUrl}`),
  getImportedProductProportions: id => get(`${defaultUrl}${id}${ImportedProductProportionsUrl}`),
  saveProductProportions: data => post(productProportionsUrl, data),
  saveProductProportion: data => post(productProportionsUrl, data),
  processImportedProductProportions: payload => get(`${defaultUrl}${payload.ProductId}${ImportedProductProportionsUrl}/Process`, payload.data),
  importProductProportions: payload => uploadFiles(`${defaultUrl}${payload.ProductId}${ImportProductProportionsUrl}`, payload.data),
  deleteImportedProductProportions: payload => del(`${defaultUrl}${payload.ProductId}${ImportedProductProportionsUrl}`, payload.data),
  getProductCosmosIngredientRawMatMatches: id => get(`${defaultUrl}${id}${cosmosIngredientRawMatMatchesUrl}`),
  deleteProductProportion: id => del(`${productProportionsUrl}${id}`),
  getProductInciList: id => get(`${defaultUrl}${id}${inciListUrl}`),
  getProductMosCalculationList: payload => {
    return get(`${defaultUrl}${payload.productId}${mosCalculationListUrl}${payload.productCategory?payload.productCategory.ProductCategoryId:-1}`)
  },
  getProductFormulaReviewList: id => get(`${defaultUrl}${id}${formulaReviewListUrl}`),
  getProductImpurityList: id => get(`${defaultUrl}${id}${impuritiestUrl}`),
  getOrdersOwnedByProductCompany: id => get(`${defaultUrl}${id}/OrdersOwnedByProductCompany`),
  updateStatus: data => post(`${defaultUrl}/status`, data),
  getProductListForCompany: companyId => get(`${defaultUrl}/?CompanyId=${companyId}`),
  getProductClp: productId => get(`${defaultUrl}${productId}/clp`),
  updateInciQty: productId => get(`/UpdateProductInciQty?ProductId=${productId}`),
  duplicateProduct: (originalProductId, productToSave) =>
    post(`${defaultUrl}/${originalProductId}/duplicate`, productToSave),
  exportCompositionForLatinAmerica: productId => download(`${defaultUrl}${productId}/ExportCompoByShade`),
  getLastFRProductData: id => get(`${defaultUrl}${id}${lastFRCheckList}`),
  checkListChangeStatusDocument: data => get(`${defaultUrl}${data.productId}${FRReceivedDocument}${data.document}/${data.status}`),
  getLastFRMissingDocument: id => get(`${defaultUrl}${id}${lastFRMissingDocument}`),
};
