import * as substanceActionSelectors from './substanceActionSelectors';
import * as substanceActionActions from './substanceActionActions';
import * as substanceActionTypes from './substanceActionTypes';
import sagas from './substanceActionSagas';
import reducer from './substanceActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceActionSelectors, substanceActionActions, substanceActionTypes };
export default reducer;
