import * as substanceInformationSelectors from './substanceInformationSelectors';
import * as substanceInformationActions from './substanceInformationActions';
import * as substanceInformationTypes from './substanceInformationTypes';
import sagas from './substanceInformationSagas';
import reducer from './substanceInformationReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceInformationSelectors, substanceInformationActions, substanceInformationTypes };
export default reducer;
