import moment from 'moment';
import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const TimeCellRenderer = ({ value }) => {
  const dateFormat = 'HH:mm:ss';
  if (isNullOrUndef(value)) return '';
  const resultValue = moment.utc(value);
  if (resultValue.isValid()) {
    resultValue.local();
  }
  return resultValue.isValid() ? resultValue.format(dateFormat) : value;
};

TimeCellRenderer.defaultProps = {
  value: '',
};

TimeCellRenderer.propTypes = {
  value: PropTypes.string,
};

export default TimeCellRenderer;
