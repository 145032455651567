import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import formNames from '../../constants/formNames';
import DistributorForm from './DistributorForm';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { distributorActions, distributorSelectors, distributorTypes } from '../../redux/distributor';
import { referentialSelectors, referentialsTypes } from '../../redux/referentials';
import { applicationSelectors } from '../../redux/application';
import { companyActions } from '../../redux/company';

class DistributorDetailsContainer extends Component {
  componentDidMount() {
    this.props.onLoadDistributor();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.distributorId !== this.props.distributorId) {
      this.props.onLoadDistributor();
    }
  }

  render() {
    return <DistributorForm {...this.props} />;
  }
}

function mapStateToProps(state, { distributorId }) {
  const currentDistributor = distributorSelectors.getById(state)[distributorId];
  return {
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      distributorTypes.DISTRIBUTOR.REQUEST,
      distributorTypes.SAVE_DISTRIBUTOR.REQUEST,
      referentialsTypes.ALL_REFERENTIALS.REQUEST,
    ),

    savingDistributor: applicationSelectors.isLoading(state, distributorTypes.SAVE_DISTRIBUTOR.REQUEST),
    isNew: distributorSelectors.isNew(state),
    currentDistributor,
    countryList: referentialSelectors.getCountryList(state),
    languageList: referentialSelectors.getLanguageList(state),
    initialValues: currentDistributor,
    // isUsed: distributorSelectors.isCurrentDistributorUsed(state),
    distributorTitle: distributorSelectors.distributorTitle(state),
  };
}

function mapDispatchToProps(dispatch, { distributorId }) {
  return {
    onLoadDistributor: () => {
      if (distributorId > 0) {
        dispatch(distributorActions.distributor.request(distributorId));
        dispatch(companyActions.listCompanies.request());
      }
      // dispatch(distributorActions.listDistributorCompanies.request(id));
      // dispatch(distributorActions.listDistributorProducts.request(id));
    },
    onSaveDistributor: data => {
      dispatch(distributorActions.saveDistributor.request(data));
    },
    // getDistributorCompanies: id => dispatch(distributorActions.listDistributorCompanies.request(id)),
    getDistributorProducts: id => dispatch(distributorActions.listDistributorProducts.request(id)),
    // linkCompanyToDistributor: data => dispatch(distributorActions.linkCompanyToDistributor.request(data)),
    // linkProductToDistributor: data => dispatch(distributorActions.linkProductToDistributor.request(data)),
    // onProductListRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
    // onCompanyListRowDoubleClicked: company => dispatch(push(`/companies/${company.CompanyId}`)),
    onDeleteDistributor: () => dispatch(distributorActions.deleteDistributor.request(distributorId)),
  };
}

DistributorDetailsContainer.propTypes = {
  distributorId: PropTypes.number.isRequired,
  onLoadDistributor: PropTypes.func.isRequired,
};

const withData = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withProductFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.distributor);

export default compose(
  withData,
  withProductFormLeaveRouteDirtyWrapper,
)(DistributorDetailsContainer);
