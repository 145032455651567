import { all, call, put, takeLatest } from 'redux-saga/effects';
import { applicationActions } from '../../application';
import { modalActions } from '../../modal';
import { shadeSagas } from '../../shade';
import { shadeXRmActions, shadeXRmTypes } from './index';

export default function* root() {
  yield all([takeLatest(shadeXRmTypes.LOAD_MODAL_DATA.REQUEST, loadModalDataSaga)]);
}

function* loadModalDataSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { ProductId } = payload;
    yield call(shadeSagas.fetchShadeSagas, {
      payload: {
        query: { ProductId, include: { OptIncludeShadeProportions: true } },
      },
    });

    yield put(shadeXRmActions.loadModalData.success(payload));
    yield put(modalActions.showShadeXRmModal({ productId: ProductId }));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(shadeXRmActions.loadModalData.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
