import { schema } from 'normalizr';

export const companyDistributorAgreementSchema = new schema.Entity(
  'companyDistributorAgreements',
  {
    // Document: documentSchemas.documentSchema,
    // Company: companySchemas.companySchema,
    // Distributor: distributorSchemas.distributorSchema,
  },
  { idAttribute: 'CompanyDistributorAgreementId' },
);

export const companyDistributorAgreementListSchema = [companyDistributorAgreementSchema];
