import queryString from 'query-string';
import { get, post } from './apiUtils';

const defaultUrl = '/Substances/';

export default {
  getSubstanceList: () => get(defaultUrl),
  getSubstance: substanceId => get(`${defaultUrl}${substanceId}`),
  saveSubstance: data => post(`${defaultUrl}`, data),
  getSubstanceProportions: id => get(`${defaultUrl}${id}/Proportions/`),
  getSubstanceFunctions: id => get(`${defaultUrl}${id}/Functions/`),
  getRawMatsForSubstance: id => get(`${defaultUrl}${id}/RawMats/`),
  getProductsForSubstance: id => get(`${defaultUrl}${id}/Products/`),
  updateStatus: data => post(`${defaultUrl}/status`, data),
  fetchSubstances: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  getSubstanceCosAnnexesForSubstance: substanceId =>
   get(`${defaultUrl}${substanceId}/SubstanceCosAnnexes/`),
};
