import { createRequestTypes } from '../utils';

export const FETCH_PRODUCT_PROPORTIONS = createRequestTypes('productProportion/FETCH_PRODUCT_PROPORTIONS');
export const PRODUCT_PROPORTION_LIST_FOR_PRODUCT = createRequestTypes(
  'productProportion/PRODUCT_PROPORTION_LIST_FOR_PRODUCT',
);
export const PRODUCT_PROPORTION_LIST_FOR_RAWMAT = createRequestTypes(
  'productProportion/PRODUCT_PROPORTION_LIST_FOR_RAWMAT',
);
export const ADD_PRODUCT_PROPORTION = 'productProportion/ADD_PRODUCT_PROPORTION';
export const EDIT_PRODUCT_PROPORTION = 'productProportion/EDIT_PRODUCT_PROPORTION';
export const SAVE_PRODUCT_PROPORTIONS = createRequestTypes('productProportion/SAVE_PRODUCT_PROPORTIONS');
export const REORDER_PRODUCT_PROPORTION = 'productProportion/REORDER_PRODUCT_PROPORTION';
export const UPDATE_MAX_QTY = createRequestTypes('productProportion/UPDATE_MAX_QTY');
export const SELECT_PRODUCT_PROPORTION_RM = createRequestTypes('productProportion/SELECT_PRODUCT_PROPORTION_RM');
export const SELECT_PRODUCT_PROPORTION_RM_SUBMIT = createRequestTypes('productProportion/SELECT_PRODUCT_PROPORTION_RM_SUBMIT');
export const ADD_RM_TO_PRODUCT_AND_GO_TO_PRODUCT = createRequestTypes('productProportion/ADD_RM_TO_PRODUCT_AND_GO_TO_PRODUCT');
