import { createSelector } from 'reselect';

export const getIds = (state) => state.workflowStatus.list.allIds;
export const getById = ({ workflowStatus }) => workflowStatus.byId;
export const getWorkflowStatus = (state, id) => getById(state)[id];

export const getWorkflowStatusList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getWorkflowStatusListByCode = code =>
  createSelector(getWorkflowStatusList, workflowStatusList =>
    workflowStatusList.filter(workflow => workflow.WorkflowCode === code).sort((a, b) => a.OrderNr - b.OrderNr),
  );

export const getWorkflowStatusListByFinalCode = createSelector(getWorkflowStatusList, workflowStatusList => (code) =>
  workflowStatusList.find(workflow => workflow.Code === code)
);

export const makeGetWorkflowStatusListByCode = createSelector(
  getWorkflowStatusListByCode,
  workflowStatusListByCode => workflowStatusListByCode,
);

export const getAgreementStatusList = makeGetWorkflowStatusListByCode('AGREEMENT');
export const getMandateStatusList = makeGetWorkflowStatusListByCode('MANDATE');
export const getCpsrbConclusionStatusList = makeGetWorkflowStatusListByCode('CPSRB_CONCLUSION');
export const getRawMatStatusList = makeGetWorkflowStatusListByCode('RAWMAT');
export const getProductCompoStatusList = makeGetWorkflowStatusListByCode('PRODUCT_COMPO');
