import * as substanceProductSelectors from './substanceProductSelectors';
import * as substanceProductActions from './substanceProductActions';
import * as substanceProductTypes from './substanceProductTypes';

import sagas, * as substanceProductSagas from './substanceProductSagas';
import reducer from './substanceProductReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceProductSelectors, substanceProductActions, substanceProductTypes, substanceProductSagas };
export default reducer;
