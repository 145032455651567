import { takeLatest, put, all } from 'redux-saga/effects';
import { submit } from 'redux-form';
import * as generateAgreementFormTypes from './generateAgreementFormTypes';
import * as generateAgreementFormActions from './generateAgreementFormActions';
import formNames from '../../../constants/formNames';
import { createSaveFormWithValidation } from '../utils';
import { saveCompanyDistributorAgreementsSaga } from '../../companyDistributorAgreement/companyDistributorAgreementSagas';

const formName = formNames.generateAgreement;

export default function* root() {
  yield all([
    takeLatest(generateAgreementFormTypes.SAVE_BUTTON_CLIKED, saveForm),
    takeLatest(generateAgreementFormActions.submitAgreementForm.REQUEST, submitAgreementFormSaga),
  ]);
}

function* saveForm() {
  yield put(submit(formName));
}

const submitAgreementFormSaga = createSaveFormWithValidation(
  generateAgreementFormActions.submitAgreementForm,
  saveCompanyDistributorAgreementsSaga,
);
