import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import StepComponent from '../../_Common/StepComponent';
import formNames from '../../../constants/formNames';
import { productSelectors } from '../../../redux/product';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { modalActions } from '../../../redux/modal';
import cpsrTypes from '../../../constants/cpsrTypes';

const ProductCpsrStatus = props => {
  const { workflowStatusList, statusCode, onChange, disabled, nextStepDisabled } = props;
  return (
    <StepComponent
      options={workflowStatusList}
      value={statusCode}
      onChange={onChange}
      disabled={disabled}
      nextStepDisabled={nextStepDisabled}
    />
  );
};

const mapStateToProps = (state, { ProductId, CpsrTypeCode, disabled = false }) => {
  const product = productSelectors.getById(state)[ProductId];
  const getWorkflowStatusList = workflowStatusSelectors.makeGetWorkflowStatusListByCode(CpsrTypeCode);
  const statusCode = CpsrTypeCode === cpsrTypes.CPSRA ? product.CpsrAStatus : product.CpsrBStatus;
  return {
    statusCode,
    disabled: disabled || productSelectors.isNew(state) || isDirty(formNames.product)(state),
    workflowStatusList: getWorkflowStatusList(state),
  };
};

const mapDispatchToProps = (dispatch, { ProductId, CpsrTypeCode }) => ({
  onChange: ({ Code }) =>
    dispatch(modalActions.showCpsrStatusCommentModal({ nextStatusCode: Code, ProductId, CpsrTypeCode })),
});

ProductCpsrStatus.defaultProps = {
  nextStepDisabled: false,
};

ProductCpsrStatus.propTypes = {
  workflowStatusList: PropTypes.array.isRequired, //eslint-disable-line
  // statusCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  nextStepDisabled: PropTypes.bool,
  ProductId: PropTypes.number.isRequired, //eslint-disable-line
  CpsrTypeCode: PropTypes.string.isRequired, //eslint-disable-line
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCpsrStatus);
