import PropTypes from 'prop-types';
import React from 'react';
import BaseLoader from '../../_Common/BaseLoader';
import MandatoryInfoCPSR from './MandatoryInfoCPSR';
import MandatoryInfoLabelReview from './MandatoryInfoLabelReview';
import MandatoryInfoInCaseInspection from './MandatoryInfoInCaseInspection';
import {Col} from 'react-bootstrap';
import MissingDocuments from './MissingDocuments';

const CheckListTab = props => {
  const {isLoadingCheckListTab, lastFRProductData} = props;
  return (
    <BaseLoader isLoading={isLoadingCheckListTab}>
      {lastFRProductData != null && (
        <div>
          <Col sm={6}>
            <MissingDocuments {...props} />
          </Col>
          <Col sm={6}>
            <MandatoryInfoCPSR {...props} /><br/>
            <MandatoryInfoLabelReview {...props} /><br/>
            <MandatoryInfoInCaseInspection {...props} />
          </Col>
        </div>
      )}
    </BaseLoader>
  );
};

CheckListTab.propTypes = {
  isLoadingCheckListTab: PropTypes.bool.isRequired,
};

export default CheckListTab;
