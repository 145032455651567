import { createAction } from 'redux-actions';
import * as labelReviewClaimWizardTypes from './labelReviewClaimWizardTypes';
import { createUpdateAction } from '../../../../utils/actions';

export const loadLabelReviewClaimData = createAction(labelReviewClaimWizardTypes.LOAD_LABEL_REVIEW_CLAIM_DATA);
export const handleFieldEdit = createUpdateAction(labelReviewClaimWizardTypes.FIELD_EDITED);
export const handleAddButtonClick = createAction(labelReviewClaimWizardTypes.ADD_BUTTON_CLICKED);
export const handleDeleteButtonClick = createAction(labelReviewClaimWizardTypes.DELETE_BUTTON_CLICKED);
export const handleRevertButtonClick = createAction(labelReviewClaimWizardTypes.REVERT_BUTTON_CLICKED);
export const addNewLabelReviewClaim = createAction(labelReviewClaimWizardTypes.ADD_NEW_LABEL_REVIEW_CLAIM);
export const removeLabelReviewClaim = createAction(labelReviewClaimWizardTypes.REMOVE_LABEL_REVIEW_CLAIM);
export const updateLabelReviewClaim = createAction(labelReviewClaimWizardTypes.UPDATE_LABEL_REVIEW_CLAIM);
