import * as labelSymbolSelectors from './labelSymbolSelectors';
// import * as labelSymbolActions from './labelSymbolActions';
// import * as labelSymbolTypes from './labelSymbolTypes';
import * as labelSymbolSchemas from './labelSymbolSchemas';
import sagas from './labelSymbolSagas';
import reducer from './labelSymbolReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { labelSymbolSelectors, labelSymbolSchemas };
export default reducer;
