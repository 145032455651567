import { createSelector } from 'reselect';

export const getIds = ({ safecosmeticDashboard }) => safecosmeticDashboard.list.allIds;
export const getById = ({ safecosmeticDashboard }) => safecosmeticDashboard.byId;
export const getSafecosmeticDashboard = (state, id) => state.safecosmeticDashboard.byId[id];
export const getProductList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getSafecosmeticDashboardProductList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);
