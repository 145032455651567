import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import RawMatForm from './RawMatForm';
import formNames from '../../constants/formNames';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { rawMatActions, rawMatSelectors, rawMatTypes } from '../../redux/rawMat';
import { rawMatFormSelectors, rawMatFormActions } from '../../redux/form/rawMatForm';
import { rawMatActionActions } from '../../redux/rawMatAction';
import { rawMatSupplierActions, rawMatSupplierSelectors } from '../../redux/rawMatSupplier';
import { applicationSelectors } from '../../redux/application';
import { companySelectors } from '../../redux/company';
import {productProportionActions} from "../../redux/productProportion";

class RawMatDetailsContainer extends Component {
  componentDidMount() {
    this.props.onLoadRawMat();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.rawMatId !== this.props.rawMatId) {
      this.props.onLoadRawMat();
    }
  }

  render() {
    return <RawMatForm {...this.props} />;
  }
}

const mapStateToProps = (state, { rawMatId, location }) => {
  const showDetails = location.query.show_details === 'true';
  const returnToProduct = location.query.returnToProduct;
  const rawMat = rawMatFormSelectors.makeGetInitialValues(state)(rawMatId);
  const isNew = rawMatSelectors.isNew(state);
  const company = R.compose(
    companyId => R.propOr({}, companyId, companySelectors.getById(state)),
    R.propOr(null, 'CompanyId'),
  )(rawMat);
  return {
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      rawMatTypes.RAWMAT.REQUEST,
      rawMatTypes.SAVE_RAWMAT.REQUEST,
    ),
    companyFullName: R.propOr('', 'FullName', company),
    initialValues: rawMat,
    isNew,
    RawMatSupplierList: rawMatSupplierSelectors.getRawMatSupplierList(state),
    rawMatTitle: rawMatSelectors.getRawMatTitle(state),
    showDetails,
    returnToProduct,
    isRawMatEditable: rawMatSelectors.makeIsRawMatEditable(rawMatId)(state) || isNew,
  };
};

const mapDispatchToProps = (dispatch, { rawMatId, location }) => {
  const showDetails = location.query.show_details === 'true';
  const returnToProduct = location.query.returnToProduct;
  const tabName = location.query.tab;
  const importedProductProportionId = location.query.importedProductProportionId;
  return {
    onLoadRawMat: () => {
      if (rawMatId > 0) {
        dispatch(rawMatActions.rawMat.request(rawMatId));
        dispatch(rawMatActionActions.fetchRawMatActions.request({ rawMatId }));
      }
      dispatch(rawMatSupplierActions.listRawMatSuppliers.request());
    },
    onSaveRawMat: rawMatFormActions.submitRawMatForm,
    onShowDetails: () => dispatch(push({ ...location, query: { ...location.query, show_details: !showDetails } })),
    onReturnToProduct: () => {
      dispatch(productProportionActions.addRmToProductAndGoToProduct.request({rawMatId, productId: returnToProduct, tab: tabName, importedProductProportionId: importedProductProportionId}));
      // dispatch(push({pathname: '/products/'+returnToProduct, query: { openTab: 3, addRawMat: rawMatId }}));
    }
  };
};

const withData = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withProductFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.rawMat);

export default compose(
  withData,
  withProductFormLeaveRouteDirtyWrapper,
)(RawMatDetailsContainer);

RawMatDetailsContainer.propTypes = {
  onLoadRawMat: PropTypes.func.isRequired,
  rawMatId: PropTypes.number.isRequired,
  currentRawMat: PropTypes.object.isRequired, //eslint-disable-line
};

RawMatDetailsContainer.defaultProps = {
  currentRawMat: {}, //eslint-disable-line
};
