import React, { Component } from 'react';
import reactDom from 'react-dom';
// import { whyDidYouUpdate } from 'why-did-you-update';
import { Provider } from 'react-redux';
import { Router, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { persistStore } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import { getRoutes } from './routes';
import configureStore from './redux/store';
import './index.css';
import './locales';
// import DevTools from './components/DevTools';
import ModalRoot from './components/ModalRoot';
import { setAuthTokenGetter } from './api/apiUtils';
import { userSelectors } from './redux/user';
//import registerServiceWorker from './registerServiceWorker';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
// const isDev = process.env.NODE_ENV !== 'production';

// const isDev = false;

// Disabled React devtools if in PRODUCTION
if (
  process.env.NODE_ENV === 'production' &&
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ &&
  Object.keys(window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers).length
) {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {};
}

// whyDidYouUpdate(React);
const store = configureStore();

// Inject selector method in callapi for token
setAuthTokenGetter(() => userSelectors.userToken(store.getState()));

const history = syncHistoryWithStore(hashHistory, store);

// history.listen(location => console.log(location));

const userSaveFilter = createFilter('user', ['currentUser']);

class AppProvider extends Component {
  constructor() {
    super();
    this.state = {
      rehydrated: false,
    };
  }

  componentWillMount() {
    persistStore(
      store,
      {
        whitelist: ['user'],
        transforms: [userSaveFilter],
      },
      () => {
        this.setState({ rehydrated: true });
      },
    );
  }

  render() {
    if (!this.state.rehydrated) {
      return <div>Loading...</div>;
    }
    return (
      <Provider store={store}>
        <div>
          <Router history={history}>{getRoutes(store)}</Router>
          <ModalRoot />
          {/* {isDev && <DevTools />} */}
        </div>
      </Provider>
    );
  }
}

reactDom.render(<AppProvider />, document.getElementById('root'));
//registerServiceWorker();
