import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../_Common/Forms';
import { requiredField } from '../../utils/fieldValidation';
import { DirectiveStatusTypesDropdown } from '../_Common/Dropdowns';
import CollapsePanelLink from '../_Common/CollapsePanelLink';

class SubstanceBasicFormSection extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showDetails: false,
      invalid: false,
    };
  }
  render() {
    const { readOnly } = this.props;
    return (
      <div>
        <Row>
          <Col sm={12}>
            <Field
              name="Code"
              label={`${I18n.t('substance.code')} *`}
              component={InputField}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="InciName"
              label={`${I18n.t('substance.inciName')} * 1`}
              component={InputField}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="InciNameUs"
              label={I18n.t('substance.inciNameUs')}
              component={InputField}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              name="CasNr"
              label={I18n.t('substance.casNr')}
              component={InputField}
              readOnly={readOnly}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="EcNr"
              label={I18n.t('substance.ecNr')}
              component={InputField}
              readOnly={readOnly}
            />
          </Col>
        </Row>
        <CollapsePanelLink
          defaultCollapsed={!this.state.showDetails}
          onExpandPanel={() => this.setState({ showDetails: !this.state.showDetails })}
          disabled={this.state.invalid && this.state.showDetails}>
          <div>
            <Row>
              <Col sm={12}>
                <Field
                  name="Description"
                  label={I18n.t('general.description')}
                  component={InputField}
                  readOnly={readOnly}
                  componentClass="textarea"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Field
                  name="IupacName"
                  label={I18n.t('substance.iupacName')}
                  component={InputField}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Field
                  name="InnName"
                  label={I18n.t('substance.innName')}
                  component={InputField}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="CosDirective"
                  label={I18n.t('substance.CosDirective')}
                  component={InputField}
                  readOnly={readOnly}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="DirectiveStatusCode"
                  label={`${I18n.t('substance.DirectiveStatusCode')} *`}
                  component={DirectiveStatusTypesDropdown}
                  validate={requiredField}
                  disabled={readOnly}
                />
              </Col>
            </Row>
          </div>
        </CollapsePanelLink>
      </div>
    );
  }
}

SubstanceBasicFormSection.defaultProps = {
  name: 'Substance',
  readOnly: false,
};

SubstanceBasicFormSection.propTypes = {
  name: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default SubstanceBasicFormSection;
