import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as phraseListModalTypes from './phraseListModalTypes';

const selectedPhrase = handleActions(
  {
    [phraseListModalTypes.HIDE_PHRASE_LIST_MODAL]: () => null,
  },
  null,
);

const filter = handleActions(
  {
    [phraseListModalTypes.SET_FILTER]: (state, { payload }) => payload,
    [phraseListModalTypes.SHOW_PHRASE_LIST_MODAL]: (state, { payload: { CpsrTypeCode } }) => CpsrTypeCode || state,
  },
  'all',
);

export default combineReducers({
  selectedPhrase,
  filter,
});
