import { createSelector } from 'reselect';
import { isDirty } from 'redux-form';
import formNames from '../../../constants/formNames';
import { substanceClpSelectors } from '../../substanceClp';

const formName = formNames.substanceClp;

export const makeGetInitialValues = createSelector(
  substanceClpSelectors.makeGetSubstanceClpsForSubstance,
  initialValues => initialValues || {},
);

export const isFormDirty = isDirty(formName);
