import {put, all, select, takeLatest, call, takeEvery} from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as categoryTRFormActions from './categoryTRFormActions';
import * as categoryTRFormSelectors from './categoryTRFormSelectors';
import { categoryTRSagas } from '../../../redux/categoryTR';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import {initialize} from "redux-form";
import formNames from "../../../constants/formNames";
import {referentialSelectors} from "../../referentials";

export default function* root() {
  yield all([
    takeLatest(categoryTRFormActions.submitCategoryForm.REQUEST, submitCategoryFormSaga),
    takeEvery(categoryTRFormActions.loadForm, loadFormSaga),
  ]);
}

const submitCategoryFormSaga = createSaveFormWithValidation(categoryTRFormActions.submitCategoryForm, saveCategorySaga);

export function* saveCategorySaga({payload}) {
  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const finalValue = [];
  languages.forEach(lang => {
    finalValue.push(payload[lang.Alpha2]);
  })

  yield call(categoryTRSagas.saveCategoryTRsSaga, {payload: finalValue});
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.CATEGORY_FORM) {
    yield put(modalActions.hideModal());
  }
}

function* loadFormSaga({ payload }) {
  const { categoryCode } = payload;

  const initialFormValues = yield select(state =>
    categoryTRFormSelectors.getInitialValues(state, categoryCode),
  );

  yield put(initialize(formNames.categoryTR, initialFormValues));
}

