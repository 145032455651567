import PropTypes from 'prop-types';
import React from 'react';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { Translate as T } from 'react-redux-i18n';
import fr from '../../assets/img/flags/France.png';
import us from '../../assets/img/flags/United-States.png';

const LanguageSelector = ({ locale, setLocale }) => {
  const selectedFlag = locale === 'en' ? us : fr;
  const Title = (
    <span>
      <img src={selectedFlag} alt="us" />
      &nbsp;
      <T value={`languages.${locale}`} />
    </span>
  );
  return (
    <NavDropdown title={Title} id="basic-nav-dropdown">
      <MenuItem onClick={() => setLocale('en')}>
        <img src={us} alt="us" />&nbsp;<T value="languages.en" />
      </MenuItem>
      <MenuItem onClick={() => setLocale('fr')}>
        <img src={fr} alt="fr" />&nbsp;<T value="languages.fr" />
      </MenuItem>
    </NavDropdown>
  );
};

LanguageSelector.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default LanguageSelector;
