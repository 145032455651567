import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import OrderActionList from './OrderActionList';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const OrderActionsModal = props => {
  const { title, closeModal, orderId } = props;
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OrderActionList height="60vh" orderId={orderId} />
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

OrderActionsModal.defaultProps = {};

OrderActionsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
};

export default OrderActionsModal;
