import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import cosAnnexeTRApi from '../../api/cosAnnexeTRApi';
import * as cosAnnexeTRTypes from './cosAnnexeTRTypes';
import * as cosAnnexeTRActions from './cosAnnexeTRActions';
import * as cosAnnexeTRSchemas from './cosAnnexeTRSchemas';

export default function* root() {
  yield all([
    takeLatest(cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.REQUEST, getSubstanceListSaga),
    takeLatest(cosAnnexeTRTypes.SAVE_COS_ANNEXE_TRs.REQUEST, saveSubstanceTRsSaga),
  ]);
}

export function* getSubstanceListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    cosAnnexeTRActions.listCosAnnexeTR,
    cosAnnexeTRApi.getCosAnnexeTRList,
    cosAnnexeTRSchemas.cosAnnexeTRListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.REQUEST));
}

export function* saveSubstanceTRsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    cosAnnexeTRActions.saveCosAnnexeTRs,
    cosAnnexeTRApi.saveCosAnnexeTR,
    cosAnnexeTRSchemas.cosAnnexeTRListSchema,
    payload,
  );
  yield put(cosAnnexeTRActions.listCosAnnexeTR.request());
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}
