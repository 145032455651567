import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { phraseTypes } from '../../../redux/phrase';
import { phraseTRActions, phraseTRTypes } from '../../../redux/phraseTR';
import { referentialsTypes, referentialSelectors } from '../../../redux/referentials';
import PhraseTranslationList from './PhraseTranslationList';
import { applicationSelectors } from '../../../redux/application';
import {getFormValues, isPristine, isSubmitting, reset} from "redux-form";
import formNames from "../../../constants/formNames";
import {phraseTRFormActions} from "../../../redux/form/phraseTRForm";
import BaseLoader from "../../_Common/BaseLoader";

const formName = formNames.phrasesTR;

class PhraseTranslationListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <BaseLoader isLoading={this.props.isLoading}>
        <PhraseTranslationList form={formName} {...this.props} />
      </BaseLoader>);
  }
}

const mapStateToProps = state => {
  const formValues = getFormValues(formName);
  return {
    formValues: formValues(state),
    formName,
    isFormPristine: isPristine(formNames.phrasesTR)(state),
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isLoading: applicationSelectors.isLoading(
      state,
      phraseTypes.PHRASE_LIST.REQUEST,
      phraseTRTypes.PHRASE_TRANSLATION_LIST.REQUEST,
      referentialsTypes.ALL_REFERENTIALS.REQUEST,
      isSubmitting(formName),
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(phraseTRActions.listPhraseTranslation.request());
    dispatch(phraseTRFormActions.loadForm());
  },
  onAddButtonClick: data => dispatch(phraseTRFormActions.addTranslation(data)),
  // onChangeValue: data => dispatch(phraseTRFormActions.changeTranslation(data)),
  onSaveTranslation: data => dispatch(phraseTRFormActions.onSaveButtonClick({ formData: data })),
  onCancelButtonClick: () => {
    dispatch(reset(formName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PhraseTranslationListContainer);

PhraseTranslationListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
