import {all, select, takeEvery, put, } from "redux-saga/effects";
import formNames from "../../../constants/formNames";
import * as substanceFormActions from "./substanceFormActions";
import {referentialSelectors} from "../../referentials";
import { change } from 'redux-form';

const formName = formNames.substance;

export default function* root() {
  yield all([
    takeEvery(substanceFormActions.onCramerClasseSelected, onSelectCramerClasseSaga),
  ]);
}

function* onSelectCramerClasseSaga({ payload }) {
  const { code } = payload;

  const classes = yield select(state => referentialSelectors.getCramerClasses(state));

  const cramerClasse = classes.find(elem => elem.Code === code);
  if(cramerClasse){
    const cramerClasseInfo = `${cramerClasse.Description} ${cramerClasse.Ttc} ${cramerClasse.TtcUom}`;
    yield put(change(formName, 'CramerClasseInfo', cramerClasseInfo));
  }
}
