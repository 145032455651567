import { get, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/safecosmeticDashboard/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getSafecosmeticDashboard: ({ query } = {}) => get(getUrl({ query })),
  getProductSafecosmeticUrl: id => get(`${defaultUrl}/safecosmeticUrl/${id}`),
  // {
  //   console.log(id);
  //   return `http://qr-code-SAT.safecosmetic.info:81/ProductData-SAT/?previewProductSheet=${id}`;
  // },
  getProductCosmosUrl: id => get(`${defaultUrl}/cosmosUrl/${id}`),
  // {
  //   console.log(id);
  //   return `https://biorius.pulsar.be/cosmos-SAT/DesktopModules/PFT/EditProduct.aspx?EntityType=Product&EntityId=${id}&ProductSupplierId=9021`;
  // },
};
