import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

const LoadingButton = ({ isLoading, loadingText, children, disabled, ...otherProps }) => (
  <Button {...otherProps} disabled={isLoading || disabled}>
    {children}&nbsp;{isLoading && <i className="fa fa-spinner fa-spin" aria-hidden="true" />}
  </Button>
);

LoadingButton.defaultProps = {
  isLoading: false,
  disabled: false,
  loadingText: '',
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LoadingButton;
