import { takeLatest, all, put } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { COMPUTE_STATS } from './statsTypes';
import StatApi from '../../api/statApi';
import * as statsActions from './statsActions';
import { callApiSaga } from '../sagaUtils';

export default function* root() {
  yield all([takeLatest(statsActions.computeStats.request, getAllStatData)]);
}

function* getAllStatData({ payload }) {
  console.log(payload);
  // yield put(applicationActions.setLoading(COMPUTE_STATS.REQUEST));
  yield callApiSaga(statsActions.computeStats, StatApi.getAllStats, payload);
  yield put(applicationActions.unsetLoading(COMPUTE_STATS.REQUEST));

  // try {
  //   yield callApiSagaAndNormalize(
  //     generalDashboardActions.listProducts,
  //     generalDashboardApi.getGeneralDashboard,
  //     generalDashboardSchemas.generalDashboardListSchema,
  //     payload,
  //   );
  // } catch (error) {
  //   if (error.Message) {
  //     const message = error.Message;
  //     yield put(applicationActions.setGeneralError(message));
  //     yield put(generalDashboardActions.listProducts.failure(error));
  //   }
  //   console.log('error');
  //   console.log(error);
  // } finally {
  //   yield put(applicationActions.unsetLoading(generalDashboardTypes.GENERALDASHBOARD_LIST.REQUEST));
  // }
}
