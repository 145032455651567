import React from 'react';
import GeneralGrid from './GeneralGrid';
import GeneralDashboardSearchCriteriaPanel from '../GeneralDashboardSearchCriteriaPanel';

const GeneralDashboardSearchGrid = props => (<div>
  <GeneralDashboardSearchCriteriaPanel {...props} />
  <GeneralGrid showAddButton {...props} />
</div>);

GeneralDashboardSearchGrid.propTypes = {};

export default GeneralDashboardSearchGrid;
