import * as productDataReviewWizardSelectors from './productDataReviewWizardSelectors';
import * as productDataReviewWizardActions from './productDataReviewWizardActions';
import * as productDataReviewWizardTypes from './productDataReviewWizardTypes';
import sagas from './productDataReviewWizardSagas';
import reducer from './productDataReviewWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { productDataReviewWizardSelectors, productDataReviewWizardActions, productDataReviewWizardTypes };
export default reducer;
