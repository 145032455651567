import React from 'react';
import { Col, Row, ControlLabel, Media } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import clpImages from '../../../../assets/img/clp';
import images from '../../../../assets/img';
import { InputField, CheckBoxField } from '../../../_Common/Forms';
import { requiredField } from '../../../../utils/fieldValidation';
import CheckBoxSymbol from './CheckBoxSymbol';
import { createForm } from './utils';
import PaoHourglassTypeSelectdField from './PaoHourglassTypeSelectdField';

const OuterLabelForm = props => (
  <Col sm={12}>
    <Col sm={6}>
      <Col sm={11}>
        <Row>
          <Field name="HasOuterLabel" label={I18n.t('draftLabel.HasOuterLabel')} component={CheckBoxField} />
        </Row>
        <Row>
          <Field name="ProductName" label={I18n.t('draftLabel.ProductName')} component={InputField} />
        </Row>
        <Row>
          <Field name="ProductFunction" label={I18n.t('draftLabel.ProductFunction')} component={InputField} />
        </Row>
        <Row>
          <Field name="NominalContents" label={I18n.t('draftLabel.NominalContents')} component={InputField} />
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsInstructionOnOuter"
              label={I18n.t('draftLabel.IsInstructionOnOuter')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="InstructionText"
              label={I18n.t('draftLabel.InstructionText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsMarketingTextOnOuter"
              label={I18n.t('draftLabel.IsMarketingTextOnOuter')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="MarketingTextOnOuter"
              label={I18n.t('draftLabel.MarketingTextOnOuter')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Field name="OriginOuterText" label={I18n.t('draftLabel.OriginOuterText')} component={InputField} />
        </Row>
        <Row>
          <Field
            name="CompanyOuterText"
            label={I18n.t('draftLabel.CompanyOuterText')}
            component={InputField}
            componentClass="textarea"
          />
        </Row>
        <Row>
          <Field
            name="IsIngredientOnOuter"
            label={I18n.t('draftLabel.IsIngredientOnOuter')}
            component={CheckBoxField}
          />
        </Row>
      </Col>
    </Col>
    <Col sm={6}>
      <Col sm={11} smOffset={1}>
        <Row>
          <Col sm={4}>
            <Media>
              <Media.Body>
                <Field
                  name="IsHairColorAttentionSymbolOnOuter"
                  label={I18n.t('draftLabel.IsHairColorAttentionSymbolOnOuter')}
                  component={CheckBoxField}
                />
              </Media.Body>
              <Media.Left align="middle">
                <img src={images.hairColorAttention} alt="hairColorAttention" />
              </Media.Left>
            </Media>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field name="IsWarningOnOuter" label={I18n.t('draftLabel.IsWarningOnOuter')} component={CheckBoxField} />
          </Col>
          <Col sm={9}>
            <Field
              name="WarningText"
              label={I18n.t('draftLabel.WarningText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <ControlLabel>{I18n.t('draftLabel.ClpSymboles')}</ControlLabel>
        </Row>
        <Row>
          <Col sm={2}>
            <Field name="IsFlammableSymbolOnOuter" component={CheckBoxSymbol} imageSrc={clpImages.flammable} />
          </Col>
          <Col sm={2}>
            <Field name="IsCombustibleSymbolOnOuter" component={CheckBoxSymbol} imageSrc={clpImages.oxidising} />
          </Col>
          <Col sm={2}>
            <Field name="IsCorrosiveSymbolOnOuter" component={CheckBoxSymbol} imageSrc={clpImages.corrosive} />
          </Col>
          <Col sm={2}>
            <Field name="IsIrritantSymbolOnOuter" component={CheckBoxSymbol} imageSrc={clpImages.irritant} />
          </Col>
          <Col sm={2}>
            <Field
              name="IsAllergenSymbolOnOuter"
              component={CheckBoxSymbol}
              imageSrc={clpImages.inhalationSensitizer}
            />
          </Col>
          <Col sm={2}>
            <Field name="IsEnvironmentSymbolOnOuter" component={CheckBoxSymbol} imageSrc={clpImages.environment} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsTranslationOnOuter"
              label={I18n.t('draftLabel.IsTranslationOnOuter')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="TranslationText"
              label={I18n.t('draftLabel.TranslationText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field name="IsLeafletOnOuter" label={I18n.t('draftLabel.IsLeafletOnOuter')} component={CheckBoxField} />
            <Field
              name="IsLeafletMandatoryOnOuter"
              label={I18n.t('draftLabel.IsLeafletMandatoryOnOuter')}
              component={CheckBoxField}
              formAlignment={false}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="LeafletSymbolTextOnOuter"
              label={
                <div>
                  {I18n.t('draftLabel.LeafletSymbolTextOnOuter')}{' '}
                  <img
                    style={{ width: '30px' }}
                    src={images.leaflet}
                    alt={I18n.t('draftLabel.LeafletSymbolTextOnOuter')}
                  />
                </div>
              }
              placeholder={I18n.t('draftLabel.LeafletSymbolTextOnOuter')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsPAOSymbolOnOuter"
              label={I18n.t('draftLabel.IsPAOSymbolOnOuter')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="PAOSymbolRemarkOnOuter"
              label={I18n.t('draftLabel.PAOSymbolRemarkOnOuter')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
          <Row>
            <Col sm={12}>
              <Field
                component={PaoHourglassTypeSelectdField}
                name="PAOHourglassTypeCode"
                label={I18n.t('draftLabel.PAOHourglassTypeCode')}
                validate={requiredField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field name="IsGreenSotSymbolOnOuter" component={CheckBoxSymbol} imageSrc={images.greendot} />
            </Col>
            <Col sm={2}>
              <Field name="IsrecyclingSymbolOnOuter" component={CheckBoxSymbol} imageSrc={images.recycling} />
            </Col>
            <Col sm={2}>
              <Field name="IsFSCSymbolOnOuter" component={CheckBoxSymbol} imageSrc={images.fsc} />
            </Col>
            <Col sm={4}>
              <Field
                name="IsBunnySymbolOnInner"
                component={CheckBoxSymbol}
                imageSrc={[images.leapingBunny, images.bunny]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Field
                name="BatchCodeRemarkOnOuter"
                label={I18n.t('draftLabel.BatchCodeRemarkOnOuter')}
                component={InputField}
                componentClass="textarea"
              />
            </Col>
          </Row>
        </Row>
      </Col>
    </Col>
  </Col>
);

OuterLabelForm.propTypes = {};

export default createForm(OuterLabelForm);
