import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Row, Col, Pager, Badge } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import * as R from 'ramda';
import BaseLoader from '../../../_Common/BaseLoader';
import InnerLabelForm from './InnerLabelForm';
import OuterLabelForm from './OuterLabelForm';
import LeafletForm from './LeafletForm';
import DraftLabelSteps from './DraftLabelSteps';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
    height: '70vh',
  }),
  pager: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  },
};

class DraftLabelModel extends Component {
  state = {
    activePage: 0,
  };

  addOne = R.add(1);
  removeOne = R.add(-1);

  handlePageChange = fn => {
    this.setState(({ activePage }) => ({
      activePage: fn(activePage),
    }));
  };

  isFirstPage = () => this.state.activePage === 0;
  isLastPage = () => this.state.activePage === 2;
  handlePreviousPage = () => this.handlePageChange(this.removeOne);

  handleNextPage = () => {
    if (this.isLastPage()) {
      this.props.onSaveButtonClick();
    } else {
      this.props.onNextButtonClick(this.state.activePage);
      this.handlePageChange(this.addOne);
    }
  };

  // isSaveDisabled = () => (this.isLastPage() ? this.props.isInvalid || this.props.isLoading : this.props.isLoading);

  render() {
    const { isLoading, title, productId, isInvalid } = this.props;
    const { activePage } = this.state;
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            <span>&nbsp;&nbsp;</span>
            {isInvalid ? <Badge bsStyle="danger">Errors</Badge> : null}
          </Modal.Title>
        </Modal.Header>
        <div className={styles.modalBody}>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col sm={12}>
                  <DraftLabelSteps activeStep={activePage} />
                </Col>
              </Row>
              <BaseLoader isLoading={isLoading}>
                <Row>{activePage === 0 && <InnerLabelForm productId={productId} />}</Row>
                <Row>{activePage === 1 && <OuterLabelForm productId={productId} />}</Row>
                <Row>{activePage === 2 && <LeafletForm productId={productId} />}</Row>
              </BaseLoader>
            </Grid>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Pager style={styles.pager}>
            {!this.isFirstPage() && (
              <Pager.Item previous disabled={isLoading} onClick={this.handlePreviousPage}>
                {`← ${I18n.t('general.previous')}`}
              </Pager.Item>
            )}
            <Pager.Item next disabled={isLoading || isInvalid} onClick={this.handleNextPage}>
              {this.isLastPage() ? I18n.t('general.save') : `${I18n.t('general.next')} →`}
            </Pager.Item>
          </Pager>
        </Modal.Footer>
      </div>
    );
  }
}

DraftLabelModel.defaultProps = {
  title: '',
  isLoading: false,
};

DraftLabelModel.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool.isRequired,
};

export default DraftLabelModel;
