import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as rawMatSupplierTypes from './rawMatSupplierTypes';
import * as rawMatTypes from '../rawMat/rawMatTypes';

const byId = handleActions(
  {
    [combineActions(rawMatSupplierTypes.RAWMAT_SUPPLIER_LIST.SUCCESS, rawMatTypes.RAWMAT.SUCCESS)]: (
      state,
      { payload },
    ) => merge({}, state, payload.entities.rawMatSuppliers),
  },
  {},
);

const allIds = handleActions(
  {
    [rawMatSupplierTypes.RAWMAT_SUPPLIER_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [rawMatTypes.RAWMAT.SUCCESS]: (state, { payload }) => {
      const { rawMatSuppliers = [] } = payload.entities;
      return [...new Set([...state, ...Object.keys(rawMatSuppliers).map(Number)])];
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
