import { schema } from 'normalizr';
import * as actionSchemas from '../action/actionSchemas';

export const taskActionSchema = new schema.Entity(
  'taskActions',
  { Action: actionSchemas.actionSchema },
  { idAttribute: 'TaskActionId' },
);

export const taskActionListSchema = [taskActionSchema];
