import { createSelector } from 'reselect';
import { referentialSelectors } from '../../../referentials';
import { rawMatSelectors } from '../../../rawMat';
import { rawMatDataReviewSelectors } from '../../../rawMatDataReview';
import * as R from 'ramda';

export const getRawMatDataReviews = ({ formulaReviewWizard }) => formulaReviewWizard.rawMatDataReviews;
export const getRawMatDataReviewsbyId = state => getRawMatDataReviews(state).byId;
export const getRawMatDataReviewsIds = state => getRawMatDataReviews(state).list.allIds;
export const getRawMatDataReview = (state, id) => getRawMatDataReviewsbyId(state)[id];

export const getRawMatDataReviewList = createSelector(
  [getRawMatDataReviewsIds, getRawMatDataReviewsbyId],
  (allIds, byId) => allIds.map(id => byId[id]),
);

const getComment = (rawMatDataReview, defaultComment) =>
  // if (rawMatDataReview.IsOk === true) return rawMatDataReview.Comment;
  // if (!rawMatDataReview.Comment) return defaultComment;
  rawMatDataReview.Comment;

export const getRawMatDataReviewWithAnnexeList = createSelector(
  [getRawMatDataReviewList, referentialSelectors.getRawMatAnnexeParts],
  (rawMatDataReviewList, rawMatAnnexeParts) => {
    const result = rawMatDataReviewList.reduce((acc, rdr) => {
      const rawMatAnnexePart = rawMatAnnexeParts.find(ra => ra.Code === rdr.RawMatAnnexePartCode);
      return [
        ...acc,
        {
          ...rdr,
          RawMatAnnexePart: rawMatAnnexePart,
          Comment: getComment(rdr, rawMatAnnexePart.DefaultComment),
        },
      ];
    }, []);
    return result.sort((a, b) => a.RawMatAnnexePart.Order - b.RawMatAnnexePart.Order);
  },
);

const sortByName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'Name'))]));

export const getRawMatList = (state, productId, formulaReviewId) => {
  const rawMatListForProduct = rawMatSelectors.getRawMatListForProduct(productId)(state);

  const rawMatNamesFromReviews = formulaReviewId
    ? rawMatDataReviewSelectors
      .getRawMatDataReviewListForFormulaReview(state, formulaReviewId)
      .reduce((acc, rdr) => {
        const mandatory =
            (rdr.MissingDocumentsMandatory &&
              rdr.MissingDocumentsMandatory.map(elem => ({ Name: elem.RawMatName, Id: elem.RawMatId }))) ||
            [];
        const notMandatory =
            (rdr.MissingDocumentsNotMandatory &&
              rdr.MissingDocumentsNotMandatory.map(elem => ({ Name: elem.RawMatName, Id: elem.RawMatId }))) ||
            [];
        return [...acc, ...mandatory, ...notMandatory];
      }, [])
      .filter(rm => rm !== null)
    : [];

  const result = [
    ...new Set(rawMatListForProduct.map(rm => ({ Name: rm.Name, Id: rm.RawMatId })).concat(rawMatNamesFromReviews)),
  ];

  const finalRMFromReview = [];
  result.forEach(elem => {
    const existing = finalRMFromReview.find(element => element.Name === elem.Name && element.Id === elem.Id);
    if (existing == null) {
      finalRMFromReview.push(elem);
    }
  });
  const finalResult = sortByName(finalRMFromReview);

  return finalResult;
};
