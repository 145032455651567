import { schema } from 'normalizr';

export const substanceToxProfileSchema = new schema.Entity(
  'substanceToxProfiles',
  {},
  {
    idAttribute: 'SubstanceToxicologicalProfileId',
  },
);

export const substanceToxProfileListSchema = [substanceToxProfileSchema];
