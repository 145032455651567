import PropTypes from 'prop-types';
import React from 'react';
import { RIETextArea } from 'riek';
import { css } from 'glamor';

const styles = {
  editable: css({
    backgroundColor: 'yellow',
  }),
};

const RIEField = props => {
  const {
    label,
    initValue,
    input: { value, onChange },
  } = props;

  const handleChange = e => onChange(e[`${label}`]);

  return (
    <RIETextArea
      propName={label}
      value={value === '' ? initValue : value}
      change={handleChange}
      className={`${styles.editable}`}
      rows={3}
      cols={100}
    />
  );
};

RIEField.defaultProps = {
  initValue: '',
};

RIEField.propTypes = {
  label: PropTypes.string.isRequired,
  initValue: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default RIEField;
