import {createSelector} from 'reselect';
import {productPhraseTRSelectors} from '../../productPhraseTR';
import {productPhraseSelectors} from '../../productPhrase';
import {phraseSelectors} from '../../phrase';
import {phraseTRSelectors} from '../../phraseTR';
import {referentialSelectors} from '../../referentials';
import * as R from "ramda";

const sortByCode = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'PhraseCode'))]));

export const getInitialFormValues = (state, productId, cpsrTypeCode) => {
  const sortedPhraseList = sortByCode(productPhraseSelectors.getProductPhraseList(state)
    .map(productPhrase => {
      productPhrase.Phrase = phraseSelectors.getById(state)[productPhrase.PhraseId];
      productPhrase.PhraseCode = productPhrase.Phrase.PhraseCode;
      return productPhrase;
    })
    .filter(productPhrase => productPhrase.ProductId === productId && productPhrase.CpsrTypeCode === cpsrTypeCode));
  const productPhraseTRList = productPhraseTRSelectors.getProductPhraseTRList(state);
  const phraseTRList = phraseTRSelectors.getPhraseTRList(state);
  const languageList = referentialSelectors.getLanguageList(state);
  return sortedPhraseList.reduce((acc, phrase) => {
    const TRS = {};
    languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
      TRS[lang.Alpha2] = {
        Text: productPhraseTRList.find(elem => elem.LanguageCode === lang.Alpha2 && elem.ProductPhraseId === phrase.ProductPhraseId),
        Phrase: phraseTRList.find(elem => elem.PhraseCode === phrase.PhraseCode && elem.LanguageCode === lang.Alpha2),
      };
    });

    return {
      ...acc,
      [phrase.ProductPhraseId]: {
        ...TRS,
        ProductPhrase: phrase,
        PhraseText: phrase.Phrase.Text,
      },
      allIds: [...acc.allIds, phrase.ProductPhraseId],
    }
  }, {allIds: []});
}
