import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as processTypes from './processTypes';
import queryString from 'query-string';

const byId = handleActions(
  {
    [processTypes.PROCESS.SUCCESS]: (state, { payload }) => merge({}, payload.entities.processes),
  },
  {},
);

const allIds = handleActions(
  {
    [processTypes.PROCESS.SUCCESS]: (state, { payload }) => [...new Set([...state, payload.result])],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
