import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { processTypeSelectors } from '../../../redux/processType';
import { SelectField } from '../Forms';
import * as R from 'ramda';

const ProcessTypeListDropdown = props => {
  const { processTypeList, processTypeListFilter, sortByValue, children } = props;

  const filterOptions = (...args) => sortByValue(processTypeListFilter(...args));

  return children ? (
    children({ processTypeList, filterOptions })
  ) : (
    <SelectField
      options={processTypeList}
      labelField="FullName"
      valueField="ProcessTypeId"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  let processTypeList = initialOptions || processTypeSelectors.getProcessTypeList(state);
  processTypeList = getProcessTypeList(processTypeList);
  return {
    processTypeList,
    sortByValue: processTypeSelectors.sortByValue,
    processTypeListFilter: processTypeSelectors.makeGetProcessTypeListFilter(processTypeList),
  };
};

ProcessTypeListDropdown.defaultProps = {
  children: null,
};

ProcessTypeListDropdown.propTypes = {
  processTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  processTypeListFilter: PropTypes.func.isRequired,
  sortByValue: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const getProcessTypeList = processTypeList => {
  const setProcessTypeFullName = processType =>
    R.assoc('FullName', `${R.prop('ProcessTypeCode', processType)} - ${R.prop('Description', processType)} - ${R.prop('RegionValue', processType)}`, processType);

  return processTypeList.map(setProcessTypeFullName);
};

export default connect(
  mapStateToProps,
  null,
)(ProcessTypeListDropdown);
