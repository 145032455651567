import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Grid, Button } from 'react-bootstrap';
import { css } from 'glamor';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  labelReviewClaimWizardSelectors,
  labelReviewClaimWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewClaimWizard';
import LabelReviewClaimItem from './LabelReviewClaimItem';

const styles = {
  labelReviewClaimItem: css({
    '& .row': {
      marginBottom: '15px',
    },
  }),
  header: css({
    '& h4': {
      textAlign: 'center',
    },
  }),
};

const LabelReviewClaimHeader = () =>
  (<div className={styles.header}>
    <Col sm={4}>
      <h4>
        {I18n.t('labelReviewWizard.claim')}
      </h4>
    </Col>
    <Col sm={1}>
      <h4>{I18n.t('labelReviewWizard.substantation')}</h4>
    </Col>
    <Col sm={7}>
      <Col sm={5}>
        <h4>{I18n.t('labelReviewWizard.remark')}</h4>
      </Col>
      <Col sm={7}>
        <Col sm={10}>
          <h4>{I18n.t('labelReviewWizard.recommandation')}</h4>
        </Col>
      </Col>
    </Col>
  </div>);

const LabelReviewClaims = ({ labelReviewClaimIds, handleAddButtonClick }) =>
  (<Grid fluid>
    <Col sm={12}>
      <Row>
        <LabelReviewClaimHeader />
      </Row>
      {labelReviewClaimIds.map(lrcId =>
        (<div key={lrcId} className={styles.labelReviewClaimItem}>
          <Row>
            <LabelReviewClaimItem labelReviewClaimId={lrcId} />
          </Row>
        </div>),
      )}
      <Col sm={1}>
        <Button onClick={handleAddButtonClick} bsSize="small">
          <i className="fa fa-plus" aria-hidden="true" />
        </Button>
      </Col>
    </Col>
  </Grid>);

LabelReviewClaims.defaultProps = {
  labelReviewClaimIds: [],
};

LabelReviewClaims.propTypes = {
  labelReviewClaimIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired),
  handleAddButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = () => state => ({
  labelReviewClaimIds: labelReviewClaimWizardSelectors.getLabelReviewClaimsIds(state),
});

const mapDispatchToProps = dispatch => ({
  handleAddButtonClick: () => dispatch(labelReviewClaimWizardActions.handleAddButtonClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewClaims);
