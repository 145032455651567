import * as productProportionFormSelectors from './productProportionFormSelectors';
import * as productProportionFormActions from './productProportionFormActions';
import * as productProportionFormTypes from './productProportionFormTypes';
import sagas from './productProportionFormSagas';
import reducer from './productProportionFormReducer';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productProportionFormSelectors, productProportionFormActions, productProportionFormTypes };
export default reducer;
