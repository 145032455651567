import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as rawMatDataReviewWizardTypes from './rawMatDataReviewWizardTypes';

const rawMatDataReviewsById = handleActions(
  {
    [rawMatDataReviewWizardTypes.LOAD_RAWMAT_REVIEW_DATA]: (state, { payload }) => ({
      ...payload.entities.rawMatDataReviews,
    }),
    [rawMatDataReviewWizardTypes.RESET_COMMENT]: (state, { payload }) => {
      const { RawMatDataReviewId, Comment } = payload;
      return {
        ...state,
        [RawMatDataReviewId]: {
          ...state[RawMatDataReviewId],
          Comment,
        },
      };
    },
    [rawMatDataReviewWizardTypes.UPDATE_RAWMAR_REVIEW_DATA]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
  },
  {},
);

const rawMatDataReviewsAllIds = handleActions(
  {
    [rawMatDataReviewWizardTypes.LOAD_RAWMAT_REVIEW_DATA]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId: rawMatDataReviewsById,
  list: combineReducers({
    allIds: rawMatDataReviewsAllIds,
  }),
});
