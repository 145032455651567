import { schema } from 'normalizr';
import * as rawMatSchemas from '../rawMat/rawMatSchemas';
import * as productSchemas from '../product/productSchemas';

export const productSchema = new schema.Entity('products', {}, { idAttribute: 'ProductId' });
export const productListSchema = [productSchema];

export const importedProductProportionSchema = new schema.Entity(
    'importedProductProportions',
    {
      RawMat: rawMatSchemas.rawMatWithProportionsSchema,
      Product: productSchemas.productSchema,
    },
    { idAttribute: 'ImportedProductProportionId' },
  );
export const importedProductProportionWithRawMatSchema = new schema.Entity(
    'importedProductProportions',
    {
      RawMat: rawMatSchemas.rawMatWithProportionsSchema,
    },
    { idAttribute: 'ImportedProductProportionId' },
  );
  
export const importedProductProportionListSchema = [importedProductProportionSchema];
export const importedProductProportionWithRawMatListSchema = [importedProductProportionWithRawMatSchema];
  