import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import TaskTypeForm from './TaskTypeForm';
import BaseLoader from '../../../_Common/BaseLoader';
import { I18n } from 'react-redux-i18n';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
};

const TaskTypeFormModal = props => {
  const { onSaveButtonClick, isDirty, closeModal, isLoadingContent, isSubmitting, isInEditing } = props;

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{isInEditing ? I18n.t('taskType.editTitle') : I18n.t('taskType.addTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseLoader isLoading={isSubmitting}>
          <TaskTypeForm {...props} />
        </BaseLoader>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />
              &nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={onSaveButtonClick}
              disabled={!isDirty || isLoadingContent}
            >
              <i className="fa fa-floppy-o" />
              &nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Body>
    </div>
  );
};

TaskTypeFormModal.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoadingContent: PropTypes.bool.isRequired,
};

export default TaskTypeFormModal;
