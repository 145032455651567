import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { homePageFormActions, homePageFormSelectors } from '../../../redux/ui/homePage/homePageForm';
import { userSelectors } from '../../../redux/user';
import HomePageForm from './HomePageForm';


const formName = 'homePageForm';

class HomePageFormContainer extends Component {
  componentDidMount() {
    this.props.onLoadHomePageForm();
  }

  render() {
    return (
      <div>
        <HomePageForm {...this.props} />
      </div>
    );
  }
}

HomePageFormContainer.propTypes = {
  onLoadHomePageForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const currentUser = userSelectors.getCurrentUser(state);
  return {
    showHomePageForm: (currentUser.ActionCodes.indexOf('ViewDashboard') >= 0),
    initialValues: homePageFormSelectors.getInitialValues(state),
    isSavingForm: homePageFormSelectors.getIsSavingHomePageForm(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoadHomePageForm: () => {
    dispatch(homePageFormActions.onLoadHomePageForm());
  },
  onSaveButtonClicked: () => dispatch(homePageFormActions.saveButtonClicked()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(HomePageFormContainer);
