import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  substanceInformationActions,
  substanceInformationSelectors,
  substanceInformationTypes,
} from '../../../redux/substanceInformation';
import InformationList from './InformationList';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';

class InformationListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }
  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <InformationList {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => {
  const getSubstanceInformationsForSubstance = substanceInformationSelectors.makeGetSubstanceInformationListForSubstance(
    substanceId,
  );
  return {
    substanceInformationList: getSubstanceInformationsForSubstance(state),
    isLoading: applicationSelectors.isLoading(
      state,
      substanceInformationTypes.FETCH_SUBSTANCE_INFORMATIONS.REQUEST,
      substanceInformationTypes.DELETE_SUBSTANCE_INFORMATION.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceInformationActions.fetchSubstanceInformations.request({ substanceId }));
    }
  },
  fetchSubstanceInformationList: () =>
    dispatch(
      dispatch(
        substanceInformationActions.fetchSubstanceInformations.request({
          substanceId,
        }),
      ),
    ),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showSubstanceInformationFormModal({
        substanceId,
      }),
    ),
  onDeleteButtonClick: ({ SubstanceInformationId }) =>
    dispatch(
      substanceInformationActions.deleteSubstanceInformation.request(SubstanceInformationId, {
        substanceId,
      }),
    ),
  onEditButtonClick: ({ SubstanceInformationId: substanceInformationId }) =>
    dispatch(
      modalActions.showSubstanceInformationFormModal({
        substanceId,
        substanceInformationId,
      }),
    ),
});

InformationListContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationListContainer);
