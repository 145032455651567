import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../../_Common/BaseLoader';
import SubstanceInformationForm from './SubstanceInformationForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
  }),
};

const SubstanceInformationFormModal = props => {
  const renderTitle = () =>
    (props.editMode
      ? `${I18n.t('substanceInformationModal.EditTitle', { substanceName: props.substanceName })}`
      : `${I18n.t('substanceInformationModal.AddTitle', { substanceName: props.substanceName })}`);

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{renderTitle()}</Modal.Title>
      </Modal.Header>
      <div className={styles.modalBody}>
        <Modal.Body>
          <BaseLoader isLoading={props.isLoading}>
            <SubstanceInformationForm {...props} />
          </BaseLoader>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={props.closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={props.onSaveButtonClick}
              disabled={props.isFormInValid || props.isFormPristine}
            >
              <Translate value="general.save" />&nbsp;
              <i className="fa fa-arrow-circle-right" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

SubstanceInformationFormModal.defaultProps = {
  substanceName: '',
};

SubstanceInformationFormModal.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFormInValid: PropTypes.bool.isRequired,
  isFormPristine: PropTypes.bool.isRequired,
  substanceName: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
};

export default SubstanceInformationFormModal;
