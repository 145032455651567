import queryString from 'query-string';
import { get, post, del, makeConstructUrlFromQuery } from './apiUtils';
import * as R from 'ramda';

const defaultUrl = '/ToxProfileItems/';
const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getToxProfile: R.compose(
    get,
    getUrl,
  ),
  getToxProfileItem: id => get(`${defaultUrl}${id}`),
  saveToxProfileItem: data => post(`${defaultUrl}`, data),
  deleteToxProfileItem: ids => del(defaultUrl, ids),
};
