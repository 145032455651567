import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { referentialSelectors } from '../../../redux/referentials';
import { SelectField } from '../Forms';

const CpsrPhraseStylesDropdown = props => {
  const { cpsrPhraseStyleList } = props;
  return <SelectField options={cpsrPhraseStyleList} labelField="Value" valueField="Key" {...props} />;
};

const mapStateToProps = () => state => {
  const getCpsrPhraseStyleList = referentialSelectors.makeGetCpsrPhraseStyleList();
  return {
    cpsrPhraseStyleList: getCpsrPhraseStyleList(state),
  };
};

CpsrPhraseStylesDropdown.defaultProps = {
  cpsrPhraseStyleList: [],
};

CpsrPhraseStylesDropdown.propTypes = {
  cpsrPhraseStyleList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.number.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ),
};

export default connect(
  mapStateToProps,
  null,
)(CpsrPhraseStylesDropdown);
