import { schema } from 'normalizr';
import { cosAnnexeSchemas } from '../cosAnnexe';

export const substanceCosAnnexeSchema = new schema.Entity(
  'substanceCosAnnexes',
  {
    CosAnnexe: cosAnnexeSchemas.cosAnnexeSchema,
  },
  {
    idAttribute: 'SubstanceCosAnnexeId',
  },
);

export const substanceCosAnnexeListSchema = [substanceCosAnnexeSchema];
