import queryString from 'query-string';
import { get, post, del } from './apiUtils';

const defaultUrl = '/ProductPhrases/';

export default {
  getProductPhraseList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveProductPhrase: data => post(defaultUrl, data),
  copyProductPhrasesFromProduct: ({ cpsrTypeCode, ...data }) =>
    post(`${defaultUrl}Copy?CpsrType=${cpsrTypeCode}`, data),
  deleteProductPhrase: ids => del(defaultUrl, ids),
};
