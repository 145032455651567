import { schema } from 'normalizr';

export const functionSchema = new schema.Entity('functions', {}, { idAttribute: 'FunctionId' });
export const companySchema = new schema.Entity('companies', {}, { idAttribute: 'CompanyId' });
export const rawMatSchema = new schema.Entity('rawMats', { Company: companySchema }, { idAttribute: 'RawMatId' });
export const substanceSchema = new schema.Entity('substances', {}, { idAttribute: 'SubstanceId' });
const workflowStatusSchema = new schema.Entity('workflowStatus', {}, { idAttribute: 'Code' });
const actionSchema = new schema.Entity('actions', {}, { idAttribute: 'Code' });
const productActionSchema = new schema.Entity('productActions', {}, { idAttribute: 'ProductActionId' });

export const substanceFunctionSchema = new schema.Entity(
  'substanceFunctions',
  { Function: functionSchema },
  { idAttribute: 'SubstanceFunctionId' },
);

export const substanceProportionSchema = new schema.Entity(
  'substanceProportions',
  {},
  { idAttribute: 'SubstanceProportionId' },
);

const substanceProportionWithSubstanceSchema = new schema.Entity(
  'substanceProportions',
  {
    ReferencedSubstance: substanceSchema,
  },
  { idAttribute: 'SubstanceProportionId' },
);

const substanceWithProportionSchema = new schema.Entity(
  'substances',
  {
    SubstanceProportions: [substanceProportionWithSubstanceSchema],
  },
  { idAttribute: 'SubstanceId' },
);

export const rawMatProportionSchema = new schema.Entity(
  'rawMatProportions',
  {
    Substance: substanceWithProportionSchema,
    Function: functionSchema,
  },
  { idAttribute: 'RawMatProportionId' },
);

export const functionListSchema = [functionSchema];
export const substanceListSchema = [substanceSchema];
export const substanceFunctionListSchema = [substanceFunctionSchema];
export const rawMatProportionListSchema = [rawMatProportionSchema];
export const substanceProportionListSchema = [substanceProportionSchema];
export const substanceProportionWithSubstanceListSchema = [substanceProportionWithSubstanceSchema];
export const rawMatListSchema = [rawMatSchema];
export const workflowStatusListSchema = [workflowStatusSchema];
export const actionListSchema = [actionSchema];
export const productActionListSchema = [productActionSchema];

const mySchema = {
  functionListSchema,
  substanceListSchema,
  rawMatProportionListSchema,
  rawMatListSchema,
  rawMatSchema,
  substanceProportionWithSubstanceListSchema,
  workflowStatusListSchema,
  actionListSchema,
  productActionListSchema,
};

export default mySchema;
