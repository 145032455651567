import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';

import InputField from './InputField';
import { ColumnFlexBox, FitToContentBox } from '../FlexBox';
import { isEmail, maxLength16, maxLength64, maxLength128 } from '../../../utils/fieldValidation';
import SelectField from './SelectField';

const ContactForm = ({ languageList }) => (
  <ColumnFlexBox>
    <FitToContentBox>
      <Row>
        <Col sm={12}>
          <Grid fluid>
            <Row>
              <Row>
                <Col sm={6}>
                  <Field name="Company" label={I18n.t('contact.company')} component={InputField} />
                </Col>
                <Col sm={6}>
                  <Field name="Website" label={I18n.t('contact.website')} component={InputField} />
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <Field name="Title" label={I18n.t('contact.title')} component={InputField} validate={maxLength16} />
                </Col>
                <Col sm={3}>
                  <Field
                    name="FirstName"
                    label={I18n.t('contact.firstname')}
                    component={InputField}
                    validate={maxLength64}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="LastName"
                    label={I18n.t('contact.lastname')}
                    component={InputField}
                    validate={maxLength64}
                  />
                </Col>
                <Col sm={3}>
                  <Field
                    name="Position"
                    label={I18n.t('contact.position')}
                    component={InputField}
                    validate={maxLength64}
                  />
                </Col>
                <Col sm={2}>
                  <Field
                    name="LanguageAlpha3"
                    label={I18n.t('contact.language')}
                    component={SelectField}
                    options={languageList}
                    labelField="Value"
                    valueField="Key"
                    matchPos="start"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Field
                    name="Email"
                    label={I18n.t('contact.email')}
                    component={InputField}
                    validate={[isEmail, maxLength128]}
                  />
                </Col>
                <Col sm={3}>
                  <Field name="Phone" label={I18n.t('contact.phone')} component={InputField} validate={maxLength64} />
                </Col>
                <Col sm={3}>
                  <Field name="Mobile" label={I18n.t('contact.mobile')} component={InputField} validate={maxLength64} />
                </Col>
                <Col sm={3}>
                  <Field name="Fax" label={I18n.t('contact.fax')} component={InputField} />
                </Col>
              </Row>
            </Row>
          </Grid>
        </Col>
      </Row>
    </FitToContentBox>
  </ColumnFlexBox>
);

// const validate = (values) => {
//   const errors = {};
//   if (!values.Code) {
//     errors.Code = I18n.t('general.required');
//   }
//   return errors;
// };

export default ContactForm;

// export default reduxForm({
//   form: 'contactForm',
//   destroyOnUnmount: true,
//   enableReinitialize: true,
//   validate,
// })(ContactForm);
