import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, LinkCellRenderer, DateCellRenderer } from '../../_Common/Grids';

const ProductOrderList = props => {
  const { currentProductOrders, isLoading, getProductOrders, onRowDoubleClicked } = props;

  const columns = [
    {
      headerName: I18n.t('order.orderNumber'),
      sort: 'desc',
      field: 'OrderNr',
      cellRendererFramework: LinkCellRenderer,
      width: 75,
      cellRendererParams: {
        textKey: 'OrderNr',
        linkKey: 'Url',
        downloadable: false
      },
      suppressSizeToFit: true,
    }
    // {
    //   headerName: I18n.t('order.orderNumber'),
    //   field: 'OrderNr',
    //   width: 100,
    //   sort: 'desc',
    //   suppressSizeToFit: true,
    //   cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    // }
    ,
    {
      headerName: I18n.t('order.orderType'),
      field: 'OrderType',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.description'),
      field: 'Description',
      width: 200,
    },
    {
      headerName: I18n.t('order.regulatoryRegion'),
      width: 100,
      field: 'RegulatoryRegionCode',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.Status'),
      width: 100,
      field: 'StatusName',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.startDate'),
      width: 100,
      field: 'StartDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.endDate'),
      width: 100,
      field: 'EndDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      suppressSizeToFit: true,
    },
  ];

  currentProductOrders.forEach(element => {
    element.Url = '#orders/' + element.OrderId;
  });

  
  return (
    <SelectionGrid
      data={currentProductOrders}
      keyName="OrderId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getProductOrders}
      rowDoubleClicked={onRowDoubleClicked}
      showAddButton={false}
    />
  );
};

ProductOrderList.propTypes = {
  currentProductOrders: PropTypes.array, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProductOrders: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default ProductOrderList;
