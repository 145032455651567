import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { combineActions, handleActions } from 'redux-actions';
import * as errorTypes from './errorTypes';

const byId = handleActions(
  {
    [combineActions(errorTypes.FETCH_ERROR_LIST.SUCCESS)]: (state, { payload }) =>
      merge({}, state, payload.entities.errors),
  },
  {},
);

const allIds = handleActions(
  {
    [errorTypes.FETCH_ERROR_LIST.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
