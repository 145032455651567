import React from 'react';
import PropTypes from 'prop-types';
import { Field, Fields } from 'redux-form';
import { Col, Row, Grid, Button, FormGroup, ControlLabel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import {InputField, InputFieldWithDropdown, SelectField} from '../../_Common/Forms';
import { isNumberField, maxLength2048, maxLength32,maxLength9 } from '../../../utils/fieldValidation';
import BaseLoader from '../../_Common/BaseLoader';

const MosAndToxCertForm = props => {
  const { isLoading, uomList, onSubstanceToxProfileButttonClick, cramerClasseList, onCramerClasseSelected } = props;

  return (
    <BaseLoader isLoading={isLoading}>
      <Grid fluid>
        <section>
          <Row>
            <Col sm={2}>
              <Field
                name="NoaelCERT"
                label={`${I18n.t('substance.noaelCert')}`}
                component={InputField}
                inputGroupLabel={'mg/kg bw/day'}
                validate={isNumberField}
              />
            </Col>
            <Col sm={10}>
              <Field
                name="NoaelCERTSource"
                label={`${I18n.t('substance.noaelCertSource')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
        </section>

        <hr />
        <Row>
          {/* <Col sm={3}>
            <Col sm={7}>
              <FormGroup>
                <ControlLabel>&nbsp;</ControlLabel>
                <Button bsSize="small" block onClick={onSubstanceToxProfileButttonClick}>
                  Substance Toxicological Profile
                </Button>
              </FormGroup>
            </Col>
          </Col> */}
          <Col sm={2}>
            <Field
              name="SolubilityCERT"
              label={`${I18n.t('substance.SolubilityCert')}`}
              component={InputField}
              inputGroupLabel={'%'}
              validate={isNumberField}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="VapCERT"
              label={`${I18n.t('substance.VapCert')}`}
              component={InputField}
              inputGroupLabel={'Pa'}
              validate={isNumberField}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="DAaCERT"
              label={`${I18n.t('substance.DAaCert')}`}
              component={InputField}
              inputGroupLabel={'%'}
              validate={isNumberField}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="DapCERT"
              label={`${I18n.t('substance.DapCert')}`}
              component={InputField}
              validate={maxLength9}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="TheoreticalDapCERT"
              label={`${I18n.t('substance.TheoreticalDapCert')}`}
              component={InputField}
              validate={maxLength9}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={5}>
            <Field
              name="SummaryCERT"
              label={`${I18n.t('substance.SummaryCert')}`}
              componentClass="textarea"
              component={InputField}
              validate={maxLength2048}
            />
          </Col>
          <Col sm={5}>
            <Field
              name="MiscToxCERT"
              label={`${I18n.t('substance.MiscToxCert')}`}
              componentClass="textarea"
              component={InputField}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <Field
              name="MemoCERT"
              label={`${I18n.t('substance.MemoCert')}`}
              componentClass="textarea"
              component={InputField}
              validate={maxLength2048}
            />
          </Col>
          <Col sm={5}>
            <Field
              name="ProfileCERT"
              label={`${I18n.t('substance.ProfileCert')}`}
              componentClass="textarea"
              component={InputField}
            />
          </Col>
        </Row>
      </Grid>
    </BaseLoader>
  );
};

MosAndToxCertForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubstanceToxProfileButttonClick: PropTypes.func.isRequired,
  uomList: PropTypes.array.isRequired, //eslint-disable-line
  cramerClasseList: PropTypes.array.isRequired, //eslint-disable-line
};

export default MosAndToxCertForm;
