import { createRequestActions } from '../utils';
import * as sessionTypes from './sessionTypes';
// import workflowTypes from '../../constants/workflowTypes';

// export const listTasks = createRequestActions(taskTypes.TASK_LIST, payload => payload, (data, meta) => meta);
// export const saveTask = createRequestActions(taskTypes.SAVE_TASK, data => data);
// export const deleteTask = createRequestActions(taskTypes.DELETE_TASK, data => data);
// export const updateStatus = ({ WorkflowStatusCode, TaskId, Comment = null, searchCriteria }) => ({
//   type: taskTypes.UPDATE_TASK_STATUS.REQUEST,
//   payload: {
//     WorkflowStatusCode,
//     Comment,
//     TaskId,
//     searchCriteria,
//   },
// });
export const startSession = createRequestActions(sessionTypes.START_SESSION, data => data);
export const stopSession = createRequestActions(sessionTypes.STOP_SESSION, data => data);
