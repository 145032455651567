import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { DateCellRenderer, PercentageCellRenderer, MeasureCellRenderer, LinkCellRenderer, LimitedMeasureCellRenderer } from '../../_Common/Grids';

const actionButtonsRenderer = props => {
  const { data, onConfirm, onIgnore, node } = props;
  
  return (
    <div style={{ textAlign: 'center' }}>
      {node.level === 0 && (
        <ButtonToolbar>
          <Button bsSize="xsmall" type="button" onClick={() => onConfirm(data)}>
            <i className="fa fa-print" aria-hidden="true" />
            &nbsp;
            <Translate value="impactAssessmentProduct.confirm" />
          </Button>
          <Button bsSize="xsmall" type="button" onClick={() => onIgnore(data)}>
            <i className="fa fa-print" aria-hidden="true" />
            &nbsp;
            <Translate value="impactAssessmentProduct.ignore" />
          </Button>
        </ButtonToolbar>
      )}{' '}
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onConfirm: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
};

const ImpactAssessmentValueList = props => {
  const {
    currentImpactAssessmentProducts,
    requestingImpactAssessmentValueList,
    requestingImpactAssessmentList,
    onConfirmImpactAssessmentProductClick,
    onIgnoreImpactAssessmentProductClick,
    onRefresh,
    onRowDoubleClicked,
    requestingFindCandidate,
  } = props;

  const columns = [
    {
      headerName: 'Id',
      field: 'ImpactAssessmentProductId',
      width: 75,
      suppressSizeToFit: true,
      sort: 'desc',
      cellRenderer: 'group',
    },
    {
      headerName: I18n.t('product.producerName'),
      width: 125,
      field: 'Shortname',
    },
    {
      headerName: I18n.t('product.code'),
      sort: 'desc',
      field: 'Code',
      cellRendererFramework: LinkCellRenderer,
      width: 75,
      cellRendererParams: {
        textKey: 'Code',
        linkKey: 'Url',
        downloadable: false
      },
      suppressSizeToFit: true,
    }
    // {
    //   headerName: I18n.t('product.code'),
    //   width: 95,
    //   field: 'Code',
    //   suppressSizeToFit: true,
    //   cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    // }
    ,
    {
      headerName: I18n.t('general.name'),
      width: 120,
      field: 'ProductName',
    },
    // {
    //   headerName: I18n.t('product.isNotified'),
    //   width: 30,
    //   field: 'IsNotified',
    // },
    // {
    //   headerName: I18n.t('product.notificationDate'),
    //   width: 60,
    //   field: 'NotifiedOn',
    // },
    // {
    //   headerName: I18n.t('product.notificationCode'),
    //   width: 30,
    //   field: 'NotificationCode',
    // },
    // {
    //   headerName: I18n.t('product.category'),
    //   width: 30,
    //   field: 'CategoryDescription',
    // },
    // {
    //   headerName: I18n.t('product.useType'),
    //   width: 30,
    //   field: 'ProductUseTypeCode',
    // },
    {
      headerName: I18n.t('impactAssessmentProduct.notificationDateEU'),
      width: 60,
      field: 'NotifiedEU',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('impactAssessmentProduct.notificationCodeEU'),
      width: 60,
      field: 'NotificationCodeEU',
    },
    {
      headerName: I18n.t('impactAssessmentProduct.notificationDateUK'),
      width: 60,
      field: 'NotifiedUK',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('impactAssessmentProduct.notificationCodeUK'),
      width: 60,
      field: 'NotificationCodeUK',
    },
    {
      headerName: 'Subs.Type',
      field: 'InciType',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Inci',
      field: 'InciName',
      width: 75,
      // suppressSizeToFit: true,
    },
    {
      headerName: 'Use Type',
      field: 'ProductUseTypeCode',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Category',
      field: 'CategoryDescription',
      width: 75,
      suppressSizeToFit: true,
    },
    // {
    //   headerName: 'Test',
    //   field: 'Comparator',
    //   width: 75,
    //   suppressSizeToFit: true,
    // },
    {
      headerName: 'Quantity',
      field: 'QuantityValue',
      width: 75,
      suppressSizeToFit: true,
      cellRenderer: PercentageCellRenderer,
    },
    {
      headerName: 'MoS',
      field: 'MoSValue',
      width: 75,
      suppressSizeToFit: true,
      cellRenderer: LimitedMeasureCellRenderer,
      cellRendererParams: {
        nbDecimal: 0,
        max: 1000000
      },
    },
    // {
    //   headerName: 'Out',
    //   cellRenderer: ({ data, node }) => {
    //     if (data.BeyondThreshold) {
    //       return '<i class="fa fa-exclamation-triangle" aria-hidden="true" style="color:#FF0000;"></i>';
    //     }
    //     return '<i class="fa fa-check-circle" aria-hidden="true" style="color:#23db23;"></i>';
    //   },
    //   width: 75,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('impactAssessmentProduct.status'),
    //   width: 65,
    //   field: 'Status',
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('impactAssessmentProduct.actions'),
    //   cellRendererFramework: actionButtonsRenderer,
    //   cellRendererParams: {
    //     onConfirm: onConfirmImpactAssessmentProductClick,
    //     onIgnore: onIgnoreImpactAssessmentProductClick,
    //   },
    //   width: 200,
    //   minWidth: 200,
    //   suppressSizeToFit: true,
    // },
  ];
  

currentImpactAssessmentProducts.forEach(element => {
  element.Url = '#products/' + element.ProductId;
});

  return (
    <SelectionGrid
      data={currentImpactAssessmentProducts.filter(val => { return val.BeyondThreshold })}
      keyName="ImpactAssessmentValueId"
      columns={columns}
      isLoading={
        requestingImpactAssessmentValueList || requestingImpactAssessmentList || requestingFindCandidate
      }
      onRefresh={onRefresh}
      deltaRowDataMode={false}
      rowDoubleClicked={onRowDoubleClicked}
      showAddButton={false}
      showExpandCollapseButtons
    />
  );
};

ImpactAssessmentValueList.propTypes = {
  currentImpactAssessmentProducts: PropTypes.arrayOf(
    PropTypes.shape({
      impactAssessmentProductId: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  requestingImpactAssessmentValueList: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
  onConfirmImpactAssessmentProductClick: PropTypes.func.isRequired,
  onIgnoreImpactAssessmentProductClick: PropTypes.func.isRequired,
};



export default ImpactAssessmentValueList;
