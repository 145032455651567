import { createSelector } from 'reselect';
import { homepageSelectors } from '../../../homepage';

const getStateSlice = state => state.ui.homePage.homePageForm;

export const getIsLoadingHomePageForm = state => getStateSlice(state).isLoadingHomePageForm;
export const getIsSavingHomePageForm = state => getStateSlice(state).isSavingHomePageForm;
const getHomePageId = state => getStateSlice(state).homePageId;

export const getInitialValues = createSelector(
  [homepageSelectors.getById, getHomePageId],
  (homePageById, homePageId) => homePageById[homePageId],
);
