import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField } from '../../../_Common/Forms';
import { ProcessTypeListDropdown } from '../../../_Common/Dropdowns';
import { orderItemFormSelectors, orderItemFormActions } from '../../../../redux/form/orderItemForm';
import { orderItemActions } from '../../../../redux/orderItem';
import { orderItemProductActions } from '../../../../redux/orderItemProduct';
import { processTypeSelectors } from '../../../../redux/processType';
import {
  isPositiveOrZeroInteger,
  isPositiveInteger,
  requiredField,
  isPositiveOrZeroDecimal
} from '../../../../utils/fieldValidation';
import { referentialSelectors } from '../../../../redux/referentials';
import { GeneralGrid, CheckBoxCellRenderer } from '../../Grids';
import { ProductListDropdown } from '../../../_Common/Dropdowns';
import { OrderItemListDropdown } from '../../../_Common/Dropdowns';

const formName = formNames.orderItemProduct;

const OrderItemProductForm = props => {
  const {
    onSubmit,
    handleSubmit,
    // processTypeList,
    // onProcessTypeSelected,
    // onValueChange,
    // isNew,
    orderItems,
    products
  } = props;

  console.log('initialValues', props.initialValues);

  const orderItemColumns = [
    {
      // headerName: I18n.t('orderProcess.type'),
      // field: 'ProcessTypeValue',
      width: 45,
      cellRenderer: function(params) { 
        var input = document.createElement('input');
        input.type="checkbox";
        input.checked=false;
        input.addEventListener('click', function (event) {
            params.value=!params.value;
            params.node.data.fieldName = params.value;
        });
        return input;
      },
    },
    {
      headerName: I18n.t('orderProcess.type'),
      field: 'ProcessTypeValue',
      width: 75,
    },
    {
      headerName: I18n.t('taskType.region'),
      field: 'Region',
      width: 80,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('orderProcess.quantity'),
      field: 'Quantity',
      width: 45,
      suppressSizeToFit: true,
      suppressFilter: true,
    },
  ];

  const productColumns = [
    {
      // headerName: I18n.t('orderProcess.type'),
      // field: 'ProcessTypeValue',
      width: 45,
      cellRenderer: function(params) { 
        var input = document.createElement('input');
        input.type="checkbox";
        input.checked=false;
        input.addEventListener('click', function (event) {
          console.log('event');
          console.log(params.data.ProductId);
          // selectedProducts.push(params.data.ProductId);
            // params.value=!params.value;
            // params.node.data.fieldName = params.value;
            // this.setState({ isDirty: true });
            // self.setState({ isDirty: true });
          });
        return input;
      },
    },    {
      headerName: I18n.t('product.code'),
      field: 'Code',
      width: 75,
      suppressSizeToFit: true,
      sort: 'desc',
      cellStyle: {color: 'blue', textDecoration: 'underline dotted'},
    },
    {
      headerName: I18n.t('product.productName'),
      field: 'ProductName',
      width: 200,
      minWidth: 200,
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.NumOfShades'),
      width: 60,
      field: 'ShadeCount',
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.cosmoWorkflow'),
    //   width: 60,
    //   field: 'IsNotified',
    //   suppressSizeToFit: true,
    // },    {
    //   headerName: I18n.t('product.percentageTask'),
    //   width: 60,
    //   field: 'IsNotified',
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.IsNotified'),
    //   width: 60,
    //   field: 'IsNotified',
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.IsNoLongerOnMarket'),
    //   width: 100,
    //   field: 'IsNoLongerOnMarket',
    //   valueGetter: getNoLongerInMarket,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.dl'),
    //   width: 60,
    //   field: 'dl',
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.CpnpCat3'),
    //   width: 100,
    //   field: 'NotificationCode',
    //   suppressSizeToFit: true,
    // }
  ];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Field
              name="ProductId"
              label={I18n.t('orderProcess.existingProducts')}
              component={ProductListDropdown}
              initialOptions={products} // {productList}
              // onChange={data => cleanFormValue('ProductName')}
              disabled={false}
              useVirtualized
              multi={true}
              validate={requiredField}
            />
        </Row>
        <Row>
          <Field
              name="OrderItemId"
              label={I18n.t('orderProcess.existingProcessTypes')}
              component={OrderItemListDropdown}
              initialOptions={orderItems}
              disabled={false}
              useVirtualized
              multi={true}
              validate={requiredField}
            />
        </Row>
        {/* <Row>
          <Col sm={12}>
            <GeneralGrid
                data={products}
                columns={productColumns}
                keyName="Id"
                // isLoading={isLoading}
                deltaRowDataMode={false}
                height="25vh"
            />
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col sm={12}>
            <GeneralGrid
                data={orderItems}
                columns={orderItemColumns}
                // getNodeChildDetails={getNodeChildDetails}
                keyName="Id"
                // isLoading={isLoading}
                deltaRowDataMode={false}
                height="25vh"
            />
          </Col>
        </Row> */}
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { orderItemId, orderId }) => {
  const getFormInitialValues = orderItemFormSelectors.makeGetInitialValues(orderItemId, orderId);
  const { currentOrderProcessInfo } = state.order;
  return {
    initialValues: getFormInitialValues(state),
    processTypeList: processTypeSelectors.getProcessTypeList(state),
    isNew: !(orderItemId != null),
    currentOrderProcessInfo,
    currencies: referentialSelectors.getCurrencies(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onSubmit: data => {
    dispatch(orderItemProductActions.submitOrdersItemsProducts.request({ ...data, orderId }));
  },
  onProcessTypeSelected: processTypeId => dispatch(orderItemFormActions.onProcessTypeSelected(processTypeId)),
  onValueChange: dataChanged => dispatch(orderItemFormActions.onChangeCheckValues(dataChanged)),
});

OrderItemProductForm.defaultProps = {
  initialValues: {},
};

OrderItemProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  processTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      ProcessTypeId: PropTypes.number.isRequired,
      ProcessTypeCode: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(OrderItemProductForm);
