import {put, all, select, takeLatest, call} from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as productCategoryTRFormActions from './productCategoryTRFormActions';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import {referentialSelectors} from "../../referentials";
import {productCategoryTRSagas} from "../../productCategoryTR";

export default function* root() {
  yield all([
    takeLatest(productCategoryTRFormActions.submitProductCategoryTRForm.REQUEST, submitProductTRFormSaga),
  ]);
}

const submitProductTRFormSaga = createSaveFormWithValidation(productCategoryTRFormActions.submitProductCategoryTRForm, saveProductCategoryTRSaga);

export function* saveProductCategoryTRSaga({payload}) {
  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const finalValue = [];
  languages.forEach(lang => {
    finalValue.push(payload[lang.Alpha2]);
  })

  yield call(productCategoryTRSagas.saveProductCategoryTRSaga, {payload: finalValue});
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PRODUCT_CATEGORY_TRANSLATION_FORM) {
    yield put(modalActions.hideModal());
  }
}

