import React from 'react';
import PropTypes from 'prop-types';

import { css } from 'glamor';
import { Grid, Col, Row } from 'react-bootstrap';
import LabelReviewItem from './_Common/LabelReviewItem';
import LabelReviewItemHeader from './_Common/LabelReviewItemHeader';

const styles = {
  rows: css({
    marginBottom: '15px',
  }),
};

const LabelReviewItems = props => {
  const { labelReviewItemList, updateLabelReviewItem, editLabelReviewItemComment, resetLabelReviewItemComment } = props;

  return (
    <Grid fluid>
      <Col sm={12}>
        <Row>
          <LabelReviewItemHeader />
        </Row>
        {labelReviewItemList.map(lrItem => {
          const { LabelReviewItemId, ReferentialData, IsInnerLabelOk, IsOuterLabelOk, IsLeafletOk, Comment } = lrItem;
          return (
            <div key={LabelReviewItemId} className={styles.rows}>
              <Row>
                <LabelReviewItem
                  LabelReviewItemId={LabelReviewItemId}
                  Label={ReferentialData.Label}
                  IsInnerLabelOk={IsInnerLabelOk}
                  IsOuterLabelOk={IsOuterLabelOk}
                  IsLeafletOk={IsLeafletOk}
                  Comment={Comment}
                  editLabelReviewItemComment={editLabelReviewItemComment}
                  resetLabelReviewItemComment={resetLabelReviewItemComment}
                  updateLabelReviewItem={updateLabelReviewItem}
                />
              </Row>
            </div>
          );
        })}
      </Col>
    </Grid>
  );
};

LabelReviewItems.defaultProps = {
  labelReviewItemList: [],
};

LabelReviewItems.propTypes = {
  labelReviewItemList: PropTypes.array, //eslint-disable-line
  updateLabelReviewItem: PropTypes.func.isRequired,
  editLabelReviewItemComment: PropTypes.func.isRequired,
  resetLabelReviewItemComment: PropTypes.func.isRequired,
};

export default LabelReviewItems;
