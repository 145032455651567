import { createRequestActions } from '../utils';
import * as substanceFunctionTypes from './substanceFunctionTypes';

export const listSubstanceFunctions = createRequestActions(
  substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST,
  ({ substanceFunctionId, ...query } = {}) => ({
    ...(substanceFunctionId && { id: substanceFunctionId }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);

export const saveSubstanceFunction = createRequestActions(substanceFunctionTypes.SAVE_SUBSTANCE_FUNCTION);
export const deleteSubstanceFunction = createRequestActions(
  substanceFunctionTypes.DELETE_SUBSTANCE_FUNCTION,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
