import * as productCategoryTRFormSelectors from './productCategoryTRFormSelectors';
import * as productCategoryTRFormActions from './productCategoryTRFormActions';
import * as productCategoryTRFormTypes from './productCategoryTRFormTypes';
import sagas from './productCategoryTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { productCategoryTRFormSelectors, productCategoryTRFormActions, productCategoryTRFormTypes };
