import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import RawMatInformationModal from './RawMatInformationModal';
import { applicationSelectors } from '../../../../redux/application';
import { rawMatPhraseTypes } from '../../../../redux/rawMatPhrase';
import formNames from '../../../../constants/formNames';

const formName = formNames.rawMatInfo;
const isAnyTrue = R.any(R.equals(true));

const RawMatInformationModalContainer = props => <RawMatInformationModal {...props} />;

const mapStateToProps = state => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isLoading: applicationSelectors.isLoading(state, rawMatPhraseTypes.SAVE_RAWMAT_PHRASE.REQUEST),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RawMatInformationModalContainer);
