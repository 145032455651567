import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {i18nReducer} from 'react-redux-i18n';
import {reducer as formReducer} from 'redux-form';

import error from './error';
import user from './user';
import application from './application';
import company from './company';
import order from './order';
import distributor from './distributor';
import product from './product';
import productTR from './productTR';
import rawMat from './rawMat';
import ingredient from './ingredient';
import substance from './substance';
import substanceTR from './substanceTR';
import modal from './modal';
import phrase from './phrase';
import phraseTR from './phraseTR';
import homepage from './homepage';
import referentials from './referentials';
import category from './category';
import categoryTR from './categoryTR';
import processType from './processType';
import rawMatSupplier from './rawMatSupplier';
import func from './function';
import substanceFunction from './substanceFunction';
import rawMatProportion from './rawMatProportion';
import substanceProportion from './substanceProportion';
import workflowStatus from './workflowStatus';
import action from './action';
import productAction from './productAction';
import productCategory from './productCategory';
import productCategoryTR from './productCategoryTR';
import formulaReview from './formulaReview';
import labelReview from './labelReview';
import notification from './notification';
import complianceReview from './complianceReview';
import rawMatDataReview from './rawMatDataReview';
import productDataReview from './productDataReview';
import formulaReviewWizard from './wizard/formulaReviewWizard';
import labelReviewWizard from './wizard/labelReviewWizard';
import productProportion from './productProportion';
import rawMatDocument from './rawMatDocument';
import document from './document';
import companyDocument from './companyDocument';
import productDocument from './productDocument';
import dashboard from './dashboard';
import inciList from './inciList';
import productPhrase from './productPhrase';
import productPhraseTR from './productPhraseTR';
import labelReviewItem from './labelReviewItem';
import labelReviewInci from './labelReviewInci';
import labelReviewClaim from './labelReviewClaim';
import labelReviewSymbol from './labelReviewSymbol';
import labelSymbol from './labelSymbol';
import labelSymbolCorrectiveAction from './labelSymbolCorrectiveAction';
import userRole from './userRole';
import role from './role';
import orderProduct from './orderProduct';
import orderImport from './orderImport';
import orderItem from './orderItem';
import substanceRawMat from './substanceRawMat';
import substanceProduct from './substanceProduct';
import substanceDocument from './substanceDocument';
import cosAnnexe from './cosAnnexe';
import cosAnnexeTR from './cosAnnexeTR';
import substanceInformation from './substanceInformation';
import rawMatPhrase from './rawMatPhrase';
import substanceAction from './substanceAction';
import rawMatAction from './rawMatAction';
import substanceToxProfile from './substanceToxProfile';
import substanceClp from './substanceClp';
import substanceCosAnnexe from './substanceCosAnnexe';
import shade from './shade';
import shadeProportion from './shadeProportion';
import orderAction from './orderAction';
import draftLabel from './draftLabel';
import companyDistributorAgreement from './companyDistributorAgreement';
import mandate from './mandate';
import mandateProduct from './mandateProduct';
import uiReducer from './ui';
import productClp from './productClp';
import generalDashboard from './generalDashboard';
import safecosmeticDashboard from './safecosmeticDashboard';
import phraseCpsrChapter from './phraseCpsrChapter';
import shadeInciList from './shadeInciList';
import impactAssessment from './impactAssessment';
import impactAssessmentAction from './impactAssessmentAction';
import impactAssessmentProduct from './impactAssessmentProduct';
import impactAssessmentThreshold from './impactAssessmentThreshold';
import task from './task';
import taskAction from './taskAction';
import taskType from './taskType';
import toxProfileChapter from './toxProfileChapter';
import toxProfileItem from './toxProfileItem';
import process from './process';
import workItem from './workItem';
import cpsrChapters from './cpsrChapters';
import formError from './form/errorsForm';

const rootReducer = combineReducers({
  // labelReviewWizard,
  action,
  application,
  category,
  categoryTR,
  company,
  companyDistributorAgreement,
  companyDocument,
  complianceReview,
  cosAnnexe,
  cosAnnexeTR,
  cpsrChapters,
  dashboard,
  distributor,
  document,
  draftLabel,
  error,
  form: formReducer,
  formError: formError,
  formulaReview,
  formulaReviewWizard,
  func,
  generalDashboard,
  homepage,
  i18n: i18nReducer,
  impactAssessment,
  impactAssessmentAction,
  impactAssessmentProduct,
  impactAssessmentThreshold,
  inciList,
  ingredient,
  labelReview,
  labelReviewClaim,
  labelReviewItem,
  labelReviewInci,
  labelReviewSymbol,
  labelReviewWizard,
  notification,
  labelSymbol,
  labelSymbolCorrectiveAction,
  mandate,
  mandateProduct,
  modal,
  order,
  orderAction,
  orderImport,
  orderItem,
  orderProduct,
  process,
  processType,
  phrase,
  phraseTR,
  phraseCpsrChapter,
  product,
  productTR,
  productAction,
  productCategory,
  productCategoryTR,
  productClp,
  productDataReview,
  productDocument,
  productPhrase,
  productPhraseTR,
  // productPhraseForm,
  productProportion,
  rawMat,
  rawMatAction,
  rawMatDataReview,
  rawMatDocument,
  rawMatPhrase,
  rawMatProportion,
  rawMatSupplier,
  referentials,
  role,
  routing,
  safecosmeticDashboard,
  shade,
  shadeInciList,
  shadeProportion,
  substance,
  substanceTR,
  substanceAction,
  substanceClp,
  substanceCosAnnexe,
  substanceDocument,
  substanceFunction,
  substanceInformation,
  substanceProduct,
  substanceProportion,
  substanceRawMat,
  substanceToxProfile,
  task,
  taskAction,
  taskType,
  toxProfileChapter,
  toxProfileItem,
  ui: uiReducer,
  user,
  userRole,
  workflowStatus,
  workItem,
});

export default rootReducer;
