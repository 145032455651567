import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { allergenModalActions, allergenModalTypes } from '../../../../redux/modal/allergenModal';
import AllergenListModal from './AllergenListModal';

const AllergensListModalContainer = props => <AllergenListModal {...props} />;

const mapStateToProps = (state, { rawMatId }) => {
  const rawMat = rawMatSelectors.getById(state)[rawMatId];
  const rawMatName = R.propOr('', 'Name')(rawMat);
  return {
    title: I18n.t('allergenListModal.title', { rawMatName }),
    isSaveDisabled:
      substanceSelectors.getIsLoadingAllergenSubstances(state) ||
      applicationSelectors.isLoading(state, allergenModalTypes.SAVE_RAWMAT_PROPORTION_ALLERGENS.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onSaveButtonClick: data => {
    dispatch(allergenModalActions.onSaveButtonClick(rawMatId, data));
  },
});

AllergensListModalContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllergensListModalContainer);
