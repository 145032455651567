import { createRequestTypes } from '../utils';

export const RAWMAT_LIST = createRequestTypes('rawMat/RAWMAT_LIST');
export const RAWMAT = createRequestTypes('rawMat/RAWMAT');
export const SAVE_RAWMAT = createRequestTypes('rawMat/SAVE_RAWMAT');
export const RAWMAT_PROPORTIONS = createRequestTypes('rawMat/RAWMAT_PROPORTIONS');
export const LINK_COSMOS_INGREDIENT_RAWMATS = createRequestTypes('rawMat/LINK_COSMOS_INGREDIENT_RAWMATS');
export const ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION = createRequestTypes(
  'rawMat/ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION',
);
export const SAVE_RAWMAT_PROPORTION = createRequestTypes('rawMat/SAVE_RAWMAT_PROPORTION');
export const NEW_RAWMAT = 'rawMat/NEW_RAWMAT';
export const ADD_EDIT_RAWMAT_PROPORTION = 'rawMat/ADD_EDIT_RAWMAT_PROPORTION';
export const CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT = createRequestTypes(
  'rawMat/CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT',
);
export const UPDATE_RAWMAT_STATUS = createRequestTypes('rawMat/UPDATE_RAWMAT_STATUS');
export const UPDATE_INCI_QTY = createRequestTypes('rawMat/UPDATE_INCI_QTY');
