import * as categoryTRFormSelectors from './categoryTRFormSelectors';
import * as categoryTRFormActions from './categoryTRFormActions';
import * as categoryTRFormTypes from './categoryTRFormTypes';
import sagas from './categoryTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { categoryTRFormSelectors, categoryTRFormActions, categoryTRFormTypes };
