import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  generalDashboardActions,
  generalDashboardTypes,
  generalDashboardSelectors,
} from '../../../redux/generalDashboard';
import GeneralDashboardProductList from './GeneralDashboardProductList';
import { applicationSelectors } from '../../../redux/application';
import { companySelectors, companyActions } from '../../../redux/company';
import { actionSelectors, actionActions } from '../../../redux/action';
import userRoleTypes from '../../../constants/userRoleTypes';
import { userSelectors } from '../../../redux/user';
import { userRoleActions } from '../../../redux/userRole';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { rawMatActions, rawMatSelectors } from '../../../redux/rawMat';
import { push } from 'react-router-redux';

class GeneralDashboardContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <GeneralDashboardProductList {...this.props} />;
  }
}

const mapStateToProps = state => ({
  generalDashboardProductList: generalDashboardSelectors.getProductList(state),
  isLoading: applicationSelectors.isLoading(state, generalDashboardTypes.GENERALDASHBOARD_LIST.REQUEST),
  companyList: companySelectors.getCompanyList(state),
  actionList: actionSelectors.getActionList(state),
  productStateList: workflowStatusSelectors.getWorkflowStatusList(state),
  salesRepList: userSelectors.getUsersByRole(state, userRoleTypes.saleRep),
  expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
  accountManagerList: userSelectors.getUsersByRole(state, userRoleTypes.customerSupport),
  rawMatList: rawMatSelectors.getRawMatList(state),
  workflowStatusNameById: workflowStatusSelectors.getById(state),
});

const mapDispatchToProps = (dispatch, state) => ({
  onLoad: () => {
    // dispatch(generalDashboardActions.listProducts.request());
    dispatch(companyActions.listCompanies.request());
    dispatch(actionActions.listActions.request());
    dispatch(
      userRoleActions.listUserRoleForRoles.request([
        userRoleTypes.expert,
        userRoleTypes.customerSupport,
        userRoleTypes.saleRep,
      ]),
    );
    dispatch(rawMatActions.listRawMats.request());
  },
  getProductList: () => dispatch(generalDashboardActions.listProducts.request()),
  onRefreshList: payload => dispatch(generalDashboardActions.listProducts.request(payload)),
  searchProducts: payload => dispatch(generalDashboardActions.listProducts.request(payload)),
  onRowDoubleClick: product => dispatch(push(`/products/${product.ProductId}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralDashboardContainer);

GeneralDashboardContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
