import { handleActions } from 'redux-actions';
import * as productDataReviewWizardTypes from './productDataReviewWizardTypes';

const productDataReviewReducer = handleActions(
  {
    [productDataReviewWizardTypes.LOAD_PRODUCT_REVIEW_DATA]: (state, { payload }) => ({
      ...payload,
    }),
    [productDataReviewWizardTypes.UPDATE_PRODUCT_REVIEW_DATA]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

export default productDataReviewReducer;
