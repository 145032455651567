import * as labelReviewSymbolWizardSelectors from './labelReviewSymbolWizardSelectors';
import * as labelReviewSymbolWizardActions from './labelReviewSymbolWizardActions';
import * as labelReviewSymbolWizardTypes from './labelReviewSymbolWizardTypes';
import sagas from './labelReviewSymbolWizardSagas';
import reducer from './labelReviewSymbolWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { labelReviewSymbolWizardSelectors, labelReviewSymbolWizardActions, labelReviewSymbolWizardTypes };
export default reducer;
