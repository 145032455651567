import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomSelect from "./CustomSelect";
import * as referentialSelectors from "../../../../redux/referentials/referentialsSelectors";

const CpsrPhraseStylesDropdownCustom = props => {
  const { cpsrPhraseStyleList } = props;
  return <CustomSelect options={cpsrPhraseStyleList} labelField="Value" valueField="Key" {...props} />;
};

const mapStateToProps = () => state => {
  const getCpsrPhraseStyleList = referentialSelectors.makeGetCpsrPhraseStyleList();
  return {
    cpsrPhraseStyleList: getCpsrPhraseStyleList(state),
  };
};

CpsrPhraseStylesDropdownCustom.defaultProps = {
  cpsrPhraseStyleList: [],
};

CpsrPhraseStylesDropdownCustom.propTypes = {
  cpsrPhraseStyleList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.number.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ),
};

export default connect(
  mapStateToProps,
  null,
)(CpsrPhraseStylesDropdownCustom);
