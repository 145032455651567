import { createRequestActions } from '../utils';
import * as productPhraseTypes from './productPhraseTypes';

export const saveProductPhrase = createRequestActions(productPhraseTypes.SAVE_PRODUCT_PHRASE, (productId, data) => ({
  productId,
  data,
}));
export const copyProductPhrasesFromProduct = createRequestActions(
  productPhraseTypes.COPY_PRODUCT_PHRASE_FROM_PRODUCT,
  (fromProductId, toProductId, cpsrTypeCode) => ({
    fromProductId,
    toProductId,
    cpsrTypeCode,
  }),
);
export const listProductPhraseForProduct = createRequestActions(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT);
export const deleteProductPhrase = createRequestActions(productPhraseTypes.DELETE_PRODUCT_PHRASE, (ProductId, Id) => ({
  ProductId,
  Ids: Array.isArray(Id) ? Id : [Id],
}));
