import PropTypes from 'prop-types';
import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';

import BaseFormGroup from './BaseFormGroup';

const styles = {
  addressGroup: css({
    display: 'block',
  }),
};

const ContactField = props => {
  const {
    company,
    website,
    title,
    firstname,
    lastname,
    position,
    phone,
    mobile,
    fax,
    meta: { error, touched, dirty },
  } = props;

  return (
    <BaseFormGroup {...props}>
      <div className={styles.addressGroup}>
        <label>{I18n.t('contact.company')}</label>
        <input id="company" type="text" value={company} />
        <label>{I18n.t('contact.website')}</label>
        <input id="website" type="text" value={website} />
        <input id="title" type="text" value={title} label={I18n.t('contact.title')} />
        <input id="firtsname" type="text" value={firstname} label={I18n.t('distributor.name')} />
        <input id="lastname" type="text" value={lastname} />
        <input id="position" type="text" value={position} />
        <input id="phone" type="text" value={phone} />
        <input id="mobile" type="text" value={mobile} />
        <input id="fax" type="text" value={fax} />

        <Field name="Name" label={I18n.t('distributor.name')} component="AddressForm" />
        {(touched || dirty) && error && <HelpBlock>{error}</HelpBlock>}
      </div>
    </BaseFormGroup>
  );
};

ContactField.defaultProps = {};

ContactField.propTypes = {
  company: PropTypes.string,
  website: PropTypes.string,
  title: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  position: PropTypes.string,
  phone: PropTypes.string,
  mobile: PropTypes.string,
  fax: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default ContactField;
