import { createRequestActions } from '../utils';
import * as orderProductTypes from './orderProductTypes';

export const listOrdersProducts = createRequestActions(
  orderProductTypes.ORDER_PRODUCT_LIST,
  payload => payload,
  (data, meta) => meta,
);
export const deleteOrderProduct = createRequestActions(
  orderProductTypes.DELETE_ORDER_PRODUCT,
  (ProductId, OrderId) => ({
    OrderId,
    ProductId,
  }),
);

export const getOrderProductForOrder = createRequestActions(
  orderProductTypes.ORDER_PRODUCT_LIST,
  OrderId => ({ OrderId }),
  (data, OrderId) => ({ OrderId }),
);

export const getOrderProductForProduct = createRequestActions(
  orderProductTypes.ORDER_PRODUCT_LIST,
  ProductId => ({ ProductId }),
  (data, ProductId) => ({ ProductId }),
);
