import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { onlyUpdateForKeys } from 'recompose';
import { FormControl } from 'react-bootstrap';

const styles = {
  textarea: css({
    '& textarea.input-sm': {
      height: '60px',
    },
  }),
};

const Textarea = onlyUpdateForKeys(['text'])(({ text, onChange, fieldName }) => {
  const handleChange = ({ target }) => onChange({ [fieldName]: target.value });
  return (
    <div className={styles.textarea}>
      <FormControl value={text || undefined} componentClass="textarea" bsSize="small" onChange={handleChange} />
    </div>
  );
});

Textarea.defaultProps = {
  text: undefined,
};

Textarea.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default Textarea;
