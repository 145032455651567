import { takeEvery, put, all, select, takeLatest, call } from 'redux-saga/effects';
import { change, formValueSelector, submit } from 'redux-form';
import * as R from 'ramda';
import { createSaveFormWithValidation } from '../utils';
import { isString } from '../../../utils/utils';
import { rawMatSelectors } from '../../rawMat';
import { productProportionSagas } from '../../productProportion';
import { rawMatSupplierActions } from '../../rawMatSupplier';
import { modalActions, modalTypes, modalSelectors } from '../../modal';
import { applicationActions } from '../../application';
import formNames from '../../../constants/formNames';
import * as productProportionFormTypes from './productProportionFormTypes';
import * as productProportionFormActions from './productProportionFormActions';

const formName = formNames.productProportionForm;

const getFormValue = formValueSelector(formName);

export default function* root() {
  yield all([
    takeEvery(productProportionFormTypes.RAWMAT_SELECTED, onRawMatSelectedSaga),
    takeEvery(productProportionFormTypes.ADD_BUTTON_CLICKED, onAddButtonClickedSaga),
    takeLatest(productProportionFormActions.submitProductProportionForm.REQUEST, submitProductProportionFormSaga),
    takeLatest(productProportionFormTypes.SAVE_BUTTON_CLIKED, saveForm),
  ]);
}

const submitProductProportionFormSaga = createSaveFormWithValidation(
  productProportionFormActions.submitProductProportionForm,
  saveProductProportionsSaga,
);

function* saveForm() {
  yield put(submit(formName));
}

export function* saveProductProportionsSaga({ type, payload }) {
  const { productId } = payload;

  const rawMatSupplierId = R.path(['RawMat', 'RawMatSupplierId'], payload);

  // New rawMatSupplier created
  const productProportionToSave = isString(rawMatSupplierId)
    ? {
      ...payload,
      RawMat: {
        ...payload.RawMat,
        RawMatSupplierId: -1,
        RawMatSupplier: {
          ...payload.RawMat.RawMatSupplier,
          RawMatSupplierId: -1,
          Name: rawMatSupplierId,
        },
      },
    }
    : payload;

  try {
    yield put(applicationActions.setLoading(type));
    const productProportion = yield call(productProportionSagas.saveProductProportionsSaga, {
      type,
      payload: productProportionToSave,
      meta: { productId },
    });

    const modalType = yield select(state => modalSelectors.getModalType(state));
    if (modalType === modalTypes.PRODUCT_PROPORTION) {
      yield put(modalActions.hideModal());
    }

    if (productProportion) {
      yield put(rawMatSupplierActions.listRawMatSuppliers.request());
    }
    yield put(productProportionFormActions.submitProductProportionForm.success());
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

const createNewRawMatFromExisting = R.applySpec({
  Code: () => null,
  RawMatId: () => null,
  Status: () => null,
  Name: R.prop('Name'),
  RawMatSupplierId: R.prop('RawMatSupplierId'),
});

function* onAddButtonClickedSaga({ payload }) {
  const rawMatToCopy = yield select(state => getFormValue(state, 'RawMat'));
  const newRawMat = createNewRawMatFromExisting(rawMatToCopy);
  yield put(change(formName, 'RawMat', newRawMat));
  yield put(change(formName, 'RawMatId', null));
}

function* onRawMatSelectedSaga({ payload }) {
  const { RawMatId } = payload;
  let rawMat = {};
  if (RawMatId) {
    rawMat = yield select(state => rawMatSelectors.getById(state)[RawMatId]);
  }
  yield put(change(formName, 'RawMat', rawMat));
}
