import { createSelector } from 'reselect';
import queryString from 'query-string';
import * as R from 'ramda';
import { getBaseType } from '../../utils';
import { complianceReviewWizardSelectors } from './complianceReviewWizard';
import { productDataReviewWizardSelectors } from './productDataReviewWizard';
import { rawMatDataReviewWizardSelectors } from './rawMatDataReviewWizard';

const getFomulaReviewWizardStateSlice = state => state.formulaReviewWizard;
export const getFormulaReview = state => getFomulaReviewWizardStateSlice(state).formulaReview;

export const getFormulaReviewWithDetails = createSelector(
  [
    complianceReviewWizardSelectors.getComplianceReviewList,
    rawMatDataReviewWizardSelectors.getRawMatDataReviewList,
    productDataReviewWizardSelectors.getProductDataReview,
    getFormulaReview,
  ],
  (ComplianceReviews, RawMatDataReviews, ProductDataReview, formulaReview) => ({
    ...formulaReview,
    ComplianceReviews,
    RawMatDataReviews,
    ProductDataReview,
  }),
);

export const getIsWizardLoading = (actionType, query) => state => {
  return getFomulaReviewWizardStateSlice(state).meta.loading[
    `${getBaseType(actionType)}/${queryString.stringify(R.omit(['include'])(query))}`
    ];
}
