import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import queryString from 'query-string';
import * as phraseCpsrChapterTypes from './phraseCpsrChapterTypes';

const byId = handleActions(
  {
    [phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.SUCCESS]: (state, { payload, meta = {} }) =>
      (payload.entities.phraseCpsrChapters
        ? {
          ...state,
          ...payload.entities.phraseCpsrChapters,
        }
        : state),
  },
  {},
);

const byQueryIds = handleActions(
  {
    [phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.REQUEST]: (state, { payload, meta = {} }) => {
      const { query = {} } = payload;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: true,
      };
    },
    [combineActions(
      phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.SUCCESS,
      phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.FAILURE,
    )]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: false,
      };
    },
  },
  {},
);

const allIds = handleActions(
  {
    [phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.SUCCESS]: (state, { payload }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
