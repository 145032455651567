import * as substanceTRSelectors from './substanceTRSelectors';
import * as substanceTRActions from './substanceTRActions';
import * as substanceTRTypes from './substanceTRTypes';
import * as substanceTRSchemas from './substanceTRSchemas';

import sagas, * as substanceTRSagas from './substanceTRSagas';
import reducer from './substanceTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceTRSelectors, substanceTRActions, substanceTRTypes, substanceTRSchemas, substanceTRSagas };
export default reducer;
