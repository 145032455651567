import * as companyDistributorAgreementSelectors from './companyDistributorAgreementSelectors';
import * as companyDistributorAgreementActions from './companyDistributorAgreementActions';
import * as companyDistributorAgreementTypes from './companyDistributorAgreementTypes';
import * as companyDistributorAgreementSchemas from './companyDistributorAgreementSchemas';
import sagas from './companyDistributorAgreementSagas';
import reducer from './companyDistributorAgreementReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  companyDistributorAgreementSelectors,
  companyDistributorAgreementActions,
  companyDistributorAgreementTypes,
  companyDistributorAgreementSchemas,
};
export default reducer;
