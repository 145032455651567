import { takeLatest, put, all, take } from 'redux-saga/effects';
import { modalActions } from '../../modal';
import * as labelReviewApprovalFormTypes from './labelReviewApprovalFormTypes';
import { labelReviewActions, labelReviewTypes } from '../../labelReview';

export default function* root() {
  yield all([takeLatest(labelReviewApprovalFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClickSaga)]);
}

function* onSaveButtonClickSaga({ payload }) {
  yield put(labelReviewActions.updateLabelReview.request(payload));
  yield take(labelReviewTypes.UPDATE_LABEL_REVIEW.SUCCESS);
  yield put(modalActions.hideModal());
}
