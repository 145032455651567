import * as allergenModalActions from './allergenModalActions';
import * as allergenModalTypes from './allergenModalTypes';
import * as allergenModalSelectors from './allergenModalSelectors';
import sagas from './allergenModalSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { allergenModalActions, allergenModalTypes, allergenModalSelectors };
