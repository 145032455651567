import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as taskActionTypes from './taskActionTypes';

export const listTaskActions = createRequestActions(taskActionTypes.TASK_ACTION_LIST, TaskId => ({
  TaskId,
}));

export const showTaskActionsHistory = createAction(taskActionTypes.SHOW_TASK_ACTIONS_HISTORY, TaskId => ({
  TaskId,
}));

export const saveTaskAction = createRequestActions(taskActionTypes.SAVE_TASK_ACTION, (TaskId, data) => ({
  TaskId,
  ...data,
}));
