import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import * as R from 'ramda';
import { isNullOrUndef } from './utils';

const isNilOrEmpty = R.anyPass([R.isEmpty, R.isNil]);

// eslint-disable-next-line
const rangeRegexp = /^((((0|[1-9]{1}\d*)(\.\d+)?(%|ppm|ppb|mg\/kg)?-|[<|>]=?|(\+\-)?|(\~)?|(\+\/\-)?)(0|[1-9]{1}\d*)(\.\d+)?(%|ppm|ppb|mg\/kg)?))$/i;

export const isValidRange = value =>
  (isNilOrEmpty(value) || rangeRegexp.test(value.replace(/\s/g, '')) ? undefined : I18n.t('form.rangeFieldError'));

export const isGreaterThan = limit => value =>
  (R.gte(value, limit) ? undefined : I18n.t('form.greaterThanError', { limit }));

export const requiredField = value => (!isNilOrEmpty(value) ? undefined : I18n.t('form.requiredFieldError'));
export const isNumberField = value =>
  !isNullOrUndef(value) && (Number.isNaN(Number(value)) ? I18n.t('form.numberFieldError') : undefined);
export const isPositiveOrZeroInteger = value => {
  if (!isNullOrUndef(value) && !Number.isInteger(Number(value))) {
    return I18n.t('form.integerFieldError');
  }
  if (Number(value) < 0) {
    return I18n.t('form.positiveOrZeroIntegerFieldError');
  }
  return undefined;
};
export const isPositiveInteger = value => {
  if (!isNullOrUndef(value) && !Number.isInteger(Number(value))) {
    return I18n.t('form.integerFieldError');
  }
  if (Number(value) < 1) {
    return I18n.t('form.positiveIntegerFieldError');
  }
  return undefined;
};
export const isPositiveOrZeroDecimal = value => {
  if (!isNullOrUndef(value) && Number.isNaN(Number(value))) {
    return I18n.t('form.decimalFieldError');
  }
  if (Number(value) < 0) {
    return I18n.t('form.positiveOrZeroDecimalFieldError');
  }
  return undefined;
};
export const isInteger = value =>
  !isNullOrUndef(value) && (Number.isInteger(Number(value)) ? undefined : I18n.t('form.integerFieldError'));
export const isValidDate = value => value && (moment(value).isValid() ? undefined : I18n.t('form.validDateError'));
const maxLength = max => value => (value && value.length > max ? I18n.t('form.maxLengthError', { max }) : undefined);
export const isEmail = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? I18n.t('form.emailError') : undefined);

export const maxLength9 = maxLength(9);
export const maxLength16 = maxLength(16);
export const maxLength20 = maxLength(20);
export const maxLength32 = maxLength(32);
export const maxLength48 = maxLength(48);
export const maxLength50 = maxLength(50);
export const maxLength64 = maxLength(64);
export const maxLength128 = maxLength(128);
export const maxLength255 = maxLength(255);
export const maxLength256 = maxLength(256);
export const maxLength512 = maxLength(512);
export const maxLength1024 = maxLength(1024);
export const maxLength2048 = maxLength(2048);

export const upperCaseOrNumber = value => {
  const regexp = /^[A-Z0-9]{1,50}$/;
  // const regexp = /^[A-Z]{1}[A-Z0-9]{0,49}$/;
  return value && !regexp.test(value) ? I18n.t('form.upperCaseNumberError') : undefined;
};
