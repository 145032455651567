import { schema } from 'normalizr';
import * as actionSchemas from '../action/actionSchemas';

export const impactAssessmentActionSchema = new schema.Entity(
  'impactAssessmentActions',
  { Action: actionSchemas.actionSchema },
  { idAttribute: 'ImpactAssessmentActionId' },
);

export const impactAssessmentActionListSchema = [impactAssessmentActionSchema];
