import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userSelectors } from '../../redux/user';

const WithPrivileges = ({ requiredActionCodes, currentUserActionCodes, authorizationType, children }) => {
  const isRequiredActionInCurrentUserActions = requiredActionCode =>
    currentUserActionCodes.find(currentUserActionCode => currentUserActionCode === requiredActionCode) !== undefined;

  const isAuthorized =
    authorizationType === 'all'
      ? requiredActionCodes.every(isRequiredActionInCurrentUserActions)
      : requiredActionCodes.some(isRequiredActionInCurrentUserActions);

  return children(isAuthorized);
};

const mapStateToProps = state => ({
  currentUserActionCodes: userSelectors.getCurrentUserActionCodes(state),
});

WithPrivileges.defaultProps = {
  authorizationType: 'atLeastOne',
};

WithPrivileges.propTypes = {
  authorizationType: PropTypes.oneOf(['atLeastOne', 'all']),
  requiredActionCodes: PropTypes.arrayOf(PropTypes.string),
  currentUserActionCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(WithPrivileges);
