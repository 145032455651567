import 'react-virtualized-select/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { css } from 'glamor';
import {FormGroup} from "react-bootstrap";
import formFieldState from "../../../../constants/formFieldState";
import {connect} from "react-redux";
import {errorsFormActions} from "../../../../redux/form/errorsForm";

const styleSelect = css({
  '& .Select_menu_outer': {
    zIndex: '9999!important',
    color: 'red',
  },
});

class CustomSelect extends React.PureComponent {
  state = {
    validationState: null,
    errorLabel: undefined,
  }

  componentDidMount() {
    const { value, handleFieldStatusChange, isNew, validate } = this.props;
    if(isNew){
      const errorLabel = validate ? validate(value) : undefined;
      const fieldStatus = errorLabel ? formFieldState.error : formFieldState.success;
      handleFieldStatusChange(fieldStatus);
    }
  }

  handleChange = opt => {
    const { valueField, handleFieldStatusChange, validate, onChange } = this.props;

    let val = null;
    if (opt !== null) {
      val = opt[valueField];
      onChange(val);
    } else {
      onChange(opt);
    }

    const errorLabel = validate ? validate(val) : undefined;
    const fieldStatus = errorLabel ? formFieldState.error : formFieldState.success;
    handleFieldStatusChange(fieldStatus);
  }

  handleOnBlur = () => {
    const { validate, value } = this.props;
    const errorLabel = validate ? validate(value) : undefined;
    let validationState = null;
    if(errorLabel) validationState = 'error';
    this.setState({ validationState, errorLabel });
  };

  render = () => {
    const { label, labelField, valueField, value, options, onChange, ...otherProps } = this.props;
    const { errorLabel, validationState } = this.state;

    return (
      <FormGroup validationState={validationState} bsSize="small">
        <label className={'control-label'}>{label}</label>
        <div className={styleSelect}>
          <Select
            value={value}
            options={options}
            onChange={this.handleChange}
            labelKey={labelField}
            valueKey={valueField}
            onBlur={this.handleOnBlur}
            selectComponent={Select}
            {...otherProps}
          />
        </div>
        {errorLabel && (<span class={'help-block'}>{errorLabel}</span>)}
      </FormGroup>
    );
  }
};

const mapDispatchToProps = (_, props) => {
  const { name } = props;
  return dispatch => ({
    handleFieldStatusChange: status => dispatch(errorsFormActions.handleFieldStatusUpdate({ name, status })),
  });
};

CustomSelect.defaultProps = {
  labelField: 'Value',
  valueField: 'Key',
  options: [],
  matchProp: 'label',
};

CustomSelect.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  isNew: PropTypes.bool, // eslint-disable-line
  options: PropTypes.array, // eslint-disable-line
  label: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  handleFieldStatusChange: PropTypes.func,
  matchPos: PropTypes.string,
  matchProp: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(CustomSelect);
