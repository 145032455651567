import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Row, Col, Button, FormGroup} from 'react-bootstrap';
import { css } from 'glamor';
import {phraseSelectors} from "../../../redux/phrase";
import {Field} from "redux-form";
import {EditableLabelField} from "../../_Common/Forms";
import CustomSimpleField from "../../_Common/Forms/Custom/CustomSimpleField";

const styles = {
  phraseRender: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  boldTxt: css({
    fontWeight: 'bold',
    marginRight: '5px'
  }),
  lineRender: css({
    marginTop: '10px',
    marginBottom: '5px',
  }),
};

const ProductPhraseTRForm = props => {
  const {
    formValues,
    onAddButtonClick,
    selectedLanguage,
    productPhraseId,
    productId,
    CpsrTypeCode,
    change,
  } = props;

  const data = formValues[productPhraseId];
  const dataForLang = data[selectedLanguage];

  const placheHolders = [
    { value: /(\$\$V1\$\$)/g, name: `${productPhraseId}.ProductPhrase.V1` },
    { value: /(\$\$Column1\$\$)/g, name: `${productPhraseId}.ProductPhrase.V1` },
    { value: /(\$\$V2\$\$)/g, name: `${productPhraseId}.ProductPhrase.V2` },
    { value: /(\$\$Column2\$\$)/g, name: `${productPhraseId}.ProductPhrase.V2` },
    { value: /(\$\$V3\$\$)/g, name: `${productPhraseId}.ProductPhrase.V3` },
    { value: /(\$\$Column3\$\$)/g, name: `${productPhraseId}.ProductPhrase.V3` },
    { value: /(\$\$V4\$\$)/g, name: `${productPhraseId}.ProductPhrase.V4` },
    { value: /(\$\$Column4\$\$)/g, name: `${productPhraseId}.ProductPhrase.V4` },
    { value: /(\$\$V5\$\$)/g, name: `${productPhraseId}.ProductPhrase.V5` },
    { value: /(\$\$Column5\$\$)/g, name: `${productPhraseId}.ProductPhrase.V5` },
    { value: /(\$\$V6\$\$)/g, name: `${productPhraseId}.ProductPhrase.V6` },
    { value: /(\$\$Column6\$\$)/g, name: `${productPhraseId}.ProductPhrase.V6` },
    { value: /(\$\$V7\$\$)/g, name: `${productPhraseId}.ProductPhrase.V7` },
    { value: /(\$\$Column7\$\$)/g, name: `${productPhraseId}.ProductPhrase.V7` },
    { value: /(\$\$TM\$\$)/g, name: `${productPhraseId}.ProductPhrase.TM` },
    { value: /(\$\$Column8\$\$)/g, name: `${productPhraseId}.ProductPhrase.TM` },
  ];

  const placheHolders2 = [
    { value: /(\$\$V1\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V1` },
    { value: /(\$\$Column1\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V1` },
    { value: /(\$\$V2\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V2` },
    { value: /(\$\$Column2\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V2` },
    { value: /(\$\$V3\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V3` },
    { value: /(\$\$Column3\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V3` },
    { value: /(\$\$V4\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V4` },
    { value: /(\$\$Column4\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V4` },
    { value: /(\$\$V5\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V5` },
    { value: /(\$\$Column5\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V5` },
    { value: /(\$\$V6\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V6` },
    { value: /(\$\$Column6\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V6` },
    { value: /(\$\$V7\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V7` },
    { value: /(\$\$Columnè\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.V7` },
    { value: /(\$\$TM\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.TM` },
    { value: /(\$\$Column8\$\$)/g, name: `${productPhraseId}.${selectedLanguage}.Text.TM` },
  ];

  return (
    <Row>
      {!data.ProductPhrase.PhraseCode.startsWith('TB') ? (
        <div className={styles.lineRender}>
          <Col sm={1}>
            <div className={styles.phraseRender}>
              <div>
                <span className={styles.boldTxt}>
                    <Translate value="productPhraseForm.OrderNr" />:
                </span>
                {data.ProductPhrase.OrderNr}
              </div>
              <div>
                <span className={styles.boldTxt}>
                    <Translate value="productPhraseForm.CpsrChapterCode" />:
                </span>
                {data.ProductPhrase.PhraseCode}
              </div>
            </div>
          </Col>
          <Col sm={5}>
            <FormGroup>
              <Field
                component={EditableLabelField}
                name={`${productPhraseId}.PhraseText`}
                placeholders={placheHolders}
                readOnly
              />
            </FormGroup>
          </Col>
          {selectedLanguage && selectedLanguage !== "" && (
            <Col md={6}>
              {dataForLang.Text ? (
                <Col sm={10}>
                  <FormGroup>
                    <Field
                      component={EditableLabelField}
                      name={`${productPhraseId}.${selectedLanguage}.Phrase.Text`}
                      placeholders={placheHolders2}
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col sm={2} smOffset={10}>
                  <Button
                    bsSize="small"
                    bsStyle="info"
                    type="button"
                    onClick={() => onAddButtonClick({productPhraseId, selectedLanguage, productId, CpsrTypeCode, PhraseTRId: dataForLang.Phrase.PhraseTRId })}
                    disabled={!dataForLang.Phrase}
                  >
                    <Translate value="phraseTranslation.add" />
                  </Button>
                </Col>
              )}
            </Col>
          )}
        </div>
      ) : (
        <div className={styles.lineRender}>
          <Col sm={1}>
            <div className={styles.phraseRender}>
              <div>
                <span className={styles.boldTxt}>
                    <Translate value="productPhraseForm.OrderNr" />:
                </span>
                {data.ProductPhrase.OrderNr}
              </div>
            </div>
          </Col>
          <Col sm={5}>
            <Row>
              <Col sm={6}>
                <CustomSimpleField
                  label={I18n.t('productPhraseForm.Characteristic')}
                  value={data.ProductPhrase.V1}
                  component="textarea"
                  readOnly
                />
              </Col>
              <Col sm={6}>
                <CustomSimpleField
                  label={I18n.t('productPhraseForm.Specification')}
                  component="textarea"
                  readOnly
                  value={data.ProductPhrase.V2}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <CustomSimpleField
                  label={I18n.t('productPhraseForm.Results')}
                  component="textarea"
                  readOnly
                  value={data.ProductPhrase.TM}
                />
              </Col>
              <Col sm={6}>
                <CustomSimpleField
                  label={I18n.t('productPhraseForm.TestMethod')}
                  component="textarea"
                  readOnly
                  value={data.ProductPhrase.V3}
                />
              </Col>
            </Row>
          </Col>
          {selectedLanguage && selectedLanguage !== "" && (
            <Col md={6}>
              {dataForLang.Text ? (
                <div>
                  <Row>
                    <Col sm={6}>
                      <CustomSimpleField
                        label={I18n.t('productPhraseForm.Characteristic')}
                        value={dataForLang.Text.V1}
                        onChange={(newValue) => change(`${productPhraseId}.${selectedLanguage}.Text.V1`, newValue)}
                        component="textarea"
                      />
                    </Col>
                    <Col sm={6}>
                      <CustomSimpleField
                        label={I18n.t('productPhraseForm.Specification')}
                        component="textarea"
                        onChange={(newValue) => change(`${productPhraseId}.${selectedLanguage}.Text.V2`, newValue)}
                        value={dataForLang.Text.V2}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <CustomSimpleField
                        label={I18n.t('productPhraseForm.Results')}
                        component="textarea"
                        onChange={(newValue) => change(`${productPhraseId}.${selectedLanguage}.Text.TM`, newValue)}
                        value={dataForLang.Text.TM}
                      />
                    </Col>
                    <Col sm={6}>
                      <CustomSimpleField
                        label={I18n.t('productPhraseForm.TestMethod')}
                        component="textarea"
                        onChange={(newValue) => change(`${productPhraseId}.${selectedLanguage}.Text.V3`, newValue)}
                        value={dataForLang.Text.V3}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col sm={2} smOffset={10}>
                  <Button
                    bsSize="small"
                    bsStyle="info"
                    type="button"
                    onClick={() => onAddButtonClick({productPhraseId, selectedLanguage, productId, CpsrTypeCode, PhraseTRId: dataForLang.Phrase.PhraseTRId })}
                  >
                    <Translate value="phraseTranslation.add" />
                  </Button>
                </Col>
              )}
            </Col>
          )}
        </div>
      )}
    </Row>
  );
};

const mapStateToProps = (state, {phraseCode}) => {
  const phrase = phraseSelectors.getPhraseByCode(state, phraseCode);
  return {
    phrase,
  };
};

ProductPhraseTRForm.propTypes = {
  productPhraseId: PropTypes.number.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ProductPhraseTRForm);
