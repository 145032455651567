import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import { getPinnedBottomRowData } from './utils';
import BaseLoader from '../../../_Common/BaseLoader';
import ShadeCompoList from './_ShadeCompoListContainer';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
  }),
};

class ShadeCompoModal extends React.Component {
  setGrid = g => {
    this.grid = g;
    this.initializeGrid();
  };
  getRowsData = () => {
    if (this.grid) {
      return this.grid.api.rowModel.rowsToDisplay.map(rn => rn.data);
    }
    return [];
  };
  initializeGrid = () => {
    if (this.grid) {
      this.grid.api.setPinnedBottomRowData(getPinnedBottomRowData(this.getRowsData()));
    }
  };
  handleOnSave = () => {
    if (this.grid) {
      this.props.onSaveButtonClick(this.getRowsData());
    }
  };
  render() {
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <div className={styles.modalBody}>
          <Modal.Body>
            <BaseLoader isLoading={false}>
              <ShadeCompoList
                shadeId={this.props.shadeId}
                productId={this.props.productId}
                whenGridReady={this.setGrid}
                buttonsDisabledByValidedComposition={this.props.buttonsDisabledByValidedComposition}
              />
            </BaseLoader>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={this.props.closeModal}>
                <i className="fa fa-times" />
                &nbsp;
                <Translate value="general.close" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="button"
                onClick={this.handleOnSave}
                disabled={this.props.isSaveDisabled || this.props.buttonsDisabledByValidedComposition}
              >
                <Translate value="general.save" />
                &nbsp;
                <i className="fa fa-arrow-circle-right" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

ShadeCompoModal.defaultProps = {
  title: '',
};

ShadeCompoModal.propTypes = {
  shadeId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
};

export default ShadeCompoModal;
