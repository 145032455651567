import { takeLatest, put, all, select, take } from 'redux-saga/effects';
import * as R from 'ramda';
import { productActions } from '../../../../redux/product';
import { companySelectors } from '../../../../redux/company';
import { mandateSelectors, mandateActions, mandateTypes } from '../../../../redux/mandate';
import { modalActions, modalSelectors, modalTypes } from '../../../../redux/modal';
import * as types from './types';

export default function* root() {
  yield all([takeLatest(types.LOAD, onLoadSaga), takeLatest(types.SAVE, onSaveSaga)]);
}

function* onSaveSaga({ payload }) {
  const { mandateId, mandateProducts, distributorId, mandateData } = payload;
  if (mandateId <= 0) return;
  const mandate = yield select(state => mandateSelectors.getById(state)[mandateId]);
  yield put(mandateActions.saveMandate.request({ ...mandate, ...mandateData, mandateProducts }, { distributorId }));
  yield take(mandateTypes.SAVE_MANDATE.SUCCESS);
  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.MANDATE_PRODUCTS) {
    yield put(modalActions.hideModal());
  }
}

function* onLoadSaga({ payload }) {
  const { mandateId } = payload;
  if (mandateId <= 0) return;
  const company = yield select(companySelectors.makeGetCompanyForMandate(mandateId));
  const companyId = R.prop('CompanyId', company);
  yield put(productActions.listProductForCompany.request(companyId));
}
