import {merge} from 'lodash';
import {handleActions, combineActions} from 'redux-actions';
import * as productTypes from './productTypes';
import * as productDocumentTypes from '../productDocument/productDocumentTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';
import {substanceProductTypes} from '../substanceProduct';
import * as editInciListFormTypes from '../form/editInciListForm/editInciListFormTypes';
import {mandateProductTypes} from '../mandateProduct';
import {mandateTypes} from '../mandate';
import {impactAssessmentTypes} from '../impactAssessment';

const initialState = {
  productList: [],
  currentProduct: {},
  currentProductProportions: [],
  currentProductCompanies: [],
  currentProdigumProductProportions: [],
  currentImportedProductProportions: [],
  currentProductMosCalculationList: [],
  currentProductImpurityList: [],
  currentProductCosmosIngredientRawMatMatches: [],
  ordersOwnedByCurrentProductCompany: [],
  currentProductOrders: [],
  currentProductTasks: [],
  byId: {},
  list: {
    allIds: [],
    byCompanyIds: {},
  },
  currentLastFrCheckList: {},
  currentLastFrMissingDocument: [],
};

export default handleActions(
  {
    [impactAssessmentTypes.IMPACT_ASSESSMENT_LIST_BY_PRODUCT.SUCCESS]: (state, {payload, meta: {ProductId}}) => ({
      ...state,
      byId: {
        ...state.byId,
        [ProductId]: {
          ...state.byId[ProductId],
          ImpactAssessments: payload.result,
        },
      },

      currentProductImpactAssessments: payload.result.map(id => payload.entities.impactAssessments[id]), // Should be removed once normalized
    }),

    [combineActions(productTypes.PRODUCT_LIST.SUCCESS, productTypes.PRODUCT_LIST2.SUCCESS)]: (state, {payload}) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.products),
      list: {
        ...state.list,
        allIds: payload.result,
      },
    }),
    [editInciListFormTypes.SAVE_FORM.SUCCESS]: (state, {payload, meta: {ProductId}}) => ({
      ...state,
      byId: {
        ...state.byId,
        [ProductId]: {
          ...state.byId[ProductId],
          InciListUpperPart: payload.UpperPart,
          InciListLowerPart: payload.LowerPart,
        },
      },
    }),
    [productTypes.PRODUCT_LIST_FOR_COMPANY.SUCCESS]: (state, {payload, meta: {companyId}}) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.products),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, ...payload.result])],
        byCompanyIds: {
          ...state.list.byCompanyIds,
          [companyId]: payload.result,
        },
      },
    }),
    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT.SUCCESS]: (state, {payload}) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.products),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, ...Object.keys(payload.entities.products || []).map(Number)])],
      },
    }),
    [substanceProductTypes.FETCH_PRODUCTS_FOR_SUBSTANCE.SUCCESS]: (state, {payload}) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.products),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, ...payload.result])],
      },
    }),
    [combineActions(mandateProductTypes.FETCH_MANDATE_PRODUCTS.SUCCESS, mandateTypes.FETCH_MANDATES.SUCCESS)]: (
      state,
      {payload},
    ) => ({
      ...state,
      byId: {...state.byId, ...payload.entities.products},
      list: {
        ...state.list,
        allIds: payload.entities.products
          ? [...new Set([...state.list.allIds, ...Object.keys(payload.entities.products).map(Number)])]
          : state.list.allIds,
      },
    }),
    [combineActions(productTypes.PRODUCT.SUCCESS, productTypes.SAVE_PRODUCT_CPSRB_COMMENT.SUCCESS)]: (
      state,
      {payload},
    ) => ({
      ...state,
      currentProduct: payload.entities.products[payload.result], // To remove once refactored
      byId: {
        ...state.byId,
        ...payload.entities.products,
      },
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, payload.result])],
      },
    }),
    [productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT.SUCCESS]: (state, {payload, meta: {ProductId}}) => ({
      ...state,
      byId: {
        ...state.byId,
        [ProductId]: {
          ...state.byId[ProductId],
          ProductDocuments: payload.result,
        },
      },
    }),

    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS]: (
      state,
      {payload, meta: {ProductId}},
    ) => ({
      ...state,
      byId: {
        ...state.byId,
        [ProductId]: {
          ...state.byId[ProductId],
          ProductProportions: payload.result,
        },
      },

      currentProductProportions: payload.result.map(id => payload.entities.productProportions[id]), // Should be removed once normalized
    }),

    // To be normalized
    [productTypes.PRODUCT_COMPANY_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentProductCompanies: payload,
    }),
    [productTypes.PRODUCT_ORDER_LIST.SUCCESS]: (state, {payload}) => ({
      ...state, currentProductOrders: payload}),
    [productTypes.PRODUCT_TASK_LIST.SUCCESS]: (state, {payload}) => ({
      ...state, currentProductTasks: payload
    }),
    [productTypes.PRODUCT_MOS_CALCULATION_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentProductMosCalculationList: payload,
    }),
    [productTypes.PRODUCT_IMPURITY_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentProductImpurityList: payload,
    }),
    [productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentProdigumProductProportions: payload,
    }),
    [productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentImportedProductProportions: payload,
    }),
    [productTypes.SAVE_PRODUCT_PHRASES.SUCCESS]: state => ({...state, temporaryProductPhraseList: []}),
    [productTypes.ORDERS_OWNED_BY_PRODUCT_COMPANY.SUCCESS]: (state, {payload}) => ({
      ...state,
      ordersOwnedByCurrentProductCompany: payload,
    }),
    [productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentProductCosmosIngredientRawMatMatches: payload,
    }),
    [productTypes.NEW_PRODUCT]: state => ({
      ...state,
      currentProduct: {ProductId: -1, IsActive: true},
      currentProductOrders: [],
      currentProductTasks: [],
      currentProductProportions: [],
      currentProductCompanies: [],
      currentProdigumProductProportions: [],
      currentImportedProductProportions: [],
      currentProductCosmosIngredientRawMatMatches: [],
      ordersOwnedByCurrentProductCompany: [],
      currentProductMosCalculationList: [],
    }),
    [productTypes.REPORT_TEST.SUCCESS]: (state, {payload}) => ({
      ...state,
      report: payload,
    }),
    [productTypes.REPORT.SUCCESS]: (state, {payload}) => ({
      ...state,
      report: payload,
    }),
    [productTypes.FETCH_LAST_FR_CHECK_LIST.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentLastFrCheckList: payload,
    }),
    [productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.SUCCESS]: (state, {payload}) => ({
      ...state,
      currentLastFrMissingDocument: payload,
    }),
  },
  initialState,
);
