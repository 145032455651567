import { createSelector } from 'reselect';

export const getById = ({ substanceAction }) => substanceAction.byId;
export const getIds = ({ substanceAction }) => substanceAction.list.allIds;
export const getSubstanceActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getSubstanceAction = (state, id) => state.substanceAction.byId[id];

const getSubstanceActionsForSubstance = substanceId =>
  createSelector(getSubstanceActionList, substanceActionList =>
    substanceActionList.filter(substanceAction => substanceAction.SubstanceId === substanceId),
  );

export const makeGetSubstanceActionsForSubstance = createSelector(
  getSubstanceActionsForSubstance,
  substanceActionsForSubtance => substanceActionsForSubtance,
);
