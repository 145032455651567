import * as labelReviewItemSelectors from './labelReviewItemSelectors';
import * as labelReviewItemActions from './labelReviewItemActions';
import * as labelReviewItemTypes from './labelReviewItemTypes';
import * as labelReviewItemSchemas from './labelReviewItemSchemas';
//import sagas from './labelReviewItemSagas';
import reducer from './labelReviewItemReducer';

//import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { labelReviewItemSelectors, labelReviewItemActions, labelReviewItemTypes, labelReviewItemSchemas };
export default reducer;
