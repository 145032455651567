import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { modalActions } from '../../redux/modal';

const PrintProductDocumentsButton = ({ onClick }) => (
  <Button bsSize="small" onClick={onClick}>
    <Translate value="report.print" />&nbsp;
    <i className="fa fa-print" aria-hidden="true" />
  </Button>
);

const mapDispatchToProps = (dispatch, { productId }) => ({
  onClick: () => dispatch(modalActions.showPrintDocumentsModal({ productId })),
});

PrintProductDocumentsButton.propTypes = {
  productId: PropTypes.number.isRequired, //eslint-disable-line
  onClick: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PrintProductDocumentsButton);
