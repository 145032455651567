import { createSelector } from 'reselect';
import { get, differenceWith, isEqual } from 'lodash/fp';
import { isNullOrUndef } from '../../../../utils/utils';
import { referentialSelectors } from '../../../referentials';
import { labelReviewItemSelectors } from '../../../labelReviewItem';

export const getLabelReviewItems = ({ labelReviewWizard }) => labelReviewWizard.labelReviewItems;
export const getLabelReviewItemsbyId = state => getLabelReviewItems(state).byId;
export const getLabelReviewItemsIds = state => getLabelReviewItems(state).list.allIds;
export const getLabelReviewItem = (state, id) => getLabelReviewItemsbyId(state)[id];

export const getLabelReviewItemList = createSelector(
  [getLabelReviewItemsIds, getLabelReviewItemsbyId],
  (allIds, byId) => allIds.map(id => byId[id]),
);

export const getLabelReviewItemListWithDetails = state => {
  const labelReviewItemReferentialDataList = referentialSelectors.getLabelReviewItemReferentialData(state);
  const labelReviewItemList = getLabelReviewItemList(state);
  return labelReviewItemReferentialDataList.map(referentialData => {
    const labelReviewItem = labelReviewItemList.find(lrItem => lrItem.ItemCode === referentialData.Code);
    const comment = get('Comment', labelReviewItem);
    return {
      ...labelReviewItem,
      Comment: !isNullOrUndef(comment) ? comment : referentialData.Comment,
      ReferentialData: { ...referentialData },
    };
  });
};

export const getLabelReviewInciWithDetails = state => {
  const labelReviewInciReferential = referentialSelectors.getLabelReviewInciReferentialData(state);
  const labelReviewInci = getLabelReviewItemList(state).find(
    lrItem => lrItem.ItemCode === labelReviewInciReferential.Code,
  );

  if (!labelReviewInci) return {};
  const comment = get('Comment', labelReviewInci);
  return {
    ...labelReviewInci,
    Comment: !isNullOrUndef(comment) ? comment : labelReviewInciReferential.Comment,
    ReferentialData: { ...labelReviewInciReferential },
  };
};

export const getEditedLabelReviewItemList = createSelector(
  [labelReviewItemSelectors.getLabelReviewItemListForLabelReview, getLabelReviewItemList],
  (labelReviewItemList, labelReviewItemWizardList) =>
    differenceWith(isEqual, labelReviewItemWizardList, labelReviewItemList),
);
