import { createSelector } from 'reselect';
import { workflowStatusSelectors } from '../workflowStatus';

export const getIds = ({ action }) => action.list.allIds;
export const getById = ({ action }) => action.byId;
export const getAction = (state, id) => getById(state)[id];

export const getActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getActionListNotInWorkflow = createSelector(
  [getActionList, workflowStatusSelectors.getWorkflowStatusList],
  (actionList, workflowStatusList) =>
    actionList.filter(a => workflowStatusList.find(wfs => wfs.ActionCode === a.Code) === undefined),
);
export const getActionListForEntityNotInWorkflow = entity =>
  createSelector(
    [getActionList, workflowStatusSelectors.getWorkflowStatusList],
    (actionList, workflowStatusList) =>
      actionList.filter(a => a.Entity === entity && workflowStatusList.find(wfs => wfs.ActionCode === a.Code) === undefined),
  );
