import { substanceSelectors } from '../../substance';
import { substanceTRSelectors } from '../../substanceTR';
import {referentialSelectors} from "../../referentials";

export const getInitialValues = (state, substanceId) => {
  const substance = substanceSelectors.getById(state)[substanceId];
  const substanceTRs = substanceTRSelectors.getSubstanceTRBySubstanceId(state, substanceId);
  // console.log('substanceTRs', substanceTRs);
  // console.log('substanceId', substanceId);
  const languageList = referentialSelectors.getLanguageList(state);
  const values = {
    substance
  }
  languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
    const productForLang = substanceTRs.find(elem => elem.LanguageCode === lang.Alpha2);
    values[lang.Alpha2] = productForLang ? productForLang : {
      SubstanceId: substanceId,
      LanguageCode: lang.Alpha2,
    }
  });
  return values;
};
