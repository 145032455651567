import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companyActions, companyTypes } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { modalActions } from '../../../redux/modal';
import CompanyRawMatsTab from './CompanyRawMatsTabComponent';

class CompanyRawMatsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <CompanyRawMatsTab {...this.props} />;
  }
}

const mapStateToProps = state => {
  const {
    company: { currentCompanyRawMats },
  } = state;
  return {
    workflowStatusNameById: workflowStatusSelectors.getById(state),
    currentCompanyRawMats,
    requestingCompanyRawMatList: applicationSelectors.isLoading(state, companyTypes.COMPANY_RAW_MAT_LIST.REQUEST),
    requestingCopyRawMat: applicationSelectors.isLoading(state, companyTypes.SUBMIT_COPY_RM_FORM.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => dispatch(companyActions.listCompanyRawMats.request({ id:companyId, filter: 'ALL' })),
  onFilter: (filter) => dispatch(companyActions.listCompanyRawMats.request({ id:companyId, filter: filter })),
  onExportToExcel: (filter) => 
    dispatch(companyActions.exportCompanyProductsByRawMats.request({ companyId:companyId, rawMats: filter })),
  onRefresh: () => dispatch(companyActions.listCompanyRawMats.request(companyId)),
  onRowDoubleClicked: rawMat => dispatch(push(`/rawmats/${rawMat.RawMatId}`)),
  onCopyRawMatClick: () => dispatch(modalActions.showCopyRawMatModal({ companyId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyRawMatsContainer);
