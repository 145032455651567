import React from 'react';
import { Grid, Col } from 'react-bootstrap';
import ProductDocumentList from './ProductDocumentList';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const ProductDocumentsTab = props =>
  (<div>
    <Grid fluid>
      <Col sm={10}>
        <ProductDocumentList {...props} />
      </Col>
      <Col sm={2}>
        <DropzoneForm {...props} />
      </Col>
    </Grid>
  </div>);

export default ProductDocumentsTab;
