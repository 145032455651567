import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField, SelectField } from '../../../_Common/Forms';
import { requiredField, isPositiveOrZeroInteger } from '../../../../utils/fieldValidation';
// import { ProcessTypeRefListDropdown } from '../../../_Common/Dropdowns';

const ProcessBasicFormSection = props => {
  const { readOnly, regions, currencies } = props;

  return (
    <div>
      {/* <Col sm={3}>
        <Field
          name="Code"
          label={`${I18n.t('taskType.processType')} *`}
          component={ProcessTypeRefListDropdown}
          disabled={readOnly}
          validate={requiredField}
        />
      </Col> */}
      <Col sm={3}>
        <Field
          name="RegionCode"
          label={`${I18n.t('taskType.region')} *`}
          component={SelectField}
          options={regions}
          labelField="Value"
          valueField="Key"
          validate={requiredField}
          disabled={readOnly}
        />
      </Col>
      <Col sm={3}>
        <Field
          name="UnitPrice"
          label={`${I18n.t('taskType.unitPrice')} *`}
          component={InputField}
          readOnly={readOnly}
          validate={[isPositiveOrZeroInteger, requiredField]}
        />
      </Col>
      <Col sm={3}>
        <Field
          name="UnitPriceCurrency"
          label={`${I18n.t('processType.currency')} *`}
          component={SelectField}
          options={currencies}
          labelField="Value"
          valueField="Key"
          validate={requiredField}
          disabled={readOnly}
        />
      </Col>
    </div>
  );
};

ProcessBasicFormSection.defaultProps = {
  readOnly: false,
};

ProcessBasicFormSection.propTypes = {
  readOnly: PropTypes.bool,
};

export default ProcessBasicFormSection;
