import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { userRoleWithUserAndRoleListSchema } from './userRoleSchemas';
import { callApiSagaAndNormalize } from '../sagaUtils';

import userRoleApi from '../../api/userRoleApi';
import * as userRoleTypes from './userRoleTypes';
import * as userRoleActions from './userRoleActions';

export default function* root() {
  yield all([takeLatest(userRoleTypes.USER_ROLE_LIST_FOR_ROLES.REQUEST, getUserRolesForRoleSaga)]);
}

function* getUserRolesForRoleSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { roles } = payload;
  yield callApiSagaAndNormalize(
    userRoleActions.listUserRoleForRoles,
    userRoleApi.getUserRoles,
    userRoleWithUserAndRoleListSchema,
    {
      IsUserActive: true,
      OptIncludeUser: true,
      OptIncludeRole: true,
      RoleTypes: roles,
    },
  );
  yield put(applicationActions.unsetLoading(type));
}
