import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import * as phraseTypes from './phraseTypes';
import * as rawMatPhraseTypes from '../rawMatPhrase/rawMatPhraseTypes';
import { productPhraseTypes } from '../productPhrase';

const byId = handleActions(
  {
    [combineActions(
      phraseTypes.PHRASE_LIST.SUCCESS,
      phraseTypes.GET_PHRASE.SUCCESS,
      // phraseTypes.SAVE_PHRASE.SUCCESS,
      productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS,
      rawMatPhraseTypes.FETCH_RAWMAT_PHRASES.SUCCESS,
    )]: (state, { payload }) => merge({}, state, payload.entities.phrases),
    [phraseTypes.SAVE_PHRASE.SUCCESS]: (state, {payload}) => {
      state[payload.result] = payload.entities.phrases[payload.result];
      return state;
    }
  },
  {},
);

const allIds = handleActions(
  {
    [phraseTypes.PHRASE_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(
      phraseTypes.SAVE_PHRASE.SUCCESS,
      phraseTypes.GET_PHRASE.SUCCESS,
    )]: (state, { payload }) =>  {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
    [combineActions(
      productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS,
      rawMatPhraseTypes.FETCH_RAWMAT_PHRASES.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.phrases) {
        return [...new Set([...state, ...Object.keys(payload.entities.phrases).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
