import React from 'react';
import CopyRawMatModal from './CopyRawMatModal';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import formNames from '../../../../constants/formNames';

const CopyRawMatModalContainer = props => <CopyRawMatModal {...props} />;

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.copyRM)),
});

export default connect(
  null,
  mapDispatchToProps,
)(CopyRawMatModalContainer);
