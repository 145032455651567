import { merge } from 'lodash';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as generalDashboardTypes from './generalDashboardTypes';

const byId = handleActions(
  {
    [generalDashboardTypes.GENERALDASHBOARD_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.generalDashboard),
  },
  {},
);

const allIds = handleActions(
  {
    [generalDashboardTypes.GENERALDASHBOARD_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
