import { createRequestTypes } from '../utils';

export const IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST = createRequestTypes(
  'impact_assessment/GENERATE_IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST',
);
export const IMPACT_ASSESSMENT_VALUE_LIST = createRequestTypes(
  'impact_assessment/GENERATE_IMPACT_ASSESSMENT_VALUE_LIST',
);
export const CALCULATE_IMPACT_ASSESSMENT_VALUE_LIST = createRequestTypes(
  'impact_assessment/GENERATE_IMPACT_ASSESSMENT_VALUE_LIST',
);export const GET_IMPACT_ASSESSMENT_VALUE_LIST = createRequestTypes(
  'impact_assessment/LOAD_IMPACT_ASSESSMENT_VALUE_LIST',
);
export const IMPACT_ASSESSMENT_PRODUCT_LIST = createRequestTypes('impact_assessment/LIST_IMPACT_ASSESSMENT_PRODUCTS');
export const NEW_IMPACT_ASSESSMENT_PRODUCT = 'impact_assessment/NEW_IMPACT_ASSESSMENT_PRODUCT';
export const SAVE_IMPACT_ASSESSMENT_PRODUCT = createRequestTypes('impact_assessment/SAVE_IMPACT_ASSESSMENT_PRODUCT');
export const GENERATE_IMPACT_ASSESSMENT_CANDIDATE_PRODUCTS = createRequestTypes(
  'impact_assessment/GENERATE_IMPACT_ASSESSMENT_CANDIDATE_PRODUCS',
);
export const CONFIRM_IMPACT_ASSESSMENT_PRODUCT = createRequestTypes(
  'impact_assessment/CONFIRM_IMPACT_ASSESSMENT_PRODUCT',
);
export const IGNORE_IMPACT_ASSESSMENT_PRODUCT = createRequestTypes(
  'impact_assessment/IGNORE_IMPACT_ASSESSMENT_PRODUCT',
);
export const REVIEW_IMPACT_ASSESSMENT_PRODUCT = createRequestTypes(
  'impact_assessment/REVIEW_IMPACT_ASSESSMENT_PRODUCT',
);
