import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
import { processActions, processTypes } from '../../../../redux/process';
import { orderActions } from '../../../../redux/order';
import ProcessModal from './ProcessModal';

const formName = formNames.process;
const isAnyTrue = R.any(R.equals(true));

class ProcessModalContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <ProcessModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isLoading: applicationSelectors.isLoading(state, processTypes.PROCESS.REQUEST),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('orderProcess.processModalTitle'),
  };
};

const mapDispatchToProps = (dispatch, { orderId, ProcessId }) => ({
  onLoad: () => {
    dispatch(processActions.process.request({ ProcessId }));
    dispatch(orderActions.listOrderProducts.request(orderId));
  },
  onSaveButtonClick: () => dispatch(submit(formName)),
});

ProcessModalContainer.propTypes = {
  processId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessModalContainer);
