import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  impactAssessmentProductActions,
  impactAssessmentProductTypes,
  impactAssessmentProductSelectors,
} from '../../../redux/impactAssessmentProduct';
import { applicationSelectors } from '../../../redux/application';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';

import ImpactAssessmentCandidateProductsTab from './ImpactAssessmentCandidateProductsTab';

class ImpactAssessmentCandidateProductsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    impactAssessmentId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.impactAssessmentId > 0) {
      if (this.props.currentImpactAssessmentProducts == undefined) {
        this.props.onLoad();
      }
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.impactAssessmentId !== this.props.impactAssessmentId && this.props.impactAssessmentId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ImpactAssessmentCandidateProductsTab {...this.props} />;
  }
}

const mapStateToProps = (state, { impactAssessmentId }) => ({
  workflowStatusNameById: workflowStatusSelectors.getById(state),
  currentImpactAssessmentProducts: impactAssessmentProductSelectors.getImpactAssessmentProductsForImpactAssessment(
    state,
  )(impactAssessmentId),
  requestingImpactAssessmentCandidateProductList: applicationSelectors.isLoading(
    state,
    impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST.REQUEST,
  ),
  requestingImpactAssessmentList: applicationSelectors.isLoading(
    state,
    impactAssessmentProductTypes.IMPACT_ASSESSMENT_VALUE_LIST.REQUEST,
  ),
  requestingFindCandidate: applicationSelectors.isLoading(
    state,
    impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { impactAssessmentId }) => ({
  onLoad: () => {
    dispatch(impactAssessmentProductActions.listImpactAssessmentProducts.request(impactAssessmentId))
  },
  onRefresh: () => dispatch(impactAssessmentProductActions.listImpactAssessmentProducts.request(impactAssessmentId)),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
  goToProductPage: () => dispatch(push({ pathname: '/products', query: { impactAssessmentId } })),
  calculateValues: () =>
    dispatch(impactAssessmentProductActions.calculateImpactAssessmentValues.request(impactAssessmentId)),
  getValues: () =>
    dispatch(impactAssessmentProductActions.getImpactAssessmentValues.request(impactAssessmentId)),
  onConfirmImpactAssessmentProductClick: ({ ImpactAssessmentProductId }) =>
    dispatch(
      impactAssessmentProductActions.confirmImpactAssessmentProduct.request({
        ImpactAssessmentProductId,
        impactAssessmentId,
      }),
    ),
  onIgnoreImpactAssessmentProductClick: ({ ImpactAssessmentProductId }) =>
    dispatch(impactAssessmentProductActions.ignoreImpactAssessmentProduct.request(ImpactAssessmentProductId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImpactAssessmentCandidateProductsContainer);
