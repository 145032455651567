import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, DateCellRenderer } from '../../Grids';
import dateComparator from '../../../../utils/dateComparator';

const TaskActionList = props => {
  const { taskActionList, isLoading, getTaskActionList, ...otherProps } = props;
  const columns = [
    {
      headerName: I18n.t('taskAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('taskAction.code'),
      field: 'Action.Code',
      width: 40,
    },
    {
      headerName: I18n.t('taskAction.description'),
      field: 'Action.Description',
      width: 150,
    },
    {
      headerName: I18n.t('taskAction.comment'),
      field: 'Comment',
    },
    {
      headerName: I18n.t('taskAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  return (
    <div>
      <SelectionGrid
        data={taskActionList}
        keyName="TaskActionId"
        columns={columns}
        isLoading={isLoading}
        onRefresh={getTaskActionList}
        showAddButton={false}
        {...otherProps}
      />
    </div>
  );
};

TaskActionList.propTypes = {
  taskActionList: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getTaskActionList: PropTypes.func.isRequired,
};

export default TaskActionList;
