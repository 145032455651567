import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';

// import other containers
// import ProductOrdersContainer from './ProductOrders';
import ImpactAssessmentDetailsContainer from './_ImpactAssessmentDetailsContainer';
import ImpactAssessmentCandidateProducts from './ImpactAssessmentCandidateProducts';
import ImpactAssessmentValues from './ImpactAssessmentValues';
// import ImpactAssessmentProducts from './ImpactAssessmentProducts';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const ImpactAssessmentDetails = props => {
  const impactAssessmentId = Number(props.params.id);
  return (
    <div className={styles.detailsContainer}>
      <ImpactAssessmentDetailsContainer impactAssessmentId={impactAssessmentId} {...props} />
      <Col sm={12}>
      <Tabs id="ImpactAssessmentTabs" mountOnEnter defaultActiveKey={2}>
        {/* <Tabs id="ImpactAssessmentTabs" mountOnEnter unmountOnExit defaultActiveKey={2}> */}
          <Tab eventKey={2} title={I18n.t('impactAssessment.resultTab')}>
            <ImpactAssessmentValues impactAssessmentId={impactAssessmentId} />
          </Tab>
          {/* <Tab eventKey={1} title={I18n.t('impactAssessment.notCalculatedProductTab')}>
            <ImpactAssessmentCandidateProducts impactAssessmentId={impactAssessmentId} />
          </Tab> */}
          {/* <Tab eventKey={2} title={I18n.t('impactAssessment.impactedProductTab')}> */}
          {/* <CompanyProducts companyId={companyId} {...props} /> */}
          {/* <ImpactAssessmentProducts impactAssessmentId={impactAssessmentId} /> */}
          {/* </Tab> */}
        </Tabs>
      </Col>
    </div>
  );
};

ImpactAssessmentDetails.defaultProps = {
  nbProdigumProductProportionChanged: 0,
  location: PropTypes.shape({
    query: {},
  }),
};

ImpactAssessmentDetails.propTypes = {
  nbProdigumProductProportionChanged: PropTypes.number,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      duplicateFrom: PropTypes.string,
    }),
  }),
};

export default ImpactAssessmentDetails;
