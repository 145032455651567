import _ from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { createAuthorizationCheck } from '../_common/selectors';
import orderAuthorizationCodes from '../../constants/orderAuthorizationCodes';
// import { createGeneralSelectors } from '../_common/selectors';

export const getOrderList = state => state.order.orderList;
// export const getOrderImportList = state => state.order.orderImportList;
export const makeGetOrderById = createSelector(getOrderList, orderList =>
  createSelector(R.identity, orderId => orderList.find(order => order.OrderId === orderId) || {}),
);

export const getCurrentOrder = state => state.order.currentOrder;
export const getCurrentOrderMissingDocuments = state => state.order.currentOrderMissingDocuments;
export const getCurrentOrderProducts = state => state.order.currentOrderProducts;
export const getCurrentOrderProcessInfo = state => state.order.currentOrderProcessInfo;

export const getProductsOwnedByCurrentOrderCompany = state => 
state.order.productsOwnedByCurrentOrderCompany;

const getAuthorizationCodes = R.propOr([], 'AuthorizationCodes');

const createMakeGetAuthorizationChek = createAuthorizationCheck(orderId => getCurrentOrder)(getAuthorizationCodes);

export const makeIsOrderEditable = createMakeGetAuthorizationChek([orderAuthorizationCodes.EDIT_ORDER]);

export const getAvailableProductsForCurrentOrder = createSelector(
  [getCurrentOrderProducts, getProductsOwnedByCurrentOrderCompany],
  (currentOrderProducts, productsOwnedByCurrentOrderCompany) =>
    _.differenceWith(
      productsOwnedByCurrentOrderCompany,
      currentOrderProducts,
      (arr1, arr2) => arr1.ProductId === arr2.ProductId,
    ),
);

export const getProductFormModalInitialValues = createSelector([getCurrentOrder], currentOrder => ({
  ProducerId: currentOrder.ProducerId,
}));

export const makeGetOrderByProducerId = createSelector(getOrderList, orderList =>
  createSelector(R.identity, producerId => orderList.filter(order => order.ProducerId === producerId)),
);
// const getOrderImportSlice = state => state.orderImportList;
// export const { getById, getIds, makeGetByQuery } = createGeneralSelectors(getOrderImportSlice);
