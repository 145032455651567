import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import SubstanceForm from './SubstanceForm';
import formNames from '../../constants/formNames';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { substanceActions, substanceTypes } from '../../redux/substance';
import { applicationSelectors } from '../../redux/application';
import { substanceActionActions, substanceActionTypes } from '../../redux/substanceAction';
import { substanceFormSelectors } from '../../redux/form/substanceForm';
import { substanceDocumentActions } from '../../redux/substanceDocument';
import {modalActions} from "../../redux/modal";

class substanceDetailsContainer extends Component {
  componentDidMount() {
    this.props.onLoadSubstance(this.props.substanceId);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoadSubstance(this.props.substanceId);
    }
  }

  render() {
    return <SubstanceForm {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId, location }) => {
  const showDetails = location.query.show_details === 'true';
  const getInitialValues = substanceFormSelectors.makeGetInitialValues(substanceId);
  const initialValues = getInitialValues(state);
  return {
    initialValues,
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      substanceTypes.SAVE_SUBSTANCE.REQUEST,
      substanceTypes.SUBSTANCE.REQUEST,
      substanceTypes.UPDATE_SUBSTANCE_STATUS.REQUEST,
      substanceActionTypes.FETCH_SUBSTANCE_ACTIONS.REQUEST,
    ),
    showDetails,
    isSusbstanceEditable: substanceFormSelectors.makeGetIsSubstanceEditable(substanceId)(state)
  };
};

const mapDispatchToProps = (dispatch, { location, substanceId }) => {
  const showDetails = location.query.show_details === 'true';
  return {
    onLoadSubstance: id => {
      if (id > 0) {
        dispatch(substanceActions.substance.request(id));
        dispatch(substanceActionActions.fetchSubstanceActions.request({ substanceId: id }));
      }
    },
    onSubmit: substanceActions.submitSubstanceForm,
    onShowDetails: () => dispatch(push({ ...location, query: { ...location.query, show_details: !showDetails } })),
    saveSubstanceStructure: data => {
      dispatch(substanceDocumentActions.uploadSubstanceStructure.request(substanceId, data));
    },
    onTranslateClick: () => dispatch(modalActions.showSubstanceTRModal({ substanceId })),
  };
};

const withData = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.substance);

export default compose(
  withData,
  withFormLeaveRouteDirtyWrapper,
)(substanceDetailsContainer);

substanceDetailsContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  onLoadSubstance: PropTypes.func.isRequired,
};
