import queryString from 'query-string';
import * as R from 'ramda';
import { labelReviewItemWizardSelectors } from './labelReviewItemWizard';
import { labelReviewInciWizardSelectors } from './labelReviewInciWizard';
import { labelReviewClaimWizardSelectors } from './labelReviewClaimWizard';
import { labelReviewSymbolWizardSelectors } from './labelReviewSymbolWizard';
import { labelSymbolWizardSelectors } from './labelSymbolWizard';
import { applicationSelectors } from '../../application';
import { labelReviewTypes } from '../../labelReview';
import { referentialsTypes } from '../../referentials';
import { getBaseType } from '../../utils';

export const getLabelReview = state => getLabelReviewWizardStateSlice(state).labelReview;
const getLabelReviewWizardStateSlice = state => state.labelReviewWizard;


export const getLabelReviewWithDetails = state => {
  const labelReview = getLabelReview(state);
  const labelReviewItems = labelReviewItemWizardSelectors.getEditedLabelReviewItemList(
    state,
    labelReview.LabelReviewId,
  );

  const labelReviewInci = labelReviewInciWizardSelectors.getLabelReviewInci(state);
  const labelReviewClaims = labelReviewClaimWizardSelectors.getLabelReviewClaimList(state);
  const labelSymbols = labelSymbolWizardSelectors.getPrivateLabelSymbolList(state);
  const labelReviewSymbols = labelReviewSymbolWizardSelectors
    .getLabelReviewSymbolList(state)
    .map(labelReviewSymbol => ({
      ...labelReviewSymbol,
      labelSymbol: labelSymbols.find(labelSymbol => labelSymbol.Code === labelReviewSymbol.SymbolCode),
    }));

  return {
    ...labelReview,
    labelReviewItems,
    labelReviewInci,
    labelReviewClaims,
    labelReviewSymbols,
  };
};

export const getDeletedLabelReviewClaims = state =>
  labelReviewClaimWizardSelectors.getLabelReviewClaimsDeletedIds(state);
export const getDeletedLavelReviewSymbols = state =>
  labelReviewSymbolWizardSelectors.getLabelReviewSymbolsDeletedIds(state);
export const getDeletedLabelSymbols = state => labelSymbolWizardSelectors.getLabelSymbolsDeletedCodes(state);

export const isLoadingWizard = state =>
  applicationSelectors.isLoading(
    state,
    referentialsTypes.ALL_REFERENTIALS.REQUEST,
    labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.REQUEST,
  );

export const getProductId = ({ labelReviewWizard }) => labelReviewWizard.meta.selectedProduct.ProductId;

export const getIsWizardLoading = (actionType, query) => state =>
  getLabelReviewWizardStateSlice(state).meta.loading[
    `${getBaseType(actionType)}/${queryString.stringify(R.omit(['include'])(query))}`
  ];
