import * as processSelectors from './processSelectors';
import * as processActions from './processActions';
import * as processTypes from './processTypes';
import * as processSchemas from './processSchemas';

import sagas from './processSagas';
import reducer from './processReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { processSelectors, processActions, processTypes, processSchemas };
export default reducer;
