import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import * as R from 'ramda';
import {SelectionGrid, LinkCellRenderer} from '../../_Common/Grids';
import WorkflowStatus from '../../../Hoc/WorkflowStatus';

const getYN = ({data, colDef}) => R.compose(boolToYN, R.propOr(false, colDef.field))(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const getNoLongerInMarket = ({data, colDef}) => {
  return data.IsNoLongerOnMarket ? 'yes' : '';
};

const DetailedProductList = props => {
  const {productList, isLoadingProductList, fetchProductList, onRowDoubleClicked} = props;
  return (
    <div>
      <WorkflowStatus
        render={workflowStatus => {
          const getWorkflowName = ({data, colDef}) =>
            R.compose(R.propOr('', 'Name'), R.find(R.propEq('Code', data[colDef.field])))(workflowStatus);

          const columns = [
            // {
            //   headerName: I18n.t('product.code'),
            //   field: 'Code',
            //   width: 75,
            //   suppressSizeToFit: true,
            //   sort: 'desc',
            // },
            {
              headerName: I18n.t('product.code'),
              sort: 'desc',
              field: 'Code',
              cellRendererFramework: LinkCellRenderer,
              width: 75,
              cellRendererParams: {
                textKey: 'Code',
                linkKey: 'Url',
                downloadable: false
              },
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.productName'),
              field: 'ProductName',
              width: 200,
              minWidth: 200,
              // suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.NumOfShades'),
              width: 65,
              field: 'ShadeCount',
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.CompoStatus'),
              width: 100,
              field: 'CompositionStatus',
              valueGetter: getWorkflowName,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.CPSRAStatus'),
              width: 100,
              field: 'CpsrAStatus',
              valueGetter: getWorkflowName,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.CPSRBStatus'),
              width: 100,
              field: 'CpsrBStatus',
              valueGetter: getWorkflowName,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.CPSRBType'),
              width: 100,
              field: 'CpsrBConclusionStatus',
              valueGetter: getWorkflowName,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.isLabelApprovalDone'),
              width: 100,
              field: 'IsLabelApprovalDone',
              valueGetter: getYN,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.partAToDo'),
              width: 100,
              field: 'IsCpsrAToDo',
              valueGetter: getYN,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.IsNotified'),
              width: 100,
              field: 'IsNotified',
              valueGetter: getYN,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.IsNoLongerOnMarket'),
              width: 100,
              field: 'IsNoLongerOnMarket',
              valueGetter: getNoLongerInMarket,
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.dl'),
              width: 100,
              field: 'dl',
              suppressSizeToFit: true,
            },
            {
              headerName: I18n.t('product.CpnpCat3'),
              width: 100,
              field: 'CpnpCat3',
              suppressSizeToFit: true,
            },
          ];

          productList.forEach(element => {
            element.Url = '#products/' + element.ProductId;
          });

          return (
            <SelectionGrid
              data={productList}
              keyName="ProductId"
              columns={columns}
              isLoading={isLoadingProductList}
              onRefresh={fetchProductList}
              showAddButton={false}
              rowDoubleClicked={onRowDoubleClicked}
            />
          );
        }}
      />
    </div>
  );
};

export default DetailedProductList;

DetailedProductList.defaultProps = {
  productList: [],
};

DetailedProductList.propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      ProductId: PropTypes.number.isRequired,
    }).isRequired,
  ),
  isLoadingProductList: PropTypes.bool.isRequired,
  fetchProductList: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
};
