import { createRequestTypes } from '../utils';

export const SAVE_SUBSTANCE_DOCUMENT = createRequestTypes('substanceDocument/SAVE_SUBSTANCE_DOCUMENT');
export const SUBSTANCE_DOCUMENT_LIST = createRequestTypes('substanceDocument/SUBSTANCE_DOCUMENT_LIST');
export const UPLOAD_SUBSTANCE_DOCUMENT = createRequestTypes('substanceDocument/UPLOAD_SUBSTANCE_DOCUMENT');
export const UPLOAD_SUBSTANCE_STRUCTURE = createRequestTypes('substanceDocument/UPLOAD_SUBSTANCE_STRUCTURE');
export const SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE = createRequestTypes(
  'substanceDocument/SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE',
);
export const DELETE_SUBSTANCE_DOCUMENT = createRequestTypes('substanceDocument/DELETE_SUBSTANCE_DOCUMENT');
export const SUBSTANCE_STRUCTURE = createRequestTypes('substanceDocument/SUBSTANCE_STRUCTURE');
