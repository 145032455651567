import { takeLatest, put, all, call } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import processTypeApi from '../../api/processTypeApi';
import * as processTypeTypes from './processTypeTypes';
import * as processTypeActions from './processTypeActions';
import * as processTypeSchemas from './processTypeSchemas';
import { modalActions, modalTypes } from '../modal';
import { normalize } from 'normalizr';

export default function* root() {
  yield all([
    takeLatest(processTypeTypes.PROCESSTYPE_LIST.REQUEST, getProcessTypeListSaga),
    takeLatest(processTypeTypes.PROCESSTYPEREF_LIST.REQUEST, getProcessTypeRefListSaga),
    takeLatest([processTypeTypes.ADD_PROCESSTYPE, processTypeTypes.EDIT_PROCESSTYPE], onAddEditProcessTypeSaga),
    takeLatest(processTypeTypes.SAVE_PROCESSTYPE.REQUEST, saveProcessTypeSaga),
  ]);
}

function* onAddEditProcessTypeSaga({ payload }) {
  yield put(
    modalActions.showModal({ modalType: modalTypes.PROCESSTYPE_FORM, modalProps: { ...payload, modalWidth: '800px' } }),
  );
}

export function* getProcessTypeListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const processTypes = yield call(processTypeApi.getProcessTypeList, payload);
    const normalizedData = normalize(processTypes, processTypeSchemas.processTypeListSchema);
    yield put(processTypeActions.listProcessTypes.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(processTypeActions.listProcessTypes.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

export function* saveProcessTypeSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedProcessType = yield callApiSaga(processTypeActions.saveProcessType, processTypeApi.saveProcessType, payload);
  yield put(processTypeActions.listProcessTypeRefs.request());
  yield put(processTypeActions.listProcessTypes.request());
  yield put(applicationActions.unsetLoading(type));
  return savedProcessType;
}

export function* getProcessTypeRefListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(processTypeTypes.PROCESSTYPEREF_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    processTypeActions.listProcessTypeRefs,
    processTypeApi.getProcessTypeRefList,
    processTypeSchemas.processTypeRefListSchema,
  );
  yield put(applicationActions.unsetLoading(processTypeTypes.PROCESSTYPEREF_LIST.REQUEST));
}
