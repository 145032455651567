import queryString from 'query-string';
import { get, del, post } from './apiUtils';

const defaultUrl = '/SubstancesFunctions/';

export default {
  getSubstanceFunctions: ({ id, query } = {}) => get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  deleteSubstanceFunction: id => del(`${defaultUrl}${id}`),
  saveSubstanceFunction: data => post(`${defaultUrl}`, data),
};
