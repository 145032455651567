import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../../_Common/Forms';
import { companySelectors, companyActions } from '../../../../redux/company';
import { CompanyListDropdown } from '../../../_Common/Dropdowns';
import formNames from '../../../../constants/formNames';
import { compose } from 'recompose';

const formName = formNames.copyRM;

const CopyRawMatForm = props => {
  const { onSubmit, handleSubmit, companyList } = props;

  console.log('companyList', companyList);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <Field
              name="companySourceName"
              label={I18n.t('modalCopyRawMat.companySource')}
              component={InputField}
              readOnly
            />
            <Field
              name="companyDestinationId"
              label={I18n.t('modalCopyRawMat.companyDestination')}
              component={CompanyListDropdown}
              initialOptions={companyList}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { companyId }) => {
  const company = companySelectors.getById(state)[companyId];
  const getCompanyList = companySelectors.makeGetCompanyPrivateRMListExceptSpecific(companyId);
  const initialValues = {
    companySourceId: companyId,
    companySourceName: company.ShortName,
  };
  return {
    initialValues,
    companyList: getCompanyList(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(companyActions.submitCopyRmAction.request({ ...data })),
});

CopyRawMatForm.defaultProps = {
  initialValues: {},
};

CopyRawMatForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(CopyRawMatForm);
