import { takeLatest, put, takeEvery, select, all, take, call } from 'redux-saga/effects';
import { replace, LOCATION_CHANGE } from 'react-router-redux';
import * as R from 'ramda';
import { createSaveFormWithValidation } from '../form/utils';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import orderApi from '../../api/orderApi';
import orderProductApi from '../../api/orderProductApi';
import productApi from '../../api/productApi';
import * as orderTypes from './orderTypes';
import * as orderActions from './orderActions';
import * as orderSelectors from './orderSelectors';
import { orderProductActions, orderProductTypes } from '../orderProduct';
import { orderFormSelectors } from '../form/orderForm';
import { orderActionActions } from '../orderAction';
import { modalActions, modalTypes, modalSelectors } from '../modal';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(orderTypes.ORDER_PRODUCT_LIST.REQUEST, getOrderProductListSaga),
    takeLatest(orderTypes.ORDER_LIST.REQUEST, getOrderListSaga),
    takeLatest(orderTypes.ORDER.REQUEST, getOrderSaga),
    takeLatest(orderTypes.ORDER_MISSING_DOCUMENT_LIST.REQUEST, getOrderMissingDocumentListSaga),
    takeLatest(orderTypes.ORDER_PROCESS_INFO.REQUEST, getOrderProcessInfoSaga),
    takeLatest(orderTypes.SAVE_ORDER.REQUEST, saveOrderSaga),
    takeLatest(orderTypes.DELETE_ORDER.REQUEST, deleteOrderSaga),
    takeLatest(orderTypes.SAVE_ORDER_PROCESS_INFO.REQUEST, saveOrderProcessInfoSaga),
    takeLatest(orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY.REQUEST, getProductsOwnedByOrderCompanySaga),
    takeLatest(orderTypes.SAVE_ORDER_PRODUCTS.REQUEST, saveOrderProductsSaga),
    takeLatest(orderTypes.ADD_PRODUCT_TO_CURRENT_ORDER.REQUEST, addProductToCurrentOrderSaga),
    takeLatest(orderTypes.SAVE_PRODUCT_AND_ADD_TO_CURRENT_ORDER.REQUEST, saveProductAndAddToCurrentOrderSaga),
    takeLatest(orderTypes.REMOVE_PRODUCT_FROM_ORDER, removeProductFromOrderSaga),
    takeLatest(orderTypes.UPDATE_ORDER_STATUS.REQUEST, updateOrderStatusSaga),
    takeLatest(orderActions.submitOrderForm.REQUEST, submitOrderFormSaga),
  ]);
}

const submitOrderFormSaga = createSaveFormWithValidation(orderActions.submitOrderForm, saveOrderSaga);

const createDuplicate = R.evolve({
  OrderId: () => -1,
  OrderNr: () => null,
  CreatedOn: () => null,
  CreatedBy: () => null,
  Status: () => null,
  ActualProductCount: () => null,
  NotifiedProductCount: () => null,
  StartDate: () => null,
  EndDate: () => null,
});

function* onLocationChange(action) {
  if (action.payload.pathname === '/orders/-1') {
    const duplicateFromId = Number(yield select(orderFormSelectors.getDuplicateFromId));
    if (duplicateFromId) {
      const currentOrderToDuplicate = yield select(orderSelectors.getCurrentOrder);
      yield put(orderActions.newOrder(createDuplicate(currentOrderToDuplicate)));
    } else {
      yield put(orderActions.newOrder());
    }
  }
}

export function* updateOrderStatusSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { WorkflowStatusCode } = payload;
  try {
    const OrderId = yield call(orderApi.updateStatus, payload);
    yield put(orderActions.updateStatus.success({ OrderId, WorkflowStatusCode }));
    const modalType = yield select(modalSelectors.getModalType);
    if (modalType === modalTypes.ORDER_STATUS_COMMENT) {
      yield put(modalActions.hideModal());
    }
    if (OrderId !== undefined) {
      yield put(orderActions.order.request(OrderId));
      yield put(orderActionActions.fetchOrderActions.request({ OrderId }));
    }
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(orderActions.updateStatus.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* removeProductFromOrderSaga({ payload }) {
  const { ProductId, OrderId } = payload;
  yield put(orderProductActions.deleteOrderProduct.request(ProductId, OrderId));
  yield take(orderProductTypes.DELETE_ORDER_PRODUCT.SUCCESS);
  yield put(orderActions.listOrderProducts.request(OrderId));
}

function* getOrderProductListSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.ORDER_PRODUCT_LIST.REQUEST));
  yield callApiSaga(orderActions.listOrderProducts, orderApi.getOrderProductList, action.payload);
  yield put(applicationActions.unsetLoading(orderTypes.ORDER_PRODUCT_LIST.REQUEST));
}

function* getProductsOwnedByOrderCompanySaga(action) {
  yield put(applicationActions.setLoading(orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY.REQUEST));
  yield callApiSaga(
    orderActions.listProductsOwnedByOrderCompany,
    orderApi.getProductsOwnedByOrderCompany,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY.REQUEST));
}

function* getOrderListSaga() {
  yield put(applicationActions.setLoading(orderTypes.ORDER_LIST.REQUEST));
  yield callApiSaga(orderActions.listOrders, orderApi.getOrderList);
  yield put(applicationActions.unsetLoading(orderTypes.ORDER_LIST.REQUEST));
}

function* getOrderSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.ORDER.REQUEST));
  yield callApiSaga(orderActions.order, orderApi.getOrder, action.payload);
  yield put(applicationActions.unsetLoading(orderTypes.ORDER.REQUEST));
}

function* getOrderProcessInfoSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.ORDER_PROCESS_INFO.REQUEST));
  yield callApiSaga(orderActions.orderProcessInfo, orderApi.getOrderProcessInfo, action.payload);
  yield put(applicationActions.unsetLoading(orderTypes.ORDER_PROCESS_INFO.REQUEST));
}

function* getOrderMissingDocumentListSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.ORDER_MISSING_DOCUMENT_LIST.REQUEST));
  yield callApiSaga(orderActions.orderMissingDocumentList, orderApi.getOrderMissingDocumentList, action.payload.orderId);
  yield put(applicationActions.unsetLoading(orderTypes.ORDER_MISSING_DOCUMENT_LIST.REQUEST));
}

function* deleteOrderSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.DELETE_ORDER.REQUEST));
  try {
  yield callApiSaga(orderActions.deleteOrder, orderApi.deleteOrder, action.payload);
  yield put(replace('/orders/'));
  yield put(orderActions.listOrders.request());
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(orderTypes.DELETE_ORDER.REQUEST));
  }
}

function* saveOrderSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.SAVE_ORDER.REQUEST));
  try {
    const id = yield callApiSaga(orderActions.saveOrder, orderApi.saveOrder, action.payload);
    if (id) {
      yield put(replace(`/orders/${id}`));
      yield put(orderActions.order.request(id));
      yield put(orderActions.listOrders.request());
      yield put(orderActions.listProductsOwnedByOrderCompany.request(id));
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(orderTypes.SAVE_ORDER.REQUEST));
  }
}
function* saveOrderProcessInfoSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.SAVE_ORDER_PROCESS_INFO.REQUEST));
  try {
    const id = yield callApiSaga(orderActions.saveOrderProcessInfo, orderApi.saveOrderProcessInfo, action.payload);
    yield put(orderActions.orderProcessInfo.request(id));
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(orderTypes.SAVE_ORDER_PROCESS_INFO.REQUEST));
  }
}

function* saveProductAndAddToCurrentOrderSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const currentOrder = yield select(state => orderSelectors.getCurrentOrder(state));
  const { ProducerId } = currentOrder;

  const ProductId = yield callApiSaga(orderActions.saveProductAndAddToCurrentOrder, productApi.saveProduct, {
    ...payload,
    ProducerId,
  });
  yield put(orderActions.addProductToCurrentOrder.request(ProductId));
  yield put(modalActions.hideModal({ modalType: 'PRODUCT_FORM' }));
  yield put(applicationActions.unsetLoading(type));
}

function* saveOrderProductsSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.SAVE_ORDER_PRODUCTS.REQUEST));
  yield callApiSaga(orderActions.saveOrderProducts, orderProductApi.saveOrderProducts, action.payload);
  const OrderId = yield select(state => orderSelectors.getCurrentOrder(state).OrderId);
  yield put(orderActions.listOrderProducts.request(OrderId));
  yield put(applicationActions.unsetLoading(orderTypes.SAVE_ORDER_PRODUCTS.REQUEST));
}

function* addProductToCurrentOrderSaga(action) {
  yield put(applicationActions.setLoading(orderTypes.ADD_PRODUCT_TO_CURRENT_ORDER.REQUEST));
  const OrderId = yield select(state => orderSelectors.getCurrentOrder(state).OrderId);
  const { payload } = action;
  const data = {
    ProductId: payload,
    OrderId,
  };
  yield callApiSaga(orderActions.addProductToCurrentOrder, orderProductApi.saveOrderProducts, [data]);
  yield put(orderActions.listOrderProducts.request(OrderId));
  yield put(orderActions.order.request(OrderId));
  yield put(applicationActions.unsetLoading(orderTypes.ADD_PRODUCT_TO_CURRENT_ORDER.REQUEST));
}
