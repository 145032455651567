import * as mandateSelectors from './mandateSelectors';
import * as mandateActions from './mandateActions';
import * as mandateTypes from './mandateTypes';
import * as mandateSchemas from './mandateSchemas';
import sagas from './mandateSagas';
import reducer from './mandateReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  mandateSelectors,
  mandateActions,
  mandateTypes,
  mandateSchemas,
};
export default reducer;
