import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import ProductCategoriesTab from './ProductCategoriesTab';
import { modalActions } from '../../../redux/modal';
import {productCategoryActions, productCategorySelectors, productCategoryTypes} from "../../../redux/productCategory";

class ProductCategoriesContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ProductCategoriesTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  return ({
    currentProductCategories: productCategorySelectors.getProductCategoryByProductId(state, productId),
    isLoading: applicationSelectors.isLoading(state, productCategoryTypes.PRODUCT_CATEGORY_LIST.REQUEST),
  });
}

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(productCategoryActions.listProductCategories.request(productId));
  },
  getProductCategories: () => dispatch(productCategoryActions.listProductCategories.request(productId)),
  onAddButtonClick: () => {
    dispatch(
      modalActions.showProductCategoryFormModal({
        productId,
      }),
    )
  },
  onEditButtonClick: ({ ProductCategoryId: productCategoryId }) => {
    dispatch(
      modalActions.showProductCategoryFormModal({
        productId,
        productCategoryId,
      }),
    )
  },
  onTranslateButtonClick: ({ ProductCategoryId }) => {
    dispatch(
      modalActions.showProductCategoryTranslationFormModal({
        ProductCategoryId,
      }),
    )
  },
  onDeleteButtonClick: ({ ProductCategoryId: productCategoryId }) => {
    dispatch(productCategoryActions.deleteProductCategory.request({ productCategoryId, productId })
    )
  },
  onMarkButtonClick: ({ ProductCategoryId: productCategoryId }) => {
    dispatch(productCategoryActions.markProductCategoryAsPrincipal.request( { productCategoryId, productId })
    )
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoriesContainer);
