import { get } from './apiUtils';

const defaultUrl = '/DashboardTypes/';
const resultUrl = '/Results/';

export default {
  getDashboardResults: id => get(`${defaultUrl}${id}${resultUrl}`),
  getDashboardList: () => get(defaultUrl),
  getDashboard: id => get(`${defaultUrl}${id}`),
};
