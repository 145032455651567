import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import RawMatPageContainer from './_RawMatsPageContainer';
import BasicDetailContainer from '../_Common/BasicDetailContainer';

const RawMats = ({ children, ...otherProps }) => (
  <Grid fluid>
    <Row>
      <Col md={3}>
        <RawMatPageContainer {...otherProps} />
      </Col>
      <Col md={9}>
        <BasicDetailContainer>{children && children}</BasicDetailContainer>
      </Col>
    </Row>
  </Grid>
);

RawMats.propTypes = {
  children: PropTypes.node,
};

export default RawMats;
