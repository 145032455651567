import * as taskActionSelectors from './taskActionSelectors';
import * as taskActionActions from './taskActionActions';
import * as taskActionTypes from './taskActionTypes';
import sagas from './taskActionSagas';
import reducer from './taskActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { taskActionSelectors, taskActionActions, taskActionTypes };
export default reducer;
