import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardDetails from './DashboardDetails';
import { dashboardTypes, dashboardActions, dashboardSelectors } from '../../redux/dashboard';
import { applicationSelectors } from '../../redux/application';
//import { modalActions } from '../../redux/modal';

class DashboardDetailsContainer extends Component {
  static propTypes = {
    onLoadDashboard: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    if (Number(this.props.params.id) > 0) {
      this.props.onLoadDashboard(this.props.params.id);
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.params.id !== this.props.params.id && Number(this.props.params.id) > 0) {
      this.props.onLoadDashboard(this.props.params.id);
    }
  }

  render() {
    const dashboardTypeId = Number(this.props.params.id);
    const dashboardTitle = this.props.dashboardTitle;
    return <DashboardDetails dashboardTypeId={dashboardTypeId} dashboardTitle={dashboardTitle} {...this.props} />;
  }
}

function mapStateToProps(state, { params: { id: dashboardId } }) {
  return {
    requestingCurrentDashboard: applicationSelectors.isLoading(state, dashboardTypes.DASHBOARD.REQUEST),
    initialValues: dashboardSelectors.getDashboard(state, dashboardId),
    dashboardTitle: dashboardSelectors.dashboardTitle(state),
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    onLoadDashboard: id => {
      dispatch(dashboardActions.getDashboardResults.request(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetailsContainer);
