import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { createRequestActions, createFetchAction } from '../utils';
import * as substanceTypes from './substanceTypes';

export const submitSubstanceForm = createFormAction(substanceTypes.SUBMIT_SUBSTANCE_FORM);

export const listSubstances = createRequestActions(substanceTypes.SUBSTANCE_LIST);
export const fetchSubstances = createFetchAction(substanceTypes.FETCH_SUBSTANCES, 'SubstanceId');
export const substance = createRequestActions(substanceTypes.SUBSTANCE);
export const saveSubstance = createRequestActions(substanceTypes.SAVE_SUBSTANCE);
export const listSubstanceProportions = createRequestActions(substanceTypes.SUBSTANCE_PROPORTIONS);
export const newSubstance = createAction(substanceTypes.NEW_SUBSTANCE);

export const updateStatus = createRequestActions(substanceTypes.UPDATE_SUBSTANCE_STATUS, data => ({
  ...data,
  WorkflowCode: 'SUBSTANCE',
}));
export const checkIfSubstanceloaded = createRequestActions(substanceTypes.ChECK_IF_SUBSTANCE_LOADED);
