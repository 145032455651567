import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar, Tabs, Tab } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../../_Common/BaseLoader';
import AcuteRepeatToxicityForm from './AcuteRepeatToxicityForm';
import IrritationAndPhototoxForm from './IrritationAndPhototoxForm';
import OtherInfoForm from './OtherInfoForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
    '& .tab-content': {
      marginTop: '15px',
    },
    '& textarea.form-control.input-sm': {
      height: '60px',
    },
  }),
};

const SubstanceToxProfileModal = props => {
  const { title, substanceId, hasErrors, isLoading, onSaveButtonClick } = props;
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <div className={styles.modalBody}>
        <Modal.Body>
          <BaseLoader isLoading={isLoading}>
            <Tabs defaultActiveKey={1} id="controlled-tab-example">
              <Tab eventKey={1} title={I18n.t('substanceToxicologicalProfile.AcuteAndRepeatToxicity')}>
                <AcuteRepeatToxicityForm substanceId={substanceId} />
              </Tab>
              <Tab eventKey={2} title={I18n.t('substanceToxicologicalProfile.IrritationAndPhototox')}>
                <IrritationAndPhototoxForm substanceId={substanceId} />
              </Tab>
              <Tab eventKey={3} title={I18n.t('substanceToxicologicalProfile.OtherInfo')}>
                <OtherInfoForm substanceId={substanceId} />
              </Tab>
            </Tabs>
          </BaseLoader>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={props.closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button bsSize="small" bsStyle="success" type="button" onClick={onSaveButtonClick} disabled={hasErrors}>
              <Translate value="general.save" />&nbsp;
              <i className="fa fa-arrow-circle-right" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

SubstanceToxProfileModal.defaultProps = {
  title: '',
};

SubstanceToxProfileModal.propTypes = {
  title: PropTypes.string,
  hasErrors: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  substanceId: PropTypes.number.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  // isLoading: PropTypes.bool.isRequired,
  // isFormInValid: PropTypes.bool.isRequired,
  // isFormPristine: PropTypes.bool.isRequired,
};

export default SubstanceToxProfileModal;
