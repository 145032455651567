import { call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit, SubmissionError } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { curry, lensPath, set, last } from 'ramda';

function* saveForm(formName, saveEntity, action) {
  yield put(startSubmit(formName));
  yield call(saveEntity, action);
  yield put(stopSubmit(formName));
}

// const transformToTraduction = (value, fieldName) => I18n.t(`form.${value}`, { fieldName });

function saveFormWithValidation(submitFormAction, saveEntitySaga, { onSuccess = () => {} } = {}) {
  return function* innerFn(action) {
    try {
      const result = yield call(saveEntitySaga, { ...action });
      yield put(submitFormAction.success());
      yield onSuccess(result, action.payload);
    } catch (error) {
      if (error.Errors) {
        // const errors = mapObjIndexed(transformToTraduction, error.Errors);
        const errors = Object.entries(error.Errors).reduce((acc, err) => {
          const [key, value] = err;
          const splittedKey = key.split('.');
          const lens = lensPath(splittedKey);
          const translattedValue = I18n.t(`form.${value}`, { fieldName: last(splittedKey) });
          return set(lens, translattedValue)(acc);
        }, {});

        const formError = new SubmissionError({
          ...errors,
        });
        yield put(submitFormAction.failure(formError));
      }
    }
  };
}
export const createSaveForm = curry(saveForm);
export const createSaveFormWithValidation = curry(saveFormWithValidation);
