import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, Row, Col} from 'react-bootstrap';
import {orderImportActions, orderImportTypes, orderImportSelectors} from '../../../redux/orderImport';
import {applicationSelectors} from '../../../redux/application';
import OrderImportList from './OrderImportList';

class OrderImportListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col md={12}>
            <OrderImportList {...this.props} />;
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: applicationSelectors.isLoading(
      state,
      orderImportTypes.ORDER_IMPORT_LIST.REQUEST,
    ),
    orderImportList: orderImportSelectors.getOrderImportList(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    // dispatch(orderImportActions.listOrderImports.request());
  },
  getOrderImportList: () => dispatch(orderImportActions.listOrderImports.request()),
  onInitializePolling: () => {
    dispatch(orderImportActions.initializePolling.request());
  },
  onPoll: () => dispatch(orderImportActions.poll.request()),
  // onGoToHangfire: () => history.push('/hangfire'),
  onGoToHangfire: () => dispatch(orderImportActions.goToHangfire.request()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderImportListContainer);

OrderImportListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
