import React, { Component } from 'react';
import { Grid, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import PlannerSearchList from './PlannerSearchList';
import OrderImportListContainer from './OrderImportList/_OrderImportListContainer';
import WorkDashboardContainer from './WorkDashboard/_WorkDashboardContainer';
import userRoleTypes from '../../constants/userRoleTypes';
import { userSelectors } from '../../redux/user';
import { userRoleActions } from '../../redux/userRole';
import { applicationSelectors } from '../../redux/application';
import { companySelectors, companyActions } from '../../redux/company';
import { orderActions, orderSelectors } from '../../redux/order';
import { orderImportActions, orderImportSelectors } from '../../redux/orderImport';
import { orderItemSelectors, orderItemActions, orderItemTypes } from '../../redux/orderItem';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { referentialSelectors } from '../../redux/referentials';

class PlannerDashboardContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Tabs id="PlannerDashboardTabs" defaultActiveKey={1}>
              <Tab eventKey={1} title={I18n.t('plannerDashboard.title')}>
                <PlannerSearchList {...this.props} />
              </Tab>
              <Tab eventKey={2} title={I18n.t('orderImportDashboard.title')}>
                <OrderImportListContainer {...this.props} />
              </Tab>
              <Tab eventKey={3} title={I18n.t('workDashboard.title')}>
                <WorkDashboardContainer {...this.props} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  orderPlannerList: orderItemSelectors.getOrderPlannerDashboard(state),
  isLoading: applicationSelectors.isLoading(state, orderItemTypes.ORDER_PLANNER_LIST.REQUEST),
  companyList: companySelectors.getCompanyList(state),
  expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
  accountManagerList: userSelectors.getUsersByRole(state, userRoleTypes.customerSupport),
  orderList: orderSelectors.getOrderList(state),
  currencies: referentialSelectors.getCurrencies(state),
});

const mapDispatchToProps = (dispatch, state) => ({
  onLoad: () => {
    dispatch(companyActions.listCompanies.request());
    dispatch(userRoleActions.listUserRoleForRoles.request([userRoleTypes.expert, userRoleTypes.customerSupport]));
    dispatch(orderActions.listOrders.request());
    dispatch(orderImportActions.listOrderImports.request());
  },
  search: payload => dispatch(orderItemActions.orderPlannerList.request(payload)),
  onRowDoubleClicked: order => dispatch(push(`/orders/${order.OrderId}`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlannerDashboardContainer);
