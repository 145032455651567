import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as impactAssessmentThresholdTypes from './impactAssessmentThresholdTypes';

export const listImpactAssessmentThresholds = createRequestActions(
  impactAssessmentThresholdTypes.IMPACT_ASSESSMENT_THRESHOLD_LIST,
);
export const saveImpactAssessmentThreshold = createRequestActions(
  impactAssessmentThresholdTypes.SAVE_IMPACT_ASSESSMENT_THRESHOLD,
);
export const deleteImpactAssessmentThreshold = createRequestActions(
  impactAssessmentThresholdTypes.DELETE_IMPACT_ASSESSMENT_THRESHOLD,
);
export const newImpactAssessmentThreshold = createAction(
  impactAssessmentThresholdTypes.NEW_IMPACT_ASSESSMENT_THRESHOLD,
);
export const addImpactAssessmentThreshold = createAction(
  impactAssessmentThresholdTypes.ADD_IMPACT_ASSESSMENT_THRESHOLD,
  ImpactAssessmentId => ({
    ImpactAssessmentId,
  }),
);
export const editImpactAssessmentThreshold = createAction(
  impactAssessmentThresholdTypes.EDIT_IMPACT_ASSESSMENT_THRESHOLD,
  ImpactAssessmentId => ({
    ImpactAssessmentId,
  }),
);
