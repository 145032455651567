import PropTypes from 'prop-types';
import React from 'react';
import ProductCategoryList from './ProductCategoryList';

const CategoriesTab = props => {

  return (
    <div>
      <ProductCategoryList {...props}
      />
    </div>
  );
};


CategoriesTab.propTypes = {
  currentProductCategories: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  getProductCategories: PropTypes.func.isRequired,
};

export default CategoriesTab;
