export default {
  formulaAcceptance: {
    name: 'formulaAcceptance',
    fileName: 'Final INCI List.pdf',
    row: 0,
  },
  cpsra: {
    name: 'cpsra',
    fileName: 'Cosmetic Product Safety TEST Report - Part A.pdf',
    row: 1,
  },
  cpsraconfidential: {
    name: 'cpsraconfidential',
    fileName: 'Cosmetic Product Safety Report - Confidential - Part A.pdf',
    row: 1,
  },
  // cpsraconfidentialnew: {
  //   name: 'cpsraconfidentialnew',
  //   fileName: 'Cosmetic Product Safety Report - Confidential - Part A.pdf',
  //   row: 1,
  // },
  cpsraByShade: {
    name: 'cpsraByShade',
    fileName: 'Cosmetic Product Safety Report (by Shade) - Part A.pdf',
    row: 1,
  },
  cpsrAByShadeConfidential: {
    name: 'cpsrAByShadeConfidential',
    fileName: 'Cosmetic Product Safety Report (by Shade) - Confidential - Part A.pdf',
    row: 1,
  },
  cpsraNew: {
    name: 'cpsraNew',
    fileName: 'Cosmetic Product Safety Report - Part A.pdf',
    row: 1,
  },
  cpsrb: {
    name: 'cpsrb',
    fileName: 'Cosmetic Product Safety Report - Part B.pdf',
    row: 2,
  },
  cpsrbconfidential: {
    name: 'cpsrbconfidential',
    fileName: 'Cosmetic Product Safety Report - Confidential - Part B.pdf',
    row: 2,
  },
  cpsrbByShade: {
    name: 'cpsrbByShade',
    fileName: 'Cosmetic Product Safety Report (by Shade) - Part B.pdf',
    row: 2,
  },
  cpsrbByShadeConfidential: {
    name: 'cpsrbByShadeConfidential',
    fileName: 'Cosmetic Product Safety Report (by Shade) - Confidential - Part B.pdf',
    row: 2,
  },
  cpsrbNew: {
    name: 'cpsrbNew',
    fileName: 'Cosmetic Product Safety Report - Part B.pdf',
    row: 2,
  },
  closingLetter: {
    name: 'closingLetter',
    fileName: 'RegulatoryStudyClosure.pdf',
    row: 4,
  },
  preval: {
    name: 'preval',
    fileName: 'Cosmetic Product Regulatory and Toxicological Compliance Study Report.pdf',
    row: 3,
  },
  prevalNew: {
    name: 'prevalNew',
    fileName: 'Cosmetic Product Regulatory and Toxicological Compliance Study Report.pdf',
    row: 3,
  },
  cpsrbWithAnnexes: {
    name: 'cpsrbWithAnnexes',
    fileName: 'CpsrbWithAnnexes.pdf',
    row: 3,
  },
  cpsrbWithAnnexesNew: {
    name: 'cpsrbWithAnnexesNew',
    fileName: 'CpsrbWithAnnexes.pdf',
    row: 3,
  },
  inciNames: {
    name: 'inciNames',
    fileName: 'InciNames.pdf',
    row: 3,
  },
  complianceCertificate: {
    name: 'complianceCertificate',
    fileName: 'Compliance Certificate.pdf',
    row: 6,
  },
  efficacyAssessmentSummary: {
    name: 'efficacyAssessmentSummary',
    fileName: 'Efficacy Assessment Summary.pdf',
    row: 6,
  },
  efficacyAssessment: {
    name: 'efficacyAssessment',
    fileName: 'Efficacy Assessment.pdf',
    row: 6,
  },
  productCpnp: {
    name: 'productCpnp',
    fileName: 'Product CPNP Composition.pdf',
    row: 5,
  },
  quantitativeQualitativeCompo: {
    name: 'quantitativeQualitativeCompo',
    fileName: 'Quantitative / Qualitative Compo.pdf',
    row: 5,
  },
  productToxProfile: {
    name: 'productToxProfile',
    fileName: 'Product Tox Profile.pdf',
    row: 7,
  },
  safetyAssessmentSummary: {
    name: 'safetyAssessmentSummary',
    fileName: 'Safety Assessment Summary.pdf',
    row: 7,
  },
  compoFormulaReview: {
    name: 'compoFormulaReview',
    fileName: 'Compo Formula Review.pdf',
  },
  quickFormulaReview: {
    name: 'quickFormulaReview',
    fileName: 'Quick Formula Review.pdf',
  },
  formulaReview: {
    name: 'formulaReview',
    fileName: 'Formula Review.pdf',
  },
  labelReview: {
    name: 'labelReview',
    fileName: 'Label Review.pdf',
  },
  draftLabel: {
    name: 'draftLabel',
    fileName: 'Draft Label.pdf',
  },
  agreement: {
    name: 'agreement',
    fileName: 'Agreement.pdf',
  },
  mandate: {
    name: 'mandate',
    fileName: 'Mandate.pdf',
  },
  quickMandate: {
    name: 'quickMandate',
    fileName: 'Quick Mandate.pdf',
  },
  toxProfile: {
    name: 'toxProfile',
    fileName: 'Tox Profile.pdf',
  },
};
