import { takeLatest, put, all, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { modalActions, modalTypes, modalSelectors } from '../modal';
import { callApiSaga } from '../sagaUtils';
import reportApi from '../../api/reportApi';
import * as reportActionTypes from './reportActionTypes';
import * as reportActions from './reportActions';

export default function* root() {
  yield all([
    takeLatest(reportActionTypes.FETCH_REPORT.REQUEST, fetchReportSaga),
    takeLatest(reportActionTypes.FETCH_FORMULAREVIEW_REPORT.REQUEST, fetchFormulaReviewReportSaga),
    takeLatest(reportActionTypes.FETCH_FORMULAREVIEW_QUICKREPORT.REQUEST, fetchFormulaReviewQuickReportSaga),
    takeLatest(reportActionTypes.FETCH_FORMULAREVIEW_COMPOREPORT.REQUEST, fetchFormulaReviewCompoReportSaga),
    takeLatest(reportActionTypes.FETCH_LABELREVIEW_REPORT.REQUEST, fetchLabelReviewReportSaga),
    takeLatest(reportActionTypes.FETCH_PRODUCT_REPORT_LIST.REQUEST, fetchProductReportListSaga),
    takeLatest(reportActionTypes.FETCH_AGREEMENT_REPORT.REQUEST, fetchAgreementReportSaga),
    takeLatest(reportActionTypes.FETCH_MANDATE_REPORT.REQUEST, fetchMandateReportSaga),
    takeLatest(reportActionTypes.FETCH_QUICKMANDATE_REPORT.REQUEST, fetchMandateQuickReportSaga),
    takeLatest(reportActionTypes.FETCH_TOXPROFILE_REPORT.REQUEST, fetchToxProfileReportSaga),
  ]);
}

function* fetchReportSaga({ payload }) {
  const { productId, reportType, format, region, country, language, includeInci, compositionInInci } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_REPORT.REQUEST));
  yield callApiSaga(reportActions.getReport, reportApi.getProductReport, productId, reportType, format, region, country, language, includeInci, compositionInInci);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.PRINT_DOCUMENTS) {
    yield put(modalActions.hideModal());
  }

  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_REPORT.REQUEST));
}

function* fetchLabelReviewReportSaga({ payload }) {
  const { labelReviewId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_LABELREVIEW_REPORT.REQUEST));
  yield callApiSaga(reportActions.getLabelReviewReport, reportApi.getLabelReviewReport, labelReviewId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_LABELREVIEW_REPORT.REQUEST));
}

function* fetchFormulaReviewReportSaga({ payload }) {
  const { formulaReviewId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_FORMULAREVIEW_REPORT.REQUEST));
  yield callApiSaga(reportActions.getAgreementReport, reportApi.getFormulaReviewReport, formulaReviewId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_FORMULAREVIEW_REPORT.REQUEST));
}

function* fetchFormulaReviewCompoReportSaga({ payload }) {
  const { formulaReviewId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_FORMULAREVIEW_COMPOREPORT.REQUEST));
  yield callApiSaga(reportActions.getFormulaReviewCompoReport, reportApi.getFormulaReviewCompoReport, formulaReviewId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_FORMULAREVIEW_COMPOREPORT.REQUEST));
}

function* fetchFormulaReviewQuickReportSaga({ payload }) {
  const { formulaReviewId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_FORMULAREVIEW_QUICKREPORT.REQUEST));
  yield callApiSaga(reportActions.getFormulaReviewQuickReport, reportApi.getFormulaReviewQuickReport, formulaReviewId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_FORMULAREVIEW_QUICKREPORT.REQUEST));
}

function* fetchProductReportListSaga({ payload }) {
  const { productId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_PRODUCT_REPORT_LIST.REQUEST));
  yield callApiSaga(reportActions.getProductReportList, reportApi.getProductReportList, productId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_PRODUCT_REPORT_LIST.REQUEST));
}

function* fetchAgreementReportSaga({ payload }) {
  const { companyDistributorAgreementId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_AGREEMENT_REPORT.REQUEST));
  yield callApiSaga(reportActions.getFormulaReviewReport, reportApi.getAgreementReport, companyDistributorAgreementId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_AGREEMENT_REPORT.REQUEST));
}

function* fetchMandateReportSaga({ payload }) {
  const { mandateId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_MANDATE_REPORT.REQUEST));
  yield callApiSaga(reportActions.getMandateReport, reportApi.getMandateReport, mandateId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_MANDATE_REPORT.REQUEST));
}

function* fetchMandateQuickReportSaga({ payload }) {
  const { mandateId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_QUICKMANDATE_REPORT.REQUEST));
  yield callApiSaga(reportActions.getQuickMandateReport, reportApi.getQuickMandateReport, mandateId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_QUICKMANDATE_REPORT.REQUEST));
}

function* fetchToxProfileReportSaga({ payload }) {
  const { substanceId } = payload;
  yield put(applicationActions.setLoading(reportActionTypes.FETCH_TOXPROFILE_REPORT.REQUEST));
  yield callApiSaga(reportActions.getToxProfileReport, reportApi.getToxProfileReport, substanceId);
  yield put(applicationActions.unsetLoading(reportActionTypes.FETCH_TOXPROFILE_REPORT.REQUEST));
}
