import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const ImpactAssessmentList = props => {
  const {
    impactAssessmentList,
    onImpactAssessmentSelected,
    onAddImpactAssessment,
    onRefreshList,
    requestingImpactAssessmentList,
    selectedId,
  } = props;
  const columns = [
    {
      headerName: '',
      width: 100,
      field: 'ImpactAssessmentId',
    },
    {
      headerName: I18n.t('general.code'),
      width: 100,
      field: 'ImpactAssessmentCode',
    },
    {
      headerName: I18n.t('general.name'),
      field: 'Description',
      suppressSizeToFit: true,
      sort: 'asc',
    },
  ];
  return (
    <SelectionGrid
      data={impactAssessmentList}
      onSelected={onImpactAssessmentSelected}
      onRefresh={onRefreshList}
      onAdd={onAddImpactAssessment}
      showLoadingIcon={requestingImpactAssessmentList}
      selectedId={selectedId}
      columns={columns}
      keyName="ImpactAssessmentId"
      suppressRowClickSelection
      showAddButton
    />
  );
};

ImpactAssessmentList.defaultProps = {
  selectedId: '',
};

ImpactAssessmentList.propTypes = {
  impactAssessmentList: PropTypes.arrayOf(
    PropTypes.shape({
      ImpactAssessmentId: PropTypes.number.isRequired,
      Description: PropTypes.string.isRequired,
      ImpactAssessmentCode: PropTypes.string.isRequired,
      Status: PropTypes.string,
    }),
  ).isRequired,
  onImpactAssessmentSelected: PropTypes.func.isRequired,
  onAddImpactAssessment: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  requestingImpactAssessmentList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default ImpactAssessmentList;
