import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../_Common/BaseLoader';
import RawMatProportionForm from './RawMatProportionForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};
const AddProductProportionModal = props => {
  const {
    currentRawMat: { Name },
    closeModal,
    isDirty,
    isLoadingContent,
    onSubmitButtonClick,
    onSave,
    currentRawMatProportion,
    invalid,
    selectedSubstanceId,
    selectedRawMatId,
  } = props;

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Add Substance to {Name} Raw Material composition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseLoader isLoading={isLoadingContent}>
          <RawMatProportionForm initialValues={currentRawMatProportion} onSubmit={onSave} {...props} />
        </BaseLoader>
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={onSubmitButtonClick}
              disabled={!isDirty || isLoadingContent || invalid || (!selectedSubstanceId && !selectedRawMatId)}>
              <i className="fa fa-floppy-o" />&nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

AddProductProportionModal.defaultProps = {
  selectedSubstanceId: null,
  selectedRawMatId: null,
};

AddProductProportionModal.propTypes = {
  currentRawMat: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    RawMatId: PropTypes.number.isRequired,
  }).isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoadingContent: PropTypes.bool.isRequired,
  selectedSubstanceId: PropTypes.number,
  selectedRawMatId: PropTypes.number,
  currentRawMatProportion: PropTypes.object, //eslint-disable-line
};

export default AddProductProportionModal;
