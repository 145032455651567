import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { SelectionGrid, EditDeleteButtonRenderer } from '../../_Common/Grids';

const RawMatPhraseList = props => {
  const {
    rawMatPhraseList,
    isLoadingRawMatPhraseList,
    fetchRawMatPhraseList,
    phraseList,
    cpsrInfoRmChapterList,
    onAddButtonClick,
    onEditButtonClick,
    onDeleteButtonClick,
  } = props;

  const getPhraseById = phraseId => R.find(R.propEq('PhraseId', phraseId))(phraseList);
  const getCpsrChapterCode = cpsrChapterCode => R.find(R.propEq('Key', cpsrChapterCode))(cpsrInfoRmChapterList);

  const getPhraseValue = ({ colDef, data }) =>
    R.compose(R.propOr('', colDef.field), getPhraseById, R.prop('PhraseId'))(data);

  const getCpsrChapterValue = ({ colDef, data }) =>
    R.compose(R.propOr('', colDef.field), getCpsrChapterCode, R.prop('RawMatInfoChapterCode'))(data);

  const columns = [
    {
      headerName: I18n.t('rawMatPhrase.OrderNr'),
      field: 'OrderNr',
      sort: 'asc',
      width: 80,
    },
    {
      headerName: I18n.t('rawMatPhrase.Phrase'),
      valueGetter: getPhraseValue,
      field: 'Text',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('rawMatPhrase.Description'),
      field: 'Description',
    },
    {
      headerName: I18n.t('rawMatPhrase.Specification'),
      field: 'Specification',
    },
    {
      headerName: I18n.t('rawMatPhrase.Method'),
      field: 'Method',
    },
    {
      headerName: I18n.t('rawMatPhrase.CpsrChapterCode'),
      valueGetter: getCpsrChapterValue,
      field: 'Value',
    },
    {
      headerName: I18n.t('rawMatPhrase.phCode'),
      valueGetter: getPhraseValue,
      field: 'PhraseCode',
      width: 80,
    },
    {
      headerName: I18n.t('general.actions'),
      width: 130,
      cellRendererFramework: EditDeleteButtonRenderer,
      cellRendererParams: {
        onDelete: onDeleteButtonClick,
        onEdit: onEditButtonClick,
      },
    },
  ];
  return (
    <SelectionGrid
      data={rawMatPhraseList}
      keyName="RawMatPhraseId"
      columns={columns}
      isLoading={isLoadingRawMatPhraseList}
      onRefresh={fetchRawMatPhraseList}
      onAdd={onAddButtonClick}
    />
  );
};

RawMatPhraseList.propTypes = {
  fetchRawMatPhraseList: PropTypes.func.isRequired,
  rawMatPhraseList: PropTypes.array.isRequired, //eslint-disable-line
  phraseList: PropTypes.array.isRequired, //eslint-disable-line
  cpsrInfoRmChapterList: PropTypes.array.isRequired, //eslint-disable-line
  isLoadingRawMatPhraseList: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

export default RawMatPhraseList;
