import PropTypes from 'prop-types';
import React from 'react';
import idx from 'idx';
import { css } from 'glamor';
import { Button, HelpBlock, FormControl } from 'react-bootstrap';
import BaseFormGroup from './BaseFormGroup';

const StatusField = props => {
  const {
    meta,
    options,
    valueField,
    labelField,
    input,
    input: { value, onChange },
    valueRenderer,
  } = props;

  const style = css({
    display: 'flex',
    alignItems: 'initial',
    padding: 0,
    '& li': {
      listStyleType: 'none',
    },
    '& button': {
      display: 'inline-flex',
      textDecorationLine: 'none!important',
    },
  });

  const isFirstValueSelected = idx(options, _ => _[0][valueField] === value);
  const isLastValueSelected = idx(options, _ => _[options.length - 1][valueField] === value);

  const getCurrentValue = () => {
    if (options && options.length > 0) {
      return options.find(status => status[valueField] === value);
    }
    return undefined;
  };

  const getDisplayedValue = () => {
    const currentValue = getCurrentValue();
    if (currentValue) {
      return typeof valueRenderer === 'function' ? valueRenderer(currentValue) : currentValue[labelField];
    }
    return '';
  };

  const onArrowClick = operation => {
    const currentValue = idx(getCurrentValue(), _ => _[valueField]);
    if (currentValue) {
      onChange(operation(currentValue));
    }
  };

  const createAdd = numberToAdd => number => number + numberToAdd;
  const addOne = createAdd(1);
  const removeOne = createAdd(-1);

  return (
    <BaseFormGroup {...props}>
      <ul {...style}>
        <li>
          <Button onClick={() => onArrowClick(removeOne)} disabled={isFirstValueSelected} bsStyle="link">
            <i className="fa fa-chevron-left" />
          </Button>
        </li>
        <li>
          <FormControl bsSize="small" type="text" readOnly {...input} value={getDisplayedValue()} />
        </li>
        <li>
          <Button onClick={() => onArrowClick(addOne)} disabled={isLastValueSelected} bsStyle="link">
            <i className="fa fa-chevron-right" />
          </Button>
        </li>
      </ul>
      {meta && meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
    </BaseFormGroup>
  );
};

StatusField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  options: PropTypes.array.isRequired, //eslint-disable-line
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  valueRenderer: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

StatusField.defaultProps = {
  labelField: 'Value',
  valueField: 'Key',
  valueRenderer: undefined,
  meta: undefined,
};

export default StatusField;
