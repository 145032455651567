import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl, FormGroup } from 'react-bootstrap';

const CompoComment = props => (
  <div>
    <Col sm={12}>
      <FormGroup>
        <FormControl
          id="CompoComment"
          bsSize="large"
          componentClass="textarea"
          value={props.formulaReview != undefined ? props.formulaReview.CompositionComment : ''}
          onChange={e => props.updateFormulaReview({ CompositionComment: e.target.value })}
          rows={34}
        />
      </FormGroup>
    </Col>
  </div>
);

CompoComment.defaultProps = {
  formulaReview: {
    CompositionComment: '',
  },
};

CompoComment.propTypes = {
  updateFormulaReview: PropTypes.func.isRequired,
  formulaReview: PropTypes.shape({
    CompositionComment: PropTypes.string,
  }).isRequired,
};

export default CompoComment;
