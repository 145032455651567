import { createRequestActions } from '../utils';
import * as companyOrderDocumentTypes from './companyOrderDocumentTypes';

export const uploadCompanyOrderDocument = createRequestActions(
  companyOrderDocumentTypes.UPLOAD_COMPANY_ORDER_DOCUMENT,
  (CompanyId, data) => ({
    CompanyId,
    ...data,
  }),
);
