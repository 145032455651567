import { takeLatest, put, all, select } from 'redux-saga/effects';
import { modalTypes, modalActions } from '..';
import { productPhraseSelectors } from '../../productPhrase';
import { phraseCpsrChapterActions } from '../../phraseCpsrChapter';
import { productPhraseFormActions } from '../../form/productPhraseForm';
import * as phraseListModalTypes from './phraseListModalTypes';

export default function* root() {
  yield all([
    takeLatest(phraseListModalTypes.SHOW_PHRASE_LIST_MODAL, showPhraseListModal),
    takeLatest(phraseListModalTypes.SUBMIT, onSubmitSaga),
    takeLatest(phraseListModalTypes.LOAD, onLoadSaga),
  ]);
}

function* onLoadSaga({ payload }) {
  const { ProductPhraseId } = payload;

  if (ProductPhraseId) {
    const productPhrase = yield select(state => productPhraseSelectors.getById(state)[ProductPhraseId]);
    if (productPhrase) {
      const { CpsrChapterCode } = productPhrase;
      yield put(phraseCpsrChapterActions.fetchPhraseCpsrChapters.request({ CpsrChapterCode }));
    }
  }
}

function* showPhraseListModal({ payload }) {
  yield put(
    modalActions.showModal({ modalType: modalTypes.PHRASE_LIST, modalProps: { modalWidth: '1200px', ...payload } }),
  );
}

function* onSubmitSaga({ payload }) {
  const { ProductPhraseId } = payload;
  if (ProductPhraseId) {
    yield put(productPhraseFormActions.editProductPhrase(payload));
  } else {
    yield put(productPhraseFormActions.addProductPhrase(payload));
  }
  yield put(modalActions.hideModal());
}
