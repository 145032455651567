import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import OrderForm from './OrderForm';
import { orderActions, orderTypes } from '../../redux/order';
import formNames from '../../constants/formNames';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { companyActions, companySelectors } from '../../redux/company';
import { applicationSelectors } from '../../redux/application';
import { referentialSelectors } from '../../redux/referentials';
import { userSelectors } from '../../redux/user';
import { userRoleActions } from '../../redux/userRole';
import userRoleTypes from '../../constants/userRoleTypes';
import { workflowStatusSelectors } from '../../redux/workflowStatus';
import { orderActionActions } from '../../redux/orderAction';
import { orderFormSelectors, orderFormActions } from '../../redux/form/orderForm';

class OrderDetailsContainer extends Component {
  static propTypes = {
    orderId: PropTypes.number.isRequired,
    duplicateId: PropTypes.number,
    onLoadOrder: PropTypes.func.isRequired,
    currentOrder: PropTypes.object.isRequired, //eslint-disable-line
  };

  static defaultProps = {
    duplicateId: null,
  };

  componentDidMount() {
    const orderIdToFetch = this.getOrderIdToFetch(this.props);
    if (orderIdToFetch > 0) {
      this.props.onLoadOrder(orderIdToFetch);
    }
  }

  componentDidUpdate(previousProps) {
    if (this.getOrderIdToFetch(previousProps) !== this.getOrderIdToFetch(this.props)) {
      const orderIdToFetch = this.getOrderIdToFetch(this.props);
      if (orderIdToFetch > 0) {
        this.props.onLoadOrder(orderIdToFetch);
      }
    }
  }

  getOrderIdToFetch = props => (props.orderId > 0 ? props.orderId : props.duplicateId);

  render() {
    const { currentOrder } = this.props;
    return <OrderForm initialValues={currentOrder} {...this.props} />;
  }
}

function mapStateToProps(state, { orderId }) {
  const { currentOrder, currentOrderProducts } = state.order;
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('ORDER');
  const getIsOrderEditable = orderFormSelectors.makeGetIsOrderEditable(orderId);
  return {
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      orderTypes.SAVE_ORDER.REQUEST,
      orderTypes.ORDER.REQUEST,
      orderTypes,
    ),
    currentOrder,
    currentOrderProducts,
    isOrderEditable: getIsOrderEditable(state),
    inDuplicateMode: orderFormSelectors.getInDuplicateMode(state),
    regulatoryRegions: referentialSelectors.getRegulatoryRegions(state),
    companyList: companySelectors.getCompanyList(state),
    isNew: orderFormSelectors.isNew(state),
    isErasable: orderFormSelectors.isErasable(state),
    workflowStatusList: getWorkflowStatusList(state),
    orderTitle: orderFormSelectors.orderTitle(state),
    expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
    saleRepList: userSelectors.getUsersByRole(state, userRoleTypes.saleRep),
    customerSupportList: userSelectors.getUsersByRole(state, userRoleTypes.customerSupport),
  };
}

function mapDispatchToProps(dispatch, { orderId }) {
  return {
    onLoadOrder: id => {
      dispatch(orderActions.order.request(id));
      // dispatch(orderActions.listOrderProducts.request(id));
      dispatch(companyActions.listCompanies.request(id));
      dispatch(orderActionActions.fetchOrderActions.request({ orderId }));
      dispatch(
        userRoleActions.listUserRoleForRoles.request([
          userRoleTypes.expert,
          userRoleTypes.customerSupport,
          userRoleTypes.saleRep,
        ]),
      );
    },
    onSaveOrder: orderActions.submitOrderForm,
    onDuplicate: () => dispatch(orderFormActions.duplicateOrder(orderId)),
    getOrderProductListRequest: id => dispatch(orderActions.listOrderProducts.request(id)),
    // onChangeStatus: ({ Code }) => dispatch(orderActions.updateStatus({ WorkflowStatusCode: Code, orderId })),
    onDelete: () => {
      console.log('delete order ' + orderId)
      dispatch(orderActions.deleteOrder.request(orderId))
    },
  };
}
const withData = connect(mapStateToProps, mapDispatchToProps);
const withProductFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.order);

export default compose(withData, withProductFormLeaveRouteDirtyWrapper)(OrderDetailsContainer);
