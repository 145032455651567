import 'react-virtualized-select/styles.css';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { Creatable } from 'react-select';
import { withState } from 'recompose';

import VirtualizedSelect from 'react-virtualized-select';
import BaseFormGroup from './BaseFormGroup';
import { css } from 'glamor';

const styleSelect = css({
  '& .Select_menu_outer': {
    zIndex: '9999!important',
    color: 'red',
  },
});

const SelectField = props => {
  const {
    input,
    valueField,
    labelField,
    allowCreate,
    useVirtualized,
    options,
    createdValue,
    setCreatedValue,
    multi,
    onValueChange,
    ...otherProps
  } = props;

  const handleChange = opt => {
    if (opt !== null) {
      if (opt.new) {
        setCreatedValue(opt, () => input.onChange(opt[valueField]));
      } else if (!multi) {
        setCreatedValue(null, () => input.onChange(opt[valueField]));
      } else {
        setCreatedValue(null, () => input.onChange(opt.map(value => value[valueField])));
      }
    } else {
      input.onChange(opt);
    }
    if (onValueChange !== undefined) {
      onValueChange(opt);
    }
  };

  const SelectComponent = (() => {
    if (useVirtualized) {
      return VirtualizedSelect;
    }
    if (allowCreate) {
      return Creatable;
    }
    return Select;
  })();

  const finalOptions = createdValue ? [...options, { ...createdValue }] : options;

  const handleOnBlur = () => {
    input.onBlur();
  };

  const RenderComp = (
    <div className={styleSelect}>
      <SelectComponent
        value={input.value}
        options={finalOptions}
        onChange={handleChange}
        labelKey={labelField}
        valueKey={valueField}
        onBlur={handleOnBlur}
        selectComponent={allowCreate ? Creatable : Select}
        {...otherProps}
        multi={props.multi}
      />
    </div>
  );

  return <BaseFormGroup {...props}>{RenderComp}</BaseFormGroup>;
};

SelectField.defaultProps = {
  labelField: 'Value',
  valueField: 'Key',
  options: [],
  matchProp: 'label',
  allowCreate: false,
  useVirtualized: false,
  onNewOptionClick: undefined,
  multi: false,
};

SelectField.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  options: PropTypes.array, // eslint-disable-line
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    invalid: PropTypes.bool.isRequired,
  }).isRequired,
  matchPos: PropTypes.string,
  matchProp: PropTypes.string,
  allowCreate: PropTypes.bool,
  multi: PropTypes.bool,
  useVirtualized: PropTypes.bool,
  setCreatedValue: PropTypes.func.isRequired,
  createdValue: PropTypes.object, //eslint-disable-line
  onNewOptionClick: PropTypes.func,
};

export default withState('createdValue', 'setCreatedValue', null)(SelectField);
