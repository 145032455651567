import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import { removeById } from '../utils';
import * as productProportionTypes from './productProportionTypes';
import * as productTypes from '../product/productTypes';

const byId = handleActions(
  {
    [productProportionTypes.FETCH_PRODUCT_PROPORTIONS.SUCCESS]: (state, { payload }) => merge({}, state, payload),
    [combineActions(
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT.SUCCESS,
      productProportionTypes.UPDATE_MAX_QTY.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      ...payload.entities.productProportions,
    }),
    [productTypes.DELETE_PRODUCT_PROPORTION.SUCCESS]: removeById,
  },
  {},
);

const allIds = handleActions(
  {
    [productProportionTypes.FETCH_PRODUCT_PROPORTIONS.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT.SUCCESS,
      productProportionTypes.UPDATE_MAX_QTY.SUCCESS,
    )]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [productTypes.DELETE_PRODUCT_PROPORTION.SUCCESS]: (state, { payload }) => R.without([payload])(state),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
