import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cosAnnexeActions, cosAnnexeSelectors } from '../../../redux/cosAnnexe';
import CosAnnexeList from './CosAnnexeList';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { getCosAnnexeList } from './selectors';

class CosAnnexeListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <CosAnnexeList {...this.props} />;
  }
}

const mapStateToProps = state => ({
  cosAnnexeList: getCosAnnexeList(state),
  isLoading: applicationSelectors.getIsLoading(cosAnnexeSelectors.makeGetByQueryLoading())(state),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(cosAnnexeActions.fetchCosAnnexes.request());
  },
  getCosAnnexeList: () => dispatch(cosAnnexeActions.fetchCosAnnexes.request()),
  onAddButtonClick: () => dispatch(modalActions.showCosAnnexeFormModal()),
  onEditButtonClick: ({ CosAnnexeId: cosAnnexeId }) => dispatch(modalActions.showCosAnnexeFormModal({ cosAnnexeId })),
  onDetailsButtonClick: ({ Code: cosAnnexeCode }) =>
    dispatch(modalActions.showSubstanceCosAnnexeListModal({ cosAnnexeCode })),
  onDeleteButtonClick: ({ CosAnnexeId }) => dispatch(cosAnnexeActions.deleteCosAnnexe.request(CosAnnexeId)),
  onTranslateClick: ({ CosAnnexeId }) => dispatch(modalActions.showCosAnnexeTRModal({ CosAnnexeId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CosAnnexeListContainer);

CosAnnexeListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
