import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ProductDuplicateOrReplaceFormModal from './ProductDuplicateOrReplaceFormModal';
import { productSelectors } from '../../../../redux/product';
import { productProportionActions, productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import { productFormActions } from '../../../../redux/form/productForm';
import { companySelectors } from '../../../../redux/company';

const ProductDuplicateOrReplaceModalContainer = props => <ProductDuplicateOrReplaceFormModal {...props} />;

const mapStateToProps = (state, { ProductId }) => {
  const productId = ProductId;
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  const companies = companySelectors.getCompanyList(state);

  return {
    title: I18n.t('productDuplicateOrReplaceModal.title', { productName }),
    isSaveDisabled: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
    availableOrdersForCurrentProduct: productSelectors.getOrdersOwnedByCurrentProductCompany(state),
    companyList: companies,
    productName: productName,
    productId: productId,
  };
};

const mapDispatchToProps = (dispatch, { productId, mode, orderNr }) => ({
  onSave: data => dispatch(productProportionActions.reOrderProductProportions(productId, data)),
  onSaveButtonClick: (productId, mode, orderNr) => {
    if (mode === "Duplicate") {
      dispatch(productFormActions.duplicateProduct(productId, orderNr));
    }
    else if (mode === "Replace") {
      dispatch(productFormActions.replaceProduct(productId, orderNr));
    }
  },
});

ProductDuplicateOrReplaceModalContainer.propTypes = {
  ProductId: PropTypes.number.isRequired,
  availableOrdersForCurrentProduct: PropTypes.arrayOf(
    PropTypes.shape({
      OrderNr: PropTypes.string.isRequired,
      OrderId: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDuplicateOrReplaceModalContainer);
