import * as phraseFormSelectors from './phraseFormSelectors';
import * as phraseFormActions from './phraseFormActions';
import * as phraseFormTypes from './phraseFormTypes';
import sagas from './phraseFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { phraseFormSelectors, phraseFormActions, phraseFormTypes };
