import { normalize } from 'normalizr';
import { takeLatest, put, all, select, call } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import substanceDocumentApi from '../../api/substanceDocumentApi';
import documentApi from '../../api/documentApi';
import * as substanceDocumentTypes from './substanceDocumentTypes';
import * as substanceDocumentActions from './substanceDocumentActions';
import * as substanceDocumentSchemas from './substanceDocumentSchemas';
import * as substanceDocumentSelectors from './substanceDocumentSelectors';
import * as substanceActions from '../substance/substanceActions';

export default function* root() {
  yield all([
    takeLatest(substanceDocumentTypes.SAVE_SUBSTANCE_DOCUMENT.REQUEST, saveSubstanceDocumentSagas),
    takeLatest(substanceDocumentTypes.UPLOAD_SUBSTANCE_DOCUMENT.REQUEST, uploadSubstanceDocumentSagas),
    takeLatest(substanceDocumentTypes.UPLOAD_SUBSTANCE_STRUCTURE.REQUEST, uploadSubstanceStructureSagas),
    takeLatest(
      substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.REQUEST,
      getSubstanceDocumentListForSubstanceSaga,
    ),
    takeLatest(substanceDocumentTypes.DELETE_SUBSTANCE_DOCUMENT.REQUEST, deleteSubstanceDocumentSaga),
  ]);
}

function* deleteSubstanceDocumentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { SubstanceDocumentId } = payload;
  const substanceDocument = yield select(state =>
    substanceDocumentSelectors.getSubstanceDocument(state, SubstanceDocumentId),
  );

  yield callApiSaga(
    substanceDocumentActions.deleteSubstanceDocument,
    substanceDocumentApi.deleteSubstanceDocument,
    SubstanceDocumentId,
  );

  if (substanceDocument && substanceDocument.SubstanceId) {
    const { SubstanceId } = substanceDocument;
    yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(SubstanceId));
  }

  yield put(applicationActions.unsetLoading(type));
}

function* saveSubstanceDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { SubstanceId, ...data } = payload;

  yield callApiSaga(substanceDocumentActions.saveSubstanceDocument, documentApi.saveDocument, data);
  yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(SubstanceId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadSubstanceDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { SubstanceId } = payload;
  yield callApiSaga(
    substanceDocumentActions.saveSubstanceDocument,
    substanceDocumentApi.uploadSubstanceDocument,
    payload,
  );
  yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(SubstanceId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadSubstanceStructureSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { SubstanceId } = payload;
  yield callApiSaga(
    substanceDocumentActions.uploadSubstanceStructure,
    substanceDocumentApi.uploadSubstanceStructure,
    payload,
  );
  yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(SubstanceId));
  yield put(substanceActions.substance.request(SubstanceId));
  yield put(applicationActions.unsetLoading(type));
}

function* getSubstanceDocumentListForSubstanceSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { SubstanceId } = payload;
    const substanceDocuments = yield call(substanceDocumentApi.getSubstanceDocumentList, {
      SubstanceId,
      OptIncludeDocument: true,
    });
    const normalizedData = normalize(
      substanceDocuments,
      substanceDocumentSchemas.substanceDocumentListWithDocumentSchema,
    );
    yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.success(normalizedData, SubstanceId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceDocumentActions.listSubstanceDocumentsForSubstance.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
