import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { productClpActions, productClpSelectors } from '../../../redux/productClp';

class Productclassifications extends Component {
  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    const { classifications } = this.props;

    return (
      <div style={{ color: 'black', fontweight: 'bold', float: 'right' }}>
        CLP:&nbsp;&nbsp;
        {classifications.length > 0 && (
          <span style={{ color: 'red', fontweight: 'bold' }}>
            {classifications.map(classification => I18n.t(`clp.${classification}`)).join(', ')}
          </span>
        )}
        {classifications.length === 0 && <span style={{ color: 'green', fontweight: 'bold' }}>NCL</span>}
      </div>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const productClp = productClpSelectors.getByProductId(state)[productId];
  console.log('productClp', productClp);
  return {
    classifications: R.propOr([], 'Classifications')(productClp),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(productClpActions.fetchProductClpForProduct.request(productId));
  },
});

Productclassifications.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
  classifications: PropTypes.array.isRequired, //eslint-disable-line
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Productclassifications);
