import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { SelectionGrid, LinkCellRenderer, DeleteButtonRenderer, DateCellRenderer} from '../../_Common/Grids';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { DeleteButton } from '../../_Common/Grids';

const ActionButtonsRedender = props => {
  const { onDelete, onSend, data } = props;
  // const handleClick = fn => () => fn(data);
  const handleSendClick = () => {
    onSend(data);
  };
  const handleDeleteClick = () => {
    onDelete(data);
  };

  return (
    <div>
      <ButtonToolbar>
        <DeleteButton onClick={() => { handleDeleteClick(onDelete)}} />
        <Button variant="info" type="button" bsSize="xsmall" onClick={() => {
          handleSendClick(onSend)}
          } >
          <Translate value="notification.sendToCosmos" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRedender.propTypes = {
  onEdit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
};

const ProductDocumentList = props => {
  const {
    productDocuments,
    isLoadingProductDocumentList,
    getProductDocuments,
    saveProductDocument,
    deleteProductDocument,
    sendProductDocumentToCosmos
  } = props;

  const handleCellValueChange = ({data}) => saveProductDocument(data.Document);
  const onDeleteClick = ({ProductDocumentId}) => deleteProductDocument(ProductDocumentId);
  const onSendClick = ({ProductDocumentId}) => 
    sendProductDocumentToCosmos(ProductDocumentId);
  const columns = [
    {
      headerName: I18n.t('document.name'),
      field: 'Document.FileName',
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'Document.FileName',
        linkKey: 'Document.FileLink',
      },
    },
    {
      headerName: I18n.t('document.description'),
      field: 'Document.Description',
      editable: true,
      onCellValueChanged: handleCellValueChange,
    },
    {
      headerName: I18n.t('document.createdBy'),
      field: 'CreatedBy',
      width: 50,
    },
    {
      headerName: I18n.t('document.createdOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true
      },
      sort: 'desc',
      width: 135,
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('general.actions'),
    //   cellRendererFramework: DeleteButtonRenderer,
    //   width: 100,
    //   suppressSizeToFit: true,
    //   cellRendererParams: {
    //     onClick: onDeleteClick,
    //   },
    // },
    {
      headerName: I18n.t('notification.actions'),
      width: 200,
      minWidth: 200,
      // suppressSizeToFit: true,
      cellRendererFramework: ActionButtonsRedender,
      cellRendererParams: {
        onDelete: onDeleteClick,
        onSend: onSendClick,
      },
    },
  ];
  console.log('productDocuments', productDocuments);

  return (
    <SelectionGrid
      data={productDocuments}
      keyName="DocumentId"
      columns={columns}
      isLoading={isLoadingProductDocumentList}
      onRefresh={getProductDocuments}
      showFilterRow
      stopEditingWhenGridLosesFocus
      showAddButton={false}
    />
  );
};

ProductDocumentList.defaultProps = {
  productDocuments: [],
};

ProductDocumentList.propTypes = {
  productDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      ProductDocumentId: PropTypes.number.isRequired,
      Document: PropTypes.shape({
        DocumentId: PropTypes.number.isRequired,
        FileName: PropTypes.string.isRequired,
        FileLink: PropTypes.string.isRequired,
        Description: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  getProductDocuments: PropTypes.func.isRequired,
  isLoadingProductDocumentList: PropTypes.bool.isRequired,
  saveProductDocument: PropTypes.func.isRequired,
  sendProductDocumentToCosmos: PropTypes.func.isRequired,
  deleteProductDocument: PropTypes.func.isRequired,
};

export default ProductDocumentList;
