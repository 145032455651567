import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting, formValueSelector } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
// import { orderItemProductTypes, orderItemProductActions } from '../../../../redux/orderItemProduct';
import { orderItemTypes } from '../../../../redux/orderItem';
import OrderItemProductModal from './OrderItemProductModal';

const formName = formNames.orderItemProduct;
const formValueselector = formValueSelector(formName);
const isAnyTrue = R.any(R.equals(true));

const OrderItemProductModalContainer = props => <OrderItemProductModal {...props} />;

const mapStateToProps = state => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isLoading: applicationSelectors.isLoading(state, orderItemTypes.SAVE_ORDER_ITEM.REQUEST),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('orderProcess.modalTitle'),
    currencyCode: formValueselector(state, 'CurrencyCode'),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => {
    dispatch(submit(formName));
  },
  onSubmit: () => {
    console.log('submit');
  }
});

OrderItemProductModalContainer.defaultProps = {
  orderItemId: undefined,
};

OrderItemProductModalContainer.propTypes = {
  orderItemId: PropTypes.number,
  orderId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderItemProductModalContainer);
