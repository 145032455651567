import * as labelReviewInciSelectors from './labelReviewInciSelectors';
import * as labelReviewInciActions from './labelReviewInciActions';
import * as labelReviewInciTypes from './labelReviewInciTypes';
import * as labelReviewInciSchemas from './labelReviewInciSchemas';
// import sagas from './labelReviewInciSagas';
import reducer from './labelReviewInciReducer';

// import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { labelReviewInciSelectors, labelReviewInciActions, labelReviewInciTypes, labelReviewInciSchemas };
export default reducer;
