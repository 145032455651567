import { schema } from 'normalizr';
import * as documentSchemas from '../document/documentSchemas';

export const substanceDocumentSchema = new schema.Entity(
  'substanceDocuments',
  {},
  { idAttribute: 'SubstanceDocumentId' },
);
export const substanceDocumentWithDocumentSchema = new schema.Entity(
  'substanceDocuments',
  {
    Document: documentSchemas.documentSchema,
  },
  { idAttribute: 'SubstanceDocumentId' },
);

export const substanceDocumentListWithDocumentSchema = [substanceDocumentWithDocumentSchema];
