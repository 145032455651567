import { createSelector } from 'reselect';
import { referentialSelectors } from '../../../redux/referentials';
import { cosAnnexeSelectors } from '../../../redux/cosAnnexe';

export const getCosAnnexeList = createSelector(
  [referentialSelectors.makeGetCosAnnexeTypeByCode, cosAnnexeSelectors.getCosAnnexeList],
  (getCosAnnexeTypeByCode, cosAnnexeList) =>
    cosAnnexeList.map(cosAnnexe => ({
      ...cosAnnexe,
      UsedIncosAnnexeType: getCosAnnexeTypeByCode(cosAnnexe.UsedIn),
      NotUsedIncosAnnexeType: getCosAnnexeTypeByCode(cosAnnexe.NotUsedIn),
    })),
);
