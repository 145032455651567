import { all, put, takeLatest } from 'redux-saga/effects';
import safecosmeticDashboardApi from '../../api/safecosmeticDashboardApi';
import { applicationActions } from '../application';
import * as safecosmeticDashboardActions from './safecosmeticDashboardActions';
import * as safecosmeticDashboardSchemas from './safecosmeticDashboardSchemas';
import * as safecosmeticDashboardTypes from './safecosmeticDashboardTypes';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';

export default function* root() {
  yield all([takeLatest(safecosmeticDashboardTypes.SAFECOSMETIC_DASHBOARD_LIST.REQUEST, getProductListSaga)]);
  yield all([takeLatest(safecosmeticDashboardTypes.SHOW.REQUEST, showProductOnSafecosmeticSaga)]);
  yield all([takeLatest(safecosmeticDashboardTypes.SHOW_COSMOS.REQUEST, showProductOnCosmosSaga)]);
}

export function* getProductListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(safecosmeticDashboardTypes.SAFECOSMETIC_DASHBOARD_LIST.REQUEST));
  try {
    yield callApiSagaAndNormalize(
      safecosmeticDashboardActions.listProducts,
      safecosmeticDashboardApi.getSafecosmeticDashboard,
      safecosmeticDashboardSchemas.safecosmeticDashboardListSchema,
      payload,
    );
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setSafecosmeticError(message));
      yield put(safecosmeticDashboardActions.listProducts.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(safecosmeticDashboardTypes.SAFECOSMETIC_DASHBOARD_LIST.REQUEST));
  }
}

export function* showProductOnSafecosmeticSaga({ payload }) {
  yield put(applicationActions.setLoading(safecosmeticDashboardTypes.SHOW.REQUEST));
  try {
    const url = yield callApiSaga(
      safecosmeticDashboardActions.showOnSafecosmetic,
      safecosmeticDashboardApi.getProductSafecosmeticUrl,
      payload.prodigumProductId,
    );
    if (url !== undefined) {
      window.open(url, '_blank');
    }
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setSafecosmeticError(message));
      yield put(safecosmeticDashboardActions.listProducts.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(safecosmeticDashboardTypes.SHOW.REQUEST));
  }
}

export function* showProductOnCosmosSaga({ payload }) {
  yield put(applicationActions.setLoading(safecosmeticDashboardTypes.SHOW_COSMOS.REQUEST));
  try {
    const url = yield callApiSaga(
      safecosmeticDashboardActions.showOnCosmos,
      safecosmeticDashboardApi.getProductCosmosUrl,
      payload.prodigumProductId,
    );
    if (url !== undefined) {
      window.open(url, '_blank');
    }
  } catch (error) {
    console.log(error);
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setSafecosmeticError(message));
      yield put(safecosmeticDashboardActions.listProducts.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(safecosmeticDashboardTypes.SHOW_COSMOS.REQUEST));
  }
}
