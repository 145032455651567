import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';

const formName = 'homePageForm';

const getFormValues = formValueSelector(formName);

const HomePageFormViewer = ({ htmlContent }) => {
  const parsed = draftToHtml(JSON.parse(htmlContent || null));
  return <div dangerouslySetInnerHTML={{ __html: parsed }} />;
};

HomePageFormViewer.defaultProps = {
  htmlContent: '',
};

HomePageFormViewer.propTypes = {
  htmlContent: PropTypes.string,
};

const mapStateToProps = state => ({
  htmlContent: getFormValues(state, 'HtmlContent'),
});

export default connect(mapStateToProps)(HomePageFormViewer);
