import { createAction } from 'redux-actions';
import * as productPhraseFormTypes from './productPhraseFormTypes';

export const addProductPhrase = createAction(productPhraseFormTypes.ADD_PRODUCT_PHRASE);
export const editProductPhrase = createAction(productPhraseFormTypes.EDIT_PRODUCT_PHRASE);
export const addTablePhrase = createAction(productPhraseFormTypes.ADD_TABLE_PHRASE);
export const resetPlaceholders = createAction(productPhraseFormTypes.RESET_PLACEHOLDERS);
export const onSaveButtonClick = createAction(productPhraseFormTypes.SAVE_BUTTON_CLICK);
export const loadForm = createAction(productPhraseFormTypes.LOAD_FORM);
export const onSelectProductPhrase = createAction(productPhraseFormTypes.SELECT_PRODUCT_PHRASE);
export const onDeleteButtonClick = createAction(productPhraseFormTypes.DELETE_BUTTON_CLICK);
export const onSelectFilter = createAction(productPhraseFormTypes.SELECT_FILTER);
