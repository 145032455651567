import * as productTRFormSelectors from './productTRFormSelectors';
import * as productTRFormActions from './productTRFormActions';
import * as productTRFormTypes from './productTRFormTypes';
import sagas from './productTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { productTRFormSelectors, productTRFormActions, productTRFormTypes };
