import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
// import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import DashboardForm from './DashboardForm';
import LeaveRouteDirtyWrapper from '../_Common/LeaveRouteDirtyWrapper';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css(
    {
      // marginTop: '25px',
    },
  ),
};

const DashboardDetails = props => {
  const { dirty, dashboardTypeId, dashboardTitle } = props;
  return (
    <LeaveRouteDirtyWrapper isDirty={dirty} {...props}>
      <div className={styles.detailsContainer}>
        <DashboardForm dashboardTypeId={dashboardTypeId} dashboardTitle={dashboardTitle} {...props} />
      </div>
    </LeaveRouteDirtyWrapper>
  );
};

DashboardDetails.propTypes = {
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  isNew: PropTypes.bool,
  dashboardTitle: PropTypes.string.isRequired,
  dashboardTypeId: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};
  // if (!values.Code) {
  //   errors.Code = I18n.t('general.required');
  // }
  // if (!values.ShortName) {
  //   errors.ShortName = I18n.t('general.required');
  // }
  // if (!values.ProductCodePrefix) {
  //   errors.ProductCodePrefix = I18n.t('general.required');
  // }
  return errors;
};

export default reduxForm({
  form: 'dashboardForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(DashboardDetails);
