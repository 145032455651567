import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ProductProportionCreateRMModal from './ProductProportionCreateRMModal';
import { productSelectors } from '../../../../redux/product';
import { productProportionActions, productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';

const ProductProportionCreateRMModalContainer = props => <ProductProportionCreateRMModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  return {
    title: I18n.t('productProportionReOrderModal.title', { productName }),
    isSaveDisabled: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSave: data => dispatch(productProportionActions.reOrderProductProportions(productId, data)),
});

ProductProportionCreateRMModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductProportionCreateRMModalContainer);
