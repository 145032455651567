import { createSelector } from 'reselect';
import * as R from 'ramda';
import { createGeneralSelectors } from '../_common/selectors';

const getShadeProportionStateSlice = state => state.shadeProportion;
export const {
  getById,
  getIds,
  getLoading,
  getByQueryIds,
  makeGetByQuery,
  makeGetByQueryLoading,
} = createGeneralSelectors(getShadeProportionStateSlice);

export const getShadeProportion = (state, id) => getById(state)[id];
export const getShadeProportionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const makeGetShadeProportionsForShade = createSelector([makeGetByQuery], getShadeProportionsByQuery =>
  createSelector(R.identity, shadeId => getShadeProportionsByQuery({ shadeId })),
);
export const makeGetShadeProportionsForProduct = createSelector([makeGetByQuery], getShadeProportionsByQuery =>
  createSelector(R.identity, productId => getShadeProportionsByQuery({ productId })),
);

// export const makeGetShadeProportionsForShade = createSelector([shadeSelectors.getById, getById], (shadeById, byId) =>
//   createSelector(
//     shadeId => shadeById[shadeId],
//     shade =>
//       R.compose(
//         R.map(id => byId[id]),
//         R.propOr([], 'ShadeProportions'),
//       )(shade),
//   ),
// );
