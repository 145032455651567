import React from 'react';
import { connect } from 'react-redux';
import * as selectors from './selectors';
import ShadeXInciCompoList from './ShadeXInciCompoList';

const ShadeXInciCompoListContainer = props => <ShadeXInciCompoList {...props} />;

const mapStateToProps = (state, { productId }) => ({
  columns: selectors.makeGetColumnsForProduct(state)(productId),
  data: selectors.makeGetShadeInciCompoDataForProduct(state)(productId),
});

export default connect(mapStateToProps)(ShadeXInciCompoListContainer);
