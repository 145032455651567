import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
// import sagaPlugin from 'reactotron-redux-saga';

Reactotron.clear();

const reactotron = Reactotron.configure({ name: 'CalmosV3' })
  .use(reactotronRedux()) //  <- here i am!
  // .use(sagaPlugin()) //  <- here i am!
  .connect(); // Don't forget about me!

export default reactotron;
