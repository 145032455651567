import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { reduxForm } from 'redux-form';
import { Row, Col, ButtonToolbar, Button, Form, FormControl, ControlLabel } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import BaseLoader from '../../_Common/BaseLoader';
import ProductCpsrStatus from './ProductCpsrStatus';
import PhraseInstanceForm from './PhraseInstanceForm';
import ProductCpsrbConclusionSelectField from './ProductCpsrbConclusionSelectField';
import cpsrTypes from '../../../constants/cpsrTypes';
import ProductCpsrbCommentForm from './ProductCpsrbCommentForm';

const ProductCPSRForm = props => {
  const {
    handleSubmit,
    onSaveProductPhrases,
    CpsrChapters,
    onAddProductPhrase,
    onCancelButtonClick,
    isFormPristine,
    submitting,
    isLoading,
    nbSelectedItems,
    onDeleteProductPhrases,
    onSelectFilter,
    cpsrChapterFilter,
    invalid,
    CpsrTypeCode,
    onAddTablePhrase,
    isCpsrXEditable,
    onLoadTemplateButtonClick,
    showConclusionStatus,
    productId,
    workflowStatusNextStepDisabled,
    formHasError,
  } = props;
  console.log('ProductCPSRForm props', props);

  return (
    <BaseLoader isLoading={isLoading}>
      <Form onSubmit={handleSubmit(onSaveProductPhrases)}>
        <div>
          <Row>
            <Col sm={5}>
              <ButtonToolbar>
                <Button type="button" bsSize="small" onClick={onAddProductPhrase} disabled={!isCpsrXEditable}>
                  <Translate value="general.addPhrase" />
                </Button>
                <Button type="button" bsSize="small" onClick={onAddTablePhrase} disabled={!isCpsrXEditable}>
                  <Translate value="phrase.addTablePhrase" />
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="danger"
                  type="button"
                  onClick={onCancelButtonClick}
                  disabled={isFormPristine || submitting}
                >
                  <i className="fa fa-undo" />
                  &nbsp;
                  <Translate value="general.cancel" />
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="danger"
                  type="button"
                  onClick={onDeleteProductPhrases}
                  disabled={nbSelectedItems === 0 || !isCpsrXEditable}
                >
                  <Translate value="general.delete" /> {nbSelectedItems > 0 && `(${nbSelectedItems})`}
                </Button>
                <Button
                  bsSize="small"
                  bsStyle="success"
                  type="submit"
                  disabled={isFormPristine || submitting || invalid || !isCpsrXEditable || formHasError}
                >
                  <i className="fa fa-floppy-o" />
                  &nbsp;
                  <Translate value="general.save" />
                </Button>
                <Button bsSize="small" disabled={submitting || !isCpsrXEditable} onClick={onLoadTemplateButtonClick}>
                  <i className="fa fa-download" />
                  &nbsp;
                  <Translate value="productCpsrForm.loadTemplate" />
                </Button>
              </ButtonToolbar>
            </Col>
            <Col sm={2}>
              <ProductCpsrStatus
                ProductId={productId}
                CpsrTypeCode={CpsrTypeCode}
                nextStepDisabled={workflowStatusNextStepDisabled}
                statusCode="test"
              />
            </Col>
            <Col sm={2}>
              {showConclusionStatus && (
                <div>
                  <Col sm={4}>
                    <FormControl.Static>
                      <ControlLabel>
                        <Translate value="productCpsrForm.conclusion" />
                      </ControlLabel>
                    </FormControl.Static>
                  </Col>
                  <Col sm={8}>
                    <ProductCpsrbConclusionSelectField productId={productId} disabled={!isCpsrXEditable} />
                  </Col>
                </div>
              )}
            </Col>
            <Col sm={3}>
              <Select
                options={CpsrChapters.map(opt => ({
                  label: `${opt.Order}. ${opt.Value}`,
                  value: opt.Key,
                }))}
                value={cpsrChapterFilter}
                onChange={onSelectFilter}
              />
            </Col>
            <br />
            <br />
          </Row>
          {CpsrTypeCode === cpsrTypes.CPSRB && (
            <Row>
              <Col sm={24}>
                <ProductCpsrbCommentForm productId={productId} disabled={!isCpsrXEditable} />
              </Col>
            </Row>
          )}
          <PhraseInstanceForm {...props} />
        </div>
      </Form>
    </BaseLoader>
  );
};

ProductCPSRForm.defaultProps = {
  cpsrChapterFilter: undefined,
  statusList: [],
  status: undefined,
  onLoadTemplateButtonClick: () => {},
};

ProductCPSRForm.propTypes = {
  statusList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ),
  onChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSaveProductPhrases: PropTypes.func.isRequired,
  onAddProductPhrase: PropTypes.func.isRequired,
  CpsrChapters: PropTypes.array.isRequired, //eslint-disable-line
  onCancelButtonClick: PropTypes.func.isRequired,
  isFormPristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isCpsrXEditable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nbSelectedItems: PropTypes.number.isRequired,
  onDeleteProductPhrases: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  cpsrChapterFilter: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  onAddTablePhrase: PropTypes.func.isRequired,
  onLoadTemplateButtonClick: PropTypes.func,
  showConclusionStatus: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  CpsrTypeCode: PropTypes.string.isRequired,
  workflowStatusNextStepDisabled: PropTypes.bool.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProductCPSRForm);
