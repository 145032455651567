import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import {Row, Col, Image} from 'react-bootstrap';

import { ColumnFlexBox, FitToContentBox } from '../FlexBox';
// import {css} from "glamor";
// const styles = css({
//   '& img': {
//     width: '100%',
//     height: '100%',
//   },
// });

const ImageDropzoneForm = props => {
  const { onUploadFile, image, height, width } = props;
  const handleUpload = data => {
    onUploadFile({ data });
  };
  const dropzoneStyle = {
    position: 'relative',
    width: `${width}px`,
    height: `${height}px`,
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
  }
  const imageStyle = {
    height: `${height - 5}px`,
    width: `${width - 5}px`,
    borderRadius: '5px',
  }

  return (
    <ColumnFlexBox>
      <FitToContentBox>
        <Row>
          <Col sm={12}>
            <Dropzone multiple={false} onDrop={handleUpload} style={dropzoneStyle}>
              {image ? (
                <Image
                  src={`data:image/png;base64,${image}`}
                  responsive style={imageStyle}
                />
              ) : (
                <p>Drop an image or click to select a file to upload.</p>
              )}
            </Dropzone>
          </Col>
        </Row>
      </FitToContentBox>
    </ColumnFlexBox>
  );
};

ImageDropzoneForm.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  image: PropTypes.any,
  onUploadFile: PropTypes.func.isRequired,
};

ImageDropzoneForm.defaultProps = {
  height: 200,
  width: 200,
};

export default ImageDropzoneForm;
