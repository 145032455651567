import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderActionSelectors } from '../../../../redux/orderAction';
import { applicationSelectors } from '../../../../redux/application';
import { actionSelectors } from '../../../../redux/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Button } from 'react-bootstrap';
import { orderActions } from '../../../../redux/order';
import * as orderTypes from "../../../../redux/order/orderTypes";
import BaseLoader from "../../BaseLoader";

class OrderMissingDocumentList extends React.Component {
  state = {
    copied: false
  }

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { debug, isLoading } = this.props;

    return (
      <div>
        <BaseLoader isLoading={isLoading}>
          <Row>
            <Col>
            <textarea
              rows={40}
              cols={160}
              value={this.props.text}
            />
            </Col>
          </Row>
          <Row>
            <Col className="pull-right">
              <CopyToClipboard
                options={{ debug: debug, message: "" }}
                text={this.props.text}
                onCopy={() => {
                  this.setState({ copied : true });
                  this.props.closeModal();
                }}
              >
                <Button
                  bsSize="small"
                  bsStyle="success"
                  type="button"
                  >
                  Copy to clipboard
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </BaseLoader>
      </div>
    );
  }
};

const mapStateToProps = (state, { orderId }) => {
  const getOrderActions = orderActionSelectors.makeGetOrderActionsForOrder(orderId);
  return {
    orderActionList: getOrderActions(state),
    actionList: actionSelectors.getActionList(state),
    isLoading: applicationSelectors.isLoading(state, orderTypes.ORDER_MISSING_DOCUMENT_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onLoad: () => {
    dispatch(orderActions.orderMissingDocumentList.request({ orderId }));
  },
});

OrderMissingDocumentList.defaultProps = {
  height: '',
};

OrderMissingDocumentList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  orderActionList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderMissingDocumentList);
