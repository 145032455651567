import { takeLatest, takeEvery, put, all, select } from 'redux-saga/effects';
import uuidV1 from 'uuid/v1';
import { normalize } from 'normalizr';
import { isNullOrUndef } from '../../../../utils/utils';
import { labelReviewSymbolSelectors, labelReviewSymbolSchemas } from '../../../labelReviewSymbol';
import { labelSymbolSelectors, labelSymbolSchemas } from '../../../labelSymbol';
import {
  labelReviewSymbolWizardActions,
  labelReviewSymbolWizardTypes,
  labelReviewSymbolWizardSelectors,
} from './index';
import { labelReviewWizardTypes, labelReviewWizardSelectors } from '../../labelReviewWizard';
import { labelSymbolWizardActions } from '../labelSymbolWizard';
import {applicationActions} from "../../../application";
import {callApiSaga} from "../../../sagaUtils";
import * as productDocumentActions from "../../../productDocument/productDocumentActions";
import productsDocumentApi from "../../../../api/productsDocumentsApi";
import * as documentActions from "../../../document/documentActions";
import documentApi from "../../../../api/documentApi";

export default function* root() {
  yield all([
    takeLatest(
      [labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS, labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS],
      newOrEditLabelReviewSaga,
    ),
    takeEvery([labelReviewSymbolWizardTypes.FIELD_EDITED], onEditFieldSaga),
    takeEvery(labelReviewSymbolWizardTypes.REVERT_BUTTON_CLICKED, onRevertButtonClickedSaga),
    takeEvery(labelReviewSymbolWizardTypes.ADD_BUTTON_CLICKED, onAddButtonClickedSaga),
    takeEvery(labelReviewSymbolWizardTypes.DELETE_BUTTON_CLICKED, onDeleteButtonClickedSaga),
    takeEvery(labelReviewSymbolWizardTypes.UPLOAD_SYMBOL_IMAGE.REQUEST, onSymbolImageUploadSaga),
  ]);
}

function* onSymbolImageUploadSaga({ type, payload }) {
  const productId = yield select(labelReviewWizardSelectors.getProductId);
  yield put(applicationActions.setLoading(type));
  const payloadToGive = {
    ProductId: productId,
    ...payload,
  };
  const res = yield callApiSaga(labelReviewSymbolWizardActions.uploadSymbolImage, productsDocumentApi.uploadProductDocument, payloadToGive);
  const resInt = parseInt(res);

  yield put(labelReviewSymbolWizardActions.updateLabelSymbolDocID({
    Code: payload.code,
    DocId: resInt,
  }));
  yield callApiSaga(documentActions.getDocumentImage, documentApi.getDocumentBytes, resInt);

  yield put(applicationActions.unsetLoading(type));
}

function* onDeleteButtonClickedSaga({ payload }) {
  const labelReviewSymbolId = payload;
  const labelReviewSymbol = yield select(state =>
    labelReviewSymbolWizardSelectors.getLabelReviewSymbol(state, labelReviewSymbolId),
  );
  yield put(labelReviewSymbolWizardActions.removeLabelReviewSymbol(labelReviewSymbolId));
  yield put(labelSymbolWizardActions.removeLabelSymbol(labelReviewSymbol.SymbolCode));
  if (!isNullOrUndef(yield select(state => labelSymbolSelectors.getLabelSymbol(state, labelReviewSymbol.SymbolCode)))) {
    yield put(labelSymbolWizardActions.markAsDeleted(labelReviewSymbol.SymbolCode));
  }
}

function* onAddButtonClickedSaga() {
  const ProductId = yield select(labelReviewWizardSelectors.getProductId);
  const SymbolCode = uuidV1();
  const newLabelReviewSymbol = {
    ProductId,
    LabelReviewSymbolId: uuidV1(),
    SymbolCode,
  };
  yield put(labelReviewSymbolWizardActions.addNewLabelReviewSymbol(newLabelReviewSymbol));
  yield put(labelSymbolWizardActions.addNewLabelSymbol({ Code: SymbolCode }));
}

function* onRevertButtonClickedSaga({ payload }) {
  const { symbolCode, labelReviewSymbolId } = payload;
  const defaultCorrectiveAction = yield select(state =>
    labelSymbolSelectors.getDefaultLabelSymbolCorrectiveAction(state)(symbolCode),
  );

  if (defaultCorrectiveAction) {
    yield put(
      labelReviewSymbolWizardActions.updateLabelReviewSymbol({
        id: labelReviewSymbolId,
        CorrectiveActions: defaultCorrectiveAction,
      }),
    );
  }
}

function* onEditFieldSaga({ payload }) {
  yield put(labelReviewSymbolWizardActions.updateLabelReviewSymbol(payload));
}

function* newOrEditLabelReviewSaga({ payload }) {
  const { LabelReviewId, ProductId } = payload;
  const editMode = !isNullOrUndef(LabelReviewId);
  const publicLabelSymbolList = yield select(labelSymbolSelectors.getPublicLabelSymbolList);

  const currentLabelReviewSymbolList = editMode
    ? yield select(state => labelReviewSymbolSelectors.getLabelReviewSymbolListForLabelReview(state, LabelReviewId))
    : yield select(state => labelReviewSymbolSelectors.getLabelReviewSymbolListFromLatestLabelReview(state, ProductId));

  const privateLabelSymbolListForLabelReviewSymbolList = yield select(state =>
    labelSymbolSelectors
      .getPrivateLabelSymbolList(state)
      .filter(labelSymbol =>
        currentLabelReviewSymbolList.map(labelReviewSymbol => labelReviewSymbol.SymbolCode).includes(labelSymbol.Code),
      ),
  );

  const allSymbolList = [...publicLabelSymbolList, ...privateLabelSymbolListForLabelReviewSymbolList];

  let labelReviewSymbolList = allSymbolList.map(labelSymbol => ({
    ...(currentLabelReviewSymbolList.find(labelReviewSymbol => labelReviewSymbol.SymbolCode === labelSymbol.Code) || {
      LabelReviewSymbolId: uuidV1(),
      SymbolCode: labelSymbol.Code,
    }),
  }));

  const finalSymbolList = allSymbolList.map(labelSymbol => {
    if (editMode) return labelSymbol;
    if (!labelSymbol.Public) {
      const newCode = uuidV1();
      const labelReviewSymbol = labelReviewSymbolList.find(
        labelReviewSymbol => labelReviewSymbol.SymbolCode === labelSymbol.Code,
      );
      labelReviewSymbol.SymbolCode = newCode;
      labelReviewSymbol.LabelSymbol = newCode;
      return {
        ...labelSymbol,
        LabelSymbolId: null,
        Code: newCode,
      };
    }
    return labelSymbol;
  });

  labelReviewSymbolList = labelReviewSymbolList.map(labelReviewSymbol => {
    if (editMode) {
      return {
        ...labelReviewSymbol,
        LabelReviewId,
      };
    }

    return {
      ...labelReviewSymbol,
      LabelReviewSymbolId: uuidV1(),
      LabelReviewId: null,
    };
  });

  const result = normalize(labelReviewSymbolList, labelReviewSymbolSchemas.labelReviewSymbolListSchema);
  yield put(labelReviewSymbolWizardActions.loadLabelReviewSymbolData(result));
  const resultSymbolList = normalize(finalSymbolList, labelSymbolSchemas.labelSymbolListSchema);
  yield put(labelSymbolWizardActions.loadLabelSymbolData(resultSymbolList));
}
