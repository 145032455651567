import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'glamor';
import * as R from 'ramda';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, RadioFields } from '../../../_Common/Forms';
import { referentialSelectors } from '../../../../redux/referentials';
import { substanceInformationActions, substanceInformationSelectors } from '../../../../redux/substanceInformation';
import { requiredField, maxLength128, isInteger } from '../../../../utils/fieldValidation';

const styles = {
  textarea: css({
    '& textarea.form-control.input-sm': {
      height: '60px',
    },
  }),
};

const SubstanceInformationForm = props => {
  const { substanceInformationTypeList, onSubmit, handleSubmit } = props;

  return (
    <div className={styles.textarea}>
      <Form horizontal onSubmit={handleSubmit(onSubmit)}>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              <Field
                name="Description"
                label={`${I18n.t('substanceInformation.Description')}`}
                component={InputField}
                componentClass="textarea"
                validate={maxLength128}
              />
            </Col>
            <Col sm={12}>
              <Field
                name="Specification"
                label={`${I18n.t('substanceInformation.Specification')}`}
                component={InputField}
                componentClass="textarea"
                validate={maxLength128}
              />
            </Col>
            <Col sm={12}>
              <Field
                name="Method"
                label={`${I18n.t('substanceInformation.Method')}`}
                component={InputField}
                componentClass="textarea"
                validate={maxLength128}
              />
            </Col>
            <Col sm={10}>
              <Field
                component={RadioFields}
                label={`${I18n.t('substanceInformation.Type')}`}
                name="TypeCode"
                options={substanceInformationTypeList}
              />
            </Col>
            <Col sm={2}>
              <Field
                component={InputField}
                label={`${I18n.t('substanceInformation.Order')} *`}
                name="Order"
                validate={[requiredField, isInteger]}
              />
            </Col>
          </Row>
        </Grid>
      </Form>
    </div>
  );
};

SubstanceInformationForm.defaultProps = {
  initialValues: {},
  substanceInformationTypeList: [],
};

SubstanceInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object, //eslint-disable-line
  substanceInformationTypeList: PropTypes.array, //eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
};
const getDefaultInformationType = R.compose(R.prop('Code'), R.head);
const mapStateToProps = _state => {
  const substanceInformationTypeList = referentialSelectors.getSubstanceInformationTypes(_state);
  const defaultInformationType = getDefaultInformationType(substanceInformationTypeList);
  const defaultSubstanceInformation = { TypeCode: defaultInformationType };
  return (state, { substanceInformationId }) => {
    const substanceInformation =
      substanceInformationSelectors.getById(state)[substanceInformationId] || defaultSubstanceInformation;
    return {
      initialValues: substanceInformation,
      substanceInformationTypeList,
    };
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onSubmit: data => dispatch(substanceInformationActions.saveSubstanceInformation.request({ ...data, substanceId })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.substanceInformation,
    destroyOnUnmount: true,
  }),
)(SubstanceInformationForm);
