import PropTypes from 'prop-types';
import React from 'react';
import {Form, Row, Col, Grid, ControlLabel, FormGroup, Button} from 'react-bootstrap';
import {Field, reduxForm} from 'redux-form';
import idx from 'idx';
import {I18n, Translate} from 'react-redux-i18n';
import BaseLoader from '../_Common/BaseLoader';
import formNames from '../../constants/formNames';
import {InputField, DateField, SelectField, CheckBoxField} from '../_Common/Forms';
import StepComponent from '../_Common/StepComponent';
import ProductMosCalculationTimeRange from './ProductMosCalculationTimeRange';
import CollapsePanelLink from '../_Common/CollapsePanelLink';
import DetailHeader from '../_Common/DetailHeader';
import PrintProductDocumentsButton from './PrintProductDocumentsButton';
import {requiredField, maxLength128, maxLength255} from '../../utils/fieldValidation';
import GoToParentButton from './GoToParentButton';
import {css} from 'glamor';

const stylesMemo = css({
  '& textarea.form-control.input-sm': {
    height: '195px',
  },
});

const ProductForm = props => {
  const {
    handleSubmit,
    onSaveProduct,
    productTitle,
    companyList,
    isLoadingDetails,
    disabledFields,
    workflowStatusList,
    onChangeStatus,
    productStatus,
    isProductEditable,
    parentProductId,
    isNew,
    showStatusHistory,
    showDetails,
    onShowDetails,
    invalid,
    dirty,
    showGoToParrentButton,
    cpsrBTypeList,
    mosCalculationTypeList,
    inDuplicateMode,
    productId,
    onTranslateClick,
  } = props;

  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveProduct)}>
        <DetailHeader
          title={`${I18n.t('product.panelTitle')} : ${productTitle}`}
          showDuplicate={!isNew && !inDuplicateMode}
          {...props}
        >
          <Grid fluid>
            <Row>
              <Col sm={3}>
                <Field
                  name="ProductName"
                  label={`${I18n.t('product.productName')} *`}
                  component={InputField}
                  readOnly={!isProductEditable}
                  validate={[requiredField, maxLength255]}
                />
              </Col>
              <Col sm={3}>
                <Field name="Code" label={I18n.t('product.cosmosCode')} component={InputField} readOnly/>
              </Col>
              <Col sm={1}>
                <Field
                  name="CreatedOn"
                  label={I18n.t('product.creationDate')}
                  component={DateField}
                  labelColSize={4}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={2}>
                <ControlLabel>{I18n.t('product.Status')}</ControlLabel>
                <StepComponent
                  label={I18n.t('product.status')}
                  options={workflowStatusList}
                  value={productStatus}
                  onChange={onChangeStatus}
                  disabled={isNew || dirty}
                  onHistoryClick={showStatusHistory}
                />
              </Col>
              <Col sm={1}>
                <Field name="IsActive" label={I18n.t('product.isActive')} component={CheckBoxField}/>
              </Col>
              <Col sm={2}>
                <Button bsSize="small" onClick={onTranslateClick}>
                  {I18n.t('product.translateButton')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Field
                  name="OtherName"
                  label={I18n.t('product.usualName')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="FormulaCode"
                  label={I18n.t('product.formulaNumber')}
                  component={InputField}
                  validate={maxLength128}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="ValidatedOn"
                  label={I18n.t('product.validationDate')}
                  component={DateField}
                  labelColSize={4}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={1}>
                <Field name="ValidatedBy" label={I18n.t('product.validatedBy')} component={InputField} readOnly/>
              </Col>
              <Col sm={1}>
                <FormGroup>
                  <ControlLabel>&nbsp;</ControlLabel>
                  <PrintProductDocumentsButton productId={productId}/>
                </FormGroup>
              </Col>
              <Col sm={2} smOffset={1}>
                {showGoToParrentButton && (
                  <FormGroup bsSize="small">
                    <ControlLabel>&nbsp;</ControlLabel>
                    <div>
                      <GoToParentButton parentProductId={parentProductId}/>
                    </div>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <Field
                  name="ProducerId"
                  label={`${I18n.t('product.producerName')} *`}
                  component={SelectField}
                  options={companyList}
                  labelField="ShortName"
                  valueField="CompanyId"
                  disabled={idx(disabledFields, _ => _.ProducerId) || !isProductEditable}
                  validate={requiredField}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="ManufacturerId"
                  label={I18n.t('product.manufacturerName')}
                  component={SelectField}
                  options={companyList}
                  labelField="ShortName"
                  valueField="CompanyId"
                  disabled={!isProductEditable}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="PackagerId"
                  label={I18n.t('product.packagerName')}
                  component={SelectField}
                  options={companyList}
                  labelField="ShortName"
                  valueField="CompanyId"
                  disabled={!isProductEditable}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CosmosCompositionOwnerId"
                  label={`${I18n.t('product.cosmosCompositionOwner')}`}
                  component={SelectField}
                  options={companyList}
                  labelField="ShortName"
                  valueField="CompanyId"
                  disabled={!isProductEditable}
                />
              </Col>
            </Row>
            <CollapsePanelLink
              defaultCollapsed={!showDetails}
              onExpandPanel={onShowDetails}
              disabled={invalid && showDetails}
            >
              <div>
                <Row>
                  <Col sm={3} className={stylesMemo.toString()}>
                    <Field
                      name="Memo"
                      label={I18n.t('product.memo')}
                      component={InputField}
                      componentClass="textarea"
                      readOnly={!isProductEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="MosCalculationType"
                      label={I18n.t('product.MosCalculationType')}
                      component={SelectField}
                      options={mosCalculationTypeList}
                      labelField="Value"
                      valueField="Code"
                      disabled={!isProductEditable}
                    />
                    <Field
                      component={ProductMosCalculationTimeRange}
                      label={`${I18n.t('product.mosCalculation')}`}
                      name="MosCalculationTimeRangeCode"
                      disabled={!isProductEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <ControlLabel>
                      <Translate value="product.inciOptions"/>
                    </ControlLabel>
                    <Field
                      name="MicaInd"
                      label={I18n.t('product.MicaInd')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isProductEditable}
                    />
                    <Field
                      name="InciRangeInd"
                      label={I18n.t('product.InciRangeInd')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isProductEditable}
                    />
                    <Field
                      name="ManualInciInd"
                      label={I18n.t('product.ManualInciInd')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isProductEditable}
                    />
                    <Field
                      name="CPSRBType"
                      component={SelectField}
                      options={cpsrBTypeList}
                      labelField="Value"
                      valueField="Key"
                      disabled={!isProductEditable}
                    />
                    <br/>
                    <br/>
                    <Field
                      name="WithSpf"
                      label={I18n.t('product.withSpf')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isProductEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <ControlLabel>
                        <Translate value="product.cpnpStatus"/>
                      </ControlLabel>
                    <Field
                      name="IsNoLongerOnMarket"
                      label={I18n.t('product.IsNoLongerOnMarket')}
                      component={CheckBoxField}
                      formAlignment={false}
                      />
                    <Field
                      name="IsWaitingNotification"
                      label={I18n.t('product.isWaiting')}
                      component={CheckBoxField}
                      formAlignment={false}
                    />
                  </Col>
                  <Col>
                    <Field
                      name="ResponsibleUntil"
                      label={I18n.t('product.responsibleUntil')}
                      component={DateField}
                      labelColSize={4}
                      disabled={!isProductEditable}
                    />
                  </Col>
                </Row>
              </div>
            </CollapsePanelLink>
          </Grid>
        </DetailHeader>
      </Form>
    </BaseLoader>
  );
};

ProductForm.defaultProps = {
  isLoadingDetails: false,
  disabledFields: {},
  productStatus: '',
  parentProductId: null,
  showStatusHistory: undefined,
};

ProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveProduct: PropTypes.func.isRequired,
  productTitle: PropTypes.string.isRequired,
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  isLoadingDetails: PropTypes.bool,
  showDetails: PropTypes.bool.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  disabledFields: PropTypes.object, //eslint-disable-line
  change: PropTypes.func.isRequired,
  workflowStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      WorkflowStatusId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  productStatus: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  showStatusHistory: PropTypes.func,
  invalid: PropTypes.bool.isRequired,
  cpsrBTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  mosCalculationTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  productId: PropTypes.number.isRequired,
  parentProductId: PropTypes.number,
  inDuplicateMode: PropTypes.bool.isRequired,
  isProductEditable: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  showGoToParrentButton: PropTypes.bool.isRequired,
  onTranslateClick: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formNames.product,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ProductForm);
