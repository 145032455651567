import Loader from 'react-loader-advanced';
import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';

const BaseLoader = ({ isLoading, icon, children, minHeight }) => {
  const styles = {
    container: css({
      minHeight,
    }),
  };

  return (
    <Loader
      contentBlur={1}
      show={isLoading}
      message={
        <span>
          <i className={`${icon}`} />
        </span>
      }
    >
      <div className={styles.container}>{children}</div>
    </Loader>
  );
};

BaseLoader.defaultProps = {
  isLoading: false,
  icon: 'fa fa-refresh fa-spin fa-4x',
  minHeight: '100px',
};

BaseLoader.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  minHeight: PropTypes.string,
};

export default BaseLoader;
