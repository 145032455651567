import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import ProductList from './ProductList';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class RawMatActionsModal2 extends React.Component {
  //= props => {
  setGrid = g => {
    this.grid = g;
  };
  handleOnSave = () => {
    if (this.grid) {
      this.props.onSaveButtonClick(this.grid.api.getSelectedNodes());
    }
  };

  render() {
    const { title, closeModal, productId, saveButtonDisabled } = this.props;
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductList
            height="60vh"
            productId={productId}
            whenGridReady={this.setGrid}
            onRowDoucleClicked={this.handleOnSave}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />
                &nbsp;
                <Translate value="general.close" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="button"
                onClick={this.handleOnSave}
                disabled={saveButtonDisabled}
              >
                <Translate value="general.save" />
                &nbsp;
                <i className="fa fa-arrow-circle-right" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

RawMatActionsModal2.defaultProps = {};

RawMatActionsModal2.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  // whenGridReady: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  saveButtonDisabled: PropTypes.bool.isRequired,
};

export default RawMatActionsModal2;
