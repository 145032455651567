import { createRequestActions, createFetchAction } from '../utils';
import * as ToxProfileItemTypes from './toxProfileItemTypes';

export const listToxProfileItems = createFetchAction(ToxProfileItemTypes.TOXPROFILEITEM_LIST);
export const saveToxProfileItem = createRequestActions(
  ToxProfileItemTypes.SAVE_TOXPROFILEITEM,
  (substanceId, data) => ({
    substanceId,
    data,
  }),
);
export const deleteToxProfileItem = createRequestActions(
  ToxProfileItemTypes.DELETE_TOXPROFILEITEM,
  (substanceId, Id) => ({
    substanceId,
    Ids: Array.isArray(Id) ? Id : [Id],
  }),
);
