import * as phraseCpsrChapterSelectors from './phraseCpsrChapterSelectors';
import * as phraseCpsrChapterActions from './phraseCpsrChapterActions';
import * as phraseCpsrChapterTypes from './phraseCpsrChapterTypes';
import * as phraseCpsrChapterSchema from './phraseCpsrChapterSchemas';
import sagas, * as phraseCpsrChapterSagas from './phraseCpsrChapterSagas';
import reducer from './phraseCpsrChapterReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  phraseCpsrChapterSelectors,
  phraseCpsrChapterActions,
  phraseCpsrChapterTypes,
  phraseCpsrChapterSchema,
  phraseCpsrChapterSagas,
};
export default reducer;
