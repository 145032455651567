import * as productProportionSelectors from './productProportionSelectors';
import * as productProportionActions from './productProportionActions';
import * as productProportionTypes from './productProportionTypes';
import * as productProportionSchema from './productProportionSchemas';
import sagas, * as productProportionSagas from './productProportionSagas';
import reducer from './productProportionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  productProportionSelectors,
  productProportionActions,
  productProportionTypes,
  productProportionSchema,
  productProportionSagas,
};
export default reducer;
