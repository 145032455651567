import {createRequestTypes} from "../../../utils";

export const LOAD_LABEL_REVIEW_SYMBOL_DATA = 'labelReviewSymbolWizard/LOAD_LABEL_REVIEW_SYMBOL_DATA';
export const FIELD_EDITED = 'labelReviewSymbolWizard/FIELD_EDITED';
export const UPDATE_LABEL_REVIEW_SYMBOL = 'labelReviewSymbolWizard/UPDATE_LABEL_REVIEW_SYMBOL';
export const ADD_BUTTON_CLICKED = 'labelReviewSymbolWizard/ADD_BUTTON_CLICKED';
export const DELETE_BUTTON_CLICKED = 'labelReviewSymbolWizard/DELETE_BUTTON_CLICKED';
export const REVERT_BUTTON_CLICKED = 'labelReviewSymbolWizard/REVERT_BUTTON_CLICKED';
export const ADD_NEW_LABEL_REVIEW_SYMBOL = 'labelReviewSymbolWizard/NEW_LABEL_REVIEW_SYMBOL';
export const REMOVE_LABEL_REVIEW_SYMBOL = 'labelReviewSymbolWizard/REMOVE_LABEL_REVIEW_SYMBOL';
export const UPLOAD_SYMBOL_IMAGE = createRequestTypes('labelReviewSymbolWizard/UPLOAD_SYMBOL_IMAGE');
export const UPDATE_SYMBOL_DOCUEMNTID = 'labelReviewSymbolWizard/UPDATE_SYMBOL_DOCUEMNTID';
