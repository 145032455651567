import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import * as cpsrCharptersActions from './cpsrChaptersActions';
import * as cpsrCharptersTypes from './cpsrChaptersTypes';
import cpsrChaptersApi from '../../api/cpsrChaptersApi';
import { cpsrChapterListSchema } from './cpsrChaptersSchemas';

export default function* root() {
  yield all([takeLatest(cpsrCharptersTypes.CPSR_CHAPTER_LIST.REQUEST, getToxProfileChapterListSaga)]);
}

function* getToxProfileChapterListSaga({ payload }) {
  yield put(applicationActions.setLoading(cpsrCharptersTypes.CPSR_CHAPTER_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    cpsrCharptersActions.listCpsrChapter,
    cpsrChaptersApi.getCpsrChaptersList,
    cpsrChapterListSchema,
  );
  yield put(applicationActions.unsetLoading(cpsrCharptersTypes.CPSR_CHAPTER_LIST.REQUEST));
}
