import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { applicationSelectors } from '../../../../redux/application';
import { SelectionGrid, DeleteButtonRenderer } from '../../../_Common/Grids';
import formNames from '../../../../constants/formNames';
import {
  substanceCosAnnexeActions,
  substanceCosAnnexeSelectors,
  substanceCosAnnexeTypes,
} from '../../../../redux/substanceCosAnnexe';

const formName = formNames.substanceCosAnnexeForm;

class SubstanceCosAnnexeList extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    const {
      substanceCosAnnexes,
      isLoading,
      height,
      fetchSubstanceCosAnnexes,
      onSubstanceCosAnnexeSelected,
      onAddButtonClick,
      whenGridReady,
      onDeleteButtonClick,
    } = this.props;

    const columns = [
      {
        headerName: I18n.t('substanceCosAnnexe.CasNr'),
        field: 'CasNr',
      },
      {
        headerName: I18n.t('substanceCosAnnexe.EcNr'),
        field: 'EcNr',
      },
      {
        headerName: I18n.t('substanceCosAnnexe.Inci'),
        field: 'Inci',
      },
      {
        headerName: I18n.t('substanceCosAnnexe.Ratio'),
        field: 'Ratio',
      },
      {
        headerName: I18n.t('general.actions'),
        cellRendererFramework: DeleteButtonRenderer,
        cellRendererParams: {
          onClick: onDeleteButtonClick,
        },
      },
    ];

    return (
      <SelectionGrid
        data={substanceCosAnnexes}
        keyName="SubstanceCosAnnexeId"
        columns={columns}
        isLoading={isLoading}
        onRefresh={fetchSubstanceCosAnnexes}
        showAddButton
        onAdd={onAddButtonClick}
        whenGridReady={whenGridReady}
        onSelected={onSubstanceCosAnnexeSelected}
        height={height}
      />
    );
  }
}

const mapStateToProps = (state, { cosAnnexeCode }) => {
  const getSubstanceCosAnnexes = substanceCosAnnexeSelectors.makeGetByQuery(state);
  return {
    substanceCosAnnexes: getSubstanceCosAnnexes({ cosAnnexeCode }),
    isLoading: applicationSelectors.getIsLoading(
      substanceCosAnnexeSelectors.makeGetByQueryLoading({ cosAnnexeCode }),
      substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE.REQUEST,
      isSubmitting(formName),
    )(state),
  };
};

const mapDispatchToProps = (dispatch, { cosAnnexeCode }) => ({
  onLoad: () => {
    if (cosAnnexeCode) {
      dispatch(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.request({ cosAnnexeCode }));
    }
  },
  fetchSubstanceCosAnnexes: () =>
    dispatch(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.request({ cosAnnexeCode })),
  onDeleteButtonClick: ({ SubstanceCosAnnexeId }) =>
    dispatch(substanceCosAnnexeActions.deleteSubstanceCosAnnexe.request(SubstanceCosAnnexeId)),
});

SubstanceCosAnnexeList.defaultProps = {
  height: '',
};

SubstanceCosAnnexeList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  height: PropTypes.string,
  fetchSubstanceCosAnnexes: PropTypes.func.isRequired,
  onSubstanceCosAnnexeSelected: PropTypes.func.isRequired,
  substanceCosAnnexes: PropTypes.array.isRequired, //eslint-disable-line
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubstanceCosAnnexeList);
