import { schema } from 'normalizr';
import * as actionSchemas from '../action/actionSchemas';

export const rawMatActionSchema = new schema.Entity(
  'rawMatActions',
  { Action: actionSchemas.actionSchema },
  { idAttribute: 'RawMatActionId' },
);

export const rawMatActionListSchema = [rawMatActionSchema];
