import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { ErrorListContainer } from './ErrorList';
// import PhraseListContainer from './ErrorList/_PhraseListContainer';

const Errors = props =>
  (<Grid fluid>
    <Row>
      <Col md={12}>
        <ErrorListContainer {...props} />
      </Col>
    </Row>
  </Grid>);

export default Errors;
