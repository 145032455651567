import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';

const FormulaConclusion = ({ conclusion: { Key, Value }, onChange, selectedValue }) => {
  const handleRadioButtonChange = ({ target }) => onChange({ Conclusion: target.value });
  return (
    <Radio
      bsSize="large"
      name="ConclusionRadio"
      checked={selectedValue === Key}
      value={Key}
      onChange={handleRadioButtonChange}>
      {Value}
    </Radio>
  );
};

FormulaConclusion.propTypes = {
  conclusion: PropTypes.shape({
    Key: PropTypes.string.isRequired,
    Value: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

const FormulaReviewConclusionList = ({
  conclusionList,
  updateFormulaReview,
  formulaReview: { Conclusion: formulaConclusion },
}) => (
  <div>
    {conclusionList.map(conclusion => (
      <FormulaConclusion
        key={conclusion.Key}
        conclusion={conclusion}
        selectedValue={formulaConclusion}
        onChange={updateFormulaReview}
      />
    ))}
  </div>
);

FormulaReviewConclusionList.propTypes = {
  conclusionList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  updateFormulaReview: PropTypes.func.isRequired,
  formulaReview: PropTypes.shape({
    Conclusion: PropTypes.string.isRequired,
  }).isRequired,
};

export default FormulaReviewConclusionList;
