import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'glamor';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, FormControl, ControlLabel, Modal, Button, ButtonToolbar } from 'react-bootstrap';
import BaseLoader from '../../_Common/BaseLoader';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
  form: css({
    '& textarea.form-control': {
      height: '100px',
    },
  }),
};

class CommentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  handleCommentChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  render() {
    const { title, message, label, isLoading, onConfirm, closeModal, validate } = this.props;

    const saveButtonDisabled = !validate(this.state.comment) || isLoading;

    const handleSave = () => onConfirm(this.state.comment);

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseLoader isLoading={isLoading}>
            {message && <p>{message}</p>}
            <div className={styles.form}>
              <FormGroup controlId="Change_Product_Composition">
                <ControlLabel>{label}</ControlLabel>
                <FormControl
                  bsSize="small"
                  componentClass="textarea"
                  value={this.state.comment}
                  onChange={this.handleCommentChange}
                  placeholder={I18n.t('general.enterComment')}
                />
              </FormGroup>
            </div>
          </BaseLoader>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />&nbsp;
                <Translate value="general.close" />
              </Button>
              <Button bsSize="small" bsStyle="success" disabled={saveButtonDisabled} type="button" onClick={handleSave}>
                <i className="fa fa-floppy-o" />&nbsp;
                <Translate value="general.save" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Body>
      </div>
    );
  }
}

CommentModal.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  label: PropTypes.string,
  validate: PropTypes.func,
};

CommentModal.defaultProps = {
  message: '',
  label: 'Comment',
  validate: () => true,
};

export default CommentModal;
