import React from 'react';
import PropTypes from 'prop-types';
import { isSubmitting, isInvalid, isPristine, submit } from 'redux-form';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import formNames from '../../../../constants/formNames';
import { substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { substanceToxProfileTypes } from '../../../../redux/substanceToxProfile';
import SubstanceToxProfileModal from './SubstanceToxProfileModal';

const formName = formNames.substanceToxProfile;
const isAnyTrue = R.any(R.equals(true));

const SubstanceToxProfileModalContainer = props => <SubstanceToxProfileModal {...props} />;

const mapStateToProps = (state, { substanceId }) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  const substance = substanceSelectors.getById(state)[substanceId];
  const substanceName = R.propOr('', 'InciName', substance);
  return {
    title: I18n.t('substanceToxicologicalProfileModal.title', { substanceName }),
    hasErrors: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    isLoading: applicationSelectors.isLoading(
      state,
      substanceToxProfileTypes.FETCH_SUBSTANCE_TOX_PROFILES.REQUEST,
      substanceToxProfileTypes.SAVE_SUBSTANCE_TOX_PROFILE.REQUEST,
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formName)),
});

SubstanceToxProfileModalContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceToxProfileModalContainer);
