import { createRequestActions } from '../utils';
import * as shadeInciListTypes from './shadeInciListTypes';

export const getShadesInciListForProduct = createRequestActions(
  shadeInciListTypes.SHADES_INCI_LIST_FOR_PRODUCT,
  ProductId => ({
    ProductId,
  }),
  (productInciList, ProductId) => ({ ProductId }),
);
