import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form, Grid, Row, Col, Panel, Badge, OverlayTrigger } from 'react-bootstrap';
import { Field, reduxForm, FormSection } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField, ButtonField } from '../../../_Common/Forms';
import { RawMatListDropdown } from '../../../_Common/Dropdowns';
import { RangeFormatsTooltip } from '../../../_Common/Tooltips';
import RawMatBasicFormSection from './RawMatBasicFormSection';
import { isNullOrUndef } from '../../../../utils/utils';
import formNames from '../../../../constants/formNames';
import { productProportionFormActions } from '../../../../redux/form/productProportionForm';
import {
  requiredField,
  isPositiveOrZeroInteger,
  isPositiveOrZeroDecimal,
  isValidRange,
  maxLength512,
} from '../../../../utils/fieldValidation';

class ProductProportionForm extends React.Component {
  state = { createNewRawMatMode: isNullOrUndef(this.props.rawMatId) };
  // state = { createNewRawMatMode: isNullOrUndef(this.props.rawMatId) && isNullOrUndef(this.props.RawMatId) };

  componentWillReceiveProps(nextProps) {
    if (this.props.rawMatId !== nextProps.rawMatId) {
      this.setState({ createNewRawMatMode: isNullOrUndef(nextProps.rawMatId) });
    }
  }

  render() {
    const {
      handleSubmit,
      ProductProportionId,
      onRawMatSelected,
      rawMatList,
      isInEditing,
      onAddButtonClicked,
      onSubmit,
      ...otherProps
    } = this.props;

    /**
     * @return {boolean}
     */
    const isRawMatSelected = () => !isNullOrUndef(this.props.rawMatId);

    const handleRawMatChange = (e, rawMatId) => {
      this.setState({ createNewRawMatMode: false });
      this.props.onRawMatSelected(rawMatId);
    };

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col sm={11}>
                  <Field
                    name="RawMatId"
                    label={I18n.t('general.rawMat')}
                    component={RawMatListDropdown}
                    initialOptions={rawMatList}
                    onChange={handleRawMatChange}
                    // disabled={!isNullOrUndef(ProductProportionId)}
                  />
                </Col>
                <Col sm={1}>
                  <ButtonField
                    bsStyle="default"
                    data-for="addTip"
                    bsSize="small"
                    disabled={!isRawMatSelected()}
                    onClick={() => {
                      this.setState({ createNewRawMatMode: true });
                      onAddButtonClicked();
                    }}
                  >
                    <i className="fa fa-plus" />
                  </ButtonField>
                </Col>
              </Row>
              <br />
              <Row>
                <Panel
                  header={
                    <span>
                      {I18n.t('productProportion.rawMatDetailsHeader')}
                      &nbsp;
                      {this.state.createNewRawMatMode && (
                        <Badge>{I18n.t('productProportion.createNewRawMatModeLabel')}</Badge>
                      )}
                    </span>
                  }
                  bsStyle="primary"
                >
                  <FormSection name="RawMat" {...this.props}>
                    <RawMatBasicFormSection
                      {...otherProps}
                      readOnly={isRawMatSelected() && !this.state.createNewRawMatMode}
                    />
                  </FormSection>
                </Panel>
              </Row>
              {/* <hr /> */}
              <Row>
                <Panel header={I18n.t('productProportion.productProportionHeader')} bsStyle="primary">
                  <Row>
                    <Col sm={3}>
                      <Field
                        name="Percentage"
                        label={`${I18n.t('productProportion.percentage')} *`}
                        component={InputField}
                        inputGroupLabel="%"
                        validate={[requiredField, isPositiveOrZeroDecimal]}
                      />
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="PercentageRange"
                        placeholder={I18n.t('productProportion.percentageRange')}
                        label={
                          <span>
                            {I18n.t('productProportion.percentageRange')}
                            &nbsp;
                            <OverlayTrigger placement="top" overlay={RangeFormatsTooltip()}>
                              <span>
                                <i className="fa fa-question-circle" aria-hidden="true" />
                              </span>
                            </OverlayTrigger>
                          </span>
                        }
                        component={InputField}
                        validate={isValidRange}
                      />
                    </Col>
                    <Col sm={3}>
                      <Field name="Phase" label={I18n.t('productProportion.phase')} component={InputField} />
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="Order"
                        label={`${I18n.t('productProportion.order')} *`}
                        component={InputField}
                        validate={[requiredField, isPositiveOrZeroInteger]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Field
                        name="ap"
                        label={I18n.t('general.comment')}
                        component={InputField}
                        componentClass="textarea"
                        validate={maxLength512}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

ProductProportionForm.defaultProps = {
  ProductProportionId: undefined,
  rawMatId: null,
};

ProductProportionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAddButtonClicked: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rawMatId: PropTypes.number,
  isInEditing: PropTypes.bool.isRequired,
  rawMatList: PropTypes.arrayOf(
    PropTypes.shape({
      RawMatId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
      SupplierName: PropTypes.string.isRequired,
      RawMatSupplierId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  change: PropTypes.func.isRequired,
  ProductProportionId: PropTypes.number,
  onRawMatSelected: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => productProportionFormActions.submitProductProportionForm({ ...data, productId }, dispatch),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.productProportionForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProductProportionForm);
