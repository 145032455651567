import { createRequestActions } from '../utils';
import * as companyDocumentTypes from './companyDocumentTypes';

export const saveCompanyDocument = createRequestActions(
  companyDocumentTypes.SAVE_COMPANY_DOCUMENT,
  (CompanyId, data) => ({
    CompanyId,
    ...data,
  }),
);

export const saveCompanyNotificationData = createRequestActions(
  companyDocumentTypes.SAVE_COMPANY_NOTIFICATION_DATA,
  (CompanyId, data) => ({
    CompanyId,
    ...data,
  }),
);

export const uploadCompanyDocument = createRequestActions(
  companyDocumentTypes.UPLOAD_COMPANY_DOCUMENT,
  (CompanyId, data) => ({
    CompanyId,
    ...data,
  }),
);

export const uploadCompanyNotificationData = createRequestActions(
  companyDocumentTypes.UPLOAD_COMPANY_NOTIFICATION_DATA,
  (CompanyId, data) => ({
    CompanyId,
    ...data,
  }),
);

export const listCompanyDocumentsForCompany = createRequestActions(
  companyDocumentTypes.COMPANY_DOCUMENT_LIST_FOR_COMPANY,
  CompanyId => ({ CompanyId }),
  (CompanyDocuments, CompanyId) => ({ CompanyId }),
);

export const deleteCompanyDocument = createRequestActions(
  companyDocumentTypes.DELETE_COMPANY_DOCUMENT,
  CompanyDocumentId => ({ CompanyDocumentId }),
);
