import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { userRoleActions, userRoleTypes } from '../../../../redux/userRole';
import TaskModal from './TaskModal';
import { taskTypeActions, taskTypeTypes } from '../../../../redux/taskType';
import { applicationSelectors } from '../../../../redux/application';
import { substanceToxProfileTypes } from '../../../../redux/substanceToxProfile';

const formName = formNames.task;
const isAnyTrue = R.any(R.equals(true));

class TaskModalContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <TaskModal {...this.props} />;
  }
}

const mapStateToProps = (state, { manager }) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: manager ? I18n.t('orderTask.modalTitle') : I18n.t('toDoList.reassignTitle'),
    manager,
    isLoading: applicationSelectors.isLoading(
      state,
      userRoleTypes.USER_ROLE_LIST_FOR_ROLES.REQUEST,
      taskTypeTypes.TASKTYPE_LIST.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { orderId, ProcessId, manager }) => ({
  onLoad: () => {
    if (manager) {
      dispatch(userRoleActions.listUserRoleForRoles.request([userRoleTypes.expert]));
      dispatch(taskTypeActions.listTaskTypes.request({ OptIncludeOther: true }));
    }
  },
  onSaveButtonClick: () => {
    dispatch(submit(formName))
  },
});

TaskModalContainer.propTypes = {
  taskId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  manager: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskModalContainer);
