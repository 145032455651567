import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as shadeInciListTypes from './shadeInciListTypes';

const byId = handleActions(
  {
    [shadeInciListTypes.SHADES_INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload, meta: { ProductId } }) => ({
      ...state,
      ...payload.entities.inciList,
    }),
  },
  {},
);
const inciSubstance = handleActions(
  {
    [shadeInciListTypes.SHADES_INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload, meta: { ProductId } }) => ({
      ...state,
      ...payload.entities.inciSubstances,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [shadeInciListTypes.SHADES_INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  inciSubstance,
  list: combineReducers({
    allIds,
  }),
});
