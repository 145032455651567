import { createSelector } from 'reselect';
import * as R from 'ramda';
import * as distributorSelectors from '../distributor/distributorSelectors';
import config from '../../config';


export const getById = ({ companyDistributorAgreement }) => companyDistributorAgreement.byId;
export const getIds = ({ companyDistributorAgreement }) => companyDistributorAgreement.list.allIds;
export const getByDistributorIds = ({ companyDistributorAgreement }) =>
  companyDistributorAgreement.list.byDistributorIds;
export const getByCompanyIds = ({ companyDistributorAgreement }) => companyDistributorAgreement.list.byCompanyIds;
export const getCompanyDistributorAgreement = (state, id) => getById(state)[id];
export const getDistributorDocument = (state, id) => getById(state)[id];
export const getCompanyDistributorAgreementList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

const getIdsForDistributor = distributorId =>
  createSelector(getByDistributorIds, byDistributorIds => R.defaultTo([], byDistributorIds[distributorId]));

export const getCompanyDistributorAgreementsForDistributor = distributorId =>
  createSelector([getIdsForDistributor(distributorId), getById], (IdsForDistributor, byId) =>
    IdsForDistributor.map(id => byId[id]),
  );

export const makeGetCompanyDistributorAgreementsForDistributor = createSelector(
  getCompanyDistributorAgreementsForDistributor,
  R.identity,
);

const getIdsForCompany = companyId =>
  createSelector(getByCompanyIds, byCompanyIds => R.defaultTo([], byCompanyIds[companyId]));

export const getCompanyDistributorAgreementsForCompany = companyId =>
  createSelector(
    [getIdsForCompany(companyId), getById, distributorSelectors.getDistributorList],
    (IdsForCompany, byId, distributorList) =>
      IdsForCompany.map(id => {
        const companyDistributorAgreement = byId[id];
        if (companyDistributorAgreement != null) {
          const distributor = distributorList.find(
            elem => elem.DistributorId == companyDistributorAgreement.DistributorId,
          );
          companyDistributorAgreement.Distributor = distributor;
        }
        return companyDistributorAgreement;
      }),
  );

export const makeGetCompanyDistributorAgreementsForCompany = createSelector(
  getCompanyDistributorAgreementsForCompany,
  companyDistributorAgreementsForCompany => companyDistributorAgreementsForCompany,
);
