import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import EditButton from './../../_Common/Grids/EditButton';
import DeleteButton from './../../_Common/Grids/DeleteButton';
import { I18n } from 'react-redux-i18n';

const RegionCellRenderer = props => {
  const { onProcessEditButtonClick, node, onProcessDeleteButtonClick, onProcessAddButtonClick, data } = props;
  // console.log('DATA', data);
  return (
    <div>
      {node.level === 1 &&
        data.ProcessId !== -1 && (   
          <div style={{ display: 'flex', widht: '100%', justifyContent: 'space-between' }}>          
          <label style={{display: 'none'}}>{data.ProductCode !== null ? data.ProductCode : I18n.t('orderProcess.unknown')}</label>
          <div>{data.ProductCode !== null ? data.ProductCode : I18n.t('orderProcess.unknown')}&nbsp;-&nbsp;
          {data.ProductName !== null ? data.ProductName : I18n.t('orderProcess.unknown')}</div>
          <ButtonToolbar style={{ textAlign: 'center', widht: '100%' }}>
            <EditButton onClick={() => onProcessEditButtonClick(data)} {...props} />
            <DeleteButton onClick={() => onProcessDeleteButtonClick(data)} {...props} />
          </ButtonToolbar>
        </div>
      )}
      {node.level === 1 &&
        data.ProcessId === -1 && (
        <div style={{ display: 'flex', widht: '100%', justifyContent: 'flex-end' }}>
          <Button bsStyle="default" bsSize="xsmall" type="button" onClick={() => onProcessAddButtonClick(data)}>
            <i className="fa fa-plus" />
              &nbsp;
          </Button>
        </div>
      )}
    </div>
  );
};

RegionCellRenderer.defaultProps = {
  node: {},
};

RegionCellRenderer.propTypes = {
  node: PropTypes.shape({
    level: PropTypes.number,
  }).isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
  onProcessEditButtonClick: PropTypes.func.isRequired,
  onProcessDeleteButtonClick: PropTypes.func.isRequired,
};

export default RegionCellRenderer;
