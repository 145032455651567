import queryString from 'query-string';
import { post, get, del } from './apiUtils';

const defaultUrl = '/OrdersProducts/';

export default {
  saveOrderProducts: data => post(defaultUrl, data),
  getOrdersProducts: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  deleteOrderProduct: query => del(`${defaultUrl}?${queryString.stringify(query)}`),
};
