import * as categoryFormSelectors from './categoryFormSelectors';
import * as categoryFormActions from './categoryFormActions';
import * as categoryFormTypes from './categoryFormTypes';
import sagas from './categoryFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { categoryFormSelectors, categoryFormActions, categoryFormTypes };
