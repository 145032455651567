import React from 'react';
import PropTypes from 'prop-types';
import RawPhraseListContainer from './_RawPhraseListContainer';

const RawMatInformationTab = ({ rawMatId }) => (
  <div>
    <RawPhraseListContainer rawMatId={rawMatId} />
  </div>
);

RawMatInformationTab.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default RawMatInformationTab;
