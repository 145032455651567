import { takeEvery, all } from 'redux-saga/effects';
import * as labelReviewTypes from "../labelReview/labelReviewTypes";
import {callApiSaga} from "../sagaUtils";
import {documentActions} from "../document";
import documentApi from "../../api/documentApi";

export default function* root() {
  yield all([
    takeEvery(labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS, getLabelSymbolImage,
    ),
  ]);
}

function* getLabelSymbolImage({ payload }) {
  if(payload.entities && payload.entities.labelSymbols){
    const docIds = [];
    Object.keys(payload.entities.labelSymbols).map(symbolKey => {
      if(payload.entities.labelSymbols[symbolKey].DocumentId) {
        docIds.push(payload.entities.labelSymbols[symbolKey].DocumentId);
      }
    });
    yield all([
      docIds.map(id => callApiSaga(
        documentActions.getDocumentImage,
        documentApi.getDocumentBytes,
        id,
      ))
    ])
  }
}
