import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as taskTypeTypes from './taskTypeTypes';

export const saveTaskType = createRequestActions(taskTypeTypes.SAVE_TASKTYPE);
export const deleteTaskType = createRequestActions(taskTypeTypes.DELETE_TASKTYPE, taskTypeId => ({ taskTypeId }));

export const listTaskTypes = createRequestActions(taskTypeTypes.TASKTYPE_LIST);
export const addTaskType = createAction(taskTypeTypes.ADD_TASKTYPE, () => ({ TaskTypeId: undefined }));
export const editTaskType = createAction(taskTypeTypes.EDIT_TASKTYPE, TaskTypeId => ({
  TaskTypeId,
}));
