import { createSelector } from 'reselect';

export const getIds = ({ substanceProportion }) => substanceProportion.list.allIds;
export const getById = ({ substanceProportion }) => substanceProportion.byId;
export const getSubstanceProportionList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);
export const getSubstanceProportion = (state, id) => state.substanceProportion.byId[id];

export const getSubstanceProportionListForSubstance = (state, substanceId) =>
  getSubstanceProportionList(state).filter(sp => sp.SubstanceId === substanceId);
