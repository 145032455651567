import * as generalDashboardSelectors from './generalDashboardSelectors';
import * as generalDashboardActions from './generalDashboardActions';
import * as generalDashboardTypes from './generalDashboardTypes';
import * as generalDashboardSchemas from './generalDashboardSchemas';

import sagas, * as generalDashboardSagas from './generalDashboardSagas';
import reducer from './generalDashboardReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { generalDashboardSelectors, generalDashboardActions, generalDashboardTypes, generalDashboardSchemas, generalDashboardSagas };
export default reducer;
