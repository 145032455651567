import { createSelector } from 'reselect';
import * as productSelectors from '../product/productSelectors';
import { createGeneralSelectors } from '../_common/selectors';
import { isNullOrUndef } from '../../utils/utils';
import * as R from 'ramda';

const getNotificationSlice = state => state.notification;

export const { makeGetByQueryLoading, makeGetByQuery } = createGeneralSelectors(getNotificationSlice);

export const getById = ({ notification }) => notification.byId;
export const getIds = ({ notification }) => notification.list.allIds;
export const getNotification = (state, id) => getById(state)[id];
export const getNotificationList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getNotificationListForCurrentProduct = createSelector(
  [getNotificationList, productSelectors.getCurrentProduct],
  (notificationList, product) => (isNullOrUndef(product) ? [] : notificationList.filter(fr => fr.ProductId === product.ProductId)),
);

export const makeGetNotificationsForProduct = createSelector([makeGetByQuery], getNotificationsByQuery =>
  createSelector(R.identity, productId => getNotificationsByQuery({ productId })),
);
