import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productActions, productTypes, productSelectors } from '../../../redux/product';
import {
  productProportionTypes,
  productProportionSelectors,
  productProportionActions,
} from '../../../redux/productProportion';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import CompositionTab from './CompositionTab';
import { functionSelectors } from '../../../redux/function';
import { rawMatTypes, rawMatActions } from '../../../redux/rawMat';
import { push } from 'react-router-redux';

class CompositionContainer extends Component {
  render() {
    console.log(this.props);
    return <CompositionTab {...this.props} />;
  }
  componentDidMount() {
    if(
      (this.props.currentProductComposition || []).length === 0) {
        this.props.getProductProportions();
      }
  }
  
  componentDidUpdate(previousProps) {
    if (this.getProductIdToFetch(previousProps) !== this.getProductIdToFetch(this.props)) {
      this.props.getProductProportions();
    }
  }

  getProductIdToFetch = props => (props.productId > 0 ? props.productId : props.duplicateId);

}

CompositionContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const getCompo = productProportionSelectors.makeGetCompositionForProduct();
  const productProportionId = props.location.query.ProductProportionId ? parseInt(props.location.query.ProductProportionId) : null;
  return (state, { productId }) => ({
    currentProductComposition: getCompo(state)(productId),
    isLoadingCompositionList: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productTypes.DELETE_PRODUCT_PROPORTION.REQUEST,
    ),
    isProductCompositionEditable: productSelectors.makeIsProductProportionEditable(productId)(state),
    isAddDisabled: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productTypes.DELETE_PRODUCT_PROPORTION.REQUEST,
      rawMatTypes.RAWMAT_LIST.REQUEST,
      productTypes.PRODUCT.REQUEST,
    ),
    functionById: functionSelectors.getById(state),
    productProportionIdToEdit: productProportionId,
  });
};

const mapDispatchToProps = (dispatch, { productId, rawMatId }) => ({
  getProductProportions: () => dispatch(productProportionActions.listProductProportionsForProduct.request(productId)),
  deleteProductProportion: id => dispatch(productActions.deleteProductProportion.request(id)),
  onAdd: () => {
    dispatch(productProportionActions.addProductProportion(productId, rawMatId))
  },
  onEdit: (productId, productProportionId) =>
    dispatch(productProportionActions.editProductProportion(productId, productProportionId)),
  onReOrderCompositionButtonClick: (productProportionIdToEdit) => dispatch(modalActions.showProductProportionReOrderModal({ productId, productProportionIdToEdit })),
  onSelectRMButtonClick: () => dispatch(modalActions.showProductProportionSelectRMModal({ productId })),
  onCreateRMButtonClick: () => dispatch(push({ pathname: '/rawmats/-1', query: { returnToProduct: productId }})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompositionContainer);
