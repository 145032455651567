export const SHOW_MODAL = 'modal/SHOW';
export const HIDE_MODAL = 'modal/HIDE';
export const SHOW_EDIT_RAWMAT_PROPORTION_MODAL = 'modal/SHOW_EDIT_RAWMAT_PROPORTION_MODAL';

// Modal types
export const PRODUCT_PROPORTION = 'PRODUCT_PROPORTION';
export const NOTIFICATION_FORM = 'NOTIFICATION_FORM';
export const IMPACT_ASSESSMENT_THRESHOLD = 'IMPACT_ASSESSMENT_THRESHOLD';
export const IMPACT_ASSESSMENT_ACTIONS_HISTORY = 'IMPACT_ASSESSMENT_ACTIONS_HISTORY';
export const RAWMAT_PROPORTION = 'RAWMAT_PROPORTION';
export const ADD_PRODUCT_TO_ORDER_FORM = 'ADD_PRODUCT_TO_ORDER_FORM';
export const PRODUCT_COMPOSITION_STATUS = 'PRODUCT_COMPOSITION_STATUS';
export const PRODUCT_ACTIONS_HISTORY = 'PRODUCT_ACTIONS_HISTORY';
export const PRODUCT_FORMULA_REVIEW = 'PRODUCT_FORMULA_REVIEW';
export const SUBSTANCE_PROPORTION = 'SUBSTANCE_PROPORTION';
export const PHRASE_LIST = 'PHRASE_LIST';
export const LABEL_REVIEW_WIZARD = 'LABEL_REVIEW_WIZARD';
export const LABEL_REVIEW_APPROVAL = 'LABEL_REVIEW_APPROVAL';
export const LABEL_REVIEW_VALIDATION = 'LABEL_REVIEW_VALIDATION';
export const COS_ANNEXE_FORM = 'COS_ANNEXE_FORM';
export const SUBSTANCE_INFORMATION_FORM = 'SUBSTANCE_INFORMATION_FORM';
export const RAWMAT_INFORMATION_FORM = 'RAWMAT_INFORMATION_FORM';
export const SUBSTANCE_STATUS_COMMENT = 'SUBSTANCE_STATUS_COMMENT';
export const RAWMAT_STATUS_COMMENT = 'RAWMAT_STATUS_COMMENT';
export const ORDER_STATUS_COMMENT = 'ORDER_STATUS_COMMENT';
export const SUBSTANCE_ACTIONS_HISTORY = 'SUBSTANCE_ACTIONS_HISTORY';
export const ORDER_ACTIONS_HISTORY = 'ORDER_ACTIONS_HISTORY';
export const RAWMAT_ACTIONS_HISTORY = 'RAWMAT_ACTIONS_HISTORY';
export const SUBSTANCE_TOX_PROFILE = 'SUBSTANCE_TOX_PROFILE';
export const SHADE_FORM = 'SHADE_FORM';
export const PRODUCT_CATEGORY_FORM = 'PRODUCT_CATEGORY_FORM';
export const PRODUCT_CATEGORY_TRANSLATION_FORM = 'PRODUCT_CATEGORY_TRANSLATION_FORM';
export const SHADE_COMPO = 'SHADE_COMPO';
export const SHADE_X_RM_COMPO = 'SHADE_X_RM_COMPO';
export const SHADE_X_INCI_COMPO = 'SHADE_X_INCI_COMPO';
export const PRODUCT_PROPORTION_REORDER = 'PRODUCT_PROPORTION_REORDER';
export const PRODUCT_PROPORTION_SELECTRM = 'PRODUCT_PROPORTION_SELECTRM';
export const PRODUCT_PROPORTION_CREATERM = 'PRODUCT_PROPORTION_CREATERM';
export const CPSR_LOAD_TEMPLATE = 'CPSR_LOAD_TEMPLATE';
export const DRAFT_LABEL = 'DRAFT_LABEL';
export const PRINT_DOCUMENTS = 'PRINT_DOCUMENTS';
export const MANDATE_PRODUCTS = 'MANDATE_PRODUCTS';
export const CPSR_STATUS_COMMENT = 'CPSR_STATUS_COMMENT';
export const EDIT_INCI_LIST = 'EDIT_INCI_LIST';
export const PHRASE_FORM = 'PHRASE_FORM';
export const CATEGORY_FORM = 'CATEGORY_FORM';
export const PROCESSTYPE_FORM = 'PROCESSTYPE_FORM';
export const TASKTYPE_FORM = 'TASKTYPE_FORM';
export const ALLERGEN_LIST = 'ALLERGEN_LIST';
export const IMPURITY_LIST = 'IMPURITY_LIST';
export const PRODUCT_INCI_IMPURITY_LIST = 'PRODUCT_INCI_IMPURITY_LIST';
export const SUBSTANCE_COS_ANNEXE_LIST = 'SUBSTANCE_COS_ANNEXE_LIST';
export const TO_PROFILE_ITEM = 'TO_PROFILE_ITEM';
export const COPY_RAW_MAT_FORM = 'COPY_RAW_MAT_FORM';
export const ORDER_ITEM_FORM = 'ORDER_ITEM_FORM';
export const PROCESS_FORM = 'PROCESS_FORM';
export const TASK_FORM = 'TASK_FORM';
export const TASK_ACTIONS_HISTORY = 'TASK_ACTIONS_HISTORY';
export const WORK_ITEM_FORM = 'WORK_ITEM_FORM';
export const PRODUCT_DUPLICATE_OR_REPLACE = 'PRODUCT_DUPLICATE_OR_REPLACE';
export const GENERATE_AGREEMENT = 'GENERATE_AGREEMENT';
export const ORDER_ITEM_MULTIPLE_PRODUCT_SELECTION_FORM = 'ORDER_ITEM_MULTIPLE_PRODUCT_SELECTION_FORM';
export const ORDER_MISSING_DOCUMENT_LIST_FORM = 'ORDER_MISSING_DOCUMENT_LIST_FORM';
export const PRODUCT_TR_FORM = 'PRODUCT_TR_FORM';
export const SUBSTANCE_TR_FORM = 'SUBSTANCE_TR_FORM';
export const COSANNEXE_TR_FORM = 'COSANNEXE_TR_FORM';
