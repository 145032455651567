import { takeLatest, put, all, select, takeEvery, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga, createFetchSaga } from '../sagaUtils';
import { notificationTypes, notificationActions, notificationSchemas, notificationSelectors } from './index';
import { modalActions, modalTypes, modalSelectors } from '../modal';
import { createSaveForm } from '../form/utils';
import formNames from '../../constants/formNames';
import notificationApi from '../../api/notificationApi';

export default function* root() {
  yield all([
    takeLatest(notificationTypes.NOTIFICATIONS_FOR_PRODUCT_LIST.REQUEST, getNotificationListForProductSaga),
    takeLatest(notificationTypes.SAVE_NOTIFICATION.REQUEST, saveNotificationSaga),
    takeLatest(notificationTypes.DELETE_NOTIFICATION.REQUEST, deleteNotificationSaga),
    takeLatest(notificationTypes.GENERATE_COMPLIANCE_CERTIFICATE.REQUEST, generateComplianceCertificateSaga),
    takeLatest(notificationTypes.RENIEW_COMPLIANCE_CERTIFICATE.REQUEST, reniewComplianceCertificateSaga),
    // takeLatest(notificationTypes.UPDATE_NOTIFICATION.REQUEST, updateNotificationSaga),
    takeLatest(notificationTypes.ADD_NOTIFICATION,onAddEditNotification)
  ]);
}

function* getNotificationListForProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(notificationActions.listNotificationsForProduct.REQUEST));
  yield callApiSagaAndNormalize(
    notificationActions.listNotificationsForProduct,
    notificationApi.getNotifications,
    notificationSchemas.notificationListSchema,
    {
      ...payload,
      // OptIncludeDetails: true,
    },
  );
  yield put(applicationActions.unsetLoading(notificationActions.listNotificationsForProduct.REQUEST));
}

function* onAddEditNotification({ payload }) {
  yield put(modalActions.showModal({ modalType: modalTypes.NOTIFICATION, modalProps: { ...payload } }));
}

function* saveNotificationSaga({ payload, type }) {
  const { productId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(notificationActions.saveNotification, notificationApi.saveNotification, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.NOTIFICATION_FORM) {
    yield put(modalActions.hideModal());
  }
  yield put(applicationActions.unsetLoading(type));
  yield put(notificationActions.listNotificationsForProduct.request(productId));
}

function* deleteNotificationSaga({ payload, type }) {
  const { ProductId, NotificationId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(notificationActions.deleteNotification, notificationApi.deleteNotification, payload);

  yield put(notificationActions.listNotificationsForProduct.request(ProductId));

  yield put(applicationActions.unsetLoading(type));
}

function* generateComplianceCertificateSaga({ payload, type }) {
  const { ProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(notificationActions.generateComplianceCertificate, notificationApi.generateComplianceCertificate, payload);
  yield put(applicationActions.unsetLoading(type));
}

function* reniewComplianceCertificateSaga({ payload, type }) {
  const { ProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(notificationActions.reniewComplianceCertificate, notificationApi.reniewComplianceCertificate, payload);
  yield put(notificationActions.listNotificationsForProduct.request({ ProductId }));
  yield put(applicationActions.unsetLoading(type));
}