import * as R from 'ramda';
import { createSelector } from 'reselect';
import { phraseSelectors } from '../../phrase';
import { phraseCpsrChapterSelectors } from '../../phraseCpsrChapter';
import { productPhraseSelectors } from '../../productPhrase';

export const getFilter = ({ modal: { phraseListModal } }) => phraseListModal.filter;

export const makeGetPhraseList = createSelector(R.identity, ({ ProductPhraseId, CpsrTypeCode }) =>
  createSelector(
    [
      phraseSelectors.getphraseList,
      phraseSelectors.getById,
      getFilter,
      productPhraseSelectors.getById,
      phraseCpsrChapterSelectors.makeGetByQuery,
    ],
    (phraseList, phraseById, cpsrChapterFilter, productPhraseById, getPhraseCpsrChapterByQuery) => {
      const phrasesForCpsrType = phraseList.filter(phrase => phrase.CpsrTypeCode === CpsrTypeCode);

      if (ProductPhraseId) {
        const { CpsrChapterCode } = productPhraseById[ProductPhraseId];

        return CpsrChapterCode && cpsrChapterFilter !== 'all'
          ? getPhraseCpsrChapterByQuery({ CpsrChapterCode }).map(({ PhraseId }) => phraseById[PhraseId])
          : phrasesForCpsrType;
      }

      return phrasesForCpsrType;
    },
  ),
);
