import { merge } from 'lodash';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as impactAssessmentTypes from './impactAssessmentTypes';

/*
const initialState = {
  impactAssessmentList: [],
  currentImpactAssessment: {},
  currentImpactAssessmentProducts: [],
};
*/

const byId = handleActions(
  {
    [impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.impactAssessments),
    [impactAssessmentTypes.IMPACT_ASSESSMENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.impactAssessments,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [impactAssessmentTypes.IMPACT_ASSESSMENT.SUCCESS]: (state, { payload }) => [...new Set([...state, payload.result])],
    /*    [impactAssessmentTypes.IMPACT_ASSESSMENT_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentImpactAssessmentProducts: payload,
    }), */
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
