import * as labelReviewItemWizardSelectors from './labelReviewItemWizardSelectors';
import * as labelReviewItemWizardActions from './labelReviewItemWizardActions';
import * as labelReviewItemWizardTypes from './labelReviewItemWizardTypes';
import sagas from './labelReviewItemWizardSagas';
import reducer from './labelReviewItemWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { labelReviewItemWizardSelectors, labelReviewItemWizardActions, labelReviewItemWizardTypes };
export default reducer;
