import queryString from 'query-string';
import { get, post, del } from './apiUtils';

const defaultUrl = '/Categories/';

export default {
  getCategoryList: () => get(defaultUrl),
  getPhraseList: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  getCategory: id => get(`${defaultUrl}${id}`),
  saveCategory: data => post(`${defaultUrl}`, data),
  deleteCategory: id => del(`${defaultUrl}${id}`),
};
