import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import { I18n } from 'react-redux-i18n';

const DraftLabelSteps = props => {
  const { activeStep } = props;
  const steps = [
    {
      key: 'innerLabel',
      title: I18n.t('draftLabelModal.innerLabel'),
    },
    {
      key: 'outerLabel',
      title: I18n.t('draftLabelModal.outerLabel'),
    },
    {
      key: 'leaflet',
      title: I18n.t('draftLabelModal.leaflet'),
    },
  ];

  return <Steps current={activeStep}>{steps.map(step => <Step {...step} />)}</Steps>;
};

DraftLabelSteps.defaultProps = {
  activeStep: 0,
};

DraftLabelSteps.propTypes = {
  activeStep: PropTypes.number,
};

export default DraftLabelSteps;
