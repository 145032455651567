import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { modalSelectors, modalActions } from '../modal';
import substanceInformationApi from '../../api/substanceInformationApi';
import * as substanceInformationTypes from './substanceInformationTypes';
import * as substanceInformationSchemas from './substanceInformationSchemas';
import * as substanceInformationActions from './substanceInformationActions';

export default function* root() {
  yield all([
    takeLatest(substanceInformationTypes.FETCH_SUBSTANCE_INFORMATIONS.REQUEST, getSubstanceInformationSaga),
    takeLatest(substanceInformationTypes.SAVE_SUBSTANCE_INFORMATION.REQUEST, saveSubstanceInformationSaga),
    takeLatest(substanceInformationTypes.DELETE_SUBSTANCE_INFORMATION.REQUEST, deleteSubstanceInformationSaga),
  ]);
}

export function* saveSubstanceInformationSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { substanceId } = payload;
  yield callApiSaga(
    substanceInformationActions.saveSubstanceInformation,
    substanceInformationApi.saveSubstanceInformations,
    payload,
  );

  const isModalShown = yield select(state => modalSelectors.isModalShown(state));
  if (isModalShown) {
    yield put(modalActions.hideModal());
  }
  yield put(substanceInformationActions.fetchSubstanceInformations.request({ substanceId }));

  yield put(applicationActions.unsetLoading(type));
}

function* deleteSubstanceInformationSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { id, query } = payload;
  yield callApiSaga(
    substanceInformationActions.deleteSubstanceInformation,
    substanceInformationApi.deleteSubstanceInformations,
    id,
  );
  yield put(substanceInformationActions.fetchSubstanceInformations.request(query));
  yield put(applicationActions.unsetLoading(type));
}

export function* getSubstanceInformationSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceInformationList = yield call(substanceInformationApi.getSubstanceInformations, payload);
    const normalizedData = normalize(
      substanceInformationList,
      substanceInformationSchemas.substanceInformationListSchema,
    );
    yield put(substanceInformationActions.fetchSubstanceInformations.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceInformationActions.fetchSubstanceInformations.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
