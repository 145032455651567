import * as mandateProductSelectors from './mandateProductSelectors';
import * as mandateProductActions from './mandateProductActions';
import * as mandateProductTypes from './mandateProductTypes';
import * as mandateProductSchemas from './mandateProductSchemas';
import sagas from './mandateProductSagas';
import reducer from './mandateProductReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  mandateProductSelectors,
  mandateProductActions,
  mandateProductTypes,
  mandateProductSchemas,
};
export default reducer;
