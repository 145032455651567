import { schema } from 'normalizr';
import * as rawMatSchemas from '../rawMat/rawMatSchemas';
import * as productSchemas from '../product/productSchemas';

export const productProportionSchema = new schema.Entity(
  'productProportions',
  {
    RawMat: rawMatSchemas.rawMatWithProportionsSchema,
    Product: productSchemas.productSchema,
  },
  { idAttribute: 'ProductProportionId' },
);
export const productProportionWithRawMatSchema = new schema.Entity(
  'productProportions',
  {
    RawMat: rawMatSchemas.rawMatWithProportionsSchema,
  },
  { idAttribute: 'ProductProportionId' },
);

export const productProportionListSchema = [productProportionSchema];
export const productProportionWithRawMatListSchema = [productProportionWithRawMatSchema];
