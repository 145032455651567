import { createSelector } from 'reselect';
import * as documentSelectors from '../document/documentSelectors';
import * as companySelectors from '../company/companySelectors';
import config from '../../config';

const API_ROOT = config.baseUrl;

export const getById = ({ companyDocument }) => companyDocument.byId;
export const getIds = ({ companyDocument }) => companyDocument.list.allIds;
export const getCompanyDocument = (state, id) => getById(state)[id];
export const getCompanyDocumentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getCompanyDocumentListForCompany = (state, companyId) => {
  const company = companySelectors.getCompany(state, companyId);
  if (!company || !company.CompanyDocuments) return undefined;
  return company.CompanyDocuments.map(cdId => {
    const companyDocument = getCompanyDocument(state, cdId);
    return {
      ...companyDocument,
      Document: {
        ...documentSelectors.getById(state)[companyDocument.DocumentId],
        FileLink: `${API_ROOT}/CompaniesDocuments/${cdId}/File`,
      },
    };
  });
};
