import * as cosAnnexeTRFormSelectors from './cosAnnexeTRFormSelectors';
import * as cosAnnexeTRFormActions from './cosAnnexeTRFormActions';
import * as cosAnnexeTRFormTypes from './cosAnnexeTRFormTypes';
import sagas from './cosAnnexeTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);


export { cosAnnexeTRFormSelectors, cosAnnexeTRFormActions, cosAnnexeTRFormTypes };
