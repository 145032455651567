export default {
  category: 'categoryForm',
  categoryTR: 'categoryTRForm',
  company: 'companyForm',
  copyRM: 'copyRMForm',
  cosAnnexe: 'cosAnnexeForm',
  cosAnnexeTR: 'cosAnnexeTR',
  cpsrA: 'ProductCPSRAForm',
  cpsrATR: 'ProductCPSRATRForm',
  cpsrB: 'ProductCPSRBForm',
  cpsrBTR: 'ProductCPSRBTRForm',
  distributor: 'distributorForm',
  draftLabel: 'DraftLabelForm',
  editInciListForm: 'editInciListForm',
  generateAgreement: 'generateAgreementForm',
  impactAssessment: 'impactAssessmentForm',
  impactAssessmentThreshold: 'impactAssessmentFormThreshold',
  mandate: 'mandateForm',
  minimalProduct: 'minimalProductForm',
  notification: 'notificationForm',
  order: 'orderForm',
  orderItem: 'orderItem',
  orderItemProduct: 'orderItemProduct',
  orderProcessInfo: 'orderProcessInfoForm',
  phrase: 'phraseForm',
  phrasesTR: 'phrasesTRForm',
  process: 'process',
  processTypeForm: 'processTypeForm',
  product: 'productForm',
  productAction: 'productAction',
  productCategory: 'productCategory',
  productCategoryTR: 'productCategoryTR',
  productCpsrbCommentForm: 'productCpsrbCommentForm',
  productDuplcateOrReplaceForm: 'ProductDuplicateOrReplaceForm',
  productProportionForm: 'ProductProportionForm',
  productTR: 'productTR',
  rawMat: 'rawMatForm',
  rawMatInfo: 'rawMatInformationForm',
  rawMatProportionForm: 'rawMatProportionForm',
  shade: 'shade',
  shadeCompo: 'shadeCompo',
  substance: 'substanceForm',
  substanceClp: 'substanceClp',
  substanceCosAnnexeForm: 'substanceCosAnnexeForm',
  substanceInformation: 'substanceInformationForm',
  substanceToxProfile: 'substanceToxProfileForm',
  substanceTR: 'substanceTR',
  productCategoryTR: 'productCategoryTR',
  task: 'task',
  taskTypeForm: 'taskTypeForm',
  toxProfileItemForm: 'toxProfileItemForm',
  workItem: 'workItem',
};
