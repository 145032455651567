import { createRequestActions } from '../utils';
import * as inciListTypes from './inciListTypes';

export const getInciListForProduct = createRequestActions(
  inciListTypes.INCI_LIST_FOR_PRODUCT,
  ProductId => ({
    ProductId,
  }),
  (productInciList, ProductId) => ({ ProductId }),
);
