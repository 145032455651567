import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { inciListActions, inciListSelectors, inciListTypes } from '../../../redux/inciList';
import { productSelectors } from '../../../redux/product';
import { productCategorySelectors } from '../../../redux/productCategory';
import { referentialSelectors } from '../../../redux/referentials';
import IncilistTab from './InciListTab';
import { modalActions } from '../../../redux/modal';

class InciListContainer extends Component {
  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <IncilistTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const inciText = inciListSelectors.getInciTextForProduct(state)(productId);
  const product = productSelectors.getById(state)[productId];
  const isLoadingInciListTab = applicationSelectors.isLoading(state, inciListTypes.INCI_LIST_FOR_PRODUCT.REQUEST);
  return {
    currentProductInciList: inciListSelectors.getInciSubstancesWithDetailsForProduct(state, productId),
    currentProductCategories: productCategorySelectors.getProductCategoryByProductId(state, productId),
    inciUpperPart: R.prop('UpperPart', inciText),
    inciLowerPart: R.prop('LowerPart', inciText),
    showInciValidationButton: R.propOr(false, 'ManualInciInd', product),
    showImpuritiesButton: R.prop('CompositionStatus', product) === "PRODUCT_COMPO_VALIDATED",
    isLoadingInciListTab,
    getCosAnneTypeByCode: referentialSelectors.makeGetCosAnnexeTypeByCode(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(inciListActions.getInciListForProduct.request(productId));
  },
  getProductInciList: () => dispatch(inciListActions.getInciListForProduct.request(productId)),
  onImpuritiesButtonClick: () => dispatch(modalActions.showProductInciImpurityListModal({ productId })),
});

InciListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InciListContainer);
