import PropTypes from 'prop-types';
import React from 'react';
import { pathOr, pipe, length, divide, __, add, multiply } from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditButtonRenderer } from '../../_Common/Grids';
import WithPrivileges from '../../_Common/WithPrivileges';
import userActionsCodes from '../../../constants/userActionsCodes';

const PhraseList = props => {
  const { phraseList, isLoading, savePhrase, getPhraseList, cpsrTypeList, onEditButtonClick, onAddButtonClick } = props;

  const rowHeight = pipe(pathOr('1', ['data', 'Text']), length, divide(__, 255), Math.floor, add(1), multiply(25));

  const getCpsrTypeCode = value => {
    const cpsrType = cpsrTypeList.find(cType => cType.Value === value);
    if (!cpsrType) return undefined;
    return cpsrType.Key;
  };

  const cpsrTypeValues = cpsrTypeList.map(p => p.Value);

  const getCpsrTypeValue = code => {
    const cpsrType = cpsrTypeList.find(cType => cType.Key === code);
    if (!cpsrType) return undefined;
    return cpsrType.Value;
  };

  return (
    <WithPrivileges
      requiredActionCodes={[userActionsCodes.CalmosAdminAction, userActionsCodes.CalmosSuperAdminAction]}>
      {isAllowedToEditAdd => {
        const columns = [
          {
            headerName: I18n.t('phrase.phraseCode'),
            field: 'PhraseCode',
            width: 30,
            sort: 'asc',
          },
          {
            headerName: I18n.t('phrase.text'),
            field: 'Text',
            editable: true,
            width: 250,
            onCellValueChanged: ({ data }) => savePhrase(data),
            cellEditor: 'largeText',
            cellStyle: {
              'white-space': 'normal',
            },
          },
          {
            headerName: I18n.t('phrase.CpsrType'),
            field: 'CpsrTypeCode',
            editable: true,
            width: 30,
            cellEditor: 'select',
            cellRenderer: ({ value }) => getCpsrTypeValue(value) || '',
            newValueHandler: ({ data, newValue }) => {
              data.CpsrTypeCode = getCpsrTypeCode(newValue); //eslint-disable-line
            },
            onCellValueChanged: ({ data }) => savePhrase(data),
            cellEditorParams: {
              values: cpsrTypeValues,
            },
          },
          {
            headerName: I18n.t('general.actions'),
            width: 30,
            hide: !isAllowedToEditAdd,
            cellRendererFramework: EditButtonRenderer,
            cellRendererParams: {
              onClick: onEditButtonClick,
            },
          },
        ];

        return (
          <>
          <SelectionGrid
            data={phraseList}
            columns={columns}
            onRefresh={getPhraseList}
            //      isLoading={isLoading}
            showLoadingIcon={isLoading}
            stopEditingWhenGridLosesFocus
            singleClickEdit
            keyName="PhraseId"
            showAddButton={isAllowedToEditAdd}
            showFilterRow
            onAdd={onAddButtonClick}
            getRowHeight={rowHeight}
          />
          </>
        );
      }}
    </WithPrivileges>
  );
};

PhraseList.propTypes = {
  phraseList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  savePhrase: PropTypes.func.isRequired,
  cpsrTypeList: PropTypes.array, // eslint-disable-line
  getPhraseList: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default PhraseList;
