import React from 'react';
import PropTypes from 'prop-types';
import DistributorAgreementListContainer from './_DistributorAgreementListContainer';

const AgreeementsTab = props => <DistributorAgreementListContainer {...props} />;

AgreeementsTab.propTypes = {
  distributorId: PropTypes.number.isRequired,
};

export default AgreeementsTab;
