import { createRequestActions } from '../utils';
import * as substanceInformationTypes from './substanceInformationTypes';

export const fetchSubstanceInformations = createRequestActions(
  substanceInformationTypes.FETCH_SUBSTANCE_INFORMATIONS,
  ({ substanceInformationId: id, ...query } = {}) => ({
    ...(id && { id }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);

export const saveSubstanceInformation = createRequestActions(substanceInformationTypes.SAVE_SUBSTANCE_INFORMATION);
export const deleteSubstanceInformation = createRequestActions(
  substanceInformationTypes.DELETE_SUBSTANCE_INFORMATION,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
