import { takeEvery, put, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as companyFormTypes from './companyFormTypes';

export default function* root() {
  yield all([takeEvery(companyFormTypes.DUPLICATE_COMPANY, duplicateCompanySaga)]);
}

function* duplicateCompanySaga({ payload, type }) {
  const { companyId } = payload;
  yield put(
    push({
      query: { show_details: true, duplicateFrom: companyId },
      pathname: '/companies/-1',
    }),
  );
}
