import * as R from 'ramda';
import { createSelector } from 'reselect';
import reportTypes from './reportTypes';
import { productSelectors } from '../product';

export const getProductReports = () => [
  reportTypes.formulaAcceptance,
  reportTypes.preval,
  //reportTypes.prevalNew,
  //reportTypes.cpsraNew,
  reportTypes.cpsra,
  //reportTypes.cpsrbNew,
  reportTypes.cpsrb,
  reportTypes.cpsraByShade,
  reportTypes.cpsrbByShade,
  reportTypes.cpsraconfidential,
  // reportTypes.cpsraconfidentialnew,
  reportTypes.cpsrbconfidential,
  reportTypes.cpsrAByShadeConfidential,
  reportTypes.cpsrbByShadeConfidential,
  reportTypes.cpsrbWithAnnexes,
  // reportTypes.cpsrbWithAnnexesNew,
  // reportTypes.closingLetter,
  // reportTypes.complianceCertificate,
  reportTypes.productCpnp,
  reportTypes.productToxProfile,
  reportTypes.inciNames,
  reportTypes.quantitativeQualitativeCompo,
  reportTypes.efficacyAssessmentSummary,
  reportTypes.efficacyAssessment,
  reportTypes.safetyAssessmentSummary,
];
const isFormulaAcceptancePrintable = productSelectors.makeIsProductCompoValidated;
const isComplianceCertificatePrintable = createSelector(
  [productSelectors.makeIsProductCompoValidated, productSelectors.makeHasProductCpnp],
  (getIsProductCompoValidated, getHasProductCpnp) =>
    createSelector(
      [getIsProductCompoValidated, getHasProductCpnp],
      R.compose(
        R.all(R.equals(true)),
        R.pair,
      ),
    ),
);
const isClosingLetterPrintable = state => productId =>
  R.compose(
    R.complement(R.anyPass([R.isEmpty, R.isNil])),
    R.prop('NotificationCode'),
    productSelectors.makeGetProductById(state),
  )(productId);

const handlers = {
  [reportTypes.formulaAcceptance.name]: isFormulaAcceptancePrintable,
  [reportTypes.complianceCertificate.name]: isComplianceCertificatePrintable,
  [reportTypes.closingLetter.name]: isClosingLetterPrintable,
};

export const makeIsReportPrintable = state => productId => reportName => {
  const handler = handlers[reportName];
  return !handler ? true : handler(state)(productId);
};
