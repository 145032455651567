import React from 'react';
import PropTypes from 'prop-types';

const TabTitle = ({ title, isDirty }) => (
  <span style={isDirty ? { color: 'red', fontWeight: 'bold' } : {}}>{title}</span>
);

TabTitle.defaultProps = {
  isDirty: false,
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isDirty: PropTypes.bool,
};

export default TabTitle;
