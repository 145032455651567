import { takeEvery, all, put, select, take } from 'redux-saga/effects';
import { flow, omit, map, differenceWith, isEqual, without, difference, isFinite, filter } from 'lodash/fp';
import { change, initialize, getFormInitialValues, arrayInsert, formValueSelector, submit, isDirty } from 'redux-form';
import uuidV1 from 'uuid/v1';
import formNames from '../../../constants/formNames';
import cpsrTypes from '../../../constants/cpsrTypes';
import * as productPhraseFormTypes from './productPhraseFormTypes';
import * as productPhraseFormSelectors from './productPhraseFormSelectors';
import * as productPhraseFormActions from './productPhraseFormActions';
import { productPhraseActions, productPhraseTypes } from '../../productPhrase';
import { phraseSelectors } from '../../phrase';
import {referentialSelectors} from "../../referentials";

export default function* root() {
  yield all([
    takeEvery(productPhraseFormTypes.RESET_PLACEHOLDERS, resetPlaceholdersSaga),
    takeEvery(productPhraseFormTypes.ADD_PRODUCT_PHRASE, addProductPhraseSaga),
    takeEvery(productPhraseFormTypes.EDIT_PRODUCT_PHRASE, editProductPhraseSaga),
    takeEvery(productPhraseFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClick),
    takeEvery(productPhraseFormTypes.LOAD_FORM, loadFormSaga),
    takeEvery(productPhraseFormTypes.SELECT_PRODUCT_PHRASE, onSelectProductPhraseSaga),
    takeEvery(productPhraseFormTypes.DELETE_BUTTON_CLICK, onDeleteButtonClick),
    takeEvery(productPhraseFormTypes.SELECT_FILTER, onSelectFilterSaga),
    takeEvery(productPhraseFormTypes.ADD_TABLE_PHRASE, addTablePhraseSaga),
  ]);
}

// const takeProductPhrases = omit(['allIds', 'selectedIds', 'cpsrChapter', 'visibleIds']);

const removeTemporaryIds = filter(isFinite);
const removeTemporaryProductPhrase = filter(productPhrase => isFinite(productPhrase.ProductPhraseId));
const onlyForChapter = chapterCode => filter(productPhrase => productPhrase.CpsrChapterCode === chapterCode);

function* onSelectFilterSaga({ payload }) {
  const { formName, filter } = payload; //eslint-disable-line
  const cpsrChapterCode = filter && filter.value;
  yield put(change(formName, 'cpsrChapter', cpsrChapterCode));
  if (!cpsrChapterCode) {
    const allIds = yield select(state => productPhraseFormSelectors.getFormValue(state, formName, 'allIds'));
    yield put(change(formName, 'visibleIds', allIds));
  } else {
    const currentFormValues = yield select(state => productPhraseFormSelectors.getCurrentFormValues(state, formName));

    const filteredProductPhraseList = flow(
      removeTemporaryProductPhrase,
      onlyForChapter(cpsrChapterCode),
      map(productPhrase => productPhrase.ProductPhraseId),
    )(
      currentFormValues.allIds.map(id => ({
        ...currentFormValues[id],
      })),
    );

    const tmpIds = currentFormValues.allIds.filter(id => !isFinite(id));
    yield put(change(formName, 'visibleIds', [...tmpIds, ...filteredProductPhraseList]));
  }
}

function* onDeleteButtonClick({ payload }) {
  const { formName, ProductId, CpsrTypeCode } = payload;
  const currentSelectdIds = yield select(state =>
    productPhraseFormSelectors.getFormValue(state, formName, 'selectedIds'),
  );
  const allIds = yield select(state => productPhraseFormSelectors.getFormValue(state, formName, 'allIds'));
  const cpsrChapterFIlter = yield select(state =>
    productPhraseFormSelectors.getFormValue(state, formName, 'cpsrChapter'),
  );
  const noSelectedIds = difference(allIds, currentSelectdIds);

  const IdsToDelete = removeTemporaryIds(currentSelectdIds);

  let productPhrases = {};
  if (IdsToDelete.length > 0) {
    yield put(productPhraseActions.deleteProductPhrase.request(ProductId, currentSelectdIds));
    yield take(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS);
    productPhrases = yield select(state =>
      productPhraseFormSelectors.getInitialFormValues(state, ProductId, CpsrTypeCode),
    );
    // yield put(initialize(formName, productPhraseList, { keepDirty: true }));
  }

  const initialFormValues = yield select(state => getFormInitialValues(formName)(state));

  const finalValues = {
    ...initialFormValues,
    ...productPhrases,
    selectedIds: [],
    allIds: noSelectedIds,
    visibleIds: noSelectedIds,
  };

  yield put(change(formName, 'selectedIds', []));
  yield put(change(formName, 'allIds', noSelectedIds));
  yield put(change(formName, 'visibleIds', noSelectedIds));
  yield put(change(formName, 'cpsrChapter', cpsrChapterFIlter));
  yield put(initialize(formName, finalValues, { keepDirty: true }));
}

function* onSelectProductPhraseSaga({ payload }) {
  const { formName, id, selected } = payload;
  const currentSelectdIds = yield select(state =>
    productPhraseFormSelectors.getFormValue(state, formName, 'selectedIds'),
  );

  const newSelectedIds = selected ? [...currentSelectdIds, id] : without([id], currentSelectdIds);
  yield put(change(formName, 'selectedIds', newSelectedIds));
}

function* onSaveButtonClick({ payload }) {
  const { formName, ProductId, CpsrTypeCode, formData } = payload;

  if (yield select(isDirty(formNames.productCpsrbCommentForm)) && CpsrTypeCode === cpsrTypes.CPSRB) {
    yield put(submit(formNames.productCpsrbCommentForm));
  }

  const initialFormValues = yield select(state => getFormInitialValues(formName)(state));
  const getProducPhrase = flow(map(omit('Phrase')));
  const productPhrases = getProducPhrase(
    formData.allIds.map(id => ({
      ...formData[id],
    })),
  );

  const initialProductPhrases = getProducPhrase(initialFormValues);


  const editedProductPhrases = differenceWith(isEqual, productPhrases, initialProductPhrases);
  if (editedProductPhrases.length > 0) {
    yield put(productPhraseActions.saveProductPhrase.request(ProductId, editedProductPhrases));
    yield take(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS);
    const productPhraseList = yield select(state =>
      productPhraseFormSelectors.getInitialFormValues(state, ProductId, CpsrTypeCode),
    );
    yield put(change(formName, 'selectedIds', []));
    yield put(initialize(formName, productPhraseList));
  }
}

function* resetPlaceholdersSaga({ payload }) {
  const { field, formName } = payload;
  yield put(change(formName, `${field}.V1`, null));
  yield put(change(formName, `${field}.V2`, null));
  yield put(change(formName, `${field}.V3`, null));
  yield put(change(formName, `${field}.V4`, null));
  yield put(change(formName, `${field}.V5`, null));
  yield put(change(formName, `${field}.V6`, null));
  yield put(change(formName, `${field}.V7`, null));
  yield put(change(formName, `${field}.TM`, null));
}

function* editProductPhraseSaga({ payload }) {
  const { formName, ProductPhraseId, PhraseId } = payload;
  const getFormValue = formValueSelector(formName);

  const newPhrase = yield select(state => phraseSelectors.getphrase(state, PhraseId));
  const currentProductPhrase = yield select(state => getFormValue(state, ProductPhraseId));

  const newProductPhrase = {
    ...currentProductPhrase,
    V1: null,
    V2: null,
    V3: null,
    V4: null,
    V5: null,
    V6: null,
    V7: null,
    TM: null,
    PhraseId,
    Phrase: newPhrase,
  };

  yield put(change(formName, newProductPhrase.ProductPhraseId, newProductPhrase));
}

function* addProductPhraseSaga({ payload }) {
  const { formName, ...productPhraseData } = payload;
  const initialFormValues = yield select(state => getFormInitialValues(formName)(state));
  const ProductPhraseId = uuidV1();

  const styleList = yield select(state => referentialSelectors.getCpsrPhraseStyleList(state));
  const defaultValue = styleList.find(elem => elem.Value === "black");

  const newProductPhrase = {
    ...productPhraseData,
    ProductPhraseId,
    CpsrPhraseStyleId: defaultValue.Key,
    OrderNr: 1,
    Phrase: yield select(state => phraseSelectors.getphrase(state, payload.PhraseId)),
  };

  const newFormValues = {
    ...initialFormValues,
    [newProductPhrase.ProductPhraseId]: {
      ...newProductPhrase,
    },
  };

  yield put(change(formName, newProductPhrase.ProductPhraseId, newProductPhrase));
  // yield put(initialize(formName, newFormValues, { keepDirty: true }));
  yield put(arrayInsert(formName, 'allIds', 0, ProductPhraseId));
  yield put(arrayInsert(formName, 'visibleIds', 0, ProductPhraseId));
}

function* addTablePhraseSaga({ payload }) {
  const { CpsrTypeCode } = payload;
  const getTablePhrase = phraseSelectors.getTablePhraseForCpsrType(CpsrTypeCode);
  const Phrase = yield select(state => getTablePhrase(state));
  if (Phrase) {
    const { PhraseId } = Phrase;
    yield put(productPhraseFormActions.addProductPhrase({ ...payload, PhraseId }));
  }
}

function* loadFormSaga({ payload }) {
  const { formName, ProductId, CpsrTypeCode } = payload;
  yield take(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS);

  const initialFormValues = yield select(state =>
    productPhraseFormSelectors.getInitialFormValues(state, ProductId, CpsrTypeCode),
  );

  yield put(initialize(formName, initialFormValues));
}
