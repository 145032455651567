import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { DateCellRenderer, EditButtonRenderer, SelectionGrid } from '../../_Common/Grids';

const ActionButtonsRedender = props => {
  const { onEdit, onApprove, onValidate, onPrint, data, getIsEditLoading } = props;
  const handleClick = fn => () => fn(data);

  return (
    <div>
      <ButtonToolbar>
        <EditButtonRenderer onClick={onEdit} isLoading={getIsEditLoading(data)} {...props} />
        <Button bsSize="xsmall" type="button" onClick={() => onPrint(data)}>
          <Translate value="labelReview.print" />
        </Button>
        <Button bsSize="xsmall" type="button" onClick={handleClick(onValidate)}>
          <Translate value="labelReview.validate" />
        </Button>
        <Button bsSize="xsmall" type="button" onClick={handleClick(onApprove)}>
          <Translate value="labelReview.approve" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRedender.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  getIsEditLoading: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
};

const getYN = ({ data, colDef }) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const LabelReviewList = props => {
  const {
    labelReviewList,
    getLabelReviewListForProduct,
    onEditLabelReview,
    onApproveLabelReviewClick,
    onValidateLabelReviewClick,
    onPrintLabelReviewClick,
    isLoadingLabelReviewList,
    getIsLoadingWizardForLabelReview,
  } = props;

  const columns = [
    {
      headerName: I18n.t('labelReview.revisionNr'),
      field: 'RevisionNr',
      width: 50,
      suppressSizeToFit: true,
      sort: 'desc',
    },
    {
      headerName: I18n.t('labelReview.user'),
      field: 'CreatedBy',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.creationDate'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      width: 135,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.isValid'),
      field: 'IsValidated',
      valueGetter: getYN,
      width: 50,
    },
    {
      headerName: I18n.t('labelReview.validationDate'),
      field: 'ValidatedOn',
      cellRenderer: DateCellRenderer,
      width: 95,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.by'),
      field: 'ValidatedBy',
      width: 50,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.commment'),
      field: 'ValidationComment',
    },
    {
      headerName: I18n.t('labelReview.isApproved'),
      field: 'IsApproved',
      valueGetter: getYN,
      width: 50,
    },
    {
      headerName: I18n.t('labelReview.approvalDate'),
      field: 'ApprovedOn',
      cellRenderer: DateCellRenderer,
      width: 95,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.by'),
      field: 'ApprovedBy',
      width: 50,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.commment'),
      field: 'ApprovalComment',
    },
    {
      headerName: I18n.t('labelReview.sendingDate'),
      field: 'SentOn',
      cellRenderer: DateCellRenderer,
      width: 95,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('labelReview.actions'),
      width: 200,
      minWidth: 200,
      suppressSizeToFit: true,
      cellRendererFramework: ActionButtonsRedender,
      cellRendererParams: {
        onEdit: onEditLabelReview,
        getIsEditLoading: ({ LabelReviewId }) => getIsLoadingWizardForLabelReview(LabelReviewId),
        onApprove: onApproveLabelReviewClick,
        onValidate: onValidateLabelReviewClick,
        onPrint: onPrintLabelReviewClick,
      },
    },
  ];

  return (
    <SelectionGrid
      data={labelReviewList}
      onRefresh={getLabelReviewListForProduct}
      isLoading={isLoadingLabelReviewList}
      columns={columns}
      keyName="LabelReviewId"
      showAddButton={false}
    />
  );
};

LabelReviewList.propTypes = {
  labelReviewList: PropTypes.array.isRequired, //eslint-disable-line
  getLabelReviewListForProduct: PropTypes.func.isRequired,
  onEditLabelReview: PropTypes.func.isRequired,
  onApproveLabelReviewClick: PropTypes.func.isRequired,
  onValidateLabelReviewClick: PropTypes.func.isRequired,
  onPrintLabelReviewClick: PropTypes.func.isRequired,
  getIsLoadingWizardForLabelReview: PropTypes.func.isRequired,
  isLoadingLabelReviewList: PropTypes.bool.isRequired,
};

export default LabelReviewList;
