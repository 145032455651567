import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';

import phraseApi from '../../api/phraseApi';
import * as phraseTypes from './phraseTypes';
import * as phraseActions from './phraseActions';
import { phraseListSchema, phraseSchema } from './phraseSchemas';

export default function* root() {
  yield all([
    takeLatest(phraseTypes.GET_PHRASE.REQUEST, getPhraseSaga),
    takeLatest(phraseTypes.PHRASE_LIST.REQUEST, getPhraseListSaga),
    takeLatest(phraseTypes.SAVE_PHRASE.REQUEST, savePhraseSaga),
    takeLatest(phraseTypes.DELETE_PHRASE.REQUEST, deletePhraseSaga),
  ]);
}

export function* getPhraseSaga(action) {
  yield put(applicationActions.setLoading(phraseTypes.GET_PHRASE.REQUEST));
  yield callApiSagaAndNormalize(phraseActions.getPhrase, phraseApi.getPhrase, phraseSchema, action.payload);
  yield put(applicationActions.unsetLoading(phraseTypes.GET_PHRASE.REQUEST));
}

export function* getPhraseListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const phraseList = yield call(phraseApi.getPhraseList, payload);
    const normalizedData = normalize(phraseList, phraseListSchema);
    yield put(phraseActions.listPhrases.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(phraseActions.listPhrases.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* deletePhraseSaga({ payload, type }) {
  const { PhraseId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(phraseActions.deletePhrase, phraseApi.deletePhrase, PhraseId);
  yield put(phraseActions.listPhrases.request());
  yield put(applicationActions.unsetLoading(type));
}

export function* savePhraseSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedPhrase = yield callApiSagaAndNormalize(
    phraseActions.savePhrase,
    phraseApi.savePhrase,
    phraseSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
  return savedPhrase;
}
