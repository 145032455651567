import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import CertComposition from './CertComposition';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';
import { Row, Col, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Translate } from 'react-redux-i18n';

class CertCompositionTab extends PureComponent {
  static propTypes = {
    rawMatList: PropTypes.array.isRequired, //eslint-disable-line
    saveProductProportion: PropTypes.func.isRequired,
    deleteProductProportion: PropTypes.func,
    createAndLinkRawMatFromProdigumIngredient: PropTypes.func.isRequired,
    saveAndLinkProductProportion: PropTypes.func.isRequired,
    certCompositionList: PropTypes.array.isRequired, //eslint-disable-line
    isLoadingCosmosCompositionTab: PropTypes.bool,
    productId: PropTypes.number.isRequired,
    onSelectRMButtonClick: PropTypes.func.isRequired,
    onCreateRMButtonClick: PropTypes.func.isRequired,
    onDeleteCompositionButtonClick: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return (
      !nextProps.isLoadingCosmosCompositionTab &&
      (this.props.certCompositionList !== nextProps.certCompositionList || this.props.rawMatList,
        nextProps.rawMatList)
    );
  }

  render() {
    const {
      rawMatList,
      certCompositionList,
      currentImportedProductProportions,
      // saveProductProportion,
      deleteProductProportion,
      createAndLinkRawMatFromProdigumIngredient,
      saveAndLinkProductProportion,
      onUploadFile,
      productId,
      onSelectRMButtonClick,
      onCreateRMButtonClick,
      onDeleteCompositionButtonClick
    } = this.props;

    const handleOnUploadFile = (data) => {
      this.props.onUploadFile(productId, data);
    }

    return (
      <div>
        {currentImportedProductProportions.length == 0 &&
          <Row>
            <Col>
              <DropzoneForm onUploadFile={handleOnUploadFile} />
            </Col>
          </Row>
        }

        {certCompositionList.map((item, index) => (
          <CertComposition
            key={index}
            certComposition={item}
            rawMatList={rawMatList}
            initialValues={{ rawMatId: item.rawMatId, Percentage: item.Percentage?item.Percentage:item.importedProductProportion.Percentage}}
            // initialValues={_.pick(item, 'rawMatId', 'Percentage')}
            onSave={saveAndLinkProductProportion}
            onDelete={deleteProductProportion}
            onAdd={createAndLinkRawMatFromProdigumIngredient}
            onUploadFile={onUploadFile}
            onSelectRMButtonClick={onSelectRMButtonClick}
            onCreateRMButtonClick={onCreateRMButtonClick}
          />
        ))}
        {currentImportedProductProportions.length > 0 &&
          <Row>
            <Col>
              <Button
                bsSize="small"
                bsStyle="danger"
                onClick={() => {
                  onDeleteCompositionButtonClick(productId)
                }}
                data-tip data-for="selectTip"          
              >
                <i className="fa fa-delete" />
                <Translate value="general.delete" />
              </Button>
              <ReactTooltip id="selectTip" place="bottom" type="dark" effect="solid">
                <Translate value="compositionTab.selectMatchingRawMat" />
              </ReactTooltip>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default CertCompositionTab;
