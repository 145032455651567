import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { LinkContainer } from 'react-router-bootstrap';
import TooltipWrapper from '../_Common/TooltipWrapper';

const styles = {
  container: css({
    '& .nav-pills > li > a': {
      borderRadius: '0px',
    },
  }),
};

const TooltipLinkContainer = ({ tooltip, iconClass, ...other }) => (
  <LinkContainer {...other}>
    <NavItem>
      <TooltipWrapper text={tooltip} place="right" effect="solid">
        <i className={iconClass} />
      </TooltipWrapper>
    </NavItem>
  </LinkContainer>
);

TooltipLinkContainer.propTypes = {
  tooltip: PropTypes.string,
  iconClass: PropTypes.string,
};

const Navigation = ({ userDetails }) => (
  <div className={`${styles.container} bg-info`}>
    <Nav bsStyle="pills" stacked>
      {userDetails.ActionCodes.indexOf('ViewErrorList') >= 0 && (
        <TooltipLinkContainer
          to="/controlCenter"
          iconClass="fa fa-exclamation-circle fa-lg"
          tooltip={I18n.t('menu.controlCenter')}
        />
      )}
      <TooltipLinkContainer to="/home" iconClass="fa fa-home fa-lg" tooltip={I18n.t('menu.home')} />
      <TooltipLinkContainer to="/planner" iconClass="fa fa-clock fa-lg" tooltip={I18n.t('menu.planner')} />
      <TooltipLinkContainer to="/companies" iconClass="fa fa-industry fa-lg" tooltip={I18n.t('menu.companies')} />
      <TooltipLinkContainer to="/orders" iconClass="fa fa-book fa-lg" tooltip={I18n.t('menu.orders')} />
      <TooltipLinkContainer to="/products" iconClass="fa fa-barcode fa-lg" tooltip={I18n.t('menu.products')} />
      <TooltipLinkContainer to="/rawmats" iconClass="fa fa-flask fa-lg" tooltip={I18n.t('menu.rawmats')} />
      {/* <TooltipLinkContainer to="/ingredients" iconClass="fa fa-magic" tooltip={I18n.t('menu.ingredients')} /> */}
      <TooltipLinkContainer to="/substances" iconClass="fa fa-cubes fa-lg" tooltip={I18n.t('menu.substances')} />
      <TooltipLinkContainer to="/distributors" iconClass="fa fa-truck fa-lg" tooltip={I18n.t('menu.distributors')} />
      <TooltipLinkContainer to="/phrases" iconClass="fa fa-comment fa-lg" tooltip={I18n.t('menu.phrases')} />
      {userDetails.ActionCodes.indexOf('ViewMore') >= 0 && (
        <NavDropdown id="nav-dropdown" title="More">
          {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
            <TooltipLinkContainer
              to="/plannerDashboard"
              iconClass="fa fa-list-ol fa-lg"
              tooltip={I18n.t('menu.plannerDashboard')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
            <TooltipLinkContainer
              to="/generalDashboard"
              iconClass="fa fa-tachometer-alt fa-lg"
              tooltip={I18n.t('menu.generalDashboard')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
            <TooltipLinkContainer
              to="/impactAssessments"
              iconClass="fa fa-gavel fa-lg"
              tooltip={I18n.t('menu.impactAssessments')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
            <TooltipLinkContainer
              to="/safecosmeticDashboard"
              iconClass="fa fa-qrcode fa-lg"
              tooltip={I18n.t('menu.safecosmeticDashboard')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewErrorList') >= 0 && (
            <TooltipLinkContainer
              to="/stats"
              iconClass="fa fa-exclamation-circle fa-lg"
              tooltip={I18n.t('menu.stats')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewErrorList') >= 0 && (
            <TooltipLinkContainer
              to="/errors"
              iconClass="fa fa-exclamation-triangle fa-lg"
              tooltip={I18n.t('menu.errors')}
            />
          )}
          {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
            <TooltipLinkContainer to="/dashboard" iconClass="fa fa-list fa-lg" tooltip={I18n.t('menu.dashboardList')} />
          )}
        </NavDropdown>
      )}
    </Nav>
  </div>
);

Navigation.propTypes = {
  userDetails: PropTypes.shape({
    UserName: PropTypes.string,
  }).isRequired,
};

export default Navigation;
