import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as actionTypes from './actionTypes';
import { substanceActionTypes } from '../substanceAction';
import { rawMatActionTypes } from '../rawMatAction';

const byId = handleActions(
  {
    [actionTypes.ACTION_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.actions,
    }),
    [combineActions(
      substanceActionTypes.FETCH_SUBSTANCE_ACTIONS.SUCCESS,
      rawMatActionTypes.FETCH_RAWMAT_ACTIONS.SUCCESS,
    )]: (state, { payload }) => merge({}, state, payload.entities.actions),
  },
  {},
);

const allIds = handleActions(
  {
    [actionTypes.ACTION_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(
      substanceActionTypes.FETCH_SUBSTANCE_ACTIONS.SUCCESS,
      rawMatActionTypes.FETCH_RAWMAT_ACTIONS.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.actions) {
        return [...new Set([...state, ...Object.keys(payload.entities.actions).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
