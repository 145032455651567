import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import BaseLoader from '../../../_Common/BaseLoader';
import InciText from '../../../_Common/InciText';
import EditInciListForm from './EditInciListForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const EditInciListModal = ({
  title,
  closeModal,
  productId,
  isSaveDisabled,
  calculatedInciTextUpperPart,
  calculatedInciTextLowerPart,
  onSaveButtonClick,
  isFormSubmitting,
  isLoadingInciList,
}) => (
  <div>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <BaseLoader isLoading={isFormSubmitting}>
      <Modal.Body>
        <EditInciListForm productId={productId} />
        <hr />
        <h3 {...css({ display: 'inline' })}>
          <Translate value="editInciListModal.calculatedInciListTitle" />{' '}
          {isLoadingInciList && <i className="fa fa-spinner fa-spin" aria-hidden="true" />}
        </h3>
        <div {...css({ marginTop: '10px' })}>
          <InciText inciUpperPart={calculatedInciTextUpperPart} inciLowerPart={calculatedInciTextLowerPart} />
        </div>
      </Modal.Body>
    </BaseLoader>
    <Modal.Footer>
      <div className={styles.buttonPanel}>
        <ButtonToolbar>
          <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
            <i className="fa fa-times" />&nbsp;
            <Translate value="general.close" />
          </Button>
          <Button bsSize="small" bsStyle="success" type="button" onClick={onSaveButtonClick} disabled={isSaveDisabled}>
            <Translate value="general.save" />&nbsp;
            <i className="fa fa-arrow-circle-right" />
          </Button>
        </ButtonToolbar>
      </div>
    </Modal.Footer>
  </div>
);

EditInciListModal.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  isLoadingInciList: PropTypes.bool.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  calculatedInciTextUpperPart: PropTypes.string.isRequired,
  calculatedInciTextLowerPart: PropTypes.string.isRequired,
};

export default EditInciListModal;
