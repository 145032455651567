import {put, all, select, takeLatest, call} from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as substanceTRFormActions from './substanceTRFormActions';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import {referentialSelectors} from "../../referentials";
import {substanceTRSagas} from "../../substanceTR";

export default function* root() {
  yield all([
    takeLatest(substanceTRFormActions.submitSubstanceTRForm.REQUEST, submitProductTRFormSaga),
  ]);
}

const submitProductTRFormSaga = createSaveFormWithValidation(substanceTRFormActions.submitSubstanceTRForm, saveSubstanceTRSaga);

export function* saveSubstanceTRSaga({payload}) {
  console.log('saveSubstanceTRSaga');
  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const finalValue = [];
  languages.forEach(lang => {
    finalValue.push(payload[lang.Alpha2]);
  })

  yield call(substanceTRSagas.saveSubstanceTRsSaga, {payload: finalValue});
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.SUBSTANCE_TR_FORM) {
    yield put(modalActions.hideModal());
  }
}

