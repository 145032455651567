import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { substanceActions, substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { impurityModalSelectors, impurityModalTypes } from '../../../../redux/modal/impurityModal';
import SubstanceList from './SubstanceList';

class SubstanceListContainer extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return <SubstanceList {...this.props} height="70vh" />;
  }
}

const mapStateToProps = (state, { rawMatId }) => {
  const substanceList = substanceSelectors.getImpuritySubstances(state);
  return {
    rawMatProportionList: impurityModalSelectors.makeGetRawMatProportionListForRawMat(state)(rawMatId),
    substanceList,
    showLoading:
      substanceSelectors.getIsLoadingImpuritySubstances(state) ||
      applicationSelectors.isLoading(state, impurityModalTypes.SAVE_RAWMAT_PROPORTION_IMPURITIES.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMount: () => {
    dispatch(substanceActions.fetchSubstances.request({ IsImpurity: true }));
  },
});

SubstanceListContainer.propTypes = {
  onMount: PropTypes.func.isRequired,
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceListContainer);
