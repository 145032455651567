import { createRequestActions, createFetchAction } from '../utils';
import * as mandateProductTypes from './mandateProductTypes';

export const fetchMandateProducts = createFetchAction(mandateProductTypes.FETCH_MANDATE_PRODUCTS, 'mandateProductId');
export const saveMandateProduct = createRequestActions(
  mandateProductTypes.SAVE_MANDATE_PRODUCT,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
