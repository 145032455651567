import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import queryString from 'query-string';
import * as R from 'ramda';
import * as formulaReviewTypes from './formulaReviewTypes';

const byId = handleActions(
  {
    [formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.formulaReviews,
    }),
    [formulaReviewTypes.FETCH_FORMULA_REVIEWS.SUCCESS]: (state, { payload, meta = {} }) =>
      (payload.entities.formulaReviews
        ? {
          ...state,
          ...payload.entities.formulaReviews,
        }
        : state),
  },
  {},
);

const allIds = handleActions(
  {
    [formulaReviewTypes.FORMULA_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
    [formulaReviewTypes.FETCH_FORMULA_REVIEWS.SUCCESS]: (state, { payload }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
  },
  [],
);

const byQueryIds = handleActions(
  {
    [formulaReviewTypes.FETCH_FORMULA_REVIEWS.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [combineActions(
      formulaReviewTypes.FETCH_FORMULA_REVIEWS.REQUEST,
      formulaReviewTypes.FETCH_FORMULA_REVIEWS.SUCCESS,
      formulaReviewTypes.FETCH_FORMULA_REVIEWS.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === formulaReviewTypes.FETCH_FORMULA_REVIEWS.REQUEST ? payload : meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: type === formulaReviewTypes.FETCH_FORMULA_REVIEWS.REQUEST,
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
