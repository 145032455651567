import { createSelector } from 'reselect';

export const getById = ({ impactAssessmentAction }) => impactAssessmentAction.byId;
export const getIds = ({ impactAssessmentAction }) => impactAssessmentAction.list.allIds;
export const getImpactAssessmentActionList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

const getImpactAssessmentActionsForSubstance = impactAssessmentId =>
  createSelector(getImpactAssessmentActionList, impactAssessmentActionList =>
    impactAssessmentActionList.filter(
      impactAssessmentAction => impactAssessmentAction.ImpactAssessmentId === impactAssessmentId,
    ),
  );

export const makeGetImpactAssessmentActionsForSubstance = createSelector(
  getImpactAssessmentActionsForSubstance,
  impactAssessmentActionsForSubtance => impactAssessmentActionsForSubtance,
);
