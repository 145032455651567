import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import * as substanceDocumentTypes from './substanceDocumentTypes';

const byId = handleActions(
  {
    [substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.substanceDocuments,
    }),

    [substanceDocumentTypes.DELETE_SUBSTANCE_DOCUMENT.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
    [substanceDocumentTypes.DELETE_SUBSTANCE_DOCUMENT.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
