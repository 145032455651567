import * as substanceProportionSelectors from './substanceProportionSelectors';
import * as substanceProportionActions from './substanceProportionActions';
import * as substanceProportionTypes from './substanceProportionTypes';
import * as substanceProportionSchemas from './substanceProportionSchemas';

import sagas, * as substanceProportionSagas from './substanceProportionSagas';
import reducer from './substanceProportionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  substanceProportionSelectors,
  substanceProportionActions,
  substanceProportionTypes,
  substanceProportionSchemas,
  substanceProportionSagas,
};
export default reducer;
