import { takeLatest, takeEvery, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import productPhraseApi from '../../api/productPhraseApi';
import * as productPhraseTypes from './productPhraseTypes';
import * as productPhraseActions from './productPhraseActions';
import * as productPhraseSchemas from './productPhraseSchemas';

export default function* root() {
  yield all([
    takeEvery(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.REQUEST, getProductPhraseListForProductSaga),
    takeLatest(productPhraseTypes.SAVE_PRODUCT_PHRASE.REQUEST, saveProductPhraseSaga),
    takeLatest(productPhraseTypes.COPY_PRODUCT_PHRASE_FROM_PRODUCT.REQUEST, copyProductPhrasesFromProduct),
    takeLatest(productPhraseTypes.DELETE_PRODUCT_PHRASE.REQUEST, deleteProductPhraseSaga),
  ]);
}

function* deleteProductPhraseSaga({ payload, type }) {
  const { ProductId, Ids } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productPhraseActions.deleteProductPhrase, productPhraseApi.deleteProductPhrase, Ids);
  yield put(productPhraseActions.listProductPhraseForProduct.request({ ProductId }));
  yield put(applicationActions.unsetLoading(type));
}

function* saveProductPhraseSaga({ payload, type }) {
  const { productId, data } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productPhraseActions.saveProductPhrase, productPhraseApi.saveProductPhrase, data);
  yield put(productPhraseActions.listProductPhraseForProduct.request({ productId }));
  yield put(applicationActions.unsetLoading(type));
}

function* copyProductPhrasesFromProduct({ payload, type }) {
  const { toProductId, cpsrTypeCode } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    productPhraseActions.copyProductPhrasesFromProduct,
    productPhraseApi.copyProductPhrasesFromProduct,
    payload,
  );
  yield put(productPhraseActions.listProductPhraseForProduct.request({ ProductId: toProductId, cpsrTypeCode }));
  yield put(applicationActions.unsetLoading(type));
}

function* getProductPhraseListForProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    productPhraseActions.listProductPhraseForProduct,
    productPhraseApi.getProductPhraseList,
    productPhraseSchemas.productPhraseWithPhraseListSchema,
    {
      ...payload,
      OptIncludePhrase: true,
    },
  );
  yield put(applicationActions.unsetLoading(type));
}
