import {createAction} from 'redux-actions';
import {createFormAction} from 'redux-form-saga';
import {createRequestActions} from '../utils';

import * as productTypes from './productTypes';
import workflowTypes from '../../constants/workflowTypes';

export const submitProductForm = createFormAction(productTypes.SUBMIT_PRODUCT_FORM);

export const listProducts = createRequestActions(productTypes.PRODUCT_LIST);
export const listProducts2 = createRequestActions(productTypes.PRODUCT_LIST2);
export const saveProductCpsrbComment = createRequestActions(productTypes.SAVE_PRODUCT_CPSRB_COMMENT);
export const listProductForCompany = createRequestActions(
  productTypes.PRODUCT_LIST_FOR_COMPANY,
  companyId => ({
    companyId,
  }),
  (data, companyId) => companyId,
);
export const product = createRequestActions(productTypes.PRODUCT);

export const generateMissingDocumentText = createRequestActions(productTypes.GENERATE_MISSING_DOCUMENT_TEXT);

export const updateInciQty = createRequestActions(productTypes.UPDATE_INCI_QTY, ProductId => ({
  ProductId,
}));
export const saveProduct = createRequestActions(productTypes.SAVE_PRODUCT);
export const reportTest = createRequestActions(productTypes.REPORT_TEST);
export const getProductReport = createRequestActions(productTypes.REPORT);
export const updateProduct = createRequestActions(productTypes.UPDATE_PRODUCT);
export const saveProductProportions = createRequestActions(productTypes.SAVE_PRODUCT_PROPORTIONS);
export const processImportedProductProportions = createRequestActions(
  productTypes.PROCESS_IMPORTED_PRODUCT_PROPORTIONS,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);
export const importProductProportions = createRequestActions(
  productTypes.IMPORT_PRODUCT_PROPORTIONS,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);
export const deleteImportedProductProportions = createRequestActions(
  productTypes.DELETE_IMPORTED_PRODUCT_PROPORTIONS,
  (ProductId, data) => ({
    ProductId,
    ...data,
  }),
);

export const saveProductPhrases = createRequestActions(productTypes.SAVE_PRODUCT_PHRASES);
export const deleteProductPhrases = createRequestActions(productTypes.DELETE_PRODUCT_PHRASES);
export const deleteProductProportion = createRequestActions(productTypes.DELETE_PRODUCT_PROPORTION);
export const listProductOrders = createRequestActions(productTypes.PRODUCT_ORDER_LIST);
export const listProductTasks = createRequestActions(productTypes.PRODUCT_TASK_LIST);
export const listProductImpactAssessments = createRequestActions(productTypes.PRODUCT_IMPACT_ASSESSMENT_LIST);
export const listProductPhrases = createRequestActions(productTypes.PRODUCT_PHRASE_LIST);
export const listProductInciList = createRequestActions(productTypes.PRODUCT_INCI_LIST);
export const listProductMosCalculationList = createRequestActions(productTypes.PRODUCT_MOS_CALCULATION_LIST);
export const listProductFormulaReviewList = createRequestActions(productTypes.PRODUCT_FORMULA_REVIEW_LIST);
export const listProductImpurityList = createRequestActions(productTypes.PRODUCT_IMPURITY_LIST);
export const listProductCosmosIngredientRawMatMatches = createRequestActions(
  productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST,
);
export const listProductProdigumProductProportions = createRequestActions(
  productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST,
);
export const listProductImportedProductProportions = createRequestActions(
  productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST,
);
export const saveAndLinkProductProportion = createRequestActions(productTypes.SAVE_AND_LINK_PRODUCT_PROPORTION);
export const saveAndLinkImportedProductProportion = createRequestActions(productTypes.SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION);
export const listOrdersOwnedByProductCompany = createRequestActions(productTypes.ORDERS_OWNED_BY_PRODUCT_COMPANY);
export const addOrderToCurrentProduct = createRequestActions(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT);
export const newProduct = createAction(productTypes.NEW_PRODUCT);
export const changeProductCompositionStatus = createAction(productTypes.CHANGE_PRODUCT_COMPOSITION_STATUS);

export const loadDetailPage = createAction(productTypes.LOAD_DETAIL_PAGE);

export const addOrderToCurrentProductAction = ({OrderId}) => ({
  type: productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST,
  payload: {
    OrderId,
  },
});

export const saveProductProportionAction = productProportion => ({
  type: productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
  payload: productProportion,
});

export const removeOrderFromProduct = createAction(productTypes.REMOVE_ORDER_FROM_PRODUCT, (ProductId, OrderId) => ({
  OrderId,
  ProductId,
}));

export const updateStatus = ({WorkflowStatusCode, ProductId, Comment = null}) => ({
  type: productTypes.UPDATE_PRODUCT_STATUS.REQUEST,
  payload: {
    WorkflowStatusCode,
    Comment,
    ProductId,
    WorkflowCode: workflowTypes.PRODUCT,
  },
});

export const updateCPSRStatus = createRequestActions(
  productTypes.UPDATE_PRODUCT_CPSR_STATUS,
  (ProductId, WorkflowStatusCode, WorkflowCode, Comment) => ({
    ProductId,
    WorkflowStatusCode,
    WorkflowCode,
    Comment,
  }),
);

export const updateCPSRBConclusionStatus = createRequestActions(
  productTypes.UPDATE_PRODUCT_CPSRB_CONCLUSION_STATUS,
  ({productId, workflowStatusCode, comment = null}) => ({
    productId,
    comment,
    workflowStatusCode,
    WorkflowCode: workflowTypes.CPSRB_CONCLUSION,
  }),
);

export const updateCompositionStatus = ({WorkflowStatusCode, ProductId, Comment = null}) => ({
  type: productTypes.UPDATE_COMPOSITION_STATUS.REQUEST,
  payload: {
    WorkflowStatusCode,
    Comment,
    ProductId,
    WorkflowCode: workflowTypes.PRODUCT_COMPO,
  },
});

export const exportCompositionForLatinAmerica = createRequestActions(
  productTypes.EXPORT_COMPOSITION_FOR_LATIN_AMERICA,
  ({productId}) => ({
    productId,
  }),
);

export const fetchLastFRCheckList = createRequestActions(productTypes.FETCH_LAST_FR_CHECK_LIST, productId => ({
  productId,
}));

export const checkListChangeStatusDocument = createRequestActions(
  productTypes.CHECK_LIST_CHANGE_STATUS,
  ({document, status, productId}) => ({
    document,
    status,
    productId,
  }),
);

export const fetchLastFRMissingDocument = createRequestActions(
  productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS,
  productId => ({
    productId,
  }),
);

export const missingDocumentChangeStatus = createRequestActions(
  productTypes.MISSING_DOCUMENT_CHANGE_STATUS,
  ({id, status, productId}) => ({
    id,
    status,
    productId,
  }),
);
