import { PrefixIndexStrategy } from 'js-search';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import createFilterOptions from 'react-select-fast-filter-options';
import * as rawMatProportionSelectors from '../rawMatProportion/rawMatProportionSelectors';
import { substanceSelectors } from '../substance';
import { createGeneralSelectors, createAuthorizationCheck } from '../_common/selectors';
import { substanceProportionSelectors } from '../substanceProportion';
import { productProportionSelectors } from '../productProportion';
import { formulaReviewSelectors } from '../formulaReview';
import { isNullOrUndef } from '../../utils/utils';
import { workflowStatusSelectors } from '../workflowStatus';
import { rawMatSelectors } from '../rawMat';
import { productSelectors } from '../product';
import { companySelectors } from '../company';
import rawMatAuthorizationCodes from '../../constants/rawMatAuthorizationCodes';

export const getCurrentRawMatDocuments = state => state.rawMat.currentRawMatDocuments;

const detailFormValueSelector = formValueSelector('rawMatForm');
export const getRawMatProportionFormSelector = formValueSelector('rawMatProportionForm');

export const getRawMatTitle = state => {
  const rawMatName = detailFormValueSelector(state, 'Name') || '';
  const code = detailFormValueSelector(state, 'Code') || '';
  return `${code} - ${rawMatName}`;
};

export const isNew = state => {
  const id = detailFormValueSelector(state, 'RawMatId');
  return id <= 0 || isNullOrUndef(id);
};

const getRawMatSlice = state => state.rawMat;

export const { getById, getIds, makeGetItemById: makeGetRawMatById } = createGeneralSelectors(getRawMatSlice);

export const getRawMat = (state, id) => getById(state)[id];
export const getRawMatById = id => state => getById(state)[id];
export const getRawMatList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getCurrentRawMatProportions = ({ rawMat }) => rawMat.rawMatReducer.currentRawMatProportions;

const getAuthorizationCodes = R.propOr([], 'AuthorizationCodes');

const createMakeGetAuthorizationChek = createAuthorizationCheck(makeGetRawMatById)(getAuthorizationCodes);

export const makeIsRawMatEditable = createMakeGetAuthorizationChek([rawMatAuthorizationCodes.EDIT_RAWMAT]);

const rawMatPrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetRawMatListFilter = createSelector(R.identity, rawMatList =>
  createFilterOptions({
    options: rawMatList,
    labelKey: 'FullName',
    valueKey: 'RawMatId',
    indexStrategy: rawMatPrefixIndexStrategy,
  }),
);

export const sortByName = createSelector(
  R.identity,
  R.sortWith([R.ascend(R.propOr('', 'Name')), R.ascend(R.propOr('', 'SupplierName'))]),
);

export const getRawMatListForProduct = productId =>
  createSelector(
    [productProportionSelectors.getProductProportionsForProduct(productId), getById],
    (productProportions, rawMatsById) => {
      const rawMatIds = [...new Set(productProportions.map(pp => pp.RawMatId))];
      return rawMatIds.map(id => rawMatsById[id]);
    },
  );

export const makeGetRawMatListForProduct = createSelector(getRawMatListForProduct, R.identity);

export const getPublicRawMats = createSelector(getRawMatList, rawMatList =>
  rawMatList.filter(rawMat => !rawMat.CompanyId),
);

export const makeGetAvailableRawMatListForProduct = () =>
  createSelector(
    productSelectors.getById,
    companySelectors.getRawMatListForCompany,
    companySelectors.getById,
    rawMatSelectors.getPublicRawMats,
    workflowStatusSelectors.getWorkflowStatusList,
    (productById, getRawMatListForCompany, companyById, publicRawMatList, rawMatStatusList) =>
      createSelector(
        productId => productById[productId],
        product => {
          const company = companyById[product.ProducerId];
          return company && company.UsesPrivateRawMat ? getRawMatListForCompany(product.ProducerId) : publicRawMatList;
        },
      ),
  );

export const getRawMatListForFormulaReview = (state, formulaReviewId) => {
  const formulaReview = formulaReviewSelectors.getFormulaReview(state, formulaReviewId);

  return formulaReview && getRawMatListForProduct(formulaReview.ProductId)(state);
};

export const getAddEditRawMatProportionSubstanceList = createSelector(
  substanceSelectors.getSubstanceList,
  substanceSelectors.makeGetSubstancesNotAlreadyInRawMatComposition(),
  (substanceList, getSubstancesNotAlreadyInRawMatComposition) =>
    createSelector(
      (rawMatId, rawMatProportionId) =>
        (rawMatProportionId ? substanceList : getSubstancesNotAlreadyInRawMatComposition(rawMatId)),
      substances => substances,
    ),
);

const substancePrefixIndexStrategy = new PrefixIndexStrategy();
export const getAllowedNewSubstanceListFilter = createSelector(
  getAddEditRawMatProportionSubstanceList,
  getAddEditRawMatProportionSubstances =>
    createSelector(
      rawMatId => getAddEditRawMatProportionSubstances(rawMatId, null),
      substanceList =>
        createFilterOptions({
          options: substanceList,
          labelKey: 'InciName',
          valueKey: 'SubstanceId',
          indexStrategy: substancePrefixIndexStrategy,
        }),
    ),
);

export const getRawMatCompositionForRawMat = createSelector(
  rawMatProportionSelectors.getRawMatProportionListForRawMat,
  substanceSelectors.getById,
  substanceProportionSelectors.getById,
  (getRawMatProportionListForRawMat, substanceById, substanceProportionById) =>
    createSelector(
      rawMatId => getRawMatProportionListForRawMat(rawMatId),
      rawMatProportionsForRawMat => {
        const composition = rawMatProportionsForRawMat.reduce(
          (acc, rp) => [
            ...acc,
            {
              ...rp,
              PercentageInRawMat: rp.Percentage,
              Type: 'complexSubstance',
              InciName: substanceById[rp.SubstanceId].InciName,
              Children: substanceById[rp.SubstanceId].SubstanceProportions?substanceById[rp.SubstanceId].SubstanceProportions.map(
                spId => substanceProportionById[spId],
              ).reduce(
                (acc2, sp) => [
                  ...acc2,
                  {
                    ...sp,
                    Type: 'simpleSubstance',
                    ...substanceById[sp?sp.ReferencedSubstanceId:-1],
                  },
                ],
                [],
              ):[],
            },
          ],
          [],
        );
        return composition;
      },
    ),
);

export const getRawMatListWithCompo = createSelector(
  [getIds, getById, rawMatProportionSelectors.getRawMatProportionFromCompoForRawMatWithSubstance],
  (allIds, byId, getRMP) => {
    return allIds.map(id => {
      const rm = byId[id];
      const rmP = getRMP(id);
      return ({
        ...rm,
        Id: `RawMat${rm.RawMatId}`,
        Children: rmP.map(elem => ({
          Id: `RawMatProportion${elem.RawMatProportionId}`,
          Code: elem.Substance.Code,
          Name: elem.Substance.InciName,
          Percentage: elem.Percentage,
          IsImpurity: elem.IsImpurity
        })),
      });
    });
  }
);
