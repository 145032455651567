import {all, put, takeLatest} from 'redux-saga/effects';
import {applicationActions} from "../application";
import {modalActions} from "../modal";
import {callApiSaga, callApiSagaAndNormalize} from "../sagaUtils";
import productCategoryApi from "../../api/productCategoryApi";
import productApi from "../../api/productApi";
import * as productCategoryTypes from "./productCategoryTypes";
import {productCategoryActions, productCategorySchemas} from "./index";

export default function* root() {
  yield all([
    takeLatest(productCategoryTypes.PRODUCT_CATEGORY_LIST.REQUEST, getProductCategoryListSaga),
    takeLatest(productCategoryTypes.SAVE_PRODUCT_CATEGORY.REQUEST, saveProductCategorySaga),
    takeLatest(productCategoryTypes.DELETE_PRODUCT_CATEGORY.REQUEST, deleteProductCategorySaga),
    takeLatest(productCategoryTypes.MARK_PRODUCT_CATEGORY_AS_PRINCIPAL.REQUEST, markProductCategoryAsPrincipalSaga),
  ]);
}

function* getProductCategoryListSaga(action) {
  yield put(applicationActions.setLoading(productCategoryTypes.PRODUCT_CATEGORY_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    productCategoryActions.listProductCategories,
    productApi.getProductCategories,
    productCategorySchemas.productCategoryListSchema,
    action.payload
  );
  yield put(applicationActions.unsetLoading(productCategoryTypes.PRODUCT_CATEGORY_LIST.REQUEST));
}

function* saveProductCategorySaga(action) {
  yield put(applicationActions.setLoading(productCategoryTypes.SAVE_PRODUCT_CATEGORY.REQUEST));
  const {payload} = action;
  yield put(modalActions.hideModal());
  yield callApiSaga(productCategoryActions.saveProductCategory, productCategoryApi.saveProductCategory, payload);
  yield put(productCategoryActions.listProductCategories.request(action.payload.productId));
  yield put(applicationActions.unsetLoading(productCategoryTypes.SAVE_PRODUCT_CATEGORY.REQUEST));
}

function* markProductCategoryAsPrincipalSaga(action) {
  yield put(applicationActions.setLoading(productCategoryTypes.MARK_PRODUCT_CATEGORY_AS_PRINCIPAL.REQUEST));
  const {payload} = action;
  yield callApiSaga(productCategoryActions.saveProductCategory, productCategoryApi.markProductCategoryAsPrincipal, payload.productCategoryId);
  yield put(productCategoryActions.listProductCategories.request(action.payload.productId));
  yield put(applicationActions.unsetLoading(productCategoryTypes.MARK_PRODUCT_CATEGORY_AS_PRINCIPAL.REQUEST));
}

function* deleteProductCategorySaga(action) {
  yield put(applicationActions.setLoading(productCategoryTypes.DELETE_PRODUCT_CATEGORY.REQUEST));
  yield callApiSaga(productCategoryActions.deleteProductCategory, productCategoryApi.deleteProductCategory, action.payload.productCategoryId);
  yield put(productCategoryActions.listProductCategories.request(action.payload.productId));
  yield put(applicationActions.unsetLoading(productCategoryTypes.DELETE_PRODUCT_CATEGORY.REQUEST));
}
