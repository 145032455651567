import moment from 'moment';

export default (valueA, valueB) => {
  const dateA = moment(valueA);
  const dateB = moment(valueB);
  if (!dateA.isValid() || !dateB.isValid()) {
    return 0;
  }
  return dateA.isBefore(dateB) ? -1 : 1;
};
