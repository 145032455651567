import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid } from '../../Grids';

const SubstanceList = props => {
  const { substanceList, height, showLoading, inciSubstanceList, whenGridReady } = props;

  const getSubstanceValue = ({ data, colDef }) =>
    R.compose(R.propOr('', colDef.field), R.find(R.propEq('SubstanceId', data.SubstanceId)))(substanceList);

  const columns = [
    {
      headerName: I18n.t('substance.code'),
      field: 'Code',
      valueGetter: getSubstanceValue,
    },
    {
      headerName: I18n.t('substance.casNr'),
      field: 'CasNr',
      valueGetter: getSubstanceValue,
    },
    {
      headerName: I18n.t('substance.inciName'),
      field: 'InciName',
      sort: 'asc',
      valueGetter: getSubstanceValue,
    },
    {
      headerName: I18n.t('rawMatProportion.percentage'),
      field: 'PercentageInProduct',
      editable: true,
    },
    {
      headerName: I18n.t('rawMatProportion.percentageRange'),
      field: 'PercentageRange',
      editable: true,
    },
    {
      headerName: I18n.t('rawMatProportion.percentageRangeComment'),
      field: 'PercentageRangeComment',
      editable: true,
    },
  ];

  return (
    <SelectionGrid
      key={`${props.productid}-${inciSubstanceList.length}-${substanceList.length}`}
      data={inciSubstanceList}
      height={height}
      isLoading={showLoading}
      showRefreshButton={false}
      columns={columns}
      keyName="InciSubstanceId"
      whenGridReady={whenGridReady}
      singleClickEdit
      stopEditingWhenGridLosesFocus
    />
  );
};

SubstanceList.defaultProps = {
  height: '70vh',
  whenGridReady: () => {},
};

SubstanceList.propTypes = {
  substanceList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string,
      CasNr: PropTypes.string,
      SubstanceId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  inciSubstanceList: PropTypes.arrayOf(
    PropTypes.shape({
      InciSubstanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      SubstanceId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  height: PropTypes.string,
  whenGridReady: PropTypes.func,
  showLoading: PropTypes.bool.isRequired,
};

export default SubstanceList;
