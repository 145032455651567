import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';


class LinkCellRenderer extends React.Component {
  render() {
    if(this.props.downlodable){
      return (
        <a href={get(this.props.data, this.props.linkKey)} download>
          {get(this.props.data, this.props.textKey)}
      </a>
        );
    } else {
      return (
        <a href={get(this.props.data, this.props.linkKey)} target={this.props.target} >
          {get(this.props.data, this.props.textKey)}
    </a>
      );
    }
  }
}

LinkCellRenderer.defaultProps = {
  linkKey: 'FileLink',
  textKey: 'FileName',
  target: '_self',
  downloadable: true
};

LinkCellRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  linkKey: PropTypes.string,
  textKey: PropTypes.string,
  downlodable: PropTypes.bool,
  target: PropTypes.string
};

export default LinkCellRenderer;
