import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

const DeleteButton = props => {
  const { onClick, disabled } = props;
  return (
    <Button bsStyle="danger" bsSize="xsmall" type="button" onClick={onClick} disabled={disabled}>
      <i className="fa fa-trash" />&nbsp;<Translate value="general.remove" />
    </Button>
  );
};

DeleteButton.defaultProps = {
  disabled: false,
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DeleteButton;
