import { handleActions } from 'redux-actions';
import * as ingredientTypes from './ingredientTypes';

const initialState = {
  ingredientList: [],
  currentIngredient: {},
};

export default handleActions(
  {
    [ingredientTypes.INGREDIENT_LIST.SUCCESS]: (state, { payload }) => ({ ...state, ingredientList: payload }),
    [ingredientTypes.INGREDIENT.SUCCESS]: (state, { payload }) => ({ ...state, currentIngredient: payload }),
    [ingredientTypes.NEW_INGREDIENT]: state => ({
      ...state,
      currentIngredient: {
        IngredientId: -1,
      },
    }),
  },
  initialState,
);
