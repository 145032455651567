import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import formNames from '../../../constants/formNames';
import SubstanceClpForm from './SubstanceClpForm';
import { substanceClpActions, substanceClpTypes } from '../../../redux/substanceClp';
import { applicationSelectors } from '../../../redux/application';
import { substanceClpFormSelectors } from '../../../redux/form/substanceClpForm';

const formName = formNames.substanceClp;

class SubstanceClpFormContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <SubstanceClpForm {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => {
  const getInitialValues = substanceClpFormSelectors.makeGetInitialValues(substanceId);
  return {
    initialValues: getInitialValues(state),
    isLoading: applicationSelectors.isLoading(
      state,
      substanceClpTypes.SAVE_SUBSTANCE_CLP.REQUEST,
      substanceClpTypes.FETCH_SUBSTANCE_CLPS.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceClpActions.fetchSubstanceClps.request({ substanceId }));
    }
  },
  onSubmit: data => dispatch(substanceClpActions.saveSubstanceClp.request({ substanceId, ...data })),
});

SubstanceClpFormContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(SubstanceClpFormContainer);
