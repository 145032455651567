import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { toxProfileChapterSelectors } from '../../../redux/toxProfileChapter';
import { SelectField } from '../Forms';

const ToxProfileChapterDropdown = props => {
  const { chapterList } = props;

  return (
    <div>
      <SelectField options={chapterList} labelField="FullTitle" valueField="Code" useVirtualized {...props} />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { initialOptions, subChapter, parentChapterCode } = props;
  let chapterList = initialOptions || toxProfileChapterSelectors.getToxProfileChapterList(state);
  chapterList = getChapterList(chapterList, subChapter, parentChapterCode);
  return {
    chapterList,
  };
};

ToxProfileChapterDropdown.defaultProps = {
  children: null,
  subChapter: false,
};

ToxProfileChapterDropdown.propTypes = {
  chapterList: PropTypes.arrayOf(
    PropTypes.shape({
      ToxProfileChapterId: PropTypes.number.isRequired,
      FullTitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
  children: PropTypes.func,
  subChapter: PropTypes.bool,
  parentChapterCode: PropTypes.string,
};

const getChapterList = (chapterList, subChapter, parentChapterCode) => {
  const setChapterFullTitle = chapter => R.assoc('FullTitle', `${R.prop('Title', chapter)}`, chapter);
  chapterList = chapterList.map(setChapterFullTitle);

  // console.log('subChapter', subChapter);
  // console.log('parentChapterCode', parentChapterCode);

  const sortedList = subChapter
    ? chapterList.filter(chap => chap.ParentChapterCode == parentChapterCode).sort((a, b) => a.Order > b.Order)
    : chapterList.filter(chap => chap.ParentChapterCode == null).sort((a, b) => a.Order > b.Order);
  // chapterList
  //   .filter(chap => chap.ParentChapterCode == null)
  //   .sort((a, b) => a.Order > b.Order)
  //   .map(chap => {
  //     sortedList.push(chap);
  //     chapterList.filter(all => all.ParentChapterCode == chap.Code).forEach(elem => sortedList.push(elem));
  //   });

  return sortedList;
};

export default connect(
  mapStateToProps,
  null,
)(ToxProfileChapterDropdown);
