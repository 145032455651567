import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  safecosmeticDashboardActions,
  safecosmeticDashboardTypes,
  safecosmeticDashboardSelectors,
} from '../../../redux/safecosmeticDashboard';
import SafecosmeticDashboardProductList from './SafecosmeticDashboardProductList';
import { applicationSelectors } from '../../../redux/application';
import { companySelectors, companyActions } from '../../../redux/company';
import userRoleTypes from '../../../constants/userRoleTypes';
import { userSelectors } from '../../../redux/user';
import { userRoleActions } from '../../../redux/userRole';
import { push } from 'react-router-redux';

class SafecosmeticDashboardContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <SafecosmeticDashboardProductList {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    safecosmeticDashboardProductList: safecosmeticDashboardSelectors.getProductList(
      state,
    ),
    isLoading: applicationSelectors.isLoading(
      state,
      safecosmeticDashboardTypes.SAFECOSMETIC_DASHBOARD_LIST.REQUEST,
    ),
    companyList: companySelectors.getCompanyList(state),
    salesRepList: userSelectors.getUsersByRole(state, userRoleTypes.saleRep),
    expertList: userSelectors.getUsersByRole(state, userRoleTypes.expert),
    accountManagerList: userSelectors.getUsersByRole(state, userRoleTypes.customerSupport),
  };
};

const mapDispatchToProps = (dispatch, state) => ({
  onLoad: () => {
    dispatch(companyActions.listCompanies.request());
    dispatch(
      userRoleActions.listUserRoleForRoles.request([
        userRoleTypes.expert,
        userRoleTypes.customerSupport,
        userRoleTypes.saleRep,
      ]),
    );
  },
  getProductList: () => dispatch(safecosmeticDashboardActions.listProducts.request()),
  onRefreshList: (payload) => dispatch(safecosmeticDashboardActions.listProducts.request(payload)),
  searchProducts: (payload) => dispatch(safecosmeticDashboardActions.listProducts.request(payload)),
  onShowOnSafecosmetic: (payload) => dispatch(safecosmeticDashboardActions.showOnSafecosmetic.request(payload)),
  onShowOnCosmos: (payload) => dispatch(safecosmeticDashboardActions.showOnCosmos.request(payload)),
  onRowDoubleClick: product => dispatch(push(`/products/${product.ProductId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  SafecosmeticDashboardContainer,
);

SafecosmeticDashboardContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
