import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col, Radio, Panel } from 'react-bootstrap';

const LabelReviewStatus = props => {
  const { labelInfoAvailabilityCodes, labelReview, updateLabelReview } = props;
  const { InnerLabelAvailability, OuterLabelAvailability, LeafletLabelAvailability } = labelReview;
  const handleRadioButtonChangeForLabel = labelName => ({ target }) => updateLabelReview({ [labelName]: target.value });
  return (
    <Grid fluid>
      <Col sm={12}>
        <Row>
          <Col sm={4}>
            <Panel header={I18n.t('labelReviewWizard.innerLabel')}>
              {labelInfoAvailabilityCodes &&
                labelInfoAvailabilityCodes.map(({ Key, Value }) =>
                  (<Radio
                    key={`inner_${Key}`}
                    bsSize="small"
                    value={Key}
                    name="LR-innerLabel"
                    checked={InnerLabelAvailability === Key}
                    onChange={handleRadioButtonChangeForLabel('InnerLabelAvailability')}
                  >
                    {Value}
                  </Radio>),
                )}
            </Panel>
          </Col>
          <Col sm={4}>
            <Panel header={I18n.t('labelReviewWizard.outerLabel')}>
              {labelInfoAvailabilityCodes &&
                labelInfoAvailabilityCodes.map(({ Key, Value }) =>
                  (<Radio
                    key={`outer_${Key}`}
                    bsSize="small"
                    value={Key}
                    name="LR-outerLabel"
                    checked={OuterLabelAvailability === Key}
                    onChange={handleRadioButtonChangeForLabel('OuterLabelAvailability')}
                  >
                    {Value}
                  </Radio>),
                )}
            </Panel>
          </Col>
          <Col sm={4}>
            <Panel header={I18n.t('labelReviewWizard.leaflet')}>
              {labelInfoAvailabilityCodes &&
                labelInfoAvailabilityCodes.map(({ Key, Value }) =>
                  (<Radio
                    key={`leaflet_${Key}`}
                    bsSize="small"
                    value={Key}
                    name="LR-leafletLabel"
                    checked={LeafletLabelAvailability === Key}
                    onChange={handleRadioButtonChangeForLabel('LeafletLabelAvailability')}
                  >
                    {Value}
                  </Radio>),
                )}
            </Panel>
          </Col>
        </Row>
      </Col>
    </Grid>
  );
};

LabelReviewStatus.defaultProps = {
  InnerLabelAvailability: undefined,
  OuterLabelAvailability: undefined,
  LeafletLabelAvailability: undefined,
  labelInfoAvailabilityCodes: undefined,
};

LabelReviewStatus.propTypes = {
  labelInfoAvailabilityCodes: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ),
  labelReview: PropTypes.shape({
    LabelReviewId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  updateLabelReview: PropTypes.func.isRequired,
};

export default LabelReviewStatus;
