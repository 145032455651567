import { createAction } from 'redux-actions';
import { I18n } from 'react-redux-i18n';
import { push } from 'react-router-redux';

import * as modalTypes from './modalTypes';

export const showModal = createAction(modalTypes.SHOW_MODAL);
export const hideModal = createAction(modalTypes.HIDE_MODAL);
export const showEditRawMatProportionModal = createAction(modalTypes.SHOW_EDIT_RAWMAT_PROPORTION_MODAL);

// Action creators
export const showModalComponent = (modalType, modalComponent, modalProps) => dispatch => {
  dispatch(
    showModal({
      modalType,
      modalProps,
      modalComponent,
    }),
  );
};

const modalWidth = {
  EXTRALARGE: '1600px',
  LARGE: '1200px',
  NARROW: '600px',
};

export const showAddProductToOrderFormModal = modalProps =>
  showModal({ modalType: modalTypes.ADD_PRODUCT_TO_ORDER_FORM, modalProps });

export const showCosAnnexeFormModal = modalProps => showModal({ modalType: modalTypes.COS_ANNEXE_FORM, modalProps });
export const showSubstanceStatusCommentModal = modalProps =>
  showModal({ modalType: modalTypes.SUBSTANCE_STATUS_COMMENT, modalProps });
export const showCpsrStatusCommentModal = modalProps =>
  showModal({ modalType: modalTypes.CPSR_STATUS_COMMENT, modalProps });
export const showPhraseFormModal = modalProps => showModal({ modalType: modalTypes.PHRASE_FORM, modalProps });
export const showCategoryFormModal = modalProps => showModal({ modalType: modalTypes.CATEGORY_FORM, modalProps });
export const showTaskTypeFormModal = modalProps => showModal({ modalType: modalTypes.TASKTYPE_FORM, modalProps });
export const showRawMatStatusCommentModal = modalProps =>
  showModal({ modalType: modalTypes.RAWMAT_STATUS_COMMENT, modalProps });
export const showOrderStatusCommentModal = modalProps =>
  showModal({ modalType: modalTypes.ORDER_STATUS_COMMENT, modalProps });
export const showPrintDocumentsModal = modalProps => showModal({ modalType: modalTypes.PRINT_DOCUMENTS, modalProps: { modalWidth: "700px", ...modalProps } });
export const showSubstanceInformationFormModal = modalProps =>
  showModal({ modalType: modalTypes.SUBSTANCE_INFORMATION_FORM, modalProps });
export const showShadeFormModal = modalProps => showModal({ modalType: modalTypes.SHADE_FORM, modalProps });
export const showProductCategoryFormModal = modalProps => showModal({ modalType: modalTypes.PRODUCT_CATEGORY_FORM,
  modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showProductCategoryTranslationFormModal = modalProps => showModal({ modalType: modalTypes.PRODUCT_CATEGORY_TRANSLATION_FORM,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showNotificationFormModal = modalProps => showModal({ modalType: modalTypes.NOTIFICATION_FORM, modalProps });
export const showRawMatInformationFormModal = modalProps =>
  showModal({
    modalType: modalTypes.RAWMAT_INFORMATION_FORM,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showAllergenListModal = modalProps =>
  showModal({
    modalType: modalTypes.ALLERGEN_LIST,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showImpurityListModal = modalProps =>
  showModal({
    modalType: modalTypes.IMPURITY_LIST,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showProductInciImpurityListModal = modalProps =>
  showModal({
    modalType: modalTypes.PRODUCT_INCI_IMPURITY_LIST,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showSubstanceActionsModal = modalProps =>
  showModal({
    modalType: modalTypes.SUBSTANCE_ACTIONS_HISTORY,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });

export const showImpactAssessmentActionsModal = modalProps =>
  showModal({
    modalType: modalTypes.IMPACT_ASSESSMENT_ACTIONS_HISTORY,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showRawMatActionsModal = modalProps =>
  showModal({
    modalType: modalTypes.RAWMAT_ACTIONS_HISTORY,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showOrderActionsModal = modalProps =>
  showModal({
    modalType: modalTypes.ORDER_ACTIONS_HISTORY,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showOrderMissingDocumentListModal = modalProps =>
  showModal({
    modalType: modalTypes.ORDER_MISSING_DOCUMENT_LIST_FORM,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showSubstanceToxProfileModal = modalProps =>
  showModal({
    modalType: modalTypes.SUBSTANCE_TOX_PROFILE,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showShadeCompoModal = modalProps =>
  showModal({ modalType: modalTypes.SHADE_COMPO, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showShadeXRmModal = modalProps =>
  showModal({ modalType: modalTypes.SHADE_X_RM_COMPO, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showShadeXInciModal = modalProps =>
  showModal({ modalType: modalTypes.SHADE_X_INCI_COMPO, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showProductProportionReOrderModal = modalProps => showModal({
    modalType: modalTypes.PRODUCT_PROPORTION_REORDER,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
//cosmos-2902
export const showProductProportionSelectRMModal = modalProps =>
  showModal({
    modalType: modalTypes.PRODUCT_PROPORTION_SELECTRM,
    modalProps: { modalWidth: modalWidth.EXTRALARGE, ...modalProps },
  });
export const showProductProportionCreateRMModal = modalProps =>
  showModal({
    modalType: modalTypes.PRODUCT_PROPORTION_CREATERM,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
//end
// cosmos2303
export const showProductReplaceOrDuplicateModalNew = modalProps =>
  showModal({
    modalType: modalTypes.PRODUCT_DUPLICATE_OR_REPLACE,
    modalProps: { modalWidth: modalWidth.NARROW, ...modalProps },
  });
export const showMandateProductsrModal = modalProps =>
  showModal({ modalType: modalTypes.MANDATE_PRODUCTS, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showEditInciListeModal = modalProps =>
  showModal({ modalType: modalTypes.EDIT_INCI_LIST, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showProductCpsrLoadTemplateModal = modalProps =>
  showModal({ modalType: modalTypes.CPSR_LOAD_TEMPLATE, modalProps: { modalWidth: modalWidth.LARGE, ...modalProps } });
export const showDraftLabelModal = modalProps =>
  showModal({
    modalType: modalTypes.DRAFT_LABEL,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showSubstanceCosAnnexeListModal = modalProps =>
  showModal({
    modalType: modalTypes.SUBSTANCE_COS_ANNEXE_LIST,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showThresholdFormModal = modalProps =>
  showModal({
    modalType: modalTypes.IMPACT_ASSESSMENT_THRESHOLD,
    modalProps: { ...modalProps },
  });

export const showLeaveRouteDirtyModal = (pushRoute, onClose) => dispatch =>
  dispatch(
    showModal({
      modalType: 'CONFIRM',
      modalProps: {
        title: I18n.t('modal.leaveRouteDirty.title'),
        message: I18n.t('modal.leaveRouteDirty.message'),
        onConfirm: () => {
          dispatch(push(pushRoute));
          onClose();
        },
        onReject: onClose,
        onHide: onClose,
      },
    }),
  );

export const showProductCategoryConfirmationModal = (onConfirm, onReject) => dispatch =>
  dispatch(
    showModal({
      modalType: 'CONFIRM',
      modalProps: {
        title: I18n.t('product.changeCategoryTitle'),
        message: I18n.t('product.changeCategoryMessage'),
        onConfirm,
        closeModal: () => null, // Will be called when modal is hidden
        onReject,
      },
    }),
  );

export const showProductReplaceOrDuplicateModal = (onConfirm, onReject) => dispatch =>
  dispatch(
    showModal({
      modalType: 'CONFIRM',
      modalProps: {
        title: I18n.t('product.replaceOrDuplicateTitle'),
        message: I18n.t('product.replaceOrDuplicateMessage'),
        onConfirm,
        closeModal: () => null, // Will be called when modal is hidden
        onReject,
      },
    }),
  );

export const showToxProfileItemFormModal = modalProps =>
  showModal({ modalType: modalTypes.TO_PROFILE_ITEM, modalProps });

export const showGenerateAgrementModal = modalProps =>
  showModal({
    modalType: modalTypes.GENERATE_AGREEMENT,
    modalProps: { modalWidth: modalWidth.LARGE, ...modalProps },
  });
export const showCopyRawMatModal = modalProps => showModal({ modalType: modalTypes.COPY_RAW_MAT_FORM, modalProps });
export const showOrderItemFormModal = modalProps => showModal({ modalType: modalTypes.ORDER_ITEM_FORM, modalProps });
export const showProcessFormModal = modalProps => showModal({ modalType: modalTypes.PROCESS_FORM, modalProps });
export const showTaskFormModal = modalProps => showModal({ modalType: modalTypes.TASK_FORM, modalProps });
export const showWorkItemFormModal = modalProps => showModal({ modalType: modalTypes.WORK_ITEM_FORM, modalProps });

export const showOrderItemMultipleProductSelectionFormModal = modalProps => showModal({ modalType: modalTypes.ORDER_ITEM_MULTIPLE_PRODUCT_SELECTION_FORM, modalProps });
export const showProductTRModal = modalProps => showModal({ modalType: modalTypes.PRODUCT_TR_FORM, modalProps });
export const showSubstanceTRModal = modalProps => showModal({ modalType: modalTypes.SUBSTANCE_TR_FORM, modalProps });
export const showCosAnnexeTRModal = modalProps => showModal({ modalType: modalTypes.COSANNEXE_TR_FORM, modalProps });
