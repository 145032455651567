import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, LinkCellRenderer, DeleteButtonRenderer, DateCellRenderer } from '../../_Common/Grids';

const RawMatDocumentList = props => {
  const {
    rawMatDocuments,
    isLoadingRawMatDocumentList,
    getRawMatDocuments,
    saveRawMatDocument,
    deleteRawMatDocument,
  } = props;

  const handleCellValueChange = ({ data }) => saveRawMatDocument(data.Document);
  const onDeleteClick = ({ RawMatDocumentId }) => deleteRawMatDocument(RawMatDocumentId);
  const columns = [
    {
      headerName: I18n.t('document.name'),
      field: 'Document.FileName',
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'Document.FileName',
        linkKey: 'Document.FileLink',
      },
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('document.description'),
      field: 'Document.Description',
      editable: true,
      onCellValueChanged: handleCellValueChange,
    },
    {
      headerName: I18n.t('document.createdBy'),
      field: 'CreatedBy',
    },
    {
      headerName: I18n.t('document.createdOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      sort: 'desc',
    },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      width: 100,
      suppressSizeToFit: true,
      cellRendererParams: {
        onClick: onDeleteClick,
      },
    },
  ];

  return (
    <SelectionGrid
      data={rawMatDocuments}
      keyName="DocumentId"
      columns={columns}
      isLoading={isLoadingRawMatDocumentList}
      onRefresh={getRawMatDocuments}
      showFilterRow
      stopEditingWhenGridLosesFocus
      showAddButton={false}
    />
  );
};

RawMatDocumentList.defaultProps = {
  rawMatDocuments: [],
};

RawMatDocumentList.propTypes = {
  rawMatDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      RawMatDocumentId: PropTypes.number.isRequired,
      Document: PropTypes.shape({
        DocumentId: PropTypes.number.isRequired,
        FileName: PropTypes.string.isRequired,
        FileLink: PropTypes.string.isRequired,
        Description: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  getRawMatDocuments: PropTypes.func.isRequired,
  isLoadingRawMatDocumentList: PropTypes.bool.isRequired,
  saveRawMatDocument: PropTypes.func.isRequired,
  deleteRawMatDocument: PropTypes.func.isRequired,
};

export default RawMatDocumentList;
