import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Row, Col, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField, DateField, SelectField, CheckBoxField } from '../_Common/Forms';
import formNames from '../../constants/formNames';
import DetailHeader from '../_Common/DetailHeader';
import BaseLoader from '../_Common/BaseLoader';
import RawMatStatus from './RawMatStatus';
import CollapsePanelLink from '../_Common/CollapsePanelLink';
import { requiredField, maxLength32, maxLength128 } from '../../utils/fieldValidation';

const RawMatForm = props => {
  const {
    handleSubmit,
    isLoadingDetails,
    rawMatTitle,
    onSaveRawMat,
    RawMatSupplierList,
    showDetails,
    returnToProduct,
    onReturnToProduct,
    onShowDetails,
    invalid,
    rawMatId,
    companyFullName,
    isRawMatEditable,
  } = props;

  return (
    // <LeaveRouteDirtyWrapper isDirty={dirty} {...props}>
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveRawMat)}>
        <DetailHeader title={`${I18n.t('rawMat.panelTitle')} : ${rawMatTitle}`} {...props}>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Grid fluid>
                  <Row>
                    <Col sm={2}>
                      <Field
                        name="Code"
                        label={`${I18n.t('rawMat.code')} * 1`}
                        component={InputField}
                        validate={[requiredField, maxLength32]}
                        readOnly={!isRawMatEditable}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="Name"
                        label={`${I18n.t('rawMat.name')} *`}
                        component={InputField}
                        validate={[requiredField, maxLength128]}
                        readOnly={!isRawMatEditable}
                      />
                    </Col>
                    <Col sm={2}>
                      <Field name="CreatedOn" label={I18n.t('rawMat.createdOn')} component={DateField} disabled />
                    </Col>
                    <Col sm={2}>
                      <FormGroup>
                        <ControlLabel>{I18n.t('rawMat.Status')}</ControlLabel>
                        <RawMatStatus rawMatId={rawMatId} />
                      </FormGroup>
                    </Col>
                    <Col sm={1}>
                      <Field name="IsActive" label={I18n.t('rawMat.isActive')} component={CheckBoxField} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Field
                        name="RawMatSupplierId"
                        label={`${I18n.t('rawMat.supplier')} *`}
                        component={SelectField}
                        options={RawMatSupplierList}
                        labelField="Name"
                        valueField="RawMatSupplierId"
                        validate={requiredField}
                        useVirtualized
                        allowCreate
                        disabled={!isRawMatEditable}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormGroup>
                        <ControlLabel>{I18n.t('rawMat.company')}</ControlLabel>
                        <FormControl type="text" value={companyFullName} bsSize="small" disabled />
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <Field name="ValidatedOn" label={I18n.t('rawMat.validatedOn')} component={DateField} readOnly />
                    </Col>
                    <Col sm={1}>
                      <Field name="ValidatedBy" label={I18n.t('rawMat.validatedBy')} component={InputField} readOnly />
                    </Col>
                    {returnToProduct && rawMatId >= -1 && (
                      <>
                      <Col sm={3}>
                    <ControlLabel>{I18n.t('rawMat.productCompositionMode')} </ControlLabel>
                        <Button bsSize="small"  bsStyle="danger" onClick={onReturnToProduct} disabled={rawMatId === -1}>
                          {I18n.t('rawMat.addToProduct')}
                        </Button>
                      </Col>
                      </>
                    ) }
                  </Row>
                </Grid>
              </Col>
            </Row>
            <CollapsePanelLink
              defaultCollapsed={!showDetails}
              onExpandPanel={onShowDetails}
              disabled={invalid && showDetails}>
              <div>
                <Row>
                  <Col sm={1}>
                    <Field
                      name="HasValidMsds"
                      label={I18n.t('rawMat.HasValidMsds')}
                      component={CheckBoxField}
                      disabled={!isRawMatEditable}
                    />
                  </Col>
                  <Col sm={11}>
                    <Field
                      name="MsdsSource"
                      label={I18n.t('rawMat.MsdsSource')}
                      component={InputField}
                      componentClass="textarea"
                      readOnly={!isRawMatEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={1}>
                    <Field
                      name="HasValidCofa"
                      label={I18n.t('rawMat.HasValidCofa')}
                      component={CheckBoxField}
                      disabled={!isRawMatEditable}
                    />
                  </Col>
                  <Col sm={11}>
                    <Field
                      name="CofaSource"
                      label={I18n.t('rawMat.CofaSource')}
                      component={InputField}
                      componentClass="textarea"
                      readOnly={!isRawMatEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="CpsrSummaryMemo"
                      label={I18n.t('rawMat.CpsrSummaryMemo')}
                      component={InputField}
                      componentClass="textarea"
                      readOnly={!isRawMatEditable}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      name="ImpuritySummaryMemo"
                      label={I18n.t('rawMat.ImpuritySummaryMemo')}
                      component={InputField}
                      componentClass="textarea"
                      // readOnly={!isRawMatEditable}
                    />
                  </Col>
                </Row>
              </div>
            </CollapsePanelLink>
          </Grid>
        </DetailHeader>
      </Form>
    </BaseLoader>
    // </LeaveRouteDirtyWrapper>
  );
};

RawMatForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  rawMatTitle: PropTypes.string.isRequired,
  onSaveRawMat: PropTypes.func.isRequired,
  isRawMatEditable: PropTypes.bool.isRequired,
  RawMatSupplierList: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      RawMatSupplierId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isLoadingDetails: PropTypes.bool.isRequired,
  showDetails: PropTypes.bool.isRequired,
  returnToProduct: PropTypes.number,
  onReturnToProduct: PropTypes.func,
  onShowDetails: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  rawMatId: PropTypes.number.isRequired,
  companyFullName: PropTypes.string.isRequired,
};

const validate = values => {
  const errors = {};

  // Check values for validation errors here
  if (!values.Code) {
    errors.code = "Can't be empty";
  }
  if (!values.CreatedOn) {
    errors.createdOn = "Can't be empty";
  }

  return errors;
};

export default reduxForm({
  form: formNames.rawMat,
  destroyOnUnmount: true,
  asyncBlurFields: [], // this seems to prevent the error
  enableReinitialize: true,
  // initialValues: {
  //   RawMatId: -1,
  // },
  validate,
})(RawMatForm);
