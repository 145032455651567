import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import mandateApi from '../../api/mandateApi';
import * as mandateActions from './mandateActions';
import * as mandateTypes from './mandateTypes';
import * as mandateSchemas from './mandateSchemas';

export default function* root() {
  yield all([
    takeLatest(mandateTypes.FETCH_MANDATES.REQUEST, getMandatesSaga),
    takeLatest(mandateTypes.SAVE_MANDATE.REQUEST, saveMandatesSaga),
  ]);
}

export function* saveMandatesSaga(action) {
  const { type, payload, meta } = action;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(mandateActions.saveMandate, mandateApi.saveMandate, payload);

  yield put(mandateActions.fetchMandates.request(meta.query));
  yield put(applicationActions.unsetLoading(type));
}

export function* getMandatesSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const mandateList = yield call(mandateApi.getMandates, payload);
    const normalizedData = normalize(mandateList, mandateSchemas.mandateListSchema);
    yield put(mandateActions.fetchMandates.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(mandateActions.fetchMandates.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
