import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col, Grid } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import BaseLoader from '../_Common/BaseLoader';
import { InputField, DateField } from '../_Common/Forms';
//import CollapsePanelLink from '../_Common/CollapsePanelLink';
import DetailHeader from '../_Common/DetailHeader';
import LeaveRouteDirtyWrapper from '../_Common/LeaveRouteDirtyWrapper';

const IngredientForm = props => {
  const {
    handleSubmit,
    onSaveingredient,
    ingredientTitle,
    isLoadingDetails,
    dirty,
  } = props;
  return (
    <LeaveRouteDirtyWrapper isDirty={dirty} {...props}>
      <BaseLoader isLoading={isLoadingDetails}>
        <Form onSubmit={handleSubmit(onSaveingredient)}>
          <DetailHeader title={`${I18n.t('ingredient.panelTitle')} : ${ingredientTitle}`} {...props}>
            <Grid fluid>
              <Row>
                <Col sm={2}>
                  <Field name="Code" label={`${I18n.t('general.code')} *`} component={InputField} />
                </Col>
                <Col sm={3}>
                  <Field name="InciName" label={I18n.t('ingredient.inciName')} component={InputField} />
                </Col>
                <Col sm={3}>
                  <Field name="IupacName" label={I18n.t('ingredient.iupacName')} component={InputField} />
                </Col>
                <Col sm={2}>
                  <Field
                    name="CreatedOn"
                    label={I18n.t('general.createdOn')}
                    component={DateField}
                    labelColSize={2}
                    popoverAttachment="top left"
                    popoverTargetAttachment="top left"
                    popoverTargetOffset="10px 10px"
                    readOnly
                  />
                </Col>
                <Col sm={2}>
                  <Field name="CreatedBy" label={I18n.t('general.createdBy')} component={InputField} readOnly />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <Field name="CasNum" label={I18n.t('ingredient.casNum')} component={InputField} />
                </Col>
                <Col sm={2}>
                  <Field name="EcNum" label={I18n.t('ingredient.ecNum')} component={InputField} />
                </Col>
              </Row>
            </Grid>
          </DetailHeader>
        </Form>
      </BaseLoader>
    </LeaveRouteDirtyWrapper>
  );
};

IngredientForm.defaultProps = {
  isLoadingDetails: false,
};

IngredientForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveingredient: PropTypes.func.isRequired,
  ingredientTitle: PropTypes.string.isRequired,
  isLoadingDetails: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'ingredientForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  initialValues: {
    IngredientId: -1,
  },
  //validate,
})(IngredientForm);
