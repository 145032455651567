import React from 'react';
import { connect } from 'react-redux';
import { isDirty, formValueSelector, isInvalid, isSubmitting, submit } from 'redux-form';
import ProcessTypeFormModal from './ProcessTypeFormModal';
import { processTypeTypes, processTypeActions } from '../../../../redux/processType';
import { applicationSelectors } from '../../../../redux/application';
import { referentialSelectors } from '../../../../redux/referentials';
import { processTypeFormActions, processTypeFormSelectors } from '../../../../redux/form/processTypeForm';
import formNames from '../../../../constants/formNames';

const formName = formNames.processTypeForm;
const formValueselector = formValueSelector(formName);

class ProcessTypeFormContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <ProcessTypeFormModal {...this.props} />;
  }
}

const mapStateToProps = (state, { ProcessTypeId }) => ({
  isLoadingContent: applicationSelectors.isLoading(state, processTypeTypes.SAVE_PROCESSTYPE.REQUEST),
  isSubmitting: isSubmitting(formName)(state),
  // processTypeRefList: processTypeSelectors.(state),
  regions: referentialSelectors.getRegions(state),
  currencies: referentialSelectors.getCurrencies(state),
  isDirty: isDirty(formName)(state),
  invalid: isInvalid(formName)(state),
  isInEditing: ProcessTypeId != null,
  initialValues: processTypeFormSelectors.getInitialValues(state)(ProcessTypeId),
  processTypeRefCode: formValueselector(state, 'Code'),
  currencyCode: formValueselector(state, 'UnitPriceCurrency'),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(processTypeActions.listProcessTypeRefs.request()),
  onSaveButtonClick: () => dispatch(submit(formName)),
  onProcessTypeRefSelected: code => dispatch(processTypeFormActions.onProcessTypeRefSelected(code)),
  onAddButtonClicked: () => dispatch(processTypeFormActions.onAddButtonClicked()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessTypeFormContainer);
