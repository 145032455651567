import { createRequestActions } from '../utils';
import * as reportActionTypes from './reportActionTypes';

export const getReport = createRequestActions(reportActionTypes.FETCH_REPORT, (productId, reportType, format, region, country, language, includeInci, compositionInInci) => ({

  productId,
  reportType,
  format,
  region,
  country,
  language,
  includeInci,
  compositionInInci,
}));

export const getFormulaReviewReport = createRequestActions(
  reportActionTypes.FETCH_FORMULAREVIEW_REPORT,
  formulaReviewId => ({
    formulaReviewId,
  }),
);

export const getLabelReviewReport = createRequestActions(reportActionTypes.FETCH_LABELREVIEW_REPORT, labelReviewId => ({
  labelReviewId,
}));
export const getFormulaReviewCompoReport = createRequestActions(
  reportActionTypes.FETCH_FORMULAREVIEW_COMPOREPORT,
  formulaReviewId => ({
    formulaReviewId,
  }),
);
export const getFormulaReviewQuickReport = createRequestActions(
  reportActionTypes.FETCH_FORMULAREVIEW_QUICKREPORT,
  formulaReviewId => ({
    formulaReviewId,
  }),
);
export const getProductReportList = createRequestActions(reportActionTypes.FETCH_PRODUCT_REPORT_LIST, productId => ({
  productId,
}));

export const getAgreementReport = createRequestActions(
  reportActionTypes.FETCH_AGREEMENT_REPORT,
  companyDistributorAgreementId => ({
    companyDistributorAgreementId,
  }),
);

export const getMandateReport = createRequestActions(reportActionTypes.FETCH_MANDATE_REPORT, mandateId => ({
  mandateId,
}));

export const getQuickMandateReport = createRequestActions(reportActionTypes.FETCH_QUICKMANDATE_REPORT, mandateId => ({
  mandateId,
}));

export const getToxProfileReport = createRequestActions(reportActionTypes.FETCH_TOXPROFILE_REPORT, substanceId => ({
  substanceId,
}));
