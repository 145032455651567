import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { createRequestActions } from '../utils';

import * as impactAssessmentTypes from './impactAssessmentTypes';
import workflowTypes from '../../constants/workflowTypes';

export const submitImpactAssessmentForm = createFormAction(impactAssessmentTypes.SUBMIT_IMPACT_ASSESSMENT_FORM);

// export const listImpactAssessmentProducts = createRequestActions(impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST);
export const listImpactAssessments = createRequestActions(impactAssessmentTypes.IMPACT_ASSESSMENT_LIST);
export const listImpactAssessmentsByProduct = createRequestActions(
  impactAssessmentTypes.IMPACT_ASSESSMENT_LIST_BY_PRODUCT,
);
export const impactAssessment = createRequestActions(impactAssessmentTypes.IMPACT_ASSESSMENT);
export const saveImpactAssessment = createRequestActions(impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT);
export const updateImpactAssessment = createRequestActions(impactAssessmentTypes.UPDATE_IMPACT_ASSESSMENT);
export const newImpactAssessment = createAction(impactAssessmentTypes.NEW_IMPACT_ASSESSMENT);
export const loadDetailPage = createAction(impactAssessmentTypes.LOAD_DETAIL_PAGE);
export const updateImpactAssessmentCategories = createAction(impactAssessmentTypes.UPATE_IMPACT_ASSESSMENT_CATEGORIES);
// Actions creators
export const updateStatus = ({ WorkflowStatusCode, ImpactAssessmentId, Comment = null }) => ({
  type: impactAssessmentTypes.UPDATE_IMPACT_ASSESSMENT_STATUS.REQUEST,
  payload: {
    WorkflowStatusCode,
    Comment,
    ImpactAssessmentId,
    WorkflowCode: workflowTypes.IMPACT_ASSESSMENT,
  },
});
export const showImpactAssessmentActionsHistory = createAction(
  impactAssessmentTypes.SHOW_IMPACT_ASSESSMENT_ACTIONS_HISTORY,
  ImpactAssessmentId => ({
    ImpactAssessmentId,
  }),
);
