import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';

const styles = {
  buttonPanel: css({
    textAlign: 'center',
  }),
};

const ConfirmModal = ({ title, message, onConfirm, closeModal, onReject }) => {
  const yesClick = () => {
    onConfirm();
    closeModal();
  };

  const noClick = () => {
    if (onReject !== null && onReject !== undefined) {
      onReject();
    }
    closeModal();
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <Button bsStyle="danger" onClick={noClick}>
            <Translate value="general.no" />
          </Button>
          <Button bsStyle="primary" onClick={yesClick}>
            <Translate value="general.yes" />
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  closeModal: PropTypes.func,
  onReject: PropTypes.func,
};

export default ConfirmModal;
