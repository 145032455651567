import * as taskSelectors from './taskSelectors';
import * as taskActions from './taskActions';
import * as taskTypes from './taskTypes';
import * as taskSchemas from './taskSchemas';

import sagas from './taskSagas';
import reducer from './taskReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { taskSelectors, taskActions, taskTypes, taskSchemas };
export default reducer;
