import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import formNames from '../../constants/formNames';
import processApi from '../../api/processApi';
import * as processTypes from './processTypes';
import { orderItemActions } from '../orderItem';
import { processSchema } from './processSchemas';
import { orderActions } from '../order';
import * as processActions from './processActions';
import { modalTypes, modalActions, modalSelectors } from '../modal';
import { createSaveForm } from '../form/utils';
import { orderSelectors } from '../order';
import { taskActions } from '../task';

export default function* root() {
  yield all([
    takeLatest(processTypes.PROCESS.REQUEST, getProcessSaga),
    takeLatest(processTypes.SAVE_PROCESS.REQUEST, saveProcessFormSaga),
    takeLatest(processTypes.DELETE_PROCESS.REQUEST, deleteProcessSaga),
    takeLatest(processTypes.ADD_PROCESS.REQUEST, addProcessSaga),
    takeLatest(processTypes.ADD_MULTIPLE_PROCESSES.REQUEST, addMultipleProcessesSaga),
  ]);
}

const saveProcessFormSaga = createSaveForm(formNames.process, saveProcessSaga);

function* deleteProcessSaga({ payload, type }) {
  const { processId, orderId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(processActions.deleteProcess, processApi.deleteProcess, processId);
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}

function* getProcessSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProcessId } = payload;
  yield callApiSagaAndNormalize(processActions.process, processApi.getProcess, processSchema, ProcessId);
  yield put(applicationActions.unsetLoading(type));
}

export function* saveProcessSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { orderId } = payload;
  const order = yield select(state => orderSelectors.getCurrentOrder(state, orderId));
  payload.ProducerId = order.ProducerId;
  yield callApiSaga(processActions.saveProcess, processApi.saveProcess, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.PROCESS_FORM) {
    yield put(modalActions.hideModal());
  }
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(orderActions.listOrderProducts.request(orderId));
  yield put(applicationActions.unsetLoading(type));
}

export function* addProcessSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { orderItemId, orderId } = payload;
  yield callApiSaga(processActions.addProcess, processApi.addProcess, orderItemId);
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}

// Pas utilisé
export function* addMultipleProcessesSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { orderItemId, orderId } = payload;
  yield callApiSaga(processActions.addMultipleProcesses, processApi.addMultipleProcesses, payload);
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}
