import { normalize } from 'normalizr';

import { takeLatest, all, put, call } from 'redux-saga/effects';
import { phraseCpsrChapterSchema, phraseCpsrChapterListSchema } from './phraseCpsrChapterSchemas';
import { createFetchSaga } from '../sagaUtils';
import * as phraseCpsrChapterTypes from './phraseCpsrChapterTypes';
import * as phraseCpsrChapterActions from './phraseCpsrChapterActions';
import phraseCpsrChapterApi from '../../api/phraseCpsrChapterApi';

export default function* root() {
  yield all([takeLatest(phraseCpsrChapterTypes.FETCH_PHRASE_CPSR_CHAPTERS.REQUEST, fetchPhraseCpsrChaptersSaga)]);
}

const fetchPhraseCpsrChaptersSaga = createFetchSaga(
  function* fetchPhraseCpsrChaptersSaga({ payload, type }) {
    const phraseCpsrChapters = yield call(phraseCpsrChapterApi.fetchPhraseCpsrChapters, payload);
    const schema = Array.isArray(phraseCpsrChapters) ? phraseCpsrChapterListSchema : phraseCpsrChapterSchema;
    const normalizedData = normalize(phraseCpsrChapters, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(phraseCpsrChapterActions.fetchPhraseCpsrChapters.success(data, payload));
    },
    onFailure: (error, payload) => put(phraseCpsrChapterActions.fetchPhraseCpsrChapters.failure(error, payload)),
  },
);
