import { createSelector } from 'reselect';
import { categorySelectors } from '../../category';

const defaultInitialValues = {
  CpsrTypeCode: 'CPSRA',
};

export const getInitialValues = createSelector([categorySelectors.getById], categoryById =>
  createSelector(
    categoryId => categoryById[categoryId],
    category => category || defaultInitialValues,
  ),
);
