import * as complianceReviewWizardSelectors from './complianceReviewWizardSelectors';
import * as complianceReviewWizardActions from './complianceReviewWizardActions';
import * as complianceReviewWizardTypes from './complianceReviewWizardTypes';
import sagas from './complianceReviewWizardSagas';
import reducer from './complianceReviewWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { complianceReviewWizardSelectors, complianceReviewWizardActions, complianceReviewWizardTypes };
export default reducer;
