import React from 'react';
import { connect } from 'react-redux';
import { substanceFormSelectors } from '../../../redux/form/substanceForm';
import DetailHeader from '../../_Common/DetailHeader';
import * as actions from './actions';
import * as selectors from './selectors';

const SubstanceDetailHeaderContainer = props => <DetailHeader {...props} />;

const mapStateToProps = state => ({
  dirty: selectors.dirty(state),
  invalid: selectors.invalid(state),
  pristine: selectors.pristine(state),
  submitting: selectors.submitting(state),
  title: substanceFormSelectors.getSubstanceFormTitle(state),
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(actions.canceleButtonClick()),
  onSave: () => dispatch(actions.saveButtonClick()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceDetailHeaderContainer);
