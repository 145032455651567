import { createRequestActions } from '../utils';
import * as orderItemTypes from './orderItemTypes';

export const listOrderItems = createRequestActions(
  orderItemTypes.ORDER_ITEM_LIST,
  payload => payload,
  (data, meta) => meta,
);
export const saveOrderItem = createRequestActions(orderItemTypes.SAVE_ORDER_ITEM);
export const deleteOrderItem = createRequestActions(orderItemTypes.DELETE_ORDER_ITEM, data => data);
export const orderPlannerList = createRequestActions(orderItemTypes.ORDER_PLANNER_LIST);
