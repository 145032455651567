import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { CategoryListContainer } from './CategoryList';

const Categories = props =>
  (<Grid fluid>
    <Row>
      <Col md={12}>
        <CategoryListContainer {...props} />
      </Col>
    </Row>
  </Grid>);

export default Categories;
