import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { compose } from 'recompose';
import { isDirty } from 'redux-form';
import { push } from 'react-router-redux';
import formNames from '../../constants/formNames';
import ProductForm from './ProductForm';
import { productActions, productSelectors, productTypes } from '../../redux/product';
import { productProportionActions } from '../../redux/productProportion';
import { phraseActions } from '../../redux/phrase';
import { rawMatSupplierActions } from '../../redux/rawMatSupplier';
import { companyActions, companySelectors } from '../../redux/company';
import { categoryActions } from '../../redux/category';
import {applicationActions, applicationSelectors} from '../../redux/application';
import { productActionActions } from '../../redux/productAction';
import { rawMatActions } from '../../redux/rawMat';
import { workflowStatusSelectors, workflowStatusTypes } from '../../redux/workflowStatus';
import { formulaReviewSelectors } from '../../redux/formulaReview';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { modalActions } from '../../redux/modal';
import { referentialSelectors } from '../../redux/referentials';
import { productFormSelectors } from '../../redux/form/productForm';

class ProductDetailsContainer extends PureComponent {
  componentDidMount() {
    const productIdToFetch = this.getProductIdToFetch(this.props);
    this.props.onLoadProduct(productIdToFetch);    
  }

  componentDidUpdate(previousProps) {
    if (this.getProductIdToFetch(previousProps) !== this.getProductIdToFetch(this.props)) {
      const productIdToFetch = this.getProductIdToFetch(this.props);
      this.props.onLoadProduct(productIdToFetch);
    }
  }

  getProductIdToFetch = props => (props.productId > 0 ? props.productId : props.duplicateId);

  render() {
    return (
      <div>
        <ProductForm {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = () => {
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('PRODUCT');
  return (state, { params: { id: productId }, location }) => {
    const getInitialValues = productFormSelectors.makeGetInitialValues(state);
    const { UOMs } = state.referentials;
    const showDetails = location.query.show_details === 'true';
    const productStatus = productSelectors.getCurrentProductStatus(state);
    const parentProductId = R.propOr(null, 'ParentProductId', productSelectors.getCurrentProduct(state));
    const isNew = productSelectors.isNew(state);
    return {
      currentProductOrderList: productSelectors.getCurrentProductOrders(state),
      showGoToParrentButton: !!parentProductId,
      parentProductId,
      initialValues: getInitialValues(productId),
      currentProductImpactAssessmentList: productSelectors.getCurrentProductImpactAssessments(state),
      companyList: companySelectors.getCompanyList(state),
      UOMs,
      isLoadingDetails: applicationSelectors.isLoading(
        state,
        productTypes.SAVE_PRODUCT.REQUEST,
        productTypes.PRODUCT.REQUEST,
        workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST,
        productTypes.UPDATE_PRODUCT_STATUS.REQUEST,
      ),
      isProductEditable: productSelectors.makeIsProductEditable(productId)(state) || isNew,
      isAnyFormulaReviewForProduct: formulaReviewSelectors.isAnyFormulaReviewForProduct(state, productId),
      productTitle: productSelectors.productTitle(state),
      nbProdigumProductProportionChanged: productSelectors.getNbProdigumProductProportionChanged(state),
      isDirty: isDirty('productForm')(state),
      workflowStatusList: getWorkflowStatusList(state),
      productStatus,
      isNew,
      showDetails,
      inDuplicateMode: productFormSelectors.getInDuplicateMode(state),
      duplicateMode: productFormSelectors.getDuplicateMode(state),
      cpsrBTypeList: referentialSelectors.getCpsrBTypeList(state),
      mosCalculationTypeList: referentialSelectors.getMosCalculationTypes(state),
    };
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  const showDetails = ownProps.location.query.show_details === 'true';
  const ProductId = ownProps.productId;
  return {
    onLoadProduct: id => {
      dispatch(categoryActions.listCategories.request());
      dispatch(companyActions.listCompanies.request());
      if (id > 0) {
        dispatch(productActions.product.request(id));
        dispatch(phraseActions.listPhrases.request({ IncludeChaptersCode: true }));
        dispatch(productActionActions.listProductActions.request(id));
        //dispatch(rawMatActions.listRawMats.request());
        //dispatch(productProportionActions.listProductProportionsForProduct.request(id));
        dispatch(rawMatSupplierActions.listRawMatSuppliers.request());
      }
    },
    onSaveProduct: productActions.submitProductForm,
    onShowDetails: () =>
      dispatch(push({ ...ownProps.location, query: { ...ownProps.location.query, show_details: !showDetails } })),
    onChangeStatus: ({ Code }) => dispatch(productActions.updateStatus({ WorkflowStatusCode: Code, ProductId })),
    showCategoryChangeConformationPopup: (onConfirm, onClose) =>
      dispatch(modalActions.showProductCategoryConfirmationModal(onConfirm, onClose)),
    onDuplicate: () =>
      dispatch(
        modalActions.showProductReplaceOrDuplicateModalNew(
          { ProductId },
        ),
      ),
    showStatusHistory: () => dispatch(productActionActions.showProductActionsHistory(ProductId)),
    onTranslateClick: () => dispatch(modalActions.showProductTRModal({ ProductId })),
    onTranslateCategoryClick: (ProductCategoryId) => dispatch(modalActions.showProductCategoryTRModal({ ProductId, ProductCategoryId })),
  };
}

ProductDetailsContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoadProduct: PropTypes.func.isRequired,
  duplicateId: PropTypes.number,
};

ProductDetailsContainer.defaultProps = {
  duplicateId: null,
};

const withData = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withProductFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.product);

export default compose(
  withData,
  withProductFormLeaveRouteDirtyWrapper,
)(ProductDetailsContainer);
