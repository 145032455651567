import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Col } from 'react-bootstrap';

const LabelReviewItemHeader = () =>
  (<div>
    <Col smOffset={3} sm={1}>
      <h4>
        {I18n.t('labelReviewWizard.innerLabel')}
      </h4>
    </Col>
    <Col sm={1}>
      <h4>
        {I18n.t('labelReviewWizard.outerLabel')}
      </h4>
    </Col>
    <Col sm={1}>
      <h4>
        {I18n.t('labelReviewWizard.leaflet')}
      </h4>
    </Col>
  </div>);

export default LabelReviewItemHeader;
