import { normalize } from 'normalizr';
import { takeLatest, put, all, select, call } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import rawMatDocumentApi from '../../api/rawMatDocumentApi';
import documentApi from '../../api/documentApi';
import * as rawMatDocumentTypes from './rawMatDocumentTypes';
import * as rawMatDocumentActions from './rawMatDocumentActions';
import * as rawMatDocumentSchemas from './rawMatDocumentSchemas';
import * as rawMatDocumentSelectors from './rawMatDocumentSelectors';

export default function* root() {
  yield all([
    takeLatest(rawMatDocumentTypes.SAVE_RAWMAT_DOCUMENT.REQUEST, saveRawMatDocumentSagas),
    takeLatest(rawMatDocumentTypes.UPLOAD_RAWMAT_DOCUMENT.REQUEST, uploadRawMatDocumentSagas),
    takeLatest(rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.REQUEST, getRawMatDocumentListForRawMatSaga),
    takeLatest(rawMatDocumentTypes.DELETE_RAWMAT_DOCUMENT.REQUEST, deleteRawMatDocumentSaga),
  ]);
}

function* deleteRawMatDocumentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { RawMatDocumentId } = payload;
  yield callApiSaga(
    rawMatDocumentActions.deleteRawMatDocument,
    rawMatDocumentApi.deleteRawMatDocument,
    RawMatDocumentId,
  );

  const rawMatDocument = yield select(state => rawMatDocumentSelectors.getRawMatDocument(state, RawMatDocumentId));
  if (rawMatDocument && rawMatDocument.RawMatId) {
    const { RawMatId } = rawMatDocument;
    yield put(rawMatDocumentActions.listRawMatDocumentsForRawMat.request(RawMatId));
  }

  yield put(applicationActions.unsetLoading(type));
}

function* saveRawMatDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { RawMatId, ...data } = payload;
  yield callApiSaga(rawMatDocumentActions.saveRawMatDocument, documentApi.saveDocument, data);
  yield put(rawMatDocumentActions.listRawMatDocumentsForRawMat.request(RawMatId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadRawMatDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { RawMatId } = payload;
  yield callApiSaga(rawMatDocumentActions.saveRawMatDocument, rawMatDocumentApi.uploadRawMatDocument, payload);
  yield put(rawMatDocumentActions.listRawMatDocumentsForRawMat.request(RawMatId));
  yield put(applicationActions.unsetLoading(type));
}

function* getRawMatDocumentListForRawMatSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { RawMatId } = payload;
    const rawMatDocuments = yield call(rawMatDocumentApi.getRawMatDocumentList, { RawMatId, OptIncludeDocument: true });
    const normalizedData = normalize(rawMatDocuments, rawMatDocumentSchemas.rawMatDocumentListWithDocumentSchema);
    yield put(rawMatDocumentActions.listRawMatDocumentsForRawMat.success(normalizedData, RawMatId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatDocumentActions.listRawMatDocumentsForRawMat.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
