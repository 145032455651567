import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLocale } from 'react-redux-i18n';
import { userActions } from '../../redux/user';
import { applicationActions } from '../../redux/application';
import { referentialActions } from '../../redux/referentials';
import { workflowStatusActions } from '../../redux/workflowStatus';
import { actionActions } from '../../redux/action';
import App from './App';

class AppShellContainer extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const { children, ...other } = this.props;
    return <App {...other}>{children}</App>;
  }
}

AppShellContainer.propTypes = {
  children: PropTypes.element.isRequired,
  onMount: PropTypes.func.isRequired,
};

function mapStateToProps({ application, user, i18n }) {
  return {
    generalError: application.generalError,
    userDetails: user.currentUser,
    locale: i18n.locale,
    showNotification: application.showNotification,
    notificationMessage: application.notificationMessage,
    notificationLevel: application.notificationLevel,
    applicationVersion: application.applicationVersion,
  };
}

const mapDispatchToProps = dispatch => {
  const { logout } = userActions;
  const { clearNotification } = applicationActions;

  const boundActionCreators = bindActionCreators(
    {
      onLogout: logout,
      onNotificationEmited: clearNotification,
      setLocale,
    },
    dispatch,
  );

  return {
    ...boundActionCreators,
    onGeneralErrorEmited: () => dispatch(applicationActions.clearGeneralError()),
    onMount: () => {
      dispatch(referentialActions.allReferentials.request());
      dispatch(workflowStatusActions.listWorkflowStatus.request());
      dispatch(actionActions.listActions.request());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppShellContainer);
