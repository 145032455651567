import * as companyDocumentSelectors from './companyDocumentSelectors';
import * as companyDocumentActions from './companyDocumentActions';
import * as companyDocumentTypes from './companyDocumentTypes';
import sagas from './companyDocumentSagas';
import reducer from './companyDocumentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { companyDocumentSelectors, companyDocumentActions, companyDocumentTypes };
export default reducer;
