import * as statsSelectors from './statsSelectors';
import * as statsActions from './statsActions';
import * as statsTypes from './statsTypes';
import sagas from './statsSagas';
// import reducer from './statsReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { statsSelectors, statsActions, statsTypes };
// export default reducer;
