import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { referentialsTypes } from '../../../redux/referentials';
import { modalActions, modalTypes } from '../../../redux/modal';
import { labelReviewActions, labelReviewSelectors } from '../../../redux/labelReview';
import {
  labelReviewWizardActions,
  labelReviewWizardSelectors,
  labelReviewWizardTypes,
} from '../../../redux/wizard/labelReviewWizard';
import LabelReviewTab from './LabelReviewTab';
import { reportActions, reportActionTypes } from '../../../redux/report';

class LabelReviewContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <LabelReviewTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId: ProductId }) => ({
  labelReviewList: labelReviewSelectors.makeGetByQuery(state)({ ProductId }),
  isLoadingLabelReviewTab: applicationSelectors.getIsLoading(
    labelReviewSelectors.makeGetByQueryLoading({ ProductId }),
    referentialsTypes.ALL_REFERENTIALS.REQUEST,
  )(state),
  isLoadingNewWizard: applicationSelectors.getIsLoading(
    labelReviewWizardSelectors.getIsWizardLoading(labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST, {
      ProductId,
      include: { OptIncludeDetails: true },
    }),
  )(state),
  getIsLoadingWizardForLabelReview: LabelReviewId =>
    applicationSelectors.getIsLoading(
      labelReviewWizardSelectors.getIsWizardLoading(labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST, {
        LabelReviewId,
        ProductId,
      }),
    )(state),
  isLoadingLabelReviewList: applicationSelectors.isLoading(state, reportActionTypes.FETCH_LABELREVIEW_REPORT.REQUEST),
});

const mapDispatchToProps = (dispatch, { productId: ProductId }) => ({
  onLoad: () => {
    if (ProductId > 0) {
      dispatch(labelReviewActions.fetchLabelReviews.request({ ProductId }));
    }
  },
  getLabelReviewListForProduct: () => dispatch(labelReviewActions.fetchLabelReviews.request({ ProductId })),
  onNewLabelReviewClick: () =>
    dispatch(
      labelReviewWizardActions.newLabelReviewWizard.request({
        ProductId,
        include: { OptIncludeDetails: true },
      }),
    ),
  onEditLabelReview: ({ LabelReviewId }) =>
    dispatch(
      labelReviewWizardActions.editLabelReviewWizard.request({
        ProductId,
        LabelReviewId,
        include: { OptIncludeDetails: true },
      }),
    ),
  onApproveLabelReviewClick: ({ LabelReviewId }) =>
    dispatch(
      modalActions.showModal({
        modalType: modalTypes.LABEL_REVIEW_APPROVAL,
        modalProps: { LabelReviewId, ProductId },
      }),
    ),
  onValidateLabelReviewClick: ({ LabelReviewId }) =>
    dispatch(
      modalActions.showModal({
        modalType: modalTypes.LABEL_REVIEW_VALIDATION,
        modalProps: { LabelReviewId, ProductId },
      }),
    ),
  onPrintLabelReviewClick: ({ LabelReviewId }) => dispatch(reportActions.getLabelReviewReport.request(LabelReviewId)),
});

LabelReviewContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LabelReviewContainer);
