import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../../_Common/Forms';
import { requiredField } from '../../../../utils/fieldValidation';

const ProcessRefBasicFormSection = props => {
  const { readOnly } = props;

  return (
    <div>
      <Col sm={6}>
        <Field
          name="Code"
          label={`${I18n.t('processType.code')} *`}
          component={InputField}
          validate={requiredField}
          readOnly={readOnly}
        />
      </Col>
      <Col sm={6}>
        <Field
          name="Value"
          label={`${I18n.t('processType.label')} *`}
          component={InputField}
          validate={requiredField}
          readOnly={readOnly}
        />
      </Col>
    </div>
  );
};

ProcessRefBasicFormSection.defaultProps = {
  readOnly: false,
};

ProcessRefBasicFormSection.propTypes = {
  readOnly: PropTypes.bool,
};

export default ProcessRefBasicFormSection;
