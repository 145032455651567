import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { processTypeActions, processTypeTypes, processTypeSelectors } from '../../../redux/processType';
import { applicationSelectors } from '../../../redux/application';
import ProcessTypeList from './ProcessTypeList';
import { referentialSelectors } from '../../../redux/referentials';

class ProcessTypePageContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col md={12}>
            <ProcessTypeList {...this.props} />;
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  processTypeList: processTypeSelectors.getProcessTypeList(state),
  isLoading: applicationSelectors.isLoading(
    state,
    processTypeTypes.PROCESSTYPE_LIST.REQUEST,
    processTypeTypes.SAVE_PROCESSTYPE.REQUEST,
  ),
  currencies: referentialSelectors.getCurrencies(state),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(processTypeActions.listProcessTypes.request({ IncludeDuration: true }));
    dispatch(processTypeActions.listProcessTypeRefs.request());
  },
  getProcessTypeList: () => dispatch(processTypeActions.listProcessTypes.request({ IncludeDuration: true })),
  onAddButtonClick: () => dispatch(processTypeActions.addProcessType()),
  onEditButtonClick: processTypeId => {
    dispatch(processTypeActions.editProcessType(processTypeId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessTypePageContainer);

ProcessTypePageContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
