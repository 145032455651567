import { createSelector } from 'reselect';
import * as R from 'ramda';
import { rawMatSelectors } from '../../rawMat';
import { productProportionSelectors } from '../../productProportion';
import { isNullOrUndef } from '../../../utils/utils';

export const getRawMatList = rawMatSelectors.makeGetAvailableRawMatListForProduct();

export const makeGetInitialValuesForProduct = createSelector(R.identity, ProductId =>
  createSelector(
    [productProportionSelectors.getById, productProportionSelectors.getproductProportionList, rawMatSelectors.getById],
    (productProportionById, productProportionList, rawMatById) =>
      createSelector(
        productProportionId => productProportionById[productProportionId],
        productProportion => {
          const isInEditMode = !isNullOrUndef(productProportion);
          if (!isInEditMode) {
            const Order = R.compose(
              R.add(1),
              R.defaultTo(0),
              Number.parseInt,
              R.apply(Math.max),
              R.pluck('Order'),
              R.filter(R.whereEq({ ProductId })),
            )(productProportionList);

            return { Order };
          }
          return {
            ...productProportion,
            RawMat: rawMatById[productProportion.RawMatId],
          };
        },
      ),
  ),
);
