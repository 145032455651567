import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const ProductList = props => {
  const { productList, onProductSelected, onAddProduct, onRefreshList, requestingProductList, selectedId } = props;
  const columns = [
    {
      headerName: I18n.t('general.code'),
      width: 75,
      suppressSizeToFit: true,
      field: 'Code',
    },
    {
      headerName: I18n.t('general.name'),
      field: 'ProductName',
      // suppressSizeToFit: true,
      sort: 'asc',
      width: 350,
      minWidth: 200,
    },
  ];
  return (
    <SelectionGrid
      data={productList}
      onSelected={onProductSelected}
      onRefresh={onRefreshList}
      onAdd={onAddProduct}
      showLoadingIcon={requestingProductList}
      selectedId={selectedId}
      columns={columns}
      keyName="ProductId"
      suppressRowClickSelection
      showAddButton
    />
  );
};

ProductList.defaultProps = {
  selectedId: '',
};

ProductList.propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      ProductId: PropTypes.number.isRequired,
      ProductName: PropTypes.string.isRequired,
      // Code: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onProductSelected: PropTypes.func.isRequired,
  onAddProduct: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  requestingProductList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default ProductList;
