import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import formNames from '../../constants/formNames';
import StepComponent from '../_Common/StepComponent';
import { substanceSelectors } from '../../redux/substance';
import { workflowStatusSelectors } from '../../redux/workflowStatus';
import { modalActions } from '../../redux/modal';

const SubstanceStatus = props => {
  const { workflowStatusList, statusCode, disabled, onChange, onHistoryClick } = props;
  return (
    <StepComponent
      options={workflowStatusList}
      value={statusCode}
      onChange={onChange}
      disabled={disabled}
      onHistoryClick={onHistoryClick}
    />
  );
};

const mapStateToProps = (state, { substanceId }) => {
  const substance = substanceSelectors.getById(state)[substanceId];
  const getWorkflowStatusList = workflowStatusSelectors.makeGetWorkflowStatusListByCode('SUBSTANCE');
  return {
    statusCode: R.propOr('', 'Status', substance),
    workflowStatusList: getWorkflowStatusList(state),
    disabled: substanceSelectors.isNew(state) || isDirty(formNames.substance)(state),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onChange: ({ Code }) => dispatch(modalActions.showSubstanceStatusCommentModal({ nextStatusCode: Code, substanceId })),
  onHistoryClick: () => dispatch(modalActions.showSubstanceActionsModal({ substanceId })),
});

SubstanceStatus.propTypes = {
  workflowStatusList: PropTypes.array.isRequired, //eslint-disable-line
  statusCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceStatus);
