import { takeLatest, takeEvery, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import phraseTRApi from '../../api/phraseTRApi';
import * as phraseTRTypes from './phraseTRTypes';
import * as phraseTRActions from './phraseTRActions';
import * as phraseTRSchemas from './phraseTRSchemas';

export default function* root() {
  yield all([
    takeEvery(phraseTRTypes.PHRASE_TRANSLATION_LIST.REQUEST, getPhraseTranslationListSaga),
    takeLatest(phraseTRTypes.SAVE_TRANSLATION.REQUEST, saveTranslationSaga),
  ]);
}

function* saveTranslationSaga({ payload, type }) {
  const { data } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(phraseTRActions.saveTranslation, phraseTRApi.savePhraseTranslation, data);
  yield put(phraseTRActions.listPhraseTranslation.request());
  yield put(applicationActions.unsetLoading(type));
}

function* getPhraseTranslationListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    phraseTRActions.listPhraseTranslation,
    phraseTRApi.getPhraseTranslationList,
    phraseTRSchemas.phraseTRListSchema,
    {
      ...payload,
    },
  );
  yield put(applicationActions.unsetLoading(type));
}
