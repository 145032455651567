import PropTypes from 'prop-types';
import React, { Component } from 'react';
import daggy from 'daggy';
import Loadable from 'react-loadable';
import * as R from 'ramda';
import { Form, Grid, Row, Col, Panel, Radio, FormGroup, OverlayTrigger } from 'react-bootstrap';
import { Field, reduxForm, FormSection } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import { InputField, SelectField, CheckBoxField } from '../../_Common/Forms';
import { RangeFormatsTooltip } from '../../_Common/Tooltips';
import { RawMatListDropdown, SubstanceListDropdown } from '../../_Common/Dropdowns';
import BaseLoader from '../../_Common/BaseLoader';
import { isPositiveOrZeroDecimal, requiredField, isValidRange, maxLength64 } from '../../../utils/fieldValidation';

const LoadableSubstanceList = Loadable({
  loader: () => import('./SubstanceList'),
  loading: () => null,
});

const LoadableSubstanceBasicFormSection = Loadable({
  loader: () => import('../../Substances/SubstanceBasicFormSection'),
  loading: () => null,
});

const SubstanceFilter = daggy.taggedSum('SubstanceFilter', {
  All: [],
  Impurities: [],
  Allergens: [],
});

class RawMatProportionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isSubstanceSelected: false,
      substanceFilter: SubstanceFilter.All,
    };
  }

  getFilteredSubstanceList = () =>
    this.state.substanceFilter.cata({
      All: () => this.props.substanceList,
      Allergens: () => this.props.substanceList.filter(s => s.IsPerfumeAllergen),
      Impurities: () => this.props.substanceList.filter(s => s.IsImpurity),
    });

  getSubstanceListFilter = (...args) => {
    const predicate = this.state.substanceFilter.cata({
      All: () => R.T,
      Allergens: () => R.whereEq({ IsPerfumeAllergen: true }),
      Impurities: () => R.whereEq({ IsImpurity: true }),
    });
    return R.compose(
      R.sortWith([R.ascend(R.propOr('', 'InciName'))]),
      R.filter(predicate),
    )(this.props.substanceListFilter(...args));
  };

  handleSubstanceChange = substanceId => {
    const { fetchSubstance } = this.props;

    if (substanceId !== null) {
      fetchSubstance(substanceId);
    }
  };

  isSubstanceSelected = () => this.props.selectedSubstanceId != null;
  isRawMatSelected = () => this.props.selectedRawMatId != null;

  handleRawMatChange = rawMatId => {
    if (rawMatId !== null) {
      this.props.onRawMatSelected(rawMatId);
    }
  };

  handleSubstanceFilterChange = substanceFilter => {
    this.setState({ substanceFilter });
  };

  render() {
    const {
      handleSubmit,
      substanceList,
      substanceListFilter,
      functionList,
      isLoadingFunctions,
      currentRawMatProportion,
      showSubstanceFilter,
      rawMatProportionId,
      rawMatList,
      isInEditMode,
      selectedSubstanceId,
      selectedRawMatId,
      onRawMatSelected,
      isLoadingSubstanceDetails,
      sortByInciName,
      ...otherProps
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col sm={12}>
                  <Field
                    name="SubstanceId"
                    label={I18n.t('general.substance')}
                    component={SubstanceListDropdown}
                    onFocus={() => LoadableSubstanceBasicFormSection.preload()}
                    initialOptions={this.getFilteredSubstanceList()}
                    onChange={(e, newValue) => this.handleSubstanceChange(newValue)}
                    disabled={isInEditMode || this.isRawMatSelected()}
                    useVirtualized
                  />
                </Col>
              </Row>
              <Row>
                {showSubstanceFilter && (
                  <FormGroup>
                    <Radio
                      inline
                      name="substanceFilter"
                      checked={SubstanceFilter.All.is(this.state.substanceFilter)}
                      onChange={() => this.handleSubstanceFilterChange(SubstanceFilter.All)}
                    >
                      <Translate value="rawMatProportion.filter.all" />
                    </Radio>
                    <Radio
                      inline
                      name="substanceFilter"
                      checked={this.state.substanceFilter === SubstanceFilter.Allergens}
                      onChange={() => this.handleSubstanceFilterChange(SubstanceFilter.Allergens)}
                    >
                      <Translate value="rawMatProportion.filter.allergens" />
                    </Radio>
                    <Radio
                      inline
                      name="substanceFilter"
                      checked={this.state.substanceFilter === SubstanceFilter.Impurities}
                      onChange={() => this.handleSubstanceFilterChange(SubstanceFilter.Impurities)}
                    >
                      <Translate value="rawMatProportion.filter.impurities" />
                    </Radio>
                  </FormGroup>
                )}
              </Row>
              {!isInEditMode && (
                <Col sm={12}>
                  <Field
                    name="ReferencedRawMatId"
                    label={I18n.t('rawMat.rawMatSubstances')}
                    component={RawMatListDropdown}
                    initialOptions={rawMatList}
                    onFocus={() => LoadableSubstanceList.preload()}
                    onChange={(e, newValue) => this.handleRawMatChange(newValue)}
                    disabled={this.isSubstanceSelected()}
                  />
                </Col>
              )}
              <Row />
              <Row>
                {this.isSubstanceSelected() && (
                  <div>
                    <Panel header={I18n.t('rawMatProportion.substanceDetailsHeader')} bsStyle="primary">
                      <BaseLoader isLoading={isLoadingSubstanceDetails}>
                        <FormSection name="Substance">
                          <LoadableSubstanceBasicFormSection
                            {...otherProps}
                            readOnly={this.isSubstanceSelected() || isInEditMode}
                          />
                        </FormSection>
                      </BaseLoader>
                    </Panel>

                    <Panel header={I18n.t('rawMatProportion.rawMatProportionHeader')} bsStyle="primary">
                      <Row>
                        <Col sm={3}>
                          <Field
                            name="Percentage"
                            label={I18n.t('rawMatProportion.substanceQty')}
                            component={InputField}
                            inputGroupLabel="%"
                            validate={[requiredField, isPositiveOrZeroDecimal]}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="PercentageForInciCalculation"
                            label={I18n.t('rawMatProportion.inciQuantity')}
                            component={InputField}
                            inputGroupLabel="%"
                            validate={isPositiveOrZeroDecimal}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="PercentageRange"
                            placeholder={I18n.t('rawMatProportion.percentageRange')}
                            label={
                              <span>
                                {I18n.t('rawMatProportion.percentageRange')}
                                &nbsp;
                                <OverlayTrigger placement="top" overlay={RangeFormatsTooltip()}>
                                  <span>
                                    <i className="fa fa-question-circle" aria-hidden="true" />
                                  </span>
                                </OverlayTrigger>
                              </span>
                            }
                            component={InputField}
                            validate={isValidRange}
                          />
                        </Col>
                        <Col sm={3}>
                          <Field
                            name="FunctionId"
                            label={I18n.t('rawMatProportion.function')}
                            component={SelectField}
                            options={functionList}
                            labelField="Name"
                            valueField="FunctionId"
                            isLoading={isLoadingFunctions}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Field
                            name="PercentageRangeComment"
                            label={I18n.t('rawMatProportion.percentageRangeComment')}
                            component={InputField}
                            validate={maxLength64}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={2}>
                          <Field
                            name="IsAllergen"
                            label={I18n.t('rawMatProportion.isAllergen')}
                            component={CheckBoxField}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="IsIngredient"
                            label={I18n.t('rawMatProportion.isIngredient')}
                            component={CheckBoxField}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="IsImpurity"
                            label={I18n.t('rawMatProportion.isImpurity')}
                            component={CheckBoxField}
                          />
                        </Col>
                        <Col sm={2}>
                          <Field name="IsNano" label={I18n.t('rawMatProportion.isNano')} component={CheckBoxField} />
                        </Col>
                        <Col sm={2}>
                          <Field
                            name="IsNonNanoVerified"
                            label={I18n.t('rawMatProportion.isNonNano')}
                            component={CheckBoxField}
                          />
                        </Col>
                      </Row>
                    </Panel>
                  </div>
                )}
                {this.isRawMatSelected() && (
                  <div>
                    <div style={{ marginBottom: '15px' }}>
                      <LoadableSubstanceList rawMatId={selectedRawMatId} height="400px" />
                    </div>
                    <Col sm={3}>
                      <Field
                        name="Percentage"
                        label={I18n.t('rawMatProportion.percentage')}
                        component={InputField}
                        inputGroupLabel="%"
                        validate={[requiredField, isPositiveOrZeroDecimal]}
                      />
                    </Col>
                  </div>
                )}
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

RawMatProportionForm.defaultProps = {
  RawMatProportionForm: [],
  rawMatProportionId: undefined,
  selectedSubstanceId: null,
  selectedRawMatId: null,
};

RawMatProportionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sortByInciName: PropTypes.func.isRequired,
  substanceList: PropTypes.arrayOf(
    PropTypes.shape({
      SubstanceId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      InciName: PropTypes.string.isRequired,
      InciNameUs: PropTypes.string,
      CasNr: PropTypes.string,
      EcNr: PropTypes.string,
      Description: PropTypes.string,
      IupacName: PropTypes.string,
      InnName: PropTypes.string,
    }),
  ).isRequired,
  onRawMatSelected: PropTypes.func.isRequired,
  isLoadingSubstanceDetails: PropTypes.bool.isRequired,
  selectedSubstanceId: PropTypes.number,
  selectedRawMatId: PropTypes.number,
  isInEditMode: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  substanceListFilter: PropTypes.func.isRequired,
  getSubstanceFunctions: PropTypes.func.isRequired,
  functionList: PropTypes.arrayOf(
    PropTypes.shape({
      FunctionId: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rawMatList: PropTypes.array.isRequired, //eslint-disable-line
  rawMatProportionId: PropTypes.number,
  isLoadingFunctions: PropTypes.bool.isRequired,
  fetchSubstance: PropTypes.func.isRequired,
  showSubstanceFilter: PropTypes.bool.isRequired,
  currentRawMatProportion: PropTypes.object, //eslint-disable-line
};

export default reduxForm({
  form: 'rawMatProportionForm',
  destroyOnUnmount: true,
  asyncBlurFields: [], // this seems to prevent the error
  enableReinitialize: true,
  initialValues: {
    RawMatProportionId: -1,
  },
})(RawMatProportionForm);
