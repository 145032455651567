import * as productTRSelectors from './productTRSelectors';
import * as productTRActions from './productTRActions';
import * as productTRTypes from './productTRTypes';
import * as productTRSchemas from './productTRSchemas';

import sagas, * as productTRSagas from './productTRSagas';
import reducer from './productTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productTRSelectors, productTRActions, productTRTypes, productTRSchemas, productTRSagas };
export default reducer;
