import React from 'react';
import PropTypes from 'prop-types';

const InciText = props => {
  const { inciUpperPart, inciLowerPart } = props;
  return (
    <div>
      <p className="text-danger">
        {inciUpperPart}
      </p>
      <p className="text-success">
        {inciLowerPart}
      </p>
    </div>
  );
};

InciText.defaultProps = {
  inciUpperPart: '',
  inciLowerPart: '',
};

InciText.propTypes = {
  inciUpperPart: PropTypes.string,
  inciLowerPart: PropTypes.string,
};

export default InciText;
