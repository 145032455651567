import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import * as editInciListFormTypes from './editInciListFormTypes';
import * as editInciListFormActions from './editInciListFormActions';
import formNames from '../../../constants/formNames';
import { createSaveForm } from '../../form/utils';
import { applicationActions } from '../../application';
import { modalSelectors, modalTypes, modalActions } from '../../modal';
import inciListApi from '../../../api/inciListApi';

export default function* root() {
  yield all([takeEvery(editInciListFormTypes.SAVE_FORM.REQUEST, onSaveFormSaga)]);
}

const onSaveFormSaga = createSaveForm(formNames.editInciListForm, saveProductInciListSaga);

function* saveProductInciListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const inciText = yield call(inciListApi.saveInciList, payload);
    yield put(editInciListFormActions.saveForm.success(inciText, payload));

    const modalType = yield select(modalSelectors.getModalType);
    if (modalType === modalTypes.EDIT_INCI_LIST) {
      yield put(modalActions.hideModal());
    }
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(editInciListFormActions.saveForm.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
