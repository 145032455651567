import {handleActions} from 'redux-actions';
import * as orderImportTypes from './orderImportTypes';
import {merge} from 'lodash';
import {combineReducers} from "redux";

const byId = handleActions(
  {
    [orderImportTypes.ORDER_IMPORT_LIST.SUCCESS]: (state, {payload}) =>
      merge({}, state, payload.entities.orderImports),
  },
  {},
);

const allIds = handleActions(
  {
    [orderImportTypes.ORDER_IMPORT_LIST.SUCCESS]: (state, {payload}) => payload.result,
  },
  [],
);

const currentOrderImport = handleActions(
  {
    [orderImportTypes.ORDER_IMPORT.SUCCESS]: (state, {payload}) => payload,
    [orderImportTypes.NEW_ORDER_IMPORT]: (state, {payload}) => ({
      OrderImportId: -1,
      ...payload,
    }),
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
  currentOrderImport,
});
