import { createSelector } from 'reselect';
import * as formulaReviewSelectors from '../formulaReview/formulaReviewSelectors';

export const getById = ({ productDataReview }) => productDataReview.byId;
export const getIds = ({ productDataReview }) => productDataReview.list.allIds;
export const getProductDataReview = (state, id) => getById(state)[id];
export const getProductDataReviewList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getProductDataReviewForFormulaReview = createSelector(
  [formulaReviewSelectors.getFormulaReview, getById],
  (formulaReview, productDataReviewById) => productDataReviewById[formulaReview.ProductDataReview],
);
