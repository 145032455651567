import PropTypes from 'prop-types';
import React from 'react';
import { Form, Grid, Row, Col } from 'react-bootstrap';
import { Field, reduxForm, FormSection } from 'redux-form';
import { I18n } from 'react-redux-i18n';

import { InputField } from '../_Common/Forms';
import formNames from '../../constants/formNames';
import AddressForm from '../_Common/Forms/AddressForm';
import BaseLoader from '../_Common/BaseLoader';
import DetailHeader from '../_Common/DetailHeader';
import DistributorContact from './DistributorContact';

const DistributorForm = props => {
  const { onSaveDistributor, handleSubmit, distributorTitle, isLoadingDetails } = props;
  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveDistributor)}>
        <DetailHeader title={`${I18n.t('distributor.panelTitle')} : ${distributorTitle}`} {...props}>
          <Row>
            <Col sm={9}>
              <Grid fluid>
                <Row>
                  <Col sm={2}>
                    <Field name="Name" label={I18n.t('distributor.name')} component={InputField} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormSection name="Address">
                      <AddressForm {...props} />
                    </FormSection>
                    <DistributorContact {...props} />
                  </Col>
                </Row>
              </Grid>
            </Col>
            <Col sm={3} />
          </Row>
        </DetailHeader>
      </Form>
    </BaseLoader>
  );
};

DistributorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveDistributor: PropTypes.func.isRequired,
  distributorTitle: PropTypes.string.isRequired,
  isLoadingDetails: PropTypes.bool.isRequired,
  onDeleteDistributor: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formNames.distributor,
  destroyOnUnmount: true,
  asyncBlurFields: [], // this seems to prevent the error
  enableReinitialize: true,
})(DistributorForm);
