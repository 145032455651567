import * as productFormSelectors from './productFormSelectors';
import * as productFormActions from './productFormActions';
import * as productFormTypes from './productFormTypes';
import sagas from './productFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productFormSelectors, productFormActions, productFormTypes };
