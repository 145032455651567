import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import ImpactAssessmentValueList from './ImpactAssessmentValueList';

const ImpactAssessmentValuesTab = props => {
  const { goToProductPage, calculateValues, requestingImpactAssessmentList, ...otherProps } = props;
  return (
    <div>
      <Button bsSize="small" onClick={goToProductPage}>
        {I18n.t('product.goToProducts')}
      </Button>
      {/* <Button bsSize="small" onClick={toggleShowAllValues}>
        Show All values
      </Button> */}
      &nbsp;
      <br />
      <br />
      <ImpactAssessmentValueList
        requestingImpactAssessmentList={requestingImpactAssessmentList}
        {...otherProps}
      />
    </div>
  );
};

ImpactAssessmentValuesTab.propTypes = {
  goToProductPage: PropTypes.func.isRequired,
  calculateValues: PropTypes.func.isRequired,
};

export default ImpactAssessmentValuesTab;
