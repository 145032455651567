import * as dashboardSelectors from './dashboardSelectors';
import * as dashboardActions from './dashboardActions';
import * as dashboardTypes from './dashboardTypes';
import sagas from './dashboardSagas';
import reducer from './dashboardReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { dashboardSelectors, dashboardActions, dashboardTypes };
export default reducer;
