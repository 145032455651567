import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';
import BaseFormGroup from './BaseFormGroup';

const RadioFields = props => {
  const { input, options, labelField, valueField, getDefaultValue, disabled } = props;
  const getValue = () => input.value || getDefaultValue(options);

  return (
    <BaseFormGroup {...props}>
      {options.map(option => {
        const handleChange = () => {
          input.onChange(option[valueField]);
        };

        const checked = getValue() === option[valueField];

        return (
          <Radio {...input} key={option[valueField]} checked={checked} onChange={handleChange} disabled={disabled}>
            {option[labelField]}
          </Radio>
        );
      })}
    </BaseFormGroup>
  );
};

RadioFields.defaultProps = {
  defaultValue: undefined,
  label: '',
  getDefaultValue: () => '',
  labelField: 'Value',
  valueField: 'Code',
  disabled: false,
};

RadioFields.propTypes = {
  labelField: PropTypes.string,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  getDefaultValue: PropTypes.func,
  options: PropTypes.array.isRequired, //eslint-disable-line
};

export default RadioFields;
