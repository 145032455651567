import { schema } from 'normalizr';
import { productSchema } from '../product/productSchemas';
import { orderSchema } from '../order/orderSchemas';

export const orderProductSchema = new schema.Entity('ordersProducts', {}, { idAttribute: 'OrderProductId' });
export const detailedOrderProductSchema = new schema.Entity(
  'ordersProducts',
  {
    Product: productSchema,
    Order: orderSchema,
  },
  { idAttribute: 'OrderProductId' },
);

export const orderProductListSchema = [orderProductSchema];
export const detailedOrderProductListSchema = [detailedOrderProductSchema];
