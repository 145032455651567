import { createRequestActions } from '../utils';

import * as documentTypes from './documentTypes';

export const saveDocument = createRequestActions(documentTypes.SAVE_DOCUMENT);
export const downloadDocument = createRequestActions(documentTypes.DOWNLOAD_DOCUMENT, (documentId, filename) => ({
  documentId,
  filename,
}));
export const getDocumentImage = createRequestActions(documentTypes.GET_IMAGE_DOCUMENT, (documentId) => ({
  documentId,
}));

