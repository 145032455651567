import { schema } from 'normalizr';
import * as substanceSchemas from '../substance/substanceSchemas';

export const complianceReviewSchema = new schema.Entity(
  'complianceReviews',
  {
    Substance: substanceSchemas.substanceSchema,
  },
  { idAttribute: 'ComplianceReviewId' },
);
export const complianceReviewListSchema = [complianceReviewSchema];
