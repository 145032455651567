import * as categorySelectors from './categorySelectors';
import * as categoryActions from './categoryActions';
import * as categoryTypes from './categoryTypes';
import * as categorySchemas from './categorySchemas';

import sagas, * as categorySagas from './categorySagas';
import reducer from './categoryReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { categorySelectors, categoryActions, categoryTypes, categorySchemas, categorySagas };
export default reducer;
