import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import {rawMatSelectors} from "../../../../redux/rawMat";
import DataGrid, { MasterDetail, Scrolling, FilterRow, HeaderFilter, SearchPanel, FilterPanel, Column, Button, Summary, TotalItem } from 'devextreme-react/data-grid';
import ProductProportionRMCompositionTemplate from './ProductProportionRMCompositionTemplate';
import { Button as RButton } from 'react-bootstrap';
import { FormControl, ControlLabel, Grid, Row, Col } from 'react-bootstrap';
import { push } from 'react-router-redux';
import ReactTooltip from 'react-tooltip';

class ProductProportionSelectRMListDX extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataGridRef: null,
      refDataSource: null,
      limitedRawmatList: [],
      filter: ''
    };
    this.dataGridRef = React.createRef();
  }

  get dataGrid() {
    return this.dataGridRef.current.instance;
  }

  onCellPrepared = (e) => {
    e.cellElement.style.fontWeight="bold";
  }

  onCellPrepared2 = (e) => {
    e.cellElement.style.textAlign="left";
    e.cellElement.style.width = "100";
  }

  render() {
    const { rawMats, onShowCompoDX, addToProductDX, RMName, RMSupplier, RMProportions } = this.props;
    let attributes = [];
    let props = RMProportions
    if (props) {
      props.map(child => {
        const name = child.InciName;
        attributes[name] = child.Percentage;
      });
    }
    return (
      <>
        <Grid fluid>
          <Row>
            {RMName &&
              <>
              <Col sm={3}>
                <ControlLabel>
                  <Translate value="rawMat.name" />
                </ControlLabel>
                <FormControl
                  type="text"
                    bsSize="small"
                    value={RMName}
                    enabled={false}
                />
              </Col>
              <Col sm={2}>
                <ControlLabel>
                  <Translate value="rawMat.supplier" />
                </ControlLabel>
                <FormControl
                  type="text"
                  bsSize="small"
                  value={RMSupplier}
                  enabled={false}
                  />
              </Col>
              </>
            }
            <Col sm={2}>
              <ControlLabel>
                <Translate value="rawMat.prefilter" />
              </ControlLabel>
              <FormControl
                type="text"
                bsSize="small"
                value={this.state.filter}
                onChange={event => {
                  this.setState({
                    filter: event.target.value,
                    })
                  }}
              />
            </Col>
            <Col sm={5}>
              <Col sm={1}>
                <br/>
                <RButton bsSize="small" bsStyle="warning" type="button" onClick={() => {
                  const datagrid = this.dataGrid;
                  const dataSource = datagrid.getDataSource();
                  dataSource.filter("Name","contains",this.state.filter);
                  dataSource.load().then((r)=>{
                    console.log(r);
                    console.log(dataSource.totalCount());
                  });
                  }}
                  data-tip data-for="prefilterTip"
                >
                  <i className="fa fa-filter" />
                </RButton>
                <ReactTooltip id="prefilterTip" place="bottom" type="dark" effect="solid">
                  <Translate value="compositionTab.prefilter" />
                </ReactTooltip>
              </Col>
              <Col sm={1}>
                <br/>
                <RButton bsSize="small" bsStyle="danger" type="button" onClick={() => {
                  const datagrid = this.dataGrid;
                  const dataSource = datagrid.getDataSource();
                  if (dataSource.totalCount() < 20) {
                    dataSource.items().map(item => {
                      if (item.Children.length === 0) {
                        const obj = {
                          row: {
                            data: {
                              RawMatId: item.RawMatId
                            }
                          }
                        }
                        onShowCompoDX(obj);
                      }
                    })
                  }
                }}
                data-tip data-for="searchTip"
                >
                  <i className="fa fa-search" />
                </RButton>
                <ReactTooltip id="searchTip" place="bottom" type="dark" effect="solid">
                  <Translate value="compositionTab.loadCompositions" />
                </ReactTooltip>
              </Col>
              <Col sm={1}>
                <br/>
                <RButton bsSize="small" bsStyle="primary" type="button" onClick={() => {
                  const datagrid = this.dataGrid;
                  const dataSource = datagrid.getDataSource();
                  if (dataSource.totalCount() < 100) {
                    datagrid.expandAll(-1);
                  }
                }}
                data-tip data-for="expandAllTip"
                >
                  <i className="fa fa-expand-alt" />
                </RButton>
                <ReactTooltip id="expandAllTip" place="bottom" type="dark" effect="solid">
                  <Translate value="general.expandAll" />
                </ReactTooltip>                </Col>
              <Col sm={1}>
                <br/>
                <RButton bsSize="small" bsStyle="primary" type="button" onClick={() => {
                  const datagrid = this.dataGrid;
                  datagrid.collapseAll(-1);
                }}
                data-tip data-for="collapseAllTip"
                >
                  <i className="fa fa-compress-alt" />
                </RButton>
                <ReactTooltip id="collapseAllTip" place="bottom" type="dark" effect="solid">
                  <Translate value="general.collapseAll" />
                </ReactTooltip>
              </Col>
              <Col sm={1}>
                <br/>
                <RButton bsSize="small" bsStyle="warning" type="button" onClick={() => {
                  this.props.onCreateRMButtonClick();
                  this.props.closeModal();
                }}
                data-tip data-for="createTip"
                >
                  <i className="fa fa-plus" />
                </RButton>
                <ReactTooltip id="createTip" place="bottom" type="dark" effect="solid">
                  <Translate value="compositionTab.createRM" />
                </ReactTooltip>
              </Col>
              </Col>
            </Row>
            <Row>
            {RMName &&
              <>
                <Col sm={4}>
                  <DataGrid
                    id="gridContainer"
                    dataSource={[attributes]}
                    showBorders={true}
                    onCellPrepared={this.onCellPrepared2}
                  >
                  </DataGrid>
                </Col>
              </>
            }
          </Row>
        </Grid>
        <DataGrid
          id="gridContainer"
          dataSource={rawMats}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          showBorders={true}
          ref={this.dataGridRef}
          onCellPrepared={this.onCellPrepared}
        >
          <Scrolling mode="none" />
          <FilterPanel visible={true} />
          <FilterRow visible={true}
            applyFilter={''}
          />
          <HeaderFilter visible={true} />
          <Summary ><TotalItem column="Code" summaryType="count"/></Summary>
          <SearchPanel visible={true}
            width={240}
            placeholder="Search..."
          />
          <Column caption= {I18n.t('rawMat.code')}
            dataField= 'Code'
            width={150}
            >
          </Column>
          <Column caption= {I18n.t('rawMat.name')}
            dataField= 'Name'>
          </Column>
          <Column caption= {I18n.t('rawMat.supplier')}
            dataField= 'SupplierName'
            width={150}
          >
          </Column>
          <Column caption= {I18n.t('rawMat.Status')}
            dataField= 'StatusName'
            width={100}>
          </Column>
          <Column caption= {I18n.t('rawMat.validatedOn')}
            dataField= 'ValidatedOn'
            width={100}
          >
          </Column>
          <Column type='buttons' width={100}>
            <Button hint="Show" icon="search" onClick={onShowCompoDX} />
            <Button hint="Select" icon="check" onClick={addToProductDX} />
          </Column>
          <MasterDetail
            enabled={true}
            component={ProductProportionRMCompositionTemplate}
          />
        </DataGrid>
      </>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  return {
    rawMats: rawMatSelectors.getRawMatListWithCompo(state),
    isLoading: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onCreateRMButtonClick2: (data) => {
    dispatch(push({ pathname: '/rawmats/-1', query: { returnToProduct: productId, tab: 200, importedProductProportionId: data }})) },
});

ProductProportionSelectRMListDX.defaultProps = {
  height: '',
  productProportions: [],
};

ProductProportionSelectRMListDX.propTypes = {
  RMName: PropTypes.string,
  RMSupplier: PropTypes.string,
  RMProportions: PropTypes.any,
  targetSupplier: PropTypes.string,
  height: PropTypes.string,
  whenGridReady: PropTypes.func.isRequired,
  productProportions: PropTypes.array.isRequired, //eslint-disable-line
  rawMats: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  addToProductDX: PropTypes.func.isRequired,
  onShowCompoDX: PropTypes.func.isRequired,
  onCreateRMButtonClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductProportionSelectRMListDX);
