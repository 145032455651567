import {merge} from 'lodash';
import {handleActions, combineActions} from 'redux-actions';
import { combineReducers } from 'redux';
import * as productCategoryTypes from './productCategoryTypes';
import queryString from 'query-string';

const initialState = {
  byId: {},
  list: {
    allIds: [],
    // byQueryIds: {},
  },
};


const byId = handleActions(
  {
    [productCategoryTypes.PRODUCT_CATEGORY_LIST.SUCCESS]: (state, { payload }) => payload.entities.productsCategories,
  },
  {},
);

const allIds = handleActions(
  {
    [productCategoryTypes.PRODUCT_CATEGORY_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

// const byQueryIds = handleActions(
//   {
//     [productCategoryTypes.PRODUCT_CATEGORY_LIST.SUCCESS]: (state, { payload, meta = {} }) => {
//       const queryStr = queryString.stringify(meta);
//       return {
//         ...state,
//         [queryStr]: Array.isArray(payload.result) ? payload.result : [payload.result],
//       };
//     },
//   },
//   {},
// );

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    // byQueryIds,
  }),
});

// export default handleActions(
//   {
//     [productCategoryTypes.PRODUCT_CATEGORY_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload, meta = {} }) => {
//       const queryStr = queryString.stringify(meta);
//       return {
//         ...state,
//         [queryStr]: Array.isArray(payload.result) ? payload.result : [payload.result],
//       };
//     },
//   },
//   initialState,
// );
