import React from 'react';
import { connect } from 'react-redux';
import { submit, isDirty, formValueSelector, isInvalid } from 'redux-form';
import AddRawMatProportionModal from './AddRawMatProportionModal';
import formNames from '../../../constants/formNames';
import { rawMatActions, rawMatSelectors, rawMatTypes } from '../../../redux/rawMat';
import { functionSelectors } from '../../../redux/function';
import { companySelectors } from '../../../redux/company';
import { substanceActions, substanceSelectors, substanceTypes } from '../../../redux/substance';
import { substanceFunctionActions, substanceFunctionTypes } from '../../../redux/substanceFunction';
import { rawMatProportionSelectors, rawMatProportionActions } from '../../../redux/rawMatProportion';
import { isNullOrUndef } from '../../../utils/utils';
import * as rawMatProportionFormSelectors from './RawMatProportionFormSelectors';
import { applicationSelectors } from '../../../redux/application';

const formName = formNames.rawMatProportionForm;

const formselector = formValueSelector(formName);

const AddRawMatProportionContainer = props => <AddRawMatProportionModal {...props} />;

const mapStateToProps = (state, ownProps) => {
  const { rawMatProportionId, rawMatId } = ownProps;
  const substanceId = rawMatProportionSelectors.rawMatProportionFormValueSelector(state, 'SubstanceId');
  const currentRawMat = rawMatSelectors.getRawMat(state, rawMatId);
  const isInEditMode = !isNullOrUndef(rawMatProportionId);
  const rawMatList = currentRawMat.CompanyId
    ? companySelectors.getRawMatListForCompany(state)(currentRawMat.CompanyId)
    : rawMatSelectors.getPublicRawMats(state);
  const selectedRawMatId = formselector(state, 'ReferencedRawMatId');
  return {
    substanceList: rawMatSelectors.getAddEditRawMatProportionSubstanceList(state)(rawMatId, rawMatProportionId),
    currentRawMat,
    isInEditMode,
    currentRawMatProportion: rawMatProportionFormSelectors.getInitialValues(state)(rawMatProportionId, substanceId),
    rawMatList,
    isDirty: isDirty(formName)(state),
    invalid: isInvalid(formName)(state),
    selectedSubstanceId: formselector(state, 'SubstanceId'),
    selectedRawMatId,
    substanceListFilter: rawMatSelectors.getAllowedNewSubstanceListFilter(state)(rawMatId),
    sortByInciName: substanceSelectors.sortByInciName,
    substance: substanceSelectors.getSubstance(state, substanceId),
    isLoadingContent: applicationSelectors.isLoading(
      state,
      rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION.REQUEST,
      rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST,
    ),
    showSubstanceFilter: !isInEditMode && !selectedRawMatId,
    isLoadingFunctions: applicationSelectors.isLoading(state, substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST.REQUEST),
    isLoadingSubstanceDetails: applicationSelectors.isLoading(state, substanceTypes.SUBSTANCE.REQUEST),
    functionList: functionSelectors.getFunctionListForSubstanceOrAll(state)(substanceId),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onSave: data =>
    (data.ReferencedRawMatId
      ? dispatch(rawMatProportionActions.addRawMatProportionsFromRawMat.request({ ...data, rawMatId }))
      : dispatch(rawMatActions.saveRawMatProportion.request({ ...data, rawMatId }))),
  onSubmitButtonClick: () => dispatch(submit('rawMatProportionForm')),
  getSubstanceFunctions: id => dispatch(substanceFunctionActions.getSubstanceFunctionsForSubstance.request(id)),
  fetchSubstance: id => dispatch(substanceActions.substance.request(id)),
  onRawMatSelected: selectedRawMatId =>
    dispatch(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId: selectedRawMatId,
        include: {
          OptIncludeSubstance: true,
          OptIncludeFunctions: true,
        },
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRawMatProportionContainer);
