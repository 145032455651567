import queryString from 'query-string';
import { get, del, post } from './apiUtils';

const defaultUrl = '/DraftLabels/';

export default {
  getDraftLabels: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  deleteDraftLabels: id => del(`${defaultUrl}${id}`),
  saveDraftLabels: data => post(`${defaultUrl}`, data),
};
