import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {GeneralGrid, CheckListItemRenderer} from '../../_Common/Grids';
import ActionButtonsRenderer from "./ActionButtonRenderer";
import * as R from "ramda";
import workflowStatus from "../../../constants/workflowStatus";
import { MissingMandatoryInfoForLR } from './DocumentListBuilder';


const MandatoryInfoLabelReview = props => {
  const {
    lastFRProductData,
    productId,
    onReceivedClick,
    onVerifiedClick
  } = props;

  const getIsIsReceivedButtonFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_MISSING),
    R.always(false),
    R.always(true),
  );

  const getIsVerifiedButtonDisabledFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_RECEIVED),
    R.always(false),
    R.always(true),
  );

  const itemList = MissingMandatoryInfoForLR(lastFRProductData, productId);

  const columns = [
    {
      headerName: 'Info type',
      field: 'text',
      width: 100,
      minWidth: 100,
      cellRendererFramework: CheckListItemRenderer
    },
    {
      headerName: I18n.t('general.actions'),
      width: 55,
      cellRendererFramework: ActionButtonsRenderer,
      cellRendererParams: {
        onMarkAsReceivedClick: onReceivedClick,
        onMarkAsVerifiedClick: onVerifiedClick,
        getIsReceivedButtonDisabled: R.compose(
          getIsIsReceivedButtonFromStatusCode,
          R.prop('status'),
        ),
        getIsVerifiedButtonDisabled: R.compose(
          getIsVerifiedButtonDisabledFromStatusCode,
          R.prop('status'),
        ),
      }
    }
  ];
  console.log('itemList', itemList);

  return (
    <div>
      <span><b><Translate value="lastFRCheckList.mandatoryInformationForLabelReview"/></b></span>
      <GeneralGrid
        key={itemList.length + props.isLoadingCheckListTab}
        data={itemList}
        columns={columns}
        keyName="text"
        height="15vh"
        showFilterRow={false}
      />
    </div>
  );
};

MandatoryInfoLabelReview.propTypes = {
  isLoadingCheckListTab: PropTypes.bool.isRequired,
};

export default MandatoryInfoLabelReview;
