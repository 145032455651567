import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { SelectionGrid, DateCellRenderer } from '../../_Common/Grids';
import { Button, ButtonToolbar } from 'react-bootstrap';

const actionButtonsRenderer = props => {
  const { data, onConfirm, onIgnore, node } = props;
  return (
    <div style={{ textAlign: 'center' }}>
      {node.level === 1 && (
        <ButtonToolbar>
          <Button bsSize="xsmall" type="button" onClick={() => onConfirm(data)}>
            <i className="fa fa-print" aria-hidden="true" />
            &nbsp;
            <Translate value="product.impactReviewed" />
          </Button>
          <Button bsSize="xsmall" type="button" onClick={() => onIgnore(data)}>
            <i className="fa fa-print" aria-hidden="true" />
            &nbsp;
            <Translate value="product.impactIgnored" />
          </Button>
        </ButtonToolbar>
      )}{' '}
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onConfirm: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
};
const ProductImpactAssessmentList = props => {
  const {
    currentProductImpactAssessments,
    isLoading,
    getProductImpactAssessments,
    onRowDoubleClicked,
    onConfirmImpactAssessmentProductClick,
    onIgnoreImpactAssessmentProductClick,
  } = props;

  const columns = [
    {
      headerName: I18n.t('impactAssessment.number'),
      field: 'ImpactAssessmentId',
      cellRenderer: ({ data, node }) => {
        if (node.level === 0) {
          return data.ImpactAssessmentId;
        }
        if (node.level === 1) {
          return data.ImpactAssessmentProductId;
        }
        return '';
      },
      width: 100,
      sort: 'desc',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessment.code'),
      field: 'ImpactAssessmentCode',
      width: 100,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessment.type'),
      field: 'ImpactAssessmentType',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessment.description'),
      field: 'Description',
      width: 200,
      // suppressSizeToFit: true,
    },
    /*    {
      headerName: I18n.t('impactAssessment.regulatoryRegion'),
      width: 100,
      field: 'RegulatoryRegionCode',
      suppressSizeToFit: true,
    }, */
    {
      headerName: I18n.t('impactAssessment.startDate'),
      width: 100,
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessment.endDate'),
      width: 100,
      field: 'EndDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      suppressSizeToFit: true,
    },
    {
      headerName: 'Inci',
      field: 'InciName',
      width: 75,
      suppressSizeToFit: true,
      visible: true,
    },
    {
      headerName: 'Test',
      field: 'Comparator',
      width: 75,
      suppressSizeToFit: true,
      visible: true,
    },
    {
      headerName: 'Quantity',
      field: 'QuantityValue',
      width: 75,
      suppressSizeToFit: true,
      visible: true,
    },
    {
      headerName: 'MoS',
      field: 'MoSValue',
      width: 75,
      suppressSizeToFit: true,
      visible: true,
    },
    {
      headerName: 'Out',
      cellRenderer: ({ data, node }) => {
        if (node.level === 2) {
          if (data.BeyondThreshold) {
            return '<i class="fa fa-exclamation-triangle" aria-hidden="true" style="color:#FF0000;"></i>';
          }
          if (!data.BeyondThreshold) {
            return '<i class="fa fa-check-circle" aria-hidden="true" style="color:#538c14;"></i>';
          }
        }
        return '';
      },
      width: 75,
      suppressSizeToFit: true,
      visible: true,
    },
    {
      headerName: I18n.t('impactAssessmentProduct.status'),
      width: 65,
      field: 'Status',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessmentProduct.actions'),
      cellRendererFramework: actionButtonsRenderer,
      cellRendererParams: {
        onConfirm: onConfirmImpactAssessmentProductClick,
        onIgnore: onIgnoreImpactAssessmentProductClick,
      },
      width: 200,
      minWidth: 200,
      suppressSizeToFit: true,
    },
  ];

  return (
    <SelectionGrid
      data={currentProductImpactAssessments}
      getNodeChildDetails={getNodeChildDetails}
      keyName="ImpactAssessmentId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getProductImpactAssessments}
      rowDoubleClicked={onRowDoubleClicked}
      deltaRowDataMode={false}
      showAddButton={false}
      showExpandCollapseButtons
    />
  );
};

const getNodeChildDetails = rowItem => {
  if (rowItem.Products) {
    return {
      group: true,
      expanded: true,
      children: rowItem.Products,
      key: rowItem.ImpactAssessmentProductId,
    };
  }
  if (rowItem.Values) {
    return {
      group: true,
      expanded: true,
      children: rowItem.Values,
      key: rowItem.ImpactAssessmentValueId,
    };
  }
  return null;
};

ProductImpactAssessmentList.propTypes = {
  currentProductImpactAssessments: PropTypes.arrayOf(
    PropTypes.shape({
      impactAssessmentId: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getProductImpactAssessments: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onConfirmImpactAssessmentProductClick: PropTypes.func.isRequired,
  onIgnoreImpactAssessmentProductClick: PropTypes.func.isRequired,
};

export default ProductImpactAssessmentList;
