import { createAction } from 'redux-actions';
import { createRequestActions } from '../../utils';
import * as productInciImpurityModalTypes from './productInciImpurityModalTypes';

export const onSaveButtonClick = createAction(
  productInciImpurityModalTypes.SAVE_BUTTON_CLICKED,
  (productId, data) => data,
  (productId, data) => ({ productId }),
);

export const saveInciSubstanceImpuritiesForProductSaga = createRequestActions(
  productInciImpurityModalTypes.SAVE_INCI_SUBSTANCE_IMPURITIES,
  (productId, data) => data,
  (productId, data) => ({ productId }),
);
