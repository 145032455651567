import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import SubstanceCosAnnexeList from './SubstanceCosAnnexeList';
import SubstanceCosAnnexeForm from './SubstanceCosAnnexeForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

class SubstanceCosAnnexesModal extends React.Component {
  state = {
    selectedSubstanceCosAnnexe: null,
  };

  onGridReady = g => {
    this.grid = g;
  };

  onNewButtonClick = () => {
    if (this.grid) {
      this.grid.api.deselectAll();
      this.setState({
        selectedSubstanceCosAnnexe: null,
      });
    }
  };
  handleCosAnnexeSelected = substanceCosAnnexeId =>
    this.setState({
      selectedSubstanceCosAnnexe: substanceCosAnnexeId,
    });

  render() {
    const { title, closeModal, cosAnnexeCode } = this.props;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SubstanceCosAnnexeList
            height="60vh"
            cosAnnexeCode={cosAnnexeCode}
            whenGridReady={this.onGridReady}
            onSubstanceCosAnnexeSelected={this.handleCosAnnexeSelected}
            onAddButtonClick={this.onNewButtonClick}
          />
          <SubstanceCosAnnexeForm
            substanceCosAnnexeId={this.state.selectedSubstanceCosAnnexe}
            cosAnnexeCode={cosAnnexeCode}
            onNewButtonClick={this.onNewButtonClick}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />&nbsp;
                <Translate value="general.close" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

SubstanceCosAnnexesModal.defaultProps = {};

SubstanceCosAnnexesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cosAnnexeCode: PropTypes.string.isRequired,
};

export default SubstanceCosAnnexesModal;
