import * as documentSelectors from './documentSelectors';
import * as documentActions from './documentActions';
import * as documentTypes from './documentTypes';
import sagas from './documentSagas';
import reducer from './documentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { documentSelectors, documentActions, documentTypes };
export default reducer;
