import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import * as draftLabelTypes from './draftLabelTypes';

const byId = handleActions(
  {
    [draftLabelTypes.FETCH_DRAFT_LABELS.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.draftLabels),
    [draftLabelTypes.DELETE_DRAFT_LABEL.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [draftLabelTypes.FETCH_DRAFT_LABELS.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [draftLabelTypes.DELETE_DRAFT_LABEL.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

const byProductIds = handleActions(
  {
    [draftLabelTypes.FETCH_DRAFT_LABELS.SUCCESS]: (state, { payload, meta = {} }) => {
      if (meta.query && meta.query.productId) return { ...state, [meta.query.productId]: payload.result };
      return state;
    },
    [draftLabelTypes.DELETE_DRAFT_LABEL.SUCCESS]: (state, { payload }) => R.without([payload])(state),
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byProductIds,
  }),
});
