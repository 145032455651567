import { takeLatest, put, all } from 'redux-saga/effects';
import { productPhraseActions } from '../../../../redux/productPhrase';
import { productPhraseFormActions } from '../../../../redux/form/productPhraseForm';
import { modalActions } from '../../../../redux/modal';
import * as types from './types';

export default function* root() {
  yield all([takeLatest(types.SAVE_BUTTON_CLICK, saveButtonClickSaga)]);
}

function* saveButtonClickSaga({ payload }) {
  const { currentProductId, selectedProductId, formName, cpsrTypeCode } = payload;
  yield put(
    productPhraseActions.copyProductPhrasesFromProduct.request(selectedProductId, currentProductId, cpsrTypeCode),
  );
  yield put(modalActions.hideModal());
  yield put(
    productPhraseFormActions.loadForm({
      formName,
      ProductId: currentProductId,
      CpsrTypeCode: cpsrTypeCode,
    }),
  );
}
