import { createRequestActions } from '../../utils';
import * as shadeXInciTypes from './shadeXInciTypes';

export const loadModalData = createRequestActions(
  shadeXInciTypes.LOAD_MODAL_DATA,
  ProductId => ({
    ProductId,
  }),
  (result, ProductId) => ({
    ProductId,
  }),
);
