import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-redux-i18n';
import FormulaReviewList from './FormulaReviewList';
import BaseLoader from '../../_Common/BaseLoader';
import LoadingButton from '../../_Common/LoadingButton';

const FormulaReviewTab = props => {
  const { onNewFormulaReviewClick, isLoadingFormulaReviewTab, isLoadingNewWizard, ...otherProps } = props;
  return (
    <BaseLoader isLoading={isLoadingFormulaReviewTab}>
      <div>
        <LoadingButton type="button" bsSize="small" onClick={onNewFormulaReviewClick} isLoading={isLoadingNewWizard}>
          <Translate value="product.addFormulaReview" />
        </LoadingButton>&nbsp;&nbsp;
      </div>
      <br />
      <div>
        <FormulaReviewList {...otherProps} isLoadingNewWizard={isLoadingNewWizard} />
      </div>
    </BaseLoader>
  );
};

FormulaReviewTab.propTypes = {
  onNewFormulaReviewClick: PropTypes.func.isRequired,
  isLoadingFormulaReviewTab: PropTypes.bool.isRequired,
  isLoadingNewWizard: PropTypes.bool.isRequired,
};

export default FormulaReviewTab;
