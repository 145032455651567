import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { productActions, productTypes, productSelectors } from '../../../redux/product';
import { taskActions } from '../../../redux/task';
import { applicationSelectors } from '../../../redux/application';
import ProductTasksTab from './ProductTasksTab';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import {modalActions} from "../../../redux/modal";

class ProductTasksContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ProductTasksTab {...this.props} />;
  }
}

const mapStateToProps = state => {
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('TASK');
  return {
    currentProductTasks: productSelectors.getCurrentProductTasks(state),
    availableOrdersForCurrentProduct: productSelectors.getAvailableOrdersForCurrentProduct(state),
    isLoading: applicationSelectors.isLoading(state, productTypes.PRODUCT_TASK_LIST.REQUEST),
    workflowStatusList: getWorkflowStatusList(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
    onLoad: () => {
      dispatch(productActions.listProductTasks.request(productId));
      //dispatch(productActions.listOrdersOwnedByProductCompany.request(productId));
    },
    getProductTasks: () => dispatch(productActions.listProductTasks.request(productId)),
    onDeleteClick: OrderId => dispatch(productActions.removeOrderFromProduct(productId, OrderId)),
    onRowDoubleClicked: order => dispatch(push(`/orders/${order.OrderId}`)),
    addOrderToCurrentProduct: data => dispatch(productActions.addOrderToCurrentProductAction(data)),
    onChangeStatus: data => {
      dispatch(taskActions.updateStatus({ WorkflowStatusCode: data.Code, TaskId: data.TaskId }));
      dispatch(productActions.listProductTasks.request(productId));
    },
    onEditSelectedButtonClick: taskIds => dispatch(
      modalActions.showTaskFormModal({
        taskId: -1,
        manager: true,
        productId,
        taskIds
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTasksContainer);
