import { takeEvery, all, put, select, take } from 'redux-saga/effects';
import { isEqual } from 'lodash/fp';
import { change, initialize, getFormInitialValues } from 'redux-form';
import * as productPhraseTRFormTypes from './productPhraseTRFormTypes';
import * as productPhraseTRFormSelectors from './productPhraseTRFormSelectors';
import {phraseTRTypes} from '../../phraseTR';
import {productPhraseTRActions, productPhraseTRSelectors, productPhraseTRTypes} from '../../productPhraseTR';
import {productPhraseTypes, productPhraseSelectors} from '../../productPhrase';
import {referentialSelectors} from "../../referentials";

export default function* root() {
  yield all([
    takeEvery(productPhraseTRFormTypes.ADD_TRANSLATION, addTranslationSaga),
    takeEvery(productPhraseTRFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClick),
    takeEvery(productPhraseTRFormTypes.LOAD_FORM, loadFormSaga),
  ]);
}

function* onSaveButtonClick({ payload }) {
  const { formData, formName, ProductId, CpsrTypeCode } = payload;

  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const initialFormValues = yield select(state => getFormInitialValues(formName)(state));

  const editedTranslations = [];
  formData.allIds.forEach(id => {
    const initial = initialFormValues[id];
    const edited = formData[id];
    languages.forEach(lang => {
      const equal = isEqual(initial[lang.Alpha2], edited[lang.Alpha2]);
      if(!equal) editedTranslations.push(edited[lang.Alpha2].Text);
    })
  });

  if (editedTranslations.length > 0) {
    yield put(productPhraseTRActions.saveProductPhraseTR.request(editedTranslations, ProductId, CpsrTypeCode));
    yield take(productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.SUCCESS);
    const productPhraseTranslationList = yield select(state =>
      productPhraseTRFormSelectors.getInitialFormValues(state, ProductId, CpsrTypeCode),
    );
    yield put(initialize(formName, productPhraseTranslationList));
  }
}

function* addTranslationSaga({ payload }) {
  const { productPhraseId, selectedLanguage, CpsrTypeCode, productId, PhraseTRId, formName } = payload;

  const productPhrase = yield select(state => productPhraseSelectors.getById(state)[productPhraseId]);
  const productPhraseTRList = yield select(state => productPhraseTRSelectors.getProductPhraseTRList(state));
  const TR = productPhraseTRList.find(elem => elem.LanguageCode === selectedLanguage && elem.ProductPhraseId === productPhraseId);

  if(!TR){
    const newTranslation = {
      ProductId: productId,
      ProductPhraseId: productPhraseId,
      PhraseTRId,
      LanguageCode: selectedLanguage,
      V1: productPhrase.V1,
      V2: productPhrase.V2,
      V3: productPhrase.V3,
    V5: productPhrase.V5,
    V6: productPhrase.V6,
    V7: productPhrase.V7,
    TM: productPhrase.TM,
    };
    yield put(change(formName, `${productPhraseId}.${selectedLanguage}.Text`, newTranslation));
  }
}

function* loadFormSaga({ payload }) {
  const { formName, ProductId, CpsrTypeCode } = payload;
  yield all([
    take(productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.SUCCESS),
    take(productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.SUCCESS),
    take(phraseTRTypes.PHRASE_TRANSLATION_LIST.SUCCESS)
  ]);

  const initialFormValues = yield select(state =>
    productPhraseTRFormSelectors.getInitialFormValues(state, ProductId, CpsrTypeCode),
  );

  yield put(initialize(formName, initialFormValues));
}
