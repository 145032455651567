import * as orderSelectors from './orderSelectors';
import * as orderActions from './orderActions';
import * as orderTypes from './orderTypes';
import * as orderSchemas from './orderSchemas';
import sagas from './orderSagas';
import reducer from './orderReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { orderSelectors, orderActions, orderTypes, orderSchemas };
export default reducer;
