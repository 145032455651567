import { createRequestActions } from '../utils';
import * as substanceDocumentTypes from './substanceDocumentTypes';

export const saveSubstanceDocument = createRequestActions(
  substanceDocumentTypes.SAVE_SUBSTANCE_DOCUMENT,
  (SubstanceId, data) => ({
    SubstanceId,
    ...data,
  }),
);

export const uploadSubstanceDocument = createRequestActions(
  substanceDocumentTypes.UPLOAD_SUBSTANCE_DOCUMENT,
  (SubstanceId, data) => ({
    SubstanceId,
    ...data,
  }),
);

export const uploadSubstanceStructure = createRequestActions(
  substanceDocumentTypes.UPLOAD_SUBSTANCE_STRUCTURE,
  (SubstanceId, data) => ({
    SubstanceId,
    data,
  }),
);

export const listSubstanceDocumentsForSubstance = createRequestActions(
  substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE,
  SubstanceId => ({ SubstanceId }),
  (SubstanceDocuments, SubstanceId) => ({ SubstanceId }),
);

export const deleteSubstanceDocument = createRequestActions(
  substanceDocumentTypes.DELETE_SUBSTANCE_DOCUMENT,
  SubstanceDocumentId => ({ SubstanceDocumentId }),
);

export const dsubstanceDocument = createRequestActions(substanceDocumentTypes.SUBSTANCE_STRUCTURE, SubstanceId => ({
  SubstanceId,
}));
