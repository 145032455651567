import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companyActions, companyTypes } from '../../../redux/company';
import { companyDocumentActions } from '../../../redux/companyDocument';
import { applicationSelectors } from '../../../redux/application';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';

import CompanyNotificationsTab from './CompanyNotificationsTab';

class CompanyNotificationsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      // this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      // this.props.onLoad();
    }
  }

  render() {
    return <CompanyNotificationsTab {...this.props} />;
  }
}

const mapStateToProps = state => {
  const {
    company: { currentCompanyProducts },
  } = state;
  return {
    workflowStatusNameById: workflowStatusSelectors.getById(state),
    currentCompanyProducts,
    requestingCompanyProductList: applicationSelectors.isLoading(state, companyTypes.COMPANY_PRODUCT_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onUploadFile: data => dispatch(companyDocumentActions.uploadCompanyNotificationData.request(companyId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyNotificationsContainer);
