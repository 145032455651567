import { select, takeEvery, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import { isGeneralErrorShown } from './applicationSelectors';

export default function* root() {
  yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}

function* onLocationChange() {
  if (yield select(isGeneralErrorShown)) {
  }
}
