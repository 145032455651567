import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import { I18n } from 'react-redux-i18n';

const LabelReviewSteps = props => {
  const { activeStep } = props;
  const steps = [
    {
      key: 'LabelReview',
      title: I18n.t('labelReviewWizard.LabelReview'),
    },
    {
      key: 'Part1',
      title: I18n.t('labelReviewWizard.Part1'),
    },
    {
      key: 'Inci',
      title: I18n.t('labelReviewWizard.Inci'),
    },
    {
      key: 'Claim',
      title: I18n.t('labelReviewWizard.ClaimTitle'),
    },
    {
      key: 'Symbol',
      title: I18n.t('labelReviewWizard.Symbol'),
    },
  ];

  return (
    <Steps current={activeStep}>
      {steps.map(step => <Step {...step} />)}
    </Steps>
  );
};

LabelReviewSteps.defaultProps = {
  activeStep: 1,
};

LabelReviewSteps.propTypes = {
  activeStep: PropTypes.number,
};

export default LabelReviewSteps;
