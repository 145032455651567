import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import {Translate, I18n} from 'react-redux-i18n';
import {GeneralGrid, CheckListItemRenderer} from '../../_Common/Grids';
import workflowStatus from '../../../constants/workflowStatus';
import ActionButtonsRenderer from "./ActionButtonRenderer";
import { MissingMandatoryInfoForCPSR } from './DocumentListBuilder';

const MandatoryInfoCPSR = props => {
  const {
    lastFRProductData,
    productId,
    onReceivedClick,
    onVerifiedClick
  } = props;

  const getIsIsReceivedButtonFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_MISSING.toString()),
    R.always(false),
    R.always(true),
  );

  const getIsVerifiedButtonDisabledFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_RECEIVED),
    R.always(false),
    R.always(true),
  );

  const itemList = MissingMandatoryInfoForCPSR(lastFRProductData, productId);

  if (lastFRProductData.DermatologicalIrritationTestsStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED && !lastFRProductData.IsDermatologicalIrritationTestsNotMandatory) {
    itemList.push({
      text: 'DermatologicalIrritationTestsStatus',
      productId: productId,
      status: lastFRProductData.DermatologicalIrritationTestsStatus
    });
  }
  if (lastFRProductData.OcularIrritationTestsStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'OcularIrritationTestsStatus',
      productId: productId,
      status: lastFRProductData.OcularIrritationTestsStatus
    });
  }

  const columns = [
    {
      headerName: 'Info type',
      field: 'text',
      width: 100,
      minWidth: 100,
      cellRendererFramework: CheckListItemRenderer
    },
    {
      headerName: I18n.t('general.actions'),
      width: 55,
      cellRendererFramework: ActionButtonsRenderer,
      cellRendererParams: {
        onMarkAsReceivedClick: onReceivedClick,
        onMarkAsVerifiedClick: onVerifiedClick,
        getIsReceivedButtonDisabled: R.compose(
          getIsIsReceivedButtonFromStatusCode,
          R.prop('status'),
        ),
        getIsVerifiedButtonDisabled: R.compose(
          getIsVerifiedButtonDisabledFromStatusCode,
          R.prop('status'),
        ),
      }
    }
  ];

  return (
    <div>
      <span><b><Translate value="lastFRCheckList.mandatoryInformationForCPSR"/></b></span>
      <GeneralGrid
        key={itemList.length + props.isLoadingCheckListTab}
        data={itemList}
        columns={columns}
        keyName="text"
        height="15vh"
        showFilterRow={false}
      />
    </div>
  );
};

MandatoryInfoCPSR.propTypes = {
  isLoadingCheckListTab: PropTypes.bool.isRequired,
};

export default MandatoryInfoCPSR;
