import { productSelectors } from '../../product';
import { productTRSelectors } from '../../productTR';
import {referentialSelectors} from "../../referentials";

export const getInitialValues = (state, productId) => {
  const product = productSelectors.getById(state)[productId];
  const productTRs = productTRSelectors.getProductTRByProductId(state, productId);
  const languageList = referentialSelectors.getLanguageList(state);
  const values = {
    product
  }
  languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
    const productForLang = productTRs.find(elem => elem.LanguageCode === lang.Alpha2);
    values[lang.Alpha2] = productForLang ? productForLang : {
      ProductId: productId,
      LanguageCode: lang.Alpha2,
    }
  });
  return values;
};
