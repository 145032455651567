import { createSelector } from 'reselect';

export const getById = ({ substanceFunction }) => substanceFunction.byId;
export const getIds = ({ substanceFunction }) => substanceFunction.list.allIds;
export const getSubstanceFunctionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getSubstanceFunction = (state, id) => state.substanceFunction.byId[id];

const getSubstanceFunctionListForSubstance = substanceId =>
  createSelector([getSubstanceFunctionList], sustanceFunctionList =>
    sustanceFunctionList.filter(sf => sf.SubstanceId === substanceId),
  );

export const makeGetSubstanceFunctionListForSubstance = createSelector(
  getSubstanceFunctionListForSubstance,
  substanceFunctionListForSubstance => substanceFunctionListForSubstance,
);
