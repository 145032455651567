import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import substanceActionApi from '../../api/substanceActionApi';
import * as substanceActionTypes from './substanceActionTypes';
import * as substanceActionSchemas from './substanceActionSchemas';
import * as substanceActionActions from './substanceActionActions';

export default function* root() {
  yield all([takeLatest(substanceActionTypes.FETCH_SUBSTANCE_ACTIONS.REQUEST, getSubstanceActionsSaga)]);
}

export function* getSubstanceActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceActionList = yield call(substanceActionApi.getSubstanceActions, payload);
    const normalizedData = normalize(substanceActionList, substanceActionSchemas.substanceActionListSchema);
    yield put(substanceActionActions.fetchSubstanceActions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceActionActions.fetchSubstanceActions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
