import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import CompanyProductList from './CompanyProductList';

const CompanyProductsTab = props => {
  const { goToProductPage, ...otherProps } = props;
  return (
    <div>
      <Button bsSize="small" onClick={goToProductPage}>
        {I18n.t('product.goToProducts')}
      </Button>
      <br />
      <br />
      <CompanyProductList {...otherProps} />
    </div>
  );
};

CompanyProductsTab.propTypes = {
  goToProductPage: PropTypes.func.isRequired,
};

export default CompanyProductsTab;
