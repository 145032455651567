import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { SelectionGrid, DateCellRenderer, EditButton, TooltipRenderer } from '../../_Common/Grids';
import LoadingButton from "../../_Common/LoadingButton";

const actionButtonsRenderer = props => {
  const { data, onEdit, onCopy, onCompoPrint, onPrint, onQuickPrint, getIsEditLoading, getIsCopyLoading } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <ButtonToolbar>
        <EditButton onClick={() => onEdit(data)} isLoading={getIsEditLoading(data)} disabled={!data.New} />
        <LoadingButton isLoading={getIsCopyLoading(data)} bsSize="xs" bsStyle="info" type="button" onClick={() => onCopy(data)}>
          <i className="fa fa-lg" aria-hidden="true" />&nbsp;<Translate value="general.copy" />
        </LoadingButton>
        {/* <Button bsSize="xs" type="button" onClick={() => onCompoPrint(data)}>
          <i className="fa fa-print fa-lg" aria-hidden="true" />&nbsp;<Translate value="formulaReview.compoPrint" />
        </Button> */}
        <Button bsSize="xs" type="button" onClick={() => onPrint(data)}>
          <i className="fa fa-print fa-lg" aria-hidden="true" />&nbsp;<Translate value="formulaReview.print" />
        </Button>
        <Button bsSize="xs" type="button" onClick={() => onQuickPrint(data)}>
          <i className="fa fa-print" aria-hidden="true" />&nbsp;<Translate value="formulaReview.quickPrint" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onEdit: PropTypes.func.isRequired,
  onCompoPrint: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onQuickPrint: PropTypes.func.isRequired,
  getIsEditLoading: PropTypes.func.isRequired,
};

const FormulaReviewList = props => {
  const {
    formulaReviewList,
    getFormulaReviewListForProduct,
    onEditFormulaReview,
    onCopyFormulaReview,
    onFormulaReviewReportPrintClick,
    onFormulaReviewReportQuickPrintClick,
    onFormulaReviewReportCompoPrintClick,
    isLoadingFormulaReviewList,
    getIsLoadingWizardForFormulaReview,
    getIsCopyLoadingWizardForFormulaReview,
  } = props;

  const columns = [
    {
      headerName: I18n.t('formulaReview.revisionNr'),
      field: 'RevisionNr',
      width: 50,
      suppressSizeToFit: true,
      sort: 'desc',
    },
    {
      headerName: I18n.t('formulaReview.user'),
      field: 'CreatedBy',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('formulaReview.creationDate'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      width: 135,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('formulaReview.validationDate'),
      field: 'ValidatedOn',
      cellRenderer: DateCellRenderer,
      width: 135,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('general.region'),
      field: 'RegionCode',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('general.country'),
      field: 'CountryAlpha2',
      suppressSizeToFit: true,
      width: 75,
    },
    {
      headerName: I18n.t('formulaReview.commment'),
      field: 'FormulaReviewComment',
      cellRendererFramework: TooltipRenderer,
    },
    {
      headerName: I18n.t('formulaReview.actions'),
      cellRendererFramework: actionButtonsRenderer,
      cellRendererParams: {
        onEdit: onEditFormulaReview,
        onCopy: onCopyFormulaReview,
        getIsEditLoading: ({ FormulaReviewId }) => getIsLoadingWizardForFormulaReview(FormulaReviewId),
        getIsCopyLoading:  ({ FormulaReviewId }) => getIsCopyLoadingWizardForFormulaReview(FormulaReviewId),
        onCompoPrint: onFormulaReviewReportCompoPrintClick,
        onPrint: onFormulaReviewReportPrintClick,
        onQuickPrint: onFormulaReviewReportQuickPrintClick,
      },
      width: 320,
      minWidth: 240,
      suppressSizeToFit: true,
    },
  ];

  return (
    <SelectionGrid
      key={Date.now()}
      data={formulaReviewList}
      onRefresh={getFormulaReviewListForProduct}
      isLoading={isLoadingFormulaReviewList}
      columns={columns}
      keyName="FormulaReviewId"
      showAddButton={false}
    />
  );
};

FormulaReviewList.propTypes = {
  formulaReviewList: PropTypes.array.isRequired, //eslint-disable-line
  getFormulaReviewListForProduct: PropTypes.func.isRequired,
  isLoadingFormulaReviewList: PropTypes.bool.isRequired,
  onEditFormulaReview: PropTypes.func.isRequired,
  getIsLoadingWizardForFormulaReview: PropTypes.func.isRequired,
  onFormulaReviewReportQuickPrintClick: PropTypes.func.isRequired,
  onFormulaReviewReportCompoPrintClick: PropTypes.func.isRequired,
  onFormulaReviewReportPrintClick: PropTypes.func.isRequired,
};

export default FormulaReviewList;
