import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as homepageTypes from './homepageTypes';

const byId = handleActions(
  {
    [homepageTypes.MERGE_HOMEPAGES]: (state, { payload }) => ({
      ...state,
      ...payload.entities.homePages,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [homepageTypes.MERGE_HOMEPAGES]: (state, { payload }) => [...new Set([...state, payload.result])],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
