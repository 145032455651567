import PropTypes from 'prop-types';
import React from 'react';
import {css} from 'glamor';
import * as R from 'ramda';
import {I18n} from 'react-redux-i18n';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { DateCellRenderer } from '../../_Common/Grids';
// const getYN = ({data, colDef}) =>
//   R.compose(
//     boolToYN,
//     R.propOr(false, colDef.field),
//   )(data);
// const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const getNoLongerInMarket = ({data, colDef}) => {
  return data.IsNoLongerOnMarket ? 'yes' : '';
};

const CompanyProductList = props => {
  const {
    currentCompanyProducts,
    requestingCompanyProductList,
    onRefresh,
    onRowDoubleClicked,
    workflowStatusNameById,
  } = props;
  const styles = {
    main: css({
      height: '100%',
    }),
    gridContainer: css({
      height: '100%',
    }),
  };

  const getWorkflowName = ({data, colDef}) => R.propOr('', 'Name', workflowStatusNameById[data[colDef.field]]);

  const columns = [
    {
      headerName: I18n.t('product.code'),
      field: 'Code',
      width: 75,
      suppressSizeToFit: true,
      sort: 'desc',
      cellStyle: {color: 'blue', textDecoration: 'underline dotted'},
    },
    {
      headerName: I18n.t('product.productName'),
      field: 'ProductName',
      width: 200,
      minWidth: 200,
      // cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
      // suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.NumOfShades'),
    //   width: 65,
    //   field: 'ShadeCount',
    //   suppressSizeToFit: true,
    // },
    {
      headerName: I18n.t('product.CompoStatus'),
      width: 100,
      field: 'CompositionStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.CPSRAStatus'),
      width: 100,
      field: 'CpsrAStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.CPSRBStatus'),
      width: 100,
      field: 'CpsrBStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.CPSRBType'),
    //   width: 100,
    //   field: 'CpsrBConclusionStatus',
    //   valueGetter: getWorkflowName,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.isLabelApprovalDone'),
    //   width: 100,
    //   field: 'IsLabelApprovalDone',
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.partAToDo'),
    //   width: 100,
    //   field: 'IsCpsrAToDo',
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.dl'),
    //   width: 100,
    //   field: 'dl',
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.IsNotified'),
    //   width: 100,
    //   field: 'IsNotified',
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.CpnpCat3'),
    //   width: 100,
    //   field: 'NotificationCode',
    //   suppressSizeToFit: true,
    // },
    {
      headerName: I18n.t('product.IsNotifiedEU'),
      width: 100,
      field: 'NotifiedEU',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('product.CpnpCat3EU'),
      width: 100,
      field: 'NotificationCodeEU',
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.IsNotifiedUK'),
    //   width: 100,
    //   field: 'IsNotifiedUK',
    //   suppressSizeToFit: true,
    // },
    {
      headerName: I18n.t('product.IsNotifiedUK'),
      width: 100,
      field: 'NotifiedUK',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('product.CpnpCat3UK'),
      width: 100,
      field: 'NotificationCodeUK',
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.IsNotifiedCAN'),
    //   width: 100,
    //   field: 'IsNotifiedCan',
    //   suppressSizeToFit: true,
    // },
    {
      headerName: I18n.t('product.IsNotifiedCAN'),
      width: 100,
      field: 'NotifiedCAN',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },    {
      headerName: I18n.t('product.CpnpCat3CAN'),
      width: 100,
      field: 'NotificationCodeCAN',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.IsNoLongerOnMarket'),
      width: 100,
      field: 'IsNoLongerOnMarket',
      valueGetter: getNoLongerInMarket,
      suppressSizeToFit: true,
    }
  ];


  return (
    <div className={`${styles.main} ag-blue`}>
      <div className={styles.gridContainer}>
        <SelectionGrid
          data={currentCompanyProducts}
          keyName="ProductId"
          columns={columns}
          isLoading={requestingCompanyProductList}
          onRefresh={onRefresh}
          rowDoubleClicked={onRowDoubleClicked}
          showAddButton={false}
        />
      </div>
    </div>
  );
};

CompanyProductList.propTypes = {
  currentCompanyProducts: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number,
      productName: PropTypes.string,
    }),
  ).isRequired,
  requestingCompanyProductList: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
};

export default CompanyProductList;
