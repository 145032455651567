import { css } from 'glamor';
import PropTypes from 'prop-types';
import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LinkContainer } from 'react-router-bootstrap';
import TooltipWrapper from '../_Common/TooltipWrapper';

const styles = {
  container: css({
    '& .nav-pills > li > a': {
      borderRadius: '0px',
    },
  }),
};

const TooltipLinkContainer = ({ tooltip, iconClass, ...other }) => (
  <LinkContainer {...other}>
    <NavItem>
      <TooltipWrapper text={tooltip} place="right" effect="solid">
        <i className={iconClass} />
      </TooltipWrapper>
    </NavItem>
  </LinkContainer>
);

TooltipLinkContainer.propTypes = {
  tooltip: PropTypes.string,
  iconClass: PropTypes.string,
};

const MoreMenu = ({ userDetails }) => (
  <div className={`${styles.navBox} bg-info`}>
    <Nav bsStyle="pills" stacked>
      {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
        <TooltipLinkContainer
          to="/dashboard"
          iconClass="fa fa-tachometer fa-lg"
          tooltip={I18n.t('menu.dashboardList')}
        />
      )}
      {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
        <TooltipLinkContainer
          to="/generalDashboard"
          iconClass="fa fa-tachometer fa-lg"
          tooltip={I18n.t('menu.generalDashboard')}
        />
      )}
      {userDetails.ActionCodes.indexOf('ViewDashboard') >= 0 && (
        <TooltipLinkContainer
          to="/safecosmeticDashboard"
          iconClass="fa fa-tachometer fa-lg"
          tooltip={I18n.t('menu.safecosmeticDashboard')}
        />
      )}
      {userDetails.ActionCodes.indexOf('ViewErrorList') >= 0 && (
        <TooltipLinkContainer
          to="/errors"
          iconClass="fa fa-exclamation-triangle fa-lg"
          tooltip={I18n.t('menu.errors')}
        />
      )}
    </Nav>
  </div>
);

function mapStateToProps({ application, user, i18n }) {
  return {
    userDetails: user.currentUser,
  };
}

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoreMenu);

MoreMenu.propTypes = {
  userDetails: PropTypes.shape({
    UserName: PropTypes.string,
  }).isRequired,
};
