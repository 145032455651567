import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, DeleteButtonRenderer } from '../../_Common/Grids';
import ButtonDropdown from '../../_Common/ButtonDropDown';

const CompanyDistributorList = props => {
  const {
    currentCompanyDistributors,
    requestingCompanyDistributorList,
    onRefresh,
    addDistributorToCompany,
    distributorsNotLinkedWithCurrentCompany,
    onRowDoubleClicked,
    onRemoveBtnClicked,
  } = props;

  const columns = [
    {
      headerName: I18n.t('distributor.country'),
      field: 'Distributor.CountryAlpha3',
      width: 30,
      sort: 'asc',
      sortedAt: 1,
    },
    {
      headerName: I18n.t('distributor.name'),
      field: 'Distributor.Name',
      editable: true,
      width: 300,
      sort: 'asc',
      sortedAt: 2,
    },
    {
      headerName: I18n.t('general.actions'),
      minWidth: 100,
      width: 100,
      suppressSizeToFit: true,
      cellRendererFramework: DeleteButtonRenderer,
      cellRendererParams: {
        onClick: onRemoveBtnClicked,
      },
    },
  ];

  return (
    <div>
      <ButtonDropdown
        options={distributorsNotLinkedWithCurrentCompany}
        labelKey="Name"
        valueKey="DistributorId"
        buttonText="Add"
        onButtonClick={addDistributorToCompany}
      />
      <SelectionGrid
        data={currentCompanyDistributors}
        columns={columns}
        isLoading={requestingCompanyDistributorList}
        onRefresh={onRefresh}
        rowDoubleClicked={onRowDoubleClicked}
        keyName="DistributorId"
        showAddButton={false}
      />
    </div>
  );
};

CompanyDistributorList.defaultProps = {
  currentCompanyDistributors: [],
};

CompanyDistributorList.propTypes = {
  distributorsNotLinkedWithCurrentCompany: PropTypes.arrayOf(
    PropTypes.shape({
      DistributorId: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  addDistributorToCompany: PropTypes.func.isRequired,
  currentCompanyDistributors: PropTypes.array.isRequired, //eslint-disable-line
  onRefresh: PropTypes.func.isRequired,
  requestingCompanyDistributorList: PropTypes.bool.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onRemoveBtnClicked: PropTypes.func.isRequired,
};

export default CompanyDistributorList;
