import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as cosAnnexeTRTypes from './cosAnnexeTRTypes';

const byId = handleActions(
  {
    [cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.cosAnnexesTR),
  },
  {},
);

const allIds = handleActions(
  {
    [cosAnnexeTRTypes.COS_ANNEXE_TR_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
