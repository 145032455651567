import { takeEvery, all, put, select, take } from 'redux-saga/effects';
import { isEqual } from 'lodash/fp';
import { change, initialize, getFormInitialValues, submit, isDirty } from 'redux-form';
import formNames from '../../../constants/formNames';
import * as phraseTRFormTypes from './phraseTRFormTypes';
import * as phraseTRFormSelectors from './phraseTRFormSelectors';
import {phraseTRActions, phraseTRTypes} from '../../phraseTR';
import { phraseSelectors } from '../../phrase';
import {referentialSelectors} from "../../referentials";

export default function* root() {
  yield all([
    takeEvery(phraseTRFormTypes.ADD_TRANSLATION, addTranslationSaga),
    // takeEvery(phraseTRFormTypes.CHANGE_TRANSLATION, changeTranslationSaga),
    takeEvery(phraseTRFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClick),
    takeEvery(phraseTRFormTypes.LOAD_FORM, loadFormSaga),
  ]);
}

function* onSaveButtonClick({ payload }) {
  const { formData } = payload;

  // if (yield select(isDirty(formNames.phrasesTR))) {
  //   yield put(submit(formNames.phrasesTR));
  // }

  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const initialFormValues = yield select(state => getFormInitialValues(formNames.phrasesTR)(state));

  const editedTranslations = [];
  formData.allCodes.forEach(code => {
    const initial = initialFormValues[code];
    const translation = formData[code];
    languages.forEach(lang => {
      const equal = isEqual(initial[lang.Alpha2], translation[lang.Alpha2]);
      if(!equal) editedTranslations.push(translation[lang.Alpha2]);
    })
  });
  if (editedTranslations.length > 0) {
    yield put(phraseTRActions.saveTranslation.request(editedTranslations));
    yield take(phraseTRTypes.PHRASE_TRANSLATION_LIST.SUCCESS);
    const phraseTranslationList = yield select(state =>
      phraseTRFormSelectors.makeGetInitialFormValue(state),
    );
    yield put(initialize(formNames.phrasesTR, phraseTranslationList));
  }
}

function* addTranslationSaga({ payload }) {
  const { phraseCode, selectedLanguage } = payload;
  const phrase = yield select(state => phraseSelectors.getPhraseByCode(state, phraseCode));

  const newTranslation = {
    PhraseCode: phraseCode,
    LanguageCode: selectedLanguage,
    Text: phrase.Text,
  };
  yield put(change(formNames.phrasesTR, `${phraseCode}.${selectedLanguage}`, newTranslation));
  // // yield put(initialize(formName, newFormValues, { keepDirty: true }));
}

// function* changeTranslationSaga({ payload }) {
//   const { phraseCode, selectedLanguage, newValue } = payload;
//
//   yield put(change(formNames.phrasesTR, `${phraseCode}.${selectedLanguage}.Text`, newValue));
//   // // yield put(initialize(formName, newFormValues, { keepDirty: true }));
// }

function* loadFormSaga({ payload }) {
  yield take(phraseTRTypes.PHRASE_TRANSLATION_LIST.SUCCESS);

  const initialFormValues = yield select(state =>
    phraseTRFormSelectors.makeGetInitialFormValue(state),
  );

  yield put(initialize(formNames.phrasesTR, initialFormValues));
}
