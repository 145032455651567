import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Col, Button, FormGroup, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import CheckBox from './_Common/CheckBox';
import Textarea from './_Common/Textarea';
import {
  labelReviewClaimWizardSelectors,
  labelReviewClaimWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewClaimWizard';

const LabelReviewClaimItem = props => {
  const isErrorForField = fieldName => R.pathOr(false, [fieldName, 'isError'])(props.errors);
  const getErrorForField = fieldName => R.pathOr(false, [fieldName, 'description'])(props.errors);

  return (
    <div>
      <Col sm={4}>
        <FormGroup validationState={isErrorForField('Claim') ? 'error' : null}>
          <Textarea text={props.Claim} fieldName="Claim" onChange={props.handleFieldEdit} />
          {isErrorForField('Claim') ? (
            <HelpBlock>{I18n.t(getErrorForField('Claim'))}</HelpBlock>
          ) : null}
        </FormGroup>
      </Col>
      <Col sm={1}>
        <CheckBox
          checked={props.IsOk}
          inline={false}
          text="Ok"
          onChange={({ target }) => props.handleFieldEdit({ IsOk: target.checked })}
        />
        <CheckBox
          checked={props.HasRemarks}
          inline={false}
          text="Rem."
          onChange={({ target }) => props.handleFieldEdit({ HasRemarks: target.checked })}
        />
      </Col>
      <Col sm={7}>
        <Col sm={5}>
          <Textarea text={props.Remarks} fieldName="Remarks" onChange={props.handleFieldEdit} />
        </Col>
        <Col sm={7}>
          <Col sm={10}>
            <Textarea
              text={props.Recommandations}
              fieldName="Recommandations"
              bsSize="small"
              onChange={props.handleFieldEdit}
            />
          </Col>
          <Col sm={1}>
            <Button onClick={props.handleRevertButtonClick} bsSize="small">
              <i className="fa fa-undo" aria-hidden="true" />
            </Button>
          </Col>
          <Col sm={1}>
            <Button onClick={props.handleDeleteButtonClick} bsSize="small" bsStyle="danger">
              <i className="fa fa-times" aria-hidden="true" />
            </Button>
          </Col>
        </Col>
      </Col>
    </div>
  );
};

LabelReviewClaimItem.defaultProps = {
  Claim: undefined,
  Remarks: undefined,
  Recommandations: undefined,
  IsOk: undefined,
  HasRemarks: undefined,
  errors: {},
};

LabelReviewClaimItem.propTypes = {
  Claim: PropTypes.string,
  Remarks: PropTypes.string,
  Recommandations: PropTypes.string,
  IsOk: PropTypes.bool,
  HasRemarks: PropTypes.bool,
  handleFieldEdit: PropTypes.func.isRequired,
  handleDeleteButtonClick: PropTypes.func.isRequired,
  handleRevertButtonClick: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

const mapStateToProps = (_, ownProps) => {
  const { labelReviewClaimId } = ownProps;
  return state => {
    const labelReviewClaim = labelReviewClaimWizardSelectors.getLabelReviewClaim(state, labelReviewClaimId);
    return {
      Claim: R.prop('Claim', labelReviewClaim),
      Remarks: R.prop('Remarks', labelReviewClaim),
      Recommandations: R.prop('Recommandations', labelReviewClaim),
      IsOk: R.prop('IsOk', labelReviewClaim),
      HasRemarks: R.prop('HasRemarks', labelReviewClaim),
      errors: labelReviewClaimWizardSelectors.getErrorsById(state)[labelReviewClaimId],
    };
  };
};

const mapDispatchToProps = (_, ownProps) => {
  const { labelReviewClaimId } = ownProps;
  return dispatch => ({
    handleFieldEdit: data => dispatch(labelReviewClaimWizardActions.handleFieldEdit(labelReviewClaimId, data)),
    handleDeleteButtonClick: () => dispatch(labelReviewClaimWizardActions.handleDeleteButtonClick(labelReviewClaimId)),
    handleRevertButtonClick: () => dispatch(labelReviewClaimWizardActions.handleRevertButtonClick(labelReviewClaimId)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewClaimItem);
