import { createRequestActions } from '../utils';
import * as draftLabelTypes from './draftLabelTypes';

export const fetchDraftLabels = createRequestActions(
  draftLabelTypes.FETCH_DRAFT_LABELS,
  ({ draftLabelId: id, ...query } = {}) => ({
    ...(id && { id }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);

export const saveDraftLabel = createRequestActions(draftLabelTypes.SAVE_DRAFT_LABEL);
export const deleteDraftLabel = createRequestActions(
  draftLabelTypes.DELETE_DRAFT_LABEL,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
