import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {Modal, Button, ButtonToolbar, Radio} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../../_Common/BaseLoader';
import DocumentList from './DocumentList';
import Select from 'react-select';
import { Col } from 'react-bootstrap';
import regionCode from '../../../../constants/regionCode';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
    textAlign: 'left'
  }),
  errorContainer: css({
    display: 'flex',
    justifyContent: 'center',
    color: 'red',
  }),
};

const isNotEmpty = R.complement(R.isEmpty);

class PrintDocumentsModal extends Component {
  state = {
    selectedDoc: '',
    selectedFormat: 'pdf',
    selectedRegion: regionCode.EU,
    selectedCountry: undefined,
    includeInci: true,
    compositionInInci: false,
    language: 'en',
    errorCpsr: false
  };

  isDocumentSelected = () => isNotEmpty(this.state.selectedDoc);

  isRegionCountrySelected = () => {
    const { selectedDoc, selectedRegion, selectedCountry, includeInci } = this.state;
    switch(selectedDoc){
      case 'preval':
      case 'prevalNew':
      case 'cpsra':
      case 'cpsraNew':
      case 'cpsraconfidential':
      case 'cpsraconfidentialnew':
      case 'cpsraByShade':
      case 'cpsrAByShadeConfidential':
      case 'cpsrb':
      case 'cpsrbNew':
      case 'cpsrbconfidential':
      case 'cpsrbByShade':
      case 'cpsrbByShadeConfidential':
        return !(!selectedRegion || (selectedRegion === regionCode.ASEAN && !selectedCountry));
      default:
        return true;
    }
  }

  isCpsrSelected = () => {
    const { selectedDoc } = this.state;
    switch(selectedDoc){
      case 'preval':
      case 'prevalNew':
      case 'cpsra':
      case 'cpsraNew':
      case 'cpsraconfidential':
      case 'cpsraconfidentialNew':
      case 'cpsraByShade':
      case 'cpsrAByShadeConfidential':
      case 'cpsrb':
      case 'cpsrbNew':
      case 'cpsrbconfidential':
      case 'cpsrbByShade':
      case 'cpsrbByShadeConfidential':
      case 'cpsrbWithAnnexes':
      case 'cpsrbWithAnnexesNew':
        return true;
      default:
        return false;
    }
  }

  isNotificationAvailable = () => {
    const notifications = this.props.notifications.filter(elem => elem.RegionCode == this.state.selectedRegion && (elem.CountryCode == this.state.selectedCountry))
    const notification = notifications.sort((a, b) => (b.NotificationId - a.NotificationId)).shift(); // sort and get first
    return notification ? notification.ResponsibleCompany : null
  };

  handleOnSelectDoc = selectedDoc => {
    this.setState({ selectedDoc });
  };

  handleOnSelectFormat = keyValue => {
    if (keyValue) {
      this.setState({ selectedFormat: keyValue.value});
    }else{
      this.setState({ selectedFormat: undefined});
    }
  };

  handleOnSelectRegion = keyValue => {
    if (keyValue) {
      const ref = this;
      const notification = this.props.notifications.find(elem => elem.RegionCode === keyValue.Key && (!elem.CountryCode || elem.CountryCode === ref.state.selectedCountry))
      this.setState({ selectedRegion: keyValue.Key, selectedCountry: undefined, errorCpsr: !notification});
    }else{
      this.setState({ selectedRegion: undefined, selectedCountry: undefined, errorCpsr: false});
    }
  };

  handleOnSelectCountry = keyValue => {
    if (keyValue) {
      const notification = this.props.notifications.find(elem => elem.RegionCode === this.state.selectedRegion && (!elem.CountryCode || elem.CountryCode === keyValue.Key))
      this.setState({ selectedCountry: keyValue.Key, errorCpsr: !notification});
    }else{
      this.setState({ selectedCountry: undefined, errorCpsr: false});
    }
  };

  handleOnIncludeInciChanged = value => {
    this.setState({ includeInci: value })
  };

  handleOnCompositionInInciChanged = value => {
    this.setState({ compositionInInci: value })
  };

  handleOnLanguage = keyValue => {
    if (keyValue) {
      this.setState({ language: keyValue.key});
    }
  };

  handleOnPrint = () => {
    const { selectedRegion, selectedCountry, includeInci, compositionInInci } = this.state;
    this.props.onPrintButtonClick(this.state.selectedDoc, this.state.selectedFormat, selectedRegion, selectedCountry, this.state.language, includeInci, compositionInInci);
  };
  render() {
    const { title, closeModal, isDownloadingDocument, productId } = this.props;
    const { selectedRegion, selectedCountry, errorCpsr, includeInci, compositionInInci } = this.state;
    const reportFormats = [ { key: "pdf", value: "pdf" }, { key:"docx", value: "docx" }];
    const languages = [ { key: "en", value: "EN" }, { key:"fr", value: "FR" }];

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BaseLoader isLoading={isDownloadingDocument}>
            <DocumentList
              onChange={this.handleOnSelectDoc}
              onRegionSelected={this.handleOnSelectRegion}
              onIncludeInciChanged={this.handleOnIncludeInciChanged}
              onCompositionInInciChanged={this.handleOnCompositionInInciChanged}
              selectedRegion={selectedRegion}
              onCountrySelected={this.handleOnSelectCountry}
              selectedCountry={selectedCountry}
              includeInci={includeInci}
              compositionInInci={compositionInInci}
              productId={productId}
              message={(this.isCpsrSelected() && !this.isNotificationAvailable()) && <Translate value={`report.errorNoResponsible`} />}
            />
            <div className={styles.errorContainer}>{(this.isCpsrSelected() && errorCpsr) && <Translate value={`report.errorNoResponsible`} />}</div>
          </BaseLoader>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <Col sm={3}>
              <Select
                options={languages}
                labelKey="value"
                valueKey="key"
                value={this.state.language}
                onChange={this.handleOnLanguage}
                clearable="false"
              />
            </Col>
            <Col sm={3}>
              <Select
                options={reportFormats}
                labelKey="value"
                valueKey="key"
                value={this.state.selectedFormat}
                onChange={this.handleOnSelectFormat}
                clearable={false}
              />
            </Col>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times" />
                &nbsp;
                <Translate value="general.close" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="button"
                onClick={this.handleOnPrint}
                disabled={isDownloadingDocument || !this.isDocumentSelected() || !this.isRegionCountrySelected()
                  || (this.isCpsrSelected() && !this.isNotificationAvailable())}
              >
                <Translate value="general.print" />
                &nbsp;
                <i className="fa fa-print" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

PrintDocumentsModal.defaultProps = {};

PrintDocumentsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onPrintButtonClick: PropTypes.func.isRequired,
  isDownloadingDocument: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
  productDistributor: PropTypes.array,
};

export default PrintDocumentsModal;
