import { createSelector } from 'reselect';
import * as formulaReviewSelectors from '../formulaReview/formulaReviewSelectors';

export const getById = ({ complianceReview }) => complianceReview.byId;
export const getIds = ({ complianceReview }) => complianceReview.list.allIds;
export const getComplianceReview = (state, id) => getById(state)[id];
export const getComplianceReviewList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

// export const getComplianceReviewListForFormulaReview = createSelector(
//   [formulaReviewSelectors.getFormulaReview, getComplianceReviewList],
//   (formulaReview, complianceReviewList) =>
//     complianceReviewList.filter(cr => cr.FormulaReviewId === formulaReview.FormulaReviewId),
// );

export const getComplianceReviewListForFormulaReview = createSelector(
  [formulaReviewSelectors.getFormulaReview, getById],
  (formulaReview, complianceReviewById) =>
    formulaReview.ComplianceReviews.map(complianceReviewId => complianceReviewById[complianceReviewId]),
);
