import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, PercentageCellRenderer, LinkCellRenderer, TooltipRenderer } from '../../_Common/Grids';
import CustomFilter from './CustomFilter.jsx';

const InciList = props => {
  const { currentProductInciList, currentProductCategories, isLoadingInciListTab, getCosAnneTypeByCode, getProductInciList } = props;

  const categoryCodes = currentProductCategories.map(cat =>  { return cat.CategoryCode});
  const getRestrictions2 = function (data) {
    if (data !== undefined) {
      const txt = `${
        data.UsedIn
          ? `- ${I18n.t('cosAnnexe.UsedIn')}: ${R.propOr('', 'Description', getCosAnneTypeByCode(data.UsedIn))}: ${
            data.MaxUse
          } %`
          : ''
      }<br/>${
        data.NotUsedIn
          ? `- ${I18n.t('cosAnnexe.NotUsedIn')}: ${R.propOr(
            '',
            'Description',
            getCosAnneTypeByCode(data.NotUsedIn),
          )}: ${data.MaxUse} %`
          : ''
      }${
        data.OtherRestrictions
          ? `- ${I18n.t('cosAnnexe.OtherRestrictions')}: ${R.propOr('', 'OtherRestrictions', data)}: ${data.MaxUse} %`
          : ''
      }`;
      return txt;
    }
    return '';
  };

  const columns = [
    {
      headerName: I18n.t('substance.inciName'),
      field: 'Substance.InciName',
      filterFramework: CustomFilter,
      cellRendererFramework: LinkCellRenderer, 
      cellRendererParams: {
        textKey: 'InciName',
        linkKey: 'SubstanceUrl',
        downloadable: false
      },
    },
    {
      headerName: I18n.t('substance.casNr'),
      field: 'Substance.CasNr',
      filterFramework: CustomFilter,
    },
    {
      headerName: I18n.t('general.percentageInProduct'),
      field: 'PercentageInProduct',
      cellRenderer: PercentageCellRenderer,
      sort: 'desc',
      sortedAt: 2,
    },
    {
      headerName: I18n.t('general.percentageInProductForInciCalculation'),
      field: 'PercentageInProductForInciCalculation',
      cellRenderer: PercentageCellRenderer,
    },
    {
      headerName: I18n.t('substance.type'),
      sort: 'asc',
      sortedAt: 1,
      valueGetter: ({ data }) => (data.SubstanceType ? I18n.t(`substanceTypes.${data.SubstanceType}`) : ''),
    },
    {
      headerName: I18n.t('substance.cosAnnexes'),
      field: 'Code',
    },
    {
      headerName: I18n.t('substance.MaxUse'),
      // cellRenderer: ({ data }) =>
      //   (data.MaxUse ? data.MaxUse + data.MaxUseUom : ''),
      valueGetter: ({ data }) => (data.MaxUse ? data.MaxUse + data.MaxUseUom : ''),
      },
    {
      headerName: I18n.t('substance.CalcCondition'),
      autoHeight: true,
      cellRenderer: ({ data }) =>
        R.compose(
          // result => `<TooltipRenderer value="<p style='white-space: normal !important'>${result}</p>" />`,
          result => `<p style="white-space: normal !important">${result}</p>`,
          R.reduce((acc, val) => `${acc} ${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['CalculationCondition']),
        )(data),
      valueGetter: ({ data }) =>
        R.compose(
          // result => `<TooltipRenderer value="<p style='white-space: normal !important'>${result}</p>" />`,
          result => `${result}`,
          R.reduce((acc, val) => `${acc} ${val}` + String.fromCharCode(10), ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['CalculationCondition']),
        )(data),
    },
    {
      headerName: I18n.t('substance.Restriction'),
      autoHeight: true,
      cellRenderer: ({ data }) =>
        `<p style="white-space: normal !important">${
          data.UsedIn
            ? `- ${I18n.t('cosAnnexe.UsedIn')}: ${R.propOr('', 'Description', getCosAnneTypeByCode(data.UsedIn))}: ${
              data.MaxUse
            } %`
            : ''
        }<br/>${
          data.NotUsedIn
            ? `- ${I18n.t('cosAnnexe.NotUsedIn')}: ${R.propOr(
              '',
              'Description',
              getCosAnneTypeByCode(data.NotUsedIn),
            )}: ${data.MaxUse} %`
            : ''
        }${
          data.OtherRestrictions
            ? `- ${I18n.t('cosAnnexe.OtherRestrictions')}: ${R.propOr('', 'OtherRestrictions', data)}: ${data.MaxUse} %`
            : ''
        }</p>`,
      valueGetter: ({ data }) =>
        `${
          data.UsedIn
            ? `- ${I18n.t('cosAnnexe.UsedIn')}: ${R.propOr('', 'Description', getCosAnneTypeByCode(data.UsedIn))}: ${
              data.MaxUse
            } %`
            : ''
        }`+ String.fromCharCode(10) + `${
          data.NotUsedIn
            ? `- ${I18n.t('cosAnnexe.NotUsedIn')}: ${R.propOr(
              '',
              'Description',
              getCosAnneTypeByCode(data.NotUsedIn),
            )}: ${data.MaxUse} %`
            : ''
        }${
          data.OtherRestrictions
            ? `- ${I18n.t('cosAnnexe.OtherRestrictions')}: ${R.propOr('', 'OtherRestrictions', data)}: ${data.MaxUse} %`
            : ''
        }`,
    },
    {
      headerName: I18n.t('substance.warning'),
      autoHeight: true,
      cellRenderer: ({ data }) =>
        R.compose(
          result => `<p style="white-space: normal !important">${result}</p>`,
          R.reduce((acc, val) => `${acc} ${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['Warning']),
        )(data),
      valueGetter: ({ data }) =>
        R.compose(
          result => `${result}`,
          R.reduce((acc, val) => `${acc} ${val}` + String.fromCharCode(10), ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['Warning']),
        )(data),
    },
    {
      headerName: I18n.t('substance.useCondition'),
      autoHeight: true,
      cellRenderer: ({ data }) =>
        R.compose(
          result => `<p style="white-space: normal !important">${result}</p>`,
          R.reduce((acc, val) => `${acc} ${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['UseConditionWording']),
        )(data),
        valueGetter: ({ data }) =>
        R.compose(
          result => `${result}`,
          R.reduce((acc, val) => `${acc} ${val}` + String.fromCharCode(10), ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.props(['UseConditionWording']),
        )(data),    },
    {
      headerName: I18n.t('substance.summary'),
      field: 'Substance.Summary',
      cellRendererFramework: TooltipRenderer
    },
  ];

  const getNodeChildDetails = rowItem => {
    // if (rowItem.Type === 'rawMat' || rowItem.Type === 'complexSubstance') {
    if (rowItem.CosAnnexes && rowItem.CosAnnexes.length > 0) {
      // rowItem.CosAnnexes.filter(cos => rowItem.PercentageInProduct > cos.MaxUse)
      const productUseTypes = rowItem.CosAnnexes; //.filter(cos => rowItem.PercentageInProduct > cos.MaxUse);
                                                          // && (cos.UsedIn == undefined || cos.UsedIn == '01 ' || categoryCodes.includes(cos.UsedIn)));
      return {
        group: true,
        expanded: true,
        children: productUseTypes, // rowItem.CosAnnexes,
        key: rowItem.InciName,
      };
    }
    return null;
  };


  return (
    <div>
      <SelectionGrid
        data={currentProductInciList}
        keyName="SubstanceId"
        columns={columns}
        getNodeChildDetails={getNodeChildDetails}
        getRowHeight={({ data }) => {
          const ln =
            25 *
            R.max(
              Math.floor(
                getRestrictions2(data).length / 30,
              ) + 1,
              Math.floor((data.CalculationCondition ? data.CalculationCondition.length : 0) / 30) + 1,
              1,
            );
          return ln;
        }}
        isLoading={isLoadingInciListTab}
        deltaRowDataMode={false}
        onRefresh={getProductInciList}
        // showAddButton={false}
        showExpandCollapseButtons
      />
    </div>
  );
};
InciList.defaultProps = {
  currentProductInciList: [],
  currentProductCategories: [],
};

InciList.propTypes = {
  getCosAnneTypeByCode: PropTypes.func.isRequired,
  currentProductInciList: PropTypes.array, //eslint-disable-line
  currentProductCategories: PropTypes.array,
  isLoadingInciListTab: PropTypes.bool.isRequired,
  getProductInciList: PropTypes.func.isRequired,
};

export default InciList;
