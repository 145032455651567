import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import { isNullOrUndef } from '../../utils/utils';

const detailsFormValueSelector = formValueSelector('taskTypeForm');

export const getById = ({ taskType }) => taskType.byId;
export const getIds = ({ taskType }) => taskType.list.allIds;
export const getTaskType = (state, id) => getById(state)[id];
export const getTaskTypeById = id => state => getById(state)[id];
export const getTaskTypeList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export function taskTypeTitle(state) {
  const code = detailsFormValueSelector(state, 'Name') || '';
  return `${code}`;
}

export function isNew(state) {
  const id = detailsFormValueSelector(state, 'taskTypeId');
  return id <= 0 || isNullOrUndef(id);
}
