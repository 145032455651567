import PropTypes from 'prop-types';
import React from 'react';
import {Form, Row, Col, Grid, FormGroup, ControlLabel, Button} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import formNames from '../../constants/formNames';
import BaseLoader from '../_Common/BaseLoader';
import SubstanceStatus from './SubstanceStatus';
import { InputField, DateField, CheckBoxField, CanvasField } from '../_Common/Forms';
import SubstanceDetailHeader from './SubstanceDetailHeader';
import CollapsePanelLink from '../_Common/CollapsePanelLink';
import { DirectiveStatusTypesDropdown } from '../_Common/Dropdowns';
import { requiredField, maxLength32, maxLength50, maxLength256 } from '../../utils/fieldValidation';
import { css } from 'glamor';

const stylesDescription = css({
  '& textarea.form-control.input-sm': {
    height: '138px',
  },
});

const SubstanceForm = props => {
  const {
    handleSubmit,
    onSubmit,
    isLoadingDetails,
    showDetails,
    onShowDetails,
    invalid,
    substanceId,
    isSusbstanceEditable,
    saveSubstanceStructure,
    initialValues,
    onTranslateClick,
  } = props;

  const structure = initialValues.Structure;

  // console.log('initialValues', initialValues);

  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubstanceDetailHeader>
          <Grid fluid>
            <Row>
              <Col sm={2}>
                <Field
                  name="Code"
                  label={`${I18n.t('substance.code')} *`}
                  component={InputField}
                  validate={[requiredField, maxLength32]}
                  readOnly={!isSusbstanceEditable}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CasNr"
                  label={`${I18n.t('substance.casNr')}`}
                  component={InputField}
                  validate={maxLength50}
                  readOnly={!isSusbstanceEditable}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="EcNr"
                  label={`${I18n.t('substance.ecNr')}`}
                  component={InputField}
                  validate={maxLength50}
                  readOnly={!isSusbstanceEditable}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="CreatedOn"
                  label={I18n.t('general.createdOn')}
                  component={DateField}
                  labelColSize={2}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={2}>
                <Field name="CreatedBy" label={I18n.t('general.createdBy')} component={InputField} readOnly />
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <ControlLabel>{I18n.t('substance.Status')}</ControlLabel>
                  <SubstanceStatus substanceId={substanceId} />
                </FormGroup>
              </Col>
              <Col sm={1}>
                <Button bsSize="small" onClick={onTranslateClick}>
                  {I18n.t('product.translateButton')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Field
                  name="InciName"
                  label={`${I18n.t('substance.inciName')} * 1`}
                  component={InputField}
                  validate={[requiredField, maxLength256]}
                  readOnly={!isSusbstanceEditable}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="IupacName"
                  label={`${I18n.t('substance.iupacName')}`}
                  component={InputField}
                  readOnly={!isSusbstanceEditable}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="ValidatedOn"
                  label={I18n.t('rawMat.validatedOn')}
                  component={DateField}
                  labelColSize={2}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={2}>
                <Field name="ValidatedBy" label={I18n.t('rawMat.validatedBy')} component={InputField} readOnly />
              </Col>
            </Row>
            <CollapsePanelLink
              defaultCollapsed={!showDetails}
              onExpandPanel={onShowDetails}
              disabled={invalid && showDetails}
            >
              <div>
                <Row>
                  <Col sm={3}>
                    <Field
                      name="InciNameUs"
                      label={I18n.t('substance.inciNameUs')}
                      component={InputField}
                      readOnly={!isSusbstanceEditable}
                    />
                    <Field
                      name="InciNameCanada"
                      label={I18n.t('substance.inciNameCanada')}
                      component={InputField}
                      readOnly={!isSusbstanceEditable}
                    />
                    <Field
                      name="InciNameCombi"
                      label={I18n.t('substance.inciNameCombi')}
                      component={InputField}
                      readOnly={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      name="InnName"
                      label={I18n.t('substance.innName')}
                      component={InputField}
                      readOnly={!isSusbstanceEditable}
                    />
                    <Field
                      name="CosDirective"
                      label={I18n.t('substance.CosDirective')}
                      component={InputField}
                      readOnly={!isSusbstanceEditable}
                    />
                    <Field
                      name="DirectiveStatusCode"
                      label={`${I18n.t('substance.DirectiveStatusCode')} *`}
                      component={DirectiveStatusTypesDropdown}
                      validate={requiredField}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={3} className={stylesDescription.toString()}>
                    <Field
                      name="Description"
                      label={I18n.t('substance.Description')}
                      component={InputField}
                      componentClass="textarea"
                      readOnly={!isSusbstanceEditable}
                      validate={maxLength256}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="Structure"
                      component={CanvasField}
                      label={I18n.t('substanceToxProfile.structure')}
                      placeholder={I18n.t('substanceToxProfile.structurePasteZone')}
                      onSave={saveSubstanceStructure}
                      initialImage={structure}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Field
                      name="IsPerfumeAllergen"
                      label={I18n.t('substance.isAllergenInPerfume')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="IsColourSubstance"
                      label={I18n.t('substance.isColourSubstance')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="IsImpurity"
                      label={I18n.t('substance.isImpurity')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="WithAllergens"
                      label={I18n.t('substance.WithAllergens')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="VOC"
                      label={I18n.t('substance.voc')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  {/* <Col sm={2}>
                    <Field
                      name="ClpThreshold"
                      label={I18n.t('substance.clpThreshold')}
                      component={InputField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col sm={2}>
                    <Field
                      name="mineralOrigin"
                      label={I18n.t('substance.mineralOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="animalOrigin"
                      label={I18n.t('substance.animalOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="vegetalOrigin"
                      label={I18n.t('substance.vegetalOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="syntheticOrigin"
                      label={I18n.t('substance.syntheticOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="microbiologicalOrigin"
                      label={I18n.t('substance.microbiologicalOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                  <Col sm={2}>
                    <Field
                      name="otherOrigin"
                      label={I18n.t('substance.otherOrigin')}
                      component={CheckBoxField}
                      formAlignment={false}
                      disabled={!isSusbstanceEditable}
                    />
                  </Col>
                </Row>
              </div>
            </CollapsePanelLink>
          </Grid>
        </SubstanceDetailHeader>
      </Form>
    </BaseLoader>
  );
};

SubstanceForm.defaultProps = {
  isLoadingDetails: false,
};

SubstanceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoadingDetails: PropTypes.bool,
  showDetails: PropTypes.bool.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isSusbstanceEditable: PropTypes.bool.isRequired,
  substanceId: PropTypes.number.isRequired,
};

const validate = values => {
  const errors = {};
  if (!values.CosOtherLimitation) {
    errors.CosOtherLimitation = 'required';
  }

  return errors;
};

export default reduxForm({
  form: formNames.substance,
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(SubstanceForm);
