import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { orderActionSelectors, orderActionTypes, orderActionActions } from '../../../../redux/orderAction';
import { applicationSelectors } from '../../../../redux/application';
import { DateCellRenderer, SelectionGrid } from '../../Grids';
import dateComparator from '../../../../utils/dateComparator';
import { actionSelectors } from '../../../../redux/action';

const OrderActionsList = props => {
  const { actionList, orderActionList, isLoading, height, getOrderActionList } = props;

  const getActionByCode = actionCode => actionList.find(action => action.Code === actionCode);

  const getActionValue = ({ colDef, data }) =>
    R.compose(R.propOr('', colDef.field), getActionByCode, R.propOr('', 'ActionCode'))(data);

  const columns = [
    {
      headerName: I18n.t('productAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('productAction.code'),
      field: 'ActionCode',
      width: 40,
    },
    {
      headerName: I18n.t('productAction.description'),
      field: 'Description',
      valueGetter: getActionValue,
      width: 150,
    },
    {
      headerName: I18n.t('productAction.comment'),
      field: 'Comment',
    },
    {
      headerName: I18n.t('productAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  return (
    <SelectionGrid
      data={orderActionList}
      keyName="OrderActionId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getOrderActionList}
      showAddButton={false}
      height={height}
    />
  );
};

const mapStateToProps = (state, { orderId }) => {
  const getOrderActions = orderActionSelectors.makeGetOrderActionsForOrder(orderId);
  return {
    orderActionList: getOrderActions(state),
    actionList: actionSelectors.getActionList(state),
    isLoading: applicationSelectors.isLoading(state, orderActionTypes.FETCH_ORDER_ACTIONS.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  getOrderActionList: () => dispatch(orderActionActions.fetchOrderActions.request({ orderId })),
});

OrderActionsList.defaultProps = {
  height: '',
};

OrderActionsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  orderActionList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  getOrderActionList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderActionsList);
