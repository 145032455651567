import { takeLatest, put, all, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga } from '../sagaUtils';
import { productActionListSchema } from '../schema';
import { modalActions, modalTypes } from '../modal';
import taskApi from '../../api/taskApi';
import * as taskActionTypes from './taskActionTypes';
import * as taskActionActions from './taskActionActions';
import { taskActionListSchema } from './taskActionSchemas';

export default function* root() {
  yield all([
    takeLatest(taskActionTypes.TASK_ACTION_LIST.REQUEST, getTaskActionsSaga),
    takeLatest(taskActionTypes.SHOW_TASK_ACTIONS_HISTORY, showTaskActionsHistorySaga),
    takeLatest(taskActionTypes.SAVE_TASK_ACTION.REQUEST, saveTaskActionSaga),
  ]);
}

function* getTaskActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    taskActionActions.listTaskActions,
    taskApi.getTaskActionList,
    taskActionListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* showTaskActionsHistorySaga({ payload }) {
  // const product = yield select(productSelectors.getCurrentProduct);
  yield put(
    modalActions.showModal({
      modalType: modalTypes.TASK_ACTIONS_HISTORY,
      modalProps: {
        ...payload,
        title: `Task history`,
        modalWidth: '1200px',
      },
    }),
  );
}

function* saveTaskActionSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(taskActionActions.saveTaskAction, taskApi.saveTaskAction, payload);
  const { TaskId } = payload;
  yield put(taskActionActions.listTaskActions.request(TaskId));
  yield put(applicationActions.unsetLoading(type));
}
