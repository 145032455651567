import PropTypes from 'prop-types';
import React from 'react';
import DeleteButton from './DeleteButton';

const DeleteButtonRenderer = props => {
  const { node, data, onClick, disabled } = props;
  return (
    <div style={{ textAlign: 'center', widht: '100%' }}>
      {node.level === 0 && <DeleteButton onClick={() => onClick(data)} disabled={disabled} />}
    </div>
  );
};

DeleteButtonRenderer.defaultProps = {
  disabled: false,
};

DeleteButtonRenderer.propTypes = {
  node: PropTypes.shape({
    level: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  data: PropTypes.object.isRequired, //eslint-disable-line
  onClick: PropTypes.func.isRequired,
};

export default DeleteButtonRenderer;
