import { get, post, del } from './apiUtils';

const defaultUrl = '/ImpactAssessments/';
const candidateProductsUrl = '/Calculate'; //'/CandidateProducts/';
const valuesUrl = '/Values/';
const thresholdsUrl = '/Thresholds/';
const productsUrl = '/Products/';

export default {
  generateImpactAssessmentCandidateProducts: impactAssessmentId =>
    get(`${defaultUrl}${impactAssessmentId}${candidateProductsUrl}`),
  calculateImpactAssessmentValue: impactAssessmentId => post(`${defaultUrl}${impactAssessmentId}${valuesUrl}`),
  getImpactAssessmentValueList: impactAssessmentId => get(`${defaultUrl}${impactAssessmentId}${valuesUrl}`),
  getImpactAssessmentProductList: impactAssessmentId => get(`${defaultUrl}${impactAssessmentId}${productsUrl}`),
  getImpactAssessmentThresholdList: impactAssessmentId => get(`${defaultUrl}${impactAssessmentId}${thresholdsUrl}`),
  getImpactAssessmentList: () => get(`${defaultUrl}`),
  getImpactAssessmentListByProduct: productId => get(`${defaultUrl}ByProduct/${productId}`),
  getImpactAssessment: impactAssessmentId => get(`${defaultUrl}${impactAssessmentId}`),
  saveImpactAssessment: data => post(`${defaultUrl}`, data),
  saveImpactAssessmentThreshold: data => post(`${defaultUrl}-1${thresholdsUrl}`, data),
  deleteImpactAssessmentThreshold: payload => del(`${defaultUrl}${payload.impactAssessmentId}${thresholdsUrl}${payload.id}`),
  updateStatus: data => post(`${defaultUrl}/status`, data),
  confirmImpactAssessmentProduct: data => {
    post(`${defaultUrl}product/${data}/confirm`);
  },
  ignoreImpactAssessmentProduct: data => post(`${defaultUrl}product/${data}/ignore`),
  reviewImpactAssessmentProduct: data => post(`${defaultUrl}product/${data}/review`),
};
