import * as substanceToxProfileSelectors from './substanceToxProfileSelectors';
import * as substanceToxProfileActions from './substanceToxProfileActions';
import * as substanceToxProfileTypes from './substanceToxProfileTypes';
import sagas from './substanceToxProfileSagas';
import reducer from './substanceToxProfileReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceToxProfileSelectors, substanceToxProfileActions, substanceToxProfileTypes };
export default reducer;
