import * as controlCenterTypes from './controlCenterTypes';
import * as controlCenterActions from './controlCenterActions';
import sagas, * as controlCenterSagas from './controlCenterSagas';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { controlCenterTypes, controlCenterActions, controlCenterSagas };
// export default reducer;
