import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Navbar } from 'react-bootstrap';
import { css } from 'glamor';

const styles = {
  footer: css({
    '& .navbar': {
      minHeight: '30px',
    },
    '& .navbar-text': {
      margin: '5px',
    },
  }),
};

const Footer = ({ applicationVersion }) => (
  <div className={styles.footer}>
    <Navbar fluid fixedBottom>
      {/* <Navbar.Text>Powered by Pulsar Consulting - &copy; {moment().get('year')}</Navbar.Text> */}
      <Navbar.Text pullRight>
        v {applicationVersion} {process.env.REACT_APP_ENV}
      </Navbar.Text>
    </Navbar>
  </div>
);

Footer.propTypes = {
  applicationVersion: PropTypes.string,
};

export default Footer;
