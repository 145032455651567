import { schema } from 'normalizr';
import * as actionSchemas from '../action/actionSchemas';

export const substanceActionSchema = new schema.Entity(
  'substanceActions',
  { Action: actionSchemas.actionSchema },
  { idAttribute: 'SubstanceActionId' },
);

export const substanceActionListSchema = [substanceActionSchema];
