import { createRequestTypes } from '../utils';

export const ORDER_LIST = createRequestTypes('order/ORDER_LIST');
export const ORDER_PRODUCT_LIST = createRequestTypes('order/ORDER_PRODUCT_LIST');
export const ORDER = createRequestTypes('order/ORDER');
export const ORDER_MISSING_DOCUMENT_LIST = createRequestTypes('order/ORDER_MISSING_DOCUMENT_LIST');
export const ORDER_PROCESS_INFO = createRequestTypes('order/ORDER_PROCESS_INFO');
export const SAVE_ORDER = createRequestTypes('order/SAVE_ORDER');
export const DELETE_ORDER = createRequestTypes('order/DELETE_ORDER');
export const SAVE_ORDER_PROCESS_INFO = createRequestTypes('order/SAVE_ORDER_PROCESS_INFO');
export const SAVE_ORDER_PRODUCTS = createRequestTypes('order/SVAE_ORDER_PRODUCTS');
export const PRODUCTS_OWNED_BY_ORDER_COMPANY = createRequestTypes('order/PRODUCTS_OWNED_BY_ORDER_COMPANY');
export const ADD_PRODUCT_TO_CURRENT_ORDER = createRequestTypes('order/ADD_PRODUCT_TO_CURRENT_ORDER');
export const SAVE_PRODUCT_AND_ADD_TO_CURRENT_ORDER = createRequestTypes('order/SAVE_PRODUCT_AND_ADD_TO_CURRENT_ORDER');
export const REMOVE_PRODUCT_FROM_ORDER = 'order/REMOVE_PRODUCT_FROM_ORDER';
export const NEW_ORDER = 'order/NEW_ORDER';
export const SUBMIT_ORDER_FORM = 'order/SUBMIT_ORDER_FORM';
export const UPDATE_ORDER_STATUS = createRequestTypes('order/UPDATE_ORDER_STATUS');
export const INITIALIZE_POLLING = createRequestTypes('order/INITIALIZE_POLLING');
export const POLL = createRequestTypes('order/POLL');
export const ORDER_IMPORT_LIST = createRequestTypes('order/ORDER_IMPORT_LIST');
