import * as productClpSelectors from './productClpSelectors';
import * as productClpActions from './productClpActions';
import * as productClpTypes from './productClpTypes';
import sagas from './productClpSagas';
import reducer from './productClpReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productClpSelectors, productClpActions, productClpTypes };
export default reducer;
