import * as substanceDocumentSelectors from './substanceDocumentSelectors';
import * as substanceDocumentActions from './substanceDocumentActions';
import * as substanceDocumentTypes from './substanceDocumentTypes';
import sagas from './substanceDocumentSagas';
import reducer from './substanceDocumentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceDocumentSelectors, substanceDocumentActions, substanceDocumentTypes };
export default reducer;
