import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { get } from 'lodash';

import { SelectionGrid, LinkCellRenderer, DeleteButtonRenderer, DateCellRenderer } from '../../_Common/Grids';

const actionButtonsRenderer = props => {
  const { data, textKey, onDownload } = props;

  return (
    <div>
      <ButtonToolbar>
        <Button bsStyle="primary" bsSize="xsmall" type="button" onClick={() => onDownload(data)}>
          <i className="fa fa-download" aria-hidden="true" />
          &nbsp;&nbsp;&nbsp;
          {get(data, textKey)}
          {/* <Translate value="formulaReview.print" /> */}
        </Button>
      </ButtonToolbar>
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onDownload: PropTypes.func.isRequired,
};

const CompanyDocumentList = props => {
  const {
    companyDocuments,
    isLoadingCompanyDocumentList,
    getCompanyDocuments,
    saveCompanyDocument,
    deleteCompanyDocument,
  } = props;

  const handleCellValueChange = ({ data }) => saveCompanyDocument(data.Document);
  const onDeleteClick = ({ CompanyDocumentId }) => deleteCompanyDocument(CompanyDocumentId);
  const columns = [
    {
      headerName: I18n.t('document.name'),
      field: 'Document.FileName',
      cellRendererFramework: LinkCellRenderer,
      cellRendererParams: {
        textKey: 'Document.FileName',
        linkKey: 'Document.FileLink',
      },
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('document.description'),
      field: 'Document.Description',
      editable: true,
      onCellValueChanged: handleCellValueChange,
    },
    {
      headerName: I18n.t('document.createdBy'),
      field: 'CreatedBy',
      width: 50,
    },
    {
      headerName: I18n.t('document.createdOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      sort: 'desc',
      width: 100,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      width: 100,
      minWidth: 100,
      suppressSizeToFit: true,
      cellRendererParams: {
        onClick: onDeleteClick,
      },
    },
  ];

  return (
    <SelectionGrid
      data={companyDocuments}
      keyName="DocumentId"
      columns={columns}
      isLoading={isLoadingCompanyDocumentList}
      onRefresh={getCompanyDocuments}
      showFilterRow
      stopEditingWhenGridLosesFocus
      showAddButton={false}
    />
  );
};

CompanyDocumentList.defaultProps = {
  companyDocuments: [],
};

CompanyDocumentList.propTypes = {
  companyDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      CompanyDocumentId: PropTypes.number.isRequired,
      Document: PropTypes.shape({
        DocumentId: PropTypes.number.isRequired,
        FileName: PropTypes.string.isRequired,
        FileLink: PropTypes.string.isRequired,
        Description: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  getCompanyDocuments: PropTypes.func.isRequired,
  isLoadingCompanyDocumentList: PropTypes.bool.isRequired,
  saveCompanyDocument: PropTypes.func.isRequired,
  deleteCompanyDocument: PropTypes.func.isRequired,
  downloadCompanyDocument: PropTypes.func.isRequired,
};

export default CompanyDocumentList;
