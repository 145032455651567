import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Dashboards from './Dashboards';
import { dashboardActions, dashboardTypes, dashboardSelectors } from '../../redux/dashboard';
import { applicationSelectors } from '../../redux/application';

class DashboardsPage extends Component {
  static propTypes = {
    getDashboardList: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  componentDidMount() {
    this.props.getDashboardList();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <Dashboards selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  dashboardList: dashboardSelectors.getDashboardList(state),
  isLoadingDetails: applicationSelectors.isLoading(
      state,
      dashboardTypes.DASHBOARD_LIST.REQUEST,
      dashboardTypes.DASHBOARD.REQUEST,
    ),
  requestingDashboardList: applicationSelectors.isLoading(state, dashboardTypes.DASHBOARD_LIST.REQUEST),
});

export default connect(mapStateToProps, {
  getDashboardList: dashboardActions.listDashboards.request,
  onDashboardSelected: id => push(`/dashboard/${id}`),
})(DashboardsPage);
