import {put, all, select, takeLatest, call, takeEvery} from 'redux-saga/effects';
import {change} from 'redux-form';
import * as R from 'ramda';
import {createSaveFormWithValidation} from '../utils';
import * as processTypeFormActions from './processTypeFormActions';
import {processTypeSagas, processTypeSelectors} from '../../../redux/processType';
import {modalActions, modalTypes, modalSelectors} from '../../../redux/modal';
import formNames from '../../../constants/formNames';
import {applicationActions} from '../../application';
import {isNullOrUndef} from '../../../utils/utils';

const formName = formNames.processTypeForm;

export default function* root() {
  yield all([
    takeEvery(processTypeFormActions.onProcessTypeRefSelected, onProcessTypeRefSelectedSaga),
    takeLatest(processTypeFormActions.submitProcessTypeForm.REQUEST, submitProcessTypeFormSaga),
  ]);
}

const submitProcessTypeFormSaga = createSaveFormWithValidation(processTypeFormActions.submitProcessTypeForm, saveProcessTypeSaga);

export function* saveProcessTypeSaga({type, payload}) {
  const processTypeRefId = R.path(['ProcessTypeRef', 'ProcessTypeRefId'], payload);

  // New ProcessType created
  const processTypeToSave = isNullOrUndef(processTypeRefId)
    ? {
      ...payload,
      ProcessTypeRef: {
        ...payload.ProcessTypeRef,
        ProcessTypeRefId: -1,
      },
    }
    : payload;

  const processTypeList = yield select(state => processTypeSelectors.getProcessTypeList(state));
  const combiAlreadyExist = processTypeList.filter(process =>
    process.ProcessTypeCode === processTypeToSave.ProcessTypeCode
    // && process.RegionCode === processTypeToSave.RegionCode
    && process.ProcessTypeId !== processTypeToSave.ProcessTypeId,
  );
  const processTypeRefList = yield select(state => processTypeSelectors.getProcessTypeRefList(state));
  const refAlreadyExist = processTypeRefList.filter(ref =>
    ref.Code === processTypeToSave.ProcessTypeRef.Code
    && ref.ProcessTypeRefId !== processTypeToSave.ProcessTypeRef.ProcessTypeRefId,
  );

  if (combiAlreadyExist.length > 0) {
    yield put(applicationActions.showNotification('Combinaison ProcessTypeRef - Region already exist', 'error'));
  } else if (refAlreadyExist.length > 0) {
    yield put(applicationActions.showNotification('ProcessTypeRef with this Code already exist', 'error'));
  } else {
    try {
      yield put(applicationActions.setLoading(type));
      yield call(processTypeSagas.saveProcessTypeSaga, {
        type,
        payload: processTypeToSave,
      });

      const modalType = yield select(state => modalSelectors.getModalType(state));
      if (modalType === modalTypes.PROCESSTYPE_FORM) {
        yield put(modalActions.hideModal());
      }
      yield put(processTypeFormActions.submitProcessTypeForm.success());
    } catch (error) {
      throw error;
    } finally {
      yield put(applicationActions.unsetLoading(type));
    }
  }

}

function* onProcessTypeRefSelectedSaga({payload}) {
  const {Code} = payload;
  let processTypeRef = {};
  if (Code) {
    processTypeRef = yield select(state => processTypeSelectors.getProcessTypeRefListById(state)[Code]);
  }
  yield put(change(formName, 'ProcessTypeRef', processTypeRef));
}
