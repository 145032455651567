import { createSelector } from 'reselect';
import { isNullOrUndef } from '../../utils/utils';

export const getModalType = state => state.modal.general.modalType;
export const getModalComponent = state => state.modal.general.modalComponent;
export const getModalProps = state => state.modal.general.modalProps;

export const isModalShown = state => getModalType(state) || getModalComponent(state);

export const getShowModal = createSelector(
  [getModalType, getModalComponent],
  (modalType, modalComponent) => !isNullOrUndef(modalType) || !isNullOrUndef(modalComponent),
);
