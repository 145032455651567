import * as R from 'ramda';
import { get, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/PhraseCpsrChapters/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  fetchPhraseCpsrChapters: R.compose(get, getUrl),
};
