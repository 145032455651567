import { createRequestTypes } from '../utils';

export const SAVE_COMPANY_DOCUMENT = createRequestTypes('companyDocument/SAVE_COMPANY_DOCUMENT');
export const SAVE_COMPANY_NOTIFICATION_DATA = createRequestTypes('companyDocument/SAVE_COMPANY_NOTIFICATION_DATA');
export const UPLOAD_COMPANY_DOCUMENT = createRequestTypes('companyDocument/UPLOAD_COMPANY_DOCUMENT');
export const UPLOAD_COMPANY_NOTIFICATION_DATA = createRequestTypes('companyDocument/UPLOAD_COMPANY_NOTIFICATION_DATA');
export const COMPANY_DOCUMENT_LIST_FOR_COMPANY = createRequestTypes(
  'companyDocument/COMPANY_DOCUMENT_LIST_FOR_COMPANY',
);
export const DELETE_COMPANY_DOCUMENT = createRequestTypes('companyDocument/DELETE_COMPANY_DOCUMENT');
