import { createSelector } from 'reselect';
import * as R from 'ramda';
import { substanceSelectors } from '../substance';
import * as productSelectors from '../product/productSelectors';
import * as cosAnnexeSelectors from '../cosAnnexe/cosAnnexeSelectors';

export const getByProductId = ({ inciList }) => inciList.byProductId;
export const getInciListForProduct = (state, productId) => getByProductId(state)[productId];
export const makeGetInciListForProduct = createSelector(getByProductId, byProductId =>
  createSelector(productId => byProductId[productId], inciList => inciList),
);

export const getInciSubstancesForProduct = createSelector([getInciListForProduct], inciListForProduct => {
  if (!inciListForProduct) return [];
  return inciListForProduct.Substances.map(id => inciListForProduct.InciSubstances[id]);
});

export const getInciSubstancesWithDetailsForProduct = createSelector(
  [getInciSubstancesForProduct, substanceSelectors.getById, cosAnnexeSelectors.makeGetCosAnnexeListForSubstance],
  (inciSubstanceListForProduct, substanceById, getCosAnnexeListForSubstance) =>
    inciSubstanceListForProduct.map(inciSubstance => ({
      ...inciSubstance,
      Substance: substanceById[inciSubstance.SubstanceId],
      CosAnnexes: getCosAnnexeListForSubstance(inciSubstance.SubstanceId),
    })),
);

export const getInciTextForProduct = createSelector(
  [makeGetInciListForProduct, productSelectors.getById],
  (getInciListForProduct2, productById) =>
    createSelector(
      [productId => productById[productId], productId => getInciListForProduct2(productId)],
      (product, calculatedInciListForProduct) => {
        const isManualInci = product ? product.ManualInciInd : undefined;
        if (isManualInci) {
          let upperPart = product.InciListUpperPart;
          let lowerPart = product.InciListLowerPart;
          return {
            UpperPart: upperPart,
            LowerPart: lowerPart,
          };
        }
        return R.propOr({}, 'InciText', calculatedInciListForProduct);
      },
    ),
);
