import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  substanceDocumentActions,
  substanceDocumentSelectors,
  substanceDocumentTypes,
} from '../../../redux/substanceDocument';
import { applicationSelectors } from '../../../redux/application';
import SubstanceDocumentList from './SubstanceDocumentList';

class SubstanceDocumentsContainer extends Component {
  componentDidMount() {
    if (this.props.substanceId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }
  render() {
    return <SubstanceDocumentList {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => {
  const getSubstanceDocumentListForSubstance = substanceDocumentSelectors.makeGetSubstanceDocumentListWithDocumentForSubstance(
    substanceId,
  );
  return {
    substanceDocuments: getSubstanceDocumentListForSubstance(state),
    isLoadingSubstanceDocumentList: applicationSelectors.isLoading(
      state,
      substanceDocumentTypes.SUBSTANCE_DOCUMENT_LIST_FOR_SUBSTANCE.REQUEST,
      substanceDocumentTypes.SAVE_SUBSTANCE_DOCUMENT.REQUEST,
      substanceDocumentTypes.UPLOAD_SUBSTANCE_DOCUMENT.REQUEST,
      substanceDocumentTypes.DELETE_SUBSTANCE_DOCUMENT.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(substanceId));
    }
  },
  saveSubstanceDocument: data => dispatch(substanceDocumentActions.saveSubstanceDocument.request(substanceId, data)),
  getSubstanceDocuments: () =>
    dispatch(substanceDocumentActions.listSubstanceDocumentsForSubstance.request(substanceId)),
  deleteSubstanceDocument: id => dispatch(substanceDocumentActions.deleteSubstanceDocument.request(id)),
});

SubstanceDocumentsContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceDocumentsContainer);
