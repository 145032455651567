import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Translate} from 'react-redux-i18n';
import { Row, Col, Form, ButtonToolbar, Button} from 'react-bootstrap';
import { css } from 'glamor';
import {reduxForm} from "redux-form";
import ProductPhraseTRForm from "./ProductPhraseTRForm";
import Select from "react-select";

const styles = {
  container: css({
    marginTop: '10px',
  }),
  titleContainer: css({
    display: 'flex',
    justifyContent: "center",
    marginBottom: '10px',
    '& .Select': { width: '200px' },
  }),
};

class ProductPhraseTR extends PureComponent {
  constructor(props) {
    super(props);
    let lang = '';
    if(props.languages.length > 0){
      lang = props.languages[0].Alpha2;
    }
    this.state = {
      selectedLanguage: lang,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = option => {
    this.setState({ selectedLanguage: option });
  };

  render(){
    const {
      formValues,
      onSaveTranslation,
      onCancelButtonClick,
      onAddButtonClick,
      handleSubmit,
      isFormPristine,
      submitting,
      invalid,
      languages,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSaveTranslation)}>
        <div>
          <div {...css({ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end', marginRight: '10px' })}>
            <ButtonToolbar>
              <Button
                bsSize="small"
                bsStyle="danger"
                type="button"
                onClick={onCancelButtonClick}
                disabled={isFormPristine || submitting}
              >
                <i className="fa fa-undo" />
                &nbsp;
                <Translate value="general.cancel" />
              </Button>
              <Button
                bsSize="small"
                bsStyle="success"
                type="submit"
                disabled={isFormPristine || submitting || invalid}
              >
                <i className="fa fa-floppy-o" />
                &nbsp;
                <Translate value="general.save" />
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <div className={styles.titleContainer}>
              <span><b><Translate value="phraseTranslation.english"/></b></span>
            </div>
          </Col>
          <Col sm={4}>
            <div className={styles.titleContainer}>
              <Select
                onChange={this.handleChange}
                value={this.state.selectedLanguage}
                options={languages}
                labelKey="Value"
                valueKey="Alpha2"
              />
            </div>
          </Col>
          <Col sm={2}>
                  <Button
                    bsSize="small"
                    bsStyle="info"
                    type="button"
                    // onClick={() => onAddAllButtonClick({productPhraseId, selectedLanguage, productId, CpsrTypeCode, PhraseTRId: dataForLang.Phrase.PhraseTRId })}
                    onClick={() => {
                      console.log('formValues', formValues);
                      formValues.allIds.map(productPhraseId => {
                        const data = formValues[productPhraseId];
                        const dataForLang = data[this.state.selectedLanguage];
                        if (dataForLang.Phrase) {
                          onAddButtonClick({productPhraseId,
                            selectedLanguage: this.state.selectedLanguage,
                            productId: this.props.productId,
                            CpsrTypeCode: this.props.CpsrTypeCode,
                            PhraseTRId: dataForLang.Phrase.PhraseTRId })
                        }
                      })
                    }}
                    // disabled={!dataForLang.Phrase}
                  >
                    <Translate value="phraseTranslation.addAll" />
                  </Button>
                </Col>        </Row>
        <Row>
          <Col md={12} className={styles.container}>
            {formValues && formValues.allIds && formValues.allIds.map(productPhraseId => (
              <ProductPhraseTRForm key={productPhraseId} productPhraseId={productPhraseId} selectedLanguage={this.state.selectedLanguage} {...this.props}/>
            ))}
          </Col>
        </Row>
      </Form>
    );
  }
};

ProductPhraseTR.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSaveTranslation: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProductPhraseTR);
