import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';

const styles = {
  buttonToggleContainer: css({
    width: '100%',
    textAlign: 'center',
    marginBottom: '5px',
    marginTop: '5px',
  }),
};

class CollapsePanelLink extends PureComponent {
  static defaultProps = {
    defaultCollapsed: false,
    dimension: 'height',
    hideText: '',
    showText: '',
    disabled: false,
    // isLoading: false,
  };

  static propTypes = {
    defaultCollapsed: PropTypes.bool,
    hideText: PropTypes.string,
    showText: PropTypes.string,
    children: PropTypes.node.isRequired,
    onExpandPanel: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    // isLoading: PropTypes.bool,
  };

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     collapsed: props.defaultCollapsed,
  //   };

  //   this.toggleCollapse = this.toggleCollapse.bind(this);
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.defaultCollapsed !== this.state.collapsed) {
  //     this.setState(prevState => ({
  //       collapsed: !prevState.collapsed,
  //     }));
  //   }
  // }

  // shouldComponentUpdate(nextProps) {
  //   return !nextProps.isLoading;
  // }

  // toggleCollapse() {
  //   this.setState({
  //     collapsed: !this.state.collapsed,
  //   });
  // }

  render() {
    // const { collapsed } = this.state;
    const { hideText, showText, children, defaultCollapsed, onExpandPanel, disabled } = this.props;
    return (
      <div>
        <div className={styles.buttonToggleContainer}>
          <Button onClick={onExpandPanel} bsStyle="link" bsSize="xsmall" disabled={disabled}>
            {defaultCollapsed ? (
              showText || I18n.t('general.showDetails')
            ) : (
              hideText || I18n.t('general.hideDetails')
            )}&nbsp;
            {defaultCollapsed ? <i className="fa fa-angle-down" /> : <i className="fa fa-angle-up" />}
          </Button>
        </div>
        <Collapse in={!defaultCollapsed} >{children}</Collapse>
      </div>
    );
  }
}

export default CollapsePanelLink;
