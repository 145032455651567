import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';
import { Button, Grid, Col, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import CompositionList from './CompositionList';
import GoToRmButton from './GoToRmButton';
import UpdateInciQtyButton from './UpdateInciQtyButton';
import ProductCompositionStatus from './ProductCompositionStatus';

const buttonBar = css({ marginBottom: '10px' });

const CompositionTab = props => {
  const {
    onReOrderCompositionButtonClick,
    onSelectRMButtonClick,
    onCreateRMButtonClick,
    productId,
    isAddDisabled,
    isProductCompositionEditable,
    ...otherProps
  } = props;

  return (
    <div>
      <div className={buttonBar}>
        <Grid fluid>
          <div>
            <Col sm={7}>
              <ButtonToolbar>
                <GoToRmButton productId={productId} />
                <UpdateInciQtyButton productId={productId} disabled={isAddDisabled || !isProductCompositionEditable} />
              </ButtonToolbar>
            </Col>
            <Col sm={4}>
              <Col sm={5}>
              <ButtonToolbar>
                <Button
                  bsSize="small"
                  onClick={onSelectRMButtonClick}
                  disabled={!isProductCompositionEditable}
                >
                  <Translate value="compositionTab.SelectRM" />
                </Button>
                <Button
                  bsSize="small"
                  onClick={onCreateRMButtonClick}
                  disabled={!isProductCompositionEditable}
                >
                  <Translate value="compositionTab.CreateRM" />
                </Button>
                </ButtonToolbar>
              </Col>
              <Col sm={5}>
                <ProductCompositionStatus productId={productId} />
              </Col>
              <Col sm={2}>
                <Button
                  bsSize="small"
                  onClick={() => onReOrderCompositionButtonClick()}
                  disabled={!isProductCompositionEditable}
                >
                  <Translate value="compositionTab.ReOrderComposition" />
                </Button>
              </Col>
            </Col>
          </div>
        </Grid>
      </div>
      <CompositionList isProductCompositionEditable={isProductCompositionEditable} onReOrderCompositionButtonClick={onReOrderCompositionButtonClick} {...otherProps} />
    </div>
  );
};

CompositionTab.propTypes = {
  onReOrderCompositionButtonClick: PropTypes.func.isRequired,
  onSelectButtonClick: PropTypes.func.isRequired,
  onCreateButtonClick: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  isProductCompositionEditable: PropTypes.bool.isRequired,
};

export default CompositionTab;
