import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CommentModal } from '../../_Common/Modals';
import { productActions, productTypes } from '../../../redux/product';
import { applicationSelectors } from '../../../redux/application';

const ChangeCompositionStatusContainer = props => <CommentModal {...props} />;

const mapStateToProps = state => ({
  isLoading: applicationSelectors.isLoading(state, productTypes.UPDATE_COMPOSITION_STATUS.REQUEST),
});

const mapDispatchToProps = (dispatch, { WorkflowStatusCode, productId: ProductId }) => ({
  onConfirm: Comment => dispatch(productActions.updateCompositionStatus({ WorkflowStatusCode, Comment, ProductId })),
});

ChangeCompositionStatusContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  WorkflowStatusCode: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCompositionStatusContainer);
