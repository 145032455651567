import * as phraseTRFormSelectors from './phraseTRFormSelectors';
import * as phraseTRFormActions from './phraseTRFormActions';
import * as phraseTRFormTypes from './phraseTRFormTypes';
import sagas from './phraseTRFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { phraseTRFormSelectors, phraseTRFormActions, phraseTRFormTypes };
