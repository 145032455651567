import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { orderItemSelectors } from '../../../redux/orderItem';
import { SelectField } from '../Forms';

const OrderItemListDropdown = props => {
  const { orderItemList, orderItemListFilter, sortByName, children } = props;

  const filterOptions = (...args) => sortByName(orderItemListFilter(...args));

  return children ? (
    children({ orderItemList, filterOptions })
  ) : (
    <SelectField
      options={orderItemList}
      labelField="Name"
      valueField="OrderItemId"
      filterOptions={filterOptions}
      useVirtualized
      multi
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  let orderItemList = initialOptions; // || productSelectors.getProductList(state);
  orderItemList = getOrderItemList(orderItemList);
  return {
    orderItemList,
    sortByName: orderItemSelectors.sortByName,
    orderItemListFilter: orderItemSelectors.makeGetOrderItemListFilter(orderItemList),
  };
};

OrderItemListDropdown.defaultProps = {
  children: null,
};

OrderItemListDropdown.propTypes = {
  orderItemList: PropTypes.arrayOf(
    PropTypes.shape({
      OrderItemId: PropTypes.number.isRequired,
      ProcessTypeValue: PropTypes.string,
      Region: PropTypes.string,
    }),
  ).isRequired,
  orderItemListFilter: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const getOrderItemList = createSelector(R.identity, orderItemList => {
  const setOrderItemFullName = orderItem =>
    R.assoc('Name', `${R.prop('ProcessTypeValue', orderItem)} - ${R.prop('Region', orderItem)}`, orderItem);
  return orderItemList.map(setOrderItemFullName);
});

export default connect(mapStateToProps, null)(OrderItemListDropdown);
