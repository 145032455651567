import { createRequestTypes } from '../utils';

export const NOTIFICATION_LIST = createRequestTypes('notification/NOTIFICATION_LIST');
export const NOTIFICATIONS_FOR_PRODUCT_LIST = createRequestTypes('notification/NOTIFICATIONS_FOR_PRODUCT_LIST');

export const FETCH_NOTIFICATIONS = createRequestTypes('notification/FETCH_NOTIFICATIONS');
export const SAVE_NOTIFICATION = createRequestTypes('notification/SAVE_NOTIFICATION');
export const DELETE_NOTIFICATION = createRequestTypes('notification/DELETE_NOTIFICATION');
export const UPDATE_NOTIFICATION = createRequestTypes('notification/UPDATE_NOTIFICATION');
export const ADD_NOTIFICATION = 'notification/ADD_NOTIFICATION';
export const EDIT_NOTIFICATION = 'notification/EDIT_NOTIFICATION';
export const GENERATE_COMPLIANCE_CERTIFICATE = createRequestTypes('notification/GENERATE_COMPLIANCE_CERTIFICATE');
export const DOWNLOAD_COMPLIANCE_CERTIFICATE = createRequestTypes('notification/DOWNLOAD_COMPLIANCE_CERTIFICATE');
export const RENIEW_COMPLIANCE_CERTIFICATE = createRequestTypes('notification/RENIEW_COMPLIANCE_CERTIFICATE');
