import { schema } from 'normalizr';
import { phraseSchemas } from '../phrase';

export const rawMatPhraseSchema = new schema.Entity(
  'rawMatPhrases',
  { Phrase: phraseSchemas.phraseSchema },
  { idAttribute: 'RawMatPhraseId' },
);

export const rawMatPhraseListSchema = [rawMatPhraseSchema];
