import { createSelector } from 'reselect';
import { isDirty } from 'redux-form';
import { impactAssessmentSelectors } from '../../impactAssessment';
import formNames from '../../../constants/formNames';

const formName = formNames.impactAssessment;

export const makeGetInitialValues = createSelector(impactAssessmentSelectors.getById, impactAssessmentById =>
  createSelector(
    impacAssessmentId => impacAssessmentId,
    impactAssessmentId => impactAssessmentById[impactAssessmentId] || {},
  ),
);

export const isFormDirty = isDirty(formName);
