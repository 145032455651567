import React from 'react';
import { Grid, Col } from 'react-bootstrap';
import CompanyDocumentList from './CompanyDocumentList';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const CompanyDocumentsTab = props => (
  <div>
    <Grid fluid>
      <Col sm={10}>
        <CompanyDocumentList {...props} />
      </Col>
      <Col sm={2}>
        <DropzoneForm {...props} />
      </Col>
    </Grid>
  </div>
);

export default CompanyDocumentsTab;
