import queryString from 'query-string';
import { uploadFiles, get, post, del } from './apiUtils';

const defaultUrl = '/ProductsDocuments/';
const upload = '/File/';
const sendToCosmos = '/SendToCosmos/';

export default {
  uploadProductDocument: ({ ProductId, data }) => uploadFiles(`${defaultUrl}${ProductId}${upload}`, data),
  sendProductDocumentToCosmos: ({ ProductDocumentId, data }) => {
   post(`${defaultUrl}${ProductDocumentId}${sendToCosmos}`, data)
  },
  getProductDocumentList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  deleteProductDocument: id => del(`${defaultUrl}${id}`),
};
