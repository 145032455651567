import { createAction } from 'redux-actions';
import { createRequestActions } from '../../utils';
import * as impurityModalTypes from './impurityModalTypes';

export const onSaveButtonClick = createAction(
  impurityModalTypes.SAVE_BUTTON_CLICKED,
  (rawMatId, data) => data,
  (rawMatId, data) => ({ rawMatId }),
);

export const saveRawMatProportionImpuritiesForRawMat = createRequestActions(
  impurityModalTypes.SAVE_RAWMAT_PROPORTION_IMPURITIES,
  (rawMatId, data) => data,
  (rawMatId, data) => ({ rawMatId }),
);
