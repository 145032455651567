import queryString from 'query-string';
import * as R from 'ramda';
import { get, post, del, makeConstructUrlFromQuery } from './apiUtils';
import { download } from './apiUtils';

const defaultUrl = '/Notifications/';
const generateUrl = 'GenerateComplianceCertificate/';
const reniewUrl = '/Reniew/';
const deleteUrl = '/Delete/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getNotifications: query => (query ?
    get(`${defaultUrl}?${queryString.stringify(query)}`)
    : get(defaultUrl)),
  saveNotification: data => post(defaultUrl, data),
  deleteNotification: data =>
    del(`${defaultUrl}${deleteUrl}`, data),
  generateComplianceCertificate: data => {
    download(`${defaultUrl}${generateUrl}${data.NotificationId}`,'test.pdf')
  },
  reniewComplianceCertificate: data => {
    download(`${defaultUrl}${reniewUrl}${data.NotificationId}`,'test.pdf')
  },
  fetchNotifications: R.compose(
    get,
    getUrl,
  ),
};
