import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectField } from '../../components/_Common/Forms';
import { referentialSelectors } from '../../redux/referentials';

const ProductUseTypeSelectField = ({ productUseTypes, ...otherProps }) => (
  <SelectField options={productUseTypes} {...otherProps} />
);

const mapStateToProps = state => ({
  productUseTypes: referentialSelectors.getProductUseTypes(state),
});

ProductUseTypeSelectField.propTypes = {
  productUseTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(ProductUseTypeSelectField);
