import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
// import { userRoleActions, userRoleTypes } from '../../../../redux/userRole';
import WorkItemModal from './WorkItemModal';
import { applicationSelectors } from '../../../../redux/application';
// import { orderItemTypes } from '../../../../redux/orderItem';
import { workItemTypes } from '../../../../redux/workItem';

const formName = formNames.workItem;
const isAnyTrue = R.any(R.equals(true));

class WorkItemModalContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <WorkItemModal {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('dailyRecords.modalTitle'),
    isLoading: applicationSelectors.isLoading(state, workItemTypes.SAVE_WORK_ITEM.REQUEST),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
  },
  onSaveButtonClick: () => dispatch(submit(formName)),
});

WorkItemModalContainer.propTypes = {
  WorkItemId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkItemModalContainer);
