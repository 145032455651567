import * as labelReviewClaimSelectors from './labelReviewClaimSelectors';
import * as labelReviewClaimActions from './labelReviewClaimActions';
import * as labelReviewClaimTypes from './labelReviewClaimTypes';
import * as labelReviewClaimSchemas from './labelReviewClaimSchemas';
//import sagas from './labelReviewClaimSagas';
import reducer from './labelReviewClaimReducer';

//import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { labelReviewClaimSelectors, labelReviewClaimActions, labelReviewClaimTypes, labelReviewClaimSchemas };
export default reducer;
