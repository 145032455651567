import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import OrderList from './OrderList';
import { orderActions, orderTypes } from '../../redux/order';
import { companyActions } from '../../redux/company';
import { applicationSelectors } from '../../redux/application';

class OrdersPage extends Component {

  static propTypes = {
    onRefreshList: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    getProducerList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onRefreshList();
    this.props.getProducerList();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return (
      <OrderList selectedId={selectedId} {...this.props} />
    );
  }
}

const mapStateToProps = (state) => {
  const { orderList } = state.order;
  return ({
    orderList,
    requestingOrderList: applicationSelectors.isLoading(state, orderTypes.ORDER_LIST.REQUEST),
  });
};

export default connect(mapStateToProps, {
  onRefreshList: orderActions.listOrders.request,
  getProducerList: companyActions.listCompanies.request,
  onOrderSelected: id => push(`/orders/${id}`),
  onAddOrder: () => push('orders/-1'),
})(OrdersPage);
