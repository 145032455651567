import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import { isNullOrUndef } from '../../utils/utils';
import { mandateSelectors } from '../mandate';
import * as R from 'ramda';
import { companyDistributorAgreementSelectors } from '../companyDistributorAgreement';

const detailsFormValueSelector = formValueSelector('distributorForm');

export const getById = ({ distributor }) => distributor.byId;
export const getIds = ({ distributor }) => distributor.list.allIds;
export const getDistributor = (state, id) => getById(state)[id];
export const getDistributorById = id => state => getById(state)[id];
export const getDistributorList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getProductDistributorList = state => state.distributor.list.productDistributor;

export function distributorTitle(state) {
  const code = detailsFormValueSelector(state, 'Name') || '';
  return `${code}`;
}

export function isNew(state) {
  const id = detailsFormValueSelector(state, 'DistributorId');
  return id <= 0 || isNullOrUndef(id);
}

const makeGetByIdForObj = obj =>
  R.compose(
    R.defaultTo({}),
    R.flip(R.prop)(obj),
  );

const getDistributorForMandate = mandateId =>
  createSelector(
    [getById, mandateSelectors.getById, companyDistributorAgreementSelectors.getById],
    (distributorById, mandateById, agreementById) =>
      R.compose(
        makeGetByIdForObj(distributorById),
        R.prop('DistributorId'),
        makeGetByIdForObj(agreementById),
        R.prop('AgreementId'),
        makeGetByIdForObj(mandateById),
      )(mandateId),
  );

export const makeGetDistributorForMandate = createSelector(getDistributorForMandate, R.identity);
