import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CosAnnexeList from './CosAnnexeList';
import { cosAnnexeActions, cosAnnexeSelectors } from '../../../redux/cosAnnexe';
import {
  substanceCosAnnexeActions,
  substanceCosAnnexeSelectors,
  substanceCosAnnexeTypes,
} from '../../../redux/substanceCosAnnexe';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { referentialSelectors } from '../../../redux/referentials';

class CosAnnexeListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }
  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <CosAnnexeList {...this.props} />;
  }
}
const mapStateToProps = (state, { substanceId }) => {
  const getSubstanceCosAnnexesForSubstance = substanceCosAnnexeSelectors.makeGetSubstanceCosAnnexesForSubstance(state);
  return {
    theFn: getSubstanceCosAnnexesForSubstance,
    substanceCosAnnexeList: getSubstanceCosAnnexesForSubstance(substanceId),
    cosAnnexeList: cosAnnexeSelectors.getCosAnnexeList(state),
    cosAnnexeTypes: referentialSelectors.getCosAnnexeTypes(state),
    isLoading: applicationSelectors.isLoading(
      state,
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES_FOR_SUBSTANCE.REQUEST,
      substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    dispatch(cosAnnexeActions.fetchCosAnnexes.request());
    if (substanceId > 0) {
      dispatch(substanceCosAnnexeActions.fetchSubstanceCosAnnexesForSubstance.request(substanceId));
    }
  },
  fetchCosAnnexeList: () =>
    dispatch(substanceCosAnnexeActions.fetchSubstanceCosAnnexesForSubstance.request(substanceId)),
  onDeleteButtonClick: ({ CosAnnexeId }) =>
    dispatch(cosAnnexeActions.deletecosAnnexe.request(CosAnnexeId, { substanceId })),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showCosAnnexeFormModal({
        substanceId,
      }),
    ),
  onEditButtonClick: ({ CosAnnexeId }) =>
    dispatch(
      modalActions.showCosAnnexeFormModal({
        substanceId,
        cosAnnexeId: CosAnnexeId,
      }),
    ),
});

CosAnnexeListContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CosAnnexeListContainer);
