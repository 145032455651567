import { takeLatest, all, put } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import * as substanceCosAnnexeFormActions from './substanceCosAnnexeFormActions';
import { createSaveFormWithValidation } from '../utils';
import formNames from '../../../constants/formNames';
import { substanceCosAnnexeSagas } from '../../substanceCosAnnexe';

const formName = formNames.substanceCosAnnexeForm;

export default function* root() {
  yield all([
    takeLatest(substanceCosAnnexeFormActions.submitSubstanceCosAnnexeForm.REQUEST, submitSubstanceCosAnnexeFormSaga),
  ]);
}

const submitSubstanceCosAnnexeFormSaga = createSaveFormWithValidation(
  substanceCosAnnexeFormActions.submitSubstanceCosAnnexeForm,
  action =>
    substanceCosAnnexeSagas.saveSubstanceCosAnnexeSaga({
      ...action,
      meta: { query: { cosAnnexeCode: action.payload.CosAnnexeCode } },
    }),
  {
    * onSuccess(res, payload) {
      const { SubstanceCosAnnexeId } = payload;
      if (!SubstanceCosAnnexeId) {
        yield put(initialize(formName, {}));
      }
    },
  },
);
