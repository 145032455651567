import { createAction } from 'redux-actions';
import { createFetchAction } from '../../utils';
import * as labelReviewWizardTypes from './labelReviewWizardTypes';

export const newLabelReviewWizard = createFetchAction(labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD, 'labelReviewId');

export const editLabelReviewWizard = createFetchAction(
  labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD,
  'labelReviewId',
);

export const loadLabelReviewData = createAction(labelReviewWizardTypes.LOAD_LABEL_REVIEW_DATA);

export const updateLabelReview = createAction(labelReviewWizardTypes.UPDATE_LABEL_REVIEW);

export const onSaveButtonClick = createAction(labelReviewWizardTypes.SAVE_BUTTON_CLICKED);
