import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import { workflowStatusListSchema } from '../schema';
import workflowStatusApi from '../../api/workflowStatusApi';
import * as workflowStatusTypes from './workflowStatusTypes';
import * as workflowStatusActions from './workflowStatusActions';

export default function* root() {
  yield all([takeLatest(workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST, getWorkflowStatusListSaga)]);
}

function* getWorkflowStatusListSaga() {
  yield put(applicationActions.setLoading(workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    workflowStatusActions.listWorkflowStatus,
    workflowStatusApi.getWorkflowStatusList,
    workflowStatusListSchema,
  );
  yield put(applicationActions.unsetLoading(workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST));
}
