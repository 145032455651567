import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
//import Select from 'react-select';
//import { InputField, CheckBoxField, SelectField, DateField, RTEField, AddressForm } from '../_Common/Forms';
import DetailHeader from '../_Common/DetailHeader';
//import CollapsePanelLink from '../_Common/CollapsePanelLink';
import DashboardResultList from './DashboardResults';

const DashboardForm = props => {
  const {
    // handleSubmit,
    // isNew,
    dashboardTitle,
    dashboardTypeId
  } = props;
  return (
    <Form>
      <DetailHeader
        title={`${I18n.t('dashboard.title')} : ${dashboardTitle}`}
        {...props}
        showDuplicate={false}
        showDelete={false}
      >
        <DashboardResultList dashboardTypeId={dashboardTypeId} {...props}/>
      </DetailHeader>
    </Form>
  );
};

DashboardForm.propTypes = {
  handleSubmit: PropTypes.func,
  isNew: PropTypes.bool,
  dashboardTitle: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'dashboardForm',
  destroyOnUnmount: true,
  asyncBlurFields: [], // this seems to prevent the error
  enableReinitialize: true,
  initialValues: {
    dashboardTypeId: -1,
  },
  //validate,
})(DashboardForm);
