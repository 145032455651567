import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mandateSelectors, mandateActions, mandateTypes } from '../../../redux/mandate';
import { documentSelectors, documentTypes } from '../../../redux/document';
import { applicationSelectors } from '../../../redux/application';
import { companyDistributorAgreementSelectors } from '../../../redux/companyDistributorAgreement';
import { companySelectors } from '../../../redux/company';
import { modalActions } from '../../../redux/modal';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import DistributorMandateList from './DistributorMandateList';
import { reportActions } from '../../../redux/report';

class DistributorMandateListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }
  componentDidUpdate(previousProps) {
    if (previousProps.distributorId !== this.props.distributorId) {
      this.props.onLoad();
    }
  }

  render() {
    return <DistributorMandateList {...this.props} />;
  }
}

const mapStateToProps = (state, { distributorId }) => {
  const getMandateList = mandateSelectors.makeGetMandatesForDistributor(distributorId);
  return {
    mandateList: getMandateList(state),
    companyDistributorAgreementList: companyDistributorAgreementSelectors.getCompanyDistributorAgreementList(state),
    companyList: companySelectors.getCompanyList(state),
    mandateStatusList: workflowStatusSelectors.getMandateStatusList(state),
    documentList: documentSelectors.getDocumentList(state),
    isLoading: applicationSelectors.isLoading(
      state,
      mandateTypes.FETCH_MANDATES.REQUEST,
      documentTypes.DOWNLOAD_DOCUMENT.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { distributorId }) => ({
  onLoad: () => {
    if (distributorId > 0) {
      dispatch(mandateActions.fetchMandates.request({ distributorId }));
    }
  },
  onRefresh: () => dispatch(mandateActions.fetchMandates.request({ distributorId })),
  onEditButtonClick: ({ MandateId: mandateId }) =>
    dispatch(
      modalActions.showMandateProductsrModal({
        mandateId,
        distributorId,
      }),
    ),
  onPrintButtonClick: ({ MandateId: mandateId }) => dispatch(reportActions.getMandateReport.request(mandateId)),
  onQuickPrintButtonClick: ({ MandateId: mandateId }) =>
    dispatch(reportActions.getQuickMandateReport.request(mandateId)),
});

DistributorMandateListContainer.propTypes = {
  distributorId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DistributorMandateListContainer);
