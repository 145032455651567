import { isEmpty, isNil } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { productSelectors, productActions } from '../../../redux/product';
import { productProportionActions, productProportionTypes } from '../../../redux/productProportion';
import { shadeActions, shadeSelectors, shadeTypes } from '../../../redux/shade';
import { shadeXInciActions, shadeXInciTypes } from '../../../redux/ui/shadeXInciModal';
import { shadeXRmActions, shadeXRmTypes } from '../../../redux/ui/shadeXRmModal';
import ShadeTab from './ShadeTab';
import workflowStatus from '../../../constants/workflowStatus';

const ShadeTabContainer = props => (
  <div>
    <ShadeTab {...props} />
  </div>
);

const mapStateToProps = (state, { productId }) => {
  const shades = shadeSelectors.makeGetShadesForProduct(state)(productId);
  const emptyOrNilShades = isNil(shades) || isEmpty(shades);
  const product = productSelectors.getById(state)[productId];
  return {
    isLoadingShadeInciModal: applicationSelectors.getIsLoading([shadeXInciTypes.LOAD_MODAL_DATA.REQUEST])(state),
    isLoadingShadeRmModal: applicationSelectors.getIsLoading([shadeXRmTypes.LOAD_MODAL_DATA.REQUEST])(state),
    buttonsDisabled:
      applicationSelectors.isLoading(
        state,
        shadeTypes.FETCH_SHADES.REQUEST,
        shadeTypes.DELETE_SHADE.REQUEST,
        shadeTypes.UPDATE_INCI.REQUEST,
      ) || emptyOrNilShades,
    buttonsDisabledByValidedComposition:
      !isNil(product.CompositionStatus) && product.CompositionStatus === workflowStatus.PRODUCT_COMPO_VALIDATED,
    isUpdatingMaxQty: applicationSelectors.isLoading(state, productProportionTypes.UPDATE_MAX_QTY.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onCompoByRmButtonClick: () => {
    dispatch(shadeXRmActions.loadModalData.request(productId));
  },
  onCompoByInciButtonClick: () => {
    dispatch(shadeXInciActions.loadModalData.request(productId));
  },
  onUpdateInciButtonClick: () => {
    dispatch(shadeActions.updateInci.request({ productId }));
  },
  onUpdateMaxQtyButtonClick: () => {
    dispatch(productProportionActions.updateMaxQty.request({ productId }));
  },
  onLatinAmericaAnastasiaCompoClick: () => {
    dispatch(productActions.exportCompositionForLatinAmerica.request({ productId }));
  },
});

ShadeTabContainer.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadeTabContainer);
