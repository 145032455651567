import { createRequestTypes } from '../utils';

export const SUBSTANCE_LIST = createRequestTypes('substance/SUBSTANCE_LIST');
export const FETCH_SUBSTANCES = createRequestTypes('substance/FETCH_SUBSTANCES');
export const SUBSTANCE = createRequestTypes('substance/SUBSTANCE');
export const SAVE_SUBSTANCE = createRequestTypes('substance/SAVE_SUBSTANCE');
export const SUBSTANCE_PROPORTIONS = createRequestTypes('substance/SUBSTANCE_PROPORTIONS');
export const SUBSTANCE_FUNCTIONS = createRequestTypes('substance/SUBSTANCE_FUNCTIONS');
export const UPDATE_SUBSTANCE_STATUS = createRequestTypes('substance/UPDATE_SUBSTANCE_STATUS');
export const NEW_SUBSTANCE = 'substance/NEW_SUBSTANCE';
export const SUBMIT_SUBSTANCE_FORM = 'substance/SUBMIT_SUBSTANCE_FORM';
export const ChECK_IF_SUBSTANCE_LOADED = createRequestTypes('substance/ChECK_IF_SUBSTANCE_LOADED');
