import { get, post, del } from './apiUtils';

const defaultUrl = '/Distributors/';
const producersUrl = '/Companies/';
const productsUrl = '/Products/';
const productUrl = '/Product/';
const agreementsUrl = '/Agreements/';

export default {
  getDistributorAgreements: id => get(`${defaultUrl}${id}${agreementsUrl}`),
  getDistributorList: () => get(defaultUrl),
  getDistributor: id => get(`${defaultUrl}${id}`),
  saveDistributor: data => post(defaultUrl, data),
  getDistributorCompanies: id => get(`${defaultUrl}${id}${producersUrl}`),
  getDistributorProducts: id => get(`${defaultUrl}${id}${productsUrl}`),
  deleteDistributor: id => del(`${defaultUrl}${id}`),
  getProductDistributorList: id => get(`${productUrl}${id}${defaultUrl}`),
};
