import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { phraseTRActions, phraseTRTypes } from '../../../redux/phraseTR';
import { referentialsTypes, referentialSelectors } from '../../../redux/referentials';
import ProductPhraseTR from './ProductPhraseTR';
import { applicationSelectors } from '../../../redux/application';
import {getFormValues, isPristine, isSubmitting, reset} from "redux-form";
import BaseLoader from "../../_Common/BaseLoader";
import {productPhraseActions, productPhraseTypes} from "../../../redux/productPhrase";
import {productPhraseTRActions, productPhraseTRTypes} from "../../../redux/productPhraseTR";
import {productPhraseTRFormActions} from "../../../redux/form/productPhraseTRForm";

const getFormName = cpsrTypdeCode => `Product${cpsrTypdeCode}TRForm`;

class ProductPhraseTRContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const formName = getFormName(this.props.CpsrTypeCode);
    return (
      <BaseLoader isLoading={this.props.isLoading}>
        <ProductPhraseTR form={formName} {...this.props} />
      </BaseLoader>);
  }
}

const mapStateToProps = (state, { CpsrTypeCode }) => {
  const formName = getFormName(CpsrTypeCode);
  const formValues = getFormValues(formName);
  return {
    formValues: formValues(state),
    formName,
    isFormPristine: isPristine(formName)(state),
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isLoading: applicationSelectors.isLoading(
      state,
      productPhraseTypes.PRODUCT_PHRASE_LIST_FOR_PRODUCT.REQUEST,
      productPhraseTRTypes.PRODUCT_PHRASE_TR_LIST_FOR_PRODUCT.REQUEST,
      phraseTRTypes.PHRASE_TRANSLATION_LIST.REQUEST,
      referentialsTypes.ALL_REFERENTIALS.REQUEST,
      isSubmitting(formName),
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId: ProductId, CpsrTypeCode }) => {
  const formName = getFormName(CpsrTypeCode);

  return {
    onLoad: () => {
      dispatch(productPhraseActions.listProductPhraseForProduct.request({ ProductId, CpsrTypeCode }));
      dispatch(productPhraseTRActions.listProductPhraseTRForProduct.request({ ProductId, CpsrTypeCode }));
      dispatch(phraseTRActions.listPhraseTranslation.request());
      dispatch(productPhraseTRFormActions.loadForm({formName, ProductId, CpsrTypeCode}));
    },
    onAddButtonClick: data => dispatch(productPhraseTRFormActions.addTranslation({formName, ...data})),
    onSaveTranslation: data => dispatch(productPhraseTRFormActions.onSaveButtonClick({ formData: data, formName, ProductId, CpsrTypeCode })),
    onCancelButtonClick: () => {
      dispatch(reset(formName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPhraseTRContainer);

ProductPhraseTRContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
