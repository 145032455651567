import * as productCategorySelectors from './productCategorySelectors';
import * as productCategoryActions from './productCategoryActions';
import * as productCategoryTypes from './productCategoryTypes';
import * as productCategorySchemas from './productCategorySchemas';
import sagas, * as productCategorySagas from './productCategorySagas';
import reducer from './productCategoryReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productCategorySelectors, productCategoryActions, productCategoryTypes, productCategorySchemas, productCategorySagas };
export default reducer;
