import { takeLatest, put, takeEvery, all, select, call, take } from 'redux-saga/effects';
import * as R from 'ramda';
import { normalize } from 'normalizr';
import { LOCATION_CHANGE, replace } from 'react-router-redux';
import { applicationActions } from '../application';
import { substanceActionActions } from '../substanceAction';
import * as substanceCosAnnexeActions from '../substanceCosAnnexe/substanceCosAnnexeActions';
import { substanceFormSelectors } from '../form/substanceForm';
import { modalActions, modalSelectors, modalTypes } from '../modal';
import { createSaveForm, createSaveFormWithValidation } from '../form/utils';
import formNames from '../../constants/formNames';
import { substanceListSchema, substanceSchema, substanceProportionWithSubstanceListSchema } from '../schema';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';

import substanceApi from '../../api/substanceApi';
import * as substanceTypes from './substanceTypes';
import * as substanceActions from './substanceActions';
import {substanceSelectors} from "./index";

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(substanceTypes.SUBSTANCE_LIST.REQUEST, getSubstanceListSaga),
    takeLatest(substanceTypes.SUBSTANCE.REQUEST, getSubstanceSaga),
    takeLatest(substanceTypes.SAVE_SUBSTANCE.REQUEST, saveSubstanceFormSaga),
    takeLatest(substanceTypes.SUBSTANCE_PROPORTIONS.REQUEST, getSubstanceProportionsSaga),
    takeLatest(substanceTypes.UPDATE_SUBSTANCE_STATUS.REQUEST, updateSubstanceStatusSaga),
    takeLatest(substanceActions.submitSubstanceForm.REQUEST, submitSubstanceFormSaga),
    takeLatest(substanceTypes.FETCH_SUBSTANCES.REQUEST, fetchSubstancesSaga),
    takeLatest(substanceTypes.ChECK_IF_SUBSTANCE_LOADED.REQUEST, checkIfSubstancesLoadedSaga),
  ]);
}

const submitSubstanceFormSaga = createSaveFormWithValidation(substanceActions.submitSubstanceForm, saveSubstanceSaga);

const saveSubstanceFormSaga = createSaveForm(formNames.substance, saveSubstanceSaga);

function* onLocationChange(action) {
  if (action.payload.pathname === '/substances/-1') {
    yield put(substanceActions.newSubstance());
  }
}

export function* fetchSubstancesSaga({ payload, type, meta = {} }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceList = yield call(substanceApi.fetchSubstances, payload);
    const schema = Array.isArray(substanceList) ? substanceListSchema : substanceSchema;
    const normalizedData = normalize(substanceList, schema);
    yield put(substanceActions.fetchSubstances.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceActions.fetchSubstances.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
function* getSubstanceListSaga() {
  yield put(applicationActions.setLoading(substanceTypes.SUBSTANCE_LIST.REQUEST));
  yield callApiSagaAndNormalize(substanceActions.listSubstances, substanceApi.getSubstanceList, substanceListSchema);
  yield put(applicationActions.unsetLoading(substanceTypes.SUBSTANCE_LIST.REQUEST));
}

export function* updateSubstanceStatusSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { WorkflowStatusCode } = payload;

  try {
    const SubstanceId = yield call(substanceApi.updateStatus, payload);
    yield put(substanceActions.updateStatus.success({ SubstanceId, WorkflowStatusCode }));
    const modalType = yield select(modalSelectors.getModalType);
    if (modalType === modalTypes.SUBSTANCE_STATUS_COMMENT) {
      yield put(modalActions.hideModal());
    }
    if (SubstanceId !== undefined) {
      yield put(substanceActions.substance.request(SubstanceId));
      yield put(substanceActionActions.fetchSubstanceActions.request({ SubstanceId }));
    }
    yield take(substanceTypes.SUBSTANCE.SUCCESS);
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceActions.updateStatus.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* getSubstanceSaga(action) {
  yield put(applicationActions.setLoading(substanceTypes.SUBSTANCE.REQUEST));
  yield callApiSagaAndNormalize(substanceActions.substance, substanceApi.getSubstance, substanceSchema, action.payload);
  yield put(applicationActions.unsetLoading(substanceTypes.SUBSTANCE.REQUEST));
}

function* getSubstanceProportionsSaga(action) {
  yield put(applicationActions.setLoading(substanceTypes.SUBSTANCE_PROPORTIONS.REQUEST));
  yield callApiSagaAndNormalize(
    substanceActions.listSubstanceProportions,
    substanceApi.getSubstanceProportions,
    substanceProportionWithSubstanceListSchema,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(substanceTypes.SUBSTANCE_PROPORTIONS.REQUEST));
}

const IsCasOrEcDifferent = R.compose(
  R.not,
  R.converge(R.equals, [R.head, R.last]),
  R.map(R.pick(['CasNr', 'EcNr'])),
);

function* saveSubstanceSaga(action) {
  yield put(applicationActions.setLoading(substanceTypes.SAVE_SUBSTANCE.REQUEST));
  const substanceValues = action.payload;
  try {
    const substanceId = yield callApiSaga(substanceActions.saveSubstance, substanceApi.saveSubstance, substanceValues);
    if (substanceId !== undefined) {
      const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
      yield put(replace({ ...previousLocation, pathname: `/substances/${substanceId}` }));
      yield put(substanceActions.substance.request(substanceId));
      yield put(substanceActions.listSubstances.request());
      const initialValues = yield select(state => substanceFormSelectors.makeGetInitialValues(substanceId)(state));
      if (IsCasOrEcDifferent([initialValues, substanceValues]) && initialValues.SubstanceId) {
        yield put(substanceCosAnnexeActions.fetchSubstanceCosAnnexesForSubstance.request(substanceId));
      }
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(substanceTypes.SAVE_SUBSTANCE.REQUEST));
  }
}

export function* checkIfSubstancesLoadedSaga({ payload, type, meta = {} }) {
  const { substanceIds } = payload;
  const notLoaded = yield select(state => substanceSelectors.GetNotLoadedSubstanceId(state)(substanceIds));
  if(notLoaded.length > 0) yield put(substanceActions.fetchSubstances.request({ substanceIds: notLoaded }));
}
