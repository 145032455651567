import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Panel, Checkbox, FormGroup, ControlLabel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import cpsrTypes from '../../../constants/cpsrTypes';
import { requiredField, isInteger } from '../../../utils/fieldValidation';
import CustomSimpleField from "../../_Common/Forms/Custom/CustomSimpleField";
import CpsrChaptersDropdownCustom from "../../_Common/Forms/Custom/CpsrChaptersDropdownCustom";
import CpsrPhraseStylesDropdownCustom from "../../_Common/Forms/Custom/CpsrPhraseStylesDropdownCustom";
import {Field} from "redux-form";
import SelectField from "../../_Common/Forms/SelectField";
import regionCode from "../../../constants/regionCode";
import Select from "react-select";

const valueRenderer = opt => `${opt.Order}. ${opt.Value}`;
const optionRenderer = opt => `${opt.Order}. ${opt.Value}`;

const PhraseTableItem = props => {
  const {
    productPhraseId,
    onSelectProductPhrase,
    isSelected,
    isCpsrXEditable,
    cpsrTypeCode,
    formValues,
    change,
    regions,
    countries,
  } = props;

  const handleSelectProductPhrase = ({ target }) =>
    onSelectProductPhrase({ id: productPhraseId, selected: target.checked });

  const isNew = !Number.isInteger(productPhraseId);

  return (
    <Panel bsStyle="primary">
      <Row>
        <Col sm={1}>
          <Col sm={3}>
            <FormGroup>
              <ControlLabel />
              <Checkbox checked={isSelected} onChange={handleSelectProductPhrase} disabled={!isCpsrXEditable} />
            </FormGroup>
          </Col>
          <Col sm={9}>
            <CustomSimpleField
              name={`${productPhraseId}.OrderNr`}
              value={formValues[productPhraseId].OrderNr}
              label={I18n.t('productPhraseForm.OrderNr')}
              onChange={(newValue) => change(`${productPhraseId}.OrderNr`, newValue)}
              component={'input'}
              validate={isInteger}
              readOnly={!isCpsrXEditable}
            />
          </Col>
        </Col>
        <Col sm={3}>
          <CpsrChaptersDropdownCustom
            name={`${productPhraseId}.CpsrChapterCode`}
            label={I18n.t('productPhraseForm.CpsrChapterCode')}
            readOnly={!isCpsrXEditable}
            validate={requiredField}
            value={formValues[productPhraseId].CpsrChapterCode}
            onChange={(newValue) => change(`${productPhraseId}.CpsrChapterCode`, newValue)}
            cpsrTypeCode={cpsrTypeCode}
            valueRenderer={valueRenderer}
            optionRenderer={optionRenderer}
            isNew={isNew}
          />
        </Col>
        <Col sm={3}>
          <CpsrPhraseStylesDropdownCustom
            name={`${productPhraseId}.CpsrPhraseStyleId`}
            label={I18n.t('productPhraseForm.CpsrPhraseStyleId')}
            validate={requiredField}
            readOnly={!isCpsrXEditable}
            value={formValues[productPhraseId].CpsrPhraseStyleId}
            onChange={(newValue) => change(`${productPhraseId}.CpsrPhraseStyleId`, newValue)}
            isNew={isNew}
          />
        </Col>
        <Col xs={2}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.region')}</ControlLabel>
            <Select
              options={regions}
              labelKey="Value"
              valueKey="Key"
              value={formValues[productPhraseId].RegionCode}
              onChange={(newValue) => change(`${productPhraseId}.RegionCode`, newValue ? newValue.Key : null)}
              clearable
              disabled={!isCpsrXEditable}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.country')}</ControlLabel>
            <Select
              options={formValues[productPhraseId].RegionCode ? countries.filter(elem => elem.RegionCode === formValues[productPhraseId].RegionCode) : countries}
              labelKey="Value"
              valueKey="Key"
              value={formValues[productPhraseId].CountryAlpha2}
              onChange={(newValue) => change(`${productPhraseId}.CountryAlpha2`, newValue ? newValue.Key : null)}
              clearable="true"
              disabled={!isCpsrXEditable || formValues[productPhraseId].RegionCode !== regionCode.ASEAN}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <CustomSimpleField
            name={`${productPhraseId}.V1`}
            label={I18n.t('productPhraseForm.Characteristic')}
            value={formValues[productPhraseId].V1}
            onChange={(newValue) => change(`${productPhraseId}.V1`, newValue)}
            component="textarea"
            readOnly={!isCpsrXEditable}
          />
        </Col>
        <Col sm={3}>
          <CustomSimpleField
            name={`${productPhraseId}.V2`}
            label={I18n.t('productPhraseForm.Specification')}
            component="textarea"
            readOnly={!isCpsrXEditable}
            value={formValues[productPhraseId].V2}
            onChange={(newValue) => change(`${productPhraseId}.V2`, newValue)}
          />
        </Col>
        <Col sm={3}>
          <CustomSimpleField
            name={`${productPhraseId}.TM`}
            label={I18n.t('productPhraseForm.Results')}
            component="textarea"
            readOnly={!isCpsrXEditable}
            value={formValues[productPhraseId].TM}
            onChange={(newValue) => change(`${productPhraseId}.TM`, newValue)}
          />
        </Col>
        <Col sm={3}>
          <CustomSimpleField
            name={`${productPhraseId}.V3`}
            label={I18n.t('productPhraseForm.TestMethod')}
            component="textarea"
            readOnly={!isCpsrXEditable}
            value={formValues[productPhraseId].V3}
            onChange={(newValue) => change(`${productPhraseId}.V3`, newValue)}
          />
        </Col>
      </Row>
    </Panel>
  );
};

PhraseTableItem.propTypes = {
  cpsrTypeCode: PropTypes.oneOf([cpsrTypes.CPSRA, cpsrTypes.CPSRB]).isRequired,
  productPhraseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  CpsrChapters: PropTypes.array.isRequired, //eslint-disable-line
  CpsrPhraseStyleList: PropTypes.array.isRequired, //eslint-disable-line
  onSelectProductPhrase: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isCpsrXEditable: PropTypes.bool.isRequired,
};

export default PhraseTableItem;
