import PropTypes from 'prop-types';
import React from 'react';
import {FormControl} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {css} from 'glamor';
import moment, { utc } from 'moment';
import BaseFormGroup from './BaseFormGroup';

const styles = {
  datepicker: css({
    display: 'block',
    '& .react-datepicker-popper': {zIndex: 3},
  }),
};

class DateField extends React.PureComponent {
  state = {
    touched: false,
  };

  handleChange = value => {
    if (value) value.utc();
    this.setState({
      touched: true,
    });
    this.props.input.onChange(value ? value.format() : null);
  };

  render() {
    const {label, input, placeholder, dateFormat} = this.props;
    let value = moment.utc(input.value);
    if (!value.isValid()) {
      value = null;
    } else {
      value.local();
    }

    return (
      <BaseFormGroup {...this.props} meta={{...this.props.meta, touched: this.state.touched}}>
        <div className={styles.datepicker}>
          <FormControl
            componentClass={DatePicker}
            dateFormat={dateFormat}
            selected={value}
            onFocus={input.onFocus}
            onBLur={input.onBlur}
            onChange={this.handleChange}
            placeholderText={placeholder || label}
            {...this.props}
          />
        </div>
      </BaseFormGroup>
    );
  }
}

DateField.defaultProps = {
  dateFormat: 'DD/MM/YYYY',
  placeholder: '',
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default DateField;
