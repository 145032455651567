import { put, all, select, takeLatest, call, takeEvery } from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as taskTypeFormActions from './taskTypeFormActions';
import { taskTypeActions, taskTypeSagas } from '../../../redux/taskType';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import { processTypeSelectors } from '../../../redux/processType';
import { change, formValueSelector } from 'redux-form';
import formNames from '../../../constants/formNames';
import * as R from 'ramda';
import { isNullOrUndef } from '../../../utils/utils';
import { applicationActions } from '../../application';

const formName = formNames.taskTypeForm;
const getFormValue = formValueSelector(formName);

export default function* root() {
  yield all([
    takeEvery(taskTypeFormActions.onProcessTypeSelected, onProcessTypeSelectedSaga),
    takeEvery(taskTypeFormActions.onAddButtonClicked, onAddButtonClickedSaga),
    takeLatest(taskTypeFormActions.submitTaskTypeForm.REQUEST, submitTaskTypeFormSaga),
  ]);
}

const submitTaskTypeFormSaga = createSaveFormWithValidation(taskTypeFormActions.submitTaskTypeForm, saveTaskTypeSaga);

export function* saveTaskTypeSaga({ type, payload }) {
  const taskTypeId = R.path(['ProcessType', 'ProcessTypeId'], payload);

  // New ProcessType created
  const taskTypeToSave = isNullOrUndef(taskTypeId)
    ? {
      ...payload,
      ProcessType: {
        ...payload.ProcessType,
        ProcessTypeId: -1,
      },
    }
    : payload;

  try {
    yield put(applicationActions.setLoading(type));
    yield call(taskTypeSagas.saveTaskTypeSaga, {
      type,
      payload: taskTypeToSave,
    });

    const modalType = yield select(state => modalSelectors.getModalType(state));
    if (modalType === modalTypes.TASKTYPE_FORM) {
      yield put(modalActions.hideModal());
    }
    yield put(taskTypeFormActions.submitTaskTypeForm.success());
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

const createNewProcessTypeFromExisting = R.applySpec({
  Code: R.prop('Code'),
  ProcessTypeId: () => null,
  Value: R.prop('Value'),
  UnitPrice: R.prop('UnitPrice'),
  UnitPriceCurrency: R.prop('UnitPriceCurrency'),
});

function* onAddButtonClickedSaga({ payload }) {
  const processTypeToCopy = yield select(state => getFormValue(state, 'ProcessType'));
  const newProcessType = createNewProcessTypeFromExisting(processTypeToCopy);
  yield put(change(formName, 'ProcessType', newProcessType));
  yield put(change(formName, 'ProcessTypeId', null));
}

function* onProcessTypeSelectedSaga({ payload }) {
  const { processTypeId } = payload;
  let processType = {};
  if (processTypeId) {
    processType = yield select(state => processTypeSelectors.getById(state)[processTypeId]);
  }
  yield put(change(formName, 'ProcessType', processType));
}
