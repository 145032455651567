import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col } from 'react-bootstrap';
import SubstanceDocumentsContainer from './_SubstanceDocumentsContainer';
import SubstanceUploadFileContainer from './_SubstanceUploadFileContainer';

const SubstanceDocumentsTab = ({ substanceId }) => (
  <div>
    <Grid fluid>
      <Col sm={10}>
        <SubstanceDocumentsContainer substanceId={substanceId} />
      </Col>
      <Col sm={2}>
        <SubstanceUploadFileContainer substanceId={substanceId} />
      </Col>
    </Grid>
  </div>
);

SubstanceDocumentsTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default SubstanceDocumentsTab;
