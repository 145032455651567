import { createRequestActions } from '../utils';
import * as workItemTypes from './workItemTypes';

export const listWorkItems = createRequestActions(
  workItemTypes.WORK_ITEM_LIST,
  payload => payload,
  (data, meta) => meta,
);
export const saveWorkItem = createRequestActions(workItemTypes.SAVE_WORK_ITEM, data => data);
export const deleteWorkItem = createRequestActions(workItemTypes.DELETE_WORK_ITEM, data => data);
