import React from 'react';
import PropTypes from 'prop-types';
import DistributorMandateListContainer from './_DistributorMandateListContainer';

const AgreeementsTab = props => {
  const { distributorId } = props;
  return (
    <div>
      <DistributorMandateListContainer distributorId={distributorId} />
    </div>
  );
};

AgreeementsTab.propTypes = {
  distributorId: PropTypes.number.isRequired,
};

export default AgreeementsTab;
