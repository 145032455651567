import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as productProportionTypes from './productProportionTypes';

export const fetchProductProportions = createRequestActions(
  productProportionTypes.FETCH_PRODUCT_PROPORTIONS,
  ({ ProductProportion: id, ...query } = {}) => {
    const result = {
      ...(id && { id }),
      ...(Object.keys(query).length > 0 && { query }),
    };
    return result;
  },
  (result, data) => data,
);

export const listProductProportionsForProduct = createRequestActions(
  productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT,
  ProductId => ({ ProductId }),
  (ProductProportions, ProductId) => ({ ProductId }),
);

export const updateMaxQty = createRequestActions(productProportionTypes.UPDATE_MAX_QTY);

// export const updateMaxQty = createFetchAction(productProportionTypes.UPDATE_MAX_QTY, 'ProductProportionId');

export const listProductProportionsForRawMat = createRequestActions(
  productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT,
  RawMatId => ({ RawMatId }),
  (ProductProportions, RawMatId) => ({ RawMatId }),
);

// export const addProductProportion = createAction(productProportionTypes.ADD_PRODUCT_PROPORTION, ProductId => ({
//   ProductId,
// }));
export const addProductProportion = createAction(productProportionTypes.ADD_PRODUCT_PROPORTION, (ProductId, RawMatId) => ({
  ProductId, RawMatId
}));
export const editProductProportion = createAction(
  productProportionTypes.EDIT_PRODUCT_PROPORTION,
  (ProductId, ProductProportionId) => ({ ProductId, ProductProportionId }),
);

export const saveProductProportions = createRequestActions(
  productProportionTypes.SAVE_PRODUCT_PROPORTIONS,
  (productId, data) => data,
  (productId, data) => ({
    productId,
  }),
);

export const selectProductProportionRM = createRequestActions(
  productProportionTypes.SELECT_PRODUCT_PROPORTION_RM,
  (productId, data) => data,
  (productId, data) => ({
    productId,
  }),
);

export const selectProductProportionRMSubmit = createRequestActions(
  productProportionTypes.SELECT_PRODUCT_PROPORTION_RM_SUBMIT,
  payload => payload,
);

export const addRmToProductAndGoToProduct = createRequestActions(
  productProportionTypes.ADD_RM_TO_PRODUCT_AND_GO_TO_PRODUCT,
  payload => payload,
);

export const reOrderProductProportions = createAction(
  productProportionTypes.REORDER_PRODUCT_PROPORTION,
  (productId, data) => data,
  (productId, data) => ({
    productId,
  }),
);
