// import * as productCpsrbCommentFormSelectors from './productCpsrbCommentFormSelectors';
import * as productCpsrbCommentFormActions from './productCpsrbCommentFormActions';
import * as productCpsrbCommentFormTypes from './productCpsrbCommentFormTypes';
import sagas from './productCpsrbCommentFormSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productCpsrbCommentFormActions, productCpsrbCommentFormTypes };
