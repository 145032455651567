import { takeEvery, put, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import * as orderFormTypes from './orderFormTypes';

export default function* root() {
  yield all([takeEvery(orderFormTypes.DUPLICATE_ORDER, duplicateOrderSaga)]);
}

function* duplicateOrderSaga({ payload, type }) {
  const { orderId } = payload;
  yield put(
    push({
      query: { duplicateFrom: orderId },
      pathname: '/orders/-1',
    }),
  );
}
