import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';

import rawMatSupplierApi from '../../api/rawMatSupplierApi';
import * as rawMatSupplierTypes from './rawMatSupplierTypes';
import * as rawMatSupplierActions from './rawMatSupplierActions';
import * as rawMatSupplierSchemas from './rawMatSupplierSchemas';

export default function* root() {
  yield all([takeLatest(rawMatSupplierTypes.RAWMAT_SUPPLIER_LIST.REQUEST, getRawMatSupplierListSaga)]);
}

function* getRawMatSupplierListSaga() {
  yield put(applicationActions.setLoading(rawMatSupplierTypes.RAWMAT_SUPPLIER_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    rawMatSupplierActions.listRawMatSuppliers,
    rawMatSupplierApi.getRawMatSupplierList,
    rawMatSupplierSchemas.rawMatSupplierListSchema,
  );
  yield put(applicationActions.unsetLoading(rawMatSupplierTypes.RAWMAT_SUPPLIER_LIST.REQUEST));
}
