import { takeLatest, put, all, select, take } from 'redux-saga/effects';
import { initialize, getFormValues } from 'redux-form';
import * as actionTypes from './actionTypes';
import { substanceActions, substanceSchemas } from '../../../../redux/substance';
import { applicationActions } from '../../../../redux/application';
import substanceApi from '../../../../api/substanceApi';
import { callApiSagaAndNormalize } from '../../../../redux/sagaUtils';
import { substanceProportionActions, substanceProportionTypes } from '../../../../redux/substanceProportion';
import { modalActions, modalTypes, modalSelectors } from '../../../../redux/modal';

export default function* root() {
  yield all([takeLatest(actionTypes.SUBSTANCE_SELECTED, onSubstanceSelectedSaga)]);
  yield all([takeLatest(actionTypes.SAVE_SUBSTANCE_PROPORTION_FORM, onsaveSubstanceProportionFormSaga)]);
}

function* onSubstanceSelectedSaga({ payload }) {
  const { SubstanceId } = payload;
  let substance = null;
  if (SubstanceId) {
    // using another type than the usual one to fecth a substance to avoid having the loader displayed in the background
    yield put(applicationActions.setLoading(actionTypes.FETCH_SELECTED_SUBSTANCE_DETAILS));
    substance = yield callApiSagaAndNormalize(
      substanceActions.substance,
      substanceApi.getSubstance,
      substanceSchemas.substanceSchema,
      SubstanceId,
    );
    yield put(applicationActions.unsetLoading(actionTypes.FETCH_SELECTED_SUBSTANCE_DETAILS));
    // yield put(substanceActions.substance.request(SubstanceId));
    // yield take(substanceTypes.SUBSTANCE.SUCCESS);
    // substance = yield select(state => substanceSelectors.getSubstance(state, SubstanceId));
  }
  yield put(
    initialize(
      'substanceProportionForm',
      {
        Substance: substance,
      },
      {
        keepDirty: true,
      },
    ),
  );
}

function* onsaveSubstanceProportionFormSaga({ payload }) {
  const { SubstanceId } = payload;
  const data = yield select(state => getFormValues('substanceProportionForm')(state));

  yield put(substanceProportionActions.saveSubstanceProportion.request({ ...data, SubstanceId }));
  yield take(substanceProportionTypes.SAVE_SUBSTANCE_PROPORTION.SUCCESS);
  yield put(substanceActions.listSubstanceProportions.request(SubstanceId));
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.SUBSTANCE_PROPORTION) {
    yield put(modalActions.hideModal());
  }
}
