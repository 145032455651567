import { createSelector } from 'reselect';
import { rawMatSelectors } from '../../rawMat';

const defaultRawMatFormValues = {
  IsActive: true,
};

export const makeGetInitialValues = createSelector(rawMatSelectors.getById, rawMatById =>
  createSelector(rawMatId => rawMatId, rawMatId => rawMatById[rawMatId] || defaultRawMatFormValues),
);
