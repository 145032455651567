import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as complianceReviewWizardTypes from './complianceReviewWizardTypes';

const complianceReviewsById = handleActions(
  {
    [complianceReviewWizardTypes.LOAD_COMPLIANCE_REVIEW_DATA]: (state, { payload }) => {
      return ({
        ...payload.entities.complianceReviews,
      })
    },
    [complianceReviewWizardTypes.RESET_COMMENT]: (state, { payload }) => {
      const { ComplianceReviewId } = payload;
      return {
        ...state,
        [ComplianceReviewId]: {
          ...state[ComplianceReviewId],
          Comment: null,
        },
      };
    },
    [complianceReviewWizardTypes.UPDATE_COMPLIANCE_REVIEW]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
  },
  {},
);

const complianceReviewsAllIds = handleActions(
  {
    [complianceReviewWizardTypes.LOAD_COMPLIANCE_REVIEW_DATA]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId: complianceReviewsById,
  list: combineReducers({
    allIds: complianceReviewsAllIds,
  }),
});
