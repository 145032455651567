import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../redux/modal';

class LeaveRouteDirtyWrapper extends PureComponent {
  static propTypes = {
    router: PropTypes.shape({
      setRouteLeaveHook: PropTypes.func,
    }).isRequired,
    route: PropTypes.object, //eslint-disable-line
    onRouteLeaveDirty: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isDirty: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.isInLeaveHook = false;
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log(nextProps);
  // }

  componentDidMount() {
    const { router, route, onRouteLeaveDirty } = this.props;
    // console.log(this.props);
    router.setRouteLeaveHook(route, routeContext => {
      // console.log(routeContext);
      if (this.props.isDirty && !this.isInLeaveHook && !this.props.submitting) {
        this.isInLeaveHook = true;
        onRouteLeaveDirty(routeContext.pathname, () => {
          // callback when modal is closed
          this.isInLeaveHook = false;
        });
        return false;
      }
      return true;
    });
  }

  render() {
    return this.props.children;
  }
}

export default connect(undefined, {
  onRouteLeaveDirty: modalActions.showLeaveRouteDirtyModal,
})(LeaveRouteDirtyWrapper);
