import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { substanceSelectors, substanceTypes, substanceActions } from '../../../../redux/substance';
import { CommentModal } from '../../../_Common/Modals';
import { workflowStatusSelectors } from '../../../../redux/workflowStatus';
import { applicationSelectors } from '../../../../redux/application';

const SubstanceStatusCommentModalContainer = props => <CommentModal {...props} />;

SubstanceStatusCommentModalContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  nextStatusCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { substanceId, nextStatusCode }) => {
  const substance = substanceSelectors.getById(state)[substanceId];
  const getStatusNameByCode = R.compose(
    R.propOr('', 'Name'),
    statusCode => workflowStatusSelectors.getById(state)[statusCode],
  );
  const currentStatusName = getStatusNameByCode(substance.Status);
  const nextStatusName = getStatusNameByCode(nextStatusCode);

  return {
    title: I18n.t('substanceStatusModal.title', { currentStatusName, nextStatusName }),
    isLoading: applicationSelectors.isLoading(state, substanceTypes.UPDATE_SUBSTANCE_STATUS.REQUEST),
    label: I18n.t('substanceStatusModal.label'),
  };
};

const mapDispatchToProps = (dispatch, { substanceId, nextStatusCode }) => ({
  onConfirm: Comment =>
    dispatch(substanceActions.updateStatus.request({ WorkflowStatusCode: nextStatusCode, Comment, substanceId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceStatusCommentModalContainer);
