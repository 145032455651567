import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import CpsrLoadTemplateModal from './CpsrLoadTemplateModal';
import { productSelectors, productTypes, productActions } from '../../../../redux/product';
import { applicationSelectors } from '../../../../redux/application';
import { productPhraseTypes } from '../../../../redux/productPhrase';
import * as actions from './actions';

// const CpsrLoadTemplateModalContainer = props => <CpsrLoadTemplateModal {...props} />;
class CpsrLoadTemplateModalContainer extends Component {

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <CpsrLoadTemplateModal {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  return {
    title: I18n.t('CpsrLoadTemplateModal.title', { productName }),
    saveButtonDisabled: applicationSelectors.isLoading(
      state,
      productTypes.PRODUCT_LIST.REQUEST,
      productPhraseTypes.COPY_PRODUCT_PHRASE_FROM_PRODUCT.REQUEST,
    ),
  };
};

const getSelectedProductId = R.compose(
  R.path(['data', 'ProductId']),
  R.head,
);

const mapDispatchToProps = (_, { productId, cpsrTypeCode, formName }) => {
  const onSaveButtonClick = actions.saveButtonClick({ productId, cpsrTypeCode, formName });
  return dispatch =>
  // const whenGridReady = g => {
  //   this.grid = g;
  // };

    ({
      onLoad: () => {
        dispatch(productActions.listProducts2.request());
      },
      onSaveButtonClick: lst => {
        dispatch(onSaveButtonClick(getSelectedProductId(lst)));
      },
      // whenGridReady,
    });
};

CpsrLoadTemplateModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CpsrLoadTemplateModalContainer);
