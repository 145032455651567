import PropTypes from 'prop-types';

const DurationCellRenderer = ({ value }) => {
  if (value === null) return null;
  const hours = Math.floor(value / 60);
  const minutes = (value - (hours * 60));
  return `${hours}h${('0' + minutes).slice(-2)}`;
};

DurationCellRenderer.defaultProps = {
  value: '',
};

DurationCellRenderer.propTypes = {
  value: PropTypes.number,
};

export default DurationCellRenderer;
