import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { referentialsTypes } from '../../../redux/referentials';
import { reportActions, reportActionTypes } from '../../../redux/report';
import { formulaReviewActions, formulaReviewSelectors } from '../../../redux/formulaReview';
import { inciListActions } from '../../../redux/inciList';
import {
  formulaReviewWizardActions,
  formulaReviewWizardSelectors,
  formulaReviewWizardTypes,
} from '../../../redux/wizard/formulaReviewWizard';
import FormulaReviewTab from './FormulaReviewTab';

class FormulaReviewContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <FormulaReviewTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId: ProductId }) => ({
  formulaReviewList: formulaReviewSelectors.makeGetByQuery(state)({ ProductId }),
  isLoadingFormulaReviewTab: applicationSelectors.getIsLoading(
    formulaReviewSelectors.makeGetByQueryLoading({ ProductId }),
    referentialsTypes.ALL_REFERENTIALS.REQUEST,
  )(state),
  getIsLoadingWizardForFormulaReview: FormulaReviewId => {
    return applicationSelectors.getIsLoading(
      formulaReviewWizardSelectors.getIsWizardLoading(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.REQUEST, {
        FormulaReviewId,
        ProductId,
      }),
    )(state);
  },
  getIsCopyLoadingWizardForFormulaReview: FormulaReviewId => {
    return applicationSelectors.getIsLoading(
      formulaReviewWizardSelectors.getIsWizardLoading(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST, {
        FormulaReviewId,
        ProductId,
      }),
    )(state);
  },
  isLoadingNewWizard: applicationSelectors.getIsLoading(
    formulaReviewWizardSelectors.getIsWizardLoading(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.REQUEST, {
      ProductId,
      include: { OptIncludeDetails: true },
    }),
  )(state),
  isLoadingFormulaReviewList: applicationSelectors.isLoading(
    state,
    reportActionTypes.FETCH_FORMULAREVIEW_REPORT.REQUEST,
    reportActionTypes.FETCH_FORMULAREVIEW_QUICKREPORT.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { productId: ProductId }) => ({
  onLoad: () => {
    if (ProductId > 0) {
      dispatch(inciListActions.getInciListForProduct.request(ProductId));
      dispatch(formulaReviewActions.fetchFormulaReviews.request({ ProductId }));
    }
  },
  getFormulaReviewListForProduct: () => dispatch(formulaReviewActions.fetchFormulaReviews.request({ ProductId })),
  onNewFormulaReviewClick: () =>
    dispatch(
      formulaReviewWizardActions.newFormulaReviewWizard.request({
        ProductId,
        include: { OptIncludeDetails: true },
      }),
    ),
  onFormulaReviewReportCompoPrintClick: ({ FormulaReviewId }) =>
    dispatch(reportActions.getFormulaReviewCompoReport.request(FormulaReviewId)),
  onFormulaReviewReportPrintClick: ({ FormulaReviewId }) =>
    dispatch(reportActions.getFormulaReviewReport.request(FormulaReviewId)),
  onFormulaReviewReportQuickPrintClick: ({ FormulaReviewId }) =>
    dispatch(reportActions.getFormulaReviewQuickReport.request(FormulaReviewId)),
  onEditFormulaReview: ({ FormulaReviewId }) =>
    dispatch(
      formulaReviewWizardActions.editFormulaReviewWizard.request({
        ProductId,
        FormulaReviewId,
        include: { OptIncludeDetails: true },
      }),
    ),
  onCopyFormulaReview: ({ FormulaReviewId }) =>
    dispatch(
      formulaReviewWizardActions.newFormulaReviewWizard.request({
        ProductId,
        FormulaReviewId,
        include: { OptIncludeDetails: true },
      }),
    ),
});

FormulaReviewContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormulaReviewContainer);
