import * as productActionSelectors from './productActionSelectors';
import * as productActionActions from './productActionActions';
import * as productActionTypes from './productActionTypes';
import sagas from './productActionSagas';
import reducer from './productActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productActionSelectors, productActionActions, productActionTypes };
export default reducer;
