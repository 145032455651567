import * as toxProfileChapterSelectors from './toxProfileChapterSelectors';
import * as toxProfileChapterActions from './toxProfileChapterActions';
import * as toxProfileChapterTypes from './toxProfileChapterTypes';
import sagas from './toxProfileChapterSagas';

import reducer from './toxProfileChapterReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { toxProfileChapterSelectors, toxProfileChapterActions, toxProfileChapterTypes };
export default reducer;
