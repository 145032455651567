import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { companyActions, companyTypes } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import { companyOrderDocumentActions } from '../../../redux/companyOrderDocument';
import CompanyOrderList from './CompanyOrderList';

class CompanyOrdersContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <CompanyOrderList {...this.props} />;
  }
}

const mapStateToProps = state => {
  const { company: { currentCompanyOrders } } = state;
  return {
    currentCompanyOrders,
    requestingCompanyOrderList: applicationSelectors.isLoading(state, companyTypes.COMPANY_ORDER_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => dispatch(companyActions.listCompanyOrders.request(companyId)),
  onRefresh: () => dispatch(companyActions.listCompanyOrders.request(companyId)),
  onRowDoubleClicked: order => dispatch(push(`/orders/${order.OrderId}`)),
  onUploadFile: data => {
    console.log('dispatch');
    dispatch(companyOrderDocumentActions.uploadCompanyOrderDocument.request(companyId, data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOrdersContainer);
