import React, { Component } from 'react';
import CompanyRawMatList from './CompanyRawMatList';
import LoadingButton from '../../_Common/LoadingButton';
import { I18n } from 'react-redux-i18n';
import { Radio } from 'react-bootstrap';
import { connect } from 'react-redux';
import GoToRmButton from "./GoToRmButton";
import ExportProductsToExcelButton from "./ExportProductsToExcelButton";

class CompanyRawMatsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'ALL',
    };
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.setState({ filter: 'ALL' });
    }
  }

  render () {
  const { onCopyRawMatClick, requestingCopyRawMat, onFilter, companyId, ...otherProps } = this.props;
  return (
    <div>
      <LoadingButton bsSize="small" onClick={onCopyRawMatClick} isLoading={requestingCopyRawMat}>
        {I18n.t('rawMat.copyRM')}
      </LoadingButton>&nbsp;&nbsp;&nbsp;
      <GoToRmButton companyId={companyId} filter={this.state.filter} />&nbsp;&nbsp;&nbsp;
      <Radio
        inline
        checked={this.state.filter === 'ALL'}
        onChange={() => {
          this.setState({ filter: 'ALL' });
          onFilter('ALL');
        }}
      >
        Show all
        {/* <Translate value="rawMat.filter.perfumes" /> */}
      </Radio>
      <Radio
        inline
        checked={this.state.filter === 'PERFUME'}
        onChange={() => {
          this.setState({ filter: 'PERFUME' });
          onFilter('PERFUME');
        }}
      >
        Show perfumes
        {/* <Translate value="rawMat.filter.perfumes" /> */}
      </Radio>
      <Radio
        inline
        checked={this.state.filter === 'EXTRACT'}
        onChange={() => {
          this.setState({ filter: 'EXTRACT' });
          onFilter('EXTRACT');
        }}
      >
        Show extracts
        {/* <Translate value="rawMat.filter.perfumes" /> */}
      </Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {/* <ExportProductsToExcelButton companyId={companyId} filter={this.state.filter} onClick={this.props.onExportToExcel} />&nbsp;&nbsp;&nbsp; */}
     <br />
      <br />
      <CompanyRawMatList {...otherProps} />
    </div>
  );
  }
};

export default CompanyRawMatsTab;
