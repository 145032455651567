import { createRequestTypes } from '../utils';

export const IMPACT_ASSESSMENT_LIST = createRequestTypes('impact_assessment/LIST_IMPACT_ASSESSMENTS');
export const IMPACT_ASSESSMENT_LIST_BY_PRODUCT = createRequestTypes(
  'impact_assessment/LIST_IMPACT_ASSESSMENTS_BY_PRODUCT',
);
export const IMPACT_ASSESSMENT = createRequestTypes('impact_assessment/IMPACT_ASSESSMENT');
export const SAVE_IMPACT_ASSESSMENT = createRequestTypes('impact_assessment/SAVE_IMPACT_ASSESSMENT');
export const UPDATE_IMPACT_ASSESSMENT = createRequestTypes('impact_assessment/UPDATE_IMPACT_ASSESSMENT');
export const UPDATE_IMPACT_ASSESSMENT_STATUS = createRequestTypes('impact_assessment/UPDATE_IMPACT_ASSESSMENT_STATUS');
export const NEW_IMPACT_ASSESSMENT = 'impact_assessment/NEW_IMPACT_ASSESSMENT';
export const LOAD_DETAIL_PAGE = 'impact_assessment/LOAD_DETAIL_PAGE';
export const SUBMIT_IMPACT_ASSESSMENT_FORM = 'impact_assessment/SUBMIT_IMPACT_ASSESSMENT_FORM';
export const UPATE_IMPACT_ASSESSMENT_CATEGORIES = 'impact_assessment/UPATE_IMPACT_ASSESSMENT_CATEGORIES';
export const SHOW_IMPACT_ASSESSMENT_ACTIONS_HISTORY = 'impact_assessment/SHOW_IMPACT_ASSESSMENT_ACTIONS_HISTORY';
