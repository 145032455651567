import React from 'react';
import PropTypes from 'prop-types';
import SubstanceProductListContainer from './_SubstanceProductListContainer';

const ProductListTab = ({ substanceId }) => (
  <div>
    <SubstanceProductListContainer substanceId={substanceId} />
  </div>
);

ProductListTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default ProductListTab;
