import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {Col, Form, Grid, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {InputField} from "../../Forms";
import {css} from "glamor";
import Select from "react-select";
import formNames from "../../../../constants/formNames";

const formName = formNames.substanceTR;

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  titleContainer: css({
    display: 'flex',
    justifyContent: "center",
    marginBottom: '10px',
    '& .Select': { width: '200px' },
  }),
};

const fields = [
  { field: 'DermalAbsorptionComment', label: 'substanceTRFormModal.DermalAbsorptionComment'},
  { field: 'IngestionAbsorptionComment', label: 'substanceTRFormModal.IngestionAbsorptionComment'},
  { field: 'InhalationAbsorptionComment', label: 'substanceTRFormModal.InhalationAbsorptionComment'},
  { field: 'DermalNoaelComment', label: 'substanceTRFormModal.DermalNoaelComment'},
  { field: 'IngestionNoaelComment', label: 'substanceTRFormModal.IngestionNoaelComment'},
  { field: 'InhalationNoaelComment', label: 'substanceTRFormModal.InhalationNoaelComment'},
]

class SubstanceTRForm extends Component {
  state = {
    selectedLanguage: this.props.languages.length > 0 ? this.props.languages[0].Alpha2 : '',
  };

  componentDidMount() {
    this.props.onLoad();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = option => {
    this.setState({ selectedLanguage: option });
  };

  render(){
    const {onSubmit, handleSubmit, languages} = this.props;
    const { selectedLanguage } = this.state;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <div className={styles.titleContainer}>
                <span><b><Translate value="phraseTranslation.english"/></b></span>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.titleContainer}>
                <Select
                  onChange={this.handleChange}
                  value={selectedLanguage}
                  options={languages}
                  labelKey="Value"
                  valueKey="Alpha2"
                />
              </div>
            </Col>
          </Row>
          {fields.map(field => (
            <Row key={field.field}>
              <Col sm={6}>
                <Field name={`substance.${field.field}`} label={I18n.t(`${field.label}`)} componentClass="textarea" component={InputField} readOnly/>
              </Col>
              <Col sm={6}>
                {selectedLanguage && selectedLanguage !== "" && (
                  <Field name={`${selectedLanguage}.${field.field}`} label={I18n.t(`${field.label}`)} componentClass="textarea" component={InputField}/>
                )}
              </Col>
            </Row>
          ))}
        </Grid>
      </Form>
    );
  }
};

SubstanceTRForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formName,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SubstanceTRForm);
