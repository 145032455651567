import { takeLatest, put, all, select, take } from 'redux-saga/effects';
import * as R from 'ramda';
import { applicationActions } from '../../application';
import { productSelectors } from '../../product';
import { companyActions, companyTypes } from '../../company';
import * as draftLabelFormTypes from './draftLabelFormTypes';

export default function* root() {
  yield all([takeLatest(draftLabelFormTypes.LOAD_FORM.REQUEST, loadDraftLabelFormSaga)]);
}

function* loadDraftLabelFormSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { productId } = payload;
  const product = yield select(state => productSelectors.getById(state)[productId]);
  const responsibleCompanyId = R.propOr(null, 'ResponsibleCompanyId', product);
  if (responsibleCompanyId) {
    yield put(companyActions.company.request(responsibleCompanyId));
    yield take(companyTypes.COMPANY.SUCCESS);
  }

  yield put(applicationActions.unsetLoading(type));
}
