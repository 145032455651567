import { schema } from 'normalizr';
import * as rawMatProportionSchema from '../rawMatProportion/rawMatProportionSchema';
import * as rawMatSupplierSchemas from '../rawMatSupplier/rawMatSupplierSchemas';
import * as companySchemas from '../company/companySchemas';

export const rawMatSchema = new schema.Entity(
  'rawMats',
  { Company: companySchemas.companySchema, RawMatSupplier: rawMatSupplierSchemas.rawMatSupplierSchema },
  { idAttribute: 'RawMatId' },
);
export const rawMatWithProportionsSchema = new schema.Entity(
  'rawMats',
  {
    RawMatProportions: rawMatProportionSchema.rawMatProportionWithSubstanceListSchema,
  },
  { idAttribute: 'RawMatId' },
);

export const rawMatListSchema = [rawMatSchema];
