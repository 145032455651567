import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { dissoc, without } from 'ramda';
import * as labelReviewSymbolWizardTypes from './labelReviewSymbolWizardTypes';

const labelReviewSymbolsById = handleActions(
  {
    [labelReviewSymbolWizardTypes.LOAD_LABEL_REVIEW_SYMBOL_DATA]: (state, { payload }) => ({
      ...payload.entities.labelReviewSymbols,
    }),
    [labelReviewSymbolWizardTypes.REMOVE_LABEL_REVIEW_SYMBOL]: (state, { payload }) => dissoc(payload, state),
    [labelReviewSymbolWizardTypes.ADD_NEW_LABEL_REVIEW_SYMBOL]: (state, { payload }) => {
      const { LabelReviewSymbolId } = payload;
      return {
        ...state,
        [LabelReviewSymbolId]: { ...payload },
      };
    },

    [labelReviewSymbolWizardTypes.UPDATE_LABEL_REVIEW_SYMBOL]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
  },
  {},
);

const labelReviewSymbolsAllIds = handleActions(
  {
    [labelReviewSymbolWizardTypes.ADD_NEW_LABEL_REVIEW_SYMBOL]: (state, { payload }) => [
      ...state,
      payload.LabelReviewSymbolId,
    ],
    [labelReviewSymbolWizardTypes.LOAD_LABEL_REVIEW_SYMBOL_DATA]: (state, { payload }) => payload.result,
    [labelReviewSymbolWizardTypes.REMOVE_LABEL_REVIEW_SYMBOL]: (state, { payload }) => without([payload], state),
  },
  [],
);

const deletedIds = handleActions(
  {
    [labelReviewSymbolWizardTypes.REMOVE_LABEL_REVIEW_SYMBOL]: (state, { payload }) => {
      if (!Number.isInteger(Number(payload))) return state;
      return [...new Set([...state, payload])];
    },
    [labelReviewSymbolWizardTypes.LOAD_LABEL_REVIEW_SYMBOL_DATA]: () => [],
  },
  [],
);

export default combineReducers({
  byId: labelReviewSymbolsById,
  list: combineReducers({
    allIds: labelReviewSymbolsAllIds,
    deletedIds,
  }),
});
