import { normalize } from 'normalizr';
import { takeLatest, put, all, select, takeEvery } from 'redux-saga/effects';
import uuidV1 from 'uuid/v1';
import * as formulaReviewWizardTypes from '../formulaReviewWizardTypes';
import * as complianceReviewSchemas from '../../../complianceReview/complianceReviewSchemas';
import * as complianceReviewWizardActions from './complianceReviewWizardActions';
import * as complianceReviewWizardTypes from './complianceReviewWizardTypes';
import { formulaReviewSelectors } from '../../../formulaReview';
import { complianceReviewSelectors } from '../../../complianceReview';
import { inciListSelectors } from '../../../inciList';

export default function* root() {
  yield all([
    takeLatest(formulaReviewWizardTypes.NEW_FORMULA_REVIEW_WIZARD.SUCCESS, newFomulaReviewWizardSaga),
    takeLatest(formulaReviewWizardTypes.EDIT_FORMULA_REVIEW_WIZARD.SUCCESS, editFomulaReviewWizardSaga),
    takeEvery(complianceReviewWizardTypes.UPDATE_COMPLIANCE_REVIEW, updateComplianceReviewWizardSaga),
  ]);
}

function* updateComplianceReviewWizardSaga({ payload }) {
  if (Object.prototype.hasOwnProperty.call(payload, 'IsOk')) {
    const { id, IsOk } = payload;
    if (IsOk === true) {
      yield put(complianceReviewWizardActions.resetComment(id));
    }
  }
}

function* editFomulaReviewWizardSaga({ payload }) {
  const { FormulaReviewId } = payload;

  const complianceReviewList = yield select(state =>
    complianceReviewSelectors.getComplianceReviewListForFormulaReview(state, FormulaReviewId),
  );

  const result = normalize(complianceReviewList, complianceReviewSchemas.complianceReviewListSchema);

  yield put(complianceReviewWizardActions.loadComplianceReviewData(result));
}

function* newFomulaReviewWizardSaga({ payload }) {
  const { ProductId, FormulaReviewId } = payload;

  const formulaReviewForCopy = yield select(state =>
    formulaReviewSelectors.getById(state)[FormulaReviewId],
  );

  const latestFormulaReviewComplianceReviewList = formulaReviewForCopy
    ? yield select(state =>
      complianceReviewSelectors.getComplianceReviewListForFormulaReview(state, formulaReviewForCopy.FormulaReviewId),
    )
    : [];

  const complianceReviewList = yield select(state =>
    inciListSelectors.getInciSubstancesForProduct(state, ProductId).map(({ SubstanceId, PercentageInProduct, Mos }) => {
      const complianceReview = latestFormulaReviewComplianceReviewList.find(cr => cr.SubstanceId === SubstanceId) || {
        SubstanceId,
        IsOk: true,
      };
      return {
        ...complianceReview,
        Percentage: PercentageInProduct,
        Mos,
        ComplianceReviewId: uuidV1(),
      };
    }),
  );

  const result = normalize(complianceReviewList, complianceReviewSchemas.complianceReviewListSchema);

  yield put(complianceReviewWizardActions.loadComplianceReviewData(result));
}
