import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
// import * as R from 'ramda';
import { GeneralGrid, EditDeleteButtonRenderer } from '../../_Common/Grids';
import RegionCellRenderer from './RegionCellRenderer';
// import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import PriceCellRenderer from '../../_Common/Grids/PriceCellRenderer';

const OrderProcessList = props => {
  const {
    // isReadOnly,
    currentOrderItems,
    isLoading,
    getOrderItems,
    onDeleteClick,
    onEditButtonClick,
    onProcessEditButtonClick,
    onProcessDeleteButtonClick,
    onProcessAddButtonClick,
    onAddButtonClick,
    onRowDoubleClicked,
    currencies,
    currentOrderProcessInfo,
    exchangeRate,
    orderId,
  } = props;

  const columns = [
    {
      headerName: I18n.t('orderProcess.type'),
      field: 'ProcessTypeValue',
      width: 75,
      // sort: 'asc',
    },
    {
      headerName: I18n.t('processType.code'),
      field: 'ProcessTypeCode',
      width: 75,
      // sort: 'asc',
    },
    {
      headerName: I18n.t('taskType.region'),
      field: 'Region',
      width: 80,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('taskType.product'),
      cellRendererFramework: RegionCellRenderer,
      cellRendererParams: {
        onProcessEditButtonClick: data => onProcessEditButtonClick(data),
        onProcessDeleteButtonClick: ({ ProcessId }) => onProcessDeleteButtonClick(ProcessId),
        onProcessAddButtonClick: data => onProcessAddButtonClick(data.OrderItemId),
      },
      cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
      valueGetter: ({ data }) => (data.ProductCode !== null ? data.ProductCode : I18n.t('orderProcess.unknown')),
    },
    {
      headerName: I18n.t('orderProcess.quantity'),
      field: 'Quantity',
      width: 45,
      suppressSizeToFit: true,
      suppressFilter: true,
    },
    {
      headerName: I18n.t('orderProcess.shadeCount'),
      field: 'ShadeCount',
      width: 50,
      suppressSizeToFit: true,
      suppressFilter: true,
    },
    {
      headerName: I18n.t('orderProcess.actualShadeCount'),
      field: 'ActualShadeCount',
      width: 50,
      suppressSizeToFit: true,
      suppressFilter: true,
    },
    {
      headerName: I18n.t('orderProcess.currency'),
      field: 'CurrencyCode',
      width: 25,
      suppressFilter: true
    },
    {
      headerName: I18n.t('taskType.unitPrice'),
      field: 'UnitPrice',
      width: 95,
      suppressSizeToFit: true,
      cellRenderer: PriceCellRenderer,
      cellRendererParams: {
        currencies,
        currencyLabel: 'CurrencyCode',
        exchangeRate: exchangeRate
      },
    },
    {
      headerName: I18n.t('orderProcess.discountPercent'),
      field: 'Discount',
      width: 55,
      suppressSizeToFit: true,
      suppressFilter: true
    },
    {
      headerName: I18n.t('orderProcess.subtotal'),
      field: 'Subtotal',
      width: 75,
      suppressSizeToFit: true,
      cellRenderer: PriceCellRenderer,
      cellRendererParams: {
        currencies,
        currencyLabel: 'CurrencyCode',
        exchangeRate: exchangeRate
      },
    },
    {
      headerName: I18n.t('orderProcess.progress'),
      field: 'Progress',
      width: 55,
      suppressSizeToFit: true,
      suppressFilter: true
    },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: EditDeleteButtonRenderer,
      suppressSizeToFit: true,
      width: 120,
      cellRendererParams: {
        onDelete: ({ OrderItemId }) => onDeleteClick(OrderItemId),
        onEdit: ({ OrderItemId }) => onEditButtonClick(OrderItemId),
      },
    },
  ];

  const getNodeChildDetails = rowItem => {
    if (rowItem.Processes) {
      const children = rowItem.Processes.map(process => ({ ...process }));
      if (!children.find(elem => elem.ProcessId === -1)) {
        children.push({ ProcessId: -1, Id: `Process-1-${rowItem.OrderItemId}`, OrderItemId: rowItem.OrderItemId });
      }
      var shadeCnt = 0;
      children.map(item => {
        shadeCnt += item.ShadeCount?item.ShadeCount:0;
        item.ActualShadeCount = item.ShadeCount;
        item.ShadeCount = undefined;
      });
      rowItem.ActualShadeCount = shadeCnt;
      return {
        group: true,
        expanded: true,
        children: children,
        key: rowItem.Id,
      };
    }
    return null;
  };

  currentOrderItems.forEach(element => {
    element.Children = element.Processes;
  });

  return (
    <GeneralGrid
      key={`process-${orderId}`}
      data={currentOrderItems}
      columns={columns}
      getNodeChildDetails={getNodeChildDetails}
      keyName="Id"
      isLoading={isLoading}
      onRefresh={getOrderItems}
      // rowDoubleClicked={onRowDoubleClicked}
      onAdd={onAddButtonClick}
      showAddButton={currentOrderProcessInfo != null && currentOrderProcessInfo.CurrencyCode !== null}
      showExpandCollapseButtons
      deltaRowDataMode={false}
      rowDoubleClicked={onRowDoubleClicked}
    />
  );
};

OrderProcessList.propTypes = {
  currentOrderItems: PropTypes.arrayOf(
    PropTypes.shape({
      OrderItemId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getOrderItems: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  // workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
};

export default OrderProcessList;
