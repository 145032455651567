import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Col, Row } from 'react-bootstrap';
import { css } from 'glamor';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import images from '../../../assets/img/clp';
import { isNumberField } from '../../../utils/fieldValidation';
import BaseLoader from '../../_Common/BaseLoader';
import { CheckBoxField, InputField } from '../../_Common/Forms';

const styles = {
  form: css({
    '& hr': {
      marginTop: '5px',
      marginBottom: '5px',
      borderTop: '1px dashed #8c8b8b',
      borderBottom: '1px dashed #fff',
    },
  }),
};

const renderField = (BaseComponent, { ...otherProps }) => fieldName => (
  <Field name={fieldName} label={I18n.t(`substanceClp.${fieldName}`)} component={BaseComponent} {...otherProps} />
);

const renderCheckBox = renderField(CheckBoxField, { formAlignment: false });
const renderInputField = renderField(InputField, { horizontal: true, validate: isNumberField });

const SubstanceClpForm = props => {
  const { isLoading, handleSubmit, onSubmit } = props;
  return (
    <BaseLoader isLoading={isLoading}>
      <Grid fluid>
        <div className={styles.form}>
          <Form horizontal onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={4}>
                {renderInputField('ClpThreshold')}
              </Col>
            </Row>
            <Row>
              <Col sm={1}>{renderCheckBox('IsClassified')}</Col>
              <Col sm={2} smOffset={3}>
                {I18n.t('substanceClp.SpecialConcentrationLimits')}
              </Col>
            </Row>
            <Row>
              <Col sm={1}>
                {/* Image */}
                <img src={images.corrosive} alt="corrosive" />
              </Col>
              <Col sm={2}>
                {renderCheckBox('SkinEyeCorrosionCat1A')}
                {renderCheckBox('SkinEyeCorrosionCat1B')}
                {renderCheckBox('SkinEyeCorrosionCat1C')}
              </Col>
              <Col sm={2}>
                {renderInputField('Skin1A')}
                {renderInputField('Skin1B')}
              </Col>
            </Row>
            <Col sm={12}>
              <hr />
            </Col>
            <Row>
              <Col sm={1}>
                {/* Image */}
                <img src={images.irritant} alt="irritant" />
              </Col>
              <Col sm={2}>
                <Col smOffset={0}>
                  {renderCheckBox('SkinIrritantCat2')}
                  <Col smOffset={1}>{renderCheckBox('SkinIrritantCat3')}</Col>
                </Col>
              </Col>
              <Col sm={2}>
                {renderInputField('Skin2')}
                {renderInputField('Skin3')}
              </Col>
              <Col sm={10} smOffset={2}>
                <hr />
              </Col>
              <Col sm={2} smOffset={1}>
                {renderCheckBox('EyeDamageCat1')}
                <Col smOffset={1}>
                  {renderCheckBox('EyeIrritantCat2A')}
                  <Col smOffset={1}>{renderCheckBox('EyeIrritantCat2B')}</Col>
                </Col>
              </Col>
              <Col sm={2}>
                {renderInputField('Eye1')}
                {renderInputField('Eye2')}
              </Col>
              <Col sm={10} smOffset={2}>
                <hr />
              </Col>
              <Col sm={2} smOffset={1}>
                {renderCheckBox('StotRespirIrritantCat2')}
              </Col>
              <Col sm={2}>{renderInputField('Inhal2')}</Col>
              <Col sm={10} smOffset={2}>
                <hr />
              </Col>
              <Col sm={2} smOffset={1}>
                {renderCheckBox('SkinSensitizer')}
              </Col>
              <Col sm={2}>{renderInputField('SkinSensitizerLimit')}</Col>
            </Row>
            <Col sm={12}>
              <hr />
            </Col>
            <Row>
              <Col sm={1}>
                {/* Image */}
                <img src={images.inhalationSensitizer} alt="inhalationSensitizer" />
              </Col>
              <Col sm={2}>{renderCheckBox('InhalationSensitizer')}</Col>
            </Row>
          </Form>
        </div>
      </Grid>
    </BaseLoader>
  );
};

SubstanceClpForm.defaultProps = {
  isLoading: false,
};

SubstanceClpForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SubstanceClpForm;
