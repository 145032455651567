import * as rawMatPhraseSelectors from './rawMatPhraseSelectors';
import * as rawMatPhraseActions from './rawMatPhraseActions';
import * as rawMatPhraseTypes from './rawMatPhraseTypes';
import sagas from './rawMatPhraseSagas';
import reducer from './rawMatPhraseReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { rawMatPhraseSelectors, rawMatPhraseActions, rawMatPhraseTypes };
export default reducer;
