import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../redux/application';
import {
    toxProfileChapterActions,
    toxProfileChapterTypes,
    toxProfileChapterSelectors,
  } from '../../redux/toxProfileChapter';
import ToxProfileStructure from './ToxProfileStructure';

class ToxProfileStructurePageContainer extends PureComponent {
  
    componentDidMount() {
        this.props.onLoad();
    }
    
    render() {
        return (
            <ToxProfileStructure {...this.props} />
        )
    }
// } = props => <ToxProfileStructure {...props} />;
}

function mapStateToProps (state) {
    return {
        isLoading: applicationSelectors.getIsLoading(
            toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.REQUEST
        )(state),
        treeStructure: buildStructure(state),
        chapters: toxProfileChapterSelectors.getToxProfileChapterList(state)
    }
};

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onLoad: () => {
            dispatch(toxProfileChapterActions.listToxProfilChapter.request());
        },
    }
};

const buildStructure = (state) => {
    let chapters = toxProfileChapterSelectors.getToxProfileChapterList(state)
    let structure = [];
    chapters.map(function(node) {
        if (!node.ParentChapterCode) {
            let item = {
                id: node.Code, name: node.Title, expanded: true, child: [], order: node.Order
            }
            structure.push(item)
        }
    })
    chapters.map(function (node) {
        if (node.ParentChapterCode) {
            var chapter = structure.find(function(item) {
                return (item.id == node.ParentChapterCode)});
            var child = {
                id: node.Code, name: node.Title, expanded: true, child: [], order: node.Order
            }
            chapter.child.push(child);
        }
    })
    return structure;
};

ToxProfileStructurePageContainer.propTypes = {
    onLoad: PropTypes.func.isRequired,
};

ToxProfileStructurePageContainer.defaultProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (ToxProfileStructurePageContainer);