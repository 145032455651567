import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { categorySelectors } from '../../../../redux/category';
import {isPristine, isSubmitting, reset, submit} from "redux-form";
import {productTRFormActions, productTRFormSelectors} from "../../../../redux/form/productTRForm";
import {referentialSelectors} from "../../../../redux/referentials";
import ProductTRModal from "./ProductTRModal";
import formNames from "../../../../constants/formNames";
import {applicationSelectors} from "../../../../redux/application";
import {labelReviewTypes} from "../../../../redux/labelReview";
import {productTRActions, productTRTypes} from "../../../../redux/productTR";

const formName = formNames.productTR;
const ProductTRModalContainer = props => (
  <ProductTRModal {...props}/>
);

const mapStateToProps = (state, {ProductId}) => {
  const isFormSubmitting = isSubmitting(formName)(state);
  const initialFormValues = productTRFormSelectors.getInitialValues(state, ProductId);
  const isFormPristine = isPristine(formName)(state);
  return {
    title: `${I18n.t('productTRFormModal.title')}`,
    initialValues: initialFormValues,
    languages: referentialSelectors.getLanguageList(state).filter(elem => elem.Alpha2 !== 'en'),
    isSaveDisabled: isFormSubmitting || isFormPristine,
    isLoading: applicationSelectors.isLoading(state, productTRTypes.PRODUCT_TR_LIST.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, {ProductId}) => {
  return ({
    onLoad: () => {
      dispatch(productTRActions.listProductTR.request({ ProductId }));
    },
    onSaveButtonClick: () => dispatch(submit(formName)),
    onSubmit: data => productTRFormActions.submitProductTRForm(data, dispatch),
    onCancelButtonClick: () => {
      dispatch(reset(formName));
    },
  });
};

ProductTRModalContainer.defaultProps = {
  categoryId: undefined,
};

ProductTRModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTRModalContainer);
