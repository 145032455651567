import { categorySelectors } from '../../category';
import { categoryTRSelectors } from '../../categoryTR';
import {referentialSelectors} from "../../referentials";

export const getInitialValues = (state, code) => {
  const category = categorySelectors.getCategoryByCode(state, code);
  const categoryTR = categoryTRSelectors.getCategoryByCode(state, code);
  const languageList = referentialSelectors.getLanguageList(state);
  const values = {
    category
  }
  languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
    const catForLang = categoryTR.find(elem => elem.LanguageCode === lang.Alpha2);
    values[lang.Alpha2] = catForLang ? catForLang : {
      CategoryCode: code,
      LanguageCode: lang.Alpha2,
    }
  });
  return values;
};
