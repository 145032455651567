import * as substanceClpSelectors from './substanceClpSelectors';
import * as substanceClpActions from './substanceClpActions';
import * as substanceClpTypes from './substanceClpTypes';
import sagas from './substanceClpSagas';
import reducer from './substanceClpReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceClpSelectors, substanceClpActions, substanceClpTypes };
export default reducer;
