import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { substanceRawMatActions, substanceRawMatTypes } from './index';
import { rawMatSchemas } from '../rawMat';
import substanceApi from '../../api/substanceApi';

export default function* root() {
  yield all([takeLatest(substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE.REQUEST, fetchSubstanceRawMatSaga)]);
}

export function* fetchSubstanceRawMatSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { SubstanceId } = payload;
    const rawMatList = yield call(substanceApi.getRawMatsForSubstance, SubstanceId);
    const normalizedData = normalize(rawMatList, rawMatSchemas.rawMatListSchema);
    yield put(substanceRawMatActions.fetchRawMatsForSubstance.success(normalizedData, SubstanceId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceRawMatActions.fetchRawMatsForSubstance.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
