import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';

// We should use a sateful component otherwise we get an error. See: https://github.com/ag-grid/ag-grid/issues/1490
// It happens with ag-grid 12.0. It's probably fixed in a more recent version.
// eslint-disable-next-line
class CheckListItemActionRenderer extends React.Component {
  render() {
    const { node, data, onClick, productId } = this.props;

    return (
      <div>
      { data.text === '-HasFormula' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('Formula')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasProductCofa' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('ProductCofa')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasInPackagingStability' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('InPackagingStability')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasChallengeTest' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('ChallengeTest')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasMicrobiologicalQuality' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('MicrobiologicalQuality')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasProductSpfTest' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('ProductSpfTest')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
            { data.text === '-HasDermatologicalIrritationTests' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('DermatologicalIrritationTests')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
      { data.text === '-HasOcularIrritationTests' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('OcularIrritationTests')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasPrimaryLabel' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('PrimaryLabel')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasSecondaryLabel' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('SecondaryLabel')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasBatchNr' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('BatchNr')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasPackagingProcedure' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('PackagingProcedure')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasMetrologyStatement' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('MetrologyStatement')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasPackagingInfoLabel' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('PackagingInfoLabel')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasCompatibilityPackaging' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('CompatibilityPackaging')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasOutPackagingStability' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('OutPackagingStability')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasProductMsds' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('ProductMsds')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasManufacturingMethod' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('ManufacturingMethod')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasQC' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('QC')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasUndesirableEffects' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('UndesirableEffects')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}

      { data.text === '-HasNonAnimalTestingStatement' && (
        <tr>
          <td>
            <Button bsSize="small" onClick={() => onClick('NonAnimalTestingStatement')}>
              <Translate value="lastFRCheckList.received" />
            </Button>
          </td>
        </tr>
      )}
  </div>
  );
  }
}

CheckListItemActionRenderer.defaultProps = {
  value: '',
  colDef: {
    headerName: '',
  },
};

CheckListItemActionRenderer.propTypes = {
  value: PropTypes.string,
  colDef: PropTypes.shape({
    headerName: PropTypes.string,
  }),
};

export default CheckListItemActionRenderer;
