import * as shadeProportionSelectors from './shadeProportionSelectors';
import * as shadeProportionActions from './shadeProportionActions';
import * as shadeProportionTypes from './shadeProportionTypes';
import * as shadeProportionSchemas from './shadeProportionSchemas';
import sagas, * as shadeProportionSagas from './shadeProportionSagas';
import reducer from './shadeProportionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  shadeProportionSelectors,
  shadeProportionActions,
  shadeProportionTypes,
  shadeProportionSchemas,
  shadeProportionSagas,
};
export default reducer;
