import PropTypes from 'prop-types';
import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, HelpBlock, Radio } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { I18n } from 'react-redux-i18n';

const LabelReviewValidationForm = props => {
  const { validatedOn, isValidated, sentOn, validationComment, dateFormat, updateFor, errors } = props;
  return (
    <Grid fluid>
      <Col sm={12}>
        <Form horizontal>
          <FormGroup controlId="validationDate" validationState={errors.validatedOn.isError ? 'error' : null}>
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.validationDate')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass={DatePicker}
                onChange={updateFor('validatedOn')}
                // onBlur={({ target }) => console.log(target)}
                selected={validatedOn}
                dateFormat={dateFormat}
              />
              {errors.validatedOn.isError && <HelpBlock>{errors.validatedOn.description}</HelpBlock>}
            </Col>
          </FormGroup>
          <FormGroup controlId="sendingDate" validationState={errors.sentOn.isError ? 'error' : null}>
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.sendingDate')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass={DatePicker}
                onChange={updateFor('sentOn')}
                // onBlur={({ target }) => updateFor('sentOn')(target.value)}
                selected={sentOn}
                dateFormat={dateFormat}
              />
              {errors.sentOn.isError && <HelpBlock>{errors.sentOn.description}</HelpBlock>}
            </Col>
          </FormGroup>
          <FormGroup controlId="validationComment">
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.comment')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass="textarea"
                value={validationComment}
                onChange={({ target }) => updateFor('validationComment')(target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="validated">
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.validate')}
            </Col>
            <Col sm={8}>
              <Radio
                name="validatedRadioGroup"
                checked={!isValidated}
                onChange={() => updateFor('isValidated')(false)}
                inline
              >
                {I18n.t('general.no')}
              </Radio>
              <Radio
                name="validatedRadioGroup"
                checked={isValidated}
                onChange={() => updateFor('isValidated')(true)}
                inline
              >
                {I18n.t('general.yes')}
              </Radio>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Grid>
  );
};

LabelReviewValidationForm.defaultProps = {
  dateFormat: 'DD/MM/YYYY',
  validationComment: undefined,
  errors: {},
};

LabelReviewValidationForm.propTypes = {
  dateFormat: PropTypes.string,
  errors: PropTypes.object.isRequired, //eslint-disable-line
  updateFor: PropTypes.func.isRequired,
  validationComment: PropTypes.string,
  isValidated: PropTypes.bool.isRequired,
  sentOn: PropTypes.object, //eslint-disable-line
  validatedOn: PropTypes.object, //eslint-disable-line
};

export default LabelReviewValidationForm;
