import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ProductProportionSelectRMModal from './ProductProportionSelectRMModal';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { productProportionActions, productProportionTypes } from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import {
  rawMatProportionActions,
  rawMatProportionTypes,
  rawMatProportionSelectors,
} from '../../../../redux/rawMatProportion';
import { productActions, productSelectors } from '../../../../redux/product';
import { push } from 'react-router-redux';

const ProductProportionSelectRMModalContainer = props =>
  <ProductProportionSelectRMModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  return {
    title: I18n.t('productProportionSelectRMModal.title', { productName }),
    isSaveDisabled: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SELECT_PRODUCT_PROPORTION_RM.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId, importedProductProportionId }) => ({
  onSave: data => {
    // productProportionFormActions.submitProductProportionForm({ ...data, productId }, dispatch)
  },
  getIsShowCompoLoading: () => false,
  onShowCompo: data => {
    dispatch(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId: data.RawMatId,
        include: {
          OptIncludeSubstance: true,
        },
      }),
    );
  },
  onShowCompoDX: data => {
    dispatch(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId: data.row.data.RawMatId,
        include: {
          OptIncludeSubstance: true,
        },
      }),
    );
  },
  addToProduct: data => dispatch(productProportionActions.selectProductProportionRMSubmit.request({
    productId,
    importedProductProportionId,
    rawMatId: data.RawMatId,
  })),
  addToProductDX: data => {
    dispatch(productProportionActions.selectProductProportionRMSubmit.request({
      productId,
      importedProductProportionId,
      rawMatId: data.row.data.RawMatId,
    }));
    dispatch(productActions.listProductImportedProductProportions.request(productId));
  },
  onCreateRMButtonClick: () => {
    dispatch(push({ pathname: '/rawmats/-1', query: { returnToProduct: productId, tab: importedProductProportionId?200:3, importedProductProportionId: importedProductProportionId }}))
  },
  // onCreateRMButtonClick: data = {
  //   dispatch(push({ pathname: '/rawmats/-1', query: { returnToProduct: productId, tab: 200, importedProductProportionId: data }}));
  // },
  getIsAddToProductLoading: () => false,
});

ProductProportionSelectRMModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  // rawMatList: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductProportionSelectRMModalContainer);
