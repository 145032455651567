import * as labelReviewSelectors from './labelReviewSelectors';
import * as labelReviewActions from './labelReviewActions';
import * as labelReviewTypes from './labelReviewTypes';
import * as labelReviewSchemas from './labelReviewSchemas';
import sagas, * as labelReviewSagas from './labelReviewSagas';
import reducer from './labelReviewReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { labelReviewSelectors, labelReviewActions, labelReviewTypes, labelReviewSchemas, labelReviewSagas };
export default reducer;
