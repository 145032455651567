import React, { Component } from 'react';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import SubstanceProportionModal from './SubstanceProportionModal';
import { substanceSelectors, substanceActions, substanceTypes } from '../../../../redux/substance';
import { substanceProportionTypes } from '../../../../redux/substanceProportion';
import * as selector from './selectors';
import * as actions from './actions';
import { applicationSelectors } from '../../../../redux/application';

class SubstanceProportionFormContainer extends Component {
  render() {
    return <SubstanceProportionModal {...this.props} />;
  }
}

const mapStateToProps = (state, { SubstanceId, SubstanceProportionId }) => {
  const currentSubstance = substanceSelectors.getSubstance(state, SubstanceId);
  return {
    currentSubstanceName: currentSubstance ? currentSubstance.InciName : '',
    isLoading: applicationSelectors.isLoading(
      state,
      substanceProportionTypes.SUBSTANCE_PROPORTION_LIST.REQUEST,
      substanceProportionTypes.SAVE_SUBSTANCE_PROPORTION.REQUEST,
    ),
    isFecthingSubstanceDetails: applicationSelectors.isLoading(state, substanceTypes.SUBSTANCE.REQUEST),
    substanceList: selector.getSubstanceList(state, SubstanceId, SubstanceProportionId),
    isSubmittable: selector.isSubmittable(state),
    selectedSubstanceId: selector.getSelectedSubstanceId(state),
    substanceDetails: selector.getSelectedSubstanceDetails(state),
    initialValues: selector.getSubstanceProportion(state, SubstanceProportionId),
    substanceListFilter: selector.getSubstanceListFilter(state, SubstanceId, SubstanceProportionId),
    sortByInciName: substanceSelectors.sortByInciName,
    substanceProportionId: SubstanceProportionId,
  };
};

const mapDispatchToProps = (dispatch, { SubstanceId }) => ({
  onSave: () => dispatch(actions.saveSubstanceProportionForm(SubstanceId)),
  onSubmitButtonClick: () => dispatch(submit('substanceProportionForm')),
  fetchSubstance: id => dispatch(substanceActions.substance.request(id)),
  onSubstanceSelected: substanceId => dispatch(actions.substanceSelected(substanceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubstanceProportionFormContainer);
