import { createAction } from 'redux-actions';
import * as labelReviewItemWizardTypes from './labelReviewItemWizardTypes';
import { createUpdateAction } from '../../../../utils/actions';

export const loadLabelReviewItemData = createAction(labelReviewItemWizardTypes.LOAD_LABEL_REVIEW_ITEM_DATA);
export const editComment = createAction(labelReviewItemWizardTypes.EDIT_COMMENT, (LabelReviewItemId, Comment) => ({
  LabelReviewItemId,
  Comment,
}));
export const resetComment = createAction(labelReviewItemWizardTypes.RESET_COMMENT, LabelReviewItemId => ({
  LabelReviewItemId,
}));
export const updateLabelReviewItem = createUpdateAction(labelReviewItemWizardTypes.UPDATE_LABEL_REVIEW_ITEM_DATA);
