import { createSelector } from 'reselect';
import * as R from 'ramda';
import { isDirty } from 'redux-form';
import DuplicateModes from './DuplicateModes';
import formNames from '../../../constants/formNames';
import * as productSelectors from '../../product/productSelectors';
import { routingSelectors } from '../../routing';

const formName = formNames.product;

const defaultInitialValue = {
  IsActive: true,
  // ProducerId: null,
  // ManufacturerId: null,
};

// const getInitialValues = productId =>
//   createSelector([productSelectors.getById], productById => productById[productId] || defaultInitialValue);

// export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues);

export const getInDuplicateMode = createSelector(
  routingSelectors.getQuery,
  R.propSatisfies(duplicateFromId => Number.isInteger(Number.parseInt(duplicateFromId, 10)), 'duplicateFrom'),
);

export const getDuplicateFromId = createSelector(routingSelectors.getQuery, R.propOr(null, 'duplicateFrom'));
export const getDuplicateMode = createSelector(routingSelectors.getQuery, R.propOr(null, 'mode'));
export const getDuplicateOrderNr = createSelector(routingSelectors.getQuery, R.propOr(null, 'orderNr'));

const createCopy = R.evolve({
  ProductId: () => -1,
  CreatedOn: () => null,
  CreatedBy: () => null,
  Code: () => null,
  ValidatedOn: () => null,
  ValidatedBy: () => null,
  CpsrAStatus: () => null,
  CpsrBStatus: () => null,
  CosmosProductCode: () => null,
  IsValidated: () => false,
  CpsrBConclusionStatus: () => null,
  CompositionStatus: () => null,
});

const removeCPNPInfoFromProduct = R.evolve({
  IsWaitingNotification: () => null,
  IsNotified: () => null,
  NotifiedOn: () => null,
  NotificationCode: () => null,
});

const removeCPNPInfoFromProductForReplace = R.evolve({
  IsWaitingNotification: () => null,
  IsNotified: () => null,
  NotifiedOn: () => null,
//  NotificationCode: () => null,
});

const createDuplicate = R.cond([
  [
    R.equals(DuplicateModes.duplicate),
    R.always(
      R.compose(
        removeCPNPInfoFromProduct,
        createCopy,
        product => ({
          ...product,
          parentProductId: -1,
        }),
      ),
    ),
  ],
  [
    R.T,
    R.always(
      R.compose(
        removeCPNPInfoFromProductForReplace,
        createCopy,
        product => ({
          ...product,
          parentProductId: product.ProductId,
        }),
      ),
    ),
  ],
]);

export const makeGetInitialValues = createSelector(
  [productSelectors.getById, getDuplicateFromId, getDuplicateMode],
  (productById, duplicateFromId, duplicateMode) =>
    createSelector(R.identity, productId => {
      const product = duplicateFromId ? productById[duplicateFromId] : productById[productId];
      if (!product) return defaultInitialValue;
      return duplicateFromId ? createDuplicate(duplicateMode)(product) : product;
    }),
);

export const isFormDirty = isDirty(formName);
