import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import { css } from 'glamor';
import {Button, ButtonToolbar, Col} from 'react-bootstrap';
import { EditButton, DeleteButton } from '../../_Common/Grids';
import LoadingButton from '../../_Common/LoadingButton';

const EditDeleteButtonRenderer = props => {
  const { onEdit, data, onViewDetails, isEditDisable, isViewDetailsDisable, isLoadingDetails, onDelete, onTranslateClick } = props;

  const handleOnEdit = () => onEdit(data);
  const handleOnViewDetails = () => onViewDetails(data);
  const handleOnDelete = () => onDelete(data);
  const handleOnTranslate = () => onTranslateClick(data);

  return (
    <div {...css({ display: 'flex', justifyContent: 'center', width: 240 })}>
      <ButtonToolbar>
        <EditButton onClick={handleOnEdit} disabled={isEditDisable} />
        <Button bsSize="xsmall" onClick={handleOnTranslate}>
          <span>{I18n.t('productTRFormModal.translateButton')}</span>
        </Button>
        <LoadingButton
          bsSize="xsmall"
          onClick={handleOnViewDetails}
          disabled={isViewDetailsDisable}
          isLoading={isLoadingDetails}
        >
          <i className="fa fa-list" aria-hidden="true" />
          &nbsp;
          <Translate value="general.details" />
        </LoadingButton>
        <DeleteButton onClick={handleOnDelete} />
      </ButtonToolbar>
    </div>
  );
};

EditDeleteButtonRenderer.defaultProps = {
  node: {},
  isEditDisable: false,
  isViewDetailsDisable: false,
};

EditDeleteButtonRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onEdit: PropTypes.func.isRequired,
  onViewDetails: PropTypes.func.isRequired,
  isEditDisable: PropTypes.bool,
  isViewDetailsDisable: PropTypes.bool,
  isLoadingDetails: PropTypes.bool.isRequired,
};

export default EditDeleteButtonRenderer;
