import queryString from 'query-string';
import { get, post, download, del } from './apiUtils';

const defaultUrl = '/CompaniesDistributorsAgreements/';
const distributorUrl = '/Distributors/';
const agreementsUrl = '/Agreements/';

export default {
  getCompanyDistributorAgreementList: id => get(`${distributorUrl}${id}${agreementsUrl}`),
  getCompanyDistributorAgreements: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  getCompanyDistributorAgreement: ( id, filename ) => download(`${defaultUrl}${id}/File`, filename),
  saveCompanyDistributorAgreement: data => post(`${defaultUrl}`, data),
  generateAgreement: id => post(`${defaultUrl}/GenerateAgreement?CompanyDistributorAgreementId=${id}`),
  markAsSentAgreement: id => post(`${defaultUrl}/MarkAsSentAgreement?CompanyDistributorAgreementId=${id}`),
  markAsSignedAgreement: id => post(`${defaultUrl}/MarkAsSignedAgreement?CompanyDistributorAgreementId=${id}`),
  revokeAgreement: id => post(`${defaultUrl}/RevokeAgreement?CompanyDistributorAgreementId=${id}`),
  removeAgreement: id => del(`${defaultUrl}${id}`),
};
