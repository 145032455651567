import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { applicationSelectors } from '../../../redux/application';
import { substanceTypes } from '../../../redux/substance';
import { referentialSelectors } from '../../../redux/referentials';
import { substanceToxProfileActions } from '../../../redux/substanceToxProfile';
import { modalActions } from '../../../redux/modal';
import MosAndToxForm from './MosAndToxForm';
import { isNumberField } from '../../../utils/fieldValidation';
import formNames from '../../../constants/formNames';
import {substanceFormActions} from "../../../redux/form/substanceForm";

class MosAndToxFormContainer extends Component {
  componentDidMount() {
    this.props.onLoad(this.props.substanceId);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad(this.props.substanceId);
    }
  }

  render() {
    return <MosAndToxForm {...this.props} />;
  }
}

const mapStateToProps = state => ({
  isLoading: applicationSelectors.isLoading(
    state,
    substanceTypes.SAVE_SUBSTANCE.REQUEST,
    substanceTypes.SUBSTANCE.REQUEST,
  ),
  uomList: referentialSelectors.getUoms(state),
  cramerClasseList: referentialSelectors.getCramerClasses(state),
});

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceToxProfileActions.fetchSubstanceToxProfiles.request({ substanceId }));
    }
  },
  onSubstanceToxProfileButttonClick: () => dispatch(modalActions.showSubstanceToxProfileModal({ substanceId })),
  onCramerClasseSelected: code => dispatch(substanceFormActions.onCramerClasseSelected(code)),
});

const validate = values => {
  const errors = {};
  const dermalAbsorptionError = isNumberField(values.DermalAbsorption);
  if (dermalAbsorptionError) {
    errors.DermalAbsorption = dermalAbsorptionError;
  } else if (!values.DermalAbsorptionUom) {
    errors.DermalAbsorption = I18n.t('form.uomRequiredField');
  }
  return errors;
};

MosAndToxFormContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    form: formNames.substance,
    enableReinitialize: true,
    destroyOnUnmount: true,
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(MosAndToxFormContainer);
