import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { phraseSelectors, phraseActions, phraseTypes } from '../../../../redux/phrase';
import { applicationSelectors } from '../../../../redux/application';
import cpsrTypes from '../../../../constants/cpsrTypes';
import { GeneralGrid } from '../../../_Common/Grids';

const InforRmPhraseList = props => {
  const { input: { value, onChange, name }, phraseList, fetchPhraseList, isLoading } = props;
  const columns = [
    {
      headerName: I18n.t('phrase.phraseCode'),
      field: 'PhraseCode',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('phrase.text'),
      field: 'Text',
    },
  ];

  const handleOnSelect = ({ [name]: id }) => onChange(id);

  return (
    <GeneralGrid
      data={phraseList}
      selectedId={value}
      height="50vh"
      columns={columns}
      keyName={name}
      onRefresh={fetchPhraseList}
      isLoading={isLoading}
      showAddButton={false}
      onSelectedData={handleOnSelect}
    />
  );
};

const mapStateToProps = () => state => {
  const getPhraseList = phraseSelectors.makeGetPhraseListForCpsrTypeCode(cpsrTypes.INFORM);
  return {
    phraseList: getPhraseList(state),
    isLoading: applicationSelectors.isLoading(state, phraseTypes.PHRASE_LIST.REQUEST),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPhraseList: () => dispatch(phraseActions.listPhrases.request({ CpsrTypeCode: cpsrTypes.INFORM })),
});

InforRmPhraseList.propTypes = {
  fetchPhraseList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  phraseList: PropTypes.array.isRequired, // eslint-disable-line
};

InforRmPhraseList.defaultProps = {
  value: '',
  phraseList: [],
  isLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(InforRmPhraseList);
