import * as orderActionSelectors from './orderActionSelectors';
import * as orderActionActions from './orderActionActions';
import * as orderActionTypes from './orderActionTypes';
import sagas from './orderActionSagas';
import reducer from './orderActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { orderActionSelectors, orderActionActions, orderActionTypes };
export default reducer;
