import * as R from 'ramda';
import { get, post, del, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/CosAnnexes/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getCosAnnexes: R.compose(
    get,
    getUrl,
  ),
  deleteCosAnnexes: id => del(`${defaultUrl}${id}`),
  saveCosAnnexes: data => post(`${defaultUrl}`, data),
};
