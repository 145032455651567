import queryString from 'query-string';
import * as R from 'ramda';
import { get, post, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/LabelReviews/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getLabelReviews: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveLabelReview: data => post(defaultUrl, data),
  fetchLabelReviews: R.compose(
    get,
    getUrl,
  ),
};
