import workflowStatus from '../../../constants/workflowStatus';

export function GenerateTextForClipboard(product, itemList1, itemList2, itemList3) {

  let text = '';
  if (itemList1.length + itemList2.length + itemList3.length > 0) {
    text = product.Code + ' ' + product.ProductName + '\n ';
    itemList1.forEach(item => {
      text = text + '\t\t- ' + item.text + '\n ';
    });
    itemList2.forEach(item => {
      text = text + '\t\t- ' + item.text + '\n ';
    });
    itemList3.forEach(item => {
      text = text + '\t\t- ' + item.AnnexePartCode + '    for ' + item.RawMatName +  '\n ';
    });
  }

  return text;
}

export function MissingMandatoryInfoForCPSR(lastFRProductData, productId) {
  const itemList = [];
  if (lastFRProductData.FormulaStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'FormulaStatus',
      productId: productId,
      status: lastFRProductData.FormulaStatus
    });
  }
  if (lastFRProductData.ProductCofaStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'ProductCofaStatus',
      productId: productId,
      status: lastFRProductData.ProductCofaStatus
    });
  }
  if (lastFRProductData.InPackagingStabilityStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'InPackagingStabilityStatus',
      productId: productId,
      status: lastFRProductData.InPackagingStabilityStatus
    });
  }

  if (lastFRProductData.ChallengeTestStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED && !lastFRProductData.IsChallengeTestNotMandatory) {
    itemList.push({
      text: 'ChallengeTestStatus',
      productId: productId,
      status: lastFRProductData.ChallengeTestStatus
    });
  }
  if (lastFRProductData.MicrobiologicalQualityStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED && !lastFRProductData.IsMicrobiologicalQualityNotMandatory) {
    itemList.push({
      text: 'MicrobiologicalQualityStatus',
      productId: productId,
      status: lastFRProductData.MicrobiologicalQualityStatus
    });
  }
  if (lastFRProductData.ProductSpfTestStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED && !lastFRProductData.IsProductSpfTestNotMandatory) {
    itemList.push({
      text: 'ProductSpfTestStatus',
      productId: productId,
      status: lastFRProductData.ProductSpfTestStatus
    });
  }
  return itemList;
}

export function MissingMandatoryInfoForLR(lastFRProductData, productId) {
  const itemList = [];
  if (lastFRProductData.PrimaryLabelStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({text: 'PrimaryLabelStatus', productId: productId, status: lastFRProductData.PrimaryLabelStatus});
  }
  if (lastFRProductData.SecondaryLabelStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED && !lastFRProductData.IsSecondaryLabelNotMandatory) {
    itemList.push({text: 'SecondaryLabelStatus', productId: productId, status: lastFRProductData.SecondaryLabelStatus});
  }
  if (lastFRProductData.BatchNrStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({text: 'BatchNrStatus', productId: productId, status: lastFRProductData.BatchNrStatus});
  }
  if (lastFRProductData.MetrologyStatementStatus !== workflowStatus.DOCUMENT_RECEIVED_VERIFIED) {
    itemList.push({
      text: 'MetrologyStatementStatus',
      productId: productId,
      status: lastFRProductData.MetrologyStatementStatus
    });
  }
  return itemList;
}
