import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const MeasureCellRenderer = props => {
  const { value, uom, nbDecimal, uomField, data } = props;
  if (isNullOrUndef(value)) return null;
  const uomToReturn = uomField ? data[uomField] : uom;
  let valueToReturn = null;
  if (isNullOrUndef(nbDecimal)) {
    valueToReturn = value;
  } else {
    valueToReturn = nbDecimal > 0 ? parseFloat(Number(value).toFixed(nbDecimal)) : Math.round(value);
  }
  return `${valueToReturn} ${uomToReturn || ''}`;
};

MeasureCellRenderer.propTypes = {
  uom: PropTypes.string.isRequired,
  params: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  nbDecimal: PropTypes.number,
  uomField: PropTypes.string,
};

export default MeasureCellRenderer;
