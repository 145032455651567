import { handleActions } from 'redux-actions';
import { merge } from 'lodash';
import { AUTHENTICATE, LOGOUT } from './userTypes';
import { userRoleTypes } from '../userRole';

export const initialState = {
  currentUser: undefined,
  error: '',
  errorDescription: '',
  byId: {},
  list: {
    allIds: [],
  },
};

export default handleActions(
  {
    [AUTHENTICATE.SUCCESS]: (state, { payload }) => ({ ...initialState, currentUser: payload }),
    [AUTHENTICATE.FAILURE]: (state, { payload: { error, errorDescription } }) => ({
      ...initialState,
      error,
      errorDescription,
    }),
    [userRoleTypes.USER_ROLE_LIST_FOR_ROLES.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.users),
      list: {
        ...state.list,
        allIds: [...new Set([...state.list.allIds, ...Object.keys(payload.entities.users).map(Number)])],
      },
    }),
    [LOGOUT]: () => initialState,
  },
  initialState,
);
