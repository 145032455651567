import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, LinkCellRenderer, PercentageCellRenderer, EditDeleteButtonRenderer } from '../../_Common/Grids';

const getYN = ({ data, colDef }) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always(''));

const getLevel1TotalPercentage = R.compose(
  R.sum,
  R.pluck('PercentageInRawMat'),
);

const getColorFromRowLevel = R.cond([
  [R.equals(0), R.always('#FFFFFF')],
  [R.equals(1), R.always('#EEEEEE')],
  [R.equals(2), R.always('#DDDDDD')],
  [R.T, R.always('')],
]);

const setBackgroundFromColor = R.objOf('background');

const getRowStyle = R.compose(
  setBackgroundFromColor,
  getColorFromRowLevel,
  R.path(['node', 'level']),
);

class RawMatCompositionList extends Component {
  componentDidUpdate(prevProps) {
    if (this.grid) {
      this.setPinnedRowBottomData(prevProps.currentRawMatComposition);
    }
  }

  setGrid = g => {
    this.grid = g;
    this.setPinnedRowBottomData(this.props.currentRawMatComposition);
  };

  setPinnedRowBottomData = rawMatProportions => {
    if (this.grid) {
      this.grid.api.setPinnedBottomRowData([{ PercentageInRawMat: getLevel1TotalPercentage(rawMatProportions) }]);
    }
  };

  defaultColumns = {
    editable: ({ node }) => node.group,
  };

  getColumns = () => {
    const {
      onEdit,
      onDelete,
      functionById,
      isRawMatCompositionEditable,
    } = this.props;
    return [
      // {
      //   headerName: I18n.t('substance.inciName'),
      //   field: 'InciName',
      //   cellRenderer: 'group',
      //   width: 350,
      //   minWidth: 250,
      //   suppressSizeToFit: true,
      //   cellRendererParams: {
      //     suppressCount: true
      //   },
      // },
      {
        headerName: I18n.t('substance.inciName'),
        field: 'InciName',
        cellRendererFramework: LinkCellRenderer,
        width: 350,
        minWidth: 250,
        cellRendererParams: {
          textKey: 'InciName',
          linkKey: 'Url',
          downloadable: false
        },
        suppressSizeToFit: true,
      },
      {
        headerName: I18n.t('general.quantityInParent'),
        field: 'Percentage',
        cellRenderer: PercentageCellRenderer,
        width: 175,
      },
      {
        headerName: I18n.t('general.quantityInRawMat'),
        field: 'PercentageInRawMat',
        sort: 'desc',
        cellRenderer: PercentageCellRenderer,
        valueGetter:({data}) => {
          if (data.Type === "complexSubstance") {
            data.Children.map(child => child.PercentageInRawMat = data.Percentage * child.Percentage / 100);
          };
          return data.PercentageInRawMat;
        },
        width: 175,
      },
      {
        headerName: I18n.t('rawMatProportion.quantityRange'),
        valueGetter: R.compose(
          R.join(' '),
          R.props(['PercentageRange', 'PercentageRangeComment']),
          R.prop('data'),
        ),
      },
      {
        headerName: I18n.t('substance.function'),
        valueGetter: ({ data }) => R.pathOr('', [data.FunctionId, 'Name'])(functionById),
      },
      {
        headerName: I18n.t('rawMatProportion.isAllergen'),
        field: 'IsAllergen',
        valueGetter: getYN,
        width: 100,
      },
      {
        headerName: I18n.t('rawMatProportion.isNano'),
        field: 'IsNano',
        valueGetter: getYN,
        width: 100,
      },
      {
        headerName: I18n.t('rawMatProportion.isNonNano'),
        field: 'IsNonNanoVerified',
        valueGetter: getYN,
        width: 100,
      },
      {
        headerName: I18n.t('rawMatProportion.isImpurity'),
        field: 'IsImpurity',
        valueGetter: getYN,
        width: 100,
      },
      {
        headerName: I18n.t('rawMatProportion.isIngredient'),
        field: 'IsIngredient',
        valueGetter: getYN,
        width: 100,
      },
      {
        headerName: I18n.t('rawMatProportion.quantityForInciCalculation'),
        field: 'PercentageForInciCalculation',
        cellRenderer: PercentageCellRenderer,
        width: 120,
      },
      {
        headerName: I18n.t('general.comment'),
        field: 'Comment',
      },
      {
        headerName: I18n.t('general.actions'),
        width: 120,
        minWidth: 120,
        cellRendererFramework: EditDeleteButtonRenderer,
        cellRendererParams: {
          onDelete: data => onDelete(data.RawMatProportionId),
          onEdit: data => onEdit(data.RawMatId, data.RawMatProportionId),
          isDeleteDisable: !isRawMatCompositionEditable,
          isEditDisable: !isRawMatCompositionEditable,
        },
      },
    ];
  }

  getNodeChildDetails = rowItem => {
    if (rowItem.Type === 'complexSubstance') {
      return {
        group: true,
        expanded: true,
        children: rowItem.Children,
        key: rowItem.InciName,
      };
    }
    return null;
  };

  render() {
    const {
      currentRawMatComposition,
      getRawMatProportions,
      isLoadingRawMatCompositionList,
      onAdd,
      isAddDisabled,
      isRawMatCompositionEditable,
      rawMatid
    } = this.props;

  
    currentRawMatComposition.forEach(element => {
      element.Url = '#substances/' + element.SubstanceId;
    });

    return (
      <SelectionGrid
        key={`${rawMatid}-${currentRawMatComposition.length}`}
        data={currentRawMatComposition}
        columns={this.getColumns()}
        isLoading={isLoadingRawMatCompositionList}
        onRefresh={getRawMatProportions}
        getNodeChildDetails={this.getNodeChildDetails}
        keyName="RawMatProportionId"
        defaultColDef={this.defaultColumns}
        onAdd={onAdd}
        addDisabled={isAddDisabled || !isRawMatCompositionEditable}
        deltaRowDataMode={false}
        whenGridReady={this.setGrid}
        getRowStyle={getRowStyle}
      />
    );
  }
}

RawMatCompositionList.defaultProps = {
  isAddDisabled: false,
  functionById: {},
};

RawMatCompositionList.propTypes = {
  currentRawMatComposition: PropTypes.array.isRequired, //eslint-disable-line
  getRawMatProportions: PropTypes.func.isRequired,
  isLoadingRawMatCompositionList: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isAddDisabled: PropTypes.bool,
  functionById: PropTypes.shape({
    FunctionId: PropTypes.number,
    Name: PropTypes.string,
  }),
};

export default RawMatCompositionList;
