import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as substanceProportionTypes from './substanceProportionTypes';

export const listSubstanceProportions = createRequestActions(substanceProportionTypes.SUBSTANCE_PROPORTION_LIST);

export const saveSubstanceProportion = createRequestActions(substanceProportionTypes.SAVE_SUBSTANCE_PROPORTION);
export const deleteSubstanceProportion = createRequestActions(
  substanceProportionTypes.DELETE_SUBSTANCE_PROPORTION,
  SubstanceProportionId => ({
    SubstanceProportionId,
  }),
);

export const addSubstanceProportion = createAction(substanceProportionTypes.ADD_SUBSTANCE_PROPORTION, SubstanceId => ({
  SubstanceId,
}));
export const editSubstanceProportion = createAction(
  substanceProportionTypes.EDIT_SUBSTANCE_PROPORTION,
  (SubstanceId, SubstanceProportionId) => ({ SubstanceId, SubstanceProportionId }),
);
