import _ from 'lodash';
import * as R from 'ramda';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import inciCalculationTypes from '../../constants/inciCalculationTypes';
import productAuthorizationCodes from '../../constants/productAuthorizationCodes';
import workflowStatus from '../../constants/workflowStatus';
import { isNullOrUndef } from '../../utils/utils';
import { companySelectors } from '../company';
import { workflowStatusSelectors } from '../workflowStatus';
import { createAuthorizationCheck, createGeneralSelectors } from '../_common/selectors';
import { impactAssessmentProductSelectors } from '../impactAssessmentProduct';
import { PrefixIndexStrategy } from 'js-search';
import createFilterOptions from 'react-select-fast-filter-options';
import {getWorkflowStatusListByFinalCode} from "../workflowStatus/workflowStatusSelectors";

const detailFormValueSelector = formValueSelector('productForm');

export const productTitle = state => {
  const productName = detailFormValueSelector(state, 'ProductName') || '';
  const code = detailFormValueSelector(state, 'Code') || '';
  return `${code} - ${productName}`;
};

export function isNew(state) {
  const id = detailFormValueSelector(state, 'ProductId');
  return id <= 0 || isNullOrUndef(id);
}

export const getCategoryList = state => state.referentials.ProductCategories;
export const getCurrentProduct = state => state.product.currentProduct;
export const getCurrentProdigumProductProportions = state => state.product.currentProdigumProductProportions;
export const getCurrentImportedProductProportions = state => state.product.currentImportedProductProportions;
export const getCurrentProductProportions = state => state.product.currentProductProportions;
export const getOrdersOwnedByCurrentProductCompany = state => state.product.ordersOwnedByCurrentProductCompany;
export const getCurrentProductOrders = state => state.product.currentProductOrders;
export const getCurrentProductCategories = state => state.product.currentProductCategories;
export const getCurrentProductTasks = state => state.product.currentProductTasks;
export const getCurrentProductImpactAssessments = state => state.product.currentProductImpactAssessments;
export const getCurrentProductCosmosIngredientRawMatMatches = state =>
  state.product.currentProductCosmosIngredientRawMatMatches;
export const getCurrentLastFrCheckList = state => state.product.currentLastFrCheckList;
export const getCurrentLastFrMissingDocument = state => state.product.currentLastFrMissingDocument;

const getProductSlice = state => state.product;

export const { getById, getIds, makeGetItemById: createGetProductById } = createGeneralSelectors(getProductSlice);

export const getProduct = (state, id) => getById(state)[id];
export const getProductList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const makeGetProductById = createSelector(getById, productById =>
  createSelector(productId => productById[productId], R.identity),
);

export const getCurrentProductOrdersWithStatus = createSelector([getCurrentProductOrders, workflowStatusSelectors.getWorkflowStatusListByFinalCode],
  (orders, statusByCode) => {
    return orders.map(order => {
      const status = statusByCode(order.Status);
      return ({
        ...order,
        StatusName: status.Name
      })
    });
  })

export const getByCompanyIds = ({ product }) => product.list.byCompanyIds;

export const makeGetInciCalculationType = createSelector(
  [state => companySelectors.getById(state), getById],
  (companyById, byId) =>
    createSelector(R.identity, productId => {
      const product = byId[productId];
      const company = companyById[product.ProducerId];
      return product.InciRangeInd
        ? inciCalculationTypes.SPEC
        : R.propOr(inciCalculationTypes.ALPHA, 'InciCalculationType', company);
    }),
);

const getAuthorizationCodes = R.propOr([], 'AuthorizationCodes');

const createMakeGetAuthorizationChek = createAuthorizationCheck(createGetProductById)(getAuthorizationCodes);

const productPrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetProductListFilter = createSelector(R.identity, productList =>
  createFilterOptions({
    options: productList,
    labelKey: 'FullName',
    valueKey: 'ProductId',
    indexStrategy: productPrefixIndexStrategy,
  }),
);

export const makeIsProductEditable = createMakeGetAuthorizationChek([productAuthorizationCodes.EDIT_PRODUCT]);
export const makeIsProductProportionEditable = createMakeGetAuthorizationChek([productAuthorizationCodes.EDIT_COMPO]);
export const makeIsCpsrAEditable = createMakeGetAuthorizationChek([productAuthorizationCodes.EDIT_CPSRA]);
export const makeIsCpsrBEditable = createMakeGetAuthorizationChek([productAuthorizationCodes.EDIT_CPSRB]);

const getIdsForCompany = companyId =>
  createSelector(getByCompanyIds, byCompanyIds => R.defaultTo([], byCompanyIds[companyId]));

const getProductListWithoutProductId = productId =>
  createSelector(getProductList, R.reject(R.propEq('ProductId', productId)));

const getProductListWithManufacturerAndCPSR = productId =>
  createSelector(getProductList, R.reject(R.propEq('ProductId', productId)));

const getProductsForCompany = companyId =>
  createSelector([getIdsForCompany(companyId), getById], (IdsForCompany, byId) => IdsForCompany.map(id => byId[id]));

const getProductsForImpactAssessment = impactAssessmentId =>
  createSelector(
    [
      state =>
        impactAssessmentProductSelectors.getImpactAssessmentProductsForImpactAssessment(state)(impactAssessmentId),
      getById,
    ],
    (ImpactAssessmentProducts, byId) => ImpactAssessmentProducts.map(IAProd => byId[IAProd.productId]),
  );

export const makeGetProductsForCompany = createSelector(getProductsForCompany, R.identity);

export const makeGetProductListWithoutProductId = createSelector(
  getProductListWithoutProductId,
  productList => productList,
);

export const makeGetProductListWithManufacturerAndCPSR = createSelector(
  getProductListWithManufacturerAndCPSR,
  productList => productList,
);

export const makeHasProductCpnp = createSelector(makeGetProductById, getProductById =>
  createSelector(
    productById => getProductById(productById),
    R.compose(
      R.all(R.allPass([R.complement(R.isEmpty), R.complement(R.isNil), R.complement(R.equals(false))])),
      R.props(['NotifiedOn', 'IsNotified', 'NotificationCode']),
    ),
  ),
);

export const makeIsProductCompoValidated = createSelector(makeGetProductById, getProductById =>
  createSelector(
    productById => getProductById(productById),
    product => product.CompositionStatus === workflowStatus.PRODUCT_COMPO_VALIDATED,
  ),
);

export const getCurrentProductStatus = state => {
  const currentProduct = getCurrentProduct(state);
  const productWorkflowStatus = workflowStatusSelectors.getWorkflowStatusListByCode('PRODUCT')(state);
  if (productWorkflowStatus.length) {
    return currentProduct.IsValidated ? productWorkflowStatus[1].Code : productWorkflowStatus[0].Code;
  }
  return undefined;
};

export const getCurrentProductCompositionStatus = state => getCurrentProduct(state).CompositionStatus;

export const getFilteredProductList = (state, filter) => {
  const { companyId, impactAssessmentId } = filter;
  if (companyId !== undefined) {
    return getProductList(state).filter(p => p.ProducerId === companyId);
  }
  if (impactAssessmentId !== undefined) {
    return getProductsForImpactAssessment(impactAssessmentId);
  }
  return getProductList(state);
};

export const getAvailableOrdersForCurrentProduct = createSelector(
  [getCurrentProductOrders, getOrdersOwnedByCurrentProductCompany],
  (currentProductOrders, OrdersOwnedByCurrentProductCompany) =>
    _.differenceWith(
      OrdersOwnedByCurrentProductCompany,
      currentProductOrders,
      (arr1, arr2) => arr1.OrderId === arr2.OrderId,
    ),
);

export const getProdigumProductProportionsNotMatched = createSelector(
  [getCurrentProdigumProductProportions, getCurrentProductProportions],
  (currentProdigumProductProportions, currentProductProportions) =>
    _.differenceWith(
      currentProdigumProductProportions,
      currentProductProportions,
      (arr1, arr2) => arr1.ProportionId === arr2.CosmosProportionId,
    ),
);

export const getImportedProductProportionsNotMatched = createSelector(
  [getCurrentImportedProductProportions, getCurrentProductProportions],
  (currentImportedProductProportions, currentProductProportions) =>
    _.differenceWith(
      currentImportedProductProportions,
      currentProductProportions,
      (arr1, arr2) => arr1.ProductProportionId === arr2.ProductProportionId,
    ),
);

export const getProductProportionsMatched = createSelector([getCurrentProductProportions], currentProductProportions =>
  currentProductProportions.filter(cp => cp.CosmosProportionId !== null),
);

export const getProductProportionsNotMatched = createSelector(
  [getCurrentProductProportions],
  currentProductProportions => currentProductProportions.filter(cp => cp.CosmosProportionId === null),
);

export const getCERTProductProportionsMatched = createSelector([getCurrentProductProportions], currentImportedProductProportions =>
  currentImportedProductProportions.filter(cp => cp.ProductProportionId !== null),
);

export const getCERTProductProportionsNotMatched = createSelector(
  [getCurrentProductProportions],
  currentImportedProductProportions => currentImportedProductProportions.filter(cp => cp.ProductProportionId === null),
);

export const getComosCompositionList = createSelector(
  [
    getProdigumProductProportionsNotMatched,
    getProductProportionsMatched,
    getProductProportionsNotMatched,
    getCurrentProduct,
    getCurrentProdigumProductProportions,
    getCurrentProductCosmosIngredientRawMatMatches,
    state => state.rawMat.list.allIds.map(id => state.rawMat.byId[id]),
  ],
  (
    prodigumProductProportionsNotMatched,
    productProportionsMatched,
    productProportionsNotMatched,
    currentProduct,
    currentProdigumProductProportions,
    currentProductCosmosIngredientRawMatMatches,
    rawMatList,
  ) => {
    const hasPercentageChanged = productProportion => {
      const prodigumProductProportion = getProdigumProductProportionByProductProportion(productProportion);
      if (prodigumProductProportion === undefined) return false;
      return prodigumProductProportion.BeforeTransfoPct !== productProportion.CosmosPercentageOnLastMatch;
    };

    const getProdigumProductProportionByProductProportion = productProportion =>
      currentProdigumProductProportions.find(pp => pp.ProportionId === productProportion.CosmosProportionId);

    const getMatchedRawMatBycosmosIngredientId = cosmosIngredientId =>
      currentProductCosmosIngredientRawMatMatches.find(elem => elem.CosmosIngredientId === cosmosIngredientId);

    const getProposedRawMat = prodigumProductProportion => {
      const MatchedRawMat = getMatchedRawMatBycosmosIngredientId(prodigumProductProportion.ReferencedId);

      const proposedRawMat =
        MatchedRawMat !== undefined
          ? MatchedRawMat
          : rawMatList.find(r => r.RawMatId === prodigumProductProportion.CorrespondingRawMatId);

      return proposedRawMat;
    };

    const prodigumProductProportionsNotMatchedList = prodigumProductProportionsNotMatched.map(item => ({
      productId: currentProduct.ProductId,
      prodigumProductProportion: item,
      productProportion: null,
      hasPercentageChanged: false,
      rawMatId: _.get(getProposedRawMat(item), 'RawMatId', undefined),
      percentage: item.BeforeTransfoPct.toString(),
    }));

    const productProportionsMatchedList = productProportionsMatched.map(item => ({
      productId: currentProduct.ProductId,
      prodigumProductProportion: getProdigumProductProportionByProductProportion(item),
      productProportion: item,
      hasPercentageChanged: hasPercentageChanged(item),
      rawMatId: item.RawMatId,
      percentage: item.Percentage.toString(),
    }));

    const productProportionsNotMatchedList = productProportionsNotMatched.map(item => ({
      productId: currentProduct.ProductId,
      prodigumProductProportion: null,
      productProportion: item,
      hasPercentageChanged: false,
      rawMatId: item.RawMatId,
      percentage: item.Percentage.toString(),
    }));

    return [
      ...prodigumProductProportionsNotMatchedList,
      ...productProportionsNotMatchedList,
      ...productProportionsMatchedList,
    ];
  },
);

export const getImportedCompositionList = createSelector(
  [
    getImportedProductProportionsNotMatched,
    getCERTProductProportionsMatched,
    getCERTProductProportionsNotMatched,
    getCurrentProduct,
    getCurrentImportedProductProportions,
    getCurrentProductCosmosIngredientRawMatMatches,
    state => state.rawMat.list.allIds.map(id => state.rawMat.byId[id]),
  ],
  (
    prodigumProductProportionsNotMatched,
    productProportionsMatched,
    productProportionsNotMatched,
    currentProduct,
    currentImportedProductProportions,
    currentProductCosmosIngredientRawMatMatches,
    rawMatList,
  ) => {
    const hasPercentageChanged = productProportion => {
      const importedProductProportion = getImportedProductProportionByProductProportion(productProportion);
      if (importedProductProportion === undefined) return false;
      return importedProductProportion.BeforeTransfoPct !== productProportion.CosmosPercentageOnLastMatch;
    };

    const getImportedProductProportionByProductProportion = productProportion =>
      currentImportedProductProportions.find(pp => pp.ProductProportionId === productProportion.ProductProportionId);

    const getMatchedRawMatBycosmosIngredientId = cosmosIngredientId =>
      currentProductCosmosIngredientRawMatMatches.find(elem => elem.CosmosIngredientId === cosmosIngredientId);

    const getProposedRawMat = prodigumProductProportion => {
      const MatchedRawMat = getMatchedRawMatBycosmosIngredientId(prodigumProductProportion.ReferencedId);

      const proposedRawMat =
        MatchedRawMat !== undefined
          ? MatchedRawMat
          : rawMatList.find(r => r.RawMatId === prodigumProductProportion.CorrespondingRawMatId);

      return proposedRawMat;
    };

    const prodigumProductProportionsNotMatchedList = prodigumProductProportionsNotMatched.map(item => ({
      productId: currentProduct.ProductId,
      importedProductProportion: item,
      productProportion: null,
      hasPercentageChanged: false,
      rawMatId: _.get(getProposedRawMat(item), 'RawMatId', undefined),
      Percentage: item.BeforeTransfoPct?item.BeforeTransfoPct.toString():null,
    }));

    const productProportionsMatchedList = productProportionsMatched.map(item => ({
      productId: currentProduct.ProductId,
      importedProductProportion: getImportedProductProportionByProductProportion(item),
      productProportion: item,
      hasPercentageChanged: hasPercentageChanged(item),
      rawMatId: item.RawMatId,
      Percentage: item.Percentage.toString(),
    }));

    const productProportionsNotMatchedList = productProportionsNotMatched.map(item => ({
      productId: currentProduct.ProductId,
      importedProductProportion: null,
      productProportion: item,
      hasPercentageChanged: false,
      rawMatId: item.RawMatId,
      Percentage: item.Percentage.toString(),
    }));

    return [
      ...prodigumProductProportionsNotMatchedList,
      ...productProportionsNotMatchedList,
      ...productProportionsMatchedList,
    ];
  },
);

export const getNbProdigumProductProportionChanged = createSelector(
  [getComosCompositionList],
  cosmosCompositionList => cosmosCompositionList.filter(c => c.hasPercentageChanged === true).length,
);

export const sortByName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'ProductName'))]));

export const getCpsrBTypes = '';
