import { createSelector } from 'reselect';
import { difference } from 'ramda';
import { labelSymbolCorrectiveActionSelectors } from '../labelSymbolCorrectiveAction';


export const getByCode = ({ labelSymbol }) => labelSymbol.byCode;
export const getCodes = ({ labelSymbol }) => labelSymbol.list.allCodes;
export const getPublicCodes = ({ labelSymbol }) => labelSymbol.list.publicCodes;
export const getLabelSymbol = (state, code) => getByCode(state)[code];
export const getPrivateCodes = state => difference(getCodes(state), getPublicCodes(state));

export const getLabelSymbolList = createSelector([getCodes, getByCode], (allCodes, byCode) =>
  allCodes.map(code => byCode[code]),
);
export const getPublicLabelSymbolList = createSelector([getPublicCodes, getByCode], (publicCodes, byCode) =>
  publicCodes.map(code => byCode[code]),
);

export const getPrivateLabelSymbolList = createSelector([getPrivateCodes, getByCode], (privateCodes, byCode) =>
  privateCodes.map(code => byCode[code]),
);

export const getDefaultLabelSymbolCorrectiveAction = createSelector(
  [getByCode, labelSymbolCorrectiveActionSelectors.getByCode],
  (labelSymbolByCode, labelSymbolCorrectiveActionByCode) =>
    createSelector(
      symbolCode => symbolCode,
      symbolCode => {
        const labelSymbol = labelSymbolByCode[symbolCode];
        if (labelSymbol) {
          const correctiveAction = labelSymbolCorrectiveActionByCode[labelSymbol.CorrectiveActionCode];
          if (correctiveAction) {
            return correctiveAction.Value;
          }
        }
        return '';
      },
    ),
);
