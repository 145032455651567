import queryString from 'query-string';
import { get, post } from './apiUtils';

const defaultUrl = '/ProcessTypes';
const refsUrl = '/Refs';

export default {
  getProcessTypeList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  getProcessTypeRefList: () => get(`${defaultUrl}${refsUrl}`),
  saveProcessType: data => post(`${defaultUrl}`, data),
};
