import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';

import errorApi from '../../api/exceptionApi';
import * as errorTypes from './errorTypes';
import * as errorActions from './errorActions';
import { errorListSchema } from './errorSchemas';

export default function* root() {
  yield all([
    takeLatest(errorTypes.FETCH_ERROR_LIST.REQUEST, getErrorListSaga),
  ]);
}

export function* getErrorListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const errorList = yield call(errorApi.getExceptionList, payload);
    const normalizedData = normalize(errorList, errorListSchema);
    yield put(errorActions.fetchErrors.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(errorActions.fetchErrors.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}