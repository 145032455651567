import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import * as distributorTypes from './distributorTypes';
import { companyDistributorAgreementTypes } from '../companyDistributorAgreement';

const byId = handleActions(
  {
    [distributorTypes.DISTRIBUTOR_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.distributors),
    [distributorTypes.DISTRIBUTOR.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.distributors),
    [distributorTypes.DELETE_DISTRIBUTOR.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
    [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.SUCCESS]: (
      state,
      { payload },
    ) => ({ ...state, ...payload.entities.distributors }),
    [distributorTypes.DISTRIBUTOR_AGREEMENT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentCompanyProducts: payload,
    }),

  },
  {},
);

const allIds = handleActions(
  {
    [distributorTypes.DISTRIBUTOR_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [distributorTypes.DISTRIBUTOR.SUCCESS]: (state, { payload }) => [...new Set([...state, payload.result])],
    [distributorTypes.DELETE_DISTRIBUTOR.SUCCESS]: (state, { payload }) => R.without([payload], state),
    [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.SUCCESS]: (
      state,
      { payload },
    ) => {
      if (payload.entities.distributors) {
        return [...new Set([...state, ...Object.keys(payload.entities.distributors).map(Number)])];
      }
      return state;
    },
  },
  [],
);

const productDistributor = handleActions(
  {
    [distributorTypes.PRODUCT_DISTRIBUTOR_LIST.SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    productDistributor,
  }),
});
