import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Col, Row, Grid } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import { InputField, RadioFields } from '../../_Common/Forms';
import { maxLength2048 } from '../../../utils/fieldValidation';
import BaseLoader from '../../_Common/BaseLoader';

const styles = {
  textarea: css({
    '& textarea.form-control.input-sm': {
      height: '80px',
    },
  }),
};

const CpsrArgumentForm = props => {
  const { isLoading, impurityTypes } = props;
  return (
    <BaseLoader isLoading={isLoading}>
      <Grid fluid>
        <div className={styles.textarea}>
          <Row>
            <Col sm={8}>
              <Field
                name="CpsrBTest"
                label={`${I18n.t('substance.CpsrBTest')}`}
                component={InputField}
                componentClass="textarea"
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={1}>
              <Field
                component={RadioFields}
                label={`${I18n.t('substance.ImpurityTypeCode')}`}
                name="ImpurityTypeCode"
                options={impurityTypes}
                labelField="Value"
                valueField="Code"
              />
            </Col>
            <Col sm={7}>
              <Field
                name="ImpurityComment"
                label={`${I18n.t('substance.ImpurityComment')}`}
                component={InputField}
                componentClass="textarea"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Field
                name="CosNote"
                label={`${I18n.t('substance.CosNote')}`}
                componentClass="textarea"
                component={InputField}
                validate={maxLength2048}
              />
            </Col>
          </Row>
        </div>
      </Grid>
    </BaseLoader>
  );
};

CpsrArgumentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  impurityTypes: PropTypes.array.isRequired, //eslint-disable-line
};

export default CpsrArgumentForm;
