import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const OrderList = props => {
  const { orderList, onOrderSelected, onRefreshList, onAddOrder, requestingOrderList, selectedId } = props;

  const columns = [
    {
      headerName: I18n.t('order.orderNumber'),
      width: 100,
      sort: 'desc',
      field: 'OrderNr',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.companyShortName'),
      width: 100,
      field: 'ProducerShortName',
    },
    {
      headerName: I18n.t('company.am'),
      width: 35,
      field: 'CustomerSupport',
      valueGetter:({ data }) => (data.CustomerSupport ? data.CustomerSupport : data.DefaultCustomerSupport),
    },
    {
      headerName: I18n.t('order.Status'),
      width: 50,
      field: 'Status',
    },
  ];

  return (
    <SelectionGrid
      data={orderList}
      onSelected={onOrderSelected}
      onRefresh={onRefreshList}
      onAdd={onAddOrder}
      isLoading={requestingOrderList}
      selectedId={selectedId}
      columns={columns}
      suppressRowClickSelection
      keyName="OrderId"
      showAddButton
    />
  );
};

OrderList.defaultProps = {
  selectedId: '',
};

OrderList.propTypes = {
  orderList: PropTypes.arrayOf(
    PropTypes.shape({
      OrderId: PropTypes.number.isRequired,
      ProducerShortName: PropTypes.string.isRequired,
      OrderNr: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onOrderSelected: PropTypes.func.isRequired,
  onAddOrder: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  requestingOrderList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default OrderList;
