import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { modalActions, modalTypes } from '../../../redux/modal';
import NotificationTab from './NotificationTab';
import { notificationActions, notificationSelectors, notificationTypes } from '../../../redux/notification';


class NotificationContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <NotificationTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => ({
    notificationList: notificationSelectors.getNotificationListForCurrentProduct(state),
    isLoading: applicationSelectors.isLoading(state, notificationTypes.FETCH_NOTIFICATIONS.REQUEST),
    isEditLoading: applicationSelectors.isLoading(state, notificationTypes.EDIT_NOTIFICATION.REQUEST),
  });

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    if (productId > 0) {
      dispatch(notificationActions.listNotificationsForProduct.request(productId));
    }
  },
  getNotificationListForProduct: () => 
    dispatch(notificationActions.listNotificationsForProduct.request({ productId })
    ),
  onNewNotificationClick: () => dispatch(modalActions.showNotificationFormModal({productId})),
  onEditNotification: ({ NotificationId: notificationId }) =>
    dispatch(
      modalActions.showNotificationFormModal({
        productId,
        notificationId,
        include: { OptIncludeDetails: true },
      }),
    ),
  onDeleteNotification: ({ NotificationId: notificationId }) =>
    dispatch(
      notificationActions.deleteNotification.request({
        NotificationId: notificationId,
        ProductId: productId
      }),
    ), 
  onGenerateComplianceCertificate:  ({ NotificationId: notificationId, ProductId: productId }) =>
    dispatch(
      notificationActions.generateComplianceCertificate.request({
        NotificationId: notificationId,
        ProductId: productId
      }),
    ), 
  onReniewComplianceCertificate:  ({ NotificationId: notificationId, ProductId: productId }) => {
    dispatch(
      notificationActions.reniewComplianceCertificate.request({
        NotificationId: notificationId,
        ProductId: productId
      }),
    )
  }, 
});

NotificationContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationContainer);
