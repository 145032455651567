import { createRequestTypes } from '../utils';

export const CATEGORY_LIST = createRequestTypes('category/CATEGORY_LIST');
export const SAVE_CATEGORY = createRequestTypes('category/SAVE_CATEGORY');
export const DELETE_CATEGORY = createRequestTypes('category/DELETE_CATEGORY');
export const UPDATE_CATEGORY = createRequestTypes('category/UPDATE_CATEGORY');

export const CATEGORY = createRequestTypes('category/CATEGORY');

export const NEW_CATEGORY = 'category/NEW_CATEGORY';

