import { createSelector } from 'reselect';
import { actionSelectors } from '../action';

export const getIds = ({ taskAction }) => taskAction.list.allIds;
export const getById = ({ taskAction }) => taskAction.byId;
export const getTaskAction = (state, id) => getById(state)[id];
export const getTaskActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getTaskActionForTask = (state, id) => {
  const result = getTaskActionList(state)
    .filter(ta => ta.TaskId === id)
    .reduce(
      (acc, ta) => [
        ...acc,
        {
          ...ta,
          Action: actionSelectors.getAction(state, ta.ActionCode),
        },
      ],
      [],
    );
  return result;
};
