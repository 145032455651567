import uuidV1 from 'uuid/v1';
import { getOr } from 'lodash/fp';
import { takeLatest, put, all, select, call } from 'redux-saga/effects';
import * as labelReviewWizardTypes from './labelReviewWizardTypes';
import * as labelReviewWizardActions from './labelReviewWizardActions';
import * as labelReviewWizardSelectors from './labelReviewWizardSelectors';
import { createFetchSaga } from '../../sagaUtils';
import { labelReviewSelectors, labelReviewActions, labelReviewTypes, labelReviewSagas } from '../../labelReview';
import { modalTypes, modalActions, modalSelectors } from '../../modal';
import { inciListSagas } from '../../inciList';
import { productSelectors } from '../../product';

export default function* root() {
  yield all([
    takeLatest(labelReviewWizardTypes.SAVE_BUTTON_CLICKED, onSaveButtonClickedSaga),
    takeLatest(labelReviewTypes.SAVE_LABEL_REVIEW.SUCCESS, onLabelReviewSavedSaga),
    takeLatest(labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.REQUEST, newLabelReviewRequestWizardSaga),
    takeLatest(labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS, newLabelReviewSuccessWizardSaga),
    takeLatest(labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.REQUEST, editLabelReviewRequestSaga),
    takeLatest(labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS, editLabelReviewSuccessSaga),
  ]);
}

const editLabelReviewRequestSaga = createFetchSaga(
  function* editLabelReviewRequestSaga({ payload, type }) {
    const { query } = payload;
    const result = yield all([
      call(labelReviewSagas.fetchLabelReviewSaga, { payload, type }),
      call(inciListSagas.getInciListForProductSaga, { payload: query, type }),
    ]);
    return result;
  },
  {
    * onSuccess(data, payload) {
      const { query: { LabelReviewId, ProductId } = {} } = payload;
      yield put(labelReviewWizardActions.editLabelReviewWizard.success({ LabelReviewId, ProductId }, payload));
    },
    onFailure: (error, payload) => put(labelReviewWizardActions.editLabelReviewWizard.failure(error, payload)),
  },
);

function* editLabelReviewSuccessSaga({ payload, meta }) {
  const {
    query: { LabelReviewId, ProductId },
  } = meta;
  const labelReview = yield select(state => labelReviewSelectors.getLabelReview(state, LabelReviewId));

  yield put(labelReviewWizardActions.loadLabelReviewData(labelReview));

  const product = yield select(state => productSelectors.getProduct(state, ProductId));
  yield put(
    modalActions.showModal({
      modalType: modalTypes.LABEL_REVIEW_WIZARD,
      modalProps: {
        ...payload,
        ProductId,
        title: `Edit Label review ${getOr('', 'ProductName', product)}`,
        modalWidth: '1200px',
      },
    }),
  );
}

const newLabelReviewRequestWizardSaga = createFetchSaga(
  function* newLabelReviewRequestWizardSaga({ payload, type }) {
    const { query = {} } = payload;
    const { ProductId } = query;

    const latestLabelReview = yield select(state =>
      labelReviewSelectors.getLatestLabelReviewForProduct(state, ProductId),
    );

    if (latestLabelReview) {
      const { LabelReviewId } = latestLabelReview;
      yield all([
        call(labelReviewSagas.fetchLabelReviewSaga, {
          payload: { query: { ...payload.query, LabelReviewId } },
          type,
        }),
        call(inciListSagas.getInciListForProductSaga, { payload: query, type }),
      ]);
    }
  },
  {
    * onSuccess(data, payload) {
      const { query: { LabelReviewId, ProductId } = {} } = payload;
      yield put(labelReviewWizardActions.newLabelReviewWizard.success({ LabelReviewId, ProductId }, payload));
    },
    onFailure: (error, payload) => put(labelReviewWizardActions.newLabelReviewWizard.failure(error, payload)),
  },
);

function* newLabelReviewSuccessWizardSaga({ payload, meta }) {
  const {
    query: { ProductId },
  } = meta;

  const product = yield select(state => productSelectors.getProduct(state, ProductId));
  const latestLabelReview = yield select(state =>
    labelReviewSelectors.getLatestLabelReviewForProduct(state, ProductId),
  );

  const labelReview = {
    InnerLabelAvailability: latestLabelReview ? latestLabelReview.InnerLabelAvailability : null,
    OuterLabelAvailability: latestLabelReview ? latestLabelReview.OuterLabelAvailability : null,
    LeafletLabelAvailability: latestLabelReview ? latestLabelReview.LeafletLabelAvailability : null,
    LabelReviewId: uuidV1(),
    ProductId,
  };

  yield put(labelReviewWizardActions.loadLabelReviewData(labelReview));

  yield put(
    modalActions.showModal({
      modalType: modalTypes.LABEL_REVIEW_WIZARD,
      modalProps: {
        ...payload,
        title: `New Label review ${getOr('', 'ProductName', product)}`,
        modalWidth: '1200px',
      },
    }),
  );
}

function* onSaveButtonClickedSaga() {
  const labelReview = yield select(labelReviewWizardSelectors.getLabelReviewWithDetails);
  const deletedLabelLabelReviewClaimIds = yield select(labelReviewWizardSelectors.getDeletedLabelReviewClaims);
  const deletedLabelReviewSymbolIds = yield select(labelReviewWizardSelectors.getDeletedLavelReviewSymbols);
  const deletedLabelSymbolIds = yield select(labelReviewWizardSelectors.getDeletedLabelSymbols);

  yield put(
    labelReviewActions.saveLabelReview.request({
      ...labelReview,
      DeletedLabelReviewClaims: deletedLabelLabelReviewClaimIds,
      DeletedLabelReviewSymbols: deletedLabelReviewSymbolIds,
      DeletedLabelSymbols: deletedLabelSymbolIds,
    }),
  );
}

function* onLabelReviewSavedSaga() {
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.LABEL_REVIEW_WIZARD) {
    yield put(modalActions.hideModal());
  }
}
