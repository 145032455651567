import * as cpsrChaptersSelectors from './cpsrChaptersSelectors';
import * as cpsrChaptersActions from './cpsrChaptersActions';
import * as cpsrChaptersTypes from './cpsrChaptersTypes';
import sagas from './cpsrChaptersSagas';

import reducer from './cpsrChaptersReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { cpsrChaptersSelectors, cpsrChaptersActions, cpsrChaptersTypes };
export default reducer;
