import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import DraftLabelForm from './DraftLabelForm';
import { draftLabelFormSelectors } from '../../../redux/form/draftLabelForm';
import { draftLabelActions } from '../../../redux/draftLabel';
import formNames from '../../../constants/formNames';

const formName = formNames.draftLabel;

const DraftLabelFormContainer = props => <DraftLabelForm {...props} />;

const mapStateToProps = (state, { productId }) => {
  const getInitialValues = draftLabelFormSelectors.makeGetInitialValues(productId);
  return {
    initialValues: getInitialValues(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => dispatch(draftLabelActions.saveDraftLabel.request({ ...data, productId })),
});

DraftLabelFormContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(DraftLabelFormContainer);
