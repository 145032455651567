import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RadioFields } from '../../components/_Common/Forms';
import { referentialSelectors } from '../../redux/referentials';

const ProductMosCalculationTimeRange = ({ mosCalculationTimeRanges, ...otherProps }) => (
  <RadioFields options={mosCalculationTimeRanges} {...otherProps} />
);

const mapStateToProps = state => ({
  mosCalculationTimeRanges: referentialSelectors.getMosCalculationTimeRanges(state),
});

ProductMosCalculationTimeRange.propTypes = {
  mosCalculationTimeRanges: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(ProductMosCalculationTimeRange);
