import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import ShadeList from './ShadeList';
import { shadeActions, shadeSelectors, shadeTypes } from '../../../redux/shade';

class ShadeListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <ShadeList {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const getShades = shadeSelectors.makeGetShadesForProduct(state);
  return {
    shadeList: getShades(productId),
    isLoading: applicationSelectors.isLoading(
      state,
      shadeTypes.FETCH_SHADES.REQUEST,
      shadeTypes.DELETE_SHADE.REQUEST,
      shadeTypes.UPDATE_INCI.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId, buttonsDisabledByValidedComposition }) => ({
  onLoad: () => {
    if (productId > 0) {
      dispatch(shadeActions.fetchShades.request({ productId }));
    }
  },
  fetchShadeList: () => dispatch(shadeActions.fetchShades.request({ productId })),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showShadeFormModal({
        productId,
      }),
    ),
  onEditButtonClick: ({ ShadeId: shadeId }) =>
    dispatch(
      modalActions.showShadeFormModal({
        productId,
        shadeId,
      }),
    ),
  onCompoButtonClick: ({ ShadeId: shadeId }) =>
    dispatch(
      modalActions.showShadeCompoModal({
        productId,
        shadeId,
        buttonsDisabledByValidedComposition,
      }),
    ),
  onDeleteButtonClick: ({ ShadeId: shadeId }) => dispatch(shadeActions.deleteShade.request({ shadeId, productId })),
});
ShadeListContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadeListContainer);
