import * as rawMatActionSelectors from './rawMatActionSelectors';
import * as rawMatActionActions from './rawMatActionActions';
import * as rawMatActionTypes from './rawMatActionTypes';
import sagas from './rawMatActionSagas';
import reducer from './rawMatActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { rawMatActionSelectors, rawMatActionActions, rawMatActionTypes };
export default reducer;
