import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as toxProfileItemTypes from './toxProfileItemTypes';
import queryString from 'query-string';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import { removeById } from '../utils';

const byId = handleActions(
  {
    [toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state.byId, payload.entities.toxProfileItem),
    [toxProfileItemTypes.DELETE_TOXPROFILEITEM.SUCCESS]: removeById,
  },
  {},
);

const byQueryIds = handleActions(
  {
    [toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const queryStr = queryString.stringify(query);
      return {
        ...state,
        [queryStr]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
    [toxProfileItemTypes.DELETE_TOXPROFILEITEM.SUCCESS]: (state, { payload }) => R.without([payload])(state),
  },
  {},
);

const allIds = handleActions(
  {
    [toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
  },
  [],
);

export default combineReducers(
  {
    byId,
    list: combineReducers({
      allIds,
      byQueryIds,
    }),
  },
  {},
);
