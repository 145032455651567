import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm, change } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField } from '../../../_Common/Forms';
import { ProductListDropdown } from '../../../_Common/Dropdowns';
import { processFormSelectors } from '../../../../redux/form/processForm';
import { maxLength255 } from '../../../../utils/fieldValidation';
import { orderSelectors } from '../../../../redux/order';
import { processActions } from '../../../../redux/process';
// import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

const formName = formNames.process;

const validate = data => data.ProductId !== null || data.ProductName !== null;

const ProcessForm = props => {
  const { onSubmit, handleSubmit, availableProductsForCurrentOrder, cleanFormValue } = props;
  // const productList = availableProductsForCurrentOrder;
  return (
    <Form horizontal onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            {/* <Field
              name="ProductId"
              label={I18n.t('orderProcess.existingProduct')}
              component={SelectField}
              options={availableProductsForCurrentOrder}
              labelField="ProductName"
              valueField="ProductId"
              onChange={data => cleanFormValue('ProductName')}
            /> */}
            <Field
              name="ProductId"
              label={I18n.t('orderProcess.existingProduct')}
              component={ProductListDropdown}
              initialOptions={availableProductsForCurrentOrder} // {productList}
              onChange={data => cleanFormValue('ProductName')}
              disabled={false}
              useVirtualized
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="ProductName"
              label={I18n.t('orderProcess.newProduct')}
              component={InputField}
              validate={maxLength255}
              onChange={data => cleanFormValue('ProductId')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field
              name="ShadeCount"
              label={I18n.t('orderProcess.shadeCountLabel')}
              component={InputField}
              validate={maxLength255}
              onChange={data => cleanFormValue('ShadeCount')}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { ProcessId, orderId }) => {
  const getFormInitialValues = processFormSelectors.makeGetInitialValues(ProcessId);
  const initialValue = getFormInitialValues(state);
  return {
    initialValues: initialValue,
    availableProductsForCurrentOrder: orderSelectors.getProductsOwnedByCurrentOrderCompany(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onSubmit: data => dispatch(processActions.saveProcess.request({ ...data, orderId })),
  cleanFormValue: field => dispatch(change(formName, field, null)),
});

ProcessForm.defaultProps = {
  initialValues: {},
};

ProcessForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  availableProductsForCurrentOrder: PropTypes.arrayOf(
    PropTypes.shape({
      ProductId: PropTypes.number.isRequired,
      ProductName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate,
  }),
)(ProcessForm);
