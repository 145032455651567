import { createSelector } from 'reselect';
import { productSelectors } from '../product';

export const getBySubstanceId = ({ substanceProduct }) => substanceProduct.bySubstanceId;

export const getProductIdsForSubstance = substanceId => state => getBySubstanceId(state)[substanceId];

export const getProductsForSubstance = substanceId =>
  createSelector([getProductIdsForSubstance(substanceId), productSelectors.getById], (productIds = [], productById) =>
    productIds.map(productId => productById[productId]),
  );

export const makeGetProductsForSubstance = createSelector(
  getProductsForSubstance,
  productsForSubstance => productsForSubstance,
);
