import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Checkbox } from 'react-bootstrap';
import { css } from 'glamor';

const CheckBoxSymbol = props => {
  const { input, meta, imageSrc } = props;

  const getValidationState = () => {
    const { touched, dirty, error } = meta;
    if ((touched || dirty) && error) {
      return 'error';
    } else if (dirty) {
      return 'warning';
    }
    return null;
  };

  const images = Array.isArray(imageSrc) ? imageSrc : Array.of(imageSrc);

  const styles = {
    form: css({
      '& .checkbox': {
        textAlign: 'center',
      },
      '& .form-group': {
        display: 'flex',
        flexDirection: 'column',
      },
    }),
    images: css({
      display: 'flex',
      alignSelf: 'center',
      '& img': {
        width: '50px',
        height: '50px',
        marginBottom: '5px',
      },
    }),
  };

  return (
    <div className={styles.form}>
      <FormGroup>
        <Checkbox {...input} checked={input.value} bsSize="small" validationState={getValidationState()} />
        <div className={styles.images}>
          {images.map((img, index) => <img key={index} src={img} alt={input.name} />)}
        </div>
      </FormGroup>
    </div>
  );
};

CheckBoxSymbol.propTypes = {
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  input: PropTypes.object.isRequired, //eslint-disable-line
  meta: PropTypes.object.isRequired, //eslint-disable-line
};

export default CheckBoxSymbol;
