import { schema } from 'normalizr';
import * as documentSchemas from '../document/documentSchemas';
import * as mandateProductSchemas from '../mandateProduct/mandateProductSchemas';

export const mandateSchema = new schema.Entity(
  'mandates',
  {
    Document: documentSchemas.documentSchema,
    MandateProducts: mandateProductSchemas.mandateProductListSchema,
  },
  { idAttribute: 'MandateId' },
);

export const mandateListSchema = [mandateSchema];
