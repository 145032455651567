import * as productDocumentSelectors from './productDocumentSelectors';
import * as productDocumentActions from './productDocumentActions';
import * as productDocumentTypes from './productDocumentTypes';
import sagas from './productDocumentSagas';
import reducer from './productDocumentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productDocumentSelectors, productDocumentActions, productDocumentTypes };
export default reducer;
