import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { evolve, omit } from 'ramda';
import { push } from 'react-router-redux';
import ProductList from './ProductList';
import { productActions, productTypes, productSelectors } from '../../redux/product';
import { applicationSelectors } from '../../redux/application';

class ProductsPageContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    // console.log('ProductPageContainer Mounted');
    this.props.onLoad();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log('ProductPageContainer updated');
  // }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <ProductList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location: { query },
  } = ownProps;
  const companyId = query.companyId ? Number(query.companyId) : undefined;
  const impactAssessmentId = query.impactAssessmentId ? Number(query.impactAssessmentId) : undefined;
  return {
    productList: productSelectors.getFilteredProductList(state, { companyId, impactAssessmentId }),
    requestingProductList: applicationSelectors.isLoading(state, productTypes.PRODUCT_LIST.REQUEST),
  };
};

function mapDispatchToProps(dispatch, { location }) {
  return {
    onLoad: () => dispatch(productActions.listProducts.request()),
    onProductSelected: id => {
      dispatch(
        push(
          evolve({
            query: omit(['duplicateFrom', 'mode']),
            pathname: () => `/products/${id}`,
          })(location),
        ),
      );
    },
    onAddProduct: () => dispatch(push({ query: { show_details: true }, pathname: '/products/-1' })),
    onRefreshList: () => dispatch(productActions.listProducts.request()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductsPageContainer);
