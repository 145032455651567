import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import OrderActionsModal from './OrderActionsModal';
import { orderSelectors } from '../../../../redux/order';

const OrderActionsModalContainer = props => <OrderActionsModal {...props} />;

const mapStateToProps = (state, { orderId }) => {
  const order = orderSelectors.getCurrentOrder(state);
  const orderNr = R.propOr('', 'OrderNr', order);
  return {
    title: I18n.t('orderActionsModal.title', { orderNr }),
  };
};

OrderActionsModalContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, null)(OrderActionsModalContainer);
