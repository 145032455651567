import PropTypes from 'prop-types';
import React from 'react';
//import { I18n } from 'react-redux-i18n';
import { SelectionGrid } from '../../_Common/Grids';

const DashboardResultList = props => {
  const {
    dashboardResults,
    requestingDashboardResultList
  } = props;

  const columns = [
    {
      headerName: 'Col1',
      field: 'FormulaReviewId',
      width: 120,
    },
    {
      headerName: 'Col2',
      field: 'CreatedBy',
      editable: true,
      width: 300,
    },
  ];

  return (
    <div>
      <SelectionGrid
        data={dashboardResults}
        columns={columns}
        isLoading={requestingDashboardResultList}
        keyName="ProductId"
        showAddButton={false}
      />
    </div>
  );
};

DashboardResultList.defaultProps = {
  dashboardResults: [],
};

DashboardResultList.propTypes = {
  dashboardResults: PropTypes.array.isRequired, //eslint-disable-line
  onRefresh: PropTypes.func.isRequired,
  requestingDashboardResultList: PropTypes.bool.isRequired,
};

export default DashboardResultList;
