import queryString from 'query-string';
import * as R from 'ramda';
import { get, post, makeConstructUrlFromQuery } from './apiUtils';

const defaultUrl = '/FormulaReviews/';

const getUrl = makeConstructUrlFromQuery(defaultUrl);

export default {
  getFormulaReviews: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveFormulaReview: data => post(defaultUrl, data),
  fetchFormulaReviews: R.compose(
    get,
    getUrl,
  ),
};
