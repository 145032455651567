import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import * as productProportionFormTypes from './productProportionFormTypes';

export const onRawMatSelected = createAction(productProportionFormTypes.RAWMAT_SELECTED, RawMatId => ({
  RawMatId,
}));
export const onAddButtonClicked = createAction(productProportionFormTypes.ADD_BUTTON_CLICKED);

export const submitProductProportionForm = createFormAction(productProportionFormTypes.SUBMIT_PRODUCT_PROPORTION_FORM);
export const onSaveButtonClick = createAction(productProportionFormTypes.SAVE_BUTTON_CLIKED);
