import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { css } from 'glamor';
import * as R from 'ramda';
import { I18n, Translate } from 'react-redux-i18n';
import { submit, isPristine, isSubmitting } from 'redux-form';
import { PhraseForm } from '../../Forms';
import {phraseSelectors, phraseActions, phraseTypes} from '../../../../redux/phrase';
import BaseLoader from '../../../_Common/BaseLoader';
import formNames from '../../../../constants/formNames';
import {phraseFormSelectors} from "../../../../redux/form/phraseForm";
import {applicationSelectors} from "../../../../redux/application";

const formName = formNames.phrase;

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  modalBody: css({
    overflow: 'auto',
  }),
};
class PhraseFormModal extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    const { phraseId, title, closeModal, onSaveButtonClick, isSaveDisabled, isLoading } = this.props;
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div className={styles.modalBody}>
          <Modal.Body>
            <BaseLoader isLoading={isLoading}>
              <PhraseForm phraseId={phraseId} {...this.props}/>
            </BaseLoader>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
                <i className="fa fa-times"/>&nbsp;
                <Translate value="general.close"/>
              </Button>
              <Button bsSize="small" bsStyle="success" type="button" onClick={onSaveButtonClick}
                      disabled={isSaveDisabled}>
                <Translate value="general.save"/>&nbsp;
                <i className="fa fa-arrow-circle-right"/>
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

const mapStateToProps = (state, { phraseId }) => {
  const phrase = phraseSelectors.getById(state)[phraseId];
  const phraseCode = R.propOr('', 'PhraseCode', phrase);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormPristine = isPristine(formName)(state);

  const getInitialValues = phraseFormSelectors.makeGetInitialValues(state);
  const values = getInitialValues(phraseId);
  return {
    isLoading: (isFormSubmitting || applicationSelectors.isLoading(state, phraseTypes.GET_PHRASE.REQUEST)),
    isSaveDisabled: isFormSubmitting || isFormPristine,
    title: phrase ? I18n.t('phraseFormModal.editTitle', { phraseCode }) : I18n.t('phraseFormModal.addTitle'),
    initialValues: values,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return ({
    onLoad: () => {
      dispatch(phraseActions.getPhrase.request(props.phraseId));
    },
    onSaveButtonClick: () => {
      dispatch(submit(formName));
    },
  });
}

PhraseFormModal.defaultProps = {
  phraseId: undefined,
};

PhraseFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  phraseId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhraseFormModal);
