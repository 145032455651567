import * as draftLabelSelectors from './draftLabelSelectors';
import * as draftLabelActions from './draftLabelActions';
import * as draftLabelTypes from './draftLabelTypes';
import sagas from './draftLabelSagas';
import reducer from './draftLabelReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { draftLabelSelectors, draftLabelActions, draftLabelTypes };
export default reducer;
