import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../_Common/Grids/SelectionGrid';

const IngredientList = props => {
  const {
    ingredientList,
    requestingIngredientList,
    onIngredientSelected,
    onAddIngredient,
    onRefreshList,
    selectedId,
  } = props;

  const columns = [
    {
      headerName: I18n.t('general.code'),
      width: 100,
      field: 'Code',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('ingredient.inciName'),
      field: 'InciName',
    },
  ];

  return (
    <SelectionGrid
      data={ingredientList}
      onSelected={onIngredientSelected}
      onRefresh={onRefreshList}
      onAdd={onAddIngredient}
      isLoading={requestingIngredientList}
      selectedId={selectedId}
      columns={columns}
      keyName="IngredientId"
      showAddButton
    />
  );
};

IngredientList.defaultProps = {
  selectedId: '',
};

IngredientList.propTypes = {
  ingredientList: PropTypes.arrayOf(
    PropTypes.shape({
      IngredientId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      InciName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onIngredientSelected: PropTypes.func.isRequired,
  onAddIngredient: PropTypes.func.isRequired,
  onRefreshList: PropTypes.func.isRequired,
  requestingIngredientList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default IngredientList;
