import { createSelector } from 'reselect';

export const getById = ({ substanceClp }) => substanceClp.byId;
export const getSubstanceClpList = createSelector(getById, substanceClpById => Object.values(substanceClpById));

export const getSubstanceClp = (state, id) => state.substanceClp.byId[id];

const getSubstanceClpsForSubstance = substanceId =>
  createSelector(getSubstanceClpList, substanceClpList =>
    substanceClpList.find(substanceClp => substanceClp.SubstanceId === substanceId),
  );

export const makeGetSubstanceClpsForSubstance = createSelector(
  getSubstanceClpsForSubstance,
  substanceClpsForSubstance => substanceClpsForSubstance,
);
