import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { rawMatActionSelectors, rawMatActionTypes, rawMatActionActions } from '../../../../redux/rawMatAction';
import { applicationSelectors } from '../../../../redux/application';
import { DateCellRenderer, SelectionGrid } from '../../../_Common/Grids';
import dateComparator from '../../../../utils/dateComparator';
import { actionSelectors } from '../../../../redux/action';

const RawMatActionsList = props => {
  const { actionList, rawMatActionList, isLoading, height, getRawMatActionList } = props;

  const getActionByCode = actionCode => actionList.find(action => action.Code === actionCode);

  const getActionValue = ({ colDef, data }) =>
    R.compose(R.propOr('', colDef.field), getActionByCode, R.propOr('', 'ActionCode'))(data);

  const columns = [
    {
      headerName: I18n.t('productAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('productAction.code'),
      field: 'ActionCode',
      width: 40,
    },
    {
      headerName: I18n.t('productAction.description'),
      field: 'Description',
      valueGetter: getActionValue,
      width: 150,
    },
    {
      headerName: I18n.t('productAction.comment'),
      field: 'Comment',
    },
    {
      headerName: I18n.t('productAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  return (
    <SelectionGrid
      data={rawMatActionList}
      keyName="RawMatActionId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getRawMatActionList}
      showAddButton={false}
      height={height}
    />
  );
};

const mapStateToProps = (state, { rawMatId }) => {
  const getRawMatActions = rawMatActionSelectors.makeGetRawMatActionsForRawMat(rawMatId);
  return {
    rawMatActionList: getRawMatActions(state),
    actionList: actionSelectors.getActionList(state),
    isLoading: applicationSelectors.isLoading(state, rawMatActionTypes.FETCH_RAWMAT_ACTIONS.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  getRawMatActionList: () => dispatch(rawMatActionActions.fetchRawMatActions.request({ rawMatId })),
});

RawMatActionsList.defaultProps = {
  height: '',
};

RawMatActionsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  rawMatActionList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  getRawMatActionList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RawMatActionsList);
