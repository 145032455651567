import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import * as categoryTypes from './categoryTypes';

const byId = handleActions(
  {
    [categoryTypes.CATEGORY_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.categories),
    [categoryTypes.CATEGORY.SUCCESS]: (state, { payload }) => merge({}, state, payload.entities.categories),
    [categoryTypes.DELETE_CATEGORY.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [categoryTypes.CATEGORY_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [categoryTypes.CATEGORY.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [categoryTypes.DELETE_CATEGORY.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
