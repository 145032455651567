import { createSelector } from 'reselect';
import * as labelReviewSelectors from '../labelReview/labelReviewSelectors';

export const getById = ({ labelReviewInci }) => labelReviewInci.byId;
export const getIds = ({ labelReviewInci }) => labelReviewInci.list.allIds;
export const getlabelReviewInci = (state, id) => getById(state)[id];
export const getlabelReviewInciList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getlabelReviewInciForLabelReview = createSelector(
  [labelReviewSelectors.getLabelReview, getById],
  (labelReview, labelReviewInciById) => {
    if (!labelReview) return null;
    return labelReviewInciById[labelReview.LabelReviewInci];
  },
);

export const getlabelReviewInciFromLatestLabelReview = (state, productId) => {
  const latestLabelReview = labelReviewSelectors.getLatestLabelReviewForProduct(state, productId);
  if (!latestLabelReview) return null;
  return getlabelReviewInciForLabelReview(state, latestLabelReview.LabelReviewId);
};
