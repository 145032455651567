import { createSelector } from 'reselect';
import * as labelReviewSelectors from '../labelReview/labelReviewSelectors';

export const getById = ({ labelReviewItem }) => labelReviewItem.byId;
export const getIds = ({ labelReviewItem }) => labelReviewItem.list.allIds;
export const getLabelReviewItem = (state, id) => getById(state)[id];
export const getLabelReviewItemList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getLabelReviewItemListForLabelReview = createSelector(
  [labelReviewSelectors.getLabelReview, getById],
  (labelReview, labelReviewItemById) => {
    if (!labelReview) return [];
    return labelReview.LabelReviewItems.map(labelReviewItemId => labelReviewItemById[labelReviewItemId]);
  },
);

export const getLabelReviewItemListFromLatestLabelReview = (state, productId) => {
  const latestLabelReview = labelReviewSelectors.getLatestLabelReviewForProduct(state, productId);
  if (!latestLabelReview) return [];
  return getLabelReviewItemListForLabelReview(state, latestLabelReview.LabelReviewId);
};
