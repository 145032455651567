import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditDeleteButtonRenderer } from '../../_Common/Grids';

const InformationList = props => {
  const {
    substanceInformationList,
    fetchSubstanceInformationList,
    isLoading,
    onAddButtonClick,
    onEditButtonClick,
    onDeleteButtonClick,
  } = props;

  const columns = [
    {
      headerName: I18n.t('substanceInformation.Order'),
      field: 'Order',
      width: 50,
      sort: 'asc',
    },
    {
      headerName: I18n.t('substanceInformation.Description'),
      field: 'Description',
    },
    {
      headerName: I18n.t('substanceInformation.Specification'),
      field: 'Specification',
    },
    {
      headerName: I18n.t('substanceInformation.Method'),
      field: 'Method',
    },
    {
      headerName: I18n.t('substanceInformation.Type'),
      field: 'TypeCode',
    },
    {
      headerName: I18n.t('general.actions'),
      width: 190,
      cellRendererFramework: EditDeleteButtonRenderer,
      suppressSizeToFit: true,
      cellRendererParams: {
        onDelete: onDeleteButtonClick,
        onEdit: onEditButtonClick,
      },
    },
  ];

  return (
    <SelectionGrid
      data={substanceInformationList}
      keyName="SubstanceInformationId"
      columns={columns}
      onAdd={onAddButtonClick}
      isLoading={isLoading}
      onRefresh={fetchSubstanceInformationList}
    />
  );
};

InformationList.defaultProps = {
  substanceInformationList: [],
};

InformationList.propTypes = {
  substanceInformationList: PropTypes.array.isRequired, //eslint-disable-line
  fetchSubstanceInformationList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

export default InformationList;
