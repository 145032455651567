import PropTypes from 'prop-types';
import React from 'react';
import { Form, Row, Col, Grid, ControlLabel } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { I18n } from 'react-redux-i18n';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import BaseLoader from '../_Common/BaseLoader';
import formNames from '../../constants/formNames';
import { InputField, DateField, SelectField } from '../_Common/Forms';
import StepComponent from '../_Common/StepComponent';
import DetailHeader from '../_Common/DetailHeader';
import { requiredField, maxLength255 } from '../../utils/fieldValidation';
import ProductUseTypeSelectField from '../Products/ProductUseTypeSelectField';
import ImpactAssessmentThresholds from './ImpactAssessmentThresholds';
import LoadingButton from './../_Common/LoadingButton';
import idx from "idx";

const ImpactAssessmentForm = props => {
  const {
    handleSubmit,
    onSaveImpactAssessment,
    onFindCandidatesClick,
    impactAssessmentTitle,
    isLoadingDetails,
    workflowStatusList,
    onChangeStatus,
    isNew,
    showStatusHistory,
    impactAssessmentStatus,
    dirty,
    categoryList,
    impactAssessmentId,
    requestingFindCandidate,
    companyList,
  } = props;

  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveImpactAssessment)}>
        <DetailHeader title={`${I18n.t('impactAssessment.panelTitle')} : ${impactAssessmentTitle}`} {...props}>
          <Grid fluid>
            <Row>
              <Col sm={2}>
                <Field
                  name="ImpactAssessmentCode"
                  label={`${I18n.t('impactAssessment.code')} 1 *`}
                  component={InputField}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="Description"
                  label={`${I18n.t('impactAssessment.description')} *`}
                  component={InputField}
                  validate={[requiredField, maxLength255]}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="CreatedOn"
                  label={I18n.t('impactAssessment.creationDate')}
                  component={DateField}
                  labelColSize={4}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={2}>
                <ControlLabel>{I18n.t('impactAssessment.status')}</ControlLabel>
                <StepComponent
                  label={I18n.t('impactAssessment.status')}
                  options={workflowStatusList}
                  value={impactAssessmentStatus}
                  onChange={onChangeStatus}
                  disabled={isNew || dirty}
                  onHistoryClick={showStatusHistory}
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  dateFormat={'DD/MM/YYYY HH:mm'}

                />
              </Col>
              <Col sm={1}>
                <Field
                  name="LastModifiedOn"
                  label={I18n.t('impactAssessment.modificationDate')}
                  component={DateField}
                  labelColSize={4}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={1}>
                <br />
                <LoadingButton
                  bsSize="small"
                  onClick={onFindCandidatesClick}
                  disabled={dirty}
                  isLoading={requestingFindCandidate}
                >
                  {I18n.t('impactAssessment.performAssessment')}
                </LoadingButton>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Field
                  component={ProductUseTypeSelectField}
                  label={`${I18n.t('product.useType')}`}
                  name="UseTypeCodes"
                />
                <Field
                  name="CategoryCodes"
                  label={I18n.t('product.categories')}
                  component={SelectField}
                  labelField="Description"
                  valueField="Code"
                  options={categoryList}
                  multi
                />
                <Field
                  name="ProducerId"
                  label={`${I18n.t('impactAssessment.producer')}`}
                  component={SelectField}
                  options={companyList}
                  labelField="ShortName"
                  valueField="CompanyId"
                />
                 <Col sm="4">              
               <Field name="ExecutionStatus" label={I18n.t('impactAssessment.executionStatus')} component={InputField} readOnly/>
               </Col>
               <Col sm="4">
               <Field
                  name="ExecutionStartedOn"
                  label={I18n.t('impactAssessment.startedOn')}
                  component={DateField}
                  dateFormat = "DD/MM/YYYY HH:mm"
                  labelColSize={2}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
                </Col>
                <Col sm="4">
                 <Field
                  name="ExecutionCompletedOn"
                  label={I18n.t('impactAssessment.completedOn')}
                  component={DateField}
                  dateFormat = "DD/MM/YYYY HH:mm"
                  labelColSize={2}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
               </Col>
              </Col>
              <Col sm={8}>
                <ImpactAssessmentThresholds impactAssessmentId={impactAssessmentId} />
              </Col>
            </Row>
          </Grid>
        </DetailHeader>
      </Form>
    </BaseLoader>
  );
};

ImpactAssessmentForm.defaultProps = {
  isLoadingDetails: false,
  disabledFields: {},
  impactAssessmentStatus: '',
  showStatusHistory: undefined,
  categoryList: [],
  categories: '',
};

ImpactAssessmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveImpactAssessment: PropTypes.func.isRequired,
  onFindCandidatesClick: PropTypes.func.isRequired,
  impactAssessmentTitle: PropTypes.string.isRequired,
  isLoadingDetails: PropTypes.bool,
  onShowDetails: PropTypes.func.isRequired,
  disabledFields: PropTypes.object, //eslint-disable-line
  change: PropTypes.func.isRequired,
  workflowStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      WorkflowStatusId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  impactAssessmentStatus: PropTypes.string.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  isImpactAssessmentEditable: PropTypes.bool.isRequired,
  showStatusHistory: PropTypes.func,
  invalid: PropTypes.bool.isRequired,
  impactAssessmentId: PropTypes.number.isRequired,
  dirty: PropTypes.bool.isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Description: PropTypes.string.isRequired,
    }),
  ),
  categories: PropTypes.string,
};

// export default reduxForm({
//  destroyOnUnmount: true,
//  enableReinitialize: true,
// })(ImpactAssessmentForm);
export default compose(
  withLeaveRouteFormDirtyWrapper(formNames.impactAssessment),
  reduxForm({
    form: formNames.impactAssessment,
    destroyOnUnmount: true,
    asyncBlurFields: [], // this seems to prevent the error
    enableReinitialize: true,
    initialValues: {
      ImpactAssessmentId: -1,
    },
    // validate,
  }),
)(ImpactAssessmentForm);
