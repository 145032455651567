import * as R from 'ramda';
import { createSelector } from 'reselect';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { rawMatSelectors } from '../../../redux/rawMat';

export const getRawMatListForProduct = createSelector( //eslint-disable-line
  rawMatSelectors.makeGetAvailableRawMatListForProduct(),
  workflowStatusSelectors.getWorkflowStatusList,
  (getRawMatsForProduct, rawMatStatusList) =>
    createSelector(
      productId => getRawMatsForProduct(productId),
      rawMatsForProduct => {
        const getStatusByCode = code => R.find(R.propEq('Code', code))(rawMatStatusList);
        // const getStatusByName = name => R.find(R.propEq('Name', name))(rawMatStatusList);
        const setRawMatFullName = rawMat =>
          R.assoc(
            'FullName',
            // `${R.prop('Name', rawMat)} - ${R.prop('SupplierName', rawMat)} - ${R.prop('Code', rawMat)} - ${R.prop('Name',)(getStatusByCode(R.prop('Status', rawMat)))}`,
            `${R.prop('Name', rawMat)} - ${R.prop('SupplierName', rawMat)} - ${R.prop('Code', rawMat)} - ${R.prop('StatusName', rawMat)}`,
            rawMat,
          );
        return rawMatsForProduct.map(setRawMatFullName);
      },
    ),
);
