import { createRequestActions, createFetchAction } from '../utils';
import * as substanceClpTypes from './substanceClpTypes';

export const fetchSubstanceClps = createFetchAction(
  substanceClpTypes.FETCH_SUBSTANCE_CLPS,
  'substanceToxicologicalProfileId',
);
export const saveSubstanceClp = createRequestActions(substanceClpTypes.SAVE_SUBSTANCE_CLP);

export const deleteSubstanceClp = createRequestActions(
  substanceClpTypes.DELETE_SUBSTANCE_CLP,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
