import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { productSelectors } from '../../../../redux/product';
import { applicationSelectors } from '../../../../redux/application';
import { reportActions, reportTypes, reportActionTypes } from '../../../../redux/report';
import PrintDocumentsModal from './PrintDocumentsModal';
import { distributorActions, distributorSelectors, distributorTypes } from "../../../../redux/distributor";
import { notificationActions, notificationSelectors } from "../../../../redux/notification";

class PrintDocumentsModalContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <PrintDocumentsModal {...this.props} />;
  }
};

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
 return {
    title: I18n.t('printDocumentsModal.title', { productName }),
    isDownloadingDocument: applicationSelectors.isLoading(state,
      reportActionTypes.FETCH_REPORT.REQUEST,
      distributorTypes.PRODUCT_DISTRIBUTOR_LIST.REQUEST,
    ),
    productId,
    productDistributor: distributorSelectors.getProductDistributorList(state),
    notifications: notificationSelectors.getNotificationListForCurrentProduct(state)
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    dispatch(distributorActions.listProductDistributors.request(productId));
    if (productId > 0) {
      dispatch(notificationActions.listNotificationsForProduct.request(productId));
    }
  },
  onPrintButtonClick: (reportType, format, region, country, language, includeInci, compositionInInci) => {
    dispatch(reportActions.getReport.request(productId, reportTypes[reportType], format, region, country, language, includeInci, compositionInInci));
  },
});

PrintDocumentsModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintDocumentsModalContainer);
