import PropTypes from 'prop-types';
import React from 'react';
import AutoHeight from './AutoHeight';
// import BaseLoader from './BaseLoader';

const BasicDetailContainer = ({ children }) => (
  // <BaseLoader {...otherProps}>
  <AutoHeight externalUsedHeight={98}>{children}</AutoHeight>
  // </BaseLoader>
);

BasicDetailContainer.propTypes = {
  children: PropTypes.node, //eslint-disable-line
};

export default BasicDetailContainer;
