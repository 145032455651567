import { download, get } from './apiUtils';
import { reportTypes } from '../redux/report';

const defaultUrl = '/ProductReports/';
const formulaReviewUrl = '/FormulaReviewReport/';
const labelReviewUrl = '/LabelReviewReport/';
const quickFormulaReviewUrl = '/FormulaReviewQuickReport/';
const compoFormulaReviewUrl = '/FormulaReviewCompoReport/';
const defaultProductsUrl = '/Products/';
const productReportList = '/ProductReportList/';
const agreementUrl = '/DistributorAgreementReport/';
const mandateUrl = '/MandateAgreementReport/';
const quickMandateUrl = '/QuickMandateAgreementReport/';
const toxProfileUrl = '/ToxProfileReport/';

export default {
  getProductReport: (productId, reportType, format, region, country, language, includeInci, compositionInInci) =>
    download(
      `${defaultUrl}${productId}?reportName=${reportType.name}&format=${format}&region=${region}&country=${country}&language=${language}&includeInci=${includeInci}&compositionInInci=${compositionInInci}`,
      reportType.fileName || `${reportType.name}.test.pdf`,
    ),
  getFormulaReviewReport: formulaReviewId =>
    download(`${formulaReviewUrl}${formulaReviewId}`, `${reportTypes.formulaReview.name}.pdf`),
  getLabelReviewReport: labelReviewId =>
    download(`${labelReviewUrl}${labelReviewId}`, `${reportTypes.labelReview.name}.pdf`),
  getFormulaReviewQuickReport: formulaReviewId =>
    download(`${quickFormulaReviewUrl}${formulaReviewId}`, `${reportTypes.quickFormulaReview.name}.pdf`),
  getFormulaReviewCompoReport: formulaReviewId =>
    download(`${compoFormulaReviewUrl}${formulaReviewId}`, `${reportTypes.compoFormulaReview.name}.pdf`),
  getProductReportList: productId => {
    get(`${defaultProductsUrl}${productId}${productReportList}`);
  },
  getAgreementReport: companyDistributorAgreementId =>
    download(`${agreementUrl}${companyDistributorAgreementId}`, `${reportTypes.agreement.name}.pdf`),
  getMandateReport: mandateId => download(`${mandateUrl}${mandateId}`, `${reportTypes.mandate.name}.pdf`),
  getQuickMandateReport: mandateId =>
    download(`${quickMandateUrl}${mandateId}`, `${reportTypes.quickMandate.name}.pdf`),
  getToxProfileReport: substanceId => download(`${toxProfileUrl}${substanceId}`, `${reportTypes.toxProfile.name}.pdf`),
};
