import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import phraseListModal from './PhraseListModal';

import { SHOW_MODAL, HIDE_MODAL } from './modalTypes';

export const initialState = {
  modalType: null,
  modalComponent: null,
  modalProps: {},
};

const general = handleActions(
  {
    [SHOW_MODAL]: (state, { payload: { ...otherProps } }) => ({
      ...state,
      ...otherProps,
    }),
    [HIDE_MODAL]: () => initialState,
  },
  initialState,
);
export default combineReducers({ general, phraseListModal });
