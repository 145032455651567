import { createRequestActions } from '../utils';
import * as workflowStatusTypes from './workflowStatusTypes';

export const listWorkflowStatus = createRequestActions(workflowStatusTypes.WORKFLOW_STATUS_LIST);

// listWorkflowStatus.request = entity => ({
//   type: [workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST],
//   payload: {
//     entity,
//   },
// });
