import PropTypes from 'prop-types';
import React from 'react';
import ProductOrderList from './ProductOrderList';
import ButtonDropDown from '../../_Common/ButtonDropDown';

const OrdersTab = props => {
  const { availableOrdersForCurrentProduct, addOrderToCurrentProduct, ...otherProps } = props;

  return (
    <div>
      <ButtonDropDown
        options={availableOrdersForCurrentProduct}
        labelKey="OrderNr"
        valueKey="OrderId"
        buttonText="Add"
        onButtonClick={addOrderToCurrentProduct}
      />
      <ProductOrderList {...otherProps}
      />
    </div>
  );
};


OrdersTab.propTypes = {
  availableOrdersForCurrentProduct: PropTypes.arrayOf(
    PropTypes.shape({
      OrderNr: PropTypes.string.isRequired,
      OrderId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  addOrderToCurrentProduct: PropTypes.func.isRequired,
};

export default OrdersTab;
