import * as categoryTRSelectors from './categoryTRSelectors';
import * as categoryTRActions from './categoryTRActions';
import * as categoryTRTypes from './categoryTRTypes';
import * as categoryTRSchemas from './categoryTRSchemas';

import sagas, * as categoryTRSagas from './categoryTRSagas';
import reducer from './categoryTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { categoryTRSelectors, categoryTRActions, categoryTRTypes, categoryTRSchemas, categoryTRSagas };
export default reducer;
