import * as productDataReviewSelectors from './productDataReviewSelectors';
import * as productDataReviewActions from './productDataReviewActions';
import * as productDataReviewTypes from './productDataReviewTypes';
import * as productDataReviewSchemas from './productDataReviewSchemas';
//import sagas from './productDataReviewSagas';
import reducer from './productDataReviewReducer';

//import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { productDataReviewSelectors, productDataReviewActions, productDataReviewTypes, productDataReviewSchemas };
export default reducer;
