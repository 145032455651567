import React from 'react';
import PropTypes from 'prop-types';
import StatCriteriaPanel from './StatCriteriaPanel';

class StatPageForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
    };
  }
  render() {
    const { onSearchClicked } = this.props;
    return <StatCriteriaPanel onSearch={onSearchClicked} />;
  }
}

StatPageForm.defaultProps = {
  isSavingForm: false,
  showHomePageForm: false,
};

StatPageForm.propTypes = {
  showHomePageForm: PropTypes.bool,
  onSaveButtonClicked: PropTypes.func.isRequired,
  isSavingForm: PropTypes.bool,
};

export default StatPageForm;
