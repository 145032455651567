import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Button} from 'react-bootstrap';

// We should use a sateful component otherwise we get an error. See: https://github.com/ag-grid/ag-grid/issues/1490
// It happens with ag-grid 12.0. It's probably fixed in a more recent version.
// eslint-disable-next-line
class CheckListItemRenderer extends React.Component {
  render() {
    const {value, colDef} = this.props;

    return (
      <div>
        {value === 'FormulaStatus' && (
          <Translate value="productDataReview.FormulaStatus"/>
        )}
        {value === 'ProductCofaStatus' && (
          <Translate value="productDataReview.ProductCofaStatus"/>
        )}
        {value === 'InPackagingStabilityStatus' && (
          <Translate value="productDataReview.InPackagingStabilityStatus"/>
        )}
        {value === 'ChallengeTestStatus' && (
          <Translate value="productDataReview.ChallengeTestStatus"/>
        )}
        {value === 'MicrobiologicalQualityStatus' && (
          <Translate value="productDataReview.MicrobiologicalQualityStatus"/>
        )}
        {value === 'ProductSpfTestStatus' && (
          <Translate value="productDataReview.ProductSpfTestStatus"/>
        )}
        {value === 'DermatologicalIrritationTestsStatus' && (
          <Translate value="productDataReview.DermatologicalIrritationTestsStatus"/>
        )}
        {value === 'OcularIrritationTestsStatus' && (
          <Translate value="productDataReview.OcularIrritationTestsStatus"/>
        )}
        {value === 'PrimaryLabelStatus' && (
          <Translate value="productDataReview.PrimaryLabelStatus"/>
        )}
        {value === 'SecondaryLabelStatus' && (
          <Translate value="productDataReview.SecondaryLabelStatus"/>
        )}
        {value === 'BatchNrStatus' && (
          <Translate value="productDataReview.BatchNrStatus"/>
        )}
        {value === 'PackagingProcedureStatus' && (
          <Translate value="productDataReview.PackagingProcedureStatus"/>
        )}
        {value === 'MetrologyStatementStatus' && (
          <Translate value="productDataReview.MetrologyStatementStatus"/>
        )}
        {value === 'PackagingInfoStatus' && (
          <Translate value="productDataReview.PackagingInfoStatus"/>
        )}
        {value === 'CompatibilityPackagingStatus' && (
          <Translate value="productDataReview.CompatibilityPackagingStatus"/>
        )}
        {value === 'OutPackagingStabilityStatus' && (
          <Translate value="productDataReview.OutPackagingStabilityStatus"/>
        )}
        {value === 'ProductMsdsStatus' && (
          <Translate value="productDataReview.ProductMsdsStatus"/>
        )}
        {value === 'ManufacturingMethodStatus' && (
          <Translate value="productDataReview.ManufacturingMethodStatus"/>
        )}
        {value === 'QCStatus' && (
          <Translate value="productDataReview.QCStatus"/>
        )}
        {value === 'UndesirableEffectsStatus' && (
          <Translate value="productDataReview.UndesirableEffectsStatus"/>
        )}
        {value === 'NonAnimalTestingStatementStatus' && (
          <Translate value="productDataReview.NonAnimalTestingStatementStatus"/>
        )}
      </div>
    );
  }
}

CheckListItemRenderer.defaultProps = {
  value: '',
  colDef: {
    headerName: '',
  },
};

CheckListItemRenderer.propTypes = {
  value: PropTypes.string,
  colDef: PropTypes.shape({
    headerName: PropTypes.string,
  }),
};

export default CheckListItemRenderer;
