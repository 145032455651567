import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { substanceActions, substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { productInciImpurityModalSelectors, productInciImpurityModalTypes } from '../../../../redux/modal/productInciImpurityModal';
import SubstanceList from './SubstanceList';
import {inciListTypes} from "../../../../redux/inciList";

class SubstanceListContainer extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return <SubstanceList {...this.props} height="70vh" />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const substanceList = substanceSelectors.getImpuritySubstances(state);
  return {
    inciSubstanceList: productInciImpurityModalSelectors.makeGetInciSubstanceListForProduct(state,productId)(),
    substanceList,
    showLoading:
      substanceSelectors.getIsLoadingImpuritySubstances(state) ||
      applicationSelectors.isLoading(state, productInciImpurityModalTypes.SAVE_INCI_SUBSTANCE_IMPURITIES.REQUEST, inciListTypes.INCI_LIST_FOR_PRODUCT.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onMount: () => {
    dispatch(substanceActions.fetchSubstances.request({ IsImpurity: true }));
  },
});

SubstanceListContainer.propTypes = {
  onMount: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceListContainer);
