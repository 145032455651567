import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as processTypeTypes from './processTypeTypes';

export const listProcessTypes = createRequestActions(processTypeTypes.PROCESSTYPE_LIST);
export const addProcessType = createAction(processTypeTypes.ADD_PROCESSTYPE, () => ({ ProcessTypeId: undefined }));
export const editProcessType = createAction(processTypeTypes.EDIT_PROCESSTYPE, ProcessTypeId => ({
  ProcessTypeId,
}));
export const saveProcessType = createRequestActions(processTypeTypes.SAVE_PROCESSTYPE);
export const listProcessTypeRefs = createRequestActions(processTypeTypes.PROCESSTYPEREF_LIST);
