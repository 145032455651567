import { createSelector } from 'reselect';
import * as formulaReviewSelectors from '../formulaReview/formulaReviewSelectors';

export const getById = ({ rawMatDataReview }) => rawMatDataReview.byId;
export const getIds = ({ rawMatDataReview }) => rawMatDataReview.list.allIds;
export const getRawMatDataReview = (state, id) => getById(state)[id];
export const getRawMatDataReviewList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getRawMatDataReviewListForFormulaReview = createSelector(
  [formulaReviewSelectors.getFormulaReview, getById],
  (formulaReview, rawMatDataReviewById) =>
    formulaReview.RawMatDataReviews.map(rawMatDataId => rawMatDataReviewById[rawMatDataId]),
);
