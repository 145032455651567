import * as substanceRawMatSelectors from './substanceRawMatSelectors';
import * as substanceRawMatActions from './substanceRawMatActions';
import * as substanceRawMatTypes from './substanceRawMatTypes';

import sagas, * as substanceRawMatSagas from './substanceRawMatSagas';
import reducer from './substanceRawMatReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  substanceRawMatSelectors,
  substanceRawMatActions,
  substanceRawMatTypes,
  substanceRawMatSagas,
};
export default reducer;
