import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { impurityModalActions, impurityModalTypes } from '../../../../redux/modal/impurityModal';
import ImpurityListModal from './ImpurityListModal';

const ImpurityListModalContainer = props => <ImpurityListModal {...props} />;

const mapStateToProps = (state, { rawMatId }) => {
  const rawMat = rawMatSelectors.getById(state)[rawMatId];
  const rawMatName = R.propOr('', 'Name')(rawMat);
  return {
    title: I18n.t('impurityListModal.title', { rawMatName }),
    isSaveDisabled:
      substanceSelectors.getIsLoadingImpuritySubstances(state) ||
      applicationSelectors.isLoading(state, impurityModalTypes.SAVE_RAWMAT_PROPORTION_IMPURITIES.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onSaveButtonClick: data => {
    dispatch(impurityModalActions.onSaveButtonClick(rawMatId, data));
  },
});

ImpurityListModalContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpurityListModalContainer);
