import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import { cosAnnexeSelectors } from '../../../../redux/cosAnnexe';
import formNames from '../../../../constants/formNames';
import CosAnnexeFormModal from './CosAnnexeFormModal';

const CosAnnexeFormModalContainer = props => <CosAnnexeFormModal {...props} />;

const mapStateToProps = (state, { cosAnnexeId }) => {
  const cosAnnexe = cosAnnexeSelectors.getById(state)[cosAnnexeId];
  return {
    isFormInValid: isInvalid(formNames.cosAnnexe)(state),
    isLoading: isSubmitting(formNames.cosAnnexe)(state),
    isFormPristine: isPristine(formNames.cosAnnexe)(state),
    title: cosAnnexe
      ? I18n.t('cosAnnexeModalForm.EditTitle', { cosAnnexeName: `${cosAnnexe.Code} - ${cosAnnexe.Name}` })
      : I18n.t('cosAnnexeModalForm.AddTitle'),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.cosAnnexe)),
});

CosAnnexeFormModalContainer.defaultProps = {
  cosAnnexeId: undefined,
};

CosAnnexeFormModalContainer.propTypes = {
  cosAnnexeId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CosAnnexeFormModalContainer);
