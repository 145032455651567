import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ImpactAssessmentActionsModal from './ImpactAssessmentActionsModal';
import { impactAssessmentSelectors } from '../../../../redux/impactAssessment';

const ImpactAssessmentActionsModalContainer = props => <ImpactAssessmentActionsModal {...props} />;

const mapStateToProps = (state, { ImpactAssessmentId }) => {
  const impactAssessment = impactAssessmentSelectors.getById(state)[ImpactAssessmentId];
  const impactAssessmentCode = R.propOr('', 'ImpactAssessmentCode', impactAssessment);
  return {
    title: I18n.t('impactAssessmentActionsModal.title', { impactAssessmentCode }),
  };
};

ImpactAssessmentActionsModalContainer.propTypes = {
  ImpactAssessmentId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(ImpactAssessmentActionsModalContainer);
