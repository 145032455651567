import { createRequestActions, createFetchAction } from '../utils';
import * as shadeProportionTypes from './shadeProportionTypes';

export const fetchShadeProportions = createFetchAction(
  shadeProportionTypes.FETCH_SHADE_PROPORTIONS,
  'shadeProportionId',
);
export const saveShadeProportions = createRequestActions(
  shadeProportionTypes.SAVE_SHADE_PROPORTIONS,
  (productId, data) => ({
    productId,
    data,
  }),
);

export const deleteShadeProportion = createRequestActions(
  shadeProportionTypes.DELETE_SHADE_PROPORTION,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
