import { createRequestActions, createFetchAction } from '../utils';
import * as companyDistributorAgreementTypes from './companyDistributorAgreementTypes';

export const fetchCompanyDistributorAgreementsForCompany = createFetchAction(
  companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY,
  'companyDistributorAgreementId',
);
export const fetchCompanyDistributorAgreementsForDistributor = createFetchAction(
  companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR,
  'companyDistributorAgreementId',
);
export const saveCompanyDistributorAgreementForCompany = createRequestActions(
  companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
export const saveCompanyDistributorAgreementForDistributor = createRequestActions(
  companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
export const markAsSentAgreement = createRequestActions(
  companyDistributorAgreementTypes.MARK_AS_SENT_AGREEMENT,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
export const markAsSignedAgreement = createRequestActions(
  companyDistributorAgreementTypes.MARK_AS_SIGNED_AGREEMENT,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
export const revokeAgreement = createRequestActions(
  companyDistributorAgreementTypes.REVOKE_AGREEMENT,
  (companyDistributorAgreementId, query) => ({ companyDistributorAgreementId }),
  (data, query) => ({
    query,
  }),
);

export const removeDistributorFromCompany = createRequestActions(companyDistributorAgreementTypes.REMOVE_AGREEMENT);
export const removeDistributorFromCompanyAction = (CompanyId, { CompanyDistributorAgreementId }) => ({
  type: companyDistributorAgreementTypes.REMOVE_AGREEMENT.REQUEST,
  payload: {
    CompanyId,
    CompanyDistributorAgreementId,
  },
});
