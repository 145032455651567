import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { css } from 'glamor';

const styles = {
  popover: css({
    minwidth: '800px !important',
  }),
};

// We should use a sateful component otherwise we get an error. See: https://github.com/ag-grid/ag-grid/issues/1490
// It happens with ag-grid 12.0. It's probably fixed in a more recent version.
// eslint-disable-next-line
class TooltipRenderer extends React.Component {
  render() {
    const { value, colDef } = this.props;
    const popoverTop = (
      <Popover id="popover-positioned-top" title={colDef.headerName || ''} >
        {value?.substring(0, 2162)}
      </Popover>
    );

    return (
      <OverlayTrigger placement="top" overlay={popoverTop}>
        <div>{value}</div>
      </OverlayTrigger>
    );
  }
}

TooltipRenderer.defaultProps = {
  value: '',
  colDef: {
    headerName: '',
  },
};

TooltipRenderer.propTypes = {
  value: PropTypes.string,
  colDef: PropTypes.shape({
    headerName: PropTypes.string,
  }),
};

export default TooltipRenderer;
