import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'glamor';

class AutoHeight extends Component {
  static propTypes = {
    children: PropTypes.node,
    externalUsedHeight: PropTypes.number,
  };

  static defaultProps = {
    externalUsedHeight: 130,
  };

  constructor(props) {
    super(props);
    this.state = {
      windowsHeight: 0,
      windowsWidth: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ windowsWidth: window.innerWidth, windowsHeight: window.innerHeight });
  }

  render() {
    const { children, externalUsedHeight } = this.props;
    const styles = {
      containerHeight: css({
        height: `${this.state.windowsHeight - externalUsedHeight}px`,
        overflow: 'auto',
      }),
    };
    return (
      <div className={styles.containerHeight} data-autohgh>
        {children}
      </div>
    );
  }
}

export default AutoHeight;
