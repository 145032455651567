import React from 'react';
import { Grid, Row, Col, Tabs, Tab  } from 'react-bootstrap';
import { PhraseListContainer } from './PhraseList';
import { I18n } from 'react-redux-i18n';
import { PhraseTranslationListContainer } from "./PhraseTranslationList";
import { css } from 'glamor';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
};

const Phrases = props =>
  (<div className={styles.detailsContainer}>
    <Grid fluid>
      <Row>
        <Col md={12}>
          <Tabs id="PhraseTabs" mountOnEnter unmountOnExit>
            <Tab eventKey={1} title={I18n.t('phrase.tabTitle')}>
              <PhraseListContainer {...props} />
            </Tab>
            <Tab eventKey={2} title={I18n.t('phraseTranslation.tabTitle')}>
              <PhraseTranslationListContainer {...props} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Grid>
    <br/><br/>
  </div>);

export default Phrases;
