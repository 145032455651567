import { combineReducers } from 'redux';
import * as R from 'ramda';
import { handleActions, combineActions } from 'redux-actions';
import queryString from 'query-string';
import * as labelReviewTypes from './labelReviewTypes';

const byId = handleActions(
  {
    [labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.labelReviews,
    }),
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload, meta = {} }) =>
      (payload.entities.labelReviews
        ? {
          ...state,
          ...payload.entities.labelReviews,
        }
        : state),
  },
  {},
);

const allIds = handleActions(
  {
    [labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
  },
  [],
);

const byQueryIds = handleActions(
  {
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [labelReviewTypes.FETCH_LABEL_REVIEWS.REQUEST]: (state, { payload, meta = {} }) => {
      const { query = {} } = payload;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: true,
      };
    },
    [combineActions(labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS, labelReviewTypes.FETCH_LABEL_REVIEWS.FAILURE)]: (
      state,
      { payload, meta = {} },
    ) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: false,
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
