import { createSelector } from 'reselect';
import uuidV1 from 'uuid/v1';
import { substanceSelectors } from '../../substance';
import { rawMatProportionSelectors } from '../../rawMatProportion';

export const makeGetRawMatProportionsWithAllergenSubstanceForRawMat = createSelector(
  [rawMatProportionSelectors.getRawMatProportionListForRawMat, substanceSelectors.getAllergenSubstances],
  (getRawMatProportionsForRawMat, substanceAllergenList) =>
    createSelector(getRawMatProportionsForRawMat, rawMatProportionForRawMat => {
      const sustanceAllergenIds = substanceAllergenList.map(substance => substance.SubstanceId);
      return rawMatProportionForRawMat.filter(rmp => sustanceAllergenIds.includes(rmp.SubstanceId));
    }),
);

export const makeGetRawMatProportionListForRawMat = createSelector(
  [rawMatProportionSelectors.getRawMatProportionListForRawMat, substanceSelectors.getAllergenSubstances],
  (getRawMatProportionsForRawMat, substanceAllergenList) =>
    createSelector(getRawMatProportionsForRawMat, rawMatProportionForRawMat =>
      substanceAllergenList.map(({ SubstanceId }) => {
        const correspondingRawMatProportion = rawMatProportionForRawMat.find(rmp => rmp.SubstanceId === SubstanceId);
        return correspondingRawMatProportion
          ? { ...correspondingRawMatProportion }
          : {
            SubstanceId,
            RawMatProportionId: uuidV1(),
          };
      }),
    ),
);
