import * as workItemSelectors from './workItemSelectors';
import * as workItemActions from './workItemActions';
import * as workItemTypes from './workItemTypes';
import * as workItemSchemas from './workItemSchemas';

import sagas from './workItemSagas';
import reducer from './workItemReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { workItemSelectors, workItemActions, workItemTypes, workItemSchemas };
export default reducer;
