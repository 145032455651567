import { createRequestActions, createFetchAction } from '../utils';
import * as substanceCosAnnexeTypes from './substanceCosAnnexeTypes';

export const fetchSubstanceCosAnnexes = createFetchAction(
  substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES,
  'substanceCosAnnexeId',
);

export const fetchSubstanceCosAnnexesForSubstance = createRequestActions(
  substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES_FOR_SUBSTANCE,
  substanceId => ({
    query: {
      substanceId,
    },
  }),
  (result, data) => data,
);

export const saveSubstanceCosAnnexe = createRequestActions(
  substanceCosAnnexeTypes.SAVE_SUBSTANCE_COS_ANNEXE,
  data => data,
  (result, data) => data,
);

export const deleteSubstanceCosAnnexe = createRequestActions(
  substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE,
  (id, { ...query }) => ({
    id,
    query,
  }),
);

export const linkCosAnnexeToCurrentSubstance = createRequestActions(substanceCosAnnexeTypes.LINK_COS_ANNEXE);
