import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ShadeCompoSelectors from './ShadeCompoSelectors';
import ShadeCompoList from './ShadeCompoList';
import { shadeProportionActions, shadeProportionTypes } from '../../../../redux/shadeProportion';
import { applicationSelectors } from '../../../../redux/application';
import { rawMatSelectors } from '../../../../redux/rawMat';
import { productProportionSelectors } from '../../../../redux/productProportion';

class ShadeCompoListContainer extends React.Component {
  componentDidMount = () => {
    this.props.onLoad();
  };

  render() {
    return <ShadeCompoList {...this.props} />;
  }
}

const mapStateToProps = (state, { shadeId, productId }) => {
  const getShadeCompoList = ShadeCompoSelectors.makeGetShadeCompoForShade(shadeId);
  const shadeCompoList = getShadeCompoList(state);
  return {
    isLoading: applicationSelectors.isLoading(
      state,
      shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST,
      shadeProportionTypes.SAVE_SHADE_PROPORTIONS.REQUEST,
    ),
    rawMatList: rawMatSelectors.makeGetRawMatListForProduct(productId)(state),
    productProportionList: productProportionSelectors.getproductProportionList(state),
    shadeCompoList,
  };
};

const mapDispatchToProps = (dispatch, { shadeId }) => ({
  onLoad: () => {
    if (shadeId > 0) {
      dispatch(shadeProportionActions.fetchShadeProportions.request({ shadeId }));
    }
  },
  onRefresh: () => dispatch(shadeProportionActions.fetchShadeProportions.request({ shadeId })),
});

ShadeCompoListContainer.propTypes = {
  shadeId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadeCompoListContainer);
