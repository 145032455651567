import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, ButtonToolbar } from 'react-bootstrap';
import * as R from 'ramda';
import { DateCellRenderer } from '../../_Common/Grids';
import workflowStatus from '../../../constants/workflowStatus';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { css } from 'glamor';

const ActionButtonsRenderer = props => {
  const {
    onPrintClick,
    onMarkAsSentClick,
    getIsSentButtonDisabled,
    onMarkAsSignedClick,
    getIsSignedButtonDisabled,
    onRevokeClick,
    getRevokeButtonDisabled,
    data,
  } = props;

  const handleButtonClick = fn => () => fn(data);

  const isSentButtonDisabled = getIsSentButtonDisabled(data);
  const isSignedButtonDisabled = getIsSignedButtonDisabled(data);
  const revokeButtonDisabled = getRevokeButtonDisabled(data);

  return (
    <div>
      <ButtonToolbar>
        <Button bsStyle="info" bsSize="xsmall" type="button" onClick={handleButtonClick(onPrintClick)}>
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="distributorAgreements.print" />
        </Button>
        <Button
          bsStyle="warning"
          bsSize="xsmall"
          type="button"
          disabled={isSentButtonDisabled}
          onClick={handleButtonClick(onMarkAsSentClick)}
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="distributorAgreements.markAsSent" />
        </Button>
        <Button
          bsStyle="success"
          bsSize="xsmall"
          type="button"
          disabled={isSignedButtonDisabled}
          onClick={handleButtonClick(onMarkAsSignedClick)}
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="distributorAgreements.markAsSigned" />
        </Button>
        <Button
          bsSize="xsmall"
          bsStyle="danger"
          type="button"
          disabled={revokeButtonDisabled}
          onClick={handleButtonClick(onRevokeClick)}
        >
          <i className="fa fa-ban" aria-hidden="true" />
          &nbsp;
          <Translate value="distributorAgreements.revoke" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onRevokeClick: PropTypes.func.isRequired,
  getRevokeButtonDisabled: PropTypes.func.isRequired,
};

const DistributorAgreementList = props => {
  const {
    companyDistributorAgreementList,
    companyList,
    onRefresh,
    isLoading,
    onPrintButtonClick,
    onMarkAsSentButtonClick,
    onMarkAsSignedButtonClick,
    onRevokeButtonClick,
    onAddButtonClick,
    agreementStatusList,
    distributorId,
  } = props;

  const getAgreementStatusByCode = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('Code'), R.identity]))(agreementStatusList),
  );
  const getIsSentButtonDisabledStatusFromStatusCode = R.ifElse(
    R.equals(workflowStatus.AGREEMENT_CREATED),
    R.always(false),
    R.always(true),
  );
  const getIsSignedButtonDisabledStatusFromStatusCode = R.ifElse(
    R.equals(workflowStatus.AGREEMENT_SENT),
    R.always(false),
    R.always(true),
  );
  const getRevokeButtonDisabledStatusFromStatusCode = R.ifElse(
    R.equals(workflowStatus.AGREEMENT_REVOKED),
    R.always(true),
    R.always(false),
  );

  const getCompanyById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('CompanyId'), R.identity]))(companyList),
  );

  const getCompanyFromRow = R.compose(
    getCompanyById,
    R.path(['data', 'CompanyId']),
  );

  const styles = {
    backgreen: css({
      backgroundColor: '#81CF6F',
      color: 'white',
    }),
    backred: css({
      backgroundColor: '#D35457',
      color: 'white',
    }),
  };

  const columns = [
    {
      headerName: I18n.t('distributorAgreements.id'),
      field: 'CompanyDistributorAgreementId',
      width: 50,
      sort: 'desc',
    },
    {
      headerName: I18n.t('distributorAgreements.company'),
      field: 'Company.ShortName',
    },
    {
      headerName: I18n.t('distributorAgreements.date'),
      width: 50,
      field: 'LastModifiedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: { includeHours: true, },
      sort: 'desc',
    },
    {
      headerName: I18n.t('distributorAgreements.status'),
      width: 50,
      valueGetter: R.compose(
        R.prop('Name'),
        getAgreementStatusByCode,
        R.path(['data', 'StatusCode']),
      ),
      cellClassRules: {
        [styles.backred](params) {
          return params.value === 'Revoked';
        },
        [styles.backgreen](params) {
          return params.value === 'Signed';
        },
      },
    },
    {
      headerName: I18n.t('general.actions'),
      width: 70,
      cellRendererFramework: ActionButtonsRenderer,
      cellRendererParams: {
        onPrintClick: onPrintButtonClick,
        onMarkAsSentClick: onMarkAsSentButtonClick,
        onMarkAsSignedClick: onMarkAsSignedButtonClick,
        onRevokeClick: onRevokeButtonClick,
        getIsSentButtonDisabled: R.compose(
          getIsSentButtonDisabledStatusFromStatusCode,
          R.prop('StatusCode'),
        ),
        getIsSignedButtonDisabled: R.compose(
          getIsSignedButtonDisabledStatusFromStatusCode,
          R.prop('StatusCode'),
        ),
        getRevokeButtonDisabled: R.compose(
          getRevokeButtonDisabledStatusFromStatusCode,
          R.prop('StatusCode'),
        ),
      },
    },
  ];

  return (
    <SelectionGrid
      key={`distributor-${distributorId}`}
      data={companyDistributorAgreementList}
      onRefresh={onRefresh}
      isLoading={isLoading}
      columns={columns}
      keyName="CompanyDistributorAgreementId"
      showAddButton
      onAdd={onAddButtonClick}
    />
  );
};

DistributorAgreementList.propTypes = {
  companyDistributorAgreementList: PropTypes.array.isRequired, //eslint-disable-line
  agreementStatusList: PropTypes.array.isRequired, //eslint-disable-line
  onRefresh: PropTypes.func.isRequired,
  onAgreementClick: PropTypes.func.isRequired,
  onRevokeButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  documentList: PropTypes.array.isRequired, //eslint-disable-line
};

export default DistributorAgreementList;
