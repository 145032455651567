import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { createSaveForm } from '../form/utils';
import taskApi from '../../api/taskApi';
import * as taskTypes from './taskTypes';
import * as taskActions from './taskActions';
import { taskListSchema } from './taskSchemas';
import formNames from '../../constants/formNames';
import { callApiSaga, createFetchSaga } from '../sagaUtils';
import { modalTypes, modalActions, modalSelectors } from '../modal';
import {productActions} from "../product";

export default function* root() {
  yield all([
    takeLatest(taskTypes.TASK_LIST.REQUEST, getTaskListSaga),
    takeLatest(taskTypes.SAVE_TASK.REQUEST, saveTaskFormSaga),
    takeLatest(taskTypes.DELETE_TASK.REQUEST, deleteTaskSaga),
    takeLatest(taskTypes.UPDATE_TASK_STATUS.REQUEST, updateTaskStatusSaga),
    takeLatest(taskTypes.START_TASK.REQUEST, startTaskSaga),
    takeLatest(taskTypes.STOP_TASK.REQUEST, stopTaskSaga),
  ]);
}

const saveTaskFormSaga = createSaveForm(formNames.task, saveTaskSaga);

function* getTaskListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const tasks = yield call(taskApi.getTasks, payload);
    // console.log(tasks.select(t => t.Priority == 0).size);
    const normalizedData = normalize(tasks, taskListSchema);
    yield put(taskActions.listTasks.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(taskActions.listTasks.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* deleteTaskSaga({ payload, type }) {
  const { taskId, orderId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(taskActions.deleteTask, taskApi.deleteTask, taskId);
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}

export function* saveTaskSaga({ type, payload }) {
  // console.log('date', payload.DueDate);
  yield put(applicationActions.setLoading(type));
  const { orderId, productId } = payload;
  if (payload.taskIds == undefined) {
    yield callApiSaga(taskActions.saveTask, taskApi.saveTask, payload);
  }
  else {
    console.log(payload.taskIds)
    yield callApiSaga(taskActions.saveMultipleTasks, taskApi.saveMultipleTasks, payload);
  }

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.TASK_FORM) {
    yield put(modalActions.hideModal());
  }

  if (payload.searchCriteria) {
    yield put(taskActions.listTasks.request(payload.searchCriteria));
  } else if(productId){
    yield put(productActions.listProductTasks.request(productId));
  }else {
    yield put(taskActions.listTasks.request({ orderId, productId, IncludeDetails: true }));
  }
  yield put(applicationActions.unsetLoading(type));
}

const updateTaskStatusSaga = createFetchSaga(
  function* updatePTaskStatusSaga({ payload, type }) {
    const id = yield callApiSaga(taskActions.saveTask, taskApi.updateStatus, payload);
    return id;
  },
  {
    * onSuccess(id, payload) {
      yield put(taskActions.saveTask.success(id, payload));
      const isModalShown = yield select(modalSelectors.isModalShown);
      if (isModalShown) {
        yield put(modalActions.hideModal());
      }
      if (id !== undefined) {
        yield put(taskActions.listTasks.request(payload.searchCriteria));
      }
    },
    onFailure: (error, payload) => put(taskActions.saveTask.failure(error, payload)),
  },
);

export function* startTaskSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { searchCriteria, taskId } = payload;
  yield callApiSaga(taskActions.startTask, taskApi.startTask, taskId);
  yield put(taskActions.listTasks.request(searchCriteria));
  yield put(applicationActions.unsetLoading(type));
}

export function* stopTaskSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { searchCriteria, taskId } = payload;
  yield callApiSaga(taskActions.stopTask, taskApi.stopTask, taskId);
  yield put(taskActions.listTasks.request(searchCriteria));
  yield put(applicationActions.unsetLoading(type));
}
