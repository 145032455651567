import { schema } from 'normalizr';


export const actionSchema = new schema.Entity(
  'actions',
  {},
  { idAttribute: 'ActionId' },
);

export const actionListSchema = [actionSchema];
