import { handleActions } from 'redux-actions';
import packageJson from '../../../package.json';
import * as types from './applicationTypes';

export const initialState = {
  generalError: '',
  notificationMessage: '',
  showNotification: false,
  notificationLevel: 'success',
  applicationVersion: packageJson.version,
  loaders: {},
};

export default handleActions(
  {
    [types.SET_GENERAL_ERROR]: (state, action) => ({ ...state, generalError: action.payload }),
    [types.CLEAR_GENERAL_ERROR]: state => ({
        ...state,
        generalError: '',
      }),
    [types.SHOW_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      notificationMessage: payload.message,
      showNotification: true,
      notificationLevel: payload.level,
    }),
    [types.CLEAR_NOTIFICATION]: state => ({
      ...state,
      notificationMessage: '',
      showNotification: false,
      notificationLevel: 'success',
    }),
    [types.SET_LOADING]: (state, action) => ({
      ...state,
      loaders: {
        ...state.loaders,
        [action.payload]: true,
      },
    }),
    [types.UNSET_LOADING]: (state, action) => ({
      ...state,
      loaders: {
        ...state.loaders,
        [action.payload]: false, // CLT: Better to remove the property?
      },
    }),
  },
  initialState,
);
