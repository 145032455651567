import { takeLatest, put, all } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import controlCenterApi from '../../api/controlCenterApi';
import * as controlCenterTypes from './controlCenterTypes';
import * as controlCenterActions from './controlCenterActions';
// import * as controlCenterSchemas from './controlCenterSchemas';

export default function* root() {
  yield all([
    // takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(controlCenterTypes.START_HANGFIRE.REQUEST, startHangfireSaga),
    takeLatest(controlCenterTypes.STOP_HANGFIRE.REQUEST, stopHangfireSaga),
    takeLatest(controlCenterTypes.ALLOW_LOGIN.REQUEST, allowLoginSaga),
    takeLatest(controlCenterTypes.PROHIBIT_LOGIN.REQUEST, prohibitLoginSaga),
  ]);
}

export function* startHangfireSaga() {
  yield put(applicationActions.setLoading(controlCenterTypes.START_HANGFIRE.REQUEST));
  yield callApiSaga(
    controlCenterActions.startHangfire,
    controlCenterApi.startHangfire,
  );
  yield put(applicationActions.unsetLoading(controlCenterTypes.START_HANGFIRE.REQUEST));
}

export function* stopHangfireSaga() {
  yield put(applicationActions.setLoading(controlCenterTypes.STOP_HANGFIRE.REQUEST));
  yield callApiSaga(
    controlCenterActions.stopHangfire,
    controlCenterApi.stopHangfire,
  );
  yield put(applicationActions.unsetLoading(controlCenterTypes.STOP_HANGFIRE.REQUEST));
}

export function* allowLoginSaga() {
  yield put(applicationActions.setLoading(controlCenterTypes.ALLOW_LOGIN.REQUEST));
  yield callApiSaga(
    controlCenterActions.allowLogin,
    controlCenterApi.allowLogin,
  );
  yield put(applicationActions.unsetLoading(controlCenterTypes.ALLOW_LOGIN.REQUEST));
}

export function* prohibitLoginSaga() {
  yield put(applicationActions.setLoading(controlCenterTypes.PROHIBIT_LOGIN.REQUEST));
  yield callApiSaga(
    controlCenterActions.prohibitLogin,
    controlCenterApi.prohibitLogin,
  );
  yield put(applicationActions.unsetLoading(controlCenterTypes.STOP_HANGFIRE.REQUEST));
}
/* function* savecontrolCenterSaga(action) {
  yield put(applicationActions.setLoading(controlCenterTypes.SAVE_controlCenter.REQUEST));
  const controlCenterId = yield callApiSaga(
    controlCenterActions.savecontrolCenter,
    controlCenterApi.savecontrolCenter,
    action.payload,
  );
  if (controlCenterId !== undefined) {
    const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
    yield put(replace({ ...previousLocation, pathname: `/categories/${controlCenterId}` }));
    yield put(controlCenterActions.controlCenter.request(controlCenterId));
    yield put(controlCenterActions.listCategories.request());
  }
  yield put(applicationActions.unsetLoading(controlCenterTypes.SAVE_controlCenter.REQUEST));
} */