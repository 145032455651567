import * as productInciImpurityModalActions from './productInciImpurityModalActions';
import * as productInciImpurityModalTypes from './productInciImpurityModalTypes';
import * as productInciImpurityModalSelectors from './productInciImpurityModalSelectors';
import sagas from './productInciImpurityModalSagas';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productInciImpurityModalActions, productInciImpurityModalTypes, productInciImpurityModalSelectors };
