import { put, all, select, takeEvery } from 'redux-saga/effects';
import * as orderItemFormActions from './orderItemFormActions';
import { processTypeSelectors } from '../../../redux/processType';
import { orderSelectors } from '../../../redux/order';
import { change, getFormValues } from 'redux-form';
import formNames from '../../../constants/formNames';

const formName = formNames.orderItem;

export default function* root() {
  yield all([
    takeEvery(orderItemFormActions.onProcessTypeSelected, onProcessTypeSelectedSaga),
    takeEvery(orderItemFormActions.onChangeCheckValues, onChangeCheckOrderItemValues),
  ]);
}

function* onProcessTypeSelectedSaga({ payload }) {
  const { processTypeId } = payload;
  if (processTypeId) {
    const processType = yield select(state => processTypeSelectors.getById(state)[processTypeId]);
    yield put(change(formName, 'UnitPrice', processType.UnitPrice));
  }
  yield put(orderItemFormActions.onChangeCheckValues());
}

function* onChangeCheckOrderItemValues(data) {
  const dataChanged = data.payload;
  const formValues = {
    ...(yield select(state => getFormValues(formName)(state))),
    ...dataChanged,
  };
  const order = yield select(state => orderSelectors.getCurrentOrderProcessInfo(state));
  const DiscountPercent = order.DiscountPercentage || 0;
  console.log('formValues', formValues);
  const UnitPrice = formValues.UnitPrice || null;
  const Discount = (UnitPrice * DiscountPercent) / 100;
  const Quantity = formValues.Quantity || 1;
  const ShadeCount = formValues.ShadeCount ? (formValues.ShadeCount == 0 ? 1 : formValues.ShadeCount) : 1
  if (UnitPrice && Quantity) {
    const Subtotal = Quantity * ShadeCount * UnitPrice - Quantity * Discount;
    yield put(change(formName, 'Subtotal', Subtotal));
  }
}
