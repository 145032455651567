import { createSelector } from 'reselect';
import { getOr } from 'lodash/fp';
import * as R from 'ramda';
import * as productSelectors from '../product/productSelectors';
import * as rawMatSelectors from '../rawMat/rawMatSelectors';
import * as rawMatProportionSelectors from '../rawMatProportion/rawMatProportionSelectors';
import * as substanceProportionSelectors from '../substanceProportion/substanceProportionSelectors';
import * as substanceSelectors from '../substance/substanceSelectors';

export const getById = ({ productProportion }) => productProportion.byId;
export const getIds = ({ productProportion }) => productProportion.list.allIds;
export const getproductProportion = (state, id) => getById(state)[id];
export const getproductProportionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getProductProportionsForProduct = productId =>
  createSelector(getproductProportionList, R.filter(R.whereEq({ ProductId: productId })));

export const makeGetProductProportionForProduct = createSelector(
  getProductProportionsForProduct,
  productProportionsForProduct => productProportionsForProduct,
);

export const makeGetProductProportionsForProduct = createSelector(getproductProportionList, productProportions =>
  createSelector(
    productId => R.filter(R.whereEq({ ProductId: productId }), productProportions),
    productProportionsForProduct => productProportionsForProduct,
  ),
);

const getProductProportionForRawMat = rawMatId =>
  createSelector(getproductProportionList, productProportionList =>
    productProportionList.filter(pp => pp.RawMatId === rawMatId),
  );

const getProductsForRawMat = rawMatId =>
  createSelector(
    [getProductProportionForRawMat(rawMatId), productSelectors.getById],
    (productProportionsForRawMat, productById) => productProportionsForRawMat.map(pp => productById[pp.ProductId]),
  );

export const makeGetProductsForRawMat = createSelector(getProductsForRawMat, productsForRawMat => productsForRawMat);

const getRawMatProportionPercentageInProduct = productProportion => rawMatProportionId =>
  R.compose(
    R.prop('PercentageInProduct'),
    R.find(R.propEq('RawMatProportionId', rawMatProportionId)),
    R.prop('RawMatProportionPercentagesInProduct'),
  )(productProportion);

export const makeGetCompositionForProduct = () =>
  createSelector(
    [
      productSelectors.getById,
      getById,
      rawMatSelectors.getById,
      makeGetProductProportionsForProduct,
      rawMatProportionSelectors.getById,
      substanceProportionSelectors.getById,
      substanceSelectors.getById,
    ],
    (
      productById,
      productProportionById,
      rawMatById,
      getProductProportionsForProduct,
      rawMatProportionById,
      substanceProportionById,
      substanceById,
    ) =>
      createSelector(R.identity, productId => {
        const product = productById[productId];
        if (!product) return undefined;
        return getProductProportionsForProduct(productId)
          .map(pp => {
            // const pp = productProportionById[ppId];
            if (!pp) return null;
            const rawMat = rawMatById[pp.RawMatId];
            return {
              ...pp,
              Type: 'rawMat',
              PercentageInParent: pp.Percentage,
              PercentageInProduct: pp.Percentage,
              Name: rawMat.Name,
              RmCode: rawMat.Code,
              Supplier: rawMat.SupplierName,
              Id: `ProductProportion${pp.ProductProportionId}`,
              Children: getOr([], 'RawMatProportions', rawMat).map(rpId => {
                const rmp = rawMatProportionById[rpId];
                // if (!rmp) return [];
                const substance = substanceById[rmp.SubstanceId];
                return {
                  ...rmp,
                  Id: `RawMatProportion${rmp.RawMatProportionId}`,
                  Type: 'complexSubstance',
                  ReferencedSubstance: substance,
                  ProductProportionId: pp.ProductProportionId,
                  PercentageInParent: rmp.Percentage,
                  // PercentageInProduct: rmp.Percentage * pp.Percentage / 100,
                  PercentageInProduct: getRawMatProportionPercentageInProduct(pp)(rmp.RawMatProportionId),
                  Name: substance.InciName,
                  Children: getOr([], 'SubstanceProportions', substance).map(spId => {
                    const sp = substanceProportionById[spId];
                    const referencedSubstance = substanceById[sp.ReferencedSubstanceId];

                    return {
                      ...sp,
                      Type: 'simpleSubstance',
                      ProductProportionId: pp.ProductProportionId,
                      InciName: referencedSubstance.InciName,
                      Name: referencedSubstance.InciName,
                      PercentageInParent: sp.Percentage,
                      PercentageInProduct: 0,
                      // PercentageInProduct: sp.PercentageInProduct,
                    };
                  }),
                };
              }),
            };
          })
          .filter(val => val !== null);
      }),
  );
