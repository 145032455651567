import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, Panel, Checkbox, FormGroup, ControlLabel } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../_Common/Forms';
import { requiredField, isInteger, maxLength128, maxLength256 } from '../../../utils/fieldValidation';
import { ToxProfileChaptersDropdown } from '../../_Common/Dropdowns';

const ToxProfileItemForm = props => {
  const { toxProfileItemId, onSelectSubstanceToxProfileItem, isSelected, toxItem } = props;

  const handleSelectSubstanceToxProfileItem = ({ target }) =>
    onSelectSubstanceToxProfileItem({ id: toxProfileItemId, selected: target.checked });

  return (
    <Panel bsStyle="primary">
      <Row>
        <Col sm={1}>
          <Col sm={4} />
          <Col sm={8}>
            <FormGroup>
              <ControlLabel />
              <Checkbox checked={isSelected} onChange={handleSelectSubstanceToxProfileItem} />
            </FormGroup>
          </Col>
        </Col>
        <Col sm={5}>
          <Field
            name={`${toxProfileItemId}.chapter`}
            label={I18n.t('substanceToxProfile.chapter')}
            component={ToxProfileChaptersDropdown}
            validate={requiredField}
            subChapter={false}
          />
        </Col>
        <Col sm={5}>
          <Field
            name={`${toxProfileItemId}.subChapter`}
            label={I18n.t('substanceToxProfile.subChapter')}
            component={ToxProfileChaptersDropdown}
            subChapter
            parentChapterCode={toxItem.chapter}
          />
        </Col>
        <Col sm={1}>
          <Field
            name={`${toxProfileItemId}.Order`}
            label={I18n.t('substanceToxProfile.order')}
            component={InputField}
            showFeedbackIcon={false}
            validate={[isInteger, requiredField]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Field
            name={`${toxProfileItemId}.Summary`}
            label={I18n.t('substanceToxProfile.summary')}
            component={InputField}
            showFeedbackIcon={false}
            validate={maxLength256}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Field
            name={`${toxProfileItemId}.Description`}
            label={I18n.t('substanceToxProfile.description')}
            component={InputField}
            componentClass="textarea"
            showFeedbackIcon={false}
            validate={requiredField}
          />
        </Col>
      </Row>
      <Row>
      <Col sm={12}>
          <Field
            name={`${toxProfileItemId}.References`}
            label={I18n.t('substanceToxProfile.reference')}
            component={InputField}
            showFeedbackIcon={false}
            validate={maxLength256}
          />
        </Col>
      </Row>
    </Panel>
  );
};

ToxProfileItemForm.propTypes = {
  toxProfileItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelectSubstanceToxProfileItem: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

const ToxProfileItemsForm = props => {
  const { formValues, ...otherProps } = props;
  return (
    <div>
      <Row>
        {formValues &&
          formValues.visibleIds &&
          formValues.visibleIds.length > 0 &&
          formValues.visibleIds.map(toxProfileItemId => {
            const item = formValues[toxProfileItemId];
            const isSelected = formValues.selectedIds.includes(toxProfileItemId);
            return (
              <div key={toxProfileItemId}>
                <ToxProfileItemForm
                  toxProfileItemId={toxProfileItemId}
                  isSelected={isSelected}
                  toxItem={item}
                  {...otherProps}
                />
              </div>
            );
          })}
      </Row>
    </div>
  );
};
export default ToxProfileItemsForm;

ToxProfileItemsForm.propTypes = {
  formValues: PropTypes.object, //eslint-disable-line
};
