import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { modalSelectors, modalTypes, modalActions } from '../modal';
import { callApiSaga } from '../sagaUtils';
import draftLabelApi from '../../api/draftLabelApi';
import * as draftLabelTypes from './draftLabelTypes';
import * as draftLabelSchemas from './draftLabelSchemas';
import * as draftLabelActions from './draftLabelActions';

export default function* root() {
  yield all([
    takeLatest(draftLabelTypes.FETCH_DRAFT_LABELS.REQUEST, getDraftLabelSaga),
    takeLatest(draftLabelTypes.SAVE_DRAFT_LABEL.REQUEST, saveDraftLabelSaga),
    takeLatest(draftLabelTypes.DELETE_DRAFT_LABEL.REQUEST, deleteDraftLabelSaga),
  ]);
}

export function* saveDraftLabelSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { productId } = payload;
  yield callApiSaga(draftLabelActions.saveDraftLabel, draftLabelApi.saveDraftLabels, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.DRAFT_LABEL) {
    yield put(modalActions.hideModal());
  }

  yield put(draftLabelActions.fetchDraftLabels.request({ productId }));

  yield put(applicationActions.unsetLoading(type));
}

function* deleteDraftLabelSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { id, query } = payload;
  yield callApiSaga(draftLabelActions.deleteDraftLabel, draftLabelApi.deleteDraftLabels, id);
  yield put(draftLabelActions.fetchDraftLabels.request(query));
  yield put(applicationActions.unsetLoading(type));
}

export function* getDraftLabelSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const draftLabelList = yield call(draftLabelApi.getDraftLabels, payload);
    const normalizedData = normalize(draftLabelList, draftLabelSchemas.draftLabelListSchema);
    yield put(draftLabelActions.fetchDraftLabels.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(draftLabelActions.fetchDraftLabels.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
