import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const LimitedMeasureCellRenderer = props => {
  const { value, uom, nbDecimal, uomField, min, max, data } = props;
  if (isNullOrUndef(value)) return null;
  const uomToReturn = uomField ? data[uomField] : uom;
  let valueToReturn = null;
  if (isNullOrUndef(nbDecimal)) {
    valueToReturn = value;
  } else {
    valueToReturn = nbDecimal > 0 ? parseFloat(Number(value).toFixed(nbDecimal)) : Math.round(value);
  }
  if (valueToReturn <= min) valueToReturn = '<= ' + min
  if (valueToReturn >= max) valueToReturn = '>= ' + max
  return `${valueToReturn} ${uomToReturn || ''}`;
};

LimitedMeasureCellRenderer.propTypes = {
  uom: PropTypes.string.isRequired,
  params: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  nbDecimal: PropTypes.number,
  uomField: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number
};

export default LimitedMeasureCellRenderer;
