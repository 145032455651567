import { createSelector } from 'reselect';
import { rawMatSelectors } from '../rawMat';

export const getBySubstanceId = ({ substanceRawMat }) => substanceRawMat.bySubstanceId;

export const getRawMatIdsForSubstance = substanceId => state => getBySubstanceId(state)[substanceId];

export const getRawMatsForSubstance = substanceId =>
  createSelector([getRawMatIdsForSubstance(substanceId), rawMatSelectors.getById], (rawMatIds = [], rawMatById) =>
    rawMatIds.map(rmId => rawMatById[rmId]),
  );

export const makeGetRawMatsForSubstance = createSelector(
  getRawMatsForSubstance,
  rawMatsForSubstance => rawMatsForSubstance,
);
