import * as labelReviewInciWizardSelectors from './labelReviewInciWizardSelectors';
import * as labelReviewInciWizardActions from './labelReviewInciWizardActions';
import * as labelReviewInciWizardTypes from './labelReviewInciWizardTypes';
import sagas from './labelReviewInciWizardSagas';
import reducer from './labelReviewInciWizardReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);
export { labelReviewInciWizardSelectors, labelReviewInciWizardActions, labelReviewInciWizardTypes };
export default reducer;
