import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Form, Grid, Row, Col, Button, ControlLabel, FormGroup } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n, Translate } from 'react-redux-i18n';
import { InputField, DateField, SelectField } from '../_Common/Forms';
import formNames from '../../constants/formNames';
import DetailHeader from '../_Common/DetailHeader';
import BaseLoader from '../_Common/BaseLoader';
import { requiredField, maxLength16, maxLength128, isValidDate } from '../../utils/fieldValidation';
import OrderStatus from './OrderStatus';

const OrderForm = props => {
  const {
    currentOrder,
    handleSubmit,
    onSaveOrder,
    onDeleteOrder,
    orderTitle,
    companyList,
    isErasable,
    isNew,
    isLoadingDetails,
    expertList,
    customerSupportList,
    saleRepList,
    inDuplicateMode,
    orderId,
    isOrderEditable,
  } = props;

  const isReadOnly = (!isOrderEditable) || currentOrder.IsPending
  const onStartOrder = () => {
    props.change('StartDate', Date.now());
  };

  const onCloseOrder = () => {
    props.change('EndDate', Date.now());
  };

  const getActualColor = () => {
    if (props.initialValues.ForeseenProductCount) {
      return props.initialValues.ActualProductCount > props.initialValues.ForeseenProductCount ? 'tomato' : 'palegreen';
    }
    return '';
  };

  const getNotifiedColor = () => {
    if (props.initialValues.ForeseenProductCount) {
      return props.initialValues.ActualProductCount === props.initialValues.ForeseenProductCount &&
        props.initialValues.ActualProductCount === props.initialValues.NotifiedProductCount
        ? 'palegreen'
        : '';
    }
    return '';
  };

  return (
    <BaseLoader isLoading={isLoadingDetails}>
      <Form onSubmit={handleSubmit(onSaveOrder)}>
        <DetailHeader
          title={`${I18n.t('order.panelTitle')} : ${orderTitle}`}
          showDelete={isErasable}
          showDuplicate={!isNew && !inDuplicateMode}
          onDelete={onDeleteOrder}
          {...props}
        >
          <Grid fluid>
            <Row>
              <Col sm={2}>
                <Field
                  name="OrderNr"
                  label={`${I18n.t('order.orderNumber')} (1)`}
                  component={InputField}
                  placeholder={I18n.t('order.orderNumberPlaceholder')}
                  validate={maxLength16}
                  readOnly={isReadOnly}
                />
              </Col>
              <Col sm={4}>
                <Field
                  name="ProducerId"
                  label={`${I18n.t('order.producerName')} (*)`}
                  component={SelectField}
                  options={companyList}
                  labelField="FullName"
                  valueField="CompanyId"
                  validate={requiredField}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="SalesRepresentative"
                  label={I18n.t('order.salesRep')}
                  component={SelectField}
                  options={saleRepList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CreatedOn"
                  label={I18n.t('order.createdOn')}
                  component={DateField}
                  labelColSize={3}
                  popoverAttachment="top left"
                  popoverTargetAttachment="top left"
                  popoverTargetOffset="10px 10px"
                  disabled
                />
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <ControlLabel>{I18n.t('order.Status')}</ControlLabel>
                  <OrderStatus orderId={orderId} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="Description"
                  label={I18n.t('order.description')}
                  component={InputField}
                  componentClass="textarea"
                  validate={maxLength128}
                  rows={4}
                  readOnly={isReadOnly}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="CustomerSupport"
                  label={I18n.t('order.customerSupport')}
                  component={SelectField}
                  options={customerSupportList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="StartDate"
                  label={I18n.t('order.startDate')}
                  component={DateField}
                  validate={isValidDate}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={1}>
                <br />
                <Button
                  bsSize="small"
                  bsStyle="default"
                  type="button"
                  onClick={onStartOrder}
                  disabled={isReadOnly}
                >
                  <i className="fa fa-files-o" />
                  &nbsp;
                  <Translate value="order.start" />
                </Button>
              </Col>
            </Row>
            <Row />
            <Row>
              <Col sm={1}>
                <Field
                  name="ForeseenProductCount"
                  label={I18n.t('order.foreseenCount')}
                  component={InputField}
                  readOnly={isReadOnly}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="ActualProductCount"
                  label={I18n.t('order.actualCount')}
                  component={InputField}
                  readOnly
                  style={{ backgroundColor: getActualColor() }}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="NotifiedProductCount"
                  label={I18n.t('order.notifiedCount')}
                  component={InputField}
                  readOnly
                  style={{ backgroundColor: getNotifiedColor() }}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="Progress"
                  label={I18n.t('order.progress')}
                  component={InputField}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={1}>
              </Col>
              <Col sm={2} smOffset={1}>
                <Field
                  name="Expert"
                  label={I18n.t('order.expert')}
                  component={SelectField}
                  options={expertList}
                  labelField="LoginName"
                  valueField="LoginName"
                  formAlignment={false}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="EndDate"
                  label={I18n.t('order.endDate')}
                  component={DateField}
                  validate={isValidDate}
                  disabled={isReadOnly}
                />
              </Col>
              <Col sm={1}>
                <br />
                <Button
                  bsSize="small"
                  bsStyle="default"
                  type="button"
                  onClick={onCloseOrder}
                  disabled={isReadOnly}
                >
                  <i className="fa fa-files-o" />
                  &nbsp;
                  <Translate value="order.close" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Field
                  name="Comment"
                  label={I18n.t('order.comment')}
                  component={InputField}
                  componentClass="textarea"
                  rows={4}
                  readOnly={isReadOnly}
                />
              </Col>
            </Row>
          </Grid>
        </DetailHeader>
      </Form>
    </BaseLoader>
  );
};

const validate = values => {
  const errors = {};
  const startDate = moment(values.StartDate);
  const endDate = moment(values.EndDate);
  if (endDate.isBefore(startDate)) {
    errors.EndDate = I18n.t('form.endDateBeforeStarDateError');
    errors.StartDate = I18n.t('form.starDateAfterEndDateError');
  }

  return errors;
};

OrderForm.propTypes = {
  orderId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSaveOrder: PropTypes.func.isRequired,
  orderTitle: PropTypes.string.isRequired,
  isLoadingDetails: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      FullName: PropTypes.string.isRequired,
      CompanyId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  regulatoryRegions: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  inDuplicateMode: PropTypes.bool.isRequired,
  isOrderEditable: PropTypes.bool.isRequired,
  expertList: PropTypes.array.isRequired, //eslint-disable-line
  saleRepList: PropTypes.array.isRequired, //eslint-disable-line
  customerSupportList: PropTypes.array.isRequired, //eslint-disable-line
};

export default reduxForm({
  form: formNames.order,
  destroyOnUnmount: true,
  enableReinitialize: true,
  asyncBlurFields: [],
  initialValues: {
    OrderId: -1,
  },
  validate,
})(OrderForm);
