import {FormControl, FormGroup} from "react-bootstrap";
import React from "react";
import {errorsFormActions} from "../../../../redux/form/errorsForm";
import formFieldState from "../../../../constants/formFieldState";
import {connect} from "react-redux";

class CustomSimpleField extends React.PureComponent {
  state = {
    validationState: null,
    errorLabel: undefined,
  }

  handleChange = val => {
    const { handleFieldStatusChange, validate, onChange } = this.props;
    onChange(val);
    const errorLabel = validate ? validate(val) : undefined;
    const fieldStatus = errorLabel ? formFieldState.error : formFieldState.success;
    handleFieldStatusChange(fieldStatus);
  }

  handleOnBlur = () => {
    const { validate, value } = this.props;
    const errorLabel = validate ? validate(value) : undefined;
    let validationState = null;
    if(errorLabel) validationState = 'error';
    this.setState({ validationState, errorLabel });
  };

  render() {
    const { label,  value, component, readOnly } = this.props;
    const { errorLabel, validationState } = this.state;
    return (
      <FormGroup validationState={validationState} bsSize="small">
        <label className={'control-label'}>{label}</label>
        <FormControl
          value={value || undefined}
          componentClass={component}
          bsSize="small"
          onChange={({ target }) => this.handleChange(target.value)}
          onBlur={this.handleOnBlur}
          disabled={readOnly}
        />
        {errorLabel && (<span class={'help-block'}>{errorLabel}</span>)}
      </FormGroup>
    );
  }
}

const mapDispatchToProps = (_, props) => {
  const { name } = props;
  return dispatch => ({
    handleFieldStatusChange: status => dispatch(errorsFormActions.handleFieldStatusUpdate({ name, status })),
  });
};


export default connect(null, mapDispatchToProps)(CustomSimpleField);
