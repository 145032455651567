import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';

import processApi from '../../api/processApi';
import * as orderItemProductTypes from './orderItemProductTypes';
// import * as orderProductActions from './orderProductActions';
import { orderItemActions } from '../orderItem';
import { taskActions } from '../task';
// import { detailedOrderProductListSchema } from './orderProductSchemas';
import { modalActions, modalTypes, modalSelectors } from '../../redux/modal';


export default function* root() {
  yield all([
    takeLatest(orderItemProductTypes.SUBMIT.REQUEST, SubmitOrderItemProductListSaga),
  ]);
}

function* SubmitOrderItemProductListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { orderItemId, orderId } = payload;
//  yield callApiSaga(processActions.addProcess, processApi.addProcess, orderItemId);
  yield call(processApi.addMultipleProcesses, payload);
  //yield callApiSaga(orderItemActions.SUBMIT, processApi.addMultipleProcesses, payload);
  // yield callApiSaga(processActions.addProcess, processApi.addProcess, orderItemId);
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
  yield put(modalActions.hideModal());

  // try {
  //   yield put(applicationActions.setLoading(type));
  //   const ordersProducts = yield call(processApi.addMultipleProcesses, payload);
  //   const normalizedData = normalize(ordersProducts, detailedOrderProductListSchema);
  //   yield put(orderProductActions.listOrdersProducts.success(normalizedData, payload));
  // } catch (error) {
  //   if (error.Message) {
  //     const message = error.Message;
  //     yield put(applicationActions.setGeneralError(message));
  //     yield put(orderProductActions.listOrdersProducts.failure(error));
  //   }
  // }
  // yield put(applicationActions.unsetLoading(type));

}
