import * as R from 'ramda';
import { createSelector } from 'reselect';
import { isNullOrUndef } from '../../../utils/utils';
import { processTypeSelectors } from '../../processType';

export const getInitialValues = createSelector(
  [processTypeSelectors.getById, processTypeSelectors.getProcessTypeRefListById],
  (processTypeById, processTypeRefById) =>
    createSelector(R.identity, processTypeId => {
      const processType = processTypeById[processTypeId];
      const isInEditMode = !isNullOrUndef(processType);
      if (!isInEditMode) {
        return { HasTask: true, PricePerShade: false };
      }
      processType.ProcessTypeRef = processTypeRefById[processType.Code];
      return processType;
    }),
);
