import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import formNames from '../../constants/formNames';
import orderItemApi from '../../api/orderItemApi';
import * as orderItemTypes from './orderItemTypes';
import * as orderItemActions from './orderItemActions';
import { orderItemListSchema, orderPlannerDashboardListSchema } from './orderItemSchemas';
import { modalTypes, modalActions, modalSelectors } from '../modal';
import { createSaveForm } from '../form/utils';
import { taskActions } from '../task';

export default function* root() {
  yield all([
    takeLatest(orderItemTypes.ORDER_ITEM_LIST.REQUEST, getOrderItemListSaga),
    takeLatest(orderItemTypes.SAVE_ORDER_ITEM.REQUEST, saveOrderItemFormSaga),
    takeLatest(orderItemTypes.DELETE_ORDER_ITEM.REQUEST, deleteOrderItemSaga),
    takeLatest(orderItemTypes.ORDER_PLANNER_LIST.REQUEST, getOrderPlannerListSaga),
  ]);
}

const saveOrderItemFormSaga = createSaveForm(formNames.orderItem, saveOrderItemSaga);

function* deleteOrderItemSaga({ payload, type }) {
  const { orderItemId, orderId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(orderItemActions.deleteOrderItem, orderItemApi.deleteOrderItem, orderItemId);
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}

function* getOrderItemListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const ordersItems = yield call(orderItemApi.getOrderItems, payload);
    const normalizedData = normalize(ordersItems, orderItemListSchema);
    yield put(orderItemActions.listOrderItems.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(orderItemActions.listOrderItems.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

export function* saveOrderItemSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { orderId } = payload;
  yield callApiSaga(orderItemActions.saveOrderItem, orderItemApi.saveOrderItem, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.ORDER_ITEM_FORM) {
    yield put(modalActions.hideModal());
  }
  yield put(orderItemActions.listOrderItems.request({ orderId, IncludeDetails: true }));
  yield put(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  yield put(applicationActions.unsetLoading(type));
}

function* getOrderPlannerListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const orders = yield call(orderItemApi.getOrderPlannerDashboard, payload);
    const normalizedData = normalize(orders, orderPlannerDashboardListSchema);
    yield put(orderItemActions.orderPlannerList.success(normalizedData, payload));
    // yield put(orderItemActions.orderPlannerList.success(normalizedData, payload.producerId?payload.producerId:0));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(orderItemActions.orderPlannerList.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
