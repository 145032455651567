import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { taskTypeActions, taskTypeTypes, taskTypeSelectors } from '../../../redux/taskType';
import { processTypeActions } from '../../../redux/processType';
import { applicationSelectors } from '../../../redux/application';
import { Grid, Row, Col } from 'react-bootstrap';
import TaskTypeList from './TaskTypeList';

class TaskTypePageContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
      <Grid fluid>
        <Row>
          <Col md={12}>
            <TaskTypeList {...this.props} />;
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  taskTypeList: taskTypeSelectors.getTaskTypeList(state),
  isLoading: applicationSelectors.isLoading(
    state,
    taskTypeTypes.TASKTYPE_LIST.REQUEST,
    taskTypeTypes.SAVE_TASKTYPE.REQUEST,
  ),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(processTypeActions.listProcessTypes.request({ IncludeDuration: true }));
    dispatch(taskTypeActions.listTaskTypes.request({ OptIncludeProcessType: true }));
  },
  getTaskTypeList: () => dispatch(taskTypeActions.listTaskTypes.request({ OptIncludeProcessType: true })),
  onAddButtonClick: () => dispatch(taskTypeActions.addTaskType()),
  onEditButtonClick: taskTypeId => {
    dispatch(taskTypeActions.editTaskType(taskTypeId));
  },
  onDeleteButtonClick: taskTypeId => dispatch(taskTypeActions.deleteTaskType.request(taskTypeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskTypePageContainer);

TaskTypePageContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
