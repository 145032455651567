import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as orderItemTypes from './orderItemTypes';
import queryString from 'query-string';

const byId = handleActions(
  {
    [orderItemTypes.ORDER_ITEM_LIST.SUCCESS]: (state, { payload }) => {
      let res = merge({}, state);
      res = {
        ...res,
        ...payload.entities.ordersItems,
      };
      return res;
    },
  },
  {},
);

const allIds = handleActions(
  {
    [orderItemTypes.ORDER_ITEM_LIST.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
  },
  [],
);

const byQueryIds = handleActions(
  {
    [orderItemTypes.ORDER_ITEM_LIST.SUCCESS]: (state, { payload, meta = {} }) => {
      const queryStr = queryString.stringify(meta);
      return {
        ...state,
        [queryStr]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const plannerDashboardResultId = handleActions(
  {
    [orderItemTypes.ORDER_PLANNER_LIST.SUCCESS]: (state, { payload }) =>
      (Array.isArray(payload.result) ? payload.result : [payload.result]),
  },
  [],
);
const plannerDashboardResult = handleActions(
  {
    [orderItemTypes.ORDER_PLANNER_LIST.SUCCESS]: (state, { payload }) =>
      (payload.entities.ordersPlannerDashboard ? payload.entities.ordersPlannerDashboard : []),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  plannerDashboardResult,
  plannerDashboardResultId,
});
