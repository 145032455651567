import { normalize } from 'normalizr';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import companyOrderDocumentApi from '../../api/companyOrderDocumentApi';
// import documentApi from '../../api/documentApi';
import * as companyOrderDocumentTypes from './companyOrderDocumentTypes';
import * as companyOrderDocumentActions from './companyOrderDocumentActions';
import * as companyDocumentActions from '../companyDocument/companyDocumentActions';
import * as companyActions from '../company/companyActions';
// import * as companyOrderDocumentSchemas from './companyDocumentSchemas';
// import * as companyOrderDocumentSelectors from './companyDocumentSelectors';

export default function* root() {
  yield all([
    takeLatest(companyOrderDocumentTypes.UPLOAD_COMPANY_ORDER_DOCUMENT.REQUEST, uploadCompanyOrderDocumentSaga),
  ]);
}

function* uploadCompanyOrderDocumentSaga({ payload, type }) {
  console.log('uploadCompanyOrderDocumentSagas');
  yield put(applicationActions.setLoading(type));
  try {
    const { CompanyId } = payload;
    yield callApiSaga(companyOrderDocumentActions.uploadCompanyOrderDocument, companyOrderDocumentApi.uploadCompanyOrderDocument, payload);
    yield put(companyDocumentActions.listCompanyDocumentsForCompany.request(CompanyId));
  
    yield put(companyActions.listCompanyOrders.request(CompanyId))
  } catch (error) {
    yield put(applicationActions.showNotification('Error importing Excel sheet ' + error, 'error'));
  } finally {
    yield put(applicationActions.unsetLoading(type));
  } 

}
