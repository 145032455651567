import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Row, Col, Grid, Button, FormControl, FormGroup } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import VirtualizedSelect from 'react-virtualized-select';
import { RawMatListDropdown } from '../../_Common/Dropdowns';
import ReactTooltip from 'react-tooltip';

class CertComposition extends Component {
  static propTypes = {
    certComposition: PropTypes.object.isRequired, // eslint-disable-line
    rawMatList: PropTypes.array, // eslint-disable-line
    initialValues: PropTypes.shape({
      RawMatId: PropTypes.number,
      Percentage: PropTypes.number.isRequired,
    }).isRequired,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    onUploadFile: PropTypes.func.isRequired,
    onSelectRMButtonClick: PropTypes.func,
    onCreateRMButtonClick: PropTypes.func
  };

  static defaultProps = {
    initialValues: {},
    rawMatList: [],
  };

  constructor(props) {
    super(props);
    this.state = props.initialValues;
    this.onSelectedRawMatChanged = this.onSelectedRawMatChanged.bind(this);
    this.onPercentageChanged = this.onPercentageChanged.bind(this);
    this.isDirty = this.isDirty.bind(this);
    this.isFullyCompleted = this.isFullyCompleted.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onUploadFile = this.onUploadFile.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // if (this.props.initialValues !== nextProps.initialValues) {
    this.setState({
      rawMatId: nextProps.initialValues.rawMatId,
      Percentage: nextProps.initialValues.Percentage,
    });
    // }
  }

  onSave() {
    this.props.onSave({
      productProportionId: _.get(this.props.certComposition.productProportion, 'ProductProportionId', null),
      productId: this.props.certComposition.productId,
      // CosmosProportionId: null, // this.props.certComposition.prodigumProductProportion.ProportionId,
      ImportedProductProportionId: this.props.certComposition.importedProductProportion.ImportedProductProportionId, // this.props.certComposition.prodigumProductProportion.ProportionId,
      RawMatId: this.state.rawMatId,
      Percentage: this.state.Percentage,
      CosmosPercentageOnLastMatch: null, // this.props.certComposition.prodigumProductProportion.BeforeTransfoPct,
      CosmosIngredientId: null // this.props.certComposition.prodigumProductProportion.ReferencedId,
    });
  }

  onUploadFile(data) {
    this.props.onUploadFile(this.props.certComposition.productId, data);
  }

  // shouldComponentUpdate(nextProps) {
  //   return !nextProps.isLoadingProductProportion;
  // }

  onSelectedRawMatChanged(value) {
    this.setState({
      rawMatId: value,
    });
  }

  onPercentageChanged(value) {
    this.setState({
      Percentage: value,
    });
  }

  isFullyCompleted() {
    return !(_.isNil(this.state.Percentage) || _.isNil(this.state.rawMatId));
  }
  isDirty() {
    return !_.isEqual(this.state, this.props.initialValues);
  }

  render() {
    const { rawMatList, initialValues, certComposition, onDelete, onSelectRMButtonClick, onCreateRMButtonClick } = this.props;

    const getRawMatName = () => {
      // const item = certComposition;
      const item = certComposition.importedProductProportion;
      return item ? `${item.RawMatName}` : '';
    };

    const getRawMatSupplierName = () => {
      // const item = certComposition;
      const item = certComposition.importedProductProportion;
      return item ? `${item.RawMatSupplier}` : '';
    };

    const getRawMatPercentage = () => {
      // const item = certComposition;
      const item = certComposition.importedProductProportion;
      return item ? `${item.Percentage}%` : '';
    };

    return (
      <>
      <Grid fluid>
        <Row>
          <span style={certComposition.hasPercentageChanged ? { fontWeight: 'bold' } : null}>
            <Col sm={2}><p>{getRawMatName()}</p></Col>
            <Col sm={2}>{getRawMatSupplierName()}</Col>
            <Col sm={1}>{getRawMatPercentage()}</Col>
            {/* <Row>
              <Col>
                <DropzoneForm onUploadFile={this.onUploadFile} />
              </Col>
            </Row> */}
          </span>
          <Col sm={4}>
            <RawMatListDropdown initialOptions={rawMatList}>
              {({ rawMatList, filterOptions }) => (
                <VirtualizedSelect
                  value={_.get(this.state, 'rawMatId', initialValues.rawMatId)}
                  labelKey="FullName"
                  valueKey="RawMatId"
                  options={rawMatList}
                  filterOptions={filterOptions}
                  onChange={value => this.onSelectedRawMatChanged(_.get(value, 'RawMatId', null))}
                />
              )}
            </RawMatListDropdown>
            {/* <VirtualizedSelect
              value={_.get(this.state, 'rawMatId', initialValues.rawMatId)}
              labelKey="FullName"
              valueKey="RawMatId"
              options={rawMatList}
              onChange={value => this.onSelectedRawMatChanged(_.get(value, 'RawMatId', null))}
            /> */}
          </Col>
          <Col sm={1}>
            <FormGroup bsSize="small">
              <FormControl
                type="number"
                value={_.get(this.state, 'Percentage', initialValues.Percentage)}
                onChange={e => this.onPercentageChanged(e.target.value)}
                disabled={certComposition.productProportion == null}
                min={0}
                max={100}
                step={0.1}
              />
            </FormGroup>
          </Col>
          <Col sm={2}>
            <Col sm={2}>
                <Button
                  bsSize="small"
                  bsStyle="success"
                  onClick={this.onSave}
                  disabled={
                    (certComposition.productProportion == null && !this.isFullyCompleted()) ||
                    (certComposition.productProportion != null && (!this.isDirty() || !this.isFullyCompleted()))
                  }
                  data-tip data-for="saveTip"
                  >
                  <i className="fa fa-save" />
                </Button>
                <ReactTooltip id="saveTip" place="bottom" type="dark" effect="solid">
                  <Translate value="general.save" />
                </ReactTooltip>
              </Col>
            {_.get(certComposition, 'prodigumProductProportion.CorrespondingRawMatId', null) == null ? (
              <>
              <Col sm={2}>
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={() => {
                    onSelectRMButtonClick(certComposition.importedProductProportion.ImportedProductProportionId,
                                          certComposition.importedProductProportion.RawMatName,
                                          certComposition.importedProductProportion.RawMatSupplier,
                                          certComposition.importedProductProportion.ImportedRawMatProportions)
                  }}
                  data-tip data-for="selectTip"          
                >
                  <i className="fa fa-search" />
                  {/* <Translate value="compositionTab.SelectRMShort" /> */}
                </Button>
                <ReactTooltip id="selectTip" place="bottom" type="dark" effect="solid">
                  <Translate value="compositionTab.SelectRMShort" />
                </ReactTooltip>
              </Col>
              <Col sm={2}>
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={() => onCreateRMButtonClick(certComposition.importedProductProportion.ImportedProductProportionId)}
                  data-tip data-for="createTip"          
                >
                  <i className="fas fa-plus" />
                </Button>
                <ReactTooltip id="createTip" place="bottom" type="dark" effect="solid">
                  <Translate value="compositionTab.CreateRMShort" />
                </ReactTooltip>
              </Col>
              </>
            ) : null}
            <Col sm={1}></Col>
            {certComposition.productProportion != null ? (
              <Col sm={2}>
                <Button
                  bsSize="small"
                  bsStyle="danger"
                  onClick={() => onDelete(certComposition.productProportion.ProductProportionId)}
                  data-tip data-for="deleteTip"
                >
                  <i className="fa fa-trash" />
                </Button>
                  <ReactTooltip id="deleteTip" place="bottom" type="dark" effect="solid">
                  <Translate value="general.delete" />
                </ReactTooltip>
              </Col>
              ) : null}
          </Col>
        </Row>
      </Grid>
      </>
    );
  }
}

export default CertComposition;
