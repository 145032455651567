import React from 'react';
import { Grid, Row, Col, Well } from 'react-bootstrap';
import { css } from 'glamor';
import LoginForm from './LoginReduxForm';

const styles = {
  container: css({
    paddingTop: '10vh',
    height: '100vh',
  }),
  title: css({
    textAlign: 'center',
  }),
};

const Login = ({ ...otherProps }) =>

(
  <div className={styles.container}>
    <Grid>
      <Row>
        <Col md={6} mdOffset={3}>
          <Well bsSize="small">
            <h1 className={styles.title}>CALMOS</h1>
            <div style={{ textAlign: "center" }}>v{otherProps.applicationVersion}</div>
            <hr />
            <LoginForm {...otherProps} />
          </Well>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default Login;
