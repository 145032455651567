import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { substanceProductActions, substanceProductTypes } from './index';
import { productSchemas } from '../product';
import substanceApi from '../../api/substanceApi';

export default function* root() {
  yield all([takeLatest(substanceProductTypes.FETCH_PRODUCTS_FOR_SUBSTANCE.REQUEST, fetchSubstanceProductSaga)]);
}

export function* fetchSubstanceProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { SubstanceId } = payload;
    const productList = yield call(substanceApi.getProductsForSubstance, SubstanceId);
    const normalizedData = normalize(productList, productSchemas.productListSchema);
    yield put(substanceProductActions.fetchProductsForSubstance.success(normalizedData, SubstanceId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceProductActions.fetchProductsForSubstance.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
