import { createSelector } from 'reselect';
import {getphraseList} from "../phrase/phraseSelectors";

export const getById = ({ phraseTR }) => phraseTR.byId;
export const getIds = ({ phraseTR }) => phraseTR.list.allIds;
export const getPhraseTRById = (state, id) => getById(state)[id];

export const getPhraseTRList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

// export const getPhraseTRByCode = (state, phraseCode) => getPhraseTRList(state).find(elem => elem.PhraseCode === phraseCode);
