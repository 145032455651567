import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { GeneralGrid } from '../../../_Common/Grids';
import { getTotalPercentage } from './utils';

const keyName = 'ProductProportionId';

const ShadeCompoList = props => {
  const { shadeCompoList, isLoading, rawMatList, productProportionList, onRefresh } = props;

  const getProductProportionById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('ProductProportionId'), R.identity]))(productProportionList),
  );

  const getRawMatById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('RawMatId'), R.identity]))(rawMatList),
  );
  const getValueFromColDef = R.path(['colDef', 'field']);
  const getProductProportionFromRow = R.compose(
    getProductProportionById,
    R.pathOr({}, ['data', 'ProductProportionId']),
  );

  const getRawMatValue = R.converge(R.propOr(''), [
    getValueFromColDef,
    R.compose(
      getRawMatById,
      R.prop('RawMatId'),
      getProductProportionFromRow,
    ),
  ]);

  const getProductProportionValue = R.converge(R.propOr(''), [getValueFromColDef, getProductProportionFromRow]);

  const isPinnedRow = R.compose(
    R.has('rowPinned'),
    R.prop('node'),
  );
  const not100 = R.complement(R.equals(100));
  const getRowsData = R.compose(
    R.pluck('data'),
    R.path(['node', 'rowModel', 'rowsToDisplay']),
  );

  const getCellStyle = R.ifElse(
    R.converge(R.and, [isPinnedRow, R.propSatisfies(not100, 'value')]),
    R.always({ color: 'red', fontWeight: 'bold' }),
    R.always({ color: 'black', fontWeight: 'normal' }),
  );

  const columns = [
    {
      headerName: I18n.t('shadeCompo.Order'),
      valueGetter: getProductProportionValue,
      field: 'Order',
      sort: 'asc',
    },
    {
      headerName: I18n.t('shadeCompo.RmCode'),
      valueGetter: getRawMatValue,
      field: 'Code',
    },
    {
      headerName: I18n.t('shadeCompo.RmName'),
      valueGetter: getRawMatValue,
      field: 'Name',
    },
    {
      headerName: I18n.t('shadeCompo.Percentage'),
      field: 'Percentage',
      editable: R.complement(isPinnedRow) && !props.buttonsDisabledByValidedComposition,
      valueGetter: R.ifElse(
        isPinnedRow,
        R.compose(
          getTotalPercentage,
          getRowsData,
        ),
        R.path(['data', 'Percentage']),
      ),
      cellStyle: getCellStyle,
      // cellRenderer: PercentageCellRenderer,
    },
  ];

  return (
    <GeneralGrid
      data={shadeCompoList}
      keyName={keyName}
      columns={columns}
      height="70vh"
      isLoading={isLoading}
      onRefresh={onRefresh}
      singleClickEdit
      stopEditingWhenGridLosesFocus
      {...props}
    />
  );
};

ShadeCompoList.propTypes = {
  shadeCompoList: PropTypes.array.isRequired, //eslint-disable-line
  rawMatList: PropTypes.array.isRequired, //eslint-disable-line
  productProportionList: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default ShadeCompoList;
