import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ActionHistoryModal from './ActionHistoryModal';
import { productActionSelectors, productActionTypes, productActionActions } from '../../../redux/productAction';
import { applicationSelectors } from '../../../redux/application';
import { actionSelectors } from '../../../redux/action';

const ProductActionsContainer = props => <ActionHistoryModal height="60vh" {...props} />;

const mapStateToProps = (state, { ProductId }) => ({
  productActionList: productActionSelectors.getProductActionForProduct(state, ProductId),
  isLoading: applicationSelectors.isLoading(state, productActionTypes.PRODUCT_ACTION_LIST.REQUEST, productActionTypes.SAVE_PRODUCT_ACTION.REQUEST),
  actionList: actionSelectors.getActionListForEntityNotInWorkflow('product')(state),
});

const mapDispatchToProps = (dispatch, { ProductId }) => ({
  getProductActionList: () => dispatch(productActionActions.listProductActions.request(ProductId)),
  onAdd: data => dispatch(productActionActions.saveProductAction.request(ProductId, data)),
});

ProductActionsContainer.propTypes = {
  ProductId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
