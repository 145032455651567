import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import { Modal, Button, Grid, Row, Col } from 'react-bootstrap';
import ProductActionList from './ProductActionList';
import ProductActionForm from "./ProductActionForm";

class ActionHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      action: undefined,
      selectedProductActionId: null,
    };
  }

  onGridReady = g => {
    this.grid = g;
  };

  onNewButtonClick = () => {
    if (this.grid) {
      this.grid.api.deselectAll();
      this.setState({
        selectedProductActionId: null,
      });
    }
  };

  handleProductActionSelected = productActionId =>
    this.setState({
      selectedProductActionId: productActionId,
    });

  render() {
    const { title, closeModal, actionList, onAdd, ProductId, ...otherProps } = this.props;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <ProductActionList
                  onProductActionSelected={this.handleProductActionSelected}
                  whenGridReady={this.onGridReady}
                  onAddButtonClick={this.onNewButtonClick}
                  {...otherProps} />
              </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
              <ProductActionForm actionList={actionList} ProductId={ProductId} SelectedProductActionId={this.state.selectedProductActionId}/>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
            <i className="fa fa-times" />
            <Translate value="general.close" />
          </Button>
        </Modal.Footer>

      </div>
    );
  }
}

ActionHistoryModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  actionList: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onProductActionSelected: PropTypes.func.isRequired,
  ProductId: PropTypes.number.isRequired,
};


export default ActionHistoryModal;
