import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import * as R from 'ramda';
import { modalActions, modalTypes, modalSelectors } from '../../modal';
import { rawMatProportionSagas, rawMatProportionActions } from '../../rawMatProportion';
import * as allergenModalTypes from './allergenModalTypes';
import * as allergenModalActions from './allergenModalActions';
import * as allergenModalSelectors from './allergenModalSelectors';

export default function* root() {
  yield all([
    takeLatest(allergenModalTypes.SAVE_BUTTON_CLICKED, onSaveButtonClickSaga),
    takeLatest(allergenModalTypes.SAVE_RAWMAT_PROPORTION_ALLERGENS.REQUEST, saveRawMatProportionAllergensForRawMatSaga),
  ]);
}

function* saveRawMatProportionAllergensForRawMatSaga(action) {
  const { rawMatId } = action.meta;
  yield call(rawMatProportionSagas.saveRawMatProportionsSaga, action);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.ALLERGEN_LIST) {
    yield put(modalActions.hideModal());
  }

  yield put(
    rawMatProportionActions.fetchRawMatProportions.request({
      rawMatId,
      include: {
        OptIncludeSubstance: true,
        OptIncludeFunctions: true,
      },
    }),
  );
}

function* onSaveButtonClickSaga({ payload, meta }) {
  const { rawMatId } = meta;
  const existingRawMatProportions = yield select(state =>
    allergenModalSelectors.makeGetRawMatProportionsWithAllergenSubstanceForRawMat(state)(rawMatId),
  );

  const data = payload.reduce(
    (acc, rmp) => {
      const isNewRmp = !Number.isInteger(rmp.RawMatProportionId);
      const hasPercentage = !!rmp.Percentage;
      const existingRawMatProportion = R.compose(R.find(R.propEq('RawMatProportionId', rmp.RawMatProportionId)))(
        existingRawMatProportions,
      );

      const isPercentageEdited = R.compose(
        R.apply(R.complement(R.equals)),
        R.map(value => value.toString()),
        R.map(R.propOr('', 'Percentage')),
      )([existingRawMatProportion, rmp]);

      const shouldBeDeleted = !hasPercentage && !isNewRmp;
      const shouldBeSaved = (hasPercentage && isNewRmp) || (hasPercentage && isPercentageEdited);

      return {
        ...acc,
        data: shouldBeSaved ? [...acc.data, { ...rmp, rawMatId, IsAllergen: true }] : acc.data,
        idsToDelete: shouldBeDeleted ? [...acc.idsToDelete, rmp.RawMatProportionId] : acc.idsToDelete,
      };
    },
    { data: [], idsToDelete: [] },
  );

  yield put(allergenModalActions.saveRawMatProportionAllergensForRawMat.request(rawMatId, data));
}
