import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import TaskTypePageContainer from './TaskTypes/_TaskTypePageContainer';
import ProcessTypePageContainer from './ProcessTypes/_ProcessTypePageContainer';

const style = {
  detailsContainer: css({
    '& .tab-pane': {
      paddingTop: '15px'
    },
  }),
};

class ProcessTemplateContainer extends Component {
  render() {
    return (
      <Grid fluid>
        <div className={style.detailsContainer}>
          <Row>
            <Col md={12}>
              <Tabs id="ProcessTemplateTabs" defaultActiveKey={1} >
                <Tab eventKey={1} title={I18n.t('processTemplate.processTypeTab')} >
                    <ProcessTypePageContainer /><br/>
                </Tab>
                <Tab eventKey={2} title={I18n.t('processTemplate.taskTypeTab')} >
                  <TaskTypePageContainer /><br/>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcessTemplateContainer);
