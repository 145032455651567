import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as substanceRawMatTypes from './substanceRawMatTypes';

const bySubstanceId = handleActions(
  {
    [substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE.SUCCESS]: (state, { payload, meta: { SubstanceId } }) => ({
      [SubstanceId]: payload.result,
    }),
  },
  {},
);

export default combineReducers({
  bySubstanceId,
});
