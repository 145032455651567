import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import OrdersPageContainer from './_OrdersPageContainer';
import BasicDetailContainer from '../_Common/BasicDetailContainer';

const Orders = ({ children, ...otherProps }) => (
  <Grid fluid>
    <Row>
      <Col md={3}>
        <OrdersPageContainer {...otherProps} />
      </Col>
      <Col md={9}>
        <BasicDetailContainer>
          {children}
        </BasicDetailContainer>
      </Col>
    </Row>
  </Grid>
);

Orders.propTypes = {
  children: PropTypes.node,
};

export default Orders;
