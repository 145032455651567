import { createSelector } from 'reselect';
import { substanceToxProfileSelectors } from '../../substanceToxProfile';

// const getInitialValues = createSelector(
//   [substanceToxProfileSelectors.getSubstanceToxProfilesForSubstance],
//   substanceToxProfile => substanceToxProfile || {},
// );

export const makeGetInitialValues = createSelector(
  substanceToxProfileSelectors.makeGetSubstanceToxProfilesForSubstance,
  initialValues => initialValues || {},
);
