import { put, all, select, takeLatest, call, takeEvery } from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as phraseFormActions from './phraseFormActions';
import { phraseSagas } from '../../../redux/phrase';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import formNames from "../../../constants/formNames";
import { change, formValueSelector } from 'redux-form';

const formName = formNames.phrase;
const getFormValue = formValueSelector(formName);

export default function* root() {
  yield all([
    takeLatest(phraseFormActions.submitPhraseForm.REQUEST, submitPhraseFormSaga),
    takeEvery(phraseFormActions.onChapterCheckboxClickSelected, onChapterCheckboxClick),
  ]);
}

const submitPhraseFormSaga = createSaveFormWithValidation(phraseFormActions.submitPhraseForm, savePhraseSaga);

export function* savePhraseSaga(action) {
  yield call(phraseSagas.savePhraseSaga, action);
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PHRASE_FORM) {
    yield put(modalActions.hideModal());
  }
}

function* onChapterCheckboxClick({ payload }) {
  const { chapterCode } = payload;
  let values = yield select(state => getFormValue(state, 'ChaptersCode'));
  if(values.includes(chapterCode)){
    values = values.filter(elem => elem !== chapterCode);
  } else {
    values.push(chapterCode);
  }
  yield put(change(formName, 'ChaptersCode', values));
}

