import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const CompanyNotificationsTab = props => {
  return (
    <div>
      <DropzoneForm {...props} />
    </div>
  );
};

CompanyNotificationsTab.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
};

export default CompanyNotificationsTab;
