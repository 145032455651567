import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import formNames from '../../../constants/formNames';
import StepComponent from '../../_Common/StepComponent';
import { productSelectors, productActions } from '../../../redux/product';
import { workflowStatusSelectors } from '../../..//redux/workflowStatus';
import { rawMatSelectors } from '../../../redux/rawMat';

class ProductCompositionStatus extends React.Component {
  onCheckBeforeChange = nextStatus => {
    if (nextStatus.Code === 'PRODUCT_COMPO_VALIDATED') {
      return this.props.rawMats.filter(rawMat => !rawMat.IsActive).length === 0;
    }
    return true;
  };

  render() {
    const { workflowStatusList, statusCode, disabled, onChange } = this.props;
    return (
      <StepComponent
        options={workflowStatusList}
        value={statusCode}
        onChange={onChange}
        disabled={disabled}
        onCheckBeforeChange={this.onCheckBeforeChange}
        changeStateErrorMessage={'rawMatCompositionTab.cannotChangeStateMessage'}
      />
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  return {
    statusCode: R.propOr('', 'CompositionStatus', product),
    workflowStatusList: workflowStatusSelectors.getProductCompoStatusList(state),
    disabled: productSelectors.isNew(state) || isDirty(formNames.product)(state),
    rawMats: rawMatSelectors.getRawMatListForProduct(productId)(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onChange: ({ Code }) => dispatch(productActions.changeProductCompositionStatus(Code)),
});

ProductCompositionStatus.propTypes = {
  workflowStatusList: PropTypes.array.isRequired, //eslint-disable-line
  statusCode: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCompositionStatus);
