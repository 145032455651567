import * as cosAnnexeSelectors from './cosAnnexeSelectors';
import * as cosAnnexeActions from './cosAnnexeActions';
import * as cosAnnexeTypes from './cosAnnexeTypes';
import * as cosAnnexeSchemas from './cosAnnexeSchemas';
import sagas, * as cosAnnexeSagas from './cosAnnexeSagas';
import reducer from './cosAnnexeReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { cosAnnexeSelectors, cosAnnexeActions, cosAnnexeTypes, cosAnnexeSchemas, cosAnnexeSagas };
export default reducer;
