import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import { I18n } from 'react-redux-i18n';

const FormulaReviewSteps = props => {
  const { activeStep } = props;
  const steps = [
    {
      key: 'LegislationReview',
      title: I18n.t('formulaReviewWizard.LegislationReview'),
    },
    // {
    //   key: 'RmInfo',
    //   title: I18n.t('formulaReviewWizard.RmInfo'),
    // },
    {
      key: 'CompoComment',
      title: I18n.t('formulaReviewWizard.CompoComment'),
    },
    // {
    //   key: 'ProductInfo',
    //   title: I18n.t('formulaReviewWizard.ProductInfo'),
    // },
    // {
    //   key: 'ProductInfo2',
    //   title: I18n.t('formulaReviewWizard.ProductInfo2'),
    // },
    {
      key: 'PartAQuestion',
      title: I18n.t('formulaReviewWizard.PartAQuestion'),
    },
  ];

  return (
    <Steps current={activeStep}>
      {steps.map(step => <Step {...step} />)}
    </Steps>
  );
};

FormulaReviewSteps.defaultProps = {
  activeStep: 1,
};

FormulaReviewSteps.propTypes = {
  activeStep: PropTypes.number,
};

export default FormulaReviewSteps;
