import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Row, Col } from 'react-bootstrap';

import { ColumnFlexBox, FitToContentBox } from '../FlexBox';

const DropzoneForm = props => {
  const { onUploadFile } = props;
  const handleUpload = data => {
    onUploadFile({ data });
  };
  return (
    <ColumnFlexBox>
      <FitToContentBox>
        <Row>
          <Col sm={12}>
            <Dropzone multiple={false} onDrop={handleUpload} >
              <p>Drop a file or click to select a file to upload.</p>
            </Dropzone>
          </Col>
        </Row>
      </FitToContentBox>
    </ColumnFlexBox>
  );
};

DropzoneForm.propTypes = {
  onUploadFile: PropTypes.func.isRequired,
};

export default DropzoneForm;
