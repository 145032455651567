import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getById = ({ mandate }) => mandate.byId;
export const getIds = ({ mandate }) => mandate.list.allIds;
export const getMandate = (state, id) => getById(state)[id];
export const getByDistributorIds = ({ mandate }) => mandate.list.byDistributorIds;
export const getMandateList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

const getIdsForDistributor = distributorId =>
  createSelector(getByDistributorIds, byDistributorIds => R.defaultTo([], byDistributorIds[distributorId]));

export const getMandatesForDistributor = distributorId =>
  createSelector([getIdsForDistributor(distributorId), getById], (IdsForDistributor, byId) =>
    IdsForDistributor.map(id => byId[id]),
  );

export const makeGetMandatesForDistributor = createSelector(getMandatesForDistributor, R.identity);
