import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { modalActions } from '../../../redux/modal';
import { reportActions, reportTypes, reportActionTypes } from '../../../redux/report';
import { draftLabelActions, draftLabelTypes, draftLabelSelectors } from '../../../redux/draftLabel';
import { applicationSelectors } from '../../../redux/application';
import DraftLabelTab from './DraftLabelTab';
import formNames from '../../../constants/formNames';

class DraftLabelTabContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }

  render() {
    return <DraftLabelTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const draftLabel = draftLabelSelectors.getDraftLabelForProduct(productId)(state);
  return {
    isLoading: applicationSelectors.isLoading(
      state,
      draftLabelTypes.FETCH_DRAFT_LABELS.REQUEST,
      reportActionTypes.FETCH_REPORT.REQUEST,
    ),
    printButtonDisabled: !draftLabel,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onPerformReviewButtonClick: () => {
    dispatch(destroy(formNames.draftLabel));
    dispatch(
      modalActions.showDraftLabelModal({
        productId,
      }),
    );
  },
  onPrintButtonClick: () => dispatch(reportActions.getReport.request(productId, reportTypes.draftLabel)),

  onLoad: () => {
    if (productId > 0) {
      dispatch(draftLabelActions.fetchDraftLabels.request({ productId }));
    }
  },
});

DraftLabelTabContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftLabelTabContainer);
