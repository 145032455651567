import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { isNullOrUndef } from '../../../../utils/utils';
import { labelSymbolSelectors } from '../../../labelSymbol';
import { labelSymbolCorrectiveActionSelectors } from '../../../labelSymbolCorrectiveAction';

export const getLabelReviewSymbols = ({ labelReviewWizard }) => labelReviewWizard.labelReviewSymbols;
export const getLabelReviewSymbolsById = state => getLabelReviewSymbols(state).byId;
export const getLabelReviewSymbolsDeletedIds = state => getLabelReviewSymbols(state).list.deletedIds;
export const getLabelReviewSymbolsIds = state => getLabelReviewSymbols(state).list.allIds;
// export const getLabelReviewSymbol = (state, id) => getLabelReviewSymbolsById(state)[id];

export const getLabelReviewSymbol = (state, id) => {
  const labelReviewSymbol = getLabelReviewSymbolsById(state)[id];
  // return labelReviewSymbol;
  const currentCorrectiveActions = get('CorrectiveActions', labelReviewSymbol);
  let correctiveActions = currentCorrectiveActions;
  // const symbol = labelSymbolSelectors.getLabelSymbol(state, labelReviewSymbol.SymbolCode);
  // if (!isNullOrUndef(symbol)) {
  //   if (!isNullOrUndef(currentCorrectiveActions)) {
  //     correctiveActions = currentCorrectiveActions;
  //   } else {
  //     const labelSymbolCorrectiveAction = labelSymbolCorrectiveActionSelectors.getLabelSymbolCorrectiveAction(
  //       state,
  //       symbol.CorrectiveActionCode,
  //     );
  //     correctiveActions = get('Value', labelSymbolCorrectiveAction);
  //   }
  // }
  return {
    ...labelReviewSymbol,
    CorrectiveActions: correctiveActions,
  };
};

export const getLabelReviewSymbolList = createSelector(
  [getLabelReviewSymbolsIds, getLabelReviewSymbolsById],
  (allIds, byId) => allIds.map(id => byId[id]),
);
