import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Grid, Button } from 'react-bootstrap';
import { css } from 'glamor';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import LabelReviewSymbolItem from './LabelReviewSymbolItem';
import {
  labelReviewSymbolWizardSelectors,
  labelReviewSymbolWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelReviewSymbolWizard';

const styles = {
  header: css({
    '& h4': {
      textAlign: 'center',
    },
  }),
  labelReviewSymbolItem: css({
    '& .row': {
      marginBottom: '15px',
    },
  }),
};

const LabelReviewSymbolHeader = () =>
  (<div className={styles.header}>
    <Col sm={5}>
      <h4>
        {I18n.t('labelReviewWizard.symbol')}
      </h4>
    </Col>
    <Col sm={1}>
      <h4>
        {I18n.t('labelReviewWizard.substantation')}
      </h4>
    </Col>
    <Col sm={6}>
      <Col sm={10}>
        <h4>
          {I18n.t('labelReviewWizard.correctiveAction')}
        </h4>
      </Col>
    </Col>
  </div>);

const LabelReviewSymbols = ({ labelReviewSymbolIds, handleAddButtonClick }) =>
  (<Grid fluid>
    <Col sm={12}>
      <Row>
        <LabelReviewSymbolHeader />
      </Row>
      {labelReviewSymbolIds.map(lrSymbolId =>
        (<div key={lrSymbolId} className={styles.labelReviewSymbolItem}>
          <Row>
            <LabelReviewSymbolItem labelReviewSymbolId={lrSymbolId} />
          </Row>
        </div>),
      )}
      <Col sm={1}>
        <Button onClick={handleAddButtonClick} bsSize="small">
          <i className="fa fa-plus" aria-hidden="true" />
        </Button>
      </Col>
    </Col>
  </Grid>);

LabelReviewSymbols.defaultProps = {
  labelReviewSymbolIds: [],
};

LabelReviewSymbols.propTypes = {
  labelReviewSymbolIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired),
  handleAddButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = () => state => ({
  labelReviewSymbolIds: labelReviewSymbolWizardSelectors.getLabelReviewSymbolsIds(state),
});

const mapDispatchToProps = dispatch => ({
  handleAddButtonClick: () => dispatch(labelReviewSymbolWizardActions.handleAddButtonClick()),
  resetCorrectiveActionClick: id => dispatch(labelReviewSymbolWizardActions.resetCorrectiveAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewSymbols);
