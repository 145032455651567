import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import { Modal, Grid, Row, Col, Pager } from 'react-bootstrap';
import LabelReviewSteps from './LabelReviewSteps';
import BaseLoader from '../../_Common/BaseLoader';
import LabelReviewStatus from './LabelReviewStatus';
import LabelReviewItems from './LabelReviewItems';
import LabelReviewInci from './LabelReviewInci';
import LabelReviewClaims from './LabelReviewClaims';
import LabelReviewSymbols from './LabelReviewSymbols';

const style = {
  pager: {
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  },
  modalBody: css({
    height: '70vh',
    overflowY: 'auto',
  }),
};

class LabelReviewWizardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
    };
  }

  createAdd = numberToAdd => number => number + numberToAdd;
  addOne = this.createAdd(1);
  removeOne = this.createAdd(-1);

  handlePageChange = functor => {
    this.setState(({ activePage }) => ({
      activePage: functor(activePage),
    }));
  };
  isLastPage = () => this.state.activePage === 4;
  isFirstPage = () => this.state.activePage === 0;
  handlePreviousPage = () => this.handlePageChange(this.removeOne);

  handleNextPage = () => {
    if (this.isLastPage()) {
      this.props.saveLabelReview();
    } else {
      this.handlePageChange(this.addOne);
    }
  };

  render() {
    const { title, closeModal, isLoading, ProductId, ...otherProps } = this.props;
    const { activePage } = this.state;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div className={style.modalBody}>
          <Modal.Body>
            <BaseLoader isLoading={isLoading}>
              <Grid fluid>
                <Row>
                  <Col sm={12}>
                    <LabelReviewSteps activeStep={activePage} />
                  </Col>
                </Row>
                <Col sm={12}>
                  <Row style={{ marginTop: '25px' }}>
                    {activePage === 0 && <LabelReviewStatus {...otherProps} />}
                    {activePage === 1 && <LabelReviewItems {...otherProps} />}
                    {activePage === 2 && <LabelReviewInci />}
                    {activePage === 3 && <LabelReviewClaims />}
                    {activePage === 4 && <LabelReviewSymbols />}
                  </Row>
                </Col>
              </Grid>
            </BaseLoader>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Pager style={style.pager}>
            {!this.isFirstPage() && (
              <Pager.Item previous disabled={isLoading} onClick={this.handlePreviousPage}>
                {`← ${I18n.t('general.previous')}`}
              </Pager.Item>
            )}
            <Pager.Item next disabled={isLoading} onClick={this.handleNextPage}>
              {this.isLastPage() ? I18n.t('general.save') : `${I18n.t('general.next')} →`}
            </Pager.Item>
          </Pager>
        </Modal.Footer>
      </div>
    );
  }
}

LabelReviewWizardModal.propTypes = {
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveLabelReview: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ProductId: PropTypes.number.isRequired,
};

export default LabelReviewWizardModal;
