import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { css } from 'glamor';
import {
  ConfirmModal,
  CategoryListModal,
  LabelReviewApprovalForm,
  LabelReviewValidationForm,
  AddProductToOrderModal,
  CosAnnexeFormModal,
  SubstanceInformationModal,
  RawMatInformationModal,
  SubstanceStatusCommentModal,
  SubstanceActionsModal,
  RawMatStatusCommentModal,
  RawMatActionsModal,
  SubstanceToxProfileModal,
  ShadeModal,
  ProductCategoryModal,
  ProductCategoryTRModal,
  ShadeCompoModal,
  ShadeXRmCompoModal,
  ProductProportionReOrderModal,
  ProductProportionSelectRMModal,
  ProductProportionCreateRMModal,
  ProductDuplicateOrReplaceModal,
  CpsrLoadTemplate,
  OrderStatusCommentModal,
  OrderActionsModal,
  DraftLabelModal,
  PrintDocumentsModal,
  MandateProductsModal,
  CpsrStatusCommentModal,
  SubstanceCosAnnexesModal,
  ThresholdModal,
  CopyRawMatModal,
  OrderItemModal,
  ProcessModal,
  TaskModal,
  WorkItemModal,
  TaskActionModal,
  OrderItemProductModal,
  ProductTRModal,
  SubstanceTRModal,
  CosAnnexeTRModal,
} from '../components/_Common/Modals';
import ChangeCompositionStatusContainer from '../components/Products/Composition/_ChangeCompositionStatusContainer';
import ProductActionsContainer from '../components/Products/ProductActions';
import AddRawMatProportionContainer from '../components/RawMats/RawMatComposition/_AddRawMatProportionContainer';
import ProductProportionFormContainer from '../components/Products/Composition/ProductProportionForm';
import NotificationFormContainer from './_Common/Modals/NotificationModal';
import ProcessTypeFormContainer from './ProcessTemplate/ProcessTypes/ProcessTypeForm';
import TaskTypeFormContainer from './ProcessTemplate/TaskTypes/TaskTypeForm';
import FormulaReviewWizardContainer from './Wizards/FormulaReviewWizard';
import LabelReviewWizard from './Wizards/LabelReviewWizard';
import SubstanceProportionFormContainer from './Substances/SubstanceComposition/SubstanceProportionForm';
import PhraseListContainer from './_Common/Modals/PhraseList';
import PhraseFormModal from './_Common/Modals/PhraseFormModal';
import CategoryFormModal from './_Common/Modals/CategoryFormModal';
import ShadeXRmCompoModalContainer from './_Common/Modals/ShadeXRmCompoModal';
import ShadeXInciCompoModal from './_Common/Modals/ShadeXInciCompoModal';
import EditInciListModal from './_Common/Modals/EditInciListModal';
import AllergenListModal from './_Common/Modals/AllergenListModal';
import ImpurityListModal from './_Common/Modals/ImpurityListModal';
import ProductInciImpurityListModal from './_Common/Modals/ProductInciImpurityListModal';
import GenerateAgreementModal from './_Common/Modals/GenerateAgreementModal';
import OrderMissingDocumentListModal from './_Common/Modals/OrderMissingDocumentListModal';
import ImpactAssessmentActionsContainer from './_Common/Modals/ImpactAssessmentActions';
import AddEditToxProfileItemContainer from './Substances/ToxProfile/_AddEditToxProfileItemContainer';

import { modalActions, modalSelectors, modalTypes } from '../redux/modal';

const MODAL_COMPONENTS = {
  CONFIRM: ConfirmModal,
  CATEGORY_LIST: CategoryListModal,
  [modalTypes.PRODUCT_PROPORTION]: ProductProportionFormContainer,
  [modalTypes.NOTIFICATION_FORM]: NotificationFormContainer,
  [modalTypes.TASKTYPE_FORM]: TaskTypeFormContainer,
  [modalTypes.RAWMAT_PROPORTION]: AddRawMatProportionContainer,
  [modalTypes.PRODUCT_COMPOSITION_STATUS]: ChangeCompositionStatusContainer,
  [modalTypes.PRODUCT_ACTIONS_HISTORY]: ProductActionsContainer,
  [modalTypes.IMPACT_ASSESSMENT_ACTIONS_HISTORY]: ImpactAssessmentActionsContainer,
  [modalTypes.PRODUCT_FORMULA_REVIEW]: FormulaReviewWizardContainer,
  [modalTypes.SUBSTANCE_PROPORTION]: SubstanceProportionFormContainer,
  [modalTypes.PHRASE_LIST]: PhraseListContainer,
  [modalTypes.LABEL_REVIEW_WIZARD]: LabelReviewWizard,
  [modalTypes.LABEL_REVIEW_APPROVAL]: LabelReviewApprovalForm,
  [modalTypes.LABEL_REVIEW_VALIDATION]: LabelReviewValidationForm,
  [modalTypes.ADD_PRODUCT_TO_ORDER_FORM]: AddProductToOrderModal,
  [modalTypes.COS_ANNEXE_FORM]: CosAnnexeFormModal,
  [modalTypes.SUBSTANCE_INFORMATION_FORM]: SubstanceInformationModal,
  [modalTypes.RAWMAT_INFORMATION_FORM]: RawMatInformationModal,
  [modalTypes.SUBSTANCE_STATUS_COMMENT]: SubstanceStatusCommentModal,
  [modalTypes.SUBSTANCE_ACTIONS_HISTORY]: SubstanceActionsModal,
  [modalTypes.RAWMAT_STATUS_COMMENT]: RawMatStatusCommentModal,
  [modalTypes.RAWMAT_ACTIONS_HISTORY]: RawMatActionsModal,
  [modalTypes.SUBSTANCE_TOX_PROFILE]: SubstanceToxProfileModal,
  [modalTypes.SHADE_FORM]: ShadeModal,
  [modalTypes.PRODUCT_CATEGORY_FORM]: ProductCategoryModal,
  [modalTypes.PRODUCT_CATEGORY_TRANSLATION_FORM]: ProductCategoryTRModal,
  [modalTypes.SHADE_COMPO]: ShadeCompoModal,
  [modalTypes.SHADE_X_RM_COMPO]: ShadeXRmCompoModal,
  [modalTypes.PRODUCT_PROPORTION_REORDER]: ProductProportionReOrderModal,
  [modalTypes.PRODUCT_PROPORTION_SELECTRM]: ProductProportionSelectRMModal,
  [modalTypes.PRODUCT_PROPORTION_CREATERM]: ProductProportionCreateRMModal,
  [modalTypes.PRODUCT_DUPLICATE_OR_REPLACE]: ProductDuplicateOrReplaceModal,
  [modalTypes.CPSR_LOAD_TEMPLATE]: CpsrLoadTemplate,
  [modalTypes.ORDER_STATUS_COMMENT]: OrderStatusCommentModal,
  [modalTypes.ORDER_ACTIONS_HISTORY]: OrderActionsModal,
  [modalTypes.DRAFT_LABEL]: DraftLabelModal,
  [modalTypes.PRINT_DOCUMENTS]: PrintDocumentsModal,
  [modalTypes.MANDATE_PRODUCTS]: MandateProductsModal,
  [modalTypes.CPSR_STATUS_COMMENT]: CpsrStatusCommentModal,
  [modalTypes.SHADE_X_RM_COMPO]: ShadeXRmCompoModalContainer,
  [modalTypes.SHADE_X_INCI_COMPO]: ShadeXInciCompoModal,
  [modalTypes.EDIT_INCI_LIST]: EditInciListModal,
  [modalTypes.PHRASE_FORM]: PhraseFormModal,
  [modalTypes.CATEGORY_FORM]: CategoryFormModal,
  [modalTypes.ALLERGEN_LIST]: AllergenListModal,
  [modalTypes.IMPURITY_LIST]: ImpurityListModal,
  [modalTypes.PRODUCT_INCI_IMPURITY_LIST]: ProductInciImpurityListModal,
  [modalTypes.SUBSTANCE_COS_ANNEXE_LIST]: SubstanceCosAnnexesModal,
  [modalTypes.IMPACT_ASSESSMENT_THRESHOLD]: ThresholdModal,
  [modalTypes.GENERATE_AGREEMENT]: GenerateAgreementModal,
  [modalTypes.TO_PROFILE_ITEM]: AddEditToxProfileItemContainer,
  [modalTypes.COPY_RAW_MAT_FORM]: CopyRawMatModal,
  [modalTypes.PROCESSTYPE_FORM]: ProcessTypeFormContainer,
  [modalTypes.TASKTYPE_FORM]: TaskTypeFormContainer,
  [modalTypes.ORDER_ITEM_FORM]: OrderItemModal,
  [modalTypes.PROCESS_FORM]: ProcessModal,
  [modalTypes.TASK_FORM]: TaskModal,
  [modalTypes.TASK_ACTIONS_HISTORY]: TaskActionModal,
  [modalTypes.WORK_ITEM_FORM]: WorkItemModal,
  [modalTypes.ORDER_ITEM_MULTIPLE_PRODUCT_SELECTION_FORM]: OrderItemProductModal,
  [modalTypes.ORDER_MISSING_DOCUMENT_LIST_FORM]: OrderMissingDocumentListModal,
  [modalTypes.PRODUCT_TR_FORM]: ProductTRModal,
  [modalTypes.SUBSTANCE_TR_FORM]: SubstanceTRModal,
  [modalTypes.COSANNEXE_TR_FORM]: CosAnnexeTRModal,
};
const ModalRoot = props => {
  const { modalType, modalComponent, modalProps, show, closeModal } = props;
  const { modalWidth, modalHeight, onClose, onHidden, bsSize, onHide, ...otherProps } = modalProps;
  let SpecificModal;
  if (modalComponent) {
    SpecificModal = modalComponent;
  } else if (modalType) {
    SpecificModal = MODAL_COMPONENTS[modalType];
  } else {
    return null;
  }

  const styles = {
    width: modalWidth && css({ '.modal-dialog': { width: `${modalWidth}` } }),
    height: modalHeight && css({ '.modal-dialog': { height: `${modalHeight}` } }),
  };

  const handleOnHide = onHide
    ? () => {
      onHide();
      closeModal();
    }
    : closeModal;

  return (
    <div>
      {SpecificModal && (
        <Modal
          show={show}
          onHide={handleOnHide}
          onExited={onHidden}
          onExit={onClose}
          backdrop
          keyboard
          bsSize={bsSize}
          dialogClassName={`${css(styles.height, styles.width)}`}
        >
          <SpecificModal {...otherProps} closeModal={closeModal} />
        </Modal>
      )}
    </div>
  );
};

ModalRoot.defaultProps = {
  modalType: '',
  bsSize: '',
  modalComponent: null,
  modalProps: {},
};

ModalRoot.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object, // eslint-disable-line
  modalComponent: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    modalType: modalSelectors.getModalType(state),
    modalComponent: modalSelectors.getModalComponent(state),
    modalProps: modalSelectors.getModalProps(state),
    show: modalSelectors.getShowModal(state),
  }),
  {
    closeModal: () => modalActions.hideModal(),
  },
)(ModalRoot);
