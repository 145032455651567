import React from 'react';
import moment from 'moment';
import { I18n, Translate } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { DateField, SelectField } from '../../../_Common/Forms';
import { userSelectors } from '../../../../redux/user';
import * as userRoles from '../../../../constants/userRoleTypes';
import userActionsCodes from '../../../../constants/userActionsCodes';

const SearchForm = props => {
  const { search, handleSubmit, fromChanged, toChanged, expertList, expertChanged, currentUserActionCodes } = props;

  const handleFinishedToOnChange = (e, val) => {
    toChanged(val);
  };

  const handleFinishedFromOnChange = (e, val) => {
    fromChanged(val);
  };

  const handleExpertOnChange = (newVal) => {expertChanged(newVal ? newVal.LoginName : null)};

  const setToday = (e, val) => {
    const date = moment();
    fromChanged(date.utc()
      .format());
    toChanged(date.utc()
      .format());
  };

  const setWeek = (e, val) => {
    const fromDay = moment(props.from);
    const fromDate = fromDay.startOf('week');
    fromChanged(fromDate.utc()
      .format());
    const toDay = moment(props.from);
    const toDate = toDay.endOf('week');
    toChanged(toDate.utc()
      .format());
  };

  const setMonth = (e, val) => {
    const fromDay = moment(props.from);
    const fromDate = fromDay.startOf('month');
    fromChanged(fromDate.utc()
      .format());
    const toDay = moment(props.from);
    const toDate = toDay.endOf('month');
    toChanged(toDate.utc()
      .format());
  };

  const setYear = (e, val) => {
    const fromDay = moment(props.from);
    const fromDate = fromDay.startOf('year');
    fromChanged(fromDate.utc()
      .format());
    const toDay = moment(props.from);
    const toDate = toDay.endOf('year');
    toChanged(toDate.utc()
      .format());
  };

  let display = 'none';
  if (currentUserActionCodes.find(currentUserActionCode =>
    currentUserActionCode === userActionsCodes.CalmosAdminAction
    || currentUserActionCode === userActionsCodes.CalmosSuperAdminAction) !== undefined) {
    display = 'block';
  }

  return (
    <Form onSubmit={handleSubmit(search)}>
      <Row>
        <Col sm={1}>
          <Field
            name="from"
            label={I18n.t('dailyRecords.fromDate')}
            component={DateField}
            labelColSize={4}
            onChange={handleFinishedFromOnChange}
          />
        </Col>
        <Col sm={1}>
          <Field
            name="to"
            label={I18n.t('dailyRecords.toDate')}
            component={DateField}
            labelColSize={4}
            onChange={handleFinishedToOnChange}
          />
        </Col>
        <Col sm={1} style={{ display }}>
          <Field
            name="expert"
            label={I18n.t('orderTask.expert')}
            component={SelectField}
            options={expertList}
            labelKey="LoginName"
            valueKey="LoginName"
            clearable
            onValueChange={handleExpertOnChange}
          />
        </Col>
        <Col sm={1}>
          <Button style={{ marginTop: '22px' }} bsStyle="success" bsSize="small" type={'submit'}>
            <Translate value="generalDashboard.search" />
            &nbsp;
            <i className="fa fa-search" aria-hidden="true" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Button style={{ marginTop: '22px' }} bsStyle="success" bsSize="small" onClick={setToday}>
            <Translate value="dates.today" />
          </Button>&nbsp;
          <Button style={{ marginTop: '22px' }} bsStyle="default" bsSize="small" onClick={setWeek}>
            <Translate value="dates.byWeek" />
          </Button>&nbsp;
          <Button style={{ marginTop: '22px' }} bsStyle="default" bsSize="small" onClick={setMonth}>
            <Translate value="dates.byMonth" />
          </Button>&nbsp;
          <Button style={{ marginTop: '22px' }} bsStyle="default" bsSize="small" onClick={setYear}>
            <Translate value="dates.byYear" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state, props) => ({
  initialValues: {
    from: props.from,
    to: props.to,
    expert: props.expert,
  },
  expertList: userSelectors.getUsersByRole(state, userRoles.default.expert),
});

export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  reduxForm({
    form: 'searchWorkItem',
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(SearchForm);
