import { normalize } from 'normalizr';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import productsDocumentApi from '../../api/productsDocumentsApi';
import documentApi from '../../api/documentApi';
import * as productDocumentTypes from './productDocumentTypes';
import * as productDocumentActions from './productDocumentActions';
import * as productDocumentSchemas from './productDocumentSchemas';
import * as productDocumentSelectors from './productDocumentSelectors';

export default function* root() {
  yield all([
    takeLatest(productDocumentTypes.SAVE_PRODUCT_DOCUMENT.REQUEST, saveProductDocumentSagas),
    takeLatest(productDocumentTypes.UPLOAD_PRODUCT_DOCUMENT.REQUEST, uploadProductDocumentSagas),
    takeLatest(productDocumentTypes.UPLOAD_PRODUCT_COMPOSITION.REQUEST, uploadProductCompositionSagas),
    takeLatest(productDocumentTypes.SEND_PRODUCT_DOCUMENT_TO_COSMOS.REQUEST, sendProductDocumentToCosmosSagas),
    takeLatest(productDocumentTypes.PRODUCT_DOCUMENT_LIST_FOR_PRODUCT.REQUEST, getProductDocumentListForProductSaga),
    takeLatest(productDocumentTypes.DELETE_PRODUCT_DOCUMENT.REQUEST, deleteProductDocumentSaga),
  ]);
}

function* deleteProductDocumentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProductDocumentId } = payload;
  yield callApiSaga(
    productDocumentActions.deleteProductDocument,
    productsDocumentApi.deleteProductDocument,
    ProductDocumentId,
  );
  const productDocument = yield select(state => productDocumentSelectors.getProductDocument(state, ProductDocumentId));
  if (productDocument && productDocument.ProductId) {
    const { ProductId } = productDocument;
    yield put(productDocumentActions.listProductDocumentsForProduct.request(ProductId));
  }

  yield put(applicationActions.unsetLoading(type));
}

function* saveProductDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProductId, ...data } = payload;
  yield callApiSaga(productDocumentActions.saveProductDocument, documentApi.saveDocument, data);
  yield put(productDocumentActions.listProductDocumentsForProduct.request(ProductId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadProductDocumentSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProductId } = payload;
  yield callApiSaga(productDocumentActions.saveProductDocument, productsDocumentApi.uploadProductDocument, payload);
  yield put(productDocumentActions.listProductDocumentsForProduct.request(ProductId));
  yield put(applicationActions.unsetLoading(type));
}

function* uploadProductCompositionSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProductId } = payload;
  yield callApiSaga(productDocumentActions.saveProductDocument, productsDocumentApi.uploadProductDocument, payload);
  yield put(productDocumentActions.listProductDocumentsForProduct.request(ProductId));
  yield put(applicationActions.unsetLoading(type));
}

function* sendProductDocumentToCosmosSagas({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { ProductDocumentId } = payload;
  yield callApiSaga(productDocumentActions.sendProductDocumentToCosmos, productsDocumentApi.sendProductDocumentToCosmos, payload);
  // yield put(productDocumentActions.listProductDocumentsForProduct.request(ProductId));
  yield put(applicationActions.unsetLoading(type));
}

function* getProductDocumentListForProductSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { ProductId } = payload;
    const productDocuments = yield call(productsDocumentApi.getProductDocumentList, {
      ProductId,
      OptIncludeDocument: true,
    });
    const normalizedData = normalize(productDocuments, productDocumentSchemas.productDocumentListWithDocumentSchema);
    yield put(productDocumentActions.listProductDocumentsForProduct.success(normalizedData, ProductId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productDocumentActions.listProductDocumentsForProduct.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
