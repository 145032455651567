import { schema } from 'normalizr';
import * as documentSchemas from '../document/documentSchemas';

export const companyDocumentSchema = new schema.Entity('companyDocuments', {}, { idAttribute: 'CompanyDocumentId' });
export const companyDocumentWithDocumentSchema = new schema.Entity(
  'companyDocuments',
  {
    Document: documentSchemas.documentSchema,
  },
  { idAttribute: 'CompanyDocumentId' },
);

export const companyDocumentListWithDocumentSchema = [companyDocumentWithDocumentSchema];
