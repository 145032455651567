import { schema } from 'normalizr';
import { labelReviewItemSchemas } from '../labelReviewItem';
import { labelReviewInciSchemas } from '../labelReviewInci';
import { labelReviewClaimSchemas } from '../labelReviewClaim';
import { labelReviewSymbolSchemas } from '../labelReviewSymbol';

export const labelReviewSchema = new schema.Entity(
  'labelReviews',
  {
    LabelReviewItems: labelReviewItemSchemas.labelReviewItemListSchema,
    LabelReviewInci: labelReviewInciSchemas.labelReviewInciSchema,
    LabelReviewClaims: labelReviewClaimSchemas.labelReviewClaimListSchema,
    LabelReviewSymbols: labelReviewSymbolSchemas.labelReviewSymbolWithDetailsListSchema,
  },
  { idAttribute: 'LabelReviewId' },
);

export const labelReviewListSchema = [labelReviewSchema];
