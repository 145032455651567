import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {I18n} from 'react-redux-i18n';
import {applicationActions} from './application';

export function* callApiWithLoadingSaga(loadingKey, requestAction, apiFn, ...args) {
  try {
    yield put(applicationActions.setLoading(loadingKey));
    yield callApiSaga(requestAction, apiFn, ...args);
  } catch (error) {
  }
  yield put(applicationActions.unsetLoading(loadingKey));
}

function* callSuccessActionAndNormalize(action, data, dataSchema) {
  const normalizedData = normalize(data, dataSchema);
  yield callSuccessAction(action, normalizedData);
}

function* callSuccessAction(action, data) {
  yield put(action.success(data));
}

export function* callApiSagaAndNormalize(requestAction, apiFn, dataSchema, ...args) {
  if (
    !Object.prototype.hasOwnProperty.call(requestAction, 'success') ||
    !Object.prototype.hasOwnProperty.call(requestAction, 'failure')
  ) {
    throw new Error('The callApi generic function must receive an object with success and failure methods');
  }
  try {
    const data = yield call(apiFn, ...args);
    yield callSuccessActionAndNormalize(requestAction, data, dataSchema);
    return data;
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(requestAction.failure(error));
    } else {
      throw error;
    }
  }
}

export const createFetchSaga = (fn, {
  onSuccess = () => {
  }, onFailure = () => {
  }
}) =>
  function* generatorFn(action) {
    const {payload, type} = action;
    try {
      yield put(applicationActions.setLoading(type));
      const result = yield fn(action);
      yield onSuccess(result, payload);
      return result;
    } catch (error) {
      const {Message, ErrorCode} = error;
      if (Message) {
        yield put(applicationActions.setGeneralError(Message));
      } else if (ErrorCode) {
        yield put(applicationActions.setGeneralError(I18n.t(`errorCodes.${ErrorCode}`)));
      }
      yield onFailure(error, payload);
      return error;
    } finally {
      yield put(applicationActions.unsetLoading(type));
    }
  };

export function* callApiSaga(requestAction, apiFn, ...args) {
  if (
    !Object.prototype.hasOwnProperty.call(requestAction, 'success') ||
    !Object.prototype.hasOwnProperty.call(requestAction, 'failure')
  ) {
    throw new Error('The callApi generic function must receive an object with success and failure methods');
  }
  try {
    const data = yield call(apiFn, ...args);
    // yield put(requestAction.success(data));
    yield callSuccessAction(requestAction, data);
    return data;
  } catch (error) {
    const {Message, ErrorCode} = error;
    if (Message) {
      yield put(applicationActions.setGeneralError(Message));
      yield put(requestAction.failure(error));
    } else if (ErrorCode) {
      yield put(applicationActions.setGeneralError(I18n.t(`errorCodes.${ErrorCode}`)));
      yield put(requestAction.failure(error));
    } else {
      throw error;
    }
    // yield put(requestAction.failure(error));
    // const message = error.Message ? error.Message : error.toString();
    // yield put(applicationActions.setGeneralError(message));
    // yield put(applicationActions.showNotification(message, 'error'));
  }
}

export default {
  callApiSaga,
};
