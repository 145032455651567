import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { createSaveForm } from '../form/utils';
import { modalSelectors, modalActions, modalTypes } from '../modal';
import formNames from '../../constants/formNames';
import rawMatPhraseApi from '../../api/rawMatPhraseApi';
import * as rawMatPhraseTypes from './rawMatPhraseTypes';
import * as rawMatPhraseSchemas from './rawMatPhraseSchemas';
import * as rawMatPhraseActions from './rawMatPhraseActions';
import { phraseActions } from '../phrase';

export default function* root() {
  yield all([
    takeLatest(rawMatPhraseTypes.FETCH_ALL_RAWMAT_PHRASES.REQUEST, getAllRawMatPhrasesSaga),
    takeLatest(rawMatPhraseTypes.FETCH_RAWMAT_PHRASES.REQUEST, getRawMatPhrasesSaga),
    takeLatest(rawMatPhraseTypes.SAVE_RAWMAT_PHRASE.REQUEST, saveRawMatPhraseFormSaga),
    takeLatest(rawMatPhraseTypes.DELETE_RAWMAT_PHRASE.REQUEST, deleteRawMatPhraseSaga),
  ]);
}

const saveRawMatPhraseFormSaga = createSaveForm(formNames.rawMatInfo, saveRawMatPhraseSaga);

function* deleteRawMatPhraseSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { id, query } = payload;
  yield callApiSaga(rawMatPhraseActions.deleteRawMatPhrase, rawMatPhraseApi.deleteRawMatPhrase, id);
  yield put(rawMatPhraseActions.fetchRawMatPhrases.request(query));
  yield put(applicationActions.unsetLoading(type));
}

export function* saveRawMatPhraseSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));

  const { rawMatId } = payload;
  yield callApiSaga(rawMatPhraseActions.saveRawMatPhrase, rawMatPhraseApi.saveRawMatPhrase, payload);

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.RAWMAT_INFORMATION_FORM) {
    yield put(modalActions.hideModal());
  }

  yield put(rawMatPhraseActions.fetchRawMatPhrases.request({ rawMatId }));

  yield put(applicationActions.unsetLoading(type));
}

export function* getAllRawMatPhrasesSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const rawMatPhraseList = yield call(rawMatPhraseApi.getRawMatPhrases, payload);
    const normalizedData = normalize(rawMatPhraseList, rawMatPhraseSchemas.rawMatPhraseListSchema);
    yield put(rawMatPhraseActions.fetchAllRawMatPhrases.success(normalizedData, payload));
    yield put(phraseActions.listPhrases.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatPhraseActions.fetchAllRawMatPhrases.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

export function* getRawMatPhrasesSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const rawMatPhraseList = yield call(rawMatPhraseApi.getRawMatPhrases, payload);
    const normalizedData = normalize(rawMatPhraseList, rawMatPhraseSchemas.rawMatPhraseListSchema);
    yield put(rawMatPhraseActions.fetchRawMatPhrases.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatPhraseActions.fetchRawMatPhrases.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
