import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as labelReviewTypes from '../labelReview/labelReviewTypes';
import { referentialsTypes } from '../referentials';

const byCode = handleActions(
  {
    [labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.labelSymbols,
    }),
    [labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS]: (state, { payload }) =>
      (payload.entities && payload.entities.labelSymbols
        ? {
          ...state,
          ...payload.entities.labelSymbols,
        }
        : state),
    [referentialsTypes.ALL_REFERENTIALS.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.LabelSymbols.reduce(
        (acc, labelSymbol) => ({
          ...acc,
          [labelSymbol.Code]: labelSymbol,
        }),
        {},
      ),
    }),
  },
  {},
);

const allCodes = handleActions(
  {
    [combineActions(
      labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS,
      labelReviewTypes.FETCH_LABEL_REVIEWS.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.labelSymbols) {
        return [...new Set([...state, ...Object.keys(payload.entities.labelSymbols)])];
      }
      return state;
    },
    [referentialsTypes.ALL_REFERENTIALS.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.LabelSymbols.map(labelSymbol => labelSymbol.Code)]),
    ],
  },
  [],
);

const publicCodes = handleActions(
  {
    [referentialsTypes.ALL_REFERENTIALS.SUCCESS]: (state, { payload }) =>
      payload.LabelSymbols.map(labelSymbol => labelSymbol.Code),
  },
  [],
);

export default combineReducers({
  byCode,
  list: combineReducers({
    allCodes,
    publicCodes,
  }),
});
