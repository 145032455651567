import PropTypes from 'prop-types';
import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Grid, HelpBlock, Radio } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { I18n } from 'react-redux-i18n';

const LabelReviewApprovalForm = props => {
  const { approvedOn, isApproved, sentOn, approvalComment, dateFormat, updateFor, errors } = props;
  return (
    <Grid fluid>
      <Col sm={12}>
        <Form horizontal>
          <FormGroup controlId="approvalDate" validationState={errors.approvedOn.isError ? 'error' : null}>
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.approvalDate')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass={DatePicker}
                onChange={updateFor('approvedOn')}
                // onBlur={({ target }) => console.log(target)}
                selected={approvedOn}
                dateFormat={dateFormat}
              />
              {errors.approvedOn.isError && <HelpBlock>{errors.approvedOn.description}</HelpBlock>}
            </Col>
          </FormGroup>
          <FormGroup controlId="sendingDate" validationState={errors.sentOn.isError ? 'error' : null}>
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.sendingDate')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass={DatePicker}
                onChange={updateFor('sentOn')}
                // onBlur={({ target }) => updateFor('sentOn')(target.value)}
                selected={sentOn}
                dateFormat={dateFormat}
              />
              {errors.sentOn.isError && <HelpBlock>{errors.sentOn.description}</HelpBlock>}
            </Col>
          </FormGroup>
          <FormGroup controlId="approvalComment">
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.comment')}
            </Col>
            <Col sm={8}>
              <FormControl
                bsSize="small"
                componentClass="textarea"
                value={approvalComment}
                onChange={({ target }) => updateFor('approvalComment')(target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="approved">
            <Col componentClass={ControlLabel} sm={4}>
              {I18n.t('labelReview.approved')}
            </Col>
            <Col sm={8}>
              <Radio
                name="approvedRadioGroup"
                checked={!isApproved}
                onChange={() => updateFor('isApproved')(false)}
                inline
              >
                {I18n.t('general.no')}
              </Radio>
              <Radio
                name="approvedRadioGroup"
                checked={isApproved}
                onChange={() => updateFor('isApproved')(true)}
                inline
              >
                {I18n.t('general.yes')}
              </Radio>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Grid>
  );
};

LabelReviewApprovalForm.defaultProps = {
  dateFormat: 'DD/MM/YYYY',
  approvalComment: undefined,
  errors: {},
};

LabelReviewApprovalForm.propTypes = {
  dateFormat: PropTypes.string,
  errors: PropTypes.object.isRequired, //eslint-disable-line
  updateFor: PropTypes.func.isRequired,
  approvalComment: PropTypes.string,
  isApproved: PropTypes.bool.isRequired,
  sentOn: PropTypes.object, //eslint-disable-line
  approvedOn: PropTypes.object, //eslint-disable-line
};

export default LabelReviewApprovalForm;
