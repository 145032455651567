import { createSelector } from 'reselect';

export const getLabelSymbols = ({ labelReviewWizard }) => labelReviewWizard.labelSymbols;
export const getLabelSymbolsByCode = state => getLabelSymbols(state).byCode;
export const getLabelSymbolsDeletedCodes = state => getLabelSymbols(state).list.deletedCodes;
export const getLabelSymbolsCodes = state => getLabelSymbols(state).list.allCodes;
export const getLabelSymbol = (state, code) => getLabelSymbolsByCode(state)[code];

export const getLabelSymbolList = createSelector([getLabelSymbolsCodes, getLabelSymbolsByCode], (allCodes, byCode) =>
  allCodes.map(code => byCode[code]),
);

export const getPrivateLabelSymbolList = state => getLabelSymbolList(state).filter(labelSymbol => !labelSymbol.Public);
export const getPublicLabelSymbolList = state =>
  getLabelSymbolList(state).filter(labelSymbol => labelSymbol.Public === true);
