import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as R from 'ramda';
import queryString from 'query-string';
import { merge } from 'lodash';
import { removeById } from '../utils';
import * as shadeTypes from './shadeTypes';

const byId = handleActions(
  {
    [combineActions(shadeTypes.FETCH_SHADES.SUCCESS, shadeTypes.UPDATE_INCI.SUCCESS)]: (
      state,
      { payload, meta = {} },
    ) => {
      if (!meta.query) return payload.entities.shades;
      return merge({}, state, payload.entities.shades);
    },
    [shadeTypes.DELETE_SHADE.SUCCESS]: removeById,
  },
  {},
);

const allIds = handleActions(
  {
    [combineActions(shadeTypes.FETCH_SHADES.SUCCESS, shadeTypes.UPDATE_INCI.SUCCESS)]: (
      state,
      { payload, meta = {} },
    ) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
    [shadeTypes.DELETE_SHADE.SUCCESS]: (state, { payload }) => R.without([payload])(state),
  },
  [],
);

const loading = handleActions(
  {
    [combineActions(
      shadeTypes.FETCH_SHADES.REQUEST,
      shadeTypes.FETCH_SHADES.SUCCESS,
      shadeTypes.FETCH_SHADES.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === shadeTypes.FETCH_SHADES.REQUEST ? payload : meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: type === shadeTypes.FETCH_SHADES.REQUEST,
      };
    },
  },
  {},
);

const byQueryIds = handleActions(
  {
    [shadeTypes.FETCH_SHADES.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
