import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { productActions, productTypes, productSelectors } from '../../../redux/product';
import { impactAssessmentActions, impactAssessmentSelectors } from '../../../redux/impactAssessment';
import { impactAssessmentProductActions } from '../../../redux/impactAssessmentProduct';
import { applicationSelectors } from '../../../redux/application';
import ImpactAssessmentsTab from './ProductImpactAssessmentsTab';

class ProductImpactAssessmentsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ImpactAssessmentsTab {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => ({
  impactAssessmentList: impactAssessmentSelectors.getImpactAssessmentListByProduct(state, productId),
  currentProductImpactAssessments: productSelectors.getCurrentProductImpactAssessments(state),
  // availableImpactAssessmentsForCurrentProduct: productSelectors.getAvailableImpactAssessmentsForCurrentProduct(state),
  isLoading: applicationSelectors.isLoading(state, productTypes.PRODUCT_IMPACT_ASSESSMENT_LIST.REQUEST),
});

const mapDispatchToProps = (dispatch, { productId, impactAssessmentId }) => ({
  onLoad: () => {
    // dispatch(productActions.listProductImpactAssessments.request(productId));
    dispatch(impactAssessmentActions.listImpactAssessmentsByProduct.request(productId));
    // dispatch(productActions.listImpactAssessmentsOwnedByProductCompany.request(productId));
  },
  getProductImpactAssessments: () => dispatch(productActions.listProductImpactAssessments.request(productId)),
  // onDeleteClick: ImpactAssessmentId =>
  // dispatch(productActions.removeImpactAssessmentFromProduct(productId, ImpactAssessmentId)),
  onRowDoubleClicked: impactAssessment => {
    dispatch(push(`/ImpactAssessments/${impactAssessment.ImpactAssessmentId}`));
  },
  onConfirmImpactAssessmentProductClick: ({ ImpactAssessmentProductId }) =>
    dispatch(
      impactAssessmentProductActions.reviewImpactAssessmentProduct.request({
        ImpactAssessmentProductId,
        impactAssessmentId,
      }),
    ),
  onIgnoreImpactAssessmentProductClick: ({ ImpactAssessmentProductId }) =>
    dispatch(impactAssessmentProductActions.ignoreImpactAssessmentProduct.request(ImpactAssessmentProductId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductImpactAssessmentsContainer);
