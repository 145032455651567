import { createSelector } from 'reselect';
import { substanceSelectors } from '../substance';
import { createGeneralSelectors } from '../_common/selectors';

const getSubstanceCosAnnexeStateSlice = state => state.substanceCosAnnexe;
export const {
  getById,
  getIds,
  getLoading,
  makeGetByQuery,
  makeGetByQueryLoading,
} = createGeneralSelectors(getSubstanceCosAnnexeStateSlice);

export const getSubstanceCosAnnexeList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

export const makeGetSubstanceCosAnnexesForSubstance = createSelector(
  [getSubstanceCosAnnexeList, substanceSelectors.getById],
  (substanceCosAnnexeList, substanceById) =>
    createSelector(
      substanceId => substanceById[substanceId],
      substance => {
        if (!substance) return [];

        const casNr = substance.CasNr;
        const ecNr = substance.EcNr;
        const substanceId = substance.SubstanceId;
        const inciName = substance.InciName;

        return substanceCosAnnexeList.filter(
          sCosA =>
            sCosA.CasNr === casNr ||
            sCosA.EcNr === ecNr ||
            sCosA.SubstanceId === substanceId ||
            sCosA.Inci === inciName,
        );
      },
    ),
);
