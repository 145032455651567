import { takeLatest, takeEvery, put, all, select, take } from 'redux-saga/effects';
import { trim, get } from 'lodash/fp';
import uuidV1 from 'uuid/v1';
import { normalize } from 'normalizr';
import { isNullOrUndef } from '../../../../utils/utils';
import { labelReviewItemSelectors, labelReviewItemSchemas } from '../../../labelReviewItem';
import { referentialSelectors, referentialsTypes } from '../../../referentials';
import * as labelReviewItemWizardActions from './labelReviewItemWizardActions';
import * as labelReviewItemWizardTypes from './labelReviewItemWizardTypes';
import * as labelReviewItemWizardSelectors from './labelReviewItemWizardSelectors';
import { labelReviewWizardTypes, labelReviewWizardSelectors } from '../../labelReviewWizard';
import { labelReviewTypes } from '../../../labelReview';

export default function* root() {
  yield all([
    takeLatest(
      [labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS, labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS],
      newOrEditLabelReviewSaga,
    ),
    takeEvery(labelReviewItemWizardTypes.EDIT_COMMENT, editCommentSaga),
  ]);
}

function* editCommentSaga({ payload }) {
  const { LabelReviewItemId, Comment } = payload;
  const labelReview = yield select(state =>
    labelReviewItemWizardSelectors
      .getLabelReviewItemList(state)
      .find(labelReviewItem => labelReviewItem.LabelReviewItemId === LabelReviewItemId),
  );
  const defaultComment = yield select(state =>
    get(
      'Comment',
      referentialSelectors
        .getLabelReviewReferentialData(state)
        .find(refData => refData.Code === labelReview.ItemCode),
    ),
  );

  if (trim(defaultComment) === trim(Comment)) {
    yield put(labelReviewItemWizardActions.resetComment(LabelReviewItemId));
  } else {
    yield put(labelReviewItemWizardActions.updateLabelReviewItem(LabelReviewItemId, { Comment }));
  }
}

function* newOrEditLabelReviewSaga({ payload }) {
  const { LabelReviewId, ProductId } = payload;
  const editMode = !isNullOrUndef(LabelReviewId);
  const isLoading = yield select(labelReviewWizardSelectors.isLoadingWizard);
  if (isLoading) {
    yield take([referentialsTypes.ALL_REFERENTIALS.SUCCESS, labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]);
  }

  const currentLabelReviewItemList = editMode
    ? yield select(state => labelReviewItemSelectors.getLabelReviewItemListForLabelReview(state, LabelReviewId))
    : yield select(state => labelReviewItemSelectors.getLabelReviewItemListFromLatestLabelReview(state, ProductId));

  const labelReviewItemReferentialDataList = yield select(state =>
    referentialSelectors.getLabelReviewReferentialData(state),
  );
  const uuidLabelReviewId = uuidV1();
  const mergedLabelReviewItemList = labelReviewItemReferentialDataList.map(referentialData => {
    const labelReviewItem = currentLabelReviewItemList.find(lrItem => lrItem.ItemCode === referentialData.Code);

    let finalReviewItem = {
      ...(labelReviewItem || {
        LabelReviewItemId: uuidV1(),
        LabelReviewId,
        ItemCode: referentialData.Code,
      }),
    };

    if (!editMode) {
      finalReviewItem = {
        ...finalReviewItem,
        LabelReviewItemId: uuidV1(),
        LabelReviewId: uuidLabelReviewId,
      };
    }

    return finalReviewItem;
  });

  const result = normalize(mergedLabelReviewItemList, labelReviewItemSchemas.labelReviewItemListSchema);
  yield put(labelReviewItemWizardActions.loadLabelReviewItemData(result));
}
