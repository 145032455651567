import { createGeneralSelectors } from '../_common/selectors';
import { createSelector } from 'reselect';
import * as toxProfileItemSelector from '../toxProfileItem/toxProfileItemSelectors';
import * as R from 'ramda';
import { PrefixIndexStrategy } from 'js-search';
import createFilterOptions from 'react-select-fast-filter-options';

const getToxProfileChapterSlice = state => state.toxProfileChapter;
export const { getById, getIds, makeGetItemById: createGetProductById } = createGeneralSelectors(
  getToxProfileChapterSlice,
);

export const getToxProfileChapterList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getChapterHierarchy = createSelector(getToxProfileChapterList, chapters => makeHierarchy(chapters));

export const getChapterHierarchyForSubstance = substanceId =>
  createSelector(
    [getToxProfileChapterList, state => toxProfileItemSelector.makeGetByQuery(state)({ substanceId })],
    (chapters, items) => {
      const chaptersCopy = JSON.parse(JSON.stringify(chapters));
      items.map(item => {
        const chapterForItem = chaptersCopy.find(elem => elem.Code == item.ChapterCode);
        if (chapterForItem.Items != null) {
          chapterForItem.Items.push(item);
        } else {
          chapterForItem.Items = [item];
        }
      });
      return makeHierarchy(chaptersCopy);
    },
  );

const makeHierarchy = chapters => {
  let firtLevelChapters = chapters.filter(chap => chap.ParentChapterCode == null);
  firtLevelChapters = firtLevelChapters.map(chap => ({
    ...chap,
    Children: chapters.filter(all => all.ParentChapterCode == chap.Code).map(subChapt => ({
      ToxProfileChapterId: subChapt.ToxProfileChapterId,
      Code: subChapt.Code,
      SubTitle: subChapt.Title,
      Order: subChapt.Order,
      Id: subChapt.ToxProfileChapterId,
      Items: subChapt.Items,
    })),
    Id: chap.ToxProfileChapterId,
  }));
  return firtLevelChapters;
};

export const sortByOrder = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'Order'))]));

const chapterPrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetChapterListFilter = createSelector(R.identity, chapterList =>
  createFilterOptions({
    options: chapterList,
    labelKey: 'Title',
    valueKey: 'ToxProfileChapterId',
    indexStrategy: chapterPrefixIndexStrategy,
  }),
);
