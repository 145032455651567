import React from 'react';
import PropTypes from 'prop-types';
import SubstanceClpFormContainer from './_SubstanceClpFormContainer';

const SubstanceClpTab = ({ substanceId }) => <SubstanceClpFormContainer substanceId={substanceId} />;

SubstanceClpTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default SubstanceClpTab;
