import { createAction } from 'redux-actions';
import * as rawMatDataReviewWizardTypes from './rawMatDataReviewWizardTypes';
import { createUpdateAction } from '../../../../utils/actions';

export const loadRawMatReviewData = createAction(rawMatDataReviewWizardTypes.LOAD_RAWMAT_REVIEW_DATA);
export const resetComment = createAction(rawMatDataReviewWizardTypes.RESET_COMMENT, (RawMatDataReviewId, Comment) => ({
  RawMatDataReviewId,
  Comment,
}));
export const updateRawMatReviewData = createUpdateAction(rawMatDataReviewWizardTypes.UPDATE_RAWMAR_REVIEW_DATA);
