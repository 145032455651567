import * as homePageFormSelectors from './homePageFormSelectors';
import * as homePageFormActions from './homePageFormActions';
import * as homePageFormTypes from './homePageFormTypes';
import sagas from './homePageFormSagas';
import reducer from './homePageFormReducer';

import sagaManager from '../../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { homePageFormSelectors, homePageFormActions, homePageFormTypes };
export default reducer;
