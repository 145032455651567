import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import taskTypeApi from '../../api/taskTypeApi';
import * as taskTypeTypes from './taskTypeTypes';
import * as taskTypeActions from './taskTypeActions';
import * as taskTypeSchemas from './taskTypeSchemas';
import { modalActions, modalTypes } from '../modal';
import { processTypeActions } from '../processType';
import taskApi from '../../api/taskApi';

export default function* root() {
  yield all([
    takeLatest(taskTypeTypes.TASKTYPE_LIST.REQUEST, getTaskTypeListSaga),
    takeLatest(taskTypeTypes.SAVE_TASKTYPE.REQUEST, saveTaskTypeSaga),
    takeLatest(taskTypeTypes.DELETE_TASKTYPE.REQUEST, deleteTaskTypeSaga),
    takeLatest([taskTypeTypes.ADD_TASKTYPE, taskTypeTypes.EDIT_TASKTYPE], onAddEditTaskTypeSaga),
  ]);
}

function* onAddEditTaskTypeSaga({ payload }) {
  yield put(
    modalActions.showModal({ modalType: modalTypes.TASKTYPE_FORM, modalProps: { ...payload, modalWidth: '800px' } }),
  );
}

export function* getTaskTypeListSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(taskTypeTypes.TASKTYPE_LIST.REQUEST));
    const tasks = yield call(taskTypeApi.getTaskTypeList, payload);
    const normalizedData = normalize(tasks, taskTypeSchemas.taskTypeListSchema);
    yield put(taskTypeActions.listTaskTypes.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(taskTypeActions.listTaskTypes.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(taskTypeTypes.TASKTYPE_LIST.REQUEST));
}

export function* saveTaskTypeSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedTaskType = yield callApiSaga(taskTypeActions.saveTaskType, taskTypeApi.saveTaskType, payload);
  yield put(processTypeActions.listProcessTypes.request({ IncludeDuration: true }));
  yield put(taskTypeActions.listTaskTypes.request({ OptIncludeProcessType: true }));
  yield put(applicationActions.unsetLoading(type));
  return savedTaskType;
}

function* deleteTaskTypeSaga(action) {
  yield put(applicationActions.setLoading(taskTypeTypes.DELETE_TASKTYPE.REQUEST));
  yield callApiSaga(taskTypeActions.deleteTaskType, taskTypeApi.deleteTaskType, action.payload.taskTypeId);
  yield put(taskTypeActions.listTaskTypes.request({ OptIncludeProcessType: true }));
  yield put(applicationActions.unsetLoading(taskTypeTypes.DELETE_TASKTYPE.REQUEST));
}
