import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
import { productSelectors } from '../../../../redux/product';
import { notificationTypes } from '../../../../redux/notification';
import NotificationModal from './NotificationModal';

const formName = formNames.notification;
const isAnyTrue = R.any(R.equals(true));

const NotificationModalContainer = props => <NotificationModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isLoading: applicationSelectors.isLoading(state, notificationTypes.SAVE_NOTIFICATION.REQUEST),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('notificationModal.title', { productName }),
  };
};

const mapDispatchToProps = dispatch => {
  return ({
    onSaveButtonClick: () => {
      dispatch(submit(formNames.notification))
    },
  });
}

NotificationModalContainer.defaultProps = {
  notificationId: undefined,
};

NotificationModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  notificationId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModalContainer);
