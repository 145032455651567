import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import CompaniesList from './CompaniesList';
import BasicDetailContainer from '../_Common/BasicDetailContainer';

const Companies = ({ children, isLoadingDetails, ...otherProps }) => (
  <Grid fluid>
    <Row>
      <Col md={3}>
        <CompaniesList {...otherProps} />
      </Col>
      <Col md={9}>
        <BasicDetailContainer isLoading={isLoadingDetails}>
          {children}
        </BasicDetailContainer>
      </Col>
    </Row>
  </Grid>

);

Companies.propTypes = {
  children: PropTypes.node,
  isLoadingDetails: PropTypes.bool,
};

export default Companies;
