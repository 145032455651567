import * as phraseTRSelectors from './phraseTRSelectors';
import * as phraseTRActions from './phraseTRActions';
import * as phraseTRTypes from './phraseTRTypes';
import sagas from './phraseTRSagas';
import reducer from './phraseTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { phraseTRSelectors, phraseTRActions, phraseTRTypes };
export default reducer;
