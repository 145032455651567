import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import SubstanceCosAnnexesModal from './SubstanceCosAnnexesModal';

const SubstanceActionsModalContainer = props => <SubstanceCosAnnexesModal {...props} />;

const mapStateToProps = (state, { cosAnnexeCode }) => ({
  title: I18n.t('substanceCosAnnexesModal.title', { cosAnnexeCode }),
});

SubstanceActionsModalContainer.propTypes = {
  cosAnnexeCode: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(SubstanceActionsModalContainer);
