import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as inciSubstanceTypes from './inciSubstanceTypes';
import * as inciSubstanceActions from './inciSubstanceActions';
import { applicationActions } from '../application';
import inciSubstanceApi from '../../api/inciSubstanceApi';

export default function* root() {
  yield all([
    takeLatest(inciSubstanceTypes.SAVE_INCI_SUBSTANCE.REQUEST, saveInciSubstancesSaga),
  ]);
}

export function* saveInciSubstancesSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const updateTransaction = yield call(inciSubstanceApi.saveInciSubstance, payload);
    // const { Data, idsToDelete: deletedIds = [] } = updateTransaction;
    // const normalizedData = normalize(Data, rawMatProportionSchema.rawMatProportionListSchema);
    // yield put(
    //   inciSubstanceActions.saveInciSubstances.success(normalizedData, {
    //     deletedIds,
    //   }),
    // );
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(inciSubstanceActions.saveInciSubstances.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}
