import { createSelector } from 'reselect';
import * as R from 'ramda';

export const getLabelReviewClaims = ({ labelReviewWizard }) => labelReviewWizard.labelReviewClaims;
export const getLabelReviewClaimsById = state => getLabelReviewClaims(state).byId;
export const getLabelReviewClaimsDeletedIds = state => getLabelReviewClaims(state).list.deletedIds;
export const getLabelReviewClaimsIds = state => getLabelReviewClaims(state).list.allIds;
export const getErrorsById = state => getLabelReviewClaims(state).meta.errors.byId;

export const getLabelReviewClaim = (state, id) => getLabelReviewClaimsById(state)[id];

export const getLabelReviewClaimList = createSelector(
  [getLabelReviewClaimsIds, getLabelReviewClaimsById],
  (allIds, byId) => allIds.map(id => byId[id]),
);

export const isError = createSelector(
  getErrorsById,
  R.compose(R.any(R.propEq('isError', true)), R.flatten, R.map(R.values), R.values),
);
