import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import LabelReviewApprovalModal from './LabelReviewApprovalModal';
import { labelReviewSelectors, labelReviewTypes } from '../../../../redux/labelReview';
import { productSelectors } from '../../../../redux/product';
import { labelReviewApprovalFormActions } from '../../../../redux/modal/LabelReviewApprovalForm';
import { applicationSelectors } from '../../../../redux/application';
import { userSelectors } from '../../../../redux/user'

const LabelReviewApprovalFormContainer = props => <LabelReviewApprovalModal {...props} />;

const mapStateToProps = (state, { LabelReviewId, ProductId }) => {
  const currentUser = userSelectors.getCurrentUser(state);
  const labelReview = labelReviewSelectors.getLabelReview(state, LabelReviewId);
  const product = productSelectors.getProduct(state, ProductId);
  const title = `Label Review Approval (version ${get('RevisionNr', labelReview)}) For Product ${get(
    'ProductName',
    product,
  )}`;
  return {
    currentUser,
    title,
    isLoading: applicationSelectors.isLoading(state, labelReviewTypes.UPDATE_LABEL_REVIEW.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { LabelReviewId }) => ({
  onSave: data => {
    // console.log(data.currentuser.UserName)
    data.approvedBy = data.currentuser.UserName
    dispatch(labelReviewApprovalFormActions.onSaveButtonClick({ LabelReviewId, ...data }))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelReviewApprovalFormContainer);

LabelReviewApprovalFormContainer.propTypes = {
  LabelReviewId: PropTypes.number.isRequired,
  ProductId: PropTypes.number.isRequired,
};
