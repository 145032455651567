import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { applicationSelectors } from '../../../redux/application';
import { shadeSelectors, shadeTypes } from '../../../redux/shade';

const tooltip = (
  <Tooltip id="tooltip">
    <Translate value="shade.copyButtonTooltip" />
  </Tooltip>
);

const ShadeListCopyButton = ({ textToCopy, disabled }) => (
  <OverlayTrigger placement="top" overlay={tooltip}>
    <CopyToClipboard text={textToCopy}>
      <Button bsSize="small" disabled={disabled}>
        <Translate value="shade.NameList" />&nbsp;
      </Button>
    </CopyToClipboard>
  </OverlayTrigger>
);

const mapStateToProps = (state, { productId }) => {
  const shadeList = shadeSelectors.makeGetShadesForProduct(state)(productId);
  return {
    textToCopy: R.compose(R.join(' / '), R.pluck('ShadeName'))(shadeList),
    disabled: applicationSelectors.isLoading(state, shadeTypes.FETCH_SHADES.REQUEST),
  };
};

ShadeListCopyButton.defaultProps = {
  textToCopy: '',
};

ShadeListCopyButton.propTypes = {
  textToCopy: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ShadeListCopyButton);
