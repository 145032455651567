import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Button, Badge } from 'react-bootstrap';
import { css } from 'glamor';
import { Translate } from 'react-redux-i18n';

const DetailHeader = ({
  children,
  title,
  dirty,
  invalid,
  submitting,
  pristine,
  reset,
  showDelete,
  showDuplicate,
  showCancel,
  showSave,
  onDelete,
  onSave,
  onDuplicate,
  inDuplicateMode,
  duplicateMode,
}) => {
  const styles = {
    detailsContainer: css({
      height: '100%',
      '& .panel': {
        height: '100%',
      },
      '& .panel-body': {
        height: 'calc(100% - 42px)',
      },
    }),
    buttonContainer: css({
      marginTop: '-4px',
    }),
  };

  const titleComp = (
    <span>
      {title}
      <span>&nbsp;&nbsp;</span>
      {inDuplicateMode ? (
        <Badge style={{ backgroundColor: '#b94a48', color: 'white' }}>
          {duplicateMode === 'duplicate' ? (
            <Translate value="general.duplicateMode" />
          ) : (
            <Translate value="general.replaceMode" />
          )}
        </Badge>
      ) : null}
      <span className={`pull-right ${styles.buttonContainer}`}>
        {showDuplicate ? (
          <Button
            bsSize="small"
            bsStyle="default"
            type="button"
            onClick={onDuplicate}
            disabled={!pristine && !submitting}
          >
            <i className="fa fa-files-o" />
            &nbsp;
            <Translate value="general.duplicate" />
          </Button>
        ) : null}
        &nbsp;
        {showDelete ? (
          <Button bsSize="small" bsStyle="danger" type="button" onClick={onDelete} disabled={!pristine && !submitting}>
            <i className="fa fa-trash" />
            &nbsp;
            <Translate value="general.delete" />
          </Button>
        ) : null}
        &nbsp;
        {(showCancel === undefined || !showCancel)  ? (
        <Button bsSize="small" bsStyle="danger" type="button" onClick={reset} disabled={pristine || submitting}>
          <i className="fa fa-undo" />
          &nbsp;
          <Translate value="general.cancel" />
        </Button>
        ) : null}
        &nbsp;
        {(showSave === undefined || !showSave)  ? (
        <Button
          bsSize="small"
          bsStyle="success"
          type={onSave ? 'button' : 'submit'}
          onClick={onSave}
          disabled={pristine || submitting}
        >
          <i className="fa fa-floppy-o" />
          &nbsp;
          <Translate value="general.save" />
        </Button>
        ) : null}
        </span>
    </span>
  );

  return (
    <div className={css(styles.detailsContainer)}>
      <Panel header={titleComp} bsStyle="primary">
        {children}
      </Panel>
    </div>
  );
};

DetailHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  dirty: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  showDelete: PropTypes.bool,
  showDuplicate: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  inDuplicateMode: PropTypes.bool,
};

DetailHeader.defaultProps = {
  title: '',
  inDuplicateMode: false,
  dirty: false,
  invalid: false,
  showDelete: false,
  showDuplicate: false,
  submitting: false,
  pristine: true,
  onDelete: () => {},
  onDuplicate: () => {},
  reset: () => {},
  onSave: undefined,
};

export default DetailHeader;
