import {put, all, select, takeLatest, call} from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as cosAnnexeTRFormActions from './cosAnnexeTRFormActions';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';
import {referentialSelectors} from "../../referentials";
import {cosAnnexeTRSagas} from "../../cosAnnexeTR";

export default function* root() {
  yield all([
    takeLatest(cosAnnexeTRFormActions.submitCosAnnexeTRForm.REQUEST, submitCosAnnexeTRFormSaga),
  ]);
}

const submitCosAnnexeTRFormSaga = createSaveFormWithValidation(cosAnnexeTRFormActions.submitCosAnnexeTRForm, saveCosAnnexeTRSaga);

export function* saveCosAnnexeTRSaga({payload}) {
  const languages = yield select(state => referentialSelectors.getLanguageList(state).filter(lang => lang.Alpha2 !== 'en'));
  const finalValue = [];
  languages.forEach(lang => {
    finalValue.push(payload[lang.Alpha2]);
  })

  yield call(cosAnnexeTRSagas.saveSubstanceTRsSaga, {payload: finalValue});
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.COSANNEXE_TR_FORM) {
    yield put(modalActions.hideModal());
  }
}

