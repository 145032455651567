import { createSelector } from 'reselect';
import * as R from 'ramda';
import { productSelectors } from '../../product';

export const makeGetInitialValues = createSelector(productSelectors.getById, productById =>
  createSelector(
    productId => productById[productId],
    R.applySpec({
      UpperPart: R.propOr('', 'InciListUpperPart'),
      LowerPart: R.propOr('', 'InciListLowerPart'),
    }),
  ),
);
