import PropTypes from 'prop-types';

const PriceCellRenderer = (props) => {
  const { value, data, exchangeRate, currencies, currencyLabel, withLabel } = props;
  if (value == null) return null;
  const currencyEntity = currencies.find(cur => cur.CodeA === data[currencyLabel]);
  const rate = exchangeRate ? exchangeRate : (currencyEntity ? currencyEntity.ExchangeRate : 1);
  // const rate = (currencyEntity ? currencyEntity.ExchangeRate : 1);
  let finalValue = data[currencyLabel] ? Math.round(value * rate) : value; // pour éviter les 299.99 et 300.01...
  if (finalValue) finalValue = Math.round(finalValue * 100) / 100;  // pour avoir 2 décimales...

  return `${withLabel ? (currencyEntity ? currencyEntity.Key + ' ' : '') : ''}${finalValue}`;
};

PriceCellRenderer.defaultProps = {
  value: '',
  withLabel: false,
};

PriceCellRenderer.propTypes = {
  value: PropTypes.number,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string,
    }),
  ).isRequired,
  exchangeRate: PropTypes.number,
  data: PropTypes.shape({
    UnitPriceCurrency: PropTypes.string.isRequired,
  }).isRequired,
  withLabel: PropTypes.bool,
};

export default PriceCellRenderer;
