import { combineReducers } from 'redux';
import queryString from 'query-string';
import * as R from 'ramda';
import { handleActions, combineActions } from 'redux-actions';
import { removeById } from '../utils';
import * as shadeProportionTypes from './shadeProportionTypes';
import * as shadeTypes from '../shade/shadeTypes';

const byId = handleActions(
  {
    [shadeProportionTypes.FETCH_SHADE_PROPORTIONS.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.entities.shadeProportions;
      return { ...state, ...payload.entities.shadeProportions };
    },
    [shadeTypes.UPDATE_INCI.SUCCESS]: (state, { payload, meta = {} }) => ({
      ...state,
      ...payload.entities.shadeProportions,
    }),
    [shadeTypes.FETCH_SHADES.SUCCESS]: (state, { payload, meta = {} }) => {
      if (payload.entities && payload.entities.shadeProportions) {
        return {
          ...state,
          ...payload.entities.shadeProportions,
        };
      }

      return state;
    },
    [shadeProportionTypes.DELETE_SHADE_PROPORTION.SUCCESS]: removeById,
  },
  {},
);

const loading = handleActions(
  {
    [combineActions(
      shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST,
      shadeProportionTypes.FETCH_SHADE_PROPORTIONS.SUCCESS,
      shadeProportionTypes.FETCH_SHADE_PROPORTIONS.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST ? payload : meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: type === shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST,
      };
    },
  },
  {},
);

const byQueryIds = handleActions(
  {
    [shadeProportionTypes.FETCH_SHADE_PROPORTIONS.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const allIds = handleActions(
  {
    [shadeProportionTypes.FETCH_SHADE_PROPORTIONS.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
    [combineActions(shadeTypes.FETCH_SHADES.SUCCESS, shadeTypes.UPDATE_INCI.SUCCESS)]: (
      state,
      { payload, meta = {} },
    ) => {
      if (payload.entities.shadeProportions) {
        return [...new Set([...state, ...Object.keys(payload.entities.shadeProportions).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
