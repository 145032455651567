import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { applicationSelectors } from '../../../redux/application';
import SubstanceRawMatList from './SubstanceRawMatList';
import { substanceRawMatActions, substanceRawMatSelectors, substanceRawMatTypes } from '../../../redux/substanceRawMat';

class SubstanceRawMatListContainer extends Component {
  static propTypes = {
    substanceId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }
  render() {
    return <SubstanceRawMatList {...this.props} />;
  }
}
const mapStateToProps = (state, { substanceId }) => {
  const getRawMatsForSubstance = substanceRawMatSelectors.makeGetRawMatsForSubstance(substanceId);
  return {
    rawMatList: getRawMatsForSubstance(state),
    isLoadingRawMatList: applicationSelectors.isLoading(
      state,
      substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceRawMatActions.fetchRawMatsForSubstance.request(substanceId));
    }
  },
  fetchRawMatList: () => dispatch(substanceRawMatActions.fetchRawMatsForSubstance.request(substanceId)),
  onRowDoubleClicked: rawMat => dispatch(push(`/rawmats/${rawMat.RawMatId}`)),
});

SubstanceRawMatListContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceRawMatListContainer);
