import { takeEvery, put, all, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { evolve, compose, assoc } from 'ramda';
import { routingSelectors } from '../../routing';
import * as productFormTypes from './productFormTypes';

export default function* root() {
  yield all([takeEvery(productFormTypes.DUPLICATE_PRODUCT, duplicateProductSaga)]);
}

function* duplicateProductSaga({ payload }) {
  const { productId, mode, orderNr } = payload;
  const currentLocation = yield select(routingSelectors.getCurrentLocation);
  yield put(
    push(
      evolve({
        query: compose(
          assoc('duplicateFrom', productId),
          assoc('show_details', true),
          assoc('mode', mode),
          assoc('orderNr', orderNr),
        ),
        pathname: () => '/products/-1',
      })(currentLocation),
    ),
  );
}
