import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Tabs, Tab } from 'react-bootstrap';
import ToDoListContainer from './ToDoList/_ToDoListContainer';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import DailyRecords from './DailyRecords/DailyRecords';
import TaskList from './TaskList/TaskList';

const style = {
  detailsContainer: css({
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
};

class UserPlannerContainer extends Component {
  render() {
    return (
      <Grid fluid>
        <div className={style.detailsContainer.toString()}>
          <Row>
            <Col md={12}>
              <Tabs id="OrderTabs" defaultActiveKey={1}>
                <Tab eventKey={1} title={I18n.t('toDoList.toDoListTabTitle')}>
                  <div>
                    <ToDoListContainer />
                  </div>
                </Tab>
                {/* <Tab eventKey={2} title={I18n.t('dailyRecords.dailyRecordTabTitle')}>
                  <div>
                    <DailyRecords />
                  </div>
                </Tab> */}
                <Tab eventKey={3} title={I18n.t('taskList.taskListTabTitle')}>
                  <div>
                    <TaskList />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPlannerContainer);
