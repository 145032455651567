import React from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { editInciListFormSelectors, editInciListFormActions } from '../../../../redux/form/editInciListForm';
import { InputField } from '../../../_Common/Forms';

const EditInciListForm = props => (
  <Form>
    <Grid fluid>
      <Col sm={12}>
        <Row>
          <Field
            name="UpperPart"
            label={I18n.t('editInciListForm.inciListUpperPart')}
            component={InputField}
            componentClass="textarea"
            style={{ height: '200px', color: '#cd0200' }}
          />
        </Row>
      </Col>
      <Col sm={12}>
        <Row>
          <Field
            name="LowerPart"
            label={I18n.t('editInciListForm.inciListLowerPart')}
            component={InputField}
            componentClass="textarea"
            style={{ height: '200px', color: '#398439' }}
          />
        </Row>
      </Col>
    </Grid>
  </Form>
);

const mapStateToProps = (state, { productId }) => {
  const getFormInitialValues = editInciListFormSelectors.makeGetInitialValues(state);
  return {
    initialValues: getFormInitialValues(productId),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => dispatch(editInciListFormActions.saveForm.request({ ...data, productId })),
});

EditInciListForm.propTypes = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.editInciListForm,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(EditInciListForm);
