import { takeLatest, all, call } from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import { productSagas } from '../../product';
import * as productCpsrbCommentFormActions from './productCpsrbCommentFormActions';

export default function* root() {
  yield all([
    takeLatest(productCpsrbCommentFormActions.submitProductCpsrbCommentForm.REQUEST, submitproductCpsrbCommentFormSaga),
  ]);
}

const submitproductCpsrbCommentFormSaga = createSaveFormWithValidation(
  productCpsrbCommentFormActions.submitProductCpsrbCommentForm,
  saveProductCpsrbCommentFormSaga,
);

function* saveProductCpsrbCommentFormSaga({ type, payload, meta }) {
  yield call(productSagas.saveProductCpsrbCommentSaga, {
    type,
    payload,
    meta,
  });
}
