import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import productTRApi from '../../api/productTRApi';
import * as productTRTypes from './productTRTypes';
import * as productTRActions from './productTRActions';
import * as productTRSchemas from './productTRSchemas';

export default function* root() {
  yield all([
    takeLatest(productTRTypes.PRODUCT_TR_LIST.REQUEST, getProductListSaga),
    takeLatest(productTRTypes.SAVE_PRODUCT_TRs.REQUEST, saveProductTRsSaga),
  ]);
}

export function* getProductListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(productTRTypes.PRODUCT_TR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    productTRActions.listProductTR,
    productTRApi.getProductTRList,
    productTRSchemas.productTRListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(productTRTypes.PRODUCT_TR_LIST.REQUEST));
}

export function* saveProductTRsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    productTRActions.saveProductTRs,
    productTRApi.saveProductTR,
    productTRSchemas.productTRListSchema,
    payload,
  );
  yield put(productTRActions.listProductTR.request());
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}
