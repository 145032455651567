import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { css } from 'glamor';
import {Col, ControlLabel, FormGroup, Grid, HelpBlock, Radio, Row} from 'react-bootstrap';
import {SelectionGrid, PercentageCellRenderer, MeasureCellRenderer, LimitedMeasureCellRenderer } from '../../_Common/Grids';
import Select from "react-select";
import regionCode from "../../../constants/regionCode";

const styles = {
  radioButtons: css({
    display: 'flex',
    justifyContent: 'center',
  }),
};

class RadioButtonCellEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  onChangeListener = event => {
    const {
      onChange,
      node: { data },
    } = this.props;
    const newValue = event.target.value === 'true';
    this.setState(prevState => ({
      value: !prevState.value,
    }));

    if (onChange) {
      onChange({ ...data, IsOk: newValue });
    }
  };

  render() {
    const {
      node: { id },
    } = this.props;
    return (
      <div className={styles.radioButtons}>
        <Radio name={`${id}`} value="true" inline checked={this.state.value} onChange={this.onChangeListener}>
          Yes
        </Radio>
        <Radio name={`${id}`} value="false" inline checked={!this.state.value} onChange={this.onChangeListener}>
          No
        </Radio>
      </div>
    );
  }
}

RadioButtonCellEditor.defaultProps = {
  value: false,
  onChange: undefined,
};

RadioButtonCellEditor.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  node: PropTypes.object.isRequired, //eslint-disable-line
};

const LegislationComplianceReview = props => {
  const { complianceReviewList, updateComplianceReview, getCosAnneTypeByCode, regionList, countryList, formulaReview } = props;

  const getUsedInList = R.compose(
    R.map(
      cosAnnexe =>
        (cosAnnexe.UsedIn
          ? `- ${R.propOr('', 'Description', getCosAnneTypeByCode(cosAnnexe.UsedIn))}: ${cosAnnexe.MaxUse} %  ${
            cosAnnexe.CalculationCondition
          } ${R.propOr('', 'Description', getCosAnneTypeByCode(cosAnnexe.NotUsedIn))}`
          : ''),
    ),
    R.prop('CosAnnexes'),
  );

  const getOtherRestrictionList = R.compose(
    R.filter(R.complement(R.isEmpty)),
    R.map(R.prop('OtherRestrictions')),
    R.prop('CosAnnexes'),
  );

  const lessThan100 = R.complement(R.lte(100));
  const getCellStyle = R.ifElse(
    R.propSatisfies(lessThan100, 'value'),
    R.always({ color: 'red', fontWeight: 'bold' }),
    R.always({ color: 'black', fontWeight: 'normal' }),
  );

  const getRestrictions = R.converge(R.concat, [getUsedInList, getOtherRestrictionList]);

  const columns = [
    {
      headerName: I18n.t('complianceReview.InciName'),
      field: 'InciName',
    },
    {
      headerName: I18n.t('complianceReview.Percentage'),
      field: 'Percentage',
      cellRenderer: PercentageCellRenderer,
      sort: 'desc',
      sortedAt: 2,
    },
    {
      // headerName: `${I18n.t('substance.mosSystemic')}`,
      headerComponentParams: { template: '<span ref="eText" class="ag-header-cell-text" role="columnheader">'+`${I18n.t('substance.mosSystemic')}`+'</span>'},
      field: 'Mos',
      cellRenderer: LimitedMeasureCellRenderer,
      cellRendererParams: {
        nbDecimal: 0, max: 1000000
      },
      cellStyle: getCellStyle
    },
    {
      headerName: I18n.t('complianceReview.CosDirective'),
      // field: 'CosDirective',
      cellRenderer: ({ data }) => {
        const cosAnnexes = R.compose(
          R.reduce((acc, val) => `${acc}${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.pluck('CosRegulation'),
        )(data.CosAnnexes);
        if (!cosAnnexes) {
          return '';
        }
        return cosAnnexes;
      },
    },
    {
      headerName: I18n.t('complianceReview.Annexe'),
      cellRenderer: ({ data }) => {
        const cosAnnexes = R.compose(
          R.reduce((acc, val) => `${acc}${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          R.pluck('Code'),
        )(data.CosAnnexes);
        if (data.CosDirective && !cosAnnexes) {
          return '<i class="fa fa-exclamation-triangle" aria-hidden="true" style="color:#b94a48;"></i>';
        }
        return cosAnnexes;
      },
    },
    {
      headerName: I18n.t('complianceReview.Restriction'),
      cellRenderer: ({ data }) =>
        R.compose(
          result => `<p style="white-space: normal">${result}</p>`,
          R.reduce((acc, val) => `${acc} ${val}<br/>`, ''),
          R.filter(R.complement(R.anyPass([R.isEmpty, R.isNil]))),
          getRestrictions,
        )(data),
    },
    {
      headerName: I18n.t('substance.type'),
      sort: 'asc',
      sortedAt: 1,
      valueGetter: ({ data }) => I18n.t(`substanceTypes.${data.SubstanceType}`),
    },
    {
      headerName: I18n.t('complianceReview.IsOk'),
      field: 'IsOk',
      cellRendererFramework: RadioButtonCellEditor,
      cellRendererParams: {
        onChange: ({ IsOk, ComplianceReviewId }) => updateComplianceReview(ComplianceReviewId, { IsOk }),
      },
    },
    {
      headerName: I18n.t('complianceReview.Comment'),
      field: 'Comment',
      editable: true,
      onCellValueChanged: ({ data: { ComplianceReviewId, Comment } }) =>
        updateComplianceReview(ComplianceReviewId, { Comment }),
    },
  ];

  const finalComplianceReviewList = formulaReview.RegionCode ? complianceReviewList.map(cr => ({
    ...cr,
      CosAnnexes: cr.CosAnnexes.filter(elem => (
        ((formulaReview.RegionCode === null || formulaReview.RegionCode === regionCode.EU) && (elem.RegionCode === null || elem.RegionCode === regionCode.EU)) ||
        ((formulaReview.RegionCode !== null && formulaReview.RegionCode !== regionCode.ASEAN) && elem.RegionCode === formulaReview.RegionCode) ||
        (formulaReview.RegionCode === regionCode.ASEAN && elem.RegionCode === regionCode.ASEAN
          && ((formulaReview.CountryAlpha2 === null && elem.CountryAlpha2 === null)
            || (formulaReview.CountryAlpha2 !== null && (elem.CountryAlpha2 === formulaReview.CountryAlpha2 || elem.CountryAlpha2 === null))))
        ))
  })) : complianceReviewList;

  return (
    <Grid fluid>
      <Row>
        <Col sm={3}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.region')}</ControlLabel>
            <Select
              options={regionList}
              labelKey="Value"
              valueKey="Key"
              value={formulaReview !== undefined ? formulaReview.RegionCode : null}
              onChange={e => props.updateFormulaReview({ RegionCode: e ? e.Key : null, CountryAlpha2: null })}
              clearable
            />
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.country')}</ControlLabel>
            <Select
              options={formulaReview.RegionCode ? countryList.filter(elem => elem.RegionCode === formulaReview.RegionCode) : []}
              labelKey="Value"
              valueKey="Key"
              value={formulaReview !== undefined ? formulaReview.CountryAlpha2 : null}
              onChange={e => props.updateFormulaReview({ CountryAlpha2: e ? e.Key : null })}
              clearable="true"
              disabled={formulaReview.RegionCode !== regionCode.ASEAN}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <SelectionGrid
            key={`${formulaReview.FormulaReviewId}-${formulaReview.RegionCode}-${formulaReview.CountryAlpha2}`}
            data={finalComplianceReviewList}
            keyName="SubstanceId"
            columns={columns}
            // isLoading={isLoadingcomplianceReviewListTab}
            // onRefresh={getProductcomplianceReviewList}
            showAddButton={false}
            stopEditingWhenGridLosesFocus
            singleClickEdit
            height="55vh"
            rowHeight="50"
            getRowHeight={({ data }) =>
              25 *
              R.max(
                Math.floor(R.compose(R.reduce((acc, val) => `${acc} ${val}<br/>`, ''), getRestrictions)(data).length / 45) +
                  1,
                R.max(1, R.prop('length')(data.CosAnnexes)),
              )
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

LegislationComplianceReview.propTypes = {
  complianceReviewList: PropTypes.arrayOf(
    PropTypes.shape({
      InciName: PropTypes.string.isRequired,
      Percentage: PropTypes.number.isRequired,
      IsOk: PropTypes.bool,
      Comment: PropTypes.string,
    }),
  ).isRequired,
  updateComplianceReview: PropTypes.func.isRequired,
  getCosAnneTypeByCode: PropTypes.func.isRequired,
  formulaReview: PropTypes.shape({
    CompositionComment: PropTypes.string,
  }).isRequired,
};

export default LegislationComplianceReview;
