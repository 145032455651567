import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import queryString from 'query-string';
import * as R from 'ramda';
import * as cosAnnexeTypes from './cosAnnexeTypes';
import * as inciListTypes from '../inciList/inciListTypes';
import * as substanceCosAnnexeTypes from '../substanceCosAnnexe/substanceCosAnnexeTypes';

const byId = handleActions(
  {
    [combineActions(cosAnnexeTypes.FETCH_COSANNEXES.SUCCESS, cosAnnexeTypes.SAVE_COSANNEXE.SUCCESS)]: (
      state,
      { payload, meta = {} },
    ) =>
      (payload.entities.cosAnnexes
        ? {
          ...state,
          ...payload.entities.cosAnnexes,
        }
        : state),
    [combineActions(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS,
      inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => merge({}, state, payload.entities.cosAnnexes),
    [cosAnnexeTypes.DELETE_COSANNEXE.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [combineActions(cosAnnexeTypes.FETCH_COSANNEXES.SUCCESS, cosAnnexeTypes.SAVE_COSANNEXE.SUCCESS)]: (
      state,
      { payload },
    ) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
    [combineActions(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.SUCCESS,
      inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.cosAnnexes) {
        return [...new Set([...state, ...Object.keys(payload.entities.cosAnnexes).map(Number)])];
      }
      return state;
    },
    [cosAnnexeTypes.DELETE_COSANNEXE.SUCCESS]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

const byQueryIds = handleActions(
  {
    [cosAnnexeTypes.FETCH_COSANNEXES.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [combineActions(
      cosAnnexeTypes.FETCH_COSANNEXES.REQUEST,
      cosAnnexeTypes.FETCH_COSANNEXES.SUCCESS,
      cosAnnexeTypes.FETCH_COSANNEXES.FAILURE,
    )]: (state, { type, payload, meta = {} }) => {
      const { query = {} } = type === cosAnnexeTypes.FETCH_COSANNEXES.REQUEST ? payload : meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: type === cosAnnexeTypes.FETCH_COSANNEXES.REQUEST,
      };
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
