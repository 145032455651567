import { createSelector } from 'reselect';
import { rawMatProportionSelectors } from '../../../redux/rawMatProportion';

const defaultInitialValue = {
  Substance: {
    DirectiveStatusCode: 'NOT_LISTED',
  },
};

export const getInitialValues = createSelector(
  rawMatProportionSelectors.getRawMatProportionWithSubstance,
  getRawMatProportionWithSubstance =>
    createSelector(getRawMatProportionWithSubstance, rawMatProportion => rawMatProportion || defaultInitialValue),
);
