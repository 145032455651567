import { takeLatest, put, select, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import impactAssessmentApi from '../../api/impactAssessmentApi';
import * as impactAssessmentThresholdTypes from './impactAssessmentThresholdTypes';
import * as impactAssessmentThresholdActions from './impactAssessmentThresholdActions';
import * as impactAssessmentThresholdSchemas from './impactAssessmentThresholdSchemas';
import { modalSelectors, modalTypes, modalActions } from '../modal';

export default function* root() {
  yield all([
    takeLatest(LOCATION_CHANGE, onLocationChange),
    takeLatest(
      impactAssessmentThresholdTypes.IMPACT_ASSESSMENT_THRESHOLD_LIST.REQUEST,
      getImpactAssessmentThresholdListSaga,
    ),
    takeLatest(
      [
        impactAssessmentThresholdTypes.ADD_IMPACT_ASSESSMENT_THRESHOLD,
        impactAssessmentThresholdTypes.EDIT_IMPACT_ASSESSMENT_THRESHOLD,
      ],
      onAddEditImpactAssessmentThreshold,
    ),
    takeLatest(
      impactAssessmentThresholdTypes.SAVE_IMPACT_ASSESSMENT_THRESHOLD.REQUEST,
      saveImpactAssessmentThresholdSaga,
    ),
    takeLatest(
      impactAssessmentThresholdTypes.DELETE_IMPACT_ASSESSMENT_THRESHOLD.REQUEST,
      deleteImpactAssessmentThresholdSaga,
    ),
  ]);
}

function* onLocationChange(action) {
  const { pathname, action: routeAction } = action.payload;
  if (pathname === '/impactAssessments/-1' && routeAction === 'PUSH') {
    yield put(impactAssessmentThresholdActions.newImpactAssessmentThreshold());
  }
}

function* onAddEditImpactAssessmentThreshold({ payload }) {
  yield put(modalActions.showModal({ modalType: modalTypes.IMPACT_ASSESSMENT_THRESHOLD, modalProps: { ...payload } }));
}

function* getImpactAssessmentThresholdListSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentThresholdTypes.IMPACT_ASSESSMENT_THRESHOLD_LIST.REQUEST));
  const result = yield callApiSagaAndNormalize(
    impactAssessmentThresholdActions.listImpactAssessmentThresholds,
    impactAssessmentApi.getImpactAssessmentThresholdList,
    impactAssessmentThresholdSchemas.impactAssessmentThresholdListSchema,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(impactAssessmentThresholdTypes.IMPACT_ASSESSMENT_THRESHOLD_LIST.REQUEST));
}

function* saveImpactAssessmentThresholdSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));

  const { impactAssessmentId } = payload;
  yield callApiSaga(
    impactAssessmentThresholdActions.saveImpactAssessmentThreshold,
    impactAssessmentApi.saveImpactAssessmentThreshold,
    payload,
  );

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.IMPACT_ASSESSMENT_THRESHOLD) {
    yield put(modalActions.hideModal());
  }

  yield put(impactAssessmentThresholdActions.listImpactAssessmentThresholds.request(impactAssessmentId));

  yield put(applicationActions.unsetLoading(type));
}

function* deleteImpactAssessmentThresholdSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));

  const { impactAssessmentId } = payload;
  yield callApiSaga(
    impactAssessmentThresholdActions.deleteImpactAssessmentThreshold,
    impactAssessmentApi.deleteImpactAssessmentThreshold,
    payload,
  );

  yield put(impactAssessmentThresholdActions.listImpactAssessmentThresholds.request(impactAssessmentId));

  yield put(applicationActions.unsetLoading(type));
}
