import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as labelReviewItemWizardTypes from './labelReviewItemWizardTypes';

const labelReviewItemsById = handleActions(
  {
    [labelReviewItemWizardTypes.LOAD_LABEL_REVIEW_ITEM_DATA]: (state, { payload }) => ({
      ...payload.entities.labelReviewItems,
    }),
    [labelReviewItemWizardTypes.RESET_COMMENT]: (state, { payload }) => {
      const { LabelReviewItemId } = payload;
      return {
        ...state,
        [LabelReviewItemId]: {
          ...state[LabelReviewItemId],
          Comment: null,
        },
      };
    },
    [labelReviewItemWizardTypes.UPDATE_LABEL_REVIEW_ITEM_DATA]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
  },
  {},
);

const labelReviewItemsAllIds = handleActions(
  {
    [labelReviewItemWizardTypes.LOAD_LABEL_REVIEW_ITEM_DATA]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId: labelReviewItemsById,
  list: combineReducers({
    allIds: labelReviewItemsAllIds,
  }),
});
