import { combineReducers } from 'redux';
import {handleActions} from "redux-actions";
import {errorsFormTypes} from "./index";


const stateByFieldName = handleActions(
  {
    [errorsFormTypes.UPDATE_FIELD_STATUS]: (state, { payload }) => {
      const { name, status } = payload;
      return {
        ...state,
        [name]: status,
      };
    },
  },
  {},
);

export default combineReducers({
  stateByFieldName,
});
