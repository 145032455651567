import {createRequestTypes} from '../utils';

export const PRODUCT_LIST = createRequestTypes('product/LIST_PRODUCTS');
export const PRODUCT_LIST2 = createRequestTypes('product/LIST_PRODUCTS2');
export const PRODUCT = createRequestTypes('product/PRODUCT');
export const SAVE_PRODUCT_CPSRB_COMMENT = createRequestTypes('product/SAVE_PRODUCT_CPSRB_COMMENT');
export const PRODUCT_LIST_FOR_COMPANY = createRequestTypes('product/PRODUCT_LIST_FOR_COMPANY');
export const SAVE_PRODUCT = createRequestTypes('product/SAVE_PRODUCT');
export const REPORT_TEST = createRequestTypes('product/REPORT_TEST');
export const REPORT = createRequestTypes('product/REPORT');
export const UPDATE_COMPOSITION_STATUS = createRequestTypes('product/UPDATE_COMPOSITION_STATUS');
export const UPDATE_PRODUCT_STATUS = createRequestTypes('product/UPDATE_PRODUCT_STATUS');
export const UPDATE_PRODUCT_CPSRB_CONCLUSION_STATUS = createRequestTypes(
  'product/UPDATE_PRODUCT_CPSRB_CONCLUSION_STATUS',
);
export const UPDATE_INCI_QTY = createRequestTypes('product/UPDATE_INCI_QTY');
export const UPDATE_PRODUCT = createRequestTypes('product/UPDATE_PRODUCT');
export const SAVE_PRODUCT_PHRASES = createRequestTypes('product/SAVE_PRODUCT_PHRASES');
export const PRODUCT_ORDER_LIST = createRequestTypes('product/PRODUCT_ORDER_LIST');
export const PRODUCT_TASK_LIST = createRequestTypes('product/PRODUCT_TASK_LIST');
export const PRODUCT_IMPACT_ASSESSMENT_LIST = createRequestTypes('product/PRODUCT_IMPACT_ASSESSMENT_LIST');
export const PRODUCT_PHRASE_LIST = createRequestTypes('product/PRODUCT_PHRASE_LIST');
export const PRODUCT_COMPANY_LIST = createRequestTypes('product/PRODUCT_COMPANY_LIST');
export const PRODUCT_INCI_LIST = createRequestTypes('product/PRODUCT_INCI_LIST');
export const UPDATE_PRODUCT_CPSR_STATUS = createRequestTypes('product/UPDATE_PRODUCT_CPSR_STATUS');
export const PRODUCT_MOS_CALCULATION_LIST = createRequestTypes('product/PRODUCT_MOS_CALCULATION_LIST');
export const PRODUCT_FORMULA_REVIEW_LIST = createRequestTypes('product/PRODUCT_FORMULA_REVIEW_LIST');
export const PRODUCT_IMPURITY_LIST = createRequestTypes('product/PRODUCT_IMPURITY_LIST');
export const DELETE_PRODUCT_PROPORTION = createRequestTypes('product/DELETE_PRODUCT_PROPORTION');
export const SAVE_PRODUCT_PROPORTIONS = createRequestTypes('product/SAVE_PRODUCT_PROPORTIONS');
export const PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST = createRequestTypes(
  'product/PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST',
);
export const PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST = createRequestTypes(
  'product/PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST',
);
export const DELETE_PRODUCT_PHRASES = createRequestTypes('product/DELETE_PRODUCT_PHRASES');
export const PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST = createRequestTypes(
  'product/PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST',
);
export const SAVE_AND_LINK_PRODUCT_PROPORTION = createRequestTypes('product/SAVE_AND_LINK_PRODUCT_PROPORTION');
export const SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION = createRequestTypes('product/SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION');
export const ADD_ORDER_TO_CURRENT_PRODUCT = createRequestTypes('product/ADD_ORDER_TO_CURRENT_PRODUCT');
export const ADD_ORDER_TO_PRODUCT = createRequestTypes('product/ADD_ORDER_TO_PRODUCT');
export const ORDERS_OWNED_BY_PRODUCT_COMPANY = createRequestTypes('product/ORDERS_OWNED_BY_PRODUCT_COMPANY');
export const NEW_PRODUCT = 'product/NEW_PRODUCT';
export const LOAD_DETAIL_PAGE = 'product/LOAD_DETAIL_PAGE';
export const CHANGE_PRODUCT_COMPOSITION_STATUS = 'product/CHANGE_PRODUCT_COMPOSITION_STATUS';
export const REMOVE_ORDER_FROM_PRODUCT = 'product/REMOVE_ORDER_FROM_PRODUCT';
export const SUBMIT_PRODUCT_FORM = 'product/SUBMIT_PRODUCT_FORM';
export const EXPORT_COMPOSITION_FOR_LATIN_AMERICA = createRequestTypes('product/EXPORT_COMPOSITION_FOR_LATIN_AMERICA');
export const FETCH_LAST_FR_CHECK_LIST = createRequestTypes('product/FETCH_LAST_FR_CHECK_LIST');
export const CHECK_LIST_CHANGE_STATUS = createRequestTypes('product/CHECK_LIST_CHANGE_STATUS');
export const FETCH_LAST_FR_MISSING_DOCUMENTS = createRequestTypes('product/FETCH_LAST_FR_MISSING_DOCUMENTS');
export const MISSING_DOCUMENT_CHANGE_STATUS = createRequestTypes('product/MISSING_DOCUMENT_CHANGE_STATUS');
export const PROCESS_IMPORTED_PRODUCT_PROPORTIONS = createRequestTypes('product/PROCESS_IMPORTED_PRODUCT_PROPORTIONS');
export const IMPORT_PRODUCT_PROPORTIONS = createRequestTypes('product/IMPORT_PRODUCT_PROPORTIONS');
export const DELETE_IMPORTED_PRODUCT_PROPORTIONS = createRequestTypes('product/DELETE_IMPORTED_PRODUCT_PROPORTIONS');
export const GENERATE_MISSING_DOCUMENT_TEXT = createRequestTypes('product/GENERATE_MISSING_DOCUMENT_TEXT');
