import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import { substanceFunctionListSchema } from '../schema';
import substanceFunctionApi from '../../api/substanceFunctionApi';
import * as substanceFunctionTypes from './substanceFunctionTypes';
import * as substanceFunctionActions from './substanceFunctionActions';

export default function* root() {
  yield all([
    takeLatest(substanceFunctionTypes.SUBSTANCE_FUNCTION_LIST.REQUEST, getSubstanceFunctionListSaga),
    takeLatest(substanceFunctionTypes.SAVE_SUBSTANCE_FUNCTION.REQUEST, saveSubstanceFunctionListSaga),
    takeLatest(substanceFunctionTypes.DELETE_SUBSTANCE_FUNCTION.REQUEST, deleteSubstanceFunctionListSaga),
  ]);
}

function* saveSubstanceFunctionListSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const substanceFunctionId = yield callApiSaga(
    substanceFunctionActions.saveSubstanceFunction,
    substanceFunctionApi.saveSubstanceFunction,
    payload,
  );
  if (substanceFunctionId !== undefined) {
    yield put(substanceFunctionActions.listSubstanceFunctions.request());
  }
  yield put(applicationActions.unsetLoading(type));
}

function* deleteSubstanceFunctionListSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { id, query } = payload;
  yield callApiSaga(substanceFunctionActions.deleteSubstanceFunction, substanceFunctionApi.deleteSubstanceFunction, id);
  yield put(substanceFunctionActions.listSubstanceFunctions.request(query));
  yield put(applicationActions.unsetLoading(type));
}

export function* getSubstanceFunctionListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const rawMatList = yield call(substanceFunctionApi.getSubstanceFunctions, payload);
    const normalizedData = normalize(rawMatList, substanceFunctionListSchema);
    yield put(substanceFunctionActions.listSubstanceFunctions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceFunctionActions.listSubstanceFunctions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
