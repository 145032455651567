import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isDirty } from 'redux-form';
import { push } from 'react-router-redux';
import formNames from '../../constants/formNames';
import ImpactAssessmentForm from './ImpactAssessmentForm';
import {
  impactAssessmentActions,
  impactAssessmentSelectors,
  impactAssessmentTypes,
} from '../../redux/impactAssessment';
import { impactAssessmentProductActions, impactAssessmentProductTypes } from '../../redux/impactAssessmentProduct';
import { applicationSelectors } from '../../redux/application';
import { workflowStatusSelectors, workflowStatusTypes } from '../../redux/workflowStatus';
import withLeaveRouteFormDirtyWrapper from '../../Hoc/withLeaveRouteFormDirtyWrapper';
import { modalActions } from '../../redux/modal';
import { impactAssessmentFormSelectors } from '../../redux/form/impactAssessmentForm';
import { categoryActions, categorySelectors } from '../../redux/category';
import {companyActions, companySelectors} from "../../redux/company";

class ImpactAssessmentDetailsContainer extends PureComponent {
  componentDidMount() {
    const impactAssessmentIdToFetch = this.getImpactAssessmentIdToFetch(this.props);
    this.props.onLoadImpactAssessment(impactAssessmentIdToFetch);
  }

  componentDidUpdate(previousProps) {
    if (this.getImpactAssessmentIdToFetch(previousProps) !== this.getImpactAssessmentIdToFetch(this.props)) {
      const impactAssessmentIdToFetch = this.getImpactAssessmentIdToFetch(this.props);
      this.props.onLoadImpactAssessment(impactAssessmentIdToFetch);
    }
  }

  getImpactAssessmentIdToFetch = props => props.impactAssessmentId;

  render() {
    return (
      <div>
        <ImpactAssessmentForm {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state, { impactAssessmentId }) => {
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('IMPACT_ASSESSMENT');
  const getInitialValues = impactAssessmentFormSelectors.makeGetInitialValues(state);
  const isNew = impactAssessmentSelectors.isNew(state);
  const values = getInitialValues(impactAssessmentId);

  return {
    initialValues: values,
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST,
      impactAssessmentTypes.IMPACT_ASSESSMENT.REQUEST,
      workflowStatusTypes.WORKFLOW_STATUS_LIST.REQUEST,
      impactAssessmentTypes.UPDATE_IMPACT_ASSESSMENT_STATUS.REQUEST,
    ),
    isImpactAssessmentEditable:
      impactAssessmentSelectors.makeIsImpactAssessmentEditable(impactAssessmentId)(state) || isNew,
    impactAssessmentTitle: impactAssessmentSelectors.impactAssessmentTitle(state),
    isDirty: isDirty('impactAssessmentForm')(state),
    workflowStatusList: getWorkflowStatusList(state),
    impactAssessmentStatus: values.Status || '',
    isNew,
    categoryList: categorySelectors.getCategoryList(state),
    impactAssessmentId,
    companyList: companySelectors.getCompanyList(state),
    requestingFindCandidate: applicationSelectors.isLoading(
      state,
      impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST.REQUEST,
    ),
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  
  const ImpactAssessmentId = ownProps.impactAssessmentId;
  return {
    onLoadImpactAssessment: id => {
      if (id > 0) {
        dispatch(impactAssessmentActions.impactAssessment.request(id));
      }
      dispatch(categoryActions.listCategories.request());
      dispatch(companyActions.listCompanies.request());
    },
    onSaveImpactAssessment: impactAssessmentActions.submitImpactAssessmentForm,
    onFindCandidatesClick: () => {
      dispatch(impactAssessmentProductActions.generateImpactAssessmentCandidateProducts.request(ImpactAssessmentId));
    },
    onShowDetails: () => dispatch(push({ ...ownProps.location, query: { ...ownProps.location.query } })),
    onChangeStatus: ({ Code }) =>
      dispatch(impactAssessmentActions.updateStatus({ WorkflowStatusCode: Code, ImpactAssessmentId })),
    updateImpactAssessmentCategories: (id, data) => {
      dispatch(impactAssessmentActions.updateImpactAssessmentCategories(id, data));
    },
    showStatusHistory: () => dispatch(modalActions.showImpactAssessmentActionsModal({ ImpactAssessmentId })),
  };
}

ImpactAssessmentDetailsContainer.propTypes = {
  impactAssessmentId: PropTypes.number.isRequired,
  onLoadImpactAssessment: PropTypes.func.isRequired,
};

ImpactAssessmentDetailsContainer.defaultProps = {};

const withData = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withImpactAssessmentFormLeaveRouteDirtyWrapper = withLeaveRouteFormDirtyWrapper(formNames.impactAssessment);

export default compose(
  withData,
  withImpactAssessmentFormLeaveRouteDirtyWrapper,
)(ImpactAssessmentDetailsContainer);
