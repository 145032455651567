import { schema } from 'normalizr';
import * as substanceSchemas from '../substance/substanceSchemas';
import * as functionSchemas from '../function/functionSchemas';

export const rawMatProportionSchema = new schema.Entity('rawMatProportions', {}, { idAttribute: 'RawMatProportionId' });

export const rawMatProportionWithSubstanceSchema = new schema.Entity(
  'rawMatProportions',
  {
    Substance: substanceSchemas.substanceWithProportionSchema,
    Function: functionSchemas.functionSchema,
  },
  { idAttribute: 'RawMatProportionId' },
);

export const rawMatProportionListSchema = [rawMatProportionSchema];
export const rawMatProportionWithSubstanceListSchema = [rawMatProportionWithSubstanceSchema];
