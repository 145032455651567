import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import { SelectionGrid, DateCellRenderer } from '../../_Common/Grids';
import { DataGrid, Column, ColumnChooser, StateStoring } from 'devextreme-react/data-grid';
// import { DataTypeProvider,
//   TreeDataState, SortingState, SelectionState, FilteringState, PagingState,
//   CustomTreeData, IntegratedFiltering, IntegratedPaging, IntegratedSorting, IntegratedSelection, } from '@devexpress/dx-react-grid';
// import { Grid,
//   Table, TableHeaderRow, TableFilterRow, TableTreeColumn,
//   PagingPanel, TableColumnResizing, Toolbar, TableColumnVisibility, ColumnChooser as ColChoos, } from '@devexpress/dx-react-grid-bootstrap3'
// import data from '../../_Common/Modals/ProductProportionSelectRMModal/data';

function render (data) {
  // return (
  // R.compose(
  //   result => `<p style="white-space: normal">${result}</p>`,
  //   R.prop('ExceptionDetails'),
  // )(data.data));
    return  <p style={{fontSize:9}}>{data.data["ExceptionDetails"]}</p>; 
};

function renderDateTime (data) {
  const date = DateCellRenderer({ value: data.data.DateTime, includeHours: true });
  return date;
};

function rowDoubleClickedDX (data) {
  console.log(data);
}

function rowDoubleClicked (data) {
  console.log(data);
}

const ErrorList = props => {
  
  const { errorList, isLoading, getErrorList } = props;

  const devcolumns = [
    { name: 'id', title: 'ID' },
    { name: 'product', title: 'Product' },
    { name: 'owner', title: 'Owner' }
  ];

  const devrows = [
    { id: 0, product: 'DevExtreme', owner: 'DevExpress' },
    { id: 1, product: 'DevExtreme Reactive', owner: 'DevExpress' }
  ];

  // const dxColumns =
  // [
  //   { name: 'Subject', title: 'Task Subject' },
  //   { name: 'Assigned_Employee_ID', title: 'Assigned', getCellValue: row => employees.find(e => e.ID === row.Assigned_Employee_ID).Name },
  //   { name: 'Status', title: 'Status' },
  //   { name: 'Priority', title: 'Priority', getCellValue: row => priorities.find(p => p.ID === row.Priority).Value },
  //   { name: 'Completion', title: '% Completed', getCellValue: row => `${row.Completion}%` },
  //   { name: 'Start_Date', title: 'Start Date', getCellValue: row => row.Start_Date.split('T')[0] },
  //   { name: 'Due_Date', title: 'Due Date', getCellValue: row => row.Due_Date.split('T')[0] },
  // ]
  // [
  //   {
  //     name: 'UnHandledExceptionId',
  //     title: 'Id',
  //     width: 60
  //   },
  //   {
  //     name: 'DateTime',
  //     width: 120,
  //   },
  //   {
  //     name: 'UserName',
  //     title: "User",
  //     width: 120
  //   },
  //   {
  //     name: 'ExceptionDetails',
  //     title: "Details",
  //     // customi: render
  //   }
  // ]

  // const employees = [{
  //   "ID": 1,
  //   "Name": "John Heart",
  //   "Picture": "images/employees/01.png"
  // }, {
  //   "ID": 2,
  //   "Name": "Samantha Bright",
  //   "Picture": "images/employees/04.png"
  // }];

  // const priorities = [
  //   { "ID": 1, "Value": "Low" },
  //   { "ID": 2, "Value": "Normal" },
  //   { "ID": 3, "Value": "Urgent" },
  //   { "ID": 4, "Value": "High" }
  // ];

  // const tasks = [{
  //   "ID": 1,
  //   "Assigned_Employee_ID": 1,
  //   "Owner_ID": 1,
  //   "Subject": "Plans 2015",
  //   "Start_Date": "2015-01-01T00:00:00",
  //   "Due_Date": "2015-04-01T00:00:00",
  //   "Status": "Completed",
  //   "Priority": 4,
  //   "Completion": 100,
  //   "Parent_ID": 0
  // }, {
  //   "ID": 182,
  //   "Assigned_Employee_ID": 2,
  //   "Owner_ID": 1,
  //   "Subject": "Submit Customer Follow Up Plan Feedback",
  //   "Start_Date": "2016-05-06T00:00:00",
  //   "Due_Date": "2016-05-10T00:00:00",
  //   "Status": "Deferred",
  //   "Priority": 2,
  //   "Completion": 0,
  //   "Parent_ID": 27
  // }];

  const columns = [
    {
      headerName: 'Id',
      field: 'UnHandledExceptionId',
      width: 10,
      // cellRendererParams: {
      //   includeHours: true,
      // },
      sort: 'desc',
    },
    {
      headerName: 'DateTime',
      field: 'DateTime',
      cellRenderer: DateCellRenderer,
      width: 20,
      cellRendererParams: {
        includeHours: true,
      },
    },
    {
      headerName: 'User',
      field: 'UserName',
      width: 10,
    },
    {
      headerName: 'ExceptionDetails',
      field: 'ExceptionDetails',
      cellRenderer: ({ data }) =>
        R.compose(
          result => `<p style="white-space: normal">${result}</p>`,
          R.prop('ExceptionDetails'),
        )(data),
    },
  ];

  return (
    <>
    {/* <Grid
      // rows={devrows}
      // columns={devcolumns}
      >
        <Table />
        <TableHeaderRow />
    </Grid> */}
      <DataGrid
        dataSource={errorList}
        showBorders={true}
        showColumnLines={true}
        showRowLines={true}
        onRowDblClick={rowDoubleClickedDX}
        // onCellPrepared={this.onCellPrepared}
        // headerCellRender={renderTitleHeader}
        // columnAutoWidth={false}
        // columns={dxColumns}
        allowColumnReordering={true}
        allowColumnResizing={true}
      >
        <Column dataField="UnHandledExceptionId" caption="Id" width={60}></Column>
        <Column dataField="DateTime" width={120} cellRender={renderDateTime}></Column>
        <Column dataField="UserName" caption="User" width={120}></Column>
        <Column dataField="ExceptionDetails" caption="Details" cellRender={render} ></Column>
        <ColumnChooser enabled={true}/>
        <StateStoring enabled={true} type="localStorage" storageKey="storage" />
      </DataGrid>
      <SelectionGrid
        data={errorList}
        columns={columns}
        onRefresh={getErrorList}
        onRowDoubleClicked={rowDoubleClicked}
        showLoadingIcon={isLoading}
        keyName="UnHandledExceptionId"
        showFilterRow
        getRowHeight={({ data }) => 2.8 * (Math.floor(data.ExceptionDetails.length / 45) + 1)}
        showAddButton={false}
      />
    </>
  );
};

ErrorList.propTypes = {
  errorList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getErrorList: PropTypes.func.isRequired,
};

export default ErrorList;
