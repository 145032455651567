import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tab, Nav, NavItem } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import { FormSection } from 'redux-form';
// import other containers
import DistributorDetailsContainer from './_DistributorDetailsContainer';
// import DistributorContact from './DistributorContact';
import DistributorAgreements from './DistributorAgreements';
import DistributorMandates from './DistributorMandates';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const DistributorDetails = props => {
  const distributorId = Number(props.params.id);
  return (
    <div className={styles.detailsContainer}>
      <DistributorDetailsContainer distributorId={distributorId} {...props} />
      <Tab.Container id="SubstanceTabs" defaultActiveKey={1}>
        <Col sm={12}>
          <Nav bsStyle="tabs">
            {/* <NavItem eventKey={0}>{I18n.t('distributor.contacts')}</NavItem> */}
            <NavItem eventKey={1}>{I18n.t('distributor.agreements')}</NavItem>
            <NavItem eventKey={2}>{I18n.t('distributor.mandates')}</NavItem>
          </Nav>

          <Tab.Content>
            {/* <Tab.Pane eventKey={0}>
              <FormSection name="Contact">
                <DistributorContacts  {...props}/>
              </FormSection>
            </Tab.Pane> */}
            <Tab.Pane eventKey={1}>
              <DistributorAgreements distributorId={distributorId} />
            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <DistributorMandates distributorId={distributorId} />
            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              {/*<DistributorProductListContainer distributorId={distributorId} />*/}
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Tab.Container>
    </div>
  );
};

DistributorDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DistributorDetails;
