import { formValueSelector } from 'redux-form';
import * as R from 'ramda';
import queryString from 'query-string';
import { PrefixIndexStrategy } from 'js-search';
import createFilterOptions from 'react-select-fast-filter-options';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { functionSelectors } from '../function';
import { createGeneralSelectors, createAuthorizationCheck } from '../_common/selectors';
import { isNullOrUndef, isUndefined } from '../../utils/utils';
import { rawMatProportionSelectors } from '../rawMatProportion';
import { substanceProportionSelectors } from '../substanceProportion';
import substanceAuthorizationCodes from '../../constants/substanceAuthorizationCodes';

const defailsFormValueSelector = formValueSelector('substanceForm');

const getSubstanceSlice = state => state.substance;

export const {
  getById,
  getIds,
  makeGetItemById: makeGetSubstanceById,
  getByQueryIds,
  getLoading,
} = createGeneralSelectors(getSubstanceSlice);

export const getSubstanceList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getSubstance = (state, id) => getById(state)[id];

export const getCurrentsubstanceFunctions = ({ substance }) => substance.currentSubstanceFunctions;

export const isNew = state => {
  const id = defailsFormValueSelector(state, 'SubstanceId');
  return id <= 0 || isNullOrUndef(id);
};

const getAuthorizationCodes = R.propOr([], 'AuthorizationCodes');

const createMakeGetAuthorizationChek = createAuthorizationCheck(makeGetSubstanceById)(getAuthorizationCodes);

export const makeIsSubstanceEditable = createMakeGetAuthorizationChek([substanceAuthorizationCodes.EDIT_SUBSTANCE]);

const substancePrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetSubstanceListFilter = createSelector(R.identity, substanceList =>
  createFilterOptions({
    options: substanceList,
    labelKey: 'FullName',
    valueKey: 'SubstanceId',
    indexStrategy: substancePrefixIndexStrategy,
  }),
);

export const sortByInciName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'InciName'))]));

const makeGetByQueryIds = querystring =>
  createSelector([getById, getByQueryIds], (byId, byQueryIds) =>
    R.compose(
      R.filter(R.complement(isUndefined)),
      R.map(id => byId[id]),
      R.propOr([], querystring),
    )(byQueryIds),
  );

const makeGetByQueryLoading = querystring =>
  R.compose(
    R.propOr(false, querystring),
    getLoading,
  );

export const getIsLoadingAllergenSubstances = makeGetByQueryLoading(queryString.stringify({ IsAllergen: true }));
export const getIsLoadingImpuritySubstances = makeGetByQueryLoading(queryString.stringify({ IsImpurity: true }));

export const getAllergenSubstances = makeGetByQueryIds(queryString.stringify({ IsAllergen: true }));
export const getImpuritySubstances = makeGetByQueryIds(queryString.stringify({ IsImpurity: true }));

export const sortByName = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'InciName'))]));

export const makeGetSubstancesNotAlreadyInRawMatComposition = () =>
  createSelector(
    getSubstanceList,
    rawMatProportionSelectors.getRawMatProportionListForRawMat,
    (substanceList, getRawMatProportionsForRawMat) =>
      createSelector(
        rawMatId => getRawMatProportionsForRawMat(rawMatId),
        rawMatProportions =>
          substanceList.filter(s => !rawMatProportions.map(c => c.SubstanceId).includes(s.SubstanceId)),
      ),
  );

export const getSubstanceComposition = (state, substanceId) => {
  const substanceProportions = substanceProportionSelectors.getSubstanceProportionListForSubstance(state, substanceId);

  return substanceProportions.reduce(
    (acc, sp) => [
      ...acc,
      {
        ...sp,
        ReferencedSubstance: getSubstance(state, sp.ReferencedSubstanceId),
      },
    ],
    [],
  );
};

export const getSecondLevelSubstances = createSelector(
  [substanceProportionSelectors.getSubstanceProportionList, getSubstanceList],
  (substanceProportionList, substanceList) => {
    if (substanceProportionList.length === 0) return substanceList;
    return _.differenceWith(
      substanceList,
      substanceProportionList,
      (substance, substanceProportion) => substance.SubstanceId === substanceProportion.ReferencedSubstanceId,
    );
  },
);

export const makeGetFirstLevelSubstancesForProduct = () =>
  createSelector(rawMatProportionSelectors.makeGetRawMatProportionsForProduct, getRawMatProportionsForProduct =>
    createSelector(
      [getRawMatProportionsForProduct, getById],
      (rawMatProportionsForProduct, substanceById) =>
        R.compose(
          R.uniqWith(R.eqProps('SubstanceId')),
          R.map(rmp => substanceById[rmp.SubstanceId]),
        )(rawMatProportionsForProduct),
    ),
  );

export const makeGetFirstLevelSubstancesForRawMat = () =>
  createSelector(R.identity, rawMatId =>
    createSelector(
      [state => rawMatProportionSelectors.getRawMatProportionListForRawMat(state)(rawMatId), getById],
      (rawMatProportionsForRawMat, substanceById) => R.compose(
        R.uniqWith(R.eqProps('SubstanceId')),
        R.map(rmp => substanceById[rmp.SubstanceId]),
      )(rawMatProportionsForRawMat),
    ),
  );

const onlyInciSubstances = R.where({ InciName: R.complement(R.anyPass([R.isNil, R.isEmpty])) });

export const makeGetInciSubstancesForProduct = () =>
  createSelector(makeGetFirstLevelSubstancesForProduct(), getFirstLevelSubstanceForProduct =>
    createSelector(getFirstLevelSubstanceForProduct, firstLevelSubstancesForProduct =>
      firstLevelSubstancesForProduct.filter(onlyInciSubstances),
    ),
  );

export const makeGetInciSubstancesForRawMat = () =>
  createSelector(makeGetFirstLevelSubstancesForRawMat(), getFirstLevelSubstanceForRawMat =>
    createSelector(getFirstLevelSubstanceForRawMat, firstLevelSubstancesForRawMat =>
      firstLevelSubstancesForRawMat.filter(onlyInciSubstances),
    ),
  );

export const GetNotLoadedSubstanceId = createSelector(getById, byId =>
  (substanceIds) => substanceIds.filter(elem => byId[elem] === undefined));
