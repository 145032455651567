import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { filterByQueryAndMerge, removeById } from '../utils';
import * as substanceClpTypes from './substanceClpTypes';

const byId = handleActions(
  {
    [substanceClpTypes.FETCH_SUBSTANCE_CLPS.SUCCESS]: filterByQueryAndMerge('substanceClps'),
    [substanceClpTypes.DELETE_SUBSTANCE_CLP.SUCCESS]: removeById,
  },
  {},
);

export default combineReducers({
  byId,
});
