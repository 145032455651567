import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form, Grid, Row, Col, Panel, Badge } from 'react-bootstrap';
import { Field, reduxForm, FormSection } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { InputField } from '../../../_Common/Forms';
import { ProcessTypeListDropdown } from '../../../_Common/Dropdowns';
import ProcessBasicFormSection from './ProcessBasicFormSection';
import { isNullOrUndef } from '../../../../utils/utils';
import formNames from '../../../../constants/formNames';
import { taskTypeFormActions } from '../../../../redux/form/taskTypeForm';
import { requiredField, isPositiveOrZeroInteger, maxLength32, maxLength16 } from '../../../../utils/fieldValidation';

class TaskTypeForm extends React.Component {
  state = { createNewProcessTypeMode: isNullOrUndef(this.props.processTypeId) };

  componentWillReceiveProps(nextProps) {
    if (this.props.processTypeId !== nextProps.processTypeId) {
      this.setState({ createNewProcessTypeMode: isNullOrUndef(nextProps.processTypeId) });
    }
  }

  render() {
    const {
      handleSubmit,
      TaskTypeId,
      onProcessTypeSelected,
      processTypeList,
      isInEditing,
      onAddButtonClicked,
      onSubmit,
      ...otherProps
    } = this.props;

    /**
     * @return {boolean}
     */
    const isProcessTypeSelected = () => !isNullOrUndef(this.props.processTypeId);

    const handleProcessTypeChange = (e, processTypeId) => {
      this.setState({ createNewProcessTypeMode: false });
      this.props.onProcessTypeSelected(processTypeId);
    };

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col sm={12}>
                  <Field
                    name="ProcessTypeId"
                    label={I18n.t('taskType.processType')}
                    component={ProcessTypeListDropdown}
                    initialOptions={processTypeList}
                    onChange={handleProcessTypeChange}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Panel
                  header={
                    <span>
                      {I18n.t('taskType.processTypeHeader')}
                      &nbsp;
                      {this.state.createNewProcessTypeMode && (
                        <Badge>{I18n.t('taskType.createProcessTypeModeLabel')}</Badge>
                      )}
                    </span>
                  }
                  bsStyle="primary"
                >
                  <FormSection name="ProcessType" {...this.props}>
                    <ProcessBasicFormSection
                      {...otherProps}
                      readOnly={isProcessTypeSelected() && !this.state.createNewProcessTypeMode}
                    />
                  </FormSection>
                </Panel>
              </Row>
              <Row>
                <Panel header={I18n.t('taskType.taskType')} bsStyle="primary">
                  <Row>
                    <Col sm={2}>
                      <Field
                        name="Code"
                        label={`${I18n.t('taskType.code')} *`}
                        component={InputField}
                        validate={[requiredField, maxLength16]}
                      />
                    </Col>
                    <Col sm={5}>
                      <Field
                        name="Name"
                        label={`${I18n.t('taskType.name')}*`}
                        component={InputField}
                        validate={[requiredField, maxLength32]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={2}>
                      <Field
                        name="ExpectedDuration"
                        label={`${I18n.t('taskType.expectedDuration')}`}
                        component={InputField}
                        validate={[isPositiveOrZeroInteger]}
                      />
                    </Col>
                    <Col sm={2}>
                      <Field
                        name="DeliveryTime"
                        label={`${I18n.t('taskType.deliveryTime')}`}
                        component={InputField}
                        validate={[isPositiveOrZeroInteger]}
                      />
                    </Col>
                   <Col sm={2}>
                      <Field
                        name="Weight"
                        label={`${I18n.t('taskType.weight')}`}
                        component={InputField}
                        validate={[isPositiveOrZeroInteger]}
                      />
                    </Col>
                    <Col sm={2}>
                      <Field
                        name="OrderNr"
                        label={`${I18n.t('taskType.orderNr')}`}
                        component={InputField}
                        validate={[isPositiveOrZeroInteger]}
                      />
                    </Col>
                  </Row>
                </Panel>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

TaskTypeForm.defaultProps = {
  processTypeCode: undefined,
};

TaskTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAddButtonClicked: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isInEditing: PropTypes.bool.isRequired,
  processTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      ProcessTypeId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  region: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.number.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.number.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  change: PropTypes.func.isRequired,
  processTypeId: PropTypes.number,
  onProcessTypeSelected: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => taskTypeFormActions.submitTaskTypeForm({ ...data, productId }, dispatch),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.taskTypeForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(TaskTypeForm);
