import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { SelectionGrid } from '../../_Common/Grids';
import { ButtonToolbar, Button } from 'react-bootstrap';
import * as R from 'ramda';
import ReactTooltip from 'react-tooltip';

const getYN = ({data, colDef}) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('X'), R.always(''));

const ActionButtonsRenderer = props => {
  const { onMarkClick, onEditClick, onTranslateClick, onDeleteClick, data } = props;

  const handleButtonClick = fn => () => fn(data);

  return (
    <div>
      <ButtonToolbar>
        <Button
          bsStyle="success"
          bsSize="xsmall"
          type="button"
          onClick={handleButtonClick(onMarkClick)}
          data-tip data-for="markTip"
        >
          <i className="fa fa-highlighter" />
        </Button>
        <ReactTooltip id="markTip" place="bottom" type="dark" effect="solid">
          <Translate value="category.markAsMain" />
        </ReactTooltip>
        <Button
          bsStyle="info"
          bsSize="xsmall"
          type="button"
          onClick={handleButtonClick(onEditClick)}
          data-tip data-for="editTip"
        >
          <i className="fa fa-edit" />
        </Button>
        <ReactTooltip id="editTip" place="bottom" type="dark" effect="solid">
          <Translate value="general.edit" />
        </ReactTooltip>
        <Button
          bsStyle="default"
          bsSize="xsmall"
          type="button"
          onClick={handleButtonClick(onTranslateClick)}
          data-tip data-for="translateTip"
        >
          <i className="fas fa-language" />
        </Button>
        <ReactTooltip id="translateTip" place="bottom" type="dark" effect="solid">
          <Translate value="general.translate" />
        </ReactTooltip>
        <Button
          bsSize="xsmall"
          bsStyle="danger"
          type="button"
          onClick={handleButtonClick(onDeleteClick)}
          data-tip data-for="deleteTip"
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
        <ReactTooltip id="deleteTip" place="bottom" type="dark" effect="solid">
          <Translate value="general.delete" />
        </ReactTooltip>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onEditClick: PropTypes.func.isRequired,
  onTranslateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

const ProductCategoryList = props => {
  const { currentProductCategories, isLoading, onAddButtonClick, onMarkButtonClick, onEditButtonClick, onTranslateButtonClick, onDeleteButtonClick, getProductCategories, productId } = props;

  const columns = [
    {
      headerName: I18n.t('category.isMain'),
      field: 'IsMain',
      width: 50,
      suppressSizeToFit: true,
      filter: false,
      valueGetter: getYN
    },
    {
      headerName: I18n.t('category.description'),
      field: 'CategoryDescription',
      width: 200,
    },
    {
      headerName: I18n.t('category.useQuantity'),
      field: 'UseQuantity',
      width: 75,
      suppressSizeToFit: true,
      filter: false,
    },
    {
      headerName: I18n.t('category.useSite'),
      field: 'UseSite',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.useSurface'),
      field: 'UseSurface',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.useFrequency'),
      field: 'UseFrequency',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.useRetentionFactor'),
      field: 'UseRetentionFactor',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.amountSprayed'),
      field: 'AmountSprayed',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.airborneFraction'),
      field: 'AirborneFraction',
      width: 70,
      filter: false,
    },
    {
      headerName: I18n.t('category.sedWeight'),
      field: 'sedWeight',
    },
    {
      headerName: I18n.t('category.sedSurface'),
      field: 'sedSurface',
    },
    {
      headerName: I18n.t('category.targetPopulation'),
      field: 'TargetPopulation',
      width: 200,
    },
    {
      headerName: I18n.t('general.actions'),
      width: 190,
      cellRendererFramework: ActionButtonsRenderer,
      suppressSizeToFit: true,
      cellRendererParams: {
        onMarkClick: onMarkButtonClick,
        onEditClick: onEditButtonClick,
        onTranslateClick: onTranslateButtonClick,
        onDeleteClick: onDeleteButtonClick,
      },
    },  ];

  return (
    <SelectionGrid
      key={productId + currentProductCategories.length}
      data={currentProductCategories}
      keyName="ProductCategoryId"
      columns={columns}
      isLoading={isLoading}
      onRefresh={getProductCategories}
      showAddButton={true}
      onAdd={onAddButtonClick}
    />
  );
};

ProductCategoryList.propTypes = {
  currentProductCategories: PropTypes.array, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  onMarkButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onTranslateButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

export default ProductCategoryList;
