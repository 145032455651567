import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { taskActions, taskTypes, taskSelectors } from '../../../../redux/task';
import { applicationSelectors } from '../../../../redux/application';
import { userSelectors } from '../../../../redux/user';
import { userRoleTypes, userRoleActions } from '../../../../redux/userRole';
import { modalActions } from '../../../../redux/modal';
import { workflowStatusSelectors } from '../../../../redux/workflowStatus';
import * as userRoles from '../../../../constants/userRoleTypes';
import TaskList from './TaskList';
import { push } from 'react-router-redux';
import * as taskActionActions from '../../../../redux/taskAction/taskActionActions';

class ToDoListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <TaskList {...this.props} />;
  }
}

const mapStateToProps = state => {
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('TASK');
  return {
    isLoading: applicationSelectors.isLoading(
      state,
      taskTypes.TASK_LIST.REQUEST,
      userRoleTypes.USER_ROLE_LIST_FOR_ROLES.REQUEST,
    ),
    expertList: userSelectors.getUsersByRole(state, userRoles.default.expert),
    user: userSelectors.getCurrentUser(state),
    currentUserActionCodes: userSelectors.getCurrentUserActionCodes(state),
    workflowStatusList: getWorkflowStatusList(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onLoad: () => {
    dispatch(userRoleActions.listUserRoleForRoles.request([userRoles.default.expert]));
  },
  getTaskList: (Expert, filterStatus) => dispatch(taskActions.listTasks.request({
    IncludeDetails: true,
    Expert,
    FilterStatus: filterStatus
  })),
  onEditButtonClick: payload => {
    payload.manager = false;
    dispatch(modalActions.showTaskFormModal(payload));
  },
  onStartButtonClick: payload => dispatch(taskActions.startTask.request(payload)),
  onStopButtonClick: payload => dispatch(taskActions.stopTask.request(payload)),
  onChangeStatus: ({ data, Expert }) => {
    const { Code, TaskId } = data;
    dispatch(
      taskActions.updateStatus({ WorkflowStatusCode: Code, TaskId, searchCriteria: { IncludeDetails: true, Expert } }),
    );
  },
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
  showStatusHistory: (TaskId) => dispatch(taskActionActions.showTaskActionsHistory(TaskId)),
  onEditSelectedButtonClick: (taskIds, searchCriteria) =>
    dispatch(
      modalActions.showTaskFormModal({
        taskId: -1,
        manager: true,
        searchCriteria,
        taskIds
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToDoListContainer);

ToDoListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
