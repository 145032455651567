import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as R from 'ramda';
import * as substanceInformationTypes from './substanceInformationTypes';

const byId = handleActions(
  {
    [substanceInformationTypes.FETCH_SUBSTANCE_INFORMATIONS.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.substanceInformations),
    [substanceInformationTypes.DELETE_SUBSTANCE_INFORMATION.SUCCESS]: (state, { payload }) => R.dissoc(payload, state),
  },
  {},
);

const allIds = handleActions(
  {
    [substanceInformationTypes.FETCH_SUBSTANCE_INFORMATIONS.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
    [substanceInformationTypes.DELETE_SUBSTANCE_INFORMATION.SUCCESS]: (state, { payload }) =>
      R.without([payload], state),
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
