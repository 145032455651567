import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import rawMatActionApi from '../../api/rawMatActionApi';
import * as rawMatActionTypes from './rawMatActionTypes';
import * as rawMatActionSchemas from './rawMatActionSchemas';
import * as rawMatActionActions from './rawMatActionActions';

export default function* root() {
  yield all([takeLatest(rawMatActionTypes.FETCH_RAWMAT_ACTIONS.REQUEST, getRawMatActionsSaga)]);
}

export function* getRawMatActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const rawMatActionList = yield call(rawMatActionApi.getRawMatActions, payload);
    const normalizedData = normalize(rawMatActionList, rawMatActionSchemas.rawMatActionListSchema);
    yield put(rawMatActionActions.fetchRawMatActions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatActionActions.fetchRawMatActions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
