import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import DistributorsPageContainer from './_DistributorsPageContainer';
import BasicDetailContainer from '../_Common/BasicDetailContainer';

const Distributors = ({ children, ...otherProps }) => (
  <Grid fluid>
    <Row>
      <Col md={2}>
        <DistributorsPageContainer {...otherProps} />
      </Col>
      <Col md={10}>{<BasicDetailContainer>{children}</BasicDetailContainer>}</Col>
    </Row>
  </Grid>
);

Distributors.propTypes = {
  children: PropTypes.node,
};

export default Distributors;
