import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, CheckBoxField, SelectField } from '../../../_Common/Forms';
import { productCategoryFormSelectors } from '../../../../redux/form/productCategoryForm';
import { productCategoryActions } from '../../../../redux/productCategory';
import ProductUseTypeSelectField from './ProductUseTypeSelectField';
import {
  isPositiveOrZeroDecimal,
  isPositiveOrZeroInteger
} from '../../../../utils/fieldValidation';

const formName = formNames.productCategory;

const ProductCategoryForm = props => {
  const { onSubmit, handleSubmit, categoryList, showCategoryChangeConformationPopup } = props;

  const onIsInhalableChange = (newValue, oldValue) => {
    props.change('IsInhalable', newValue);
  };
  const onCategoryChange = (newValue, oldValue) => {
    const newCategoryCode = newValue;
    const oldCategoryCode = oldValue;
    const newCategory = newCategoryCode !== null ? categoryList.find(c => c.Code === newCategoryCode) : null;
    const categoryValues = {};

    if (newCategory !== null) {
      categoryValues.UseQuantity = newCategory.UseQuantity;
      categoryValues.UseFrequency = newCategory.UseFrequency;
      categoryValues.UseSurface = newCategory.UseSurface;
      categoryValues.UseRetentionFactor = newCategory.UseRetentionFactor;
      categoryValues.IsInhalable = newCategory.IsInhalable;
      categoryValues.IsDermalAbsorption100 = newCategory.IsDermalAbsorption100;
      categoryValues.AmountSprayed = newCategory.AmountSprayed;
      categoryValues.AirborneFraction = newCategory.AirborneFraction;
      categoryValues.DistributionVolume1 = newCategory.DistributionVolume1;
      categoryValues.ExposureTime1 = newCategory.ExposureTime1;
      categoryValues.DistributionVolume2 = newCategory.DistributionVolume2;
      categoryValues.ExposureTime2 = newCategory.ExposureTime2;
      categoryValues.HumanBreathMinuteVolume = newCategory.HumanBreathMinuteVolume;
      categoryValues.MeanApplicationPerDay = newCategory.MeanApplicationPerDay;
      categoryValues.FractionReachingAlveoli = newCategory.FractionReachingAlveoli;
      categoryValues.DefaultFactorSubstanceExchange = newCategory.DefaultFactorSubstanceExchange;
      categoryValues.BodyWeight = newCategory.BodyWeight;
      categoryValues.Description = newCategory.Description;
      categoryValues.NormalUse = newCategory.NormalUse;
      categoryValues.ReasonableUse = newCategory.ReasonableUse;
      categoryValues.Misuse = newCategory.Misuse;
      categoryValues.UseSite = newCategory.UseSite;
      categoryValues.TargetPopulation = newCategory.TargetPopulation;
      categoryValues.ProductUseTypeCode = newCategory.ProductUseTypeCode;
    } else {
      categoryValues.UseQuantity = '';
      categoryValues.UseFrequency = '';
      categoryValues.UseSurface = '';
      categoryValues.UseRetentionFactor = '';
      categoryValues.BodyWeight = '';
      categoryValues.Description = '';
      categoryValues.ProductUseTypeCode = null;
    }

    if (showCategoryChangeConformationPopup) {
      if (props.initialValues.CategoryCode === oldCategoryCode && props.initialValues.CategoryCode !== undefined) {
        updateCategoryValues(categoryValues)
      } else {
        updateCategoryValues(categoryValues);
      }
    } else {
      updateCategoryValues(categoryValues);
    }
  };

  const updateCategoryValues = categoryValues => {
    props.change('UseQuantity', categoryValues.UseQuantity);
    props.change('UseFrequency', categoryValues.UseFrequency);
    props.change('UseSurface', categoryValues.UseSurface);
    props.change('UseRetentionFactor', categoryValues.UseRetentionFactor);
    props.change('IsInhalable', categoryValues.IsInhalable);
    props.change('IsDermalAbsorption100', categoryValues.IsDermalAbsorption100);
    props.change('AmountSprayed', categoryValues.AmountSprayed);
    props.change('AirborneFraction', categoryValues.AirborneFraction);
    props.change('DistributionVolume1', categoryValues.DistributionVolume1);
    props.change('ExposureTime1', categoryValues.ExposureTime1);
    props.change('DistributionVolume2', categoryValues.DistributionVolume2);
    props.change('ExposureTime2', categoryValues.ExposureTime2);
    props.change('HumanBreathMinuteVolume', categoryValues.HumanBreathMinuteVolume);
    props.change('MeanApplicationPerDay', categoryValues.MeanApplicationPerDay);
    props.change('FractionReachingAlveoli', categoryValues.FractionReachingAlveoli);
    props.change('DefaultFactorSubstanceExchange', categoryValues.DefaultFactorSubstanceExchange);
    props.change('BodyWeight', categoryValues.BodyWeight);
    props.change('CategoryDescription', categoryValues.Description);
    props.change('NormalUse', categoryValues.NormalUse);
    props.change('ReasonableUse', categoryValues.ReasonableUse);
    props.change('Misuse', categoryValues.Misuse);
    props.change('UseSite', categoryValues.UseSite);
    props.change('TargetPopulation', categoryValues.TargetPopulation);
    props.change('ProductUseTypeCode', categoryValues.ProductUseTypeCode);
  };

  const isProductEditable = true;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <div>
            <Row>
              <Col sm={3}>
                <Field
                  name="CategoryCode"
                  label={I18n.t('product.category')}
                  component={SelectField}
                  labelField="Description"
                  valueField="Code"
                  options={categoryList}
                  onChange={(e, newValue, oldValue) => onCategoryChange(newValue, oldValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Field
                  name="CategoryDescription"
                  label={I18n.t('product.categoryDescription')}
                  component={InputField}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="UseQuantity"
                  label={I18n.t('product.useQuantity')}
                  component={InputField}
                  inputGroupLabel={'g'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="UseFrequency"
                  label={`${I18n.t('product.useFrequency')}`}
                  component={InputField}
                  inputGroupLabel={'/ day'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="UseSurface"
                  label={I18n.t('product.useSurface')}
                  component={InputField}
                  inputGroupLabel={'cm²'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="BodyWeight"
                  label={I18n.t('product.bodyWeight')}
                  component={InputField}
                  inputGroupLabel={'kg'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={1}>
                <Field
                  name="UseRetentionFactor"
                  label={`${I18n.t('product.useRetentionFactor')}`}
                  component={InputField}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  component={ProductUseTypeSelectField}
                  label={`${I18n.t('product.useType')}`}
                  name="ProductUseTypeCode"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <Field name="IsInhalable" label={I18n.t('category.isInhalable')}
                 component={CheckBoxField}
                 onChange={(e, newValue, oldValue) => onIsInhalableChange(newValue, oldValue)}/>
              </Col>
              <Col sm={2}>
                <Field name="IsDermalAbsorption100" label={I18n.t('category.isDermalAbsorption')}
                 component={CheckBoxField}
                />
              </Col>
           </Row>
            <Row>
              <Col sm={2}>
                <Field
                  name="AmountSprayed"
                  label={I18n.t('category.amountSprayed')}
                  component={InputField}
                  inputGroupLabel={'g'}
                  disabled={props.IsInhalable}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="AirborneFraction"
                  label={I18n.t('category.airborneFraction')}
                  component={InputField}
                  inputGroupLabel={'%'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="DistributionVolume1"
                  label={I18n.t('category.distributionVolume1')}
                  component={InputField}
                  inputGroupLabel={'m³'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="ExposureTime1"
                  label={I18n.t('category.exposureTime1')}
                  component={InputField}
                  inputGroupLabel={'min'}
                  validate={[isPositiveOrZeroInteger]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="DistributionVolume2"
                  label={I18n.t('category.distributionVolume2')}
                  component={InputField}
                  inputGroupLabel={'m³'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="ExposureTime2"
                  label={I18n.t('category.exposureTime2')}
                  component={InputField}
                  inputGroupLabel={'min'}
                  validate={[isPositiveOrZeroInteger]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <Field
                  name="HumanBreathMinuteVolume"
                  label={I18n.t('category.humanBreathMinuteVolume')}
                  component={InputField}
                  inputGroupLabel={'l/min'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="MeanApplicationPerDay"
                  label={I18n.t('category.meanApplicationPerDay')}
                  component={InputField}
                  inputGroupLabel={'/day'}
                  validate={[isPositiveOrZeroInteger]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="FractionReachingAlveoli"
                  label={I18n.t('category.fractionReachingAlveoli')}
                  component={InputField}
                  inputGroupLabel={'%'}
                  validate={[isPositiveOrZeroDecimal]}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="DefaultFactorSubstanceExchange"
                  label={I18n.t('category.defaultFactorSubstanceExchange')}
                  component={InputField}
                  inputGroupLabel={'%'}
                  validate={[isPositiveOrZeroInteger]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="UseDescription"
                  label={I18n.t('product.useDescription')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="ReasonableUse"
                  label={I18n.t('product.reasonableUse')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="NormalUse"
                  label={I18n.t('product.normalUse')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="Misuse"
                  label={I18n.t('product.misuse')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Field
                  name="UseSite"
                  label={I18n.t('product.useSite')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="TargetPopulation"
                  label={I18n.t('product.targetPopulation')}
                  component={InputField}
                  readOnly={!isProductEditable}
                />
              </Col>
            </Row>
        </div>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { productCategoryId }) => {
  const getFormInitialValues = productCategoryFormSelectors.makeGetInitialValues(productCategoryId);
  const initialValues = productCategoryId ? getFormInitialValues(state): {};
  return {
    initialValues,
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => dispatch(productCategoryActions.saveProductCategory.request({ ...data, productId })),
});

ProductCategoryForm.defaultProps = {
  initialValues: {},
  showCategoryChangeConformationPopup: () => null,
};

ProductCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  showCategoryChangeConformationPopup: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProductCategoryForm);
