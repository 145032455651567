import * as notificationSelectors from './notificationSelectors';
import * as notificationActions from './notificationActions';
import * as notificationTypes from './notificationTypes';
import * as notificationSchemas from './notificationSchemas';
import sagas from './notificationSagas';
import reducer from './notificationReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { notificationSelectors, notificationActions, notificationTypes, notificationSchemas };
export default reducer;
