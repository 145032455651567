import { createRequestActions } from '../utils';
import * as taskTypes from './taskTypes';
import workflowTypes from '../../constants/workflowTypes';

export const listTasks = createRequestActions(taskTypes.TASK_LIST, payload => payload, (data, meta) => meta);
export const saveTask = createRequestActions(taskTypes.SAVE_TASK, data => data);
export const saveMultipleTasks = createRequestActions(taskTypes.SAVE_MULTIPLE_TASKS, data => data);
export const deleteTask = createRequestActions(taskTypes.DELETE_TASK, data => data);
export const updateStatus = ({ WorkflowStatusCode, TaskId, Comment = null, searchCriteria }) => ({
  type: taskTypes.UPDATE_TASK_STATUS.REQUEST,
  payload: {
    WorkflowStatusCode,
    Comment,
    TaskId,
    searchCriteria,
  },
});
export const startTask = createRequestActions(taskTypes.START_TASK, data => data);
export const stopTask = createRequestActions(taskTypes.STOP_TASK, data => data);
