import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import ProductsPageContainer from './_ProductsPageContainer';
import BasicDetailContainer from '../_Common/BasicDetailContainer';

const Products = ({ children, ...otherProps }) => (
  <Grid fluid>
    <Row>
      <Col md={3}>
        <ProductsPageContainer {...otherProps} />
      </Col>
      <Col md={9}>
        <BasicDetailContainer>{children}</BasicDetailContainer>
      </Col>
    </Row>
  </Grid>
);

Products.propTypes = {
  children: PropTypes.node,
};

export default Products;
