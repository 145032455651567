import queryString from 'query-string';
import { get, del, post } from './apiUtils';

const defaultUrl = '/SubstanceInformations/';

export default {
  getSubstanceInformations: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),
  deleteSubstanceInformations: id => del(`${defaultUrl}${id}`),
  saveSubstanceInformations: data => post(`${defaultUrl}`, data),
};
