import * as substanceSelectors from './substanceSelectors';
import * as substanceActions from './substanceActions';
import * as substanceTypes from './substanceTypes';
import * as substanceSchemas from './substanceSchemas';
import sagas, * as substanceSagas from './substanceSagas';

import reducer from './substanceReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { substanceSelectors, substanceActions, substanceTypes, substanceSchemas, substanceSagas };
export default reducer;
