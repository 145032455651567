import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import SelectionGrid from '../_Common/Grids/SelectionGrid';

const columns = [
  {
    headerName: I18n.t('dashboard.name'),
    width: 100,
    field: 'Value',
  },
];

const DashboardsList = (props) => {
  const {
    dashboardList,
    onDashboardSelected,
    requestingDashboardList,
    selectedId,
    getDashboardList,
  } = props;
  return (
    <SelectionGrid
      data={dashboardList}
      onSelected={onDashboardSelected}
      onRefresh={getDashboardList}
      isLoading={requestingDashboardList}
      selectedId={selectedId}
      columns={columns}
      onFetchData={props.onFetchData}
      keyName="DashboardTypeId"
      showAddButton={false}
    />
  );
};

DashboardsList.defaultProps = {
  selectedId: '',
};

DashboardsList.propTypes = {
  dashboardList: PropTypes.arrayOf(PropTypes.shape({
    DashboardTypeId: PropTypes.number,
    Code: PropTypes.string,
    Value: PropTypes.string,
  })),
  getDashboardList: PropTypes.func,
  onDashboardSelected: PropTypes.func,
  requestingDashboardList: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
};

export default DashboardsList;
