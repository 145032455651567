import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';

import RawMatDetailsContainer from './_RawMatDetailsContainer';
import RawMatCompositionContainer from './RawMatComposition';
import RawMatDocumentsContainer from './RawMatDocuments';
import RawMatProductList from './RawMatProductList';
import RawMatInformation from './RawMatInformation';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css({
    // marginTop: '25px',
  }),
};

const RawMatDetails = props => {
  const rawMatId = Number(props.params.id);
  // const activeKey = pathname === `/rawmats/${rawMatId}/phrases` ? 'phrases' : 'composition';
  // const activeKey = pathname === `/rawmats/${rawMatId}/phrases` ? 'phrases' : 'composition';
  return (
    <div className={styles.detailsContainer}>
      <RawMatDetailsContainer rawMatId={rawMatId} {...props} />
      <Col sm={12}>
        {/* <Nav bsStyle="tabs">
          <LinkContainer to="rawMats/73595/tab2" active title="tab1 title">
            <NavItem active title="home">NavItem 1 content</NavItem>
          </LinkContainer>
          <NavItem eventKey={2} title="Item">NavItem 2 content</NavItem>
          <NavItem eventKey={3} >NavItem 3 content</NavItem>
        </Nav>

        content below tab */}

        {/* <Tab.Container id="left-tabs-example">
          <div>
            <Row className="clearfix">
              <Col sm={12}>
                <Nav bsStyle="tabs">
                  <LinkContainer to={`rawmats/${rawMatId}/composition`} active={activeKey === 'composition'}>
                    <NavItem>
                      Tab 1
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to={`rawmats/${rawMatId}/phrases`} active={activeKey === 'phrases'}>
                    <NavItem>
                      Tab 2
                    </NavItem>
                  </LinkContainer>
                </Nav>
              </Col>
            </Row>
          </div>
        </Tab.Container>
        <div style={{ paddingTop: '15px' }}>
          {props.children && React.cloneElement(props.children, { rawMatId })}

        </div> */}

        <Tabs id="RawMatTabs">
          <Tab eventKey={1} title={I18n.t('rawMat.composition')}>
            <RawMatCompositionContainer rawMatId={rawMatId} {...props} />
          </Tab>
          <Tab eventKey={2} title={I18n.t('rawMat.phrases')}>
            <RawMatInformation rawMatId={rawMatId} />
          </Tab>
          <Tab eventKey={3} title={I18n.t('rawMat.files')}>
            <RawMatDocumentsContainer rawMatId={rawMatId} {...props} />
          </Tab>
          <Tab eventKey={4} title={I18n.t('rawMat.products')}>
            <RawMatProductList rawMatId={rawMatId} />
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

RawMatDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RawMatDetails;
