import PropTypes from 'prop-types';
import React from 'react';
import {FormControl} from 'react-bootstrap';
import {connect} from 'react-redux';
import {referentialSelectors} from '../../../redux/referentials';
import BaseFormGroup from './BaseFormGroup';

// Receive value in EURO
class PriceWithCurrencyField extends React.PureComponent {
  state = {
    touched: false,
  };

  handleChange = (val) => {
    let finalValue = val.target.value;
    const {currencies, currency} = this.props;
    if (finalValue) {
      const currencyEntity = currencies.find(cur => cur.CodeA === currency);
      const rate = currencyEntity ? currencyEntity.ExchangeRate : 1;
      finalValue = currency ? finalValue / rate : finalValue;
      finalValue = Math.round(finalValue * 100) / 100;
    }
    this.setState({
      touched: true,
    });
    console.log('props', this.props);
    this.props.input.onChange(finalValue);
    if (this.props.onValueChange) {
      console.log('IN IF');
      this.props.onValueChange(finalValue);
    }
  };

  render() {
    const {label, input, placeholder, currencies, currency, readOnly} = this.props;
    const value = input.value;

    const currencyEntity = currencies.find(cur => cur.CodeA === currency);
    const rate = currencyEntity ? currencyEntity.ExchangeRate : 1;
    // eslint-disable-next-line no-nested-ternary
    let finalValue = value ? (currency ? value * rate : value) : value;
    if (finalValue) finalValue = Math.round(finalValue * 100) / 100;

    return (
      <BaseFormGroup {...this.props} meta={{...this.props.meta, touched: this.state.touched}}>
        <FormControl
          type="text"
          componentClass="input"
          bsSize="small"
          onChange={this.handleChange}
          placeholder={placeholder || label}
          value={finalValue}
          readOnly={readOnly}
        />
      </BaseFormGroup>
    );
  }
};

PriceWithCurrencyField.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string,
    }),
  ).isRequired,
  currency: PropTypes.string,
};

const mapStateToProps = state => ({
  currencies: referentialSelectors.getCurrencies(state),
});

export default connect(
  mapStateToProps,
  null,
)(PriceWithCurrencyField);
