import queryString from 'query-string';
import { post, get, del } from './apiUtils';

const defaultUrl = '/Tasks';
const actionUrl = '/Actions';

export default {
  getTasks: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveTask: data => post(defaultUrl, data),
  saveMultipleTasks: data => post(`${defaultUrl}/multiple`, data),
  deleteTask: id => del(`${defaultUrl}/${id}`),
  updateStatus: data => post(`${defaultUrl}/status`, data),
  startTask: id => post(`${defaultUrl}/${id}/start`),
  stopTask: id => post(`${defaultUrl}/${id}/stop`),
  getTaskActionList: query => (query ? get(`${defaultUrl}${actionUrl}?${queryString.stringify(query)}`) : get(`${defaultUrl}${actionUrl}`)),
  saveTaskAction: data => post(`${defaultUrl}${actionUrl}`, data),
};
