import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { errorActions, errorTypes, errorSelectors } from '../../../redux/error';
import ErrorList from './ErrorList';
import { applicationSelectors } from '../../../redux/application';

class ErrorListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return <ErrorList {...this.props} />;
  }
}

const mapStateToProps = state => ({
  errorList: errorSelectors.getErrorList(state),
  isLoading: applicationSelectors.isLoading(state, errorTypes.FETCH_ERROR_LIST.REQUEST),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(errorActions.fetchErrors.request({ limit: 20 }));
  },
  getErrorList: () => dispatch(errorActions.fetchErrors.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorListContainer);

ErrorListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};
