import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';
import OrderProductList from './OrderProductList';
import ButtonDropDown from '../../_Common/ButtonDropDown';
import ProductListDropdown from '../../_Common/Dropdowns/ProductListDropdown';

const OrderProductsTab = props => {
  const { availableProductsForCurrentOrder, addProductToCurrentOrder, isOrderEditable, ...otherProps } = props;
  const productList = availableProductsForCurrentOrder;
  return (
    <div>
      <ButtonDropDown
        options={availableProductsForCurrentOrder}
        labelKey="ProductName"
        valueKey="ProductId"
        buttonText={I18n.t('order.addProduct')}
        onButtonClick={addProductToCurrentOrder}
        disabled={!isOrderEditable}
      />      
      <OrderProductList isAddDisabled={!isOrderEditable} allowDelete={isOrderEditable} {...otherProps} />
    </div>
  );
};

OrderProductsTab.propTypes = {
  availableProductsForCurrentOrder: PropTypes.array.isRequired, //eslint-disable-line
  addProductToCurrentOrder: PropTypes.func.isRequired, //eslint-disable-line
  isOrderEditable: PropTypes.bool.isRequired,
};

export default OrderProductsTab;
