import { schema } from 'normalizr';
import { labelSymbolCorrectiveActionSchemas } from '../labelSymbolCorrectiveAction';

export const labelSymbolSchema = new schema.Entity('labelSymbols', {}, { idAttribute: 'Code' });
export const labelSymbolWithDetailsSchema = new schema.Entity(
  'labelSymbols',
  {
    LabelSymbolCorrectiveAction: labelSymbolCorrectiveActionSchemas.labelSymbolCorrectiveActionSchema,
  },
  { idAttribute: 'Code' },
);
export const labelSymbolListSchema = [labelSymbolSchema];
export const labelSymbolWithDetailsListSchema = [labelSymbolWithDetailsSchema];
