import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { productSelectors, productTypes, productActions } from '../../../../redux/product';
import { CommentModal } from '../../../_Common/Modals';
import { workflowStatusSelectors } from '../../../../redux/workflowStatus';
import { applicationSelectors } from '../../../../redux/application';
import cpsrTypes from '../../../../constants/cpsrTypes';

const CpsrStatusCommentModalContainer = props => <CommentModal {...props} />;

const mapStateToProps = (state, { ProductId, nextStatusCode, CpsrTypeCode }) => {
  const product = productSelectors.getById(state)[ProductId];
  const getStatusNameByCode = R.compose(
    R.propOr('', 'Name'),
    statusCode => workflowStatusSelectors.getById(state)[statusCode],
  );
  const statusCode = CpsrTypeCode === cpsrTypes.CPSRA ? product.CpsrAStatus : product.CpsrBStatus;
  const currentStatusName = getStatusNameByCode(statusCode);
  const nextStatusName = getStatusNameByCode(nextStatusCode);

  return {
    title: I18n.t('CpsrStatusModal.title', { CpsrTypeCode, currentStatusName, nextStatusName }),
    isLoading: applicationSelectors.isLoading(state, productTypes.UPDATE_PRODUCT_CPSR_STATUS.REQUEST),
    label: I18n.t('CpsrStatusModal.label'),
  };
};

const mapDispatchToProps = (dispatch, { ProductId, nextStatusCode, CpsrTypeCode }) => ({
  onConfirm: Comment =>
    dispatch(productActions.updateCPSRStatus.request(ProductId, nextStatusCode, CpsrTypeCode, Comment)),
});

CpsrStatusCommentModalContainer.propTypes = {
  ProductId: PropTypes.number.isRequired,
  nextStatusCode: PropTypes.string.isRequired,
  CpsrTypeCode: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(CpsrStatusCommentModalContainer);
