import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField } from '../../../_Common/Forms';
import { ProcessTypeListDropdown } from '../../../_Common/Dropdowns';
import { orderItemFormSelectors, orderItemFormActions } from '../../../../redux/form/orderItemForm';
import { orderItemActions } from '../../../../redux/orderItem';
import { processTypeSelectors } from '../../../../redux/processType';
import {
  isPositiveOrZeroInteger,
  isPositiveInteger,
  requiredField
} from '../../../../utils/fieldValidation';
import { referentialSelectors } from '../../../../redux/referentials';
import PriceWithCurrencyField from '../../Forms/PriceWithCurrencyField';

const formName = formNames.orderItem;

const OrderItemForm = props => {
  const {
    onSubmit,
    handleSubmit,
    processTypeList,
    onProcessTypeSelected,
    onValueChange,
    isNew,
    currencyCode,
  } = props;

  const handleProcessTypeChange = (e, processTypeId) => {
    onProcessTypeSelected(processTypeId);
  };

  const handleQuantityChange = (e, qty) => {
    if (Number.isInteger(Number(qty))) {
      onValueChange({ Quantity: Number(qty) });
    }
  };

  const handleUnitPriceChange = (unitPrice) => {
    if (!isNaN(unitPrice)) {
      onValueChange({ UnitPrice: Number(unitPrice) });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={8}>
            <Field
              name="ProcessTypeId"
              label={I18n.t('orderProcess.type')}
              component={ProcessTypeListDropdown}
              initialOptions={processTypeList}
              onChange={handleProcessTypeChange}
              validate={requiredField}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="Quantity"
              label={`${I18n.t('orderProcess.quantity')}`}
              component={InputField}
              validate={[isPositiveOrZeroInteger, requiredField]}
              onChange={handleQuantityChange}
              readOnly={!isNew}
            />
          </Col>
          <Col sm={2}>
            <Field
              name="ShadeCount"
              label={`${I18n.t('orderProcess.shadeCount')}`}
              component={InputField}
              validate={[isPositiveOrZeroInteger, requiredField]}
              onChange={handleQuantityChange}
              readOnly={!isNew}
            />
          </Col>
          <Col sm={3}>
            <Field
              name="UnitPrice"
              label={`${I18n.t('taskType.unitPrice')}`}
              component={PriceWithCurrencyField}
              currency={currencyCode}
              validate={[requiredField]}
              onValueChange={handleUnitPriceChange}
            />
          </Col>
          <Col sm={3}>
            <Field
              name="Currency"
              label={`${I18n.t('orderProcess.currency')}`}
              component={InputField}
              readOnly
            />
          </Col>
          <Col sm={3}>
            <Field name="Discount" label={`${I18n.t('orderProcess.discountPercent')}`} component={InputField}
                   readOnly />
          </Col>
          <Col sm={3}>
            <Field
              name="Subtotal"
              label={`${I18n.t('orderProcess.subtotal')}`}
              component={PriceWithCurrencyField}
              currency={currencyCode}
              readOnly
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { orderItemId, orderId }) => {
  const getFormInitialValues = orderItemFormSelectors.makeGetInitialValues(orderItemId, orderId);
  const { currentOrderProcessInfo } = state.order;
  return {
    initialValues: getFormInitialValues(state),
    processTypeList: processTypeSelectors.getProcessTypeList(state),
    isNew: !(orderItemId != null),
    currentOrderProcessInfo,
    currencies: referentialSelectors.getCurrencies(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onSubmit: data => dispatch(orderItemActions.saveOrderItem.request({ ...data, orderId })),
  onProcessTypeSelected: processTypeId => dispatch(orderItemFormActions.onProcessTypeSelected(processTypeId)),
  onValueChange: dataChanged => dispatch(orderItemFormActions.onChangeCheckValues(dataChanged)),
});

OrderItemForm.defaultProps = {
  initialValues: {},
};

OrderItemForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  processTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      ProcessTypeId: PropTypes.number.isRequired,
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(OrderItemForm);
