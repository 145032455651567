import { createRequestTypes } from '../utils';

export const DISTRIBUTOR_LIST = createRequestTypes('distributor/DISTRIBUTOR_LIST');
export const DISTRIBUTOR = createRequestTypes('distributor/DISTRIBUTOR');
export const SAVE_DISTRIBUTOR = createRequestTypes('distributor/SAVE_DISTRIBUTOR');
export const DISTRIBUTOR_PRODUCT_LIST = createRequestTypes('distributor/DISTRIBUTOR_PRODUCT_LIST');
export const DISTRIBUTOR_AGREEMENT_LIST = createRequestTypes('distributor/DISTRIBUTOR_AGREEMENT_LIST');
export const DELETE_DISTRIBUTOR = createRequestTypes('distributor/DELETE_DISTRIBUTOR');

export const NEW_DISTRIBUTOR = 'distributor/NEW_DISTRIBUTOR';
export const PRODUCT_DISTRIBUTOR_LIST = createRequestTypes('distributor/PRODUCT_DISTRIBUTOR_LIST');

