import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cpsrTypes from '../../../constants/cpsrTypes';
import { referentialSelectors } from '../../../redux/referentials';
import { SelectField } from '../Forms';

const CpsrChaptersDropdown = props => {
  const { cpsrChapterList } = props;
  return <SelectField options={cpsrChapterList} labelField="Value" valueField="Key" {...props} />;
};

const mapStateToProps = () => (state, { cpsrTypeCode }) => {
  const getCpsrChapters = referentialSelectors.makeGetCpsrChapters(cpsrTypeCode);
  return {
    cpsrChapterList: getCpsrChapters(state),
  };
};

CpsrChaptersDropdown.propTypes = {
  cpsrTypeCode: PropTypes.oneOf([cpsrTypes.CPSRA, cpsrTypes.CPSRB]).isRequired,
  cpsrChapterList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(CpsrChaptersDropdown);
