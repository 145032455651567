import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { FormControl, InputGroup, DropdownButton, MenuItem } from 'react-bootstrap';
import BaseFormGroup from './BaseFormGroup';

const InputFielWithDropdown = props => {
  const { names, options, labelField, valueField, getDefaultValue } = props;
  const textField = props[names[0]];
  const selectField = props[names[1]];

  const getValue = R.path(['input', 'value']);
  const isEmpty = R.pipe(getValue, R.isEmpty);

  const selectValueToDisplay = R.ifElse(isEmpty, () => getDefaultValue(options), getValue)(selectField);

  const renderButtonDropdown = () => (
    <DropdownButton
      title={selectValueToDisplay}
      bsSize="small"
      componentClass={InputGroup.Button}
      id="input-dropdown-addon"
      onSelect={selectField.input.onChange}
      pullRight
    >
      {options.map(({ [valueField]: Key, [labelField]: Value }) => (
        <MenuItem key={Key} eventKey={Key}>
          {Value}
        </MenuItem>
      ))}
    </DropdownButton>
  );
  return (
    <div>
      <BaseFormGroup {...props} meta={textField.meta}>
        <InputGroup>
          <FormControl {...textField.input} bsSize="small" />
          {renderButtonDropdown()}
        </InputGroup>
      </BaseFormGroup>
    </div>
  );
};

InputFielWithDropdown.defaultProps = {
  labelField: 'Value',
  valueField: 'Key',
  options: [],
  getDefaultValue: () => {},
};

InputFielWithDropdown.propTypes = {
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  getDefaultValue: PropTypes.func,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.array.isRequired, //eslint-disable-line
};

export default InputFielWithDropdown;
