import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  cpsrChaptersActions,
} from '../../../redux/cpsrChapters';
import { phraseActions, phraseTypes, phraseSelectors } from '../../../redux/phrase';
import PhraseList from './PhraseList';
import { applicationSelectors } from '../../../redux/application';
import { referentialSelectors } from '../../../redux/referentials';
import { modalActions } from '../../../redux/modal';
import { Grid, Row, Col } from 'react-bootstrap';
import { css } from 'glamor';

class PhraseListContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    return (
    
    <Grid fluid>
      <div className={style.detailsContainer}>
        <Row>
          <Col md={12}>
            <PhraseList {...this.props} />
          </Col>
        </Row>
        <br/><br/>
      </div>
    </Grid>)
  // <PhraseList {...this.props} />;
  }
}

const mapStateToProps = state => ({
  phraseList: phraseSelectors.getphraseList(state),
  cpsrTypeList: referentialSelectors.getCpsrTypeList(state),
  isLoading: applicationSelectors.isLoading(
    state,
    phraseTypes.PHRASE_LIST.REQUEST,
    phraseTypes.SAVE_PHRASE.REQUEST,
    phraseTypes.DELETE_PHRASE.REQUEST,
  ),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(phraseActions.listPhrases.request());
    dispatch(cpsrChaptersActions.listCpsrChapter.request());
  },
  getPhraseList: () => dispatch(phraseActions.listPhrases.request({ IncludeChaptersCode: true })),
  onAddButtonClick: () => dispatch(modalActions.showPhraseFormModal()),
  onEditButtonClick: ({ PhraseId: phraseId }) => dispatch(modalActions.showPhraseFormModal({ phraseId })),
  savePhrase: data => dispatch(phraseActions.savePhrase.request(data)),
  onDeleteButtonClick: ({ PhraseId }) => dispatch(phraseActions.deletePhrase.request(PhraseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhraseListContainer);

PhraseListContainer.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const style = {
  detailsContainer: css({
    '& .tab-pane': {
      paddingTop: '15px'
    },
  }),
};