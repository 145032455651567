import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { getFormValues } from 'redux-form';
import * as homePageFormActions from './homePageFormActions';
import homepageApi from '../../../../api/homepageApi';
import * as homePageFormTypes from './homePageFormTypes';
import { homepageActions } from '../../../homepage';

export default function* root() {
  yield all([
    takeLatest(homePageFormTypes.LOAD_HOMEPAGE_FORM, onLoadHomePageSaga),
    takeLatest(homePageFormTypes.FETCH_HOMEPAGE_FORM.REQUEST, fetchHomePageFormSaga),
    takeLatest(homePageFormTypes.SAVE_BUTTON_CLIKED, onSaveButtonClickedSaga),
  ]);
}

function* createApiSaga(fn, { onSuccess, onFailure }) {
  try {
    const result = yield fn();
    yield all(onSuccess(result));
  } catch (error) {
    if (error.Message) {
      // const message = error.Message;
      // yield put(applicationActions.setGeneralError(message));
      yield onFailure(error);
    }
  }
}


function* onSaveButtonClickedSaga() {
  yield put(homePageFormActions.saveHomePageForm.request());
  const formValues = yield select(getFormValues('homePageForm'));
  return yield createApiSaga(
    function* saveHomePageForm() {
      return yield call(homepageApi.saveHomepage, formValues);
    },
    {
      onSuccess: data => [
        put(homePageFormActions.saveHomePageForm.success(data)),
        put(homepageActions.mergeHomepages(data)),
      ],
      onFailure: message => put(homePageFormActions.saveHomePageForm.failure(message)),
    },
  );
}


function* fetchHomePageFormSaga(action) {
  return yield createApiSaga(
    function* fetchHomePageForm() {
      return yield call(homepageApi.getHomepage);
    },
    {
      onSuccess: data => [
        put(homePageFormActions.fetchHomePageForm.success(data)),
        put(homepageActions.mergeHomepages(data)),
      ],
      onFailure: message => put(homePageFormActions.fetchHomePageForm.failure(message)),
    },
  );
}

function* onLoadHomePageSaga(action) {
  yield put(homePageFormActions.fetchHomePageForm.request());
}
