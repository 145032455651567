import * as distributorSelectors from './distributorSelectors';
import * as distributorActions from './distributorActions';
import * as distributorTypes from './distributorTypes';
import * as distributorSchemas from './distributorSchemas';
import sagas from './distributorSagas';
import reducer from './distributorReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { distributorSelectors, distributorActions, distributorTypes,distributorSchemas };
export default reducer;
