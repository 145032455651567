import queryString from 'query-string';
import { get, post, del } from './apiUtils';

const defaultUrl = '/TaskTypes/';

export default {
  getTaskTypeList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  getTaskType: id => get(`${defaultUrl}${id}`),
  saveTaskType: data => post(`${defaultUrl}`, data),
  deleteTaskType: id => del(`${defaultUrl}${id}`),
};
