import { createSelector } from 'reselect';
import { productCategorySelectors } from '../../productCategory';

const getInitialValues = productCategoryId => {
  console.log('getInitialValues');
  console.log('productCategorySelectors', productCategorySelectors);
  return createSelector(
    [productCategorySelectors.getById], productCategoryById => {
      const res = productCategoryById[productCategoryId] || {};
      console.log('res', res);
      return res;
    });
}

export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues);
