import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, DateField } from '../../../_Common/Forms';
import { shadeFormSelectors } from '../../../../redux/form/shadeForm';
import { shadeActions } from '../../../../redux/shade';
import { requiredField, maxLength48, maxLength16, maxLength128 } from '../../../../utils/fieldValidation';

const formName = formNames.shade;

const ShadeForm = props => {
  const { onSubmit, handleSubmit } = props;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <Col sm={12}>
            <Field
              name="ShadeCode"
              label={`${I18n.t('shade.ShadeCode')} *`}
              component={InputField}
              validate={[requiredField, maxLength48]}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="ShadeName"
              label={`${I18n.t('shade.ShadeName')}`}
              component={InputField}
              validate={maxLength128}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="NotificationCode"
              label={`${I18n.t('shade.NotificationCode')}`}
              component={InputField}
              validate={maxLength16}
            />
          </Col>
          <Col sm={12}>
            <Field
              name="NotifiedOn"
              label={`${I18n.t('shade.NotifiedOn')}`}
              component={DateField}
              validate={maxLength128}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { shadeId }) => {
  const getFormInitialValues = shadeFormSelectors.makeGetInitialValues(shadeId);
  return {
    initialValues: getFormInitialValues(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => dispatch(shadeActions.saveShade.request({ ...data, productId })),
});

ShadeForm.defaultProps = {
  initialValues: {},
};

ShadeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ShadeForm);
