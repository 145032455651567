import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { SelectionGrid } from '../../../_Common/Grids';
import { productSelectors, productTypes } from '../../../../redux/product';
import { companySelectors } from '../../../../redux/company';
import { applicationSelectors } from '../../../../redux/application';



const ProductList = ({ productList, companyList, height, whenGridReady, isLoading, onRowDoucleClicked }) => {

  const getManufacturer = ({data, colDef}) => {
    const manufacturer = data.ManufacturerId?
      companyList.find(c => c.CompanyId === parseInt(data.ManufacturerId, 10))
      :null;
    return manufacturer?manufacturer.ShortName:''  
  }

  const columns = [
    {
      headerName: I18n.t('general.code'),
      width: 40,
      field: 'Code',
    },
    {
      headerName: I18n.t('general.name'),
      field: 'ProductName',
    },
    {
      headerName: I18n.t('product.manufacturerName'),
      // field: 'ManufacturerId',
      valueGetter: getManufacturer,
      width: 100,
    },
    {
      headerName: I18n.t('product.CPSRAStatus'),
      field: 'CpsrAStatus',
      width: 60,
    },
    {
      headerName: I18n.t('product.CPSRBStatus'),
      field: 'CpsrBStatus',
      width: 60,
    },
  ];

  return (
    <SelectionGrid
      data={productList}
      isLoading={isLoading}
      height={height}
      columns={columns}
      rowDoubleClicked={onRowDoucleClicked}
      keyName="ProductId"
      showAddButton={false}
      whenGridReady={whenGridReady}
    />
  );
};

const mapStateToProps = (state, { productId }) => {
  // const getProductList = productSelectors.makeGetProductListWithoutProductId(productId);
  const getProductList = productSelectors.makeGetProductListWithManufacturerAndCPSR(productId);
  return {
    productList: getProductList(state),
    companyList: companySelectors.getCompanyList(state),
    isLoading: applicationSelectors.isLoading(state, productTypes.PRODUCT_LIST2.REQUEST),
  };
};

ProductList.defaultProps = {
  whenGridReady: () => {},
  height: '60vh',
};

ProductList.propTypes = {
  productList: PropTypes.array.isRequired, //eslint-disable-line
  height: PropTypes.string,
  whenGridReady: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  onRowDoucleClicked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ProductList);
