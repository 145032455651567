import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { submit, isInvalid } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import DraftLabelModal from './DraftLabelModal';
import * as actions from './actions';
import { productSelectors } from '../../../../redux/product';
import { draftLabelTypes } from '../../../../redux/draftLabel';
import { draftLabelFormActions, draftLabelFormTypes } from '../../../../redux/form/draftLabelForm';
import { applicationSelectors } from '../../../../redux/application';
import formNames from '../../../../constants/formNames';

class DraftLabelModalContainer extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId) {
      this.props.onLoad();
    }
  }
  render() {
    return <DraftLabelModal {...this.props} />;
  }
}

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  return {
    title: I18n.t('draftLabelModal.title', { productName }),
    isLoading: applicationSelectors.isLoading(
      state,
      draftLabelTypes.FETCH_DRAFT_LABELS.REQUEST,
      draftLabelTypes.SAVE_DRAFT_LABEL.REQUEST,
      draftLabelFormTypes.LOAD_FORM.REQUEST,
    ),
    isInvalid: isInvalid(formNames.draftLabel)(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onLoad: () => {
    if (productId > 0) {
      dispatch(draftLabelFormActions.loadForm.request(productId));
    }
  },
  onSaveButtonClick: () => dispatch(submit(formNames.draftLabel)),
  onNextButtonClick: currentPage => dispatch(actions.nextButtonClicked(currentPage)),
});

DraftLabelModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftLabelModalContainer);
