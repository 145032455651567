import { takeLatest, put, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import impactAssessmentApi from '../../api/impactAssessmentApi';
import * as impactAssessmentProductTypes from './impactAssessmentProductTypes';
import * as impactAssessmentProductActions from './impactAssessmentProductActions';
import * as impactAssessmentProductSchemas from './impactAssessmentProductSchemas';

export default function* root() {
  yield all([
    takeLatest(LOCATION_CHANGE, onLocationChange),
    takeLatest(
      impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST.REQUEST,
      generateImpactAssessmentCandidateProductListSaga,
    ),
    takeLatest(
      impactAssessmentProductTypes.IMPACT_ASSESSMENT_VALUE_LIST.REQUEST,
      calculateImpactAssessmentValueListSaga,
    ),
    takeLatest(
      impactAssessmentProductTypes.GET_IMPACT_ASSESSMENT_VALUE_LIST.REQUEST,
      getImpactAssessmentValueListSaga,
    ),
    takeLatest(impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST.REQUEST, getImpactAssessmentProductListSaga),
    takeLatest(
      impactAssessmentProductTypes.CONFIRM_IMPACT_ASSESSMENT_PRODUCT.REQUEST,
      confirmImpactAssessmentProductSaga,
    ),
    takeLatest(
      impactAssessmentProductTypes.IGNORE_IMPACT_ASSESSMENT_PRODUCT.REQUEST,
      ignoreImpactAssessmentProductSaga,
    ),
    takeLatest(
      impactAssessmentProductTypes.REVIEW_IMPACT_ASSESSMENT_PRODUCT.REQUEST,
      reviewImpactAssessmentProductSaga,
    ),
  ]);
}

function* onLocationChange(action) {
  const { pathname, action: routeAction } = action.payload;
  if (pathname === '/impactAssessments/-1' && routeAction === 'PUSH') {
    yield put(impactAssessmentProductActions.newImpactAssessmentProduct());
  }
}

// get existing impact assessment products linked to impact assessment
function* getImpactAssessmentProductListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const result = yield callApiSagaAndNormalize(
    impactAssessmentProductActions.listImpactAssessmentProducts,
    impactAssessmentApi.getImpactAssessmentProductList,
    impactAssessmentProductSchemas.impactAssessmentProductListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* calculateImpactAssessmentValueListSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentProductTypes.CALCULATE_IMPACT_ASSESSMENT_VALUE_LIST.REQUEST));
  yield callApiSaga(
    impactAssessmentProductActions.calculateImpactAssessmentValues,
    impactAssessmentApi.calculateImpactAssessmentValue,
    action.payload,
  );
  yield put(impactAssessmentProductActions.listImpactAssessmentProducts.request(action.payload));
  yield put(applicationActions.unsetLoading(impactAssessmentProductTypes.CALCULATE_IMPACT_ASSESSMENT_VALUE_LIST.REQUEST));
}

function* getImpactAssessmentValueListSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentProductTypes.GET_IMPACT_ASSESSMENT_VALUE_LIST.REQUEST));
  yield callApiSaga(
    impactAssessmentProductActions.getImpactAssessmentValues,
    impactAssessmentApi.getImpactAssessmentValueList,
    action.payload,
  );
  // yield put(impactAssessmentProductActions.listImpactAssessmentProducts.request(action.payload));
  yield put(applicationActions.unsetLoading(impactAssessmentProductTypes.GET_IMPACT_ASSESSMENT_VALUE_LIST.REQUEST));
}

function* generateImpactAssessmentCandidateProductListSaga(action) {
  yield put(
    applicationActions.setLoading(impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST.REQUEST),
  );
  const result = yield callApiSaga(
    impactAssessmentProductActions.generateImpactAssessmentCandidateProducts,
    impactAssessmentApi.generateImpactAssessmentCandidateProducts,
    action.payload,
  );
  yield put(impactAssessmentProductActions.listImpactAssessmentProducts.request(action.payload));
  yield put(
    applicationActions.unsetLoading(impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST.REQUEST),
  );
}

function* confirmImpactAssessmentProductSaga({ payload, type }) {
  const { impactAssessmentId, ImpactAssessmentProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(
    impactAssessmentProductActions.confirmImpactAssessmentProduct,
    impactAssessmentApi.confirmImpactAssessmentProduct,
    ImpactAssessmentProductId,
  );
  yield put(impactAssessmentProductActions.listImpactAssessmentProducts.request(impactAssessmentId));
  yield put(applicationActions.unsetLoading(type));
}

function* ignoreImpactAssessmentProductSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentProductTypes.IGNORE_IMPACT_ASSESSMENT_PRODUCT.REQUEST));
  yield callApiSaga(
    impactAssessmentProductActions.ignoreImpactAssessmentProduct,
    impactAssessmentApi.ignoreImpactAssessmentProduct,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(impactAssessmentProductTypes.IGNORE_IMPACT_ASSESSMENT_PRODUCT.REQUEST));
}

function* reviewImpactAssessmentProductSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST.REQUEST));
  yield callApiSaga(
    impactAssessmentProductActions.reviewImpactAssessmentProduct,
    impactAssessmentApi.reviewImpactAssessmentProduct,
    action.payload.ImpactAssessmentProductId,
  );
  yield put(applicationActions.unsetLoading(impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST.REQUEST));
}
