import { formValueSelector } from 'redux-form';
import { toxProfileItemSelectors } from '../../toxProfileItem';
import { toxProfileChapterSelectors } from '../../toxProfileChapter';
import formNames from '../../../constants/formNames';
import { getOr } from 'lodash/fp';

export const detailFormValueSelector = formValueSelector(formNames.toxProfileItemForm);

export const nbSelectedItems = state => getOr(0, 'length', detailFormValueSelector(state, 'selectedIds'));

export const getInitialFormValues = (state, substanceId) => {
  const chapters = toxProfileChapterSelectors.getToxProfileChapterList(state);

  const result = toxProfileItemSelectors
    .makeGetByQuery(state)({ substanceId })
    .filter(item => item.SubstanceId === substanceId)
    .reduce(
      (acc, profileItem) => {
        const chapt = chapters.find(item => item.Code == profileItem.ChapterCode);
        return {
          ...acc,
          [profileItem.ToxProfileItemId]: {
            ...profileItem,
            chapter: chapt != null ? (chapt.ParentChapterCode != null ? chapt.ParentChapterCode : chapt.Code) : '',
            subChapter: chapt != null && chapt.ParentChapterCode != null ? chapt.Code : null,
          },
          allIds: [...acc.allIds, profileItem.ToxProfileItemId],
          visibleIds: [...acc.visibleIds, profileItem.ToxProfileItemId],
        };
      },
      {
        allIds: [],
        selectedIds: [],
        toxProfileChapter: undefined,
        visibleIds: [],
      },
    );

  return result;
};
