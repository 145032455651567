import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { connect } from 'react-redux';
import ProductProportionReOrderModal from './ProductProportionReOrderModal';
import { productSelectors } from '../../../../redux/product';
import {
  productProportionActions,
  productProportionSelectors,
  productProportionTypes
} from '../../../../redux/productProportion';
import { applicationSelectors } from '../../../../redux/application';
import {rawMatSelectors, rawMatTypes} from "../../../../redux/rawMat";

const ProductProportionReOrderModalContainer = props => <ProductProportionReOrderModal {...props} />;

const mapStateToProps = (state, props) => {
  const product = productSelectors.getById(state)[props.productId];
  const productName = R.propOr('', 'ProductName', product);
  const getProductProportions = productProportionSelectors.makeGetProductProportionForProduct(props.productId);
  return {
    title: I18n.t('productProportionReOrderModal.title', { productName }),
    isSaveDisabled: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
    ),
    productProportions: getProductProportions(state),
    rawMats: rawMatSelectors.makeGetRawMatListForProduct(props.productId)(state),
    isLoading: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
      rawMatTypes.RAWMAT_LIST.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSave: data => dispatch(productProportionActions.reOrderProductProportions(productId, data)),
});

ProductProportionReOrderModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductProportionReOrderModalContainer);
