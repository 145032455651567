import { createRequestActions, createFetchAction } from '../utils';
import * as mandateTypes from './mandateTypes';

export const fetchMandates = createFetchAction(mandateTypes.FETCH_MANDATES, 'mandateId');
export const saveMandate = createRequestActions(
  mandateTypes.SAVE_MANDATE,
  (data, query) => data,
  (data, query) => ({
    query,
  }),
);
