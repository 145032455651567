import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as rawMatTypes from './rawMatTypes';
import * as rawMatDocumentTypes from '../rawMatDocument/rawMatDocumentTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';
import { substanceRawMatTypes } from '../substanceRawMat';

export const initialState = {
  currentRawMat: {},
  currentRawMatProportions: [],
  currentRawMatAllowedNewSubstancesInComposition: [],
};

const rawMatReducer = handleActions(
  {
    [rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION.SUCCESS]: (state, { payload }) => ({
      ...state,
      currentRawMatAllowedNewSubstancesInComposition: payload,
    }),
  },
  initialState,
);

const byId = handleActions(
  {
    [combineActions(rawMatTypes.RAWMAT_LIST.SUCCESS, substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE.SUCCESS)]: (
      state,
      { payload },
    ) => merge({}, state, payload.entities.rawMats),
    [rawMatTypes.RAWMAT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.rawMats,
    }),
    [rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.SUCCESS]: (state, { payload, meta: { RawMatId } }) => ({
      ...state,
      [RawMatId]: {
        ...state[RawMatId],
        RawMatDocuments: payload.result,
      },
    }),
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      ...payload.entities.rawMats,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [rawMatTypes.RAWMAT_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(rawMatTypes.RAWMAT.SUCCESS, substanceRawMatTypes.FETCH_RAWMATS_FOR_SUBSTANCE.SUCCESS)]: (
      state,
      { payload },
    ) => [...new Set([...state, ...(Array.isArray(payload.result) ? payload.result : [payload.result])])],
    [combineActions(
      rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS,
    )]: (state, { payload }) => {
      if (payload.entities.rawMats) {
        return [...new Set([...state, ...Object.keys(payload.entities.rawMats).map(Number)])];
      }
      return state;
    },
    [rawMatTypes.NEW_RAWMAT]: state => ({
      ...state,
      currentRawMat: { RawMatId: -1, IsActive: true },
    }),
  },
  [],
);

export default combineReducers({
  rawMatReducer,
  byId,
  list: combineReducers({
    allIds,
  }),
});
