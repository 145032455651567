import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class CustomFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      filterType: 'contains',
    };

    this.valueGetter = this.props.valueGetter;

    this.onChange = this.onChange.bind(this);
  }

  isFilterActive() {
    return this.state.text !== null && this.state.text !== undefined && this.state.text !== '';
  }

  doesFilterPass(params) {
    const text = this.state.text.toLowerCase();
    const value =
      params.data.Substance != null && params.data.Substance.InciName != null
        ? params.data.Substance.InciName.toLowerCase()
        : params.node.parent != null && params.node.parent.data != null && params.node.parent.data.InciName != null
          ? params.node.parent.data.InciName.toLowerCase()
          : '';

    switch (this.state.filterType) {
      case 'equals':
        return value === text;
      case 'notEqual':
        return value !== text;
      case 'startsWith':
        return value.startsWith(text);
      case 'endsWith':
        return value.endsWith(text);
      case 'contains':
        return value.indexOf(text) >= 0;
      case 'notContains':
        return value.indexOf(text) < 0;
      default:
        return false;
    }
  }

  getModel() {
    return { value: this.state.text };
  }

  setModel(model) {
    this.setState({ text: model ? model.value : '' });
  }

  afterGuiAttached(params) {
    this.focus();
  }

  focus() {
    setTimeout(() => {
      const container = ReactDOM.findDOMNode(this.refs.input);
      if (container) {
        container.focus();
      }
    });
  }

  onChange(event) {
    const newValue = event.target.value;
    if (this.state.text !== newValue) {
      this.setState(
        {
          text: newValue,
        },
        () => {
          this.props.filterChangedCallback();
        },
      );
    }
  }

  onChangeDropdown = event => {
    const newValue = event.target.value;
    if (this.state.filterType !== newValue) {
      this.setState(
        {
          filterType: newValue,
        },
        () => {
          this.props.filterChangedCallback();
        },
      );
    }
  };

  render() {
    const style = {
      border: '0px solid',
      width: '160px',
      height: '50px',
      padding: '3px',
    };

    return (
      <div style={style}>
        <select style={{ marginBottom: '4px' }} onChange={this.onChangeDropdown} defaultValue={this.state.filterType}>
          <option value="equals">Equals</option>
          <option value="notEqual">Not equal</option>
          <option value="startsWith">Starts with</option>
          <option value="endsWith">Ends with</option>
          <option value="contains">Contains</option>
          <option value="notContains">Not contains</option>
        </select>
        <input
          style={{ height: '20px', marginBottom: '5px' }}
          ref="input"
          placeholder={'Filter...'}
          value={this.state.text}
          onChange={this.onChange}
          className="form-control"
        />
      </div>
    );
  }
}
