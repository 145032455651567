import * as shadeSelectors from './shadeSelectors';
import * as shadeActions from './shadeActions';
import * as shadeTypes from './shadeTypes';
import * as shadeSchemas from './shadeSchemas';
import sagas, * as shadeSagas from './shadeSagas';
import reducer from './shadeReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { shadeSelectors, shadeActions, shadeTypes, shadeSchemas, shadeSagas };
export default reducer;
