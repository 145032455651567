import { takeLatest, put, select, takeEvery, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { replace, LOCATION_CHANGE } from 'react-router-redux';
import { applicationActions } from '../application';
import { rawMatActionActions } from '../rawMatAction';
import { productActions } from '../product';
import { rawMatProportionActions } from '../rawMatProportion';
import { callApiSaga, callApiSagaAndNormalize, createFetchSaga } from '../sagaUtils';
import { rawMatListSchema } from '../schema';
import * as rawMatSchemas from './rawMatSchemas';
import rawMatApi from '../../api/rawMatApi';
import * as rawMatTypes from './rawMatTypes';
import * as rawMatActions from './rawMatActions';
import { rawMatProportionSchema } from '../../redux/rawMatProportion';

import { modalSelectors, modalTypes, modalActions } from '../modal';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(rawMatTypes.RAWMAT_LIST.REQUEST, getRawMatListSaga),
    takeLatest(rawMatTypes.RAWMAT.REQUEST, getRawMatSaga),
    takeLatest(rawMatTypes.SAVE_RAWMAT.REQUEST, saveRawMatSaga),
    takeLatest(
      rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION.REQUEST,
      getAllowedNewSubstanceListInCompositionSaga,
    ),
    takeLatest(rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST, saveRawMatProportionSaga),
    takeLatest(rawMatTypes.ADD_EDIT_RAWMAT_PROPORTION, onAddEditRawMatProportionSaga),
    takeLatest(rawMatTypes.UPDATE_RAWMAT_STATUS.REQUEST, updateRawMatStatusSaga),
    takeLatest(
      rawMatTypes.CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT.REQUEST,
      createAndLinkRawMatFromProdigumIngredientSaga,
    ),
    takeLatest(rawMatTypes.UPDATE_INCI_QTY.REQUEST, updateInciQtySaga),
  ]);
}

function* onLocationChange(action) {
  if (action.payload.pathname === '/rawMats/-1') {
    yield put(rawMatActions.newRawMat());
  }
}

export function* updateRawMatStatusSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const { WorkflowStatusCode } = payload;
  try {
    const RawMatId = yield call(rawMatApi.updateStatus, payload);
    yield put(rawMatActions.updateStatus.success({ RawMatId, WorkflowStatusCode }));
    const modalType = yield select(modalSelectors.getModalType);
    if (modalType === modalTypes.RAWMAT_STATUS_COMMENT) {
      yield put(modalActions.hideModal());
    }
    if (RawMatId !== undefined) {
      yield put(rawMatActions.rawMat.request(RawMatId));
      yield put(rawMatActionActions.fetchRawMatActions.request({ RawMatId }));
    }
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatActions.updateStatus.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* getRawMatListSaga() {
  yield put(applicationActions.setLoading(rawMatTypes.RAWMAT_LIST.REQUEST));
  yield callApiSagaAndNormalize(rawMatActions.listRawMats, rawMatApi.getRawMatList, rawMatListSchema);
  yield put(applicationActions.unsetLoading(rawMatTypes.RAWMAT_LIST.REQUEST));
}

function* getRawMatSaga(action) {
  yield put(applicationActions.setLoading(rawMatTypes.RAWMAT.REQUEST));
  yield callApiSagaAndNormalize(rawMatActions.rawMat, rawMatApi.getRawMat, rawMatSchemas.rawMatSchema, action.payload);
  yield put(applicationActions.unsetLoading(rawMatTypes.RAWMAT.REQUEST));
}

export function* saveRawMatSaga(action) {
  yield put(applicationActions.setLoading(rawMatTypes.SAVE_RAWMAT.REQUEST));
  try {
    const rawMatId = yield callApiSaga(rawMatActions.saveRawMat, rawMatApi.saveRawMat, action.payload);
    if (rawMatId !== undefined) {
      const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
      yield put(replace({ ...previousLocation, pathname: `/rawMats/${rawMatId}` }));
      yield put(rawMatActions.rawMat.request(rawMatId));
      // yield put(rawMatActions.listRawMats.request());
    }
  } catch (error) {
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(rawMatTypes.SAVE_RAWMAT.REQUEST));
  }
}

function* saveRawMatProportionSaga(action) {
  const { rawMatId } = action.payload;
  yield put(applicationActions.setLoading(rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST));
  const result = yield callApiSaga(rawMatActions.saveRawMatProportion, rawMatApi.saveRawMatProportion, action.payload);
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.RAWMAT_PROPORTION) {
    yield put(modalActions.hideModal());
  }
  if (rawMatId) {
    yield put(
      rawMatProportionActions.fetchRawMatProportions.request({
        rawMatId,
        include: {
          OptIncludeSubstance: true,
          OptIncludeFunctions: true,
        },
      }),
    );
    yield put(rawMatActions.listAllowedNewSubstanceInComposition.request(rawMatId));
  }
  yield put(applicationActions.unsetLoading(rawMatTypes.SAVE_RAWMAT_PROPORTION.REQUEST));
  return result;
}

function* getAllowedNewSubstanceListInCompositionSaga(action) {
  yield put(applicationActions.setLoading(rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION.REQUEST));
  yield callApiSaga(
    rawMatActions.listAllowedNewSubstanceInComposition,
    rawMatApi.getAllowedNewSubstancesInComposition,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION.REQUEST));
}

function* onAddEditRawMatProportionSaga(action) {
  yield put(modalActions.showModal({ modalType: modalTypes.RAWMAT_PROPORTION, modalProps: { ...action.payload } }));
}

function* createAndLinkRawMatFromProdigumIngredientSaga(action) {
  try {
    yield put(applicationActions.setLoading(rawMatTypes.CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT.REQUEST));
    const rawMats = yield call(rawMatApi.createAndLinkRawMatFromProdigumIngredient, action.payload);
    yield put(rawMatActions.createAndLinkRawMatFromProdigumIngredient.success(rawMats));
    yield put(rawMatActions.listRawMats.request());
    const productId = yield select(({ product }) => product.currentProduct.ProductId);
    yield put(productActions.listProductProdigumProductProportions.request(productId));
    yield put(productActions.listProductCosmosIngredientRawMatMatches.request(productId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(rawMatActions.createAndLinkRawMatFromProdigumIngredient.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(rawMatTypes.CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT.REQUEST));
}

const updateInciQtySaga = createFetchSaga(
  function* updateInciQtySaga({ payload, type }) {
    const { RawMatId } = payload;
    const rawMatProportions = yield call(rawMatApi.updateInciQty, RawMatId);
    const normalizedData = normalize(rawMatProportions, rawMatProportionSchema.rawMatProportionListSchema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(rawMatActions.updateInciQty.success(data, payload));
    },
    onFailure: (error, payload) => put(rawMatActions.updateInciQty.failure(error, payload)),
  },
);
