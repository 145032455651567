import { takeLatest, put, all, select } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga } from '../sagaUtils';
import { productActionListSchema } from '../schema';
import { modalActions, modalTypes } from '../modal';
import { productSelectors } from '../product';
import productActionApi from '../../api/productActionApi';
import * as productActionTypes from './productActionTypes';
import * as productActionActions from './productActionActions';

export default function* root() {
  yield all([
    takeLatest(productActionTypes.PRODUCT_ACTION_LIST.REQUEST, getProductActionsSaga),
    takeLatest(productActionTypes.SHOW_PRODUCT_ACTIONS_HISTORY, showProductActionsHistorySaga),
    takeLatest(productActionTypes.SAVE_PRODUCT_ACTION.REQUEST, saveProductActionSaga),
  ]);
}

function* getProductActionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    productActionActions.listProductActions,
    productActionApi.getProductActionList,
    productActionListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* showProductActionsHistorySaga({ payload }) {
  const product = yield select(productSelectors.getCurrentProduct);
  yield put(
    modalActions.showModal({
      modalType: modalTypes.PRODUCT_ACTIONS_HISTORY,
      modalProps: {
        ...payload,
        title: `Product history (${product.ProductName})`,
        modalWidth: '1200px',
      },
    }),
  );
}

function* saveProductActionSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productActionActions.saveProductAction, productActionApi.saveProductAction, payload);
  const { ProductId } = payload;
  yield put(productActionActions.listProductActions.request(ProductId));
  yield put(applicationActions.unsetLoading(type));
}
