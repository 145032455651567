import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import MoreMenu from './MoreMenu';
// import PhraseListContainer from './ErrorList/_PhraseListContainer';

const More = props =>
  (<Grid fluid>
    <Row>
      <Col md={12}>
        <MoreMenu {...props} />
      </Col>
    </Row>
  </Grid>);

export default More;
