import * as impactAssessmentSelectors from './impactAssessmentSelectors';
import * as impactAssessmentActions from './impactAssessmentActions';
import * as impactAssessmentTypes from './impactAssessmentTypes';
import * as impactAssessmentSchemas from './impactAssessmentSchemas';
import sagas, * as impactAssessmentSagas from './impactAssessmentSagas';
import reducer from './impactAssessmentReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  impactAssessmentSelectors,
  impactAssessmentActions,
  impactAssessmentTypes,
  impactAssessmentSchemas,
  impactAssessmentSagas,
};
export default reducer;
