export default {
  PRODUCT: 'PRODUCT',
  CPSRA: 'CPSRA',
  CPSRB: 'CPSRB',
  PRODUCT_COMPO: 'PRODUCT_COMPO',
  ORDER: 'ORDER',
  RAWMAT: 'RAWMAT',
  SUBSTANCE: 'SUBSTANCE',
  AGREEMENT: 'AGREEMENT',
  MANDATE: 'MANDATE',
  CPSRB_CONCLUSION: 'CPSRB_CONCLUSION',
  COMPANY_RP: 'COMPANY_RP'
};
