import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { distributorActions, distributorTypes, distributorSelectors } from '../../redux/distributor';
import { applicationSelectors } from '../../redux/application';
import DistributorList from './DistributorList';

class DistributorPageContainer extends Component {
  static propTypes = {
    getDistributorList: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    // getCompanyList: PropTypes.func.isRequired,
    // getProductList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getDistributorList();
    // this.props.getCompanyList();
    // this.props.getProductList();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <DistributorList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  distributorList: distributorSelectors.getDistributorList(state),
  // currentDistributor,
  // companyList,
  // productList,
  requestingDistributorList: applicationSelectors.isLoading(state, distributorTypes.DISTRIBUTOR_LIST.REQUEST),
  // isLoadingDetails: applicationSelectors.isLoading(
  //   state,
  //   distributorTypes.SAVE_DISTRIBUTOR.REQUEST,
  //   distributorTypes.DISTRIBUTOR.REQUEST,
  // ),
});

export default connect(mapStateToProps, {
  getDistributorList: distributorActions.listDistributors.request,
  onRefreshList: distributorActions.listDistributors.request,
  // getCompanyList: companyActions.listCompanies.request,
  // getProductList: productActions.listProducts.request,
  onDistributorSelected: id => push(`/distributors/${id}`),
  onAddDistributor: () => push('distributors/-1'),
})(DistributorPageContainer);
