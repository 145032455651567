import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import StepComponent from '../_Common/StepComponent';
import { orderSelectors } from '../../redux/order';
import { orderFormSelectors } from '../../redux/form/orderForm';
import { workflowStatusSelectors } from '../../redux/workflowStatus';
import workflowStatus from '../../constants/workflowStatus';
import { modalActions } from '../../redux/modal';

const OrderStatus = props => {
  const { workflowStatusList, statusCode, isNew, onChange, onHistoryClick } = props;
  return (
    <StepComponent
      options={workflowStatusList}
      value={statusCode}
      onChange={onChange}
      disabled={isNew}
      onHistoryClick={onHistoryClick}
    />
  );
};

const mapStateToProps = (state, { orderId }) => {
  const order = orderSelectors.getCurrentOrder(state);
  const getWorkflowStatusList = workflowStatusSelectors.makeGetWorkflowStatusListByCode('ORDER');
  return {
    statusCode: R.defaultTo(workflowStatus.ORDER_PROPOSED)(R.prop('Status', order)),
    workflowStatusList: getWorkflowStatusList(state),
    isNew: orderFormSelectors.isNew(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onChange: ({ Code }) => dispatch(modalActions.showOrderStatusCommentModal({ nextStatusCode: Code, orderId })),
  onHistoryClick: () => dispatch(modalActions.showOrderActionsModal({ orderId })),
});

OrderStatus.propTypes = {
  workflowStatusList: PropTypes.array.isRequired, //eslint-disable-line
  statusCode: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onHistoryClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);
