import { get, post, del } from './apiUtils';

const defaultUrl = '/Orders/';
const missingDocuments = '/MissingDocuments/';
const productsUrl = '/Products/';
const producersUrl = '/Companies/';
const processInfoUrl = '/ProcessInfo/';
const pollingUrl = '/CompaniesDocuments';
const orderImportUrl = '/Orders/OrderImports/';

export default {
  getOrderProducerList: () => get(producersUrl),
  getOrderProductList: id => get(`${defaultUrl}${id}${productsUrl}`),
  getOrderList: () => get(defaultUrl),
  getOrderImportList: () => get(orderImportUrl),
  getOrder: id => get(`${defaultUrl}${id}`),
  getOrderMissingDocumentList: id => get(`${defaultUrl}${id}${missingDocuments}`),
  getOrderProcessInfo: id => get(`${defaultUrl}${id}${processInfoUrl}`),
  saveOrder: data => post(defaultUrl, data),
  deleteOrder: id => {
    del(`${defaultUrl}${id}`)
  },
  saveOrderProcessInfo: data => post(`${defaultUrl}${processInfoUrl}`, data),
  updateStatus: data => post(`${defaultUrl}/status`, data),
  getProductsOwnedByOrderCompany: id => get(`${defaultUrl}${id}/ProductsOwnedByOrderCompany/`),
  initializePolling: () => {
    get(`${pollingUrl}/InitializeLogisticsFTP`)
  },
  poll: () => {
    get(`${pollingUrl}/PollLogisticsFTP`)
  },
  goToHangfire: () => get(`/hangfire`)
};
