import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { DateCellRenderer, SelectionGrid } from '../../_Common/Grids';
import { EditButton, DeleteButton } from '../../_Common/Grids';

const ActionButtonsRedender = props => {
  const { onEdit, onDelete, onGenerateComplianceCertificate, onReniewComplianceCertificate, data, isEditLoading, isDeleteLoading } = props;
  const handleEditClick = () => onEdit(data);
  const handleDeleteClick = () => onDelete(data);

  return (
    <div>
      <ButtonToolbar>
        {/* <EditButtonRenderer onClick={onEdit} isLoading={isEditLoading} {...props} /> */}
        <EditButton onClick={handleEditClick} isLoading={isEditLoading} {...props} />
        <Button variant="primary" type="button" bsSize="xsmall" onClick={() => {
          onGenerateComplianceCertificate(data)}
         } >
          <Translate value="notification.printCertificate" />
        </Button>
        <Button variant="info" type="button" bsSize="xsmall" onClick={() => {
          onReniewComplianceCertificate(data)}
          } >
          <Translate value="notification.reniewCertificate" />
        </Button>
        <DeleteButton onClick={handleDeleteClick} isLoading={isDeleteLoading} {...props}/>
      </ButtonToolbar>
    </div>
  );
};

ActionButtonsRedender.propTypes = {
  onEdit: PropTypes.func.isRequired,
  isEditLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired, //eslint-disable-line
};

const getYN = ({ data, colDef }) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const NotificationList = props => {
  const {
    notificationList,
    getNotificationListForProduct,
    onNewNotificationClick,
    onEditNotification,
    onGenerateComplianceCertificate,
    onReniewComplianceCertificate,
    onDeleteNotification,
    isLoading,
    isEditLoading,
  } = props;

  const columns = [
    {
      headerName: I18n.t('notification.createdOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: true,
      },
      width: 135,
      suppressSizeToFit: true,
      sort: 'desc'
    },
    {
      headerName: I18n.t('notification.region'),
      field: 'RegionCode',
      width: 75,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.country'),
      field: 'CountryCode',
      width: 70,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.responsibleCompany'),
      field: 'ResponsibleCompany.FullName',
      minWidth: 150,
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.isNotified'),
      field: 'IsNotified',
      valueGetter: getYN,
      width: 80,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.notifiedOn'),
      field: 'NotifiedOn',
      cellRenderer: DateCellRenderer,
      width: 90,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.notificationCode'),
      field: 'NotificationCode',
      width: 100,
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.reserveCode1'),
      field: 'ReserveCode1',
      width: 100,
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.reserveCode2'),
      field: 'ReserveCode2',
      width: 100,
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.expiresOn'),
      field: 'ExpiresOn',
      cellRenderer: DateCellRenderer,
      width: 90,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('notification.actions'),
      width: 180,
      minWidth: 180,
      // suppressSizeToFit: true,
      cellRendererFramework: ActionButtonsRedender,
      cellRendererParams: {
        onEdit: onEditNotification,
        onDelete: onDeleteNotification,
        onGenerateComplianceCertificate: onGenerateComplianceCertificate,
        onReniewComplianceCertificate: onReniewComplianceCertificate,
        isEditLoading: isEditLoading,
      },
    },
  ];

  return (
    <SelectionGrid
      data={notificationList}
      onRefresh={getNotificationListForProduct}
      isLoading={isLoading}
      columns={columns}
      keyName="NotificationId"
      key={Date.now()}
      showAddButton={true}
      onAdd={onNewNotificationClick}
    />
  );
};

NotificationList.propTypes = {
  notificationList: PropTypes.array.isRequired, //eslint-disable-line
  getNotificationListForProduct: PropTypes.func.isRequired,
  onNewNotificationClick: PropTypes.func.isRequired,
  onEditNotification: PropTypes.func.isRequired,
  onDeleteNotification: PropTypes.func.isRequired,
  onGenerateComplianceCertificate: PropTypes.func.isRequired,
  onReniewComplianceCertificate: PropTypes.func.isRequired,
  isLoadingNotificationList: PropTypes.bool.isRequired,
};

export default NotificationList;
