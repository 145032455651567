import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { referentialSelectors } from '../../../redux/referentials';
import { SelectField } from '../Forms';

const CosAnnexeTypesDropdown = props => {
  const { cosAnnexeTypeList } = props;
  return <SelectField options={cosAnnexeTypeList} labelField="Description" valueField="Code" {...props} />;
};

const mapStateToProps = state => ({
  cosAnnexeTypeList: referentialSelectors.getCosAnnexeTypes(state),
});

CosAnnexeTypesDropdown.propTypes = {
  cosAnnexeTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(CosAnnexeTypesDropdown);
