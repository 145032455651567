import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Grid, Row, Col, Button} from 'react-bootstrap';
import {GeneralGrid, LinkCellRenderer} from '../../_Common/Grids';
import SelectionGrid from "../../_Common/Grids/SelectionGrid";
import config from '../../../config';

class OrderImportList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {getOrderImportList} = this.props;
    getOrderImportList();
  }

  render() {
    const {
      isLoading,
      orderImportList,
      onInitializePolling,
      onPoll,
      onGoToHangfire,
      getOrderImportList,
    } = this.props;

    const columns = [
      {
        headerName: I18n.t('orderImport.order'),
        field: 'OrderImportId',
        width: 50,
        sort:'desc',
      },
      {
        headerName: I18n.t('orderImport.createdOn'),
        field: 'MainLastModifiedOn',
        width: 80,
      },
      {
        headerName: I18n.t('orderImport.mainException'),
        field: 'MainException',
        width: 160,
      },
      {
        headerName: I18n.t('orderImport.subException'),
        field: 'Exception',
        width: 200,
      },
      {
        headerName: I18n.t('orderImport.exceptionData'),
        field: 'Data',
        width: 200,
      },
      // {
      //   headerName: I18n.t('orderImport.exception'),
      //   field: 'Exception',
      // },
      {
        headerName: I18n.t('orderImport.fileName'),
        field: 'Document.FileName',
        cellRendererFramework: LinkCellRenderer,
        cellRendererParams: {
          textKey: 'Document.FileName',
          linkKey: 'Document.FileLink',
        },
      },
      {
        headerName: I18n.t('orderImport.description'),
        field: 'Document.Description',
        width: 100,
      },
    ];

    const getNodeChildDetails = rowItem => {
      if (rowItem.OrderImportExceptionList && rowItem.OrderImportExceptionList.length > 0) {
        return {
          group: true,
          expanded: true,
          children: rowItem.OrderImportExceptionList,
          key: rowItem.OrderImportId,
        };
      }
      return null;
    };

    const buildGridData = () => {
      return this.props.orderImportList.map((elem) => ({
        OrderImportId: elem.OrderImportId,
        MainException: elem.Exception,
        MainLastModifiedOn: elem.LastModifiedOn,
        Document: elem.Document,
        OrderImportExceptionList: elem.OrderImportExceptionList
      }));
    };

    return (
      <Grid fluid>
        {/* <Row>
          <Col sm={1}>
            <Button
              bsSize="small"
              bsStyle="default"
              type="button"
              onClick={onInitializePolling}
            >
              <i className="fa fa-files-o"/>
              &nbsp;
              <Translate value="orderImport.initializePolling"/>
            </Button>
            <Button
              bsSize="small"
              bsStyle="default"
              type="button"
              onClick={onPoll}
            >
              <i className="fa fa-files-o"/>
              &nbsp;
              <Translate value="orderImport.poll"/>
            </Button>
            <Button
              bsSize="small"
              bsStyle="default"
              type="button"
              onClick={() => {
                window.location.href = config.baseUrl + '/hangfire';
                return null;
              }
              }
              // onClick={onGoToHangfire}
            >
              <i className="fa fa-files-o"/>
              &nbsp;
              hangfire
            </Button>
          </Col>
        </Row> */}
        <Row>
          <Col sm={12}>
            <SelectionGrid
              data={buildGridData()}
              columns={columns}
              keyName="OrderImportId"
              isLoading={isLoading}
              rowHeight={30}
              onRefresh={getOrderImportList}
              deltaRowDataMode={false}
              getNodeChildDetails={getNodeChildDetails}
              />
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default connect(
  null,
  null,
)(OrderImportList);

OrderImportList.propTypes = {
  orderImportList: PropTypes.arrayOf(
    PropTypes.shape({
      OrderDocumentId: PropTypes.number.isRequired,
      Document: PropTypes.shape({
        DocumentId: PropTypes.number.isRequired,
        FileName: PropTypes.string.isRequired,
        FileLink: PropTypes.string.isRequired,
        Description: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getOrderImportList: PropTypes.func.isRequired,
  onInitializePolling: PropTypes.func.isRequired,
  onPoll: PropTypes.func.isRequired,
  onGoToHangfire: PropTypes.func.isRequired,
};
