import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import {Row, Col, Panel, Checkbox, FormGroup, ControlLabel} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import cpsrTypes from '../../../constants/cpsrTypes';
import { ButtonField, EditableLabelField } from '../../_Common/Forms';
import { requiredField, isInteger } from '../../../utils/fieldValidation';
import PhraseTableItem from './PhraseTableItem';
import CustomSimpleField from "../../_Common/Forms/Custom/CustomSimpleField";
import CpsrChaptersDropdownCustom from "../../_Common/Forms/Custom/CpsrChaptersDropdownCustom";
import CpsrPhraseStylesDropdownCustom from "../../_Common/Forms/Custom/CpsrPhraseStylesDropdownCustom";
import Select from "react-select";
import regionCode from "../../../constants/regionCode";

const valueRenderer = opt => `${opt.Order}. ${opt.Value}`;
const optionRenderer = opt => `${opt.Order}. ${opt.Value}`;

const PhraseItemForm = props => {
  const {
    productPhraseId,
    resetPlaceholder,
    onSelectProductPhrase,
    isSelected,
    productName,
    onEditProductPhrase,
    isCpsrXEditable,
    cpsrTypeCode,
    formValues,
    change,
    regions,
    countries,
  } = props;

  const handleSelectProductPhrase = ({ target }) =>
    onSelectProductPhrase({ id: productPhraseId, selected: target.checked });

  const onResetButtonClick = () => resetPlaceholder(productPhraseId);

  const onEditButtonClick = () => {
    onEditProductPhrase(productPhraseId);
  }

  const placheHolders = [
    { value: /(\$\$V1\$\$)/g, name: `${productPhraseId}.V1` },
    { value: /(\$\$Column1\$\$)/g, name: `${productPhraseId}.V1` },
    { value: /(\$\$V2\$\$)/g, name: `${productPhraseId}.V2` },
    { value: /(\$\$Column2\$\$)/g, name: `${productPhraseId}.V2` },
    { value: /(\$\$V3\$\$)/g, name: `${productPhraseId}.V3` },
    { value: /(\$\$Column3\$\$)/g, name: `${productPhraseId}.V3` },
    { value: /(\$\$V4\$\$)/g, name: `${productPhraseId}.V4` },
    { value: /(\$\$Column4\$\$)/g, name: `${productPhraseId}.V4` },
    { value: /(\$\$V5\$\$)/g, name: `${productPhraseId}.V5` },
    { value: /(\$\$Column5\$\$)/g, name: `${productPhraseId}.V5` },
    { value: /(\$\$V6\$\$)/g, name: `${productPhraseId}.V6` },
    { value: /(\$\$Column6\$\$)/g, name: `${productPhraseId}.V6` },
    { value: /(\$\$V7\$\$)/g, name: `${productPhraseId}.V7` },
    { value: /(\$\$Column7\$\$)/g, name: `${productPhraseId}.V7` },
    { value: /(\$\$TM\$\$)/g, name: `${productPhraseId}.TM`, initialValue: productName },
    { value: /(\$\$Column8\$\$)/g, name: `${productPhraseId}.TM` },
  ];

  const isNew = !Number.isInteger(productPhraseId);

  return (
    <Panel bsStyle="primary">
      <Row>
        <Col sm={1}>
          <Col sm={3}>
            <FormGroup>
              <ControlLabel />
              <Checkbox checked={isSelected} onChange={handleSelectProductPhrase} disabled={!isCpsrXEditable} />
            </FormGroup>
          </Col>
          <Col sm={9}>
            <CustomSimpleField
              name={`${productPhraseId}.OrderNr`}
              value={formValues[productPhraseId].OrderNr}
              label={I18n.t('productPhraseForm.OrderNr')}
              onChange={(newValue) => change(`${productPhraseId}.OrderNr`, newValue)}
              validate={isInteger}
              readOnly={!isCpsrXEditable}
              component={'input'}
            />
          </Col>
        </Col>
        <Col sm={3}>
          <CpsrChaptersDropdownCustom
            name={`${productPhraseId}.CpsrChapterCode`}
            label={I18n.t('productPhraseForm.CpsrChapterCode')}
            readOnly={!isCpsrXEditable}
            validate={requiredField}
            value={formValues[productPhraseId].CpsrChapterCode}
            onChange={(newValue) => change(`${productPhraseId}.CpsrChapterCode`, newValue)}
            cpsrTypeCode={cpsrTypeCode}
            valueRenderer={valueRenderer}
            optionRenderer={optionRenderer}
            isNew={isNew}
          />
        </Col>
        <Col sm={3}>
          <CpsrPhraseStylesDropdownCustom
            name={`${productPhraseId}.CpsrPhraseStyleId`}
            label={I18n.t('productPhraseForm.CpsrPhraseStyleId')}
            validate={requiredField}
            readOnly={!isCpsrXEditable}
            value={formValues[productPhraseId].CpsrPhraseStyleId}
            onChange={(newValue) => change(`${productPhraseId}.CpsrPhraseStyleId`, newValue)}
            isNew={isNew}
          />
        </Col>
        <Col xs={2}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.region')}</ControlLabel>
            <Select
              options={regions}
              labelKey="Value"
              valueKey="Key"
              value={formValues[productPhraseId].RegionCode}
              onChange={(newValue) => change(`${productPhraseId}.RegionCode`, newValue ? newValue.Key : null)}
              clearable
              disabled={!isCpsrXEditable}
            />
          </FormGroup>
        </Col>
        <Col xs={2}>
          <FormGroup bsSize="small">
            <ControlLabel>{I18n.t('general.country')}</ControlLabel>
            <Select
              options={formValues[productPhraseId].RegionCode ? countries.filter(elem => elem.RegionCode === formValues[productPhraseId].RegionCode) : countries}
              labelKey="Value"
              valueKey="Key"
              value={formValues[productPhraseId].CountryAlpha2}
              onChange={(newValue) => change(`${productPhraseId}.CountryAlpha2`, newValue ? newValue.Key : null)}
              clearable="true"
              disabled={!isCpsrXEditable || formValues[productPhraseId].RegionCode !== regionCode.ASEAN}
            />
          </FormGroup>
        </Col>
        <Col sm={1}>
          <ButtonField onClick={onResetButtonClick} disabled={!isCpsrXEditable}>
            <Translate value="general.reset" />
          </ButtonField>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <Col sm={2}>
            <ButtonField bsStyle="info" onClick={onEditButtonClick} disabled={!isCpsrXEditable}>
              <i className="fa fa-pencil-square-o" />
              &nbsp;
              <Translate value="general.edit" />
            </ButtonField>
          </Col>
          <Col sm={8} smOffset={2}>
            <CustomSimpleField
              name={`${productPhraseId}.Phrase.PhraseCode`}
              label={I18n.t('productPhraseForm.PhraseCode')}
              value={formValues[productPhraseId].Phrase.PhraseCode}
              component={'input'}
              readOnly
            />
          </Col>
        </Col>
        <Col sm={8}>
          <FormGroup>
            <ControlLabel />
            <Field
              component={EditableLabelField}
              name={`${productPhraseId}.Phrase.Text`}
              placeholders={placheHolders}
              readOnly={!isCpsrXEditable}
            />
          </FormGroup>
        </Col>
      </Row>
    </Panel>
  );
};

PhraseItemForm.propTypes = {
  productPhraseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  resetPlaceholder: PropTypes.func.isRequired,
  cpsrTypeCode: PropTypes.oneOf([cpsrTypes.CPSRA, cpsrTypes.CPSRB]).isRequired,
  CpsrChapters: PropTypes.array.isRequired, //eslint-disable-line
  CpsrPhraseStyleList: PropTypes.array.isRequired, //eslint-disable-line
  onSelectProductPhrase: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  productName: PropTypes.string.isRequired,
  onEditProductPhrase: PropTypes.func.isRequired,
  isCpsrXEditable: PropTypes.bool.isRequired,
};

const PhraseInstanceForm = props => {
  const { formValues, ...otherProps } = props;
  const isTablePhrase = phrase => phrase.PhraseCode.startsWith('TB');

  return (
    <div>
      <Row>
        {formValues &&
          formValues.visibleIds &&
          formValues.visibleIds.length > 0 &&
          formValues.visibleIds.map(productPhraseId => {
            const productPhrase = formValues[productPhraseId];
            const isSelected = formValues.selectedIds.includes(productPhraseId);
            return (
              <div key={productPhraseId}>
                {isTablePhrase(productPhrase.Phrase) ? (
                  <PhraseTableItem productPhraseId={productPhraseId} isSelected={isSelected} {...otherProps} formValues={formValues} />
                ) : (
                  <PhraseItemForm productPhraseId={productPhraseId} isSelected={isSelected} {...otherProps} formValues={formValues} />
                )}
              </div>
            );
          })}
      </Row>
    </div>
  );
};
export default PhraseInstanceForm;

PhraseInstanceForm.propTypes = {
  formValues: PropTypes.object, //eslint-disable-line
};
