import { createSelector } from 'reselect';
import * as R from 'ramda';
import { draftLabelSelectors } from '../../draftLabel';
import { productSelectors } from '../../product';
import { companySelectors } from '../../company';
import { referentialSelectors } from '../../referentials';

const getDisplayedAddress = ({ AddressLine1, AddressLine2, City, PostalCode, Location: loc, Country, Company }) =>
  R.join('', [
    Company && Company.FullName && `${Company.FullName}`,
    AddressLine1 && `\n${AddressLine1}`,
    AddressLine2 && `\n${AddressLine2}`,
    City && `\n${City}`,
    PostalCode && ` - ${PostalCode}`,
    loc && `\n${loc}`,
    Country && Country.Value && `\n${Country.Value}`,
  ]);

const getInitialValues = productId =>
  createSelector(
    [
      draftLabelSelectors.getDraftLabelForProduct(productId),
      productSelectors.getById,
      companySelectors.getById,
      referentialSelectors.getCountryList,
    ],
    (draftLabel, productById, companyById, countryList) => {
      const product = productById[productId];
      const responsibleCompanyId = R.propOr(null, 'ResponsibleCompanyId', product);
      const company = responsibleCompanyId ? companyById[responsibleCompanyId] : null;
      const address = R.propOr({}, 'Address', company);

      const country = address && address.CountryCode ? countryList.find(c => c.Key === address.CountryCode) : {};

      const companyText = company ? getDisplayedAddress({ ...address, Country: country, Company: company }) : '';
      return (
        draftLabel || {
          ProductName: R.propOr(null, 'ProductName', product),
          PAOHourglassTypeCode: 'PAO',
          CompanyInnerText: companyText,
          CompanyOuterText: companyText,
          CompanyLeafletText: companyText,
        }
      );
    },
  );

export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues || {});
