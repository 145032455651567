import { createRequestActions } from '../utils';
import * as safecosmeticDashboardTypes from './safecosmeticDashboardTypes';

export const listProducts = createRequestActions(
  safecosmeticDashboardTypes.SAFECOSMETIC_DASHBOARD_LIST,
  ({ productId: id, ...query } = {}) => ({
    ...(id && { id }),
    ...(Object.keys(query).length > 0 && { query }),
  }),
  (result, data) => data,
);

export const showOnSafecosmetic = createRequestActions(safecosmeticDashboardTypes.SHOW, prodigumProductId => ({
  prodigumProductId,
}));

export const showOnCosmos = createRequestActions(safecosmeticDashboardTypes.SHOW_COSMOS, prodigumProductId => ({
  prodigumProductId,
}));
