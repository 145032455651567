import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as orderActionTypes from './orderActionTypes';

const byId = handleActions(
  {
    [orderActionTypes.FETCH_ORDER_ACTIONS.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.orderActions),
  },
  {},
);

const allIds = handleActions(
  {
    [orderActionTypes.FETCH_ORDER_ACTIONS.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
