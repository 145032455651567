import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Grid, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { css } from 'glamor';
import { Translate } from 'react-redux-i18n';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
  form: css({
    '& textarea.form-control': {
      height: '100px',
    },
  }),
};

class StatCriteriaPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: {
        StartDate: {},
        Enddate: {},
      },
    };
  }

  componentDidMount() {
    this.onClear();
  }

  onClear() {
    this.setState({
      searchCriteria: {
        StartDate: {},
        EndDate: {},
      },
    });
  }

  loadRawMats(event) {
    // alert('load rawmats ' + event.target.value)
    if (event.target.value.length > 0) {
      this.setState({ rawMat: event.target.value });
    }
  }

  render() {
    const { onSearch } = this.props;
    return (
      <div className={styles.form}>
        <Form onSubmit={() => alert('test')}>
          <FormGroup controlId="Product_Filter">
            <Grid fluid>
              <Row>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="dates.startDate" />
                  </ControlLabel>
                  <FormControl
                    type="date"
                    bsSize="small"
                    value={this.state.searchCriteria.StartDate}
                    onChange={event => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          StartDate: event.target.value,
                        },
                      });
                    }}
                  />
                  {/* <Field
                    name="StartDate"
                    label={I18n.t('dates.startDate')}
                    component={DateField}
                    validate={isValidDate}
                  /> */}
                </Col>
                <Col sm={1} />
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.yesterday" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.lastWeek" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.lastMonth" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.lastYear" />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <ControlLabel>
                    <Translate value="dates.endDate" />
                  </ControlLabel>
                  <FormControl
                    type="date"
                    bsSize="small"
                    value={this.state.searchCriteria.EndDate}
                    onChange={event => {
                      this.setState({
                        searchCriteria: {
                          ...this.state.searchCriteria,
                          EndDate: event.target.value,
                        },
                      });
                    }}
                  />
                  {/* <Field
                    name="EndDate"
                    label={I18n.t('dates.endDate')}
                    component={DateField}
                    validate={isValidDate}
                  /> */}
                </Col>
                <Col sm={1} />
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.today" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.thisWeek" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.thisMonth" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.thisYear" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <br />
                  <Button bsStyle="primary" bsSize="small" onClick={this.onClear}>
                    <Translate value="dates.yearToDate" />
                  </Button>
                </Col>
              </Row>
              <div>&nbsp;</div>
              <Row>
                <Col sm={5}>&nbsp;</Col>
                <Col sm={1}>
                  <Button bsStyle="danger" bsSize="small" onClick={this.onClear}>
                    <Translate value="generalDashboard.clearCriteria" />
                    &nbsp;
                    <i className="fa fa-search" aria-hidden="true" />
                  </Button>
                </Col>
                <Col sm={1}>
                  <Button bsStyle="success" bsSize="small" onClick={() => onSearch(this.state.searchCriteria)}>
                    <Translate value="generalDashboard.search" />
                    &nbsp;
                    <i className="fa fa-search" aria-hidden="true" />
                  </Button>
                </Col>
              </Row>
            </Grid>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

StatCriteriaPanel.defaultProps = {
  companyList: [],
  actionList: [],
  stateList: [],
  salesRepList: [],
  expertList: [],
  accountManagerList: [],
  rawMatList: [],
};

StatCriteriaPanel.propTypes = {
  onSearch: PropTypes.func.isRequired,
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  actionList: PropTypes.array.isRequired, //eslint-disable-line
  stateList: PropTypes.array.isRequired, //eslint-disable-line
  salesRepList: PropTypes.array.isRequired, //eslint-disable-line
  expertList: PropTypes.array.isRequired, //eslint-disable-line
  accountManagerList: PropTypes.array.isRequired, //eslint-disable-line
  rawMatList: PropTypes.array.isRequired, //eslint-disable-line
};

export default StatCriteriaPanel;
