import { takeLatest, put, all, call, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { createFetchSaga } from '../sagaUtils';
import substanceCosAnnexeApi from '../../api/substanceCosAnnexeApi';
import substanceApi from '../../api/substanceApi';
import * as substanceCosAnnexeTypes from './substanceCosAnnexeTypes';
import * as substanceCosAnnexeSchemas from './substanceCosAnnexeSchemas';
import * as substanceCosAnnexeActions from './substanceCosAnnexeActions';

export default function* root() {
  yield all([
    takeLatest(substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES.REQUEST, fetchSubstanceCosAnnexesSaga),
    takeLatest(substanceCosAnnexeTypes.SAVE_SUBSTANCE_COS_ANNEXE.REQUEST, saveSubstanceCosAnnexeSaga),
    takeLatest(substanceCosAnnexeTypes.DELETE_SUBSTANCE_COS_ANNEXE.REQUEST, deleteSubstanceCosAnnexeSaga),
    takeEvery(
      substanceCosAnnexeTypes.FETCH_SUBSTANCE_COS_ANNEXES_FOR_SUBSTANCE.REQUEST,
      getSubstanceCosAnnexesForSubstanceSaga,
    ),
  ]);
}

export function* deleteSubstanceCosAnnexeSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  try {
    const { id } = payload;
    yield call(substanceCosAnnexeApi.deleteSubstanceCosAnnexe, id);
    yield put(substanceCosAnnexeActions.deleteSubstanceCosAnnexe.success(id, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceCosAnnexeActions.deleteSubstanceCosAnnexe.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* saveSubstanceCosAnnexeSaga({ type, payload, meta }) {
  yield put(applicationActions.setLoading(type));
  try {
    const substanceCosAnnexes = yield call(substanceCosAnnexeApi.saveSubstanceCosAnnexe, payload);
    const schema = Array.isArray(substanceCosAnnexes)
      ? substanceCosAnnexeSchemas.substanceCosAnnexeListSchema
      : substanceCosAnnexeSchemas.substanceCosAnnexeSchema;
    const normalizedData = normalize(substanceCosAnnexes, schema);
    yield put(substanceCosAnnexeActions.saveSubstanceCosAnnexe.success(normalizedData, meta));
    // const result = yield callApiSagaAndNormalize(
    //   substanceCosAnnexeActions.saveSubstanceCosAnnexe,
    //   substanceCosAnnexeApi.saveSubstanceCosAnnexe,
    //   substanceCosAnnexeSchemas.substanceCosAnnexeSchema,
    //   payload,
    // );
    // return result;
  } catch (error) {
    yield put(substanceCosAnnexeActions.saveSubstanceCosAnnexe.failure());
    throw error;
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* getSubstanceCosAnnexesForSubstanceSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const {
    query: { substanceId },
  } = payload;
  try {
    const substanceCosAnnexeList = yield call(substanceApi.getSubstanceCosAnnexesForSubstance, substanceId);
    const normalizedData = normalize(substanceCosAnnexeList, substanceCosAnnexeSchemas.substanceCosAnnexeListSchema);
    yield put(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

export const fetchSubstanceCosAnnexesSaga = createFetchSaga(
  function* fetchShadeSagas({ payload, type }) {
    const substanceCosAnnexes = yield call(substanceCosAnnexeApi.getSubstanceCosAnnexes, payload);
    const schema = Array.isArray(substanceCosAnnexes)
      ? substanceCosAnnexeSchemas.substanceCosAnnexeListSchema
      : substanceCosAnnexeSchemas.substanceCosAnnexeSchema;
    const normalizedData = normalize(substanceCosAnnexes, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.success(data, payload));
    },
    onFailure: (error, payload) => put(substanceCosAnnexeActions.fetchSubstanceCosAnnexes.failure(error, payload)),
  },
);
