import { createRequestActions } from '../utils';
import * as rawMatDocumentTypes from './rawMatDocumentTypes';

export const saveRawMatDocument = createRequestActions(rawMatDocumentTypes.SAVE_RAWMAT_DOCUMENT, (RawMatId, data) => ({
  RawMatId,
  ...data,
}));

export const uploadRawMatDocument = createRequestActions(
  rawMatDocumentTypes.UPLOAD_RAWMAT_DOCUMENT,
  (RawMatId, data) => ({
    RawMatId,
    ...data,
  }),
);

export const listRawMatDocumentsForRawMat = createRequestActions(
  rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT,
  RawMatId => ({ RawMatId }),
  (RawMatDocuments, RawMatId) => ({ RawMatId }),
);

export const deleteRawMatDocument = createRequestActions(
  rawMatDocumentTypes.DELETE_RAWMAT_DOCUMENT,
  RawMatDocumentId => ({ RawMatDocumentId }),
);
