import PropTypes from 'prop-types';
import React from 'react';
import { pathOr, pipe, length, divide, __, add, multiply } from 'ramda';
import { SafecosmeticDashboardSearchGrid, TrueYesCellRenderer } from '../../_Common/Grids';
import DetailHeader from '../../_Common/DetailHeader';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, ButtonToolbar } from 'react-bootstrap';


const actionButtonsRenderer = props => {
  const { data, onShowOnSafecosmetic, onShowOnCosmos } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <ButtonToolbar>
      <Button bsStyle="success" bsSize="xsmall" type="button" onClick={() => onShowOnCosmos(data.ProdigumProductId)}>
        <i className="fa fa-search" aria-hidden="true" />&nbsp;<Translate value="safecosmetic.showOnCosmos" />
        </Button>
        {(data.HasPreview || data.IsPublished) &&
        <Button bsStyle="primary" bsSize="xsmall" type="button" onClick={() => onShowOnSafecosmetic(data.ProdigumProductId)}>
          <i className="fa fa-search" aria-hidden="true" />&nbsp;<Translate value="safecosmetic.showOnSafecosmetic" />
        </Button>
        }
      </ButtonToolbar>
    </div>
  );
};

actionButtonsRenderer.propTypes = {
  data: PropTypes.object.isRequired, //eslint-disable-line
  onShow: PropTypes.func.isRequired,
};

const SafecosmeticDashboardProductList = props => {
  const {
    safecosmeticDashboardProductList,
    isLoading,
    getProductList,
    searchProducts,
    onShowOnSafecosmetic,
    onShowOnCosmos,
    companyList,
    salesRepList,
    expertList,
    accountManagerList,
    onRowDoubleClick,
  } = props;

  const rowHeight = pipe(pathOr('1', ['data', 'Text']), length, divide(__, 255), Math.floor, add(1), multiply(25));

  const columns = [
    {
      headerName: I18n.t('generalDashboard.company'),
      field: 'Producer',
      width: 70,
      sort: 'asc',
    },
    {
      headerName: I18n.t('generalDashboard.code'),
      field: 'Code',
      width: 50,
      sort: 'asc',
      cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    },
    {
      headerName: I18n.t('generalDashboard.product'),
      field: 'ProductName',
    },
    {
      headerName: I18n.t('safecosmetic.isComplete'),
      field: 'IsComplete',
      cellRenderer: TrueYesCellRenderer,
      suppressFilter: false,
      width: 20,
    },
    {
      headerName: I18n.t('safecosmetic.hasPreview'),
      field: 'HasPreview',
      cellRenderer: TrueYesCellRenderer,
      suppressFilter: false,
      width: 20,
    },
    {
      headerName: I18n.t('safecosmetic.isPublished'),
      field: 'IsPublished',
      cellRenderer: TrueYesCellRenderer,
      suppressFilter: false,
      width: 20,
    },
    {
      headerName: I18n.t('safecosmetic.actions'),
      cellRendererFramework: actionButtonsRenderer,
      cellRendererParams: {
        onShowOnSafecosmetic: onShowOnSafecosmetic,
        onShowOnCosmos: onShowOnCosmos,
      },
      width: 170,
      minWidth: 170,
      suppressSizeToFit: true,
    },
  ];

  return (
    <div>
      <DetailHeader title="Safecosmetic Dashboard">
        <SafecosmeticDashboardSearchGrid
          data={safecosmeticDashboardProductList}
          columns={columns}
          onRefresh={getProductList}
          onSearch={searchProducts}
          isLoading={isLoading}
          showLoadingIcon={isLoading}
          stopEditingWhenGridLosesFocus
          singleClickEdit
          keyName="ProductId"
          showFilterRow
          getRowHeight={rowHeight}
          companyList={companyList}
          salesRepList={salesRepList}
          expertList={expertList}
          accountManagerList={accountManagerList}
          rowDoubleClicked={onRowDoubleClick}
        />
      </DetailHeader>
    </div>
  );
};

SafecosmeticDashboardProductList.propTypes = {
  safecosmeticDashboardProductList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProductList: PropTypes.func.isRequired,
  searchProducts: PropTypes.func.isRequired,
  onShowOnSafecosmetic: PropTypes.func.isRequired,
  onShowOnCosmos: PropTypes.func.isRequired,
  companyList: PropTypes.array, // eslint-disable-line
  salesRepList: PropTypes.array, // eslint-disable-line
  expertList: PropTypes.array, // eslint-disable-line
  accountManagerList: PropTypes.array, // eslint-disable-line
  onRowDoubleClick: PropTypes.func.isRequired,
};

export default SafecosmeticDashboardProductList;
