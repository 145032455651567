import { createAction } from 'redux-actions';
import * as types from './types';

export const onLoad = createAction(types.LOAD, mandateId => ({
  mandateId,
}));

export const onSave = createAction(types.SAVE, (mandateId, distributorId, mandateProducts, mandateData) => ({
  mandateId,
  distributorId,
  mandateProducts,
  mandateData,
}));
