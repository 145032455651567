import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { companyActions, companyTypes, companySelectors } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import CompanyDistributionChannelList from './CompanyDistributionChannelList';

class CompanyDistributionChannelsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.companyId !== this.props.companyId && this.props.companyId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <CompanyDistributionChannelList {...this.props} />;
  }
}

const mapStateToProps = state => {
  const { company: { currentCompanyDistributionChannels } } = state;
  return {
    currentCompanyDistributionChannels,
    distributionChannelsNotLinkedWithCurrentCompany: companySelectors.getDistributionChannelsNotLinkedWithCurrentCompany(
      state,
    ),
    requestingCompanyDistributionChannelList: applicationSelectors.isLoading(
      state,
      companyTypes.COMPANY_DISTRIBUTION_CHANNEL_LIST.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { companyId }) => ({
  onLoad: () => {
    // dispatch(distributionChannelActions.listDistributionChannels.request());
    dispatch(companyActions.listCompanyDistributionChannels.request(companyId));
  },
  onRefresh: () => dispatch(companyActions.listCompanyDistributionChannels.request(companyId)),
  addDistributionChannelToCompany: ({ Key }) =>
    dispatch(companyActions.addDistributionChannelToCompany.request(companyId, Key)),
  onRemoveBtnClicked: distributionChannel =>
    dispatch(companyActions.removeDistributionChannelFromCompanyAction(companyId, distributionChannel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDistributionChannelsContainer);
