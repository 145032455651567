import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toxProfileChapterActions, toxProfileChapterTypes } from '../../../redux/toxProfileChapter';
import { toxProfileItemActions, toxProfileItemTypes } from '../../../redux/toxProfileItem';
import ToxProfileForm from './ToxProfileForm';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { toxProfileItemFormActions, toxProfileItemFormSelectors } from '../../../redux/form/toxProfileItemForm';
import formNames from '../../../constants/formNames';
import { getFormValues, isPristine, reset } from 'redux-form';
import { reportActions } from '../../../redux/report';

class ToxProfileContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId && this.props.substanceId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ToxProfileForm form={formNames.toxProfileItemForm} {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => {
  const formValues = getFormValues(formNames.toxProfileItemForm);
  const finalData = formValues(state);

  return {
    formValues: finalData,
    isLoading: applicationSelectors.getIsLoading(
      toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.REQUEST,
      toxProfileItemTypes.TOXPROFILEITEM_LIST.REQUEST,
      toxProfileItemTypes.SAVE_TOXPROFILEITEM.REQUEST,
    )(state),
    isFormPristine: isPristine(formNames.toxProfileItemForm)(state),
    nbSelectedItems: toxProfileItemFormSelectors.nbSelectedItems(state),
  };
};

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    dispatch(toxProfileChapterActions.listToxProfilChapter.request());
    dispatch(toxProfileItemActions.listToxProfileItems.request({ substanceId }));
    dispatch(toxProfileItemFormActions.loadForm({ substanceId }));
  },
  onAddButtonClick: () => dispatch(toxProfileItemFormActions.addProfileItem({ substanceId })),
  onEditButtonClick: ({ ToxProfileItemId: toxProfileItemId }) =>
    dispatch(
      modalActions.showToxProfileItemFormModal({
        substanceId,
        toxProfileItemId,
      }),
    ),
  onDeleteButtonClick: ({ ToxProfileItemId: toxProfileItemId }) =>
    dispatch(toxProfileItemActions.deleteToxProfileItem.request({ toxProfileItemId, substanceId })),
  onSaveSubstanceToxProfileItems: formData =>
    dispatch(toxProfileItemFormActions.onSaveButtonClick({ formData, substanceId })),
  onCancelButtonClick: () => {
    dispatch(reset(formNames.toxProfileItemForm));
  },
  onSelectSubstanceToxProfileItem: data => dispatch(toxProfileItemFormActions.onSelectProfileItem({ ...data })),
  onDeleteSubstanceItems: () => dispatch(toxProfileItemFormActions.onDeleteButtonClick({ substanceId })),
  onPrintClick: () => dispatch(reportActions.getToxProfileReport.request(substanceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToxProfileContainer);
