import { takeLatest, put, select, takeEvery, all } from 'redux-saga/effects';
import { replace, LOCATION_CHANGE } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import { createSaveForm } from '../form/utils';
import distributorApi from '../../api/distributorApi';
import formNames from '../../constants/formNames';
// import companyDistributorApi from '../../api/companyDistributorApi';
// import productDistributorApi from '../../api/productDistributorApi';
import * as distributorTypes from './distributorTypes';
import * as distributorActions from './distributorActions';
import * as distributorSchemas from './distributorSchemas';

export default function* root() {
  yield all([
    takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(distributorTypes.DISTRIBUTOR_AGREEMENT_LIST.REQUEST, getDistributorAgreementListSaga),
    takeLatest(distributorTypes.DISTRIBUTOR_LIST.REQUEST, getDistributorListSaga),
    takeLatest(distributorTypes.DISTRIBUTOR.REQUEST, getDistributorSaga),
    takeLatest(distributorTypes.SAVE_DISTRIBUTOR.REQUEST, saveDistributorFormSaga),
    // takeLatest(distributorTypes.DISTRIBUTOR_COMPANY_LIST.REQUEST, getDistributorCompanyListSaga),
    // takeLatest(distributorTypes.DISTRIBUTOR_PRODUCT_LIST.REQUEST, getDistributorProductListSaga),
    // takeLatest(distributorTypes.LINK_COMPANY_TO_DISTRIBUTOR.REQUEST, linkCompanyToDistributorSaga),
    // takeLatest(distributorTypes.LINK_PRODUCT_TO_DISTRIBUTOR.REQUEST, linkProductToDistributorSaga),
    takeLatest(distributorTypes.DELETE_DISTRIBUTOR.REQUEST, deleteDistributorSaga),
    takeLatest(distributorTypes.PRODUCT_DISTRIBUTOR_LIST.REQUEST, getProductDistributorListSaga),
  ]);
}

const saveDistributorFormSaga = createSaveForm(formNames.distributor, saveDistributorSaga);

function* onLocationChange(action) {
  if (action.payload.pathname === '/distributors/-1') {
    yield put(distributorActions.newDistributor());
  }
}

function* getDistributorListSaga() {
  yield put(applicationActions.setLoading(distributorTypes.DISTRIBUTOR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    distributorActions.listDistributors,
    distributorApi.getDistributorList,
    distributorSchemas.distributorListSchema,
  );
  yield put(applicationActions.unsetLoading(distributorTypes.DISTRIBUTOR_LIST.REQUEST));
}

function* getDistributorAgreementListSaga(action) {
  yield put(applicationActions.setLoading(distributorTypes.DISTRIBUTOR_AGREEMENT_LIST.REQUEST));
  yield callApiSaga(
    distributorActions.listDistributorAgreements,
    distributorApi.getDistributorAgreements,
    action.payload.distributorId,
  );
  yield put(applicationActions.unsetLoading(distributorTypes.DISTRIBUTOR_AGREEMENT_LIST.REQUEST));
}

function* getDistributorSaga(action) {
  yield put(applicationActions.setLoading(distributorTypes.DISTRIBUTOR.REQUEST));
  yield callApiSagaAndNormalize(
    distributorActions.distributor,
    distributorApi.getDistributor,
    distributorSchemas.distributorSchema,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(distributorTypes.DISTRIBUTOR.REQUEST));
}

function* saveDistributorSaga(action) {
  yield put(applicationActions.setLoading(distributorTypes.SAVE_DISTRIBUTOR.REQUEST));
  const distributorId = yield callApiSaga(
    distributorActions.saveDistributor,
    distributorApi.saveDistributor,
    action.payload,
  );
  if (distributorId !== undefined) {
    const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
    yield put(replace({ ...previousLocation, pathname: `/distributors/${distributorId}` }));
    yield put(distributorActions.distributor.request(distributorId));
    yield put(distributorActions.listDistributors.request());
  }
  yield put(applicationActions.unsetLoading(distributorTypes.SAVE_DISTRIBUTOR.REQUEST));
}

// function* getDistributorCompanyListSaga(action) {
//   yield put(applicationActions.setLoading(distributorTypes.DISTRIBUTOR_COMPANY_LIST.REQUEST));
//   yield callApiSaga(
//     distributorActions.listDistributorCompanies,
//     distributorApi.getDistributorCompanies,
//     action.payload,
//   );
//   yield put(applicationActions.unsetLoading(distributorTypes.DISTRIBUTOR_COMPANY_LIST.REQUEST));
// }

// function* getDistributorProductListSaga(action) {
//   yield put(applicationActions.setLoading(distributorTypes.DISTRIBUTOR_PRODUCT_LIST.REQUEST));
//   yield callApiSaga(distributorActions.listDistributorProducts, distributorApi.getDistributorProducts, action.payload);
//   yield put(applicationActions.unsetLoading(distributorTypes.DISTRIBUTOR_PRODUCT_LIST.REQUEST));
// }

// function* linkCompanyToDistributorSaga(action) {
//   yield put(applicationActions.setLoading(distributorTypes.LINK_COMPANY_TO_DISTRIBUTOR.REQUEST));
//   yield callApiSaga(distributorActions.linkCompanyToDistributor, companyDistributorApi.saveCompanyDistributor, action.payload);
//   const distributorId = yield select(({ distributor }) => distributor.currentDistributor.DistributorId);
//   yield put(distributorActions.listDistributorCompanies.request(distributorId));
//   yield put(applicationActions.unsetLoading(distributorTypes.LINK_COMPANY_TO_DISTRIBUTOR.REQUEST));
// }

// function* linkProductToDistributorSaga(action) {
//   yield put(applicationActions.setLoading(distributorTypes.LINK_PRODUCT_TO_DISTRIBUTOR.REQUEST));
//   yield callApiSaga(distributorActions.linkProductToDistributor, productDistributorApi.saveProductDistributor, action.payload);
//   const distributorId = yield select(({ distributor }) => distributor.currentDistributor.DistributorId);
//   yield put(distributorActions.listDistributorProducts.request(distributorId));
//   yield put(applicationActions.unsetLoading(distributorTypes.LINK_PRODUCT_TO_DISTRIBUTOR.REQUEST));
// }

function* deleteDistributorSaga(action) {
  yield put(applicationActions.setLoading(distributorTypes.DELETE_DISTRIBUTOR.REQUEST));
  yield callApiSaga(distributorActions.deleteDistributor, distributorApi.deleteDistributor, action.payload);
  yield put(replace('/distributors/'));
  yield put(distributorActions.listDistributors.request());
  yield put(applicationActions.unsetLoading(distributorTypes.DELETE_DISTRIBUTOR.REQUEST));
}

function* getProductDistributorListSaga({payload}) {
  yield put(applicationActions.setLoading(distributorTypes.PRODUCT_DISTRIBUTOR_LIST.REQUEST));
  yield callApiSaga(
    distributorActions.listProductDistributors,
    distributorApi.getProductDistributorList,
    payload,
  );
  yield put(applicationActions.unsetLoading(distributorTypes.PRODUCT_DISTRIBUTOR_LIST.REQUEST));
}
