import FileSaver from 'file-saver';
import queryString from 'query-string';
import config from '../config';

const API_ROOT = config.baseUrl;

let authToken = () => undefined;

export function setAuthTokenGetter(tokenGetter) {
  authToken = tokenGetter;
}

export const getQueryString = data => {
  if (Object.prototype.hasOwnProperty.call(data, 'include')) {
    const { include, ...rest } = data;
    return {
      ...rest,
      ...include,
    };
  }
  return data;
};

export const makeConstructUrlFromQuery = baseUrl => ({ id, query } = {}) =>
  `${baseUrl}${id || ''}${query ? '?'.concat(queryString.stringify(getQueryString(query))) : ''}`;

export function get(endpoint, options) {
  const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const finalOptions = options || {};

  const token = authToken();
  if (token) {
    if (!finalOptions.headers) finalOptions.headers = {};
    finalOptions.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(fullUrl, finalOptions).then(response =>
    response.json().then(data => (response.ok ? data : Promise.reject(data))),
  );
}

export function download(endpoint, fileName, options) {
  const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const finalOptions = options || {};

  const token = authToken();
  if (token) {
    if (!finalOptions.headers) finalOptions.headers = {};
    finalOptions.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(fullUrl, finalOptions)
    .then(response => {
      if (!response.ok) {
        return response.json().then(data => Promise.reject(data));
      }
      const header = response.headers.get('Content-Disposition');
      fileName = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
      return response.blob();
    })
    .then(blob => {
      FileSaver.saveAs(blob, fileName);
    });
}

export function downloadPost(endpoint, fileName, options, body) {
  const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const finalOptions = options || {};

  const token = authToken();
  if (token) {
    if (!finalOptions.headers) finalOptions.headers = {};
    finalOptions.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(fullUrl, finalOptions)
    .then(response => {
      if (!response.ok) {
        return response.json().then(data => Promise.reject(data));
      }
      const header = response.headers.get('Content-Disposition');
      fileName = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
      return response.blob();
    })
    .then(blob => {
      FileSaver.saveAs(blob, fileName);
    });
}

export function post(endpoint, data) {
  return get(endpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function uploadFiles(endpoint, data) {
  const formData = new FormData();
  for (let x = 0; x < data.length; x++) {
    formData.append(`file${x}`, data[x]);
  }

  return get(endpoint, {
    method: 'POST',
    body: formData,
  });
}

export function del(endpoint, data) {
  return get(endpoint, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : null,
  });
}

export default {
  get,
  post,
  uploadFiles,
  del,
};
