import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { evolve, pick } from 'ramda';
import { push } from 'react-router-redux';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

const GoToParentButton = ({ onClick }) => (
  <Button bsSize="small" onClick={onClick}>
    {I18n.t('product.goToParent')}
  </Button>
);

GoToParentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { parentProductId, location }) => ({
  onClick: () => {
    dispatch(
      push(
        evolve({
          query: pick(['show_details']),
          pathname: () => `/products/${parentProductId}`,
        })(location),
      ),
    );
  },
});

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps,
  ),
)(GoToParentButton);
