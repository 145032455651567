import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import ImpactAssessmentCandidateProductList from './ImpactAssessmentCandidateProductList';
import LoadingButton from './../../_Common/LoadingButton';

const ImpactAssessmentCandidateProductsTab = props => {
  const { goToProductPage, calculateValues, requestingImpactAssessmentList, ...otherProps } = props;
  return (
    <div>
      <Button bsSize="small" onClick={goToProductPage}>
        {I18n.t('product.goToProducts')}
      </Button>
      {/* &nbsp;
      <LoadingButton
        bsSize="small"
        onClick={calculateValues}
        isLoading={requestingImpactAssessmentList}
      >
        {I18n.t('impactAssessmentProduct.calculateValues')}
      </LoadingButton> */}
      <br />
      <br />
      <ImpactAssessmentCandidateProductList
        requestingImpactAssessmentList={requestingImpactAssessmentList}
        {...otherProps}
      />
    </div>
  );
};

ImpactAssessmentCandidateProductsTab.propTypes = {
  goToProductPage: PropTypes.func.isRequired,
  calculateValues: PropTypes.func.isRequired,
};

export default ImpactAssessmentCandidateProductsTab;
