import queryString from 'query-string';
import { post, get, del } from './apiUtils';

const defaultUrl = '/OrderItems';
const plannerDashboard = '/PlannerDashboard';

export default {
  getOrderItems: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveOrderItem: data => post(defaultUrl, data),
  deleteOrderItem: id => del(`${defaultUrl}/${id}`),
  getOrderPlannerDashboard: query =>
    (query
      ? get(`${defaultUrl}${plannerDashboard}?${queryString.stringify(query)}`)
      : get(`${defaultUrl}${plannerDashboard}`)),
};
