import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { reduxForm, Field } from 'redux-form';
import formNames from '../../../constants/formNames';
import { productSelectors } from '../../../redux/product';
import { productCpsrbCommentFormActions } from '../../../redux/form/productCpsrbCommentForm';
import { InputField } from '../../_Common/Forms';
import { maxLength1024 } from '../../../utils/fieldValidation';

const ProductCpsrbCommentForm = ({ disabled }) => (
  <div>
    <Field
      name="CommentCPSRB"
      label={`${I18n.t('product.cpsrBComment')}`}
      component={InputField}
      validate={[maxLength1024]}
      readOnly={disabled}
    />
  </div>
);

ProductCpsrbCommentForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = R.applySpec({
  initialValues: (state, { productId }) =>
    R.compose(
      R.pick(['CommentCPSRB', 'ProductId']),
      R.prop(productId),
      productSelectors.getById,
    )(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: productCpsrbCommentFormActions.submitProductCpsrbCommentForm,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.productCpsrbCommentForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProductCpsrbCommentForm);
