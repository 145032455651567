import queryString from 'query-string';
import { uploadFiles, get, del } from './apiUtils';

const defaultUrl = '/RawMatsDocuments/';
const upload = '/File/';

export default {
  uploadRawMatDocument: ({ RawMatId, data }) => uploadFiles(`${defaultUrl}${RawMatId}${upload}`, data),
  getRawMatDocumentList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  deleteRawMatDocument: id => del(`${defaultUrl}${id}`),
};

