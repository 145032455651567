import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as rawMatDocumentTypes from './rawMatDocumentTypes';

const byId = handleActions(
  {
    [rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.rawMatDocuments,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [rawMatDocumentTypes.RAWMAT_DOCUMENT_LIST_FOR_RAWMAT.SUCCESS]: (state, { payload }) => [
      ...new Set([...state, ...payload.result]),
    ],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
