import { productCategorySelectors } from '../../productCategory';
import { productCategoryTRSelectors } from '../../productCategoryTR';
import {referentialSelectors} from "../../referentials";

export const getInitialValues = (state, productCategoryId) => {
  const productCategory = productCategorySelectors.getById(state)[productCategoryId];
  const productCategoryTRs = productCategoryTRSelectors.getProductCategoryTRByProductCategoryId(state, productCategoryId);
  const languageList = referentialSelectors.getLanguageList(state);
  const values = {
    productCategory
  }
  languageList.filter(elem => elem.Alpha2 !== 'en').map(lang => {
    const productCatForLang = productCategoryTRs.find(elem => elem.LanguageCode === lang.Alpha2);
    values[lang.Alpha2] = productCatForLang ? productCatForLang : {
      ProductCategoryId: productCategoryId,
      LanguageCode: lang.Alpha2,
    }
  });

  return values;
};
