import { createRequestTypes } from '../utils';

export const SAVE_PRODUCT_DOCUMENT = createRequestTypes('productDocument/SAVE_PRODUCT_DOCUMENT');
export const PRODUCT_DOCUMENT_LIST = createRequestTypes('productDocument/PRODUCT_DOCUMENT_LIST');
export const UPLOAD_PRODUCT_DOCUMENT = createRequestTypes('productDocument/UPLOAD_PRODUCT_DOCUMENT');
export const UPLOAD_PRODUCT_COMPOSITION = createRequestTypes('productDocument/UPLOAD_PRODUCT_COMPOSITION');
export const SEND_PRODUCT_DOCUMENT_TO_COSMOS = createRequestTypes('productDocument/SEND_PRODUCT_DOCUMENT_TO_COSMOS');
export const PRODUCT_DOCUMENT_LIST_FOR_PRODUCT = createRequestTypes(
  'productDocument/PRODUCT_DOCUMENT_LIST_FOR_PRODUCT',
);
export const DELETE_PRODUCT_DOCUMENT = createRequestTypes('productDocument/DELETE_PRODUCT_DOCUMENT');
