import { createRequestActions, createFetchAction } from '../utils';
import * as substanceToxProfileTypes from './substanceToxProfileTypes';

export const fetchSubstanceToxProfiles = createFetchAction(
  substanceToxProfileTypes.FETCH_SUBSTANCE_TOX_PROFILES,
  'substanceToxicologicalProfileId',
);
export const saveSubstanceToxProfile = createRequestActions(substanceToxProfileTypes.SAVE_SUBSTANCE_TOX_PROFILE);

export const deleteSubstanceToxProfile = createRequestActions(
  substanceToxProfileTypes.DELETE_SUBSTANCE_TOX_PROFILE,
  (id, { ...query }) => ({
    id,
    query,
  }),
);
