import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import FormulaReviewWizardModal from './FormulaReviewWizardModal';
import { applicationSelectors } from '../../../redux/application';
import { formulaReviewWizardSelectors, formulaReviewWizardActions } from '../../../redux/wizard/formulaReviewWizard';
import {
  complianceReviewWizardSelectors,
  complianceReviewWizardActions,
} from '../../../redux/wizard/formulaReviewWizard/complianceReviewWizard';
import {
  rawMatDataReviewWizardSelectors,
  rawMatDataReviewWizardActions,
} from '../../../redux/wizard/formulaReviewWizard/rawMatDataReviewWizard';
import {
  productDataReviewWizardSelectors,
  productDataReviewWizardActions,
} from '../../../redux/wizard/formulaReviewWizard/productDataReviewWizard';
import { formulaReviewTypes } from '../../../redux/formulaReview';
import { referentialSelectors } from '../../../redux/referentials';

// const FormulaReviewWizardContainer = props => <FormulaReviewWizardModal height="70vh" {...props} />;

const FormulaReviewWizardContainer = props => <FormulaReviewWizardModal height="70vh" {...props} />;

const mapStateToProps = (state, { FormulaReviewId, ProductId }) => ({
  complianceReviewList: complianceReviewWizardSelectors.getComplianceReviewWithSubstanceList(state)(ProductId),
  rawMatList: rawMatDataReviewWizardSelectors.getRawMatList(state, ProductId, FormulaReviewId),
  rawMatDataReviewList: rawMatDataReviewWizardSelectors.getRawMatDataReviewWithAnnexeList(state),
  productDataReview: productDataReviewWizardSelectors.getProductDataReview(state),
  isLoading: applicationSelectors.isLoading(state, formulaReviewTypes.SAVE_FORMULA_REVIEW.REQUEST),
  conclusionList: referentialSelectors.getFormulaReviewConclusions(state),
  formulaReview: formulaReviewWizardSelectors.getFormulaReview(state),
  getCosAnneTypeByCode: referentialSelectors.makeGetCosAnnexeTypeByCode(state),
  regionList: referentialSelectors.getRegions(state),
  countryList: referentialSelectors.getCountryList(state),
});

const mapDispatchToProps = (dispatch, { ProductId, FormulaReviewId }) => ({
  updateComplianceReview: (id, data) => dispatch(complianceReviewWizardActions.updateComplianceReview(id, data)),
  updateRawMatDataReview: (id, data) => dispatch(rawMatDataReviewWizardActions.updateRawMatReviewData(id, data)),
  updateProductDataReview: data => dispatch(productDataReviewWizardActions.updateProductDataReview(data)),
  updateFormulaReview: data => dispatch(formulaReviewWizardActions.updateFormulaReview(data)),
  saveFormulaReview: () => dispatch(formulaReviewWizardActions.saveFormulaReview(ProductId, FormulaReviewId)),
});

FormulaReviewWizardContainer.defaultProps = {
  FormulaReviewId: undefined,
};

FormulaReviewWizardContainer.propTypes = {
  ProductId: PropTypes.number.isRequired,
  FormulaReviewId: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormulaReviewWizardContainer);
