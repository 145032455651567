import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  impactAssessmentThresholdActions,
  impactAssessmentThresholdTypes,
  impactAssessmentThresholdSelectors,
} from '../../../redux/impactAssessmentThreshold';
import {
  impactAssessmentProductActions,
  impactAssessmentProductTypes,
  impactAssessmentProductSelectors,
} from '../../../redux/impactAssessmentProduct';
import { modalActions } from '../../../redux/modal';
import { applicationSelectors } from '../../../redux/application';
import { referentialSelectors } from '../../../redux/referentials';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';

import ImpactAssessmentThresholdList from './ImpactAssessmentThresholdList';

class ImpactAssessmentThresholdsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    impactAssessmentId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.impactAssessmentId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.impactAssessmentId !== this.props.impactAssessmentId && this.props.impactAssessmentId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <ImpactAssessmentThresholdList {...this.props} />;
  }
}

const mapStateToProps = () => (state, { impactAssessmentId }) => ({
  workflowStatusNameById: workflowStatusSelectors.getById(state),
  currentImpactAssessmentThresholds: impactAssessmentThresholdSelectors.getImpactAssessmentThresholdsForImpactAssessment(
    state,
  )(impactAssessmentId),
  requestingImpactAssessmentThresholdList: applicationSelectors.isLoading(
    state,
    impactAssessmentThresholdTypes.IMPACT_ASSESSMENT_THRESHOLD_LIST.REQUEST,
    impactAssessmentThresholdTypes.DELETE_IMPACT_ASSESSMENT_THRESHOLD.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { impactAssessmentId }) => ({
  onLoad: () => {
    if (impactAssessmentId > 0) {
      dispatch(impactAssessmentThresholdActions.listImpactAssessmentThresholds.request(impactAssessmentId));
    }
  },
  onRefresh: () =>
    dispatch(impactAssessmentThresholdActions.listImpactAssessmentThresholds.request(impactAssessmentId)),
  onAddThreshold: () =>
    dispatch(
      modalActions.showThresholdFormModal({
        impactAssessmentId,
      }),
    ),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
  goToProductPage: () => dispatch(push({ pathname: '/products', query: { impactAssessmentId } })),
  onDeleteImpactAssessmentThreshold: async id => {
    await dispatch(impactAssessmentThresholdActions.deleteImpactAssessmentThreshold.request({impactAssessmentId, id}));
    await dispatch(impactAssessmentThresholdActions.listImpactAssessmentThresholds.request(impactAssessmentId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImpactAssessmentThresholdsContainer);
