import { createSelector } from 'reselect';
import { orderItemSelectors } from '../../orderItem';
import { orderSelectors } from '../../order';
import { referentialSelectors } from '../../referentials';

const getInitialValues = (orderItemId, orderId) =>
  createSelector(
    [orderItemSelectors.getById, orderSelectors.getCurrentOrderProcessInfo, referentialSelectors.getCurrencies],
    (orderItemById, processInfo, currencies) => {
      const currencyLabel = processInfo.CurrencyCode ? currencies.find(elem => elem.Key === processInfo.CurrencyCode).Value : null;
      const orderItem = orderItemById[orderItemId];
      if (orderItem) {
        orderItem.Currency = currencyLabel;
        orderItem.CurrencyCode = processInfo.CurrencyCode;
      }
      return orderItemById[orderItemId] || {
        OrderId: orderId,
        Discount: processInfo.DiscountPercentage,
        Quantity: 0,
        ShadeCount: 0,
        Currency: currencyLabel,
        CurrencyCode: processInfo.CurrencyCode,
      };
    },
  );

export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues);
