import {takeLatest, put, select, all, take, call} from 'redux-saga/effects';
import {replace, LOCATION_CHANGE} from 'react-router-redux';
import {normalize} from 'normalizr';
import * as R from 'ramda';
import _ from 'lodash';
import {SubmissionError} from 'redux-form';
import {createSaveForm} from '../form/utils';
import {applicationActions} from '../application';
import {callApiSaga, callApiSagaAndNormalize, createFetchSaga} from '../sagaUtils';
import productApi from '../../api/productApi';
import rawMatApi from '../../api/rawMatApi';
import formulaReviewMissingDocumentApi from '../../api/formulaReviewMissingDocumentApi';
import orderProductApi from '../../api/orderProductApi';
import * as productTypes from './productTypes';
import * as productActions from './productActions';
import * as productSelectors from './productSelectors';
import {modalSelectors, modalTypes, modalActions} from '../modal';
import {workflowStatusSelectors} from '../workflowStatus';
import {productActionActions} from '../productAction';
import * as inciListActions from '../inciList/inciListActions';
import {rawMatActions} from '../../redux/rawMat';
import {shadeActions} from '../../redux/shade';
import {rawMatSupplierActions} from '../../redux/rawMatSupplier';
import {rawMatProportionSchema} from '../../redux/rawMatProportion';
import {productListSchema, productSchema} from './productSchemas';
import {productProportionActions} from '../productProportion';
import {orderProductActions, orderProductTypes} from '../orderProduct';
import {productFormSelectors} from '../form/productForm';
import { GenerateTextForClipboard, MissingMandatoryInfoForCPSR, MissingMandatoryInfoForLR } from '../../components/Products/CheckList/DocumentListBuilder';

export default function* root() {
  yield all([
    takeLatest(LOCATION_CHANGE, onLocationChange),
    takeLatest(productTypes.PRODUCT_LIST.REQUEST, getProductListSaga),
    takeLatest(productTypes.PRODUCT_LIST2.REQUEST, getProductListManufacturerAndCPSRSaga),
    takeLatest(productTypes.PRODUCT.REQUEST, getProductSaga),
    takeLatest(productTypes.UPDATE_PRODUCT.REQUEST, updateProductSaga),
    takeLatest(
      [
        productTypes.UPDATE_COMPOSITION_STATUS.REQUEST,
        productTypes.UPDATE_PRODUCT_STATUS.REQUEST,
        productTypes.UPDATE_PRODUCT_CPSR_STATUS.REQUEST,
        productTypes.UPDATE_PRODUCT_CPSRB_CONCLUSION_STATUS.REQUEST,
      ],
      updateProductStatusSaga,
    ),
    takeLatest(productTypes.PRODUCT_ORDER_LIST.REQUEST, getProductOrderListSaga),
    takeLatest(productTypes.PRODUCT_TASK_LIST.REQUEST, getProductTaskListSaga),
    takeLatest(productTypes.PRODUCT_IMPACT_ASSESSMENT_LIST.REQUEST, getProductImpactAssessmentListSaga),
    takeLatest(productTypes.PRODUCT_PHRASE_LIST.REQUEST, getProductPhraseListSaga),
    takeLatest(productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST.REQUEST, getProdigumProductProportionListSaga),
    takeLatest(productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST.REQUEST, getImportedProductProportionListSaga),
    takeLatest(productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST.REQUEST, getCosmosIngredientRawMatMatchListSaga),
    takeLatest(productTypes.SAVE_PRODUCT.REQUEST, saveProductFormSaga),
    takeLatest(productTypes.PROCESS_IMPORTED_PRODUCT_PROPORTIONS.REQUEST, processImportedProductProportionsSaga),
    takeLatest(productTypes.IMPORT_PRODUCT_PROPORTIONS.REQUEST, importProductProportionsSaga),
    takeLatest(productTypes.DELETE_IMPORTED_PRODUCT_PROPORTIONS.REQUEST, deleteImportedProductProportionsSaga),
    takeLatest(productTypes.REPORT_TEST.REQUEST, reportTestSaga),
    takeLatest(productTypes.REPORT.REQUEST, reportSaga),
    takeLatest(productTypes.SAVE_PRODUCT_PHRASES.REQUEST, saveProductPhrasesSaga),
    takeLatest(productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST, saveProductProportionsSaga),
    takeLatest(productTypes.DELETE_PRODUCT_PHRASES.REQUEST, deleteProductPhrasesSaga),
    takeLatest(productTypes.DELETE_PRODUCT_PROPORTION.REQUEST, deleteProductProportionSaga),
    takeLatest(productTypes.SAVE_AND_LINK_PRODUCT_PROPORTION.REQUEST, saveAndLinkProductProportionSaga),
    takeLatest(productTypes.SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION.REQUEST, saveAndLinkImportedProductProportionSaga),
    takeLatest(productTypes.PRODUCT_MOS_CALCULATION_LIST.REQUEST, getProductMosCalculationListSaga),
    takeLatest(productTypes.PRODUCT_FORMULA_REVIEW_LIST.REQUEST, getProductFormulaReviewListSaga),
    takeLatest(productTypes.PRODUCT_IMPURITY_LIST.REQUEST, getProductImpurityListSaga),
    takeLatest(productTypes.ORDERS_OWNED_BY_PRODUCT_COMPANY.REQUEST, getOrdersOwnedByProductCompanySaga),
    takeLatest(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST, addOrderToCurrentProductSaga),
    takeLatest(productTypes.ADD_ORDER_TO_PRODUCT.REQUEST, addOrderToProductSaga),
    takeLatest(productTypes.CHANGE_PRODUCT_COMPOSITION_STATUS, changeProductCompositionStatusSaga),
    takeLatest(productTypes.REMOVE_ORDER_FROM_PRODUCT, removeOrderFromProductSaga),
    takeLatest(productTypes.PRODUCT_LIST_FOR_COMPANY.REQUEST, getProductListForCompanySaga),
    takeLatest(productActions.submitProductForm.REQUEST, submitProductFormSaga),
    takeLatest(productTypes.UPDATE_INCI_QTY.REQUEST, updateInciQtySaga),
    takeLatest(productTypes.EXPORT_COMPOSITION_FOR_LATIN_AMERICA.REQUEST, exportCompositionForLatinAmericaSaga),
    takeLatest(productTypes.SAVE_PRODUCT_CPSRB_COMMENT.REQUEST, saveProductCpsrbCommentSaga),
    takeLatest(productTypes.FETCH_LAST_FR_CHECK_LIST.REQUEST, getLastFRCheckListSaga),
    takeLatest(productTypes.CHECK_LIST_CHANGE_STATUS.REQUEST, checkListChangeStatusSaga),
    takeLatest(productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST, getLastFRMissingDocumentsSaga),
    takeLatest(productTypes.MISSING_DOCUMENT_CHANGE_STATUS.REQUEST, missingDocumentChangeStatusSaga),
    takeLatest(productTypes.GENERATE_MISSING_DOCUMENT_TEXT.REQUEST, generateMissingDocumentTextSaga),

  ]);
}

function* generateMissingDocumentTextSaga({ payload, type})
{
  yield put(applicationActions.setLoading(type));


  let copyText = '';
  for(var productId of payload) {
    try {
      // yield callApiSagaAndNormalize(productActions.product, productApi.getProduct, productSchema, payload[0]);
      // yield callApiSaga(productActions.fetchLastFRCheckList, productApi.getLastFRProductData, payload[0]);
      // yield callApiSaga(productActions.fetchLastFRMissingDocument, productApi.getLastFRMissingDocument, payload[0]);
      yield callApiSagaAndNormalize(productActions.product, productApi.getProduct, productSchema, productId);
      yield callApiSaga(productActions.fetchLastFRCheckList, productApi.getLastFRProductData, productId);
      yield callApiSaga(productActions.fetchLastFRMissingDocument, productApi.getLastFRMissingDocument, productId);

      const product = yield select(productSelectors.getCurrentProduct);
      const lastFRProductData = yield select(productSelectors.getCurrentLastFrCheckList);
      const missingDocuments = yield select(productSelectors.getCurrentLastFrMissingDocument);
      
      const itemList1 = lastFRProductData==null?[]:MissingMandatoryInfoForCPSR(lastFRProductData, product.productId);
      const itemList2 = lastFRProductData==null?[]:MissingMandatoryInfoForLR(lastFRProductData, product.productId);
      const itemList3 = missingDocuments==null?[]:missingDocuments.filter(doc => doc.Mandatory == true);
      copyText = copyText + GenerateTextForClipboard(product, itemList1, itemList2, itemList3);
      console.log('processed ' + product.Code);
    } catch (error) {
      console.log('error ' + productId + ' ' + error);
    }
  }

  //document.querySelector('.clipboard-content');
  console.log(copyText);

  var textarea = document.createElement("textarea");
  textarea.textContent = copyText;
  textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  try {
    // return await navigator.clipboard.writeText(text);
    yield navigator.clipboard.writeText(copyText).then(function() {
      console.log('success');
    }, function(error) {
      console.log('error');
    });      // return document.execCommand("copy");  // Security exception may be thrown by some browsers.
  }
  catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
  }
  finally {
      document.body.removeChild(textarea);
  }
  console.log('done');
  
  // if ('clipboard' in navigator) {
  //   // return await navigator.clipboard.writeText(text);
  //   // yield navigator.clipboard.writeText(copyText).then(function() {
  //   //   console.log('success');
  //   // }, function(error) {
  //   //   console.log('error');
  //   // });
  // } else {
  //   document.execCommand('copy', true, copyText);
  // }

  yield put(applicationActions.unsetLoading(type));

}

const saveProductFormSaga = createSaveForm('productForm', saveProductSaga);

function* importProductProportionsSaga({ payload, type })
{
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productActions.importProductProportions, productApi.importProductProportions, payload);
  yield callApiSaga(
    productActions.listProductImportedProductProportions,
    productApi.getImportedProductProportions,
    payload.ProductId,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* deleteImportedProductProportionsSaga({ payload, type })
{
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productActions.deleteImportedProductProportions, productApi.deleteImportedProductProportions, payload);
  yield callApiSaga(
    productActions.listProductImportedProductProportions,
    productApi.getImportedProductProportions,
    payload.ProductId,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* processImportedProductProportionsSaga({ payload, type })
{
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(productActions.processImportedProductProportions, productApi.processImportedProductProportions, payload);
  yield put(applicationActions.unsetLoading(type));
}

function* submitProductFormSaga(action) {
  try {
    const duplicateProductId = yield select(productFormSelectors.getDuplicateFromId);
    const duplicateMode = yield select(productFormSelectors.getDuplicateMode);
    const duplicateOrderNr = yield select(productFormSelectors.getDuplicateOrderNr);
    if (duplicateProductId) {
      const newId = yield call(duplicateProductSaga, {
        payload: {product: action.payload, originalProductId: duplicateProductId, mode: duplicateMode},
        type: productTypes.SAVE_PRODUCT.REQUEST,
      });
      yield call(addOrderToProductSaga, {
        payload: {ProductId: newId, OrderId: duplicateOrderNr},
        type: productTypes.ADD_ORDER_TO_PRODUCT.REQUEST,
      });
    } else {
      yield call(saveProductSaga, {payload: action.payload, type: productTypes.SAVE_PRODUCT.REQUEST});
    }
    yield put(productActions.submitProductForm.success());
  } catch (error) {
    if (error.Errors) {
      const formError = new SubmissionError({
        ...error.Errors,
      });
      yield put(productActions.submitProductForm.failure(formError));
    }
    yield put(applicationActions.unsetLoading(productTypes.SAVE_PRODUCT.REQUEST));
  }
}

function* onLocationChange(action) {
  const {pathname, action: routeAction} = action.payload;
  if (pathname === '/products/-1' && routeAction === 'PUSH') {
    yield put(productActions.newProduct());
  }
}

function* getProductListSaga() {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_LIST.REQUEST));
  yield callApiSagaAndNormalize(productActions.listProducts, productApi.getProductList, productListSchema);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_LIST.REQUEST));
}

function* getProductListManufacturerAndCPSRSaga() {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_LIST2.REQUEST));
  yield callApiSagaAndNormalize(productActions.listProducts2, productApi.getProductList2, productListSchema);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_LIST2.REQUEST));
}

const updateInciQtySaga = createFetchSaga(
  function* updateInciQtySaga({payload, type}) {
    const {ProductId} = payload;
    const rawMatProportions = yield call(productApi.updateInciQty, ProductId);
    const normalizedData = normalize(rawMatProportions, rawMatProportionSchema.rawMatProportionListSchema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(productActions.updateInciQty.success(data, payload));
    },
    onFailure: (error, payload) => put(productActions.updateInciQty.failure(error, payload)),
  },
);

function* exportCompositionForLatinAmericaSaga({payload}) {
  const {productId} = payload;
  yield put(applicationActions.setLoading(productTypes.EXPORT_COMPOSITION_FOR_LATIN_AMERICA.REQUEST));
  yield callApiSaga(
    productActions.exportCompositionForLatinAmerica,
    productApi.exportCompositionForLatinAmerica,
    productId,
  );
  yield put(applicationActions.unsetLoading(productTypes.EXPORT_COMPOSITION_FOR_LATIN_AMERICA.REQUEST));
}

export const saveProductCpsrbCommentSaga = createFetchSaga(
  function* saveProductCpsrbCommentSaga({payload, type}) {
    const product = yield call(productApi.saveProductCpsrbComment, payload);
    const normalizedData = normalize(product, productSchema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(productActions.saveProductCpsrbComment.success(data, payload));
    },
    onFailure: (error, payload) => put(productActions.saveProductCpsrbComment.failure(error, payload)),
  },
);

export function* getProductListForCompanySaga({payload, type}) {
  yield put(applicationActions.setLoading(type));
  const {companyId} = payload;
  try {
    const productList = yield call(productApi.getProductListForCompany, companyId);
    const normalizedData = normalize(productList, productListSchema);
    yield put(productActions.listProductForCompany.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productActions.listProductForCompany.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

function* getProductSaga({payload, type}) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(productActions.product, productApi.getProduct, productSchema, payload);
  yield put(applicationActions.unsetLoading(type));
}

function* getProductOrderListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_ORDER_LIST.REQUEST));
  yield callApiSaga(productActions.listProductOrders, productApi.getProductOrders, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_ORDER_LIST.REQUEST));
}

function* getProductTaskListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_TASK_LIST.REQUEST));
  yield callApiSaga(productActions.listProductTasks, productApi.getProductTasks, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_TASK_LIST.REQUEST));
}

function* getProductImpactAssessmentListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_IMPACT_ASSESSMENT_LIST.REQUEST));
  const res = yield callApiSaga(
    productActions.listProductImpactAssessments,
    productApi.getProductImpactAssessments,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_IMPACT_ASSESSMENT_LIST.REQUEST));
}

function* getProductPhraseListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_PHRASE_LIST.REQUEST));
  yield callApiSaga(productActions.listProductPhrases, productApi.getProductPhrases, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_PHRASE_LIST.REQUEST));
}

function* getProdigumProductProportionListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST.REQUEST));
  yield callApiSaga(
    productActions.listProductProdigumProductProportions,
    productApi.getProdigumProductProportions,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST.REQUEST));
}

function* getImportedProductProportionListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST.REQUEST));
  yield callApiSaga(
    productActions.listProductImportedProductProportions,
    productApi.getImportedProductProportions,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST.REQUEST));
}

function* getProductMosCalculationListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_MOS_CALCULATION_LIST.REQUEST));
  yield callApiSaga(
    productActions.listProductMosCalculationList,
    productApi.getProductMosCalculationList,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_MOS_CALCULATION_LIST.REQUEST));
}

function* getProductFormulaReviewListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_FORMULA_REVIEW_LIST.REQUEST));
  yield callApiSaga(
    productActions.listProductFormulaReviewList,
    productApi.getProductFormulaReviewList,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_FORMULA_REVIEW_LIST.REQUEST));
}

function* getProductImpurityListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_IMPURITY_LIST.REQUEST));
  yield callApiSaga(productActions.listProductImpurityList, productApi.getProductImpurityList, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_IMPURITY_LIST.REQUEST));
}

function* getOrdersOwnedByProductCompanySaga(action) {
  yield put(applicationActions.setLoading(productTypes.ORDERS_OWNED_BY_PRODUCT_COMPANY.REQUEST));
  yield callApiSaga(
    productActions.listOrdersOwnedByProductCompany,
    productApi.getOrdersOwnedByProductCompany,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.ORDERS_OWNED_BY_PRODUCT_COMPANY.REQUEST));
}

function* getCosmosIngredientRawMatMatchListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST.REQUEST));
  yield callApiSaga(
    productActions.listProductCosmosIngredientRawMatMatches,
    productApi.getProductCosmosIngredientRawMatMatches,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST.REQUEST));
}

const takePropsWhichImpactProportionAndRelated = R.props(['ProductUseTypeCode', 'InciRangeInd']);

function* duplicateProductSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_PRODUCT.REQUEST));
  const {product, originalProductId, mode} = action.payload;
  product.CopyMode = mode;
  const savedProductId = yield callApiSaga(
    productActions.saveProduct,
    productApi.duplicateProduct,
    originalProductId,
    product,
  );

  if (savedProductId !== undefined) {
    const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
    yield put(
      replace(
        R.evolve({
          query: R.omit(['duplicateFrom', 'mode']),
          pathname: () => `/products/${savedProductId}`,
        })(previousLocation),
      ),
    );
  }
  yield put(applicationActions.unsetLoading(productTypes.SAVE_PRODUCT.REQUEST));
  return savedProductId;
}

function* saveProductSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_PRODUCT.REQUEST));
  const productToSave = action.payload;
  const productIdToSave = R.prop('ProductId')(productToSave);
  let shouldUpdateProportionAndRelated = false;

  if (productIdToSave) {
    const currentProduct = yield select(
      R.compose(
        R.prop(productIdToSave),
        productSelectors.getById,
      ),
    );
    shouldUpdateProportionAndRelated = R.compose(
      R.gt(R.__, 0),
      R.length,
      R.useWith(R.difference, [takePropsWhichImpactProportionAndRelated, takePropsWhichImpactProportionAndRelated]),
    )(productToSave, currentProduct);
  }

  const productId = yield callApiSaga(productActions.saveProduct, productApi.saveProduct, productToSave);
  if (productId !== undefined) {
    if (!productIdToSave) {
      const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
      yield put(
        replace({
          ...previousLocation,
          pathname: `/products/${productId}`,
          query: R.pick(['show_details'])(previousLocation.query),
        }),
      );
    }
    yield put(productActions.product.request(productId));
    yield put(productActions.listProducts.request());
    if (shouldUpdateProportionAndRelated) {
      yield put(inciListActions.getInciListForProduct.request(productId));
      yield put(shadeActions.fetchShades.request({productId, OptIncludeInciList: true}));
    }
  }
  yield put(applicationActions.unsetLoading(productTypes.SAVE_PRODUCT.REQUEST));
}



function* reportTestSaga(action) {
  yield put(applicationActions.setLoading(productTypes.REPORT_TEST.REQUEST));
  yield callApiSaga(productActions.reportTest, productApi.reportTest, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.REPORT_TEST.REQUEST));
}

function* reportSaga(action) {
  yield put(applicationActions.setLoading(productTypes.REPORT.REQUEST));
  yield callApiSaga(productActions.getProductReport, productApi.getProductReport, action.payload);
  yield put(applicationActions.unsetLoading(productTypes.REPORT.REQUEST));
}

function* updateProductSaga({payload, type}) {
  yield put(applicationActions.setLoading(type));
  const currentProduct = yield select(state => productSelectors.getCurrentProduct(state));
  const updatedProduct = {
    ...currentProduct,
    ...payload,
  };
  const id = yield callApiSaga(productActions.saveProduct, productApi.saveProduct, updatedProduct);
  if (id !== undefined) {
    yield put(productActions.product.request(id));
  }
  yield put(applicationActions.unsetLoading(type));
}

const updateProductStatusSaga = createFetchSaga(
  function* updateProductStatusSaga({payload, type}) {
    const id = yield callApiSaga(productActions.updateProduct, productApi.updateStatus, payload);
    return id;
  },
  {
    * onSuccess(id, payload) {
      yield put(productActions.updateProduct.success(id, payload));
      const isModalShown = yield select(modalSelectors.isModalShown);
      if (isModalShown) {
        yield put(modalActions.hideModal());
      }
      if (id !== undefined) {
        yield put(productActions.product.request(id));
        yield put(productActionActions.listProductActions.request(id));
      }
    },
    onFailure: (error, payload) => put(productActions.updateProduct.failure(error, payload)),
  },
);

function* addOrderToProductSaga(action) {
  yield put(applicationActions.setLoading(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST));
  const {payload} = action;
  yield callApiSaga(productActions.addOrderToCurrentProduct, orderProductApi.saveOrderProducts, [payload]);
  yield put(productActions.listProductOrders.request(payload.ProductId));
  yield put(applicationActions.unsetLoading(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST));
}

function* addOrderToCurrentProductSaga(action) {
  yield put(applicationActions.setLoading(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST));
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  const {payload} = action;
  const data = {
    ...payload,
    ProductId,
  };
  yield callApiSaga(productActions.addOrderToCurrentProduct, orderProductApi.saveOrderProducts, [data]);
  yield put(productActions.listProductOrders.request(ProductId));
  yield put(applicationActions.unsetLoading(productTypes.ADD_ORDER_TO_CURRENT_PRODUCT.REQUEST));
}

function* saveProductProportionsSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST));
  yield callApiSaga(productActions.saveProductProportions, productApi.saveProductProportions, action.payload);
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PRODUCT_PROPORTION) {
    yield put(modalActions.hideModal());
  }
  yield put(productProportionActions.listProductProportionsForProduct.request(ProductId));
  yield put(productActions.listProductMosCalculationList.request(ProductId));
  yield put(inciListActions.getInciListForProduct.request(ProductId));
  yield put(rawMatSupplierActions.listRawMatSuppliers.request());
  yield put(applicationActions.unsetLoading(productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST));
}

function* removeOrderFromProductSaga({payload}) {
  const {ProductId, OrderId} = payload;
  yield put(orderProductActions.deleteOrderProduct.request(ProductId, OrderId));
  yield take(orderProductTypes.DELETE_ORDER_PRODUCT.SUCCESS);
  yield put(productActions.listProductOrders.request(ProductId));
}

function* saveProductPhrasesSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_PRODUCT_PHRASES.REQUEST));
  yield callApiSaga(productActions.saveProductPhrases, productApi.saveProductPhrases, action.payload);
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  yield put(productActions.listProductPhrases.request(ProductId));
  yield put(applicationActions.unsetLoading(productTypes.SAVE_PRODUCT_PHRASES.REQUEST));
}

function* deleteProductPhrasesSaga(action) {
  yield put(applicationActions.setLoading(productTypes.DELETE_PRODUCT_PHRASES.REQUEST));
  yield callApiSaga(productActions.deleteProductPhrases, productApi.deleteProductPhrases, action.payload);
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  yield put(productActions.listProductPhrases.request(ProductId));
  yield put(applicationActions.unsetLoading(productTypes.DELETE_PRODUCT_PHRASES.REQUEST));
}

function* deleteProductProportionSaga(action) {
  yield put(applicationActions.setLoading(productTypes.DELETE_PRODUCT_PROPORTION.REQUEST));
  try {
    yield callApiSaga(productActions.deleteProductProportion, productApi.deleteProductProportion, action.payload);
    const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
    if (ProductId) {
      yield put(productProportionActions.listProductProportionsForProduct.request(ProductId));
    }
  } catch (error) {
    yield put(productActions.deleteProductProportion.failure(error));
  } finally {
    yield put(applicationActions.unsetLoading(productTypes.DELETE_PRODUCT_PROPORTION.REQUEST));
  }
}

function* saveAndLinkProductProportionSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_AND_LINK_PRODUCT_PROPORTION.REQUEST));
  const productProportion = _.omit(action.payload, 'CosmosIngredientId');
  const cosmosRawMatMatch = {
    rawMatId: action.payload.RawMatId,
    CosmosIngredientId: action.payload.CosmosIngredientId,
  };
  yield all([
    callApiSaga(productActions.saveProductProportions, productApi.saveProductProportion, productProportion),
    callApiSaga(rawMatActions.linkCosmosIngredientRawMats, rawMatApi.linkCosmosIngredientRawMats, [cosmosRawMatMatch]),
  ]);
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  yield put(productProportionActions.listProductProportionsForProduct.request(ProductId));
  yield put(productActions.listProductProdigumProductProportions.request(ProductId));
  yield put(applicationActions.unsetLoading(productTypes.SAVE_AND_LINK_PRODUCT_PROPORTION.REQUEST));
}

function* saveAndLinkImportedProductProportionSaga(action) {
  yield put(applicationActions.setLoading(productTypes.SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION.REQUEST));
  const productProportion = _.omit(action.payload, 'CosmosIngredientId');
  yield all([
    callApiSaga(productActions.saveProductProportions, productApi.saveProductProportion, productProportion),
  ]);
  const ProductId = yield select(state => productSelectors.getCurrentProduct(state).ProductId);
  yield put(productProportionActions.listProductProportionsForProduct.request(ProductId));
  yield put(productActions.listProductImportedProductProportions.request(ProductId));
  yield put(applicationActions.unsetLoading(productTypes.SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION.REQUEST));
}

function* changeProductCompositionStatusSaga({payload}) {
  const currentProduct = yield select(state => productSelectors.getCurrentProduct(state));
  const nextStatus = yield select(state => workflowStatusSelectors.getWorkflowStatus(state, payload));
  const currentStatus = yield select(state =>
    workflowStatusSelectors.getWorkflowStatus(state, currentProduct.CompositionStatus),
  );

  yield put(
    modalActions.showModal({
      modalType: modalTypes.PRODUCT_COMPOSITION_STATUS,
      modalProps: {
        productId: currentProduct.ProductId,
        title: `Change composition status from ${currentStatus.Name} to ${nextStatus.Name}`,
        label: 'Provide a comment (Optional)',
        WorkflowStatusCode: payload,
      },
    }),
  );
}

export function* getLastFRCheckListSaga(action) {
  yield put(applicationActions.setLoading(productTypes.FETCH_LAST_FR_CHECK_LIST.REQUEST));
  yield callApiSaga(productActions.fetchLastFRCheckList, productApi.getLastFRProductData, action.payload.productId);
  yield put(applicationActions.unsetLoading(productTypes.FETCH_LAST_FR_CHECK_LIST.REQUEST));
}

function* checkListChangeStatusSaga(action) {
  yield put(applicationActions.setLoading(productTypes.CHECK_LIST_CHANGE_STATUS.REQUEST));
  try {
    yield callApiSaga(productActions.checkListChangeStatusDocument, productApi.checkListChangeStatusDocument, action.payload);
    yield put(productActions.fetchLastFRCheckList.request(action.payload.productId));

  } catch (error) {
    yield put(productActions.missingDocumentChangeStatus.failure(error));
  } finally {
    yield put(applicationActions.unsetLoading(productTypes.CHECK_LIST_CHANGE_STATUS.REQUEST));
  }
}

function* getLastFRMissingDocumentsSaga(action) {
  yield put(applicationActions.setLoading(productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST));
  yield callApiSaga(productActions.fetchLastFRMissingDocument, productApi.getLastFRMissingDocument, action.payload.productId);
  yield put(applicationActions.unsetLoading(productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST));
}

function* missingDocumentChangeStatusSaga(action) {
  yield put(applicationActions.setLoading(productTypes.MISSING_DOCUMENT_CHANGE_STATUS.REQUEST));
  try {
    yield callApiSaga(productActions.missingDocumentChangeStatus, formulaReviewMissingDocumentApi.changeStatus, action.payload);
    yield put(productActions.fetchLastFRMissingDocument.request(action.payload.productId));

  } catch (error) {
    yield put(productActions.missingDocumentChangeStatus.failure(error));
  } finally {
    yield put(applicationActions.unsetLoading(productTypes.MISSING_DOCUMENT_CHANGE_STATUS.REQUEST));
  }
}
