import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { productActions, productSelectors, productTypes } from '../../../redux/product';
import { productDocumentActions } from '../../../redux/productDocument';
import { productProportionTypes } from '../../../redux/productProportion';
import { rawMatActions, rawMatTypes } from '../../../redux/rawMat';
import { applicationSelectors } from '../../../redux/application';
import * as selectors from './selectors';
import CertCompositionTab from './CertCompositionTab';
import BaseLoader from '../../_Common/BaseLoader';
import { modalActions } from '../../../redux/modal';
import { push } from 'react-router-redux';

class CertCompositionContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    productId: PropTypes.number.isRequired,
    isLoadingCosmosCompositionTab: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.productId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.productId !== this.props.productId && this.props.productId > 0) {
      // console.log('CosmosCompositionContainer Updated');
      this.props.onLoad();
    }
  }

  render() {
    return (
      <BaseLoader isLoading={this.props.isLoadingCosmosCompositionTab}>
        <CertCompositionTab {...this.props} />
      </BaseLoader>
    );
  }
}

const mapStateToProps = (state, { productId }) => {
  const {
    product: {
      currentProductProportions,
      currentImportedProductProportions,
      currentProductCosmosIngredientRawMatMatches,
    },
  } = state;
  return {
    rawMatList: selectors.getRawMatListForProduct(state)(productId),
    currentProductProportions,
    currentImportedProductProportions,
    currentProductCosmosIngredientRawMatMatches,
    isLoadingCosmosCompositionTab: applicationSelectors.isLoading(
      state,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      productTypes.PRODUCT_COSMOS_INGREDIENT_RAWMAT_MATCH_LIST.REQUEST,
      productTypes.PRODUCT_PRODIGUM_PRODUCT_PROPORTION_LIST.REQUEST,
      productTypes.PRODUCT_IMPORTED_PRODUCT_PROPORTION_LIST.REQUEST,
      productTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST,
      rawMatTypes.LINK_COSMOS_INGREDIENT_RAWMATS.REQUEST,
      rawMatTypes.RAWMAT_LIST.REQUEST,
      rawMatTypes.CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT.REQUEST,
      productTypes.SAVE_AND_LINK_IMPORTED_PRODUCT_PROPORTION.REQUEST,
    ),
    // currentProdigumProductProportionsNotMatched: productSelectors.getProdigumProductProportionsNotMatched(state),
    currentImportedProductProportionsNotMatched: productSelectors.getImportedProductProportionsNotMatched(state),
    currentProductProportionsMatched: productSelectors.getCERTProductProportionsMatched(state),
    currentProductProportionsNotMatched: productSelectors.getCERTProductProportionsNotMatched(state),
    certCompositionList: productSelectors.getImportedCompositionList(state),
  };
};

const mapDispatchToProps = (dispatch, { productId, importedProductProportionId }) => ({
  onLoad: () => {
    // dispatch(productActions.listProductProdigumProductProportions.request(productId));
    dispatch(productActions.listProductImportedProductProportions.request(productId));
    // dispatch(productActions.listProductCosmosIngredientRawMatMatches.request(productId));
  },
  saveProductProportion: data => {
    dispatch(productActions.saveProductProportions.request(data));
  },
  saveAndLinkProductProportion: (data) => {
    dispatch(productActions.saveAndLinkImportedProductProportion.request(data));
  },
  linkCosmosIngredientToRawMat: data => {
    dispatch(rawMatActions.linkCosmosIngredientRawMatRequest(data));
  },
  deleteProductProportion: id => dispatch(productActions.deleteProductProportion.request(id)),
  createAndLinkRawMatFromProdigumIngredient: ({ ReferencedId }) =>
    dispatch(rawMatActions.createAndLinkRawMatFromProdigumIngredientRequest(ReferencedId, productId)),
  onUploadFile: (productId, data) => {
    // dispatch(productDocumentActions.uploadProductComposition.request(productId, data))
    // dispatch(productActions.processImportedProductProportions.request(productId, data))
    dispatch(productActions.importProductProportions.request(productId, data))
  },
  onSelectRMButtonClick: (importedProductProportionId, RMName, RMSupplier, RMProportions) => {
    dispatch(modalActions.showProductProportionSelectRMModal({ productId, importedProductProportionId, RMName, RMSupplier, RMProportions }))
  },
  onCreateRMButtonClick: (importedProductProportionId) => {
    dispatch(push({ pathname: '/rawmats/-1', query: { returnToProduct: productId, tab: 200, importedProductProportionId: importedProductProportionId }}))
  },
  onDeleteCompositionButtonClick: (productId) => {
    dispatch(productActions.deleteImportedProductProportions.request(productId))
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CertCompositionContainer);
