import { createSelector } from 'reselect';
import * as documentSelectors from '../document/documentSelectors';
import * as rawMatSelectors from '../rawMat/rawMatSelectors';
import config from '../../config';

const API_ROOT = config.baseUrl;

export const getById = ({ rawMatDocument }) => rawMatDocument.byId;
export const getIds = ({ rawMatDocument }) => rawMatDocument.list.allIds;
export const getRawMatDocument = (state, id) => getById(state)[id];
export const getRawMatDocumentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getRawMatDocumentListForRawMat = createSelector(
  getById,
  rawMatSelectors.getById,
  documentSelectors.getById,
  (rawMatDocumentById, rawMatById, documentById) =>
    createSelector(
      rawMatId => rawMatById[rawMatId],
      rawMat => {
        if (!rawMat || !rawMat.RawMatDocuments) return [];
        return rawMat.RawMatDocuments.map(rdId => {
          const rawMatDocument = rawMatDocumentById[rdId];
          return {
            ...rawMatDocument,
            Document: {
              ...documentById[rawMatDocument.DocumentId],
              FileLink: `${API_ROOT}/RawMatsDocuments/${rdId}/File`,
            },
          };
        });
      },
    ),
);
