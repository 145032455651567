import * as toxProfileItemSelectors from './toxProfileItemSelectors';
import * as toxProfileItemActions from './toxProfileItemActions';
import * as toxProfileItemTypes from './toxProfileItemTypes';
import sagas from './toxProfileItemSagas';

import reducer from './toxProfileItemReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { toxProfileItemSelectors, toxProfileItemActions, toxProfileItemTypes };
export default reducer;
