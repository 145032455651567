import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { GeneralGrid, MeasureCellRenderer, AmountCellRenderer, DateCellRenderer } from '../_Common/Grids';
import { Grid, Row, Col, ControlLabel, Button, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { Translate } from 'react-redux-i18n';
import { taskSelectors } from '../../redux/task';
import { orderSelectors } from '../../redux/order';
import { connect } from 'react-redux';
import DurationCellRenderer from '../_Common/Grids/DurationCellRenderer';
import PriceCellRenderer from '../_Common/Grids/PriceCellRenderer';

class PlannerSearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: {},
    };
  }

  onSearch() {
    this.props.search(this.state.searchCriteria);
  }

  onClear = () => {
    this.setState({
      searchCriteria: {
        producerId: '',
        orderId: '',
        accountManager: '',
        expert: '',
        progressFrom: '',
        progressTo: '',
      },
    });
  };

  getCompanyNameById = ({ colDef, data }) => {
    const c = this.props.companyList.find(company => company.CompanyId === data.ProducerId);
    return c?c.FullName:"";
  }

  getOrderValue = ({ colDef, data }) => data.Bill * (data.PercentageDone / 100);

  getValueWithCurrency = ({ colDef, data }) => {
    return 'EUR ' + data.Bill ;
  };

  getEarnedValueWithCurrency = ({ colDef, data }) => {
    const val = data.Bill * (data.PercentageDone / 100);
    return 'EUR ' + (Math.round(val * 100) / 100);
  };

  render() {
    const { expertList, isLoading, orderPlannerList, companyList, accountManagerList, orderList, onRowDoubleClicked, currencies } = this.props;

    const columns = [
      {
        headerName: I18n.t('plannerDashboard.company'),
        field: 'Producer',
        valueGetter: this.getCompanyNameById,
      },
      {
        headerName: I18n.t('plannerDashboard.order'),
        field: 'OrderNr',
        cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
      },
      {
        headerName: I18n.t('plannerDashboard.createdOn'),
        field: 'CreatedOn',
        cellrenderer: DateCellRenderer
      },
      {
        headerName: I18n.t('plannerDashboard.orderStatus'),
        field: 'OrderStatus',
        with: 70
        // cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
      },
      {
        headerName: I18n.t('plannerDashboard.startedOn'),
        field: 'StartDate',
      },
      {
        headerName: I18n.t('plannerDashboard.naturalBill'),
        field: 'Bill',
        valueGetter: this.getValueWithCurrency,
      },
      {
        headerName: I18n.t('plannerDashboard.convertedBill'),
        field: 'Bill',
        cellRenderer: PriceCellRenderer,
        cellRendererParams: {
          currencies,
          currencyLabel: 'CurrencyCode',
          withLabel: true,
        },
      },
      {
        headerName: I18n.t('plannerDashboard.naturalValue'),
        field: 'Value',
        valueGetter: this.getEarnedValueWithCurrency,
      },
      {
        headerName: I18n.t('plannerDashboard.convertedValue'),
        field: 'Value',
        valueGetter: this.getOrderValue,
        cellRenderer: PriceCellRenderer,
        cellRendererParams: {
          currencies,
          currencyLabel: 'CurrencyCode',
          withLabel: true,
        },
      },
      {
        headerName: I18n.t('plannerDashboard.progress'),
        field: 'PercentageDone',
        cellRenderer: MeasureCellRenderer,
        cellRendererParams: {
          nbDecimal: 2,
        },
      },
      {
        headerName: I18n.t('plannerDashboard.estimateDuration'),
        field: 'ExpectedDuration',
        cellRenderer: DurationCellRenderer,
      },
      {
        headerName: I18n.t('plannerDashboard.actualDurantion'),
        field: 'ActualSecond',
        cellRenderer: DurationCellRenderer,
      },
    ];

    const companyOrderList = this.state.searchCriteria.producerId
      ? orderList.filter(order => order.ProducerId === this.state.searchCriteria.producerId)
      : orderList;

    return (
      <Grid fluid>
        <Row style={{ marginBottom: '10px' }}>
          <Col sm={3}>
            <ControlLabel>
              <Translate value="plannerDashboard.producer" />
            </ControlLabel>
            <Select
              options={companyList}
              labelKey="ShortName"
              valueKey="CompanyId"
              value={this.state.searchCriteria.producerId}
              onChange={value => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    producerId: value ? value.CompanyId : null,
                  },
                });
              }}
              clearable
            />
          </Col>
          <Col sm={3}>
            <ControlLabel>
              <Translate value="plannerDashboard.order" />
            </ControlLabel>
            <Select
              options={companyOrderList}
              labelKey="OrderNr"
              valueKey="OrderId"
              value={this.state.searchCriteria.orderId}
              onChange={value => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    orderId: value ? value.OrderId : null,
                  },
                });
              }}
              clearable
            />
          </Col>
          <Col sm={3}>
            <ControlLabel>
              <Translate value="plannerDashboard.accountManager" />
            </ControlLabel>
            <Select
              options={accountManagerList}
              labelKey="LoginName"
              valueKey="LoginName"
              value={this.state.searchCriteria.accountManager}
              onChange={value => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    accountManager: value ? value.LoginName : null,
                  },
                });
              }}
              clearable
            />
          </Col>
          <Col sm={3}>
            <ControlLabel>
              <Translate value="plannerDashboard.expert" />
            </ControlLabel>
            <Select
              options={expertList}
              labelKey="LoginName"
              valueKey="LoginName"
              value={this.state.searchCriteria.expert}
              onChange={value => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    expert: value ? value.LoginName : null,
                  },
                });
              }}
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col sm={1}>
            <ControlLabel>
              <Translate value="plannerDashboard.progressFrom" />
            </ControlLabel>
            <FormControl
              type="text"
              bsSize="small"
              value={this.state.searchCriteria.progressFrom}
              onChange={event => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    progressFrom: event.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col sm={1}>
            <ControlLabel>
              <Translate value="plannerDashboard.to" />
            </ControlLabel>
            <FormControl
              type="text"
              bsSize="small"
              value={this.state.searchCriteria.progressTo}
              onChange={event => {
                this.setState({
                  searchCriteria: {
                    ...this.state.searchCriteria,
                    progressTo: event.target.value,
                  },
                });
              }}
            />
          </Col>
          <Col sm={2}>&nbsp;</Col>
          <Col sm={1}>
            <Button style={{ marginTop: '22px' }} bsStyle="danger" bsSize="small" onClick={this.onClear}>
              <Translate value="generalDashboard.clearCriteria" />
              &nbsp;
              <i className="fa fa-search" aria-hidden="true" />
            </Button>
          </Col>
          <Col sm={1}>
            <Button
              style={{ marginTop: '22px' }}
              bsStyle="success"
              bsSize="small"
              onClick={() => this.onSearch(this.state.searchCriteria)}
            >
              <Translate value="generalDashboard.search" />
              &nbsp;
              <i className="fa fa-search" aria-hidden="true" />
            </Button>
          </Col>
        </Row>
        <div>&nbsp;</div>
        <Row>
          <Col sm={12}>
            <GeneralGrid
              data={orderPlannerList}
              columns={columns}
              keyName="OrderId"
              isLoading={isLoading}
              onRefresh={this.search}
              rowDoubleClicked={onRowDoubleClicked}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => {
  const getTasks = taskSelectors.makeGetByQuery(state);
  return {
    getTasksSelector: getTasks,
  };
};

export default connect(
  mapStateToProps,
  null,
)(PlannerSearchList);

PlannerSearchList.propTypes = {
  taskList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  search: PropTypes.func.isRequired,
};
