import * as cosAnnexeTRSelectors from './cosAnnexeTRSelectors';
import * as cosAnnexeTRActions from './cosAnnexeTRActions';
import * as cosAnnexeTRTypes from './cosAnnexeTRTypes';
import * as cosAnnexeTRSchemas from './cosAnnexeTRSchemas';

import sagas, * as cosAnnexeTRSagas from './cosAnnexeTRSagas';
import reducer from './cosAnnexeTRReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { cosAnnexeTRSelectors, cosAnnexeTRActions, cosAnnexeTRTypes, cosAnnexeTRSchemas, cosAnnexeTRSagas };
export default reducer;
