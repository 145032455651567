import { createAction } from 'redux-actions';
// import { createFormAction } from 'redux-form-saga';
import { createRequestActions } from '../utils';

import * as impactAssessmentProductTypes from './impactAssessmentProductTypes';
// import workflowTypes from '../../constants/workflowTypes';

export const generateImpactAssessmentCandidateProducts = createRequestActions(
  impactAssessmentProductTypes.IMPACT_ASSESSMENT_CANDIDATE_PRODUCT_LIST,
);

export const calculateImpactAssessmentValues = createRequestActions(
  impactAssessmentProductTypes.CALCULATE_IMPACT_ASSESSMENT_VALUE_LIST,
);
export const getImpactAssessmentValues = createRequestActions(
  impactAssessmentProductTypes.GET_IMPACT_ASSESSMENT_VALUE_LIST,
);
export const listImpactAssessmentProducts = createRequestActions(
  impactAssessmentProductTypes.IMPACT_ASSESSMENT_PRODUCT_LIST,
);
export const saveImpactAssessmentProduct = createRequestActions(
  impactAssessmentProductTypes.SAVE_IMPACT_ASSESSMENT_PRODUCT,
);
export const newImpactAssessmentProduct = createAction(impactAssessmentProductTypes.NEW_IMPACT_ASSESSMENT_PRODUCT);

export const confirmImpactAssessmentProduct = createRequestActions(
  impactAssessmentProductTypes.CONFIRM_IMPACT_ASSESSMENT_PRODUCT,
);
export const ignoreImpactAssessmentProduct = createRequestActions(
  impactAssessmentProductTypes.IGNORE_IMPACT_ASSESSMENT_PRODUCT,
);
export const reviewImpactAssessmentProduct = createRequestActions(
  impactAssessmentProductTypes.REVIEW_IMPACT_ASSESSMENT_PRODUCT,
);
