import * as shadeInciListSelectors from './shadeInciListSelectors';
import * as shadeInciListActions from './shadeInciListActions';
import * as shadeInciListTypes from './shadeInciListTypes';
import * as shadeInciListSchemas from './shadeInciListSchemas';
import sagas, * as shadeInciListSagas from './shadeInciListSagas';
import reducer from './shadeInciListReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { shadeInciListSelectors, shadeInciListActions, shadeInciListTypes, shadeInciListSchemas, shadeInciListSagas };
export default reducer;
