import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import Textarea from './_Common/Textarea';
import {
  labelSymbolWizardSelectors,
  labelSymbolWizardActions,
} from '../../../redux/wizard/labelReviewWizard/labelSymbolWizard';
import {documentSelectors} from "../../../redux/document";
import ImageDropzoneForm from "../../_Common/Forms/ImageDropzoneForm";
import * as labelReviewSymbolWizardActions
  from "../../../redux/wizard/labelReviewWizard/labelReviewSymbolWizard/labelReviewSymbolWizardActions";

const LabelSymbol = props => (
  <Col sm={12}>
    {props.IsPublic ? (
      <div>
        <Col sm={5}>
          {props.FilePath && <Image src={props.FilePath} responsive style={{ width: 'auto', margin: 'auto' }} />}
        </Col>
        <Col sm={7}>
          <b>{props.Description}</b>
        </Col>
      </div>
    ) : (
      <div>
        {props.Description ? (
          <Col sm={12}>
            <Textarea text={props.Description} fieldName="Description" onChange={props.handleFieldEdit} />
          </Col>
        ) : (
          <Col sm={5}>
            <ImageDropzoneForm
              image={props.ImageByte}
              onUploadFile={props.onUploadFile}
              height={100}
              width={100}
            />
          </Col>
        )}
      </div>
    )}
  </Col>
);

LabelSymbol.defaultProps = {
  Description: undefined,
  FilePath: undefined,
};

LabelSymbol.propTypes = {
  Description: PropTypes.string,
  handleFieldEdit: PropTypes.func.isRequired,
  FilePath: PropTypes.string,
  IsPublic: PropTypes.bool.isRequired,
};

const mapStateToProps = (_, ownProps) => {
  const { code } = ownProps;
  return state => {
    const labelSymbol = labelSymbolWizardSelectors.getLabelSymbol(state, code);
    const filename = labelSymbol && prop('Filename', labelSymbol);
    const docId = labelSymbol && prop('DocumentId', labelSymbol);
    const imageByte = docId && documentSelectors.getDocumentBytesById(state, docId);
    return {
      Description: labelSymbol && prop('Description', labelSymbol),
      ImageByte: imageByte,
      IsPublic: prop('Public', labelSymbol),
      FilePath: filename && require(`../../../assets/img/${filename}`), //eslint-disable-line
    };
  };
};

const mapDispatchToProps = (_, ownProps) => {
  const { code } = ownProps;
  return dispatch => ({
    handleFieldEdit: data => dispatch(labelSymbolWizardActions.handleFieldEdit(code, data)),
    onUploadFile: data => dispatch(labelReviewSymbolWizardActions.uploadSymbolImage.request(code, data)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelSymbol);
