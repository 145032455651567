import { createSelector } from 'reselect';

export const getById = ({ document }) => document.byId;
export const getImageById = ({ document }) => document.imageById;
export const getIds = ({ document }) => document.list.allIds;
export const getDocument = (state, id) => getById(state)[id];

const getDocumentById = id => createSelector(getById, byId => byId[id]);
export const getDocumentBytesById = (state, id) => getImageById(state)[id];

export const getDocumentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const makeGetDocumentById = createSelector(getDocumentById, docById => docById);
