import { handleActions } from 'redux-actions';
import * as labelReviewInciWizardTypes from './labelReviewInciWizardTypes';

export default handleActions(
  {
    [labelReviewInciWizardTypes.LOAD_LABEL_REVIEW_INCI_DATA]: (state, { payload }) => ({
      ...payload,
    }),
    [labelReviewInciWizardTypes.COMMENT_EDITED]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);
