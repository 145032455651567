import React from 'react';
import PropTypes from 'prop-types';
import ButtonDropdown from '../../../_Common/ButtonDropDown';

const AddProductDropdown = ({ productList, onAddButtonClick, readonly }) => (
  <div>
    <ButtonDropdown
      options={productList}
      buttonText="Add"
      labelKey="ProductName"
      valueKey="ProductId"
      onButtonClick={onAddButtonClick}
      readonly={readonly}
    />
  </div>
);

AddProductDropdown.propTypes = {
  productList: PropTypes.array.isRequired, //eslint-disable-line
  onAddButtonClick: PropTypes.func.isRequired,
};

export default AddProductDropdown;
