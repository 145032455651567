import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as homePageFormTypes from './homePageFormTypes';

const isLoadingHomePageForm = handleActions(
  {
    [homePageFormTypes.FETCH_HOMEPAGE_FORM.REQUEST]: (state, { payload }) => true,
    [homePageFormTypes.FETCH_HOMEPAGE_FORM.SUCCESS]: (state, { payload }) => false,
    [homePageFormTypes.FETCH_HOMEPAGE_FORM.FAILURE]: (state, { payload }) => false,
  },
  false,
);
const isSavingHomePageForm = handleActions(
  {
    [homePageFormTypes.SAVE_HOMEPAGE_FORM.REQUEST]: (state, { payload }) => true,
    [homePageFormTypes.SAVE_HOMEPAGE_FORM.SUCCESS]: (state, { payload }) => false,
    [homePageFormTypes.SAVE_HOMEPAGE_FORM.FAILURE]: (state, { payload }) => false,
  },
  false,
);

const homePageId = handleActions(
  {
    [homePageFormTypes.FETCH_HOMEPAGE_FORM.SUCCESS]: (state, { payload }) => payload.result,
  },
  null,
);

export default combineReducers({
  isLoadingHomePageForm,
  isSavingHomePageForm,
  homePageId,
});
