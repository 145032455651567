import { createSelector } from 'reselect';
// import { referentialSelectors } from '../referentials';

export const getIds = ({ generalDashboard }) => generalDashboard.list.allIds;
export const getById = ({ generalDashboard }) => generalDashboard.byId;
export const getGeneralDashboard = (state, id) => state.generalDashboard.byId[id];
export const getProductList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getGeneralDashboardProductList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);

/*
export const getDetailedgeneralDashboardList = createSelector(
  [getProductList, referentialSelectors.getCpsrTypeList],
  (generalDashboardList, cpsrTypeList) =>
    generalDashboardList.map(generalDashboard => ({
      ...generalDashboard,
      CpsrType: cpsrTypeList.find(cpsrType => cpsrType.CpsrTypeCode === generalDashboard.CpsrTypeCode),
    })),
);

export const getTablegeneralDashboardForCpsrType = cpsrType =>
  createSelector(getProductList, generalDashboardList => {
    const result = generalDashboardList.find(
      generalDashboard =>
        generalDashboard.CpsrTypeCode === cpsrType && generalDashboard.generalDashboardCode.startsWith('TB'),
    );
    return result;
  });

const getgeneralDashboardListForCpsrTypeCode = cpsrTypeCode =>
  createSelector(getProductList, generalDashboardList =>
    generalDashboardList.filter(generalDashboard => generalDashboard.CpsrTypeCode === cpsrTypeCode),
  );

export const makeGetgeneralDashboardListForCpsrTypeCode = createSelector(
  getgeneralDashboardListForCpsrTypeCode,
  generalDashboardListForCpsrTypeCode => generalDashboardListForCpsrTypeCode,
);
*/
