import { createSelector } from 'reselect';
import { isDirty, formValueSelector } from 'redux-form';
import * as R from 'ramda';
import { isNullOrUndef } from '../../../utils/utils';
import formNames from '../../../constants/formNames';
import { companySelectors } from '../../company';
import { routingSelectors } from '../../routing';

const formName = formNames.company;

const defailsFormValueSelector = formValueSelector(formName);

const defaultInitialValue = {
  IsActive: true,
};

export const getInDuplicateMode = createSelector(
  routingSelectors.getQuery,
  R.propSatisfies(duplicateFromId => Number.isInteger(Number.parseInt(duplicateFromId, 10)), 'duplicateFrom'),
);

export const getDuplicateFromId = createSelector(routingSelectors.getQuery, R.propOr(null, 'duplicateFrom'));

const createDuplicateAddress = R.evolve({
  AddressId: () => null,
});

const createDuplicateContact = R.evolve({
  ContactId: () => null,
});

const createDuplicate = R.evolve({
  CompanyId: () => -1,
  CreatedOn: () => null,
  CreatedBy: () => null,
  CorporateAddressId: () => null,
  CorporateContactId: () => null,
  Address: createDuplicateAddress,
  Contact: createDuplicateContact,
});

export const makeGetInitialValues = createSelector(
  [companySelectors.getById, getDuplicateFromId],
  (companyById, duplicateFromId) =>
    createSelector(R.identity, companyId => {
      const company = duplicateFromId ? companyById[duplicateFromId] : companyById[companyId];
      if (!company) return defaultInitialValue;
      return duplicateFromId ? createDuplicate(company) : company;
    }),
);

export const isNew = state => {
  const id = defailsFormValueSelector(state, 'CompanyId');
  return id <= 0 || isNullOrUndef(id);
};

export const isFormDirty = isDirty(formName);
