import * as labelReviewWizardSelectors from './labelReviewWizardSelectors';
import * as labelReviewWizardActions from './labelReviewWizardActions';
import * as labelReviewWizardTypes from './labelReviewWizardTypes';
import sagas from './labelReviewWizardSagas';
import reducer from './labelReviewWizardReducer';

import sagaManager from '../../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { labelReviewWizardSelectors, labelReviewWizardActions, labelReviewWizardTypes };
export default reducer;
