import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import * as R from 'ramda';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { referentialSelectors } from '../../../../redux/referentials';
import { rawMatPhraseActions } from '../../../../redux/rawMatPhrase';
import { rawMatPhraseFormSelectors } from '../../../../redux/form/rawMatPhraseForm';
import { InputField, SelectField } from '../../../_Common/Forms';
import InfoRmPhraseList from './InfoRmPhraseList';
import { requiredField, maxLength256, maxLength1024, isInteger } from '../../../../utils/fieldValidation';

const formatValue = R.compose(
  R.when(R.and(R.complement(R.isEmpty), R.complement(R.is(String))), R.toString),
  R.defaultTo(''),
);

const RawMatInformationForm = props => {
  const { cpsrChpaterList } = props;
  return (
    <Form>
      <Grid fluid>
        <Row>
          <Field name="PhraseId" component={InfoRmPhraseList} validate={requiredField} format={formatValue} />
        </Row>
        &nbsp;
        <Row>
          <Col sm={6}>
            <Field
              name="Description"
              label={`${I18n.t('rawMatPhrase.Description')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength256}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="Specification"
              label={`${I18n.t('rawMatPhrase.Specification')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength1024}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Field
              name="Method"
              label={`${I18n.t('rawMatPhrase.Method')}`}
              component={InputField}
              componentClass="textarea"
              validate={maxLength256}
            />
          </Col>
          <Col sm={4}>
            <Field
              name="RawMatInfoChapterCode"
              label={`${I18n.t('rawMatPhrase.CpsrChapterCode')} *`}
              component={SelectField}
              options={cpsrChpaterList}
              valueField="Key"
              labelField="Value"
              validate={requiredField}
            />
          </Col>
          <Col sm={1}>
            <Field
              name="OrderNr"
              label={`${I18n.t('rawMatPhrase.OrderNr')} *`}
              component={InputField}
              validate={[requiredField, isInteger]}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { rawMatPhraseId }) => {
  const getFormInitialValues = rawMatPhraseFormSelectors.makeGetInitialValues(rawMatPhraseId);
  return {
    cpsrChpaterList: referentialSelectors.getCpsrInfoRmChapters(state),
    initialValues: getFormInitialValues(state),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onSubmit: data => dispatch(rawMatPhraseActions.saveRawMatPhrase.request({ ...data, rawMatId })),
});

RawMatInformationForm.propTypes = {
  cpsrChpaterList: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formNames.rawMatInfo,
    enableReinitialize: true,
    destroyOnUnmount: true,
  }),
)(RawMatInformationForm);
