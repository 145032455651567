import { createSelector } from 'reselect';
import moment from 'moment';
import * as productSelectors from '../product/productSelectors';
import { createGeneralSelectors } from '../_common/selectors';
import { isNullOrUndef, isUndefined } from '../../utils/utils';

const getLabelReviewSlice = state => state.labelReview;

export const { makeGetByQueryLoading, makeGetByQuery } = createGeneralSelectors(getLabelReviewSlice);

export const getById = ({ labelReview }) => labelReview.byId;
export const getIds = ({ labelReview }) => labelReview.list.allIds;
export const getLabelReview = (state, id) => getById(state)[id];
export const getLabelReviewList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getLabelReviewListForProduct = createSelector(
  [getLabelReviewList, productSelectors.getProduct],
  (labelReviewList, product) =>
    (isNullOrUndef(product) ? [] : labelReviewList.filter(fr => fr.ProductId === product.ProductId)),
);

export const getLatestLabelReviewForProduct = createSelector(getLabelReviewListForProduct, labelReviewList =>
  labelReviewList.reduce((previousLatestLabelReview, currentLabelReview) => {
    if (isUndefined(previousLatestLabelReview)) return currentLabelReview;
    return moment(previousLatestLabelReview.CreatedOn).isAfter(moment(currentLabelReview.CreatedOn))
      ? previousLatestLabelReview
      : currentLabelReview;
  }, undefined),
);

export const isAnyLabelReviewForProduct = createSelector(
  getLabelReviewListForProduct,
  labelReviewList => labelReviewList.length > 0,
);
