import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';
import { shadeSelectors } from '../../../../redux/shade';
import { shadeProportionActions, shadeProportionTypes } from '../../../../redux/shadeProportion';
import { applicationSelectors } from '../../../../redux/application';
import ShadeCompoModal from './ShadeCompoModal';
import * as ShadeCompoSelectors from './ShadeCompoSelectors';

const ShadeCompoModalContainer = props => <ShadeCompoModal {...props} />;

const mapStateToProps = (state, { shadeId, productId }) => {
  const shade = shadeSelectors.getById(state)[shadeId];
  const shadeName = R.propOr('', 'ShadeName', shade);
  const getInitialValues = ShadeCompoSelectors.makeGetShadeCompoForShade(shadeId);
  return {
    title: I18n.t('shadeCompoModal.title', { shadeName }),
    initialValues: getInitialValues(state),
    isSaveDisabled: applicationSelectors.isLoading(
      state,
      shadeProportionTypes.SAVE_SHADE_PROPORTIONS.REQUEST,
      shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSaveButtonClick: data => dispatch(shadeProportionActions.saveShadeProportions.request(productId, data)),
});

ShadeCompoModalContainer.propTypes = {
  shadeId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShadeCompoModalContainer);
