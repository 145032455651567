import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { CustomFilterGrid, LinkCellRenderer, DateCellRenderer } from '../../_Common/Grids';
import { Grid, Row, Col } from 'react-bootstrap';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const CompanyOrderList = props => {
  const { currentCompanyOrders, requestingCompanyOrderList, onRefresh, onRowDoubleClicked } = props;

  const columns = [
    {
      headerName: I18n.t('order.OrderNr'),
      field: 'OrderNr',
      sort: 'desc',
      cellRendererFramework: LinkCellRenderer,
      width: 75,
      cellRendererParams: {
        textKey: 'OrderNr',
        linkKey: 'Url',
        downloadable: false
      },
      suppressSizeToFit: true,
    }
    // {
    //   headerName: I18n.t('order.OrderNr'),
    //   width: 100,

    //   field: 'OrderNr',
    //   suppressSizeToFit: true,
    //   sort: 'desc',
    // }
    ,
    {
      headerName: I18n.t('order.description'),
      width: 100,
      field: 'Description',
      // suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.startDate'),
      width: 100,
      field: 'StartDate',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('order.endDate'),
      width: 100,
      field: 'EndDate',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('order.Status'),
      width: 150,
      field: 'Status',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.progress'),
      width: 90,
      field: 'Progress',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('order.expert'),
      width: 100,
      field: 'Expert',
      suppressSizeToFit: true,
    },
  ];

  const radioFilter = (node, radioButtonsValue) => {
    switch (radioButtonsValue) {
      case 'withEndDate':
        return node.data.EndDate !== null;
      case 'withoutEndDate':
        return node.data.EndDate === null;
      default:
        return true;
    }
  };

  const radioButtons = [
    { value: 'all', label: 'Show all', default: true },
    { value: 'withEndDate', label: 'Show only if with end date' },
    { value: 'withoutEndDate', label: 'Show only if without end date' },
  ];

  currentCompanyOrders.forEach(element => {
    element.Url = '#orders/' + element.OrderId;
  });

  return (
    <Grid fluid>
      <Row>
        <Col sm={10}>
          <CustomFilterGrid
            data={currentCompanyOrders}
            columns={columns}
            isLoading={requestingCompanyOrderList}
            onRefresh={onRefresh}
            rowDoubleClicked={onRowDoubleClicked}
            showFilterRow={false}
            radioFilter={radioFilter}
            radioButtons={radioButtons}
          />
        </Col>
        <Col sm={2}>
          {/* <DropzoneForm {...props} /> */}
        </Col>
      </Row>
    </Grid>
  );
};

CompanyOrderList.propTypes = {
  currentCompanyOrders: PropTypes.arrayOf(
    PropTypes.shape({
      OrderNr: PropTypes.string,
      Description: PropTypes.string,
    }),
  ).isRequired,
  onRefresh: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  requestingCompanyOrderList: PropTypes.bool.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
};

export default CompanyOrderList;
