import {takeLatest, put, takeEvery, select, all, take, call} from 'redux-saga/effects';
import {replace, LOCATION_CHANGE} from 'react-router-redux';
import * as R from 'ramda';
import {createSaveFormWithValidation} from '../form/utils';
import {applicationActions} from '../application';
import {callApiSagaAndNormalize, callApiSaga} from '../sagaUtils';
import orderApi from '../../api/orderApi';
import * as orderImportTypes from './orderImportTypes';
import * as orderImportActions from './orderImportActions';
import * as orderImportSchemas from './orderImportSchemas';
// import * as orderImportSelectors from './orderImportSelectors';

export default function* root() {
  yield all([
    // takeEvery(LOCATION_CHANGE, onLocationChange),
    takeLatest(orderImportTypes.INITIALIZE_POLLING.REQUEST, initializePollingSaga),
    takeLatest(orderImportTypes.POLL.REQUEST, pollSaga),
    takeLatest(orderImportTypes.GOTOHANGFIRE.REQUEST, goToHangfireSaga),
    takeLatest(orderImportTypes.ORDER_IMPORT_LIST.REQUEST, getOrderImportListSaga),
  ]);
}

function* initializePollingSaga() {
  yield put(applicationActions.setLoading(orderImportTypes.INITIALIZE_POLLING.REQUEST));
  console.log('initializePollingSaga');
  yield call(orderApi.initializePolling);
  yield put(applicationActions.unsetLoading(orderImportTypes.INITIALIZE_POLLING.REQUEST));
}

function* pollSaga() {
  yield put(applicationActions.setLoading(orderImportTypes.POLL.REQUEST));
  console.log('pollSaga');
  yield call(orderApi.poll);
  yield put(applicationActions.unsetLoading(orderImportTypes.POLL.REQUEST));
}

function* goToHangfireSaga() {
  yield put(applicationActions.setLoading(orderImportTypes.GOTOHANGFIRE.REQUEST));
  console.log('goTohangfireSaga');
  yield call(orderApi.goToHangfire);
  yield put(applicationActions.unsetLoading(orderImportTypes.GOTOHANGFIRE.REQUEST));
}

function* getOrderImportListSaga({payload}) {
  yield put(applicationActions.setLoading(orderImportTypes.ORDER_IMPORT_LIST.REQUEST));
  yield callApiSagaAndNormalize(orderImportActions.listOrderImports, orderApi.getOrderImportList, orderImportSchemas.orderImportListSchema);
  console.log('order imports done');
  yield put(applicationActions.unsetLoading(orderImportTypes.ORDER_IMPORT_LIST.REQUEST));
}
