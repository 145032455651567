import { formValueSelector } from 'redux-form';

const defailsFormValueSelector = formValueSelector('ingredientForm');

export const getIngredientList = state => state.ingredient.ingredientList;
export const getCurrentIngredient = state => state.ingredient.currentIngredient;


export const getIngredientTitle = state => {
  const ingredientName = defailsFormValueSelector(state, 'InciName') || '';
  const code = defailsFormValueSelector(state, 'Code') || '';
  return `${code} - ${ingredientName}`;
};

export const isNew = state => {
  const id = defailsFormValueSelector(state, 'IngredientId');
  return id <= 0;
};
