import { post, get, del } from './apiUtils';

const defaultUrl = '/Processes';

export default {
  getProcess: id => get(`${defaultUrl}/${id}`),
  saveProcess: data => post(defaultUrl, data),
  deleteProcess: id => del(`${defaultUrl}/${id}`),
  addProcess: orderItemId => post(`${defaultUrl}/AddProcess/${orderItemId}`),
  addMultipleProcesses: data => post(`${defaultUrl}/AddMultipleProcesses`, data)
};
