import PropTypes from 'prop-types';
import React from 'react';
import {FormControl} from 'react-bootstrap';
import BaseFormGroup from './BaseFormGroup';

const InputField = props => {
  const {
    label,
    input,
    type,
    placeholder,
    groupLabel,
    readOnly,
    style,
    componentClass,
    inputGroupLabel,
    showFeedbackIcon,
  } = props;

  const renderField = (
    <FormControl
      {...input}
      bsSize="small"
      style={style}
      type={type}
      placeholder={groupLabel ? '' : placeholder || label}
      disabled={readOnly}
      componentClass={componentClass} // Added to use TextArea
    />
  );

  return (
    <BaseFormGroup {...props}>
      {renderField}
      {inputGroupLabel == null && showFeedbackIcon && <FormControl.Feedback />}
    </BaseFormGroup>
  );
};

InputField.defaultProps = {
  type: 'text',
  componentClass: 'input',
  readOnly: false,
  groupLabel: false,
  inputGroupLabel: null,
  label: null,
  placeholder: null,
  showFeedbackIcon: true,
  style: {},
};

InputField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object.isRequired, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
  type: PropTypes.string,
  placeholder: PropTypes.string,
  groupLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  componentClass: PropTypes.string,
  inputGroupLabel: PropTypes.string,
  showFeedbackIcon: PropTypes.bool,
};

export default InputField;
