import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import { actionListSchema } from '../schema';
import actionApi from '../../api/actionApi';
import * as actionTypes from './actionTypes';
import * as actionActions from './actionActions';

export default function* root() {
  yield all([takeLatest(actionTypes.ACTION_LIST.REQUEST, getActionListSaga)]);
}

function* getActionListSaga() {
  yield put(applicationActions.setLoading(actionTypes.ACTION_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    actionActions.listActions,
    actionApi.getActionList,
    actionListSchema,
  );
  yield put(applicationActions.unsetLoading(actionTypes.ACTION_LIST.REQUEST));
}
