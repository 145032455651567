import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import { isNullOrUndef } from '../../utils/utils';
import {getphraseList} from "../phrase/phraseSelectors";

const detailsFormValueSelector = formValueSelector('categoryForm');

// export const getcategoryList = state => state.category.categoryList;
// export const getCurrentcategory = state => state.category.currentcategory;
// export const getCurrentcategoryCompanies = state => state.category.currentcategoryCompanies;
// export const getCurrentcategoryProducts = state => state.category.currentcategoryProducts;

export const getById = ({ category }) => category.byId;
export const getIds = ({ category }) => category.list.allIds;
export const getCategory = (state, id) => getById(state)[id];
export const getCategoryById = id => state => getById(state)[id];
export const getCategoryList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getCategoryByCode = (state, code) => getCategoryList(state).find(elem => elem.Code === code);

export function categoryTitle(state) {
  const code = detailsFormValueSelector(state, 'Name') || '';
  return `${code}`;
}

export function isNew(state) {
  const id = detailsFormValueSelector(state, 'categoryId');
  return id <= 0 || isNullOrUndef(id);
}

// export const isLoadingcategory = state =>
//   applicationSelectors.isLoading(
//     state,
//     categoryTypes.category.REQUEST,
//     categoryTypes.category_COMPANY_LIST.REQUEST,
//     categoryTypes.category_PRODUCT_LIST.REQUEST,
//   );

// export const isCurrentcategoryUsed = createSelector(
//   [getCurrentcategory, getCurrentcategoryCompanies, getCurrentcategoryProducts, isLoadingcategory],
//   (currentcategory, currentcategoryCompanies, currentcategoryProducts, isLoading) => {
//     if (isLoading) {
//       return true;
//     }
//     return currentcategoryCompanies.length > 0 || currentcategoryProducts.length > 0;
//   },
// );
