import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, DateCellRenderer } from '../../_Common/Grids';
import dateComparator from '../../../utils/dateComparator';

const ProductActionList = props => {
  const {
    productActionList,
    isLoading,
    getProductActionList,
    onProductActionSelected,
    onAddButtonClick,
    whenGridReady, ...otherProps } = props;
  const columns = [
    {
      headerName: I18n.t('productAction.date'),
      field: 'CreatedOn',
      width: 60,
      comparator: dateComparator,
      cellRenderer: DateCellRenderer,
      sort: 'desc',
    },
    {
      headerName: I18n.t('productAction.code'),
      field: 'Action.Code',
      width: 40,
    },
    {
      headerName: I18n.t('productAction.description'),
      field: 'Action.Description',
      width: 150,
    },
    {
      headerName: I18n.t('productAction.comment'),
      field: 'Comment',
    },
    {
      headerName: I18n.t('productAction.user'),
      field: 'CreatedBy',
      width: 50,
    },
  ];

  return (
    <div>
      <SelectionGrid
        data={productActionList}
        keyName="ProductActionId"
        columns={columns}
        isLoading={isLoading}
        onRefresh={getProductActionList}
        showAddButton
        onAdd={onAddButtonClick}
        whenGridReady={whenGridReady}
        onSelected={onProductActionSelected}
        {...otherProps}
      />
    </div>
  );
};

ProductActionList.propTypes = {
  productActionList: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProductActionList: PropTypes.func.isRequired,
  onProductActionSelected: PropTypes.func.isRequired,
};

export default ProductActionList;
