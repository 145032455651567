import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { createGeneralSelectors, createAuthorizationCheck } from '../_common/selectors';
import impactAssessmentAuthorizationCodes from '../../constants/impactAssessmentAuthorizationCodes';
import { isNullOrUndef } from '../../utils/utils';

const detailFormValueSelector = formValueSelector('impactAssessmentForm');

export const impactAssessmentTitle = state => {
  const productName = detailFormValueSelector(state, 'Description') || '';
  const code = detailFormValueSelector(state, 'ImpactAssessmentCode') || '';
  return `${code} - ${productName}`;
};

export function isNew(state) {
  const id = detailFormValueSelector(state, 'ImpactAssessmentId');
  return id <= 0 || isNullOrUndef(id);
}

export const getCurrentImpactAssessment = state => state.impactAssessment.currentImpactAssessment;

const getImpactAssessmentSlice = state => state.impactAssessment;

export const { getById, getIds, makeGetItemById: createGetImpactAssessmentById } = createGeneralSelectors(
  getImpactAssessmentSlice,
);

export const getImpactAssessment = (state, id) => getById(state)[id];
export const getImpactAssessmentList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getImpactAssessmentListByProduct = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);
export const makeGetImpactAssessmentById = createSelector(getById, impactAssessmentById =>
  createSelector(impactAssessmentId => impactAssessmentById[impactAssessmentId], R.identity),
);

const getAuthorizationCodes = R.propOr([], 'AuthorizationCodes');

const createMakeGetAuthorizationCheck = createAuthorizationCheck(createGetImpactAssessmentById)(getAuthorizationCodes);

export const makeIsImpactAssessmentEditable = createMakeGetAuthorizationCheck([
  impactAssessmentAuthorizationCodes.EDIT_IMPACT_ASSESSMENT,
]);

const getProductListWithoutProductId = productId =>
  createSelector(getImpactAssessmentList, R.reject(R.propEq('ImpactAssesmentId', productId)));

export const makeGetProductListWithoutProductId = createSelector(
  getProductListWithoutProductId,
  productList => productList,
);

export const getFilteredImpactAssessmentList = (state, filter) => {
  const { companyId } = filter;
  if (companyId !== undefined) {
    return getImpactAssessmentList(state).filter(p => p.ProducerId === companyId);
  }
  return getImpactAssessmentList(state);
};
