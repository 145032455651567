import * as workflowStatusSelectors from './workflowStatusSelectors';
import * as workflowStatusActions from './workflowStatusActions';
import * as workflowStatusTypes from './workflowStatusTypes';
import sagas from './workflowStatusSagas';
import reducer from './workflowStatusReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { workflowStatusSelectors, workflowStatusActions, workflowStatusTypes };
export default reducer;
