import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import BaseLoader from '../../../_Common/BaseLoader';
import SubstanceProportionForm from './SubstanceProportionForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
};

const SubstanceProportionModal = props => {
  const { currentSubstanceName, closeModal, isLoading, isSubmittable, onSave, onSubmitButtonClick, ...otherProps } = props;
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Add Substance to {currentSubstanceName} Substance composition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseLoader isLoading={isLoading}>
          <SubstanceProportionForm {...otherProps} onSubmit={onSave} />
        </BaseLoader>
      </Modal.Body>
      <Modal.Footer>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={onSubmitButtonClick}
              disabled={!isSubmittable}
            >
              <i className="fa fa-floppy-o" />&nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Footer>
    </div>
  );
};

SubstanceProportionModal.propTypes = {
  currentSubstanceName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmittable: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
};

export default SubstanceProportionModal;
