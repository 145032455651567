import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as mandateProductTypes from './mandateProductTypes';
import * as mandateTypes from '../mandate/mandateTypes';

const byId = handleActions(
  {
    [mandateProductTypes.FETCH_MANDATE_PRODUCTS.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.entities.mandateProducts;
      return { ...state, ...payload.entities.mandateProducts };
    },
    [mandateTypes.FETCH_MANDATES.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.mandateProducts,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [mandateProductTypes.FETCH_MANDATE_PRODUCTS.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
    [mandateTypes.FETCH_MANDATES.SUCCESS]: (state, { payload }) => {
      if (payload.entities.mandateProducts) {
        return [...new Set([...state, ...Object.keys(payload.entities.mandateProducts).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
