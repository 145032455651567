import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as cpsrChaptersTypes from './cpsrChaptersTypes';

const initialState = {
  byId: {},
  list: {
    allIds: [],
  },
};

export default handleActions(
  {
    [cpsrChaptersTypes.CPSR_CHAPTER_LIST.SUCCESS]: (state, { payload }) => {
      return ({
        ...state,
        byId: merge({}, state.byId, payload.entities.cpsrChapters),
        list: {
          ...state.list,
          allIds: payload.result,
        },
      });
    },
  },
  initialState,
);
