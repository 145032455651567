import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { evolve, omit } from 'ramda';
import { push } from 'react-router-redux';
import ImpactAssessmentList from './ImpactAssessmentList';
import {
  impactAssessmentActions,
  impactAssessmentTypes,
  impactAssessmentSelectors,
} from '../../redux/impactAssessment';
import { productActions } from '../../redux/product';
import { applicationSelectors } from '../../redux/application';

class ImpactAssessmentsPageContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, //eslint-disable-line
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <ImpactAssessmentList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location: { query },
  } = ownProps;
  return {
    impactAssessmentList: impactAssessmentSelectors.getImpactAssessmentList(state),
    requestingImpactAssessmentList: applicationSelectors.isLoading(
      state,
      impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.REQUEST,
    ),
  };
};

function mapDispatchToProps(dispatch, { location }) {
  return {
    onLoad: () => {
      dispatch(impactAssessmentActions.listImpactAssessments.request());
      dispatch(productActions.listProducts.request());
    },
    onImpactAssessmentSelected: id => dispatch(push({ pathname: `/impactAssessments/${id}` })),
    onAddImpactAssessment: () => dispatch(push({ pathname: '/impactAssessments/-1' })),
    onRefreshList: () => dispatch(impactAssessmentActions.listImpactAssessments.request()),
  };
}

ImpactAssessmentsPageContainer.defaultProps = {
  impactAssessmentList: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImpactAssessmentsPageContainer);
