import PropTypes from 'prop-types';
import React from 'react';
import {propOr, pathOr, pipe, length, divide, __, add, multiply, compose, ifElse, equals, always} from 'ramda';
import {I18n} from 'react-redux-i18n';
// import { SelectionGrid } from '../../_Common/Grids';
import {DateCellRenderer, GeneralDashboardSearchGrid} from '../../_Common/Grids';
import DetailHeader from '../../_Common/DetailHeader';

const GeneralDashboardProductList = props => {
  const {
    generalDashboardProductList,
    isLoading,
    getProductList,
    searchProducts,
    companyList,
    actionList,
    productStateList,
    salesRepList,
    expertList,
    accountManagerList,
    rawMatList,
    workflowStatusNameById,
    onRowDoubleClick
  } = props;

  const rowHeight = pipe(
    pathOr('1', ['data', 'Text']),
    length,
    divide(__, 255),
    Math.floor,
    add(1),
    multiply(25),
  );

  const getYN = ({data, colDef}) =>
    compose(
      boolToYN,
      propOr(false, colDef.field),
    )(data);
  const boolToYN = ifElse(equals(true), always('yes'), always(''));

  const getNoLongerInMarket = ({data, colDef}) => {
    return data.IsNoLongerOnMarket ? 'yes' : '';
  };


  const getWorkflowName = ({data, colDef}) => propOr('', 'Name', workflowStatusNameById[data[colDef.field]]);
  const isLR1 = ({data, colDef}) => data.IsLabelApprovalDone && !data.IsValidated ? 'yes' : '';
  const isLR2 = ({data, colDef}) => data.IsLabelApprovalDone && data.IsValidated ? 'yes' : '';
  const getFRStatus = ({data, colDef}) => data.FRCount > 0 ? 'yes' : '';
  const getShadeCount = ({data, colDef}) => data.ShadeCount > 0 ? data.ShadeCount : '';

  const columns = [
    {
      headerName: I18n.t('generalDashboard.company'),
      field: 'Producer',
      width: 70,
      sort: 'asc',
    },
    {
      headerName: I18n.t('generalDashboard.code'),
      field: 'Code',
      width: 50,
      sort: 'asc',
    },
    {
      headerName: I18n.t('generalDashboard.product'),
      field: 'ProductName',
    },
    {
      headerName: I18n.t('generalDashboard.shadeCount'),
      field: 'ShadeCount',
      width: 50,
      valueGetter: getShadeCount
    },
    {
      headerName: I18n.t('generalDashboard.formulaReview'),
      field: 'FRCount',
      width: 50,
      valueGetter: getFRStatus
    },
    {
      headerName: I18n.t('product.LR1'),
      field: 'IsLabelApprovalDone',
      width: 50,
      valueGetter: isLR1,
    },
    {
      headerName: I18n.t('generalDashboard.draftLabel'),
      field: 'HasDraftLabel',
      width: 50,
      valueGetter: getYN
    },
    {
      headerName: I18n.t('generalDashboard.cpsrA'),
      field: 'CpsrAStatus',
      width: 50,
      valueGetter: getWorkflowName,
    },
    {
      headerName: I18n.t('product.LR2'),
      field: 'IsLabelApprovalDone',
      valueGetter: isLR2,
      width: 50,
    },
    {
      headerName: I18n.t('generalDashboard.cpsrB'),
      field: 'CpsrBStatus',
      width: 50,
      valueGetter: getWorkflowName,
    },
    {
      headerName: I18n.t('generalDashboard.isNotified'),
      field: 'IsNotified',
      width: 50,
      valueGetter: getYN
    },
    {
      headerName: I18n.t('product.IsNoLongerOnMarket'),
      width: 100,
      field: 'IsNoLongerOnMarket',
      valueGetter: getNoLongerInMarket,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('generalDashboard.notificationCode'),
      field: 'NotificationCodeEU',
      width: 50,
    },
    {
      headerName: I18n.t('generalDashboard.notifiedOn'),
      field: 'NotifiedEU',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      width: 90,
      suppressSizeToFit: true,
    },
  ];

  return (
    <div>
      <DetailHeader title="Product Dashboard" showCancel="false" showSave="false">
        <GeneralDashboardSearchGrid
          data={generalDashboardProductList}
          columns={columns}
          onRefresh={getProductList}
          onSearch={searchProducts}
          isLoading={isLoading}
          showLoadingIcon={isLoading}
          stopEditingWhenGridLosesFocus
          singleClickEdit
          keyName="ProductId"
          showFilterRow
          getRowHeight={rowHeight}
          actionList={actionList}
          companyList={companyList}
          stateList={productStateList}
          salesRepList={salesRepList}
          expertList={expertList}
          accountManagerList={accountManagerList}
          rawMatList={rawMatList}
          rowDoubleClicked={onRowDoubleClick}
        />
      </DetailHeader>
    </div>
  );
};

GeneralDashboardProductList.propTypes = {
  generalDashboardProductList: PropTypes.array, // eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProductList: PropTypes.func.isRequired,
  searchProducts: PropTypes.func.isRequired,
  companyList: PropTypes.array, // eslint-disable-line
  productStateList: PropTypes.array, // eslint-disable-line
  salesRepList: PropTypes.array, // eslint-disable-line
  expertList: PropTypes.array, // eslint-disable-line
  accountManagerList: PropTypes.array, // eslint-disable-line
  rawMatList: PropTypes.array, // eslint-disable-line
  onRowDoubleClick: PropTypes.func.isRequired,
};

export default GeneralDashboardProductList;
