import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import LoadingButton from '../../_Common/LoadingButton';
import { applicationSelectors } from '../../../redux/application';
import { rawMatActions, rawMatTypes } from '../../../redux/rawMat';

const UpdateInciQtyButton = ({ onClick, isLoading, disabled }) => (
  <LoadingButton bsSize="small" onClick={onClick} isLoading={isLoading} disabled={disabled}>
    {I18n.t('product.updateInciQty')}
  </LoadingButton>
);

UpdateInciQtyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, { disabled }) => {
  const isLoading = applicationSelectors.isLoading(state, rawMatTypes.UPDATE_INCI_QTY.REQUEST);
  return {
    isLoading,
    disabled: disabled || isLoading,
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onClick: () => dispatch(rawMatActions.updateInciQty.request(rawMatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInciQtyButton);
