import { takeLatest, put, all } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize } from '../sagaUtils';
import categoryApi from '../../api/categoryApi';
import * as categoryTypes from './categoryTypes';
import * as categoryActions from './categoryActions';
import * as categorySchemas from './categorySchemas';

export default function* root() {
  yield all([
    takeLatest(categoryTypes.CATEGORY_LIST.REQUEST, getCategoryListSaga),
    takeLatest(categoryTypes.CATEGORY.REQUEST, getCategorySaga),
    takeLatest(categoryTypes.SAVE_CATEGORY.REQUEST, saveCategorySaga),
    takeLatest(categoryTypes.DELETE_CATEGORY.REQUEST, deleteCategorySaga),
  ]);
}

export function* getCategoryListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(categoryTypes.CATEGORY_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    categoryActions.listCategories,
    categoryApi.getCategoryList,
    categorySchemas.categoryListSchema,
  );
  yield put(applicationActions.unsetLoading(categoryTypes.CATEGORY_LIST.REQUEST));
}

function* getCategorySaga(action) {
  yield put(applicationActions.setLoading(categoryTypes.CATEGORY.REQUEST));
  yield callApiSagaAndNormalize(
    categoryActions.category,
    categoryApi.getCategory,
    categorySchemas.categorySchema,
    action.payload,
  );
  yield put(applicationActions.unsetLoading(categoryTypes.CATEGORY.REQUEST));
}

export function* saveCategorySaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    categoryActions.saveCategory,
    categoryApi.saveCategory,
    categorySchemas.categorySchema,
    payload,
  );
  yield put(categoryActions.listCategories.request());
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}

function* deleteCategorySaga(action) {
  yield put(applicationActions.setLoading(categoryTypes.DELETE_CATEGORY.REQUEST));
  yield callApiSaga(categoryActions.deleteCategory, categoryApi.deleteCategory, action.payload);
  yield put(replace('/categories/'));
  yield put(categoryActions.listCategories.request());
  yield put(applicationActions.unsetLoading(categoryTypes.DELETE_CATEGORY.REQUEST));
}
