import { takeLatest, put, all, select, takeEvery, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize, callApiSaga, createFetchSaga } from '../sagaUtils';
import { labelReviewTypes, labelReviewActions, labelReviewSchemas, labelReviewSelectors } from './index';

import labelReviewApi from '../../api/labelReviewApi';

export default function* root() {
  yield all([
    takeLatest(labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.REQUEST, getLabelReviewListForProductSaga),
    takeLatest(labelReviewTypes.SAVE_LABEL_REVIEW.REQUEST, saveLabelReviewSaga),
    takeLatest(labelReviewTypes.UPDATE_LABEL_REVIEW.REQUEST, updateLabelReviewSaga),
    takeEvery(labelReviewTypes.FETCH_LABEL_REVIEWS.REQUEST, fetchLabelReviewSaga),
  ]);
}

export const fetchLabelReviewSaga = createFetchSaga(
  function* fetchLabelReviewSaga({ payload, type }) {
    const labelReviews = yield call(labelReviewApi.fetchLabelReviews, payload);
    const schema = Array.isArray(labelReviews)
      ? labelReviewSchemas.labelReviewListSchema
      : labelReviewSchemas.labelReviewSchema;
    const normalizedData = normalize(labelReviews, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(labelReviewActions.fetchLabelReviews.success(data, payload));
    },
    onFailure: (error, payload) => put(labelReviewActions.fetchLabelReviews.failure(error, payload)),
  },
);

function* getLabelReviewListForProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    labelReviewActions.listLabelReviewForProduct,
    labelReviewApi.getLabelReviews,
    labelReviewSchemas.labelReviewListSchema,
    {
      ...payload,
      // OptIncludeDetails: true,
    },
  );
  yield put(applicationActions.unsetLoading(type));
}

function* updateLabelReviewSaga({ payload, type }) {
  const { LabelReviewId } = payload;
  yield put(applicationActions.setLoading(type));
  const currentLabelReview = yield select(state => labelReviewSelectors.getLabelReview(state, LabelReviewId));
  const labelReviewToSave = {
    ...currentLabelReview,
    ...payload,
  };
  yield callApiSaga(labelReviewActions.updateLabelReview, labelReviewApi.saveLabelReview, labelReviewToSave);
  yield put(labelReviewActions.fetchLabelReviews.request({ ProductId: labelReviewToSave.ProductId }));
  yield put(applicationActions.unsetLoading(type));
}

function* saveLabelReviewSaga({ payload, type }) {
  const { ProductId } = payload;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(labelReviewActions.saveLabelReview, labelReviewApi.saveLabelReview, payload);
  yield put(labelReviewActions.fetchLabelReviews.request({ ProductId }));
  yield put(applicationActions.unsetLoading(type));
}
