import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import TabTitle from '../_Common/TabTitle';
import { substanceFormSelectors } from '../../redux/form/substanceForm';

const SubstanceCpsrArgumentTabTitle = props => <TabTitle {...props} />;

const mapStateToProps = state => ({
  title: I18n.t('substance.cpsrBArguments'),
  isDirty: substanceFormSelectors.isCpsrArgumentFormDirty(state),
});

SubstanceCpsrArgumentTabTitle.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(SubstanceCpsrArgumentTabTitle);
