export default {
  menu: {
    home: 'Accueil',
    companies: 'Produiteurs',
    orders: 'Commandes',
    products: 'Produits',
    rawmats: 'Matières premières',
    ingredients: 'Ingrédients',
    substances: 'Substances',
    distributors: 'Distributeurs',
    phrases: 'Phrases',
    dashboard: 'Tableau de bord',
    dashboardList: 'Tableaux de bord',
    generalDashboard: 'Général',
    safecosmeticDashboard: 'Safecosmetic',
    impactAssessments: 'Evaluation d\'impact',
    stats: 'Stats',
    errors: 'Erreurs',
    more: 'Plus...',
    planner: 'Planificateur',
    plannerDashboard: 'Planificateur',
  },
  languages: {
    fr: 'Français',
    en: 'Anglais',
  },
  substanceTypes: {
    Inci: '1-INCI',
    Allergen: '2-Allergen',
    Impurity: '3-Impurity',
    Ingredient: '4-Ingredient',
  },
  form: {
    requiredFieldError: 'Ce  champ est obligatoire.',
    maxLengthError: 'This field must be %{max} characters or less.',
    numberFieldError: 'It must be a number.',
    emailError: 'It must be a correct Email.',
    integerFieldError: 'It must be an integer value.',
    positiveOrZeroIntegerFieldError: 'It must be greater or equal to 0.',
    positiveIntegerFieldError: 'It must be greater or equal to 1.',
    endDateBeforeStarDateError: 'End date cannot be before start date.',
    starDateAfterEndDateError: 'Start date cannot be after end date.',
    greaterThanError: 'It must be greater than %{limit}.',
    uomRequiredField: 'You must select a unit of measure.',
    UniqueFieldError: 'This field must be unique.',
    rangeFieldError: 'You must enter a correct range.',
    upperCaseNumberError: 'Max 50 numbers and/or uppercases starting with an uppercase',
  },
  userMenu: {
    logout: 'Logout',
  },
  selectionGrid: {
    clearFilter: 'Clear filter',
    refreshData: 'Refresh list',
    add: 'Add new element to list',
    downloadToExcel: 'Download grid  content to Excel xlsx',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    editSelected: 'Edit all selected',
  },
  printDocumentsModal: {
    title: 'Print Document For Produit: %{productName}',
  },
  allergenListModal: {
    title: 'Add Allergens to RawMat: %{rawMatName}',
  },
  impurityListModal: {
    title: 'Add Impurities to RawMat: %{rawMatName}',
  },
  categoryFormModal: {
    editTitle: 'Editer category',
    addTitle: 'Add category'
  },
  rawMatCompositionTab: {
    allergensInPerfumeButtonLabel: 'Allergens In Perfume',
    impuritiesButtonLabel: 'Impurities',
    cannotChangeStateMessage: 'Cannot change status because a raw mat in the composition is inactive',
  },
  report: {
    formulaAcceptance: 'Final INCI List',
    complianceCertificate: 'Compliance Certificate',
    cpsra: 'CPSR A',
    cpsraNew: 'New CPSR A',
    cpsraByShade: 'by Shade',
    cpsrbByShade: 'by Shade',
    cpsrAByShadeConfidential: 'by Shade confidential',
    cpsrbByShadeConfidential: 'by Shade confidential',
    cpsrb: 'CPSR B',
    cpsrbNew: 'New CPSR B',
    cpsraconfidential: 'confidential',
    cpsrbconfidential: 'confidential',
    cbAnnexes: 'CB Annexes',
    closingLetter: 'Closing Letter',
    safetyAssessment: 'Safety Assessment',
    packagingConformity: 'Packaging Conformity',
    cpnp: 'CPNP Certificate',
    claimASEAN: 'Claims ASEAN Report',
    print: 'Print Document',
    productCpnp: 'CPNP Composition',
    productToxProfile: 'Tox Profile',
  },
  login: {
    username: 'Login',
    password: 'Password',
    loginButton: 'Log In',
  },
  dashboard: {
    title: 'Dashboard',
    name: 'Dashboard name',
  },
  company: {
    customerSupport: 'Account Manager',
    am: 'AM',
    expert: 'Expert',
    salesRep: 'Sales rep.',
    CASpecific: 'CA specific used',
    code: 'Code',
    contacts: 'Contacts',
    country: 'Country',
    CPNPCountry: 'CPNP Country',
    CPNPIdentification: 'CPNP Ident. Code',
    CPNPUser: 'CPNP User Code',
    defaultResponsible: 'Responsible by default',
    creationDate: 'Created on',
    details: 'Details',
    distributionChannels: 'Distribution channels',
    distributors: 'Distributors',
    documents: 'Documents',
    FRWithCompo: 'FR with compo',
    fullName: 'Full name',
    InciCalculationType: 'INCI calculation',
    isActive: 'Active',
    IsICMADMember: 'ICMAD INCI list',
    memo: 'Memo',
    orders: 'Orders',
    products: 'Produits',
    shortName: 'Short Name',
    supplierCode: 'Prefix',
    title: 'Company',
    UsesPrivateRawMat: 'Private RM',
    UsesUsInciNames: 'INCI US',
    UsesManualInci: 'Manual INCI by default',
    qualityAssessment: 'Quality Assessment',
    MetrologyIndic: 'Metrology/e-mark state',
    BatchNrIndic: 'ID Batch number',
    GMPCertif: 'GMP Certificate',
    FinishedProduitNATIndic: 'Non-animal testing statement for the finished product',
    PackagingFillingSOPIndic: 'procedure for Packaging and Filling',
    FinishedProduitQCIndic: 'Quality control, SOP, GMPc - Finished product',
    IngredientsQCIndic: 'Quality control, SOP, GMPc - Ingredients',
    IngredientsNATCertif: 'Non-animal-testing certification for the ingredients (Leaping Bunny,...)',
    GreenDotCertif: 'Green Dot certificate',
    OrganicsCertif: 'Organics, Natural or other Certificates',
    OtherSOP: 'Other Quality Assurance',
    rawMats: 'Raw Mats',
    preferedCurrency: 'Preferred Currency',
    RPStatus: 'RP Status',
    isBlackListed: 'black listed',
    invoiceType: 'Invoicing type'
  },
  distributionChannel: {
    name: 'Distribution channel',
  },
  distributor: {
    panelTitle: 'Distributor',
    name: 'Name',
    contact: 'Contact',
    producers: 'Mandates for producers',
    products: 'Distributed products',
    country: 'Country',
    contacts: 'Contact',
    agreements: 'Distribution Program',
    mandates: 'Mandates',
  },
  mandates: {
    brand: 'Cosmetic Brand',
    status: 'Status',
    mandate: 'Mandate',
    ValidityStartDate: 'Validity Start Date',
    CreatedOn: 'Created On',
    company: 'Company',
    print: 'Print',
    quickPrint: 'Quick Print',
  },
  mandateProduitModal: {
    title: 'Editer mandate',
    create: 'Create New Version',
    save: 'Sauver',
    distributor: 'Distribors',
    company: 'Company',
  },
  mandateProduits: {
    product: 'Name',
    products: 'Produits',
    code: 'Code',
    NotificationDate: 'Notification Date',
    NotificationCode: 'Notification Code',
  },
  distributorAgreements: {
    id: 'Id',
    company: 'Company',
    status: 'Status',
    date: 'Date',
    agreement: 'Agreement',
    generate: 'Generate',
    markAsSent: 'Is sent',
    markAsSigned: 'Is signed',
    revoke: 'Revoke',
    print: 'Print',
    addAgreement: 'Add Agreement to Distributor',
    invoiceAddressee: 'Invoice Addressee',
  },
  order: {
    actualCount: 'Effectif',
    addProduit: 'Add selected product',
    start: 'Start',
    close: 'Close',
    companyShortName: 'Nom court de compagnie',
    customerSupport: 'Gestionnaire',
    description: 'Description',
    endDate: 'Date fin',
    expert: 'Expert',
    foreseenCount: 'Prévu',
    fullName: 'Nom complet',
    notifiedCount: 'Notifié',
    OrderNr: 'N° de commande',
    orderType: 'Type',
    orderNumber: 'N° de commande',
    orderNumberPlaceholder: 'Order nr (if available)',
    panelTitle: 'Commande',
    producerCode: 'Producteur',
    producerName: 'Producteur',
    products: 'Produits',
    progress: "Avancement (%)",
    regulatoryRegion: 'Région',
    salesRep: 'Commercial',
    searchProduit: 'Search Produits',
    startDate: 'Date début',
    Status: 'Etat',
    processessTab: 'Processus',
    tasksTab: 'Tâches',
    initializePolling: 'Initialize polling',
    poll: 'Poll',
    createdOn: 'Création'
  },
  orderImport: {
    initializePolling: "Initialize polling",
    poll: "Poll",
    order: "Order",
    mainException: "Main Exception",
    subException: "Sub Exception",
    exceptionData: "Row Data",
    fileName: "FileName",
    description: "Description",
    createdOn: "Imported on",
  },
  orderImportDashboard: {
    title: 'Order Import Dashboard'
  },
  product: {
    addFormulaReview: 'Perform review',
    addLabelReview: 'Perform review',
    aseanClaimTab: 'Asean claim',
    category: 'Produit category',
    replaceOrDuplicateTitle: 'Replace or Duplicate',
    replaceOrDuplicateMessage: 'Do you intend to REPLACE the original product?',
    categories: 'Produit categories',
    categoryDescription: 'Category Description',
    code: 'Code',
    company: 'Company',
    compositionTab: 'Composition',
    CompoStatus: 'Compo',
    cosmosCode: 'Code Cosmos',
    cosmosCompositionOwner: 'Cosmos Composition Owner',
    cosmosCompositionTab: 'Cosmos compo.',
    cosmosWorkflow: 'Cosmos status',
    completion: '% done',
    CpnpCat3: 'CPNP nr',
    CpnpCat3UK: 'CPNP nr UK',
    CpnpCat3CAN: 'CPNP nr CAN',
    cpnpTab: 'CPNP',
    CPSRAStatus: 'CPSR-A',
    cpsraTab: 'CPSR-A',
    CPSRBStatus: 'CPSR-B',
    cpsrBComment: 'Comment',
    cpsrbTab: 'CPSR-B',
    CPSRBType: 'CPSR-B Type',
    creationDate: 'Created on',
    createdBy: 'by',
    detailTab: 'Details',
    dl: 'Draft label',
    draftLabel: 'Draft Label',
    files: 'Documents',
    formulaNumber: 'Formula nr',
    formulaReview: 'Formula Rev.',
    checkList: 'Check List',
    goToParent: 'Go to Parent product',
    goToProduits: 'Go to products',
    impactAssessment: 'Impact',
    impactIgnored: 'Ignore',
    impactReviewed: 'Reviewed',
    impurityTab: 'Impurities',
    InciTab: 'INCI List',
    isConfidential: 'Confidential',
    isLabelApprovalDone: 'Label approved',
    IsNotified: 'Notified',
    isActive: 'Active',
    IsNoLongerOnMarket: 'No longer on market',
    isWaiting: 'Waiting',
    labelReview: 'Label Rev.',
    manufacturerName: 'Manufacturer',
    misuse: 'Misuse',
    memo: 'Memo',
    MoSTab: 'MoS Calc.',
    normalUse: 'Normal use',
    NumOfShades: 'Shades',
    orderNr: 'Order nr',
    orders: 'Orders',
    orderTab: 'Orders',
    packagerName: 'Packager',
    panelTitle: 'Produit',
    partAToDo: 'CPSR-A ToDo',
    percentageInProduit: '% in Prodduct',
    producerName: 'Producer',
    productName: 'Nom de produit',
    reasonableUse: 'Usage raisonable',
    responsiblePerson: 'Responsible',
    shadeCount: 'Number of shades or 0',
    status: 'Status',
    supperDate: 'Supper date',
    targetPopulation: 'Target population',
    taskTab: 'Tasks',
    useDescription: 'Use description',
    useFrequency: 'Frequency',
    useQuantity: 'Quantity',
    user: 'User',
    useRetentionFactor: 'Retention',
    useSite: 'Use site',
    useSurface: 'Surface',
    useType: 'Leave on/ rinse off',
    usualName: 'Usual Name',
    validationDate: 'Validated on',
    notificationDate: 'CPNP date',
    notificationCode: 'CPNP nr',
    isNotified: 'Is notified',
    responsibleUntil: 'RP until',
    withSpf: 'with SPF',
    shades: 'Shades',
    Status: 'Status',
    validatedBy: 'by',
    MicaInd: 'MICA at end',
    InciRangeInd: 'INCI-Mid-Range',
    inciValidation: 'Inci Validation',
    ManualInciInd: 'Manual Inci List',
    inciOptions: 'INCI Options',
    cpnpStatus: 'CPNP',
    mosCalculation: 'Mos Calculation Time Range',
    updateInciQty: 'Update Inci Qty',
    MosCalculationType: 'Mos Calculation Type',
    changeCategoryTitle: 'Your are about to change the product category',
    changeCategoryMessage:
      'This will overwrite all the fields related to the product category. Do you really want to proceed?',
    LR1: 'LR1',
    LR2: 'LR2',
  },
  compositionTab: {
    ReOrderComposition: 'Re-Order',
  },
  inciListTab: {
    manualEditer: 'Editer',
  },
  category: {
    code: 'Code',
    description: 'Description',
    misuse: 'Misuse',
    normalUse: 'Normal Use',
    reasonableUse: 'Reasonable Use',
    sensitizationAssessmentFactors: 'Sens.Ass.Fact.',
    sensitizationQuantity: 'Sens. Qty.',
    dermalAbsorption100: '100% Abs.',
    targetPopulation: 'Target Pop.',
    useDescription: 'Use Descr.',
    useFrequency: 'Frequency',
    useRetentionFactor: 'Ret. Factor',
    useQuantity: 'Use Qty.',
    useSite: 'Site',
    useSurface: 'Surface',
    bodyWeight: 'Body Weight',
    isInhalable: 'Inhalable',
    isDermalAbsorption: 'Is Dermal Absorption 100%',
    amountSprayed: 'Amount sprayed',
    airborneFraction: 'Airborne Fraction',
    distributionVolume1: 'Distribution Volume 1',
    exposureTime1: 'Exposure Time 1',
    distributionVolume2: 'Distribution Volume 2',
    exposureTime2: 'Exposure Time 2',
    humanBreathMinuteVolume: 'Human Breath Volume',
    meanApplicationPerDay: 'Mean Application/Day',
    fractionReachingAlveoli: 'Fraction Reaching Alveoli',
    defaultFactorSubstanceExchange: 'Def. Factor Subst. Exchange'
  },
  formulaReview: {
    legislation: 'Legislation Review',
    revisionNr: 'V',
    user: 'User',
    creationDate: 'Creation Date',
    validationDate: 'Validation Date',
    commment: 'Comment',
    actions: 'Actions',
    print: 'Print',
    quickPrint: 'Check Print',
  },
  labelReview: {
    revisionNr: 'V',
    user: 'User',
    by: 'by',
    creationDate: 'Creation Date',
    isValid: 'Valid',
    validationDate: 'Validated On',
    isApproved: 'Approved',
    approvalDate: 'Approved On',
    approve: 'Approve',
    validate: 'Validate',
    approved: 'Approve',
    comment: 'Comment',
    sendingDate: 'Sending Date',
    commment: 'Comment',
    actions: 'Actions',
    print: 'Print',
  },
  formulaReviewWizard: {
    LegislationReview: 'Legislation Review',
    RmInfo: 'RawMat Info.',
    CompoComment: 'Compo. Comment',
    ProduitInfo: 'Produit Info.',
    ProduitInfo2: 'Produit Info. 2',
    PartAQuestion: 'Part A Question',
  },
  labelReviewWizard: {
    innerLabel: 'Inner Label',
    outerLabel: 'Outer Label',
    leaflet: 'Leaflet',
    missingIngredients: 'Missing ingredients in the original INCI list (to add)',
    wrongIngredients: 'Wrong ingredients in the original INCI list (to remove)',
    wrongOrder: 'Wrong order of the ingredients in the original INCI list (to move)',
    wrongSpelling: 'Incorrect spelling of the ingredients in the original INCI list (to correct)',
    claim: 'Claim',
    substantation: 'Substantation',
    remark: 'Remark',
    recommandation: 'Recommandation',
    symbol: 'Symbol',
    correctiveAction: 'Corrective Action',
    LabelReview: 'Label Review',
    Part1: 'Part1',
    Inci: 'Inci',
    ClaimTitle: 'Claims',
    Symbol: 'Symbols',
  },
  productDataReview: {
    BatchNrStatus: 'Id Batch number',
    ChallengeTestStatus: 'Challenge Test',
    CompatibilityPackagingStatus: 'Compatibility Packaging',
    FormulaStatus: 'Formula composition With INCI breakdown',
    InPackagingStabilityStatus: 'In-packaging Stability',
    ManufacturingMethodStatus: 'Manufacturing method',
    MetrologyStatementStatus: 'Metrology/e-mark statement',
    MicrobiologicalQualityStatus: 'Microbiological Quality',
    NonAnimalTestingStatementStatus: 'Non-animal testing statement for the finished product',
    OutPackagingStabilityStatus: 'Out-packaging stability',
    PackagingInfoStatus: 'Packaging information',
    PackagingProcedureStatus: 'Procedure for Packaging and Filing',
    PrimaryLabelStatus: 'Primary Label (Inner Packaging)',
    ProduitCofaStatus: 'CofA and/or specification of the finished product',
    ProduitMsdsStatus: 'Produit MSDS',
    ProduitSpfTestStatus: 'SPF Test',
    QCStatus: 'Quality control, SOP, GMPc - Finished product',
    SecondaryLabelStatus: 'Secondary Label (Outer packaging)',
    UndesirableEffectsStatus: 'Undesirable effects',
    inspectionTitle: 'To be provided in case of inspection',
    IsChallengeTestNotMandatory: 'Not Mandatory',
    IsMicrobiologicalQualityNotMandatory: 'Not Mandatory',
    IsProduitSpfTestNotMandatory: 'Not Mandatory',
    IsSecondaryLabelNotMandatory: 'Not Mandatory',
    mandatoryDataTitle: 'Mandatory Data',
    mandatoryDataTitleCPSR: 'Mandatory information for CPSR',
    mandatoryDataTitleLR: 'Mandatory information for Label Review',
  },
  productAction: {
    date: 'Date',
    code: 'Type',
    description: 'Type Description',
    comment: 'Comment',
    user: 'User',
  },
  complianceReview: {
    InciName: 'INCI Name',
    IsOk: 'Ok',
    CosDirective: 'Cos Dir',
    Annexe: 'Annexe',
    Comment: 'Comment',
    Restriction: 'Restriction',
    Type: 'Type',
    Percentage: '% In Produit',
  },
  productDuplicateOrReplaceModal: {
    title: 'Duplicate or Replace',
  },
  rawMat: {
    code: 'Code',
    company: 'Privately owned by',
    composition: 'Composition',
    createdBy: 'by',
    createdOn: 'Created on',
    files: 'Documents',
    name: 'Name',
    panelTitle: 'Raw Material',
    phrases: 'Information',
    products: 'Produits',
    quantity: 'Quantity %',
    rawMatName: 'RawMat Name',
    searchProduit: '??',
    Status: 'Status',
    summary: 'Summary',
    supersededOn: 'Reviewed on',
    supplier: 'Supplier',
    HasValidMsds: 'Valid MSDS',
    isActive: 'Active',
    MsdsSource: 'MSDS Source',
    HasValidCofa: 'Valid CofA',
    CofaSource: 'CofA Source',
    CpsrSummaryMemo: 'CPSR Summary',
    ImpuritySummaryMemo: 'Summary Impurity',
    validatedBy: 'by',
    validatedOn: 'Validated on',
    addSupplier: 'New RawMat Supplier',
    rawMatSubstances: 'Substances of RawMat',
    goToRm: 'Go to RM',
    copyRM: 'Copy RM to Company',
  },
  productProportion: {
    order: 'Order',
    panelTitle: 'Produit Composition',
    percentage: 'RM Quantity',
    percentageRange: 'Quantity Range',
    phase: 'Phase',
    productProportionHeader: 'Produit Proportion',
    rawMatDetailsHeader: 'RawMat Details',
    createNewRawMatModeLabel: 'Create mode',
  },
  rangeFormatsTooltip: {
    availableUnitsText: 'All values can be followed by their unit of measurement: %, ppm, ppb or mg/kg. Default: %',
    formats: 'Formats',
  },
  function: {
    Name: 'Name',
    Description: 'Description',
  },
  rawMatProportion: {
    function: 'Function',
    inciQuantity: 'INCI Quantity',
    isAllergen: 'Allergen',
    isImpurity: 'Impurity',
    isIngredient: 'Ingredient',
    isNano: 'Nano',
    isNonNano: 'Non-Nano',
    substanceQty: 'Substance Quantity',
    percentageRange: 'Range',
    quantityForInciCalculation: 'Qty Inci',
    quantityRange: 'Range',
    percentage: 'Percentage',
    rawMatProportionHeader: 'RawMat Proportion',
    percentageRangeComment: 'Quantity Range Comment',
    substanceDetailsHeader: 'Substance Details',
    substanceListHeader: 'Substance List',
    filter: {
      all: 'Show All',
      allergens: 'Allergens Only',
      impurities: 'Impurities Only',
    },
  },
  phraseFormModal: {
    addTitle: 'Add new Phrase',
    editTitle: 'Editer Phrase %{phraseCode}',
  },
  substanceProportion: {
    substanceDetailsHeader: 'Substance Details',
    substanceProportionHeader: 'Substance Proportion',
    percentage: 'Substance Quantity',
  },
  ingredient: {
    panelTitle: 'Ingredient',
    inciName: 'INCI Name',
    iupacName: 'IUPAC Name',
    percentageInRM: '% in RM',
    range: 'Range',
    casNum: 'CAS Num',
    ecNum: 'EC Num',
  },
  shadeCompo: {
    Order: 'Order',
    RmCode: 'RM Code',
    RmName: 'RM Name',
    Percentage: 'Percentage',
  },
  cosAnnexe: {
    Code: 'Code',
    Name: 'Name',
    MaxUse: 'Max Use',
    PhMin: 'PhMin',
    PhMax: 'PhMax',
    Reference: 'Reference',
    CalculationCondition: 'Calculation Condition',
    UseConditionWording: 'Use Condition',
    Warning: 'Warning',
    SccsOpinions: 'Sccs Opinions',
    OtherRestrictions: 'Other Resctrictions',
    OtherRemarks: 'Other remarks',
    UsedIn: 'Used in',
    NotUsedIn: 'Not Used in',
  },
  cosAnnexeModalForm: {
    AddTitle: 'Add CosAnnex',
    EditerTitle: 'Editer CosAnnex %{cosAnnexeName}',
  },
  editInciListModal: {
    title: 'Editer Inci List For Produit: %{productName}',
    calculatedInciListTitle: 'Calculated Inci List',
  },
  editInciListForm: {
    inciListUpperPart: 'Upper Part (>=1%)',
    inciListLowerPart: 'Lower Part (<1%)',
  },
  shade: {
    ShadeCode: 'Shade Code',
    ShadeName: 'Name',
    InciListNr: 'List Nr',
    NotificationCode: 'CPNP',
    NotifiedOn: 'Date',
    Compo: 'Compo',
    InciList: 'Inci List',
    compoByRM: 'Shades x RM',
    compoByInci: 'Shades x INCI',
    updateInciList: 'Update Inci Lists',
    updateMaxQty: 'Update Max Qty',
    NameList: 'Name list',
    copyButtonTooltip: 'Generate shade name list to clipboard',
    anastasiaCompo: 'Excel Export for Latin America',
  },
  substanceCosAnnexe: {
    CasNr: 'CasNr',
    EcNr: 'EcNr',
    Inci: 'Inci Name',
    Ratio: 'Ratio',
  },
  shadeCompoModal: {
    title: 'Composition For Shade: %{shadeName}',
  },
  CpsrLoadTemplateModal: {
    title: 'Load Template For Produit: %{productName}',
  },
  substanceInformation: {
    Description: 'Subst INFO Description',
    Specification: 'Subst INFO Specification',
    Method: 'Subst INFO Method',
    Type: 'INFO Type',
    Order: 'Order',
  },
  substanceInformationModal: {
    AddTitle: 'Add SUBST INFO For Substance %{substanceName}',
    EditerTitle: 'Editer SUBST INFO For Substance %{substanceName}',
  },
  substanceActionsModal: {
    title: 'Substance history (%{substanceName})',
  },
  substanceCosAnnexesModal: {
    title: 'Substance CosAnnexes For CosAnnexe: %{cosAnnexeCode}',
  },
  rawMatActionsModal: {
    title: 'RawMat history (%{rawMatName})',
  },
  draftLabel: {
    edit: 'Editer',
    HasInnerLabel: 'Inner Label',
    HasLeaflet: 'Leaflet Label',
    ProduitName: 'Produit Name',
    ProduitFunction: 'Function',
    NominalContents: 'Nominal Contents',
    InstructionText: 'Instruct./Direct./Uses',
    IsInstructionOnInner: 'Present on Inner',
    IsInstructionOnLeaflet: 'Present on Leaflet',
    MarketingTextOnInner: 'Marketing Text',
    MarketingTextOnOuter: 'Marketing Text',
    MarketingTextOnLeaflet: 'Marketing Text',
    IsMarketingtextOnInner: 'Present on Inner',
    OriginInnerText: 'Origin',
    OriginOuterText: 'Origin',
    CompanyInnerText: 'Company',
    CompanyOuterText: 'Company',
    CompanyLeafletText: 'Company',
    IsIngredientOnInner: 'Ingredient list present on Inner',
    IsIngredientOnOuter: 'Ingredient list present on Second',
    IsIngredientOnLeaflet: 'Ingredient list present on Leaflet',
    IsHairColorAttentionSymbolOnInner: 'Hair Color Att.',
    IsHairColorAttentionSymbolOnOuter: 'Hair Color Att.',
    IsHairColorAttentionSymbolOnLeaflet: 'Hair Color Att.',
    WarningText: 'Warning/Precautions',
    ClpSymboles: 'CLP Symb.',
    IsWarningOnInner: 'Present on Inner',
    IsWarningOnOuter: 'Present on Second',
    IsWarningOnLeaflet: 'Present on Leaflet',
    TranslationText: 'Translation Text.',
    IsTranslationOnInner: 'Present on Inner',
    IsTranslationOnOuter: 'Present on Second',
    IsLeafletOnInner: 'Present on Inner',
    IsLeafletOnOuter: 'Present on Second',
    IsLeafletMandatoryOnInner: 'Mandatory',
    IsLeafletMandatoryOnOuter: 'Mandatory',
    IsPAOSymbolOnInner: 'Present on Inner',
    IsPAOSymbolOnOuter: 'Present on Second',
    PAOSymbolRemarkOnOuter: 'PAO/Exp Date Symb',
    PAOHourglassTypeCode: 'PAO Hourglass Type',
    BatchCodeRemarkOnInner: 'Batch #',
    BatchCodeRemarkOnOuter: 'Batch #',
    HasOuterLabel: 'Outer Label',
    IsInstructionOnOuter: 'Present on Second',
    IsMarketingTextOnOuter: 'Present on Second',
    IsMarketingOnLeaflet: 'Present on Leaflet',
    IsCompanyOnLeaflet: 'Present on Leaflet',
    LeafletSymbolTextOnInner: 'Leaflet Symbol',
    LeafletSymbolTextOnOuter: 'Leaflet Symbol',
    PAOSymbolRemarkOnInner: 'PAO/Exp Date Symb',
    IsTranslationOnLeaflet: 'Present on Leaflet',
    CreatedBy: 'Created By',
    CreatedOn: 'Created On',
  },
  draftLabelModal: {
    innerLabel: 'Inner Label',
    outerLabel: 'Outer Label',
    leaflet: 'Leaflet',
    title: 'Draft Label For Produit: %{productName}',
  },
  orderActionsModal: {
    title: 'Order history (%{orderNr})',
  },
  productProportionReOrderModal: {
    title: 'Composition Order For Produit: (%{productName})',
  },
  substanceToxicologicalProfileModal: {
    title: 'Toxicological Profile for Substance: %{substanceName}',
  },
  productCpsrForm: {
    loadTemplate: 'Load Template',
    conclusion: 'Conclusion',
  },
  substanceToxicologicalProfile: {
    AcuteAndRepeatToxicity: 'Acute & Repeat Toxicity',
    IrritationAndPhototox: 'Irritation & Phototox',
    OtherInfo: 'Other Info',
    AcuteOralToxicity: 'Acute Oral Toxicity',
    AcuteDermalToxicity: 'Acute Dermal Toxicity',
    AcuteInhalationToxicity: 'Acute Inhalation Toxicity',
    ReproOralToxicity: 'Reproductible Oral Toxicity',
    GeneMutation: 'Gene Mutation',
    RepeatedOralToxicityDose: 'Repeated Oral Toxicity Dose',
    RepeatedDermalToxicityDose: 'Repeated Dermal Toxicity Dose',
    RepeatedInhalationToxicityDose: 'Repeated Inhalation Toxicity Dose',
    OtherToxicityInfo: 'Other Toxicity Info',
    Clastogenicity: 'Clastogenicity',
    EyeIrritation: 'Eye Irritation',
    SkinIrritation: 'Skin Irritation',
    Sensitization: 'Sensitization',
    Phototoxicity: 'Phototoxicity',
    RegulatoryStatus: 'Regulatory Status',
    SkinPenetration: 'Skin Penetration',
    PivotValue: 'Pivot Value',
  },
  substanceClp: {
    ClpThreshold: 'CLP threshold (%)',
    IsClassified: 'Not Classified',
    SkinEyeCorrosionCat1A: 'Skin Eye Corrosion - Cat. 1A',
    SkinEyeCorrosionCat1B: 'Skin Eye Corrosion - Cat. 1B',
    SkinEyeCorrosionCat1C: 'Skin Eye Corrosion - Cat. 1C',
    SkinIrritantCat2: 'Skin Irritant - Cat. 2',
    SkinIrritantCat3: 'Skin Irritant - Cat. 3',
    EyeDamageCat1: 'Eye Damage - Cat. 1',
    EyeIrritantCat2A: 'Eye Irritant - Cat. 2A',
    EyeIrritantCat2B: 'Eye Irritant - Cat. 2B',
    StotRespirIrritantCat2: 'STOT Respir. Irritant - Cat 2.',
    SkinSensitizer: 'Skin Sensitizer',
    SkinSensitizerLimit: 'Skin Sensitizer Limit',
    InhalationSensitizer: 'Inhalation Sensitizer',
    Skin1A: 'Skin 1A',
    Skin1B: 'Skin 1B',
    Skin2: 'Skin 2',
    Skin3: 'Skin 3',
    Eye1: 'Eye 1',
    Eye2: 'Eye 2',
    Inhal2: 'Inhal 2',
    SpecialConcentrationLimits: 'Special Concentration Limits',
  },
  substance: {
    goToSubstances: 'Go to Substances',
    aelcel: 'AEL/CEL',
    allergen: 'Allergen',
    aseanAnnexes: 'ASEAN Annexes',
    absorptionRate: 'Absorption',
    type: 'Type',
    CalcCondition: 'Calc. Conditions',
    casNr: 'CAS Nr',
    clp: 'CLP',
    code: 'Code',
    comment: 'Comment',
    composition: 'Composition',
    cosAnnexes: 'Cos Annexes',
    cosAnnexes2: 'Cos Annexes (2)',
    cosDirective: 'Cos. Directives',
    cpsrBArguments: 'Other Information',
    ecNr: 'EC Nr',
    function: 'Function',
    functions: 'Functions',
    WithAllergens: 'With Allergens',
    impurity: 'Impurity',
    inciName: 'INCI Name',
    inciNameCanada: 'INCI Name Canada',
    inciNameCombi: 'INCI Name EU/US/Canada',
    inciNameUs: 'INCI Name US',
    information: 'Information',
    innName: 'INN Name',
    isAllergenInPerfume: 'Allergen in Perfume',
    isColourSubstance: 'Cos Colour',
    isImpurity: 'Impurity',
    mineralOrigin: 'Mineral',
    animalOrigin: 'Animal',
    vegetalOrigin: 'Vegetal',
    syntheticOrigin: 'Synthetic',
    microbiologicalOrigin: 'Microbiological',
    otherOrigin: 'Other',
    voc: 'VOC',
    clpThreshold: 'CLP threshold (%)',
    iupacName: 'IUPAC Name',
    InnName: 'INN Name',
    linkedDocuments: 'Documents',
    MaxUse: 'MaxUse',
    mos: 'MoS',
    mosCert: 'MoS CERT',
    mosSystemic: 'MoS<sub>Systemic</sub>',
    mosInhal: 'MoS<sub>locallung</sub> ',
    Tox: 'Tox',
    nano: 'Nano',
    noael: 'NO(A)EL',
    noaelCert: 'NO(A)EL',
    noaelInhal: 'NO(A)EL inhal',
    noaelComment: 'NO(A)EL Comment',
    noaelCertSource: 'NO(A)EL Source',
    nonNano: 'Non-nano',
    panelTitle: 'Substance',
    productList: 'Produit List',
    range: 'Range',
    rawMatList: 'RawMat List',
    substanceList: 'Substance List',
    sed: 'SED',
    sedInhal: 'SED inhal',
    SolubilityCert: 'Solubility',
    VapCert: 'Vap',
    DAaCert: 'DAa',
    DapCert: 'Dap',
    TheoreticalDapCert: 'Theoretical Dap',
    SummaryCert: 'Summary',
    MiscToxCert: 'Miscellaneaous Tox',
    MemoCert: 'Memo',
    ProfileCert: 'Profile',
    CosOtherLimitation: 'Other Limitation',
    CosNote: 'COS Note',
    CosConditionWarning: 'Condition Warning',
    CosOtherSubstance: 'Other Substance',
    SccsOpinions: 'SCCS Opinions',
    DermalToxicity: 'Dermal Toxicity',
    DermalAbsorption: 'Absorption Rate',
    DermalAbsorptionComment: 'Absorption Comment',
    DermalNoael: 'NOAEL',
    DermalNoaelComment: 'NOAEL Comment',
    IngestionToxicity: 'Ingestion Toxicity',
    IngestionAbsorption: 'Absorption Rate',
    IngestionAbsorptionComment: 'Absorption Comment',
    IngestionNoael: 'NOAEL',
    IngestionNoaelComment: 'NOAEL Comment',
    InhalationToxicity: 'Inhalation Toxicity',
    InhalationAbsorption: 'Absorption Rate',
    InhalationAbsorptionComment: 'Absorption Comment',
    InhalationNoael: 'NOAEL',
    InhalationNoaelComment: 'NOAEL Comment',
    Nesil: 'WoE NESIL',
    RiCode: 'RTECS Code',
    DocReferences: 'Documentation Reference',
    LogP: 'Log Pow',
    ImpurityTypeCode: 'Impurity Type',
    Status: 'Status',
    CpsrBTest: 'CPSR Part B Argumentation',
    ImpurityComment: 'CPSR Impurity Argumentation',
    CosDirective: 'Cosmetic Directive',
    DirectiveStatusCode: 'Directive Status Type',
    Restriction: 'Restriction',
    linkCosAnnexe: 'Add a CosAnnexe Ref.',
    Description: 'Description',
    CramerClasseCode: 'Cramer Class',
    CramerClasseDescription: 'Cramer Class Description',
  },
  substanceToxProfile: {
    chapter: 'Chapter *',
    subChapter: 'SubChapter',
    summary: 'Summary',
    description: 'Description *',
    reference: 'Reference',
    order: 'Order *',
    addItem: 'Add Item',
    editTitle: 'Editer Tox Profile Item For Substance : %{inciName}',
    structure: 'Structure',
    structurePasteZone: 'Click here and paste',
  },
  rawMatPhrase: {
    Phrase: 'Phrase',
    Specification: 'Specification',
    Description: 'Info Desc',
    Method: 'Result',
    phCode: 'Ph Code',
    CpsrChapterCode: 'Info Type',
    OrderNr: 'Order',
  },
  document: {
    type: 'Type',
    name: 'Name',
    description: 'Description',
    createdBy: 'Author',
    createdOn: 'Date',
  },
  substanceStatusModal: {
    title: 'Change the substance status from %{currentStatusName} to %{nextStatusName}',
    label: 'Provide a comment (Optional)',
  },
  CpsrStatusModal: {
    title: 'Change the %{CpsrTypeCode} status from %{currentStatusName} to %{nextStatusName}',
    label: 'Provide a comment (Optional)',
  },
  rawMatStatusModal: {
    title: 'Change the rawMat status from %{currentStatusName} to %{nextStatusName}',
    label: 'Provide a comment (Optional)',
  },
  orderStatusModal: {
    title: 'Change the order status from %{currentStatusName} to %{nextStatusName}',
    label: 'Provide a comment (Optional)',
  },
  clp: {
    SkinEyeCorrosionCat1: 'Skin Corr 1',
    EyeDamageCat1: 'Eye Dam 1',
    SkinIrritantCat2: 'Skin Irrit 2',
    SkinSensitiserCat1: 'Skin Sens',
    InhalationSensitiserCat1: 'Inhal Sens',
    IsClassified: 'NCL',
    SkinEyeCorrosionCat1A: 'Skin Corr 1A',
    SkinEyeCorrosionCat1B: 'Skin Corr 1B',
    SkinEyeCorrosionCat1C: 'Skin Corr 1C',
    SkinIrritantCat3: 'Skin Irrit 3',
    EyeIrritantCat2A: 'Eye Irrit 2A',
    EyeIrritantCat2B: 'Eye Irrit 2B',
    StotRespirIrritantCat2: 'Inhal Irrit 2',
    SkinSensitizer: 'Skin Sens',
    InhalationSensitizer: 'Inhal Sens',
  },
  contact: {
    company: 'Company',
    website: 'Website',
    title: 'Title',
    firstname: 'Firstname',
    lastname: 'Lastname',
    position: 'Position',
    email: 'Email',
    phone: 'Phone',
    mobile: 'Mobile',
    fax: 'Fax',
    language: 'Language',
  },
  address: {
    addressLine1: 'Street address',
    addressLine2: 'Street address line 2',
    city: 'City',
    location: 'Location',
    postalCode: 'Postal/zip code',
    country: 'Country',
  },
  phrase: {
    text: 'Text',
    phraseCode: 'Phrase Code',
    CpsrType: 'CPSR Type',
    addTablePhrase: 'Add table',
    chapters: 'Chapter associated',
    chapterSelection: 'Select chapter',
  },
  productPhraseForm: {
    OrderNr: 'Order',
    CpsrChapterCode: 'Chapter',
    CpsrPhraseStyleId: 'Style',
    PhraseCode: 'Code',
    Characteristic: 'Characteristic',
    Specification: 'Specification',
    Results: 'Results',
    TestMethod: 'Test Method',
  },
  shadeCompoRmModal: {
    title: 'Shades X RM For Produit: %{productName}',
  },
  shadeCompoInciModal: {
    title: 'Shades X Inci For Produit: %{productName}',
  },
  modal: {
    leaveRouteDirty: {
      title: 'Are you sure ?',
      message: 'Your data will be lost! Are you sure you want to proceed ? ',
    },
    productForm: {
      title: 'Add new product',
    },
    phraseList: {
      title: 'Phrase List',
    },
    rmInfoForm: {
      title: 'Add RawMat Information',
    },
  },
  shadeModal: {
    title: 'Add Shade For Produit: %{productName}',
  },
  general: {
    actions: 'Actions',
    addPhrase: 'Add phrase',
    cancel: 'Annuler',
    close: 'Close',
    code: 'Code',
    comment: 'Comment',
    createdBy: 'Created By',
    createdOn: 'Created On',
    delete: 'Delete',
    deleteSelected: 'Delete selected',
    description: 'Description',
    dummyTab: 'Dummy',
    duplicate: 'Duplicate',
    edit: 'Editer',
    hideDetails: 'Hide Details',
    name: 'Name',
    no: 'No',
    percentageInParent: '% in Parent',
    percentageInProduit: '% in Produit',
    percentageInProduitForInciCalculation: '% in Produit For Inci Calculation',
    quantityInParent: 'in Parent',
    quantityInProduit: 'in Produit',
    quantityInRawMat: 'in RM',
    rawMat: 'RawMat',
    remove: 'Supprimer',
    required: 'Required',
    reset: 'Reset',
    save: 'Sauver',
    select: 'Select',
    showDetails: 'Show Details',
    substance: 'Substance',
    supplier: 'Supplier',
    yes: 'Yes',
    add: 'Add',
    next: 'Next',
    new: 'New',
    previous: 'Previous',
    enterComment: 'Enter your comment...',
    print: 'Print',
    duplicateMode: 'Duplicate Mode!',
    replaceMode: 'Replace Mode!',
    notAuthorizedAction: 'Not Authorized',
    details: 'Details',
    toxProfileCode: 'Chapter',
    cannotChangeStatus: 'The status cannot be change',
  },
  uom: {
    sed: 'mg/kg',
    noael: 'mg/kg bw/day',
  },
  errorCodes: {
    UnableToSupprimer: 'Unable to remove this resource',
    UnableToValidate: 'Unable to validate this resource',
    UnableToModifyBecauseValidated: 'You cannot modify a validated resource',
  },
  exception: {
    Details: 'Exception Details',
    DateTime: 'Date',
  },
  generalDashboard: {
    search: 'Search',
    clearCriteria: 'Clear',
    company: 'Company',
    product: 'Produit',
    code: 'Produit code',
    shadeCount: 'Shades',
    cpsrA: 'CPSR-A',
    cpsrB: 'CPSR-B',
    draftLabel: 'Draft Label',
    isNotified: 'Notified',
    notifiedOn: 'Notif. date',
    notificationCode: 'Notif. Code',
    formulaReview: 'FR',
  },
  impactAssessment: {
    panelTitle: 'Impact Assessment',
    code: 'Code',
    description: 'Description',
    candidateProduitTab: 'Candidate Produits',
    impactedProduitTab: 'Impacted Produits',
    creationDate: 'Created on',
    status: 'Status',
    generateCandidates: 'Find candidates',
    substance: 'Substance',
    comparator: 'Comparator',
    quantityThreshold: 'Qty Threshold',
    mosThreshold: 'MoS Threshold',
    number: 'id',
    type: 'Type',
    descritpion: 'Description',
    regulatoryRegion: 'Region',
    startDate: 'Start',
    endDate: 'End',
    producer: 'producer',
  },
  impactAssessmentProduit: {
    status: 'Status',
    confirm: 'Confirm',
    ignore: 'Ignore',
    actions: 'Actions',
    calculateValues: 'Calculate values',
  },
  impactAssessmentThreshold: {
    substance: 'Substance',
    type: 'Type',
    comparator: 'Comparator',
    Qty: 'Quantity',
    MoS: 'MoS',
    actions: 'Actions',
  },
  safecosmetic: {
    hasPreview: 'Preview',
    isComplete: 'Complete',
    isPublished: 'Published',
    actions: 'Actions',
    showOnSafecosmetic: 'Safecosmetic',
    showOnCosmos: 'cosmOs',
  },
  dates: {
    startDate: 'Start',
    endDate: 'End',
    yesterday: 'Yesterday',
    lastWeek: 'Last week',
    lastMonth: 'Last month',
    lastYear: 'Last year',
    today: 'Today',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisYear: 'This year',
    yearToDate: 'Year to date',
    byWeek: 'by week',
    byMonth: 'by month',
    byYear: 'by year'
  },
  generalDashboardSearchCriteria: {
    product: 'Produit (code, name, order, formula)',
    producer: 'Producer',
    manufacturer: 'Manufacturer',
    packager: 'Packager',
    responsible: 'Responsible',
    rawMats: 'RawMats',
    active: 'Active',
    notified: 'Notified',
    salesRep: 'Sales Rep.',
    expert: 'Expert',
    accountMgr: 'Account Manager',
  },
  lastFRCheckList: {
    forumlaReviewMissingDocument: "Formula Review Missing Documents",
    mandatoryInformationForCPSR: 'Finished product Annex - Mandatory information for CPSR',
    mandatoryInformationForLabelReview: 'Finished product Annex - Mandatory information for Label review',
    mandatoryInformationForInspection: 'Mandatory information in case of inspection',
    received: 'Document received',
    hasFormulaLabel: 'Formula composition with INCI breakdown',
    hasProduitCofaLabel: 'CofA and/or specification of the finished product',
    hasPackagingInfoLabel: 'Packaging information',
    hasCompatibilityPackaginLabel: 'Compatibility Packaging',
    hasChallengeTestLabel: 'Challenge Test',
    hasMicrobiologicalQualityLabel: 'Microbiological quality',
    hasPrimaryLabel: 'Primary Label (Inner Packaging)',
    hasPrimaryLabelLabel: 'Primary Label (Inner Packaging)',
    hasSecondaryLabelLabel: 'Secondary Label (Outer Packaging)',
    hasProduitSpfTestLabel: 'SPF efficacity Test',
    hasBatchNrLabel: 'Id Batch number',
    hasPackagingProcedure: 'Procedure for Packaging and Filling',
    hasOutPackagingStability: 'Out-packaging stability',
    hasProduitMsds: 'Produit MSDS',
    hasMetrologyStatement: 'Metrology/e-mark statement',
    hasManufacturingMethod: 'Manufacturing method',
    hasQC: 'Quality control, SOP, GMPc - Finished product',
    hasUndesirableEffects: 'Undesirable effects',
    hasNonAnimalTestingStatement: 'Non-animal testing statement for the finished product',
    MissingDocuments: 'Document type',
    MissingDocumentRawMat: 'Raw Mat',
    MissingDocumentMandatory: 'Mandatory',
    verified: 'Document verified',
  },
  modalCopyRawMat: {
    companySource: 'Company Source',
    companyDestination: 'Company Destination',
    title: 'Copy Raw Mat to Company',
    errorMessage: 'Error while copying Raw Mats',
    successMessage: 'Raw Mats copied on company',
  },
  processTemplate: {
    taskTypeTab: 'Task Type',
    processTypeTab: 'Process Type',
  },
  taskType: {
    taskType: 'Task Type',
    editTitle: 'Editer Task Type',
    addTitle: 'Add Task Type',
    code: 'Code',
    name: 'Name',
    processType: 'Process type',
    processTypeCode: 'Process code',
    processDescription: 'Name',
    region: 'Region',
    product: 'Produit',
    expectedDuration: 'Duration (min)',
    expectedDurationUOM: 'UOM',
    deliveryTime: 'Delivery (days)',
    unitPrice: 'Unit Price',
    weight: 'Weight (%)',
    orderNr: 'Order nr',
    processTypeHeader: 'Process Type',
    createProcessTypeModeLabel: 'Create mode',
  },
  processType: {
    processType: 'Process Type',
    processTypeCode: 'Process Code',
    processTypeDescription: 'Description',
    processTypeId: 'Id',
    code: 'Type Code',
    name: 'Name',
    currency: 'Currency',
    unitPrice: 'Unit price',
    unitPriceCurrency: 'Currency',
    pricePerShade: "per shade",
    hasTask: "has task(s)",
    region: 'Region',
    editTitle: 'Editer Process Type',
    addTitle: 'Add Process Type',
    processTypeRefHeader: 'Process Type Ref',
    createProcessTypeModeLabel: 'Create mode',
    expectedDuration: 'Duration',
  },
  orderProcess: {
    currency: 'Currency',
    discountPercent: 'Disc.(%)',
    progress: '% done',
    type: 'Type',
    quantity: 'Qty',
    shadeCount: 'Shades',
    actualShadeCount: 'Actual',
    discount: 'Discount',
    subtotal: 'Subtotal',
    modalTitle: 'Add/Editer process',
    processModalTitle: 'Editer',
    unknown: 'Unknown',
    existingProduit: 'Existing Produit',
    newProduit: 'Quick create new product',
    shadeCountLabel: 'Number of shades or 0',
    product: 'Produit',
    expectedDuration: 'Expected duration (min)'
  },
  orderTask: {
    orderNr: 'Order',
    product: 'Produit name',
    code: 'Produit code',
    task: 'Task',
    taskOrderNr: 'Order',
    accountMgr: 'AM',
    expert: 'Expert',
    status: 'Status',
    memo: 'Memo',
    dueDate: 'Due Date',
    modalTitle: 'Editer task',
    process: 'Process',
    taskType: 'Task Type',
  },
  toDoList: {
    toDoListTabTitle: 'ToDo List',
    reassignTitle: 'Reassign task',
    reassign: 'Reassign',
    start: 'Start',
    stop: 'Stop',
    timeSpent: 'Spent',
    expectedDuration: 'Expected'
  },
  dailyRecords: {
    dailyRecordTabTitle: 'Daily Records',
    orderNr: 'Order',
    productCode: 'Produit code',
    productName: 'Produit name',
    processType: 'Process',
    region: 'Region',
    task: 'Task',
    date: 'Date',
    fromDate: 'From',
    toDate: 'To',
    startDate: 'Start date',
    startTime: 'Start time',
    duration: 'Duration',
    endTime: 'End time',
    finish: 'finish',
    modalTitle: 'Editer Record',
    startedOn: 'Started On',
    finishedOn: 'Finished On',
    company: 'Company',
    expectedDuration: 'Expect. dur.',
  },
  plannerDashboard: {
    title: 'Planner Dashboard',
    producer: 'Producer',
    company: 'Company',
    order: 'Order',
    accountManager: 'Account manager',
    expert: 'Expert',
    processType: 'Process type',
    process: 'Process',
    region: 'Region',
    processFrom: 'Process from',
    to: 'to',
    bill: 'Bill',
    naturalBill: 'Bill (native)',
    convertedBill: 'Bill (converted)',
    value: 'Earned Value',
    naturalValue: 'Earned Value (native)',
    convertedValue: 'Earned Value (converted)',
    progress: 'Progress (%)',
    estimateDuration: 'Expected Duration (min)',
    actualDurantion: 'Actual Duration (min)',
  },
  taskAction: {
    date: 'Date',
    code: 'Type',
    description: 'Type Description',
    comment: 'Comment',
    user: 'User',
  },
  workDashboard: {
    title: 'Work Dashboard',
  }

};
