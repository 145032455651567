import { createRequestTypes } from '../utils';

export const IMPACT_ASSESSMENT_THRESHOLD_LIST = createRequestTypes(
  'impact_assessment/LIST_IMPACT_ASSESSMENT_THRESHOLDS',
);
export const NEW_IMPACT_ASSESSMENT_THRESHOLD = 'impact_assessment/NEW_IMPACT_ASSESSMENT_THRESHOLD';
export const SAVE_IMPACT_ASSESSMENT_THRESHOLD = createRequestTypes(
  'impact_assessment/SAVE_IMPACT_ASSESSMENT_THRESHOLD',
);
export const DELETE_IMPACT_ASSESSMENT_THRESHOLD = createRequestTypes(
  'impact_assessment/DELETE_IMPACT_ASSESSMENT_THRESHOLD',
);
export const ADD_IMPACT_ASSESSMENT_THRESHOLD = 'impact_assessment/ADD_IMPACT_ASSESSMENT_THRESHOLD';
export const EDIT_IMPACT_ASSESSMENT_THRESHOLD = 'impact_assessment/EDIT_IMPACT_ASSESSMENT_THRESHOLD';
