import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { rawMatActions, rawMatTypes, rawMatSelectors } from '../../redux/rawMat';
import { productProportionActions, productProportionTypes } from '../../redux/productProportion';
import { applicationSelectors } from '../../redux/application';
import RawMatList from './RawMatList';
import {companyActions} from "../../redux/company";

class RawMatsPageContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const { params } = this.props;
    const selectedId = params ? params.id : undefined;
    return <RawMatList selectedId={selectedId} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location: { query } } = ownProps;
  const {
    company: { currentCompanyRawMats },
  } = state;
  const getRawMatList = query.productId
    ? rawMatSelectors.makeGetRawMatListForProduct(Number(query.productId))
    : rawMatSelectors.getRawMatList;

  return {
    rawMatList: query.companyId ? currentCompanyRawMats : getRawMatList(state),
    isLoadingRawMatList: applicationSelectors.isLoading(
      state,
      rawMatTypes.RAWMAT_LIST.REQUEST,
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
    ),
    isLoadingDetails: applicationSelectors.isLoading(
      state,
      rawMatTypes.RAWMAT.REQUEST,
      rawMatTypes.SAVE_RAWMAT.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  const { query } = location;
  return {
    onLoad: () =>
      (query.productId
        ? dispatch(productProportionActions.listProductProportionsForProduct.request(Number(query.productId)))
        : query.companyId ? dispatch(companyActions.listCompanyRawMats.request({ id:query.companyId, filter: query.filter }))
          : dispatch(rawMatActions.listRawMats.request())),
    onRefreshList: () => dispatch(rawMatActions.listRawMats.request()),
    onRawMatSelected: id =>
      dispatch(
        push({
          ...location,
          query: { ...location.query, show_details: location.query.show_details },
          pathname: `/rawmats/${id}`,
        }),
      ),
    onAddRawMat: () => dispatch(push({ query: { show_details: true }, pathname: '/rawmats/-1' })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RawMatsPageContainer);
