import { createAction } from 'redux-actions';
import * as R from 'ramda';

export function createRequestTypes(base) {
  return {
    REQUEST: `${base}_REQUEST`,
    SUCCESS: `${base}_SUCCESS`,
    FAILURE: `${base}_FAILURE`,
  };
}

export const getBaseType = R.compose(
  R.invoker(2, 'slice')(0, -1),
  R.replace(/(FAILURE|REQUEST|SUCCESS)$/, ''),
);

export function createRequestActions(type, payload, meta = R.nthArg(1)) {
  return {
    request: createAction(type.REQUEST, payload, meta),
    success: createAction(type.SUCCESS, data => data, meta),
    failure: createAction(type.FAILURE, data => data, meta),
  };
}

export const createFetchAction = (type, idField) =>
  createRequestActions(
    type,
    ({ [idField]: id, ...query } = {}) => ({
      ...(id && { id }),
      ...(Object.keys(query).length > 0 && { query }),
    }),
    (result, data) => data,
  );

const capitalizeWord = R.converge(R.concat, [
  R.compose(
    R.toUpper,
    R.head,
  ),
  R.tail,
]);

const capitalizeKeys = obj => R.zipObj(Object.keys(obj).map(capitalizeWord), Object.values(obj));

const filterByQuery = (state, query) => {
  const pred = R.whereEq(capitalizeKeys(query));
  return R.reject(pred, state);
};

export const filterByQueryAndMerge = entityName => (state, { payload, meta }) => {
  const entities = payload.entities[entityName];
  if (meta.id) return { ...state, ...entities };
  if (meta.query) {
    const filteredState = filterByQuery(state, meta.query);
    return { ...filteredState, ...entities };
  }

  return entities;
};

export const removeById = (state, { payload }) => R.dissoc(payload, state);
