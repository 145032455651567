import { schema } from 'normalizr';
import * as userSchemas from '../user/userSchemas';
import * as roleSchemas from '../role/roleSchemas';

export const userRoleSchema = new schema.Entity('userRoles', {}, { idAttribute: 'UserRoleId' });

export const userRoleWithUserAndRoleSchema = new schema.Entity(
  'userRoles',
  {
    User: userSchemas.userSchema,
    Role: roleSchemas.roleSchema,
  },
  { idAttribute: 'UserRoleId' },
);

export const userRoleListSchema = [userRoleSchema];
export const userRoleWithUserAndRoleListSchema = [userRoleWithUserAndRoleSchema];
