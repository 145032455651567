import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {Form, Grid, Row, Col, Panel, Badge} from 'react-bootstrap';
import {Field, reduxForm, FormSection} from 'redux-form';
import {I18n} from 'react-redux-i18n';
import {InputField, SelectField, CheckBoxField} from '../../../_Common/Forms';
import {ProcessTypeRefListDropdown} from '../../../_Common/Dropdowns';
import {isNullOrUndef} from '../../../../utils/utils';
import formNames from '../../../../constants/formNames';
import {requiredField, isPositiveOrZeroDecimal} from '../../../../utils/fieldValidation';
import ProcessRefBasicFormSection from './ProcessRefBasicFormSection';
import {processTypeFormActions} from '../../../../redux/form/processTypeForm';
import PriceWithCurrencyField from '../../../_Common/Forms/PriceWithCurrencyField';

class ProcessTypeForm extends React.Component {
  state = {createNewProcessTypeRefMode: isNullOrUndef(this.props.processTypeRefCode)};

  componentWillReceiveProps(nextProps) {
    if (this.props.processTypeRefCode !== nextProps.processTypeRefCode) {
      this.setState({createNewProcessTypeRefMode: isNullOrUndef(nextProps.processTypeRefCode)});
    }
  }

  render() {
    const {
      handleSubmit,
      isInEditing,
      onAddButtonClicked,
      onSubmit,
      regions,
      currencies,
      currencyCode,
      ...otherProps
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={12}>
            <Grid fluid>
              <Row>
                <Col sm={12}>
                  <Field
                    name="ProcessTypeCode"
                    label={`${I18n.t('processType.code')} *`}
                    component={InputField}
                    validate={requiredField}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="Description"
                    label={`${I18n.t('processType.processTypeDescription')} *`}
                    component={InputField}
                    validate={requiredField}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="RegionCode"
                    label={`${I18n.t('processType.region')} *`}
                    component={SelectField}
                    options={regions}
                    labelField="Value"
                    valueField="Key"
                    validate={requiredField}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="UnitPrice"
                    label={`${I18n.t('processType.unitPrice')}`}
                    component={PriceWithCurrencyField}
                    currency={currencyCode}
                    validate={[isPositiveOrZeroDecimal]}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="UnitPriceCurrency"
                    label={`${I18n.t('processType.currency')} *`}
                    component={SelectField}
                    options={currencies}
                    labelField="Value"
                    valueField="Key"
                    validate={requiredField}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="PricePerShade"
                    label={`${I18n.t('processType.pricePerShade')} *`}
                    component={CheckBoxField}
                    validate={requiredField}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    name="HasTask"
                    label={`${I18n.t('processType.hasTask')} *`}
                    component={CheckBoxField}
                    validate={requiredField}
                  />
                </Col>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Form>
    );
  }
}

ProcessTypeForm.defaultProps = {
  processTypeCode: undefined,
};

ProcessTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onAddButtonClicked: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isInEditing: PropTypes.bool.isRequired,
  // processTypeList: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     ProcessTypeId: PropTypes.number.isRequired,
  //     Code: PropTypes.string.isRequired,
  //     Value: PropTypes.string.isRequired,
  //   }),
  // ).isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  change: PropTypes.func.isRequired,
  processTypeRefCode: PropTypes.string,
  onProcessTypeRefSelected: PropTypes.func.isRequired,
};

// const mapStateToProps = (state) => ({
//   initialValues: { PricePerShade: false, HasTask: true },
// });

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => processTypeFormActions.submitProcessTypeForm({...data}, dispatch),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.processTypeForm,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProcessTypeForm);
