import {createRequestTypes} from '../utils';


export const PRODUCT_CATEGORY_LIST = createRequestTypes('productCategory/PRODUCT_CATEGORY_LIST');
export const PRODUCT_CATEGORY_LIST_FOR_PRODUCT = createRequestTypes('productCategory/PRODUCT_CATEGORY_LIST_PRODUCT');
export const SAVE_PRODUCT_CATEGORY = createRequestTypes('productCategory/SAVE_PRODUCT_CATEGORY');
export const MARK_PRODUCT_CATEGORY_AS_PRINCIPAL = createRequestTypes('productCategory/MARK_PRODUCT_CATEGORY_AS_PRINCIPAL');
export const DELETE_PRODUCT_CATEGORY = createRequestTypes('productCategory/DELETE_PRODUCT_CATEGORY');

export const ADD_PRODUCT_CATEGORY = 'productCategory/ADD_PRODUCT_CATEGORY';
export const EDIT_PRODUCT_CATEGORY = 'productCategory/EDIT_PRODUCT_CATEGORY';
