import { createSelector } from 'reselect';
import moment from 'moment';
import * as productSelectors from '../product/productSelectors';
import { isNullOrUndef, isUndefined } from '../../utils/utils';
import { createGeneralSelectors } from '../_common/selectors';

const getFormulaReviewSlice = state => state.formulaReview;

export const { makeGetByQueryLoading, makeGetByQuery } = createGeneralSelectors(
  getFormulaReviewSlice,
);


export const getById = ({ formulaReview }) => formulaReview.byId;
export const getIds = ({ formulaReview }) => formulaReview.list.allIds;
export const getFormulaReview = (state, id) => getById(state)[id];
export const getFormulaReviewList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getFormulaReviewListForProduct = createSelector(
  [getFormulaReviewList, productSelectors.getProduct],
  (formulaReviewList, product) =>
    (isNullOrUndef(product) ? [] : formulaReviewList.filter(fr => fr.ProductId === product.ProductId)),
);

export const isAnyFormulaReviewForProduct = createSelector(
  [getFormulaReviewList, productSelectors.getProduct],
  (formulaReviewList, product) =>
    (isNullOrUndef(product) ? false : formulaReviewList.some(fr => fr.ProductId === product.ProductId)),
);


export const getLatestFormulaReviewForProduct = createSelector(getFormulaReviewListForProduct, formulaReviewList =>
  formulaReviewList.reduce((previousLatestFormulaReview, currentFormulaReview) => {
    if (isUndefined(previousLatestFormulaReview)) return currentFormulaReview;
    return moment(previousLatestFormulaReview.CreatedOn).isAfter(moment(currentFormulaReview.CreatedOn))
      ? previousLatestFormulaReview
      : currentFormulaReview;
  }, undefined),
);

// export const isAnyFormulaReviewForProduct = createSelector(
//   getFormulaReviewListForProduct,
//   formulaReviewList => formulaReviewList.length > 0,
// );
