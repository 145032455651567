import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import queryString from 'query-string';
import _ from 'lodash';
import * as rawMatProportionTypes from './rawMatProportionTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';
import * as rawMatTypes from '../rawMat/rawMatTypes';
import * as productTypes from '../product/productTypes';

const byId = handleActions(
  {
    [rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.rawMatProportions,
    }),
    [productTypes.UPDATE_INCI_QTY.SUCCESS]: (state, { payload }) =>
      _.merge({}, state, payload.entities.rawMatProportions),
    [rawMatTypes.UPDATE_INCI_QTY.SUCCESS]: (state, { payload }) =>
      _.merge({}, state, payload.entities.rawMatProportions),
    [rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.SUCCESS]: (state, { payload }) => _.omit(state, [payload]),
    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.rawMatProportions,
    }),
    [rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS]: (state, { payload, meta = {} }) => (payload.entities.rawMatProportions
        ? {
          ...state,
          ...payload.entities.rawMatProportions,
        }
        : state),
  },
  {},
);

const byQueryIds = handleActions(
  {
    [rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result) ? payload.result : [payload.result],
      };
    },
  },
  {},
);

const loading = handleActions(
  {
    [rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.REQUEST]: (state, { payload, meta = {} }) => {
      const { query = {} } = payload;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: true,
      };
    },
    [combineActions(
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS,
      rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.FAILURE,
    )]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(['include'])(query));
      return {
        ...state,
        [querystring]: false,
      };
    },
  },
  {},
);

const allIds = handleActions(
  {
    [rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])], // remove doublon
    [rawMatProportionTypes.FETCH_RAWMAT_PROPORTIONS.SUCCESS]: (state, { payload }) => {
      const ids = Array.isArray(payload.result) ? payload.result : [payload.result];
      return [...new Set([...state, ...ids])];
    },
    [rawMatProportionTypes.DELETE_RAWMAT_PROPORTION.SUCCESS]: (state, { payload, meta }) => {
      const deletedIndex = state.indexOf(payload);
      if (deletedIndex !== -1) {
        return [...state.slice(0, deletedIndex), ...state.slice(deletedIndex + 1)];
      }
      return state;
    },
    [productTypes.UPDATE_INCI_QTY.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [rawMatTypes.UPDATE_INCI_QTY.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => {
      if (payload.entities.rawMatProportions) {
        return [...new Set([...state, ...Object.keys(payload.entities.rawMatProportions).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds,
  }),
  meta: combineReducers({
    loading,
  }),
});
