import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as rawMatTypes from './rawMatTypes';

export const listRawMats = createRequestActions(rawMatTypes.RAWMAT_LIST);
export const rawMat = createRequestActions(rawMatTypes.RAWMAT);
export const saveRawMat = createRequestActions(rawMatTypes.SAVE_RAWMAT);
export const linkCosmosIngredientRawMats = createRequestActions(rawMatTypes.LINK_COSMOS_INGREDIENT_RAWMATS);
export const saveRawMatProportion = createRequestActions(rawMatTypes.SAVE_RAWMAT_PROPORTION);
export const newRawMat = createAction(rawMatTypes.NEW_RAWMAT);
export const addEditRawMatProportion = createAction(rawMatTypes.ADD_EDIT_RAWMAT_PROPORTION);
export const createAndLinkRawMatFromProdigumIngredient = createRequestActions(
  rawMatTypes.CREATE_LINK_RAWMAT_FROM_COSMOS_INGREDIENT,
);
export const listAllowedNewSubstanceInComposition = createRequestActions(
  rawMatTypes.ALLOWED_NEW_SUBSTANCE_LIST_IN_COMPOSITION,
);

export const linkCosmosIngredientRawMatsRequest = data => linkCosmosIngredientRawMats.request(data); // Shorthand for facility
export const linkCosmosIngredientRawMatRequest = data => linkCosmosIngredientRawMats.request([data]); // Shorthand for facility
export const createAndLinkRawMatFromProdigumIngredientRequest = (cosmosIngredientId, productId) =>
  createAndLinkRawMatFromProdigumIngredient.request({ cosmosIngredientId, productId });

export const updateStatus = createRequestActions(rawMatTypes.UPDATE_RAWMAT_STATUS, data => ({
  ...data,
  WorkflowCode: 'RAWMAT',
}));
export const updateInciQty = createRequestActions(rawMatTypes.UPDATE_INCI_QTY, RawMatId => ({
  RawMatId,
}));
