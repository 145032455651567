import * as impactAssessmentActionSelectors from './impactAssessmentActionSelectors';
import * as impactAssessmentActionActions from './impactAssessmentActionActions';
import * as impactAssessmentActionTypes from './impactAssessmentActionTypes';
import sagas from './impactAssessmentActionSagas';
import reducer from './impactAssessmentActionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { impactAssessmentActionSelectors, impactAssessmentActionActions, impactAssessmentActionTypes };
export default reducer;
