import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { SelectionGrid, EditDeleteButtonRenderer } from '../../_Common/Grids';

const SubstanceCompositionList = props => {
  const {
    currentSubstanceComposition,
    getSubstanceProportions,
    isLoadingSubstanceCompositionList,
    onAdd,
    onEdit,
    onDelete,
  } = props;

  const columns = [
    {
      headerName: I18n.t('substance.inciName'),
      field: 'ReferencedSubstance.InciName',
      // cellRenderer: 'group',
    },
    {
      headerName: I18n.t('general.quantityInParent'),
      field: 'Percentage',
      sort: 'desc',
      cellRenderer: ({ value }) => `${value} %`,
    },
    {
      headerName: I18n.t('substance.range'),
      field: 'ReferencedSubstance.InciName',
    },
    {
      headerName: I18n.t('substance.function'),
      field: 'Function.Name',
    },
    {
      headerName: I18n.t('substance.allergen'),
      field: 'IsAllergen',
    },
    {
      headerName: I18n.t('substance.nano'),
      field: 'ReferencedSubstance.InciName',
    },
    {
      headerName: I18n.t('substance.nonNano'),
      field: 'ReferencedSubstance.InciName',
    },
    {
      headerName: I18n.t('substance.isImpurity'),
      field: 'IsImpurity',
    },
    {
      headerName: I18n.t('general.actions'),
      width: 190,
      cellRendererFramework: EditDeleteButtonRenderer,
      cellRendererParams: {
        onDelete: data => onDelete(data.SubstanceProportionId),
        onEdit: data => onEdit(data.SubstanceProportionId),
      },
    },
  ];

  return (
    <SelectionGrid
      data={currentSubstanceComposition}
      columns={columns}
      keyName="SubstanceProportionId"
      isLoading={isLoadingSubstanceCompositionList}
      onRefresh={getSubstanceProportions}
      onAdd={onAdd}
      deltaRowDataMode={false}
    />
  );
};

SubstanceCompositionList.propTypes = {
  currentSubstanceComposition: PropTypes.arrayOf(
    PropTypes.shape({
      Percentage: PropTypes.number.isRequired,
      ReferencedSubstance: PropTypes.shape({
        InciName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  getSubstanceProportions: PropTypes.func.isRequired,
  isLoadingSubstanceCompositionList: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SubstanceCompositionList;
