import { combineReducers } from "redux";
import * as R from "ramda";
import { handleActions, combineActions } from "redux-actions";
import queryString from "query-string";
import * as notificationTypes from "./notificationTypes";

const byId = handleActions(
  {
    [combineActions(
      notificationTypes.NOTIFICATIONS_FOR_PRODUCT_LIST.SUCCESS,
      notificationTypes.FETCH_NOTIFICATIONS.SUCCESS
    )]: (state, { payload }) => 
      payload.entities.notifications
        ? {
            ...state,
            ...payload.entities.notifications
          }
        : state,
  },
  {}
);

const allIds = handleActions(
  {
    [combineActions(
      notificationTypes.NOTIFICATIONS_FOR_PRODUCT_LIST.SUCCESS,
      notificationTypes.FETCH_NOTIFICATIONS.SUCCESS
    )]: ( state, { payload }
    ) => 
      [...new Set([...state, ...payload.result])],
  },
  []
);

const byQueryIds = handleActions(
  {
    [notificationTypes.FETCH_NOTIFICATIONS.SUCCESS]: (
      state,
      { payload, meta = {} }
    ) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(["include"])(query));
      return {
        ...state,
        [querystring]: Array.isArray(payload.result)
          ? payload.result
          : [payload.result]
      };
    }
  },
  {}
);

const loading = handleActions(
  {
    [notificationTypes.FETCH_NOTIFICATIONS.REQUEST]: (
      state,
      { payload, meta = {} }
    ) => {
      const { query = {} } = payload;
      const querystring = queryString.stringify(R.omit(["include"])(query));
      return {
        ...state,
        [querystring]: true
      };
    },
    [combineActions(
      notificationTypes.FETCH_NOTIFICATIONS.SUCCESS,
      notificationTypes.FETCH_NOTIFICATIONS.FAILURE
    )]: (state, { payload, meta = {} }) => {
      const { query = {} } = meta;
      const querystring = queryString.stringify(R.omit(["include"])(query));
      return {
        ...state,
        [querystring]: false
      };
    }
  },
  {}
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byQueryIds
  }),
  meta: combineReducers({
    loading
  })
});
