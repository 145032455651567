import { createAction } from 'redux-actions';
import { createRequestActions } from '../utils';
import * as categoryTypes from './categoryTypes';

export const saveCategory = createRequestActions(categoryTypes.SAVE_CATEGORY);
export const deleteCategory = createRequestActions(categoryTypes.DELETE_CATEGORY, categoryId => ({ categoryId }));
export const updateCategory = createAction(categoryTypes.UPDATE_CATEGORY, (categoryId, data) => ({
  categoryId,
  ...data,
}));

export const listCategories = createRequestActions(categoryTypes.CATEGORY_LIST);
export const category = createRequestActions(categoryTypes.CATEGORY);
export const newCategory = createAction(categoryTypes.NEW_CATEGORY);
