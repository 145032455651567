import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, InputGroup, ControlLabel, Col, HelpBlock } from 'react-bootstrap';
import { css } from 'glamor';

const styles = {
  inputGroupOvverride: css({
    '& .input-group-addon': {
      // minWidth: '150px',
      textAlign: 'right',
    },
  }),
};

const BaseFormGroup = props => {
  const {
    label,
    meta: { error, touched, dirty },
    horizontal,
    children,
    labelColSize,
    groupLabel,
    inputGroupLabel,
  } = props;

  let validationState = null;

  if (touched && error) {
    validationState = 'error';
  } else if (dirty) {
    validationState = 'warning';
  }

  if (!label) {
    return (
      <FormGroup validationState={validationState} bsSize="small">
        <Col sm={12 - labelColSize}>{children}</Col>
        {touched && error && <HelpBlock>{error}</HelpBlock>}
      </FormGroup>
    );
  }

  if (groupLabel) {
    return (
      <FormGroup validationState={validationState} bsSize="small">
        <span className={styles.inputGroupOvverride}>
          <InputGroup bsSize="small">
            {label ? <InputGroup.Addon>{label}</InputGroup.Addon> : null}
            {children}
          </InputGroup>
        </span>
        {touched && error && <HelpBlock>{error}</HelpBlock>}
      </FormGroup>
    );
  }

  if (inputGroupLabel !== null && !groupLabel) {
    return (
      <FormGroup validationState={validationState} bsSize="small">
        {label && <ControlLabel>{label}</ControlLabel>}
        <InputGroup bsSize="small">
          {children}
          <InputGroup.Addon>{inputGroupLabel}</InputGroup.Addon>
        </InputGroup>
        {touched && error && <HelpBlock>{error}</HelpBlock>}
      </FormGroup>
    );
  }

  return (
    <FormGroup validationState={validationState} bsSize="small">
      {horizontal ? (
        <Col componentClass={ControlLabel} sm={labelColSize}>
          {label}
        </Col>
      ) : (
        <ControlLabel>{label}</ControlLabel>
      )}
      {horizontal ? <Col sm={12 - labelColSize}>{children}</Col> : children}
      {touched && error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};
BaseFormGroup.defaultProps = {
  labelColSize: 3,
  inputGroupLabel: null,
  groupLabel: false,
  label: null,
  children: null,
  horizontal: false,
};

BaseFormGroup.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  horizontal: PropTypes.bool,
  children: PropTypes.node,
  labelColSize: PropTypes.number,
  groupLabel: PropTypes.bool,
  inputGroupLabel: PropTypes.string,
};

export default BaseFormGroup;
