import {createSelector} from 'reselect';
import * as R from 'ramda';
import createFilterOptions from 'react-select-fast-filter-options';
import {PrefixIndexStrategy} from 'js-search';
import {createGeneralSelectors} from '../_common/selectors';

const getProcessSlice = state => state.processType;
export const {getById, getIds, makeGetItemById: createGetProductById} = createGeneralSelectors(getProcessSlice);
export const getProcessTypeRefListById = state => state.processType.processTypeRefsById;
export const getProcessTypeRefListAllId = state => state.processType.list.processTypeRefsAllIds;

export const getProcessTypeList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getProcessTypeRefList =
  createSelector([getProcessTypeRefListAllId, getProcessTypeRefListById],
    (allIds, byId) => allIds.map(id => byId[id]),
  );

export const sortByValue = createSelector(R.identity, R.sortWith([R.ascend(R.propOr('', 'Value'))]));

const processTypePrefixIndexStrategy = new PrefixIndexStrategy();
export const makeGetProcessTypeListFilter = createSelector(R.identity, processTypeList =>
  createFilterOptions({
    options: processTypeList,
    labelKey: 'FullName',
    valueKey: 'ProcessTypeId',
    indexStrategy: processTypePrefixIndexStrategy,
  }),
);
