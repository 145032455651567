import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import InciList from './InciList';
import InciText from '../../_Common/InciText';
import InciValidationButton from './InciValidationButton';
import { ButtonToolbar, Button, Grid, Row, Col } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

const InciListTab = ({ showInciValidationButton, showImpuritiesButton, productId, onImpuritiesButtonClick, ...otherProps }) => (
  <div>
    <Grid fluid>
      <Row>
        <Col sm={8}>
          <InciText {...otherProps} />
        </Col>
        <Col>
          <div {...css({ marginBottom: '10px' })}>
              {showInciValidationButton && <Col sm={2}><InciValidationButton productId={productId} /></Col>}
              {showImpuritiesButton && (
              <Col sm={2}>
                <ButtonToolbar>
                  <Button
                    bsSize="small"
                    onClick={onImpuritiesButtonClick}
                    // disabled={actionButtonsDisabled || !isRawMatCompositionEditable}
                  >
                    <Translate value="rawMatCompositionTab.impuritiesButtonLabel" />
                  </Button>
                </ButtonToolbar>
              </Col>
              )}
          </div>
        </Col>
      </Row>
    </Grid>
    <InciList {...otherProps} />
  </div>
);

InciListTab.propTypes = {
  showInciValidationButton: PropTypes.bool.isRequired,
  productId: PropTypes.number.isRequired,
};

export default InciListTab;
