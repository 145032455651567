import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import * as R from 'ramda';
import {GeneralGrid, DeleteButtonRenderer, EditButtonRenderer, DateCellRenderer, LinkCellRenderer} from '../../_Common/Grids';
import { Button, ButtonToolbar } from 'react-bootstrap';

const getYN = ({data, colDef}) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const getNoLongerInMarket = ({data, colDef}) => {
  return data.IsNoLongerOnMarket ? 'yes' : '';
};

const actionButtonsRenderer = props => {
  const { data, onClick } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <ButtonToolbar>
        <Button bsSize="xs" type="button" onClick={() => onClick(data)}>
          {/* <i className="fa fa-print" aria-hidden="true" /> */}
          &nbsp;
          <Translate value="order.copyList" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};

const OrderProductList = props => {
  const {
    currentOrderProducts,
    isLoading,
    getOrderProducts,
    onRowDoubleClicked,
    onDeleteClick,
    onCopyListClick,
    onCopySelectedListButtonClick,
    onShowMissingDocumentListButtonClick,
    onAddButtonClick,
    workflowStatusNameById,
    isAddDisabled,
    showAddButton,
    allowDelete,
    orderId,
    selectedProduct
  } = props;

  const productName = selectedProduct.ProductName;
  const productCode = selectedProduct.Code;
  const getWorkflowName = ({data, colDef}) => R.propOr('', 'Name', workflowStatusNameById[data[colDef.field]]);

  const columns = [
    {
      headerName: I18n.t('product.code'),
      sort: 'desc',
      field: 'Code',
      cellRendererFramework: LinkCellRenderer,
      width: 75,
      cellRendererParams: {
        textKey: 'Code',
        linkKey: 'Url',
        downloadable: false
      },
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.code'),
    //   field: 'Code',
    //   width: 75,
    //   suppressSizeToFit: true,
    //   sort: 'desc',
    //   cellStyle: {color: 'blue', textDecoration: 'underline dotted'},
    // },
    {
      headerName: I18n.t('product.productName'),
      field: 'ProductName',
      width: 150,
      minWidth: 200,
      // suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.responsiblePerson'),
    //   field: 'ResponsibleCompany.ShortName',
    //   width: 80,
    //   minWidth: 20,
    //   // suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.manufacturerName'),
    //   field: 'Manufacturer.ShortName',
    //   width: 100,
    //   minWidth: 20,
    //   // suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.isConfidential'),
    //   field: 'IsConfidential',
    //   width: 65,
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    //   suppressFilter: true,
    // },
    // {
    //   headerName: I18n.t('product.NumOfShades'),
    //   width: 60,
    //   field: 'ShadeCount',
    //   suppressSizeToFit: true,
    //   suppressFilter: true,
    // },
    // // {
    // //   headerName: I18n.t('product.CompoStatus'),
    // //   width: 100,
    // //   field: 'IsNoLongerOnMarket',
    // //   valueGetter: getWorkflowName,
    // //   suppressSizeToFit: true,
    // // },
    {
      headerName: I18n.t('product.CompoStatus'),
      width: 85,
      field: 'CompositionStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.CPSRAStatus'),
      width: 85,
      field: 'CpsrAStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.CPSRBStatus'),
      width: 85,
      field: 'CpsrBStatus',
      valueGetter: getWorkflowName,
      suppressSizeToFit: true,
    },
    // // {
    // //   headerName: I18n.t('product.CPSRBType'),
    // //   width: 100,
    // //   field: 'CpsrBConclusionStatus',
    // //   valueGetter: getWorkflowName,
    // //   suppressSizeToFit: true,
    // // },
    // // {
    // //   headerName: I18n.t('product.isLabelApprovalDone'),
    // //   width: 60,
    // //   field: 'IsLabelApprovalDone',
    // //   valueGetter: getYN,
    // //   suppressSizeToFit: true,
    // // },
    // // {
    // //   headerName: I18n.t('product.partAToDo'),
    // //   width: 60,
    // //   field: 'IsCpsrAToDo',
    // //   valueGetter: getYN,
    // //   suppressSizeToFit: true,
    // // },
    // // {
    // //   headerName: I18n.t('product.cosmosWorkflow'),
    // //   width: 100,
    // //   field: 'CosmosProductStatus',
    // //   // suppressSizeToFit: true,
    // //   // suppressFilter: true,
    // // },
    // {
    //   headerName: I18n.t('product.completion'),
    //   width: 60,
    //   field: 'Progress',
    //   suppressSizeToFit: true,
    //   suppressFilter: true,
    // },
    // {
    //   headerName: I18n.t('product.IsNotified'),
    //   width: 60,
    //   field: 'IsNotified',
    //   valueGetter: getYN,
    //   suppressSizeToFit: true,
    //   suppressFilter: true,
    // },
    {
      headerName: I18n.t('product.IsNotifiedEU'),
      width: 100,
      field: 'NotifiedEU',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('product.CpnpCat3EU'),
      width: 100,
      field: 'NotificationCodeEU',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.IsNotifiedUK'),
      width: 100,
      field: 'NotifiedUK',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('product.CpnpCat3UK'),
      width: 100,
      field: 'NotificationCodeUK',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.IsNotifiedCAN'),
      width: 100,
      field: 'NotifiedCAN',
      suppressSizeToFit: true,
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
    },
    {
      headerName: I18n.t('product.CpnpCat3CAN'),
      width: 100,
      field: 'NotificationCodeCAN',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('product.IsNoLongerOnMarket'),
      width: 50,
      field: 'IsNoLongerOnMarket',
      valueGetter: getNoLongerInMarket,
      suppressSizeToFit: true,
    },
    // {
    //   headerName: I18n.t('product.dl'),
    //   width: 60,
    //   field: 'dl',
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('product.CpnpCat3'),
    //   width: 80,
    //   field: 'NotificationCode',
    //   suppressSizeToFit: true,
    // },
    // // {
    // //   headerName: I18n.t('product.dl'),
    // //   width: 60,
    // //   field: 'dl',
    // //   suppressSizeToFit: true,
    // // },
    // // {
    // //   headerName: I18n.t('product.CpnpCat3'),
    // //   width: 100,
    // //   field: 'CpnpCat3',
    // //   suppressSizeToFit: true,
    // // },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      width: 80,
      minWidth: 80,
      suppressSizeToFit: true,
      cellRendererParams: {
        disabled: !allowDelete,
        onClick: ({ProductId}) => onDeleteClick(ProductId),
      },
    },
    // {
    //   headerName: '',
    //   cellRendererFramework: EditButtonRenderer,
    //   width: 70,
    //   minWidth: 70,
    //   suppressSizeToFit: true,
    //   cellRendererParams: {
    //     disabled: !allowDelete,
    //     onClick: ({ ProductId, ProductName, Code }) => {
    //       console.log(props);
    //       onCopyListClick(props.currentOrderProducts, ProductId, ProductName, Code, props.selectedProduct);
    //     }
    //   },
    // },
    // {
    //   headerName: '',
    //   cellRendererFramework: actionButtonsRenderer,
    //   width: 70,
    //   minWidth: 70,
    //   suppressSizeToFit: true,
    //   cellRendererParams: {
    //     disabled: !allowDelete,
    //     onClick: ({ ProductId, ProductName, Code }) => {
    //       console.log(props);
    //       onCopyListClick(props.currentOrderProducts, ProductId, ProductName, Code, props.selectedProduct);
    //     }
    //   },
    // },
  ];

  
  currentOrderProducts.forEach(element => {
    element.Url = '#products/' + element.ProductId;
  });

  return (
    <GeneralGrid
      key={`product-${orderId}`}
      data={currentOrderProducts}
      columns={columns}
      keyName="ProductId"
      isLoading={isLoading}
      onRefresh={getOrderProducts}
      rowDoubleClicked={onRowDoubleClicked}
      onAdd={onAddButtonClick}
      addDisabled={isAddDisabled}
      showAddButton={showAddButton}
      extraButtonLabel= "Missing documents"
      onExtraButtonClick={(rows) => {
        onShowMissingDocumentListButtonClick(orderId);
        // onShowMissingDocumentListButtonClick(rows.map(product => product.ProductId));
        // onCopySelectedListButtonClick(rows.map(product => product.ProductId));
      }}
    />
  );
};

OrderProductList.propTypes = {
  currentOrderProducts: PropTypes.arrayOf(
    PropTypes.shape({
      ProductId: PropTypes.number.isRequired,
      ProductName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getOrderProducts: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onCopyListClick: PropTypes.func.isRequired,
  isAddDisabled: PropTypes.bool.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  showAddButton: PropTypes.bool.isRequired,
  allowDelete: PropTypes.bool.isRequired,
  workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
};

export default OrderProductList;
