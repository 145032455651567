import { createAction } from 'redux-actions';
import * as labelSymbolWizardTypes from './labelSymbolWizardTypes';
import { createUpdateAction } from '../../../../utils/actions';

export const addNewLabelSymbol = createAction(labelSymbolWizardTypes.ADD_NEW_LABEL_SYMBOL);
export const removeLabelSymbol = createAction(labelSymbolWizardTypes.REMOVE_LABEL_SYMBOL);
export const handleFieldEdit = createUpdateAction(labelSymbolWizardTypes.FIELD_EDITED);
export const updateLabelSymbol = createAction(labelSymbolWizardTypes.UPDATE_LABEL_SYMBOL);
export const loadLabelSymbolData = createAction(labelSymbolWizardTypes.LOAD_LABEL_SYMBOL_DATA);
export const markAsDeleted = createAction(labelSymbolWizardTypes.MARK_AS_DELETED);
