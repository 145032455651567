import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { productSelectors } from '../../../redux/product';
import { SelectField } from '../Forms';

const ProductListDropdown = props => {
  const { productList, productListFilter, sortByName, children } = props;

  const filterOptions = (...args) => sortByName(productListFilter(...args));

  return children ? (
    children({ productList, filterOptions })
  ) : (
    <SelectField
      options={productList}
      labelField="FullName"
      valueField="ProductId"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  let productList = initialOptions || productSelectors.getProductList(state);
  productList = getProductList(productList);
  return {
    productList,
    sortByName: productSelectors.sortByName,
    productListFilter: productSelectors.makeGetProductListFilter(productList),
  };
};

ProductListDropdown.defaultProps = {
  children: null,
};

ProductListDropdown.propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      ProductId: PropTypes.number.isRequired,
      Code: PropTypes.string,
      ProductName: PropTypes.string,
    }),
  ).isRequired,
  productListFilter: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const getProductList = createSelector(R.identity, productList => {
  const setProductFullName = product =>
    R.assoc('FullName', `${R.prop('Code', product)} - ${R.prop('ProductName', product)}`, product);
  return productList.map(setProductFullName);
});

export default connect(mapStateToProps, null)(ProductListDropdown);
