import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { filterByQueryAndMerge, removeById } from '../utils';
import * as rawMatPhraseTypes from './rawMatPhraseTypes';

const byId = handleActions(
  {
    [rawMatPhraseTypes.FETCH_ALL_RAWMAT_PHRASES.SUCCESS]: filterByQueryAndMerge('rawMatPhrases'),
    [rawMatPhraseTypes.FETCH_RAWMAT_PHRASES.SUCCESS]: filterByQueryAndMerge('rawMatPhrases'),
    [rawMatPhraseTypes.DELETE_RAWMAT_PHRASE.SUCCESS]: removeById,
  },
  {},
);

// const allIds = handleActions(
//   {
//     [rawMatPhraseTypes.FETCH_ALL_RAWMAT_PHRASES.SUCCESS]: (state, { payload }) => payload.result,
//     [rawMatPhraseTypes.DELETE_RAWMAT_PHRASE.SUCCESS]: (state, { payload }) => R.without([payload], state),
//   },
//   [],
// );

export default combineReducers({
  byId,
  // list: combineReducers({
  //   allIds,
  // }),
});
