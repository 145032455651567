export default {
  PRODUCT_PROPOSED: 'PRODUCT_PROPOSED',
  PRODUCT_VALIDATED: 'PRODUCT_VALIDATED',
  CPSRA_NOTDONE: 'CPSRA_NOTDONE',
  CPSRA_INSERTED: 'CPSRA_INSERTED',
  CPSRA_CHECKED: 'CPSRA_CHECKED',
  CPSRA_VALIDATED: 'CPSRA_VALIDATED',
  CPSRB_NOTDONE: 'CPSRB_NOTDONE',
  CPSRB_INSERTED: 'CPSRB_INSERTED',
  CPSRB_CHECKED: 'CPSRB_CHECKED',
  CPSRB_VALIDATED: 'CPSRB_VALIDATED',
  PRODUCT_COMPO_NOTDONE: 'PRODUCT_COMPO_NOTDONE',
  PRODUCT_COMPO_INSERTED: 'PRODUCT_COMPO_INSERTED',
  PRODUCT_COMPO_CHECKED: 'PRODUCT_COMPO_CHECKED',
  PRODUCT_COMPO_VALIDATED: 'PRODUCT_COMPO_VALIDATED',
  RAWMAT_PROPOSED: 'RAWMAT_PROPOSED',
  RAWMAT_VALIDATED: 'RAWMAT_VALIDATED',
  SUBSTANCE_CREATED: 'SUBSTANCE_CREATED',
  SUBSTANCE_PROPOSED: 'SUBSTANCE_PROPOSED',
  SUBSTANCE_VALIDATED: 'SUBSTANCE_VALIDATED',
  ORDER_PROPOSED: 'ORDER_PROPOSED',
  ORDER_STARTED: 'ORDER_STARTED',
  ORDER_COMPLETED: 'ORDER_COMPLETED',
  AGREEMENT_CREATED: 'AGREEMENT_CREATED',
  AGREEMENT_SENT: 'AGREEMENT_SENT',
  AGREEMENT_SIGNED: 'AGREEMENT_SIGNED',
  AGREEMENT_REVOKED: 'AGREEMENT_REVOKED',
  MANDATE_CREATED: 'MANDATE_CREATED',
  MANDATE_ONGOING: 'MANDATE_ONGOING',
  MANDATE_VALID: 'MANDATE_VALID',
  MANDATE_SUPERSEDED: 'MANDATE_SUPERSEDED',
  MANDATE_REVOKED: 'MANDATE_REVOKED',
  CPSRB_CONCLUSION_NOTDONE: 'CPSRB_CONCLUSION_NOTDONE',
  CPSRB_CONCLUSION_POSITIVE: 'CPSRB_CONCLUSION_POSITIVE',
  CPSRB_CONCLUSION_NEGATIVE: 'CPSRB_CONCLUSION_NEGATIVE',
  DOCUMENT_MISSING: 'DOCUMENT_MISSING',
  DOCUMENT_RECEIVED_VERIFIED: 'DOCUMENT_RECEIVED_VERIFIED',
  DOCUMENT_RECEIVED: 'DOCUMENT_RECEIVED',
  // DOCUMENT_VERIFIED: 'DOCUMENT_VERIFIED',

};
