import * as functionSelectors from './functionSelectors';
import * as functionActions from './functionActions';
import * as functionTypes from './functionTypes';
import sagas from './functionSagas';
import reducer from './functionReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { functionSelectors, functionActions, functionTypes };
export default reducer;
