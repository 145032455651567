import { takeLatest, takeEvery, put, all, select, take } from 'redux-saga/effects';
import uuidV1 from 'uuid/v1';
import { normalize } from 'normalizr';
import { isNullOrUndef } from '../../../../utils/utils';
import { labelReviewClaimSelectors, labelReviewClaimSchemas } from '../../../labelReviewClaim';
import { referentialsTypes, referentialSelectors } from '../../../referentials';
import { labelReviewClaimWizardActions, labelReviewClaimWizardTypes } from './index';
import { labelReviewWizardTypes, labelReviewWizardSelectors } from '../../labelReviewWizard';
import { labelReviewTypes } from '../../../labelReview';

export default function* root() {
  yield all([
    takeLatest(
      [labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS, labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS],
      newOrEditLabelReviewSaga,
    ),
    takeEvery(labelReviewClaimWizardTypes.ADD_BUTTON_CLICKED, onAddButtonClickedSaga),
    takeEvery(labelReviewClaimWizardTypes.DELETE_BUTTON_CLICKED, onDeleteButtonClickedSaga),
    takeEvery(labelReviewClaimWizardTypes.FIELD_EDITED, onEditFieldSaga),
    takeEvery(labelReviewClaimWizardTypes.REVERT_BUTTON_CLICKED, onRevertButtonClickedSaga),
  ]);
}

function* onRevertButtonClickedSaga({ payload }) {
  const LabelReviewClaimId = payload;
  const remark = yield select(referentialSelectors.getLabelReviewClaimDefaultRemarks);
  yield put(
    labelReviewClaimWizardActions.updateLabelReviewClaim({
      id: LabelReviewClaimId,
      Remarks: remark,
    }),
  );
}

function* onEditFieldSaga({ payload }) {
  yield put(labelReviewClaimWizardActions.updateLabelReviewClaim(payload));
}

function* onDeleteButtonClickedSaga({ payload }) {
  const labelReviewClaimId = payload;
  yield put(labelReviewClaimWizardActions.removeLabelReviewClaim(labelReviewClaimId));
}

function* onAddButtonClickedSaga() {
  const ProductId = yield select(labelReviewWizardSelectors.getProductId);
  const remark = yield select(referentialSelectors.getLabelReviewClaimDefaultRemarks);
  const newLabelReviewClaim = {
    ProductId,
    LabelReviewClaimId: uuidV1(),
    Remarks: remark,
  };
  yield put(labelReviewClaimWizardActions.addNewLabelReviewClaim(newLabelReviewClaim));
}

function* newOrEditLabelReviewSaga({ payload }) {
  const { LabelReviewId, ProductId } = payload;
  const editMode = !isNullOrUndef(LabelReviewId);

  const isLoading = yield select(labelReviewWizardSelectors.isLoadingWizard);
  if (isLoading) {
    yield take([referentialsTypes.ALL_REFERENTIALS.SUCCESS, labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]);
  }

  const currentLabelReviewClaimList = editMode
    ? yield select(state => labelReviewClaimSelectors.getLabelReviewClaimListForLabelReview(state, LabelReviewId))
    : yield select(state => labelReviewClaimSelectors.getLabelReviewClaimListFromLatestLabelReview(state, ProductId));

  const labelReviewClaimList = editMode
    ? currentLabelReviewClaimList
    : currentLabelReviewClaimList.map(lrClaim => ({
      ...lrClaim,
      LabelReviewId: null,
      LabelReviewClaimId: uuidV1(),
    }));

  const result = normalize(labelReviewClaimList, labelReviewClaimSchemas.labelReviewClaimListSchema);
  yield put(labelReviewClaimWizardActions.loadLabelReviewClaimData(result));
}
