import { schema } from 'normalizr';
import { phraseSchemas } from '../phrase';

export const phraseCpsrChapterSchema = new schema.Entity(
  'phraseCpsrChapters',
  {
    Phrase: phraseSchemas.phraseSchema,
  },
  { idAttribute: 'PhraseCpsrChapterId' },
);

export const phraseCpsrChapterListSchema = [phraseCpsrChapterSchema];
