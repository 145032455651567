import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import * as R from 'ramda';
import { DateCellRenderer } from '../../_Common/Grids';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import EditButton from '../../_Common/Grids/EditButton';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { css } from 'glamor';

const EditButtonsRenderer = props => {
  const { onEditClick, onPrintButtonClick, onQuickPrintButtonClick, data } = props;

  const handleButtonClick = fn => () => fn(data);

  return (
    <ButtonToolbar>
      <EditButton onClick={handleButtonClick(onEditClick)} />
      <Button bsSize="xsmall" type="button" onClick={handleButtonClick(onPrintButtonClick)}>
        <i className="fa fa-print" aria-hidden="true" />
        &nbsp;
        <Translate value="mandates.print" />
      </Button>
      <Button bsSize="xsmall" type="button" onClick={handleButtonClick(onQuickPrintButtonClick)}>
        <i className="fa fa-print" aria-hidden="true" />
        &nbsp;
        <Translate value="mandates.quickPrint" />
      </Button>
    </ButtonToolbar>
  );
};

const DistributorMandateList = props => {
  const {
    mandateList,
    isLoading,
    onRefresh,
    companyDistributorAgreementList,
    companyList,
    onEditButtonClick,
    mandateStatusList,
    onPrintButtonClick,
    onQuickPrintButtonClick,
  } = props;

  const getMandateStatusByCode = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('Code'), R.identity]))(mandateStatusList),
  );

  const getCompanyById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('CompanyId'), R.identity]))(companyList),
  );

  const getAgreementById = R.compose(
    R.defaultTo({}),
    R.flip(R.useWith(R.find, [R.propEq('CompanyDistributorAgreementId'), R.identity]))(companyDistributorAgreementList),
  );

  const getCompanyFromRow = R.compose(
    getCompanyById,
    R.prop('CompanyId'),
    getAgreementById,
    R.path(['data', 'AgreementId']),
  );

  const styles = {
    backgreen: css({
      backgroundColor: '#81CF6F',
      color: 'white'
    }),
    backred: css({
      backgroundColor: '#D35457',
      color:'white'
    })
  };

  const columns = [
    {
      headerName: I18n.t('distributorAgreements.id'),
      field: 'MandateId',
      width: 50,
      sort: 'desc',
    },
    {
      headerName: I18n.t('mandates.company'),
      valueGetter: R.compose(
        R.propOr('', 'ShortName'),
        getCompanyFromRow,
      ),
    },
    {
      headerName: I18n.t('mandates.brand'),
      field: 'CosmeticBrand',
    },
    {
      headerName: I18n.t('mandates.CreatedOn'),
      field: 'CreatedOn',
      cellRenderer: DateCellRenderer,
      cellRendererParams: { includeHours: true, },
      width: 70,
      minWidth: 70,
    },
    {
      headerName: I18n.t('mandates.ValidityStartDate'),
      field: 'ValidityStartDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: { includeHours: true, },
      width: 70,
      minWidth: 70,
    },
    {
      headerName: I18n.t('mandates.status'),
      valueGetter: R.compose(
        R.prop('Name'),
        getMandateStatusByCode,
        R.path(['data', 'StatusCode']),
      ),
      width: 70,
      cellClassRules: {
        [styles.backred]: function (params) {
          return params.value === 'Revoked';
        },
        [styles.backgreen]: function (params) {
          return params.value === 'Ongoing';
        },
      }
    },
    // {
    //   headerName: I18n.t('mandates.mandate'),
    //   field: 'FileName',
    //   cellRendererFramework: LinkCellRenderer,
    //   cellRendererParams: {
    //     textKey: 'FileName',
    //     linkKey: 'FileLink',
    //   },
    //   suppressSizeToFit: true,
    // },
    {
      headerName: I18n.t('general.actions'),
      cellRendererFramework: EditButtonsRenderer,
      cellRendererParams: {
        onEditClick: onEditButtonClick,
        getButtonDisabled: R.compose(R.prop('StatusCode')),
        onPrintButtonClick,
        onQuickPrintButtonClick,
      },
      width: 100,
      minWidth: 100,
    },
  ];

  return (
    <SelectionGrid
      data={mandateList}
      onRefresh={onRefresh}
      isLoading={isLoading}
      columns={columns}
      keyName="MandateId"
      showAddButton={false}
    />
  );
};

DistributorMandateList.propTypes = {
  mandateList: PropTypes.array.isRequired, //eslint-disable-line
  companyDistributorAgreementList: PropTypes.array.isRequired, //eslint-disable-line
  companyList: PropTypes.array.isRequired, //eslint-disable-line
  mandateStatusList: PropTypes.array.isRequired, //eslint-disable-line
  documentList: PropTypes.array.isRequired, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
};

export default DistributorMandateList;
