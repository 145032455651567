import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import service from './data.js';

const tasks = service.getTasks();

class ProductProportionRMCompositionTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.dataSource1 = props.data.data.Children.sort((a,b) => {
      return b.Percentage - a.Percentage
    });// getTasks(props.data.key);
    let attributes = [];
    this.dataSource1.map(child => {
      const name = child.Name + (child.IsImpurity == true?"#IMP":"");
      const percentage = child.Percentage;
      attributes[name] = percentage;
      // return attributes
    });
    this.dataSource = [attributes];
  }

  onCellPrepared = (e) => {
    // if(e.rowType === "data" || e.rowType ==="header") {
        e.cellElement.style.textAlign="left";
        e.cellElement.style.width = "100";
        if (e.cellElement.innerText.includes("#IMP")) {
          e.cellElement.innerText = e.cellElement.innerText.substring(0, e.cellElement.innerText.length -4);
          e.cellElement.style.color = "red";
        }
        // // Tracks the `Amount` data field
        // e.watch(function() {
        //     return e.data.Amount;
        // }, function() {
        //     e.cellElement.style.color = e.data.Amount >= 10000 ? "green" : "red";
        // })
    // }
    // else {
    //   console.log(e.rowType);
    // }
  }

  // renderTitleHeader = (data) => {
  //   return <p style={{ font-size: '16px' }}>{data.column.caption}</p>;
  // }

  render() {
    // let { Code, Name, Percentage } = this.props.data.data.Children;
    return (
      <React.Fragment>
        {/* <div className="master-detail-caption">
          {`${Code} ${Name}'s Tasks`}
        </div> */}
        <DataGrid
          dataSource={this.dataSource}
          showBorders={true}
          onCellPrepared={this.onCellPrepared}
          // headerCellRender={renderTitleHeader}
          // columnAutoWidth={false}
        >
        </DataGrid>
      </React.Fragment>
    );
  }
  completedValue(rowData) {
    return rowData.Status === 'Completed';
  }
}

function getTasks(key) {
  return new DataSource({
    store: new ArrayStore({
      data: tasks,
      key: 'ID'
    }),
    filter: ['EmployeeID', '=', key]
  });
}

export default ProductProportionRMCompositionTemplate;