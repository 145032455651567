import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { dissoc, without } from 'ramda';
import * as labelSymbolWizardTypes from './labelSymbolWizardTypes';
import * as labelReviewSymbolWizard from "../labelReviewSymbolWizard/labelReviewSymbolWizardTypes";

const labelSymbolsByCode = handleActions(
  {
    [labelSymbolWizardTypes.LOAD_LABEL_SYMBOL_DATA]: (state, { payload }) => ({
      ...payload.entities.labelSymbols,
    }),
    [labelSymbolWizardTypes.ADD_NEW_LABEL_SYMBOL]: (state, { payload }) => {
      const { Code } = payload;
      return {
        ...state,
        [Code]: { ...payload, Public: false },
      };
    },
    [labelSymbolWizardTypes.UPDATE_LABEL_SYMBOL]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
    [labelSymbolWizardTypes.REMOVE_LABEL_SYMBOL]: (state, { payload }) => dissoc(payload, state),
    [labelReviewSymbolWizard.UPDATE_SYMBOL_DOCUEMNTID]: (state, { payload }) => {
      state[payload.Code].DocumentId = payload.DocId;
      return state;
    }
  },
  {},
);

const labelSymbolsAllCodes = handleActions(
  {
    [labelSymbolWizardTypes.LOAD_LABEL_SYMBOL_DATA]: (state, { payload }) => payload.result,
    [labelSymbolWizardTypes.ADD_NEW_LABEL_SYMBOL]: (state, { payload }) => [...state, payload.Code],
    [labelSymbolWizardTypes.REMOVE_LABEL_SYMBOL]: (state, { payload }) => without([payload], state),
  },
  [],
);

const deletedCodes = handleActions(
  {
    [labelSymbolWizardTypes.MARK_AS_DELETED]: (state, { payload }) => [...new Set([...state, payload])],
    [labelSymbolWizardTypes.LOAD_LABEL_SYMBOL_DATA]: () => [],
  },
  [],
);

export default combineReducers({
  byCode: labelSymbolsByCode,
  list: combineReducers({
    allCodes: labelSymbolsAllCodes,
    deletedCodes,
  }),
});
