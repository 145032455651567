import { get } from './apiUtils';

export default {
  login: (username, password) =>
    // FAKE SERVER CALL
    // return new Promise((resolve, reject) => {
    //   resolve({
    //     userName: 'sga fake data',
    //   });
    // });

    get('/Token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `grant_type=password&username=${username}&password=${password}`,
    })
  ,
};
