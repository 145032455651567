import queryString from 'query-string';
import { get, post, del } from './apiUtils';

const defaultUrl = '/phrases/';

export default {
  // getPhraseList: () => get(defaultUrl),
  getPhraseList: ({ id, query } = {}) =>
    get(`${defaultUrl}${id || ''}${query ? '?'.concat(queryString.stringify(query)) : ''}`),

  getPhrase: id => get(`${defaultUrl}${id}`),
  savePhrase: data => post(`${defaultUrl}`, data),
  deletePhrase: id => del(`${defaultUrl}${id}`),
};
