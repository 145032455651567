import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { Row, Col, Grid, Checkbox, FormControl, FormGroup, Button } from 'react-bootstrap';
import Select from 'react-select';

class RawMatInformation extends React.Component {
  state = {
    showMandatoryRawMatsFreeText: this.props.rawMatDataReview.MandatoryRawMatsFreeText,
    showNotMandatoryRawMatsFreeText: this.props.rawMatDataReview.NotMandatoryRawMatsFreeText,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.rawMatDataReview !== nextProps.rawMatDataReview && nextProps.rawMatDataReview) {
      const { MandatoryRawMatsFreeText, NotMandatoryRawMatsFreeText } = nextProps.rawMatDataReview;
      this.setState(prevState => ({
        showMandatoryRawMatsFreeText: prevState.showMandatoryRawMatsFreeText || MandatoryRawMatsFreeText,
        showNotMandatoryRawMatsFreeText: prevState.showNotMandatoryRawMatsFreeText || NotMandatoryRawMatsFreeText,
      }));
    }
  }

  render() {
    const {
      rawMatList,
      updateRawMatDataReview,
      rawMatDataReview: {
        RawMatAnnexePart,
        RawMatDataReviewId,
        MandatoryRawMats,
        MissingDocumentsMandatoryIds,
        MandatoryRawMatsFreeText,
        NotMandatoryRawMats,
        MissingDocumentsNotMandatoryIds,
        NotMandatoryRawMatsFreeText,
        IsOk,
        Comment,
      },
    } = this.props;
    const styles = {
      select: css({ flex: 1, marginRight: '5px' }),
      rmContainer: css({ display: 'flex', marginBottom: '5px' }),
      freeText: css({ marginRight: '5px', marginBottom: '15px' }),
    };

    return (
      <div>
        <Row>
          <Col sm={1}>
            <FormControl.Static>{RawMatAnnexePart.Value}</FormControl.Static>
          </Col>
          <Col sm={4}>
            <Row>
              <Col sm={12}>
                <div className={styles.rmContainer}>
                  <div className={styles.select}>
                    <Select
                      options={rawMatList}
                      labelKey="Name"
                      closeOnSelect={false}
                      valueKey="Id"
                      simpleValue
                      value={MissingDocumentsMandatoryIds}
                      multi
                      onChange={rawMats => {
                        const valuesId = rawMats.split(',').map(e => Number.parseInt(e));
                        updateRawMatDataReview(RawMatDataReviewId, { MissingDocumentsMandatoryIds: valuesId });
                      }}
                    />
                  </div>
                  {!this.state.showMandatoryRawMatsFreeText ? (
                    <div {...css({ alignSelf: 'center' })}>
                      <Button bsSize="xsmall" onClick={() => this.setState({ showMandatoryRawMatsFreeText: true })}>
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </Button>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            {this.state.showMandatoryRawMatsFreeText ? (
              <Row>
                <Col sm={12}>
                  <div className={styles.freeText}>
                    <FormGroup>
                      <FormControl
                        id={`MandatoryRawMatsFreeText${RawMatDataReviewId}`}
                        bsSize="small"
                        value={MandatoryRawMatsFreeText || ''}
                        onChange={e =>
                          updateRawMatDataReview(RawMatDataReviewId, { MandatoryRawMatsFreeText: e.target.value })
                        }
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm={4}>
            <Row>
              <Col sm={12}>
                <div className={styles.rmContainer}>
                  <div className={styles.select}>
                    <Select
                      options={rawMatList}
                      labelKey="Name"
                      valueKey="Id"
                      closeOnSelect={false}
                      value={MissingDocumentsNotMandatoryIds}
                      multi
                      simpleValue
                      onChange={rawMats => {
                        const valuesId = rawMats.split(',').map(e => Number.parseInt(e));
                        updateRawMatDataReview(RawMatDataReviewId, { MissingDocumentsNotMandatoryIds: valuesId });
                      }}
                    />
                  </div>
                  {!this.state.showNotMandatoryRawMatsFreeText ? (
                    <div {...css({ alignSelf: 'center' })}>
                      <Button bsSize="xsmall" onClick={() => this.setState({ showNotMandatoryRawMatsFreeText: true })}>
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </Button>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            {this.state.showNotMandatoryRawMatsFreeText ? (
              <Row>
                <Col sm={12}>
                  <div className={styles.freeText}>
                    <FormGroup>
                      <FormControl
                        id={`NotMandatoryRawMatsFreeText${RawMatDataReviewId}`}
                        bsSize="small"
                        value={NotMandatoryRawMatsFreeText || ''}
                        onChange={e =>
                          updateRawMatDataReview(RawMatDataReviewId, { NotMandatoryRawMatsFreeText: e.target.value })
                        }
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm={3}>
            <Col sm={1}>
              <FormGroup>
                <Checkbox
                  inline
                  id={`IsOK_${RawMatDataReviewId}`}
                  checked={IsOk === true}
                  onChange={({ target }) => updateRawMatDataReview(RawMatDataReviewId, { IsOk: target.checked })}
                />
              </FormGroup>
            </Col>
            <Col sm={11}>
              <FormGroup>
                <FormControl
                  id={`Comment_${RawMatDataReviewId}`}
                  bsSize="small"
                  componentClass="textarea"
                  value={Comment || ''}
                  onChange={e => updateRawMatDataReview(RawMatDataReviewId, { Comment: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

RawMatInformation.defaultProps = {
  rawMatDataReview: {
    MandatoryRawMatsFreeText: '',
    NotMandatoryRawMatsFreeText: '',
  },
};

RawMatInformation.propTypes = {
  rawMatList: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rawMatDataReview: PropTypes.shape({
    RawMatDataReviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    RawMatAnnexePartCode: PropTypes.string,
    MandatoryRawMats: PropTypes.string,
    NotMandatoryRawMats: PropTypes.string,
    MandatoryRawMatsFreeText: PropTypes.string,
    NotMandatoryRawMatsFreeText: PropTypes.string,
    IsOk: PropTypes.bool,
    Comment: PropTypes.string,
  }).isRequired,
  updateRawMatDataReview: PropTypes.func.isRequired,
};

const styles = {
  title: css({
    textAlign: 'center',
    // fontWeight: 'bold',
  }),
};

const RawMatInformationList = props => {
  const { rawMatDataReviewList, ...otherProps } = props;
  return (
    <div>
      <Grid fluid>
        <Col sm={12}>
          <Row>
            <Col smOffset={1} sm={4}>
              <h4 className={styles.title}>Mandatory</h4>
            </Col>
            <Col sm={4}>
              <h4 className={styles.title}>Not Mandatory</h4>
            </Col>
            <Col sm={3}>
              <Col sm={1}>
                <h4 className={styles.title}>Ok</h4>
              </Col>
              <Col sm={11}>
                <h4 className={styles.title}>Comment</h4>
              </Col>
            </Col>
          </Row>
          {rawMatDataReviewList.map(rdr => (
            <Row key={rdr.RawMatDataReviewId}>
              <RawMatInformation rawMatDataReview={rdr} {...otherProps} />
            </Row>
          ))}
        </Col>
      </Grid>
    </div>
  );
};

RawMatInformationList.propTypes = {
  rawMatDataReviewList: PropTypes.array.isRequired, //eslint-disable-line
};

export default RawMatInformationList;
