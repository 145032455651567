import queryString from 'query-string';
import { post, get, del } from './apiUtils';

const defaultUrl = '/WorkItems';

export default {
  getWorkItems: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  saveWorkItem: data => post(defaultUrl, data),
  deleteWorkItem: id => del(`${defaultUrl}/${id}`),
};
