import { createSelector } from 'reselect';
import * as labelReviewSelectors from '../labelReview/labelReviewSelectors';

export const getById = ({ labelReviewSymbol }) => labelReviewSymbol.byId;
export const getIds = ({ labelReviewSymbol }) => labelReviewSymbol.list.allIds;
export const getLabelReviewSymbol = (state, id) => getById(state)[id];
export const getLabelReviewSymbolList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getLabelReviewSymbolListForLabelReview = createSelector(
  [labelReviewSelectors.getLabelReview, getById],
  (labelReview, labelReviewSymbolById) => {
    if (!labelReview) return [];
    return labelReview.LabelReviewSymbols.map(labelReviewSymbolId => labelReviewSymbolById[labelReviewSymbolId]);
  },
);

export const getLabelReviewSymbolListFromLatestLabelReview = (state, productId) => {
  const latestLabelReview = labelReviewSelectors.getLatestLabelReviewForProduct(state, productId);
  if (!latestLabelReview) return [];
  return getLabelReviewSymbolListForLabelReview(state, latestLabelReview.LabelReviewId);
};
