import { schema } from 'normalizr';
import * as productSchemas from '../product/productSchemas';

export const mandateProductSchema = new schema.Entity(
  'mandateProducts',
  {
    Product: productSchemas.productSchema,
  },
  { idAttribute: 'MandateProductId' },
);

export const mandateProductListSchema = [mandateProductSchema];
