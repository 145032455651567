import queryString from 'query-string';
import { uploadFiles, get, del } from './apiUtils';

const defaultUrl = '/SubstancesDocuments/';
const upload = '/File/';
const structure = '/Structure/';

export default {
  uploadSubstanceDocument: ({ SubstanceId, data }) => uploadFiles(`${defaultUrl}${SubstanceId}${upload}`, data),
  uploadSubstanceStructure: ({ SubstanceId, data }) => uploadFiles(`${defaultUrl}${SubstanceId}${structure}`, [data]),
  getSubstanceDocumentList: query => (query ? get(`${defaultUrl}?${queryString.stringify(query)}`) : get(defaultUrl)),
  getSubstanceStructure: SubstanceId => get(`${defaultUrl}${SubstanceId}${structure}`),
  deleteSubstanceDocument: id => del(`${defaultUrl}${id}`),
};
