import * as toxProfileItemFormTypes from './toxProfileItemFormTypes';
import formNames from '../../../constants/formNames';
import { toxProfileItemTypes, toxProfileItemActions } from '../../toxProfileItem';
import * as toxProfileItemFormSelectors from './toxProfileItemFormSelectors';
import { takeEvery, all, put, select, take } from 'redux-saga/effects';
import { differenceWith, isEqual, without, isFinite, filter, difference } from 'lodash/fp';
import { initialize, getFormInitialValues, change, arrayInsert } from 'redux-form';
import uuidV1 from 'uuid/v1';

const formName = formNames.toxProfileItemForm;

export default function* root() {
  yield all([
    takeEvery(toxProfileItemFormTypes.LOAD_FORM, loadFormSaga),
    takeEvery(toxProfileItemFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClick),
    takeEvery(toxProfileItemFormTypes.ADD_TOXPROFILEITEM, addToxProfileItemSaga),
    takeEvery(toxProfileItemFormTypes.SELECT_TOXPROFILEITEM, onSelectToxProfileItemSaga),
    takeEvery(toxProfileItemFormTypes.DELETE_BUTTON_CLICK, onDeleteButtonClick),
  ]);
}

const removeTemporaryIds = filter(isFinite);

function* loadFormSaga({ payload }) {
  const { substanceId } = payload;
  yield take(toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS);

  const initialFormValues = yield select(state => toxProfileItemFormSelectors.getInitialFormValues(state, substanceId));
  yield put(initialize(formName, initialFormValues));
}

function* onSaveButtonClick({ payload }) {
  const { substanceId, formData } = payload;

  const initialFormValues = yield select(state => getFormInitialValues(formNames.toxProfileItemForm)(state));
  const formatedInitialValue = initialFormValues.allIds.map(id => ({
    ...initialFormValues[id],
  }));
  const items = formData.allIds.map(id => ({
    ...formData[id],
  }));

  const editedToxProfileItems = differenceWith(isEqual, items, formatedInitialValue);
  if (editedToxProfileItems.length > 0) {
    editedToxProfileItems.map(elem => (elem.ChapterCode = elem.subChapter != null ? elem.subChapter : elem.chapter));
    yield put(toxProfileItemActions.saveToxProfileItem.request(substanceId, editedToxProfileItems));
    yield take(toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS);
    const toxProfileItemList = yield select(state =>
      toxProfileItemFormSelectors.getInitialFormValues(state, substanceId),
    );
    yield put(change(formNames.toxProfileItemForm, 'selectedIds', []));
    yield put(initialize(formNames.toxProfileItemForm, toxProfileItemList));
  }
}

function* addToxProfileItemSaga({ payload }) {
  const { ...data } = payload;
  const initialFormValues = yield select(state => getFormInitialValues(formNames.toxProfileItemForm)(state));
  const ToxProfileId = uuidV1();
  const newToxProfile = {
    ...data,
    ToxProfileId,
  };

  const newFormValues = {
    ...initialFormValues,
    [newToxProfile.ToxProfileId]: {
      ...newToxProfile,
    },
  };
  yield put(initialize(formNames.toxProfileItemForm, newFormValues, { keepDirty: true }));
  yield put(arrayInsert(formNames.toxProfileItemForm, 'allIds', 0, ToxProfileId));
  yield put(arrayInsert(formNames.toxProfileItemForm, 'visibleIds', 0, ToxProfileId));
}

function* onSelectToxProfileItemSaga({ payload }) {
  const { id, selected } = payload;
  const currentSelectdIds = yield select(state =>
    toxProfileItemFormSelectors.detailFormValueSelector(state, 'selectedIds'),
  );

  const newSelectedIds = selected ? [...currentSelectdIds, id] : without([id], currentSelectdIds);
  yield put(change(formNames.toxProfileItemForm, 'selectedIds', newSelectedIds));
}

function* onDeleteButtonClick({ payload }) {
  const { substanceId } = payload;
  const currentSelectdIds = yield select(state =>
    toxProfileItemFormSelectors.detailFormValueSelector(state, 'selectedIds'),
  );
  const allIds = yield select(state => toxProfileItemFormSelectors.detailFormValueSelector(state, 'allIds'));
  const noSelectedIds = difference(allIds, currentSelectdIds);

  const IdsToDelete = removeTemporaryIds(currentSelectdIds);

  if (IdsToDelete.length > 0) {
    yield put(toxProfileItemActions.deleteToxProfileItem.request(substanceId, currentSelectdIds));
    yield take(toxProfileItemTypes.TOXPROFILEITEM_LIST.SUCCESS);
  }

  const initialFormValues = yield select(state => getFormInitialValues(formNames.toxProfileItemForm)(state));

  const finalValues = {
    ...initialFormValues,
    selectedIds: [],
    allIds: noSelectedIds,
    visibleIds: noSelectedIds,
  };

  yield put(change(formNames.toxProfileItemForm, 'selectedIds', []));
  yield put(change(formNames.toxProfileItemForm, 'allIds', noSelectedIds));
  yield put(change(formNames.toxProfileItemForm, 'visibleIds', noSelectedIds));
  yield put(initialize(formNames.toxProfileItemForm, finalValues, { keepDirty: true }));
}
