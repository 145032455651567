import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import substanceTRApi from '../../api/substanceTRApi';
import * as substanceTRTypes from './substanceTRTypes';
import * as substanceTRActions from './substanceTRActions';
import * as substanceTRSchemas from './substanceTRSchemas';

export default function* root() {
  yield all([
    takeLatest(substanceTRTypes.SUBSTANCE_TR_LIST.REQUEST, getSubstanceListSaga),
    takeLatest(substanceTRTypes.SAVE_SUBSTANCE_TRs.REQUEST, saveSubstanceTRsSaga),
  ]);
}

export function* getSubstanceListSaga({ payload, type }) {
  yield put(applicationActions.setLoading(substanceTRTypes.SUBSTANCE_TR_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    substanceTRActions.listSubstanceTR,
    substanceTRApi.getSubstanceTRList,
    substanceTRSchemas.substanceTRListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(substanceTRTypes.SUBSTANCE_TR_LIST.REQUEST));
}

export function* saveSubstanceTRsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const savedCategory = yield callApiSagaAndNormalize(
    substanceTRActions.saveSubstanceTRs,
    substanceTRApi.saveSubstanceTR,
    substanceTRSchemas.substanceTRListSchema,
    payload,
  );
  yield put(substanceTRActions.listSubstanceTR.request());
  yield put(applicationActions.unsetLoading(type));
  return savedCategory;
}
