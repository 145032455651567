import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { submit, isInvalid, isPristine } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
import { substanceInformationTypes } from '../../../../redux/substanceInformation';
import { substanceSelectors } from '../../../../redux/substance';
import SubstanceInformationFormModal from './SubstanceInformationFormModal';

const isGreaterThan0 = R.allPass([R.is(Number), n => n > 0]);

const SubstanceInformationFormModalContainer = props => <SubstanceInformationFormModal {...props} />;

const mapStateToProps = (state, { substanceId, substanceInformationId }) => {
  const substance = substanceSelectors.getById(state)[substanceId];
  return {
    isLoading: applicationSelectors.isLoading(state, substanceInformationTypes.SAVE_SUBSTANCE_INFORMATION.REQUEST),
    isFormInValid: isInvalid(formNames.substanceInformation)(state),
    isFormPristine: isPristine(formNames.substanceInformation)(state),
    substanceName: R.propOr('', 'InciName', substance),
    editMode: isGreaterThan0(substanceInformationId),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.substanceInformation)),
});

SubstanceInformationFormModalContainer.defaultProps = {
  substanceInformationId: undefined,
};

SubstanceInformationFormModalContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
  substanceInformationId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceInformationFormModalContainer);
