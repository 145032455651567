import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { orderActions, orderTypes, orderSelectors } from '../../../redux/order';
import { companySelectors } from '../../../redux/company';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { categoryActions } from '../../../redux/category';
import { orderFormSelectors } from '../../../redux/form/orderForm';
import {productActions, productTypes, productSelectors} from '../../../redux/product';
import OrderProductsTab from './OrderProductsTab';

class OrderProductsContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.orderId !== this.props.orderId && this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <OrderProductsTab {...this.props} />;
  }
}
const mapStateToProps = (state, { orderId }) => {
  const isLoadingCheckListTab = applicationSelectors.isLoading(
    state,
    productTypes.FETCH_LAST_FR_CHECK_LIST.REQUEST,
    productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST,
  );
  const isLoadingMissingDocument = applicationSelectors.isLoading(
    state,
    productTypes.FETCH_LAST_FR_MISSING_DOCUMENTS.REQUEST,
  );

  return {
    isLoadingCheckListTab,
    isLoadingMissingDocument,
    currentOrderProducts: orderSelectors.getCurrentOrderProducts(state),
    companyList: companySelectors.getCompanyList(state),
    workflowStatusNameById: workflowStatusSelectors.getById(state),
    isOrderEditable: orderSelectors.makeIsOrderEditable(orderId)(state),
    availableProductsForCurrentOrder: orderSelectors.getProductsOwnedByCurrentOrderCompany(state),
    isLoading: applicationSelectors.isLoading(
      state,
      orderTypes.ORDER_PRODUCT_LIST.REQUEST,
      orderTypes.PRODUCTS_OWNED_BY_ORDER_COMPANY.REQUEST,
    ),
    selectedProduct: productSelectors.getCurrentProduct(state),
    showAddButton: !orderFormSelectors.isNew(state),
  }
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onLoad: () => {
    dispatch(orderActions.listOrderProducts.request(orderId));
    dispatch(orderActions.listProductsOwnedByOrderCompany.request(orderId));
    dispatch(categoryActions.listCategories.request());
  },
  getOrderProducts: () => dispatch(orderActions.listOrderProducts.request(orderId)),
  onDeleteClick: productId => dispatch(orderActions.removeProductFromOrder(orderId, productId)),
  onCopyListClick: async (productList, productId, name, code, selectedProduct, itemList) => {
    dispatch(productActions.generateMissingDocumentText.request([productId]));
  },
  onCopySelectedListButtonClick: productIds =>
  {
    dispatch(productActions.generateMissingDocumentText.request(productIds));
  },
  onShowMissingDocumentListButtonClick: (orderId) =>
  {
    dispatch(modalActions.showOrderMissingDocumentListModal({ orderId }))
  },
  addProductToCurrentOrder: data => dispatch(orderActions.addProductToCurrentOrderAction(data)),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showAddProductToOrderFormModal({
        OrderId: orderId,
      }),
    ),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderProductsContainer);
