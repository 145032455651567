import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { rawMatSelectors } from '../../../redux/rawMat';
import { SelectField } from '../Forms';

const RawMatListDropdown = props => {
  const { rawMatList, rawMatListFilter, sortByName, children } = props;

  const filterOptions = (...args) => sortByName(rawMatListFilter(...args));

  return children ? (
    children({ rawMatList, filterOptions })
  ) : (
    <SelectField
      options={rawMatList}
      labelField="FullName"
      valueField="RawMatId"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  let rawMatList = initialOptions || rawMatSelectors.getRawMatList(state);
  rawMatList = getRawMatList(state)(rawMatList);
  return {
    rawMatList,
    sortByName: rawMatSelectors.sortByName,
    rawMatListFilter: rawMatSelectors.makeGetRawMatListFilter(rawMatList),
  };
};

RawMatListDropdown.defaultProps = {
  children: null,
};

RawMatListDropdown.propTypes = {
  rawMatList: PropTypes.arrayOf(
    PropTypes.shape({
      RawMatId: PropTypes.number.isRequired,
      FullName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rawMatListFilter: PropTypes.func.isRequired,
  sortByName: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const getRawMatList = createSelector(workflowStatusSelectors.getRawMatStatusList, rawMatStatusList =>
  createSelector(R.identity, rawMatList => {
    const getStatusByCode = code => R.find(R.propEq('Code', code))(rawMatStatusList);
    const setRawMatFullName = rawMat =>
      R.assoc(
        'FullName',
        // `${R.prop('Name', rawMat)} - ${R.prop('SupplierName', rawMat)} - ${R.prop('Code', rawMat)} - ${R.prop('Name')(
        //   getStatusByCode(R.prop('Status', rawMat)),
        // )}`,
        `${R.prop('Name', rawMat)} - ${R.prop('SupplierName', rawMat)} - ${R.prop('Code', rawMat)} - ${R.prop('StatusName', rawMat)}`,
        rawMat,
      );
    return rawMatList.map(setRawMatFullName);
  }),
);

export default connect(mapStateToProps, null)(RawMatListDropdown);
