import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as mandateTypes from './mandateTypes';

const byId = handleActions(
  {
    [mandateTypes.FETCH_MANDATES.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.entities.mandates;
      return { ...state, ...payload.entities.mandates };
    },
  },
  {},
);

const allIds = handleActions(
  {
    [mandateTypes.FETCH_MANDATES.SUCCESS]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
  },
  [],
);

const byDistributorIds = handleActions(
  {
    [mandateTypes.FETCH_MANDATES.SUCCESS]: (state, { payload, meta = {} }) => {
      if (meta.query && meta.query.distributorId) return { ...state, [meta.query.distributorId]: payload.result };
      return state;
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byDistributorIds,
  }),
});
