import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as toxProfileChapterTypes from './toxProfileChapterTypes';

const initialState = {
  byId: {},
  list: {
    allIds: [],
  },
};

export default handleActions(
  {
    [toxProfileChapterTypes.TOX_PROFILE_CHAPTER_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: merge({}, state.byId, payload.entities.toxProfileChapters),
      list: {
        ...state.list,
        allIds: payload.result,
      },
    }),
  },
  initialState,
);
