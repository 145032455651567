import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { GeneralGrid, TooltipRenderer } from '../../../_Common/Grids';

const PhraseList = props => {
  const { finalPhraseList, getPhraseList, isLoading, onSelectPhrase, onRowDoucleClick } = props;

  const columns = [
    {
      headerName: I18n.t('phrase.phraseCode'),
      field: 'PhraseCode',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('phrase.text'),
      field: 'Text',
      cellRendererFramework: TooltipRenderer,
    },
  ];

  return (
    <GeneralGrid
      data={finalPhraseList}
      columns={columns}
      onRowDoubleClicked={onRowDoucleClick}
      keyName="PhraseId"
      onRefresh={getPhraseList}
      isLoading={isLoading}
      showAddButton={false}
      height="70vh"
      selectionChanged={onSelectPhrase}
    />
  );
};

PhraseList.propTypes = {
  phraseList: PropTypes.array.isRequired, //eslint-disable-line
  getPhraseList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelectPhrase: PropTypes.func.isRequired,
  onRowDoucleClick: PropTypes.func.isRequired,
};

export default PhraseList;
