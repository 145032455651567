import { combineReducers } from 'redux';
import { merge } from 'lodash';
import { handleActions } from 'redux-actions';
import * as orderProductTypes from './orderProductTypes';

const byId = handleActions(
  {
    [orderProductTypes.ORDER_PRODUCT_LIST.SUCCESS]: (state, { payload }) =>
      merge({}, state, payload.entities.ordersProducts),
  },
  {},
);

const allIds = handleActions(
  {
    [orderProductTypes.ORDER_PRODUCT_LIST.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
