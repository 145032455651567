import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { validate } from 'spected';
import * as labelReviewClaimWizardTypes from './labelReviewClaimWizardTypes';

const labelReviewClaimsById = handleActions(
  {
    [labelReviewClaimWizardTypes.LOAD_LABEL_REVIEW_CLAIM_DATA]: (state, { payload }) => ({
      ...payload.entities.labelReviewClaims,
    }),
    [labelReviewClaimWizardTypes.ADD_NEW_LABEL_REVIEW_CLAIM]: (state, { payload }) => {
      const { LabelReviewClaimId } = payload;
      return {
        ...state,
        [LabelReviewClaimId]: { ...payload },
      };
    },
    [labelReviewClaimWizardTypes.REMOVE_LABEL_REVIEW_CLAIM]: (state, { payload }) => R.dissoc(payload, state),
    [labelReviewClaimWizardTypes.UPDATE_LABEL_REVIEW_CLAIM]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...rest,
        },
      };
    },
  },
  {},
);

const verify = validate(
  () => ({
    isError: false,
    description: undefined,
  }),
  errors => ({
    isError: true,
    description: R.head(errors),
  }),
);

const isNotNullOrEmpty = R.compose(R.not, R.converge(R.or, [R.isNil, R.isEmpty]));

const validationRules = {
  Claim: [[isNotNullOrEmpty, 'form.requiredFieldError']],
};

const errors = handleActions(
  {
    [labelReviewClaimWizardTypes.UPDATE_LABEL_REVIEW_CLAIM]: (state, { payload }) => {
      const { id, ...rest } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...verify(validationRules, { ...rest }),
          },
        },
      };
    },
  },
  { byId: {} },
);

const labelReviewClaimsAllIds = handleActions(
  {
    [labelReviewClaimWizardTypes.LOAD_LABEL_REVIEW_CLAIM_DATA]: (state, { payload }) => payload.result,
    [labelReviewClaimWizardTypes.ADD_NEW_LABEL_REVIEW_CLAIM]: (state, { payload }) => [
      ...state,
      payload.LabelReviewClaimId,
    ],
    [labelReviewClaimWizardTypes.REMOVE_LABEL_REVIEW_CLAIM]: (state, { payload }) => R.without([payload], state),
  },
  [],
);

const deletedIds = handleActions(
  {
    [labelReviewClaimWizardTypes.REMOVE_LABEL_REVIEW_CLAIM]: (state, { payload }) => {
      if (!Number.isInteger(Number(payload))) return state;
      return [...new Set([...state, payload])];
    },
    [labelReviewClaimWizardTypes.LOAD_LABEL_REVIEW_CLAIM_DATA]: () => [],
  },
  [],
);

export default combineReducers({
  byId: labelReviewClaimsById,
  meta: combineReducers({
    errors,
  }),
  list: combineReducers({
    allIds: labelReviewClaimsAllIds,
    deletedIds,
  }),
});
