import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { CheckBoxCellRenderer, LinkCellRenderer } from '../../_Common/Grids';

const CompanyRawMatList = props => {
  const { currentCompanyRawMats, requestingCompanyRawMatList, onRefresh, onExportToExcel, onRowDoubleClicked } = props;
  const styles = {
    main: css({
      height: '100%',
    }),
    gridContainer: css({
      height: '100%',
    }),
  };

  const columns = [
  {
    headerName: I18n.t('rawMat.code'),
    sort: 'desc', 
    field: 'Code',
    cellRendererFramework: LinkCellRenderer,
    cellRendererParams: {
      textKey: 'Code',
      linkKey: 'Url',
      downloadable: false
    },
    suppressSizeToFit: true,
  },
    // {
    //   headerName: I18n.t('rawMat.code'),
    //   field: 'Code',
    // },
    {
      headerName: I18n.t('rawMat.name'),
      field: 'Name',
    },
  ];


  currentCompanyRawMats.forEach(element => {
    element.Url = '#rawmats/' + element.RawMatId;
  });


  return (
    <div className={`${styles.main} ag-blue`}>
      <div className={styles.gridContainer}>
        <SelectionGrid
          rowSelection= 'multiple'
          data={currentCompanyRawMats}
          keyName="RawMatId"
          columns={columns}
          isLoading={requestingCompanyRawMatList}
          onRefresh={onRefresh}
          rowDoubleClicked={onRowDoubleClicked}
          showAddButton={false}
          extraButtonLabel=''
          extraButtonToolTip='rawMat.downloadProducts'
          extraButtonClass='fa fa-download'
          onExtraButtonClick={(rows) => {
            onExportToExcel(rows.map(rm => rm.RawMatId));
          }}
        />
      </div>
    </div>
  );
};

CompanyRawMatList.propTypes = {
  currentCompanyRawMats: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.number,
      productName: PropTypes.string,
    }),
  ).isRequired,
  requestingCompanyRawMatList: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onExportToExcel: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
};

export default CompanyRawMatList;
