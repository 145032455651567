import * as R from 'ramda';
import { createSelector } from 'reselect';
import { userRoleSelectors } from '../userRole';
import { roleSelectors } from '../role';

export function isAuthenticated({ user }) {
  return user.currentUser !== undefined;
}

const getUserSlice = R.prop('user');

export const getCurrentUser = R.compose(R.propOr({}, 'currentUser'), getUserSlice);

export const getCurrentUserActionCodes = R.compose(R.split(','), R.propOr([], 'ActionCodes'), getCurrentUser);

export const getById = ({ user }) => user.byId;
export const getIds = ({ user }) => user.list.allIds;
export const getUser = (state, id) => getById(state)[id];
export const getUserList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export function userToken({ user }) {
  if (user.currentUser) {
    return user.currentUser.access_token;
  }
  return undefined;
}

export const getUsersByRole = (state, roleType) => {
  const roleId = R.propOr(null, 'RoleId', roleSelectors.getRoleByRoleType(state, roleType));
  const userRoles = userRoleSelectors.getUserRoleList(state).filter(userRole => userRole.RoleId === roleId);
  const users = userRoles.map(userRole => getUser(state, userRole.UserId));
  return users.filter(elem => elem !== undefined);
};

export const getError = ({ user }) => user.error;
export const getErrorDesciption = ({ user }) => user.errorDescription;
