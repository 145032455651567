import { createSelector } from 'reselect';
import * as R from 'ramda';
import { formValueSelector, isDirty, getFormValues, getFormInitialValues } from 'redux-form';

import formNames from '../../../constants/formNames';
import { isNullOrUndef } from '../../../utils/utils';
import { referentialSelectors } from '../../referentials';
import * as substanceSelectors from '../../substance/substanceSelectors';

const formName = formNames.substance;

const getDefaultCode = R.compose(R.propOr('', 'Code'), R.head);
const defailsFormValueSelector = formValueSelector(formName);

export const isNew = state => {
  const id = defailsFormValueSelector(state, 'SubstanceId');
  return id <= 0 || isNullOrUndef(id);
};

const applyToForm = fn => fn(formName);

const getInitialValues = substanceId =>
  createSelector(
    [substanceSelectors.getById, getDefaultSubstanceFormValues, referentialSelectors.getCramerClasses],
    (substanceById, defaultSubstanceFormValues, cramerClasses) => {
      const values = substanceById[substanceId] || defaultSubstanceFormValues
      if(values.CramerClasseCode){
        const cramerClasse = cramerClasses.find(elem => elem.Code === values.CramerClasseCode);
        if(cramerClasse){
          values.CramerClasseInfo = `${cramerClasse.Description} ${cramerClasse.Ttc} ${cramerClasse.TtcUom}`;
        }
      }
      return {
        ...values
      };
    },
  );

const cpsrArgumentFields = ['CpsrBTest', 'ImpurityTypeCode', 'ImpurityComment', 'CosNote'];
const mosAndToxFields = [
  'DermalAbsorption',
  'DermalAbsorptionUom',
  'DermalAbsorptionComment',
  'DermalNoael',
  'DermalNoaelComment',
  'IngestionAbsorption',
  'IngestionAbsorptionComment',
  'IngestionNoael',
  'IngestionNoaelComment',
  'InhalationAbsorption',
  'InhalationAbsorptionComment',
  'InhalationNoael',
  'InhalationNoaelComment',
  'Nesil',
  'RiCode',
  'DocReferences',
  'CosAbstract',
  'LogP',
];
const mosAndToxCertFields = [
  'DermalAbsorption',
  'DermalAbsorptionUom',
  'DermalAbsorptionComment',
  'DermalNoael',
  'DermalNoaelComment',
  'IngestionAbsorption',
  'IngestionAbsorptionComment',
  'IngestionNoael',
  'IngestionNoaelComment',
  'InhalationAbsorption',
  'InhalationAbsorptionComment',
  'InhalationNoael',
  'InhalationNoaelComment',
  'Nesil',
  'RiCode',
  'DocReferences',
  'CosAbstract',
  'LogP',
];
const replaceEmptyByNull = R.when(R.isEmpty, R.always(null));

export const makeGetIsSubstanceEditable = createSelector(
  substanceSelectors.makeIsSubstanceEditable,
  getIsSubstanceEditable =>
    createSelector([isNew, getIsSubstanceEditable], (isNewSubstance, isSubstanceEditable) => isSubstanceEditable || isNewSubstance),
);

const isPartOfFormDirty = fields =>
  createSelector([applyToForm(getFormValues), applyToForm(getFormInitialValues)], (formValues, initialFormValues) =>
    R.compose(R.not, R.converge(R.equals, [R.head, R.last]), R.map(R.map(replaceEmptyByNull)), R.map(R.pick(fields)))([
      formValues,
      initialFormValues,
    ]),
  );

export const isFormDirty = isDirty(formName);
export const getDefaultSubstanceFormValues = createSelector(
  [referentialSelectors.getImpurityTypes, referentialSelectors.getDirectiveStatusTypes],
  (impurityTypeList, directiveStatusList) => {
    const ImpurityTypeCode = getDefaultCode(impurityTypeList);
    const DirectiveStatusCode = getDefaultCode(directiveStatusList);
    return {
      ImpurityTypeCode,
      DirectiveStatusCode,
    };
  },
);

export const getSubstanceFormTitle = state => {
  const substanceName = defailsFormValueSelector(state, 'InciName') || '';
  const code = defailsFormValueSelector(state, 'Code') || '';
  return `${code} - ${substanceName}`;
};

export const makeGetInitialValues = createSelector(getInitialValues, initialValues => initialValues);
export const isCpsrArgumentFormDirty = createSelector(isPartOfFormDirty(cpsrArgumentFields), dirty => dirty);
export const isMosAndToxFormDirty = createSelector(isPartOfFormDirty(mosAndToxFields), dirty => dirty);
export const isMosAndToxCertFormDirty = createSelector(isPartOfFormDirty(mosAndToxCertFields), dirty => dirty);
