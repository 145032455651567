import { takeLatest, put, call, all } from 'redux-saga/effects';
import { replace } from 'react-router-redux';
import { camelizeKeys } from 'humps';
import * as userActions from './userActions';
import * as userTypes from './userTypes';
import authenticationApi from '../../api/authenticationApi';
import { applicationActions } from '../application';

export default function* root() {
  yield all([
    takeLatest(userTypes.AUTHENTICATE.REQUEST, authenticateUser),
    takeLatest(userTypes.LOGOUT, logoutProcess),
  ]);
}

function* authenticateUser({ payload: { username, password } }) {
  try {
    yield put(applicationActions.setLoading(userTypes.AUTHENTICATE.REQUEST));
    const userData = yield call(authenticationApi.login, username, password);
    yield put(userActions.authenticate.success(userData));
    yield put(replace('/planner'));
  } catch (error) {
    yield put(userActions.authenticate.failure(camelizeKeys(error)));
  }
  yield put(applicationActions.unsetLoading(userTypes.AUTHENTICATE.REQUEST));
}

function* logoutProcess() {
  yield put(replace('/login'));
}
