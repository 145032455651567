import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { submit, isInvalid, isPristine, isSubmitting } from 'redux-form';
import * as R from 'ramda';
import formNames from '../../../../constants/formNames';
import { applicationSelectors } from '../../../../redux/application';
import { productSelectors } from '../../../../redux/product';
import { shadeTypes } from '../../../../redux/shade';
import ShadeModal from './ShadeModal';

const formName = formNames.shade;
const isAnyTrue = R.any(R.equals(true));

const ShadeModalContainer = props => <ShadeModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName', product);
  const isFormSubmitting = isSubmitting(formName)(state);
  const isFormInValid = isInvalid(formName)(state);
  const isFormPristine = isPristine(formName)(state);
  return {
    isLoading: applicationSelectors.isLoading(state, shadeTypes.SAVE_SHADE.REQUEST),
    isSaveDisabled: isAnyTrue([isFormSubmitting, isFormInValid, isFormPristine]),
    title: I18n.t('shadeModal.title', { productName }),
  };
};

const mapDispatchToProps = dispatch => ({
  onSaveButtonClick: () => dispatch(submit(formNames.shade)),
});

ShadeModalContainer.defaultProps = {
  shadeId: undefined,
};

ShadeModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
  shadeId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShadeModalContainer);
