import PropTypes from 'prop-types';
import { Component } from 'react';

export default class DelayedLoading extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    delay: PropTypes.number,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    delay: 500,
  };

  state = {
    isLoading: false,
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.isLoading) {
      this.setTimer();
    } else {
      this.clearTimer();
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = () => {
    this.clearTimer();
  };

  setTimer = () => {
    if (this.timerHandle) {
      return;
    }

    this.timerHandle = setTimeout(() => {
      this.setState({ isLoading: true });
      this.timerHandle = null;
    }, this.props.delay);
  };

  clearTimer = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = null;
    }
  };

  render() {
    return this.props.children({ isLoading: this.state.isLoading });
  }
}
