import { takeLatest, put, select, all, call } from 'redux-saga/effects';
import { replace, LOCATION_CHANGE } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { createSaveForm } from '../form/utils';
import { applicationActions } from '../application';
import { callApiSaga, callApiSagaAndNormalize, createFetchSaga } from '../sagaUtils';
import impactAssessmentApi from '../../api/impactAssessmentApi';
import * as impactAssessmentTypes from './impactAssessmentTypes';
import * as impactAssessmentActions from './impactAssessmentActions';
import * as impactAssessmentSelectors from './impactAssessmentSelectors';
import { modalSelectors, modalActions, modalTypes } from '../modal';
import { impactAssessmentListSchema, impactAssessmentSchema } from './impactAssessmentSchemas';

export default function* root() {
  yield all([
    takeLatest(LOCATION_CHANGE, onLocationChange),
    takeLatest(impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.REQUEST, getImpactAssessmentListSaga),
    takeLatest(impactAssessmentTypes.IMPACT_ASSESSMENT_LIST_BY_PRODUCT.REQUEST, getImpactAssessmentListByProductSaga),
    takeLatest(impactAssessmentTypes.IMPACT_ASSESSMENT.REQUEST, getImpactAssessmentSaga),
    takeLatest(impactAssessmentTypes.UPDATE_IMPACT_ASSESSMENT.REQUEST, updateImpactAssessmentSaga),
    takeLatest([impactAssessmentTypes.UPDATE_IMPACT_ASSESSMENT_STATUS.REQUEST], updateImpactAssessmentStatusSaga),
    takeLatest(impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST, saveImpactAssessmentFormSaga),
    takeLatest(impactAssessmentActions.submitImpactAssessmentForm.REQUEST, submitImpactAssessmentFormSaga),
  ]);
}

const saveImpactAssessmentFormSaga = createSaveForm('impactAssessmentForm', saveImpactAssessmentSaga);

function* submitImpactAssessmentFormSaga(action) {
  try {
    yield call(saveImpactAssessmentSaga, {
      payload: action.payload,
      type: impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST,
    });
    yield put(impactAssessmentActions.submitImpactAssessmentForm.success());
  } catch (error) {
    if (error.Errors) {
      const formError = new SubmissionError({
        ...error.Errors,
      });
      yield put(impactAssessmentActions.submitImpactAssessmentForm.failure(formError));
    }
    yield put(applicationActions.unsetLoading(impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST));
  }
}

function* onLocationChange(action) {
  const { pathname, action: routeAction } = action.payload;
  if (pathname === '/impactAssessments/-1' && routeAction === 'PUSH') {
    yield put(impactAssessmentActions.newImpactAssessment());
  }
}

function* getImpactAssessmentListSaga() {
  yield put(applicationActions.setLoading(impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.REQUEST));
  yield callApiSagaAndNormalize(
    impactAssessmentActions.listImpactAssessments,
    impactAssessmentApi.getImpactAssessmentList,
    impactAssessmentListSchema,
  );
  yield put(applicationActions.unsetLoading(impactAssessmentTypes.IMPACT_ASSESSMENT_LIST.REQUEST));
}

function* getImpactAssessmentListByProductSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    impactAssessmentActions.listImpactAssessmentsByProduct,
    impactAssessmentApi.getImpactAssessmentListByProduct,
    impactAssessmentListSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* getImpactAssessmentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  yield callApiSagaAndNormalize(
    impactAssessmentActions.impactAssessment,
    impactAssessmentApi.getImpactAssessment,
    impactAssessmentSchema,
    payload,
  );
  yield put(applicationActions.unsetLoading(type));
}

function* saveImpactAssessmentSaga(action) {
  yield put(applicationActions.setLoading(impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST));
  const impactAssessmentId = yield callApiSaga(
    impactAssessmentActions.saveImpactAssessment,
    impactAssessmentApi.saveImpactAssessment,
    action.payload,
  );
  if (impactAssessmentId !== undefined) {
    const previousLocation = yield select(state => state.routing.locationBeforeTransitions);
    yield put(replace({ ...previousLocation, pathname: `/impactAssessments/${impactAssessmentId}` }));
    yield put(impactAssessmentActions.impactAssessment.request(impactAssessmentId));
    yield put(impactAssessmentActions.listImpactAssessments.request());
  }
  yield put(applicationActions.unsetLoading(impactAssessmentTypes.SAVE_IMPACT_ASSESSMENT.REQUEST));
}

function* updateImpactAssessmentSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  const currentImpactAssessment = yield select(state => impactAssessmentSelectors.getCurrentImpactAssessment(state));
  const updatedImpactAssessment = {
    ...currentImpactAssessment,
    ...payload,
  };
  const id = yield callApiSaga(
    impactAssessmentActions.saveImpactAssessment,
    impactAssessmentApi.saveImpactAssessment,
    updatedImpactAssessment,
  );
  if (id !== undefined) {
    yield put(impactAssessmentActions.impactAssessment.request(id));
  }
  yield put(applicationActions.unsetLoading(type));
}

const updateImpactAssessmentStatusSaga = createFetchSaga(
  function* updateImpactAssessmentStatusSaga({ payload, type }) {
    const id = yield callApiSaga(
      impactAssessmentActions.updateImpactAssessment,
      impactAssessmentApi.updateStatus,
      payload,
    );
    return id;
  },
  {
    * onSuccess(id, payload) {
      yield put(impactAssessmentActions.updateImpactAssessment.success(id, payload));
      const isModalShown = yield select(modalSelectors.isModalShown);
      if (isModalShown) {
        yield put(modalActions.hideModal());
      }
      if (id !== undefined) {
        yield put(impactAssessmentActions.impactAssessment.request(id));
      }
    },
    onFailure: (error, payload) => put(impactAssessmentActions.updateImpactAssessment.failure(error, payload)),
  },
);
