import { takeEvery, all, put } from 'redux-saga/effects';
import {errorsFormActions, errorsFormTypes} from "./index";

export default function* root() {
  yield all([takeEvery(errorsFormTypes.HANDLE_FIELD_STATUS_UPDATE, onFieldStatusUpdate)]);
}

function* onFieldStatusUpdate({ payload }) {
  yield put(errorsFormActions.updateFieldStatus(payload));
}
