import { put, all, select, takeLatest, call } from 'redux-saga/effects';
import { createSaveFormWithValidation } from '../utils';
import * as categoryFormActions from './categoryFormActions';
import { categorySagas } from '../../../redux/category';
import { modalActions, modalTypes, modalSelectors } from '../../../redux/modal';

export default function* root() {
  yield all([takeLatest(categoryFormActions.submitCategoryForm.REQUEST, submitCategoryFormSaga)]);
}

const submitCategoryFormSaga = createSaveFormWithValidation(categoryFormActions.submitCategoryForm, saveCategorySaga);

export function* saveCategorySaga(action) {
  yield call(categorySagas.saveCategorySaga, action);
  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.CATEGORY_FORM) {
    yield put(modalActions.hideModal());
  }
}

