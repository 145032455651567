import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenerateAgrementFormModal from './GenerateAgrementFormModal';
import { companySelectors, companyActions } from '../../../../redux/company';
import { isDirty, isInvalid } from 'redux-form';
import formNames from '../../../../constants/formNames';
import { generateAgreementFormActions } from '../../../../redux/form/generateAgreement';
import { companyDistributorAgreementTypes } from '../../../../redux/companyDistributorAgreement';
import { applicationSelectors } from '../../../../redux/application';

const formName = formNames.generateAgreement;

class GenerateAgreementModalContainer extends Component {
  render() {
    return <GenerateAgrementFormModal {...this.props} />;
  }
}

const mapStateToProps = (state, { distributorId }) => ({
  companyList: companySelectors.getCompanyList(state),
  isLoadingContent: applicationSelectors.isLoading(
    state,
    companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_COMPANY.REQUEST,
    companyDistributorAgreementTypes.SAVE_COMPANY_DISTRIBUTOR_AGREEMENT_FOR_DISTRIBUTOR.REQUEST,
  ),
  initialValues: {
    distributorId,
  },
  isDirty: isDirty(formName)(state),
  invalid: isInvalid(formName)(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  onLoad: () => {
    dispatch(companyActions.listCompanies.request());
  },
  onSaveButtonClick: () => dispatch(generateAgreementFormActions.onSaveButtonClick()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenerateAgreementModalContainer);
