import React from 'react';
import { Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

const RangeFormatsTooltip = props => (
  <Tooltip id="tooltip">
    <Translate value="rangeFormatsTooltip.formats" />:
    <ul style={{ paddingLeft: '1.2em', textAlign: 'left' }}>
      <li>15-20</li>
      <li>&lt;20</li>
      <li>&lt;=20</li>
      <li>&gt;20</li>
      <li>&gt;=20</li>
      <li>&#126;20</li>
      <li>+-20</li>
      <li>+/-20</li>
    </ul>
    <Translate value="rangeFormatsTooltip.availableUnitsText" />
  </Tooltip>
);

export default RangeFormatsTooltip;
