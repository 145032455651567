import { schema } from 'normalizr';
import { phraseSchema } from '../phrase/phraseSchemas';

export const productPhraseSchema = new schema.Entity('productPhrases', {}, { idAttribute: 'ProductPhraseId' });
export const productPhraseWithPhraseSchema = new schema.Entity(
  'productPhraseTRs',
  {
    Phrase: phraseSchema,
  },
  { idAttribute: 'ProductPhraseId' },
);

export const productPhraseListSchema = [productPhraseSchema];
export const productPhraseWithPhraseListSchema = [productPhraseWithPhraseSchema];
