import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import ProductDuplicateOrReplaceForm from './ProductDuplicateOrReplaceForm';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  }),
};

const ProductDuplicateOrReplaceFormModal = props => {
  const { productName, productId, onSaveButtonClick, closeModal } = props;
  let mode;
  let orderNr;
  const handleOnSave = () => {
    closeModal();
    onSaveButtonClick(productId, mode, orderNr);
  };

  const onChangeMode = (newValue, oldValue) => {
    mode = newValue;
  };
  const onChangeOrderNr = (newValue, oldValue) => {
    orderNr = newValue;
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate or replace {productName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <BaseLoader isLoading={isSubmitting}> */}
        <ProductDuplicateOrReplaceForm
          {...props}
          mode={mode}
          onChangeMode={onChangeMode}
          onChangeOrderNr={onChangeOrderNr}
        />
        {/* </BaseLoader> */}
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />
              &nbsp;
              <Translate value="general.cancel" />
            </Button>
            <Button
              bsSize="small"
              bsStyle="success"
              type="button"
              onClick={handleOnSave}
              // disabled={!isDirty || isLoadingContent}
            >
              <i className="fa fa-floppy-o" />
              &nbsp;
              <Translate value="general.save" />
            </Button>
          </ButtonToolbar>
        </div>
      </Modal.Body>
    </div>
  );
};

ProductDuplicateOrReplaceFormModal.propTypes = {
  productName: PropTypes.string.isRequired,
  productId: PropTypes.number,
  onSaveButtonClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ProductDuplicateOrReplaceFormModal;
