import { createSelector } from 'reselect';

export const getById = ({ orderAction }) => orderAction.byId;
export const getIds = ({ orderAction }) => orderAction.list.allIds;
export const getOrderActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));
export const getOrderAction = (state, id) => state.orderAction.byId[id];

const getOrderActionsForOrder = orderId =>
  createSelector(getOrderActionList, orderActionList =>
    orderActionList.filter(orderAction => orderAction.OrderId === orderId),
  );

export const makeGetOrderActionsForOrder = createSelector(
  getOrderActionsForOrder,
  orderActionsForSubtance => orderActionsForSubtance,
);
