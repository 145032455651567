import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {Button, ButtonToolbar, Col, Form, Grid, Row} from "react-bootstrap";
import {I18n, Translate} from "react-redux-i18n";
import {InputField} from "../../Forms";
import {css} from "glamor";
import Select from "react-select";

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  titleContainer: css({
    display: 'flex',
    justifyContent: "center",
    marginBottom: '10px',
    '& .Select': { width: '200px' },
  }),
};

class CategoryTRForm extends PureComponent {
  state = {
    selectedLanguage: this.props.languages.length > 0 ? this.props.languages[0].Alpha2 : '',
  };

  componentDidMount() {
    this.props.onLoad();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = option => {
    this.setState({ selectedLanguage: option });
  };

  render(){
    const {onSubmit, handleSubmit, closeModal, isSaveDisabled, languages} = this.props;
    const { selectedLanguage } = this.state;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid fluid>
          <Row>
            <Col sm={6}>
              <div className={styles.titleContainer}>
                <span><b><Translate value="phraseTranslation.english"/></b></span>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.titleContainer}>
                <Select
                  onChange={this.handleChange}
                  value={selectedLanguage}
                  options={languages}
                  labelKey="Value"
                  valueKey="Alpha2"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.Description" label={I18n.t('category.description')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.Description`} label={I18n.t('category.description')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.UseSite" label={I18n.t('category.useSite')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.UseSite`} label={I18n.t('category.useSite')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.NormalUse" label={I18n.t('product.normalUse')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.NormalUse`} label={I18n.t('product.normalUse')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.ReasonableUse" label={I18n.t('product.reasonableUse')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.ReasonableUse`} label={I18n.t('product.reasonableUse')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.Misuse" label={I18n.t('product.misuse')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.Misuse`} label={I18n.t('product.misuse')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.TargetPopulation" label={I18n.t('product.targetPopulation')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.TargetPopulation`} label={I18n.t('product.targetPopulation')} component={InputField}/>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Field name="category.UseDescription" label={I18n.t('product.useDescription')} component={InputField} readOnly/>
            </Col>
            <Col sm={6}>
              {selectedLanguage && selectedLanguage !== "" && (
                <Field name={`${selectedLanguage}.UseDescription`} label={I18n.t('product.useDescription')} component={InputField}/>
              )}
            </Col>
          </Row>
        </Grid>
        <div className={styles.buttonPanel}>
          <ButtonToolbar>
            <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal}>
              <i className="fa fa-times" />&nbsp;
              <Translate value="general.close" />
            </Button>
            <Button bsSize="small" bsStyle="success" type="submit" disabled={isSaveDisabled}>
              <Translate value="general.save" />&nbsp;
              <i className="fa fa-arrow-circle-right" />
            </Button>
          </ButtonToolbar>
        </div>
      </Form>
    );
  }
};

CategoryTRForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CategoryTRForm);

