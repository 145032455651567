import { takeLatest, put, all, take } from 'redux-saga/effects';
import { modalActions } from '../../modal';
import * as labelReviewValidationFormTypes from './labelReviewValidationFormTypes';
import { labelReviewActions, labelReviewTypes } from '../../labelReview';

export default function* root() {
  yield all([takeLatest(labelReviewValidationFormTypes.SAVE_BUTTON_CLICK, onSaveButtonClickSaga)]);
}

// function* showPhraseListModal({ payload }) {
//   yield put(
//     modalActions.showModal({ modalType: modalTypes.PHRASE_LIST, modalProps: { ...payload, modalWidth: '1200px' } }),
//   );
// }

function* onSaveButtonClickSaga({ payload }) {
  yield put(labelReviewActions.updateLabelReview.request(payload));
  yield take(labelReviewTypes.UPDATE_LABEL_REVIEW.SUCCESS);
  yield put(modalActions.hideModal());
}
