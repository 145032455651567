import { takeLatest, put, all, select, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import * as documentTypes from './documentTypes';
import * as documentActions from './documentActions';
import * as documentSelectors from './documentSelectors';
import documentApi from '../../api/documentApi';

export default function* root() {
  yield all([
    takeLatest(documentTypes.SAVE_DOCUMENT.REQUEST, saveDocumentSagas),
    takeLatest(documentTypes.DOWNLOAD_DOCUMENT.REQUEST, downloadDocumentSaga),
  ]);

  function* downloadDocumentSaga({ type, payload }) {
    yield put(applicationActions.setLoading(type));
    const { documentId } = payload;
    try {
      const filename = payload.filename
        ? payload.filename
        : yield select(state => R.propOr('', 'FileName', documentSelectors.getById(state)[documentId]));

      yield call(documentApi.downloadDocument, documentId, filename);
      yield put(documentActions.downloadDocument.success());
    } catch (error) {
      if (error.Message) {
        const message = error.Message;
        yield put(applicationActions.setGeneralError(message));
        yield put(documentActions.downloadDocument.failure(error));
      }
    }
    yield put(applicationActions.unsetLoading(type));
  }

  function* saveDocumentSagas(action) {
    yield put(applicationActions.setLoading(documentTypes.SAVE_DOCUMENT.REQUEST));
    yield callApiSaga(documentActions.saveDocument, documentApi.saveDocument, action.payload);
    yield put(applicationActions.unsetLoading(documentTypes.SAVE_DOCUMENT.REQUEST));
  }
}
