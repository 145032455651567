import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

const GoToRmButton = ({ onClick }) => (
  <Button bsSize="small" onClick={onClick}>
    {I18n.t('rawMat.goToRm')}
  </Button>
);

GoToRmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onClick: () => dispatch(push({ pathname: '/rawMats', query: { productId } })),
});

export default connect(null, mapDispatchToProps)(GoToRmButton);
