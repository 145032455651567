export function isEmpty(o) {
  return o === "";
}

export function isNullOrUndef(o) {
  return isUndefined(o) || isNull(o);
}

export function isString(o) {
  return typeof o === 'string';
}

export function isNumber(o) {
  return typeof o === 'number';
}

export function isNull(o) {
  return o === null;
}

export function isTrue(o: any) {
  return o === true;
}

export function isUndefined(o) {
  return o === void 0; //eslint-disable-line
}
