import { merge } from 'lodash';
import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as rawMatTypes from '../rawMat/rawMatTypes';
import * as substanceTypes from '../substance/substanceTypes';
import * as substanceProportionTypes from './substanceProportionTypes';
import * as productProportionTypes from '../productProportion/productProportionTypes';

const byId = handleActions(
  {
    [substanceProportionTypes.SUBSTANCE_PROPORTION_LIST.SUCCESS]: (state, { payload }) => ({
      ...payload.entities.substanceProportions,
    }),
    [combineActions(rawMatTypes.RAWMAT_PROPORTIONS.SUCCESS, substanceTypes.SUBSTANCE_PROPORTIONS.SUCCESS)]: (
      state,
      { payload },
    ) => ({
      ...state,
      ...payload.entities.substanceProportions,
    }),
    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload.entities.substanceProportions,
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [substanceProportionTypes.SUBSTANCE_PROPORTION_LIST.SUCCESS]: (state, { payload }) => payload.result,
    [substanceTypes.SUBSTANCE_PROPORTIONS.SUCCESS]: (state, { payload }) => [...new Set([...state, ...payload.result])],
    [productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => {
      if (payload.entities.substanceProportions) {
        return [...new Set([...state, ...Object.keys(payload.entities.substanceProportions).map(Number)])];
      }
      return state;
    },
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
