import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Form, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import { compose } from 'recompose';
import formNames from '../../../../constants/formNames';
import { InputField, RadioFields, SelectField, CheckBoxField } from '../../../_Common/Forms';
import { thresholdFormSelectors } from '../../../../redux/form/thresholdForm';
import { impactAssessmentThresholdActions } from '../../../../redux/impactAssessmentThreshold';
import { requiredField, isPositiveOrZeroDecimal } from '../../../../utils/fieldValidation';
import { SubstanceListDropdown } from '../../Dropdowns';
import { substanceActions, substanceSelectors } from '../../../../redux/substance';

const formName = formNames.impactAssessmentThreshold;

class ThresholdForm extends Component {
  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    const {
      onSubmit,
      handleSubmit,
      thresholdType,
      impactAssessmentThresholdTypes,
      impactAssessmentThresholdComparators,
      substanceList,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid fluid>
          <Row>
            <Col sm={12}>
              <Field
                name="SubstanceId"
                label={`${I18n.t('impactAssessmentThreshold.substance')} *`}
                component={SubstanceListDropdown}
                // onFocus={() => LoadableSubstanceBasicFormSection.preload()}
                initialOptions={substanceList}
                disabled={false}
                useVirtualized
                validate={requiredField}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Field
                name="TypeCode"
                component={RadioFields}
                labelField="Value"
                valueField="Key"
                options={impactAssessmentThresholdTypes}
              />
            </Col>
            <Col sm={2}>
              <Field
                name="comparator"
                label={`${I18n.t('impactAssessmentThreshold.comparator')} *`}
                component={SelectField}
                options={impactAssessmentThresholdComparators}
                labelField="Value"
                valueField="Value"
                formAlignment={false}
              />
            </Col>
            {thresholdType === 'QUANTITY' && (
              <Col sm={3}>
                <Field
                  name="QuantityThreshold"
                  label={`${I18n.t('impactAssessmentThreshold.Qty')} *`}
                  component={InputField}
                  validate={isPositiveOrZeroDecimal}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
              </Col>
            )}
            {thresholdType === 'MOS' && (
              <Col sm={4}>
                <Field
                  name="MoSThreshold"
                  label={`${I18n.t('impactAssessmentThreshold.MoS')} *`}
                  component={InputField}
                  validate={isPositiveOrZeroDecimal}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={2}>
              <Field name="IsAllergen" label={I18n.t('rawMatProportion.isAllergen')} component={CheckBoxField} />
            </Col>
            <Col sm={2}>
              <Field name="IsIngredient" label={I18n.t('rawMatProportion.isIngredient')} component={CheckBoxField} />
            </Col>
            <Col sm={2}>
              <Field name="IsImpurity" label={I18n.t('rawMatProportion.isImpurity')} component={CheckBoxField} />
            </Col>
            <Col sm={2}>
              <Field name="IsNano" label={I18n.t('rawMatProportion.isNano')} component={CheckBoxField} />
            </Col>
            <Col sm={2}>
              <Field name="IsNonNanoVerified" label={I18n.t('rawMatProportion.isNonNano')} component={CheckBoxField} />
            </Col>
          </Row>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state, { thresholdId }) => {
  const getFormInitialValues = thresholdFormSelectors.makeGetInitialValues(thresholdId);
  return {
    initialValues: getFormInitialValues(state),
    substanceList: substanceSelectors.getSubstanceList(state),
  };
};

const mapDispatchToProps = (dispatch, { impactAssessmentId }) => ({
  onSubmit: data =>
    dispatch(impactAssessmentThresholdActions.saveImpactAssessmentThreshold.request({ ...data, impactAssessmentId })),
  onLoad: () => dispatch(substanceActions.listSubstances.request()),
});

ThresholdForm.defaultProps = {
  initialValues: {},
};

ThresholdForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  impactAssessmentId: PropTypes.number.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    enableReinitialize: true,
    initialValues: {
      ImpactAssessmentThresholdId: -1,
    },
  }),
)(ThresholdForm);
