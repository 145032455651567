import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import CosmosComposition from './CosmosComposition';

class CosmosCompositionTab extends PureComponent {
  static propTypes = {
    rawMatList: PropTypes.array.isRequired, //eslint-disable-line
    saveProductProportion: PropTypes.func.isRequired,
    deleteProductProportion: PropTypes.func,
    createAndLinkRawMatFromProdigumIngredient: PropTypes.func.isRequired,
    saveAndLinkProductProportion: PropTypes.func.isRequired,
    cosmosCompositionList: PropTypes.array.isRequired, //eslint-disable-line
    isLoadingCosmosCompositionTab: PropTypes.bool,
  };

  // const CosmosCompositionTab = props => {
  //   const {
  //     rawMatList,
  //     productId,
  //     cosmosCompositionList,
  //     saveProductProportion,
  //     linkCosmosIngredientToRawMat,
  //     deleteProductProportion,
  //     isLoadingProductProportion,
  //     createAndLinkRawMatFromProdigumIngredient,
  //   } = props;

  // console.log(JSON.stringify(cosmosCompositionList));

  shouldComponentUpdate(nextProps) {
    return (
      !nextProps.isLoadingCosmosCompositionTab &&
      (this.props.cosmosCompositionList !== nextProps.cosmosCompositionList || this.props.rawMatList,
        nextProps.rawMatList)
    );
  }

  render() {
    const {
      rawMatList,
      cosmosCompositionList,
      // saveProductProportion,
      deleteProductProportion,
      createAndLinkRawMatFromProdigumIngredient,
      saveAndLinkProductProportion,
    } = this.props;

    return (
      <div>
        {cosmosCompositionList.map((item, index) => (
          <CosmosComposition
            key={index}
            cosmosComposition={item}
            rawMatList={rawMatList}
            initialValues={_.pick(item, 'rawMatId', 'percentage')}
            onSave={saveAndLinkProductProportion}
            onDelete={deleteProductProportion}
            onAdd={createAndLinkRawMatFromProdigumIngredient}
          />
        ))}
      </div>
    );
  }
}

// CosmosCompositionTab.propTypes = {
//   rawMatList: PropTypes.array.isRequired, //eslint-disable-line
//   productId: PropTypes.number.isRequired,
//   saveProductProportion: PropTypes.func.isRequired,
//   deleteProductProportion: PropTypes.func,
//   linkCosmosIngredientToRawMat: PropTypes.func,
//   isLoadingProductProportion: PropTypes.bool.isRequired,
//   createAndLinkRawMatFromProdigumIngredient: PropTypes.func.isRequired,
// };

export default CosmosCompositionTab;
