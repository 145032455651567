import {createSelector} from 'reselect';
import {actionSelectors} from '../action';

export const getIds = ({ productAction }) => productAction.list.allIds;
export const getById = ({ productAction }) => productAction.byId;
export const getProductAction = (state, id) => getById(state)[id];
export const getProductActionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getProductActionForProduct = (state, id) => {
  return getProductActionList(state).filter(pa => pa.ProductId === id).reduce(
    (acc, pa) => [
      ...acc,
      {
        ...pa,
        Action: actionSelectors.getAction(state, pa.ActionCode),
      },
    ],
    [],
  );
};
