import * as labelReviewSymbolSelectors from './labelReviewSymbolSelectors';
import * as labelReviewSymbolActions from './labelReviewSymbolActions';
import * as labelReviewSymbolTypes from './labelReviewSymbolTypes';
import * as labelReviewSymbolSchemas from './labelReviewSymbolSchemas';
// import sagas from './labelReviewSymbolSagas';
import reducer from './labelReviewSymbolReducer';

// import sagaManager from '../sagaManager';

// sagaManager.addSagaToRoot(sagas);

export { labelReviewSymbolSelectors, labelReviewSymbolActions, labelReviewSymbolTypes, labelReviewSymbolSchemas };
export default reducer;
