import { createFetchAction, createRequestActions } from '../utils';
import * as cosAnnexeTypes from './cosAnnexeTypes';

export const fetchCosAnnexes = createFetchAction(cosAnnexeTypes.FETCH_COSANNEXES, 'CosAnnexeId');

export const saveCosAnnexe = createRequestActions(cosAnnexeTypes.SAVE_COSANNEXE);
export const deleteCosAnnexe = createRequestActions(cosAnnexeTypes.DELETE_COSANNEXE, (id, { ...query }) => ({
  id,
  query,
}));
