import PropTypes from 'prop-types';
import React from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';

// import other containers

import IngredientDetailsContainer from './_IngredientDetailsContainer';

const styles = {
  detailsContainer: css({
    '& form': {
      height: '100%',
    },
    '& .tab-pane': {
      paddingTop: '15px',
    },
  }),
  buttonPanel: css({
    textAlign: 'center',
  }),
  closeButton: css(
    {
      // marginTop: '25px',
    },
  ),
};

const ProductDetails = props => {
  const ingredientId = Number(props.params.id);

  return (
    <div className={styles.detailsContainer}>
      <IngredientDetailsContainer ingredientId={ingredientId} {...props} />
      <Col sm={12}>
        <Tabs id="IngredientTabs">
          <Tab eventKey={1} title={I18n.t('general.dummyTab')}>
            Dummy Tab
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

ProductDetails.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductDetails;
