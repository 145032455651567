import { schema } from 'normalizr';

export const substanceClpSchema = new schema.Entity(
  'substanceClps',
  {},
  {
    idAttribute: 'SubstanceClpId',
  },
);

export const substanceClpListSchema = [substanceClpSchema];
