import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import * as companyDistributorAgreementTypes from './companyDistributorAgreementTypes';

// const byId = handleActions(
//   {
//     [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS.SUCCESS]: (
//       state,
//       { payload, meta = {} },
//     ) => {
//       if (!meta.query) return payload.entities.companyDistributorAgreements;
//       return { ...state, ...payload.entities.companyDistributorAgreements };
//     },
//   },
//   {},
// );

const byId = handleActions(
  {
    [companyDistributorAgreementTypes.AGREEMENT_LIST_FOR_DISTRIBUTOR.SUCCESS]: (state, { payload }) => (
      {
      ...state,
      ...payload.entities.companyDistributorAgreements,
    }),
    [combineActions(
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.SUCCESS,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS,
    )]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.entities.companyDistributorAgreements;
      return { ...state, ...payload.entities.companyDistributorAgreements };
    },
  },
  {},
);

// const allIds = handleActions(
//   {
//     [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS.SUCCESS]: (
//       state,
//       { payload, meta = {} },
//     ) => {
//       if (!meta.query) return payload.result;
//       return [...new Set([...state, ...payload.result])];
//     },
//   },
//   [],
// );

const allIds = handleActions(
  {
    [companyDistributorAgreementTypes.AGREEMENT_LIST_FOR_DISTRIBUTOR.SUCCESS]: (state, { payload }) => payload.result,
    [combineActions(
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.SUCCESS,
      companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS,
    )]: (state, { payload, meta = {} }) => {
      if (!meta.query) return payload.result;
      return [...new Set([...state, ...payload.result])];
    },
  },
  [],
);

const byDistributorIds = handleActions(
  {
    [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_DISTRIBUTOR.SUCCESS]: (
      state,
      { payload, meta = {} },
    ) => {
      if (meta.query && meta.query.distributorId) return { ...state, [meta.query.distributorId]: payload.result };
      return state;
    },
  },
  {},
);

const byCompanyIds = handleActions(
  {
    [companyDistributorAgreementTypes.FETCH_COMPANY_DISTRIBUTOR_AGREEMENTS_FOR_COMPANY.SUCCESS]: (
      state,
      { payload, meta = {} },
    ) => {
      if (meta.query && meta.query.companyId) {
        const res = { ...state, [meta.query.companyId]: payload.result };
        return res;
      }
      return state;
    },
  },
  {},
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
    byDistributorIds,
    byCompanyIds,
  }),
});
