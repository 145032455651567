import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { CosAnnexeListContainer } from './CosAnnexeList';

const CosAnnexes = props =>
  (<Grid fluid>
    <Row>
      <Col md={12}>
        <CosAnnexeListContainer {...props} />
      </Col>
    </Row>
  </Grid>);

export default CosAnnexes;
