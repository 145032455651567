import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { processTypeSelectors } from '../../../redux/processType';
import { SelectField } from '../Forms';

const ProcessTypeRefListDropdown = props => {
  const { processTypeRefList, processTypeListFilter, sortByValue, children } = props;

  const filterOptions = (...args) => sortByValue(processTypeListFilter(...args));

  return children ? (
    children({ processTypeRefList, filterOptions })
  ) : (
    <SelectField
      options={processTypeRefList}
      labelField="Value"
      valueField="Code"
      filterOptions={filterOptions}
      useVirtualized
      {...props}
    />
  );
};

const mapStateToProps = (state, { initialOptions }) => {
  const processTypeRefList = processTypeSelectors.getProcessTypeRefList(state);
  return {
    processTypeRefList,
    sortByValue: processTypeSelectors.sortByValue,
    processTypeListFilter: processTypeSelectors.makeGetProcessTypeListFilter(processTypeRefList),
  };
};

ProcessTypeRefListDropdown.defaultProps = {
  children: null,
};

ProcessTypeRefListDropdown.propTypes = {
  processTypeRefList: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  processTypeListFilter: PropTypes.func.isRequired,
  sortByValue: PropTypes.func.isRequired,
  children: PropTypes.func,
};

export default connect(
  mapStateToProps,
  null,
)(ProcessTypeRefListDropdown);
