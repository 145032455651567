import { takeLatest, put, all } from 'redux-saga/effects';
import { applicationActions } from '../application';
import { callApiSagaAndNormalize } from '../sagaUtils';
import { functionListSchema } from '../schema';
import functionApi from '../../api/functionApi';
import * as functionTypes from './functionTypes';
import * as functionActions from './functionActions';

export default function* root() {
  yield all([takeLatest(functionTypes.FUNCTION_LIST.REQUEST, getFunctionListSaga)]);
}

function* getFunctionListSaga() {
  yield put(applicationActions.setLoading(functionTypes.FUNCTION_LIST.REQUEST));
  yield callApiSagaAndNormalize(functionActions.listFunctions, functionApi.getFunctionList, functionListSchema);
  yield put(applicationActions.unsetLoading(functionTypes.FUNCTION_LIST.REQUEST));
}
