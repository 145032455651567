import { createSelector } from 'reselect';
import * as R from 'ramda';
import { formValueSelector } from 'redux-form';
import { routingSelectors } from '../../routing';
import * as orderSelectors from '../../order/orderSelectors';
import { isNullOrUndef } from '../../../utils/utils';
import formNames from '../../../constants/formNames';

const defailsFormValueSelector = formValueSelector(formNames.order);

export function orderTitle(state) {
  const code = defailsFormValueSelector(state, 'OrderNr') || '';
  return `${code}`;
}

export const getInDuplicateMode = createSelector(
  routingSelectors.getQuery,
  R.propSatisfies(duplicateFromId => Number.isInteger(Number.parseInt(duplicateFromId, 10)), 'duplicateFrom'),
);

export const isNew = state => {
  const id = defailsFormValueSelector(state, 'OrderId');
  return id <= 0 || isNullOrUndef(id);
};

export const makeGetIsOrderEditable = createSelector(
  orderSelectors.makeIsOrderEditable,
  getIsOrderEditable =>
    createSelector(
      [isNew, getIsOrderEditable],
      (isNewOrder, isOrderEditable) => isOrderEditable || isNewOrder,
    ),
);

export const getDuplicateFromId = createSelector(routingSelectors.getQuery, R.propOr(null, 'duplicateFrom'));
