import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { createForm, renderField } from './utils';

const AcuteRepeatToxicityForm = () => (
  <div>
    <Row>
      <Col sm={6}>{renderField('AcuteOralToxicity')}</Col>
      <Col sm={6}>{renderField('RepeatedOralToxicityDose')}</Col>
    </Row>
    <Row>
      <Col sm={6}>{renderField('AcuteDermalToxicity')}</Col>
      <Col sm={6}>{renderField('RepeatedDermalToxicityDose')}</Col>
    </Row>
    <Row>
      <Col sm={6}>{renderField('AcuteInhalationToxicity')}</Col>
      <Col sm={6}>{renderField('RepeatedInhalationToxicityDose')}</Col>
    </Row>
    <Row>
      <Col sm={6}>{renderField('ReproOralToxicity')}</Col>
      <Col sm={6}>{renderField('OtherToxicityInfo')}</Col>
    </Row>
    <Row>
      <Col sm={6}>{renderField('GeneMutation')}</Col>
      <Col sm={6}>{renderField('Clastogenicity')}</Col>
    </Row>
  </div>
);

AcuteRepeatToxicityForm.propTypes = {};

export default createForm(AcuteRepeatToxicityForm);
