import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'glamor';
import { I18n } from 'react-redux-i18n';
import SelectionGrid from '../../_Common/Grids/SelectionGrid';
import { DeleteButtonRenderer } from '../../_Common/Grids';
import * as R from 'ramda';

const getYN = ({ data, colDef }) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always(''));

const ImpactAssessmentThresholdList = props => {
  const {
    currentImpactAssessmentThresholds,
    requestingImpactAssessmentThresholdList,
    onDeleteImpactAssessmentThreshold,
    onRefresh,
    onAddThreshold,
    onRowDoubleClicked,
  } = props;
  const styles = {
    main: css({
      height: '100%',
    }),
    gridContainer: css({
      height: '100%',
    }),
  };

  const columns = [
    {
      headerName: I18n.t('impactAssessment.substance'),
      field: 'InciName',
      width: 250,
    },
    {
      headerName: I18n.t('impactAssessment.comparator'),
      field: 'Comparator',
      width: 75,
    },
    {
      headerName: I18n.t('impactAssessment.quantityThreshold'),
      width: 85,
      field: 'QuantityThreshold',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('impactAssessment.mosThreshold'),
      width: 85,
      field: 'MoSThreshold',
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('rawMatProportion.isAllergen'),
      field: 'IsAllergen',
      valueGetter: getYN,
      width: 100,
    },
    {
      headerName: I18n.t('rawMatProportion.isNano'),
      field: 'IsNano',
      valueGetter: getYN,
      width: 100,
    },
    {
      headerName: I18n.t('rawMatProportion.isNonNano'),
      field: 'IsNonNanoVerified',
      valueGetter: getYN,
      width: 100,
    },
    {
      headerName: I18n.t('rawMatProportion.isImpurity'),
      field: 'IsImpurity',
      valueGetter: getYN,
      width: 100,
    },
    {
      headerName: I18n.t('rawMatProportion.isIngredient'),
      field: 'IsIngredient',
      valueGetter: getYN,
      width: 100,
    },
    {
      headerName: I18n.t('impactAssessmentThreshold.actions'),
      cellRendererFramework: DeleteButtonRenderer,
      cellRendererParams: {
        onClick: ({ ImpactAssessmentThresholdId }) => onDeleteImpactAssessmentThreshold(ImpactAssessmentThresholdId),
      },
      width: 80,
      minWidth: 80,
      suppressSizeToFit: true,
    },
  ];

  return (
    <div className={`${styles.main} ag-blue`}>
      <div className={styles.gridContainer}>
        <SelectionGrid
          height="180px"
          data={currentImpactAssessmentThresholds}
          keyName="ImpactAssessmentThresholdId"
          columns={columns}
          isLoading={requestingImpactAssessmentThresholdList}
          onRefresh={onRefresh}
          onAdd={onAddThreshold}
          rowDoubleClicked={onRowDoubleClicked}
          showAddButton
        />
      </div>
    </div>
  );
};

ImpactAssessmentThresholdList.propTypes = {
  currentImpactAssessmentThresholds: PropTypes.arrayOf(
    PropTypes.shape({
      impactAssessmentProductId: PropTypes.number,
      status: PropTypes.string,
    }),
  ).isRequired,
  requestingImpactAssessmentThresholdList: PropTypes.bool.isRequired,
  onDeleteImpactAssessmentThreshold: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onAddThreshold: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  workflowStatusNameById: PropTypes.object.isRequired, //eslint-disable-line
};

export default ImpactAssessmentThresholdList;
