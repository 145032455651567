import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Grid, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { draftLabelFormSelectors } from '../../../../redux/form/draftLabelForm';
import { draftLabelActions } from '../../../../redux/draftLabel';
import formNames from '../../../../constants/formNames';

const formName = formNames.draftLabel;

const mapStateToProps = (state, { productId }) => {
  const getInitialValues = draftLabelFormSelectors.makeGetInitialValues(productId);
  return {
    initialValues: getInitialValues(state),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSubmit: data => dispatch(draftLabelActions.saveDraftLabel.request({ ...data, productId })),
});

export const createForm = BaseComponent => {
  const newComponent = props => (
    <Form>
      <Grid fluid>
        <BaseComponent {...props} />
      </Grid>
    </Form>
  );

  newComponent.propTypes = {
    productId: PropTypes.number.isRequired,
  };

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: formName,
      enableReinitialize: false,
      destroyOnUnmount: false,
      // validate,
    }),
  )(newComponent);
};
