import { createSelector } from 'reselect';
import uuidV1 from 'uuid/v1';
import { substanceSelectors } from '../../substance';
import { rawMatProportionSelectors } from '../../rawMatProportion';

export const makeGetRawMatProportionsWithImpuritiesSubstanceForRawMat = createSelector(
  [rawMatProportionSelectors.getRawMatProportionListForRawMat, substanceSelectors.getImpuritySubstances],
  (getRawMatProportionsForRawMat, substanceImpurityList) =>
    createSelector(getRawMatProportionsForRawMat, rawMatProportionForRawMat => {
      const sustanceImpurityIds = substanceImpurityList.map(substance => substance.SubstanceId);
      return rawMatProportionForRawMat.filter(rmp => sustanceImpurityIds.includes(rmp.SubstanceId));
    }),
);

export const makeGetRawMatProportionListForRawMat = createSelector(
  [rawMatProportionSelectors.getRawMatProportionListForRawMat, substanceSelectors.getImpuritySubstances],
  (getRawMatProportionsForRawMat, substanceImpurityList) =>
    createSelector(getRawMatProportionsForRawMat, rawMatProportionForRawMat => {
      return substanceImpurityList.map(({ SubstanceId }) => {
          const correspondingRawMatProportion = rawMatProportionForRawMat.find(rmp => rmp.SubstanceId === SubstanceId);
          return correspondingRawMatProportion
            ? { ...correspondingRawMatProportion }
            : {
              SubstanceId,
              RawMatProportionId: uuidV1(),
            };
        });
    }
    ),
);
