import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { DeleteButtonRenderer, SelectionGrid } from '../../_Common/Grids';
import ButtonDropdown from '../../_Common/ButtonDropDown';

const CompanyDistributionChannelList = props => {
  const {
    currentCompanyDistributionChannels,
    requestingCompanyDistributionChannelList,
    onRefresh,
    addDistributionChannelToCompany,
    distributionChannelsNotLinkedWithCurrentCompany,
    onRemoveBtnClicked,
  } = props;

  const columns = [
    {
      headerName: I18n.t('distributionChannel.name'),
      width: 300,
      field: 'DistributionChannelName',
      editable: true,
      sort:'asc',
    },
    {
      headerName: I18n.t('general.actions'),
      width: 100,
      minWidth:100,
      suppressSizeToFit: true,
      // cellRendererFramework: cellProps => (
      //   <div>
      //     <Label bsStyle="danger" bsSize="small" onClick={() => onRemoveBtnClicked(cellProps.data)}>
      //       <i className="fa fa-trash" />&nbsp;<Translate value="general.remove" />
      //     </Label>
      //   </div>
      // ),
      cellRendererFramework: DeleteButtonRenderer,
      cellRendererParams: {
        onClick: onRemoveBtnClicked,
      },
    },
  ];

  return (
    <div>
      <ButtonDropdown
        options={distributionChannelsNotLinkedWithCurrentCompany}
        labelKey="Value"
        valueKey="Key"
        buttonText="Add"
        onButtonClick={addDistributionChannelToCompany}
      />
      <SelectionGrid
        data={currentCompanyDistributionChannels}
        columns={columns}
        keyName="DistributionChannelId"
        isLoading={requestingCompanyDistributionChannelList}
        onRefresh={onRefresh}
        showAddButton={false}
      />
    </div>
  );
};

CompanyDistributionChannelList.defaultProps = {
  currentCompanyDistributionChannels: [],
};

CompanyDistributionChannelList.propTypes = {
  distributionChannelsNotLinkedWithCurrentCompany: PropTypes.array, //eslint-disable-line
  distributionChannelList: PropTypes.array, //eslint-disable-line
  // linkDistributionChannelToCompany: PropTypes.func.isRequired,
  currentCompanyDistributionChannels: PropTypes.arrayOf(
    PropTypes.shape({
      distributionChannelId: PropTypes.number,
      distributionChannelName: PropTypes.string,
    }),
  ),
  addDistributionChannelToCompany: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onRemoveBtnClicked: PropTypes.func.isRequired,
  requestingCompanyDistributionChannelList: PropTypes.bool.isRequired,
};

export default CompanyDistributionChannelList;
