import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import * as inciListTypes from './inciListTypes';

const byProductId = handleActions(
  {
    [inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload, meta: { ProductId } }) => ({
      ...state,
      [ProductId]: {
        ...payload.entities.inciList[ProductId],
        InciSubstances: {
          ...payload.entities.inciSubstances,
        },
      },
    }),
  },
  {},
);

const allIds = handleActions(
  {
    [inciListTypes.INCI_LIST_FOR_PRODUCT.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byProductId,
  list: combineReducers({
    allIds,
  }),
});
