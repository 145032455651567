import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getById as getSubstanceById } from '../../../substance/substanceSelectors';
import { inciListSelectors } from '../../../inciList';
import { cosAnnexeSelectors } from '../../../cosAnnexe';

export const getComplianceReviews = ({ formulaReviewWizard }) => formulaReviewWizard.complianceReviews;
export const getComplianceReviewsbyId = state => getComplianceReviews(state).byId;
export const getComplianceReviewsIds = state => getComplianceReviews(state).list.allIds;
export const getComplianceReview = (state, id) => getComplianceReviewsbyId(state)[id];

export const getComplianceReviewList = createSelector(
  [getComplianceReviewsIds, getComplianceReviewsbyId],
  (allIds, byId) => allIds.map(id => byId[id]),
);

export const getComplianceReviewWithSubstanceList = createSelector(
  [
    getComplianceReviewList,
    getSubstanceById,
    cosAnnexeSelectors.makeGetCosAnnexeListForSubstance,
    inciListSelectors.getByProductId,
  ],
  (complianceReviewList, substanceById, getCosAnnexeForSubstance, getInciListByProductId) =>
    createSelector(
      productId => getInciListByProductId[productId],
      inciListForProduct => {
        const getSubstanceType = substanceId =>
          R.compose(R.pathOr('', ['InciSubstances', substanceId, 'SubstanceType']))(inciListForProduct);

        const res = complianceReviewList.map(cr => ({
          ...substanceById[cr.SubstanceId],
          SubstanceType: getSubstanceType(cr.SubstanceId),
          ...cr,
          CosAnnexes: getCosAnnexeForSubstance(cr.SubstanceId),
        }));
        console.log('res', res);

        return res;
      },
    ),
);

// export const getComplianceReviewWithSubstanceList = createSelector(
//   [getComplianceReviewList, getSubstanceById, cosAnnexeSelectors.makeGetCosAnnexeListForSubstance],
//   (complianceReviewList, substanceById, getCosAnnexeForSubstance) => {
//     const res = complianceReviewList.map(cr => ({
//       ...substanceById[cr.SubstanceId],
//       ...cr,
//       CosAnnexes: getCosAnnexeForSubstance(cr.SubstanceId),
//     }));

//     return res;
//   },
// );
