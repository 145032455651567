import { createAction } from 'redux-actions';
import DuplicateModes from './DuplicateModes';
import * as productFormTypes from './productFormTypes';

export const duplicateProduct = createAction(productFormTypes.DUPLICATE_PRODUCT, (productId, orderNr) => ({
  productId,
  mode: DuplicateModes.duplicate,
  orderNr: orderNr
}));
export const replaceProduct = createAction(productFormTypes.DUPLICATE_PRODUCT, (productId, orderNr) => ({
  productId,
  mode: DuplicateModes.replace,
  orderNr: orderNr
}));
