import React from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys } from 'recompose';
import { Checkbox } from 'react-bootstrap';

const CheckBox = onlyUpdateForKeys(['checked'])(({ checked, onChange, text, inline, disabled }) => (
  <Checkbox inline={inline} checked={checked} onChange={onChange} disabled={disabled}>
    {text}
  </Checkbox>
));

CheckBox.defaultProps = {
  text: 'Ok',
  inline: true,
  checked: false,
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CheckBox;
