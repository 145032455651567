import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import * as R from 'ramda';
import {SelectionGrid} from '../../_Common/Grids';
import {Button, ButtonToolbar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import workflowStatus from '../../../constants/workflowStatus';
import {css} from "glamor";

const styles = {
  buttonDisable: css({
    '& .btn[disabled]': {
      opacity: 0.35,
    },
  }),
};

const getYN = ({data, colDef}) =>
  R.compose(
    boolToYN,
    R.propOr(false, colDef.field),
  )(data);
const boolToYN = R.ifElse(R.equals(true), R.always('yes'), R.always('no'));

const ActionButtonsRenderer = props => {
  const {
    onMarkAsReceivedClick,
    getIsReceivedButtonDisabled,
    onMarkAsVerifiedClick,
    getIsVerifiedButtonDisabled,
    data,
  } = props;

  const handleButtonClick = fn => () => fn(data);

  const receivedButtonDisabled = getIsReceivedButtonDisabled(data);
  const verifiedButtonDisabled = getIsVerifiedButtonDisabled(data);

  const idReceived = "tooltipReceived" + data.FormulaReviewMissingDocumentId;
  const idVerified = "tooltipVerified" + data.FormulaReviewMissingDocumentId;

  return (
    <div className={styles.buttonDisable}>
      <ButtonToolbar>
        <OverlayTrigger placement="left" overlay={
          <Tooltip id={idReceived}>
            <Translate value="lastFRCheckList.received"/>
          </Tooltip>
        }>
          <Button
            bsStyle="info"
            bsSize="xsmall"
            type="button"
            disabled={receivedButtonDisabled}
            onClick={handleButtonClick(onMarkAsReceivedClick)}
          >
            <i className="fa fa-download" aria-hidden="true"/>
            &nbsp;
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement="right" overlay={
          <Tooltip id={idVerified}>
            <Translate value="lastFRCheckList.verified"/>
          </Tooltip>
        }>
          <Button
            bsSize="xsmall"
            bsStyle="success"
            type="button"
            disabled={verifiedButtonDisabled}
            onClick={handleButtonClick(onMarkAsVerifiedClick)}
          >
            <i className="fa fa-check" aria-hidden="true"/>
            &nbsp;
          </Button>
        </OverlayTrigger>
      </ButtonToolbar>
    </div>
  );
};

const MissingDocuments = props => {
  const {
    missingDocuments,
    onRefreshMissingDocuments,
    isLoadingMissingDocument,
    onMissingReceivedClick,
    onMissingVerifiedClick,
    // ...otherProps
  } = props;

  const getIsReceivedButtonDisableFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_MISSING),
    R.always(false),
    R.always(true),
  );

  const getIsVerifiedButtonDisableFromStatusCode = R.ifElse(
    R.equals(workflowStatus.DOCUMENT_RECEIVED),
    R.always(false),
    R.always(true),
  );

  const columns = [
    {
      headerName: I18n.t('lastFRCheckList.MissingDocuments'),
      field: 'AnnexePartCode',
      width: 90,
    },
    {
      headerName: I18n.t('lastFRCheckList.MissingDocumentRawMat'),
      field: 'RawMatName',
    },
    {
      headerName: I18n.t('lastFRCheckList.MissingDocumentMandatory'),
      field: 'Mandatory',
      valueGetter: getYN,
      width: 50,
    },
    {
      headerName: I18n.t('general.actions'),
      width: 55,
      cellRendererFramework: ActionButtonsRenderer,
      cellRendererParams: {
        onMarkAsReceivedClick: onMissingReceivedClick,
        onMarkAsVerifiedClick: onMissingVerifiedClick,
        getIsReceivedButtonDisabled: R.compose(
          getIsReceivedButtonDisableFromStatusCode,
          R.prop('Status'),
        ),
        getIsVerifiedButtonDisabled: R.compose(
          getIsVerifiedButtonDisableFromStatusCode,
          R.prop('Status'),
        ),
      },
    },
  ];

  return (
    <div>
      <span><b><Translate value="lastFRCheckList.forumlaReviewMissingDocument"/></b></span>
      <SelectionGrid
        data={missingDocuments}
        onRefresh={onRefreshMissingDocuments}
        isLoading={isLoadingMissingDocument}
        columns={columns}
        keyName="FormulaReviewMissingDocumentId"
        showAddButton={false}
      />
    </div>
  );
};

MissingDocuments.propTypes = {
  isLoadingCheckListTab: PropTypes.bool.isRequired,
};

export default MissingDocuments;
