import { createAction } from 'redux-actions';
import * as workItemFormTypes from './workItemFormTypes';

export const onFinishDateChanged = createAction(workItemFormTypes.ON_FINISH_DATE_CHANGED, value => ({
  value,
}));
export const onStartedDateChanged = createAction(workItemFormTypes.ON_STARTED_DATE_CHANGED, value => ({
  value,
}));
export const onDurationChanged = createAction(workItemFormTypes.ON_DURATION_CHANGED, value => ({
  value,
}));
