import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Modal, Button, ButtonToolbar, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { css } from 'glamor';
import MandateProductListContainer from './_MandateProductListContainer';
import AddProductDropdown from './AddProductDropdown';
import { Grid, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import workflowStatus from '../../../../constants/workflowStatus';

const styles = {
  buttonPanel: css({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '17px',
    marginLeft: '17px',
  }),
  datepicker: css({
    display: 'block',
    '& .react-datepicker-popper': { zIndex: 3 },
  }),
};

const emptyList = [];

class MandateProductsModal extends Component {
  state = {
    availableProductToAdd: [],
    validityStartDate: undefined,
    cosmeticBrand: '',
  };

  componentWillReceiveProps(nextProps) {
    if (this.grid) {
      this.refreshAvailableProductToAdd(nextProps.availableProductListForMandate);
    }
    this.setState({
      validityStartDate: nextProps.validityStartDate,
      cosmeticBrand: nextProps.cosmeticBrand ? nextProps.cosmeticBrand : '',
    });
  }

  onAddButtonClick = data => {
    this.grid.api.updateRowData({ add: [data] });
    this.refreshAvailableProductToAdd(this.props.availableProductListForMandate);
  };

  onDeleteButtonClick = data => {
    this.grid.api.updateRowData({ remove: [data] });
    this.refreshAvailableProductToAdd(this.props.availableProductListForMandate);
  };

  setGrid = g => {
    this.grid = g;
  };

  getMandateProductList = () => (this.grid ? this.grid.api.getModel().rowsToDisplay.map(rn => rn.data) : emptyList);

  getProductToAdd = availableProductListForMandate =>
    R.differenceWith(R.eqBy(R.prop('ProductId')), availableProductListForMandate, this.getMandateProductList());

  getSaveValue = () => (this.props.isNewMandate ? 'mandateProductModal.save' : 'mandateProductModal.create');

  handleOnSave = () => {
    const date = this.state.validityStartDate;
    this.props.onSave(this.getMandateProductList(), {
      cosmeticBrand: this.state.cosmeticBrand,
      validityStartDate: date,
    });
  };

  refreshAvailableProductToAdd = availableProductListForMandate => {
    const test = this.getProductToAdd(availableProductListForMandate);
    this.setState(() => ({
      availableProductToAdd: test,
    }));
  };

  handleBrandChange = e => {
    this.setState({
      cosmeticBrand: e.target.value,
    });
  };

  handleValidityChange = e => {
    this.setState({
      validityStartDate: e
        .local()
        .hour(0)
        .format(),
    });
  };

  render() {
    const { closeModal, title, mandateId, status } = this.props;

    const disable = status === workflowStatus.MANDATE_REVOKED || status === workflowStatus.MANDATE_SUPERSEDED;

    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Grid fluid style={{ paddingLeft: '0px' }}>
                <Row>
                  <Col sm={12}>
                    <FormGroup>
                      <ControlLabel>
                        <Translate value="mandates.brand" />
                      </ControlLabel>
                      <FormControl
                        type="text"
                        value={this.state.cosmeticBrand}
                        placeholder="Enter text"
                        onChange={this.handleBrandChange}
                        readOnly={disable}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <ControlLabel>
                        <Translate value="mandates.ValidityStartDate" />
                      </ControlLabel>
                      <div className={styles.datepicker}>
                        <DatePicker
                          selected={this.state.validityStartDate ? moment(this.state.validityStartDate) : moment()}
                          onChange={this.handleValidityChange}
                          dateFormat={'DD/MM/YYYY'}
                          className={'form-control'}
                          readOnly={disable}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Grid>
            </Col>
          </Row>
          <AddProductDropdown
            mandateId={mandateId}
            onAddButtonClick={this.onAddButtonClick}
            productList={this.state.availableProductToAdd}
            readOnly={disable}
          />
          <MandateProductListContainer
            height="40vh"
            mandateId={mandateId}
            whenGridReady={this.setGrid}
            onDeleteButtonClick={this.onDeleteButtonClick}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.buttonPanel}>
            <ButtonToolbar>
              <Button bsSize="small" bsStyle="danger" type="button" onClick={closeModal} disabled={disable}>
                <i className="fa fa-times" />
                &nbsp;
                <Translate value="general.close" />
              </Button>
              <Button bsSize="small" bsStyle="success" type="button" onClick={this.handleOnSave} disabled={disable}>
                <Translate value={this.getSaveValue()} />
                &nbsp;
                <i className="fa fa-arrow-circle-right" />
              </Button>
            </ButtonToolbar>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}

MandateProductsModal.defaultProps = {};

MandateProductsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isNewMandate: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  mandateId: PropTypes.number.isRequired,
  availableProductListForMandate: PropTypes.array.isRequired, //eslint-disable-line
  initialMandateProductList: PropTypes.array.isRequired, //eslint-disable-line
};

export default MandateProductsModal;
