import { normalize } from 'normalizr';
import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { callApiSaga } from '../sagaUtils';
import { applicationActions } from '../application';
import { modalActions, modalTypes, modalSelectors } from '../modal';
import { productActions } from '../product';
import productApi from '../../api/productApi';
import productProportionApi from '../../api/productProportionApi';
import * as productProportionTypes from './productProportionTypes';
import * as productProportionActions from './productProportionActions';
import * as productProportionSchemas from './productProportionSchemas';
import { inciListActions } from '../inciList';
import {productProportionSagas, productProportionSelectors} from "./index";
import {isString} from "../../utils/utils";
import * as R from "ramda";
import {push} from "react-router-redux";

export default function* root() {
  yield all([
    takeLatest(productProportionTypes.FETCH_PRODUCT_PROPORTIONS.REQUEST, getProductPorportionsSaga),
    takeLatest(
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_PRODUCT.REQUEST,
      getProductProportionListForProductSaga,
    ),
    takeLatest(
      productProportionTypes.PRODUCT_PROPORTION_LIST_FOR_RAWMAT.REQUEST,
      getProductProportionListForRawMatSaga,
    ),
    takeLatest(
      [productProportionTypes.ADD_PRODUCT_PROPORTION, productProportionTypes.EDIT_PRODUCT_PROPORTION],
      onAddEditProductProportion,
    ),
    takeLatest(productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST, saveProductProportionsSaga),
    takeLatest(productProportionTypes.REORDER_PRODUCT_PROPORTION, reOrderProductProportionsSaga),
    takeLatest(productProportionTypes.UPDATE_MAX_QTY.REQUEST, updateMaxQtySaga),
    takeLatest(productProportionTypes.SELECT_PRODUCT_PROPORTION_RM_SUBMIT.REQUEST, selectProductProportionRMSubmitSaga),
    takeLatest(productProportionTypes.ADD_RM_TO_PRODUCT_AND_GO_TO_PRODUCT.REQUEST, addRmToProductAndGoToProductSaga),
  ]);
}

// export function* getProductPorportionsSaga(payload) {
//   yield put(applicationActions.setLoading(productProportionTypes.FETCH_PRODUCT_PROPORTIONS.REQUEST));
//   yield put(productProportionActions.fetchProductProportions.request());
//   const result = yield call(productProportionApi.getProductProportions, payload);
//   yield put(applicationActions.unsetLoading(productProportionTypes.FETCH_PRODUCT_PROPORTIONS.REQUEST));
//   return result;
// }
function* getProductPorportionsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const productPorportionList = yield call(productProportionApi.getProductProportions, payload);
    const normalizedData = normalize(productPorportionList, productProportionSchemas.productProportionListSchema);
    yield put(productProportionActions.fetchProductProportions.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productProportionActions.fetchProductProportions.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* updateMaxQtySaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const productPorportionList = yield call(productProportionApi.updateMaxQty, payload);
    const normalizedData = normalize(productPorportionList, productProportionSchemas.productProportionListSchema);
    yield put(productProportionActions.updateMaxQty.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productProportionActions.updateMaxQty.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* onAddEditProductProportion({ payload }) {
  yield put(modalActions.showModal({ modalType: modalTypes.PRODUCT_PROPORTION, modalProps: { ...payload } }));
}

function* getProductProportionListForProductSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { ProductId } = payload;
    const productProportions = yield call(productApi.getFullProductProportions, ProductId);
    const normalizedData = normalize(
      productProportions,
      productProportionSchemas.productProportionWithRawMatListSchema,
    );
    yield put(productProportionActions.listProductProportionsForProduct.success(normalizedData, ProductId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productProportionActions.listProductProportionsForProduct.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}

function* getProductProportionListForRawMatSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { RawMatId } = payload;
    const productProportions = yield call(productProportionApi.getProductProportions, {
      query: { RawMatId, OptIncludeProduct: true },
    });
    const normalizedData = normalize(productProportions, productProportionSchemas.productProportionListSchema);
    yield put(productProportionActions.listProductProportionsForRawMat.success(normalizedData, RawMatId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(productProportionActions.listProductProportionsForRawMat.failure(error));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* saveProductProportionsSaga({ type, payload, meta }) {
  yield put(applicationActions.setLoading(type));
  const { productId } = meta;
  const result = yield callApiSaga(
    productProportionActions.saveProductProportions,
    productProportionApi.saveProductProportion,
    payload,
  );

  yield put(productProportionActions.listProductProportionsForProduct.request(productId));
  yield put(inciListActions.getInciListForProduct.request(productId));
  yield put(productActions.listProductMosCalculationList.request({ productId }));
  yield put(applicationActions.unsetLoading(type));
  return result;
}

export function* selectProductProportionRMSubmitSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { importedProductProportionId, productId, rawMatId } = payload;

  const productProportionList = yield select(state => productProportionSelectors.getproductProportionList(state));
  const Order = R.compose(
    R.add(1),
    R.defaultTo(0),
    Number.parseInt,
    R.apply(Math.max),
    R.pluck('Order'),
    R.filter(R.whereEq({ ProductId: productId })),
  )(productProportionList);

  const productProportionToSave = {
      productId,
      rawMatId,
      Order,
      importedProductProportionId
    };

  const result = yield call(productProportionSagas.saveProductProportionsSaga, {
    type,
    payload: productProportionToSave,
    meta: { productId },
  });

  const modalType = yield select(state => modalSelectors.getModalType(state));
  if (modalType === modalTypes.PRODUCT_PROPORTION_SELECTRM) {
    yield put(modalActions.hideModal());
  }
  return result;
}

export function* addRmToProductAndGoToProductSaga({ type, payload }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { productId, rawMatId, tab, importedProductProportionId } = payload;
    const result = yield call(productProportionSagas.selectProductProportionRMSubmitSaga, {
      type,
      payload: { productId: parseInt(productId), rawMatId, importedProductProportionId: parseInt(importedProductProportionId) },
      meta: { productId: parseInt(productId) },
    });
    console.log('result', result);
    yield put(push({pathname: '/products/'+productId, query: { openTab: tab, ProductProportionId: result.ProductProportionId }}));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
    }
  } finally {
    yield put(applicationActions.unsetLoading(type));
  }
}

export function* reOrderProductProportionsSaga({ type, payload, meta }) {
  yield put(applicationActions.setLoading(productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST));
  const { productId } = meta;
  yield callApiSaga(
    productProportionActions.saveProductProportions,
    productProportionApi.reOrderProductProportions,
    payload,
  );

  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.PRODUCT_PROPORTION_REORDER) {
    yield put(modalActions.hideModal());
  }

  yield put(productProportionActions.listProductProportionsForProduct.request(productId));
  // yield put(shadeActions.fetchShades.request({ productId }));
  yield put(inciListActions.getInciListForProduct.request(productId));
  yield put(productActions.listProductMosCalculationList.request({ productId }));

  yield put(applicationActions.unsetLoading(productProportionTypes.SAVE_PRODUCT_PROPORTIONS.REQUEST));
}
