import { normalize } from 'normalizr';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { applicationActions } from '../application';
import productApi from '../../api/productApi';
import * as inciListTypes from './inciListTypes';
import * as inciListSchemas from './inciListSchemas';
import * as inciListActions from './inciListActions';
import {substanceActions} from "../substance";

export default function* root() {
  yield all([takeLatest(inciListTypes.INCI_LIST_FOR_PRODUCT.REQUEST, getInciListForProductSaga)]);
}

export function* getInciListForProductSaga({ payload, type }) {
  try {
    yield put(applicationActions.setLoading(type));
    const { ProductId } = payload;
    const inciList = yield call(productApi.getProductInciList, ProductId);
    const normalizedData = normalize(inciList, inciListSchemas.inciListSchema);
    const substanceIds = inciList.Substances.map(elem => elem.SubstanceId);
    yield put(substanceActions.checkIfSubstanceloaded.request({ substanceIds}));
    yield put(inciListActions.getInciListForProduct.success(normalizedData, ProductId));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(inciListActions.getInciListForProduct.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
