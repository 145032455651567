import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RawMatPhraseList from './RawMatPhraseList';
import { applicationSelectors } from '../../../redux/application';
import cpsrTypes from '../../../constants/cpsrTypes';
import { rawMatPhraseSelectors, rawMatPhraseActions, rawMatPhraseTypes } from '../../../redux/rawMatPhrase';
import { phraseSelectors, phraseActions } from '../../../redux/phrase';
import { modalActions } from '../../../redux/modal';
import { referentialSelectors } from '../../../redux/referentials';

class RawMatPhraseListContainer extends Component {
  static propTypes = {
    rawMatId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.rawMatId !== this.props.rawMatId) {
      this.props.onLoad();
    }
  }

  render() {
    return <RawMatPhraseList {...this.props} />;
  }
}
const mapStateToProps = (state, { rawMatId }) => {
  const getRawMatPhraseForRawMat = rawMatPhraseSelectors.makeGetRawMatPhrasesForRawMat(rawMatId);
  return {
    rawMatPhraseList: getRawMatPhraseForRawMat(state),
    phraseList: phraseSelectors.getphraseList(state),
    cpsrInfoRmChapterList: referentialSelectors.getCpsrInfoRmChapters(state),
    isLoadingRawMatPhraseList: applicationSelectors.isLoading(
      state,
      rawMatPhraseTypes.FETCH_RAWMAT_PHRASES.REQUEST,
      rawMatPhraseTypes.DELETE_RAWMAT_PHRASE.REQUEST,
    ),
  };
};

const mapDispatchToProps = (dispatch, { rawMatId }) => ({
  onLoad: () => {
    if (rawMatId > 0) {
      dispatch(rawMatPhraseActions.fetchRawMatPhrases.request());
      dispatch(phraseActions.listPhrases.request({ CpsrTypeCode: cpsrTypes.INFORM }));
    }
  },
  fetchRawMatPhraseList: () => dispatch(rawMatPhraseActions.fetchRawMatPhrases.request({ rawMatId })),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showRawMatInformationFormModal({
        rawMatId,
      }),
    ),
  onEditButtonClick: ({ RawMatPhraseId: rawMatPhraseId }) =>
    dispatch(
      modalActions.showRawMatInformationFormModal({
        rawMatId,
        rawMatPhraseId,
      }),
    ),
  onDeleteButtonClick: ({ RawMatPhraseId: rawMatPhraseId }) =>
    dispatch(rawMatPhraseActions.deleteRawMatPhrase.request(rawMatPhraseId, { rawMatId })),
});

RawMatPhraseListContainer.propTypes = {
  rawMatId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RawMatPhraseListContainer);
