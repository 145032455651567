import { takeLatest, put, all, select, take } from 'redux-saga/effects';
import uuidV1 from 'uuid/v1';
import { isNullOrUndef } from '../../../../utils/utils';
import { referentialsTypes } from '../../../referentials';
import { labelReviewWizardTypes, labelReviewWizardSelectors } from '../../labelReviewWizard';
import { labelReviewInciSelectors } from '../../../labelReviewInci';
import { labelReviewInciWizardActions } from './index';
import { labelReviewTypes } from '../../../labelReview';

export default function* root() {
  yield all([
    takeLatest(
      [labelReviewWizardTypes.EDIT_LABEL_REVIEW_WIZARD.SUCCESS, labelReviewWizardTypes.NEW_LABEL_REVIEW_WIZARD.SUCCESS],
      newOrEditLabelReviewSaga,
    ),
  ]);
}

function* newOrEditLabelReviewSaga({ payload }) {
  const { LabelReviewId, ProductId } = payload;
  const editMode = !isNullOrUndef(LabelReviewId);
  const isLoading = yield select(labelReviewWizardSelectors.isLoadingWizard);
  if (isLoading) {
    yield take([referentialsTypes.ALL_REFERENTIALS.SUCCESS, labelReviewTypes.LABEL_REVIEW_FOR_PRODUCT_LIST.SUCCESS]);
  }

  const currentLabelReviewInci = editMode
    ? yield select(state => labelReviewInciSelectors.getlabelReviewInciForLabelReview(state, LabelReviewId))
    : yield select(state => labelReviewInciSelectors.getlabelReviewInciFromLatestLabelReview(state, ProductId));

  let result;
  if (editMode) {
    result = currentLabelReviewInci;
  } else {
    result = {
      ...currentLabelReviewInci,
      LabelReviewInciId: uuidV1(),
      LabelReviewId: null,
    };
  }
  yield put(labelReviewInciWizardActions.loadlabelReviewInciData(result));
}
