import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { modalActions, modalTypes, modalSelectors } from '../modal';
import { createFetchSaga, callApiSaga } from '../sagaUtils';
import shadeProportionApi from '../../api/shadeProportionApi';
import * as shadeProportionActions from './shadeProportionActions';
import * as shadeProportionTypes from './shadeProportionTypes';
import { shadeActions } from '../shade';
import { shadeProportionListSchema, shadeProportionSchema } from './shadeProportionSchemas';

export default function* root() {
  yield all([
    takeLatest(shadeProportionTypes.FETCH_SHADE_PROPORTIONS.REQUEST, fetchShadeProportionsSaga),
    takeLatest(shadeProportionTypes.SAVE_SHADE_PROPORTIONS.REQUEST, saveShadeProportionsSaga),
  ]);
}

export function* saveShadeProportionsSaga({ type, payload }) {
  yield put(applicationActions.setLoading(type));
  const { productId, data } = payload;

  yield callApiSaga(shadeProportionActions.saveShadeProportions, shadeProportionApi.saveShadeProportion, data);
  const modalType = yield select(modalSelectors.getModalType);
  if (modalType === modalTypes.SHADE_COMPO) {
    yield put(modalActions.hideModal());
  }
  yield put(shadeActions.fetchShades.request({ productId, OptIncludeShadeProportions: true }));
  // yield put(productProportionActions.listProductProportionsForProduct.request(productId));
  // yield put(inciListActions.getInciListForProduct.request(productId));
  // yield put(productActions.listProductMosCalculationList.request(productId));
  yield put(applicationActions.unsetLoading(type));
}

export const fetchShadeProportionsSaga = createFetchSaga(
  function* fetchShadeProportionsSaga({ payload, type }) {
    const shadeProportions = yield call(shadeProportionApi.getShadeProportions, payload);
    const schema = Array.isArray(shadeProportions) ? shadeProportionListSchema : shadeProportionSchema;
    const normalizedData = normalize(shadeProportions, schema);
    return normalizedData;
  },
  {
    * onSuccess(data, payload) {
      yield put(shadeProportionActions.fetchShadeProportions.success(data, payload));
    },
    onFailure: (error, payload) => put(shadeProportionActions.fetchShadeProportions.failure(error, payload)),
  },
);

// export function* getShadeProportionsSaga({ payload, type }) {
//   yield put(applicationActions.setLoading(type));
//   try {
//     const shadeProportionList = yield call(shadeProportionApi.getShadeProportions, payload);
//     const normalizedData = normalize(shadeProportionList, shadeProportionListSchema);
//     yield put(shadeProportionActions.fetchShadeProportions.success(normalizedData, payload));
//   } catch (error) {
//     if (error.Message) {
//       const message = error.Message;
//       yield put(applicationActions.setGeneralError(message));
//       yield put(shadeProportionActions.fetchShadeProportions.failure(error));
//     }
//   } finally {
//     yield put(applicationActions.unsetLoading(type));
//   }
// }
