import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { orderSelectors } from '../../../redux/order';
import { taskActions, taskTypes, taskSelectors } from '../../../redux/task';
import { applicationSelectors } from '../../../redux/application';
import { modalActions } from '../../../redux/modal';
import OrderTaskTab from './OrderTaskTab';
import { workflowStatusSelectors } from '../../../redux/workflowStatus';
import { push } from 'react-router-redux';
import * as taskActionActions from '../../../redux/taskAction/taskActionActions';

class OrderProcessContainer extends Component {
  static propTypes = {
    onLoad: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
  };

  componentDidMount() {
    if (this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.orderId !== this.props.orderId && this.props.orderId > 0) {
      this.props.onLoad();
    }
  }

  render() {
    return <OrderTaskTab {...this.props} />;
  }
}

const mapStateToProps = (state, { orderId }) => {
  const getTasks = taskSelectors.makeGetByQuery(state);
  const getWorkflowStatusList = workflowStatusSelectors.getWorkflowStatusListByCode('TASK');
  return {
    currentTasks: getTasks({ orderId, IncludeDetails: true }),
    // workflowStatusNameById: workflowStatusSelectors.getById(state),
    isOrderEditable: orderSelectors.makeIsOrderEditable(orderId)(state),
    isLoading: applicationSelectors.isLoading(state, taskTypes.TASK_LIST.REQUEST),
    workflowStatusList: getWorkflowStatusList(state),
  };
};

const mapDispatchToProps = (dispatch, { orderId }) => ({
  onLoad: () => {
    dispatch(taskActions.listTasks.request({ orderId, IncludeDetails: true }));
  },
  getTasks: () => dispatch(taskActions.listTasks.request({ orderId, IncludeDetails: true })),
  onEditButtonClick: taskId =>
    dispatch(
      modalActions.showTaskFormModal({
        taskId,
        manager: true,
        orderId,
      }),
    ),
  onEditSelectedButtonClick: taskIds =>
    dispatch(
      modalActions.showTaskFormModal({
        taskId: -1,
        manager: true,
        orderId,
        taskIds
      }),
    ),
  onAddButtonClick: () =>
    dispatch(
      modalActions.showTaskFormModal({
        taskId: -1,
        manager: true,
        orderId,
      }),
    ),
  onDeleteButtonClick: (taskId) =>
    dispatch(
      modalActions.showModal({
        modalType: 'CONFIRM',
        modalProps: {
          title: 'Are you sure ?',
          message: 'Are you sure you want to delete this task ?',
          // onConfirm: () => dispatch(companyActions.deleteCompany.request(ownProps.params.id)),
          onConfirm: () => dispatch(taskActions.deleteTask.request({ taskId, orderId })),
        },
      }),
    ),
  onChangeStatus: ({ Code, TaskId }) =>
    dispatch(
      taskActions.updateStatus({ WorkflowStatusCode: Code, TaskId, searchCriteria: { orderId, IncludeDetails: true } }),
    ),
  onRowDoubleClicked: product => dispatch(push(`/products/${product.ProductId}`)),
  showStatusHistory: (TaskId) => dispatch(taskActionActions.showTaskActionsHistory(TaskId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderProcessContainer);
