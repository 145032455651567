import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { substanceDocumentActions } from '../../../redux/substanceDocument';
import DropzoneForm from '../../_Common/Forms/DropzoneForm';

const SubstanceUploadFileContainer = props => <DropzoneForm {...props} />;

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onUploadFile: data => dispatch(substanceDocumentActions.uploadSubstanceDocument.request(substanceId, data)),
});

SubstanceUploadFileContainer.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

SubstanceUploadFileContainer.propTypes = {};

export default connect(null, mapDispatchToProps)(SubstanceUploadFileContainer);
