import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectField } from '../../Forms';
import { referentialSelectors } from '../../../../redux/referentials';

const PaoHourglassTypeSelectField = ({ paoHourglassTypes, ...otherProps }) => (
  <SelectField options={paoHourglassTypes} valueField="Code" {...otherProps} />
);

const mapStateToProps = state => ({
  paoHourglassTypes: referentialSelectors.getPaoHourglassTypes(state),
});

PaoHourglassTypeSelectField.propTypes = {
  paoHourglassTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Code: PropTypes.string.isRequired,
      Value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(PaoHourglassTypeSelectField);
