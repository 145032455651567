import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { merge } from 'lodash';
import * as substanceTRTypes from './substanceTRTypes';

const byId = handleActions(
  {
    [substanceTRTypes.SUBSTANCE_TR_LIST.SUCCESS]: (state, { payload }) =>{
      console.log('payload', payload);
      return merge({}, state, payload.entities.substancesTR);
    },
  },
  {},
);

const allIds = handleActions(
  {
    [substanceTRTypes.SUBSTANCE_TR_LIST.SUCCESS]: (state, { payload }) => payload.result,
  },
  [],
);

export default combineReducers({
  byId,
  list: combineReducers({
    allIds,
  }),
});
