import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { filterByQueryAndMerge, removeById } from '../utils';
import * as substanceToxProfileTypes from './substanceToxProfileTypes';

const byId = handleActions(
  {
    [substanceToxProfileTypes.FETCH_SUBSTANCE_TOX_PROFILES.SUCCESS]: filterByQueryAndMerge('substanceToxProfiles'),
    [substanceToxProfileTypes.DELETE_SUBSTANCE_TOX_PROFILE.SUCCESS]: removeById,
  },
  {},
);

export default combineReducers({
  byId,
});
