import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import OrderProcessList from './OrderProcessList';
import ButtonDropDown from '../../_Common/ButtonDropDown';
import OrderProcessInfoForm from './OrderProcessInfoForm';

const OrderProcessTab = props => {
  const {
    // availableProductsForCurrentOrder,
    addProductToCurrentOrder,
    isOrderEditable,
    currentOrderProcessInfo,
    currentOrder,
    ...otherProps
  } = props;
  return (
    <div>
      <OrderProcessInfoForm initialValues={currentOrderProcessInfo} {...otherProps}  isReadOnly={currentOrder.IsPending}/>
      <OrderProcessList
        isAddDisabled={!isOrderEditable}
        allowDelete={isOrderEditable}
        currentOrderProcessInfo
        isReadOnly={currentOrder.IsPending}
        {...otherProps}
      />
    </div>
  );
};

OrderProcessTab.propTypes = {
  // availableProductsForCurrentOrder: PropTypes.array.isRequired, //eslint-disable-line
  // addProductToCurrentOrder: PropTypes.func.isRequired, //eslint-disable-line
  // isOrderEditable: PropTypes.bool.isRequired,
};

export default OrderProcessTab;
