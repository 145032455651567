import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, ControlLabel } from 'react-bootstrap';

const ButtonField = props => (
  <FormGroup bsSize={props.bsSize}>
    <ControlLabel>&nbsp;</ControlLabel>
    <div>
      <Button type="button" bsSize={props.bsSize} {...props} />
    </div>
  </FormGroup>
);

ButtonField.defaultProps = {
  bsSize: 'small',
};

ButtonField.propTypes = {
  bsSize: PropTypes.string,
};

export default ButtonField;
