import queryString from 'query-string';
import { get, post } from './apiUtils';

const defaultUrl = '/RawMats/';
const cosmosIngredientRawMatMatchesUrl = '/CosmosIngredientRawMatMatches/';
const createAndLinkRawMatFromProdigumIngredientUrl = 'CreateAndLinkRawMatFromProdigumIngredient/';

export default {
  getRawMatList: () => get(defaultUrl),
  getRawMat: rawMatId => get(`${defaultUrl}${rawMatId}`),
  saveRawMat: data => post(`${defaultUrl}`, data),
  getRawMatProportions: id => get(`${defaultUrl}${id}/Proportions/`),
  linkCosmosIngredientRawMats: data => post(`${cosmosIngredientRawMatMatchesUrl}`, data),
  createAndLinkRawMatFromProdigumIngredient: ({ cosmosIngredientId, productId }) =>
    post(
      `${defaultUrl}${createAndLinkRawMatFromProdigumIngredientUrl}?${queryString.stringify({
        prodigumIngredientId: cosmosIngredientId,
        productId,
      })}`,
    ),
  getAllowedNewSubstancesInComposition: id => get(`${defaultUrl}${id}/AllowedNewSubstancesInComposition`),
  saveRawMatProportion: data => post('/RawMatProportions', { data: [data] }),
  updateStatus: data => post(`${defaultUrl}status`, data),
  updateInciQty: rawMatId => get(`/UpdateRawMatInciQty?RawMatId=${rawMatId}`),
};
