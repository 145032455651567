import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Grid, Row, Col, ButtonToolbar, FormGroup, ControlLabel} from 'react-bootstrap';
import { reduxForm, Field, submit, Form } from 'redux-form';
import { compose } from 'recompose';
import formNames from "../../../constants/formNames";
import LoadingButton from "../../_Common/LoadingButton";
import SelectField from "../../_Common/Forms/SelectField";
import {InputField} from "../../_Common/Forms";
import {productActionActions, productActionSelectors} from "../../../redux/productAction";
import {requiredField} from "../../../utils/fieldValidation";
import {css} from "glamor";

const stylesComment = css({
  '& textarea.form-control.input-sm': {
    height: '100px',
  },
});

const ProductActionForm = props => {
  const { onSubmit, handleSubmit, onSaveButtonClick, submitting, pristine, actionList, SelectedProductActionId } = props;

  const actionRenderer = action => {
    return `${action.Code} - ${action.Description}`;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          {!SelectedProductActionId && (
            <Col sm={3}>
              <Field
                name="ActionCode"
                label={"Action"}
                component={SelectField}
                options={actionList}
                labelField="Description"
                valueField="Code"
                matchPos="start"
                optionRenderer={actionRenderer}
                validate={requiredField}
              />
            </Col>
          )}
          <Col sm={8} className={stylesComment.toString()}>
            <Field
              name="Comment"
              label={`Comment`}
              component={InputField}
              componentClass="textarea"
              placeholder="Enter your comment..."
              rows={6}
              bsSize="small"
            />
          </Col>
          <Col sm={1}>
            <FormGroup>
              <ControlLabel>&nbsp;</ControlLabel>
              <ButtonToolbar>
                <LoadingButton
                  onClick={onSaveButtonClick}
                  bsSize="small"
                  isLoading={submitting}
                  disabled={pristine}
                >
                  {SelectedProductActionId ? 'Save' : 'Add'}
                </LoadingButton>
              </ButtonToolbar>
            </FormGroup>
          </Col>
        </Row>
      </Grid>
    </Form>
  );
};

const mapStateToProps = (state, { SelectedProductActionId }) => {
  return ({
    initialValues: productActionSelectors.getById(state)[SelectedProductActionId] || {},
  });
}

const mapDispatchToProps = (dispatch, { ProductId }) => ({
  onSaveButtonClick: () => dispatch(submit(formNames.productAction)),
  onSubmit: data => dispatch(productActionActions.saveProductAction.request(ProductId, data)),
});

ProductActionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNewButtonClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  actionList: PropTypes.array.isRequired,
  ProductId: PropTypes.number.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  reduxForm({
    form: formNames.productAction,
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ProductActionForm);
