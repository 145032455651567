import * as inciSubstanceActions from './inciSubstanceActions';
import * as inciSubstanceTypes from './inciSubstanceTypes';
import sagas, * as inciSubstanceSagas from './inciSubstanceSagas';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export {
  inciSubstanceActions,
  inciSubstanceTypes,
  inciSubstanceSagas,
};
