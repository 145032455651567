import { takeLatest, put, all, call } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { applicationActions } from '../application';
import { callApiSaga } from '../sagaUtils';
import mandateProductApi from '../../api/mandateProductApi';
import * as mandateProductActions from './mandateProductActions';
import * as mandateProductTypes from './mandateProductTypes';
import * as mandateProductSchemas from './mandateProductSchemas';

export default function* root() {
  yield all([
    takeLatest(mandateProductTypes.FETCH_MANDATE_PRODUCTS.REQUEST, getMandateProductsSaga),
    takeLatest(mandateProductTypes.SAVE_MANDATE_PRODUCT.REQUEST, saveMandateProductsSaga),
  ]);
}

export function* saveMandateProductsSaga(action) {
  const { type, payload, meta } = action;
  yield put(applicationActions.setLoading(type));
  yield callApiSaga(mandateProductActions.saveMandateProduct, mandateProductApi.saveMandateProduct, payload);

  yield put(mandateProductActions.fetchMandateProducts.request(meta.query));
  yield put(applicationActions.unsetLoading(type));
}

export function* getMandateProductsSaga({ payload, type }) {
  yield put(applicationActions.setLoading(type));
  try {
    const mandateProductList = yield call(mandateProductApi.getMandateProducts, payload);
    const normalizedData = normalize(mandateProductList, mandateProductSchemas.mandateProductListSchema);
    yield put(mandateProductActions.fetchMandateProducts.success(normalizedData, payload));
  } catch (error) {
    if (error.Message) {
      const message = error.Message;
      yield put(applicationActions.setGeneralError(message));
      yield put(mandateProductActions.fetchMandateProducts.failure(error));
    }
  }
  yield put(applicationActions.unsetLoading(type));
}
