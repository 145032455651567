import PropTypes from 'prop-types';
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { SelectionGrid, DeleteButtonRenderer } from '../../_Common/Grids';
import { Grid, Row, Col, ControlLabel, Button, ButtonToolbar, Checkbox } from 'react-bootstrap';
import { GeneralGrid, DateCellRenderer } from '../../_Common/Grids';
import StatusCellRenderer from '../../Orders/OrderTask/StatusCellRenderer';
import DurationCellRenderer from '../../_Common/Grids/DurationCellRenderer';

const ActionButtonsRenderer = props => {
  const { onEditButtonClick, data, searchCriteria, onStartButtonClick, onStopButtonClick } = props;

  return (
    <div>
      <ButtonToolbar>
        <Button
          bsStyle="info"
          bsSize="xsmall"
          type="button"
          onClick={() =>
            onEditButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.reassign" />
        </Button>
        <Button
          bsStyle="success"
          bsSize="xsmall"
          type="button"
          disabled={data.Open}
          onClick={() =>
            onStartButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.start" />
        </Button>
        <Button
          bsStyle="danger"
          bsSize="xsmall"
          type="button"
          disabled={!data.Open}
          onClick={() =>
            onStopButtonClick({
              taskId: data.TaskId,
              searchCriteria,
            })
          }
        >
          <i className="fa fa-file-text-o" aria-hidden="true" />
          &nbsp;
          <Translate value="toDoList.stop" />
        </Button>
      </ButtonToolbar>
    </div>
  );
};



const ProductTaskList = props => {
  const { currentProductTasks, isLoading, getProductTasks, onRowDoubleClicked, onDeleteClick } = props;
  const {      expertList,
    onEditButtonClick,
    onStartButtonClick,
    onStopButtonClick,
    workflowStatusList,
    onChangeStatus,
    showStatusHistory,
    productId,
    onEditSelectedButtonClick,
  } = props;

  const compareDueDate = (date1Str, date2Str) => {
      if (date1Str === null && date2Str === null) {
        return 0;
      }
      if (date1Str === null) {
        return 1;
      }
      if (date2Str === null) {
        return -1;
      }
      const date1 = Date.parse(date1Str);
      const date2 = Date.parse(date2Str);
      return date2 < date1 ? 1 : -1;
    };

  const getNumberFromStatus = (status) => {
      switch (status) {
        case 'TASK_INPROGRESS':
          return 1;
        case 'TASK_TODO':
          return 2;
        case 'TASK_ONHOLD':
          return 3;
        case 'TASK_CREATED':
          return 4;
        case 'TASK_DONE':
          return 5;
        case 'TASK_CANCELED':
          return 6;
      }
    }

  const compareStatus = (status1, status2) => {
      const valStatus1 = getNumberFromStatus(status1);
      const valStatus2 = getNumberFromStatus(status2);
      // eslint-disable-next-line no-nested-ternary
      return (valStatus1 < valStatus2 ? -1 : valStatus1 > valStatus2 ? 1 : 0);
  };

  const columns = [
    // {
    //   headerName: I18n.t('orderTask.orderNr'),
    //   field: 'OrderNr',
    //   width: 80,
    //   suppressSizeToFit: true,
    // },
    // {
    //   headerName: I18n.t('orderTask.product'),
    //   field: 'ProductCode',
    //   width: 80,
    //   suppressSizeToFit: true,
    //   valueGetter: ({ data }) => (data.ProductCode ? data.ProductCode : I18n.t('orderProcess.unknown')),
    //   cellStyle: { color: 'blue', textDecoration: 'underline dotted' },
    // },
    // {
    //   headerName: I18n.t('orderTask.product'),
    //   field: 'ProductName',
    //   valueGetter: ({ data }) => (data.ProductName ? data.ProductName : I18n.t('orderProcess.unknown')),
    //   sort: 'asc',
    //   sortedAt: 2,
    // },
    {
      headerName: I18n.t('taskType.region'),
      field: 'RegionValue',
      width: 80,
      suppressSizeToFit: true,
    },    {
      headerName: I18n.t('orderProcess.type'),
      field: 'ProcessTypeValue',
      width: 150,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('orderTask.task'),
      field: 'TaskTypeName',
    },
    {
      headerName: I18n.t('orderTask.taskOrderNr'),
      field: 'TaskTypeOrderNr',
      sort: 'asc',
      sortedAt: 4,
      comparator: compareStatus,
      width: 60,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('orderTask.accountMgr'),
      field: 'AccountManager',
      width: 80,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('orderTask.expert'),
      field: 'Expert',
      width: 80,
      suppressSizeToFit: true,
    },
    {
      headerName: I18n.t('orderTask.status'),
      field: 'Status',
      suppressSizeToFit: true,
      width: 180,
      cellRendererFramework: StatusCellRenderer,
      cellRendererParams: {
        options: workflowStatusList,
        onChange: data => {
          onChangeStatus(data);
        },
        onHistoryClick: showStatusHistory,
      },
      sort: 'asc',
      sortedAt: 3,
      comparator: compareStatus,
    },
    {
      headerName: I18n.t('toDoList.expectedDuration'),
      field: 'ExpectedDuration',
      width: 80,
      cellRenderer: DurationCellRenderer,
      suppressFilter: true,
    },
    {
      headerName: I18n.t('toDoList.timeSpent'),
      field: 'DurationDone',
      width: 80,
      cellRenderer: DurationCellRenderer,
    },
    {
      headerName: I18n.t('orderTask.dueDate'),
      field: 'DueDate',
      cellRenderer: DateCellRenderer,
      cellRendererParams: {
        includeHours: false,
      },
      sort: 'asc',
      sortedAt: 0,
      comparator: compareDueDate,
      width: 100,
      suppressSizeToFit: true,
    },    {
      headerName: I18n.t('orderTask.memo'),
      field: 'Description',
    },
    // {
    //   headerName: I18n.t('general.actions'),
    //   cellRendererFramework: ActionButtonsRenderer,
    //   suppressSizeToFit: true,
    //   width: 200,
    //   cellRendererParams: {
    //     onEditButtonClick,
    //     onStartButtonClick,
    //     onStopButtonClick,
    //     searchCriteria: { IncludeDetails: true, Expert: this.state.userToSeek },
    //   },
    // },
  ];

  return (
    <Grid fluid>
    {/* <Row style={{ marginBottom: '5px' }}>
      <Col sm={1}>
        <ControlLabel>
          <Translate value="orderTask.expert" />
        </ControlLabel>
        <Select
          options={expertList}
          labelKey="LoginName"
          valueKey="LoginName"
          value={this.state.userSelected}
          onChange={value => {
            this.setState({
              userSelected: value ? value.LoginName : null,
            });
          }}
          clearable
        />
      </Col>
      <Col sm={2}>
        <div style={{ marginTop: '23px' }}>
          <Checkbox
            onChange={({ target }) => this.setState({ filterStatus: target.checked })}
            checked={this.state.filterStatus}
            bsSize="small">
            Filter on status
          </Checkbox>
        </div>
      </Col>
      <Col sm={1}>
        <Button
          style={{ marginTop: '22px' }}
          bsStyle="success"
          bsSize="small"
          onClick={() => {
            this.setState({ userToSeek: this.state.userSelected });
            getTaskList(this.state.userSelected, this.state.filterStatus);
          }}
        >
          <Translate value="generalDashboard.search" />
          &nbsp;
          <i className="fa fa-search" aria-hidden="true" />
        </Button>
      </Col>
    </Row> */}
    <Row>
      <Col sm={12}>
        <GeneralGrid
          key={`task-${productId}`}
          data={currentProductTasks}
          columns={columns}
          keyName="TaskId"
          isLoading={isLoading}
          onRefresh={() => getProductTasks()}
          rowHeight={30}
          rowDoubleClicked={onRowDoubleClicked}
          onExtraButtonClick={(rows) => {
            onEditSelectedButtonClick(rows.map(task => task.TaskId));
          }}
          rowSelection= 'multiple'
        />
      </Col>
    </Row>
  </Grid>
  );
};

ProductTaskList.propTypes = {
  currentProductOrders: PropTypes.array, //eslint-disable-line
  isLoading: PropTypes.bool.isRequired,
  getProductTasks: PropTypes.func.isRequired,
  onRowDoubleClicked: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func, //.isRequired,
};

export default ProductTaskList;
