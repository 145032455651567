import PropTypes from 'prop-types';
import { isNullOrUndef } from '../../../utils/utils';

const AmountCellRenderer = props => {
  const { value, initialUomField, targetUomField, mode, nbDecimal, data } = props;
  const rates = {
    EUR: 1,
    USD: 1.12,
    CAD: 1.51,
    AUD: 1.62
  };
  if (isNullOrUndef(value)) return null;

  let initialUom = data[initialUomField]?data[initialUomField]:'EUR';
  let targetUom = data[targetUomField]?data[targetUomField]:'EUR';
  let convertedAmount = null;
  if (isNullOrUndef(mode) || mode === 'Natural') {
    convertedAmount = value;
  } else if (initialUom === targetUom) {
    convertedAmount = value;
  } else {
  // *****************************************************************************
    convertedAmount = value / rates[initialUom] * rates[targetUom];
  // *****************************************************************************
  }

  let valueToReturn = null;
  if (isNullOrUndef(nbDecimal)) {
    valueToReturn = convertedAmount;
  } else {
    valueToReturn = nbDecimal > 0 ? parseFloat(Number(convertedAmount).toFixed(nbDecimal)) : Math.round(convertedAmount);
  }
  return `${valueToReturn} ${targetUom || ''}`;
};

AmountCellRenderer.propTypes = {
  initialUom: PropTypes.string.isRequired,
  targetUom: PropTypes.string,
  mode: PropTypes.string,
  params: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  nbDecimal: PropTypes.number,
};

export default AmountCellRenderer;
