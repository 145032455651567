import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import * as R from 'ramda';
import { createGeneralSelectors } from '../_common/selectors';
import { isUndefined } from '../../utils/utils';
import * as substanceSelectors from '../substance/substanceSelectors';
import * as productProportionSelectors from '../productProportion/productProportionSelectors';

const getRawMatProportionSlice = state => state.rawMatProportion;

export const {
  getById,
  getIds,
  getLoading,
  getByQueryIds,
  makeGetByQueryLoading,
} = createGeneralSelectors(getRawMatProportionSlice);

export const getRawMatProportionList = createSelector([getIds, getById], (allIds, byId) =>
  allIds.map(id => byId[id]),
);
export const getRawMatProportion = (state, id) => state.rawMatProportion.byId[id];
export const getRawMatProportionWithSubstance = createSelector(
  getById,
  substanceSelectors.getById,
  (rawMatProportionById, substanceById) =>
    createSelector(
      (rawMatProportionId, substanceId) => rawMatProportionById[rawMatProportionId],
      (rawMatProportionId, substanceId) => substanceById[substanceId],
      (rawMatProportion, substance) => {
        if (!rawMatProportion && !substance) {
          return null;
        }
        const substanceToReturn = rawMatProportion ? substanceById[rawMatProportion.SubstanceId] : substance;
        return {
          ...rawMatProportion,
          SubstanceId: substanceToReturn.SubstanceId,
          Substance: substanceToReturn,
        };
      },
    ),
);

export const getRawMatProportionFromCompoForRawMat = createSelector(getRawMatProportionList, rawMatProportionList =>
  createSelector(rawMatId => rawMatProportionList.filter(rp => rp.RawMatId === rawMatId), rawMats => rawMats),
);

export const getRawMatProportionFromCompoForRawMatWithSubstance = createSelector(getRawMatProportionList, substanceSelectors.getById, (rawMatProportionList, substanceById) =>
  createSelector(rawMatId => {
    const list = rawMatProportionList.filter(rp => rp.RawMatId === rawMatId);
    list.map(elem => {
      const substance = substanceById[elem.SubstanceId];
      elem.Substance = substance;
    });
    return list;
  }, rawMats => rawMats),
);

export const getRawMatProportionListForRawMat = createSelector([getById, getByQueryIds], (byId, byQueryIds) =>
  createSelector(R.identity, rawMatId =>
      R.compose(
        R.filter(R.complement(isUndefined)),
        R.map(id => byId[id]),
        R.propOr([], queryString.stringify({ rawMatId })),
      )(byQueryIds),
  ),
);

export const makeGetRawMatProportionsForProduct = createSelector(
  productProportionSelectors.makeGetProductProportionForProduct,
  getProductProportionForProduct =>
    createSelector(
      [getProductProportionForProduct, getRawMatProportionList],
      (productProportionsForProduct, rawMatProportions) => {
        const rawMatIds = productProportionsForProduct.map(pp => pp.RawMatId);
        return rawMatProportions.filter(rmp => rawMatIds.includes(rmp.RawMatId));
      },
    ),
);

export const rawMatProportionFormValueSelector = formValueSelector('rawMatProportionForm');
