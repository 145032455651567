import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Form, Grid, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { InputField, DateField } from '../../../components/_Common/Forms';

const DraftLabelForm = props => (
  <Grid fluid>
    <Form>
      <Col sm={12}>
        <Col sm={6}>
          <Field name="CreatedBy" label={I18n.t('draftLabel.CreatedBy')} component={InputField} readOnly />
        </Col>
        <Col sm={6}>
          <Field name="CreatedOn" label={I18n.t('draftLabel.CreatedOn')} component={DateField} disabled />
        </Col>
      </Col>
    </Form>
  </Grid>
);

export default DraftLabelForm;
