import React from 'react';
import { Col, Row, ControlLabel, Media } from 'react-bootstrap';
import { Field } from 'redux-form';
import { I18n } from 'react-redux-i18n';
import clpImages from '../../../../assets/img/clp';
import images from '../../../../assets/img';
import { InputField, CheckBoxField } from '../../../_Common/Forms';
import CheckBoxSymbol from './CheckBoxSymbol';
import { createForm } from './utils';

const LeafletForm = props => (
  <Col sm={12}>
    <Col sm={6}>
      <Col sm={11}>
        <Row>
          <Field name="HasLeaflet" label={I18n.t('draftLabel.HasLeaflet')} component={CheckBoxField} />
        </Row>
        <Row>
          <Field name="ProductName" label={I18n.t('draftLabel.ProductName')} component={InputField} />
        </Row>
        <Row>
          <Field name="ProductFunction" label={I18n.t('draftLabel.ProductFunction')} component={InputField} />
        </Row>
        <Row>
          <Field name="NominalContents" label={I18n.t('draftLabel.NominalContents')} component={InputField} />
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsInstructionOnLeaflet"
              label={I18n.t('draftLabel.IsInstructionOnLeaflet')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="InstructionText"
              label={I18n.t('draftLabel.InstructionText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsMarketingOnLeaflet"
              label={I18n.t('draftLabel.IsMarketingOnLeaflet')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="MarketingTextOnLeaflet"
              label={I18n.t('draftLabel.MarketingTextOnLeaflet')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsCompanyOnLeaflet"
              label={I18n.t('draftLabel.IsCompanyOnLeaflet')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="CompanyLeafletText"
              label={I18n.t('draftLabel.CompanyLeafletText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Field
            name="IsIngredientOnLeaflet"
            label={I18n.t('draftLabel.IsIngredientOnLeaflet')}
            component={CheckBoxField}
          />
        </Row>
      </Col>
    </Col>
    <Col sm={6}>
      <Col sm={11} smOffset={1}>
        <Row>
          <Col sm={4}>
            <Media>
              <Media.Body>
                <Field
                  name="IsHairColorAttentionSymbolOnLeaflet"
                  label={I18n.t('draftLabel.IsHairColorAttentionSymbolOnLeaflet')}
                  component={CheckBoxField}
                />
              </Media.Body>
              <Media.Left align="middle">
                <img src={images.hairColorAttention} alt="hairColorAttention" />
              </Media.Left>
            </Media>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsWarningOnLeaflet"
              label={I18n.t('draftLabel.IsWarningOnLeaflet')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="WarningText"
              label={I18n.t('draftLabel.WarningText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
        <Row>
          <ControlLabel>{I18n.t('draftLabel.ClpSymboles')}</ControlLabel>
        </Row>
        <Row>
          <Col sm={2}>
            <Field name="IsFlammableSymbolOnLeaflet" component={CheckBoxSymbol} imageSrc={clpImages.flammable} />
          </Col>
          <Col sm={2}>
            <Field name="IsCombustibleSymbolOnLeaflet" component={CheckBoxSymbol} imageSrc={clpImages.oxidising} />
          </Col>
          <Col sm={2}>
            <Field name="IsCorrosiveSymbolOnLeaflet" component={CheckBoxSymbol} imageSrc={clpImages.corrosive} />
          </Col>
          <Col sm={2}>
            <Field name="IsIrritantSymbolOnLeaflet" component={CheckBoxSymbol} imageSrc={clpImages.irritant} />
          </Col>
          <Col sm={2}>
            <Field
              name="IsAllergenSymbolLeaflet"
              component={CheckBoxSymbol}
              imageSrc={clpImages.inhalationSensitizer}
            />
          </Col>
          <Col sm={2}>
            <Field name="IsEnvironmentSymbolOnLeaflet" component={CheckBoxSymbol} imageSrc={clpImages.environment} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Field
              name="IsTranslationOnLeaflet"
              label={I18n.t('draftLabel.IsTranslationOnLeaflet')}
              component={CheckBoxField}
            />
          </Col>
          <Col sm={9}>
            <Field
              name="TranslationText"
              label={I18n.t('draftLabel.TranslationText')}
              component={InputField}
              componentClass="textarea"
            />
          </Col>
        </Row>
      </Col>
    </Col>
  </Col>
);

LeafletForm.propTypes = {};

export default createForm(LeafletForm);
