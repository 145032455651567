import * as rawMatSelectors from './rawMatSelectors';
import * as rawMatActions from './rawMatActions';
import * as rawMatTypes from './rawMatTypes';
import * as rawMatSchemas from './rawMatSchemas';
import sagas, * as rawMatSagas from './rawMatSagas';
import reducer from './rawMatReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { rawMatSelectors, rawMatActions, rawMatTypes, rawMatSchemas, rawMatSagas };
export default reducer;
