import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { substanceActions, substanceSelectors, substanceTypes } from '../../../redux/substance';
import { substanceProportionActions, substanceProportionTypes } from '../../../redux/substanceProportion';
import { applicationSelectors } from '../../../redux/application';
import SubstanceCompositionTab from './SubstanceCompositionTab';

class SubstanceCompositionContainer extends Component {
  static propTypes = {
    substanceId: PropTypes.number.isRequired,
    onLoad: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onLoad();
  }

  componentDidUpdate(previousProps) {
    if (previousProps.substanceId !== this.props.substanceId) {
      this.props.onLoad();
    }
  }

  render() {
    return <SubstanceCompositionTab {...this.props} />;
  }
}

const mapStateToProps = (state, { substanceId }) => ({
  currentSubstanceComposition: substanceSelectors.getSubstanceComposition(state, substanceId),
  isLoadingSubstanceCompositionList: applicationSelectors.isLoading(
    state,
    substanceTypes.SUBSTANCE_PROPORTIONS.REQUEST,
    substanceProportionTypes.DELETE_SUBSTANCE_PROPORTION.REQUEST,
  ),
});

const mapDispatchToProps = (dispatch, { substanceId }) => ({
  onLoad: () => {
    if (substanceId > 0) {
      dispatch(substanceActions.listSubstanceProportions.request(substanceId));
    }
    dispatch(substanceActions.listSubstances.request());
  },
  getSubstanceProportions: () => dispatch(substanceActions.listSubstanceProportions.request(substanceId)),
  onAdd: () => dispatch(substanceProportionActions.addSubstanceProportion(substanceId)),
  onEdit: substanceProportionId =>
    dispatch(substanceProportionActions.editSubstanceProportion(substanceId, substanceProportionId)),
  onDelete: substanceProportionId =>
    dispatch(substanceProportionActions.deleteSubstanceProportion.request(substanceProportionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstanceCompositionContainer);
