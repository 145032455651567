import { createSelector } from 'reselect';
import * as R from 'ramda';
import { substanceFunctionSelectors } from '../substanceFunction';

export const getById = ({ func }) => func.byId;
export const getIds = ({ func }) => func.list.allIds;
export const getFunction = (state, functionId) => state.func.byId[functionId];
export const getFunctionList = createSelector([getIds, getById], (allIds, byId) => allIds.map(id => byId[id]));

export const getFunctionListNotLinkedWithSubstance = substanceId =>
  createSelector(
    [substanceFunctionSelectors.makeGetSubstanceFunctionListForSubstance(substanceId), getFunctionList],
    (substanceFunctionListForSubstance, functionList) =>
      R.differenceWith((f, sf) => f.FunctionId === sf.FunctionId, functionList, substanceFunctionListForSubstance),
  );

export const makeGetFunctionListNotLinkedWithSubstance = createSelector(
  getFunctionListNotLinkedWithSubstance,
  functionListNotLinkedWithSubstance => functionListNotLinkedWithSubstance,
);

export const getFunctionListForSubstance = createSelector(
  getFunctionList,
  substanceFunctionSelectors.getSubstanceFunctionList,
  (functionList, substanceFunctionList) =>
    createSelector(
      substanceId => substanceFunctionList.filter(sf => sf.SubstanceId === substanceId),
      substanceFunctionsForSubstance =>
        functionList.filter(f => substanceFunctionsForSubstance.map(s => s.FunctionId).includes(f.FunctionId)),
    ),
);

export const getFunctionListForSubstanceOrAll = createSelector(
  getFunctionListForSubstance,
  getFunctionList,
  (getFunctionsForSubstance, functionList) =>
    createSelector(
      substanceId => getFunctionsForSubstance(substanceId),
      functionsForSubstance =>
        (functionsForSubstance && functionsForSubstance.length ? functionsForSubstance : functionList),
    ),
);
