import * as productPhraseSelectors from './productPhraseSelectors';
import * as productPhraseActions from './productPhraseActions';
import * as productPhraseTypes from './productPhraseTypes';
import sagas from './productPhraseSagas';
import reducer from './productPhraseReducer';

import sagaManager from '../sagaManager';

sagaManager.addSagaToRoot(sagas);

export { productPhraseSelectors, productPhraseActions, productPhraseTypes };
export default reducer;
