import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { productSelectors } from '../../../../redux/product';
import { substanceSelectors } from '../../../../redux/substance';
import { applicationSelectors } from '../../../../redux/application';
import { productInciImpurityModalActions, productInciImpurityModalTypes } from '../../../../redux/modal/productInciImpurityModal';
import ProductInciImpurityListModal from './ProductInciImpurityListModal';

const ProductInciImpurityListModalContainer = props => <ProductInciImpurityListModal {...props} />;

const mapStateToProps = (state, { productId }) => {
  const product = productSelectors.getById(state)[productId];
  const productName = R.propOr('', 'ProductName')(product);
  return {
    title: I18n.t('productImpurityListModal.title', { productName }),
    isSaveDisabled:
      substanceSelectors.getIsLoadingImpuritySubstances(state) ||
      applicationSelectors.isLoading(state, productInciImpurityModalTypes.SAVE_INCI_SUBSTANCE_IMPURITIES.REQUEST),
  };
};

const mapDispatchToProps = (dispatch, { productId }) => ({
  onSaveButtonClick: data => {
    dispatch(productInciImpurityModalActions.onSaveButtonClick(productId, data));
  },
});

ProductInciImpurityListModalContainer.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInciImpurityListModalContainer);
