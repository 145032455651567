import React from 'react';
import PropTypes from 'prop-types';
import SubstanceRawMatListContainer from './_SubstanceRawMatListContainer';

const RawMatListTab = ({ substanceId }) => (
  <div>
    <SubstanceRawMatListContainer substanceId={substanceId} />
  </div>
);

RawMatListTab.propTypes = {
  substanceId: PropTypes.number.isRequired,
};

export default RawMatListTab;
